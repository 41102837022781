import { NotificationsService } from '@mt-ng2/notifications-module';
import { IAttachmentType } from '@model/interfaces/attachment-type.d';
import { AttachmentTypeService } from './../../common/attachments/attachment-type.service';
import { Component, OnInit, Input } from '@angular/core';
import { IUserRole } from '@model/interfaces/user-role';
import { DynamicField, DynamicFieldType, IDynamicFieldType, IDynamicField, DynamicFieldTypes } from '@mt-ng2/dynamic-form';
import { UserRoleService } from '../user-role.service';

@Component({
    selector: 'app-user-role-doc-type',
    templateUrl: './user-role-doctype.component.html',
})
export class UserRoleDocTypeComponent implements OnInit {
    @Input() userRole: IUserRole;
    @Input() canEdit: boolean;
    documentTypes: IAttachmentType[];
    checkedDocType: IAttachmentType[];
    constructor(
        private attachmentTypeService: AttachmentTypeService,
        private userRoleService: UserRoleService,
        private notificationService: NotificationsService,
    ) { }
    ngOnInit(): void {
        this.checkedDocType = [];
        this.attachmentTypeService.getItems().subscribe((items) => {
            this.documentTypes = items.filter((x) => {
                return x.UsedForUserRoleAssociation === true;
            });
            this.checkedDocType = this.documentTypes
                .filter((docType) => this.userRole.UserRolesAttachmentTypes.some((item) => item.AttachmentTypeId === docType.Id))
                .map((docType) => {
                    return { ...docType };
                });
        });
    }
    getValueForDynamicField(docTypeObject: IAttachmentType): boolean {
        return this.userRole.UserRolesAttachmentTypes.some((item) => item.AttachmentTypeId === docTypeObject.Id);
    }

    getDynamicField(docTypeObject: IAttachmentType): DynamicField {
        const Value: boolean = this.getValueForDynamicField(docTypeObject);
        return new DynamicField({
            formGroup: null,
            label: docTypeObject.Name,
            name: 'docTypeObject',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Checkbox,
            } as IDynamicFieldType),
            value: Value,
        } as IDynamicField);
    }

    setDocTypeCheck(docTypeObject: IAttachmentType, checked: boolean): void {
        if (checked) {
            this.checkedDocType.push(docTypeObject);
        } else {
            const i = this.checkedDocType.findIndex((item) => item.Id === docTypeObject.Id);
            this.checkedDocType.splice(i, 1);
        }
    }

    saveSelections(): void {
        this.userRoleService.updateUserRoleDocType(this.userRole.Id, this.checkedDocType).subscribe(() => {
            this.notificationService.success('Saved successfully');
        });
    }
}
