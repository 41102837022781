import { Component } from '@angular/core';

import { IEntityListDynamicCellComponent, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { IEntity } from '@mt-ng2/base-service';
import { IModalOptions } from '@mt-ng2/modal-module';

import { IPhoneNumber } from '@model/interfaces/phone-number';

@Component({
    template: `
        <div *ngIf="phoneNumber && phoneNumber.Archived" class="text-center">
            <i (mtConfirm)="archivePhoneNumber($event)" class="fa fa-trash fa-2x" aria-hidden="true" [mtConfirmOptions]="unarchiveConfirm"></i
            ><i class="fa fa-lg fa-undo overlay-fa"></i>
        </div>
        <div *ngIf="phoneNumber && !phoneNumber.Archived" class="text-center">
            <i (mtConfirm)="archivePhoneNumber($event)" class="fa fa-trash fa-2x" aria-hidden="true" [mtConfirmOptions]="archiveConfirm"></i>
        </div>
    `,
})
export class PhoneDeleteCellComponent implements IEntityListDynamicCellComponent {
    entityListComponentMembers: IEntityListComponentMembers;
    set entity(value: IEntity) {
        this.phoneNumber = value as IPhoneNumber;
    }

    phoneNumber: IPhoneNumber;

    archiveConfirm: IModalOptions = {
        confirmButtonText: 'Yes, I am sure!',
        text: `Are you sure you want to delete this phone number?`,
        title: 'Delete Phone Number',
    	icon: 'question',
};

    unarchiveConfirm: IModalOptions = {
        confirmButtonText: 'Yes, I am sure!',
        text: `Are you sure you want to restore this phone number?`,
        title: 'Restore Phone Number',
    	icon: 'question',
};

    constructor() { }

    archivePhoneNumber(event: Event): void {
        this.entityListComponentMembers.itemDeleted(this.phoneNumber as IEntity, event);
    }
}
