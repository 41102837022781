import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { IImeAppointment } from '@model/interfaces/ime-appointment';
import * as moment from 'moment-timezone';
/* This is a work around - based on the following issue (https://gitlab.4miles.com/Engineering/Breckenridge/issues/91) resolution we should update the references to this class with the new implementation */

@Injectable()
export class DateTimeConverterService {
    constructor(private datePipe: DatePipe) { }
    converToUTC(dateTime: Date): Date {
        const date = new Date(dateTime);
        return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes()));
    }

    applyTimezoneToAppointment(appt: IImeAppointment): moment.Moment {
        return this.GetDateTimeForTimeZone(appt.AppointmentDateTime, appt.Timezone);
    }

    GetDateTimeForTimeZone(date: Date, TimeZone: string): moment.Moment {
        return moment.tz(date, TimeZone);
    }

    convertToBrowserTimeZoneString(dateTime: Date): string | moment.Moment {
        return this.datePipe.transform(dateTime, 'MMMM d, yyyy, hh:mm a');
    }

    convertToDate(dateTime: Date): string {
        return this.datePipe.transform(dateTime, 'MMMM d, yyyy');
    }

    convertToDateSlashes(dateTime: Date): string {
        return this.datePipe.transform(dateTime, 'MM/dd/yyyy', 'UTC');
    }

    assumeLocalTimeForUTCDateObject(value: Date): Date {
        if (!value) {
            return null;
        }
        const dateAsString = value.toString();
        return new Date(dateAsString.substring(0, dateAsString.length - 1));
    }
}
