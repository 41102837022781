import { IPhysicianVacation } from '@model/interfaces/physician-vacation.d';
import { IImeAppointment } from '@model/interfaces/ime-appointment.d';
import { SearchParams } from '@mt-ng2/common-classes';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BaseService, ICreateOptions } from '@mt-ng2/base-service';
import { map, tap } from 'rxjs/operators';
import { DateTimeConverterService } from '../../../../../common/services/date-time-converter.service';

export const emptyImeAppointments: IImeAppointment = {
    AppointmentDateTime: null,
    Archived: false,
    CreatedById: 0,
    DateCreated: new Date(),
    Id: 0,
    ImedClaimServiceId: 0,
    Notes: null,
};

@Injectable()
export class AutoImeAppointmentService extends BaseService<IImeAppointment> {
    private appointmentCreatedSubject: Subject<IImeAppointment>;

    appointmentCreated$: Observable<IImeAppointment>;

    constructor(public http: HttpClient, private dateService: DateTimeConverterService) {
        super('/imeappointments', http);

        this.appointmentCreatedSubject = new Subject();
        this.appointmentCreated$ = this.appointmentCreatedSubject.asObservable();
    }

    getEmptyImeAppointments(): IImeAppointment {
        return { ...emptyImeAppointments };
    }

    searchAppointmentLogs(imedClaimServiceId: number, data: SearchParams): any {
        const params = this.getHttpParams(data);
        return this.http.get<IImeAppointment>(`/imeappointments/${imedClaimServiceId}/_search`, { observe: 'response', params: params });
    }
    getLastAppointmentFromTodaysDate(imedClaimServiceId: number): Observable<IImeAppointment> {
        return this.http.get<IImeAppointment>(`/imeappointments/getLastAppointmentFromTodaysDate/${imedClaimServiceId}`);
    }

    // overriding the get so that I can update the dates that should not have time
    // to remove the 'Z' and therefore consider them already in browser's time zone
    getPhysicianVacations(Id: number): Observable<IPhysicianVacation[]> {
        return this.http.get<IPhysicianVacation[]>(`/imeappointments/getPhysicianVacations/${Id}`).pipe(
            map((response) => {
                (<any>response) = response.map(this.fixDatesForVacation);
                return response;
            }),
        );
    }

    private fixDatesForVacation = (vacation: IPhysicianVacation): IPhysicianVacation => {
        vacation.FromDate = this.dateService.assumeLocalTimeForUTCDateObject(vacation.FromDate);
        vacation.ToDate = this.dateService.assumeLocalTimeForUTCDateObject(vacation.ToDate);
        return vacation;
    }

    archive(id: number, userId: number): Observable<number> {
        return this.http.put<number>(`/imeappointments/archive/${id}/user/${userId}`, {});
    }

    create(entity: IImeAppointment, options?: ICreateOptions): Observable<number> {
        return super.create(entity, options).pipe(
            tap(() => {
                this.appointmentCreatedSubject.next(entity);
            }),
        );
    }
}
