import { ISpecialtyDetail } from '@model/interfaces/specialty-detail.d';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MetaItemService } from '@mt-ng2/base-service';

@Injectable()
export class SpecialityDetailService extends MetaItemService<ISpecialtyDetail> {
    constructor(public http: HttpClient) {
        super('SpecialityDetailService', 'SpecialityDetail', 'SpecialityDetailIds', '/specialitydetails', http);
    }
}
