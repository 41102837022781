import { Component, OnInit } from '@angular/core';

import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IUser } from '@model/interfaces/user';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, SelectInputTypes } from '@mt-ng2/dynamic-form';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { UserService } from '../user.service';

@Component({
    selector: 'app-user-reassign',
    templateUrl: './user-reassign.component.html',
})
export class UserReassignComponent implements OnInit {
    constructor(
        private fb: FormBuilder,
        private userService: UserService,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
    ) {}

    reassignForm: FormGroup;
    tasksField: DynamicField;
    schedulingAssignedToField: DynamicField;
    retrievalAssignedToField: DynamicField;
    compilationAssignedToField: DynamicField;
    reportAssignedToField: DynamicField;
    alphabetsField: DynamicField;
    userField: DynamicField;

    users: IUser[] = [];

    showAlphabetField = false;

    ngOnInit(): void {
        this.reassignForm = this.fb.group({});

        this.tasksField = new DynamicField({
            label: 'Tasks',
            name: 'Tasks',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Checkbox,
            }),
            value: false,
            formGroup: '',
        });

        this.schedulingAssignedToField = new DynamicField({
            label: 'Scheduling Assigned To',
            name: 'SchedulingAssignedTo',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Checkbox,
            }),
            value: false,
            formGroup: '',
        });

        this.retrievalAssignedToField = new DynamicField({
            label: 'Retrieval Assigned To',
            name: 'RetrievalAssignedTo',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Checkbox,
            }),
            value: false,
            formGroup: '',
        });

        this.compilationAssignedToField = new DynamicField({
            label: 'Compilation Assigned To',
            name: 'CompilationAssignedTo',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Checkbox,
            }),
            value: false,
            formGroup: '',
        });

        this.reportAssignedToField = new DynamicField({
            label: 'Report Assigned To',
            name: 'ReportAssignedTo',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Checkbox,
            }),
            value: false,
            formGroup: '',
        });

        this.alphabetsField = new DynamicField({
            label: 'Physician / Individual / Facility',
            name: 'Alphabets',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.MultiselectDropdown,
                showSelectAllButtons: true,
            }),
            options: new Array(26).fill(0).map((_, i) => ({
                Id: String.fromCharCode(65 + i),
                Name: String.fromCharCode(65 + i),
            })),
            value: [],
            formGroup: '',
        });

        this.userField = new DynamicField({
            label: 'User',
            name: 'UserId',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.Dropdown,
            }),
            options: [],
            value: null,
            formGroup: '',
            validation: [(c: AbstractControl) => Validators.required(c)],
        });

        this.userService.getAll().subscribe((users) => {
            this.users = users;

            this.userField.options = this.users.map((user) => ({
                Id: user.Id,
                Name: `${user.FirstName} ${user.LastName}`,
            }));
        });

        this.reassignForm.valueChanges.subscribe(value => {
            this.showAlphabetField = value.Tasks || value.RetrievalAssignedTo;
        })
    }

    handleSubmit(): void {
        this.reassignForm.markAllAsTouched();

        if (this.reassignForm.valid) {
            this.userService
                .reassignTasksAndAssignedTo(+this.route.snapshot.paramMap.get('userId'), {
                    ...this.reassignForm.value,
                })
                .subscribe({
                    next: () => {
                    this.notificationsService.success('Reassigned successfully');
                },
                error: () => {
                    this.notificationsService.error('Failed to Reassign Tasks and Assigned To');
                }
            });
        }
    }
}
