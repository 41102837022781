import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { Observable } from 'rxjs';
import { IGroupedEmailTemplatesByFollowupDTO } from '@model/interfaces/custom/grouped-email-templates-by-followup-dto';
import { IImedClaimMasterFollowupTypeEmailTemplate } from '@model/interfaces/imed-claim-master-followup-type-email-template';

export const emptyEmailTemplateAssociation: IImedClaimMasterFollowupTypeEmailTemplate = {
    EmailTemplateId: null,
    Id: 0,
    MasterFollowupTypeId: null,
    ImedClaimTypeId: null,
};

@Injectable({
    providedIn: 'root',
})
export class ImedClaimMasterFollowupTypeEmailTemplateService extends BaseService<IImedClaimMasterFollowupTypeEmailTemplate> {
    constructor(public http: HttpClient) {
        super('/imedclaim-masterfollowuptype-emailtemplates', http);
    }

    getEmptyEmailTemplateAssociation(): IImedClaimMasterFollowupTypeEmailTemplate {
        return emptyEmailTemplateAssociation;
    }

    getEmailTemplatesGroupedByFollowup(claimtypeId: number): Observable<IGroupedEmailTemplatesByFollowupDTO[]> {
        return this.http.get<IGroupedEmailTemplatesByFollowupDTO[]>(`/imedclaim-masterfollowuptype-emailtemplates/${claimtypeId}/grouped-email-templates`);
    }
}
