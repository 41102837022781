// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row {
  border: 1px solid #ccc;
  padding-top: 10px;
  margin-top: 10px;
  background: #ececec;
  padding-bottom: 15px;
  margin-bottom: -15px;
}
.search-option {
  border: 1px solid #ccc;
  cursor: pointer;
}
.search-option:hover {
  background: #ececec;
}
.search-option:focus {
  outline: none;
}
.select-search {
  border: none;
  padding: 5px 0 5px 0;
  padding-left: 20px;
  height: 100%;
  width: 90%;
  display: inline-block;
}
.delete-search {
  padding: 5px 0 5px 0;
  display: inline-block;
  padding-right: 5px;
  line-height: 1em;
}
.delete-search:hover {
  text-shadow: 1px 1px 2px #444;
}
.dropdown-toggle {
  background: #fff;
}
.dropdown-menu {
  min-width: 300px;
  max-height: 400px;
  overflow-y: auto;
  padding: 0;
}
`, "",{"version":3,"sources":["webpack://./src/app/common/saved-search-filters/saved-search-filters.component.less"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,iBAAA;EACA,gBAAA;EACA,mBAAA;EACA,oBAAA;EACA,oBAAA;AACJ;AAEA;EACI,sBAAA;EACA,eAAA;AAAJ;AACI;EACI,mBAAA;AACR;AACI;EACI,aAAA;AACR;AAGA;EACI,YAAA;EACA,oBAAA;EACA,kBAAA;EACA,YAAA;EACA,UAAA;EACA,qBAAA;AADJ;AAIA;EACI,oBAAA;EACA,qBAAA;EACA,kBAAA;EACA,gBAAA;AAFJ;AAGI;EACI,6BAAA;AADR;AAKA;EACI,gBAAA;AAHJ;AAMA;EACI,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,UAAA;AAJJ","sourcesContent":[".row {\n    border: 1px solid #ccc;\n    padding-top: 10px;\n    margin-top: 10px;\n    background: #ececec;\n    padding-bottom: 15px;\n    margin-bottom: -15px;\n}\n\n.search-option {\n    border: 1px solid #ccc;\n    cursor: pointer;\n    &:hover {\n        background: #ececec;\n    }\n    &:focus {\n        outline: none;\n    }\n}\n\n.select-search {\n    border: none;\n    padding: 5px 0 5px 0;\n    padding-left: 20px;\n    height: 100%;\n    width: 90%;\n    display: inline-block;\n}\n\n.delete-search {\n    padding: 5px 0 5px 0;\n    display: inline-block;\n    padding-right: 5px;\n    line-height: 1em;\n    &:hover {\n        text-shadow: 1px 1px 2px #444;\n    }\n}\n\n.dropdown-toggle {\n    background: #fff;\n}\n\n.dropdown-menu {\n    min-width: 300px;\n    max-height: 400px;\n    overflow-y: auto;\n    padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
