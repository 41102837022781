// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            button {
                max-width: 760px;
            }
            button > span {
                overflow-wrap: break-word;
                max-width: 750px;
                white-space: normal;
            }
        `, "",{"version":3,"sources":["webpack://./src/app/tasks/task-list/task-assign-bulk/task-assign-bulk.component.ts"],"names":[],"mappings":";YACY;gBACI,gBAAgB;YACpB;YACA;gBACI,yBAAyB;gBACzB,gBAAgB;gBAChB,mBAAmB;YACvB","sourcesContent":["\n            button {\n                max-width: 760px;\n            }\n            button > span {\n                overflow-wrap: break-word;\n                max-width: 750px;\n                white-space: normal;\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
