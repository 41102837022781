// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sticky {
  position: fixed;
  padding-right: 10px;
  padding-top: 10px;
  margin-left: -20px;
  width: 150px;
  height: 100vh;
}
.user-list {
  height: max-content;
  width: 100%;
  padding: 5px;
  background: #b73239;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.18824);
}
.user-list li {
  list-style-type: none;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
}
.user-list li:nth-child(-n + 3) {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 5px;
}
.user-list li:nth-child(3) {
  border-bottom: solid 1px #fff;
}
`, "",{"version":3,"sources":["webpack://./src/app/common/concurrent-users/concurrent-users.component.less"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,mBAAA;EACA,iBAAA;EACA,kBAAA;EAEA,YAAA;EACA,aAAA;AAAJ;AAGA;EACI,mBAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,+EAAA;AADJ;AAJA;EAOQ,qBAAA;EACA,gBAAA;EACA,eAAA;EACA,WAAA;AAAR;AACQ;EACI,gBAAA;EACA,eAAA;EACA,kBAAA;AACZ;AACQ;EACI,6BAAA;AACZ","sourcesContent":[".sticky {\n    position: fixed;\n    padding-right: 10px;\n    padding-top: 10px;\n    margin-left: -20px;\n\n    width: 150px;\n    height: 100vh;\n}\n\n.user-list {\n    height: max-content;\n    width: 100%;\n    padding: 5px;\n    background: #b73239;\n    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.18824);\n    li {\n        list-style-type: none;\n        font-weight: 500;\n        font-size: 14px;\n        color: #fff;\n        &:nth-child(-n + 3) {\n            font-weight: 700;\n            font-size: 16px;\n            margin-bottom: 5px;\n        }\n        &:nth-child(3) {\n            border-bottom: solid 1px #fff;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
