import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { Observable } from 'rxjs';
import { IPhysicianStatusTaskTemplate } from '@model/interfaces/physician-status-task-template';
import { IGroupedTaskTemplatesByPhysicianStatusDTO } from '@model/interfaces/custom/grouped-task-templates-by-physician-status-dto';

export const emptyAddressBookTypeTaskAssociation: IPhysicianStatusTaskTemplate = {
    AddressBookTypeId: null,
    Id: 0,
    PhysicianStatusId: null,
    TaskTemplateId: null,
};

@Injectable()
export class PhysicianStatusTaskTemplateService extends BaseService<IPhysicianStatusTaskTemplate> {
    constructor(public http: HttpClient) {
        super('/physician-status-task-templates', http);
    }

    getEmptyAddressBookTypeTaskAssociation(): IPhysicianStatusTaskTemplate {
        return emptyAddressBookTypeTaskAssociation;
    }

    getGroupedTaskTemplatesByAddressBookType(addressBookTypeId: number): Observable<IGroupedTaskTemplatesByPhysicianStatusDTO[]> {
        return this.http.get<IGroupedTaskTemplatesByPhysicianStatusDTO[]>(
            `/physician-status-task-templates/${addressBookTypeId}/grouped-task-templates`,
        );
    }
}
