import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';
import { IBillsForReview } from '@model/interfaces/bills-for-review';
import { IBillsForReviewDynamicControlsParameters } from '@model/form-controls/bills-for-review.form-controls';
import { BillsForReviewDynamicControlsPartial } from '@model/partials/bills-for-review-partial.form-controls';

export class BillsForReviewDynamicConfig<T extends IBillsForReview> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private billForReview: T, private configControls?: string[]) {
        super();

        const additionalParams: IBillsForReviewDynamicControlsParameters = {};
        const dynamicControls = new BillsForReviewDynamicControlsPartial(billForReview, additionalParams);

        if (!configControls) {
            this.configControls = [
                'Facility',
                'Dos',
                'Amount',
                'HcpcsCode',
            ];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
