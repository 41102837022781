import { Component, OnInit, ViewChild, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import 'rxjs/operators';
import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { AuthService, ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection, IItemDeletedEvent } from '@mt-ng2/entity-list-module';
import { ClaimTypes } from '@model/ClaimTypes';
import { ImedClaimFollowupsEntityListConfig } from './imed-claim-followups.entity-list-config';
import { forkJoin, Subscription, Observable } from 'rxjs';
import { MtSearchBarComponent } from '@mt-ng2/searchbar-control';
import { MetaItem } from '@mt-ng2/base-service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IViewGetFollowUpsForList } from '@model/interfaces/view-get-follow-ups-for-list';
import { DatePipe } from '@angular/common';
import { map } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { ImedClaimTypes } from '@model/ImedClaimTypes';
import { IViewGetCaseFollowupsForList } from '@model/interfaces/view-get-case-followups-for-list';
import { ImedClaimFollowupService } from '../../imedclaim-followup.service';
import { MasterFollowupTypeService } from '../../services/common-components/service-followups/master-follow-up-type-service';
import { ServiceService } from '../../services/service.service';
import { UserRoleService } from '../../../user-roles/user-role.service';
import { UserService } from '../../../users/user.service';
import { CommonSearchService } from '@common/services/common-search.service';
import { ImedClaimTypeService } from '@common/services/imed-claim-type.service';
import { entityListModuleConfig } from '@common/shared.module';
import { IService } from '@model/interfaces/service';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-imed-claim-followups',
    templateUrl: './imed-claim-followups.component.html',
})
export class ImedClaimFollowupsComponent implements OnInit, OnDestroy {
    searchControl = new UntypedFormControl();
   imedClaimFollowups: IViewGetCaseFollowupsForList[] = [];
    currentPage = 1;
    query = '';
    total: number;
    followupTypes: MtSearchFilterItem[] = [];
    imedClaimTypes: MtSearchFilterItem[] = [];
    allServiceTypes: MtSearchFilterItem<IService>[] = [];
    serviceTypes: MtSearchFilterItem<IService>[] = [];
    userRoles: MtSearchFilterItem[] = [];
    users: MtSearchFilterItem[] = [];
    IsGlobalFollowUpList = false;
    entityListConfig = new ImedClaimFollowupsEntityListConfig(this.IsGlobalFollowUpList);
    order = 'ImedClaimFollowUpId';
    orderDirection = 'desc';
   imedClaimId: number;
    canAddImedClaimFollowup = false;
    entityName = 'Date Range';
    startDate: Date;
    endDate: Date;
    searchForm: UntypedFormGroup;
    commonSearchParams: SearchParams;
    subscriptions: Subscription = new Subscription();
    includeArchived = false;
    userCustomOptions: any;
    @ViewChild('searchBar') searchBar: MtSearchBarComponent;

    constructor(
        private imedClaimFollowupService: ImedClaimFollowupService,
        private masterFollowupTypeService: MasterFollowupTypeService,
        private serviceTypeService: ServiceService,
        private claimsService: ClaimsService,
        private userRoleService: UserRoleService,
        private userService: UserService,
        private commonSearchService: CommonSearchService,
        private router: Router,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
        private datePipe: DatePipe,
        private imedClaimTypeService: ImedClaimTypeService,
        private authService: AuthService,
    ) {}

    ngOnInit(): void {
        this.exportFollowUpList();
        this.searchForm = new UntypedFormGroup({
            searchIncludeArchived: new UntypedFormControl(false),
        });

        this.subscriptions.add(
            this.searchForm.get('searchIncludeArchived').valueChanges.subscribe((value) => {
                this.includeArchived = value;
                this.clearCommonSearchParamsAndSearch();
            }),
        );

        this.imedClaimId = +this.route.snapshot.parent.params.imedClaimId;
        this.canAddImedClaimFollowup =
            this.claimsService.hasClaim(ClaimTypes.CaseManagement, [ClaimValues.FullAccess]) && this.imedClaimId > 0;
        this.subscribeToUsers();
        forkJoin([
            this.masterFollowupTypeService.getMasterFollowupTypes(),
            this.serviceTypeService.getServiceTypeSortedByName(),
            this.userRoleService.getAll(),
            this.commonSearchService.getCommonSearchFilters(),
            this.imedClaimTypeService.getAll(),
        ]).subscribe(([followUpTypes, serviceTypes, userRoles, commonSearchParams, imedClaimTypes]) => {
            this.followupTypes = followUpTypes;
            this.allServiceTypes = serviceTypes;
            (this.userRoles = userRoles.map((role) => new MtSearchFilterItem(new MetaItem(role.Id, role.Name), false))),
                (this.commonSearchParams = commonSearchParams ? commonSearchParams.searchFilters : null);
            this.imedClaimTypes = imedClaimTypes.map((type) => new MtSearchFilterItem(type, false));
            this.imedClaimTypes.forEach((x) => (x.Selected = this.checkUserCustomOption(x)));
            this.commonSearchService.clearCommonSearchFilters();
            this.updateServiceFilter();
            this.getImedClaimFollowups();
        });
        this.setUserCustomOptions();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    exportFollowUpList(): void {
        this.entityListConfig.export = {
            exportName: 'Follow Up List',
            getDataForExport: () => this.getImedClaimFollowupsSubscription(true).pipe(map((x) => x.body)),
        };
     }

    subscribeToUsers(): void {
        this.subscriptions.add(
            this.userService.getAll().subscribe((users) => {
                this.users = users.map((user) => new MtSearchFilterItem(new MetaItem(user.Id, `${user.FirstName} ${user.LastName}`), false));
            }),
        );
    }

    resetFilters(): void {
        this.searchControl.reset();
        this.searchBar.clearSearch();
        this.followupTypes.forEach((x) => (x.Selected = false));
        this.imedClaimTypes.forEach((x) => (x.Selected = this.checkUserCustomOption(x)));
        this.serviceTypes.forEach((x) => (x.Selected = false));
        this.userRoles.forEach((x) => (x.Selected = false));
        this.users.forEach((x) => (x.Selected = false));
        this.startDate = null;
        this.endDate = null;
        this.searchForm.get('searchIncludeArchived').setValue(false);
        this.clearCommonSearchParamsAndSearch();
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    setUserCustomOptions(): void {
        this.userCustomOptions = this.authService.currentUser.getValue().CustomOptions;
    }

    checkUserCustomOption(filterItem: MtSearchFilterItem): boolean {
        switch (filterItem.Item.Id as ImedClaimTypes) {
            case ImedClaimTypes.GeneralLiability:
                return this.userCustomOptions.GeneralLiability;
            case ImedClaimTypes.WorkersCompensation:
                return this.userCustomOptions.WorkersComp;
            case ImedClaimTypes.Auto:
                return this.userCustomOptions.Auto;
            default:
                return false;
        }
    }

    dateSelectionChanged(evt): void {
        this.startDate = evt.startDate;
        this.endDate = evt.endDate;
        this.clearCommonSearchParamsAndSearch();
    }

    private buildSearch(): ExtraSearchParams[] {
        const selectedMasterFollowupTypesIds: number[] = this.getSelectedFilters(this.followupTypes);
        const selectedImedClaimTypeIds: number[] = this.getSelectedFilters(this.imedClaimTypes);
        const selectedServiceTypesIds: number[] = this.getSelectedFilters(this.serviceTypes);
        const selectedUserRoles: number[] = this.getSelectedFilters(this.userRoles);
        const selectedUsers: number[] = this.getSelectedFilters(this.users);
        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'MasterFollowupTypeIds',
                valueArray: selectedMasterFollowupTypesIds,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'imedClaimTypeIds',
                valueArray: selectedImedClaimTypeIds,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'ServiceTypeIds',
                valueArray: selectedServiceTypesIds,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'UserRoles',
                valueArray: selectedUserRoles,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'Users',
                valueArray: selectedUsers,
            }),
        );

        if (this.startDate && this.endDate) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'StartDate',
                    value: this.datePipe.transform(this.startDate, 'M/d/yyyy', 'UTC'),
                }),
            );
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'EndDate',
                    value: this.datePipe.transform(this.endDate, 'M/d/yyyy', 'UTC'),
                }),
            );
        }

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'includeArchived',
                value: this.includeArchived ? '1' : '0',
            }),
        );

        return _extraSearchParams;
    }

    // Clear out common search params before getting next results, also resets page to 1  to avoid 0 results
    clearCommonSearchParamsAndSearch(): void {
        this.commonSearchParams = null;
        this.currentPage = 1;
        this.getImedClaimFollowups();
    }

    getImedClaimFollowups(): void {
        this.getImedClaimFollowupsSubscription().subscribe((answer) => {
            this.imedClaimFollowups = answer.body;
            if (!this.imedClaimId) {
                this.IsGlobalFollowUpList = true;
            }
            this.entityListConfig = new ImedClaimFollowupsEntityListConfig(this.IsGlobalFollowUpList);
            this.total = +answer.headers.get('X-List-Count');
            this.exportFollowUpList();
        });
    }

    getImedClaimFollowupsSubscription(skipPaging?: boolean): Observable<HttpResponse<IViewGetCaseFollowupsForList[]>> {
        const search = this.commonSearchParams && this.commonSearchParams.query ? this.commonSearchParams.query : this.query;
        const _extraSearchParams: ExtraSearchParams[] =
            this.commonSearchParams && this.commonSearchParams.extraParams ? this.commonSearchParams.extraParams : this.buildSearch();
        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.commonSearchParams && this.commonSearchParams.order ? this.commonSearchParams.order : this.order,
            orderDirection:
                this.commonSearchParams && this.commonSearchParams.orderDirection ? this.commonSearchParams.orderDirection : this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: skipPaging ? 0 : (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: skipPaging ? 0 : entityListModuleConfig.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        return this.imedClaimFollowupService.searchForListPage(searchparams, this.imedClaimId);
    }

    search(query: string): void {
        this.query = query;
        this.getImedClaimFollowups();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getImedClaimFollowups();
    }

   imedClaimFollowupSelected(event: IItemSelectedEvent): void {
        void this.router.navigate(['./', event.entity.ImedClaimFollowUpId], { relativeTo: this.route });
    }

    selectCaseTypeAndFilterServices(): void {
        this.updateServiceFilter();
        this.clearCommonSearchParamsAndSearch();
    }

    updateServiceFilter(): void {
        const selectedClaimTypes = this.imedClaimTypes.filter((ct) => ct.Selected);

        this.serviceTypes = this.allServiceTypes.filter((s) => {
            if (selectedClaimTypes.length > 0) {
                const returnValue = selectedClaimTypes.some((c) => c.Item.Id === s.Item.ClaimTypeId);
                return returnValue;
            } else {
                return true;
            }
        });
    }

    close(): void {
        void this.router.navigateByUrl(this.router.url.replace('/follow-ups', ''));
    }

    onItemDeleted(event: IItemDeletedEvent): void {
        this.imedClaimFollowupService.archive(event.entity.ImedClaimFollowUpId as number).subscribe(() => {
            this.notificationsService.success('Followup Updated Successfully');
            this.getImedClaimFollowups();
        });
    }
}
