// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            div {
                background-color: #4c5166;
                color: white;
                border-radius: 4px;
                padding: 10px;
            }
            .service-link {
                cursor: pointer;
            }
            .service-link:hover {
                text-decoration: underline;
            }
        `, "",{"version":3,"sources":["webpack://./src/app/common/tooltip-directive/services-tooltip.component.ts"],"names":[],"mappings":";YACY;gBACI,yBAAyB;gBACzB,YAAY;gBACZ,kBAAkB;gBAClB,aAAa;YACjB;YACA;gBACI,eAAe;YACnB;YACA;gBACI,0BAA0B;YAC9B","sourcesContent":["\n            div {\n                background-color: #4c5166;\n                color: white;\n                border-radius: 4px;\n                padding: 10px;\n            }\n            .service-link {\n                cursor: pointer;\n            }\n            .service-link:hover {\n                text-decoration: underline;\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
