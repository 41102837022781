import { DynamicField } from '@mt-ng2/dynamic-form';
import { IFilmReviewServiceDynamicControlsParameters } from './../form-controls/film-review-service.form-controls';
import { IFilmReviewService } from '../interfaces/film-review-service';
import { FilmReviewServiceDynamicControls } from '../form-controls/film-review-service.form-controls';

export class FilmReviewServiceDynamicControlsPartial extends FilmReviewServiceDynamicControls {
    constructor(private filmReviewService: IFilmReviewService, private configControls?: IFilmReviewServiceDynamicControlsParameters) {
        super(filmReviewService, configControls);

        // (<DynamicField>this.Form.DiscCopiedDate).value = new Date();
        // (<DynamicField>this.Form.DiscCopiedDate).value = new Date();
        (<DynamicField>this.Form.RequestDate).value = new Date();
        (<DynamicField>this.Form.IsFederalCase).label = 'Federal Case';
        (<DynamicField>this.View.IsFederalCase).label = 'Federal Case';
    }
}
