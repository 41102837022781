import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IMetaAddressBookType } from '@model/interfaces/meta-address-book-type';

@Injectable()
export class MetaAddressBookTypeService extends StaticMetaItemService<IMetaAddressBookType> {
    constructor(public http: HttpClient) {
        super('MetaAddressBookTypeService', 'Type', 'TypeIds', '/metaaddressbooktypes', http);
    }
}
