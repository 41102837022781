import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, IDynamicFieldType, IDynamicField } from '@mt-ng2/dynamic-form';
import { IImedClaimService } from '@model/interfaces/imed-claim-service';

@Component({
    selector: 'app-rush-service-control',
    template: `
        <div *ngIf="!isEditing">
            <div class="PadL5"><label>Rush Service: </label>{{ imedClaimService.RushService ? ' Yes' : ' No' }}<br /></div>
        </div>
        <div *ngIf="isEditing">
            <mt-dynamic-field [field]="rushServiceCheckBox" (valueChanges)="imedClaimService.RushService = $event"></mt-dynamic-field>
        </div>
    `,
})
export class RushServiceFormControlComponent implements OnInit {
    @Input('isEditing') isEditing: boolean;
    @Input('imedClaimService') imedClaimService: IImedClaimService;
    rushServiceCheckBox: DynamicField;

    constructor() { }

    ngOnInit(): void {
        this.createRushServiceCheckbox();
    }

    createRushServiceCheckbox(): void {
        this.rushServiceCheckBox = new DynamicField({
            formGroup: null,
            label: 'Rush Service',
            name: 'rushService',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Checkbox,
            } as IDynamicFieldType),
            value: this.imedClaimService.RushService,
        } as IDynamicField);
    }
}
