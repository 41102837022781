import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IViewGetServicesForList } from '@model/interfaces/custom/view-get-services-for-list';
import { IImedClaimService } from '@model/interfaces/imed-claim-service';
import { ImedClaimServiceService } from '../../imed-claims/services/imedclaimservice.service';

@Component({
    selector: 'tooltip',
    styles: [
        `
            div {
                background-color: #4c5166;
                color: white;
                border-radius: 4px;
                padding: 10px;
            }
            .service-link {
                cursor: pointer;
            }
            .service-link:hover {
                text-decoration: underline;
            }
        `,
    ],
    template: `
        <div *ngIf="services && services.length">
            <span *ngFor="let service of services; index as i">
                <span (click)="navigateToService(service)" class="service-link">
                    #{{service.Id}} - {{ service.DateCreated | date: 'MM/dd/yyyy' }} - {{ service.Service.Name }}
                </span>
                <br />
            </span>
        </div>
    `,
})
export class ServiceTooltipComponent {
    @Input() imedClaimService: IViewGetServicesForList;
    forParent: boolean;
    html: string;
    services: IImedClaimService[];

    constructor(private imedClaimServiceService: ImedClaimServiceService, private router: Router, private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.getServices();
    }

    getServices(): void {
        if (this.forParent) {
            this.imedClaimServiceService.getParentForId(this.imedClaimService.Id).subscribe((service) => {
                this.services = [service];
            });
        } else {
            this.imedClaimServiceService.getChildrenForId(this.imedClaimService.Id).subscribe((services) => {
                this.services = services;
            });
        }
    }

    navigateToService(service: IImedClaimService): void {
        void this.router.navigate([`/cases/${service.ImedClaimId}/services/${service.Id}`]);
    }
}
