import { ITestimonyDepositionServiceDate } from '@model/interfaces/testimony-deposition-service-date.d';
import { ITestimonyDepositionService } from '@model/interfaces/testimony-deposition-service.d';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '@mt-ng2/base-service';
import { IServicePayload } from '@model/interfaces/custom/update-service-payload';
import { nullOutNestedObjects } from '../../../../common/custom/nullOutNestedObjects';

export const emptyTestimonyDepositionService: ITestimonyDepositionService = {
    Archived: false,
    ConfirmationReceived: false,
    ConfirmedById: null,
    CreatedById: 0,
    DateConfirmed: null,
    DateCreated: null,
    Id: 0,
    ImedClaimServiceId: 0,
    PartyThatConfirmed: null,
    PhysicianTestimonyDueDate: null,
    PhysicianTestimonyFee: null,
};

export const emptyTestimonyDepositionServiceDate: ITestimonyDepositionServiceDate = {
    DateRequested: null,
    Id: 0,
    TestimonyDepositionServiceId: 0,
};

@Injectable()
export class TestimonyDepositionServiceService extends BaseService<ITestimonyDepositionService> {
    constructor(public http: HttpClient) {
        super('/testimonydepositionservices', http);
    }

    getEmptyTestimonyDepositionService(): ITestimonyDepositionService {
        return { ...emptyTestimonyDepositionService };
    }

    getEmptyTestimonyDepositionServiceDate(): ITestimonyDepositionServiceDate {
        return { ...emptyTestimonyDepositionServiceDate };
    }

    getByImedClaimServiceId(imedClaimServiceId: number): Observable<ITestimonyDepositionService> {
        return this.http.get<ITestimonyDepositionService>(`/testimonydepositionservices/getByImedClaimServiceId/${imedClaimServiceId}`);
    }

    updateService(servicePayLoad: IServicePayload<ITestimonyDepositionService>): any {
        const clone: IServicePayload<ITestimonyDepositionService> = JSON.parse(JSON.stringify(servicePayLoad));
        nullOutNestedObjects(clone.ParentService);
        // not nulling out child service, since nested properties is filled in
        return this.http.put<number>(`/testimonydepositionservices/update-service/${clone.ParentService.Id}`, clone);
    }

    getTestimonyDepositionDateForAuditLog(dateId: number): Observable<any> {
        return this.http.get<any>(`/testimonydepositionservices/getTestimonyDepositionDateForAuditLog/${dateId}`);
    }
}
