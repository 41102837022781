import { ImedClaimServiceDynamicControlsPartial } from '@model/partials/imed-claim-service-partial.form-controls';
import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';

import { IImedClaimServiceDynamicControlsParameters } from '@model/form-controls/imed-claim-service.form-controls';
import { IImedClaimService } from '@model/interfaces/imed-claim-service';
import { IService } from '@model/interfaces/service';
import { IServiceStatus } from '@model/interfaces/service-status';
import { IImedClaimServiceExamRequestorType } from '@model/interfaces/imed-claim-service-exam-requestor-type';
import { IEligibilityCriteria } from '@model/interfaces/eligibility-criteria';
import { IImedClaimServiceDynamicControlsParametersPartial } from '@model/partials/imed-claim-service-dynamic-controls.partial';

export class ImedClaimServiceDynamicConfig<T extends IImedClaimService> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(
        private imedClaimServiceService: T,
        private services?: IService[],
        private serviceStatuses?: IServiceStatus[],
        private serviceRequestorTypes?: IImedClaimServiceExamRequestorType[],
        private eligibilityCriteria?: IEligibilityCriteria[],
        private configControls?: string[],
    ) {
        super();
        const additionalParams: IImedClaimServiceDynamicControlsParametersPartial = {
            eligibilityCriteria: this.eligibilityCriteria,
            examRequestorTypes: this.serviceRequestorTypes,
            services: this.services,
            serviceStatuses: this.serviceStatuses,
        };
        const dynamicControls = new ImedClaimServiceDynamicControlsPartial(this.imedClaimServiceService, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['ImedClaimId', 'PhysicianId', 'ServiceId', 'ServiceStatusId', 'CreatedById', 'DateCreated', 'Archived'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
