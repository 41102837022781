import { SharedModule } from './../common/shared.module';
import { DocumentQueueService } from './document-queue.service';
import { NgModule } from '@angular/core';
import { DocumentRoutingModule } from './document-routing.module';
import { DocumentQueueListComponent } from './document-queue-list.component';

@NgModule({
    declarations: [DocumentQueueListComponent],
    imports: [SharedModule, DocumentRoutingModule],
    providers: [DocumentQueueService],
})
export class DocumentQueueModule {}
