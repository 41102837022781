import { IConferenceCallServiceDateTime } from '@model/interfaces/conference-call-service-date-time.d';
import { DateTimeConverterService } from './../../../../../common/services/date-time-converter.service';
import { IConferenceCallService } from '@model/interfaces/conference-call-service.d';
import { AuthService, ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IImedClaimServiceContactInfo } from './../../../../imed-claim-basic-info/imed-claim-contacts-info';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ImedClaimServiceService } from '../../../imedclaimservice.service';
import { IImedClaimService } from '@model/interfaces/imed-claim-service';
import { ConferenceCallServiceDynamicConfig } from '../conference-call-service.dynamic-config';
import { ConferenceCallServiceService } from '../conference-call-service.service';
import { IServicePayload } from '@model/interfaces/custom/update-service-payload';
import { finalize } from 'rxjs/operators';
import { ClaimTypes } from '@model/ClaimTypes';
import { DynamicField, DynamicLabel, IDynamicFormConfig } from '@mt-ng2/dynamic-form';

@Component({
    selector: 'app-conference-call-service-basic-info',
    templateUrl: './conference-call-service-basic-info.component.html',
})
export class ConferenceCallServiceBasicInfoComponent implements OnInit {
    @Input() iImedClaimServiceModel: IImedClaimService;
    @Input() conferenceCallServiceModel: IConferenceCallService;
    @Input() canEdit: boolean;
    @Input() iImedClaimServiceContactInfo: IImedClaimServiceContactInfo;
    @Input() reportDueBy: string;
    @Output('physicianFaxSent') physicianFaxSent: EventEmitter<any> = new EventEmitter<any>();

    isEditing: boolean;
    isHovered: boolean;

    config: IDynamicFormConfig;
    conferenceCallServiceForm: any;
    conferenceCallformFactory: ConferenceCallServiceDynamicConfig<IConferenceCallService>;

    doubleClickIsDisabled = false;
    datesRequested = '';
    formRendered = false;
    canSeeAuditLog = false;
    initialImedClaimServiceContactInfo: IImedClaimServiceContactInfo;

    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    constructor(
        private imedClaimServiceService: ImedClaimServiceService,
        private notificationsService: NotificationsService,
        private activatedRoute: ActivatedRoute,
        private fb: UntypedFormBuilder,
        private authService: AuthService,
        private conferenceCallService: ConferenceCallServiceService,
        private dateTimeConverterService: DateTimeConverterService,
        private claimsService: ClaimsService,
    ) {}

    ngOnInit(): void {
        this.config = { formObject: [], viewOnly: [] };
        this.canSeeAuditLog = this.claimsService.hasClaim(ClaimTypes.Audit, [ClaimValues.FullAccess, ClaimValues.ReadOnly]);

        this.isEditing = false;
        this.setConfig();
    }

    ngOnChanges(changes: any): void {
        if (changes.iImedClaimServiceContactInfo) {
            this.initialImedClaimServiceContactInfo = { ...this.iImedClaimServiceContactInfo };
        }
    }

    setConfig(): void {
        this.conferenceCallServiceForm = this.fb.group({
            ConferenceCallService: this.fb.group({}),
            ConferenceCallServiceDateTime: this.fb.array([]),
        });
        this.conferenceCallformFactory = new ConferenceCallServiceDynamicConfig<IConferenceCallService>(this.conferenceCallServiceModel, [
            'DateTimeAgreedByPhysician',
        ]);
        this.config = this.conferenceCallformFactory.getForUpdate();
        const allDatesRequested = this.conferenceCallServiceModel.ConferenceCallServiceDateTimes.map((x) =>
            this.dateTimeConverterService.convertToBrowserTimeZoneString(x.DateTimeRequested),
        );
        this.datesRequested = allDatesRequested.join(', ');
        this.formRendered = true;

        this.viewOnly = this.config?.viewOnly?.map((x) => new DynamicLabel(x));
        this.formObject = this.config.formObject?.map((x) => new DynamicField(x));
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        this.isEditing = false;
        this.iImedClaimServiceContactInfo = { ...this.initialImedClaimServiceContactInfo };
    }

    addDateTimeRequested(updatePayload: IServicePayload<IConferenceCallService>): void {
        const datesRequested = <UntypedFormArray>this.conferenceCallServiceForm.controls.ConferenceCallServiceDateTime;
        updatePayload.ChildService.ConferenceCallServiceDateTimes = [];

        datesRequested.controls.forEach((dateRequested) => {
            const tempDateTimeRequested = <IConferenceCallServiceDateTime>{};

            if (!dateRequested.value.Id) {
                if (dateRequested.value.DateTimeRequested) {
                    tempDateTimeRequested.Id = 0;
                    tempDateTimeRequested.ConferenceCallServiceId = this.conferenceCallServiceModel.Id;
                    tempDateTimeRequested.DateTimeRequested = dateRequested.value.DateTimeRequested;
                    updatePayload.ChildService.ConferenceCallServiceDateTimes.push(tempDateTimeRequested);
                }
            } else {
                if (dateRequested.value.DateTimeRequested) {
                    updatePayload.ChildService.ConferenceCallServiceDateTimes.push(dateRequested.value as IConferenceCallServiceDateTime);
                }
            }
        });
    }

    formSubmitted(): void {
        const invalidServiceAddress = this.iImedClaimServiceContactInfo.PhysicianId > 0 && !this.iImedClaimServiceContactInfo.AddressId;

        if (this.conferenceCallServiceForm.valid && !invalidServiceAddress) {
            this.iImedClaimServiceModel = this.imedClaimServiceService.assignContactsToImedClaimService(
                this.iImedClaimServiceModel,
                this.iImedClaimServiceContactInfo,
            );
            this.conferenceCallformFactory.assignFormValues(
                this.conferenceCallServiceModel,
                this.conferenceCallServiceForm.value.ConferenceCallService as IConferenceCallService,
            );
            const updatePayload = <IServicePayload<IConferenceCallService>>{};
            updatePayload.ChildService = this.conferenceCallServiceModel;
            updatePayload.ParentService = this.iImedClaimServiceModel;
            this.addDateTimeRequested(updatePayload);

            this.conferenceCallService
                .updateService(updatePayload)                .subscribe(() => {
                    this.imedClaimServiceService.getById(this.iImedClaimServiceModel.Id).subscribe((response) => {
                        this.isEditing = false;
                        this.success();
                        this.iImedClaimServiceModel = response;
                        this.imedClaimServiceService.emitChange(this.iImedClaimServiceModel);
                        this.conferenceCallService.emitChange(this.conferenceCallServiceModel);
                        this.setConfig();
                    });
                });
        }
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }
}
