import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { IImedClaimServiceFollowup } from '@model/interfaces/imed-claim-service-followup';
import { ImedClaimServiceFollowupService } from '../imedclaimservicefollowup.service';

@Component({
    selector: 'app-imed-claim-service-followup-header',
    templateUrl: './imed-claim-service-followup-header.component.html',
})
export class ImedClaimServiceFollowupHeaderComponent implements OnInit, OnDestroy {
    imedClaimServiceFollowup: IImedClaimServiceFollowup;
    header: string;
    subscriptions: Subscription = new Subscription();

    constructor(private imedClaimServiceFollowupService: ImedClaimServiceFollowupService, private route: ActivatedRoute) { }

    ngOnInit(): void {
        this.subscriptions.add(
            this.imedClaimServiceFollowupService.changeEmitted$.subscribe((imedClaimServiceFollowup) => {
                this.setHeader(imedClaimServiceFollowup);
            }),
        );
        const id = +this.route.snapshot.paramMap.get('followupId');
        if (id > 0) {
            this.imedClaimServiceFollowupService.getById(id).subscribe((imedClaimServiceFollowup) => {
                this.setHeader(imedClaimServiceFollowup);
            });
        } else {
            this.header = 'Add Follow-up';
            this.imedClaimServiceFollowup = this.imedClaimServiceFollowupService.getEmptyImedClaimServiceFollowup();
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    setHeader(imedClaimServiceFollowup: IImedClaimServiceFollowup): void {
        this.imedClaimServiceFollowup = imedClaimServiceFollowup;
        this.header = `Follow-up: ${this.imedClaimServiceFollowup.FollowupType && this.imedClaimServiceFollowup.FollowupType.Name}`;
    }
}
