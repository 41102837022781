import { RecordRetrievalServiceDynamicControlsPartial } from '@model/partials/record-retrieval-service.form-controls.partial';
import { IRecordRetrievalService } from '@model/interfaces/record-retrieval-service.d';
import {
    IRecordRetrievalServiceDynamicControlsParameters,
    RecordRetrievalServiceDynamicControls,
} from '@model/form-controls/record-retrieval-service.form-controls';
import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';

export class RecordRetrievalServiceDynamicConfig<T extends IRecordRetrievalService> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private recordRetrievalService: T, private configControls?: string[]) {
        super();
        const additionalParams: IRecordRetrievalServiceDynamicControlsParameters = {};
        const dynamicControls = new RecordRetrievalServiceDynamicControlsPartial(this.recordRetrievalService, additionalParams);

        if (!configControls) {
            this.configControls = [
                'ImedClaimServiceId',
                'RadiologyFacility',
                'ProcessingDate',
                'CreatedById',
                'ModifiedById',
                'DateCreated',
                'DateModified',
                'Archived',
            ];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
