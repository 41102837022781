import { Component } from '@angular/core';
import { IEntityListDynamicCellComponent, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { IEntity } from '@mt-ng2/base-service';
import { ITaskTemplate } from '@model/interfaces/task-template';
import { IModalOptions } from '@mt-ng2/modal-module';

@Component({
    template: `
        <div *ngIf="taskTemplate && !taskTemplate.Archived">
            <i (mtConfirm)="archiveTaskTemplate($event)" class="fa fa-trash fa-lg" aria-hidden="true" [mtConfirmOptions]="archiveConfirm"></i>
        </div>
    `,
})
export class SystemTaskDeleteCellComponent implements IEntityListDynamicCellComponent {
    entityListComponentMembers: IEntityListComponentMembers;
    set entity(value: IEntity) {
        this.taskTemplate = value as ITaskTemplate;
    }

    taskTemplate: ITaskTemplate;

    archiveConfirm: IModalOptions = {
        confirmButtonText: 'Yes, I am sure!',
        text: `Are you sure you want to delete this system task?`,
        title: 'Delete System Task',
    	icon: 'question',
};

    constructor() { }

    archiveTaskTemplate(event: Event): void {
        this.entityListComponentMembers.itemDeleted(this.taskTemplate as IEntity, event);
    }
}
