import { Subscription, Observable } from 'rxjs';
import {
    DynamicField,
    IDynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    IDynamicFieldType,
    InputTypes,
    NumericInputTypes,
} from '@mt-ng2/dynamic-form';
import { IImeService } from '@model/interfaces/ime-service.d';
import { ImedClaimServiceService } from '../../../imedclaimservice.service';
import { ImedClaimServiceDynamicConfig } from '../../../imed-claim-service.dynamic-config';
import { IImedClaimServiceContactInfo } from '../../../../imed-claim-basic-info/imed-claim-contacts-info';
import { Component, Input, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, AbstractControl, Validators } from '@angular/forms';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IImedClaimService } from '@model/interfaces/imed-claim-service';
import { IServicePayload } from '@model/interfaces/custom/update-service-payload';
import { ConflictOfInterestFormSelection } from '../../../../../common/constants/Enums';

import { forkJoin } from 'rxjs';
import { AuthService, ClaimValues, ClaimsService } from '@mt-ng2/auth-module';
import { finalize } from 'rxjs/operators';
import { ClaimTypes } from '@model/ClaimTypes';
import { IUser } from '@model/interfaces/user';
import { UserService } from '../../../../../users/user.service';
import { VirtualTypeAheadGetItemsFunction } from '@mt-ng2/type-ahead-control';
import { ISpecialty } from '@model/interfaces/specialty';
import { SpecialityService } from '../../../../../imed-managed-list/speciality.service';
import { MetaItem } from '@mt-ng2/base-service';
import { ImeServiceService } from '../../ime-service/imeservice.service';
import { ImedClaimTypes } from '@model/ImedClaimTypes';

@Component({
    selector: 'app-wc-ime-service-basic-info',
    templateUrl: './wc-ime-service-basic-info.component.html',
})
export class WcImeServiceBasicInfoComponent implements OnInit, OnDestroy {
    @Input() iImedClaimServiceModel: IImedClaimService;
    @Input() canEdit: boolean;
    @Input() iImedClaimServiceContactInfo: IImedClaimServiceContactInfo;
    @Input() iImeService: IImeService;
    @Input() disablePhysicianFacilityControl: boolean;
    @Input() bodyPartsToBeExamined: string;
    @Input() reportDueBy: string;
    @Output('physicianFaxSent') physicianFaxSent: EventEmitter<any> = new EventEmitter<any>();

    isEditing: boolean;
    isHovered: boolean;
    imedClaimServiceForm: any;
    formFactory: ImedClaimServiceDynamicConfig<IImedClaimService>;
    doubleClickIsDisabled = false;
    iImeServiceClone: IImeService;
    subscriptions = new Subscription();

    ServiceId: number;
    physicianSelected: boolean;
    selectedValueConflictOfInterest: number;
    previousPatientControl: AbstractControl;
    conflictOfInterestControl: AbstractControl;
    canSeeAuditLog = false;
    initialImedClaimServiceModel: IImedClaimService;
    initialImedClaimServiceContactInfo: IImedClaimServiceContactInfo;

    selectedReportAssignedToUser: IUser;
    reportAssignedToUsers = [];
    formRendered = false;
    invalidPreviousPatient = false;
    invalidReportAssignedTo = false;

    getItems: VirtualTypeAheadGetItemsFunction = this.getSpecialties.bind(this);
    selectedSpecialty: number;
    purposeOfExam: string;
    contractRateFrom: number;
    contractRateTo: number;

    initialContractRateFrom: number;
    initialContractRateTo: number;

    formatter: any;

    constructor(
        private imedClaimServiceService: ImedClaimServiceService,
        private notificationsService: NotificationsService,
        private fb: UntypedFormBuilder,
        private imeServiceService: ImeServiceService,
        private authService: AuthService,
        private claimsService: ClaimsService,
        private userService: UserService,
        private specialtyService: SpecialityService,
    ) {}

    ngOnInit(): void {
        this.formatter = new Intl.NumberFormat('en-US', {
            currency: 'USD',
            style: 'currency',
        });
        this.canSeeAuditLog = this.claimsService.hasClaim(ClaimTypes.Audit, [ClaimValues.FullAccess, ClaimValues.ReadOnly]);
        this.initialImedClaimServiceModel = { ...this.iImedClaimServiceModel };
        this.isEditing = false;
        this.selectedValueConflictOfInterest = this.iImeService.ConflictOfInterestApproved
            ? ConflictOfInterestFormSelection.selected
            : ConflictOfInterestFormSelection.notSelected;
        this.physicianSelected = this.iImedClaimServiceModel.PhysicianId > 0 ? true : false;
        this.assignDefaultValues();
        this.subscriptions.add(
            this.imeServiceService.changeEmitted$.subscribe((iImeService) => {
                this.iImeService = iImeService;
                this.checkForTranslationService();
                this.clone();
            }),
        );

        this.subscriptions.add(
            this.imedClaimServiceService.changeEmitted$.subscribe((iImedClaimServiceModel) => {
                this.iImedClaimServiceModel = iImedClaimServiceModel;
                this.initialImedClaimServiceModel = { ...this.iImedClaimServiceModel };
            }),
        );

        this.clone();
        this.formRendered = true;

        this.selectedSpecialty = this.iImedClaimServiceModel.SpecialtyRequestedId > 0 ? this.iImedClaimServiceModel.SpecialtyRequestedId : null;
    }

    ngOnChanges(changes: any): void {
        if (changes.iImedClaimServiceContactInfo) {
            this.initialImedClaimServiceContactInfo = { ...this.iImedClaimServiceContactInfo };
        }
    }

    private assignDefaultValues(): void {
        this.assignPurposeOfExam();
        this.checkForTranslationService();
        if (this.iImedClaimServiceModel.ContractRateFrom !== null) {
            this.contractRateFrom = this.iImedClaimServiceModel.ContractRateFrom;
        } else {
            this.contractRateFrom = this.getDefaultContractRate('ContractRateFrom');
        }
        if (this.iImedClaimServiceModel.ContractRateTo !== null) {
            this.contractRateTo = this.iImedClaimServiceModel.ContractRateTo;
        } else {
            this.contractRateTo = this.getDefaultContractRate('ContractRateTo');
        }
    }

    private assignPurposeOfExam(): void {
        if (this.iImedClaimServiceModel.PurposeOfExam !== null) {
            this.purposeOfExam = this.iImedClaimServiceModel.PurposeOfExam;
        } else {
            this.purposeOfExam = this.getPurposeOfExamDefault();
        }
    }

    private checkForTranslationService(): void {
        if (this.iImeService.TranslationServicesRequested && this.purposeOfExam.indexOf('interpreter has been arranged') === -1) {
            this.purposeOfExam += ' Please note that a ' + this.iImeService.Language?.Name + ' interpreter has been arranged.';
        } else {
            this.assignPurposeOfExam();
        }
    }

    getDefaultContractRate(contractKey: string): number {
        const rates = [];
        const keys = [
            'CarrierPartyIndividual',
            'CarriersCounselIndividual',
            'ClaimantsAttorneyIndividual',
            'DefenseAttorneyIndividual',
            'EmployerIndividual',
            'PlantiffAttorneyIndividual',
        ];
        keys.forEach((key) => {
            if (this.iImedClaimServiceModel.ImedClaim[key] && this.iImedClaimServiceModel.ImedClaim[key][contractKey]) {
                rates.push(this.iImedClaimServiceModel.ImedClaim[key][contractKey]);
            }
        });
        if (rates.length > 0) {
            return rates.sort()[rates.length - 1];
        } else {
            return null;
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    clone(): void {
        this.iImeServiceClone = { ...this.iImeService };
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        this.isEditing = false;
        this.iImedClaimServiceModel = { ...this.initialImedClaimServiceModel };
        this.iImedClaimServiceContactInfo = { ...this.initialImedClaimServiceContactInfo };
        this.selectedReportAssignedToUser = this.iImedClaimServiceModel.ServiceAssignedToId
            ? this.reportAssignedToUsers.filter((user: IUser) => user.Id === this.iImedClaimServiceModel.ServiceAssignedToId)[0]
            : null;
    }

    getPurposeOfExamDynamicField(): DynamicField {
        return new DynamicField({
            formGroup: null,
            label: 'Purpose of Exam',
            name: 'PurposeOfExam',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Textarea,
            } as IDynamicFieldType),
            value: this.purposeOfExam,
        } as IDynamicField);
    }

    getContractRateFromDynamicField(): DynamicField {
        return new DynamicField({
            formGroup: null,
            label: 'Contract Rate From',
            name: 'ContractRateFrom',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: NumericInputTypes.Currency,
                scale: 2,
            } as IDynamicFieldType),
            validation: [Validators.min(0.01), Validators.max(100000000000.0)],
            validators: { min: 0.01, max: 100000000000.0 },
            value: this.contractRateFrom,
        } as IDynamicField);
    }

    getContractRateToDynamicField(): DynamicField {
        return new DynamicField({
            formGroup: null,
            label: 'Contract Rate To',
            name: 'ContractRateTo',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: NumericInputTypes.Currency,
                scale: 2,
            } as IDynamicFieldType),
            validation: [Validators.min(0.01), Validators.max(100000000000.0)],
            validators: { min: 0.01, max: 100000000000.0 },
            value: this.contractRateTo,
        } as IDynamicField);
    }

    getSpecialties(): Observable<ISpecialty[]> {
        return this.specialtyService.getItems();
    }

    selectionChanged({ selection }): void {
        if (selection) {
            this.selectedSpecialty = selection.Id;
        }
    }

    isPhysicianSelected(physicianSelected): void {
        if (physicianSelected && this.iImedClaimServiceModel.PhysicianId == null) {
            this.physicianSelected = true;
        } else if (physicianSelected && this.iImedClaimServiceModel.PhysicianId > 0) {
            this.physicianSelected = true;
            if (this.conflictOfInterestControl) {
                this.conflictOfInterestControl.setValue(ConflictOfInterestFormSelection.notSelected);
            }
        } else if (!physicianSelected) {
            this.physicianSelected = false;
        }
    }

    previousPatientField(): DynamicField {
        return new DynamicField({
            formGroup: null,
            label: 'Has this claimant ever been seen in this physician`s or expert`s office, for any reason - treating, Worker`s Compensation, No Fault, etc.?',
            name: 'PreviousPatient',
            options: [new MetaItem(1, 'Yes'), new MetaItem(0, 'No')],
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
            } as IDynamicFieldType),
            validation: [(c: AbstractControl) => Validators.required(c)],
            validators: { required: true },
            value:
                this.iImeService.PatientPreviouslyTreatedByPhysician === true
                    ? 1
                    : this.iImeService.PatientPreviouslyTreatedByPhysician === false
                    ? 0
                    : null,
        } as IDynamicField);
    }

    setPreviousPatientField(value: boolean): void {
        this.iImeServiceClone.PatientPreviouslyTreatedByPhysician = value;
    }

    conflictOfInterestField(): DynamicField {
        return new DynamicField({
            formGroup: null,
            label: 'Has client approved possible conflict of interest?',
            name: 'PreviousPatient',
            options: [new MetaItem(1, 'Yes'), new MetaItem(0, 'No')],
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
            } as IDynamicFieldType),
            value: this.selectedValueConflictOfInterest,
        } as IDynamicField);
    }

    setConflictOfInterestField(value: boolean): void {
        this.selectedValueConflictOfInterest = value ? ConflictOfInterestFormSelection.selected : ConflictOfInterestFormSelection.notSelected;
        this.iImeServiceClone.ConflictOfInterestApproved = value;
    }

    updatePurposeOfExamValue(value: string): void {
        this.purposeOfExam = value;
    }

    updateContractRateFromValue(value: number): void {
        this.contractRateFrom = value;
    }

    updateContractRateToValue(value: number): void {
        this.contractRateTo = value;
    }

    formSubmitted(): void {
        this.initialContractRateFrom = this.iImedClaimServiceModel.ContractRateFrom;
        this.initialContractRateTo = this.iImedClaimServiceModel.ContractRateTo;
        this.iImedClaimServiceModel.ContractRateFrom = this.contractRateFrom;
        this.iImedClaimServiceModel.ContractRateTo = this.contractRateTo;
        const invalidServiceAddress = this.iImedClaimServiceContactInfo.PhysicianId > 0 && !this.iImedClaimServiceContactInfo.AddressId;

        if (this.isContractRangeValid(this.iImedClaimServiceModel) && !invalidServiceAddress) {
            this.iImedClaimServiceModel = this.imedClaimServiceService.assignContactsToImedClaimService(
                this.iImedClaimServiceModel,
                this.iImedClaimServiceContactInfo,
            );
            this.iImedClaimServiceModel.ServiceAssignedToId =
                this.selectedReportAssignedToUser && this.selectedReportAssignedToUser.Id ? this.selectedReportAssignedToUser.Id : null;
            this.iImedClaimServiceModel.SpecialtyRequestedId = this.selectedSpecialty ? this.selectedSpecialty : null;
            this.iImedClaimServiceModel.PurposeOfExam = this.purposeOfExam;

            this.invalidPreviousPatient =
                this.iImeServiceClone.PatientPreviouslyTreatedByPhysician === null && this.iImedClaimServiceModel.PhysicianId > 0;

            if (this.invalidReportAssignedTo || this.invalidPreviousPatient) {
                return;
            }

            const updatePayload = <IServicePayload<IImeService>>{};
            this.iImeServiceClone.ModifiedById = this.authService.currentUser.getValue().Id;
            updatePayload.ParentService = { ...this.iImedClaimServiceModel };
            updatePayload.ChildService = { ...this.iImeServiceClone };
            forkJoin(
                this.imeServiceService.updateService(this.iImedClaimServiceModel.Id, updatePayload),
                this.imeServiceService.updateServiceConflictOfInterest(this.iImedClaimServiceModel.Id, updatePayload),
            ).subscribe((success) => {
                this.imedClaimServiceService.getById(this.iImedClaimServiceModel.Id).subscribe((response) => {
                    this.isEditing = false;
                    this.success();
                    this.iImedClaimServiceModel = response;
                    this.imedClaimServiceService.emitChange(this.iImedClaimServiceModel);
                    this.imeServiceService.emitChange(this.iImeServiceClone);
                });
            });
        } else {
            setTimeout(() => (this.doubleClickIsDisabled = false), 0);
        }
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    isContractRangeValid(iImedClaimServiceModel: IImedClaimService): boolean {
        if (
            iImedClaimServiceModel.ContractRateFrom &&
            iImedClaimServiceModel.ContractRateTo &&
            iImedClaimServiceModel.ContractRateFrom > iImedClaimServiceModel.ContractRateTo
        ) {
            this.notificationsService.error('Contract Range From cannot be higher than Contract Range To');
            this.iImedClaimServiceModel.ContractRateFrom = this.initialContractRateFrom;
            this.iImedClaimServiceModel.ContractRateTo = this.initialContractRateTo;
            this.contractRateFrom = this.initialContractRateFrom;
            this.contractRateTo = this.initialContractRateTo;
            return false;
        }
        return true;
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }

    getPurposeOfExamDefault(): string {
        return 'Please bring all available recent medical records, MRIs, CT scans, x-rays, and other diagnostic test results to the examiner at the time of the exam for review. Please comply with all local ordinances regarding COVID 19 and be sure to wear a face covering and bring Photo ID.';
    }
}
