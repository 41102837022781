import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MetaItemService } from '@mt-ng2/base-service';
import { IMasterFollowupType } from '@model/interfaces/master-followup-type';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class MasterFollowupTypeService extends MetaItemService<IMasterFollowupType> {
    constructor(public http: HttpClient) {
        super('MasterFollowupTypeService', 'Type', 'TypeIds', '/masterfollowuptypes', http);
    }

    getMasterFollowupTypesByServiceId(serviceId: number): Observable<IMasterFollowupType[]> {
        return this.http.get<IMasterFollowupType[]>(`/options/masterfollowuptypes/${serviceId}`);
    }

    getMasterFollowupTypesByImedClaimTypeId(imedClaimTypeId: number): Observable<IMasterFollowupType[]> {
        return this.http.get<IMasterFollowupType[]>(`/options/imedclaim-masterfollowuptypes/${imedClaimTypeId}`);
    }
}
