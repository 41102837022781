import { Validators } from '@angular/forms';
import { CkEditorDynamicFieldComponent, IAutoComplete } from '@common/ckeditor-dynamic-field/ckeditor-dynamic-field.component';
import { AutomatedTemplateDynamicControls, IAutomatedTemplateDynamicControlsParameters } from '@model/form-controls/automated-template.form-controls';
import { IAddressBookType } from '@model/interfaces/address-book-type';
import { IAutomatedTemplate } from '@model/interfaces/automated-template';
import { IService } from '@model/interfaces/service';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, IDynamicField, IDynamicFieldType, IState, SelectInputTypes } from '@mt-ng2/dynamic-form';
import { IDynamicFieldPartial } from './dynamic-field.partial';
import { EventEmitter} from '@angular/core';
import { IEmailTemplate } from '@model/interfaces/email-template';

export interface IAutomatedTemplateDynamicControlsParametersPartial extends IAutomatedTemplateDynamicControlsParameters {
    states?: IState[];
    services?: IService[];
    addressBookTypes?: IAddressBookType[];
    emailTemplates?: IEmailTemplate[];
    selectedStateIdsArray?: number[];
    selectedServiceIdsArray?: number[];
    selectedAddressBookTypeIdsArray?: number[];
    selectedEmailTemplateIdsArray?: number[];
    advancedEditor?: boolean;
    autoComplete?: IAutoComplete;
}

export class AutomatedTemplateDynamicControlsPartial extends AutomatedTemplateDynamicControls {
    selectOptions: any;
    constructor(automatedTemplate?: IAutomatedTemplate, additionalParameters?: IAutomatedTemplateDynamicControlsParametersPartial) {
        super(automatedTemplate, additionalParameters);

        (<DynamicField>this.Form.IsForAllStates).value = automatedTemplate?.Id === 0 ? true : automatedTemplate && automatedTemplate.IsForAllStates;
        (<DynamicField>this.Form.IsForAllStates).labelHtml = '<label>All States</label>';

        this.Form.associatedStates = new DynamicField({
            formGroup: this.formGroup,
            label: 'Select States',
            name: 'associatedStates',
            options: additionalParameters?.states,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.MultiselectDropdown,
                maxToShowInSelectedText: 1,
                showSelectAllButtons: true,
            } as IDynamicFieldType),
            value: additionalParameters?.selectedStateIdsArray,
        } as IDynamicField);

        this.Form.associatedServices = new DynamicField({
            formGroup: this.formGroup,
            label: 'Select Services',
            name: 'associatedServices',
            options: additionalParameters?.services,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.MultiselectDropdown,
                maxToShowInSelectedText: 1,
                showSelectAllButtons: true,
            } as IDynamicFieldType),
            value: additionalParameters?.selectedServiceIdsArray,
        } as IDynamicField);

        this.Form.associatedAddressBookTypes = new DynamicField({
            formGroup: this.formGroup,
            label: 'Select Address Book Types',
            name: 'associatedAddressBookTypes',
            options: additionalParameters?.addressBookTypes,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.MultiselectDropdown,
                maxToShowInSelectedText: 1,
                showSelectAllButtons: true,
            } as IDynamicFieldType),
            value: additionalParameters?.selectedAddressBookTypeIdsArray,
        } as IDynamicField);

        this.Form.associatedEmailTemplates = new DynamicField({
            formGroup: this.formGroup,
            label: 'Select Email Templates',
            name: 'associatedEmailTemplates',
            options: additionalParameters?.emailTemplates,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.MultiselectDropdown,
                maxToShowInSelectedText: 1,
                showSelectAllButtons: true,
            } as IDynamicFieldType),
            value: additionalParameters?.selectedEmailTemplateIdsArray,
        } as IDynamicField);

        (<DynamicField>this.Form.Template).component = CkEditorDynamicFieldComponent;
        (<DynamicField>this.Form.Template).validation = [ Validators.required ];
        (<DynamicField>this.Form.Template).validators = { required: true };
        (<IDynamicFieldPartial>this.Form.Template).additionalOptions = {
            advancedEditor: true,
            autoComplete: additionalParameters?.autoComplete,
            automatedTemplate,
        };
    }

}
