import { IAppFaxEmailComponentApi } from './fax-email-component';
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { IEntityListDynamicCellComponent, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { IMetaItem } from '@model/interfaces/base';
import { AttachmentServiceTypeEnums, AttachmentTypeEnums, ServiceIdEnums, PopUpTypeEnums, FaxEmailEntityTypeEnums } from '../constants/Enums';
import { AttachmentTypeService } from '../attachments/attachment-type.service';
import { IAttachmentType } from '@model/interfaces/attachment-type';
import { Observable, Subscription } from 'rxjs';
import { IViewGetServicesForList } from '@model/interfaces/custom/view-get-services-for-list';
import { ImeServiceService } from '../../imed-claims/services/service-specifics/ime-service/imeservice.service';
import { PopUpService, IPopUpParams } from '../services/popup-service';
import { MetaItem } from '@mt-ng2/base-service';
import { ImedClaimServiceService } from '../../imed-claims/services/imedclaimservice.service';
import { safeDetectChanges } from '../cdr-safety/cdr-safety.library';
import { IName, NameTypeEnum } from '@model/interfaces/custom/name';

@Component({
    template: `
        <select
            [(ngModel)]="selectedType"
            *ngIf="doctypes && doctypes.length > 1"
            class="form-control"
            (change)="setComponentValueBasedOnSelection(selectedType)"
        >
            <option *ngFor="let doctype of doctypes" [ngValue]="doctype">{{ doctype.Name }}</option>
        </select>
        <app-fax-email
            *ngIf="appFaxEmailComponentExists"
            (closeModal)="resetDropDownList()"
            [entity]="service"
            [fromServicesListPage]="true"
            [entityId]="service?.Id"
            [imedclaimServiceId]="service?.Id"
            [entityTypeId]="entityTypeId"
            [name]="claimantName"
            (ready)="appFaxEmailComponentApi = $event"
        ></app-fax-email>
    `,
})
export class FaxEmailServicesWrapperComponent implements IEntityListDynamicCellComponent, OnInit, OnDestroy {
    entityListComponentMembers: IEntityListComponentMembers;
    service: IViewGetServicesForList;
    selectedType = {};
    addressBookType = false;
    entityTypeId: FaxEmailEntityTypeEnums;
    doctypes: IMetaItem[] = [];
    hasAuthorizationDocument: boolean;
    claimantName: IName;
    attachmentTypeSubscription: Subscription;

    appFaxEmailComponentExists = true;

    systemGeneratedAttachments: AttachmentTypeEnums[] = [
        AttachmentTypeEnums.Addendum,
        AttachmentTypeEnums.Re_Exam_Letter,
        AttachmentTypeEnums.Radiology_Retrieval_Label,
        AttachmentTypeEnums.Film_Review_Cover_Letter,
        AttachmentTypeEnums.Retrieval_Documents_Complete,
        AttachmentTypeEnums.Completed_Retrieval_Cover,
        AttachmentTypeEnums.Record_Review_Cover_Letter,
        AttachmentTypeEnums.Appointment_TBD_Letter,
        AttachmentTypeEnums.Appointment_TBD_Letter,
        AttachmentTypeEnums.Completed_Rejection_Cover,
        AttachmentTypeEnums.No_Records_On_File_Cover,
        AttachmentTypeEnums.Undeliverable_Cover,
    ];

    attachmentServiceTypeId: AttachmentServiceTypeEnums;

    set entity(value: any) {
        this.service = value;
    }

    appFaxEmailComponentApi: IAppFaxEmailComponentApi;

    constructor(
        private imedClaimServicService: ImedClaimServiceService,
        private attachmentTypeService: AttachmentTypeService,
        private popUpService: PopUpService,
        private imeServiceService: ImeServiceService,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.doctypes.unshift({ Id: 0, Name: 'Select' });
        this.claimantName = { firstName: this.service.ClaimantFirstName, lastName: this.service.ClaimantLastName, nameType: NameTypeEnum.Individual };
        if (
            (this.service.DateOfAllegedAccidentOrInjury && this.service.ServiceTypeId !== +ServiceIdEnums.Record_Retrievals_HIPAA_Processing) ||
            (this.service.ServiceTypeId === +ServiceIdEnums.Record_Retrievals_HIPAA_Processing && this.service.HasAuthorizationsDocument === 1) ||
            (this.service.DateOfAllegedAccidentOrInjury && this.service.ServiceTypeId !== +ServiceIdEnums.Radiology_Received_From_Client) ||
            (this.service.ServiceTypeId === +ServiceIdEnums.Radiology_Received_From_Client && this.service.HasAuthorizationsDocument === 1)
        ) {
            this.getFilteredDocuments();
        }
    }

    ngOnDestroy(): void {
        if (this.attachmentTypeSubscription) {
            this.attachmentTypeSubscription.unsubscribe();
        }
        this.cdr.detach();
    }

    checkForRecordRetrievedDocument(): void {
        // if (this.service.ServiceTypeId === ServiceIdEnums.Record_Retrievals_HIPAA_Processing && this.service.HasRecordRetreivedDocument !== 1) {
        //     this.doctypes = this.doctypes.filter((type) => type.Id !== AttachmentTypeEnums.Completed_Retrieval_Cover);
        // }

        // if (this.service.ServiceTypeId === ServiceIdEnums.Record_Retrievals_HIPAA_Processing && this.service.HasRecordRejectionDocument !== 1) {
        //     this.doctypes = this.doctypes.filter((type) => type.Id !== AttachmentTypeEnums.Completed_Rejection_Cover);
        // }

        if (this.service.ServiceTypeId === +ServiceIdEnums.Record_Retrievals_HIPAA_Processing || this.service.ServiceTypeId === +ServiceIdEnums.Radiology_Received_From_Client) {
            if (this.service.HasRecordRetreivedDocument !== 1) {
                this.doctypes = this.doctypes.filter((type) => type.Id !== +AttachmentTypeEnums.Completed_Retrieval_Cover);
            }

            if (this.service.HasRecordRejectionDocument !== 1) {
                this.doctypes = this.doctypes.filter((type) => type.Id !== +AttachmentTypeEnums.Completed_Rejection_Cover);
            }

            if (this.service.HasNoRecordOnFileDocument !== 1) {
                this.doctypes = this.doctypes.filter((type) => type.Id !== +AttachmentTypeEnums.No_Records_On_File_Cover);
            }

            if (this.service.HasUndeliverableRecordDocument !== 1) {
                this.doctypes = this.doctypes.filter((type) => type.Id !== +AttachmentTypeEnums.Undeliverable_Cover);
            }
        }
    }

    checkForRadiologyRetrievalSubService(): void {
        if (this.service.ServiceTypeId === +ServiceIdEnums.Record_Retrievals_HIPAA_Processing && this.service.RecordRetrievalwWithRadiology !== 1) {
            this.doctypes = this.doctypes.filter((type) => type.Id !== +AttachmentTypeEnums.Radiology_Retrieval_Label);
        }
    }

    getFilteredDocuments(): void {
        this.attachmentTypeSubscription = this.attachmentTypeService.getCachedAttachmentTypes().subscribe((types) => {
            types = types.filter((x) => x.Services.some((y) => y.Id === this.service.ServiceTypeId));
            types = this.filterNonSystemGeneratedDocs(types);
            this.doctypes.push(...types.map((type) => new MetaItem(type.Id, type.Name)));
            this.checkIfIMEFilmReviewIsRequested();
            this.checkForRecordRetrievedDocument();
            this.checkForRadiologyRetrievalSubService();
            this.selectedType = this.doctypes[0];
            safeDetectChanges(this.cdr);
        });
    }

    checkIfIMEFilmReviewIsRequested(): void {
        if (this.imedClaimServicService.imeServiceTypes.has(this.service.ServiceTypeId) && !this.service.ImeHasRadiologyReview) {
            this.doctypes = this.doctypes.filter((type) => type.Id !== +AttachmentTypeEnums.Film_Review_Cover_Letter);
        }
    }

    filterNonSystemGeneratedDocs(types: IAttachmentType[]): IAttachmentType[] {
        return types.filter((type) => this.systemGeneratedAttachments.indexOf(type.Id) >= 0);
    }

    getAttachmentServiceType(serviceId: number): Observable<AttachmentServiceTypeEnums> {
        return this.attachmentTypeService.mapServiceToAttachmentServiceType(serviceId);
    }

    resetDropDownList(): void {
        this.selectedType = this.doctypes[0];
        // force destroy and recreate app-fax-email component so that CKEditor doesn't error out
        this.appFaxEmailComponentExists = false;
        setTimeout(() => {
            this.appFaxEmailComponentExists = true;
        }, 0);
    }

    checkForPhysicianReports(): void {
        this.imeServiceService.checkForReportFromPhysician(this.service.Id).subscribe((hasReports) => {
            if (hasReports) {
                this.appFaxEmailComponentApi.show();
            } else {
                const message =
                    'There are no physician reports associated with any IME services performed by this physician on this case. Do you want to continue?';
                const popUp: IPopUpParams = {
                    confirmButtonText: 'Continue',
                    html: message,
                    title: 'Missing Physician Reports',
                    type: PopUpTypeEnums.Soft_Pop_Up,
                };
                this.popUpService.showPopUp(popUp).subscribe((response) => {
                    if (response.value) {
                        this.appFaxEmailComponentApi.show();
                    } else {
                        this.resetDropDownList();
                    }
                });
            }
        });
    }

    validateDateOfInjury(): void {
        this.appFaxEmailComponentApi.validateServiceDateOfInjuryFromList(this.service);
    }

    setComponentValueBasedOnSelection(value: any): void {
        if (value.Id > 0) {
            if (value.Id === AttachmentTypeEnums.Addendum) {
                this.entityTypeId = FaxEmailEntityTypeEnums.IMEAddendum;
            }
            if (value.Id === AttachmentTypeEnums.Re_Exam_Letter) {
                this.entityTypeId = FaxEmailEntityTypeEnums.IMEReExam;
            }
            if (value.Id === AttachmentTypeEnums.Film_Review_Cover_Letter) {
                this.entityTypeId = FaxEmailEntityTypeEnums.RadiologyCoverLetter;
            }
            if (value.Id === AttachmentTypeEnums.Radiology_Retrieval_Label) {
                this.entityTypeId = FaxEmailEntityTypeEnums.FilmRetrievalLabel;
            }
            if (value.Id === AttachmentTypeEnums.Retrieval_Documents_Complete) {
                this.entityTypeId = FaxEmailEntityTypeEnums.RecordsRetrievalComplex;
            }
            if (value.Id === AttachmentTypeEnums.Completed_Retrieval_Cover) {
                this.entityTypeId = FaxEmailEntityTypeEnums.RecordsRetrievalSimple;
            }
            if (value.Id === AttachmentTypeEnums.Completed_Rejection_Cover) {
                this.entityTypeId = FaxEmailEntityTypeEnums.RecordsRejectionLetter;
            }
            if (value.Id === AttachmentTypeEnums.Record_Review_Cover_Letter && this.service.DateOfAllegedAccidentOrInjury) {
                this.entityTypeId = FaxEmailEntityTypeEnums.RecordReview;
            }
            if (value.Id === AttachmentTypeEnums.Appointment_TBD_Letter) {
                this.entityTypeId = FaxEmailEntityTypeEnums.AppointmentTBDLetter;
                this.service.AppointmentDateTime = null;
            }
            if (value.Id === AttachmentTypeEnums.No_Records_On_File_Cover) {
                this.entityTypeId = FaxEmailEntityTypeEnums.NoRecordsOnFile;
            }
            if (value.Id === AttachmentTypeEnums.Undeliverable_Cover) {
                this.entityTypeId = FaxEmailEntityTypeEnums.UndeliverableRecords;
            }

            this.appFaxEmailComponentApi.createEmail(this.entityTypeId);
            this.showComponent();
        }
    }

    showComponent(): void {
        this.validateDateOfInjury();
        if (this.entityTypeId === FaxEmailEntityTypeEnums.IMEReExam || this.entityTypeId === FaxEmailEntityTypeEnums.IMEAddendum) {
            this.checkForPhysicianReports();
        } else {
            this.appFaxEmailComponentApi.show();
        }
    }
}
