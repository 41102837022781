import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from '@mt-ng2/base-service';
import { SearchParams } from '@mt-ng2/common-classes';
import { IPhoneNumber } from '@model/interfaces/phone-number';

export const emptyPhoneNumber: IPhoneNumber = {
    Archived: null,
    CreatedById: 0,
    DateCreated: null,
    Id: 0,
    IsPrimary: null,
    Phone: null,
    PhoneTypeId: 0,
};

@Injectable()
export class PhoneNumberService extends BaseService<IPhoneNumber> {
    constructor(public http: HttpClient) {
        super('/phones', http);
    }

    getEmptyPhoneNumber(): IPhoneNumber {
        return { ...emptyPhoneNumber };
    }
}
