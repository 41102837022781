import { AddressBookSelectionEnums } from '../../../../common/constants/Enums';
import { IImeContactInfo } from '../../../imed-claim-basic-info/imed-claim-contacts-info';
import { IImeService } from '@model/interfaces/ime-service.d';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from '@mt-ng2/base-service';
import { IServicePayload } from '@model/interfaces/custom/update-service-payload';
import { nullOutNestedObjects } from '../../../../common/custom/nullOutNestedObjects';
import { INote } from '@mt-ng2/note-control';

export const emptyImeService: IImeService = {
    Archived: false,
    ConflictOfInterestApproved: false,
    CreatedById: 0,
    DateCreated: null,
    FilmReviewId: null,
    HasPreLitigation: false,
    Id: 0,
    ImedClaimServiceId: 0,
    IsFederalCase: false,
    PatientPreviouslyTreatedByPhysician: null,
    RadiologyReviewRequested: false,
    Surveillance: false,
};

@Injectable()
export class AutoImeServiceService extends BaseService<IImeService> {
    constructor(public http: HttpClient) {
        super('/imeservices', http);
    }

    getEmptyImeService(): IImeService {
        return { ...emptyImeService };
    }

    getByImedClaimServiceId(imedClaimServiceId: number): Observable<IImeService> {
        return this.http.get<IImeService>(`/imeservices/getByImedClaimServiceId/${imedClaimServiceId}`);
    }

    assignContactsToIme(imeService: IImeService, imeContact: IImeContactInfo): IImeService {
        imeService.TranslationServicesVendorAddressBookId = imeContact.TranslationServicesVendorAddressBookId;
        imeService.TranslationServicesVendorIndividualId = imeContact.TranslationServicesVendorIndividualId;

        imeService.BackgroundInvestigationVendorAddressBookId = imeContact.BackgroundInvestigationVendorAddressBookId;
        imeService.BackgroundInvestigationVendorIndividualId = imeContact.BackgroundInvestigationVendorIndividualId;

        imeService.TransportationServicesVendorAddressBookId = imeContact.TransportationServicesVendorAddressBookId;
        imeService.TransportationServicesVendorIndividualId = imeContact.TransportationServicesVendorIndividualId;

        imeService.SurveillanceVendorAddressBookId = imeContact.SurveillanceVendorAddressBookId;
        imeService.SurveillanceVendorIndividualId = imeContact.SurveillanceVendorIndividualId;

        imeService.TranscriptionServicesVendorAddressBookId = imeContact.TranscriptionServicesVendorAddressBookId;
        imeService.TranscriptionServicesVendorIndividualId = imeContact.TranscriptionServicesVendorIndividualId;

        imeService.RentalFacilityVendorAddressBookId = imeContact.RentalFacilityVendorAddressBookId;
        imeService.RentalFacilityVendorIndividualId = imeContact.RentalFacilityVendorIndividualId;

        return imeService;
    }

    assignImetoContacts(imeContact: IImeContactInfo, imeService: IImeService): IImeContactInfo {
        if (imeService) {
            imeContact.TransportationServicesVendorName = imeService.TransportationServicesVendorIndividual
                ? imeService.TransportationServicesVendorIndividual.FirstName + ' ' + imeService.TransportationServicesVendorIndividual.LastName
                : imeService.TransportationServicesVendorAddressBook
                ? imeService.TransportationServicesVendorAddressBook.FacilityName
                : 'N/A';

            imeContact.TranslationServicesVendorName = imeService.TranslationServicesVendorIndividual
                ? imeService.TranslationServicesVendorIndividual.FirstName + ' ' + imeService.TranslationServicesVendorIndividual.LastName
                : imeService.TranslationServicesVendorAddressBook
                ? imeService.TranslationServicesVendorAddressBook.FacilityName
                : 'N/A';

            imeContact.BackgroundInvestigationVendorName = imeService.BackgroundInvestigationVendorIndividual
                ? imeService.BackgroundInvestigationVendorIndividual.FirstName + ' ' + imeService.BackgroundInvestigationVendorIndividual.LastName
                : imeService.BackgroundInvestigationVendorAddressBook
                ? imeService.BackgroundInvestigationVendorAddressBook.FacilityName
                : 'N/A';

            imeContact.SurveillanceVendorName = imeService.SurveillanceVendorIndividual
                ? imeService.SurveillanceVendorIndividual.FirstName + ' ' + imeService.SurveillanceVendorIndividual.LastName
                : imeService.SurveillanceVendorAddressBook
                ? imeService.SurveillanceVendorAddressBook.FacilityName
                : 'N/A';

            imeContact.TranscriptionServicesVendorName = imeService.TranscriptionServicesVendorIndividual
                ? imeService.TranscriptionServicesVendorIndividual.FirstName + ' ' + imeService.TranscriptionServicesVendorIndividual.LastName
                : imeService.TranscriptionServicesVendorAddressBook
                ? imeService.TranscriptionServicesVendorAddressBook.FacilityName
                : 'N/A';

            imeContact.RentalFacilityVendorName = imeService.RentalFacilityVendorIndividual
                ? imeService.RentalFacilityVendorIndividual.FirstName + ' ' + imeService.RentalFacilityVendorIndividual.LastName
                : imeService.RentalFacilityVendorAddressBook
                ? imeService.RentalFacilityVendorAddressBook.FacilityName
                : 'N/A';

            imeContact.TranslationServicesVendorAddressBookId = imeService.TranslationServicesVendorAddressBookId;
            imeContact.TranslationServicesVendorIndividualId = imeService.TranslationServicesVendorIndividualId;

            imeContact.BackgroundInvestigationVendorAddressBookId = imeService.BackgroundInvestigationVendorAddressBookId;
            imeContact.BackgroundInvestigationVendorIndividualId = imeService.BackgroundInvestigationVendorIndividualId;

            imeContact.TransportationServicesVendorAddressBookId = imeService.TransportationServicesVendorAddressBookId;
            imeContact.TransportationServicesVendorIndividualId = imeService.TransportationServicesVendorIndividualId;

            imeContact.SurveillanceVendorAddressBookId = imeService.SurveillanceVendorAddressBookId;
            imeContact.SurveillanceVendorIndividualId = imeService.SurveillanceVendorIndividualId;

            imeContact.TranscriptionServicesVendorAddressBookId = imeService.TranscriptionServicesVendorAddressBookId;
            imeContact.TranscriptionServicesVendorIndividualId = imeService.TranscriptionServicesVendorIndividualId;

            imeContact.RentalFacilityVendorAddressBookId = imeService.RentalFacilityVendorAddressBookId;
            imeContact.RentalFacilityVendorIndividualId = imeService.RentalFacilityVendorIndividualId;
        }

        return imeContact;
    }

    unAssignContactFromIME(imeContactinfo: IImeContactInfo, name: string): IImeContactInfo {
        switch (name as AddressBookSelectionEnums) {
            case AddressBookSelectionEnums.BackgroundInvestigationVendor:
                imeContactinfo.BackgroundInvestigationVendorAddressBookId = null;
                imeContactinfo.BackgroundInvestigationVendorIndividualId = null;
                imeContactinfo.BackgroundInvestigationVendorName = null;
                break;
            case AddressBookSelectionEnums.TranslationServicesVendor:
                imeContactinfo.TranslationServicesVendorAddressBookId = null;
                imeContactinfo.TranslationServicesVendorIndividualId = null;
                imeContactinfo.TranslationServicesVendorName = null;
                break;
            case AddressBookSelectionEnums.TransportationServicesVendor:
                imeContactinfo.TransportationServicesVendorAddressBookId = null;
                imeContactinfo.TransportationServicesVendorIndividualId = null;
                imeContactinfo.TransportationServicesVendorName = null;
                break;
            case AddressBookSelectionEnums.SurveillanceVendor:
                imeContactinfo.SurveillanceVendorName = null;
                imeContactinfo.SurveillanceVendorAddressBookId = null;
                imeContactinfo.SurveillanceVendorIndividualId = null;
                break;
            case AddressBookSelectionEnums.TranscriptionServicesVendor:
                imeContactinfo.TranscriptionServicesVendorName = null;
                imeContactinfo.TranscriptionServicesVendorAddressBookId = null;
                imeContactinfo.TranslationServicesVendorIndividualId = null;
                break;
            case AddressBookSelectionEnums.RentalFacilityVendor:
                imeContactinfo.RentalFacilityVendorName = null;
                imeContactinfo.RentalFacilityVendorAddressBookId = null;
                imeContactinfo.RentalFacilityVendorIndividualId = null;
                break;
            default:
                break;
        }
        return imeContactinfo;
    }

    updateService(Id: number, servicePayLoad: IServicePayload<IImeService>): Observable<number> {
        nullOutNestedObjects(servicePayLoad.ParentService);
        nullOutNestedObjects(servicePayLoad.ChildService);
        return this.http.put<number>(`/imeservices/update-service/${Id}`, servicePayLoad);
    }

    updateServiceConflictOfInterest(Id: number, servicePayLoad: IServicePayload<IImeService>): Observable<number> {
        nullOutNestedObjects(servicePayLoad.ParentService);
        nullOutNestedObjects(servicePayLoad.ChildService);
        return this.http.put<number>(`/imeservices/update-service-conflict/${Id}`, servicePayLoad);
    }

    checkForReportFromPhysician(Id: number): Observable<boolean> {
        return this.http.get<boolean>(`/imeservices/check-for-report-from-physician/${Id}`);
    }

    updateAdjusterInquiries(imedClaimServiceId: number, notes: INote[]): any {
        return this.http.put<any>(`/imedclaimservices/${imedClaimServiceId}/notes/update`, notes);
    }
}
