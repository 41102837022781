import { Component } from '@angular/core';

import { IEntityListDynamicCellComponent, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { IEntity } from '@mt-ng2/base-service';
import { IMasterServiceStatus } from '@model/interfaces/master-service-status';
import { ModalService } from '@mt-ng2/modal-module';
import { IServiceStatus } from '@model/interfaces/service-status';
import { IService } from '@model/interfaces/service';

@Component({
    template: `<span (click)="seeAll()" *ngIf = "showSeeAll()" class="btn btn-transparent pull-left">See All [{{serviceCount}}]</span>`,
})
export class MasterServiceStatusAssociatedServicesComponent implements IEntityListDynamicCellComponent {
    entityListComponentMembers: IEntityListComponentMembers;
    serviceTypes: IService[];

    set entity(value: IEntity) {
        this.masterServiceStatus = value as IMasterServiceStatus;
        this.ServiceStatus = this.masterServiceStatus.ServiceStatus.filter((ft) => !ft.Archived) ;
        this.serviceCount = this.ServiceStatus.length;
    }

    masterServiceStatus: IMasterServiceStatus;
    ServiceStatus: IServiceStatus[];
    serviceCount: number;

    constructor( private modalService: ModalService) {}

    seeAll(): void {
        this.modalService
            .showModal({
                cancelButtonColor: '#3085d6',
                cancelButtonText: 'Close',
                focusConfirm: false,
                html: `<div class="table-responsive">
                <table class="table table-hover table-striped">
                <thead>
                    <tr class="no-pointer">
                    <th>
                        <b>Services</b>
                    </th>
                   </tr>
                </thead>
                ${this.getAssociatedServicesBody()}
                </tr>
                </table>
                </div>
                <br />`,
                showCancelButton: true,
                showCloseButton: true,
                showConfirmButton: false,
                title: '<strong>Associated Services</strong>',
                width: 800,
            })
            .subscribe();
    }

    getAssociatedServicesBody(): string {
        let tBody = '';
        this.serviceTypes = this.ServiceStatus.map((ft) => ft.Service);
        this.serviceTypes = this.serviceTypes
                                .sort((a, b) => (a.Name < b.Name ? -1 : 1));
        this.serviceTypes.forEach((st) => {
            tBody += `<tr class="no-pointer">
                <td class="floatLeft">${st.Name}</td>
                </tr>`;
        });
        return `<tbody>${tBody}</tbody>`;
    }

    showSeeAll(): boolean {
        return this.masterServiceStatus ? this.masterServiceStatus.ServiceStatus.filter((ft) => !ft.Archived).length > 3 : false;
    }
}
