import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { IAddress } from '@model/interfaces/address';
import { AddressDynamicControlsPartial } from '@model/partials/address-partial.form-controls';
import { IState } from '@model/interfaces/state';
import { IAddressType } from '@model/interfaces/address-type';
import { AuthService } from '../../../../../node_modules/@mt-ng2/auth-module';
import { AddressDynamicConfig } from '../../../address-books/addresses/address.dynamic-config';
import { AddressService } from '../../../address-books/addresses/address.service';
import { AddressTypeService } from '../../../address-books/addresses/addresstype.service';
import { CountyService } from '@app-shared/services/county.service';
import { StateService } from '@app-shared/services/state.service';
import { IImedClaimPartial } from '@model/partials/imed-claim-partial';

@Component({
    selector: 'app-claim-address-basic-info',
    styles: [
        `
            .border {
                border: solid 1px #858a9f;
                margin-top: 10px;
                margin-bottom: 10px;
                border-radius: 0.5%;
            }
        `,
    ],
    templateUrl: './claim-address-basic-info.component.html',
})
export class ImedClaimAddressBasicInfoComponent implements OnInit {
    @Input() address: IAddress;
    @Input() canEdit: boolean;
    @Input() isInitialCreation: boolean;
    @Input() form: UntypedFormGroup;
    @Input() imedClaim: IImedClaimPartial;
    @Output() hideAddressDetails: EventEmitter<boolean> = new EventEmitter();
    @Output() onAddressSaved = new EventEmitter<IAddress>();
    claimId: number;
    @Input() isEditing = false;
    isHovered: boolean;
    addressForm: UntypedFormGroup;
    formFactory: AddressDynamicConfig<IAddress>;
    doubleClickIsDisabled = false;
    addressControls: any;
    addressControlsView: any;
    selectedAddressTypeIdsArray: number[] = [];
    addressTypes: IAddressType[];
    states: IState[];
    addressBookId: number;
    subscriptions: Subscription = new Subscription();
    countyName: string;
    formCreated = false;
    formIsRendered = false;
    constructor(
        private addressService: AddressService,
        private addressTypeService: AddressTypeService,
        private authService: AuthService,
        private statesService: StateService,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private router: Router,
        private fb: UntypedFormBuilder,
        private activatedRoute: ActivatedRoute,
        private countyService: CountyService,
    ) {}

    ngOnInit(): void {
        this.claimId = +this.activatedRoute.snapshot.paramMap.get('imedClaimId');
        this.addressControls = <AddressDynamicControlsPartial>this.addressControls;
        // if (!this.addressForm) {
        //     this.addressForm = this.fb.group({
        //         Address: this.fb.group({}),
        //     });
        // }

        forkJoin(this.addressTypeService.getItems(), this.statesService.getItems()).subscribe(() => this.createForm());
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
    createForm(): void {
        // Get Current County if applicable
        this.countyService.getByZip(this.address.Zip).subscribe((answer) => {
            if (answer) {
                this.countyName = answer.CountyName;
            }
        });

        this.formFactory = new AddressDynamicConfig<IAddress>(this.address, null);

        this.addressTypes = this.addressTypeService.items;
        this.states = this.statesService.items;

        this.getControls();
        this.addressForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    assignFormGroups(): UntypedFormGroup {
        return this.fb.group({
            Address: this.fb.group({}),
            AddressTypes: this.fb.group({}),
        });
    }

    getControls(): void {
        if (!this.formCreated) {
            if (!this.address.AddressTypes) {
                this.address.AddressTypes = [];
            } else {
                this.address.AddressTypes.forEach((addressType) => this.selectedAddressTypeIdsArray.push(addressType.Id));
            }
        }
        const viewAndFormAddressControl = new AddressDynamicControlsPartial(this.address, {
            addressTypes: this.addressTypes,
            formGroup: 'Address',
            selectedAddressTypeIdsArray: this.selectedAddressTypeIdsArray,
            states: this.states,
        });
        this.addressControls = viewAndFormAddressControl.Form;
        this.addressControlsView = viewAndFormAddressControl.View;
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        this.isEditing = false;
        this.emitHideAddressDetails();
    }

    emitHideAddressDetails(): void {
        if (this.claimId > 0) {
            this.hideAddressDetails.emit(true);
        }
    }

    formSubmitted(): void {
        if (this.addressForm.valid) {
            this.formFactory.assignFormValues(this.address, this.addressForm.value.Address as IAddress);
            this.address.AddressTypes = [];
            if (this.addressForm.value.Address.AssociatedAddressTypes) {
                this.addressForm.value.Address.AssociatedAddressTypes.forEach((addressTypeId: number) => {
                    this.address.AddressTypes.push(this.addressTypes.find((x) => x.Id === addressTypeId));
                });
            }
            this.address.IsPrimary = true;
            if (!this.address.Id || this.address.Id === 0) {
                this.address.CreatedById = this.authService.currentUser.getValue().Id;
                // handle new address save
                this.addressService.saveClaimAddress(this.claimId, this.address).subscribe(() => {
                    this.isEditing = false;
                    this.success();
                    this.addressService.emitChange(this.address);
                    this.imedClaim.Addresses.push(this.address);
                    this.emitHideAddressDetails();
                });
            } else {
                this.address.ModifiedById = this.authService.currentUser.getValue().Id;
                // handle existing address save
                this.addressService.updateClaimAddress(this.claimId, this.address).subscribe(() => {
                    this.isEditing = false;
                    this.success();
                    this.addressService.emitChange(this.address);
                    this.emitHideAddressDetails();
                });
            }
        } else {
            this.error();
            markAllFormFieldsAsTouched(this.form);
        }
    }

    getCountyName(): void {
        this.countyService.getByZip(this.addressForm.value.Address.Zip as string).subscribe((answer) => {
            if (answer) {
                this.countyName = answer.CountyName;
            }
        });
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }

    addressTypeSelectionChanged(event: any): void {
        this.selectedAddressTypeIdsArray = event === null ? [] : event;
        this.getControls();
    }

    setAddress() {
        if (this.isInitialCreation) {
            this.formFactory.assignFormValues(this.address, this.addressForm.value.Address as IAddress);
            this.address.AddressTypes = [];
            if (this.addressForm.value.Address.AssociatedAddressTypes) {
                this.addressForm.value.Address.AssociatedAddressTypes.forEach((addressTypeId: number) => {
                    this.address.AddressTypes.push(this.addressTypes.find((x) => x.Id === addressTypeId));
                });
            }
            this.address.IsPrimary = true;
            this.onAddressSaved.emit(this.address);
        }
    }
}
