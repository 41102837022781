import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IPhysicianVacation } from '../interfaces/physician-vacation';
import { IUser } from '../interfaces/user';
import { IPhysician } from '../interfaces/physician';

export interface IPhysicianVacationDynamicControlsParameters {
    formGroup?: string;
    physicians?: IPhysician[];
    createdBies?: IUser[];
    modifiedBies?: IUser[];
}

export class PhysicianVacationDynamicControls {

    formGroup: string;
    physicians: IPhysician[];
    createdBies: IUser[];
    modifiedBies: IUser[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private physicianvacation?: IPhysicianVacation, additionalParameters?: IPhysicianVacationDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'PhysicianVacation';
        this.physicians = additionalParameters && additionalParameters.physicians || undefined;
        this.createdBies = additionalParameters && additionalParameters.createdBies || undefined;
        this.modifiedBies = additionalParameters && additionalParameters.modifiedBies || undefined;

        this.Form = {
            Archived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived',
                name: 'Archived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physicianvacation && this.physicianvacation.hasOwnProperty('Archived') && this.physicianvacation.Archived !== null ? this.physicianvacation.Archived : false,
            }),
            CreatedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Created By',
                name: 'CreatedById',
                options: this.createdBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.physicianvacation && this.physicianvacation.CreatedById || null,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physicianvacation && this.physicianvacation.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physicianvacation && this.physicianvacation.DateModified || null,
            }),
            FromDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'From Date',
                name: 'FromDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.physicianvacation && this.physicianvacation.FromDate || null,
            }),
            ModifiedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Modified By',
                name: 'ModifiedById',
                options: this.modifiedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physicianvacation && this.physicianvacation.ModifiedById || null,
            }),
            PhysicianId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Physician',
                name: 'PhysicianId',
                options: this.physicians,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.physicianvacation && this.physicianvacation.PhysicianId || null,
            }),
            ToDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'To Date',
                name: 'ToDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.physicianvacation && this.physicianvacation.ToDate || null,
            }),
        };

        this.View = {
            Archived: new DynamicLabel({
			    label: 'Archived',
			    value: this.physicianvacation && this.physicianvacation.hasOwnProperty('Archived') && this.physicianvacation.Archived !== null ? this.physicianvacation.Archived : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            CreatedById: new DynamicLabel({
			    label: 'Created By',
			    value: getMetaItemValue(this.createdBies as unknown as IMetaItem[], this.physicianvacation && this.physicianvacation.hasOwnProperty('CreatedById') && this.physicianvacation.CreatedById !== null ? this.physicianvacation.CreatedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            DateCreated: new DynamicLabel({
			    label: 'Date Created',
			    value: this.physicianvacation && this.physicianvacation.DateCreated || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DateModified: new DynamicLabel({
			    label: 'Date Modified',
			    value: this.physicianvacation && this.physicianvacation.DateModified || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            FromDate: new DynamicLabel({
			    label: 'From Date',
			    value: this.physicianvacation && this.physicianvacation.FromDate || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            ModifiedById: new DynamicLabel({
			    label: 'Modified By',
			    value: getMetaItemValue(this.modifiedBies as unknown as IMetaItem[], this.physicianvacation && this.physicianvacation.hasOwnProperty('ModifiedById') && this.physicianvacation.ModifiedById !== null ? this.physicianvacation.ModifiedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            PhysicianId: new DynamicLabel({
			    label: 'Physician',
			    value: getMetaItemValue(this.physicians as unknown as IMetaItem[], this.physicianvacation && this.physicianvacation.hasOwnProperty('PhysicianId') && this.physicianvacation.PhysicianId !== null ? this.physicianvacation.PhysicianId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ToDate: new DynamicLabel({
			    label: 'To Date',
			    value: this.physicianvacation && this.physicianvacation.ToDate || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
        };

    }
}
