import { IEntityListConfig, EntityListConfig, EntityListColumn, IEntityListColumn } from '@mt-ng2/entity-list-module';
import { DatePipe } from '@angular/common';
import { IImedClaimFollowup } from '@model/interfaces/imed-claim-followup';
import { IViewGetCaseFollowupsForList } from '@model/interfaces/view-get-case-followups-for-list';
import { CaseFollowupDeleteDynamicCellComponent } from './imed-claim-followups-delete-dynamic-cell.component';

export class ImedClaimFollowupsEntityListConfig extends EntityListConfig {
    columns: IEntityListColumn[];
    constructor(private IsGlobalFollowUpList: boolean) {
        super(<IEntityListConfig> {
            columns: [
                new EntityListColumn({
                    accessorFunction:  (f: IViewGetCaseFollowupsForList) => {
                        if (this.IsGlobalFollowUpList) {
                          return new DatePipe('en-US').transform(f.Date, 'MMMM d, yyyy hh:mm a');
                        }
                        return new DatePipe('en-US').transform(f.FollowUpDate, 'MMMM d, yyyy hh:mm a');
                    },
                    name: 'Date/Time',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (f: IViewGetCaseFollowupsForList): string {
                        return '<a href="#/cases/' + f.ClaimId + '">' + f.ClaimantName + '</a>';
                    },
                    bindToInnerHtml: true,
                    fireOnColumnSelected: true,
                    name: 'Claimant Name',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (entity: IViewGetCaseFollowupsForList) => {
                        return `<div class="notes-column">${entity.Notes}</div>` || ' ';
                    },
                    bindToInnerHtml: true,
                    excludeFromExport: true,
                    name: 'Notes',
                    sort: {
                        disableSort: true,
                    },
                    style: { width: 300 },
                }),
                new EntityListColumn({
                    accessorFunction: (entity: IViewGetCaseFollowupsForList) => {
                        return entity.Notes ? entity.Notes.replace(/<\/?[^>]+(>|$)/g, '').replace(/&nbsp;/g, ' ') : '';
                    },
                    excludeFromView: true,
                    name: 'Notes',
                    sort: {
                        disableSort: true,
                    },
                    style: { width: 300 },
                }),
                new EntityListColumn({
                    accessorFunction: (entity: IViewGetCaseFollowupsForList) => {
                        if (entity.ReportDueBy) {
                            return 'Report Due By ' + this.getDateWithNoOffset(new Date(entity.ReportDueBy.toString())).toLocaleDateString();
                        }
                        return entity.Name;
                    },
                    name: 'Follow-up Type',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['UserRole'],
                    name: 'User Role',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['UserName'],
                    name: 'User',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    component: CaseFollowupDeleteDynamicCellComponent,
                    excludeFromExport: true,
                    fireOnColumnSelected: false,
                    name: 'Delete',
                    sort: { disableSort: true },
                }),
            ],
            rowClass: (entity: IImedClaimFollowup) => (entity.Archived ? 'archived' : null),
        });
    }

    getDateWithNoOffset(date: Date): Date {
        const userTimezoneOffset = date.getTimezoneOffset() * 60000;
        return new Date(date.getTime() + userTimezoneOffset);
    }
}
