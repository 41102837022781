import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IModalResult, ModalService } from '@mt-ng2/modal-module';

import { PopUpTypeEnums, ServiceStatusIdEnums } from '../constants/Enums';
import { IImedClaimService } from '@model/interfaces/imed-claim-service';
import { ISoftPopUpValidationFunctionResult, ISoftPopUp, SoftPopUpResults } from '@model/interfaces/custom/soft-popup';
import { ImedClaimServiceService } from '../../imed-claims/services/imedclaimservice.service';

export interface IPopUpParams {
    html: string;
    title?: string;
    type: PopUpTypeEnums;
    confirmButtonText?: string;
    cancelButtonText?: string;
    showCancelButton?: boolean;
}

export interface IHasLegalFoundationPopUp {
    hasPreLitigationDocumentUploaded: boolean;
    hasPreLitigationCheckboxChecked: boolean;
    isFederalCase: boolean;
    iImedClaimServiceModel: IImedClaimService;
    isHardPopUp: boolean;
    popUpValidationFunction: () => void;
}

@Injectable()
export class PopUpService {
    hardPopUpServiceStatuses: ServiceStatusIdEnums[] = [
        ServiceStatusIdEnums.Radiology_Review_Films_Forwarded_To_Expert_Pending_Report,
        ServiceStatusIdEnums.IME_Scheduled_Pending_Receipt_Of_Records,
        ServiceStatusIdEnums.IME_Scheduled_Pending_Compilation_Of_Records,
        ServiceStatusIdEnums.IME_Addendum_Scheduled_Pending_Receipt_Of_Records,
        ServiceStatusIdEnums.IME_Addendum_Scheduled_Pending_Compilation_Of_Records,
        ServiceStatusIdEnums.IME_Re_Exam_Scheduled_Pending_Receipt_Of_Records,
        ServiceStatusIdEnums.IME_Re_Exam_Scheduled_Pending_Compilation_Of_Records,
        ServiceStatusIdEnums.IME_Expert_Consultation_Scheduled_Pending_Receipt_Of_Records,
        ServiceStatusIdEnums.IME_Expert_Consultation_Scheduled_Pending_Compilation_Of_Records,
        ServiceStatusIdEnums.Billing_Desk_Review_Scheduled_Pending_Review_Of_Records,
        ServiceStatusIdEnums.Record_Review_Scheduled_Pending_Receipt_Of_Records,
        ServiceStatusIdEnums.Record_Review_Scheduled_Pending_Compilation_Of_Records,
    ];

    constructor(private imedClaimServiceService: ImedClaimServiceService, private modalService: ModalService) { }

    showPopUp({ html = '', title = '', type = PopUpTypeEnums.Soft_Pop_Up, confirmButtonText = 'Save', cancelButtonText = 'Cancel', showCancelButton = true }: IPopUpParams): Observable<IModalResult> {
        return this.modalService.showModal({
            cancelButtonColor: '#d33',
            cancelButtonText: cancelButtonText,
            confirmButtonColor: '#3085d6',
            confirmButtonText: confirmButtonText,
            focusConfirm: false,
            html: html,
            showCancelButton: showCancelButton,
            showCloseButton: false,
            showConfirmButton: type === PopUpTypeEnums.Soft_Pop_Up ? true : false,
            title: title,
            width: 800,
        });
    }

    prelitigationValidationFunction(): () => void {
        return function (): ISoftPopUpValidationFunctionResult {
            const popup: ISoftPopUp = { Messages: [] };
            if (this.hasPreLitigationCheckboxChecked || this.hasPreLitigationDocumentUploaded || this.isFederalCase) {
                return { result: SoftPopUpResults.Passed };
            } else {
                popup.Messages.push('Legal Foundation Document is required for non-Federal cases.');
                return { result: SoftPopUpResults.Failed, isHardPopup: this.isHardPopUp ? true : false, popup: popup };
            }
        };
    }

    setPopupConfig(service: IHasLegalFoundationPopUp): void {
        this.imedClaimServiceService.checkForPreLitigationDocumentIsUploaded(service.iImedClaimServiceModel.Id).subscribe((response) => {
            service.hasPreLitigationDocumentUploaded = response;
        });
        service.isHardPopUp = this.hardPopUpServiceStatuses.includes(service.iImedClaimServiceModel.ServiceStatusId);
        service.popUpValidationFunction = this.prelitigationValidationFunction().bind(service);
    }
}
