import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IService } from '@model/interfaces/service';

@Injectable()
export class AssociationServiceSelectionService {
    private _service: BehaviorSubject<IService> = new BehaviorSubject<IService>(null);

    constructor() { }

    setAssociationType(value: IService): void {
        this._service.next(value);
    }

    getAssociationType(): Observable<IService> {
        return this._service.asObservable();
    }
}
