import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldTypes,
    DynamicFieldType,
    InputTypes,
    ITimepickerOptions,
    IDynamicFieldType,
    DynamicLabel,
    SelectInputTypes,
    noZeroRequiredValidator,
    IDynamicField,
} from '@mt-ng2/dynamic-form';

import { CommunicationMethodEnums, ViaEnums } from '../../common/constants/Enums';
import { MetaItem } from '@mt-ng2/base-service';
import { IImedClaimFollowupDynamicControlsParameters, ImedClaimFollowupDynamicControls } from '@model/form-controls/imed-claim-followup.form-controls';
import { IImedClaimFollowup } from '@model/interfaces/imed-claim-followup';
import { IImedClaimService } from './imed-claim-service.partial';
import { MultiselectItem } from '@mt-ng2/multiselect-control';

export interface ImedClaimFollowupDynamicControlsParametersPartial extends IImedClaimFollowupDynamicControlsParameters {
    services?: MetaItem[];
    selectedServiceIdsArray?: number[];
    additionalBTPs?: MetaItem[];
    selectedAdditionalBTPIdsArray?: number[];
}

export class ImedClaimFollowupDynamicControlsPartial extends ImedClaimFollowupDynamicControls {
    communicationMethods: MetaItem[] = [
        new MetaItem(CommunicationMethodEnums.TELEPHONE, 'Telephone'),
        new MetaItem(CommunicationMethodEnums.EMAIL, 'EMail'),
        new MetaItem(CommunicationMethodEnums.MAIL, 'Mail'),
        new MetaItem(CommunicationMethodEnums.ELECTRONIC, 'Electronic'),
        new MetaItem(CommunicationMethodEnums.NA, 'N/A'),
    ];

    viaTypes: MetaItem[] = [
        new MetaItem(ViaEnums.EMAIL, 'Email'),
        new MetaItem(ViaEnums.MAIL, 'Mail'),
        new MetaItem(ViaEnums.UPS, 'UPS'),
        new MetaItem(ViaEnums.SYSTEM_LINK, 'System Link'),
    ];

    constructor(
        imedclaimfollowupPartial?: IImedClaimFollowup,
        additionalParameters?: ImedClaimFollowupDynamicControlsParametersPartial,
        canEditfollowupDate?: boolean,
    ) {
        super(imedclaimfollowupPartial, additionalParameters);

        (<DynamicField>this.Form.ReceivedAppointmentDateTime).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Input,
            inputType: InputTypes.DateTimepicker,
            timepickerOptions: { meridian: true } as ITimepickerOptions,
        });

        if (!canEditfollowupDate) {
            (<DynamicField>this.Form.Date).disabled = true;
        }
        (<DynamicField>this.Form.CommunicationMethod).type.fieldType = DynamicFieldTypes.Select;
        (<DynamicField>this.Form.CommunicationMethod).type.inputType = SelectInputTypes.RadioButtonList;
        (<DynamicField>this.Form.CommunicationMethod).options = this.communicationMethods;
        (<DynamicField>this.Form.CommunicationMethod).value = imedclaimfollowupPartial.CommunicationMethod
            ? this.communicationMethods.find((x) => x.Id === imedclaimfollowupPartial.CommunicationMethod).Id
            : imedclaimfollowupPartial.CommunicationMethod === 0
            ? 0
            : null;
        (<DynamicField>this.View.CommunicationMethod).value = imedclaimfollowupPartial.CommunicationMethod
            ? this.communicationMethods.find((x) => x.Id === imedclaimfollowupPartial.CommunicationMethod).Name
            : imedclaimfollowupPartial.CommunicationMethod === 0
            ? 'Telephone'
            : null;

        (<DynamicField>this.Form.Via).type.fieldType = DynamicFieldTypes.Select;
        (<DynamicField>this.Form.Via).type.inputType = SelectInputTypes.RadioButtonList;
        (<DynamicField>this.Form.Via).options = this.viaTypes;
        (<DynamicField>this.View.Via).value = imedclaimfollowupPartial.Via
            ? this.viaTypes.find((x) => x.Id === imedclaimfollowupPartial.Via).Name
            : imedclaimfollowupPartial.Via === 0
            ? 'Email'
            : null;

        (<DynamicField>this.Form.ReceivedAppointmentDateTime).setRequired(true);
        (<DynamicField>this.Form.ReceivedAppointmentDateTime).label = 'Appointment Date';
        (<DynamicField>this.Form.ReceivedAppointmentDateTime).labelHtml = '<label>Appointment Date</label>';

        (<DynamicField>this.Form.MasterFollowupTypeId).label = 'Follow-up Type';
        (<DynamicField>this.Form.MasterFollowupTypeId).labelHtml = 'Follow-up Type';

        (<DynamicField>this.View.MasterFollowupTypeId).label = 'Follow-up Type';
        (<DynamicField>this.View.MasterFollowupTypeId).label = 'Follow-up Type';

        if (!(imedclaimfollowupPartial.ReceivedAppointmentDateTime instanceof Date) && imedclaimfollowupPartial.ReceivedAppointmentDateTime) {
            let offsetDate = new Date(imedclaimfollowupPartial.ReceivedAppointmentDateTime);
            offsetDate.setHours(offsetDate.getHours() - 4);
            (<DynamicField>this.Form.ReceivedAppointmentDateTime).value = offsetDate;
            (<DynamicField>this.View.ReceivedAppointmentDateTime).value = offsetDate;
        }

        (<DynamicLabel>this.View.ReceivedAppointmentDateTime).label = 'Appointment Date';
        (<DynamicField>this.Form.ReceiptOfNoticeOfImeConfirmedByName).setRequired(true);
        (<DynamicField>this.Form.ImeAppointmentConfirmedWithPlaintiffsAttorneyOfficeByName).setRequired(true);

        (<DynamicField>this.Form.PersonContactedOrSpokenTo).setRequired(true);
        (<DynamicField>this.Form.ReportForwardedToClientDate).label = 'Report forwarded Date';

        (<DynamicField>this.Form.MasterFollowupTypeId).type.inputType = SelectInputTypes.Dropdown;

        if ((<DynamicLabel>this.View.ReportDueBy).value) {
            (<DynamicLabel>this.View.MasterFollowupTypeId).value =
                'Report Due By ' + this.getDateWithNoOffset(new Date((<DynamicLabel>this.View.ReportDueBy).value.toString())).toLocaleDateString();
        }

        (<DynamicField>this.Form.CommunicationMethod).setRequired(true);
        (<DynamicField>this.Form.CommunicationMethod).validation = [Validators.required];
        this.Form.associatedServices = new DynamicField({
            formGroup: this.formGroup,
            label: 'Select Services',
            name: 'associatedServices',
            options: additionalParameters?.services,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.MultiselectDropdown,
                maxToShowInSelectedText: 1,
                showSelectAllButtons: true,
            } as IDynamicFieldType),
            value: additionalParameters?.selectedServiceIdsArray,
        } as IDynamicField);

        this.Form.associatedBTPs = new DynamicField({
            formGroup: this.formGroup,
            label: 'Select Additional Carrier/Bill-To parties',
            name: 'associatedBTPs',
            options: additionalParameters?.additionalBTPs,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.MultiselectDropdown,
                maxToShowInSelectedText: 1,
                showSelectAllButtons: true,
            } as IDynamicFieldType),
            value: additionalParameters?.selectedAdditionalBTPIdsArray,
        } as IDynamicField);
    }

    getDateWithNoOffset(date: Date): Date {
        const userTimezoneOffset = date.getTimezoneOffset() * 60000;
        return new Date(date.getTime() + userTimezoneOffset);
    }
}
