import { Injectable } from '@angular/core';
import { AddressBookAssociationTypeEnums } from '@common/constants/Enums';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class AddressBookAssociationsToggleService {
    private _associationType: BehaviorSubject<AddressBookAssociationTypeEnums> = new BehaviorSubject<AddressBookAssociationTypeEnums>(
        AddressBookAssociationTypeEnums.SYSTEM_TASK,
    );

    constructor() {}

    setAssociationType(value: AddressBookAssociationTypeEnums): void {
        this._associationType.next(value);
    }

    getAssociationType(): Observable<AddressBookAssociationTypeEnums> {
        return this._associationType.asObservable();
    }
}
