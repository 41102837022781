import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import 'rxjs/operators';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues, AuthService } from '@mt-ng2/auth-module';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection, IItemDeletedEvent } from '@mt-ng2/entity-list-module';

import { AddressService } from '../address.service';
import { IAddress } from '@model/interfaces/address';
import { entityListModuleConfig } from '../../../common/shared.module';
import { ClaimTypes } from '@model/ClaimTypes';
import { AddressesEntityListConfig } from './addresses.entity-list-config';
import { AddressTypeService } from './../addresstype.service';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Component({
    selector: 'app-addresses',
    templateUrl: './addresses.component.html',
})
export class AddressesComponent implements OnInit {
    searchControl = new UntypedFormControl();
    addresses: IAddress[];
    currentPage = 1;
    query = '';
    total: number;
    addressTypes: MtSearchFilterItem[] = [];
    entityListConfig = new AddressesEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();
    addressBookId: number;

    canAddAddress = false;
    canDelete: boolean;

    constructor(
        private addressService: AddressService,
        private addressTypeService: AddressTypeService,
        private claimsService: ClaimsService,
        private router: Router,
        private notificationService: NotificationsService,
        private activatedRoute: ActivatedRoute,
        private authService: AuthService,
    ) { }

    ngOnInit(): void {
        this.addressBookId = +this.activatedRoute.parent.snapshot.params.addressBookId;
        this.canAddAddress = this.claimsService.hasClaim(ClaimTypes.AddressBooks, [ClaimValues.FullAccess]);
        this.addressService.setCanDelete(this.canAddAddress);
        this.addressTypeService.getSearchFilterItems().subscribe((answer) => (this.addressTypes = answer));
        this.getAddresses();
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    private buildSearch(): ExtraSearchParams[] {
        const selectedAddressTypesIds: number[] = this.getSelectedFilters(this.addressTypes);
        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'AddressTypeIds',
                valueArray: selectedAddressTypesIds,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'AddressBookId',
                value: this.addressBookId.toString(),
            }),
        );
        return _extraSearchParams;
    }

    getAddresses(): void {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: entityListModuleConfig.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        this.addressService.searchAddress(this.addressBookId, searchparams).subscribe((answer) => {
            this.addresses = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.query = query;
        this.currentPage = 1;
        this.getAddresses();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getAddresses();
    }

    addressSelected(event: IItemSelectedEvent): void {
        void this.router.navigate([`address-books/${this.addressBookId}/addresses`, event.entity.Id]);
    }

    addAddress(): void {
        void this.router.navigate([`address-books/${this.addressBookId}/addresses/add`]);
    }

    onItemDeleted(event: IItemDeletedEvent): void {
        const selectedAddress: IAddress = event.entity;
        selectedAddress.Archived = !selectedAddress.Archived;
        selectedAddress.ModifiedById = this.authService.currentUser.getValue().Id;
        selectedAddress.DateModified = new Date();
        this.addressService.update(selectedAddress).subscribe(() => {
            this.notificationService.success('Address Deleted Successfully');
            this.getAddresses();
        });
    }
}
