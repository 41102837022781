import { Component, OnInit, Input } from '@angular/core';
import { EmailLabelGenerator } from './email-label.generator';
import { IEmailAddress } from '@model/interfaces/email-address';
import { Router } from '@angular/router';
import { EmailAddressDynamicConfig } from '@model/shared-entities/email-addresses/email-address.dynamic-config';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { emptyEmailAddress } from '@model/shared-entities/email-addresses/email-address.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { finalize } from 'rxjs/operators';
import { IndividualEmailService } from '../individuals/shared/individual-email.service';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { IEntity } from '@model/interfaces/base';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, DynamicLabel, IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { IEmailType } from '@model/interfaces/email-type';
import { EmailTypes } from '@common/constants/Enums';

@Component({
    selector: 'app-shared-emails',
    templateUrl: './shared-emails.component.html',
})
export class SharedEmailsComponent implements OnInit {
    @Input() canEdit: boolean;
    @Input() individualId: number;
    @Input() addressBookId: number;
    @Input() addressBookIsFacilityType;
    @Input() emailTypes: IEmailType[] = [];
    @Input() individualEmails: IEmailAddress[] = [];
    @Input() facilityEmails: IEmailAddress[] = [];

    // TODO: improve dependency inversion
    emailLabelGenerator = new EmailLabelGenerator();
    emailCardHeading = 'Emails';
    emailFormFactory: EmailAddressDynamicConfig<IEmailAddress>;
    emailForm: UntypedFormGroup;
    emailAddress: IEmailAddress;
    config: IDynamicFormConfig;
    isEditing: boolean;
    doubleClickIsDisabled: boolean;

    facilityEmailsControl: DynamicField;
    showFacilityEmailsControl = false;
    selectionChanged = false;

    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    constructor(
        private emailAddressService: IndividualEmailService,
        private fb: UntypedFormBuilder,
        private router: Router,
        private notificationsService: NotificationsService,
    ) {}

    ngOnInit(): void {
        this.emailForm = this.fb.group({});
        this.config = { formObject: [], viewOnly: [] };

        if (this.addressBookIsFacilityType) {
            this.facilityEmailsControl = new DynamicField({
                formGroup: '',
                label: 'Facility Emails',
                name: 'facilityEmailId',
                options: this.facilityEmails.map((fe) => ({ Name: this.emailLabelGenerator.GetDropdownLabel(fe), Id: fe.Id })),
                type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Select }),
                validation: [],
                validators: {},
                value: null,
            });
        }
        this.emailTypes = this.emailTypes.filter((emailType) => emailType.Id !== +EmailTypes.IME_Billing && emailType.Id !== +EmailTypes.Retrieval_Billing);
    }

    ngOnChanges(changes): void {
        if (changes.facilityEmails && !changes.facilityEmails.firstChange && this.addressBookIsFacilityType) {
            const newFacilityEmailsControl = new DynamicField({
                formGroup: '',
                label: 'Facility Emails',
                name: 'facilityEmailId',
                options: this.facilityEmails.map((fe) => ({ Name: this.emailLabelGenerator.GetDropdownLabel(fe), Id: fe.Id })),
                type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Select }),
                validation: [],
                validators: {},
                value: null,
            });
            setTimeout(() => (this.facilityEmailsControl = newFacilityEmailsControl));
        }
    }

    setConfig(): void {
        this.emailFormFactory = new EmailAddressDynamicConfig<IEmailAddress>(this.emailAddress, this.emailTypes);
        this.config = this.emailFormFactory.getForCreate();

        this.viewOnly = this.config?.viewOnly?.map((x) => new DynamicLabel(x));
        this.formObject = this.config.formObject?.map((x) => new DynamicField(x));
    }

    selectFacilityEmail(event): void {
        this.selectionChanged = true;
        const selectedEmail = this.facilityEmails.find((fe) => fe.Id === event);
        this.emailAddress = { ...emptyEmailAddress };
        this.emailAddress.Email = selectedEmail.Email;
        this.emailAddress.EmailTypeId = selectedEmail.EmailTypeId;
        this.emailAddress.IsPrimary = selectedEmail.IsPrimary;
        this.emailAddress.Notes = selectedEmail.Notes ? selectedEmail.Notes : '';
        this.setConfig();
        setTimeout(() => (this.selectionChanged = false));
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            this.emailFormFactory.assignFormValues(this.emailAddress, form.value.EmailAddress as IEmailAddress);
            this.emailAddressService
                .saveEntity(this.individualId, this.emailAddress)
                .pipe()
                .subscribe(() => {
                    this.success();
                    this.setEditState(false);
                    this.setConfig();
                });
        } else {
            markAllFormFieldsAsTouched(form);
            this.error();
        }
    }

    private getBaseRoute(): string {
        return `address-books/${this.addressBookId}/individuals/${this.individualId}`;
    }

    handleSelectEmail(emailAddress: IEntity): void {
        const emailAddressId = emailAddress.Id;
        void this.router.navigateByUrl(`${this.getBaseRoute()}/emailaddresses/${emailAddressId}`);
    }

    handleSeeAll(): void {
        void this.router.navigateByUrl(`${this.getBaseRoute()}/emailaddresses`);
    }

    setEditState(newState: boolean): void {
        if (newState) {
            this.emailAddress = { ...emptyEmailAddress };
            this.setConfig();
        }
        this.isEditing = newState;
        this.emailCardHeading = newState ? 'Add Email' : 'Emails';
    }

    error(): void {
        this.notificationsService.error('Save failed.  Please check the form and try again.');
    }

    success(): void {
        this.notificationsService.success('Email saved successfully!');
    }
}
