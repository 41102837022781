import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IAddressBook } from '../interfaces/address-book';
import { IAddressBookType } from '../interfaces/address-book-type';
import { IImage } from '../interfaces/image';
import { IUser } from '../interfaces/user';
import { IAddress } from '../interfaces/address';
import { IMetaAddressBookType } from '../interfaces/meta-address-book-type';

export interface IAddressBookDynamicControlsParameters {
    formGroup?: string;
    metaAddressBookTypes?: IMetaAddressBookType[];
    addressBookTypes?: IAddressBookType[];
    letterHeadImages?: IImage[];
    createdBies?: IUser[];
    modifiedBies?: IUser[];
    physicalAddresses?: IAddress[];
    mailingAddresses?: IAddress[];
    courtSealImages?: IImage[];
    wetSignatureImages?: IImage[];
}

export class AddressBookDynamicControls {

    formGroup: string;
    metaAddressBookTypes: IMetaAddressBookType[];
    addressBookTypes: IAddressBookType[];
    letterHeadImages: IImage[];
    createdBies: IUser[];
    modifiedBies: IUser[];
    physicalAddresses: IAddress[];
    mailingAddresses: IAddress[];
    courtSealImages: IImage[];
    wetSignatureImages: IImage[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private addressbook?: IAddressBook, additionalParameters?: IAddressBookDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'AddressBook';
        this.metaAddressBookTypes = additionalParameters && additionalParameters.metaAddressBookTypes || undefined;
        this.addressBookTypes = additionalParameters && additionalParameters.addressBookTypes || undefined;
        this.letterHeadImages = additionalParameters && additionalParameters.letterHeadImages || undefined;
        this.createdBies = additionalParameters && additionalParameters.createdBies || undefined;
        this.modifiedBies = additionalParameters && additionalParameters.modifiedBies || undefined;
        this.physicalAddresses = additionalParameters && additionalParameters.physicalAddresses || undefined;
        this.mailingAddresses = additionalParameters && additionalParameters.mailingAddresses || undefined;
        this.courtSealImages = additionalParameters && additionalParameters.courtSealImages || undefined;
        this.wetSignatureImages = additionalParameters && additionalParameters.wetSignatureImages || undefined;

        this.Form = {
            AddressBookTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Address Book Type',
                name: 'AddressBookTypeId',
                options: this.addressBookTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.addressbook && this.addressbook.AddressBookTypeId || null,
            }),
            Archived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived',
                name: 'Archived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.addressbook && this.addressbook.hasOwnProperty('Archived') && this.addressbook.Archived !== null ? this.addressbook.Archived : false,
            }),
            AttachmentIdentifier: new DynamicField({
                formGroup: this.formGroup,
                label: 'Attachment Identifier',
                name: 'AttachmentIdentifier',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(10) ],
                validators: { 'maxlength': 10 },
                value: this.addressbook && this.addressbook.hasOwnProperty('AttachmentIdentifier') && this.addressbook.AttachmentIdentifier !== null ? this.addressbook.AttachmentIdentifier.toString() : '',
            }),
            Attention: new DynamicField({
                formGroup: this.formGroup,
                label: 'Attention',
                name: 'Attention',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(250) ],
                validators: { 'maxlength': 250 },
                value: this.addressbook && this.addressbook.hasOwnProperty('Attention') && this.addressbook.Attention !== null ? this.addressbook.Attention.toString() : '',
            }),
            ContractRateFrom: new DynamicField({
                formGroup: this.formGroup,
                label: 'Contract Rate From',
                name: 'ContractRateFrom',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.addressbook && this.addressbook.ContractRateFrom || null,
            }),
            ContractRateTo: new DynamicField({
                formGroup: this.formGroup,
                label: 'Contract Rate To',
                name: 'ContractRateTo',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.addressbook && this.addressbook.ContractRateTo || null,
            }),
            CourtSealImageId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Court Seal Image',
                name: 'CourtSealImageId',
                options: this.courtSealImages,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.addressbook && this.addressbook.CourtSealImageId || null,
            }),
            CreatedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Created By',
                name: 'CreatedById',
                options: this.createdBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.addressbook && this.addressbook.CreatedById || null,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.addressbook && this.addressbook.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.addressbook && this.addressbook.DateModified || null,
            }),
            DaysToProduce: new DynamicField({
                formGroup: this.formGroup,
                label: 'Days To Produce',
                name: 'DaysToProduce',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.addressbook && this.addressbook.DaysToProduce || null,
            }),
            DomainName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Domain Name',
                name: 'DomainName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(150) ],
                validators: { 'maxlength': 150 },
                value: this.addressbook && this.addressbook.hasOwnProperty('DomainName') && this.addressbook.DomainName !== null ? this.addressbook.DomainName.toString() : '',
            }),
            Ein: new DynamicField({
                formGroup: this.formGroup,
                label: 'Ein',
                name: 'Ein',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(20) ],
                validators: { 'maxlength': 20 },
                value: this.addressbook && this.addressbook.hasOwnProperty('Ein') && this.addressbook.Ein !== null ? this.addressbook.Ein.toString() : '',
            }),
            FacilityLegacyId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Facility Legacy',
                name: 'FacilityLegacyId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.addressbook && this.addressbook.hasOwnProperty('FacilityLegacyId') && this.addressbook.FacilityLegacyId !== null ? this.addressbook.FacilityLegacyId.toString() : '',
            }),
            FacilityName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Facility Name',
                name: 'FacilityName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(250) ],
                validators: { 'required': true, 'maxlength': 250 },
                value: this.addressbook && this.addressbook.hasOwnProperty('FacilityName') && this.addressbook.FacilityName !== null ? this.addressbook.FacilityName.toString() : '',
            }),
            HistoricalNotes: new DynamicField({
                formGroup: this.formGroup,
                label: 'Historical Notes',
                name: 'HistoricalNotes',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.addressbook && this.addressbook.hasOwnProperty('HistoricalNotes') && this.addressbook.HistoricalNotes !== null ? this.addressbook.HistoricalNotes.toString() : '',
            }),
            IndividualLegacyFacilityId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Individual Legacy Facility',
                name: 'IndividualLegacyFacilityId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.addressbook && this.addressbook.hasOwnProperty('IndividualLegacyFacilityId') && this.addressbook.IndividualLegacyFacilityId !== null ? this.addressbook.IndividualLegacyFacilityId.toString() : '',
            }),
            LetterHeadImageId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Letter Head Image',
                name: 'LetterHeadImageId',
                options: this.letterHeadImages,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.addressbook && this.addressbook.LetterHeadImageId || null,
            }),
            MailingAddressId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Mailing Address',
                name: 'MailingAddressId',
                options: this.mailingAddresses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.addressbook && this.addressbook.MailingAddressId || null,
            }),
            MetaAddressBookTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Meta Address Book Type',
                name: 'MetaAddressBookTypeId',
                options: this.metaAddressBookTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.addressbook && this.addressbook.MetaAddressBookTypeId || null,
            }),
            ModifiedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Modified By',
                name: 'ModifiedById',
                options: this.modifiedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.addressbook && this.addressbook.ModifiedById || null,
            }),
            Naic: new DynamicField({
                formGroup: this.formGroup,
                label: 'Naic',
                name: 'Naic',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(20) ],
                validators: { 'maxlength': 20 },
                value: this.addressbook && this.addressbook.hasOwnProperty('Naic') && this.addressbook.Naic !== null ? this.addressbook.Naic.toString() : '',
            }),
            Notes: new DynamicField({
                formGroup: this.formGroup,
                label: 'Notes',
                name: 'Notes',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.WYSIWYG,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.addressbook && this.addressbook.hasOwnProperty('Notes') && this.addressbook.Notes !== null ? this.addressbook.Notes.toString() : '',
            }),
            PhysicalAddressId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Physical Address',
                name: 'PhysicalAddressId',
                options: this.physicalAddresses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.addressbook && this.addressbook.PhysicalAddressId || null,
            }),
            PrintOnCheckAs: new DynamicField({
                formGroup: this.formGroup,
                label: 'Print On Check As',
                name: 'PrintOnCheckAs',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(250) ],
                validators: { 'maxlength': 250 },
                value: this.addressbook && this.addressbook.hasOwnProperty('PrintOnCheckAs') && this.addressbook.PrintOnCheckAs !== null ? this.addressbook.PrintOnCheckAs.toString() : '',
            }),
            RequiresWetSignature: new DynamicField({
                formGroup: this.formGroup,
                label: 'Requires Wet Signature',
                name: 'RequiresWetSignature',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.addressbook && this.addressbook.hasOwnProperty('RequiresWetSignature') && this.addressbook.RequiresWetSignature !== null ? this.addressbook.RequiresWetSignature : false,
            }),
            StrivenContactId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Striven Contact',
                name: 'StrivenContactId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.addressbook && this.addressbook.StrivenContactId || null,
            }),
            StrivenId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Striven',
                name: 'StrivenId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.addressbook && this.addressbook.StrivenId || null,
            }),
            WetSignatureImageId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Wet Signature Image',
                name: 'WetSignatureImageId',
                options: this.wetSignatureImages,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.addressbook && this.addressbook.WetSignatureImageId || null,
            }),
        };

        this.View = {
            AddressBookTypeId: new DynamicLabel({
			    label: 'Address Book Type',
			    value: getMetaItemValue(this.addressBookTypes as unknown as IMetaItem[], this.addressbook && this.addressbook.hasOwnProperty('AddressBookTypeId') && this.addressbook.AddressBookTypeId !== null ? this.addressbook.AddressBookTypeId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Archived: new DynamicLabel({
			    label: 'Archived',
			    value: this.addressbook && this.addressbook.hasOwnProperty('Archived') && this.addressbook.Archived !== null ? this.addressbook.Archived : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            AttachmentIdentifier: new DynamicLabel({
			    label: 'Attachment Identifier',
			    value: this.addressbook && this.addressbook.hasOwnProperty('AttachmentIdentifier') && this.addressbook.AttachmentIdentifier !== null ? this.addressbook.AttachmentIdentifier.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Attention: new DynamicLabel({
			    label: 'Attention',
			    value: this.addressbook && this.addressbook.hasOwnProperty('Attention') && this.addressbook.Attention !== null ? this.addressbook.Attention.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ContractRateFrom: new DynamicLabel({
			    label: 'Contract Rate From',
			    value: this.addressbook && this.addressbook.ContractRateFrom || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: null,
			        scale: 2,
			    })
			}
            ),
            ContractRateTo: new DynamicLabel({
			    label: 'Contract Rate To',
			    value: this.addressbook && this.addressbook.ContractRateTo || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: null,
			        scale: 2,
			    })
			}
            ),
            CourtSealImageId: new DynamicLabel({
			    label: 'Court Seal Image',
			    value: getMetaItemValue(this.courtSealImages as unknown as IMetaItem[], this.addressbook && this.addressbook.hasOwnProperty('CourtSealImageId') && this.addressbook.CourtSealImageId !== null ? this.addressbook.CourtSealImageId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            CreatedById: new DynamicLabel({
			    label: 'Created By',
			    value: getMetaItemValue(this.createdBies as unknown as IMetaItem[], this.addressbook && this.addressbook.hasOwnProperty('CreatedById') && this.addressbook.CreatedById !== null ? this.addressbook.CreatedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            DateCreated: new DynamicLabel({
			    label: 'Date Created',
			    value: this.addressbook && this.addressbook.DateCreated || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DateModified: new DynamicLabel({
			    label: 'Date Modified',
			    value: this.addressbook && this.addressbook.DateModified || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DaysToProduce: new DynamicLabel({
			    label: 'Days To Produce',
			    value: this.addressbook && this.addressbook.DaysToProduce || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: NumericInputTypes.Integer,
			        scale: null,
			    })
			}
            ),
            DomainName: new DynamicLabel({
			    label: 'Domain Name',
			    value: this.addressbook && this.addressbook.hasOwnProperty('DomainName') && this.addressbook.DomainName !== null ? this.addressbook.DomainName.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Ein: new DynamicLabel({
			    label: 'Ein',
			    value: this.addressbook && this.addressbook.hasOwnProperty('Ein') && this.addressbook.Ein !== null ? this.addressbook.Ein.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            FacilityLegacyId: new DynamicLabel({
			    label: 'Facility Legacy',
			    value: this.addressbook && this.addressbook.hasOwnProperty('FacilityLegacyId') && this.addressbook.FacilityLegacyId !== null ? this.addressbook.FacilityLegacyId.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            FacilityName: new DynamicLabel({
			    label: 'Facility Name',
			    value: this.addressbook && this.addressbook.hasOwnProperty('FacilityName') && this.addressbook.FacilityName !== null ? this.addressbook.FacilityName.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            HistoricalNotes: new DynamicLabel({
			    label: 'Historical Notes',
			    value: this.addressbook && this.addressbook.hasOwnProperty('HistoricalNotes') && this.addressbook.HistoricalNotes !== null ? this.addressbook.HistoricalNotes.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Textarea,
			        scale: null,
			    })
			}
            ),
            IndividualLegacyFacilityId: new DynamicLabel({
			    label: 'Individual Legacy Facility',
			    value: this.addressbook && this.addressbook.hasOwnProperty('IndividualLegacyFacilityId') && this.addressbook.IndividualLegacyFacilityId !== null ? this.addressbook.IndividualLegacyFacilityId.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            LetterHeadImageId: new DynamicLabel({
			    label: 'Letter Head Image',
			    value: getMetaItemValue(this.letterHeadImages as unknown as IMetaItem[], this.addressbook && this.addressbook.hasOwnProperty('LetterHeadImageId') && this.addressbook.LetterHeadImageId !== null ? this.addressbook.LetterHeadImageId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            MailingAddressId: new DynamicLabel({
			    label: 'Mailing Address',
			    value: getMetaItemValue(this.mailingAddresses as unknown as IMetaItem[], this.addressbook && this.addressbook.hasOwnProperty('MailingAddressId') && this.addressbook.MailingAddressId !== null ? this.addressbook.MailingAddressId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            MetaAddressBookTypeId: new DynamicLabel({
			    label: 'Meta Address Book Type',
			    value: getMetaItemValue(this.metaAddressBookTypes as unknown as IMetaItem[], this.addressbook && this.addressbook.hasOwnProperty('MetaAddressBookTypeId') && this.addressbook.MetaAddressBookTypeId !== null ? this.addressbook.MetaAddressBookTypeId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ModifiedById: new DynamicLabel({
			    label: 'Modified By',
			    value: getMetaItemValue(this.modifiedBies as unknown as IMetaItem[], this.addressbook && this.addressbook.hasOwnProperty('ModifiedById') && this.addressbook.ModifiedById !== null ? this.addressbook.ModifiedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Naic: new DynamicLabel({
			    label: 'Naic',
			    value: this.addressbook && this.addressbook.hasOwnProperty('Naic') && this.addressbook.Naic !== null ? this.addressbook.Naic.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Notes: new DynamicLabel({
			    label: 'Notes',
			    value: this.addressbook && this.addressbook.hasOwnProperty('Notes') && this.addressbook.Notes !== null ? this.addressbook.Notes.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.WYSIWYG,
			        scale: null,
			    })
			}
            ),
            PhysicalAddressId: new DynamicLabel({
			    label: 'Physical Address',
			    value: getMetaItemValue(this.physicalAddresses as unknown as IMetaItem[], this.addressbook && this.addressbook.hasOwnProperty('PhysicalAddressId') && this.addressbook.PhysicalAddressId !== null ? this.addressbook.PhysicalAddressId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            PrintOnCheckAs: new DynamicLabel({
			    label: 'Print On Check As',
			    value: this.addressbook && this.addressbook.hasOwnProperty('PrintOnCheckAs') && this.addressbook.PrintOnCheckAs !== null ? this.addressbook.PrintOnCheckAs.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            RequiresWetSignature: new DynamicLabel({
			    label: 'Requires Wet Signature',
			    value: this.addressbook && this.addressbook.hasOwnProperty('RequiresWetSignature') && this.addressbook.RequiresWetSignature !== null ? this.addressbook.RequiresWetSignature : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            StrivenContactId: new DynamicLabel({
			    label: 'Striven Contact',
			    value: this.addressbook && this.addressbook.StrivenContactId || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: NumericInputTypes.Integer,
			        scale: null,
			    })
			}
            ),
            StrivenId: new DynamicLabel({
			    label: 'Striven',
			    value: this.addressbook && this.addressbook.StrivenId || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: NumericInputTypes.Integer,
			        scale: null,
			    })
			}
            ),
            WetSignatureImageId: new DynamicLabel({
			    label: 'Wet Signature Image',
			    value: getMetaItemValue(this.wetSignatureImages as unknown as IMetaItem[], this.addressbook && this.addressbook.hasOwnProperty('WetSignatureImageId') && this.addressbook.WetSignatureImageId !== null ? this.addressbook.WetSignatureImageId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };

    }
}
