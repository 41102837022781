import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';

import {
    PhysicianVacationDynamicControls,
    IPhysicianVacationDynamicControlsParameters,
} from '@model/form-controls/physician-vacation.form-controls';
import { IPhysicianVacation } from '@model/interfaces/physician-vacation';
import { IUser } from '@model/interfaces/user';
import { IPhysician } from '@model/interfaces/physician';

export class PhysicianVacationDynamicConfig<T extends IPhysicianVacation> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private physicianVacation: T, private physicians?: IPhysician[], private configControls?: string[]) {
        super();
        const additionalParams: IPhysicianVacationDynamicControlsParameters = {
            physicians: this.physicians,
        };
        const dynamicControls = new PhysicianVacationDynamicControls(this.physicianVacation, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['FromDate', 'ToDate'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
