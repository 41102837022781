import { Component, Input, EventEmitter, OnInit, Output, SimpleChanges, SimpleChange, ViewChild, OnChanges } from '@angular/core';
import { IUserSearchFilter } from '@model/interfaces/user-search-filter';
import { SearchParams } from '@mt-ng2/common-classes';
import { CommonSearchService, ICommonSearch } from '../services/common-search.service';
import { ActivatedRoute } from '@angular/router';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { forkJoin } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';

@Component({
    selector: 'app-saved-search-filters',
    styleUrls: ['./saved-search-filters.component.less'],
    templateUrl: './saved-search-filters.component.html',
})
export class SavedSearchFiltersComponent implements OnInit, OnChanges {
    // Variables for saving/searching
    @Input() userId: number;
    @Input() filter: SearchParams;
    @Input() searchChanged: boolean;
    @Output() searchSelected: EventEmitter<boolean> = new EventEmitter();

    route: string;
    filterName = '';
    selectedSearchName = 'Select a saved search';

    doubleClickIsDisabled = false;

    // Items for dropdown menu
    savedSearches: IUserSearchFilter[];

    constructor(
        private commonSearchSearchService: CommonSearchService,
        private activatedRoute: ActivatedRoute,
        private notificationsService: NotificationsService,
    ) {}

    ngOnInit(): void {
        this.activatedRoute.url
            .pipe(
                switchMap((path) => {
                    this.route = path.length && path[path.length - 1].path ? path[path.length - 1].path : 'address-books'; // Get searches based on last part of route or address books if route is empty
                    return forkJoin(
                        this.commonSearchSearchService.getSavedSearchFilters(this.route, this.userId),
                        this.commonSearchSearchService.getCommonSearchFilters(),
                    );
                }),
            )
            .subscribe(([searches, commonFilters]) => {
                this.savedSearches = searches;
                if (commonFilters && commonFilters.savedSearchName) {
                    this.selectedSearchName = commonFilters.savedSearchName;
                } else {
                    this.resetButtonText();
                }
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        const searchChanged: SimpleChange = changes.searchChanged;
        if (searchChanged && searchChanged.currentValue !== undefined) {
            this.resetButtonText();
        }
    }

    resetButtonText(): void {
        if (!this.savedSearches || !this.savedSearches.length) {
            this.selectedSearchName = 'No saved searches available';
        } else {
            this.selectedSearchName = 'Select a saved search';
        }
    }

    save(): void {
        if (!this.filterName) {
            this.notificationsService.error('A name must be provided to save a search.');
            setTimeout(() => (this.doubleClickIsDisabled = false));
        } else {
            const searchFilter: IUserSearchFilter = {
                Filter: JSON.stringify(this.filter),
                FilterName: this.filterName,
                Id: 0,
                PageRoute: this.route,
                SearchFilterUserId: this.userId,
            };
            this.commonSearchSearchService.create(searchFilter).subscribe(
                (id) => {
                    searchFilter.Id = id;
                    this.selectedSearchName = this.filterName;
                    this.notificationsService.success('Search saved successfully.');
                    this.savedSearches.push(searchFilter);
                    this.filterName = '';
                },
                () => this.notificationsService.error('Save failed.  Please check the form and try again.'),
            );
        }
    }

    truncateSearchFilterName(filterName: string): string {
        return filterName.length > 40 ? filterName.substring(0, 40) + '...' : filterName;
    }

    deleteSavedSearch(search: IUserSearchFilter): void {
        const searchId = search.Id;
        this.commonSearchSearchService.deleteSearch(searchId).subscribe(
            () => {
                this.notificationsService.success('Search deleted successfully.');
                this.savedSearches = this.savedSearches.filter((search) => search.Id !== searchId);
                this.resetButtonText();
                this.searchSelected.emit();
            },
            () => this.notificationsService.error('Search could not be deleted. Please try again.'),
        );
    }

    selectServiceAndEmit(search: IUserSearchFilter): void {
        this.selectedSearchName = this.truncateSearchFilterName(search.FilterName);
        const parsedParams = <SearchParams>JSON.parse(search.Filter);
        const searchFilter: ICommonSearch = {
            savedSearchName: search.FilterName,
            searchFilters: parsedParams,
        };
        this.commonSearchSearchService.setCommonSearchFilters(searchFilter);
        this.searchSelected.emit();
    }
}
