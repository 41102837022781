import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';
import { IFollowupType } from '@model/interfaces/followup-type';
import { IImedClaimFollowup } from '@model/interfaces/imed-claim-followup';
import { IImedClaimFollowupDynamicControlsParameters } from '@model/form-controls/imed-claim-followup.form-controls';
import { ImedClaimFollowupDynamicControlsParametersPartial, ImedClaimFollowupDynamicControlsPartial } from '@model/partials/imed-claim-followup-partial.form-controls';
import { IMasterFollowupType } from '@model/interfaces/master-followup-type';
import { MultiselectItem } from '@mt-ng2/multiselect-control';
import { MetaItem } from '@mt-ng2/base-service';

export class ImedClaimFollowupDynamicConfig<T extends IImedClaimFollowup> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private imedClaimFollowup: T, private masterFollowupTypes?: IMasterFollowupType[],private services?: MetaItem[], private aditionalBTPs?: MetaItem[], private configControls?: string[]) {
        super();
        const additionalParams: ImedClaimFollowupDynamicControlsParametersPartial = {
            masterFollowupTypes: this.masterFollowupTypes,
            services: this.services,
            additionalBTPs: this.aditionalBTPs,
        };
        const dynamicControls = new ImedClaimFollowupDynamicControlsPartial(this.imedClaimFollowup, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['Notes'];
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
