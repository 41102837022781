import { IIndividual } from '@model/interfaces/individual.d';
import {
    EntityListConfig,
    SortDirection,
    EntityListColumn,
    IEntityListColumn,
    IEntityListConfig,
    EntityListDeleteColumn,
} from '@mt-ng2/entity-list-module';

export class IndividualsEntityListConfig extends EntityListConfig {
    columns: IEntityListColumn[];

    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['FirstName'],
                    name: 'First Name',
                }),
                new EntityListColumn({
                    accessors: ['LastName'],
                    name: 'Last Name',
                }),
                new EntityListColumn({
                    name: 'Title',
                }),
                new EntityListColumn({
                    name: 'Suffix',
                }),
            ],
            delete: new EntityListDeleteColumn({
                columnHtml: (entity: IIndividual) =>
                    entity.Archived
                        ? '<div><i class="fa fa-2x fa-trash" aria-hidden="true"></i><i class="fa fa-2x fa-undo overlay-fa" data-fa-transform="shrink-8 down-1"></i></div>'
                        : '<div><i class="fa fa-2x fa-trash" aria-hidden="true"></i><i class="fa fa-2x fa-undo overlay-fa-invisible" data-fa-transform="shrink-8 down-1"></i></div>',
                confirm: {
                    confirmButtonText: 'Yes, I am sure!',
                    text: 'Are you sure you want to modify this record?',
                    title: 'Archive Status',
                },
                headerText: 'Archive',
            }),
            rowClass: (entity: IIndividual) => (entity.Archived ? 'archived' : null),
        };
        super(listConfig);
    }
}
