import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MetaItemService } from '@mt-ng2/base-service';
import { ITaskTemplateDueDateType } from '@model/interfaces/task-template-due-date-type';

@Injectable({
    providedIn: 'root'
})
export class TaskTemplateDueDateTypeService extends MetaItemService<ITaskTemplateDueDateType> {
    constructor(public http: HttpClient) {
        super('TaskTemplateDueDateTypes', 'TaskTemplateDueDateType', 'Id', '/task-template-due-date-types', http);
    }
}
