import { IImeAppointment } from '@model/interfaces/ime-appointment.d';
import { ImeAppointmentEntityListConfig } from './ime-appointments.entity-list-config';
import { entityListModuleConfig } from './../../../../../common/shared.module';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import 'rxjs/operators';
import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { IColumnSortedEvent, SortDirection, IItemDeletedEvent } from '@mt-ng2/entity-list-module';
import { ImeAppointmentService } from './ime-appointment.service';
import { AuthService } from '@mt-ng2/auth-module';

@Component({
    selector: 'app-ime-appointments',
    templateUrl: './ime-appointments.component.html',
})
export class ImeAppointmentsComponent implements OnInit {
    searchControl = new UntypedFormControl();
    appointments: IImeAppointment[];
    currentPage = 1;
    query = '';
    total: number;
    entityListConfig = new ImeAppointmentEntityListConfig();
    order = 'Id'; // this.entityListConfig.getDefaultSortProperty();
    orderDirection = 'desc';
    id: string;
    imedClaimId: number;
    serviceId: number;
    includeArchived = false;

    constructor(
        private imeAppointmentService: ImeAppointmentService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
    ) { }

    ngOnInit(): void {
        this.imedClaimId = this.activatedRoute.parent.parent.snapshot.params.imedClaimId;
        this.serviceId = this.activatedRoute.parent.snapshot.params.serviceId;
        this.getAppointments();
    }

    getAppointments(): void {
        const search = this.query;
        const selectedSourceIds: number[] = [];

        const _extraSearchParams: ExtraSearchParams[] = [];
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'includeArchived',
                value: this.includeArchived.toString(),
            }),
        );

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: entityListModuleConfig.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);

        this.imeAppointmentService.searchAppointmentLogs(this.serviceId, searchparams).subscribe((answer) => {
            this.appointments = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.query = query;
        this.currentPage = 1;
        this.getAppointments();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getAppointments();
    }

    cancelClick(): void {
        void this.router.navigate([`cases/${this.imedClaimId}/services/${this.serviceId}`]);
    }

    onItemDeleted(event: IItemDeletedEvent): void {
        const imeAppointment: IImeAppointment = event.entity;
        this.imeAppointmentService.archive(imeAppointment.Id, this.authService.currentUser.getValue().Id).subscribe(() => {
            this.getAppointments();
        });
    }
}
