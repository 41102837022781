import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { IMasterFollowupTypeServiceStatus } from '@model/interfaces/master-followup-type-service-status';

export const emptyFollowupStatusAssociation: IMasterFollowupTypeServiceStatus = {
    Id: 0,
    MasterFollowupTypeId: null,
    MasterServiceStatusId: null,
    ServiceId: null,
};

@Injectable()
export class MasterFollowupTypeServiceStatusService extends BaseService<IMasterFollowupTypeServiceStatus> {
    constructor(public http: HttpClient) {
        super('/masterfollowuptypeservicestatuses', http);
    }

    getEmptyServiceFollowupAssociation(): IMasterFollowupTypeServiceStatus {
        return emptyFollowupStatusAssociation;
    }
}
