import { IPhoneType } from '@model/interfaces/phone-type.d';
import { IPhoneNumberDynamicControlsParameters, PhoneNumberDynamicControls } from '@model/form-controls/phone-number.form-controls';
import { IPhoneNumber } from '@model/interfaces/phone-number.d';
import { DynamicConfig, IDynamicConfig, IDynamicFormConfig, DynamicField, DynamicFieldType, InputTypes } from '@mt-ng2/dynamic-form';

export const emptyPhoneNumber: IPhoneNumber = {
    Archived: false,
    CreatedById: 0,
    DateCreated: null,
    DateModified: null,
    Extension: null,
    Id: 0,
    IsPrimary: null,
    ModifiedById: null,
    Phone: null,
    PhoneTypeId: 0,
};

export class PhoneNumberDynamicConfig<T extends IPhoneNumber> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private phoneNumber: T, private phoneTypes?: IPhoneType[], private configControls?: string[]) {
        super();
        const additionalParams: IPhoneNumberDynamicControlsParameters = {
            phoneTypes: this.phoneTypes,
        };
        const dynamicControls = new PhoneNumberDynamicControls(this.phoneNumber, additionalParams);
        (dynamicControls.Form.Phone as DynamicField).type.inputType = InputTypes.Phone;
        (dynamicControls.Form.Phone as DynamicField).setMaxLength(null);
        (dynamicControls.Form.Phone as DynamicField).setMinLength(10);
        if (!configControls) {
            this.configControls = ['PhoneTypeId', 'Phone', 'Extension', 'IsPrimary'];
        }

        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
