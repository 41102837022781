import { IAppFaxEmailComponentApi } from '../../../../../common/fax-email/fax-email-component';
import { CurrencyFormatterService } from '../../../../../common/services/currency-formatter.service';
import {
    SubServiceEnums,
    ServiceStatusIdEnums,
    AttachmentTypeEnums,
    AttachmentServiceTypeEnums,
    ServiceIdEnums,
    FaxEmailEntityTypeEnums,
} from '../../../../../common/constants/Enums';
import { CommonImedClaimService } from '../../../../shared/common-imed-claim-service';
import { SubServiceService } from '../../../common-components/sub-services/sub-service.service';
import { ImeAppointmentService } from '../../ime-service/ime-appointments/ime-appointment.service';
import { ImedClaimServiceFollowupService } from '../../../common-components/service-followups/imedclaimservicefollowup.service';
import { ImedClaimServiceStatusLogService } from '../../../common-components/service-status/imed-claim-service-status-log.service';
import { ImedClaimService } from '../../../../imedclaim.service';
import { IRecordRetrievalService } from '@model/interfaces/record-retrieval-service.d';
import { RecordRetrievalServiceClientService } from '../record-retrieval-service-client.service';
import { AddressBooksSelectService } from '../../../../../common/address-book-select-list/address-books-select.service';
import { CommunicationLogService } from '../../../../../common/services/communication-log.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { ImedClaimServiceService } from '../../../imedclaimservice.service';
import { PhysicianService } from '../../../../../address-books/individuals/physicians/physician.service';
import { ServiceStatusService } from '../../../common-components/service-status/servicestatus.service';
import { BaseAttachmentsService } from '../../../../../common/attachments/base-attachments.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { tap } from 'rxjs/operators';
import { IFilmInformationDTO } from '@model/interfaces/custom/film-information-dto';
import { ModalService } from '../../../../../common/services/modal.service';
import { AuthService, ClaimsService } from '@mt-ng2/auth-module';
import { IAttachmentDTO } from '@model/interfaces/custom/attachment-dto';
import { FilmInformationService } from '@app-shared/services/film-information.service';
import { FaxEmailService } from '@common/fax-email/fax-email.service';
import { IFilmRetrievalExtrasDTO } from '@model/interfaces/custom/film-retrieval-extras-dto';
import { IDocumentGenerationDTO } from '@model/interfaces/custom/document-generation-dto';
import { HttpResponse } from '@angular/common/http';
import { IName, NameTypeEnum } from '@model/interfaces/custom/name';

@Component({
    selector: 'app-record-retrieval-service-client-detail',
    templateUrl: './record-retrieval-service-client-detail.component.html',
})
export class RecordRetrievalServiceDetailClientComponent extends CommonImedClaimService implements OnInit, OnDestroy {
    recordRetrievalServiceModel: IRecordRetrievalService;
    filmInformations: IFilmInformationDTO[];
    isAuhorizationDocumentUploaded: boolean;
    isPhysicianOrFacilitySelected = false;

    faxEmailComponentApi: IAppFaxEmailComponentApi;
    rejectionFaxEmailComponentApi: IAppFaxEmailComponentApi;
    complexFaxEmailComponentApi: IAppFaxEmailComponentApi;
    appLabelFaxEmailComponentApi: IAppFaxEmailComponentApi;
    noRecordOnFileFaxEmailComponentApi: IAppFaxEmailComponentApi;
    undeliverableRecordsFaxEmailComponentApi: IAppFaxEmailComponentApi;
    additionalRecordsFaxEmailComponentApi: IAppFaxEmailComponentApi;

    attachmentServiceTypeEnum: AttachmentServiceTypeEnums = AttachmentServiceTypeEnums.Record_Retrieval;
    entityTypeEnums = FaxEmailEntityTypeEnums;

    filmRetrievalExtras: IFilmRetrievalExtrasDTO = { recordsType: '', notes: '' };

    documentArray: IAttachmentDTO[] = [];

    constructor(
        private communicationLogService: CommunicationLogService,
        private router: Router,
        private route: ActivatedRoute,
        private imedclaimServiceStausLogService: ImedClaimServiceStatusLogService,
        private imedClaimServiceFollowupService: ImedClaimServiceFollowupService,
        private imedClaimServiceService: ImedClaimServiceService,
        private addressBookSelectService: AddressBooksSelectService,
        private currencyFormatterService: CurrencyFormatterService,
        private physicianService: PhysicianService,
        private imeAppointmentService: ImeAppointmentService,
        private subServiceService: SubServiceService,
        private imedClaimService: ImedClaimService,
        private serviceStatusService: ServiceStatusService,
        private recordRetrievalServiceService: RecordRetrievalServiceClientService,
        private filmInformationService: FilmInformationService,
        private attachmentsService: BaseAttachmentsService,
        private notificationsService: NotificationsService,
        private modalService: ModalService,
        private claimService: ClaimsService,
        private authService: AuthService,
        protected faxEmailService: FaxEmailService,
    ) {
        super(
            communicationLogService,
            router,
            route,
            imedclaimServiceStausLogService,
            imedClaimServiceFollowupService,
            imedClaimServiceService,
            addressBookSelectService,
            currencyFormatterService,
            physicianService,
            imeAppointmentService,
            subServiceService,
            imedClaimService,
            serviceStatusService,
            attachmentsService,
            modalService,
            claimService,
            authService,
        );
    }

    ngOnInit(): void {
        this.subscriptions.add(
            this.imedClaimServiceService.changeEmitted$.subscribe((imedClaimService) => {
                this.imedClaimServiceModel = imedClaimService;
                this.getIsAuthorizationDocumentUploaded().subscribe();
                this.setIsPhysicianOrFacilitySelected();
                if (this.imedClaimServiceModel.ImedClaimSubServices.some((s) => s.SubServiceId === +SubServiceEnums.Radiology_Retrieval)) {
                    this.getRecordRetrievalServiceByImedClaimServiceId().subscribe();
                }
            }),
        );

        forkJoin(
            this.getImedClaimServiceStatusLog(),
            this.getCommunicationLog(),
            this.getFollowups(),
            this.getSubServices(),
            this.getServiceStatuses(),
            this.getPrimaryEmailForImedClaimDefenseAttorney(),
            this.getLatestAppointmentByImedClaimServiceId(),
            this.getRecordRetrievalServiceByImedClaimServiceId(),
            this.getFilmInformations(),
            this.getDocuments(),
            this.getIsAuthorizationDocumentUploaded(),
        ).subscribe(() => {
            this.subscribeToCommunicationLogs();
            this.setPhysicianInformations();
            this.setIsPhysicianOrFacilitySelected(); // 1 - 1 there is not going to be more than 1 ImeServices_ImedClaimServiceId records
            this.setFormRendered();
        });

        this.editingComponent.next('');
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    getDocuments(): Observable<HttpResponse<IAttachmentDTO[]>> {
        return this.attachmentsService.all('imed-claim-service', this.imedClaimServiceModel.Id, '').pipe(
            tap((response) => {
                this.documentArray = response.body;
            }),
        );
    }

    getClaimantName(): IName {
        return { firstName: this.imedClaimServiceModel.ImedClaim.FirstName, lastName: this.imedClaimServiceModel.ImedClaim.LastName, nameType: NameTypeEnum.Individual };
    }

    getIsAuthorizationDocumentUploaded(): Observable<any> {
        return this.imedClaimServiceService
            .checkForAuthorizationDocument(this.imedClaimServiceModel.Id)
            .pipe(tap((response) => (this.isAuhorizationDocumentUploaded = response)));
    }

    getRecordRetrievalServiceByImedClaimServiceId(): Observable<IRecordRetrievalService> {
        return this.recordRetrievalServiceService
            .getByImedClaimServiceId(this.imedClaimServiceModel.Id)
            .pipe(tap((recordRetrievalService: IRecordRetrievalService) => (this.recordRetrievalServiceModel = recordRetrievalService)));
    }

    rebindFilmInformation(): void {
        this.getFilmInformations().subscribe();
    }

    getFilmInformations(): Observable<IFilmInformationDTO[]> {
        return this.filmInformationService
            .getByImedClaimServiceId(this.imedClaimServiceId, ServiceIdEnums.Radiology_Received_From_Client)
            .pipe(tap((filmInformations) => (this.filmInformations = filmInformations)));
    }

    error(errorMessage: string): void {
        this.notificationsService.error(errorMessage);
    }

    success(): void {
        this.notificationsService.success('Fax sent Successfully');
    }

    otherAttachmentChanged(attachmentTypeId?: any): void {
        super.otherAttachmentChanged(attachmentTypeId);
        if (attachmentTypeId === AttachmentTypeEnums.Records_Retrieved) {
            this.showRecordRetrievedFaxDialog();
        } else if (attachmentTypeId === AttachmentTypeEnums.Rejection) {
            this.rejectionFaxEmailComponentApi.show();
        }
    }

    attachmentChanged(value: number): void {
        super.attachmentChanged(value);
        if (value === +AttachmentTypeEnums.Records_Retrieved) {
            this.generateAndUploadRetrievalRelatedCover('records-retrieval-simple');
        } else if (value === +AttachmentTypeEnums.Rejection) {
            this.generateAndUploadRetrievalRelatedCover('records-rejection-letter');
        } else if (value === +AttachmentTypeEnums.No_Records_On_File) {
            this.generateAndUploadRetrievalRelatedCover('no-records-on-file');
        } else if (value === +AttachmentTypeEnums.Undeliverable) {
            this.generateAndUploadRetrievalRelatedCover('undeliverable-records');
        } else if (value === +AttachmentTypeEnums.Addl_Records_Retrieved) {
            this.generateAndUploadRetrievalRelatedCover('additional-records-retrieved');
        }
    }

    generateAndUploadRetrievalRelatedCover(documentType: string): void {
        this.faxEmailService.generateDocuments(documentType, this.createDocDTO()).subscribe(() => this.attachmentsService.requestRefresh());
    }

    createDocDTO(): IDocumentGenerationDTO {
        return { EntityId: this.imedClaimServiceModel.Id, Extras: null };
    }

    emitStatusChange(): void {
        this.imedClaimServiceService.update(this.imedClaimServiceModel).subscribe(() => {
            this.imedClaimServiceService.emitChange(this.imedClaimServiceModel);
        });
    }

    showComplexRecordRetrievalPackageDialog(): void {
        this.complexFaxEmailComponentApi.show();
    }

    faxComplexRecordRetrievalPackage(): void {
        this.imedClaimServiceModel.ServiceStatusId = ServiceStatusIdEnums.RadiologyReceivedFromClient_Pending_Bill_From_Facility;
        this.emitStatusChange();
    }

    showRecordRetrievedFaxDialog(): void {
        this.faxEmailComponentApi.show();
    }

    setIsPhysicianOrFacilitySelected(): void {
        this.isPhysicianOrFacilitySelected =
            this.imedClaimServiceModel && (this.imedClaimServiceModel.PhysicianId > 0 || this.imedClaimServiceModel.FacilityAddressBookId > 0);
    }

    updateRecordRetrievalStatusForSimpleFaxOrEmail(): void {
        const BillTypeAttachments = this.documentArray.filter((item) => {
            return item.AttachmentTypeId === +AttachmentTypeEnums.Bill_Physician_Expert;
        });
        if (!BillTypeAttachments.length) {
            this.imedClaimServiceModel.ServiceStatusId =
                ServiceStatusIdEnums.RadiologyReceivedFromClient_Medical_Records_Received_Pending_Submission_To_Client;
        }
        this.emitStatusChange();
    }

    faxSimpleRecordRetrievalPackage(): void {
        this.emitStatusChange();
    }

    faxRejectionRecordRetrievalPackage(): void {
        this.emitStatusChange();
    }

    showFilmRetrievalLabelFaxEmailDialog(): void {
        this.appLabelFaxEmailComponentApi.show();
    }

    printSimpleRecordRetrievalPackage(): void {
        this.updateRecordRetrievalStatusForSimpleFaxOrEmail();
    }

    refreshDocuments(): void {
        this.attachmentsService.requestRefresh();
        this.getDocuments().subscribe(() => this.setDisablePhysicianFacilityControl());
    }
}
