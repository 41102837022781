import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IPhysicianFollowupLog } from '@model/interfaces/physician-followup-log';
import { BaseService } from '../../../../../node_modules/@mt-ng2/base-service';
import { SearchParams } from '../../../../../node_modules/@mt-ng2/common-classes';
import { IPhysicianStatusLog } from '@model/interfaces/physician-status-log';
import { Observable } from '../../../../../node_modules/rxjs';
import { IPhysicianStatusLogPartial } from '@model/partials/physician-status-log-partial';

export const emptyPhysicianStatusLog: IPhysicianStatusLogPartial = {
    ChangedById: 0,
    ChangedDate: new Date(),
    Id: 0,
    PhysicianDetailId: 0,
    PhysicianStatus: null,
    PhysicianStatusId: 0,
    User: null,
};

@Injectable()
export class PhysicianStatusLogService extends BaseService<IPhysicianStatusLogPartial> {
    constructor(public http: HttpClient) {
        super('/physicianstatuslogs', http);
    }

    searchStatuses(individualId: number, data: SearchParams): any {
        const params = this.getHttpParams(data);
        return this.http.get<IPhysicianStatusLogPartial>(`/physicianstatuslogs/${individualId}/_search`, { observe: 'response', params: params });
    }

    getByIndividualId(individualId: number): Observable<IPhysicianStatusLogPartial[]> {
        return this.http.get<IPhysicianStatusLogPartial[]>(`/physicianstatuslogs/getByIndividualId/${individualId}`);
    }
}
