import { Component, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TaskTemplateService } from '../services/tasktemplate.service';
import { ITaskTemplate } from '@model/interfaces/task-template';
import { TaskTemplateTypes } from '@common/constants/Enums';

@Component({
    selector: 'app-system-task-basic-info',
    templateUrl: './system-task-basic-info.component.html',
})
export class SystemTaskBasicInfoComponent {
    @Input('task') task: ITaskTemplate;
    taskTemplateTypes = TaskTemplateTypes;

    isEditing = false;

    constructor(private router: Router, private taskTemplateService: TaskTemplateService) {}

    copyTaskTemplate(): void {
        this.taskTemplateService.copiedTask = this.task;
        void this.router.navigate(['system-tasks', 'add']);
    }

    edit(): void {
        this.taskTemplateService.clearCopiedTask();
        this.taskTemplateService.selectedTask = this.task;
        this.isEditing = true;
    }

    toggleEdit() {
        this.isEditing = !this.isEditing;
    }
}
