import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { IExpandableObject } from '../expandable-object';
import { IAutomatedTemplate } from '../interfaces/automated-template';

export interface IAutomatedTemplateDynamicControlsParameters {
    formGroup?: string;
}

export class AutomatedTemplateDynamicControls {

    formGroup: string;

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private automatedtemplate?: IAutomatedTemplate, additionalParameters?: IAutomatedTemplateDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'AutomatedTemplate';

        this.Form = {
            Archived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived',
                name: 'Archived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.automatedtemplate && this.automatedtemplate.hasOwnProperty('Archived') && this.automatedtemplate.Archived !== null ? this.automatedtemplate.Archived : false,
            }),
            Auto: new DynamicField({
                formGroup: this.formGroup,
                label: 'Auto',
                name: 'Auto',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.automatedtemplate && this.automatedtemplate.hasOwnProperty('Auto') && this.automatedtemplate.Auto !== null ? this.automatedtemplate.Auto : false,
            }),
            CreatedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Created By',
                name: 'CreatedById',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.automatedtemplate && this.automatedtemplate.CreatedById || null,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.automatedtemplate && this.automatedtemplate.DateCreated || null,
            }),
            GeneralLiability: new DynamicField({
                formGroup: this.formGroup,
                label: 'General Liability',
                name: 'GeneralLiability',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.automatedtemplate && this.automatedtemplate.hasOwnProperty('GeneralLiability') && this.automatedtemplate.GeneralLiability !== null ? this.automatedtemplate.GeneralLiability : false,
            }),
            IsForAllStates: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is For All States',
                name: 'IsForAllStates',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.automatedtemplate && this.automatedtemplate.hasOwnProperty('IsForAllStates') && this.automatedtemplate.IsForAllStates !== null ? this.automatedtemplate.IsForAllStates : false,
            }),
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.automatedtemplate && this.automatedtemplate.hasOwnProperty('Name') && this.automatedtemplate.Name !== null ? this.automatedtemplate.Name.toString() : '',
            }),
            OverrideMargins: new DynamicField({
                formGroup: this.formGroup,
                label: 'Override Margins',
                name: 'OverrideMargins',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.automatedtemplate && this.automatedtemplate.hasOwnProperty('OverrideMargins') && this.automatedtemplate.OverrideMargins !== null ? this.automatedtemplate.OverrideMargins : false,
            }),
            Template: new DynamicField({
                formGroup: this.formGroup,
                label: 'Template',
                name: 'Template',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.WYSIWYG,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.automatedtemplate && this.automatedtemplate.hasOwnProperty('Template') && this.automatedtemplate.Template !== null ? this.automatedtemplate.Template.toString() : '',
            }),
            WorkersComp: new DynamicField({
                formGroup: this.formGroup,
                label: 'Workers Comp',
                name: 'WorkersComp',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.automatedtemplate && this.automatedtemplate.hasOwnProperty('WorkersComp') && this.automatedtemplate.WorkersComp !== null ? this.automatedtemplate.WorkersComp : false,
            }),
        };

        this.View = {
            Archived: new DynamicLabel({
			    label: 'Archived',
			    value: this.automatedtemplate && this.automatedtemplate.hasOwnProperty('Archived') && this.automatedtemplate.Archived !== null ? this.automatedtemplate.Archived : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Auto: new DynamicLabel({
			    label: 'Auto',
			    value: this.automatedtemplate && this.automatedtemplate.hasOwnProperty('Auto') && this.automatedtemplate.Auto !== null ? this.automatedtemplate.Auto : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            CreatedById: new DynamicLabel({
			    label: 'Created By',
			    value: this.automatedtemplate && this.automatedtemplate.CreatedById || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: NumericInputTypes.Integer,
			        scale: null,
			    })
			}
            ),
            DateCreated: new DynamicLabel({
			    label: 'Date Created',
			    value: this.automatedtemplate && this.automatedtemplate.DateCreated || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            GeneralLiability: new DynamicLabel({
			    label: 'General Liability',
			    value: this.automatedtemplate && this.automatedtemplate.hasOwnProperty('GeneralLiability') && this.automatedtemplate.GeneralLiability !== null ? this.automatedtemplate.GeneralLiability : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            IsForAllStates: new DynamicLabel({
			    label: 'Is For All States',
			    value: this.automatedtemplate && this.automatedtemplate.hasOwnProperty('IsForAllStates') && this.automatedtemplate.IsForAllStates !== null ? this.automatedtemplate.IsForAllStates : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Name: new DynamicLabel({
			    label: 'Name',
			    value: this.automatedtemplate && this.automatedtemplate.hasOwnProperty('Name') && this.automatedtemplate.Name !== null ? this.automatedtemplate.Name.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            OverrideMargins: new DynamicLabel({
			    label: 'Override Margins',
			    value: this.automatedtemplate && this.automatedtemplate.hasOwnProperty('OverrideMargins') && this.automatedtemplate.OverrideMargins !== null ? this.automatedtemplate.OverrideMargins : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Template: new DynamicLabel({
			    label: 'Template',
			    value: this.automatedtemplate && this.automatedtemplate.hasOwnProperty('Template') && this.automatedtemplate.Template !== null ? this.automatedtemplate.Template.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.WYSIWYG,
			        scale: null,
			    })
			}
            ),
            WorkersComp: new DynamicLabel({
			    label: 'Workers Comp',
			    value: this.automatedtemplate && this.automatedtemplate.hasOwnProperty('WorkersComp') && this.automatedtemplate.WorkersComp !== null ? this.automatedtemplate.WorkersComp : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };

    }
}
