import { SpecialityService } from './speciality.service';
import { SpecialityDetailService } from './speciality-detail.service';
import { SpecialtyDynamicControls } from '@model/form-controls/specialty.form-controls';
import { ImageTypeDynamicControls } from '@model/form-controls/image-type.form-controls';
import { BodyPartDynamicControls } from '@model/form-controls/body-part.form-controls';
import { IManagedList } from '@model/interfaces/custom/managed-list';
import { Component, OnInit } from '@angular/core';
import { ImageTypeService } from './image-type.service';
import { BodyPartService } from './body-part.service';
import { LanguageDynamicControls } from '@model/form-controls/language.form-controls';
import { LanguageService } from './language.service';

export const ManagedList: IManagedList[] = [
    {
        DynamicConfig: 'SpecialtyDynamicControls',
        Id: 1,
        Name: 'Specialty',
        Service: 'Speciality',
    },
    {
        DynamicConfig: 'SpecialtyDetailDynamicControls',
        Id: 2,
        Name: 'Specialty Details',
        Service: 'SpecialityDetail',
    },
    {
        DynamicConfig: 'ImageTypeDynamicControls',
        Id: 3,
        Name: 'Image Types',
        Service: 'ImageType',
    },
    {
        DynamicConfig: 'BodyPartDynamicControls',
        Id: 4,
        Name: 'Body Parts',
        Service: 'BodyPart',
    },
    {
        DynamicConfig: 'LanguageDynamicControls',
        Id: 5,
        Name: 'Languages',
        Service: 'Language',
    },
];

@Component({
    selector: 'imed-managed-list.component',
    templateUrl: './imed-managed-list.component.html',
})
export class ImedManagedListComponent implements OnInit {
    specialtydynamicForm = new SpecialtyDynamicControls(null).Form;
    specialtyDetaildynamicForm = new SpecialtyDynamicControls(null).Form;
    imageTypeDynamicControls = new ImageTypeDynamicControls(null).Form;
    bodyPartDynamicControls = new BodyPartDynamicControls(null).Form;
    languageDynamicControls = new LanguageDynamicControls(null).Form;
    selectedManagedList: IManagedList;
    managedList = [];
    isLoaded = false;

    constructor(
        public specialityService: SpecialityService,
        public specialityDetailService: SpecialityDetailService,
        public imageTypeService: ImageTypeService,
        public bodyPartService: BodyPartService,
        public languageService: LanguageService,
    ) { }

    ngOnInit(): void {
        this.managedList.push(...ManagedList);
        this.isLoaded = true;
    }
}
