import { Nf10ServicesDynamicControls, INf10ServicesDynamicControlsParameters } from '../form-controls/nf10-services.form-controls';
import { INf10Services } from '../interfaces/nf10-services';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, DynamicLabel, NumericInputTypes } from '@mt-ng2/dynamic-form';

export class Nf10ServicesDynamicControlsPartial extends Nf10ServicesDynamicControls {
    constructor(nf10servicesPartial?: INf10Services, additionalParameters?: INf10ServicesDynamicControlsParameters) {
        super(nf10servicesPartial, additionalParameters);

        // Form changes
        (<DynamicField>this.Form.PolicyHolder).labelHtml = '<label>POLICY HOLDER</label>';
        (<DynamicField>this.Form.PolicyNumber).labelHtml = '<label>POLICY NUMBER</label>';
        (<DynamicField>this.Form.AsAssignee).labelHtml = '<label>As Assignee</label>';
        (<DynamicField>this.Form.IsEntireClaimDenied).labelHtml = '<label>Your entire claim is denied as follows</label>';
        (<DynamicField>this.Form.IsPortionOfClaimDenied).labelHtml = '<label>A portion of your claim is denied as follows</label>';
        (<DynamicField>this.Form.LossOfEarnings).labelHtml = '<label>Loss of Earnings</label>';
        (<DynamicField>this.Form.LossOfEarningsAmount).labelHtml = '<label>Amount</label>';
        (<DynamicField>this.Form.LossOfEarningsAmount).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Numeric,
            inputType: NumericInputTypes.Currency,
            scale: 2,
        });
        (<DynamicField>this.Form.HealthServiceBenefits).labelHtml = '<label>Health Service Benefits</label>';
        (<DynamicField>this.Form.HealthServiceBenefitsAmount).labelHtml = '<label>Amount</label>';
        (<DynamicField>this.Form.HealthServiceBenefitsAmount).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Numeric,
            inputType: NumericInputTypes.Currency,
            scale: 2,
        });
        (<DynamicField>this.Form.OtherNecessaryExpenses).labelHtml = '<label>Other Necessary Expenses</label>';
        (<DynamicField>this.Form.OtherNecessaryExpensesAmount).labelHtml = '<label>Amount</label>';
        (<DynamicField>this.Form.OtherNecessaryExpensesAmount).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Numeric,
            inputType: NumericInputTypes.Currency,
            scale: 2,
        });
        (<DynamicField>this.Form.Interest).labelHtml = '<label>Interest</label>';
        (<DynamicField>this.Form.InterestAmount).labelHtml = '<label>Amount</label>';
        (<DynamicField>this.Form.InterestAmount).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Numeric,
            inputType: NumericInputTypes.Currency,
            scale: 2,
        });
        (<DynamicField>this.Form.Attorneyfee).labelHtml = '<label>Attorney\'s Fee</label>';
        (<DynamicField>this.Form.AttorneyfeeAmount).labelHtml = '<label>Amount</label>';
        (<DynamicField>this.Form.AttorneyfeeAmount).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Numeric,
            inputType: NumericInputTypes.Currency,
            scale: 2,
        });
        (<DynamicField>this.Form.DeathBenefit).labelHtml = '<label>Death Benefit</label>';
        (<DynamicField>this.Form.DeathBenefitAmount).labelHtml = '<label>Amount</label>';
        (<DynamicField>this.Form.DeathBenefitAmount).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Numeric,
            inputType: NumericInputTypes.Currency,
            scale: 2,
        });
        (<DynamicField>this.Form.PolicyNotInForce).labelHtml = '<label>Policy not in force on date of accident</label>';
        (<DynamicField>this.Form.InjuredPersonExcludedUnderPolicy).labelHtml = '<label>Injured person excluded under policy conditions or exclusion</label>';
        (<DynamicField>this.Form.PolicyConditionsViolated).labelHtml = '<label>Policy conditions violated</label>';
        (<DynamicField>this.Form.NoReasonableJustificationGivenForLateNoticeOfClaim).labelHtml =
            '<label>No reasonable justification given for late notice of claim</label>';
        (<DynamicField>this.Form.ReasonableJustificationNotEstablished).labelHtml = '<label>Reasonable justification not established</label>';
        (<DynamicField>this.Form.InjuredPersonNotEligible).labelHtml = '<label>Injured person not an "Eligible Injured Person"</label>';
        (<DynamicField>this.Form.InjuriesDidNotAriseFromMv).labelHtml = '<label>Injuries did not arise out of use or operation of a motor vehicle</label>';
        (<DynamicField>this.Form.ClaimNotWithinScopeOfElection).labelHtml =
            '<label>Claim not within the scope of your election under Optional Basic Economic Loss coverage</label>';
        (<DynamicField>this.Form.PeriodOfDisability).labelHtml = '<label>Period of disability contested</label>';
        (<DynamicField>this.Form.PeriodOfDisabilityFrom).labelHtml = '<label>Period in dispute From</label>';
        (<DynamicField>this.Form.PeriodOfDisabilityTo).labelHtml = '<label>Through</label>';
        (<DynamicField>this.Form.ClaimedLossNotProven).labelHtml = '<label>Claimed loss not proven</label>';
        (<DynamicField>this.Form.ExaggeratedEarningClaimDenied).labelHtml = '<label>Exaggerated earnings claim of $____ per month denied</label>';
        (<DynamicField>this.Form.ExaggeratedEarningClaimPerMonth).labelHtml = '<label>Amount</label>';
        (<DynamicField>this.Form.ExaggeratedEarningClaimPerMonth).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Numeric,
            inputType: NumericInputTypes.Currency,
            scale: 2,
        });
        (<DynamicField>this.Form.StatutoryOffsetTaken).labelHtml = '<label>Statutory offset taken</label>';
        (<DynamicField>this.Form.LossOfEarningsBenefitsDeniedOther).labelHtml = '<label>Other, explained below</label>';
        (<DynamicField>this.Form.AmountOfClaimExceedsDailyLimit).labelHtml = '<label>Amount of claim exceeds daily limit of coverage</label>';
        (<DynamicField>this.Form.UnreasonableOrUnnecessaryExpenses).labelHtml = '<label>Unreasonable or unnecessary expenses</label>';
        (<DynamicField>this.Form.IncurredAfterOneYearFromDateOfAccident).labelHtml = '<label>Incurred after one year from date of accident</label>';
        (<DynamicField>this.Form.OtherReasonableAndNecessaryExpensesDeniedOther).labelHtml = '<label>Other, explained below</label>';
        (<DynamicField>this.Form.FeesNotInAccordanceWithFeeSchedules).labelHtml = '<label>Fees not in accordance with fee schedules</label>';
        (<DynamicField>this.Form.ExcessiveTreatment).labelHtml = '<label>Excessive treatment, service or hospitalization</label>';
        (<DynamicField>this.Form.ExcessiveTreatmentFromDate).labelHtml = '<label>From</label>';
        (<DynamicField>this.Form.ExcessiveTreatmentToDate).labelHtml = '<label>Through</label>';
        (<DynamicField>this.Form.TreatmentNotRelatedToAccident).labelHtml = '<label>Treatment not related to accident</label>';
        (<DynamicField>this.Form.UnnecessaryTreatment).labelHtml = '<label>Unnecessary treatment, service, or hospitalization</label>';
        (<DynamicField>this.Form.UnnecessaryTreatmentFromDate).labelHtml = '<label>From</label>';
        (<DynamicField>this.Form.UnnecessaryTreatmentToDate).labelHtml = '<label>Through</label>';
        (<DynamicField>this.Form.HealthServiceBenefitsDeniedOther).labelHtml = '<label>Other, explained below</label>';
        (<DynamicField>this.Form.PeriodOfBillTreatmentDates).labelHtml = '<label>Period of bill - treatment dates</label>';
        (<DynamicField>this.Form.DateOfBill).labelHtml = '<label>Date of bill</label>';
        (<DynamicField>this.Form.DateBillReceivedByInsurer).labelHtml = '<label>Date bill received by insurer</label>';
        (<DynamicField>this.Form.DateFinalVerificationRequested).labelHtml = '<label>Date final verification requested</label>';
        (<DynamicField>this.Form.DateFinalVerificationReceived).labelHtml = '<label>Date final verification received</label>';
        (<DynamicField>this.Form.AmountOfBill).labelHtml = '<label>Amount of bill</label>';
        (<DynamicField>this.Form.AmountOfBill).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Numeric,
            inputType: NumericInputTypes.Currency,
            scale: 2,
        });
        (<DynamicField>this.Form.AmountPaidByInsurer).labelHtml = '<label>Amount paid by insurer</label>';
        (<DynamicField>this.Form.AmountPaidByInsurer).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Numeric,
            inputType: NumericInputTypes.Currency,
            scale: 2,
        });
        (<DynamicField>this.Form.AmountInDispute).labelHtml = '<label>Amount in dispute</label>';
        (<DynamicField>this.Form.AmountInDispute).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Numeric,
            inputType: NumericInputTypes.Currency,
            scale: 2,
        });
        (<DynamicField>this.Form.Ime).labelHtml = '<label>IME</label>';
        (<DynamicField>this.Form.Ime).disabled = true;
        (<DynamicField>this.Form.Peer).labelHtml = '<label>Peer</label>';
        (<DynamicField>this.Form.Peer).disabled = true;
        (<DynamicField>this.Form.SuggestedExplanationOfDenial).labelHtml = '<label>Suggested Explanation of denial</label>';

        // View changes
        (<DynamicLabel>this.View.PolicyHolder).label = 'POLICY HOLDER';
        (<DynamicLabel>this.View.PolicyNumber).label = 'POLICY NUMBER';
        (<DynamicLabel>this.View.AsAssignee).label = 'As Assignee';
        (<DynamicLabel>this.View.IsEntireClaimDenied).label = 'Your entire claim is denied as follows';
        (<DynamicLabel>this.View.IsPortionOfClaimDenied).label = 'A portion of your claim is denied as follows';
        (<DynamicLabel>this.View.LossOfEarnings).label = 'Loss of Earnings';
        (<DynamicLabel>this.View.LossOfEarningsAmount).label = 'Amount';
        (<DynamicLabel>this.View.HealthServiceBenefits).label = 'Health Service Benefits';
        (<DynamicLabel>this.View.HealthServiceBenefitsAmount).label = 'Amount';
        (<DynamicLabel>this.View.OtherNecessaryExpenses).label = 'Other Necessary Expenses';
        (<DynamicLabel>this.View.OtherNecessaryExpensesAmount).label = 'Amount';
        (<DynamicLabel>this.View.Interest).label = 'Interest';
        (<DynamicLabel>this.View.InterestAmount).label = 'Amount';
        (<DynamicLabel>this.View.Attorneyfee).label = 'Attorney\'s Fee';
        (<DynamicLabel>this.View.AttorneyfeeAmount).label = 'Amount';
        (<DynamicLabel>this.View.DeathBenefit).label = 'Death Benefit';
        (<DynamicLabel>this.View.DeathBenefitAmount).label = 'Amount';
        (<DynamicLabel>this.View.PolicyNotInForce).label = 'Policy not in force on date of accident';
        (<DynamicLabel>this.View.InjuredPersonExcludedUnderPolicy).label = 'Injured person excluded under policy conditions or exclusion';
        (<DynamicLabel>this.View.PolicyConditionsViolated).label = 'Policy conditions violated';
        (<DynamicLabel>this.View.NoReasonableJustificationGivenForLateNoticeOfClaim).label =
            'No reasonable justification given for late notice of claim';
        (<DynamicLabel>this.View.ReasonableJustificationNotEstablished).label = 'Reasonable justification not established';
        (<DynamicLabel>this.View.InjuredPersonNotEligible).label = 'Injured person not an "Eligible Injured Person"';
        (<DynamicLabel>this.View.InjuriesDidNotAriseFromMv).label = 'Injuries did not arise out of use or operation of a motor vehicle';
        (<DynamicLabel>this.View.ClaimNotWithinScopeOfElection).label =
            'Claim not within the scope of your election under Optional Basic Economic Loss coverage';
        (<DynamicLabel>this.View.PeriodOfDisability).label = 'Period of disability contested';
        (<DynamicLabel>this.View.PeriodOfDisabilityFrom).label = 'Period in dispute From';
        (<DynamicLabel>this.View.PeriodOfDisabilityTo).label = 'Through';
        (<DynamicLabel>this.View.ClaimedLossNotProven).label = 'Claimed loss not proven';
        (<DynamicLabel>this.View.ExaggeratedEarningClaimDenied).label = 'Exaggerated earnings claim of $____ per month denied';
        (<DynamicLabel>this.View.ExaggeratedEarningClaimPerMonth).label = 'Amount';
        (<DynamicLabel>this.View.StatutoryOffsetTaken).label = 'Statutory offset taken';
        (<DynamicLabel>this.View.LossOfEarningsBenefitsDeniedOther).label = 'Other, explained below';
        (<DynamicLabel>this.View.AmountOfClaimExceedsDailyLimit).label = 'Amount of claim exceeds daily limit of coverage';
        (<DynamicLabel>this.View.UnreasonableOrUnnecessaryExpenses).label = 'Unreasonable or unnecessary expenses';
        (<DynamicLabel>this.View.IncurredAfterOneYearFromDateOfAccident).label = 'Incurred after one year from date of accident';
        (<DynamicLabel>this.View.OtherReasonableAndNecessaryExpensesDeniedOther).label = 'Other, explained below';
        (<DynamicLabel>this.View.FeesNotInAccordanceWithFeeSchedules).label = 'Fees not in accordance with fee schedules';
        (<DynamicLabel>this.View.ExcessiveTreatment).label = 'Excessive treatment, service or hospitalization';
        (<DynamicLabel>this.View.ExcessiveTreatmentFromDate).label = 'From';
        (<DynamicLabel>this.View.ExcessiveTreatmentToDate).label = 'Through';
        (<DynamicLabel>this.View.TreatmentNotRelatedToAccident).label = 'Treatment not related to accident';
        (<DynamicLabel>this.View.UnnecessaryTreatment).label = 'Unnecessary treatment, service, or hospitalization';
        (<DynamicLabel>this.View.UnnecessaryTreatmentFromDate).label = 'From';
        (<DynamicLabel>this.View.UnnecessaryTreatmentToDate).label = 'Through';
        (<DynamicLabel>this.View.HealthServiceBenefitsDeniedOther).label = 'Other, explained below';
        (<DynamicLabel>this.View.PeriodOfBillTreatmentDates).label = 'Period of bill - treatment dates';
        (<DynamicLabel>this.View.DateOfBill).label = 'Date of bill';
        (<DynamicLabel>this.View.DateBillReceivedByInsurer).label = 'Date bill received by insurer';
        (<DynamicLabel>this.View.DateFinalVerificationRequested).label = 'Date final verification requested';
        (<DynamicLabel>this.View.DateFinalVerificationReceived).label = 'Date final verification received';
        (<DynamicLabel>this.View.AmountOfBill).label = 'Amount of bill';
        (<DynamicLabel>this.View.AmountPaidByInsurer).label = 'Amount paid by insurer';
        (<DynamicLabel>this.View.AmountInDispute).label = 'Amount in dispute';
        (<DynamicLabel>this.View.Ime).label = 'IME';
        (<DynamicLabel>this.View.Peer).label = 'Peer';
        (<DynamicLabel>this.View.SuggestedExplanationOfDenial).label = 'Suggested Explanation of denial';
    }
}
