import { ImedClaimServiceStatusLogService } from './../service-status/imed-claim-service-status-log.service';
import { entityListModuleConfig } from './../../../../common/shared.module';
import { IImedClaimServiceStatusLog } from '@model/interfaces/imed-claim-service-status-log.d';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import 'rxjs/operators';
import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';
import { ImedClaimServiceStatusLogEntityListConfig } from './imed-claim-service-status-logs.entity-list-config';

@Component({
    selector: 'app-imed-claim-service-statuses-logs',
    templateUrl: './imed-claim-service-status-logs.component.html',
})
export class ImedClaimServiceStatusLogsComponent implements OnInit {
    searchControl = new UntypedFormControl();
    imedClaimServiceStatusLogs: IImedClaimServiceStatusLog[];
    currentPage = 1;
    query = '';
    total: number;
    entityListConfig = new ImedClaimServiceStatusLogEntityListConfig();
    order = 'Id'; // this.entityListConfig.getDefaultSortProperty();
    orderDirection = 'desc';
    id: string;
    imedClaimId: number;
    serviceId: number;

    statuses: MtSearchFilterItem[] = [];

    constructor(
        private imedClaimServiceStatusLogService: ImedClaimServiceStatusLogService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.imedClaimId = this.activatedRoute.parent.parent.snapshot.params.imedClaimId;
        this.serviceId = this.activatedRoute.parent.snapshot.params.serviceId;
        this.getImedClaimServiceStatusLogs();
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    getImedClaimServiceStatusLogs(): void {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = [];

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: entityListModuleConfig.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);

        this.imedClaimServiceStatusLogService.searchStatusLogs(this.serviceId, searchparams).subscribe((answer) => {
            this.imedClaimServiceStatusLogs = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.query = query;
        this.currentPage = 1;
        this.getImedClaimServiceStatusLogs();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getImedClaimServiceStatusLogs();
    }

    cancelClick(): void {
        void this.router.navigate([`cases/${this.imedClaimId}/services/${this.serviceId}`]);
    }
}
