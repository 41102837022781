import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, IRoleGuarded, ClaimValues, MtTwoFactorGuard } from '@mt-ng2/auth-module';
import {
    LoginOldStyleComponent,
    AdminAccessOldStyleComponent,
    ForgotPasswordOldStyleComponent,
    ResetPasswordOldStyleComponent,
    TwoFactorComponent,
} from '@mt-ng2/login-module';
import { ClaimTypes } from '@model/ClaimTypes';
import { UserService } from './users/user.service';
import { IEntityRouteConfig } from '@mt-ng2/entity-components-base';
import { UserDetailComponent } from './users/user-detail/user-detail.component';
import { PocComponent } from './poc.component';
import { BaseDashboardComponent } from './dashboard/base-dashboard-component/base-dashboard.component';
import { DashboardHeaderComponent } from './dashboard/dashboard-header/dashboard-header.component';
import { AuditLogRedirectComponent } from './common/audit-log-redirect/audit-log-redirect.component';
import { RedirectComponent } from './common/redirect.component';
import { appPaths } from '../client-portal/default-routes/app-paths.library';
import { MtPublicAuthGuard } from '@mt-ng2/auth-module';

const homeRoleGuard: IRoleGuarded = {
    claimType: ClaimTypes.CaseManagement,
    claimValues: [ClaimValues.FullAccess, ClaimValues.ReadOnly],
    isHomePage: true,
};

const userEntityConfig: IEntityRouteConfig = {
    addressesPath: 'addresses',
    claimType: ClaimTypes.Users,
    documentsPath: 'documents',
    entityIdParam: 'userId',
    notesPath: 'notes',
    service: UserService,
};

const appRoutes: Routes = [
    {
        canActivate: [MtPublicAuthGuard],
        path: appPaths.login,
        component: LoginOldStyleComponent,
    },
    { path: 'forgotpassword', component: ForgotPasswordOldStyleComponent, title: 'Forgot Password' },
    { path: 'resetpassword', component: ResetPasswordOldStyleComponent, title: 'Reset Password' },
    { path: 'adminaccess', component: AdminAccessOldStyleComponent, title: 'Admin Access' },
    {
        canActivate: [MtTwoFactorGuard],
        component: TwoFactorComponent,
        path: appPaths.verify2FA,
    },
    {
        canActivate: [AuthGuard],
        children: [{ path: '', component: BaseDashboardComponent, pathMatch: 'full' }],
        component: DashboardHeaderComponent,
        path: 'home',
    },
    {
        canActivate: [AuthGuard],

        children: [{ path: '', component: BaseDashboardComponent, pathMatch: 'full' }],
        component: DashboardHeaderComponent,
        path: 'settings',
    },
    {
        canActivate: [AuthGuard],
        component: AuditLogRedirectComponent,
        path: `audit-log-redirect/:entityType/:entityId`,
    },
    {
        canActivate: [AuthGuard],
        component: RedirectComponent,
        path: `redirect/:entityId`,
    },
    {
        canActivate: [AuthGuard],
        component: UserDetailComponent,
        data: userEntityConfig,
        path: 'my-profile',
        pathMatch: 'full',
    },
    {
        canActivate: [AuthGuard],
        component: PocComponent,
        data: homeRoleGuard,
        path: 'poc',
    },
    { data: homeRoleGuard, path: '**', redirectTo: '/home' },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload'})],
})
export class AppRoutingModule {}
