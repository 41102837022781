import { NgModule } from '@angular/core';
import { SharedModule } from '@common/shared.module';
import { MasterServiceStatusBasicInfoComponent } from './master-service-status-basic-info/master-service-status-basic-info.component';
import { MasterServiceStatusDetailComponent } from './master-service-status-detail/master-service-status-detail.component';
import { MasterServiceStatusFormComponent } from './master-service-status-form/master-service-status-form.component';
import { MasterServiceStatusHeaderComponent } from './master-service-status-header/master-service-status-header.component';
import { MasterServiceStatusRoutingModule } from './master-service-status-routing.module';
import { MasterServiceStatusAddComponent } from './master-service-statuses-add/master-service-statuses-add.component';
import { MasterServiceStatusAssociatedServicesComponent } from './master-service-statuses-list/master-service-statuses-associated-services.component';
import { MasterServiceStatussComponent } from './master-service-statuses-list/master-service-statuses.component';
import { MasterServiceStatusService } from './services/master-service-status.service';

@NgModule({
    declarations: [
        MasterServiceStatussComponent,
        MasterServiceStatusAssociatedServicesComponent,
        MasterServiceStatusAddComponent,
        MasterServiceStatusBasicInfoComponent,
        MasterServiceStatusDetailComponent,
        MasterServiceStatusHeaderComponent,
        MasterServiceStatusFormComponent,
    ],
    imports: [SharedModule, MasterServiceStatusRoutingModule],
})
export class MasterServiceStatusModule {
    static forRoot(): any {
        return {
            ngModule: MasterServiceStatusModule,
            providers: [MasterServiceStatusService],
        };
    }
}
