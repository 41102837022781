import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { Observable, forkJoin } from 'rxjs';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { ImedClaimSubpoenaDetailService } from '../services/imed-claim-subpoena-detail.service';
import { ImedClaimSubpoenaDetailDynamicConfig } from './imed-claim-subpoena-detail.dynamic-config';
import { IImedClaimSubpoenaDetail } from '@model/interfaces/imed-claim-subpoena-detail';
import { IImedClaim } from '@model/interfaces/imed-claim';
import { IAddressBook } from '@model/interfaces/address-book';
import { ICourtAddressBookDTO } from '@model/interfaces/custom/court-address-book-dto';
import { IAddress } from '@model/interfaces/address';
import { IState } from '@model/interfaces/state.d';
import { StateService } from '@app-shared/services/state.service';
import { IAddressBookSelectEntity } from '@common/address-book-select-list/address-books-select-entity';
import { AddressBooksSelectService } from '@common/address-book-select-list/address-books-select.service';
import { IndividualService } from '../../address-books/individuals/individual.service';
import { IIndividual } from '@model/interfaces/individual';
import { IImedClaimSubpoenaCoDefenseCounselAssociation } from '@model/interfaces/imed-claim-subpoena-co-defense-counsel-association';
import { IImedClaimSubpoenaDefenseCounselAssociation } from '@model/interfaces/imed-claim-subpoena-defense-counsel-association';
import { DynamicLabel, DynamicField, IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { ImedClaimServiceService } from '../services/imedclaimservice.service';
import { MultiselectItem } from '@mt-ng2/multiselect-control';
import moment from 'moment';

@Component({
    selector: 'app-imed-claim-subpoena-detail-basic-info',
    templateUrl: './imed-claim-subpoena-detail-basic-info.component.html',
})
export class ImedClaimSubpoenaDetailBasicInfoComponent implements OnInit {
    @Input() imedClaimSubpoenaDetail: IImedClaimSubpoenaDetail;
    @Input() imedClaimId: number;
    @Input() canEdit: boolean;

    isEditing = false;
    isHovered: boolean;
    config: IDynamicFormConfig;
    formFactory: ImedClaimSubpoenaDetailDynamicConfig<IImedClaimSubpoenaDetail>;
    claimId: number;
    hearingAddresses: IAddress[];
    imedClaims: IImedClaim[];
    residingCourtAddressBooks: IAddressBook[];
    courts: ICourtAddressBookDTO[];
    courtPrimaryAddress: IAddress;
    selectedCourt: ICourtAddressBookDTO[] = null;
    form: UntypedFormGroup;
    states: IState[];
    selectedAddress: IAddress;
    cloneAddress: IAddress;
    dummyAddress: IAddress = {
        Address1: 'SELECT HEARING ADDRESS',
        Address2: '',
        Archived: false,
        City: '',
        CreatedById: null,
        DateCreated: null,
        Id: 0,
        State: {
            Id: 0,
            Name: '',
            StateCode: '',
        },
        StateId: null,
        Zip: '',
    };
    counselType: any;
    imedClaimServices: MultiselectItem[] = [];
    selectedImedClaimServiceIds: number[] = [];
    exisitingPlaintiffLodgedObjectionDate: Date;

    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    get isNewImedClaimSubpoenaDetail(): boolean {
        return this.imedClaimSubpoenaDetail && this.imedClaimSubpoenaDetail.Id ? false : true;
    }

    constructor(
        private imedClaimSubpoenaDetailService: ImedClaimSubpoenaDetailService,
        private individualService: IndividualService,
        private notificationsService: NotificationsService,
        private router: Router,
        private route: ActivatedRoute,
        private stateService: StateService,
        private addressBooksSelectService: AddressBooksSelectService,
        private imedClaimServicesService: ImedClaimServiceService,
    ) {}

    ngOnInit(): void {
        this.config = { formObject: [], viewOnly: [] };
        forkJoin(this.imedClaimSubpoenaDetailService.getCourtAddressBook(), this.getStates()).subscribe((answers) => {
            const [courts, states] = answers;
            this.courts = courts;
            this.states = states;
            this.setCourtName();
            this.getSelectedCourt(this.imedClaimSubpoenaDetail.ResidingCourtAddressBookId);
            this.setFullAddress();
            this.selectedAddress = this.imedClaimSubpoenaDetail?.Address;
            this.setConfig();
        });
        this.getCoDefenseCounsels();
        this.getDefenseCounsels();
        this.claimId = +this.route.snapshot.paramMap.get('imedClaimId');
        this.exisitingPlaintiffLodgedObjectionDate = this.imedClaimSubpoenaDetail?.PlaintiffLodgedObjectionDate;
        this.setImedClaimServiceList();
    }

    getSelectedCourt(value: any): void {
        this.selectedCourt = this.courts.filter((court) => court.Id === value);
        if (this.selectedCourt[0] && this.imedClaimSubpoenaDetail.AddressBook) {
            this.imedClaimSubpoenaDetail.AddressBook.FacilityName = this.selectedCourt[0].OriginalCourtName;
        }
    }

    setCourtName(): void {
        this.courts.forEach(function (court): void {
            if (court.DaysToProduce && court.IsMailingAddressPresent && court.IsPhysicalAddressPresent) {
                court.OriginalCourtName = court.Name;
                court.Name = court.Name + ` [${court?.Documents as any}]`;
            } else {
                court.OriginalCourtName = court.Name;
                court.Name = court.Name + ' [ Required Court information is missing ]';
            }
        });
    }

    setFullAddress(): void {
        this.selectedCourt[0]?.AllCourtAddresses.forEach(function (address): void {
            address.Name = `${address?.Address1} ${address?.Address2} ${address?.City} , ${address?.State?.StateCode} ${address?.Zip}`;
        });
        this.hearingAddresses = this.selectedCourt[0]?.AllCourtAddresses;
        this.courtPrimaryAddress = this.selectedCourt[0]?.PrimaryAddress;
    }

    setConfig(): void {
        this.formFactory = new ImedClaimSubpoenaDetailDynamicConfig<IImedClaimSubpoenaDetail>(
            this.courts,
            this.courtPrimaryAddress,
            this.imedClaimSubpoenaDetail,
            this.hearingAddresses,
            this.imedClaims,
            this.selectedCourt,
            this.states,
        );
        if (this.isNewImedClaimSubpoenaDetail) {
            this.isEditing = true;
            this.config = this.formFactory.getForCreate();
        } else {
            this.config = this.formFactory.getForUpdate();
        }

        this.viewOnly = this.config?.viewOnly?.map((x) => new DynamicLabel(x));
        this.formObject = this.config.formObject?.map((x) => new DynamicField(x));
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.isNewImedClaimSubpoenaDetail) {
            void this.router.navigate([`/cases/${this.claimId}`]);
        } else {
            this.isEditing = false;
        }
    }

    getStates(): Observable<IState[]> {
        return this.stateService.getAll().pipe(tap((answer) => (this.states = answer)));
    }

    formSubmitted(form: UntypedFormGroup): void {
        form.value.ImedClaimSubpoenaDetail.ImedClaimSubpoenaCoDefenseCounselAssociations =
            this.imedClaimSubpoenaDetail.ImedClaimSubpoenaCoDefenseCounselAssociations?.map((coDefenseCounsel) => {
                return {
                    AddressBook: coDefenseCounsel.AddressBook,
                    AddressBookId: coDefenseCounsel.AddressBookId,
                    Id: coDefenseCounsel.Id,
                    ImedClaimSubpoenaDetailId: coDefenseCounsel.ImedClaimSubpoenaDetailId,
                    Individual: coDefenseCounsel.Individual,
                    IndividualId: coDefenseCounsel.IndividualId,
                    Name: coDefenseCounsel.Name,
                };
            });

        form.value.ImedClaimSubpoenaDetail.ImedClaimSubpoenaDefenseCounselAssociations =
            this.imedClaimSubpoenaDetail.ImedClaimSubpoenaDefenseCounselAssociations?.map((defenseCounsel) => {
                return {
                    AddressBook: defenseCounsel.AddressBook,
                    AddressBookId: defenseCounsel.AddressBookId,
                    Id: defenseCounsel.Id,
                    ImedClaimSubpoenaDetailId: defenseCounsel.ImedClaimSubpoenaDetailId,
                    Individual: defenseCounsel.Individual,
                    IndividualId: defenseCounsel.IndividualId,
                    Name: defenseCounsel.Name,
                };
            });

        this.initializeSubpoenaDetailAddress(form.value.ImedClaimSubpoenaDetail);
        if (form.valid) {
            this.formFactory.assignFormValues(this.imedClaimSubpoenaDetail, form.value.ImedClaimSubpoenaDetail as IImedClaimSubpoenaDetail);
            this.saveImedClaimSubpoenaDetail();
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error('Save failed.  Please check the form and try again.');
        }
    }

    initializeSubpoenaDetailAddress(address: any): void {
        this.imedClaimSubpoenaDetail.Address.Address1 = address.Address1;
        this.imedClaimSubpoenaDetail.Address.Address2 = address.Address2;
        this.imedClaimSubpoenaDetail.Address.City = address.City;
        this.imedClaimSubpoenaDetail.Address.StateId = address.State;
        this.imedClaimSubpoenaDetail.Address.State = this.states.find((state) => {
            return state.Id === address.State;
        });
        this.imedClaimSubpoenaDetail.Address.Zip = address.Zip;
    }

    formCreated(form: UntypedFormGroup): void {
        this.form = form;

        (form.controls.ImedClaimSubpoenaDetail as UntypedFormGroup).controls.ResidingCourtAddressBookId.valueChanges.subscribe((value) => {
            this.handleCourtSelectionChange(value);
        }),
            (form.controls.ImedClaimSubpoenaDetail as UntypedFormGroup).controls.HearingAddressId.valueChanges.subscribe((value) => {
                this.handleAddressChange(value, form);
            });
    }

    imedClaimServiceSelectionChanged(event: any): void {
        this.selectedImedClaimServiceIds = event.selectedItems.map((claimService) => claimService.Id);
    }

    setImedClaimServiceList(): void {
        this.imedClaimServicesService.getAllImedClaimServicesByImedClaimId(this.imedClaimId).subscribe((imedClaimServices) => {
            this.imedClaimServices = imedClaimServices.map((imedClaimService) => {
                this.selectedImedClaimServiceIds = imedClaimServices.map((imedClaimService) => imedClaimService.Id);
                return new MultiselectItem({ Id: imedClaimService.Id, Name: `${imedClaimService.Id} - ${imedClaimService.Service.Name}` }, true);
            });
        });
    }

    handleCourtSelectionChange(value: any): void {
        this.getSelectedCourt(value);
        this.setFullAddress();
        this.formObject.find((val) => val.name === 'HearingAddressId').options = this.hearingAddresses;

        if (this.hearingAddresses?.length > 0 && this.hearingAddresses[0]?.Address1 !== 'SELECT HEARING ADDRESS') {
            this.hearingAddresses.splice(0, 0, this.dummyAddress);
        }
    }

    handleAddressChange(value: any, form: UntypedFormGroup): void {
        this.cloneAddress = this.selectedCourt[0]?.AllCourtAddresses?.find((address) => address.Id === value);
        if (this.cloneAddress) {
            this.selectedAddress = this.cloneAddress;
        }
        if (this.selectedAddress) {
            this.prefillCourtAddressFieldsWithSelectedAddress(form, this.selectedAddress);
        }
    }

    searchAddressBooks(counselType): void {
        this.counselType = counselType;
        this.addressBooksSelectService.openAddressBookSelect('Attorney', this.addToCODefenseAndDefenseCounsel);
    }

    addToCODefenseAndDefenseCounsel = (addressBookSelect: IAddressBookSelectEntity) => {
        if (addressBookSelect.IndividualId) {
            this.individualService
                .getSubpoenaCounsel(addressBookSelect.IndividualId)
                .subscribe((counsel: IImedClaimSubpoenaCoDefenseCounselAssociation | IImedClaimSubpoenaDefenseCounselAssociation) => {
                    counsel.ImedClaimSubpoenaDetailId = this.claimId;
                    if (this.counselType === 'CoDefenseCounsel') {
                        if (!this.imedClaimSubpoenaDetail.ImedClaimSubpoenaCoDefenseCounselAssociations) {
                            this.imedClaimSubpoenaDetail.ImedClaimSubpoenaCoDefenseCounselAssociations = [];
                        } else {
                            this.imedClaimSubpoenaDetail.ImedClaimSubpoenaCoDefenseCounselAssociations?.push(counsel);
                        }
                    } else {
                        if (!this.imedClaimSubpoenaDetail.ImedClaimSubpoenaDefenseCounselAssociations) {
                            this.imedClaimSubpoenaDetail.ImedClaimSubpoenaDefenseCounselAssociations = [];
                        } else {
                            this.imedClaimSubpoenaDetail.ImedClaimSubpoenaDefenseCounselAssociations?.push(
                                counsel as IImedClaimSubpoenaDefenseCounselAssociation,
                            );
                        }
                    }
                });
            setTimeout(() => {
                window.scrollTo(0, document.body.scrollHeight);
            }, 100);
        }
    };

    prefillCourtAddressFieldsWithSelectedAddress(form: UntypedFormGroup, selectedAddress: any): void {
        (form.controls.ImedClaimSubpoenaDetail as UntypedFormGroup).controls.Address1.setValue(selectedAddress?.Address1);
        (form.controls.ImedClaimSubpoenaDetail as UntypedFormGroup).controls.Address2.setValue(selectedAddress?.Address2);
        (form.controls.ImedClaimSubpoenaDetail as UntypedFormGroup).controls.City.setValue(selectedAddress?.City);
        (form.controls.ImedClaimSubpoenaDetail as UntypedFormGroup).controls.State.setValue(selectedAddress?.StateId);
        (form.controls.ImedClaimSubpoenaDetail as UntypedFormGroup).controls.Zip.setValue(selectedAddress?.Zip);
    }

    private saveImedClaimSubpoenaDetail(): void {
        this.imedClaimSubpoenaDetail.ImedClaimId = this.claimId;

        if (this.isNewImedClaimSubpoenaDetail) {
            this.imedClaimSubpoenaDetailService.createWithFks(this.imedClaimSubpoenaDetail).subscribe((answer) => {

                if (this.imedClaimSubpoenaDetail.PlaintiffLodgedObjectionDate != null && this.selectedImedClaimServiceIds.length > 0) {
                    this.imedClaimSubpoenaDetailService
                        .createPlaintiffLodgedFollowups(this.imedClaimSubpoenaDetail, this.selectedImedClaimServiceIds)
                        .subscribe(() => {
                            this.exisitingPlaintiffLodgedObjectionDate = this.imedClaimSubpoenaDetail.PlaintiffLodgedObjectionDate;
                        });
                }

                this.imedClaimSubpoenaDetail.Id = answer;
                this.success(true);
                this.getCoDefenseCounsels();
                this.getDefenseCounsels();
            });
        } else {
            this.imedClaimSubpoenaDetailService.updateWithFks(this.imedClaimSubpoenaDetail).subscribe(() => {

                if (
                    this.imedClaimSubpoenaDetail.PlaintiffLodgedObjectionDate != null &&
                    this.selectedImedClaimServiceIds.length > 0 &&
                    ( this.exisitingPlaintiffLodgedObjectionDate == null ||
                        moment.utc(this.exisitingPlaintiffLodgedObjectionDate).format('MM/DD/YYYY') !=
                            moment.utc(this.imedClaimSubpoenaDetail.PlaintiffLodgedObjectionDate).format('MM/DD/YYYY'))
                ) {

                    this.imedClaimSubpoenaDetailService
                        .createPlaintiffLodgedFollowups(this.imedClaimSubpoenaDetail, this.selectedImedClaimServiceIds)
                        .subscribe(() => {
                            this.exisitingPlaintiffLodgedObjectionDate = this.imedClaimSubpoenaDetail.PlaintiffLodgedObjectionDate;
                        });
                }
                this.success();
                this.getCoDefenseCounsels();
                this.getDefenseCounsels();
            });
        }
    }

    private success(newImedClaimSubpoenaDetailSave?: boolean): void {
        if (newImedClaimSubpoenaDetailSave) {
            void this.router.navigate([`/cases/${this.claimId}`]);
        }
        this.isEditing = false;
        this.setConfig();
        this.imedClaimSubpoenaDetailService.emitChange(this.imedClaimSubpoenaDetail);
        this.notificationsService.success('Subpoena Details saved successfully.');
    }

    removeCoDefenseCounsel(item: IImedClaimSubpoenaCoDefenseCounselAssociation, event: Event): void {
        if (event && event.stopPropagation) {
            event.stopPropagation();
        }
        if (item.Id > 0) {
            this.imedClaimSubpoenaDetailService.deleteCoDefenseCounsel(item.Id).subscribe(() => {
                this.getCoDefenseCounsels();
                this.notificationsService.success('Deleted successfully');
            });
        } else {
            this.ClearCoDefenseCounselAddressBooks();
        }
    }

    removeDefenseCounsel(item: IImedClaimSubpoenaDefenseCounselAssociation, event: Event): void {
        if (event && event.stopPropagation) {
            event.stopPropagation();
        }
        if (item.Id > 0) {
            this.imedClaimSubpoenaDetailService.deleteDefenseCounsel(item.Id).subscribe(() => {
                this.getDefenseCounsels();
                this.notificationsService.success('Deleted successfully');
            });
        } else {
            this.ClearDefenseCounselAddressBooks();
        }
    }

    getCoDefenseCounsels(): void {
        this.imedClaimSubpoenaDetailService
            .getCoDefenseCounsel(this.imedClaimSubpoenaDetail.Id)
            .subscribe((coDefenseCounsels: IImedClaimSubpoenaCoDefenseCounselAssociation[]) => {
                this.imedClaimSubpoenaDetail.ImedClaimSubpoenaCoDefenseCounselAssociations = coDefenseCounsels;
            });
    }

    getDefenseCounsels(): void {
        this.imedClaimSubpoenaDetailService
            .getDefenseCounsel(this.imedClaimSubpoenaDetail.Id)
            .subscribe((defenseCounsels: IImedClaimSubpoenaDefenseCounselAssociation[]) => {
                this.imedClaimSubpoenaDetail.ImedClaimSubpoenaDefenseCounselAssociations = defenseCounsels;
            });
    }

    getCoDefenseAndDefenseCounselName(Individual: IIndividual, AddressBook: IAddressBook): string {
        return AddressBook.FacilityName !== ''
            ? `${Individual.FirstName} ${Individual.LastName}, ${AddressBook?.FacilityName}`
            : `${Individual.FirstName} ${Individual.LastName}`;
    }

    ClearCoDefenseCounselAddressBooks(): void {
        const coDefenseCounselsLength = this.imedClaimSubpoenaDetail.ImedClaimSubpoenaCoDefenseCounselAssociations.length - 1;
        this.imedClaimSubpoenaDetail.ImedClaimSubpoenaCoDefenseCounselAssociations[coDefenseCounselsLength].AddressBookId = null;
        this.imedClaimSubpoenaDetail.ImedClaimSubpoenaCoDefenseCounselAssociations[coDefenseCounselsLength].IndividualId = null;
        this.imedClaimSubpoenaDetail.ImedClaimSubpoenaCoDefenseCounselAssociations[coDefenseCounselsLength].Name = null;
        this.imedClaimSubpoenaDetail.ImedClaimSubpoenaCoDefenseCounselAssociations[coDefenseCounselsLength].ImedClaimSubpoenaDetailId = null;
        this.getCoDefenseCounsels();
    }

    ClearDefenseCounselAddressBooks(): void {
        const defenseCounselsLength = this.imedClaimSubpoenaDetail.ImedClaimSubpoenaDefenseCounselAssociations.length - 1;
        this.imedClaimSubpoenaDetail.ImedClaimSubpoenaDefenseCounselAssociations[defenseCounselsLength].AddressBookId = null;
        this.imedClaimSubpoenaDetail.ImedClaimSubpoenaDefenseCounselAssociations[defenseCounselsLength].IndividualId = null;
        this.imedClaimSubpoenaDetail.ImedClaimSubpoenaDefenseCounselAssociations[defenseCounselsLength].Name = null;
        this.imedClaimSubpoenaDetail.ImedClaimSubpoenaDefenseCounselAssociations[defenseCounselsLength].ImedClaimSubpoenaDetailId = null;
        this.getDefenseCounsels();
    }
}
