import { EmailTemplateDynamicControls, IEmailTemplateDynamicControlsParameters } from '../form-controls/email-template.form-controls';
import { IEmailTemplate } from '../interfaces/email-template';
import { ChangeDetectorRef } from '@angular/core';
import { DynamicFieldTypes, DynamicField, InputTypes, DynamicFieldType } from '@mt-ng2/dynamic-form';
import { CkEditorDynamicFieldComponent } from '../../common/ckeditor-dynamic-field/ckeditor-dynamic-field.component';

export class EmailTemplateDynamicControlsPartial extends EmailTemplateDynamicControls {
    constructor(emailtemplatePartial?: IEmailTemplate, additionalParameters?: IEmailTemplateDynamicControlsParameters) {
        super(emailtemplatePartial, additionalParameters);

        // (<DynamicField>this.Form.Subject).component = TextareaMergeFieldsDynamicFieldComponent;
        (<DynamicField>this.Form.Body).component = CkEditorDynamicFieldComponent;
        // (<DynamicField>this.Form.To).component = TextareaMergeFieldsDynamicFieldComponent;

        (<DynamicField>this.Form.To).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Input,
            inputType: InputTypes.Textbox,
        });

        (<DynamicField>this.Form.Cc).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Input,
            inputType: InputTypes.Textbox,
        });
        (<DynamicField>this.Form.Cc).labelHtml = '<label>CC</label>';
        (<DynamicField>this.View.Cc).label = 'CC';

        (<DynamicField>this.Form.Bcc).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Input,
            inputType: InputTypes.Textbox,
        });
        (<DynamicField>this.Form.Bcc).labelHtml = '<label>BCC</label>';
        (<DynamicField>this.View.Bcc).label = 'BCC';
    }
}
