import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IUserRole } from '@model/interfaces/user-role';
import { UserRoleService } from '../user-role.service';
import { ClaimTypes } from '@model/ClaimTypes';
import { IClaimType } from '@model/interfaces/claim-type';
import { ClaimTypeService } from '../claimtype.service';
import { IClaimValue } from '@model/interfaces/claim-value';
import { ClaimValueService } from '../claimvalue.service';
import { Permission } from '../user-role-permissions/permissions.library';
import { UserRole } from '../user-role.library';

@Component({
    selector: 'app-user-role-detail',
    templateUrl: './user-role-detail.component.html',
})
export class UserRoleDetailComponent implements OnInit {
    canEditUserRoles: boolean;
    canEdit: boolean;
    userRole: IUserRole;
    claimTypes: IClaimType[];
    claimValues: IClaimValue[];
    permissions: Permission[];
    hideDelete = false;

    constructor(
        private userRoleService: UserRoleService,
        private claimsService: ClaimsService,
        private claimTypeService: ClaimTypeService,
        private claimValueService: ClaimValueService,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.canEditUserRoles = this.claimsService.hasClaim(ClaimTypes.UserRoles, [ClaimValues.FullAccess]);
        const id = +this.route.snapshot.paramMap.get('userRoleId');
        forkJoin(this.claimValueService.getItems(), this.getUserRoleById(id), this.userRoleService.isUserRoleInUse(id)).subscribe(
            (forkJoinReturns) => {
                const [claimValues, userRole, isUserRoleInUse] = forkJoinReturns;

                if (id > 0) {
                    // set the claimValues
                    this.claimValues = [...claimValues];
                    this.claimValues.push({ Name: 'No Access', Id: 0 });
                    // set the claimTypes
                    this.claimTypeService.getFilteredItems(userRole.UserTypeId).subscribe((claimTypes) => {
                        this.claimTypes = [...claimTypes];
                        this.hideDelete = isUserRoleInUse;
                        this.setUserRole(userRole);
                    });
                } else {
                    this.setUserRole(userRole);
                }
                // set the user role
            },
        );
    }

    getUserRoleById(id: number): Observable<IUserRole> {
        if (id > 0) {
            return this.userRoleService.getById(id);
        } else {
            return of(this.userRoleService.getEmptyUserRole());
        }
    }

    setUserRole(userRole: IUserRole): void {
        this.userRole = userRole;
        // set canEdit based on first whether the userRole can be edited
        // then by the claims for this user
        this.canEdit = this.canEditUserRoles;
        // using user role, set the permissions array
        if (this.userRole && this.userRole.UserRoleClaims) {
            if (this.userRole && this.userRole.UserRoleClaims) {
                this.permissions = this.claimTypes.map((claimType) => new Permission(this.userRole, claimType));
            }
        }
    }

    deleteRole(): void {
        this.userRoleService.deleteRole(this.userRole.Id).subscribe(() => {
            void this.router.navigate(['/roles']);
            this.notificationsService.success('Role Deleted');
        });
    }

    savePermission(permission: Permission): void {
        // since we have certain roles that cannot be edited (i.e. admin role)
        // we check here to be sure
        if (!this.userRole.IsEditable) {
            return;
        }
        const userRoleUpdate = new UserRole({ ...this.userRole });

        userRoleUpdate.updateUserRoleClaims(permission);
        this.userRoleService.updateClaims(userRoleUpdate.Id, userRoleUpdate.UserRoleClaims).subscribe(() => {
            this.notificationsService.success('Saved Succesfully');
            this.setUserRole(userRoleUpdate.userRole);
        });
    }
    updateRoleObject(): void {
        this.userRoleService.getById(+this.route.snapshot.paramMap.get('userRoleId')).subscribe((response) => {
            this.userRole = response;
            this.setUserRole(this.userRole);
        });
    }
}
