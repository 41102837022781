import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import 'rxjs/operators';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues, AuthService } from '@mt-ng2/auth-module';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection, IItemDeletedEvent } from '@mt-ng2/entity-list-module';

import { PhysicianVacationService } from '../physicianvacation.service';
import { IPhysicianVacation } from '@model/interfaces/physician-vacation';
import { entityListModuleConfig } from '../../../../../common/shared.module';
import { ClaimTypes } from '@model/ClaimTypes';
import { PhysicianVacationsEntityListConfig } from './physician-vacations.entity-list-config';
import { NotificationsService } from '../../../../../../../node_modules/@mt-ng2/notifications-module';

@Component({
    selector: 'app-physician-vacations',
    templateUrl: './physician-vacations.component.html',
})
export class PhysicianVacationsComponent implements OnInit {
    searchControl = new UntypedFormControl();
    physicianVacations: IPhysicianVacation[];
    currentPage = 1;
    query = '';
    total: number;
    entityListConfig = new PhysicianVacationsEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();
    addVacationLink: string[];

    canAddPhysicianVacation = false;

    addressBookId: number;
    individualId: number;

    constructor(
        private physicianVacationService: PhysicianVacationService,
        private claimsService: ClaimsService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private notificationService: NotificationsService,
    ) { }

    ngOnInit(): void {
        this.addressBookId = this.activatedRoute.parent.parent.snapshot.params.addressBookId;
        this.individualId = this.activatedRoute.parent.snapshot.params.individualId;

        this.addVacationLink = [`/address-books/${this.addressBookId}/individuals/${this.individualId}/physician-vacations/`, 'add'];

        this.canAddPhysicianVacation = this.claimsService.hasClaim(ClaimTypes.AddressBooks, [ClaimValues.FullAccess]);
        this.getPhysicianVacations();
    }

    getPhysicianVacations(): void {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'IndividualId', // We'll have to get the Physician later in the service
                value: this.individualId.toString(),
            }),
        );

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: entityListModuleConfig.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        this.physicianVacationService.get(searchparams).subscribe((answer) => {
            this.physicianVacations = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.query = query;
        this.currentPage = 1;
        this.getPhysicianVacations();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getPhysicianVacations();
    }

    physicianVacationSelected(event: IItemSelectedEvent): void {
        void this.router.navigate([`/address-books/${this.addressBookId}/individuals/${this.individualId}/physician-vacations/`, event.entity.Id]);
    }

    onItemDeleted(event: IItemDeletedEvent): void {
        const selectedPhysicianVacation: IPhysicianVacation = event.entity;
        selectedPhysicianVacation.Archived = !selectedPhysicianVacation.Archived;
        this.physicianVacationService.update(selectedPhysicianVacation).subscribe(() => {
            this.notificationService.success('Physician Vacation Updated Successfully');
            this.getPhysicianVacations();
        });
    }

    cancelClick(): void {
        void this.router.navigate([`address-books/${this.addressBookId}/individuals/${this.individualId}`]);
    }
}
