import { Component, OnInit } from '@angular/core';
import { IMasterFollowupType } from '@model/interfaces/master-followup-type';
import { MasterFollowUpTypeService } from '../services/master-followup-type.service';

@Component({
    templateUrl: './master-followup-types-add.component.html',
})
export class MasterFollowUpTypeAddComponent implements OnInit {
    masterFollowupType: IMasterFollowupType;
    canEdit = true; // route guard ensures this component wouldn't be loaded if user didn't have permission already

    constructor(private masterFollowUpTypeService: MasterFollowUpTypeService) {}

    ngOnInit(): void {
        this.masterFollowupType = this.masterFollowUpTypeService.getEmptyMasterFollowupType();
    }
}
