import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { IFilteredServiceFollowupStatusTaskDTO } from '@model/interfaces/custom/filtered-service-followup-status-task-dto';
import { ITaskTemplate } from '@model/interfaces/task-template';
import { IMasterFollowupType } from '@model/interfaces/master-followup-type';
import { IGroupedTaskTemplatesByFollowupDTO } from '@model/interfaces/custom/grouped-task-templates-by-followup-dto';

export interface IFollowupTaskAssociation {
    Followup: IMasterFollowupType;
    TaskTemplate: ITaskTemplate;
}

@Component({
    selector: 'followup-task-row',
    styleUrls: ['../../row-styles.less'],
    templateUrl: './followup-task-row.component.html',
})
export class FollowupTaskRowComponent implements OnInit {
    private _filteredServiceData: IFilteredServiceFollowupStatusTaskDTO;
    @Input('filteredServiceData')
    set filteredServiceData(val: IFilteredServiceFollowupStatusTaskDTO) {
        this._filteredServiceData = { ...val };
    }
    get filteredServiceData(): IFilteredServiceFollowupStatusTaskDTO {
        return this._filteredServiceData;
    }

    @Input() groupedTasks: IGroupedTaskTemplatesByFollowupDTO;
    @Input() className = '';
    @Output() onTaskSelected: EventEmitter<IFollowupTaskAssociation> = new EventEmitter();
    @Output() onTaskRemoved: EventEmitter<IFollowupTaskAssociation> = new EventEmitter();
    @Output() onTaskExempted: EventEmitter<IFollowupTaskAssociation> = new EventEmitter();

    selectedTask: ITaskTemplate;

    constructor() { }

    ngOnInit(): void {
        this.filteredServiceData.TaskTemplates = this.filteredServiceData.TaskTemplates.filter(
            (x) => !this.groupedTasks.TaskTemplate.some((y) => y.Id === x.Id),
        );
    }

    taskSelected(): void {
        this.filteredServiceData.TaskTemplates = this.filteredServiceData.TaskTemplates.filter((x) => x.Id !== this.selectedTask.Id);
        this.groupedTasks.TaskTemplate.push(this.selectedTask);
        this.onTaskSelected.emit({
            Followup: this.groupedTasks.FollowupType,
            TaskTemplate: this.selectedTask,
        } as IFollowupTaskAssociation);
        this.selectedTask = null;
    }

    itemRemoved(index: number, task: ITaskTemplate): void {
        this.groupedTasks.TaskTemplate.splice(index, 1);
        this.filteredServiceData.TaskTemplates.push(task);
        this.onTaskRemoved.emit({
            Followup: this.groupedTasks.FollowupType,
            TaskTemplate: task,
        } as IFollowupTaskAssociation);
    }

    itemExempted(index: number, task: ITaskTemplate): void {
        this.onTaskExempted.emit({
            Followup: this.groupedTasks.FollowupType,
            TaskTemplate: task,
        } as IFollowupTaskAssociation);
    }
}
