import { Component, Input, OnInit } from '@angular/core';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { UserRoleService } from '../../user-roles/user-role.service';
import { IImedClaimAccessRequestIndividual } from '@model/interfaces/imed-claim-access-request-individual';
import { UserRoleIdEnums } from '@common/constants/Enums';
import { ImedClaimService } from '../imedclaim.service';
import { forkJoin } from 'rxjs';
import { AuthService } from '@mt-ng2/auth-module';

@Component({
    selector: 'app-imed-claim-access-requests',
    templateUrl: './imed-claim-access-request.component.html',
})
export class ImedClaimAccessRequestComponent implements OnInit {
    @Input('claimId') claimId: number;

    imedClaimAccessRequests: IImedClaimAccessRequestIndividual[];
    userId: number;
    canGrantAndRevokeAccess = false;

    constructor(
        private notificationsService: NotificationsService,
        private userRoleService: UserRoleService,
        private authService: AuthService,
        private imedClaimService: ImedClaimService,
    ) {}

    ngOnInit(): void {
        this.userId = this.authService.currentUser.getValue().Id;

        forkJoin([this.userRoleService.getRoleByUserId(this.userId), this.imedClaimService.getImedClaimAccessRequests(this.claimId)]).subscribe(
            ([result, requests]) => {
                this.canGrantAndRevokeAccess = result.ClientAccess;
                this.imedClaimAccessRequests = requests.map((request) => {
                    request.Description = `${request.IndividualId} - ${request.Individual.FirstName} ${request.Individual.LastName} made a request.`;
                    return request;
                });
            },
        );
    }

    grantAccess(request: IImedClaimAccessRequestIndividual, event: Event): void {
        if (event && event.stopPropagation) {
            event.stopPropagation();
        }

        this.imedClaimService.grantImedClaimAccess(request.Id).subscribe(
            (success) => {
                request.ImedClaimAccessGranted = true;
                this.notificationsService.success('Access Granted');
            },
            (error) => {
                this.notificationsService.error('Error granting access');
            },
        );
    }

    revokeAccess(request: IImedClaimAccessRequestIndividual, event: Event): void {
        if (event && event.stopPropagation) {
            event.stopPropagation();
        }

        this.imedClaimService.revokeImedClaimAccess(request.Id).subscribe(
            (success) => {
                this.imedClaimAccessRequests = this.imedClaimAccessRequests.filter((r) => r.Id !== request.Id);
                this.notificationsService.success('Access Revoked');
            },
            (error) => {
                this.notificationsService.error('Error revoking access');
            },
        );
    }
}
