import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@mt-ng2/auth-module';
import { SharedPhoneNumberService } from '@model/shared-entities/phone-numbers/phone-number.service';

@Injectable()
export class AddressBookPhoneNumberService extends SharedPhoneNumberService {
    constructor(public http: HttpClient, public authService: AuthService) {
        super('/phones/address-books', http, authService);
    }
}
