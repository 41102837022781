import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, IterableDiffer } from '@angular/core';
import { Router } from '@angular/router';
import { Chart } from 'chart.js';
import { DashboardService, IProductivity } from '../dashboard.service';
import { SearchParams, IEntitySearchParams, ExtraSearchParams } from '@mt-ng2/common-classes';
import { CommonSearchService, ICommonSearch } from '../../common/services/common-search.service';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-productivity-widget',
    styleUrls: ['../base-dashboard-component/base-dashboard-common-css.component.less'],
    templateUrl: './productivity-widget.component.html',
})
export class ProductivityWidgetComponent implements OnInit {
    @ViewChild('productivityChart') productivityChartRef: ElementRef;

    productivityChart: any;
    productivity: IProductivity[];
    chart;

    constructor(
        private router: Router,
        private dashboardService: DashboardService,
        private commonSearchService: CommonSearchService,
        private datePipe: DatePipe,
    ) {}

    // https://stackoverflow.com/questions/39475891/add-dataset-bar-chart-chart-js if they want all service to show
    // TODO : Figure out re-rendering issue - https://stackoverflow.com/questions/28609932/chartjs-resizing-very-quickly-flickering-on-mouseover

    renderProductivityChart(): void {
        this.dashboardService.getProductivity().subscribe((responses: IProductivity[]) => {
            this.productivity = responses;
            const labels = responses.map((response: IProductivity) => response.UserRole);
            const data = responses.map((response: IProductivity) => this.getProductivityServiceObject(response));
            data.push(0);
            if (this.productivityChart) {
                this.productivityChart.destroy();
            }
            this.productivityChart = new Chart(this.productivityChartRef.nativeElement, {
                data: {
                    datasets: [
                        {
                            backgroundColor: this.dashboardService.poolColors(labels.length),
                            data: data,
                            fillColor: this.dashboardService.poolColors(labels.length),
                            highlightFill: this.dashboardService.poolColors(labels.length),
                            highlightStroke: this.dashboardService.poolColors(labels.length),
                            label: 'Productivity',
                            strokeColor: this.dashboardService.poolColors(labels.length),
                        },
                    ],
                    labels: labels,
                },
                options: {
                    legend: { display: false },
                    onHover: (e, el) => {
                        e.target.style.cursor = el[0] ? 'pointer' : 'default';
                    },
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                    precision: 0,
                                },
                            },
                        ],
                    },
                    title: {
                        display: true,
                        text: 'Productivity',
                    },
                },
                type: 'bar',
            });
        });
    }

    getProductivityServiceObject(response: IProductivity): any {
        return { y: response.Count, productivity: response };
    }

    ngOnInit(): void {
        this.renderProductivityChart();
    }

    showProductivityData(evt: any): void {
        const data = this.productivityChart.getElementsAtEvent(evt);

        if (data.length) {
            const firstPoint = data[0];
            const value = this.productivityChart.data.datasets[firstPoint._datasetIndex].data[firstPoint._index];
            const _extraSearchParams: ExtraSearchParams[] = [];

            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'UserRoles',
                    valueArray: value.productivity.UserRoleId.toString(),
                }),
            );

            const startOfWeek = this.dashboardService.getStartDateWeek();
            const endOfWeek = this.dashboardService.getEndDateWeek();

            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'StartDate',
                    value: this.datePipe.transform(startOfWeek, 'M/d/yyyy', 'UTC'),
                }),
            );

            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'EndDate',
                    value: this.datePipe.transform(endOfWeek, 'M/d/yyyy', 'UTC'),
                }),
            );

            const searchEntity: IEntitySearchParams = {
                extraParams: _extraSearchParams,
                query: '',
            };

            const searchFilter: ICommonSearch = {
                dashboardName: 'Productivity',
                searchFilters: new SearchParams(searchEntity),
            };

            this.commonSearchService.setCommonSearchFilters(searchFilter);
            void this.router.navigate(['cases/0/services/0/follow-ups']);
        }
    }
}
