import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { forkJoin } from 'rxjs';
import { IAddressBook } from '@model/interfaces/address-book';
import { AddressBookService } from '../addressbook.service';
import { AutomatedTemplateService } from '../../automated-templates/services/automated-template.service';
import { ICourtSubpoenaDocument } from '@model/interfaces/court-subpoena-document';
import { IAutomatedTemplate } from '@model/interfaces/automated-template';
import { ICourtSubpoenaDocumentType } from '@model/interfaces/court-subpoena-document-type';
import { response } from 'express';
import { CourtSubpoenaDocumentTypeService } from '../addresses/courtsubpoenadocumenttype.service';

@Component({
    selector: 'court-subpoena-documents',
    styles: [
        `
            .clear-padding {
                padding-left: 0;
            }
        `,
    ],
    styleUrls: ['../../styles/row-styles.less'],
    templateUrl: './court-subpoena-documents.component.html',
})
export class CourtSubpoenaDocumentsComponent implements OnInit, OnChanges {
    @Input() addressBook: IAddressBook;

    subpoenaDocuments: ICourtSubpoenaDocument[] = [];
    courtAutomatedTemplates: IAutomatedTemplate[] = [];
    subpoenaDocumentTypes: ICourtSubpoenaDocumentType[] = [];

    constructor(
        private addressBookService: AddressBookService,
        private automatedTemplateService: AutomatedTemplateService,
        private courtSubpoenaDocumentTypeService: CourtSubpoenaDocumentTypeService,
        private notificationService: NotificationsService,
    ) {}

    ngOnInit(): void {
        this.getFilteredCourtSubpoenaDocumentData();
    }

    ngOnChanges(): void {
        this.getFilteredCourtSubpoenaDocumentData();
    }

    getFilteredCourtSubpoenaDocumentData(): void {
        forkJoin(
            this.addressBookService.getSubpoenaDocumentsForAddressBook(this.addressBook.Id),
            this.automatedTemplateService.getByAddressBookId(this.addressBook.Id),
            this.courtSubpoenaDocumentTypeService.getAll(),
        ).subscribe(([subpoenaDocuments, automatedTemplates, documentTypes]) => {
             this.subpoenaDocumentTypes = documentTypes;
             this.courtAutomatedTemplates = automatedTemplates;
             this.subpoenaDocuments = subpoenaDocuments;
             this.subpoenaDocumentTypes = documentTypes.filter((x) => !this.subpoenaDocuments.some((y) => y.DocumentTypeId === x.Id));
        });
    }

    addAutomatedTemplateAssociation(): void {
        this.subpoenaDocuments.push({
            AutomatedTemplate: { Id: 0 },
            CourtSubpoenaDocumentType: { Id: 0 },
            Id: 0,
        } as ICourtSubpoenaDocument);
    }

    automatedTemplateSelected(subpoenaDocument: ICourtSubpoenaDocument): void {
        subpoenaDocument.AutomatedTemplateId = subpoenaDocument.AutomatedTemplate.Id;
        if (subpoenaDocument.Id > 0) {
            this.addressBookService.updateSubpoenaDocument(this.addressBook.Id, subpoenaDocument).subscribe(() => {
                this.notificationService.success('Subpoena associated document saved successfully');
                this.getFilteredCourtSubpoenaDocumentData();
            });
        } else {
            if (subpoenaDocument.DocumentTypeId > 0) {
                this.addressBookService.createSubpoenaDocument(this.addressBook.Id, subpoenaDocument).subscribe((response) => {
                    subpoenaDocument.Id = response;
                    this.notificationService.success('Subpoena associated document saved successfully');
                    this.getFilteredCourtSubpoenaDocumentData();
                });
            }
        }
    }

    documentTypeSelected(subpoenaDocument: ICourtSubpoenaDocument): void {
        subpoenaDocument.DocumentTypeId = subpoenaDocument.CourtSubpoenaDocumentType.Id;
        if (subpoenaDocument.Id > 0) {
            this.addressBookService.updateSubpoenaDocument(this.addressBook.Id, subpoenaDocument).subscribe(() => {
                this.notificationService.success('Subpoena associated document saved successfully');
                this.getFilteredCourtSubpoenaDocumentData();
            });
        } else {
            if (subpoenaDocument.AutomatedTemplateId > 0) {
                this.addressBookService.createSubpoenaDocument(this.addressBook.Id, subpoenaDocument).subscribe((response) => {
                    subpoenaDocument.Id = response;
                    this.notificationService.success('Subpoena associated document saved successfully');
                    this.getFilteredCourtSubpoenaDocumentData();
                });
            }
        }

    }

    itemRemoved(index: number, subpoenaDocument: ICourtSubpoenaDocument): void {
        if (subpoenaDocument.Id > 0) {
            this.addressBookService.deleteSubpoenaDocument(this.addressBook.Id, subpoenaDocument.Id).subscribe(() => {
                this.notificationService.success('Subpoena associated document deleted successfully');
                this.getFilteredCourtSubpoenaDocumentData();
            });
        }
        this.subpoenaDocuments.splice(index, 1);
    }
}
