import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { DynamicField, IDynamicField, DynamicFieldType, IDynamicFieldType, DynamicFieldTypes } from '@mt-ng2/dynamic-form';

import { IUserRole } from '@model/interfaces/user-role.d';
import { UserRoleService } from '../user-role.service';
import { IUserRoleServiceFollowupsStatus } from '@model/interfaces/custom/userole-service-followups-status';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IFollowupType } from '@model/interfaces/followup-type';
import { IServiceStatus } from '@model/interfaces/service-status';
import { ServiceType } from './../../common/constants/Enums';
import { IServicePartial } from '@model/partials/service.d.partial';
import { ServiceService } from '../../imed-claims/services/service.service';

@Component({
    selector: 'app-user-role-services',
    templateUrl: './user-role-services.component.html',
})
export class UserRoleServicesComponent implements OnInit {
    @Input() canEdit: boolean;
    @Input() userRole: IUserRole;
    @Output() updateServices: EventEmitter<string> = new EventEmitter<string>();
    userRoleServiceForm: UntypedFormGroup;
    serviceTypes: IServicePartial[];
    abstractUserRoleServiceForm: any;
    abstractUserRoleServiceView: any;
    isEditing: boolean = false;
    formCreated: boolean = false;

    services: IServicePartial[];
    includedServices: IServicePartial[];
    includedfollowupTypes: IFollowupType[] = [];
    includedServiceStatusTypes: IServiceStatus[] = [];
    saveObject: IUserRoleServiceFollowupsStatus;
    ServiceType = ServiceType;
    isHeaderForCourRelatedServiceShown = false;

    constructor(
        private serviceService: ServiceService,
        private userRoleService: UserRoleService,
        private notificationService: NotificationsService,
    ) { }

    ngOnInit(): void {
        this.serviceService.getAll().subscribe((services: IServicePartial[]) => {
            this.services = services.sort((a, b) => (a.Sort < b.Sort ? -1 : 1));

            this.includedServices = this.services
                .filter((service) => this.userRole.UserRoleServices.some((item) => item.ServiceId === service.Id))
                .map((service) => {
                    return { ...service };
                });

            this.services.forEach((item) => {
                this.setShowCourtRelatedHeader(item);
            });
        });
    }

    setShowCourtRelatedHeader(service: IServicePartial): void {
        if (!this.isHeaderForCourRelatedServiceShown && service.IsCourtRelatedService) {
            this.isHeaderForCourRelatedServiceShown = service.IsCourtRelatedService;
            service.ShowCourtRelatedHeaderShowing = true;
        } else {
            service.ShowCourtRelatedHeaderShowing = false;
        }
    }

    getValueForDynamicField(serviceTypeObject: IServicePartial | IFollowupType | IServiceStatus, type: ServiceType): boolean {
        if (type === ServiceType.Service) {
            return this.includedServices.some((item) => item.Id === serviceTypeObject.Id);
        } else if (type === ServiceType.Followups) {
            return this.includedfollowupTypes.some((item) => item.Id === serviceTypeObject.Id);
        } else {
            return this.includedServiceStatusTypes.some((item) => item.Id === serviceTypeObject.Id);
        }
    }

    getDynamicField(serviceTypeObject: IServicePartial, type: ServiceType): DynamicField {
        const Value: boolean = this.getValueForDynamicField(serviceTypeObject, type);
        return new DynamicField({
            formGroup: null,
            label: type === ServiceType.Service ? serviceTypeObject.Alias : serviceTypeObject.Name,
            name: 'serviceTypeObject',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Checkbox,
            } as IDynamicFieldType),
            value: Value,
        } as IDynamicField);
    }

    addItem(arrayList: (IServicePartial | IFollowupType | IServiceStatus)[], item: IServicePartial | IFollowupType | IServiceStatus): void {
        arrayList.push(item);
    }

    removeItem(arrayList: (IServicePartial | IFollowupType | IServiceStatus)[], object: IServicePartial | IFollowupType | IServiceStatus): void {
        const i = arrayList.findIndex((item) => item.Id === object.Id);
        arrayList.splice(i, 1);
    }

    setServiceCheck(serviceTypeObject: IServicePartial | IFollowupType | IServiceStatus, checked: boolean, type: number): void {
        if (checked) {
            if (type === +ServiceType.Service) {
                this.addItem(this.includedServices, serviceTypeObject);
            } else if (type === +ServiceType.Followups) {
                this.addItem(this.includedfollowupTypes, serviceTypeObject);
            } else {
                this.addItem(this.includedServiceStatusTypes, serviceTypeObject);
            }
        } else {
            if (type === +ServiceType.Service) {
                this.removeItem(this.includedServices, serviceTypeObject);
            } else if (type === +ServiceType.Followups) {
                this.removeItem(this.includedfollowupTypes, serviceTypeObject);
            } else {
                this.removeItem(this.includedServiceStatusTypes, serviceTypeObject);
            }
        }
    }

    serviceIncluded(service: IServicePartial): boolean {
        return this.includedServices.some((item) => item.Id === service.Id);
    }

    formSubmitted(): void {
        const serviceIds = this.includedServices.map((item) => item.Id);
        this.includedfollowupTypes = this.includedfollowupTypes.filter((item) => {
            return serviceIds.includes(item.ServiceId);
        });
        this.includedServiceStatusTypes = this.includedServiceStatusTypes.filter((item) => {
            return serviceIds.includes(item.ServiceId);
        });
        this.saveObject = {
            FollowupType: this.includedfollowupTypes,
            Service: this.includedServices,
            ServiceStatus: this.includedServiceStatusTypes,
        };

        // Making  call and then emit event for container component to update userRole Object.
        this.userRoleService.updateUserRoleServices(this.userRole.Id, this.saveObject).subscribe(() => {
            this.notificationService.success('Successfully saved.');
        });
    }
}
