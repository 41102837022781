import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, IRoleGuarded, ClaimValues } from '@mt-ng2/auth-module';
import { EmailTemplateDetailComponent } from './email-template-detail/email-template-detail.component';
import { EmailTemplatesComponent } from './email-template-list/email-templates.component';
import { EmailTemplateService } from './emailtemplate.service';
import { EmailTemplateHeaderComponent } from './email-template-header/email-template-header.component';
import { ClaimTypes } from '@model/ClaimTypes';

const emailTemplateEntityConfig = {
    claimType: ClaimTypes.EmailTemplates,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'emailTemplateId',
    service: EmailTemplateService,
    title: 'EmailTemplate Detail',
};

const emailTemplateListRoleGuard = {
    claimType: ClaimTypes.EmailTemplates,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'EmailTemplates',
};

const emailTemplateAddRoleGuard = {
    claimType: ClaimTypes.EmailTemplates,
    claimValues: [ClaimValues.FullAccess],
};

const emailTemplateRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: EmailTemplatesComponent,
        data: emailTemplateListRoleGuard,
        path: 'email-templates',
    },
    {
        canActivate: [AuthGuard],
        children: [
            {
                component: EmailTemplateDetailComponent,
                path: '',
                pathMatch: 'full',
            },
        ],
        component: EmailTemplateHeaderComponent,
        data: emailTemplateAddRoleGuard,
        path: 'email-templates/add',
    },
    {
        canActivate: [AuthGuard],
        children: [
            {
                component: EmailTemplateDetailComponent,
                path: '',
                pathMatch: 'full',
            },
        ],
        component: EmailTemplateHeaderComponent,
        data: emailTemplateEntityConfig,
        path: `email-templates/:${emailTemplateEntityConfig.entityIdParam}`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(emailTemplateRoutes)],
})
export class EmailTemplateRoutingModule { }
