import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmailAddressService } from '@model/shared-entities/email-addresses/email-address.service';
import { AuthService } from '../../../../node_modules/@mt-ng2/auth-module';

@Injectable()
export class AddressBookEmailService extends EmailAddressService {
    constructor(public http: HttpClient, public authService: AuthService) {
        super('/emails/address-books', http, authService);
    }
}
