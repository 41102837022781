import { ISubService } from '@model/interfaces/sub-service.d';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StaticMetaItemService } from '@mt-ng2/base-service';

@Injectable()
export class SubServiceService extends StaticMetaItemService<ISubService> {
    constructor(public http: HttpClient) {
        super('ServiceService', 'Service', 'ServiceIds', '/options/subservices', http);
    }
}
