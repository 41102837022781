import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { IAddressBookSelectEntity } from '../address-books-select-entity';
import { AddressBooksSelectService } from '../address-books-select.service';

@Component({
    selector: 'selected-address-book-display',
    templateUrl: './selected-address-book-display.component.html',
})
export class SelectedAddressBookDisplayComponent {
    @Input() selectedAddressBooks: IAddressBookSelectEntity[];
    @Output() onAddressBookRemoved = new EventEmitter<IAddressBookSelectEntity>();
    @Output() onClose = new EventEmitter<any>();

    constructor(private addressBookSelectService: AddressBooksSelectService) {}

    removeAddressBook(addressBook: IAddressBookSelectEntity): void {
        this.onAddressBookRemoved.emit(addressBook);
    }

    close(): void {
        this.addressBookSelectService.resetAppState();
        this.onClose.emit();
    }
}
