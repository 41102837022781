import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Chart } from 'chart.js';
import { DashboardService, IProductivity, INewService, IActiveService, ITrendingService } from '../dashboard.service';
import { SearchParams, IEntitySearchParams, ExtraSearchParams } from '@mt-ng2/common-classes';
import { CommonSearchService, ICommonSearch } from '../../common/services/common-search.service';

@Component({
    selector: 'app-new-services-widget',
    styleUrls: ['../base-dashboard-component/base-dashboard-common-css.component.less'],
    templateUrl: './new-services-widget.component.html',
})
export class NewServicesWidgetComponent implements OnInit {
    @ViewChild('newServiceChart') newServiceChartRef: ElementRef;

    newServiceChart: any;
    newService: INewService[];
    chart;

    constructor(private router: Router, private dashboardService: DashboardService, private commonSearchService: CommonSearchService) {}

    // https://stackoverflow.com/questions/39475891/add-dataset-bar-chart-chart-js if they want all service to show
    // TODO : Figure out re-rendering issue - https://stackoverflow.com/questions/28609932/chartjs-resizing-very-quickly-flickering-on-mouseover

    renderNewServiceChart(): void {
        this.dashboardService.getNewService().subscribe((responses: INewService[]) => {
            this.newService = responses;

            // let labels = responses.map((response: INewService) => response.Type);
            const yesterdayData = responses
                .filter((response) => response.Yesterday === true)
                .map((response: INewService) => this.getNewServiceObject(response));
            const thisWeekData = responses
                .filter((response) => response.ThisWeek === true)
                .map((response: INewService) => this.getNewServiceObject(response));
            yesterdayData.push(0);
            thisWeekData.push(0);

            if (this.newServiceChart) {
                this.newServiceChart.destroy();
            }

            this.newServiceChart = new Chart(this.newServiceChartRef.nativeElement, {
                data: {
                    datasets: [
                        {
                            backgroundColor: ['#f7dcdc', '#f7dcdc', '#f7dcdc'],
                            data: yesterdayData,
                            label: 'Yesterday',
                        },
                        {
                            backgroundColor: ['#b5fcd9', '#b5fcd9', '#b5fcd9'],
                            data: thisWeekData,
                            label: 'This Week',
                        },
                    ],
                    // Sort Id is used to order groupings, needs to be updated on front end and backend if client wants the order changed
                    labels: ['Examinations & Reviews', 'Retrievals', 'Court Related Service'],
                },
                options: {
                    legend: {
                        display: true,
                        onHover: (e) => (e.target.style.cursor = 'pointer'),
                    },
                    onHover: (e, el) => {
                        e.target.style.cursor = el[0] ? 'pointer' : 'default';
                    },
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                    precision: 0,
                                },
                            },
                        ],
                    },
                    title: {
                        display: true,
                        text: 'New Services',
                    },
                },
                type: 'bar',
            });
        });
    }

    getNewServiceObject(response: INewService): any {
        return { y: response.Count, newService: response };
    }

    ngOnInit(): void {
        this.renderNewServiceChart();
    }

    showNewServiceData(evt: any): void {
        const outerData = this.newServiceChart.getElementsAtEvent(evt);
        const innerData = this.newServiceChart.getDatasetAtEvent(evt);
        if (outerData.length) {
            const innerPoint = innerData[0];
            const outerPoint = outerData[0];
            const value = this.newServiceChart.data.datasets[innerPoint._datasetIndex].data[outerPoint._index];
            const _extraSearchParams: ExtraSearchParams[] = [];
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'newServiceChartType',
                    value: value.newService.SortId.toString(),
                }),
            );

            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'yesterday',
                    value: value.newService.Yesterday.toString(),
                }),
            );

            const searchEntity: IEntitySearchParams = {
                extraParams: _extraSearchParams,
                order: 'ServiceType',
                orderDirection: 'Asc',
                query: '',
            };

            const searchFilter: ICommonSearch = {
                dashboardName: 'New Services',
                searchFilters: new SearchParams(searchEntity),
            };

            this.commonSearchService.setCommonSearchFilters(searchFilter);
            void this.router.navigate(['/cases/0/services']);
        }
    }
}
