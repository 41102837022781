import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { IImedClaimAdditionalBillToPartyDTO } from '@model/interfaces/custom/imed-claim-additional-btp.dto';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ImedClaimServiceSplitBillingDetailService } from './imed-claim-service-split-billing-detail.service';
import { IImedClaimServiceSplitBillingDetailDTO } from '@model/interfaces/custom/imed-claim-service-split-billing-detail.dto';

@Component({
    selector: 'app-split-billing-details',
    styles: ['.custom-padded { margin: 0 0 0.5em 0; }'],
    templateUrl: './imed-claim-service-split-billing-detail.component.html',
})
export class ImedClaimServiceSplitBillingDetailComponent implements OnInit {
    @Input() imedClaimServiceId: number;
    @Input() canEdit: boolean;
    isHovered = false;
    doubleClickIsDisabled = false;

    splitBillingDetails: IImedClaimServiceSplitBillingDetailDTO[] = [];
    _imedClaimServiceSplitBillingDetailService: ImedClaimServiceSplitBillingDetailService;

    constructor(
        private imedClaimServiceSplitBillingDetailService: ImedClaimServiceSplitBillingDetailService,
        private notificationsService: NotificationsService,
    ) {
        this._imedClaimServiceSplitBillingDetailService = imedClaimServiceSplitBillingDetailService;
    }

    ngOnInit(): void {
        this.getSplitBillingDetails();
    }

    getSplitBillingDetails(): void {
        this.imedClaimServiceSplitBillingDetailService
            .getSplitBillingDetails(this.imedClaimServiceId)
            .subscribe((incomingDTOs: IImedClaimServiceSplitBillingDetailDTO[]) => {
                this.splitBillingDetails = incomingDTOs;
            });
    }

    remove(item: IImedClaimAdditionalBillToPartyDTO, event: Event): void {
        this.imedClaimServiceSplitBillingDetailService.delete(item.Id).subscribe(() => {
            this.getSplitBillingDetails();
            this.notificationsService.success('Split Billing Detail deleted successfully');
        });
    }
}
