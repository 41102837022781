import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CkEditorSingletonService {
  private hasLoadedNamespace = false;

  nameSpaceAlreadyLoaded(): boolean {
    if (!this.hasLoadedNamespace) {
      this.hasLoadedNamespace = true;
      return true;
    }
    return false;
  }

  indicateCkeditorAsAlreadyUsed(): void {
    if (!this.hasLoadedNamespace) {
        this.hasLoadedNamespace = true;
    }
  }
}
