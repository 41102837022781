import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '@model/ClaimTypes';
import { BaseDashboardComponent } from './base-dashboard-component/base-dashboard.component';
import { DashboardHeaderComponent } from './dashboard-header/dashboard-header.component';
import { DashboardService } from './dashboard.service';

const dashboardRoleGuard = {
    claimType: ClaimTypes.CaseManagement,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Dashboard',
};

const dashboardEntityConfig = {
    claimType: ClaimTypes.CaseManagement,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'dashboardType',
    service: DashboardService,
    title: 'Dashboard',
};

const dashboardRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        children: [{ path: '', component: BaseDashboardComponent, pathMatch: 'full' }],
        component: DashboardHeaderComponent,
        data: dashboardRoleGuard,
        path: 'dashboard',
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(dashboardRoutes)],
})
export class DashboardRoutingModule { }
