import { Subscription, forkJoin } from 'rxjs';
import { IUser } from '@model/interfaces/user.d';
import { IFilmReviewService } from '@model/interfaces/film-review-service.d';
import { AuthService, ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IImedClaimServiceContactInfo } from './../../../../imed-claim-basic-info/imed-claim-contacts-info';
import { Component, Input, OnInit, ViewChild, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { ImedClaimServiceService } from '../../../imedclaimservice.service';
import { IImedClaimService } from '@model/interfaces/imed-claim-service';
import { UserService } from '../../../../../users/user.service';
import { IServicePayload } from '@model/interfaces/custom/update-service-payload';
import { finalize } from 'rxjs/operators';
import { ClaimTypes } from '@model/ClaimTypes';
import { FilmReviewServiceDynamicConfig } from '../../../common-components/film-review/film-review-service.dynamic-config';
import { FilmReviewServiceService } from '../../../common-components/film-review/film-review-service.service';
import { ImedClaimTypes } from '@model/ImedClaimTypes';
import { INote } from '@mt-ng2/note-control';
import { ImedClaimAdjusterInquiriesComponent } from '../../../common-components/adjuster-inquiry-notes/adjuster-inquiry-notes.component';
import { ImedClaimServiceNotesService } from '../../../imed-claim-service-notes.service';

@Component({
    selector: 'app-auto-film-review-service-basic-info',
    templateUrl: './auto-film-review-service-basic-info.component.html',
})
export class AutoFilmReviewServiceBasicInfoComponent implements OnInit, OnChanges {
    @Input() iImedClaimServiceModel: IImedClaimService;
    @Input() filmReviewServiceModel: IFilmReviewService;
    @Input() canEdit: boolean;
    @Input() bodyPartsToBeExamined: string;
    @Input() reportDueBy: string;
    @Input() iImedClaimServiceContactInfo: IImedClaimServiceContactInfo;
    @Input() disablePhysicianFacilityControl: boolean;
    @Output('onRefreshStatus') onRefreshStatus: EventEmitter<any> = new EventEmitter<any>();
    @Output('physicianFaxSent') physicianFaxSent: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('adjusterInquiries') adjusterInquiries: ImedClaimAdjusterInquiriesComponent;

    notesService = ImedClaimServiceNotesService;

    isEditing: boolean;
    isHovered: boolean;
    formRendered = false;

    filmReviewServiceFirstConfig: any = {};
    filmReviewServiceSecondConfig: any = {};

    filmReviewServiceForm: FormGroup;

    filmReviewFormFirstFactory: FilmReviewServiceDynamicConfig<IFilmReviewService>;
    filmReviewFormSecondFactory: FilmReviewServiceDynamicConfig<IFilmReviewService>;

    doubleClickIsDisabled = false;

    users = [];
    selectedDiscCopiedUser: IUser;
    selectedDiscMailedUser: IUser;
    imedClaimServiceId: number;
    ServiceId: number;
    subscriptions = new Subscription();
    canSeeAuditLog = false;

    selectedReportAssignedToUser: IUser;
    reportAssignedToUsers = [];
    invalidReportAssignedTo = false;

    adjusterInquiriesArray: INote[];
    initialImedClaimServiceContactInfo: IImedClaimServiceContactInfo;

    constructor(
        private imedClaimServiceService: ImedClaimServiceService,
        private notificationsService: NotificationsService,
        private fb: UntypedFormBuilder,
        private authService: AuthService,
        private filmReviewService: FilmReviewServiceService,
        private userService: UserService,
        private claimsService: ClaimsService,
    ) {}

    ngOnInit(): void {
        this.isEditing = false;
        this.canSeeAuditLog = this.claimsService.hasClaim(ClaimTypes.Audit, [ClaimValues.FullAccess, ClaimValues.ReadOnly]);

        this.userService.getAll().subscribe((users) => {
            this.users = users;
            this.setConfig();
        });

        this.formRendered = true;

        this.subscriptions.add(
            this.imedClaimServiceService.changeEmitted$.subscribe((iImedClaimServiceModel) => {
                this.iImedClaimServiceModel = iImedClaimServiceModel;
                this.setConfig();
            }),
        );

        this.adjusterInquiriesArray = this.iImedClaimServiceModel.Notes;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.iImedClaimServiceContactInfo) {
            this.initialImedClaimServiceContactInfo = { ...this.iImedClaimServiceContactInfo };
        }
    }

    reloadAdjusterInquiries(): void {
        this.adjusterInquiriesArray = this.adjusterInquiries.NoteArray;
    }

    saveAllAdjusterInquiries(): void {
        this.filmReviewService.updateAdjusterInquiries(this.iImedClaimServiceModel.Id, this.adjusterInquiries.NoteArray).subscribe();
        this.adjusterInquiries.getNotes();
        this.adjusterInquiriesArray = this.adjusterInquiries.NoteArray;
    }

    adjusterInquiryDeleted(): void {
        this.notificationsService.success('Adjuster Inquiry Successfully Deleted');
    }

    adjusterInquiryNotDeleted(): void {
        this.notificationsService.error('An error occurred while deleting Adjuster Inquiry');
    }

    setConfig(): void {
        if (this.filmReviewServiceModel.DiscCopiedById > 0) {
            this.selectedDiscCopiedUser = this.users.filter((x) => x.Id === this.filmReviewServiceModel.DiscCopiedById)[0];
        } else {
            this.selectedDiscCopiedUser = this.users.filter((x) => x.Id === this.authService.currentUser.getValue().Id)[0];
        }

        if (this.filmReviewServiceModel.DiscCopiedById > 0) {
            this.selectedDiscMailedUser = this.users.filter((x) => x.Id === this.filmReviewServiceModel.DiscMailedById)[0];
        } else {
            this.selectedDiscMailedUser = this.users.filter((x) => x.Id === this.authService.currentUser.getValue().Id)[0];
        }

        this.filmReviewServiceForm = this.fb.group({ FilmReviewService: this.fb.group({}) });

        this.filmReviewServiceFirstConfig = { formObject: [], viewOnly: [] };
        this.filmReviewServiceSecondConfig = { formObject: [], viewOnly: [] };

        this.filmReviewFormFirstFactory = new FilmReviewServiceDynamicConfig<IFilmReviewService>(this.filmReviewServiceModel, [
            'RequestDate',
            'DiscCopiedDate',
        ]);

        this.filmReviewFormSecondFactory = new FilmReviewServiceDynamicConfig<IFilmReviewService>(this.filmReviewServiceModel, [
            'OriginalFiledForReference',
            'DiscMailedDate',
        ]);

        this.filmReviewServiceFirstConfig = this.filmReviewFormFirstFactory.getForUpdate();
        this.filmReviewServiceSecondConfig = this.filmReviewFormSecondFactory.getForUpdate();
        this.formRendered = true;
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        this.isEditing = false;
        this.selectedReportAssignedToUser = this.reportAssignedToUsers.find((u) => u.Id === this.iImedClaimServiceModel.ServiceAssignedToId);
        this.adjusterInquiriesArray = this.adjusterInquiries._savedNotes;
        this.iImedClaimServiceContactInfo = { ...this.initialImedClaimServiceContactInfo };
    }

    formSubmitted(): void {
        const invalidServiceAddress = this.iImedClaimServiceContactInfo.PhysicianId > 0 && !this.iImedClaimServiceContactInfo.AddressId;

        // TODO:invalidReportAssignedTo get from ImedClaim & make a check if needed in future
        if (this.filmReviewServiceForm.valid && !this.invalidReportAssignedTo && !invalidServiceAddress) {
            this.iImedClaimServiceModel = this.imedClaimServiceService.assignContactsToImedClaimService(
                this.iImedClaimServiceModel,
                this.iImedClaimServiceContactInfo,
            );
            this.filmReviewFormFirstFactory.assignFormValues(
                this.filmReviewServiceModel,
                this.filmReviewServiceForm.value.FilmReviewService as IFilmReviewService,
            );
            this.filmReviewServiceModel.RequestDateChangedById = this.authService.currentUser.getValue().Id;
            this.filmReviewServiceModel.RequestDateChangedDate = new Date();

            this.iImedClaimServiceModel.ServiceAssignedToId =
                this.selectedReportAssignedToUser && this.selectedReportAssignedToUser.Id ? this.selectedReportAssignedToUser.Id : null;

            const updatePayload = <IServicePayload<IFilmReviewService>>{};
            this.filmReviewServiceModel.DiscCopiedById = this.selectedDiscCopiedUser.Id;
            this.filmReviewServiceModel.DiscMailedById = this.selectedDiscMailedUser.Id;
            updatePayload.ChildService = this.filmReviewServiceModel;
            updatePayload.ParentService = this.iImedClaimServiceModel;

            this.saveAllAdjusterInquiries();

            this.filmReviewService.updateService(updatePayload).subscribe(() => {
                forkJoin(
                    this.imedClaimServiceService.getById(this.iImedClaimServiceModel.Id),
                    this.filmReviewService.getByImedClaimServiceId(this.iImedClaimServiceModel.Id),
                ).subscribe((response) => {
                    const [imedClaimServiceResponse, filmReviewServiceResponse] = response;
                    this.filmReviewServiceModel = filmReviewServiceResponse;
                    this.iImedClaimServiceModel = imedClaimServiceResponse;
                    this.imedClaimServiceService.emitChange(this.iImedClaimServiceModel);
                    this.filmReviewService.emitChange(this.filmReviewServiceModel);
                    this.isEditing = false;
                    this.success();
                    this.setConfig();
                });
            });
        } else {
            markAllFormFieldsAsTouched(this.filmReviewServiceForm);
            this.error();
        }
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }
}
