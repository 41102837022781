import { AbstractControl } from '@angular/forms';

// Validates that list of email addresses ontains only valid emails, for use in reactive form controls
export function ValidateEmailAddressList(control: AbstractControl): any {
    // This regex tests for email addresses and ignores trailing commas
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const emails: string[] =
        control.value && control.value !== ''
            ? control.value
                  .split(/[,;]+/)
                  .map((email) => email.trim())
                  .filter((email) => email !== '')
            : null;
    if (emails && !emails.every((email) => emailRegex.test(email) === true)) {
        return { invalidEmails: true };
    }
    return null;
}
