import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IEmailType } from '../../interfaces/email-type';

@Injectable()
export class EmailTypeService extends StaticMetaItemService<IEmailType> {
    constructor(public http: HttpClient) {
        super('EmailTypeService', 'Type', 'TypeIds', '/options/emailTypes', http);
    }
}
