import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IImeAppointment } from '../interfaces/ime-appointment';
import { IUser } from '../interfaces/user';
import { IImedClaimService } from '../interfaces/imed-claim-service';

export interface IImeAppointmentDynamicControlsParameters {
    formGroup?: string;
    imedClaimServices?: IImedClaimService[];
    createdBies?: IUser[];
    modifiedBies?: IUser[];
}

export class ImeAppointmentDynamicControls {

    formGroup: string;
    imedClaimServices: IImedClaimService[];
    createdBies: IUser[];
    modifiedBies: IUser[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private imeappointment?: IImeAppointment, additionalParameters?: IImeAppointmentDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'ImeAppointment';
        this.imedClaimServices = additionalParameters && additionalParameters.imedClaimServices || undefined;
        this.createdBies = additionalParameters && additionalParameters.createdBies || undefined;
        this.modifiedBies = additionalParameters && additionalParameters.modifiedBies || undefined;

        this.Form = {
            AppointmentDateTime: new DynamicField({
                formGroup: this.formGroup,
                label: 'Appointment Date Time',
                name: 'AppointmentDateTime',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.imeappointment && this.imeappointment.AppointmentDateTime || null,
            }),
            Archived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived',
                name: 'Archived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imeappointment && this.imeappointment.hasOwnProperty('Archived') && this.imeappointment.Archived !== null ? this.imeappointment.Archived : false,
            }),
            CreatedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Created By',
                name: 'CreatedById',
                options: this.createdBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.imeappointment && this.imeappointment.CreatedById || null,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imeappointment && this.imeappointment.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imeappointment && this.imeappointment.DateModified || null,
            }),
            ImedClaimServiceId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Imed Claim Service',
                name: 'ImedClaimServiceId',
                options: this.imedClaimServices,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.imeappointment && this.imeappointment.ImedClaimServiceId || null,
            }),
            LegacyAppointmentId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Legacy Appointment',
                name: 'LegacyAppointmentId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imeappointment && this.imeappointment.LegacyAppointmentId || null,
            }),
            ModifiedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Modified By',
                name: 'ModifiedById',
                options: this.modifiedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imeappointment && this.imeappointment.ModifiedById || null,
            }),
            Notes: new DynamicField({
                formGroup: this.formGroup,
                label: 'Notes',
                name: 'Notes',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imeappointment && this.imeappointment.hasOwnProperty('Notes') && this.imeappointment.Notes !== null ? this.imeappointment.Notes.toString() : '',
            }),
            Timezone: new DynamicField({
                formGroup: this.formGroup,
                label: 'Timezone',
                name: 'Timezone',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.imeappointment && this.imeappointment.hasOwnProperty('Timezone') && this.imeappointment.Timezone !== null ? this.imeappointment.Timezone.toString() : '',
            }),
        };

        this.View = {
            AppointmentDateTime: new DynamicLabel({
			    label: 'Appointment Date Time',
			    value: this.imeappointment && this.imeappointment.AppointmentDateTime || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            Archived: new DynamicLabel({
			    label: 'Archived',
			    value: this.imeappointment && this.imeappointment.hasOwnProperty('Archived') && this.imeappointment.Archived !== null ? this.imeappointment.Archived : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            CreatedById: new DynamicLabel({
			    label: 'Created By',
			    value: getMetaItemValue(this.createdBies as unknown as IMetaItem[], this.imeappointment && this.imeappointment.hasOwnProperty('CreatedById') && this.imeappointment.CreatedById !== null ? this.imeappointment.CreatedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            DateCreated: new DynamicLabel({
			    label: 'Date Created',
			    value: this.imeappointment && this.imeappointment.DateCreated || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DateModified: new DynamicLabel({
			    label: 'Date Modified',
			    value: this.imeappointment && this.imeappointment.DateModified || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            ImedClaimServiceId: new DynamicLabel({
			    label: 'Imed Claim Service',
			    value: getMetaItemValue(this.imedClaimServices as unknown as IMetaItem[], this.imeappointment && this.imeappointment.hasOwnProperty('ImedClaimServiceId') && this.imeappointment.ImedClaimServiceId !== null ? this.imeappointment.ImedClaimServiceId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            LegacyAppointmentId: new DynamicLabel({
			    label: 'Legacy Appointment',
			    value: this.imeappointment && this.imeappointment.LegacyAppointmentId || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: NumericInputTypes.Integer,
			        scale: null,
			    })
			}
            ),
            ModifiedById: new DynamicLabel({
			    label: 'Modified By',
			    value: getMetaItemValue(this.modifiedBies as unknown as IMetaItem[], this.imeappointment && this.imeappointment.hasOwnProperty('ModifiedById') && this.imeappointment.ModifiedById !== null ? this.imeappointment.ModifiedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Notes: new DynamicLabel({
			    label: 'Notes',
			    value: this.imeappointment && this.imeappointment.hasOwnProperty('Notes') && this.imeappointment.Notes !== null ? this.imeappointment.Notes.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Textarea,
			        scale: null,
			    })
			}
            ),
            Timezone: new DynamicLabel({
			    label: 'Timezone',
			    value: this.imeappointment && this.imeappointment.hasOwnProperty('Timezone') && this.imeappointment.Timezone !== null ? this.imeappointment.Timezone.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };

    }
}
