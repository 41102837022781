import { IImedClaimService } from '@model/partials/imed-claim-service.partial.d';
import { CommunicationLogService } from '../../common/services/communication-log.service';
import { IImeAppointment } from '@model/interfaces/ime-appointment.d';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { SearchParams } from '@mt-ng2/common-classes';
import { MetaItemService } from '@mt-ng2/base-service';
import { IViewGetServicesForList } from '@model/interfaces/custom/view-get-services-for-list';
import { IImedClaimServiceContactInfo } from '../imed-claim-basic-info/imed-claim-contacts-info';
import { PhysicianStatusesIdEnums, ServiceIdEnums } from '../../common/constants/Enums';
import { IImedClaimServiceStatusValidationDTO } from '@model/interfaces/custom/imed-claim-service-status-validation-dto';
import { IFaxEmailDataDTO } from '../../common/select-contact-faxes/select-contact-faxes.component';
import { IModalResult, ModalService } from '@mt-ng2/modal-module';
import { IPhysicianDetail } from '@model/interfaces/physician-detail';
import { ImedClaimServiceBillDocumentsUploadStatusDTO } from '@model/interfaces/imedclaimservice-bill-doc-upload-status';

export const emptyImedClaimService: IImedClaimService = {
    Archived: false,
    CreatedById: 0,
    DateCreated: null,
    HighValueService: false,
    Id: 0,
    ImedClaimId: 0,
    PhysicianId: null,
    RushService: false,
    ServiceAssignedToId: null,
    ServiceId: 0,
    ServiceStatusId: 0,
};

@Injectable()
export class ImedClaimServiceService extends MetaItemService<any> {
    imeServiceTypes: Map<number, string> = new Map();
    canDelete: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    showCaseInfo: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    setCanDelete(value: boolean): void {
        this.canDelete.next(value);
    }
    setShowCaseInfo(value: boolean): void {
        this.showCaseInfo.next(value);
    }
    getShowCaseInfo(): Observable<boolean> {
        return this.showCaseInfo.asObservable();
    }

    constructor(public http: HttpClient, public communicationLogService: CommunicationLogService, private modalService: ModalService) {
        super('ImedClaimServiceService', 'Service', 'ServiceIds', '/imedclaimservices', http);
        this.getIMEserviceTypes();
    }

    getIMEserviceTypes(): void {
        const names = Object.values(ServiceIdEnums).filter((val) => typeof val === 'string') as string[];
        const vals = Object.values(ServiceIdEnums).filter((val) => typeof val === 'number') as number[];

        for (let i = 0; i < names.length; i++) {
            if (names[i].substring(0, 3) === 'IME') {
                this.imeServiceTypes.set(vals[i], names[i]);
            }
        }
    }

    getLatestAppointmentByImedClaimServiceId(imedClaimId: number): Observable<IImeAppointment> {
        return this.http.get<IImeAppointment>(`/imedclaimservices/${imedClaimId}/latestappointment`, {});
    }

    getEmptyImedClaimService(): IImedClaimService {
        return { ...emptyImedClaimService };
    }

    searchImedClaimServices(imedClaimId: number, data: SearchParams): any {
        const requestParams = this.getHttpParams(data);
        const requestPayload = {};
        requestParams.keys().forEach(key => {
            requestPayload[key] = requestParams.get(key);
        });
        return this.http.post<IViewGetServicesForList>(`/imedclaimservices/${imedClaimId}/_searchServices`, requestPayload,{ observe: 'response' });
    }

    archive(id: number, userId: number): any {
        return this.http.put<number>(`/imedclaimservices/archive/${id}/user/${userId}`, {});
    }

    assignContactsToImedClaimService(imeClaimService: IImedClaimService, imedClaimServiceContact: IImedClaimServiceContactInfo): IImedClaimService {
        if (imedClaimServiceContact.PhysicianId) {
            imeClaimService.PhysicianId = imedClaimServiceContact.PhysicianId;
            imeClaimService.FacilityIndividualId = null;
            imeClaimService.FacilityAddressBookId = null;
        }
        if (imedClaimServiceContact.AddressBookId) {
            imeClaimService.FacilityAddressBookId = imedClaimServiceContact.AddressBookId;
            imeClaimService.FacilityIndividualId = imedClaimServiceContact.IndividualId;
            imeClaimService.PhysicianId = null;
        }
        imeClaimService.ServiceAddressId = imedClaimServiceContact.AddressId;
        return imeClaimService;
    }

    assignImedClaimServicetoContacts(
        imedClaimServiceContact: IImedClaimServiceContactInfo,
        imeClaimService: IImedClaimService,
    ): IImedClaimServiceContactInfo {
        imedClaimServiceContact.PhysicianName = imeClaimService.Physician
            ? imeClaimService.Physician.Individual.FirstName + ' ' + imeClaimService.Physician.Individual.LastName
            : '';
        imedClaimServiceContact.PhysicianId = imeClaimService.PhysicianId;
        imedClaimServiceContact.AddressId = imeClaimService.ServiceAddressId;
        imedClaimServiceContact.IndividualId = imeClaimService.FacilityIndividualId;

        const individual =
            imeClaimService.FacilityIndividualId && imeClaimService.AddressBook
                ? imeClaimService.AddressBook.Individuals.find((x) => x.Id === imeClaimService.FacilityIndividualId)
                : null;
        if (individual && imeClaimService.AddressBook) {
            imedClaimServiceContact.AddressBookName = individual.FirstName + ' ' + individual.LastName;
        } else {
            imedClaimServiceContact.AddressBookName = imeClaimService.AddressBook ? imeClaimService.AddressBook.FacilityName : '';
        }

        imedClaimServiceContact.AddressBookId = imeClaimService.FacilityAddressBookId;
        return imedClaimServiceContact;
    }

    updateStatus(imedClaimService: IImedClaimServiceStatusValidationDTO): any {
        return this.http.put<number>(`/imedclaimservices/updateStatus`, imedClaimService);
    }

    checkForPreLitigationDocumentIsUploaded(Id): Observable<any> {
        return this.http.get(`/imedclaimservices/checkForLitigationDoc/${Id}`, {});
    }

    checkForAuthorizationDocument(Id): Observable<any> {
        return this.http.get(`/imedclaimservices/checkForAuthorizationDocument/${Id}`, {});
    }

    getAllContactFaxes(imedClaimServiceId: number): Observable<IFaxEmailDataDTO[]> {
        return this.http.get<IFaxEmailDataDTO[]>(`/imedclaimservices/${imedClaimServiceId}/all-contact-faxes`, {});
    }

    getAllContactEmails(imedClaimServiceId: number): Observable<IFaxEmailDataDTO[]> {
        return this.http.get<IFaxEmailDataDTO[]>(`/imedclaimservices/${imedClaimServiceId}/all-contact-emails`, {});
    }

    getAllContactEmailsForCase(imedClaimId: number): Observable<IFaxEmailDataDTO[]> {
        return this.http.get<IFaxEmailDataDTO[]>(`/imedclaimservices/imedclaim/${imedClaimId}/all-contact-emails`, {});
    }

    getAllContactFaxesForCase(imedClaimId: number): Observable<IFaxEmailDataDTO[]> {
        return this.http.get<IFaxEmailDataDTO[]>(`/imedclaimservices/imedclaim/${imedClaimId}/all-contact-faxes`, {});
    }

    getDefaultContactEmails(imedClaimId: number): Observable<string> {
        return imedClaimId ? this.http.get<string>(`/imedclaimservices/${imedClaimId}/default-contact-emails`, {}) : of('');
    }

    getChildrenForId(imedClaimServiceId: number): Observable<IImedClaimService[]> {
        return this.http.get<IImedClaimService[]>(`/imedclaimservices/children-for-id/${imedClaimServiceId}`);
    }

    getParentForId(imedClaimServiceId: number): Observable<IImedClaimService> {
        return this.http.get<IImedClaimService>(`/imedclaimservices/parent-for-id/${imedClaimServiceId}`);
    }

    getHardPopupMessages(imedClaimServiceModel: IImedClaimService, message = ''): Observable<IModalResult> {
        let bodyOfEmail = message;
        if (imedClaimServiceModel) {
            if (!(imedClaimServiceModel.ImedClaim.FileNumber || imedClaimServiceModel.ImedClaim.ClaimNumber)) {
                bodyOfEmail = 'Claim Number or File Number is required <br/>';
            }
            if (!imedClaimServiceModel.ImedClaim.DateofAllegedAccidentOrInjury) {
                bodyOfEmail = bodyOfEmail + 'Date of alleged accident or injury is required<br/>';
            }
            if (imedClaimServiceModel.Physician != null) {
                const physicianDetail: IPhysicianDetail = imedClaimServiceModel.Physician.PhysicianDetails.find(
                    (pd) => pd.ImedClaimTypeId === imedClaimServiceModel.ImedClaim.ClaimTypeId,
                );
                if (physicianDetail && physicianDetail.PhysicianStatusId !== +PhysicianStatusesIdEnums.Active) {
                    bodyOfEmail = bodyOfEmail + 'Physician is not active';
                }
            }
        }

        return this.modalService.showModal({
            cancelButtonColor: '#d33',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Save',
            focusConfirm: false,
            html: bodyOfEmail,
            showCancelButton: true,
            showCloseButton: false,
            showConfirmButton: false,
            title: '',
            width: 800,
        });
    }

    getSoftPopupMessages(message = ''): Observable<IModalResult> {
        return this.modalService.showModal({
            cancelButtonColor: '#d33',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Save',
            focusConfirm: false,
            html: message,
            showCancelButton: true,
            showCloseButton: false,
            showConfirmButton: true,
            title: 'Are you sure you want to continue?',
            width: 800,
        });
    }

    assignParentService(childServiceId: number, parentServiceId: number): Observable<unknown> {
        return this.http.post<unknown>(`/imedclaimservices/set-parent/${childServiceId}/user/${parentServiceId}`, {});
    }

    getPhysicianFacilityControlIsDisabled(entityId: number, serviceId: number): Observable<boolean> {
        return this.http.get<boolean>(`/imedclaimservices/${serviceId}/physician-facility-control-disabled/${entityId}`);
    }

    getAllImedClaimServicesByImedClaimId(imedClaimId: number): Observable<IImedClaimService[]> {
        return this.http.get<IImedClaimService[]>(`/imedclaimservices/all-imedclaim-services/${imedClaimId}`);
    }

    checkForPhysicianFacilityIndividual(imedClaimServiceId: number): Observable<boolean> {
        return this.http.get<boolean>(`/imedclaimservices/checkForPhysicianFacilityIndividual/${imedClaimServiceId}`, {});
    }

    checkForTranscriptionServiceVendor(imedClaimServiceId: number): Observable<boolean> {
        return this.http.get<boolean>(`/imedclaimservices/checkForTranscriptionServiceVendor/${imedClaimServiceId}`, {});
    }

    checkForTransportationServiceVendor(imedClaimServiceId: number): Observable<boolean> {
        return this.http.get<boolean>(`/imedclaimservices/checkForTransportationServiceVendor/${imedClaimServiceId}`, {});
    }

    checkForTranslationServiceVendor(imedClaimServiceId: number): Observable<boolean> {
        return this.http.get<boolean>(`/imedclaimservices/checkForTranslationServiceVendor/${imedClaimServiceId}`, {});
    }

    checkForBackgroundInvestigationORSurveillanceVendor(imedClaimServiceId: number): Observable<boolean> {
        return this.http.get<boolean>(`/imedclaimservices/checkForBackgroundInvestigationORSurveillanceVendor/${imedClaimServiceId}`, {});
    }

    checkForRentalFacility(imedClaimServiceId: number): Observable<boolean> {
        return this.http.get<boolean>(`/imedclaimservices/checkForRentalFacility/${imedClaimServiceId}`, {});
    }

    isRadiologyReviewService(imedClaimServiceId: number): Observable<boolean> {
        return this.http.get<boolean>(`/imedclaimservices/isRadiologyReviewService/${imedClaimServiceId}`, {});
    }

    checkBillDocumentsUploadStatus(imedClaimServiceId: number): Observable<ImedClaimServiceBillDocumentsUploadStatusDTO> {
        return this.http.get<ImedClaimServiceBillDocumentsUploadStatusDTO>(`/imedclaimservices/bill-documents-status/${imedClaimServiceId}`, {});
    }
}
