import { Component } from '@angular/core';
import { IEntityListDynamicCellComponent, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { IEntity } from '@mt-ng2/base-service';
import { IViewGetServicesForList } from '@model/interfaces/custom/view-get-services-for-list';

@Component({
    template: `
        <div *ngIf="imedClaimService">
            <span>
                {{ imedClaimService.ServiceType }}
            </span>
            <i
                *ngIf="imedClaimService.HasParent"
                tooltip
                tooltipType="service-parent"
                [entity]="imedClaimService"
                class="fa fa-user fa-lg"
                aria-hidden="true"
            ></i>
            <i
                *ngIf="imedClaimService.HasChildren"
                tooltip
                tooltipType="service-children"
                [entity]="imedClaimService"
                class="fa fa-users fa-lg"
                aria-hidden="true"
            ></i>
        </div>
    `,
})
export class ServiceTypeDynamicCellComponent implements IEntityListDynamicCellComponent {
    entityListComponentMembers: IEntityListComponentMembers;
    result;

    set entity(value: IEntity) {
        this.imedClaimService = value as IViewGetServicesForList;
    }

    imedClaimService: IViewGetServicesForList;

    constructor() {}
}
