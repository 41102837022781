import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { IViewLog } from '@model/interfaces/view-log';

export const emptyViewLog: IViewLog = {
    DateCreated: new Date(),
    EntityType: null,
    Id: 0,
    Route: '',
    UserId: 0,
};

@Injectable()
export class ViewLogService extends BaseService<IViewLog> {
    constructor(public http: HttpClient) {
        super('/viewlogs', http);
    }

    getEmptyViewLog(): IViewLog {
        return { ...emptyViewLog };
    }
}
