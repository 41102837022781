import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';

import { IPhysicianVacation } from '@model/interfaces/physician-vacation';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { PhysicianVacationService } from '../physicianvacation.service';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';

import { ClaimTypes } from '@model/ClaimTypes';
import { PhysicianService } from '../../physician.service';

@Component({
    selector: 'app-physician-vacation-detail',
    templateUrl: './physician-vacation-detail.component.html',
})
export class PhysicianVacationDetailComponent implements OnInit {
    physicianVacation: IPhysicianVacation;
    editingComponent: Subject<any> = new Subject();
    canEdit: boolean;
    canAdd: boolean;
    id: number;
    physicianId: number;
    individualId: number;
    addressBookId: number;

    constructor(
        private physicianVacationService: PhysicianVacationService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
        private physicianService: PhysicianService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        // check claims
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.AddressBooks, [ClaimValues.FullAccess]);
        this.canAdd = this.canEdit;
        // get current id from route
        this.individualId = +this.route.parent.parent.snapshot.paramMap.get('individualId');
        this.addressBookId = +this.route.parent.parent.parent.snapshot.paramMap.get('addressBookId');

        // Getting the physician Id
        this.physicianService.getByIndividualId(this.individualId).subscribe((answer) => {
            const physician = answer;
            this.physicianId = physician.Id;

            this.id = +this.route.snapshot.paramMap.get('physicianVacationId');
            // try load if id > 0
            if (this.id > 0) {
                this.getPhysicianVacationById(this.id);
            } else {
                // set physicianVacation to emptyPhysicianVacation
                this.physicianVacation = this.physicianVacationService.getEmptyPhysicianVacation();
                this.physicianVacation.PhysicianId = this.physicianId;
            }
            this.editingComponent.next('');
        });
    }

    getPhysicianVacationById(id: number): void {
        this.physicianVacationService.getById(id).subscribe((physicianVacation) => {
            this.physicianVacation = physicianVacation;
        });
    }

    cancelClick(): void {
        void this.router.navigate([`address-books/${this.addressBookId}/individuals/${this.individualId}/physician-vacations`]);
    }
}
