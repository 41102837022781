import { INote } from '@model/interfaces/base';
import { IEntityListConfig, EntityListConfig, EntityListColumn } from '@mt-ng2/entity-list-module';

export class ImedClaimAdjusterInquiriesListConfig extends EntityListConfig {
    constructor(disableSort?: boolean) {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['Title'],
                    name: 'Subject',
                    sort: {
                        disableSort: disableSort,
                    },
                }),
                new EntityListColumn({
                    accessors: ['NoteText'],
                    bindToInnerHtml: true,
                    name: 'Inquiry Details',
                    sort: {
                        disableSort: disableSort,
                    },
                }),
            ],
            delete: {
                confirm: true,
                width: 50,
            },
        };
        super(listConfig);
    }
}
