import { NotificationsService } from '@mt-ng2/notifications-module';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import 'rxjs/operators';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues, AuthService } from '@mt-ng2/auth-module';
import { PhysicianFollowupService } from '../physician-followup-log.service';
import { IPhysicianFollowupLog } from '@model/interfaces/physician-followup-log';
import { ClaimTypes } from '@model/ClaimTypes';
import { PhysicianFollowupsEntityListConfig } from './physician-followup.entity-list-config';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection, IItemDeletedEvent } from '@mt-ng2/entity-list-module';
import { entityListModuleConfig } from '../../../../common/shared.module';

@Component({
    selector: 'app-physician-followups',
    templateUrl: './physician-followup.component.html',
})
export class PhysicianFollowupsComponent implements OnInit {
    searchControl = new UntypedFormControl();
    physicianFollowupLogs: IPhysicianFollowupLog[];
    currentPage = 1;
    query = '';
    total: number;
    entityListConfig = new PhysicianFollowupsEntityListConfig();
    order = 'Id'; // this.entityListConfig.getDefaultSortProperty();
    orderDirection = 'desc';
    id: string;
    addressBookId: number;
    individualId: number;

    constructor(private physicianFollowupService: PhysicianFollowupService, private activatedRoute: ActivatedRoute, private router: Router) { }

    ngOnInit(): void {
        this.addressBookId = this.activatedRoute.parent.parent.snapshot.params.addressBookId;
        this.individualId = this.activatedRoute.parent.snapshot.params.individualId;
        this.getPhysicianFollowupLogs();
    }

    getPhysicianFollowupLogs(): void {
        const search = this.query;

        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'IndividualId', // We'll have to get the Physician later in the service
                value: this.individualId.toString(),
            }),
        );

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: entityListModuleConfig.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);

        this.physicianFollowupService.searchFollowups(this.individualId, searchparams).subscribe((answer) => {
            this.physicianFollowupLogs = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.query = query;
        this.currentPage = 1;
        this.getPhysicianFollowupLogs();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getPhysicianFollowupLogs();
    }

    cancelClick(): void {
        void this.router.navigate([`address-books/${this.addressBookId}/individuals/${this.individualId}`]);
    }
}
