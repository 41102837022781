import { DynamicField } from '@mt-ng2/dynamic-form';

import {
    PhysicianFollowupLogDynamicControls,
    IPhysicianFollowupLogDynamicControlsParameters,
} from '../form-controls/physician-followup-log.form-controls';
import { IPhysicianFollowupLog } from '../interfaces/physician-followup-log';

export class PhysicianFollowupLogDynamicControlsPartial extends PhysicianFollowupLogDynamicControls {
    constructor(physicianfollowuplogPartial?: IPhysicianFollowupLog, additionalParameters?: IPhysicianFollowupLogDynamicControlsParameters) {
        super(physicianfollowuplogPartial, additionalParameters);

        (<DynamicField>this.Form.FollowupDate).setRequired(true);
        (<DynamicField>this.Form.FollowupNotes).setRequired(true);
    }
}
