import { AddressBookDynamicControlsPartial } from '@model/partials/address-book-partial.form-controls';
import { AddressBookSelectionEnums, AddressBookTypeIdEnums } from './../../common/constants/Enums';
import { Component, OnInit, ChangeDetectorRef, Input, OnDestroy } from '@angular/core';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { IDynamicField } from '@mt-ng2/dynamic-form';
import { IndividualDynamicConfig } from '../individuals/individual.dynamic-config';
import { emptyIndividual, IndividualService } from '../individuals/individual.service';
import { AddressBookDynamicConfig } from '../address-book.dynamic-config';
import { emptyAddressBook, AddressBookService, ICreateAddressBookFormValue } from '../addressbook.service';
import { AddressBookTypeService } from '../addressbooktype.service';
import { IAddressBookType } from '@model/interfaces/address-book-type';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { IDuplicateAddressBook } from '@model/interfaces/custom/duplicate-address-book';
import { IAddressBook } from '@model/partials/address-book.partial';
import { Router } from '@angular/router';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { MetaAddressBookTypeIdEnums } from '../../common/constants/Enums';
import { DuplicatesConfirmService } from './duplicates-confirm.service';
import { trigger, style, transition, animate } from '@angular/animations';
import { AddressBooksSelectService } from '../../common/address-book-select-list/address-books-select.service';
import { finalize } from 'rxjs/operators';
import {
    ISelectionChangedEvent as ITypeAheadSelectionChangedEvent,
    VirtualTypeAheadGetItemsFunction,
    ITypeAheadAPI,
} from '@mt-ng2/type-ahead-control';
import { IAddressBookOptions } from '@model/interfaces/custom/address-book-options';
import { IEntitySearchParams, ExtraSearchParams } from '@mt-ng2/common-classes';
import { PhysicianService } from '../individuals/physicians/physician.service';
import { AuthService } from '@mt-ng2/auth-module';
import { trimObjectStringProperties } from '../../common/custom/trimObjectStringProperties';
import { IIndividual } from '@model/interfaces/individual';
import { IndividualDynamicControlsPartial } from '@model/partials/individual-partial.form-controls';
import { AddressTypeService } from '../addresses/addresstype.service';
import { IAddressType } from '@model/interfaces/address-type';

@Component({
    animations: [
        trigger('itemAnim', [
            transition(':enter', [
                style({ transform: 'translateY(100%)', opacity: 0 }),
                animate('500ms', style({ transform: 'translateY(0)', opacity: 1 })),
            ]),
            transition(':leave', [
                style({ transform: 'translateY(0)', opacity: 1 }),
                animate('500ms', style({ transform: 'translateY(100%)', opacity: 0 })),
            ]),
        ]),
    ],
    selector: 'app-address-books-add-facility',
    styles: [
        `
            h3.section-divider {
                color: #858a9f;
                border-bottom: solid 1px #ccc;
            }
        `,
    ],
    templateUrl: './address-books-add-facility.component.html',
})
export class AddressBooksAddFacilityComponent implements OnInit, OnDestroy {
    @Input() overrideRedirect: boolean = false;
    @Input() typeFilter: string;

    selectFacilityControl = new UntypedFormControl(null, (c: AbstractControl) => Validators.required(c));
    selectedFacility: IAddressBook;
    private _showFacilityAddView = false;
    get showFacilityAddView(): boolean {
        return this._showFacilityAddView;
    }
    set showFacilityAddView(value: boolean) {
        if (value) {
            this.selectedFacility = null;
        }
        this.form.get('Individual.FirstName').mtSetRequired(!value);
        this.form.get('Individual.LastName').mtSetRequired(!value);
        this._showFacilityAddView = value;
    }

    addressBookTypes: IAddressBookType[];
    addressTypes: IAddressType[];
    addressBookOption: IAddressBookOptions;

    form: UntypedFormGroup;
    config: IDynamicField[];
    individualConfig: IDynamicField[];
    naicField: IDynamicField;
    wcbField: IDynamicField;
    domainNameField: IDynamicField;
    notesField: IDynamicField;

    addressBookDynamicView: any = new AddressBookDynamicControlsPartial().View;
    doubleClickIsDisabled = false;
    getItems: VirtualTypeAheadGetItemsFunction = this.getFacilities.bind(this);
    virtualTypeAheadControl: ITypeAheadAPI;
    invalidFacility: boolean;

    individualControls: any;
    individualViewControls: any;
    individual: IIndividual;

    addressBookControls: any;
    addressBookViewControls: any;
    addressBook: IAddressBook;

    showContractRateFields = false;
    subscriptions$ = new Subscription();
    showNaicField = false;
    showWcbField = false;
    showImeAndRetrievalBillingEmailTypes = false;

    constructor(
        private addressBookService: AddressBookService,
        private individualService: IndividualService,
        private fb: UntypedFormBuilder,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private addressBookTypeService: AddressBookTypeService,
        private addressBooksSelectService: AddressBooksSelectService,
        private addressTypeService: AddressTypeService,
        private duplicatesConfirmService: DuplicatesConfirmService,
    ) {}

    ngOnInit(): void {
        this.form = this.fb.group({
            Address: this.fb.group({}),
            AddressTypes: this.fb.group({}),
    });
        forkJoin(this.addressTypeService.getItems(), this.addressBookTypeService.getAll()).subscribe((forkJoinResponses) => {
            const [addressTypes, addressBookTypes] = forkJoinResponses;
            this.addressBookTypes = addressBookTypes;
            this.addressTypes = addressTypes;
            if (this.typeFilter === AddressBookSelectionEnums.Physician.toString()) {
                this.addressBookTypes = this.addressBookTypes.filter(
                    (type) => type.Id === +AddressBookTypeIdEnums.Physician_panel || type.Id === +AddressBookTypeIdEnums.Expert_panel,
                );
            } else {
                this.setConfig(this.addressBookTypes);
                this.setIndividualConfig();
            }
        });
        this.setIndividualFormAndView();
        this.setAddressBookFormAndView();
    }

    isIndividualContractRangeValid(individualToCreate: IIndividual): boolean {
        if (
            individualToCreate.ContractRateFrom &&
            individualToCreate.ContractRateTo &&
            individualToCreate.ContractRateFrom > individualToCreate.ContractRateTo
        ) {
            this.notificationsService.error('Contract Range From cannot be higher than Contract Range To');
            return false;
        }
        return true;
    }

    isIndividualsContractRangeValid(addressBookIndividuals: IIndividual[]): boolean {
        let returnvalue = true;
        addressBookIndividuals.forEach((individual) => {
            if (individual.ContractRateFrom && individual.ContractRateTo && individual.ContractRateFrom > individual.ContractRateTo) {
                this.notificationsService.error('Contract Range From cannot be higher than Contract Range To');
                returnvalue = false;
            }
        });
        return returnvalue;
    }

    isFacilityContractRangeValid(addressBookObject: IAddressBook): boolean {
        if (
            addressBookObject.ContractRateFrom &&
            addressBookObject.ContractRateTo &&
            addressBookObject.ContractRateFrom > addressBookObject.ContractRateTo
        ) {
            this.notificationsService.error('Contract Range From cannot be higher than Contract Range To');
            return false;
        }
        return true;
    }

    setIndividualFormAndView(): void {
        const viewAndFormIndividualControl = new IndividualDynamicControlsPartial(this.individual);
        this.individualControls = viewAndFormIndividualControl.Form;
        this.individualViewControls = viewAndFormIndividualControl.View;
    }

    setAddressBookFormAndView(): void {
        const viewAndFormAddressBookControl = new AddressBookDynamicControlsPartial(this.addressBook, {
            addressBookTypes: [],
            metaAddressBookTypes: [],
        });

        this.addressBookControls = viewAndFormAddressBookControl.Form;
        this.addressBookViewControls = viewAndFormAddressBookControl.View;
    }

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
        this.cdr.detach();
    }

    getFacilities(searchText: string): Observable<any[]> {
        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'HasPhysician',
                value: this.typeFilter === AddressBookSelectionEnums.Physician.toString() ? '1' : '0',
            }),
        );

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            query: searchText,
        };

        return this.addressBookService.getFacilities(searchEntity);
    }

    selectionChanged({ selection }: ITypeAheadSelectionChangedEvent): void {
        if (selection) {
            this.selectedFacility = this.addressBookService.getEmptyAddressBook();
            const address = selection as IAddressBookOptions;
            this.selectedFacility.Id = address.Id;
            this.selectedFacility.FacilityName = address.ShortName;
            this.selectedFacility.AddressBookTypeId = address.TypeId;
            this.invalidFacility = false;
            if (
                this.selectedFacility.AddressBookTypeId === +AddressBookTypeIdEnums.Government_offices ||
                this.selectedFacility.AddressBookTypeId === +AddressBookTypeIdEnums.Carrier ||
                this.selectedFacility.AddressBookTypeId === +AddressBookTypeIdEnums.Attorney ||
                this.selectedFacility.AddressBookTypeId === +AddressBookTypeIdEnums.Miscellaneous
            ) {
                this.showContractRateFields = true;
            } else {
                this.showContractRateFields = false;
            }

            this.showWcbField =
                this.selectedFacility.AddressBookTypeId === +AddressBookTypeIdEnums.Expert_panel ||
                this.selectedFacility.AddressBookTypeId === +AddressBookTypeIdEnums.Physician_panel;
        }
    }

    virtualTypeAheadControlReady(controlApi: ITypeAheadAPI): void {
        this.virtualTypeAheadControl = controlApi;
    }

    setConfig(addressBookTypes: IAddressBookType[]): void {
        const addressBookControls = ['AddressBookTypeId', 'FacilityName', 'Attention', 'PrintOnCheckAs', 'Ein'];
        const addressBookConfig = new AddressBookDynamicConfig(emptyAddressBook, null, addressBookTypes, addressBookControls);
        const dynamicFields = new AddressBookDynamicControlsPartial(emptyAddressBook).Form;
        this.domainNameField = dynamicFields.DomainName;
        this.naicField = dynamicFields.Naic;
        this.notesField = dynamicFields.Notes;
        this.config = addressBookConfig.getForCreate().formObject;
    }

    setIndividualConfig(): void {
        const individualConfig = new IndividualDynamicConfig(emptyIndividual);
        const formObject = individualConfig.getForCreate().formObject;
        const dynamicFields = new IndividualDynamicControlsPartial(emptyIndividual).Form;
        this.wcbField = dynamicFields.Wcb;
        this.individualConfig = formObject;
    }

    clearFacilitySelection(): void {
        this.virtualTypeAheadControl.clearValue();
        this.selectedFacility = null;
        this.showContractRateFields = false;
        this.showWcbField = false;
    }

    getAddressBookType(id: number): string {
        const type = this.addressBookTypes.find((item) => item.Id === id);
        return type ? type.Name : '';
    }

    formCreated(): void {
        this.subscriptions$.add(
            this.form.controls.AddressBook.get('AddressBookTypeId').valueChanges.subscribe((addressbookTypeId) => {
                this.showNaicField = addressbookTypeId === AddressBookTypeIdEnums.Carrier;
                this.showContractRateFields =
                    addressbookTypeId === AddressBookTypeIdEnums.Government_offices ||
                    addressbookTypeId === AddressBookTypeIdEnums.Carrier ||
                    addressbookTypeId === AddressBookTypeIdEnums.Attorney ||
                    addressbookTypeId === AddressBookTypeIdEnums.Miscellaneous;
                this.showWcbField =
                    addressbookTypeId === AddressBookTypeIdEnums.Expert_panel || addressbookTypeId === AddressBookTypeIdEnums.Physician_panel;
                this.showImeAndRetrievalBillingEmailTypes = addressbookTypeId === AddressBookTypeIdEnums.Attorney || addressbookTypeId === AddressBookTypeIdEnums.Carrier;
                this.cdr.detectChanges();
            }),
        );
    }

    save(): void {
        if (!this.selectedFacility) {
            this.invalidFacility = true;
        }
        if (this.form.valid && (this.showFacilityAddView || !this.invalidFacility)) {
            if (this.selectedFacility) {
                const individualToCreate = this.addressBookService.getIndividualFromCreateAddressBookFormValue(
                    this.selectedFacility,
                    this.form.value as ICreateAddressBookFormValue,
                    false,
                );
                if (this.isIndividualContractRangeValid(individualToCreate)) {
                    this.individualService.initializeIndividual(individualToCreate).subscribe((individual) => {
                        if (!this.overrideRedirect) {
                            void this.router.navigate([`address-books/${this.selectedFacility.Id}/individuals/${individual.Id}`]);
                        } else {
                            trimObjectStringProperties(individual);
                            const addressBookSelectEntity = this.addressBooksSelectService.createAddressBookSelect(
                                this.selectedFacility.Id,
                                this.selectedFacility.FacilityName,
                                individual.Id,
                                individual.FirstName + ' ' + individual.LastName,
                                individualToCreate.Physicians && individualToCreate.Physicians.length > 0
                                    ? individualToCreate.Physicians[0].Id
                                    : null,
                            );
                            this.addressBooksSelectService.emitChange(addressBookSelectEntity);
                        }
                        this.notificationsService.success('Saved Successfully');
                        this.individualService.emitChange(individual);
                    });
                } else {
                    setTimeout(() => (this.doubleClickIsDisabled = false), 0);
                }
            } else {
                  // eslint-disable-next-line prefer-const
                let formParamValue = this.form.value as ICreateAddressBookFormValue;
                if (this.form.value.Address.AssociatedAddressTypes) {
                    formParamValue.Address.AddressTypes = [];
                    this.form.value.Address.AssociatedAddressTypes.forEach((addressTypeId: number) => {
                        formParamValue.Address.AddressTypes.push(this.addressTypes.find((x) => x.Id === addressTypeId));
                    });
                }
                const addressBookObject = this.addressBookService.getCreateAddressBookPayload(
                    formParamValue,
                    MetaAddressBookTypeIdEnums.Facility_Organization,
                );
                addressBookObject.Notes = addressBookObject.Notes.replace(/[^(\x20-\x7F)]*/g, '');
                if (this.isFacilityContractRangeValid(addressBookObject) && this.isIndividualsContractRangeValid(addressBookObject.Individuals)) {
                    // Null out email field if email address is not provided. This is to prevent the backend from trying to validate an empty email
                    if (!addressBookObject.EmailAddresses[0].Email) {
                        addressBookObject.EmailAddresses = null;
                    }

                    // Check for Duplication before initial creation of address book;
                    this.addressBookService.getPotentialDuplicates(addressBookObject).subscribe((response: IDuplicateAddressBook[]) => {
                        if (response && response.length > 0) {
                            this.duplicatesConfirmService.showDuplicatesConfirmationModal(response, false).subscribe((result) => {
                                if (result.value) {
                                    this.createAddressBook(addressBookObject);
                                }
                            });
                        } else {
                            this.createAddressBook(addressBookObject);
                        }
                    });
                } else {
                    setTimeout(() => (this.doubleClickIsDisabled = false), 0);
                }
            }
        } else {
            setTimeout(() => (this.doubleClickIsDisabled = false), 0);
            markAllFormFieldsAsTouched(this.form);
            this.selectFacilityControl.markAsTouched();
        }
    }

    createAddressBook(toBeCreatedAddressBook: IAddressBook): void {
        this.addressBookService.initializeAddressBook(toBeCreatedAddressBook).subscribe((addressBook) => {
            void this.router.navigate(['/address-books/' + addressBook.Id]);
            this.notificationsService.success('Saved Successfully');
            if (addressBook.Individuals[0]) {
                addressBook.Individuals.forEach((individual) => trimObjectStringProperties(individual));
                this.individualService.emitChange(addressBook.Individuals[0]);
            }
            this.addressBookService.emitChange(addressBook);
        });
    }
}
