import { NgModule } from '@angular/core';
import { SharedModule } from '../common/shared.module';
import { BaseDashboardComponent } from './base-dashboard-component/base-dashboard.component';
import { ClaimantNameSearchWidget } from './claimant-name-search-widget/claimant-name-search-widget.component';
import { DashboardHeaderComponent } from './dashboard-header/dashboard-header.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardService } from './dashboard.service';
import { ActiveServicesWidgetComponent } from './active-services-widget/active-services-widget.component';
import { NewServicesWidgetComponent } from './new-services-widget/new-services-widget.component';
import { ServiceTrendWidgetComponent } from './service-trend-widget/service-trend-widget.component';
import { ProductivityWidgetComponent } from './productivity-widget/productivity-widget.component';
import { SavedSearchesWidgetComponent } from './saved-searches-widget/saved-searches-widget.component';
import { RecentlyViewedCasesWidget } from './recently-viewed-cases-widget/recently-viewed-cases.component';

@NgModule({
    declarations: [
        BaseDashboardComponent,
        ClaimantNameSearchWidget,
        DashboardHeaderComponent,
        ActiveServicesWidgetComponent,
        NewServicesWidgetComponent,
        ServiceTrendWidgetComponent,
        ProductivityWidgetComponent,
        SavedSearchesWidgetComponent,
        RecentlyViewedCasesWidget,
    ],
    imports: [SharedModule, DashboardRoutingModule],
})
export class DashboardModule {
    static forRoot(): any {
        return {
            ngModule: DashboardModule,
            providers: [DashboardService],
        };
    }
}
