import { ImeServiceIssuesComponent } from './services/service-specifics/ime-service/ime-service-issues/ime-service-issues.component';
import { CurrencyFormatterService } from './../common/services/currency-formatter.service';
import { ConferenceCallDateTimeRequestedComponent } from './services/service-specifics/conference-call-service/date-time-requested/date-time-requested.component';
import { TestimonyDepositionServiceDateRequestedComponent } from './services/service-specifics/testimony-deposition-service/date-requested/date-requested.component';
import { TestimonyDepositionServiceDetailComponent } from './services/service-specifics/testimony-deposition-service/testimony-deposition-service-detail/testimony-deposition-service-detail.component';
import { TestimonyDepositionServiceBasicInfoComponent } from './services/service-specifics/testimony-deposition-service/testimony-deposition-service-basic-info/testimony-deposition-service-basic-info.component';
import { ConferenceCallServiceDetailComponent } from './services/service-specifics/conference-call-service/conference-call-service-detail/conference-call-service-detail.component';
import { ConferenceCallServiceBasicInfoComponent } from './services/service-specifics/conference-call-service/conference-call-service-basic-info/conference-call-service-basic-info.component';
import { BillDeskReviewServiceService } from './services/service-specifics/bill-desk-review-service/bill-desk-review-service.service';
import { BillDeskReviewServiceBasicInfoComponent } from './services/service-specifics/bill-desk-review-service/bill-desk-review-service-basic-info/bill-desk-review-service-basic-info.component';
import { RecordReviewServiceDetailComponent } from './services/service-specifics/record-review-service/record-review-service-detail/record-review-service-detail.component';
import { RecordReviewServiceService } from './services/service-specifics/record-review-service/record-review-service.service';
import { RecordRetrievalFilmRetrievalInformationComponent } from './services/service-specifics/record-retrieval-service/film-retrieval-information/film-retrieval-information.component';
import { SubServiceService } from './services/common-components/sub-services/sub-service.service';
import { ImedClaimSubServiceComponent } from './services/common-components/sub-services/imed-claim-sub-services.component';
import { RecordRetrievalServiceService } from './services/service-specifics/record-retrieval-service/record-retrieval-service.service';
import { ImeServiceService } from './services/service-specifics/ime-service/imeservice.service';
import { ImedClaimSubServiceService } from '@app-shared/services/imed-claim-sub-service.service';
import { ImeAppointmentService } from './services/service-specifics/ime-service/ime-appointments/ime-appointment.service';
import { ImedClaimServiceStatusLogService } from './services/common-components/service-status/imed-claim-service-status-log.service';
import { ServiceService } from './services/service.service';
import { FollowupTypeService } from './services/common-components/service-followups/followuptype.service';
import { ServiceStatusService } from './services/common-components/service-status/servicestatus.service';
import { ImedClaimServiceService } from './services/imedclaimservice.service';
import { ImedClaimServiceFollowupService } from './services/common-components/service-followups/imedclaimservicefollowup.service';
import { AppointmentLabelGenerator } from './services/service-specifics/ime-service/ime-appointments/appointment-label-generator';
import { FilmReviewServiceDetailComponent } from './services/common-components/film-review/film-review-service-detail/film-review-service-detail.component';
import { FilmReviewServiceBasicInfoComponent } from './services/service-specifics/film-review-service/film-review-service-basic-info/film-review-service-basic-info.component';
import { RecorRetrievalServiceDetailComponent } from './services/service-specifics/record-retrieval-service/record-retrieval-service-detail/record-retrieval-service-detail.component';
import { ImeServiceBasicInfoComponent } from './services/service-specifics/ime-service/ime-service-basic-info/ime-service-basic-info.component';
import { ImeAdditionalServiceComponent } from './services/common-components/ime-additional-service/ime-additional-service.component';
import { ImeAddAppointmentsComponent } from './services/service-specifics/ime-service/ime-appointments/ime-add-appointments.component';
import { ImeServiceDetailComponent } from './services/service-specifics/ime-service/ime-service-detail/ime-service-detail.component';
import { ImeAppointmentsComponent } from './services/service-specifics/ime-service/ime-appointments/ime-appointments.component';
import { CommunicationLogsComponent } from './services/common-components/communication-log/communication-logs.component';
import { ImedClaimServicePhysicianInfo } from './services/common-components/physician-info/imed-claim-service-physician-info.component';
import { ImedClaimServiceStatusLogsComponent } from './services/common-components/service-status/imed-claim-service-status-logs.component';
import { ImedClaimServiceStatusComponent } from './services/common-components/service-status/imed-claim-service-status.component';
import { ImedClaimServiceFollowupsComponent } from './services/common-components/service-followups/imed-claim-service-followup-list/imed-claim-service-followups.component';
import { ImedClaimServiceFollowupDetailComponent } from './services/common-components/service-followups/imed-claim-service-followup-detail/imed-claim-service-followup-detail.component';
import { ImedClaimServiceFollowupHeaderComponent } from './services/common-components/service-followups/imed-claim-service-followup-header/imed-claim-service-followup-header.component';
import { ImedClaimServiceFollowupBasicInfoComponent } from './services/common-components/service-followups/imed-claim-service-followup-basic-info/imed-claim-service-followup-basic-info.component';
import { ImedClaimServicesComponent } from './services/service-list/imed-claim-services.component';
import { ImedClaimServiceHeaderComponent } from './services/service-header/imed-claim-service-header.component';
import { ImedClaimServiceDetailComponent } from './services/service-detail/imed-claim-service-detail.component';
import { NgModule } from '@angular/core';
import { ImedClaimRoutingModule } from './imed-claim-routing.module';
import { ImedClaimDetailComponent } from './imed-claim-detail/imed-claim-detail.component';
import { ImedClaimsComponent } from './imed-claim-list/imed-claims.component';
import { ImedClaimHeaderComponent } from './imed-claim-header/imed-claim-header.component';
import { ImedClaimService } from './imedclaim.service';
import { ImedClaimStatusService } from './imedclaimstatus.service';
import { ImedCommonModule } from '../common/imed-common.module';
import { IMedClaimAddressService } from './shared/imed-claim-address.service';
import { CommunicationLogService } from '../common/services/communication-log.service';
import { MtFabSpeedDialControlModule } from '@mt-ng2/fab-speed-dial-control';

import { SharedModule } from '../common/shared.module';
import { RecordRetrievalServiceBasicInfoComponent } from './services/service-specifics/record-retrieval-service/record-retrieval-service-basic-info/record-retrieval-service-basic-info.component';
import { RecordRetrievalRadiologyFacilityComponent } from './services/service-specifics/record-retrieval-service/radiology-facility/radiology-facility-info.component';
import { RecordReviewServiceBasicInfoComponent } from './services/service-specifics/record-review-service/record-review-service-basic-info/record-review-service-basic-info.component';
import { BillDeskReviewServiceDetailComponent } from './services/service-specifics/bill-desk-review-service/bill-desk-review-service-detail/bill-desk-review-service-detail.component';
import { ConferenceCallServiceService } from './services/service-specifics/conference-call-service/conference-call-service.service';
import { TestimonyDepositionServiceService } from './services/service-specifics/testimony-deposition-service/testimony-deposition-service.service';
import { AuthorizationAttachmentUploadComponent } from './services/service-specifics/record-retrieval-service/authorization-attachment-upload/authorization-attachment-upload.component';
import { MtPhoneControlModule } from '@mt-ng2/phone-control';
import { ImedClaimTaskComponent } from './imed-claim-tasks/imed-claim-task.component';
import { ImedClaimAddTaskComponent } from './imed-claim-tasks/imed-claim-add-task.component';
import { ImedClaimRequestorTypeService } from './imed-claim-requestor-type.service';
import { ConcurrentUsersComponent } from './services/common-components/concurrent-users/concurrent-users.component';
import { HighValueServiceFormControlComponent } from './services/common-components/high-value-service/high-value-service.component';
import { RushServiceFormControlComponent } from './services/common-components/rush-service/rush-service.component';
import { ServiceDeleteDynamicCellComponent } from './services/service-list/service-delete-dynamic-cell.component';
import { ImeAppointmentDeleteDynamicCellComponent } from './services/service-specifics/ime-service/ime-appointments/ime-appointment-delete-cell.component';
import { ImedClaimAdditionalBTPComponent } from './imed-claim-additional-btp/imed-claim-additional-btp.component';
import { ImedClaimCarrierPartyComponent } from './imed-claim-carrier-party/imed-claim-carrier-party.component';
import { ImedClaimAdditionalBillToPartyService } from './imed-claim-additional-btp/imedclaimadditionalbilltoparty.service';
import { ServiceFollowupDeleteDynamicCellComponent } from './services/common-components/service-followups/imed-claim-service-followup-list/imed-claim-service-followups-delete-dynamic-cell.component';
import { OtherServiceDynamicCellComponent } from './services/service-list/other-service-dynamic-cell.component';
import { AddressBookSelectOptionComponent } from '../common/address-book-select-list/address-book-select-option.component';
import { ImedClaimMergeCasesComponent } from './imed-claim-merge-cases/imed-claim-merge-cases.component';
import { CommunicationLogResendCellComponent } from './services/common-components/communication-log/communication-log-resend.component';
import { FeeScheduleComponent } from './services/common-components/fee-schedule/fee-schedule.component';
import { IMedClaimPhoneNumberService } from './shared/imed-claim-phone.service';
import { IMedClaimEmailService } from './shared/imed-claim-email.service';
import { ImedClaimTreatingPhysicianComponent } from './imed-claim-treating-physician/imed-claim-treating-physician.component';
import { ImedClaimTreatingPhysiciansComponent } from './imed-claim-treating-physicians/imed-claim-treating-physicians.service.component';
import { WCIssuesComponent } from './services/common-components/wc-issues/wc-issues.component';
import { WcImeServiceDetailComponent } from './services/service-specifics/wc-ime-service/wc-ime-service-detail/wc-ime-service-detail.component';
import { WcImeServiceBasicInfoComponent } from './services/service-specifics/wc-ime-service/wc-ime-basic-info/wc-ime-service-basic-info.component';
import { WcRecordReviewServiceDetailComponent } from './services/service-specifics/wc-record-review-service/wc-record-review-service-detail/wc-record-review-service-detail.component';
import { WcRecordReviewServiceBasicInfoComponent } from './services/service-specifics/wc-record-review-service/wc-record-review-service-basic-info/wc-record-review-service-basic-info.component';
import { AutoRecordReeviewAddendumServiceDetailComponent } from './services/service-specifics/auto-record-review/auto-record-review-detail/auto-record-review-detail.component';
import { AutoRecordReeviewAddendumServiceBasicInfoComponent } from './services/service-specifics/auto-record-review/auto-record-reviewl-basic-info/auto-record-review-basic-info.component';
import { AutoImeAppointmentDeleteDynamicCellComponent } from './services/service-specifics/auto-ime-service/auto-ime-appointments/auto-ime-appointment-delete-cell.component';
import { AutoImeAppointmentsComponent } from './services/service-specifics/auto-ime-service/auto-ime-appointments/auto-ime-appointments.component';
import { AutoImeServiceBasicInfoComponent } from './services/service-specifics/auto-ime-service/auto-ime-service-basic-info/auto-ime-service-basic-info.component';
import { AutoImeServiceDetailComponent } from './services/service-specifics/auto-ime-service/auto-ime-service-detail/auto-ime-service-detail.component';
import { AutoIssuesComponent } from './services/common-components/auto-issues/auto-issues.component';
import { AutoImeAppointmentService } from './services/service-specifics/auto-ime-service/auto-ime-appointments/auto-ime-appointment.service';
import { AutoImeServiceService } from './services/service-specifics/auto-ime-service/auto-ime-service.service';
import { AutoAppointmentLabelGenerator } from './services/service-specifics/auto-ime-service/auto-ime-appointments/auto-appointment-label-generator';
import { AutoImeAddAppointmentsComponent } from './services/service-specifics/auto-ime-service/auto-ime-appointments/auto-ime-add-appointments.component';
import { AutoIssueService } from './services/auto-issue.service';
import { WCIssueService } from './services/wc-issue.service';
import { CommonImedClaimService } from './shared/common-imed-claim-service';
import { FilmReviewFilmRetrievalInformationComponent } from './services/common-components/film-review/film-retrieval-information/film-retrieval-information.component';
import { FilmReviewFilmRetrievalSelectorComponent } from './services/common-components/film-review/film-retrieval-selector/film-retrieval-selector.component';
import { FilmReviewFilmRetrievalSelectorList } from './services/common-components/film-review/film-retrieval-selector/film-retrieval-selector-list.component';
import { FilmReviewRadiologyFacilityComponent } from './services/common-components/film-review/radiology-facility/radiology-facility-info.component';
import { FilmReviewServiceService } from './services/common-components/film-review/film-review-service.service';
import { WCFilmReviewServiceBasicInfoComponent } from './services/service-specifics/film-review-service/wc-film-review-service-basic-info/wc-film-review-service-basic-info.component';
import { WcFCE } from './services/service-specifics/wc-fce-service/wc-fce-component/wc-fce-component';
import { EligibilityCriteriaService } from './services/service-specifics/wc-fce-service/eligibility-criteria.service';
import { ImedClaimServiceExamRequestorTypeService } from './services/service-specifics/wc-fce-service/imed-claim-exam-requestor-type.service';
import { BillsForReviewListComponent } from './services/common-components/bills-for-review/bills-for-review-list/bills-for-review-list.component';
import { BillsForReviewFormComponent } from './services/common-components/bills-for-review/bills-for-review-form/bills-for-review-form.component';
import { BillsForReviewComponent } from './services/common-components/bills-for-review/bills-for-review/bills-for-review.component';
import { AutoFilmReviewServiceBasicInfoComponent } from './services/service-specifics/film-review-service/auto-film-review-service-basic-info/auto-film-review-service-basic-info.component';
import { ServiceTypeDynamicCellComponent } from './services/service-list/service-type-dynamic-cell.component';
import { RecordReviewAdditionalServiceComponent } from './services/common-components/record-review-additional-service/record-reivew-additional-service.component';
import { AdditionalServicesSharedService } from './services/service-specifics/additional-services-shared.service';
import { AutoNF10FormInfoComponent } from './services/service-specifics/auto-ime-service/auto-nf10-form-info/auto-nf10-form-info.component';
import { MasterFollowupTypeService } from './services/common-components/service-followups/master-follow-up-type-service';
import { ImedClaimAdditionalOtherComponent } from './imed-claim-additional-other/imed-claim-additional-other.component';
import { ImedClaimAdditionalOthersComponent } from './imed-claim-additional-others/imed-claim-additional-others.component';
import { ImedClaimAdditionalOtherService } from './imed-claim-additional-others/imed-claim-additional-others.service';
import { ImedClaimDeleteDynamicCellComponent } from './imed-claim-list/imed-claims-delete-dynamic-cell.component';
import { CompilationDueByFormControlComponent } from './services/common-components/compilation-due-by/compilation-due-by.component';
import { ImedClaimSubpoenaDetailBasicInfoComponent } from './imed-claim-subpoena-detail-basic-info/imed-claim-subpoena-detail-basic-info.component';
import { ImedClaimSubpoenaDetailService } from './services/imed-claim-subpoena-detail.service';
import { ImedClaimCourtFeesService } from './imed-claim-court-fees/imed-claim-court-fees.service';
import { DatePipe } from '@angular/common';
import { ImedClaimCourtFeesComponent } from './imed-claim-court-fees/imed-claim-court-fees.component';
import { SubpoenaServiceDetailComponent } from './services/service-specifics/subpoena-service/subpoena-service-detail/subpoena-service-detail.component';
import { SubpoenaServiceBasicInfoComponent } from './services/service-specifics/subpoena-service/subpoena-service-basic-info/subpoena-service-basic-info-component';
import { SubpoenaServiceService } from './services/service-specifics/subpoena-service/subpoena-service.service';
import { SubpoenaServiceCourtFeesComponent } from './services/service-specifics/subpoena-service/subpoena-service-court-fees/subpoena-service-court-fees-component';
import { ImedClaimServiceCourtFeeService } from './services/service-specifics/subpoena-service/subpoena-service-court-fees/subpoena-service-court-fees-service';
import { ImedClaimServiceProcessServerFeeService } from './services/service-specifics/subpoena-service/subpoena-service-process-server-fees/subpoena-service-process-server-fees-service';
import { SubpoenaServiceProcessServerFeesComponent } from './services/service-specifics/subpoena-service/subpoena-service-process-server-fees/subpoena-service-process-server-fees-component';
import { SubpoenaServiceWitnessFeesComponent } from './services/service-specifics/subpoena-service/subpoena-service-witness-fees/subpoena-service-witness-fees-component';
import { ImedClaimFollowupService } from './imedclaim-followup.service';
import { ImedClaimFollowupHeaderComponent } from './imedclaim-followups/imed-claim-followup-header/imed-claim-followup-header.component';
import { ImedClaimFollowupBasicInfoComponent } from './imedclaim-followups/imed-claim-followup-basic-info/imed-claim-followup-basic-info.component';
import { ImedClaimFollowupDetailComponent } from './imedclaim-followups/imed-claim-followup-detail/imed-claim-followup-detail.component';
import { CaseFollowupDeleteDynamicCellComponent } from './imedclaim-followups/imed-claim-followup-list/imed-claim-followups-delete-dynamic-cell.component';
import { ImedClaimFollowupsComponent } from './imedclaim-followups/imed-claim-followup-list/imed-claim-followups.component';
import { ImedClaimAdditionalRecordsComponent } from './imed-claim-additional-records/imed-claim-additional-records.component';
import { PageCountFormControlComponent } from './services/common-components/page-count/page-count.component';
import { ImedClaimServiceSplitBillingDetailComponent } from './services/common-components/split-billing-details/imed-claim-service-split-billing-detail.component';
import { ImedClaimServiceSplitBillingDetailService } from './services/common-components/split-billing-details/imed-claim-service-split-billing-detail.service';
import { ConsentToChangeAttachmentUploadComponent } from './imedclaim-followups/consent-to-change-attachment-upload/consent-to-change-attachment-upload.component';
import { SubpoenaServiceFacilityFeesComponent } from './services/service-specifics/subpoena-service/subpoena-service-facility-fees/subpoena-service-facility-fees-component';
import { AuthorizationAttachmentUploadClientComponent } from './services/service-specifics/radiology-received-from-client-service/authorization-attachment-upload-client/authorization-attachment-upload-client.component';
import { RecordRetrievalFilmRetrievalInformationClientComponent } from './services/service-specifics/radiology-received-from-client-service/film-retrieval-information-client/film-retrieval-information-client.component';
import { RecordRetrievalRadiologyFacilityClientComponent } from './services/service-specifics/radiology-received-from-client-service/radiology-facility-client/radiology-facility-info-client.component';
import { RecordRetrievalServiceDetailClientComponent } from './services/service-specifics/radiology-received-from-client-service/record-retrieval-service-client-detail/record-retrieval-service-client-detail.component';
import { RecordRetrievalServiceClientBasicInfoComponent } from './services/service-specifics/radiology-received-from-client-service/record-retrieval-service-client-basic-info/record-retrieval-service-client-basic-info.component';

@NgModule({
    declarations: [
        ImedClaimsComponent,
        ImedClaimHeaderComponent,
        ImedClaimDetailComponent,
        ImedClaimMergeCasesComponent,
        ImedClaimServiceDetailComponent,
        ImedClaimServiceHeaderComponent,
        ImedClaimServicesComponent,
        ImedClaimServiceFollowupBasicInfoComponent,
        ImedClaimServiceFollowupHeaderComponent,
        ImedClaimServiceFollowupDetailComponent,
        ImedClaimServiceFollowupsComponent,
        ImedClaimServiceStatusComponent,
        ImedClaimServiceStatusLogsComponent,
        ImedClaimServicePhysicianInfo,
        CommunicationLogsComponent,
        ImeAppointmentsComponent,
        ImedClaimSubServiceComponent,
        ImeServiceDetailComponent,
        AutoRecordReeviewAddendumServiceDetailComponent,
        ImeAddAppointmentsComponent,
        ImeAdditionalServiceComponent,
        RecordReviewAdditionalServiceComponent,
        ImeServiceBasicInfoComponent,
        AutoRecordReeviewAddendumServiceBasicInfoComponent,
        RecordRetrievalServiceBasicInfoComponent,
        RecorRetrievalServiceDetailComponent,
        FilmReviewServiceBasicInfoComponent,
        WCFilmReviewServiceBasicInfoComponent,
        FilmReviewServiceDetailComponent,
        RecordRetrievalRadiologyFacilityComponent,
        RecordRetrievalFilmRetrievalInformationComponent,
        FilmReviewRadiologyFacilityComponent,
        FilmReviewFilmRetrievalInformationComponent,
        RecordReviewServiceBasicInfoComponent,
        RecordReviewServiceDetailComponent,
        BillDeskReviewServiceBasicInfoComponent,
        HighValueServiceFormControlComponent,
        RushServiceFormControlComponent,
        BillDeskReviewServiceDetailComponent,
        ConferenceCallServiceBasicInfoComponent,
        ConferenceCallServiceDetailComponent,
        TestimonyDepositionServiceBasicInfoComponent,
        TestimonyDepositionServiceDetailComponent,
        TestimonyDepositionServiceDateRequestedComponent,
        ConferenceCallDateTimeRequestedComponent,
        ImeServiceIssuesComponent,
        AuthorizationAttachmentUploadComponent,
        ImedClaimTaskComponent,
        ImedClaimAddTaskComponent,
        ConcurrentUsersComponent,
        ServiceDeleteDynamicCellComponent,
        OtherServiceDynamicCellComponent,
        ServiceFollowupDeleteDynamicCellComponent,
        ImedClaimDeleteDynamicCellComponent,
        ImeAppointmentDeleteDynamicCellComponent,
        ImedClaimAdditionalBTPComponent,
        ImedClaimCourtFeesComponent,
        ImedClaimTreatingPhysiciansComponent,
        ImedClaimAdditionalOthersComponent,
        ImedClaimCarrierPartyComponent,
        ImedClaimTreatingPhysicianComponent,
        ImedClaimAdditionalOtherComponent,
        FeeScheduleComponent,
        AddressBookSelectOptionComponent,
        CommunicationLogResendCellComponent,
        FilmReviewFilmRetrievalSelectorComponent,
        FilmReviewFilmRetrievalSelectorList,
        WCIssuesComponent,
        WcImeServiceDetailComponent,
        WcImeServiceBasicInfoComponent,
        WcFCE,
        WcRecordReviewServiceDetailComponent,
        WcRecordReviewServiceBasicInfoComponent,
        AutoImeAppointmentDeleteDynamicCellComponent,
        AutoImeAppointmentsComponent,
        AutoImeServiceBasicInfoComponent,
        AutoImeServiceDetailComponent,
        AutoIssuesComponent,
        AutoImeAddAppointmentsComponent,
        CommonImedClaimService,
        BillsForReviewListComponent,
        BillsForReviewFormComponent,
        BillsForReviewComponent,
        AutoFilmReviewServiceBasicInfoComponent,
        ServiceTypeDynamicCellComponent,
        AutoNF10FormInfoComponent,
        CompilationDueByFormControlComponent,
        ImedClaimSubpoenaDetailBasicInfoComponent,
        SubpoenaServiceDetailComponent,
        SubpoenaServiceBasicInfoComponent,
        SubpoenaServiceCourtFeesComponent,
        SubpoenaServiceProcessServerFeesComponent,
        SubpoenaServiceWitnessFeesComponent,
        ImedClaimFollowupHeaderComponent,
        ImedClaimFollowupBasicInfoComponent,
        ImedClaimFollowupDetailComponent,
        CaseFollowupDeleteDynamicCellComponent,
        ImedClaimFollowupsComponent,
        ImedClaimAdditionalRecordsComponent,
        PageCountFormControlComponent,
        ImedClaimServiceSplitBillingDetailComponent,
        ConsentToChangeAttachmentUploadComponent,
        SubpoenaServiceFacilityFeesComponent,
        AuthorizationAttachmentUploadClientComponent,
        RecordRetrievalFilmRetrievalInformationClientComponent,
        RecordRetrievalRadiologyFacilityClientComponent,
        RecordRetrievalServiceDetailClientComponent,
        RecordRetrievalServiceClientBasicInfoComponent,
    ],
    imports: [SharedModule, ImedCommonModule, ImedClaimRoutingModule, MtFabSpeedDialControlModule, MtPhoneControlModule],
})
export class ImedClaimModule {
    static forRoot(): any {
        return {
            ngModule: ImedClaimModule,
            providers: [
                DatePipe,
                AppointmentLabelGenerator,
                ImedClaimService,
                ImedClaimServiceService,
                IMedClaimAddressService,
                ImedClaimServiceFollowupService,
                ImedClaimStatusService,
                ServiceStatusService,
                FollowupTypeService,
                MasterFollowupTypeService,
                ServiceService,
                CommunicationLogService,
                ImedClaimServiceStatusLogService,
                ImeAppointmentService,
                SubServiceService,
                ImedClaimSubServiceService,
                ImeServiceService,
                RecordRetrievalServiceService,
                FilmReviewServiceService,
                RecordReviewServiceService,
                BillDeskReviewServiceService,
                ConferenceCallServiceService,
                TestimonyDepositionServiceService,
                CurrencyFormatterService,
                ImedClaimRequestorTypeService,
                ImedClaimAdditionalBillToPartyService,
                ImedClaimAdditionalOtherService,
                IMedClaimEmailService,
                IMedClaimPhoneNumberService,
                AutoImeAppointmentService,
                AutoImeServiceService,
                AutoAppointmentLabelGenerator,
                AutoIssueService,
                WCIssueService,
                EligibilityCriteriaService,
                ImedClaimServiceExamRequestorTypeService,
                AdditionalServicesSharedService,
                ImedClaimSubpoenaDetailService,
                ImedClaimCourtFeesService,
                SubpoenaServiceService,
                ImedClaimServiceCourtFeeService,
                ImedClaimServiceProcessServerFeeService,
                ImedClaimFollowupService,
                ImedClaimServiceSplitBillingDetailService,
            ],
        };
    }
}
