import { Component, OnInit, Input } from '@angular/core';
import { BaseRowComponent } from '../../base-row/base-row.component';
import { IMasterFollowupTypeServiceStatus } from '@model/interfaces/master-followup-type-service-status';

@Component({
    selector: 'followup-status-row',
    styleUrls: ['../../row-styles.less'],
    templateUrl: './followup-status-row.component.html',
})
export class FollowupStatusRowComponent extends BaseRowComponent implements OnInit {
    @Input() followupStatusAssociation: IMasterFollowupTypeServiceStatus;

    constructor() {
        super();
    }

    ngOnInit(): void {
        if (this.followupStatusAssociation.Id > 0) {
            this.selectedFollowup = this.followupStatusAssociation.MasterFollowupType;
            this.selectedStatus = this.followupStatusAssociation.MasterServiceStatus;
        }
    }
}
