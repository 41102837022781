import { Component, OnInit, Input, Output, EventEmitter, ComponentRef, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { FaxEmailService } from '../fax-email/fax-email.service';
import { ICommunicationRequest, IAttachmentLinks } from '@model/interfaces/custom/communication-request';
import { IEmailTemplate } from '@model/interfaces/email-template';
import { EmailTemplateService } from '../../email-templates/emailtemplate.service';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { finalize, tap, switchMap } from 'rxjs/operators';
import { ValidateEmailAddressList } from '../custom-validators/email-list.validator';
import { OverlayRef, Overlay, OverlayPositionBuilder, GlobalPositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Subscription, Observable, of } from 'rxjs';
import { SelectContactsEmail } from '../select-contacts-email/select-contacts-email.component';
import { CommunicationLogService } from '../services/communication-log.service';
import { BaseAttachmentsService } from '../attachments/base-attachments.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { IAttachmentDTO } from '@model/interfaces/custom/attachment-dto';
import { saveAs } from 'file-saver';
import { DocumentTransactionLogService } from '../attachments/document-transaction-log.service';
import { AttachmentTypeEnums, DocumentTransactionTypeEnums, SystemGeneratedEmailTemplateEnums } from '../constants/Enums';
import { AddressBooksSelectService } from '../address-book-select-list/address-books-select.service';
import { IAddressBookSelectEntity } from '../address-book-select-list/address-books-select-entity';
import { SelectedAddressBookDisplayComponent } from '../address-book-select-list/selected-address-book-display/selected-address-book-display.component';
import { DragDrop } from '@angular/cdk/drag-drop';
import { IDocumentGenerationDTO } from '@model/interfaces/custom/document-generation-dto';
import { IModalOptions, IModalWrapperApi } from '@mt-ng2/modal-module';
import { DocumentsCrudService } from '../../common/attachments/documents-crud.service';
import { ImedClaimServiceService } from '../../imed-claims/services/imedclaimservice.service';
import { PhysicianService } from '../../address-books/individuals/physicians/physician.service';
import { AuthService } from '@mt-ng2/auth-module';
import { UserRoleService } from '../../user-roles/user-role.service';
import { IUserRole } from '@model/interfaces/user-role';

export interface ICommonEmailComponentApi {
    close: () => void;
    show: () => void;
}

@Component({
    selector: 'common-email',
    styles: [
        `
            label {
                padding: 8px 0px;
                font-size: 20px;
                color: #858a9f;
            }
            .invalid {
                border: 1px solid red;
            }
            .error {
                color: red;
                font-size: 10px;
            }
            .merging {
                position: absolute;
                height: 100%;
                width: 100%;
                background: rgba(68, 68, 68, 0.3);
                top: 0;
                left: 0;
                z-index: 9999;
                padding: 1.25em;
                border-radius: 0.3125em;
            }
            .generating {
                width: 100%;
                height: 100%;
                display: flex;
                opacity: 100%;
                font-size: 50px;
                align-items: center;
                justify-content: center;
                text-align: center;
            }
        `,
    ],
    templateUrl: './common-email.component.html',
})
export class CommonEmailComponent implements OnInit, OnDestroy {
    @Input() emailTemplates: IEmailTemplate[];
    @Input() imedClaimServiceId: number;
    @Input() individualId: number;
    @Input() addressBookId: number;
    @Input() imedClaimId: number;
    @Input() taskId: number;
    @Input() imedClaimTypeId: number;

    // public Editor = Editor;

    private _resendEmailTemplateId: number;
    @Input('resendEmailTemplateId')
    set resendEmailTemplateId(val: number) {
        this._resendEmailTemplateId = val;
        this.addPhysicianControl();
    }
    get resendEmailTemplateId(): number {
        return this._resendEmailTemplateId;
    }
    @Input() originalCommunicationLogId: number;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    set emailTemplate(val: IEmailTemplate) {
        this.emailSubject.setValue(val.Subject);
        this.emailTo.setValue(val.To.trim());
        this.emailCC.setValue(val.Cc?.trim());
        this.emailBCC.setValue(val.Bcc?.trim());
        this.emailBody.setValue(val.Body);
    }
    private _selectedTemplate: IEmailTemplate;
    @Input('selectedTemplate')
    set selectedTemplate(val: IEmailTemplate) {
        this._selectedTemplate = val;
        this.mergeTemplate();
        this.addPhysicianControl();
    }
    get selectedTemplate(): IEmailTemplate {
        return this._selectedTemplate;
    }
    emailForm: UntypedFormGroup;
    emailSubject = new UntypedFormControl('', (c: AbstractControl) => Validators.required(c));
    emailCC = new UntypedFormControl('', [(c: AbstractControl) => ValidateEmailAddressList(c)]);
    emailBCC = new UntypedFormControl('', [(c: AbstractControl) => ValidateEmailAddressList(c)]);
    emailTo = new UntypedFormControl('', [(c: AbstractControl) => Validators.required(c), ValidateEmailAddressList, Validators.maxLength(1000)]);
    emailBody = new UntypedFormControl('', (c: AbstractControl) => Validators.required(c));
    sendAsAttachment = new UntypedFormControl(false);
    selectedPhysicians: IAddressBookSelectEntity[] = [];
    selectedPhysiciansControl = new UntypedFormControl([], (c: AbstractControl) => Validators.required(c));
    overlayRef: OverlayRef;
    subscriptions: Subscription = new Subscription();
    doubleClickIsDisabled = false;
    isMergingOrSending = false;
    showSendAsAttachment = false;
    physicianId: number;
    userRole: IUserRole;

    attachments: IAttachmentDTO[];
    attachmentLinks: IAttachmentLinks[] = [];
    defaultAttachmentTypeIds: number[] = [];
    defaultAttachments: string[] = [];
    canEditAttachments: boolean;

    selectedPhysiciansDisplayComponent: SelectedAddressBookDisplayComponent;
    fileToUpload: File;
    previewMode = false;

    modalOptions: IModalOptions = {
        customClass: 'modal-hide-actions' as any,
        width: '60%',
    };
    modalWrapperApi: IModalWrapperApi;
    @Output() ready = new EventEmitter<ICommonEmailComponentApi>();

    constructor(
        protected notificationsService: NotificationsService,
        protected faxEmailService: FaxEmailService,
        private emailTemplateService: EmailTemplateService,
        private communicationLogService: CommunicationLogService,
        private fb: UntypedFormBuilder,
        private overlay: Overlay,
        private overlayPositionBuilder: OverlayPositionBuilder,
        private commonattachmentsService: BaseAttachmentsService,
        private docTransactionLogService: DocumentTransactionLogService,
        private addressSelectService: AddressBooksSelectService,
        private baseAttachmentService: BaseAttachmentsService,
        private draggable: DragDrop,
        private cdr: ChangeDetectorRef,
        private documentsService: DocumentsCrudService,
        private commonImedClaimServiceService: ImedClaimServiceService,
        private physicianService: PhysicianService,
        private userRoleService: UserRoleService,
        private authService: AuthService,
    ) {}

    ngOnInit(): void {
        this.buildForm();
        this.ready.emit({
            close: this.closeEmailModal.bind(this),
            show: this.showEmailForm.bind(this),
        });
    }

    buildForm(): void {
        this.emailForm = this.fb.group({
            bcc: this.emailBCC,
            body: this.emailBody,
            cc: this.emailCC,
            sendAsAttachment: this.sendAsAttachment,
            subject: this.emailSubject,
            to: this.emailTo,
        });
        if (this.individualId) {
            this.physicianService.getByIndividualId(this.individualId).subscribe((response) => {
                if (response != null) {
                    this.physicianId = response.Id;
                }
            });
        }
        const currentUserId = this.authService.currentUser.getValue().Id;
        this.userRoleService.getRoleByUserId(currentUserId).subscribe((response) => {
            this.userRole = response;
            this.showSendAsAttachment = this.userRole.AllowToSendAsAttachment;
            if (this.showSendAsAttachment) {
                this.sendAsAttachment.setValue(true);
            }
        });
    }

    addPhysicianControl(): void {
        if (this.isFeeSchedule) {
            this.emailForm.addControl('selectedPhysiciansControl', this.selectedPhysiciansControl);
            if (
                this.resendEmailTemplateId === +SystemGeneratedEmailTemplateEnums.FEE_SCHEDULE ||
                this.resendEmailTemplateId === +SystemGeneratedEmailTemplateEnums.Record_Review_Fee_Schedule ||
                this.resendEmailTemplateId === +SystemGeneratedEmailTemplateEnums.Radiology_Review_Fee_Schedule
            ) {
                this.selectedPhysiciansControl.setValue(this.selectedPhysicians.map((p) => ' ' + p.IndividualName));
            }
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        if (this.overlayRef) {
            this.overlayRef.dispose();
        }
    }

    get showAttachmentControl(): boolean {
        return (
            !this.isFeeSchedule &&
            ((this.selectedTemplate && this.selectedTemplate.CanHaveAttachment) || (this.attachments && this.attachments.length > 0))
        );
    }

    get isFeeSchedule(): boolean {
        return (
            (this.selectedTemplate && this.selectedTemplate.Id === +SystemGeneratedEmailTemplateEnums.FEE_SCHEDULE) ||
            this.resendEmailTemplateId === +SystemGeneratedEmailTemplateEnums.FEE_SCHEDULE ||
            (this.selectedTemplate && this.selectedTemplate.Id === +SystemGeneratedEmailTemplateEnums.Record_Review_Fee_Schedule) ||
            this.resendEmailTemplateId === +SystemGeneratedEmailTemplateEnums.Record_Review_Fee_Schedule ||
            (this.selectedTemplate && this.selectedTemplate.Id === +SystemGeneratedEmailTemplateEnums.Radiology_Review_Fee_Schedule) ||
            this.resendEmailTemplateId === +SystemGeneratedEmailTemplateEnums.Radiology_Review_Fee_Schedule
        );
    }

    getAttachments(): Observable<HttpResponse<IAttachmentDTO[]>> {
        if (this.imedClaimServiceId) {
            return this.commonattachmentsService
                .all('imed-claim-service', this.imedClaimServiceId, 'get-attachments-for-email-compilation')
                .pipe(tap(({ body: documents }) => (this.attachments = documents)));
        } else if (this.individualId) {
            return this.commonattachmentsService
                .all('individual', this.individualId, 'get-attachments-for-email-compilation')
                .pipe(tap(({ body: documents }) => (this.attachments = documents)));
        } else if (this.addressBookId) {
            return this.commonattachmentsService
                .all('address-book', this.addressBookId, 'get-attachments-for-email-compilation')
                .pipe(tap(({ body: documents }) => (this.attachments = documents)));
        } else if (this.imedClaimId) {
            return this.commonattachmentsService
                .all('imed-claim', this.imedClaimId, 'get-attachments-for-email-compilation')
                .pipe(tap(({ body: documents }) => (this.attachments = documents)));
        } else {
            return null;
        }
    }

    showEmailForm(): void {
        if (this.modalWrapperApi) {
            this.modalWrapperApi.show();
        }
        this.cdr.detectChanges(); // Needed for use in CommunicationLogResendCellComponent
    }

    closeEmailModal(): void {
        this.modalWrapperApi.close();

        this.onClose.emit();
    }

    handleEmailSend(): void {
        if (this.originalCommunicationLogId) {
            this.sendEmail(this.resend);
        } else {
            this.sendEmail(this.send);
        }
    }

    sendEmail(sendFunction: (email: ICommunicationRequest) => void): void {
        this.isMergingOrSending = true;
        if (this.emailForm.valid) {
            const email: ICommunicationRequest = {
                AppointmentId: null,
                AttachmentLinks: this.attachmentLinks,
                BCC: this.emailBCC.value,
                Body: this.emailBody.value,
                CC: this.emailCC.value,
                EmailTemplateId: this.selectedTemplate ? this.selectedTemplate.Id : this.resendEmailTemplateId ? this.resendEmailTemplateId : null,
                ImedClaimServiceId: this.imedClaimServiceId,
                IsReportForwardingEmail: this.selectedTemplate?.Name.toLowerCase() === 'report forwarded to client',
                Recepient: this.emailTo.value,
                Subject: this.emailSubject.value,
                TaskId: this.taskId,
            };
            if (this.isFeeSchedule) {
                if (this.fileToUpload && this.previewMode) {
                    this.uploadPdfAndSend(email, sendFunction);
                } else {
                    this.generateFeeSchedule(email, sendFunction);
                }
            } else {
                sendFunction.call(this, email);
            }
        } else {
            this.isMergingOrSending = false;
            markAllFormFieldsAsTouched(this.emailForm);
            setTimeout(() => (this.doubleClickIsDisabled = false));
            this.notificationsService.error('Please check form and try again');
        }
    }

    generateFeeSchedule(email: ICommunicationRequest, sendFunction: (email: ICommunicationRequest) => void): void {
        const physicianIds = this.selectedPhysicians.map((p) => p.PhysicianId);
        this.baseAttachmentService.generateFeeSchedule(this.imedClaimServiceId, physicianIds).subscribe((attachmentLinks) => {
            email.AttachmentLinks = this.attachmentLinks.concat(attachmentLinks);
            email.IndividualIds = this.selectedPhysicians.map((p) => p.IndividualId);
            sendFunction.call(this, email);
        });
    }

    uploadPdfAndSend(email: ICommunicationRequest, sendFunction: (email: ICommunicationRequest) => void): void {
        if (this.fileToUpload) {
            this.isMergingOrSending = true;
            this.baseAttachmentService
                .createAndUpload('imed-claim-service', this.imedClaimServiceId, this.fileToUpload, AttachmentTypeEnums.Fee_Schedule, 0)
                .subscribe((document) => {
                    const attachmentLink: IAttachmentLinks = { AttachmentName: document.Name, AttachmentPath: document.FilePath };
                    email.AttachmentLinks = [attachmentLink];
                    email.IndividualIds = this.selectedPhysicians.map((p) => p.IndividualId);
                    sendFunction.call(this, email);
                });
        } else {
            setTimeout(() => {
                this.isMergingOrSending = false;
            });
        }
    }

    createECDocDTO(email: ICommunicationRequest): IDocumentGenerationDTO {
        const documentAttachments = [];
        email.AttachmentLinks.forEach((link) => {
            documentAttachments.push(link.AttachmentPath);
        });
        return {
            EntityId: this.getCurrentEntityId(),
            Extras: { EmailTemplateName: this.selectedTemplate?.Name, DocumentPathsToCompile: documentAttachments },
        };
    }

    getCurrentEntityId(): number {
        if (this.imedClaimServiceId) {
            return this.imedClaimServiceId;
        } else if (this.individualId) {
            if (this.physicianId) {
                return this.physicianId;
            }
            return this.individualId;
        } else if (this.addressBookId) {
            return this.addressBookId;
        } else if (this.imedClaimId) {
            return this.imedClaimId;
        } else {
            return null;
        }
    }

    getCurrentEntityTypeName(): string {
        if (this.imedClaimServiceId) {
            return 'email-attachments';
        } else if (this.individualId) {
            if (this.physicianId) {
                return 'physician-email-attachments';
            }
            return 'individual-email-attachments';
        } else if (this.addressBookId) {
            return 'address-book-email-attachments';
        } else if (this.imedClaimId) {
            return 'imed-claim-email-attachments';
        } else {
            return null;
        }
    }

    send = (email: ICommunicationRequest): void => {
        if (this.individualId) {
            email.IndividualIds = [this.individualId];
        }
        if (this.imedClaimId) {
            email.ImedClaimId = this.imedClaimId;
        }
        if (email.AttachmentLinks.length >= 1 && (this.imedClaimServiceId || this.addressBookId || this.individualId || this.imedClaimId)) {
            this.faxEmailService.upload(this.getCurrentEntityTypeName(), this.createECDocDTO(email)).subscribe((documentId: number) => {
                this.documentsService.getById(documentId).subscribe((newlyCreatedDocument) => {
                    const attachmentLink: IAttachmentLinks = {
                        AttachmentName: newlyCreatedDocument.Name,
                        AttachmentPath: newlyCreatedDocument.FilePath,
                    };
                    email.AttachmentLinks = [attachmentLink];
                    if (this.sendAsAttachment && this.sendAsAttachment.value) {
                        email.DocumentId = newlyCreatedDocument.Id;
                        this.faxEmailService
                            .sendEmailWithAttachments(email)
                            .pipe()
                            .subscribe(
                                () => {
                                    this.notificationsService.success('Email sent successfully');
                                    this.communicationLogService.notifySubscribersOfNewLogs();
                                    if (this.imedClaimServiceId) {
                                        this.commonImedClaimServiceService.getById(this.imedClaimServiceId).subscribe((response) => {
                                            this.commonImedClaimServiceService.emitChange(response);
                                        });
                                    }

                                    this.closeEmailModal();
                                },
                                () => (this.isMergingOrSending = false),
                            );
                    } else {
                        this.faxEmailService
                            .sendEmail(email)
                            .pipe()
                            .subscribe(
                                () => {
                                    this.notificationsService.success('Email sent successfully');
                                    this.communicationLogService.notifySubscribersOfNewLogs();
                                    if (this.imedClaimServiceId) {
                                        this.commonImedClaimServiceService.getById(this.imedClaimServiceId).subscribe((response) => {
                                            this.commonImedClaimServiceService.emitChange(response);
                                        });
                                    }

                                    this.closeEmailModal();
                                },
                                () => (this.isMergingOrSending = false),
                            );
                    }
                });
            });
        } else {
            this.faxEmailService
                .sendEmail(email)
                .pipe()
                .subscribe(
                    () => {
                        this.notificationsService.success('Email sent successfully'), this.communicationLogService.notifySubscribersOfNewLogs();
                        this.closeEmailModal();
                    },
                    () => (this.isMergingOrSending = false),
                );
        }
    };

    resend = (email: ICommunicationRequest): void => {
        if (this.sendAsAttachment && this.sendAsAttachment.value) {
            this.faxEmailService.resendEmail(this.originalCommunicationLogId, email).subscribe(
                () => {
                    this.notificationsService.success('Email resent successfully'), this.communicationLogService.notifySubscribersOfNewLogs();
                    this.closeEmailModal();
                },
                () => (this.isMergingOrSending = false),
            );
        } else {
            this.faxEmailService.resendEmailWithoutAttachment(this.originalCommunicationLogId, email).subscribe(
                () => {
                    this.notificationsService.success('Email resent successfully'), this.communicationLogService.notifySubscribersOfNewLogs();
                    this.closeEmailModal();
                },
                () => (this.isMergingOrSending = false),
            );
        }
    };

    handleDocumentEmailResponse(): void {
        this.closeEmailModal();
    }

    mergeTemplate(): void {
        this.isMergingOrSending = true;
        if (this.imedClaimServiceId) {
            this.emailTemplateService
                .mergeEmailTemplate(this.selectedTemplate.Id, this.imedClaimServiceId)
                .pipe(
                    finalize(() => this.exitLoadingMode()),
                    switchMap((mergedTemplate) => {
                        return this.setMergedTemplateAndGetAttachments(mergedTemplate);
                    }),
                )
                .subscribe(() => {
                    this.setDefaultAttachments();
                });
        } else if (this.individualId) {
            this.emailTemplateService
                .mergeIndividualEmailTemplate(this.selectedTemplate.Id, this.individualId)
                .pipe(
                    finalize(() => this.exitLoadingMode()),
                    switchMap((mergedTemplate) => {
                        return this.setMergedTemplateAndGetAttachments(mergedTemplate);
                    }),
                )
                .subscribe(() => {
                    this.setDefaultAttachments();
                });
        } else if (this.addressBookId) {
            this.emailTemplateService
                .mergeAddressBookEmailTemplate(this.selectedTemplate.Id, this.addressBookId)
                .pipe(
                    finalize(() => this.exitLoadingMode()),
                    switchMap((mergedTemplate) => {
                        return this.setMergedTemplateAndGetAttachments(mergedTemplate);
                    }),
                )
                .subscribe(() => {
                    this.setDefaultAttachments();
                });
        } else if (this.imedClaimId) {
            this.emailTemplateService
                .mergeCaseEmailTemplate(this.selectedTemplate.Id, this.imedClaimId)
                .pipe(
                    finalize(() => this.exitLoadingMode()),
                    switchMap((mergedTemplate) => {
                        return this.setMergedTemplateAndGetAttachments(mergedTemplate);
                    }),
                )
                .subscribe(() => {
                    this.setDefaultAttachments();
                });
        }
    }

    exitLoadingMode(): void {
        setTimeout(() => {
            this.isMergingOrSending = false;
        });
    }

    setMergedTemplateAndGetAttachments(mergedTemplate: IEmailTemplate): any {
        this.emailTemplate = mergedTemplate;
        if (mergedTemplate.CanHaveAttachment) {
            return this.getAttachments();
        } else {
            return of(0);
        }
    }

    setDefaultAttachments(): void {
        if (this.imedClaimServiceId) {
            // if service email - filter attachment type selections only to template associated attachment types
            this.defaultAttachmentTypeIds =
                this.selectedTemplate && this.selectedTemplate.EmailTemplateAttachments
                    ? this.selectedTemplate.EmailTemplateAttachments.map((eta) => eta.AttachmentTypeId)
                    : [];
        } else if (this.imedClaimId) {
            this.defaultAttachmentTypeIds =
                this.selectedTemplate && this.selectedTemplate.EmailTemplateAttachments
                    ? this.selectedTemplate.EmailTemplateAttachments.map((eta) => eta.AttachmentTypeId)
                    : [];
        } else {
            // if address book or individual email - allow all available attachment types
            this.defaultAttachmentTypeIds = this.attachments.map((x) => x.AttachmentTypeId);
        }
        this.canEditAttachments = this.selectedTemplate.IsAttachmentsEditable;
    }

    downloadDoc(docId: number): void {
        const doc = this.attachments.find((a) => a.Id === docId);
        if (doc.IsManual) {
            // The type argument does not matter (imed-claim-service or physician) here, the backend does a getById on the documentId passed
            this.commonattachmentsService.downloadUploadedDocuments('imed-claim-service', doc.Id).subscribe(
                (x) => {
                    const thefile = new Blob([x], { type: 'application/octet-stream' });
                    saveAs(thefile, doc.Name);
                },
                (err) => {
                    this.docTransactionLogService
                        .logError(err as HttpErrorResponse, doc.Id, DocumentTransactionTypeEnums.MANUAL_DOWNLOAD_FE)
                        .subscribe();
                    this.notificationsService.error('Document download failed');
                },
            );
        } else {
            this.commonattachmentsService.downloadEmailedDocument('imed-claim-service', doc.Id).subscribe(
                (x) => {
                    const thefile = new Blob([x], { type: 'application/octet-stream' });
                    saveAs(thefile, doc.Name);
                },
                (err) => {
                    this.docTransactionLogService
                        .logError(err as HttpErrorResponse, doc.Id, DocumentTransactionTypeEnums.MANUAL_DOWNLOAD_FE)
                        .subscribe();
                    this.notificationsService.error('Document download failed');
                },
            );
        }
    }

    downloadFeeScheduleAsDocX(): void {
        if (this.selectedPhysicians && this.selectedPhysicians.length) {
            const physicianIds = this.selectedPhysicians.map((p) => p.PhysicianId);
            this.isMergingOrSending = true;
            this.faxEmailService
                .generateDocumentsAsDocX('fee-schedule', { EntityId: this.imedClaimServiceId, Extras: physicianIds } as IDocumentGenerationDTO)
                .pipe(
                    finalize(() => {
                        this.isMergingOrSending = false;
                    }),
                )
                .subscribe(
                    (data) => {
                        const thefile = new Blob([data.body as BlobPart], {
                            type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                        });
                        saveAs(thefile, atob(data.headers.get('X-File-Name') as string));
                    },
                    () => {
                        this.notificationsService.error('Document Download failed.');
                    },
                );
        } else {
            this.notificationsService.error('Physician must be selected to preview.');
        }
    }

    setFileToUpload($event): void {
        const file: File = $event.target.files[0];
        if (file.name.toLowerCase().endsWith('pdf')) {
            this.fileToUpload = file;
        } else {
            this.notificationsService.error('File must be pdf, please try again.');
        }
    }

    // Email select
    openEmailSelect(): void {
        if (!this.overlayRef || !this.overlayRef.hasAttached()) {
            this.createOverlay(this.overlayPositionBuilder.global().right('10%'));
            // Component portal dyanmically creates an instance of the component type passed as argument
            const portal = new ComponentPortal(SelectContactsEmail);
            // Attaches the dynamically created component to the overlay created in createOverlay() method
            const emailSelect: ComponentRef<SelectContactsEmail> = this.overlayRef.attach(portal);
            // Setting the email contact select components claimId property
            emailSelect.instance.imedClaimServiceId = this.imedClaimServiceId;
            this.subscribeToEmailSelect(emailSelect);
        }
    }

    openEmailCCSelect(): void {
        if (!this.overlayRef || !this.overlayRef.hasAttached()) {
            this.createOverlay(this.overlayPositionBuilder.global().right('10%'));
            const portal = new ComponentPortal(SelectContactsEmail);
            const emailSelect: ComponentRef<SelectContactsEmail> = this.overlayRef.attach(portal);
            emailSelect.instance.imedClaimServiceId = this.imedClaimServiceId;
            this.subscribeToCCEmailSelect(emailSelect);
        }
    }

    openEmailBCCSelect(): void {
        if (!this.overlayRef || !this.overlayRef.hasAttached()) {
            this.createOverlay(this.overlayPositionBuilder.global().right('10%'));
            const portal = new ComponentPortal(SelectContactsEmail);
            const emailSelect: ComponentRef<SelectContactsEmail> = this.overlayRef.attach(portal);
            emailSelect.instance.imedClaimServiceId = this.imedClaimServiceId;
            this.subscribeToBCCEmailSelect(emailSelect);
        }
    }

    // Email select
    openAddressbookSelectionDisplay(): void {
        if (!this.overlayRef || !this.overlayRef.hasAttached()) {
            this.createOverlay(this.overlayPositionBuilder.global().right('10%'));
            const portal = new ComponentPortal(SelectedAddressBookDisplayComponent);
            const component: ComponentRef<SelectedAddressBookDisplayComponent> = this.overlayRef.attach(portal);
            this.selectedPhysiciansDisplayComponent = component.instance;
            this.selectedPhysiciansDisplayComponent.selectedAddressBooks = this.selectedPhysicians;
            this.subscribeToPhysicianRemoval(component);
        }
    }

    // Creates overlay modal that email select component is rendered in
    createOverlay(positionStrategy: GlobalPositionStrategy): void {
        // overlay-on-top class sets a high z-index to ensure the overlay is on top regardless of other modals being open
        this.overlayRef = this.overlay.create({ positionStrategy, disposeOnNavigation: true, panelClass: 'overlay-on-top' });
        this.draggable.createDrag(this.overlayRef.overlayElement);
    }

    subscribeToPhysicianRemoval(component: ComponentRef<SelectedAddressBookDisplayComponent>): void {
        this.subscriptions.add(
            component.instance.onAddressBookRemoved.subscribe((physician: IAddressBookSelectEntity) => {
                const newPhysicians = this.selectedPhysiciansControl.value.filter((p) => !p.includes(physician.IndividualName));
                this.selectedPhysiciansControl.setValue(newPhysicians);
                this.selectedPhysicians = this.selectedPhysicians.filter((p) => p.IndividualId !== physician.IndividualId);
                this.selectedPhysiciansDisplayComponent.selectedAddressBooks = this.selectedPhysicians;
            }),
        );
        this.subscriptions.add(component.instance.onClose.subscribe(() => this.overlayRef.detach()));
    }

    subscribeToEmailSelect(component: ComponentRef<SelectContactsEmail>): void {
        // subscribe to the SelectContactsEmail close event and detach overlay from DOM when event is fired
        this.subscriptions.add(component.instance.close.subscribe(() => this.overlayRef.detach()));
        // susbcribe to SelectContactsEmail  selectEmails event and set the emailTo form value to the event value
        this.subscriptions.add(
            component.instance.selectEmails.subscribe((items) => {
                this.emailTo.setValue(items.join(', '));
            }),
        );
    }

    subscribeToCCEmailSelect(component: ComponentRef<SelectContactsEmail>): void {
        // subscribe to the SelectContactsEmail close event and detach overlay from DOM when event is fired
        this.subscriptions.add(component.instance.close.subscribe(() => this.overlayRef.detach()));
        // susbcribe to SelectContactsEmail  selectEmails event and set the emailTo form value to the event value
        this.subscriptions.add(
            component.instance.selectEmails.subscribe((items) => {
                this.emailCC.setValue(items.join(', '));
            }),
        );
    }

    subscribeToBCCEmailSelect(component: ComponentRef<SelectContactsEmail>): void {
        // subscribe to the SelectContactsEmail close event and detach overlay from DOM when event is fired
        this.subscriptions.add(component.instance.close.subscribe(() => this.overlayRef.detach()));
        // susbcribe to SelectContactsEmail  selectEmails event and set the emailTo form value to the event value
        this.subscriptions.add(
            component.instance.selectEmails.subscribe((items) => {
                this.emailBCC.setValue(items.join(', '));
            }),
        );
    }

    // Fee schedule physician select
    openAddressBook(): void {
        this.openAddressbookSelectionDisplay();

        this.addressSelectService.openAddressBookSelect('Physician', this.selectPhysician, true, this.imedClaimTypeId);
    }

    selectPhysician = (selectedPhysician: IAddressBookSelectEntity): void => {
        if (!this.selectedPhysicians.some((p) => p.PhysicianId === selectedPhysician.PhysicianId)) {
            const newPhysicians = this.selectedPhysiciansControl.value.concat([' ' + selectedPhysician.IndividualName]);
            this.selectedPhysiciansControl.setValue(newPhysicians);
            this.selectedPhysicians.push(selectedPhysician);
            this.selectedPhysiciansDisplayComponent.selectedAddressBooks = this.selectedPhysicians;
        }
    };

    clearPhysicians(): void {
        this.selectedPhysiciansControl.reset([]);
        this.selectedPhysicians = [];
    }
}
