// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            :host ::ng-deep .cke_contents {
                min-height: 600px;
            }
        `, "",{"version":3,"sources":["webpack://./src/app/generated-letter-templates/generated-letter-template-basic-info/generated-letter-template-basic-info.componen.ts"],"names":[],"mappings":";YACY;gBACI,iBAAiB;YACrB","sourcesContent":["\n            :host ::ng-deep .cke_contents {\n                min-height: 600px;\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
