import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '@mt-ng2/base-service';
import { Observable, BehaviorSubject } from 'rxjs';
import { IAttachmentDTO } from '@model/interfaces/custom/attachment-dto';
import { IAttachmentLinks } from '@model/interfaces/custom/communication-request';
import { IDocumentGenerationDTO } from '@model/interfaces/custom/document-generation-dto';
import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';

export enum DocumentCardSortPropertyEnums {
    AttachmentType = 1,
    Filename,
    Service,
    UploadedBy,
    DateUploaded,
    Source,
    Pages,
}

export enum DocumentCardSortDirectionEnums {
    Asc,
    Desc,
}

export enum EntityTypeEnums {
    imedClaim = 'imed-claim',
    imedClaimService = 'imed-claim-service',
    individual = 'individual',
    physician = 'physician',
    addressBook = 'address-book',
}

export interface IDocumentSortParams {
    property: DocumentCardSortPropertyEnums;
    direction: DocumentCardSortDirectionEnums;
}

@Injectable()
export class BaseAttachmentsService extends BaseService<IAttachmentDTO> {
    private _sortPropertyMap = new Map<DocumentCardSortPropertyEnums, string>([
        [DocumentCardSortPropertyEnums.AttachmentType, 'AttachmentType.Name'],
        [DocumentCardSortPropertyEnums.Filename, 'Name'],
        [DocumentCardSortPropertyEnums.Service, 'EntityName'],
        [DocumentCardSortPropertyEnums.UploadedBy, 'UploadedBy.FirstName'],
        [DocumentCardSortPropertyEnums.DateUploaded, 'DateUploaded'],
        [DocumentCardSortPropertyEnums.Source, 'UploadSource.Name'],
        [DocumentCardSortPropertyEnums.Pages, 'NoOfPages'],
    ]);

    private _sortOrderMap = new Map<DocumentCardSortDirectionEnums, string>([
        [DocumentCardSortDirectionEnums.Asc, 'Asc'],
        [DocumentCardSortDirectionEnums.Desc, 'Desc'],
    ]);

    private _defaultSort: IDocumentSortParams = {
        direction: DocumentCardSortDirectionEnums.Asc,
        property: DocumentCardSortPropertyEnums.AttachmentType,
    };
    public sortProperty: BehaviorSubject<IDocumentSortParams> = new BehaviorSubject<IDocumentSortParams>(this._defaultSort);
    public refreshRequested: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(public http: HttpClient) {
        super('/', http);
    }

    resetSortProperty(): void {
        // Unsubscribe the observers before updating the value to prevent an extra data fetch
        this.sortProperty.observers.map((o) => o.complete());
        this.sortProperty.next(this._defaultSort);
    }

    currentSort(): Observable<IDocumentSortParams> {
        return this.sortProperty.asObservable();
    }

    requestRefresh(): void {
        this.refreshRequested.next(true);
    }

    // this is used for manual update to Documents table
    createAndUpload(type: string, entityId: number, file: File, attachmentTypeId: number, templateId: number, noofPages = 0): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post<any>(`/documents/${type}/${entityId}/${attachmentTypeId}/${noofPages}/${templateId}`, formData);
    }

    // this is used for deleting document that was uploaded manually from Documents table
    deleteAttachment(type: string, entityId: number, documentId: number): Observable<any> {
        return this.http.delete<any>('/documents/' + type + '/' + entityId + '/' + documentId);
    }

    deleteBulkAttachments(type: string, entityId: number, documentIds: number[]): Observable<any> {
        return this.http.post<any>(`/documents/${type}/${entityId}/bulk-delete`, documentIds);
    }

    // this is used for get documents, can get document from document queue or documents table
    downloadUploadedDocuments(type: string, documentId: number): Observable<Blob> {
        return this.http.get('/documents/' + type + '/' + documentId, { responseType: 'blob' as const });
    }

    downloadEmailedDocument(type: string, documentId: number): Observable<Blob> {
        return this.http.get('/email-document/' + type + '/' + documentId, { responseType: 'blob' as const });
    }

    // this is used for get all documents from both document queue and documents table
    all(type: string, entityId: number, name: string): Observable<HttpResponse<IAttachmentDTO[]>> {
        const extraParams: ExtraSearchParams[] = [];
        extraParams.push(
            new ExtraSearchParams({
                name: 'EntityId',
                value: entityId.toString(),
            }),
        );

        const searchparams: IEntitySearchParams = {
            extraParams: extraParams,
            order: this._sortPropertyMap.get(this.sortProperty.getValue().property),
            orderDirection: this._sortOrderMap.get(this.sortProperty.getValue().direction),
            query: name,
        };
        const searchEntity = new SearchParams(searchparams);
        const params = this.getHttpParams(searchEntity);

        return this.http.get<IAttachmentDTO[]>('/documents/' + type + '/all/', { observe: 'response', params: params });
    }

    // this is used to fetch documents from both document queue and documents table in batches
    search(
        type: string,
        entityId: number,
        skip: number,
        take: number,
        name: string,
        forDownloadModal = false,
        loadAllDocs = false,
    ): Observable<HttpResponse<IAttachmentDTO[]>> {
        const extraParams: ExtraSearchParams[] = [];
        extraParams.push(
            new ExtraSearchParams({
                name: 'EntityId',
                value: entityId.toString(),
            }),
            new ExtraSearchParams({
                name: 'ForDownloadModal',
                value: forDownloadModal ? 'true' : 'false',
            }),
            new ExtraSearchParams({
                name: 'IncludeEmailAttachments',
                value: 'false',
            }),
            new ExtraSearchParams({
                name: 'LoadAllDocs',
                value: loadAllDocs ? 'true' : 'false',
            }),
        );

        const searchparams: IEntitySearchParams = {
            extraParams: extraParams,
            order: this._sortPropertyMap.get(this.sortProperty.getValue().property),
            orderDirection: this._sortOrderMap.get(this.sortProperty.getValue().direction),
            query: name,
            skip: skip,
            take: take,
        };
        const searchEntity = new SearchParams(searchparams);

        const params = this.getHttpParams(searchEntity);
        return this.http.get<IAttachmentDTO[]>('/documents/' + type + '/_search/', { observe: 'response', params: params });
    }

    getDocumentDataForRouting(documentId: number): Observable<any> {
        return this.http.get<any>(`/changeLogs/document-get-data-for-routing/${documentId}`);
    }

    getDocumentQueueDataForRouting(documentId: number): Observable<any> {
        return this.http.get<any>(`/changeLogs/document-queue-get-data-for-routing/${documentId}`);
    }

    generateFeeSchedule(entityId: number, physicianIds: number[]): Observable<IAttachmentLinks> {
        const payload: IDocumentGenerationDTO = {
            EntityId: entityId,
            Extras: physicianIds,
        };
        return this.http.post<IAttachmentLinks>('/fee-schedule', payload, { observe: 'body' });
    }
}
