import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SharedEntitiesService } from '@mt-ng2/shared-entities-module';
import { Observable } from '../../../../../node_modules/rxjs';
import { AuthService } from '../../../../../node_modules/@mt-ng2/auth-module';
import { IPhoneNumber } from '../../interfaces/phone-number';

export const emptyPhoneNumber: IPhoneNumber = {
    Archived: false,
    CreatedById: null,
    DateCreated: new Date(),
    Id: 0,
    IsPrimary: false,
    ModifiedById: null,
    Notes: '',
    Phone: null,
    PhoneTypeId: 1,
};

@Injectable()
export class SharedPhoneNumberService extends SharedEntitiesService<IPhoneNumber> {
    constructor(public baseEntityUrl: string, public http: HttpClient, public authService: AuthService) {
        super(baseEntityUrl, '/phone-numbers', http);
    }

    saveEntity(parentId: number, entity: IPhoneNumber): Observable<number> {
        if (entity.Id === 0) {
            entity.CreatedById = this.authService.currentUser.getValue().Id;
        } else {
            entity.ModifiedById = this.authService.currentUser.getValue().Id;
        }
        return super.saveEntity(parentId, entity);
    }
}
