import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';
import { ISubpoenaService } from '@model/interfaces/subpoena-service';
import { ISubpoenaServiceDynamicControlsParameters } from '@model/form-controls/subpoena-service.form-controls';
import { SubpoenaServiceDynamicControlsPartial } from '@model/partials/subpoena-service.form-controls.partial';
import { ICourtAddressBookDTO } from '@model/interfaces/custom/court-address-book-dto';
import { ICourtProcessServer } from '@model/interfaces/court-process-server';

export class SubpoenaServiceDynamicConfig<T extends ISubpoenaService> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private subpoenaService: T, private courts?: ICourtAddressBookDTO[], private processServer?: ICourtProcessServer[], private configControls?: string[]) {
        super();
        const additionalParams: ISubpoenaServiceDynamicControlsParameters = {};
        const dynamicControls = new SubpoenaServiceDynamicControlsPartial(this.subpoenaService, this.courts, this.processServer, additionalParams);

        if (!configControls) {
            this.configControls = [
                'ImedClaimServiceId',
                'RadiologyFacility',
                'ProcessingDate',
                'CreatedById',
                'ModifiedById',
                'DateCreated',
                'DateModified',
                'Archived',
            ];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
