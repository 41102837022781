import { Component, Input } from '@angular/core';
import { AddressBooksSelectService } from '../../common/address-book-select-list/address-books-select.service';
import { IAddressBookSelectEntity } from '../../common/address-book-select-list/address-books-select-entity';
import { IAddressBookProcessServerDTO } from '@model/interfaces/custom/address-book-process-server.dto';
import { CourtProcessServerService } from '../court-process-server/court-process-server.service';

@Component({
    selector: 'app-address-book-process-server',
    templateUrl: './address-book-process-server.component.html',
})
export class AddressBookProcessServerComponent {
    @Input() ProcessServer: IAddressBookProcessServerDTO;
    @Input() index: number;

    constructor(
        private addressSelectService: AddressBooksSelectService,
        private courtProcessServerService: CourtProcessServerService,
    ) {}

    ClearAddressBooks(): void {
        this.courtProcessServerService.newProcessServer.splice(this.index, 1);
        if (this.courtProcessServerService.newProcessServer.length === 0) {
            this.courtProcessServerService.newProcessServer.push(this.courtProcessServerService.getEmptyProcessServer());
        }
    }

    SearchAddressBooks(): void {
        this.addressSelectService.openAddressBookSelect('ProcessServer', this.select);
    }

    select = (addressBookSelect: IAddressBookSelectEntity): void => {
        this.ProcessServer.AddressBookId = addressBookSelect.AddressBookId;
        this.ProcessServer.Name = addressBookSelect.AddressBookName;
    }
}
