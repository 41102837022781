// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            .dayOffsetSpan {
                position: absolute;
                top: 5.5px;
                left: 200px;
            }
            .dayOffsetInput {
                width: 50px;
            }
            .block {
                display: block;
            }
        `, "",{"version":3,"sources":["webpack://./src/app/task-status-followup-engine/add-system-task-form/add-system-task-form.component.ts"],"names":[],"mappings":";YACY;gBACI,kBAAkB;gBAClB,UAAU;gBACV,WAAW;YACf;YACA;gBACI,WAAW;YACf;YACA;gBACI,cAAc;YAClB","sourcesContent":["\n            .dayOffsetSpan {\n                position: absolute;\n                top: 5.5px;\n                left: 200px;\n            }\n            .dayOffsetInput {\n                width: 50px;\n            }\n            .block {\n                display: block;\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
