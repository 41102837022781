import { Validators } from '@angular/forms';
import { IExpandableObject } from '../expandable-object';

import {
    DynamicField,
    DynamicFieldTypes,
    DynamicFieldType,
    InputTypes,
    ITimepickerOptions,
    IDynamicFieldType,
    DynamicLabel,
    SelectInputTypes,
    noZeroRequiredValidator,
} from '@mt-ng2/dynamic-form';

import {
    ImedClaimServiceFollowupDynamicControls,
    IImedClaimServiceFollowupDynamicControlsParameters,
} from '../form-controls/imed-claim-service-followup.form-controls';
import { IImedClaimServiceFollowup } from '../interfaces/imed-claim-service-followup';
import { CommunicationMethodEnums, ViaEnums } from '../../common/constants/Enums';
import { MetaItem } from '@mt-ng2/base-service';

export class ImedClaimServiceFollowupDynamicControlsPartial extends ImedClaimServiceFollowupDynamicControls {
    communicationMethods: MetaItem[] = [
        new MetaItem(CommunicationMethodEnums.TELEPHONE, 'Telephone'),
        new MetaItem(CommunicationMethodEnums.EMAIL, 'EMail'),
        new MetaItem(CommunicationMethodEnums.MAIL, 'Mail'),
        new MetaItem(CommunicationMethodEnums.ELECTRONIC, 'Electronic'),
        new MetaItem(CommunicationMethodEnums.NA, 'N/A'),
    ];

    viaTypes: MetaItem[] = [
        new MetaItem(ViaEnums.EMAIL, 'Email'),
        new MetaItem(ViaEnums.MAIL, 'Mail'),
        new MetaItem(ViaEnums.UPS, 'UPS'),
        new MetaItem(ViaEnums.SYSTEM_LINK, 'System Link'),
    ];

    constructor(
        imedclaimservicefollowupPartial?: IImedClaimServiceFollowup,
        additionalParameters?: IImedClaimServiceFollowupDynamicControlsParameters,
        canEditfollowupDate?: boolean,
    ) {
        super(imedclaimservicefollowupPartial, additionalParameters);

        (<DynamicField>this.Form.ReceivedAppointmentDateTime).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Input,
            inputType: InputTypes.DateTimepicker,
            timepickerOptions: { meridian: true } as ITimepickerOptions,
        });

        if (!canEditfollowupDate) {
            (<DynamicField>this.Form.Date).disabled = true;
        }
        (<DynamicField>this.Form.CommunicationMethod).type.fieldType = DynamicFieldTypes.Select;
        (<DynamicField>this.Form.CommunicationMethod).type.inputType = SelectInputTypes.RadioButtonList;
        (<DynamicField>this.Form.CommunicationMethod).options = this.communicationMethods;
        (<DynamicField>this.Form.CommunicationMethod).value = imedclaimservicefollowupPartial.CommunicationMethod
            ? this.communicationMethods.find((x) => x.Id === imedclaimservicefollowupPartial.CommunicationMethod).Id
            : imedclaimservicefollowupPartial.CommunicationMethod === 0
            ? 0
            : null;
        (<DynamicField>this.View.CommunicationMethod).value = imedclaimservicefollowupPartial.CommunicationMethod
            ? this.communicationMethods.find((x) => x.Id === imedclaimservicefollowupPartial.CommunicationMethod).Name
            : imedclaimservicefollowupPartial.CommunicationMethod === 0
            ? 'Telephone'
            : null;

        (<DynamicField>this.Form.Via).type.fieldType = DynamicFieldTypes.Select;
        (<DynamicField>this.Form.Via).type.inputType = SelectInputTypes.RadioButtonList;
        (<DynamicField>this.Form.Via).options = this.viaTypes;
        (<DynamicField>this.View.Via).value = imedclaimservicefollowupPartial.Via
            ? this.viaTypes.find((x) => x.Id === imedclaimservicefollowupPartial.Via).Name
            : imedclaimservicefollowupPartial.Via === 0
            ? 'Email'
            : null;

        (<DynamicField>this.Form.ReceivedAppointmentDateTime).setRequired(true);
        (<DynamicField>this.Form.ReceivedAppointmentDateTime).label = 'Appointment Date';
        (<DynamicField>this.Form.ReceivedAppointmentDateTime).labelHtml = '<label>Appointment Date</label>';

        (<DynamicField>this.Form.FollowupTypeId).label = 'Follow-up Type';
        (<DynamicField>this.Form.FollowupTypeId).labelHtml = 'Follow-up Type';

        (<DynamicField>this.View.FollowupTypeId).label = 'Follow-up Type';
        (<DynamicField>this.View.FollowupTypeId).label = 'Follow-up Type';

        // if (!(imedclaimservicefollowupPartial.ReceivedAppointmentDateTime instanceof Date) && imedclaimservicefollowupPartial.ReceivedAppointmentDateTime) {
        //     let offsetDate = new Date(imedclaimservicefollowupPartial.ReceivedAppointmentDateTime);
        //     offsetDate.setHours(offsetDate.getHours() - 4); // this is bad, offsetting 4 hours from db
        //     (<DynamicField>this.Form.ReceivedAppointmentDateTime).value = offsetDate;
        //     (<DynamicField>this.View.ReceivedAppointmentDateTime).value = offsetDate;
        // }

        (<DynamicLabel>this.View.ReceivedAppointmentDateTime).label = 'Appointment Date';
        (<DynamicField>this.Form.ReceiptOfNoticeOfImeConfirmedByName).setRequired(true);
        (<DynamicField>this.Form.ImeAppointmentConfirmedWithPlaintiffsAttorneyOfficeByName).setRequired(true);

        (<DynamicField>this.Form.PersonContactedOrSpokenTo).setRequired(true);
        (<DynamicField>this.Form.ReportForwardedToClientDate).label = 'Report forwarded Date';

        (<DynamicField>this.Form.FollowupTypeId).type.inputType = SelectInputTypes.Dropdown;

        if ((<DynamicLabel>this.View.ReportDueBy).value) {
            (<DynamicLabel>this.View.FollowupTypeId).value =
                'Report Due By ' + this.getDateWithNoOffset(new Date((<DynamicLabel>this.View.ReportDueBy).value.toString())).toLocaleDateString();
        }

        (<DynamicField>this.Form.CommunicationMethod).setRequired(true);
        (<DynamicField>this.Form.CommunicationMethod).validation = [Validators.required];
    }

    getDateWithNoOffset(date: Date): Date {
        const userTimezoneOffset = date.getTimezoneOffset() * 60000;
        return new Date(date.getTime() + userTimezoneOffset);
    }
}
