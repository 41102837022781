import { AddressBookSelectionEnums } from './../constants/Enums';
import { IAddressBooksForList } from '@model/interfaces/address-books-for-list.d';
import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import 'rxjs/operators';
import { ExtraSearchParams, SearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '@model/ClaimTypes';
import { AddressBooksSelectEntityListConfig } from './address-books-select.entity-list-config';
import { IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';
import { AddressBooksSelectService } from './address-books-select.service';
import { AddressBookService } from '../../address-books/addressbook.service';
import { entityListModuleConfig } from '../shared.module';
import { AppStateService, AppStates } from '../../app-state.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-address-books-select',
    templateUrl: './address-books-select.component.html',
})
export class AddressBooksSelectComponent implements OnInit, OnDestroy {
    @Input() typeFilter: string;
    @Output('closeAddressBookAction') closeAddressBookAction: EventEmitter<string> = new EventEmitter<string>();

    searchControl = new UntypedFormControl();
    addressBooks: IAddressBooksForList[];
    currentPage = 1;
    query = '';
    total: number;
    canAddAddressBook = false;

    entityListConfig = new AddressBooksSelectEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();

    subscriptions = new Subscription();
    searchParams: SearchParams;
    get isMultiSelectMode(): boolean {
        return this.addressBookSelectService.multiSelectMode;
    }
    constructor(
        private addressBookService: AddressBookService,
        private claimsService: ClaimsService,
        private addressBookSelectService: AddressBooksSelectService,
        private router: Router,
        private route: ActivatedRoute,
        private appStateService: AppStateService,
    ) {}

    ngOnInit(): void {
        if (!this.typeFilter) {
            this.typeFilter = this.addressBookSelectService.typeFilter;
        }
        this.canAddAddressBook = this.claimsService.hasClaim(ClaimTypes.AddressBooks, [ClaimValues.FullAccess]) && !this.isMultiSelectMode;
        this.subscriptions.add(
            this.router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    // the route has changed, so lets change the AppState to Main
                    // since we shouldn't be showing this search if a route change
                    // has occurred
                    this.appStateService.appState.next(AppStates.Main);
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    getAddressBooks(): void {
        const searchParams = this.searchParams;
        searchParams.order = this.order;
        searchParams.orderDirection = this.orderDirection;
        searchParams.skip = (this.currentPage - 1) * entityListModuleConfig.itemsPerPage;
        searchParams.take = entityListModuleConfig.itemsPerPage;

        searchParams.extraParams.push(
            new ExtraSearchParams({
                name: 'includeArchived',
                value: '0',
            }),
        );

        searchParams.extraParams.push(
            new ExtraSearchParams({
                name: 'ImedCaseTypeId',
                value: this.addressBookSelectService.imedClaimTypeId.toString(),
            }),
        );

        if (this.typeFilter === AddressBookSelectionEnums.Physician.toString()) {
            searchParams.extraParams.push(
                new ExtraSearchParams({
                    name: 'IsPhysician',
                    value: '1',
                }),
            );
        }

        if (this.typeFilter === AddressBookSelectionEnums.AddressBook.toString() || this.typeFilter === AddressBookSelectionEnums.Individual.toString()) {
            searchParams.extraParams.push(
                new ExtraSearchParams({
                    name: 'IsPhysician',
                    value: '0',
                }),
            );
        }

        this.addressBookService.searchAddressBooks(searchParams).subscribe((answer) => {
            this.addressBooks = answer.body;
            this.addressBooks.map((addressBook) => (addressBook.SearchType = this.typeFilter));
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getAddressBooks();
    }

    addAddressBook(): void {
        this.addressBookSelectService.openAddressBookAdd();
    }

    close(): void {
        this.closeAddressBookAction.emit('');
    }

    onSearchParamsUpdate(params: SearchParams): void {
        this.currentPage = 1;
        this.searchParams = params;
        this.getAddressBooks();
    }
}
