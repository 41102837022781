import { IImedClaimService } from '@model/partials/imed-claim-service.partial.d';
import {
    ServiceIdEnums,
    ViewLogEntityTypeEnums,
    CommunicationLogTypeEnums,
    AutomatedTemplateFaxEmailEntityTypeEnums,
} from './../../../common/constants/Enums';
import { Component, OnInit, OnDestroy, ComponentRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ImedClaimServiceService } from '../imedclaimservice.service';
import { ClaimsService, ClaimValues, AuthService } from '@mt-ng2/auth-module';
import { ClaimTypes } from '@model/ClaimTypes';
import { Observable, of, Subscription } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { IEntityUser, ConcurrentUsersSocketService } from '../../../common/hub/user-on-service-socket.service';
import { trigger, transition, style, animate } from '@angular/animations';
import { ViewLogService } from '../../../common/services/viewlog.service';
import { ImedClaimService } from '../../imedclaim.service';
import { IImedClaimPartial } from '@model/partials/imed-claim-partial';
import { IIMedClaimContactsInfo } from '../../imed-claim-basic-info/imed-claim-contacts-info';
import { EmailTemplateService } from '../../../email-templates/emailtemplate.service';
import { CommonEmailComponent } from '../../../common/common-email/common-email.component';
import { ICommunicationLogResponse } from '@model/interfaces/custom/communication-request';
import {
    CommunicationLogService,
    IResendCommunicationConfig,
    ResendCommunicationAttachmentTypes,
} from '../../../common/services/communication-log.service';
import { NullHostDirective } from '../../../common/directives/null-host.directive';
import { CommonFaxComponent } from '../../../common/common-fax/common-fax.component';
import { IAppATFaxEmailComponentApi } from '../../../common/at-fax-email/at-fax-email-component';
import { IName, NameTypeEnum } from '@model/interfaces/custom/name';

@Component({
    animations: [
        trigger('animate', [
            transition(':enter', [
                style({ transform: 'translateX(100%)', opacity: 0 }),
                animate('500ms', style({ transform: 'translateX(0)', opacity: 1 })),
            ]),
            transition(':leave', [
                style({ transform: 'translateX(0)', opacity: 1 }),
                animate('500ms', style({ transform: 'translateX(100%)', opacity: 0 })),
            ]),
        ]),
    ],

    selector: 'app-imed-claim-service-detail',
    styles: [
        `
            .slide {
                -webkit-transition: all 0.25s ease;
                -moz-transition: all 0.25s ease;
                -o-transition: all 0.25s ease;
                -ms-transition: all 0.25s ease;
                transition: all 0.25s ease;
            }
            .generateTemplateButton {
                position: absolute;
                bottom: 71px;
                left: 653px;
            }
            @media only screen and (max-width: 992px) {
                .generateTemplateButton {
                    bottom: 91px;
                }
            }
            @media only screen and (max-width: 768px) {
                .generateTemplateButton {
                    left: 422px;
                }
            }
        `,
    ],
    templateUrl: './imed-claim-service-detail.component.html',
})
export class ImedClaimServiceDetailComponent implements OnInit, OnDestroy {
    imedClaimServiceModel: IImedClaimService;
    imedClaim: IImedClaimPartial;
    imedClaimContacts: IIMedClaimContactsInfo;
    showClaimantInfo = true;
    canEdit: boolean;
    id: number;
    serviceIdEnum = ServiceIdEnums;
    ConcurrentUsers: IEntityUser[] = [];
    subscriptions: Subscription = new Subscription();
    socketOn = false;

    showATPopup: boolean;
    showSubpoenaPopup: boolean;
    showSubpoenaPackagePopup: boolean;
    showCompletedSubpoenaPopup: boolean;
    showNF10DocPopup: boolean;
    replaceSignature: boolean;

    entityTypeEnums = AutomatedTemplateFaxEmailEntityTypeEnums;

    // For injecting email component
    @ViewChild(NullHostDirective) commonEmailHost: NullHostDirective;

    get imeServiceTypesHasServiceId(): boolean {
        return this.imedClaimServiceService.imeServiceTypes.has(this.imedClaimServiceModel.ServiceId);
    }

    get serviceIsFilmReview(): boolean {
        return (
            this.imedClaimServiceModel.ServiceId === +ServiceIdEnums.Film_Review ||
            this.imedClaimServiceModel.ServiceId === +ServiceIdEnums.WC_Film_Review ||
            this.imedClaimServiceModel.ServiceId === +ServiceIdEnums.Auto_Radiology_Review ||
            this.imedClaimServiceModel.ServiceId === +ServiceIdEnums.Independent_Film_Review ||
            this.imedClaimServiceModel.ServiceId === +ServiceIdEnums.WC_Independent_Film_Review ||
            this.imedClaimServiceModel.ServiceId === +ServiceIdEnums.Auto_Independent_Film_Review
        );
    }

    get serviceIsAutoIme(): boolean {
        return (
            this.imedClaimServiceModel.ServiceId === +ServiceIdEnums.IME_Auto ||
            this.imedClaimServiceModel.ServiceId === +ServiceIdEnums.Auto_Addendum ||
            this.imedClaimServiceModel.ServiceId === +ServiceIdEnums.Auto_Re_Exam ||
            this.imedClaimServiceModel.ServiceId === +ServiceIdEnums.Auto_NF_10 ||
            this.imedClaimServiceModel.ServiceId === +ServiceIdEnums.Auto_Conference_Call
        );
    }

    get serviceIsAutoPeerOrRecordReview(): boolean {
        return (
            this.imedClaimServiceModel.ServiceId === +ServiceIdEnums.AUTO_Record_Review ||
            this.imedClaimServiceModel.ServiceId === +ServiceIdEnums.AUTO_Peer_Review
        );
    }

    constructor(
        protected imedClaimServiceService: ImedClaimServiceService,
        private imedClaimService: ImedClaimService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private router: Router,
        private ConcurrentUsersSocketService: ConcurrentUsersSocketService,
        private authService: AuthService,
        private viewLogService: ViewLogService,
        private emailTemplateService: EmailTemplateService,
        private commmunicationLogService: CommunicationLogService,
    ) {}

    ngOnInit(): void {
        // check claims
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.CaseManagement, [ClaimValues.FullAccess]);
        // get current id from route
        this.id = +this.route.snapshot.paramMap.get('serviceId');
        // If service is 0 redirect to the global services list page. This is neccesary to prevent the back button from bringing user to blank page from global follow up list.
        if (this.id === 0) {
            void this.router.navigate(['/cases/0/services']);
        }
        this.initConcurrentUsersSocket();
        this.getImedClaimServiceById(this.id).subscribe(() => {
            if (this.id) {
                this.createViewLog();
            }
        });
        if (!this.socketOn) {
            this.initConcurrentUsersSocket();
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        this.ConcurrentUsersSocketService.disconnect();
    }

    createViewLog(): void {
        const log = this.viewLogService.getEmptyViewLog();
        log.EntityType = ViewLogEntityTypeEnums.Service;
        log.Route = this.router.url;
        log.UserId = this.authService.currentUser.getValue().Id;
        log.View =
            'Claimant: ' +
            this.imedClaimServiceModel.ImedClaim.FirstName +
            ' ' +
            this.imedClaimServiceModel.ImedClaim.LastName +
            ' / Service: ' +
            this.imedClaimServiceModel.Service.Alias;
        this.viewLogService
            .create(log)
            .pipe(
                // Catch error and return empty observable in case max int size is reached in ViewLog table
                catchError((err) => of([])),
            )
            .subscribe();
    }

    initConcurrentUsersSocket(): void {
        this.socketOn = true;
        this.ConcurrentUsersSocketService.connect();
        this.ConcurrentUsersSocketService.join({
            ServiceId: this.id,
            UserId: this.authService.currentUser.getValue().Id,
            UserName: this.authService.currentUser.getValue().Name,
        });
        this.ConcurrentUsersSocketService.afterJoin().subscribe((users: IEntityUser[]) => {
            this.ConcurrentUsers = users.filter((user) => user.UserId !== this.authService.currentUser.getValue().Id);
        });

        this.ConcurrentUsersSocketService.afterLeave().subscribe((users: IEntityUser[]) => {
            this.ConcurrentUsers = users.filter((user) => user.UserId !== this.authService.currentUser.getValue().Id);
        });
    }

    getImedClaimServiceById(id: number): Observable<IImedClaimService> {
        return this.imedClaimServiceService.getById(id).pipe(
            tap((imedClaimService: IImedClaimService) => {
                this.imedClaimServiceModel = imedClaimService;
                this.canEdit = this.canEdit && !this.imedClaimServiceModel.ImedClaim.IsDisabled;
            }),
        );
    }

    refreshImedClaimServiceModelOnStatusChange(): void {
        this.getImedClaimServiceById(this.id).subscribe(() => {
            this.imedClaimServiceService.emitChange(this.imedClaimServiceModel);
        });
        setTimeout(() => {
            this.showATPopup = false;
            this.showSubpoenaPopup = false;
            this.showSubpoenaPackagePopup = false;
            this.showCompletedSubpoenaPopup = false;
            this.showNF10DocPopup = false;
        });
    }

    close(): void {
        void this.router.navigate([`cases/${this.imedClaimServiceModel.ImedClaimId}/services`]);
    }

    loadResendComponent(e: ICommunicationLogResponse): void {
        const data: IResendCommunicationConfig = {
            attachmentType: ResendCommunicationAttachmentTypes.SERVICE,
            component: e.Type === 'Fax' ? CommonFaxComponent : CommonEmailComponent,
            data: e,
        };
        this.commmunicationLogService.loadResendCommunicationComponent(this.commonEmailHost, data);
    }

    toggleAutomatedTemplateModal(isNF10Document: boolean): void {
        if (!isNF10Document) {
            this.showATPopup = !this.showATPopup;
        } else {
            this.showNF10DocPopup = !this.showNF10DocPopup;
        }
    }

    toggleSubpoenaTemplateModal(replaceSignature: boolean): void {
            this.replaceSignature = replaceSignature;
            this.showSubpoenaPopup = !this.showSubpoenaPopup;
    }

    toggleSubpoenaPackageTemplateModal(): void {
        this.showSubpoenaPackagePopup = !this.showSubpoenaPackagePopup;
    }

    toggleCompletedSubpoenaPackageTemplateModal(): void {
        this.showCompletedSubpoenaPopup = !this.showCompletedSubpoenaPopup;
    }

    getClaimantName(): IName {
        return { firstName: this.imedClaimServiceModel?.ImedClaim?.FirstName, lastName: this.imedClaimServiceModel?.ImedClaim?.LastName, nameType: NameTypeEnum.Individual };
    }
}
