import { Component } from '@angular/core';
import { IEntityListDynamicCellComponent, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { IEntity } from '@mt-ng2/base-service';
import { IAddressBooksForList } from '@model/interfaces/address-books-for-list';
import { IModalOptions } from '@mt-ng2/modal-module';

@Component({
    template: `
        <div *ngIf="addressBook && addressBook.FacilityArchived" class="text-center">
            <i (mtConfirm)="archiveAddressBook($event)" class="fa fa-trash fa-2x" aria-hidden="true" [mtConfirmOptions]="unarchiveConfirm"></i
            ><i class="fa fa-lg fa-undo overlay-fa"></i>
        </div>
        <div *ngIf="addressBook && canDelete && !addressBook.FacilityArchived">
            <i (mtConfirm)="archiveAddressBook($event)" class="fa fa-trash fa-2x" aria-hidden="true" [mtConfirmOptions]="archiveConfirm"></i>
        </div>
    `,
})
export class AddressBookDeleteDynamicCellComponent implements IEntityListDynamicCellComponent {
    entityListComponentMembers: IEntityListComponentMembers;
    set entity(value: IEntity) {
        this.addressBook = value as IAddressBooksForList;
        this.canDelete = !this.addressBook.IndividualId || (this.addressBook.IndividualId && !this.addressBook.FacilityName);
    }

    addressBook: IAddressBooksForList;
    canDelete: boolean;

    archiveConfirm: IModalOptions = {
        confirmButtonText: 'Yes, I am sure!',
        text: `Are you sure you want to modify this record?`,
        title: 'Delete Address Book',
    	icon: 'question',
};

    unarchiveConfirm: IModalOptions = {
        confirmButtonText: 'Yes, I am sure!',
        text: `Are you sure you want to modify this record?`,
        title: 'Restore Address Book',
    	icon: 'question',
};

    constructor() {}

    archiveAddressBook(event: Event): void {
        this.entityListComponentMembers.itemDeleted(this.addressBook as IEntity, event);
    }
}
