import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import 'rxjs/operators';
import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection, IItemDeletedEvent } from '@mt-ng2/entity-list-module';
import { ITaskTemplate } from '@model/interfaces/task-template';
import { SystemTaskEntityListConfig } from './system-task.entity-list-config';
import { TaskTemplateService } from '../services/tasktemplate.service';
import { entityListModuleConfig } from '../../common/shared.module';
import { ClaimTypes } from '@model/ClaimTypes';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ServiceService } from '../../imed-claims/services/service.service';
import { ImedClaimTypeService } from '@common/services/imed-claim-type.service';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'app-system-task-list',
    templateUrl: './system-task-list.component.html',
})
export class SystemTaskListComponent implements OnInit {
    searchControl = new UntypedFormControl();
    tasks: ITaskTemplate[];
    currentPage = 1;
    query = '';
    total: number;
    entityListConfig = new SystemTaskEntityListConfig();
    order = 'Id';
    orderDirection = 'asc';
    taskId: number;
    canAddTask: boolean;
    serviceTypes: MtSearchFilterItem[] = [];
    imedClaimTypes: MtSearchFilterItem[] = [];
    includeArchived = false;
    includeRecurring = true;

    constructor(
        private taskTemplateService: TaskTemplateService,
        private claimsService: ClaimsService,
        private router: Router,
        private serviceService: ServiceService,
        private imedClaimTypeService: ImedClaimTypeService,
        private notificationService: NotificationsService,
    ) {}

    ngOnInit(): void {
       forkJoin(this.serviceService.getServiceTypeSortedByName(),this.imedClaimTypeService.getSearchFilterItems()).subscribe((forkJoinResponses) => {
            const [serviceTypes,imedClaimTypes] = forkJoinResponses;
            this.serviceTypes = serviceTypes;
            this.imedClaimTypes = imedClaimTypes;
            this.getTasks();
       });
       this.canAddTask = this.claimsService.hasClaim(ClaimTypes.TaskStatusFollowupEngine, [ClaimValues.FullAccess]);
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    private buildSearch(): ExtraSearchParams[] {
        const _extraSearchParams: ExtraSearchParams[] = [];

        const selectedServiceIds: number[] = this.getSelectedFilters(this.serviceTypes);
        const selectedImedClaimTypeIds: number[] = this.getSelectedFilters(this.imedClaimTypes);

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'serviceIds',
                valueArray: selectedServiceIds,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'imedClaimTypeIds',
                valueArray: selectedImedClaimTypeIds,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'includeArchived',
                value: this.includeArchived ? '1' : '0',
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'includeRecurring',
                value: this.includeRecurring ? '1' : '0',
            }),
        );
        return _extraSearchParams;
    }

    getTasks(): void {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: entityListModuleConfig.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        this.taskTemplateService.search(searchparams).subscribe((response) => {
            this.tasks = response.body;
            this.total = response.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.query = query;
        this.currentPage = 1;
        this.getTasks();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getTasks();
    }

    taskSelected(event: IItemSelectedEvent): void {
        void this.router.navigate(['system-tasks', event.entity.Id]);
    }

    addTask(): void {
        void this.router.navigate([`system-tasks/add`]);
    }

    onItemDeleted(event: IItemDeletedEvent): void {
        this.taskTemplateService.archive(event.entity.Id as number).subscribe(() => {
            this.notificationService.success('System task archived');
            this.getTasks();
        });
    }
}
