import { Injectable } from '@angular/core';

export function formatAddress(addr: any): string {
    let fullAddress = '';
    fullAddress += addr && addr.Address1 ? addr.Address1 : '';
    fullAddress += addr && addr.Address2 ? ' ' + addr.Address2 : '';
    fullAddress += addr && addr.City ? ', ' + addr.City : '';

    fullAddress += addr && addr.StateCode ? ', ' + addr.StateCode : '';

    fullAddress += addr && addr.Zip ? ' ' + addr.Zip : '';
    fullAddress += addr && addr.CountyName ? ' ' + ' County - ' + addr.CountyName : '';
    fullAddress += addr && addr.County && addr.County.CountyName ? '- ' + addr.County.CountyName + ' County' : '';
    return fullAddress;
}

@Injectable()
export class AddressFormatterService {
    formatAddress(addr: any): string {
        return formatAddress(addr);
    }
}
