import { Component, OnInit, OnDestroy, ComponentRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subscription, forkJoin, Observable } from 'rxjs';
import { ImedClaimService } from '../imedclaim.service';
import { ClaimTypes } from '@model/ClaimTypes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { DatePipe } from '@angular/common';
import { IImedClaimPartial } from '@model/partials/imed-claim-partial';
import { filter } from 'rxjs/operators';
import { ImedClaimServiceService } from '../services/imedclaimservice.service';
import { Title } from '@angular/platform-browser';
import { SubServiceEnums, EmailTemplateTypes } from '../../common/constants/Enums';
import { OverlayRef, Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ImedClaimBasicInfoComponent } from '../imed-claim-basic-info/imed-claim-basic-info.component';
import { IImedClaim } from '@model/interfaces/imed-claim';
import { IIMedClaimContactsInfo } from '../imed-claim-basic-info/imed-claim-contacts-info';
import { emptyIMedClaimContactsInfo } from '../../common/address-book-select-list/address-books-select.service';
import { DragDrop } from '@angular/cdk/drag-drop';
import { ModalService, ModalTypes } from '../../common/services/modal.service';
import { IFilmInformationDTO } from '@model/interfaces/custom/film-information-dto';
import { CommonEmailComponent } from '../../common/common-email/common-email.component';
import { ExtraSearchParams, IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { EmailTemplateService } from '../../email-templates/emailtemplate.service';
import { FilmInformationService } from '@app-shared/services/film-information.service';
import { ModalService as MTModalService } from '@mt-ng2/modal-module';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Component({
    selector: 'app-imed-claim-header',
    templateUrl: './imed-claim-header.component.html',
})
export class ImedClaimHeaderComponent implements OnInit, OnDestroy {
    subscriptions: Subscription = new Subscription();

    headerInfo = {
        claimId: 0,
        claimantName: '',
        claimantBirthdate: '',
        DateOfAllegedAccidentOrInjury: null,
        DateSettled: null,
        DateTransferred: null,
        AdditionalInformation: '',
        isRushService: false,
        serviceOrderId: null,
    };

    canSeeAuditLog = false;
    claimId: number;
    claimantBirthdate = '';
    claimNotes = '';
    header = '';
    showRadiologyReviewIcon = false;
    caseInfoButtonClicked: boolean;
    overlayRef: OverlayRef;
    showServiceButtons;
    showCaseEmailButton = false;
    showCaseNotes;
    filmInfo: IFilmInformationDTO[] = [];

    @ViewChild('emailComponent') emailComponent: CommonEmailComponent;
    showEmailControl = false;
    imedClaimServiceId: number;
    childServiceRoute: ActivatedRoute;
    childServiceRouteSubscription: Subscription;
    isDisabledImedClaim: boolean = false;
    requestedToEnableImedClaim: boolean = false;
    canEnableImedClaim: boolean = false;

    constructor(
        private imedClaimService: ImedClaimService,
        private imedClaimServiceService: ImedClaimServiceService,
        private route: ActivatedRoute,
        private router: Router,
        private claimsService: ClaimsService,
        private datePipe: DatePipe,
        private titleService: Title,
        private overlay: Overlay,
        private draggable: DragDrop,
        private modalService: ModalService,
        private filmInformationService: FilmInformationService,
        private emailTemplateService: EmailTemplateService,
        private mtModalService: MTModalService,
        private notificationService: NotificationsService,
    ) {}

    ngOnInit(): void {
        this.canSeeAuditLog = this.claimsService.hasClaim(ClaimTypes.Audit, [ClaimValues.FullAccess, ClaimValues.ReadOnly]);
        this.canEnableImedClaim = this.claimsService.hasClaim(ClaimTypes.EnableCase, [ClaimValues.FullAccess]);
        this.claimId = +this.route.snapshot.paramMap.get('imedClaimId');
        this.imedClaimServiceId = +this.route.firstChild.snapshot.paramMap.get('serviceId');
        this.showServiceButtons = this.claimId > 0 && this.router.url.includes('services');
        this.showCaseEmailButton = this.claimId > 0 && this.imedClaimServiceId === 0;
        this.showCaseNotes = this.claimId > 0 && this.imedClaimServiceId > 0;
        this.getHeader();
        this.subscriptions.add(
            this.route.paramMap.pipe(filter((params) => this.claimId !== +params.get('imedClaimId'))).subscribe((params) => {
                this.claimId = +params.get('imedClaimId');
                this.getHeader();
            }),
        );

        this.subscriptions.add(
            this.imedClaimService.changeEmitted$.subscribe((claimant) => {
                this.updateHeaderOnClaimantChanges(claimant);
            }),
        );
        this.subscriptions.add(
            this.imedClaimServiceService.changeEmitted$.subscribe((service) => {
                this.showRadiologyReviewIcon = service.ImedClaimSubServices.some(
                    (x) => x.SubServiceId === SubServiceEnums.Radiology_Retrieval || x.SubServiceId === SubServiceEnums.Subpoena_Radiology_Retrieval,
                );
                this.getHeader();
            }),
        );
        this.subscriptions.add(
            this.imedClaimServiceService.getShowCaseInfo().subscribe((showCaseInfo) => {
                this.caseInfoButtonClicked = showCaseInfo;
                if (showCaseInfo) {
                    this.getClaimantInfo();
                } else {
                    this.destroyOverlay();
                }
            }),
        );
        this.subscriptions.add(
            this.router.events.pipe(filter((evt) => evt instanceof NavigationEnd)).subscribe((evt: NavigationEnd) => {
                if (evt.url.includes('services') && this.claimId > 0) {
                    this.showServiceButtons = true;
                    if (this.route.firstChild.snapshot.paramMap.get('serviceId')) {
                        this.showCaseNotes = true;
                        this.showCaseEmailButton = false;
                    } else {
                        this.showCaseNotes = false;
                        this.showCaseEmailButton = true;
                    }
                } else {
                    this.showServiceButtons = false;
                    this.showCaseNotes = false;
                    this.showCaseEmailButton = true;
                    this.destroyOverlay();
                }
            }),
        );

        this.subscribeToServiceRoute();
    }

    subscribeToServiceRoute(): void {
        this.subscriptions.add(
            this.router.events.subscribe((e) => {
                if (e instanceof NavigationEnd && this.childServiceRoute !== this.route.firstChild) {
                    if (this.childServiceRouteSubscription) {
                        this.childServiceRouteSubscription.unsubscribe();
                    }
                    this.childServiceRoute = this.route.firstChild;
                    this.childServiceRouteSubscription = this.childServiceRoute.paramMap.subscribe((params) => {
                        this.imedClaimServiceId = +params.get('serviceId');
                        this.getHeader();
                    });
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.resetTitle();
        this.subscriptions.unsubscribe();
        if (this.childServiceRouteSubscription) {
            this.childServiceRouteSubscription.unsubscribe();
        }
        this.destroyOverlay();
    }

    getHeader(): void {
        if (this.claimId > 0) {
            const subscriptions = {
                claimInfo: this.imedClaimService.getHeaderInfo(this.claimId, this.imedClaimServiceId),
                filmInfo: this.filmInformationService.getByImedClaimId(this.claimId),
            };

            forkJoin(subscriptions).subscribe(({ claimInfo, filmInfo }) => {
                this.filmInfo = filmInfo;
                this.headerInfo = {
                    claimId: this.claimId,
                    claimantName: claimInfo.ClaimantName,
                    claimantBirthdate: claimInfo.Birthdate && this.datePipe.transform(claimInfo.Birthdate, 'mediumDate', 'UTC'),
                    DateOfAllegedAccidentOrInjury:
                        claimInfo.DateOfAllegedAccidentOrInjury &&
                        this.datePipe.transform(claimInfo.DateOfAllegedAccidentOrInjury, 'mediumDate', 'UTC'),
                    DateSettled: claimInfo.DateSettled && this.datePipe.transform(claimInfo.DateSettled, 'mediumDate', 'UTC'),
                    DateTransferred: claimInfo.DateTransferred && this.datePipe.transform(claimInfo.DateTransferred, 'mediumDate', 'UTC'),
                    AdditionalInformation: claimInfo.AdditionalInformation,
                    isRushService: claimInfo.RushService,
                    serviceOrderId: this.imedClaimServiceId,
                };

                this.showRadiologyReviewIcon = claimInfo.HasRadiologyRetrieval;
                this.claimNotes = claimInfo.ClaimNotes;
                this.isDisabledImedClaim = claimInfo.IsDisabled;
                this.requestedToEnableImedClaim = claimInfo.RequestedToEnableImedClaim;
                this.setHeader();
                this.addClaimantNameToTitle(this.headerInfo.claimantName);
            });
        } else {
            this.resetTitle();
            this.showRadiologyReviewIcon = false;
            this.header = '';
        }
    }

    addClaimantNameToTitle(claimantName: string): void {
        this.titleService.setTitle(claimantName + ' - Viewpoint');
    }

    resetTitle(): void {
        this.titleService.setTitle('Viewpoint');
    }

    setHeader(): void {
        this.header = `Case ID: ${this.headerInfo.claimId}`;
        if (this.headerInfo.serviceOrderId) {
            this.header += ` | Service Order No: ${this.headerInfo.serviceOrderId}`;
        }
        this.header += ` | Claimant Name: ${this.headerInfo.claimantName}`;
        if (this.headerInfo.claimantBirthdate) {
            this.header += ` | Birthdate: ${this.headerInfo.claimantBirthdate}`;
        }
        if (this.headerInfo.DateOfAllegedAccidentOrInjury) {
            this.header += ` | DOI: ${this.headerInfo.DateOfAllegedAccidentOrInjury}`;
        }
        if (this.headerInfo.DateSettled) {
            this.header += ` | Date Settled: ${this.headerInfo.DateSettled}`;
        }
        if (this.headerInfo.DateTransferred) {
            this.header += ` | Date Transferred: ${this.headerInfo.DateTransferred}`;
        }
        if (this.headerInfo.isRushService) {
            this.header += ' | Rush';
        }
        if (this.headerInfo.AdditionalInformation) {
            this.header += ` | ${this.headerInfo.AdditionalInformation}`;
        }
    }

    updateHeaderOnClaimantChanges(claimant: IImedClaimPartial): void {
        this.headerInfo = {
            claimId: this.claimId,
            claimantName: `${claimant.LastName}, ${claimant.FirstName}`,
            claimantBirthdate: claimant.Birthdate && this.datePipe.transform(claimant.Birthdate, 'mediumDate', 'UTC'),
            DateOfAllegedAccidentOrInjury:
                claimant.DateofAllegedAccidentOrInjury && this.datePipe.transform(claimant.DateofAllegedAccidentOrInjury, 'mediumDate', 'UTC'),
            DateSettled: claimant.CaseSettledDate && this.datePipe.transform(claimant.CaseSettledDate, 'mediumDate', 'UTC'),
            DateTransferred: claimant.CaseTransferredDate && this.datePipe.transform(claimant.CaseTransferredDate, 'mediumDate', 'UTC'),
            AdditionalInformation: claimant.AdditionalInformation,
            isRushService: false,
            serviceOrderId: null,
        };
        this.claimNotes = claimant.Notes;
        this.isDisabledImedClaim = claimant.IsDisabled;
        this.requestedToEnableImedClaim = claimant.ImedClaimAzureLogs.some(
            (x) => x.RequestedMoveBackToApiServer && x.DateMovedBackToApiServer === null,
        );
        this.setHeader();
    }

    showCaseInfo(): void {
        this.caseInfoButtonClicked = !this.caseInfoButtonClicked;
        this.imedClaimServiceService.setShowCaseInfo(this.caseInfoButtonClicked);
    }

    displayClaimantInfo(claim: IImedClaim, contacts: IIMedClaimContactsInfo): void {
        const positionStrategy = this.overlay.position().global().centerVertically().top('100px').left('30px');
        this.overlayRef = this.overlay.create({ positionStrategy });
        const portal = new ComponentPortal(ImedClaimBasicInfoComponent);
        const ref: ComponentRef<ImedClaimBasicInfoComponent> = this.overlayRef.attach(portal);
        ref.instance.imedClaim = claim;
        ref.instance.imedClaimContacts = contacts;
        ref.instance.canEdit = false;
        this.draggable.createDrag(this.overlayRef.overlayElement);
    }

    destroyOverlay(): void {
        if (this.overlayRef && this.overlayRef.hasAttached()) {
            this.overlayRef.detach();
            this.imedClaimServiceService.setShowCaseInfo(false);
        }
    }

    getClaimantInfo(): void {
        this.imedClaimService.getById(this.claimId).subscribe((imedClaim) => {
            const contacts = { ...emptyIMedClaimContactsInfo };
            Object.assign(contacts, imedClaim);
            this.imedClaimService.assignClaimToContacts(contacts, imedClaim);
            this.displayClaimantInfo(imedClaim, contacts);
        });
    }

    showFilmModal(modal): void {
        this.filmInformationService.getByImedClaimId(this.claimId).subscribe((films) => {
            this.filmInfo = films;
            this.modalService.showDocumentModal(modal, ModalTypes.FILM);
        });
    }

    openEmailControlForService(): void {
        this.showEmailControl = true;
        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'imedClaimServiceId',
                value: this.imedClaimServiceId.toString(),
            }),
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'emailTemplateTypeId',
                value: EmailTemplateTypes.SERVICE.toString(),
            }),
        );
        this.searchEmailTemplates(_extraSearchParams);
    }

    openEmailControlForCase(): void {
        this.showEmailControl = true;
        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'emailTemplateTypeId',
                value: EmailTemplateTypes.CASE.toString(),
            }),
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'imedClaimId',
                value: this.claimId.toString(),
            }),
        );
        this.searchEmailTemplates(_extraSearchParams);
    }

    searchEmailTemplates(_extraSearchParams: any): void {
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'includeArchived',
                value: '0',
            }),
        );
        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: 'Id',
            orderDirection: 'asc',
            query: '',
        };

        const searchparams = new SearchParams(searchEntity);
        this.emailTemplateService.search(searchparams).subscribe(({ body: templates }) => {
            this.emailComponent.emailTemplates = templates;
            if (templates.length === 1) {
                this.emailComponent.selectedTemplate = this.emailComponent.emailTemplates[0];
            }
            this.emailComponent.showEmailForm();
        });
    }

    enableImedClaim(): void {
        this.mtModalService
            .showModal({
                cancelButtonColor: '#d33',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes, I want to Enable This Case',
                focusConfirm: false,
                html: `<p>By clicking on the button below, you are initiating a request to retrieve all the files and documents associated with this case. While this will allow you to interact with the case, it will also reduce the available space on the Viewpoint server. Do you want to continue?</p>`,
                showCancelButton: true,
                showConfirmButton: true,
                width: 800,
            })
            .subscribe((result) => {
                if (result.value) {
                    this.imedClaimService.enableImedClaim(this.claimId).subscribe(
                        () => {
                            this.requestedToEnableImedClaim = true;
                            this.notificationService.success(
                                'The retrieval process has been initiated. You will receive an email notification once the case has been re-enabled. Please note: this process might take up to 48hrs.',
                            );
                        },
                        (error) => {
                            this.notificationService.error('An error occurred while enabling the case.');
                        },
                    );
                }
            });
    }
}
