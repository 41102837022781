import { CurrencyFormatterService } from './../../../../../common/services/currency-formatter.service';
import { BillDeskReviewServiceService } from './../bill-desk-review-service.service';
import { ServiceStatusService } from './../../../common-components/service-status/servicestatus.service';
import { ImedClaimService } from './../../../../imedclaim.service';
import { SubServiceService } from './../../../common-components/sub-services/sub-service.service';
import { ImeAppointmentService } from './../../ime-service/ime-appointments/ime-appointment.service';
import { ImedClaimServiceService } from './../../../imedclaimservice.service';
import { ImedClaimServiceFollowupService } from './../../../common-components/service-followups/imedclaimservicefollowup.service';
import { ImedClaimServiceStatusLogService } from './../../../common-components/service-status/imed-claim-service-status-log.service';
import { CommunicationLogService } from '../../../../../common/services/communication-log.service';
import { CommonImedClaimService } from './../../../../shared/common-imed-claim-service';
import { IBillDeskReviewService } from '@model/interfaces/bill-desk-review-service.d';
import { AddressBooksSelectService } from './../../../../../common/address-book-select-list/address-books-select.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { PhysicianService } from '../../../../../address-books/individuals/physicians/physician.service';
import { BaseAttachmentsService } from '../../../../../common/attachments/base-attachments.service';
import { IImedClaimService } from '@model/interfaces/imed-claim-service';
import { AttachmentTypeEnums, AttachmentServiceTypeEnums } from '../../../../../common/constants/Enums';
import { tap } from 'rxjs/operators';
import { ModalService } from '../../../../../common/services/modal.service';
import { AuthService, ClaimsService } from '@mt-ng2/auth-module';

@Component({
    selector: 'app-bill-desk-review-service-detail',
    templateUrl: './bill-desk-review-service-detail.component.html',
})
export class BillDeskReviewServiceDetailComponent extends CommonImedClaimService implements OnInit, OnDestroy {
    billDeskReviewServiceModel: IBillDeskReviewService;
    attachmentServiceTypeEnum: AttachmentServiceTypeEnums = AttachmentServiceTypeEnums.Billing_Desk_Review;

    constructor(
        private communicationLogService: CommunicationLogService,
        private router: Router,
        private route: ActivatedRoute,
        private imedclaimServiceStausLogService: ImedClaimServiceStatusLogService,
        private imedClaimServiceFollowupService: ImedClaimServiceFollowupService,
        private imedClaimServiceService: ImedClaimServiceService,
        private addressBookSelectService: AddressBooksSelectService,
        private currencyFormatterService: CurrencyFormatterService,
        private physicianService: PhysicianService,
        private imeAppointmentService: ImeAppointmentService,
        private subServiceService: SubServiceService,
        private imedClaimService: ImedClaimService,
        private serviceStatusService: ServiceStatusService,
        private billDeskReviewServiceService: BillDeskReviewServiceService,
        private attachmentsService: BaseAttachmentsService,
        private modalService: ModalService,
        private claimService: ClaimsService,
        private authService: AuthService,
    ) {
        super(
            communicationLogService,
            router,
            route,
            imedclaimServiceStausLogService,
            imedClaimServiceFollowupService,
            imedClaimServiceService,
            addressBookSelectService,
            currencyFormatterService,
            physicianService,
            imeAppointmentService,
            subServiceService,
            imedClaimService,
            serviceStatusService,
            attachmentsService,
            modalService,
            claimService,
            authService,
        );

        this.subscriptions.add(
            this.imedClaimServiceService.changeEmitted$.subscribe((imedClaimService) => {
                this.getCommunicationLog().subscribe();
            }),
        );
    }

    ngOnInit(): void {
        forkJoin(
            this.getImedClaimServiceStatusLog(),
            this.getCommunicationLog(),
            this.getFollowups(),
            this.getSubServices(),
            this.getServiceStatuses(),
            this.getPrimaryEmailForImedClaimDefenseAttorney(),
            this.getLatestAppointmentByImedClaimServiceId(),
            this.getBillDeskReviewServiceByImedClaimServiceId(),
        ).subscribe(() => {
            this.subscribeToCommunicationLogs();
            this.setPhysicianInformations();
            this.setIsPhysicianSelected(); // 1 - 1 there is not going to be more than 1 ImeServices_ImedClaimServiceId records
            this.setFormRendered();
            this.setDisablePhysicianFacilityControl();
        });

        this.editingComponent.next('');
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    getBillDeskReviewServiceByImedClaimServiceId(): Observable<IBillDeskReviewService> {
        return this.billDeskReviewServiceService.getByImedClaimServiceId(this.imedClaimServiceModel.Id).pipe(
            tap((billDeskReviewServiceService: IBillDeskReviewService) => {
                this.billDeskReviewServiceModel = billDeskReviewServiceService; // 1 - 1 there is not going to be more than 1 ImeServices_ImedClaimServiceId records
            }),
        );
    }

    otherAttachmentChanged(value: any): void {
        super.otherAttachmentChanged(event);
        if (
            value === AttachmentTypeEnums.Medical_Records_Received ||
            value === AttachmentTypeEnums.Records_Retrieved ||
            value === AttachmentTypeEnums.Report_From_Physician ||
            value === AttachmentTypeEnums.Bill_Physician_Expert
        ) {
            this.getFollowups().subscribe();
            this.imedClaimServiceService.getById(this.imedClaimServiceModel.Id).subscribe((imedClaimService: IImedClaimService) => {
                this.imedClaimServiceModel = imedClaimService;
                this.imedClaimServiceService.emitChange(this.imedClaimServiceModel);
            });
        }
    }

    attachmentChanged(event: number): void {
        super.attachmentChanged(event);
        if (
            event === +AttachmentTypeEnums.Medical_Records_Received ||
            event === +AttachmentTypeEnums.Records_Retrieved ||
            event === +AttachmentTypeEnums.Report_From_Physician ||
            event === +AttachmentTypeEnums.Bill_Physician_Expert
        ) {
            this.getFollowups().subscribe();
            this.imedClaimServiceService.getById(this.imedClaimServiceModel.Id).subscribe((imedClaimService: IImedClaimService) => {
                this.imedClaimServiceModel = imedClaimService;
                this.imedClaimServiceService.emitChange(this.imedClaimServiceModel);
            });
        }
    }
}
