import {
    EntityListConfig,
    SortDirection,
    EntityListColumn,
    IEntityListColumn,
    IEntityListConfig,
    EntityListDeleteColumn,
} from '@mt-ng2/entity-list-module';

export class PhysicianFollowupsEntityListConfig extends EntityListConfig {
    columns: IEntityListColumn[];

    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['AddedDate'],
                    name: 'Added Date',
                    pipes: ['date'],
                }),
                new EntityListColumn({
                    accessors: ['FollowupDate'],
                    name: 'Followup Date',
                    pipes: ['date'],
                }),
                new EntityListColumn({
                    accessors: ['FollowupNotes'],
                    name: 'Followup Notes',
                }),
                new EntityListColumn({
                    accessors: ['ImedClaimType', 'Name'],
                    name: 'Case Type',
                }),
            ],
            // delete: new EntityListDeleteColumn({ columnHtml: '<i class="fa fa-2x fa-fw fa-search icon-main"></i>', headerText: 'Disable', confirm: { text: 'Are you sure you want to disable?' } }),
            // rowClass: ((entity: any) => entity.archived ? 'archived' : null),
        };
        super(listConfig);
    }
}
