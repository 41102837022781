import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AddressSharedService } from '@model/shared-entities/addresses/address-shared.service';

@Injectable()
export class IMedClaimAddressService extends AddressSharedService {
    constructor(public http: HttpClient) {
        super('/addresses/cases', http);
    }
}
