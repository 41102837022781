import { GlobalPositionStrategy, Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ChangeDetectorRef, Component, ComponentRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { safeDetectChanges } from '@common/cdr-safety/cdr-safety.library';
import { FaxEmailEnum } from '@common/constants/Enums';
import { IFaxEmail } from '@model/interfaces/custom/fax-email-type';
import { IImedClaimService } from '@model/interfaces/imed-claim-service';
import { IModalOptions, IModalWrapperApi } from '@mt-ng2/modal-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { Subscription } from 'rxjs';
import { BaseFaxEmailHelperService } from './base-fax-email-helper.service';
import { saveAs } from 'file-saver';
import { ComponentPortal } from '@angular/cdk/portal';
import { SelectContactsEmail } from '@common/select-contacts-email/select-contacts-email.component';
import { SelectContactFaxes } from '@common/select-contact-faxes/select-contact-faxes.component';
import { IName } from '@model/interfaces/custom/name';
import { IViewGetServicesForList } from '@model/interfaces/custom/view-get-services-for-list';
import { IViewGetFollowUpsForList } from '@model/interfaces/view-get-follow-ups-for-list';

@Component({
    selector: 'app-base-fax-email',
    styleUrls: ['./base-fax-email.component.less'],
    template: ``,
})
export class BaseFaxEmailComponent implements OnInit {
    types: IFaxEmail[] = [];
    selectedType = FaxEmailEnum.EMAIL;
    faxNumber: string;
    emailId: string;
    ccEmails: string;
    bccEmails: string;

    emailBody = '';
    emailSubject = '';
    imedClaimService: IImedClaimService;

    fromListPage: boolean;
    claimNumber = '';
    fileNumber = '';
    invalidEmails: boolean;
    invalidCCEmails: boolean;
    invalidBCCEmails: boolean;
    doubleClickIsDisabled = false;

    @Input() imedclaimServiceId: number;
    @Input() imedClaimId: number;
    @Input() entityId: number;
    @Input() entity: any;
    @Input() fromServicesListPage: boolean;
    @Input() name: IName;
    @Input() imedClaimTypeId: number;
    @Input() isNF10DocPopup: boolean;
    @Input() isSubpoenaDocPopup: boolean;
    @Input() isCompletedSubpoenaDocPopup: boolean;
    @Input() isAllSubpoenasDocPopup: boolean;
    @Input() replaceSignature: boolean;

    @Output() sendFax: EventEmitter<any> = new EventEmitter<any>();
    @Output() sendEmail: EventEmitter<any> = new EventEmitter<any>();
    @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
    @Output() download: EventEmitter<any> = new EventEmitter<any>();

    subscriptions: Subscription = new Subscription();

    emailOverlayRef: OverlayRef;
    faxOverlayRef: OverlayRef;

    fileToUpload: File;
    previewMode = false;
    private _isProcessing = false;
    set isProcessing(val: boolean) {
        this._isProcessing = val;
        safeDetectChanges(this.cdr);
    }
    get isProcessing(): boolean {
        return this._isProcessing;
    }

    get faxNumberIsValid(): boolean {
        return this.faxNumber !== undefined && this.faxNumber !== null && this.faxNumber !== '';
    }

    get previewTypes(): IFaxEmail[] {
        return this.types.filter((t) => t.value === +FaxEmailEnum.FAX || t.value === +FaxEmailEnum.EMAIL);
    }

    modalOptions: IModalOptions = {
        customClass: 'modal-hide-actions' as any,
        width: 800,
    };
    modalWrapperApi: IModalWrapperApi;

    constructor(
        protected cdr: ChangeDetectorRef,
        protected baseFaxEmailHelperService: BaseFaxEmailHelperService,
        protected notificationsService: NotificationsService,
        protected overlay: Overlay,
        protected overlayPositionBuilder: OverlayPositionBuilder,
    ) {}

    ngOnInit(): void {
        this.types.push({ name: 'Email', value: FaxEmailEnum.EMAIL });
        this.types.push({ name: 'Download', value: FaxEmailEnum.DOWNLOAD });
        this.types.push({ name: 'Fax', value: FaxEmailEnum.FAX });
    }

    setSelectedType(type: FaxEmailEnum): void {
        this.selectedType = type;
    }

    showModal(): void {
        this.modalWrapperApi.show();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    toggleFaxEmailDownload(event): void {
        if (Number(event.target.value) === +FaxEmailEnum.DOWNLOAD) {
            this.downloadDocument();
        }
    }

    downloadDocument = (): void => null; // placeholder method that gets defined at child component level

    setInvalidEmails(): void {
        this.invalidEmails = !this.emailId || !this.baseFaxEmailHelperService.validateEmailAddresses(this.emailId);
    }

    setInvalidCCEmails(): void {
        this.invalidCCEmails = this.ccEmails && !this.baseFaxEmailHelperService.validateEmailAddresses(this.ccEmails);
    }

    setInvalidBCCEmails(): void {
        this.invalidBCCEmails = this.bccEmails && !this.baseFaxEmailHelperService.validateEmailAddresses(this.bccEmails);
    }

    setFileToUpload($event): void {
        const file: File = $event.target.files[0];
        if (file.name.toLowerCase().endsWith('pdf')) {
            this.fileToUpload = file;
        } else {
            this.notificationsService.error('File must be pdf, please try again.');
        }
    }

    handleDocumentDownloadResponse(data: Blob, fileName: string): void {
        this.selectedType = FaxEmailEnum.EMAIL;
        const thefile = new Blob([data], { type: 'application/octet-stream' });
        saveAs(thefile, atob(fileName));
    }

    // Email select
    openEmailSelect(): void {
        if (!this.emailOverlayRef || !this.emailOverlayRef.hasAttached()) {
            this.createOverlay();
            // Component portal dyanmically creates an instance of the component type passed as argument
            const portal = new ComponentPortal(SelectContactsEmail);
            // Attaches the dynamically created component to the overlay created in createOverlay() method
            const emailSelect: ComponentRef<SelectContactsEmail> = this.emailOverlayRef.attach(portal);
            // Setting the email contact select components claimId property
            if (this.imedclaimServiceId) {
                emailSelect.instance.imedClaimServiceId = this.imedclaimServiceId;
            } else {
                emailSelect.instance.imedClaimId = this.imedClaimId;
            }
            this.subscribeToEmailSelect(emailSelect);
        }
    }
    openCCEmailSelect(): void {
        if (!this.emailOverlayRef || !this.emailOverlayRef.hasAttached()) {
            this.createOverlay();
            const portal = new ComponentPortal(SelectContactsEmail);
            const emailSelect: ComponentRef<SelectContactsEmail> = this.emailOverlayRef.attach(portal);
            if (this.imedclaimServiceId) {
                emailSelect.instance.imedClaimServiceId = this.imedclaimServiceId;
            } else {
                emailSelect.instance.imedClaimId = this.imedClaimId;
            }
            this.subscribeToCCEmailSelect(emailSelect);
        }
    }
    openBCCEmailSelect(): void {
        if (!this.emailOverlayRef || !this.emailOverlayRef.hasAttached()) {
            this.createOverlay();
            const portal = new ComponentPortal(SelectContactsEmail);
            const emailSelect: ComponentRef<SelectContactsEmail> = this.emailOverlayRef.attach(portal);
            if (this.imedclaimServiceId) {
                emailSelect.instance.imedClaimServiceId = this.imedclaimServiceId;
            } else {
                emailSelect.instance.imedClaimId = this.imedClaimId;
            }
            this.subscribeToBCCEmailSelect(emailSelect);
        }
    }

    // Creates overlay modal that email select component is rendered in
    createOverlay(): void {
        const positionStrategy = this.createPositionStrategyForOverlay();
        // overlay-on-top class sets a high z-index to ensure the overlay is on top regardless of other modals being open
        this.emailOverlayRef = this.overlay.create({ positionStrategy, disposeOnNavigation: true, panelClass: 'overlay-on-top' });
    }

    // The global position strategy determines where the component will be rendered
    createPositionStrategyForOverlay(): GlobalPositionStrategy {
        return this.overlayPositionBuilder.global().right('10%');
    }

    subscribeToEmailSelect(component: ComponentRef<SelectContactsEmail>): void {
        // subscribe to the SelectContactsEmail close event and detach overlay from DOM when event is fired
        this.subscriptions.add(component.instance.close.subscribe(() => this.emailOverlayRef.detach()));
        // susbcribe to SelectContactsEmail  selectEmails event and set the emailTo form value to the event value
        this.subscriptions.add(
            component.instance.selectEmails.subscribe((items) => {
                this.emailId = items.join(', ');
                this.setInvalidEmails();
                safeDetectChanges(this.cdr);
            }),
        );
    }
    subscribeToCCEmailSelect(component: ComponentRef<SelectContactsEmail>): void {
        this.subscriptions.add(component.instance.close.subscribe(() => this.emailOverlayRef.detach()));
        this.subscriptions.add(
            component.instance.selectEmails.subscribe((items) => {
                this.ccEmails = items.join(', ');
                this.setInvalidCCEmails();
                safeDetectChanges(this.cdr);
            }),
        );
    }
    subscribeToBCCEmailSelect(component: ComponentRef<SelectContactsEmail>): void {
        this.subscriptions.add(component.instance.close.subscribe(() => this.emailOverlayRef.detach()));
        this.subscriptions.add(
            component.instance.selectEmails.subscribe((items) => {
                this.bccEmails = items.join(', ');
                this.setInvalidBCCEmails();
                safeDetectChanges(this.cdr);
            }),
        );
    }

    openFaxSelect(): void {
        if (!this.faxOverlayRef || !this.faxOverlayRef.hasAttached()) {
            const positionStrategy = this.createPositionStrategyForOverlay();
            // overlay-on-top class sets a high z-index to ensure the overlay is on top regardless of other modals being open
            this.faxOverlayRef = this.overlay.create({ positionStrategy, disposeOnNavigation: true, panelClass: 'overlay-on-top' });
            const portal = new ComponentPortal(SelectContactFaxes);
            const ref: ComponentRef<SelectContactFaxes> = this.faxOverlayRef.attach(portal);
            if (this.imedclaimServiceId) {
                ref.instance.imedclaimserviceid = this.imedclaimServiceId;
            } else {
                ref.instance.imedclaimid = this.imedClaimId;
            }
            ref.instance.imedclaimserviceid = this.imedclaimServiceId;
            this.subscribeToFaxSelect(ref);
        }
    }

    subscribeToFaxSelect(component: ComponentRef<SelectContactFaxes>): void {
        this.subscriptions.add(component.instance.close.subscribe(() => this.faxOverlayRef.detach()));
        this.subscriptions.add(
            component.instance.selectItems.subscribe((items) => {
                this.faxNumber = items.join(', ');
                safeDetectChanges(this.cdr);
            }),
        );
    }
}
