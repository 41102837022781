import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '@model/ClaimTypes';
import { GeneratedLetterTemplateService } from '../generated-letter-template.service';
import { IGeneratedLetterTemplate } from '@model/interfaces/generated-letter-template';

@Component({
    selector: 'app-generated-letter-template-detail',
    templateUrl: './generated-letter-template-detail.component.html',
})
export class GeneratedLetterTemplateDetailComponent implements OnInit {
    generatedLetterTemplate: IGeneratedLetterTemplate;
    editingComponent: Subject<any> = new Subject();
    canEdit: boolean;
    canAdd: boolean;
    id: number;

    constructor(
        private generatedLetterTemplateService: GeneratedLetterTemplateService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
    ) { }

    ngOnInit(): void {
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.LetterTemplates, [ClaimValues.FullAccess]);
        this.canAdd = this.canEdit;
        this.id = +this.route.snapshot.paramMap.get('generatedLetterTemplateId');
        if (this.id > 0) {
            this.getLetterTemplateById(this.id);
        }
        this.editingComponent.next('');
    }

    getLetterTemplateById(id: number): void {
        this.generatedLetterTemplateService.getById(id).subscribe((letterTemplate) => {
            this.generatedLetterTemplate = letterTemplate;
        });
    }
}
