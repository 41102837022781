import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { Observable } from 'rxjs';
import { IMasterFollowupTypeTaskTemplate } from '@model/interfaces/master-followup-type-task-template';
import { IGroupedTaskTemplatesByFollowupDTO } from '@model/interfaces/custom/grouped-task-templates-by-followup-dto';
import { IImedClaimMasterFollowupTypeTaskTemplate } from '@model/interfaces/imed-claim-master-followup-type-task-template';

export const emptyServiceTaskAssociation: IImedClaimMasterFollowupTypeTaskTemplate = {
    Id: 0,
    MasterFollowupTypeId: null,
    ImedClaimTypeId: null,
    TaskTemplateId: null,
};

@Injectable()
export class ImedClaimMasterFollowupTypeTaskTemplateService extends BaseService<IImedClaimMasterFollowupTypeTaskTemplate> {
    constructor(public http: HttpClient) {
        super('/imedclaim-masterfollowuptype-tasktemplates', http);
    }

    getEmptyServiceTaskAssociation(): IImedClaimMasterFollowupTypeTaskTemplate {
        return emptyServiceTaskAssociation;
    }

    getGroupedTaskTemplatesByService(imedClaimTypeId: number): Observable<IGroupedTaskTemplatesByFollowupDTO[]> {
        return this.http.get<IGroupedTaskTemplatesByFollowupDTO[]>(`/imedclaim-masterfollowuptype-tasktemplates/${imedClaimTypeId}/groupedTaskTemplates`);
    }
}
