import { AddressBookSelectionEnums, AddressBookTypeIdEnums } from './../../common/constants/Enums';
import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import { IDynamicField } from '@mt-ng2/dynamic-form';
import { IndividualDynamicConfig } from '../individuals/individual.dynamic-config';
import { emptyIndividual, IndividualService } from '../individuals/individual.service';
import { AddressBookDynamicConfig } from '../address-book.dynamic-config';
import { emptyAddressBook, AddressBookService, ICreateAddressBookFormValue } from '../addressbook.service';
import { AddressBookTypeService } from '../addressbooktype.service';
import { IAddressBookType } from '@model/interfaces/address-book-type';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { IDuplicateAddressBook } from '@model/interfaces/custom/duplicate-address-book';
import { IAddressBook } from '@model/partials/address-book.partial';
import { Router } from '@angular/router';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { MetaAddressBookTypeIdEnums } from '../../common/constants/Enums';
import { DuplicatesConfirmService } from './duplicates-confirm.service';
import { AddressBooksSelectService } from '../../common/address-book-select-list/address-books-select.service';
import { IIndividual } from '@model/interfaces/individual';
import { IPhysician } from '@model/interfaces/physician';
import { finalize } from 'rxjs/operators';
import { PhysicianService } from '../individuals/physicians/physician.service';
import { AuthService } from '@mt-ng2/auth-module';
import { IPhysicianRecruitment } from '@model/interfaces/physician-recruitment';
import { trimObjectStringProperties } from '../../common/custom/trimObjectStringProperties';
import { IndividualDynamicControlsPartial } from '@model/partials/individual-partial.form-controls';
import { AddressBookDynamicControlsPartial } from '@model/partials/address-book-partial.form-controls';
import { ImedClaimTypes } from '@model/ImedClaimTypes';

@Component({
    selector: 'app-address-books-add-individual',
    templateUrl: './address-books-add-individual.component.html',
})
export class AddressBooksAddIndividualComponent implements OnInit, OnDestroy {
    @Input() overrideRedirect: boolean = false;
    @Input() typeFilter = '';

    form: UntypedFormGroup;
    addressBookConfig: IDynamicField[];
    individualConfig: IDynamicField[];
    addressBookTypes: IAddressBookType[];
    individualControls: any;
    individualViewControls: any;
    individual: IIndividual;
    showContractRateFields = false;

    doubleClickIsDisabled = false;

    wcbField: IDynamicField;
    domainNameField: IDynamicField;
    notesField: IDynamicField;

    subscriptions$ = new Subscription();
    showWcbField = false;

    constructor(
        private addressBookService: AddressBookService,
        private individualService: IndividualService,
        private fb: UntypedFormBuilder,
        private router: Router,
        private notificationsService: NotificationsService,
        private addressBookTypeService: AddressBookTypeService,
        private addressBooksSelectService: AddressBooksSelectService,
        private physicianService: PhysicianService,
        private authService: AuthService,
        private duplicatesConfirmService: DuplicatesConfirmService,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.form = this.fb.group({});
        forkJoin(this.addressBookTypeService.getItems()).subscribe((forkJoinResponses) => {
            const [addressBookTypes] = forkJoinResponses;
            this.addressBookTypes = addressBookTypes.filter((type) => type.IsIndividualType);
            this.filterAddressBookTypes();
            this.setIndividualFormAndView();
        });
    }
    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }

    setIndividualFormAndView(): void {
        const viewAndFormIndividualControl = new IndividualDynamicControlsPartial(this.individual);
        this.individualControls = viewAndFormIndividualControl.Form;
        this.individualViewControls = viewAndFormIndividualControl.View;
    }

    filterAddressBookTypes(): void {
        switch (this.typeFilter as AddressBookSelectionEnums) {
            case AddressBookSelectionEnums.Physician:
                this.addressBookTypes = this.addressBookTypes.filter(
                    (type) => type.Id === +AddressBookTypeIdEnums.Physician_panel || type.Id === +AddressBookTypeIdEnums.Expert_panel,
                );
                this.setConfig(this.addressBookTypes);
                break;
            default:
                this.setConfig(this.addressBookTypes);
                break;
        }
    }

    isIndividualsContractRangeValid(addressBookIndividuals: IIndividual[]): boolean {
        let returnvalue = true;
        addressBookIndividuals.forEach((individual) => {
            if (individual.ContractRateFrom && individual.ContractRateTo && individual.ContractRateFrom > individual.ContractRateTo) {
                this.notificationsService.error('Contract Range From cannot be higher than Contract Range To');
                returnvalue = false;
            }
        });
        return returnvalue;
    }

    setConfig(addressBookTypes: IAddressBookType[]): void {
        const individualConfig = new IndividualDynamicConfig(emptyIndividual);
        const addressBookControls = ['AddressBookTypeId', 'PrintOnCheckAs', 'Ein'];
        const addressBookConfig = new AddressBookDynamicConfig(emptyAddressBook, null, addressBookTypes, addressBookControls);
        const addressBookDynamicFields = new AddressBookDynamicControlsPartial(emptyAddressBook).Form;
        const individualDynamicFields = new IndividualDynamicControlsPartial(emptyIndividual).Form;
        this.wcbField = individualDynamicFields.Wcb;
        this.domainNameField = addressBookDynamicFields.DomainName;
        this.notesField = addressBookDynamicFields.Notes;

        this.addressBookConfig = addressBookConfig.getForCreate().formObject;
        this.individualConfig = individualConfig.getForCreate().formObject;
    }

    formCreated(): void {
        this.subscriptions$.add(
            this.form.controls.AddressBook.get('AddressBookTypeId').valueChanges.subscribe((addressbookTypeId) => {
                this.showWcbField =
                    addressbookTypeId === AddressBookTypeIdEnums.Expert_panel || addressbookTypeId === AddressBookTypeIdEnums.Physician_panel;
                this.showContractRateFields =
                    addressbookTypeId === AddressBookTypeIdEnums.Government_offices ||
                    addressbookTypeId === AddressBookTypeIdEnums.Carrier ||
                    addressbookTypeId === AddressBookTypeIdEnums.Attorney ||
                    addressbookTypeId === AddressBookTypeIdEnums.Miscellaneous;
                this.cdr.detectChanges();
            }),
        );
    }

    save(): void {
        if (this.form.valid) {
            const addressBookObject = this.addressBookService.getCreateAddressBookPayload(
                this.form.value as ICreateAddressBookFormValue,
                MetaAddressBookTypeIdEnums.Individual,
            );
            addressBookObject.Notes = addressBookObject.Notes.replace(/[^(\x20-\x7F)]*/g, '');
            addressBookObject.Individuals.forEach((individual) => {
                trimObjectStringProperties(individual);
                if (individual.Physicians && individual.Physicians.length && this.addressBooksSelectService.imedClaimTypeId) {
                    individual.Physicians.forEach((p) => {
                        switch (this.addressBooksSelectService.imedClaimTypeId as ImedClaimTypes) {
                            case ImedClaimTypes.Auto:
                                p.Auto = true;
                                p.GeneralLiability = false;
                                p.WorkersComp = false;
                                break;
                            case ImedClaimTypes.WorkersCompensation:
                                p.Auto = false;
                                p.GeneralLiability = false;
                                p.WorkersComp = true;
                                break;
                            case ImedClaimTypes.GeneralLiability:
                            // Fall through to default
                            default:
                                // Default physicians to General Liability specialty
                                p.Auto = false;
                                p.GeneralLiability = true;
                                p.WorkersComp = false;
                        }
                    });
                }
            });
            if (this.isIndividualsContractRangeValid(addressBookObject.Individuals)) {
                // Check for Duplication before initial creation of address book;
                this.addressBookService.getPotentialDuplicates(addressBookObject).subscribe((response: IDuplicateAddressBook[]) => {
                    if (response && response.length > 0) {
                        this.duplicatesConfirmService.showDuplicatesConfirmationModal(response, true).subscribe((result) => {
                            if (result.value) {
                                this.createAddressBook(addressBookObject);
                            }
                        });
                    } else {
                        this.createAddressBook(addressBookObject);
                    }
                });
            } else {
                setTimeout(() => (this.doubleClickIsDisabled = false), 0);
            }
        } else {
            setTimeout(() => (this.doubleClickIsDisabled = false), 0);
            markAllFormFieldsAsTouched(this.form);
        }
    }

    createAddressBook(toBeCreatedAddressBook: IAddressBook): void {
        this.addressBookService.initializeAddressBook(toBeCreatedAddressBook).subscribe((addressBook) => {
            if (!this.overrideRedirect) {
                void this.router.navigate([`address-books/${addressBook.Id}/individuals/${addressBook.Individuals[0].Id}`]);
            } else {
                let individual: IIndividual;
                let physician: IPhysician;
                if (addressBook.Individuals) {
                    individual = addressBook.Individuals[0];
                    if (addressBook.Individuals[0].Physicians) {
                        physician = addressBook.Individuals[0].Physicians[0];
                    }
                }
                trimObjectStringProperties(individual);
                const addressBookSelectEntity = this.addressBooksSelectService.createAddressBookSelect(
                    addressBook.Id,
                    addressBook.FacilityName,
                    addressBook.Individuals ? addressBook.Individuals[0].Id : 0,
                    individual ? individual.FirstName + ' ' + individual.LastName : null,
                    physician ? physician.Id : null,
                );
                this.addressBooksSelectService.emitChange(addressBookSelectEntity);
            }

            if (addressBook.Individuals[0].Physicians && addressBook.Individuals[0].Physicians.length) {
                const defaultRecruitment: IPhysicianRecruitment = {
                    Archived: false,
                    CreatedById: this.authService.currentUser.getValue().Id,
                    DateCreated: new Date(),
                    Id: 0,
                    PhysicianId: addressBook.Individuals[0].Physicians[0].Id,
                    PhysicianUserId: this.authService.currentUser.getValue().Id,
                };
                addressBook.Individuals[0].Physicians[0].PhysicianRecruitments[0] = defaultRecruitment;
                this.createPhysicianRecruitment(addressBook.Individuals[0].Physicians[0]);
            }

            this.notificationsService.success('Saved Successfully');
            this.individualService.emitChange(addressBook.Individuals[0]);
            this.addressBookService.emitChange(addressBook);
        });
    }

    createPhysicianRecruitment(physician: IPhysician): void {
        this.physicianService.saveRecruitments(physician).subscribe(() => this.physicianService.emitChange(physician));
    }
}
