import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { AuthService } from '@mt-ng2/auth-module';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection, IItemDeletedEvent } from '@mt-ng2/entity-list-module';
import { IModalOptions } from '@mt-ng2/modal-module';

import { IndividualService } from '../individual.service';
import { IIndividual } from '@model/interfaces/individual';
import { entityListModuleConfig } from '../../../common/shared.module';
import { IndividualsEntityListConfig } from './individuals.entity-list-config';

@Component({
    selector: 'app-individuals',
    templateUrl: './individuals.component.html',
})
export class IndividualsComponent implements OnInit {
    searchControl = new UntypedFormControl();
    individuals: IIndividual[];
    currentPage = 1;
    query = '';
    total: number;
    canAddIndividual = false;
    entityListConfig = new IndividualsEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();
    id: string;
    addressBookId: number;
    includeArchived = false;

    customConfirmOptions: IModalOptions = {
        confirmButtonText: 'Archive',
        text: `Are you sure you want to delete this record?`,
    };

    constructor(
        private individualService: IndividualService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private authService: AuthService,
        private notificationsService: NotificationsService,
    ) { }

    ngOnInit(): void {
        this.addressBookId = this.activatedRoute.parent.snapshot.params.addressBookId;
        this.getIndividuals();
    }

    getIndividuals(): void {
        const search = this.query;

        const _extraSearchParams: ExtraSearchParams[] = [];

        // Since Individual is part of AddressBook, it should only show Individual for that AddressBook
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'AddressBookId',
                value: this.addressBookId.toString(),
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'includeArchived',
                value: this.includeArchived.toString(),
            }),
        );

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: entityListModuleConfig.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);

        this.individualService.get(searchparams).subscribe((answer) => {
            this.individuals = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.query = query;
        this.currentPage = 1;
        this.getIndividuals();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getIndividuals();
    }

    individualSelected(event: IItemSelectedEvent): void {
        void this.router.navigate([`address-books/${this.addressBookId}/individuals`, event.entity.Id]);
    }

    onItemDeleted(event: IItemDeletedEvent): void {
        const selectedIndividual: IIndividual = event.entity;
        selectedIndividual.Archived = !selectedIndividual.Archived;
        selectedIndividual.ModifiedById = this.authService.currentUser.getValue().Id;
        selectedIndividual.DateModified = new Date();
        this.individualService.update(selectedIndividual).subscribe(() => {
            this.notificationsService.success('Individual Updated Successfully');
            this.getIndividuals();
        });
    }
}
