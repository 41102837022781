import { Validators } from '@angular/forms';
import { MasterServiceStatusDynamicControls, IMasterServiceStatusDynamicControlsParameters } from '../form-controls/master-service-status.form-controls';
import { IMasterServiceStatus } from '../interfaces/master-service-status';

import { IService } from '@model/interfaces/service';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, IDynamicField, IDynamicFieldType, IState, SelectInputTypes } from '@mt-ng2/dynamic-form';

export interface IMasterServiceStatusDynamicControlsParametersPartial extends IMasterServiceStatusDynamicControlsParameters {
    services?: IService[];
    selectedServiceIdsArray?: number[];
}

export class MasterServiceStatusDynamicControlsPartial extends MasterServiceStatusDynamicControls {
    selectOptions: any;
    constructor(masterservicestatusPartial?: IMasterServiceStatus, additionalParameters?: IMasterServiceStatusDynamicControlsParametersPartial) {
        super(masterservicestatusPartial, additionalParameters);
        this.Form.associatedServices = new DynamicField({
            formGroup: this.formGroup,
            label: 'Select Services',
            name: 'associatedServices',
            options: additionalParameters?.services,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.MultiselectDropdown,
                maxToShowInSelectedText: 1,
                showSelectAllButtons: true,
            } as IDynamicFieldType),
            validation: [Validators.required],
            validators: { required: true },
            value: additionalParameters?.selectedServiceIdsArray,
        } as IDynamicField);
    }
}
