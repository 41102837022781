// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            .focusClass {
                background-color: rgb(170, 170, 170);
            }
            .modelDialog {
                max-height: 500px;
                max-width: 1000px;
                overflow: auto;
            }
            .accordion {
                background-color: rgb(170, 170, 170);
                color: #444;
                cursor: pointer;
                padding: 18px;
                width: 100%;
                border: none;
                text-align: left;
                outline: none;
                font-size: 15px;
                transition: 0.4s;
            }
        `, "",{"version":3,"sources":["webpack://./src/app/document-queue/document-queue-list.component.ts"],"names":[],"mappings":";YACY;gBACI,oCAAoC;YACxC;YACA;gBACI,iBAAiB;gBACjB,iBAAiB;gBACjB,cAAc;YAClB;YACA;gBACI,oCAAoC;gBACpC,WAAW;gBACX,eAAe;gBACf,aAAa;gBACb,WAAW;gBACX,YAAY;gBACZ,gBAAgB;gBAChB,aAAa;gBACb,eAAe;gBACf,gBAAgB;YACpB","sourcesContent":["\n            .focusClass {\n                background-color: rgb(170, 170, 170);\n            }\n            .modelDialog {\n                max-height: 500px;\n                max-width: 1000px;\n                overflow: auto;\n            }\n            .accordion {\n                background-color: rgb(170, 170, 170);\n                color: #444;\n                cursor: pointer;\n                padding: 18px;\n                width: 100%;\n                border: none;\n                text-align: left;\n                outline: none;\n                font-size: 15px;\n                transition: 0.4s;\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
