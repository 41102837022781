import { IRecordRetrievalService } from '@model/interfaces/record-retrieval-service.d';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '@mt-ng2/base-service';
import { IServicePayload } from '@model/interfaces/custom/update-service-payload';
import { nullOutNestedObjects } from '../../../../common/custom/nullOutNestedObjects';
import { IFilmRetrievalExtrasDTO } from '@model/interfaces/custom/film-retrieval-extras-dto';
import { ISubpoenaService } from '@model/interfaces/subpoena-service';
import { IImedClaimCourtFee } from '@model/interfaces/imed-claim-court-fee';
import { IImedClaimServiceCourtFee } from '@model/interfaces/imed-claim-service-court-fee';
import { IImedClaimServiceProcessServerFee } from '@model/interfaces/imed-claim-service-process-server-fee';
import { IImedClaimServiceWitnessFee } from '@model/interfaces/imed-claim-service-witness-fee';
import { IImedClaim } from '@model/interfaces/imed-claim';
import { ISubpoenaSignatureStatusDto } from '@model/interfaces/custom/subpoena-signature-status.dto';
import { IImedClaimServiceFacilityFee } from '@model/interfaces/imed-claim-service-facility-fee';

export const emptySubpoenaService: ISubpoenaService = {
    AffidavitOfService: false,
    CertificationOfRecords: false,
    DepositionSubpoena: false,
    DomesticationRequired: false,
    Id: 0,
    ImedClaimServiceId: 0,
    LetterOfIntent: false,
    LetterOfRepresentation: false,
    OtherSubpoena: false,
    PhysicalCheckToFacilityRequired: false,
    PhysicalCheckToFacilityRequiredProcessServerFee: false,
    PhysicalCheckToFacilityRequiredWitnessFee: false,
    PhysicalCheckToForeignCourtRequiredWitnessFee: false,
    ProcessServerRequired: false,
    ProofOfservice: false,
    ProofOfServiceOfObjection: false,
    Reference: 'Entire medical record including patient histories, office notes (except psychotherapy notes), test results, RADIOLOGY STUDIES, FILMS, referrals, consults, billing records, insurance records, and records sent to you by other health care providers;',
    SubpoenaCoverPage: false,
    SubpoenaDucesTecum: false,
    TrialSubpoena: false,
    OptionalField1: '',
    OptionalField2: '',
    PhysicalCheckToFacilityRequiredFacilityFee: false,
};

@Injectable()
export class SubpoenaServiceService extends BaseService<ISubpoenaService> {
    constructor(public http: HttpClient) {
        super('/subpoenaservices', http);
    }

    getEmptySubpoenaService(): ISubpoenaService {
        return { ...emptySubpoenaService };
    }

    getByImedClaimServiceId(imedClaimServiceId: number): Observable<ISubpoenaService> {
        return this.http.get<ISubpoenaService>(`/subpoenaservices/getByImedClaimServiceId/${imedClaimServiceId}`);
    }

    getCaseCourtFee(imedClaimServiceId: number): Observable<IImedClaimCourtFee> {
        return this.http.get<IImedClaimCourtFee>(`/subpoenaservices/getCaseCourtFee/${imedClaimServiceId}`);
    }

    updateService(servicePayLoad: IServicePayload<ISubpoenaService>): any {
        const clone: IServicePayload<ISubpoenaService> = JSON.parse(JSON.stringify(servicePayLoad));
        nullOutNestedObjects(clone.ParentService);
        nullOutNestedObjects(clone.ChildService);
        return this.http.put<number>(`/subpoenaservices/update-service/${clone.ParentService.Id}`, clone);
    }

    getCourtServiceFee(imedClaimServiceId: number): Observable<IImedClaimServiceCourtFee> {
        return this.http.get<IImedClaimServiceCourtFee>(`/subpoenaservices/getServiceCourtFee/${imedClaimServiceId}`);
    }

    getProcessServerServiceFee(imedClaimServiceId: number): Observable<IImedClaimServiceProcessServerFee> {
        return this.http.get<IImedClaimServiceCourtFee>(`/subpoenaservices/getServiceProcessServerFee/${imedClaimServiceId}`);
    }

    getWitnessFee(imedClaimServiceId: number): Observable<IImedClaimServiceWitnessFee> {
        return this.http.get<IImedClaimServiceWitnessFee>(`/subpoenaservices/getWitnessFee/${imedClaimServiceId}`);
    }

    getLatestFacilityFee(imedClaimServiceId: number): Observable<IImedClaimServiceFacilityFee> {
        return this.http.get<IImedClaimServiceFacilityFee>(`/subpoenaservices/getLatestFacilityFee/${imedClaimServiceId}`);
    }

    checkForDomestication(imedClaimServiceId: number): Observable<boolean> {
        return this.http.post<boolean>(`/subpoenaservices/check-for-domestication/${imedClaimServiceId}`, {});
    }

    getSubpoenaSignatureStatus(imedClaim: IImedClaim): Observable<ISubpoenaSignatureStatusDto> {
        return this.http.post<ISubpoenaSignatureStatusDto>(`/subpoenaservices/getSignatureStatus/`, imedClaim);
    }

    IsValidSubpoenaUploaded(imedClaimServiceId: number): Observable<boolean> {
        return this.http.get<boolean>(`/subpoenaservices/isValidSubpoenaUploaded/${imedClaimServiceId}`, {});
    }

    IsValidCompletedSubpoenaUploaded(imedClaimServiceId: number): Observable<boolean> {
        return this.http.get<boolean>(`/subpoenaservices/isValidCompletedSubpoenaUploaded/${imedClaimServiceId}`, {});
    }

    DoesPlaintiffAttorneyRequireCopy(imedClaimServiceId: number): Observable<boolean> {
        return this.http.get<boolean>(`/subpoenaservices/doesPlaintiffAttorneyRequireCopy/${imedClaimServiceId}`, {});
    }

    generateAndUploadSubpoena(replaceSignature: boolean, templateId: number, imedClaimServiceId: number): any {
        return this.http.post(`/subpoena-service-document/${replaceSignature}/download/${templateId}/${imedClaimServiceId}`, {});
    }

}
