import { Component, Input, OnInit, ViewChild, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { FileItem, FileLikeObject } from 'ng2-file-upload';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { AttachmentControlComponent } from '@common/attachment-control/attachment-control.component';
import { AttachmentTypeEnums } from '@common/constants/Enums';
import { BaseAttachmentsService } from '@common/attachments/base-attachments.service';

@Component({
    selector: 'app-consent-to-change-attachment-upload',
    templateUrl: './consent-to-change-attachment-upload.component.html',
})
export class ConsentToChangeAttachmentUploadComponent implements OnInit, AfterViewInit {
    @Input('imedClaimId') imedClaimId: number;
    @Output('onClose') onClose: EventEmitter<any> = new EventEmitter<any>();
    @Output('onDocumentSaved') onDocumentSaved: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('docComponentConsentToChange') docComponentConsentToChange: AttachmentControlComponent;

    errorMessage: string;
    documentForm: UntypedFormGroup;
    formCreated = false;

    constructor(
        private attachmentsService: BaseAttachmentsService,
        private fb: UntypedFormBuilder,
        private notificationsService: NotificationsService,
    ) {}

    ngOnInit(): void {
        this.buildForm();
    }

    ngAfterViewInit(): void {
        if (this.docComponentConsentToChange) {
            this.docComponentConsentToChange.uploader.onWhenAddingFileFailed = (
                item,
                filter,
                docComponentConsentToChange: AttachmentControlComponent,
            ) => this.onAddingFileFailed(item, filter, docComponentConsentToChange);
        }
    }

    buildForm(): void {
        this.documentForm = this.fb.group({
            Document: this.fb.group({}),
        });
        this.ngAfterViewInit();
        this.formCreated = true;
    }

    onAddingFileFailed(item: FileLikeObject, filter: any, docComponent: AttachmentControlComponent): void {
        const allowedTypes = docComponent.allowedMimeType.join();
        switch (filter.name) {
            case 'fileSize':
                this.errorMessage = `Maximum upload size exceeded (${item.size} of ${docComponent.maxFileSize} allowed)`;
                break;
            case 'mimeType':
                this.errorMessage = `Type "${item.type} is not allowed. Allowed types: "${allowedTypes}"`;
                break;
            default:
                this.errorMessage = `Unknown error (filter is ${filter.name})`;
        }
        this.notificationsService.error(this.errorMessage);
    }

    Save(): void {
        const ConsentToChangeFile: FileItem =
            this.docComponentConsentToChange.uploader.queue[this.docComponentConsentToChange.uploader.queue.length - 1];

        if (ConsentToChangeFile) {
            this.attachmentsService
                .createAndUpload('imed-claim', this.imedClaimId, ConsentToChangeFile._file, AttachmentTypeEnums.Consent_to_Change, 0, 0)
                .subscribe(() => {
                    this.onDocumentSaved.emit();
                    this.notificationsService.success('Consent To Change Document uploaded');
                });
        } else {
            this.notificationsService.error('No File(s) Chosen');
        }
    }

    cancel(): void {
        this.onClose.emit();
    }
}
