import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { PhysicianVacationService } from '../physicianvacation.service';
import { IPhysicianVacation } from '@model/interfaces/physician-vacation';
import { PhysicianVacationDynamicConfig } from '../physician-vacation.dynamic-config';
import { PhysicianService } from '../../physician.service';
import { AuthService } from '../../../../../../../node_modules/@mt-ng2/auth-module';
import { finalize } from 'rxjs/operators';
import { DynamicField, DynamicLabel, IDynamicFormConfig } from '@mt-ng2/dynamic-form';

@Component({
    selector: 'app-physician-vacation-basic-info',
    templateUrl: './physician-vacation-basic-info.component.html',
})
export class PhysicianVacationBasicInfoComponent implements OnInit {
    @Input() physicianVacation: IPhysicianVacation;
    @Input() canEdit: boolean;

    isEditing: boolean;
    isHovered: boolean;
    config: IDynamicFormConfig;
    physicianVacationForm: any;
    formFactory: PhysicianVacationDynamicConfig<IPhysicianVacation>;
    doubleClickIsDisabled = false;
    individualId: number;
    addressBookId: number;

    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    constructor(
        private physicianVacationService: PhysicianVacationService,
        private physicianService: PhysicianService,
        private notificationsService: NotificationsService,
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
    ) {}

    ngOnInit(): void {
        this.isEditing = false;
        this.config = { formObject: [], viewOnly: [] };
        this.individualId = +this.route.parent.parent.snapshot.paramMap.get('individualId');
        this.addressBookId = +this.route.parent.parent.parent.snapshot.paramMap.get('addressBookId');

        this.setConfig();
    }

    setConfig(): void {
        this.formFactory = new PhysicianVacationDynamicConfig<IPhysicianVacation>(this.physicianVacation);
        this.config = this.formFactory.getForUpdate();

        if (this.physicianVacation.Id === 0) {
            this.isEditing = true;
            this.config = this.formFactory.getForCreate();
        }

        this.viewOnly = this.config?.viewOnly?.map((x) => new DynamicLabel(x));
        this.formObject = this.config.formObject?.map((x) => new DynamicField(x));
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.physicianVacation.Id === 0) {
            void this.router.navigate([`address-books/${this.addressBookId}/individuals/${this.individualId}/physician-vacations`]);
        } else {
            this.isEditing = false;
        }
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.physicianVacation, form.value.PhysicianVacation as IPhysicianVacation);
            this.setUTCDate(form);
            if (!this.physicianVacation.Id || this.physicianVacation.Id === 0) {
                this.physicianVacationService.create(this.physicianVacation).subscribe(() => {
                    this.success();
                    this.physicianVacationService.emitChange(this.physicianVacation);
                    void this.router.navigate([`address-books/${this.addressBookId}/individuals/${this.individualId}`]);
                });
            } else {
                // handle existing physicianVacation save
                this.physicianVacationService.update(this.physicianVacation).subscribe(() => {
                    this.isEditing = false;
                    this.success();
                    this.physicianVacationService.emitChange(this.physicianVacation);
                    this.setConfig();
                });
            }
        } else {
            markAllFormFieldsAsTouched(form);
            this.error();
        }
    }

    setDatesToTimeZoneRelatedDate(dateToBeConverted: string): Date {
        const dateFromFormAsISOString = new Date(dateToBeConverted).toISOString();
        const dateWithoutUTCIdentifier = new Date(dateFromFormAsISOString.substring(0, dateFromFormAsISOString.length - 1));
        return new Date(dateWithoutUTCIdentifier.mtDate.toMoment().format('MM/DD/YYYY'));
    }

    setUTCDate(form: UntypedFormGroup): void {
        this.physicianVacation.FromDate = this.setDatesToTimeZoneRelatedDate(form.controls.PhysicianVacation.get('FromDate').value as string);
        this.physicianVacation.ToDate = this.setDatesToTimeZoneRelatedDate(form.controls.PhysicianVacation.get('ToDate').value as string);
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }
}
