import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { IAddressBookType } from '@model/interfaces/address-book-type';
import { AddressBookTypeService } from '../../../address-books/addressbooktype.service';
import { AddressBookTypeIdEnums } from '@common/constants/Enums';
import { AddressBookAssociationTypeSelectionService } from '../services/ab-associations-type-selection.service';

@Component({
    selector: 'addressbook-type-select',
    styleUrls: ['../row-styles.less'],
    template: `
        <div class="row">
            <div class="col-md-4">
                <div class="miles-editable miles-card padded">
                    <h4>Select an Address Book Type:</h4>
                    <select
                        name="service"
                        class="form-control addressbook-type-select-input"
                        [(ngModel)]="selectedAddressBookType"
                        (ngModelChange)="serviceSelected()"
                    >
                        <option *ngFor="let addressBookType of addressBookTypes" [ngValue]="addressBookType">{{ addressBookType.Name }}</option>
                    </select>
                </div>
            </div>
        </div>
    `,
})
export class AddressBookTypeSelectComponent implements OnInit {
    addressBookTypes: IAddressBookType[] = [];
    selectedAddressBookType: IAddressBookType;
    @Output() onAddressBookTypeSelected: EventEmitter<any> = new EventEmitter();

    constructor(
        private addressBookTypeService: AddressBookTypeService,
        private addressBookAssociationABTypeSelectionService: AddressBookAssociationTypeSelectionService,
    ) {}

    ngOnInit(): void {
        this.addressBookTypeService
            .getItems()
            .subscribe(
                (addressBookTypes) =>
                    (this.addressBookTypes = addressBookTypes
                        .filter((x) => x.Id === +AddressBookTypeIdEnums.Expert_panel || x.Id === +AddressBookTypeIdEnums.Physician_panel)
                        .sort((a, b) => (a.Name < b.Name ? -1 : 1))),
            );
    }

    serviceSelected(): void {
        this.addressBookAssociationABTypeSelectionService.setAssociationType(this.selectedAddressBookType);
        this.onAddressBookTypeSelected.emit(this.selectedAddressBookType);
    }
}
