import { NotificationsService } from '@mt-ng2/notifications-module';
import { ImedClaimStatusService } from './../imedclaimstatus.service';
import { Component, OnInit, OnDestroy, ViewChild, inject } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import 'rxjs/operators';
import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues, AuthService, ILoggedIn } from '@mt-ng2/auth-module';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection, IItemDeletedEvent, EntityListExportService } from '@mt-ng2/entity-list-module';
import { ImedClaimService } from '../imedclaim.service';
import { IImedClaim } from '@model/interfaces/imed-claim';
import { entityListModuleConfig } from '../../common/shared.module';
import { ClaimTypes } from '@model/ClaimTypes';
import { ImedClaimsEntityListConfig } from './imed-claims.entity-list-config';
import { Subscription, Observable, forkJoin } from 'rxjs';
import { CommonSearchService } from '../../common/services/common-search.service';
import { UserService } from '../../users/user.service';
import { debounceTime, map } from 'rxjs/operators';
import { IMedClaimStatusIdEnums, PopUpTypeEnums } from '../../common/constants/Enums';
import { MtSearchBarComponent } from '@mt-ng2/searchbar-control';
import { ReuseRoute } from '../../common/cache-search-reuse-strategy/reuse-route.library';
import { DatePipe } from '@angular/common';
import { MetaItem } from '@mt-ng2/base-service';
import { IImedClaimType } from '@model/interfaces/imed-claim-type';
import { IFabSpeedDialMini, OpenDirection } from '@mt-ng2/fab-speed-dial-control';
import { ImedClaimTypeService } from '../../common/services/imed-claim-type.service';
import { ImedClaimTypes } from '@model/ImedClaimTypes';
import { IPopUpParams, PopUpService } from '../../common/services/popup-service';
import { ServiceService } from '../services/service.service';
import { IService } from '@model/interfaces/service';
import { ImedClaimsEntityListExportConfig } from './imed-claims.entity-list-export-config';

@Component({
    selector: 'app-imed-claims',
    templateUrl: './imed-claims.component.html',
})
export class ImedClaimsComponent implements OnInit, OnDestroy, ReuseRoute {
    searchControl = new UntypedFormControl();
    imedClaims: IImedClaim[];
    currentPage = 1;
    query = '';
    total: number;
    imedClaimStatuses: MtSearchFilterItem[] = [];
    imedClaimTypes: MtSearchFilterItem[] = [];
    allServiceTypes: MtSearchFilterItem<IService>[] = [];
    serviceTypes: MtSearchFilterItem<IService>[] = [];
    userCustomOptions: any;

    claimNo: string;
    fileNo: string;
    facilityOrIndividual: string;
    defenseAttorneyIndividual: string;
    defenseAttorneyFirm: string;
    paralegalIndividual: string;
    carrierPartyFirm: string;

    entityListConfig = new ImedClaimsEntityListConfig();
    entityListExportConfig = new ImedClaimsEntityListExportConfig();
    order = 'ImedClaimStatusId';
    orderDirection = 'Desc';

    canAddImedClaim = false;
    formCreated = false;
    searchClaimsForm: UntypedFormGroup;
    subscriptions: Subscription = new Subscription();
    includeArchived = false;
    currentUser: ILoggedIn;

    // Variables for saved search component
    commonSearchParams: SearchParams;
    userId: number;
    currentSearch: SearchParams;
    searchChanged: boolean;

    componentLoaded: boolean;

    // Date created filter
    createdDateStartDate: Date = null;
    createdDateEndDate: Date = null;

    // Status Changed Date filter
    statusChangeStartDate: Date = null;
    statusChangeEndDate: Date = null;

    // User who changed status filter
    allUsers: MtSearchFilterItem[] = [];

    searchExpanded = false;

    @ViewChild('searchBar') searchBar: MtSearchBarComponent;

    // Variables for populating the fab speed dial to add new cases
    fabMinis: IFabSpeedDialMini[] = [];
    openDir = OpenDirection.up;
    iconActive = 'fa fa-minus fa-lg';
    iconStart = 'fa fa-plus fa-lg';
    enablePillIcons = true;

    constructor(
        private imedClaimService: ImedClaimService,
        private imedClaimStatusService: ImedClaimStatusService,
        private claimsService: ClaimsService,
        private router: Router,
        private authService: AuthService,
        private notificationsService: NotificationsService,
        private commonSearchService: CommonSearchService,
        private userService: UserService,
        private datePipe: DatePipe,
        private imedClaimTypeService: ImedClaimTypeService,
        private popUpService: PopUpService,
        private serviceService: ServiceService,
        private entityListExportService: EntityListExportService,
    ) {}

    resetFilters(): void {
        this.searchBar.clearSearch();
        this.createdDateEndDate = null;
        this.createdDateStartDate = null;
        this.statusChangeStartDate = null;
        this.statusChangeEndDate = null;
        this.allUsers.forEach((x) => (x.Selected = false));
        this.serviceTypes.forEach((x) => (x.Selected = false));
        this.imedClaimTypes.forEach((x) => (x.Selected = false));
        if (this.includeArchived) {
            this.searchClaimsForm.get('searchIncludeArchived').setValue(false);
        }
        if (this.facilityOrIndividual) {
            this.searchClaimsForm.get('searchFacilityOrIndividual').setValue(null);
        }
        if (this.claimNo) {
            this.searchClaimsForm.get('searchClaimNo').setValue(null);
        }
        if (this.fileNo) {
            this.searchClaimsForm.get('searchFileNo').setValue(null);
        }
        if (this.defenseAttorneyFirm) {
            this.searchClaimsForm.get('searchDefenseAttorneyFirm').setValue(null);
        }
        if (this.defenseAttorneyIndividual) {
            this.searchClaimsForm.get('searchDefenseAttorneyIndividual').setValue(null);
        }
        if (this.paralegalIndividual) {
            this.searchClaimsForm.get('searchParalegalIndividual').setValue(null);
        }
        if (this.carrierPartyFirm) {
            this.searchClaimsForm.get('searchCarrierPartyFirm').setValue(null);
        }

        this.imedClaimStatuses.forEach((x) =>
            x.Item.Id !== (IMedClaimStatusIdEnums.Active as number) && x.Item.Id !== (IMedClaimStatusIdEnums.Pending as number)
                ? (!x.Selected)
                : true,
        );
        this.imedClaimTypes.forEach((x) => (x.Selected = this.checkUserCustomOption(x)));
        this.searchExpanded = false;
        this.commonSearchParams = null;
        this.searchChanged = !this.searchChanged;
    }

    ngOnInit(): void {
        this.entityListConfig.export = {
            exportName: 'Claims List',
            onExportClick: () => this.getImedClaimsSubscription(true).pipe(map((x) => x.body)).subscribe((data: IImedClaim[]) => {
                this.entityListExportService.export(data, this.entityListExportConfig);
            }),
        };

        this.subscriptions.add(
            this.userService.getAll().subscribe((users) => {
                this.allUsers = users.map((user) => new MtSearchFilterItem(new MetaItem(user.Id, `${user.FirstName} ${user.LastName}`), false));
            }),
        );

        this.searchClaimsForm = new UntypedFormGroup({
            searchClaimNo: new UntypedFormControl(''),
            searchFacilityOrIndividual: new UntypedFormControl(''),
            searchFileNo: new UntypedFormControl(''),
            searchIncludeArchived: new UntypedFormControl(false),
            searchDefenseAttorneyIndividual: new UntypedFormControl(''),
            searchDefenseAttorneyFirm: new UntypedFormControl(''),
            searchCarrierPartyFirm: new UntypedFormControl(''),
            searchParalegalIndividual: new UntypedFormControl(''),
        });

        this.userId = this.authService.currentUser.getValue().Id;
        this.currentUser = this.authService.currentUser.getValue();
        this.canAddImedClaim = this.claimsService.hasClaim(ClaimTypes.CaseManagement, [ClaimValues.FullAccess]);

        forkJoin([
            this.commonSearchService.getCommonSearchFilters(),
            this.imedClaimStatusService.getSearchFilterItems(),
            this.imedClaimTypeService.getAll(),
            this.serviceService.getServiceTypeSortedByName(),
        ]).subscribe(([commonFilters, imedClaimStatusFilters, imedClaimTypes, serviceTypes]) => {
            this.commonSearchParams = commonFilters ? commonFilters.searchFilters : null;
            const isFromDashboardSearch = this.commonSearchParams
                ? commonFilters.searchFilters.extraParams?.some((ep) => ep.name === 'claimantName')
                : false;
            if (!this.commonSearchParams || isFromDashboardSearch) {
                this.imedClaimStatuses.push(
                    new MtSearchFilterItem(
                        {
                            Id: IMedClaimStatusIdEnums.Active,
                            Name: 'Active',
                        },
                        true,
                    ),
                );
                this.imedClaimStatuses.push(
                    new MtSearchFilterItem(
                        {
                            Id: IMedClaimStatusIdEnums.Pending,
                            Name: 'Pending',
                        },
                        true,
                    ),
                );
            }
            this.commonSearchService.clearCommonSearchFilters();

            this.imedClaimStatuses = this.imedClaimStatuses.concat(
                imedClaimStatusFilters.filter(
                    (x) => x.Item.Id !== (IMedClaimStatusIdEnums.Active as number) && x.Item.Id !== (IMedClaimStatusIdEnums.Pending as number),
                ),
            );

            this.imedClaimTypes = imedClaimTypes.map((type) => new MtSearchFilterItem(type, false));
            this.imedClaimTypes.forEach((x) => (x.Selected = this.checkUserCustomOption(x)));
            this.fabMinis = imedClaimTypes.map((ct) => {
                return {
                    htmlOverride: `<span>${ct.Name}</span>`,
                    icon: ' ',
                    onClick: () => {
                        void this.addImedClaim(ct);
                    },
                } as IFabSpeedDialMini;
            });
            this.allServiceTypes = serviceTypes;
            this.updateServiceFilter();
            this.getImedClaims();
        });

        this.subscribeToValueChanges();
        this.formCreated = true;
        this.componentLoaded = true;
        this.setUserCustomOptions();
    }

    onRouteReuse(): void {
        this.getImedClaims();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    setUserCustomOptions(): void {
        this.userCustomOptions = this.authService.currentUser.getValue().CustomOptions;
    }

    checkUserCustomOption(filterItem: MtSearchFilterItem): boolean {
        switch (filterItem.Item.Id as ImedClaimTypes) {
            case ImedClaimTypes.GeneralLiability:
                return this.userCustomOptions.GeneralLiability;
            case ImedClaimTypes.WorkersCompensation:
                return this.userCustomOptions.WorkersComp;
            case ImedClaimTypes.Auto:
                return this.userCustomOptions.Auto;
            default:
                return false;
        }
    }

    private subscribeToValueChanges(): void {
        this.subscriptions.add(
            this.searchClaimsForm
                .get('searchClaimNo')
                .valueChanges.pipe(debounceTime(400))
                .subscribe((value) => {
                    this.claimNo = value;
                    this.clearCommonSearchParamsAndSearch();
                }),
        );

        this.subscriptions.add(
            this.searchClaimsForm
                .get('searchFileNo')
                .valueChanges.pipe(debounceTime(400))
                .subscribe((value) => {
                    this.fileNo = value;
                    this.clearCommonSearchParamsAndSearch();
                }),
        );

        this.subscriptions.add(
            this.searchClaimsForm
                .get('searchFacilityOrIndividual')
                .valueChanges.pipe(debounceTime(400))
                .subscribe((value) => {
                    this.facilityOrIndividual = value;
                    this.clearCommonSearchParamsAndSearch();
                }),
        );

        this.subscriptions.add(
            this.searchClaimsForm
                .get('searchDefenseAttorneyFirm')
                .valueChanges.pipe(debounceTime(400))
                .subscribe((value) => {
                    this.defenseAttorneyFirm = value;
                    this.clearCommonSearchParamsAndSearch();
                }),
        );

        this.subscriptions.add(
            this.searchClaimsForm
                .get('searchDefenseAttorneyIndividual')
                .valueChanges.pipe(debounceTime(400))
                .subscribe((value) => {
                    this.defenseAttorneyIndividual = value;
                    this.clearCommonSearchParamsAndSearch();
                }),
        );

        this.subscriptions.add(
            this.searchClaimsForm
                .get('searchParalegalIndividual')
                .valueChanges.pipe(debounceTime(400))
                .subscribe((value) => {
                    this.paralegalIndividual = value;
                    this.clearCommonSearchParamsAndSearch();
                }),
        );

        this.subscriptions.add(
            this.searchClaimsForm
                .get('searchCarrierPartyFirm')
                .valueChanges.pipe(debounceTime(400))
                .subscribe((value) => {
                    this.carrierPartyFirm = value;
                    this.clearCommonSearchParamsAndSearch();
                }),
        );

        this.subscriptions.add(
            this.searchClaimsForm.get('searchIncludeArchived').valueChanges.subscribe((value) => {
                this.includeArchived = value;
                this.clearCommonSearchParamsAndSearch();
            }),
        );
    }

    createdByDateSelectionChanged(evt): void {
        this.createdDateEndDate = evt.endDate;
        this.createdDateStartDate = evt.startDate;
        this.clearCommonSearchParamsAndSearch();
    }

    statusChangeDateSelectionChanged(evt): void {
        this.statusChangeEndDate = evt.endDate;
        this.statusChangeStartDate = evt.startDate;
        this.clearCommonSearchParamsAndSearch();
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    private buildSearch(): ExtraSearchParams[] {
        const selectedImedClaimStatusesIds: number[] = this.getSelectedFilters(this.imedClaimStatuses);
        const selectedImedClaimTypeIds: number[] = this.getSelectedFilters(this.imedClaimTypes);
        const statusChangedByUsers: number[] = this.getSelectedFilters(this.allUsers);
        const selectedServiceTypeIds: number[] = this.getSelectedFilters(this.serviceTypes);

        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: this.searchExpanded ? 'logImedClaimStatusId' : 'imedClaimStatusIds',
                valueArray: selectedImedClaimStatusesIds,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'imedClaimTypeIds',
                valueArray: selectedImedClaimTypeIds,
            }),
        );

        if (this.claimNo) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'claimNo',
                    value: this.claimNo,
                }),
            );
        }

        if (this.commonSearchParams) {
            const filteredParam = this.commonSearchParams.extraParams?.find((extraParam) => {
                return extraParam.name === 'claimantName';
            });
            if (filteredParam && filteredParam.value) {
                _extraSearchParams.push(
                    new ExtraSearchParams({
                        name: 'claimantName',
                        value: filteredParam.value,
                    }),
                );
            }
        }

        if (this.fileNo) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'fileNo',
                    value: this.fileNo,
                }),
            );
        }

        if (this.defenseAttorneyFirm) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'defenseAttorneyFirm',
                    value: this.defenseAttorneyFirm,
                }),
            );
        }

        if (this.facilityOrIndividual) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'facilityOrIndividual',
                    value: this.facilityOrIndividual,
                }),
            );
        }

        if (this.defenseAttorneyIndividual) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'defenseAttorneyIndividual',
                    value: this.defenseAttorneyIndividual,
                }),
            );
        }

        if (this.paralegalIndividual) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'paralegalIndividual',
                    value: this.paralegalIndividual,
                }),
            );
        }

        if (this.carrierPartyFirm) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'carrierPartyFirm',
                    value: this.carrierPartyFirm,
                }),
            );
        }

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'includeArchived',
                value: this.includeArchived ? '1' : '0',
            }),
        );

        if (this.createdDateStartDate && this.createdDateEndDate) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'DateCreatedStartDate',
                    value: this.datePipe.transform(this.createdDateStartDate, 'M/d/yyyy', 'UTC'),
                }),
            );
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'DateCreatedEndDate',
                    value: this.datePipe.transform(this.createdDateEndDate, 'M/d/yyyy', 'UTC'),
                }),
            );
        }

        if (this.statusChangeStartDate && this.statusChangeEndDate) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'StatusChangeStartDate',
                    value: this.datePipe.transform(this.statusChangeStartDate, 'M/d/yyyy', 'UTC'),
                }),
            );
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'StatusChangeEndDate',
                    value: this.datePipe.transform(this.statusChangeEndDate, 'M/d/yyyy', 'UTC'),
                }),
            );
        }

        if (statusChangedByUsers) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'StatusChangedByUserIds',
                    valueArray: statusChangedByUsers,
                }),
            );
        }

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'ServiceTypeIds',
                valueArray: selectedServiceTypeIds,
            }),
        );

        return _extraSearchParams;
    }

    // Clear out common search params before getting next results
    clearCommonSearchParamsAndSearch(): void {
        this.currentPage = 1;
        this.getImedClaims();
    }

    preFillFiltersWithSavedSearch(): void {
        this.autoFillSelectedFilters(this.serviceTypes, 'ServiceTypeIds');
        this.autoFillSelectedFilters(this.imedClaimTypes, 'imedClaimTypeIds');
        this.autoFillSelectedFilters(this.imedClaimStatuses, 'imedClaimStatusIds');
        this.autoFillSelectedFilters(this.allUsers, 'StatusChangedByUserIds');
        this.setCheckBoxFilter('includeArchived', 'searchIncludeArchived', this.includeArchived);
        this.setTextFilter('claimNo', 'searchClaimNo', this.claimNo);
        this.setTextFilter('fileNo', 'searchFileNo', this.fileNo);
        this.setTextFilter('defenseAttorneyFirm', 'searchDefenseAttorneyFirm', this.defenseAttorneyFirm);
        this.setTextFilter('carrierPartyFirm', 'searchCarrierPartyFirm', this.carrierPartyFirm);
        this.setTextFilter('defenseAttorneyIndividual', 'searchDefenseAttorneyIndividual', this.defenseAttorneyIndividual);
        this.setTextFilter('paralegalIndividual', 'searchParalegalIndividual', this.paralegalIndividual);
        this.setTextFilter('facilityOrIndividual', 'searchFacilityOrIndividual', this.facilityOrIndividual);
        if (this.getDateFilter('DateCreatedStartDate') !== null) {
            this.createdDateStartDate = new Date(this.getDateFilter('DateCreatedStartDate') as string);
        }
        if (this.getDateFilter('DateCreatedEndDate') !== null) {
            this.createdDateEndDate = new Date(this.getDateFilter('DateCreatedEndDate') as string);
        }
        if (this.getDateFilter('StatusChangeStartDate') !== null) {
            this.statusChangeStartDate = new Date(this.getDateFilter('StatusChangeStartDate') as string);
        }
        if (this.getDateFilter('StatusChangeEndDate') !== null) {
            this.statusChangeEndDate = new Date(this.getDateFilter('StatusChangeEndDate') as string);
        }
    }

    getDateFilter(pptyName: string): any {
        const extrasearchParams = this.commonSearchParams.extraParams;
        const filteredParam = extrasearchParams?.find((extraParam) => {
            return extraParam.name === pptyName;
        });
        if (filteredParam && filteredParam.value) {
            return this.datePipe.transform(filteredParam.value, 'M/d/yyyy', 'UTC');
        }
        return null;
    }

    setCheckBoxFilter(pptyName: string, formControlName: string, ppty: any): void {
        const extrasearchParams = this.commonSearchParams.extraParams;
        const filteredParam = extrasearchParams?.find((extraParam) => {
            return extraParam.name === pptyName;
        });
        if (filteredParam && filteredParam.value) {
            const valueToSet = filteredParam.value === '1' ? true : false;
            if (valueToSet !== ppty) {
                this.searchClaimsForm.get(formControlName).setValue(valueToSet);
            }
        }
    }

    setTextFilter(pptyName: string, formControlName: string, currentVal: any): void {
        const extrasearchParams = this.commonSearchParams.extraParams;
        const filteredParam = extrasearchParams?.find((extraParam) => {
            return extraParam.name === pptyName;
        });
        if (filteredParam && filteredParam.value) {
            if (currentVal !== filteredParam.value) {
                this.searchClaimsForm.get(formControlName).setValue(filteredParam.value);
            }
        }
    }

    private autoFillSelectedFilters(filterObj: MtSearchFilterItem[], pptyName: string): void {
        const extrasearchParams = this.commonSearchParams.extraParams;
        const filteredParam = extrasearchParams?.find((extraParam) => {
            return extraParam.name === pptyName;
        });
        if (filteredParam && filteredParam.valueArray && filteredParam.valueArray.length > 0) {
            filterObj.forEach((x) => (x.Selected = false));
            filterObj.map((item) => {
                filteredParam?.valueArray?.forEach((element) => {
                    if (element === item.Item.Id) {
                        item.Selected = true;
                    }
                });
            });
        }
    }

    getImedClaims(): void {
        this.getImedClaimsSubscription().subscribe((answer) => {
            this.imedClaims = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    getImedClaimsSubscription(skipPaging?: boolean): Observable<any> {
        if (this.query && this.commonSearchParams) {
            this.commonSearchParams.query = this.query;
        }
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();
        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.commonSearchParams && this.commonSearchParams.order ? this.commonSearchParams.order : this.order,
            orderDirection:
                this.commonSearchParams && this.commonSearchParams.orderDirection ? this.commonSearchParams.orderDirection : this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: skipPaging ? 0 : (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: skipPaging ? 0 : entityListModuleConfig.itemsPerPage,
        };
        const searchparams = new SearchParams(searchEntity);
        // store current search params in case user wants to saves this search
        this.currentSearch = searchparams;

        return this.imedClaimService.searchClaims(searchparams);
    }

    search(query: string): void {
        this.query = query;
        if (this.commonSearchParams) {
            const filteredParam = this.commonSearchParams.extraParams?.find((extraParam) => {
                return extraParam.name === 'claimantName';
            });
            if (filteredParam && filteredParam.value) {
                this.commonSearchParams.extraParams?.forEach((item, index) => {
                    if (item.name === 'claimantName') {
                        this.commonSearchParams.extraParams?.splice(index, 1);
                    }
                });
            }
        }
        this.clearCommonSearchParamsAndSearch();
    }

    searchBySavedSearch(): void {
        this.commonSearchService.getCommonSearchFilters().subscribe((filters) => {
            if (this.commonSearchParams) {
                this.resetFilters();
            }
            this.query = '';
            this.currentPage = 1;
            this.commonSearchParams = filters ? filters.searchFilters : null;
            this.searchBar.writeValue(this.commonSearchParams.query);
            this.query = this.commonSearchParams.query;
            this.preFillFiltersWithSavedSearch();
            this.getImedClaims();
            this.commonSearchService.clearCommonSearchFilters();
        });
    }

    columnSorted(event: IColumnSortedEvent): void {
        if (this.commonSearchParams) {
            this.commonSearchParams.order = event.column.sort.sortProperty;
            this.commonSearchParams.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        } else {
            this.order = event.column.sort.sortProperty;
            this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        }
        this.getImedClaims();
    }

    imedClaimSelected(event: IItemSelectedEvent): void {
        void this.router.navigate(['/cases', event.entity.Id]);
    }

    onItemDeleted(event: IItemDeletedEvent): void {
        const selectedItem: IImedClaim = event.entity;
        this.imedClaimService.getById(selectedItem.Id).subscribe((imedClaim) => {
            imedClaim.Archived = !imedClaim.Archived;
            imedClaim.ModifiedById = this.authService.currentUser.getValue().Id;
            imedClaim.DateModified = new Date();
            this.imedClaimService.update(imedClaim).subscribe(() => {
                this.notificationsService.success('Case Updated Successfully');
                this.getImedClaims();
            });
        });
    }

    showPopupWarning(popUp: IPopUpParams): void {
        this.popUpService.showPopUp(popUp).subscribe((response) => {
            if (response.value) {
                return;
            }
        });
    }

    addImedClaim(type: IImedClaimType): void {
        const popUp: IPopUpParams = {
            confirmButtonText: 'Continue',
            html: 'You are trying to add a case outside of your defined role. Do you want to continue?',
            title: 'Case Alert',
            type: PopUpTypeEnums.Soft_Pop_Up,
        };

        switch (type.Id as ImedClaimTypes) {
            case ImedClaimTypes.GeneralLiability:
                if (!this.currentUser.CustomOptions.GeneralLiability) {
                    this.showPopupWarning(popUp);
                }
                void this.router.navigate([`/cases/${ImedClaimTypes.GeneralLiability}/add/`]);
                break;
            case ImedClaimTypes.WorkersCompensation:
                if (!this.currentUser.CustomOptions.WorkersComp) {
                    this.showPopupWarning(popUp);
                }
                void this.router.navigate([`/cases/${ImedClaimTypes.WorkersCompensation}/add/`]);
                break;
            case ImedClaimTypes.Auto:
                if (!this.currentUser.CustomOptions.Auto) {
                    this.showPopupWarning(popUp);
                }
                void this.router.navigate([`/cases/${ImedClaimTypes.Auto}/add/`]);
                break;
            default:
                this.notificationsService.error(`Unknown claim type: ${type.Name}`);
        }
    }

    updateServiceFilter(): void {
        const selectedClaimTypes = this.imedClaimTypes.filter((ct) => ct.Selected);

        this.serviceTypes = this.allServiceTypes.filter((s) => {
            if (selectedClaimTypes.length > 0) {
                const returnValue = selectedClaimTypes.some((c) => c.Item.Id === s.Item.ClaimTypeId);
                return returnValue;
            } else {
                return true;
            }
        });
    }

    selectCaseTypeAndFilterServices(): void {
        this.updateServiceFilter();
        this.clearCommonSearchParamsAndSearch();
    }
}
