import { IRecordRetrievalService } from '@model/interfaces/record-retrieval-service.d';
import { AuthService, ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IImedClaimServiceContactInfo } from './../../../../imed-claim-basic-info/imed-claim-contacts-info';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { ImedClaimServiceService } from '../../../imedclaimservice.service';
import { IImedClaimService } from '@model/interfaces/imed-claim-service';
import { Subscription, forkJoin } from 'rxjs';
import { IAttachmentDTO } from '@model/interfaces/custom/attachment-dto';
import { finalize } from 'rxjs/operators';
import { ClaimTypes } from '@model/ClaimTypes';
import { IModalOptions, IModalWrapperApi } from '@mt-ng2/modal-module';
import { IUser } from '@model/interfaces/user';
import { UserService } from '../../../../../users/user.service';
import { ISubpoenaService } from '@model/interfaces/subpoena-service';
import { SubpoenaServiceDynamicConfig } from '../supoena-service.dynamic-config';
import { SubpoenaServiceService } from '../subpoena-service.service';
import { DynamicField, DynamicLabel, IDynamicField, IDynamicLabel } from '@mt-ng2/dynamic-form';
import { SubpoenaServiceDynamicControlsPartial } from '@model/partials/subpoena-service.form-controls.partial';
import { ICourtAddressBookDTO } from '@model/interfaces/custom/court-address-book-dto';
import { ImedClaimSubpoenaDetailService } from '../../../imed-claim-subpoena-detail.service';
import { CourtProcessServerService } from '../../../../../address-books/court-process-server/court-process-server.service';
import { IServicePayload } from '@model/interfaces/custom/update-service-payload';
import { ICourtProcessServer } from '@model/interfaces/court-process-server';

@Component({
    selector: 'app-subpoena-service-basic-info',
    templateUrl: './subpoena-service-basic-info.component.html',
})
export class SubpoenaServiceBasicInfoComponent implements OnInit {
    @Input() iImedClaimServiceModel: IImedClaimService;
    @Input() subpoenaServiceModel: ISubpoenaService;
    @Input() recordRetrievalServiceModel: IRecordRetrievalService;
    @Input() canEdit: boolean;
    @Input() iImedClaimServiceContactInfo: IImedClaimServiceContactInfo;
    @Input() disablePhysicianFacilityControl: boolean;
    @Input() documentArray: IAttachmentDTO[];
    @Output('physicianFaxSent') physicianFaxSent: EventEmitter<any> = new EventEmitter<any>();

    isEditing: boolean;
    isSoftPopup: boolean;
    isHovered: boolean;
    config: any = {};
    subpoenaServiceForm: FormGroup;
    subpoenaformFactory: SubpoenaServiceDynamicConfig<ISubpoenaService>;
    doubleClickIsDisabled = false;
    subscriptions = new Subscription();
    canSeeAuditLog = false;

    modalOptions: IModalOptions = {
        customClass: 'modal-hide-actions' as any,
        width: 800,
    };
    uploadAttachmentsModalApi: IModalWrapperApi;
    selectedUser: IUser;
    subpoenaAssignedToUsers = [];
    invalidServiceAssignedTo = false;
    initialImedClaimServiceContactInfo: IImedClaimServiceContactInfo;
    courts: ICourtAddressBookDTO[];
    courtProcessServers: ICourtProcessServer[];
    subpoenaServiceFormControls: any;

    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    constructor(
        private imedClaimServiceService: ImedClaimServiceService,
        private notificationsService: NotificationsService,
        private fb: UntypedFormBuilder,
        private authService: AuthService,
        private subpoenaService: SubpoenaServiceService,
        private claimsService: ClaimsService,
        private userService: UserService,
        private imedClaimSubpoenaDetailService: ImedClaimSubpoenaDetailService,
        private courtProcessServerService: CourtProcessServerService,
    ) {}

    ngOnInit(): void {
        this.config = { formObject: [], viewOnly: [] };
        this.canSeeAuditLog = this.claimsService.hasClaim(ClaimTypes.Audit, [ClaimValues.FullAccess, ClaimValues.ReadOnly]);

        forkJoin(this.imedClaimSubpoenaDetailService.getCourtAddressBook(), this.courtProcessServerService.getAllProcessServers()).subscribe((answers) => {
            const [courts, courtProcessServers] = answers;
            this.courts = courts;
            this.courtProcessServers = courtProcessServers;
            this.setCourtsName();
            this.setConfig();
        });

        this.userService.getSubpoenaAssignedToUsers().subscribe((users) => {
            this.subpoenaAssignedToUsers = users;
            let selectedUser = users.find((u) => u.Id === this.iImedClaimServiceModel.SubpoenaAssignedToId);
            if (!selectedUser && this.iImedClaimServiceModel.SubpoenaAssignedToId !== null) {
                this.userService
                    .getById(this.iImedClaimServiceModel.SubpoenaAssignedToId)
                    .subscribe(
                        (user) => (
                            (selectedUser = user),
                            (this.selectedUser = selectedUser ? selectedUser : null),
                            this.subpoenaAssignedToUsers.push(selectedUser)
                        ),
                    );
            } else {
                this.selectedUser = selectedUser ? selectedUser : null;
            }
        });

        this.isEditing = false;
        this.subscriptions.add(
            this.imedClaimServiceService.changeEmitted$.subscribe((iImedClaimServiceModel) => {
                this.iImedClaimServiceModel = iImedClaimServiceModel;
            }),
        );
        this.setConfig();
    }

    ngOnChanges(changes: any): void {
        if (changes.iImedClaimServiceContactInfo) {
            this.initialImedClaimServiceContactInfo = { ...this.iImedClaimServiceContactInfo };
        }
    }

    setConfig(): void {
        this.subpoenaServiceForm = this.fb.group({});
        this.subpoenaformFactory = new SubpoenaServiceDynamicConfig<ISubpoenaService>(
            this.subpoenaServiceModel,
            this.courts,
            this.courtProcessServers,
            [
                'Reference',
                'DepositionSubpoena',
                'SubpoenaDucesTecum',
                'TrialSubpoena',
                'OtherSubpoena',
                'SubpoenaCoverPage',
                'AffidavitOfService',
                'LetterOfIntent',
                'ProofOfservice',
                'ProofOfServiceOfObjection',
                'CertificationOfRecords',
                'LetterOfRepresentation',
                'OptionalField1',
                'OptionalField2',
            ],
        );
        this.subpoenaServiceFormControls = new SubpoenaServiceDynamicControlsPartial(this.subpoenaServiceModel, this.courts, this.courtProcessServers, null);
        this.config = this.subpoenaformFactory.getForUpdate();

        this.viewOnly = this.config?.viewOnly?.map((x: IDynamicLabel) => new DynamicLabel(x));
        this.formObject = this.config.formObject?.map((x: IDynamicField) => new DynamicField(x));
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        this.isEditing = false;
        this.selectedUser = this.subpoenaAssignedToUsers.find((u) => u.Id === this.iImedClaimServiceModel.SubpoenaAssignedToId);
        this.iImedClaimServiceContactInfo = { ...this.initialImedClaimServiceContactInfo };
    }

    formSubmitted(): void {

        if (!this.subpoenaServiceForm.valid) {
            markAllFormFieldsAsTouched(this.subpoenaServiceForm);
            this.error();
            return;
        } else {
            this.save();
        }
    }

    save(): void {
        this.iImedClaimServiceModel = this.imedClaimServiceService.assignContactsToImedClaimService(
            this.iImedClaimServiceModel,
            this.iImedClaimServiceContactInfo,
        );
        this.subpoenaformFactory.assignFormValues(
            this.subpoenaServiceModel,
            this.subpoenaServiceForm.value.SubpoenaService as ISubpoenaService,
        );
        this.iImedClaimServiceModel.SubpoenaAssignedToId = this.selectedUser && this.selectedUser.Id > 0 ? this.selectedUser.Id : null;

        this.subpoenaformFactory.assignFormValues(
            this.subpoenaServiceModel,
            this.subpoenaServiceForm.value.RecordRetrievalService as ISubpoenaService,
        );

        const updatePayload = <IServicePayload<ISubpoenaService>>{};
        updatePayload.ChildService = this.subpoenaServiceModel;
        updatePayload.ParentService = this.iImedClaimServiceModel;

        this.subpoenaService
            .updateService(updatePayload)            .subscribe(() => {
                forkJoin(
                    this.imedClaimServiceService.getById(this.iImedClaimServiceModel.Id),
                    this.subpoenaService.getByImedClaimServiceId(this.iImedClaimServiceModel.Id),
                ).subscribe((response) => {
                    this.isEditing = false;
                    const [imedClaimServiceResponse, subpoenaServiceResponse] = response;
                    this.subpoenaServiceModel = subpoenaServiceResponse;
                    this.iImedClaimServiceModel = imedClaimServiceResponse;
                    this.imedClaimServiceService.emitChange(this.iImedClaimServiceModel);
                    this.subpoenaService.emitChange(this.subpoenaServiceModel);
                    this.success();
                    this.setConfig();
                });
            });
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }

    ShowDocumentPopup(): void {
        return this.uploadAttachmentsModalApi.show();
    }

    onDocumentChanged = (): void => null

    onDocumentSaved(): void {
        this.uploadAttachmentsModalApi.close();
        this.formSubmitted();
    }

    onCloseDocument(): void {
        this.uploadAttachmentsModalApi.close();
        if (this.isSoftPopup) {
            this.save();
        } else {
            this.error();

        }
    }

    setCourtsName(): void {
        this.courts.forEach(function(court): void {
           court.OriginalCourtName = court.Name;
           if (court.Documents.length > 0) {
               court.Name = court.Name + ' [' + court?.Documents.toString() + ']';
           }
       });
     }

    onDomesticationRequired(checked): void {
        if (!checked) {
           this.subpoenaServiceForm.get('SubpoenaService').get('ForeignCourtId').setValue(null);
        }
    }

    onProcessServerRequired(checked): void {
        if (!checked) {
          this.subpoenaServiceForm.get('SubpoenaService').get('ProcessServerId').setValue(null);
        }
    }
}
