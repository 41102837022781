import { AttachmentServiceTypeEnums, ServiceIdEnums } from './../constants/Enums';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MetaItemService } from '@mt-ng2/base-service';
import { IAttachmentType } from '@model/interfaces/attachment-type';
import { Observable, BehaviorSubject } from 'rxjs';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AttachmentTypeService extends MetaItemService<IAttachmentType> {
    private _allAttachments: BehaviorSubject<IAttachmentType[]> = new BehaviorSubject<IAttachmentType[]>([]);

    setAttachmentTypes(value: IAttachmentType[]): void {
        this._allAttachments.next(value);
    }

    getCachedAttachmentTypes(): Observable<IAttachmentType[]> {
        return this._allAttachments.asObservable();
    }

    constructor(public http: HttpClient) {
        super('AttachmentTypeService', 'Type', 'TypeIds', '/attachmenttypes', http);
        this.getAll().subscribe((types) => {
            this.setAttachmentTypes(types);
        });
    }

    public mapServiceToAttachmentServiceType(serviceId: ServiceIdEnums): Observable<AttachmentServiceTypeEnums> {
        const serviceIdtoAttachmentServiceMap = {
            1: AttachmentServiceTypeEnums.Record_Retrieval,
            2: AttachmentServiceTypeEnums.IME,
            3: AttachmentServiceTypeEnums.Radiology_Review,
            4: AttachmentServiceTypeEnums.Record_Review,
            5: AttachmentServiceTypeEnums.Billing_Desk_Review,
            6: AttachmentServiceTypeEnums.Testimony_Deposition,
            7: AttachmentServiceTypeEnums.Testimony_Deposition,
            8: AttachmentServiceTypeEnums.Testimony_Deposition,
            9: AttachmentServiceTypeEnums.Conference_Call_Between_Attorney,
            10: AttachmentServiceTypeEnums.IME_Re_Exam,
            11: AttachmentServiceTypeEnums.IME_Addendum,
            12: AttachmentServiceTypeEnums.IME_Expert_Consultation,
            13: AttachmentServiceTypeEnums.IME_Conference_Call,
            14: AttachmentServiceTypeEnums.IME,
            15: AttachmentServiceTypeEnums.Auto_IME,
            17: AttachmentServiceTypeEnums.Record_Review,
            18: AttachmentServiceTypeEnums.WC_IME_Re_Exam,
            19: AttachmentServiceTypeEnums.WC_Radiology_Review,
            20: AttachmentServiceTypeEnums.WC_Functional_Capacity,
            21: AttachmentServiceTypeEnums.Record_Review,
            22: AttachmentServiceTypeEnums.WC_Addendum,
            23: AttachmentServiceTypeEnums.WC_Conference_Call,
            24: AttachmentServiceTypeEnums.Radiology_Review,
            25: AttachmentServiceTypeEnums.IME_Addendum,
            26: AttachmentServiceTypeEnums.IME_Conference_Call,
            27: AttachmentServiceTypeEnums.Auto_IME,
            28: AttachmentServiceTypeEnums.Auto_IME,
            31: AttachmentServiceTypeEnums.Independent_Film_Review,
            32: AttachmentServiceTypeEnums.WC_Independent_Film_Review,
            33: AttachmentServiceTypeEnums.Auto_Independent_Film_Review
        };

        return of(serviceIdtoAttachmentServiceMap[serviceId]);
    }

    public getAttachmentTypes(attachmentTypeServiceId: number): Observable<IAttachmentType[]> {
        return this.http
            .get<IAttachmentType[]>(`/attachmenttypes/${attachmentTypeServiceId}/_getAttachmentTypes`, { responseType: 'json' })
            .pipe(map((types) => types.sort((a, b) => (a.Name > b.Name ? 1 : a.Name < b.Name ? -1 : 0))));
    }
}
