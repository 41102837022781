// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nopadding {
  padding: 0 !important;
}
.sortable-row-margins {
  margin-left: 25px !important;
}
.removable-row-margins {
  margin-right: 32px !important;
}
.move-handle {
  cursor: move;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.remove-button {
  padding-left: 6px !important;
  padding-right: 6px !important;
}
.second-ellipsis {
  position: relative;
  right: 17px;
  margin-right: -17px;
}
.flex {
  position: relative;
  display: flex;
}
.badge {
  position: absolute;
  background-color: #8ac175 !important;
  margin-top: 30px;
  z-index: 1;
}
`, "",{"version":3,"sources":["webpack://./src/app/imed-managed-list/app-meta-item-managed-list/app-meta-item-managed-list.component.less"],"names":[],"mappings":"AAAA;EACI,qBAAA;AACJ;AACA;EACI,4BAAA;AACJ;AACA;EACI,6BAAA;AACJ;AACA;EACI,YAAA;EACA,4BAAA;EACA,6BAAA;AACJ;AACA;EACI,4BAAA;EACA,6BAAA;AACJ;AACA;EACI,kBAAA;EACA,WAAA;EACA,mBAAA;AACJ;AACA;EACI,kBAAA;EACA,aAAA;AACJ;AACA;EACI,kBAAA;EAIA,oCAAA;EAGA,gBAAA;EACA,UAAA;AAJJ","sourcesContent":[".nopadding {\n    padding: 0 !important;\n}\n.sortable-row-margins {\n    margin-left: 25px !important;\n}\n.removable-row-margins {\n    margin-right: 32px !important;\n}\n.move-handle {\n    cursor: move;\n    padding-left: 0px !important;\n    padding-right: 0px !important;\n}\n.remove-button {\n    padding-left: 6px !important;\n    padding-right: 6px !important;\n}\n.second-ellipsis {\n    position: relative;\n    right: 17px;\n    margin-right: -17px;\n}\n.flex {\n    position: relative;\n    display: flex;\n}\n.badge {\n    position: absolute;\n    // display: block;\n    // white-space: nowrap;\n    // color: white;\n    background-color: #8ac175 !important;\n    // border-radius: 25%;\n    // padding: 0 3px;\n    margin-top: 30px;\n    z-index: 1;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
