import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';

import { IImedClaimCourtFee } from '@model/interfaces/imed-claim-court-fee';
import { ImedClaimCourtFeeDynamicControls } from '@model/form-controls/imed-claim-court-fee.form-controls';

export class ImedClaimCourtFeesDynamicConfig<T extends IImedClaimCourtFee> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private imedClaimCourtFee: T, private configControls?: string[]) {
        super();
        const dynamicControls = new ImedClaimCourtFeeDynamicControls(this.imedClaimCourtFee);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['Fees', 'DateCreated', 'CreatedById'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
