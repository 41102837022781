import { Component, OnInit, Input } from '@angular/core';
import { IMasterServiceStatusFollowupType } from '@model/interfaces/master-service-status-followup-type';
import { BaseRowComponent } from '../../base-row/base-row.component';

@Component({
    selector: 'status-followup-row',
    styleUrls: ['../../row-styles.less'],
    templateUrl: './status-followup-row.component.html',
})
export class StatusFollowupRowComponent extends BaseRowComponent implements OnInit {
    @Input() statusFollowupAssociation: IMasterServiceStatusFollowupType;

    constructor() {
        super();
    }

    ngOnInit(): void {
        if (this.statusFollowupAssociation.Id > 0) {
            this.selectedFollowup = this.statusFollowupAssociation.MasterFollowupType;
            this.selectedStatus = this.statusFollowupAssociation.MasterServiceStatus;
        }
    }
}
