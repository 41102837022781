import { Component, Input, EventEmitter, Output } from '@angular/core';
import { IFilteredServiceFollowupStatusTaskDTO } from '@model/interfaces/custom/filtered-service-followup-status-task-dto';
import { IMasterFollowupType } from '@model/interfaces/master-followup-type';
import { IMasterServiceStatus } from '@model/interfaces/master-service-status';

export interface IStatusFollowupAssociation {
    Status: IMasterServiceStatus;
    Followup: IMasterFollowupType;
}

@Component({
    template: ``,
})
export class BaseRowComponent {
    private _filteredServiceData: IFilteredServiceFollowupStatusTaskDTO;
    @Input('filteredServiceData')
    set filteredServiceData(val: IFilteredServiceFollowupStatusTaskDTO) {
        this._filteredServiceData = val;
    }
    get filteredServiceData(): IFilteredServiceFollowupStatusTaskDTO {
        return this._filteredServiceData;
    }

    @Input() className = '';
    selectedFollowup: IMasterFollowupType;
    selectedStatus: IMasterServiceStatus;

    @Output() onItemSelected: EventEmitter<any> = new EventEmitter();
    @Output() onItemRemoved: EventEmitter<any> = new EventEmitter();

    constructor() { }

    itemSelected(): void {
        this.onItemSelected.emit({
            Followup: this.selectedFollowup,
            Status: this.selectedStatus,
        } as IStatusFollowupAssociation);
    }

    itemRemoved(): void {
        this.onItemRemoved.emit(this.selectedStatus);
    }
}
