import { EntityListConfig, EntityListColumn, IEntityListColumn, IEntityListConfig, IEntityListExportConfig } from '@mt-ng2/entity-list-module';
import { IAddressBooksForList } from '@model/interfaces/address-books-for-list';
import { IAddressBookForList } from '@model/partials/address-books-for-list.partial';
import { ClaimTypeEnums } from '@common/constants/Enums';
import { IPhysicianDetail } from '@model/interfaces/physician-detail';

export class AddressBooksEntityListFeesExportConfig extends EntityListConfig {
    columns: IEntityListColumn[];

    export: IEntityListExportConfig = {
        exportName: 'Physician Panel & Expert Panel Fee List',
    };

    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    name: 'Facility ID',
                    accessorFunction: (ab: IAddressBooksForList) => {
                        return ab.Id.toString();
                    },
                }),
                new EntityListColumn({
                    name: 'Facility Name',
                    accessorFunction: (ab: IAddressBooksForList) => {
                        return ab.FacilityName;
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (ab: IAddressBooksForList): string {
                        if (ab.IndividualId) {
                            return ab.IndividualId.toString();
                        } else {
                            return '';
                        }
                    },
                    name: 'Individual ID',
                }),
                new EntityListColumn({
                    accessorFunction: function (ab: IAddressBooksForList): string {
                        if (ab.FirstName) {
                            const firstName = ab.FirstName ?? '';
                            const lastName = ab.LastName ?? '';
                            const suffix = ab.Suffix ?? '';
                            return `${lastName}, ${firstName} ${suffix}`;
                        } else {
                            return '';
                        }
                    },
                    name: 'Individual Name',
                }),
                new EntityListColumn({
                    accessors: ['Type'],
                    name: 'Physician/Expert Type',
                }),
                new EntityListColumn({
                    accessorFunction: function (ab: IAddressBooksForList): string {
                        if (ab.Specilaities) {
                            return `${ab.Specilaities.replace(/<[^>]*>/g, '')}`;
                        }
                        return '';
                    },
                    name: 'Specialty',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    accessorFunction: function (addressBook: IAddressBooksForList): string {
                        return `${addressBook.StateCode ? addressBook.StateCode : ''}`;
                    },
                    excludeFromView: true,
                    name: 'State',
                    sort: { disableSort: true },
                    style: { width: 230 },
                }),
                new EntityListColumn({
                    accessorFunction: function (ab: IAddressBookForList): string {
                        let CaseType = '';

                        if (ab.PhysicianDetails != null) {
                            ab.PhysicianDetails.forEach((pd) => {
                                const currentImedClaimId = pd.ImedClaimTypeId;

                                if (currentImedClaimId === ClaimTypeEnums.GL as number) {
                                    CaseType += 'GL, ';
                                } else if (currentImedClaimId === ClaimTypeEnums.WC as number) {
                                    CaseType += 'WC, ';
                                } else {
                                    CaseType += 'AUTO, ';
                                }
                            });
                            CaseType = CaseType.substring(0, CaseType.length - 2);
                        }
                        return CaseType.replace(/<[^>]*>/g, '\n');
                    },
                    bindToInnerHtml: true,
                    name: 'Case Type(s)',
                    sort: { disableSort: true },
                    style: { width: 200 },
                }),
                new EntityListColumn({
                    name: 'GL IME Consultation Fee',
                    accessorFunction: (ab: IAddressBookForList) => {
                        const physicianDetail = ab.PhysicianDetails && this.getGLDetails(ab.PhysicianDetails);
                        return physicianDetail ? `${physicianDetail.ImeExamConsultationFee || ''}` : '';
                    },
                }),
                new EntityListColumn({
                    name: 'GL No Show Fee',
                    accessorFunction: (ab: IAddressBookForList) => {
                        const physicianDetail = ab.PhysicianDetails && this.getGLDetails(ab.PhysicianDetails);
                        return physicianDetail ? `${physicianDetail.NoShowFee || ''}` : '';
                    },
                }),
                new EntityListColumn({
                    name: 'GL Hourly Fee',
                    accessorFunction: (ab: IAddressBookForList) => {
                        const physicianDetail = ab.PhysicianDetails && this.getGLDetails(ab.PhysicianDetails);
                        return physicianDetail ? `${physicianDetail.HourlyFee || ''}` : '';
                    },
                }),
                new EntityListColumn({
                    name: 'GL Film Review Fee',
                    accessorFunction: (ab: IAddressBookForList) => {
                        const physicianDetail = ab.PhysicianDetails && this.getGLDetails(ab.PhysicianDetails);
                        return physicianDetail ? `${physicianDetail.FilmReviewFee || ''}` : '';
                    },
                }),
                new EntityListColumn({
                    name: 'GL Additional Fee',
                    accessorFunction: (ab: IAddressBookForList) => {
                        return ab.PhysicianDetails ? `${this.getGLDetails(ab.PhysicianDetails)?.AdditionalFee || ''}` : '';
                    },
                }),
                new EntityListColumn({
                    name: 'GL Retainer',
                    accessorFunction: (ab: IAddressBookForList) => {
                        return ab.PhysicianDetails ? `${this.getGLDetails(ab.PhysicianDetails)?.Retainer || ''}` : '';
                    },
                }),
                new EntityListColumn({
                    name: 'GL Record Review Retainer',
                    accessorFunction: (ab: IAddressBookForList) => {
                        return ab.PhysicianDetails ? `${this.getGLDetails(ab.PhysicianDetails)?.RecordReviewRetainer || ''}` : '';
                    },
                }),
                new EntityListColumn({
                    name: 'GL Additional Film Review Fee',
                    accessorFunction: (ab: IAddressBookForList) => {
                        return ab.PhysicianDetails ? `${this.getGLDetails(ab.PhysicianDetails)?.AdditionalFilmReviewFee || ''}` : '';
                    },
                }),
                new EntityListColumn({
                    name: 'GL Addendum Fee',
                    accessorFunction: (ab: IAddressBookForList) => {
                        return ab.PhysicianDetails ? `${this.getGLDetails(ab.PhysicianDetails)?.AddendumFee || ''}` : '';
                    },
                }),
                new EntityListColumn({
                    name: 'GL Conference Call Fee',
                    accessorFunction: (ab: IAddressBookForList) => {
                        return ab.PhysicianDetails ? `${this.getGLDetails(ab.PhysicianDetails)?.ConferenceCallFee || ''}` : '';
                    },
                }),
                new EntityListColumn({
                    name: 'GL Full day testimony Fee',
                    accessorFunction: (ab: IAddressBookForList) => {
                        return ab.PhysicianDetails ? `${this.getGLDetails(ab.PhysicianDetails)?.FullDayTelephoneDepositionFee || ''}` : '';
                    },
                }),
                new EntityListColumn({
                    name: 'GL Half day testimony fee',
                    accessorFunction: (ab: IAddressBookForList) => {
                        return ab.PhysicianDetails ? `${this.getGLDetails(ab.PhysicianDetails)?.HalfDayTelephoneDepositionFee || ''}` : '';
                    },
                }),
                new EntityListColumn({
                    name: 'GL Virtual testimony fee',
                    accessorFunction: (ab: IAddressBookForList) => {
                        return ab.PhysicianDetails ? `${this.getGLDetails(ab.PhysicianDetails)?.VirtualTestimonyFee || ''}` : '';
                    },
                }),
                new EntityListColumn({
                    name: 'GL Full day telephone deposition fee',
                    accessorFunction: (ab: IAddressBookForList) => {
                        return ab.PhysicianDetails ? `${this.getGLDetails(ab.PhysicianDetails)?.FullDayTelephoneDepositionFee || ''}` : '';
                    },
                }),
                new EntityListColumn({
                    name: 'GL Half day telephone deposition fee',
                    accessorFunction: (ab: IAddressBookForList) => {
                        return ab.PhysicianDetails ? `${this.getGLDetails(ab.PhysicianDetails)?.HalfDayTelephoneDepositionFee || ''}` : '';
                    },
                }),

                new EntityListColumn({
                    name: 'WC IME Consultation Fee',
                    accessorFunction: (ab: IAddressBookForList) => {
                        return ab.PhysicianDetails ? `${this.getWCDetails(ab.PhysicianDetails)?.ImeExamConsultationFee || ''}` : '';
                    },
                }),
                new EntityListColumn({
                    name: 'WC No Show Fee',
                    accessorFunction: (ab: IAddressBookForList) => {
                        const physicianDetail = ab.PhysicianDetails && this.getWCDetails(ab.PhysicianDetails);
                        return physicianDetail ? `${physicianDetail.NoShowFee || ''}` : '';
                    },
                }),
                new EntityListColumn({
                    name: 'WC Hourly Fee',
                    accessorFunction: (ab: IAddressBookForList) => {
                        return ab.PhysicianDetails ? `${this.getWCDetails(ab.PhysicianDetails)?.HourlyFee || ''}` : '';
                    },
                }),
                new EntityListColumn({
                    name: 'WC Film Review Fee',
                    accessorFunction: (ab: IAddressBookForList) => {
                        return ab.PhysicianDetails ? `${this.getWCDetails(ab.PhysicianDetails)?.FilmReviewFee || ''}` : '';
                    },
                }),
                new EntityListColumn({
                    name: 'WC Additional Fee',
                    accessorFunction: (ab: IAddressBookForList) => {
                        return ab.PhysicianDetails ? `${this.getWCDetails(ab.PhysicianDetails)?.AdditionalFee || ''}` : '';
                    },
                }),
                new EntityListColumn({
                    name: 'WC Retainer',
                    accessorFunction: (ab: IAddressBookForList) => {
                        return ab.PhysicianDetails ? `${this.getWCDetails(ab.PhysicianDetails)?.Retainer || ''}` : '';
                    },
                }),
                new EntityListColumn({
                    name: 'WC Record Review Retainer',
                    accessorFunction: (ab: IAddressBookForList) => {
                        return ab.PhysicianDetails ? `${this.getWCDetails(ab.PhysicianDetails)?.RecordReviewRetainer || ''}` : '';
                    },
                }),
                new EntityListColumn({
                    name: 'WC Additional Film Review Fee',
                    accessorFunction: (ab: IAddressBookForList) => {
                        return ab.PhysicianDetails ? `${this.getWCDetails(ab.PhysicianDetails)?.AdditionalFilmReviewFee || ''}` : '';
                    },
                }),
                new EntityListColumn({
                    name: 'WC Addendum Fee',
                    accessorFunction: (ab: IAddressBookForList) => {
                        return ab.PhysicianDetails ? `${this.getWCDetails(ab.PhysicianDetails)?.AddendumFee || ''}` : '';
                    },
                }),
                new EntityListColumn({
                    name: 'WC Conference Call Fee',
                    accessorFunction: (ab: IAddressBookForList) => {
                        return ab.PhysicianDetails ? `${this.getWCDetails(ab.PhysicianDetails)?.ConferenceCallFee || ''}` : '';
                    },
                }),
                new EntityListColumn({
                    name: 'WC Full day testimony Fee',
                    accessorFunction: (ab: IAddressBookForList) => {
                        return ab.PhysicianDetails ? `${this.getWCDetails(ab.PhysicianDetails)?.FullDayTelephoneDepositionFee || ''}` : '';
                    },
                }),
                new EntityListColumn({
                    name: 'WC Half day testimony fee',
                    accessorFunction: (ab: IAddressBookForList) => {
                        return ab.PhysicianDetails ? `${this.getWCDetails(ab.PhysicianDetails)?.HalfDayTelephoneDepositionFee || ''}` : '';
                    },
                }),
                new EntityListColumn({
                    name: 'WC Virtual testimony fee',
                    accessorFunction: (ab: IAddressBookForList) => {
                        return ab.PhysicianDetails ? `${this.getWCDetails(ab.PhysicianDetails)?.VirtualTestimonyFee || ''}` : '';
                    },
                }),
                new EntityListColumn({
                    name: 'WC Full day telephone deposition fee',
                    accessorFunction: (ab: IAddressBookForList) => {
                        return ab.PhysicianDetails ? `${this.getWCDetails(ab.PhysicianDetails)?.FullDayTelephoneDepositionFee || ''}` : '';
                    },
                }),
                new EntityListColumn({
                    name: 'WC Half day telephone deposition fee',
                    accessorFunction: (ab: IAddressBookForList) => {
                        return ab.PhysicianDetails ? `${this.getWCDetails(ab.PhysicianDetails)?.HalfDayTelephoneDepositionFee || ''}` : '';
                    },
                }),

                new EntityListColumn({
                    name: 'Auto IME Consultation Fee',
                    accessorFunction: (ab: IAddressBookForList) => {
                        return ab.PhysicianDetails ? `${this.getAutoDetails(ab.PhysicianDetails)?.ImeExamConsultationFee || ''}` : '';
                    },
                }),
                new EntityListColumn({
                    name: 'Auto No Show Fee',
                    accessorFunction: (ab: IAddressBookForList) => {
                        return ab.PhysicianDetails ? `${this.getAutoDetails(ab.PhysicianDetails)?.NoShowFee || ''}` : '';
                    },
                }),
                new EntityListColumn({
                    name: 'Auto Hourly Fee',
                    accessorFunction: (ab: IAddressBookForList) => {
                        return ab.PhysicianDetails ? `${this.getAutoDetails(ab.PhysicianDetails)?.HourlyFee || ''}` : '';
                    },
                }),
                new EntityListColumn({
                    name: 'Auto Film Review Fee',
                    accessorFunction: (ab: IAddressBookForList) => {
                        return ab.PhysicianDetails ? `${this.getAutoDetails(ab.PhysicianDetails)?.FilmReviewFee || ''}` : '';
                    },
                }),
                new EntityListColumn({
                    name: 'Auto Additional Fee',
                    accessorFunction: (ab: IAddressBookForList) => {
                        return ab.PhysicianDetails ? `${this.getAutoDetails(ab.PhysicianDetails)?.AdditionalFee || ''}` : '';
                    },
                }),
                new EntityListColumn({
                    name: 'Auto Retainer',
                    accessorFunction: (ab: IAddressBookForList) => {
                        return ab.PhysicianDetails ? `${this.getAutoDetails(ab.PhysicianDetails)?.Retainer || ''}` : '';
                    },
                }),
                new EntityListColumn({
                    name: 'Auto Record Review Retainer',
                    accessorFunction: (ab: IAddressBookForList) => {
                        const physicianDetail = ab.PhysicianDetails && this.getAutoDetails(ab.PhysicianDetails);
                        return physicianDetail ? `${physicianDetail.RecordReviewRetainer || ''}` : '';
                    },
                }),
                new EntityListColumn({
                    name: 'Auto Additional Film Review Fee',
                    accessorFunction: (ab: IAddressBookForList) => {
                        return ab.PhysicianDetails ? `${this.getAutoDetails(ab.PhysicianDetails)?.AdditionalFilmReviewFee || ''}` : '';
                    },
                }),
                new EntityListColumn({
                    name: 'Auto Addendum Fee',
                    accessorFunction: (ab: IAddressBookForList) => {
                        const physicianDetail = ab.PhysicianDetails && this.getAutoDetails(ab.PhysicianDetails);
                        return physicianDetail ? `${physicianDetail.AddendumFee || ''}` : '';
                    },
                }),
                new EntityListColumn({
                    name: 'Auto Conference Call Fee',
                    accessorFunction: (ab: IAddressBookForList) => {
                        const physicianDetail = ab.PhysicianDetails && this.getAutoDetails(ab.PhysicianDetails);
                        return physicianDetail ? `${physicianDetail.ConferenceCallFee || ''}` : '';
                    },
                }),
                new EntityListColumn({
                    name: 'Auto Full day testimony Fee',
                    accessorFunction: (ab: IAddressBookForList) => {
                        return ab.PhysicianDetails ? `${this.getAutoDetails(ab.PhysicianDetails)?.FullDayTelephoneDepositionFee || ''}` : '';
                    },
                }),
                new EntityListColumn({
                    name: 'Auto Half day testimony fee',
                    accessorFunction: (ab: IAddressBookForList) => {
                        return ab.PhysicianDetails ? `${this.getAutoDetails(ab.PhysicianDetails)?.HalfDayTelephoneDepositionFee || ''}` : '';
                    },
                }),
                new EntityListColumn({
                    name: 'Auto Virtual testimony fee',
                    accessorFunction: (ab: IAddressBookForList) => {
                        return ab.PhysicianDetails ? `${this.getAutoDetails(ab.PhysicianDetails)?.VirtualTestimonyFee || ''}` : '';
                    },
                }),
                new EntityListColumn({
                    name: 'Auto Full day telephone deposition fee',
                    accessorFunction: (ab: IAddressBookForList) => {
                        return ab.PhysicianDetails ? `${this.getAutoDetails(ab.PhysicianDetails)?.FullDayTelephoneDepositionFee || ''}` : '';
                    },
                }),
                new EntityListColumn({
                    name: 'Auto Half day telephone deposition fee',
                    accessorFunction: (ab: IAddressBookForList) => {
                        return ab.PhysicianDetails ? `${this.getAutoDetails(ab.PhysicianDetails)?.HalfDayTelephoneDepositionFee || ''}` : '';
                    },
                }),
            ],
            rowClass: (entity: IAddressBooksForList) => (entity.FacilityArchived || entity.IndividualArchived ? 'archived' : null),
        };
        super(listConfig);
    }

    private getGLDetails(physicianDetails): IPhysicianDetail {
        return physicianDetails.find((pd) => pd.ImedClaimTypeId === ClaimTypeEnums.GL);
    }

    private getWCDetails(physicianDetails): IPhysicianDetail {
        return physicianDetails.find((pd) => pd.ImedClaimTypeId === ClaimTypeEnums.WC);
    }

    private getAutoDetails(physicianDetails): IPhysicianDetail {
        return physicianDetails.find((pd) => pd.ImedClaimTypeId === ClaimTypeEnums.Auto);
    }
}
