import { FollowUpTypes } from './../../../../../common/constants/Enums';
import { ImedClaimServiceFollowupDynamicControlsPartial } from '@model/partials/imed-claim-service-followup-partial.form-controls';
import { ImedClaimServiceService } from './../../../imedclaimservice.service';
import { DateTimeConverterService } from './../../../../../common/services/date-time-converter.service';
import { ImeAppointmentService } from './../../../service-specifics/ime-service/ime-appointments/ime-appointment.service';
import { IImedClaimService } from '@model/interfaces/imed-claim-service.d';
import { IImedClaimServiceFollowup } from '@model/interfaces/imed-claim-service-followup.d';
import { Component, Input, OnInit, ChangeDetectorRef, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { ImedClaimServiceFollowupService } from '../imedclaimservicefollowup.service';
import { ImedClaimServiceFollowupDynamicConfig } from '../imed-claim-service-followup.dynamic-config';
import { FollowupTypeService } from '../followuptype.service';
import { ClaimsService, ClaimValues, AuthService } from '@mt-ng2/auth-module';
import { BackButtonService } from '@mt-ng2/back-button-module';
import { IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { ServiceIdEnums } from '../../../../../common/constants/Enums';
import { IFollowupType } from '@model/interfaces/followup-type';
import { finalize } from 'rxjs/operators';
import { UserService } from '../../../../../users/user.service';
import { safeDetectChanges } from '../../../../../common/cdr-safety/cdr-safety.library';
import { IEmailTemplate } from '@model/interfaces/email-template';
import { CommonEmailComponent } from '../../../../../common/common-email/common-email.component';
import { CommonService } from '@common/services/common.service';
import { ClaimTypes } from '@model/ClaimTypes';

@Component({
    selector: 'app-imed-claim-service-followup-basic-info',
    templateUrl: './imed-claim-service-followup-basic-info.component.html',
})
export class ImedClaimServiceFollowupBasicInfoComponent implements OnInit, OnDestroy {
    @Input()
    imedClaimServiceFollowup: IImedClaimServiceFollowup;
    @Input()
    canEdit: boolean;
    imedClaimServiceModel: IImedClaimService;
    isEditing: boolean;
    isHovered: boolean;
    config: IDynamicFormConfig;
    serviceId: number;
    followUpTypeIdValue: number;
    imedClaimServiceFollowupForm: any;
    formFactory: ImedClaimServiceFollowupDynamicConfig<IImedClaimServiceFollowup>;
    doubleClickIsDisabled = false;
    renderAdditionalInfoComponent = false;
    renderReceivedAppointmentDateTime = false;
    renderReceiptOfNoticeOfImeConfirmedByName = false;
    renderLastAppointment = false;
    renderImeAppointmentConfirmedWithPlaintiffsAttorneyOfficeByName = false;
    isDate = false;
    form: UntypedFormGroup;
    abstractControls: any;
    missedAppointmentText: string;
    canFollowupBeCreated = true;
    formRendered = false;
    serviceIdFromRoute: number;
    userFullName: string;
    followupTypes: IFollowupType[];
    notes: string;
    notesMaxLength = 500;
    showNotesLength = false;

    renderFilmReviewPersonContactedOrSpokenTo = false;
    renderFilmReviewReportForwardedToClientDate = false;
    renderRecordReviewPersonContactedOrSpokenTo = false;
    renderCheckNumber = false;
    renderClearanceDate = false;
    renderReportDueBy = false;
    renderCommunicationMethod = false;
    renderConfirmedWithPhysiciansOffice = false;
    renderConfirmedCancellationWith = false;
    renderDeferredUntil = false;
    renderFollowUpAgainOn = false;
    renderReportReadyOnOrAround = false;
    renderPersonContactedOrSpokenTo = false;
    renderTrackingNumber = false;
    renderVia = false;
    followUpId: number;
    followUpTypeSubscription;
    renderAmount = false;
    canEditFollowupDate = false;
    updatedFollowupDate: Date;

    @ViewChild('emailComponent') emailComponent: CommonEmailComponent;
    showEmailControl = false;

    get notesCurrentLength(): number {
        if (this.imedClaimServiceFollowup && this.imedClaimServiceFollowup.Notes) {
            const textWithNoHTML = this.commonService.stripHtml(this.imedClaimServiceFollowup.Notes);
            return textWithNoHTML.length;
        } else {
            return 0;
        }
    }

    constructor(
        private imedClaimServiceFollowupService: ImedClaimServiceFollowupService,
        private followupTypeService: FollowupTypeService,
        private notificationsService: NotificationsService,
        private authService: AuthService,
        private router: Router,
        private userService: UserService,
        private route: ActivatedRoute,
        private backButtonService: BackButtonService,
        private cdr: ChangeDetectorRef,
        private fb: UntypedFormBuilder,
        private imeAppointmentService: ImeAppointmentService,
        private dateTimeConverterService: DateTimeConverterService,
        private imedClaimServiceService: ImedClaimServiceService,
        private commonService: CommonService,
        private claimsService: ClaimsService,
    ) {}

    ngOnInit(): void {
        this.canEditFollowupDate = this.claimsService.hasClaim(ClaimTypes.CanEditFollowupDate, [ClaimValues.FullAccess]);
        this.followUpTypeIdValue = this.imedClaimServiceFollowup.FollowupTypeId;
        this.form = this.fb.group({
            ImedClaimServiceFollowup: this.fb.group({
                FollowupTypeId: this.fb.control(this.followUpTypeIdValue),
            }),
        });
        this.serviceIdFromRoute = +this.route.snapshot.parent.parent.params.serviceId;
        this.serviceId =
            this.imedClaimServiceFollowup && this.imedClaimServiceFollowup.ImedClaimServiceId
                ? this.imedClaimServiceFollowup.ImedClaimServiceId
                : this.serviceIdFromRoute; // feels bad
        this.isEditing = false;
        this.config = { formObject: [], viewOnly: [] };

        this.imedClaimServiceService.getById(this.serviceId).subscribe((imedClaimService) => {
            this.imedClaimServiceModel = imedClaimService;
            forkJoin(
                this.followupTypeService.getAll(),
                this.imeAppointmentService.getLastAppointmentFromTodaysDate(this.serviceId),
                this.checkForFollowupCreation(),
                this.userService.getById(this.authService.currentUser.getValue().Id),
            ).subscribe((response) => {
                const [followupTypes, imeAppointment, canFollowupBeCreated, user] = response;
                this.canFollowupBeCreated = canFollowupBeCreated;
                if (imeAppointment) {
                    this.missedAppointmentText =
                        'Appointment missed on ' +
                        // eslint-disable-next-line @typescript-eslint/no-base-to-string
                        this.dateTimeConverterService.GetDateTimeForTimeZone(imeAppointment.AppointmentDateTime, imeAppointment.Timezone).toString();
                } else {
                    this.missedAppointmentText = 'Appointment missed on - NA';
                }
                this.followupTypes = followupTypes
                    .filter((item) => item.ServiceId === this.imedClaimServiceModel.ServiceId)
                    .sort((a, b) => (a.Name < b.Name ? -1 : 1));
                this.userFullName = user.FirstName + ' ' + user.LastName;
                this.setConfig();
            });
        });
    }

    ngOnDestroy(): void {
        this.cdr.detach();
    }

    checkForFollowupCreation(): Observable<boolean> {
        if (this.imedClaimServiceModel.ServiceId !== +ServiceIdEnums.Record_Retrievals_HIPAA_Processing && this.imedClaimServiceModel.ServiceId !== +ServiceIdEnums.Radiology_Received_From_Client) {
            return of(true);
        }
        return this.imedClaimServiceFollowupService.canFollowupBeCreatedForRecordRetreivalService(this.serviceId);
    }

    setConfig(): void {
        this.abstractControls = new ImedClaimServiceFollowupDynamicControlsPartial(
            this.imedClaimServiceFollowup,
            {
                followupTypes: this.followupTypes,
            },
            this.canEditFollowupDate,
        );

        this.formFactory = new ImedClaimServiceFollowupDynamicConfig<IImedClaimServiceFollowup>(
            this.imedClaimServiceFollowup,
            this.followupTypeService.items,
        );

        if (this.imedClaimServiceFollowup.Id === 0) {
            // new imedClaimServiceFollowup
            this.config = this.formFactory.getForCreate();
            this.edit();
        } else {
            // existing imedClaimServiceFollowup
            this.config = this.formFactory.getForUpdate();
            this.notes = this.imedClaimServiceFollowup.Notes;
        }
        this.assignRenderAdditionalInfo();
        this.formRendered = true;
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.imedClaimServiceFollowup.Id === 0) {
            this.backButtonService.goBack();
        } else {
            this.isEditing = false;
            this.followUpTypeIdValue = this.imedClaimServiceFollowup.FollowupTypeId;
            this.assignRenderAdditionalInfo();
        }
    }

    save(): void {
        if (this.form.valid) {
            if (this.notesCurrentLength <= this.notesMaxLength) {
                this.showEmailControl = true;
                this.formFactory.assignFormValues(
                    this.imedClaimServiceFollowup,
                    this.form.value.ImedClaimServiceFollowup as IImedClaimServiceFollowup,
                );
                if (this.updatedFollowupDate) {
                    this.imedClaimServiceFollowup.Date = this.updatedFollowupDate;
                }
                this.showNotesLength = false;
                this.formatDataForSave(); // sets required fields like createdBy
                if (!this.imedClaimServiceFollowup.Id || this.imedClaimServiceFollowup.Id === 0) {
                    // handle new imedClaimServiceFollowup save
                    this.imedClaimServiceFollowup.Date = this.followUpDateChanges(this.imedClaimServiceFollowup.Date);
                    this.imedClaimServiceFollowupService
                        .createFollowup(this.imedClaimServiceFollowup)
                        .subscribe(({ body: { Id, EmailTemplates } }) => {
                            this.followUpId = Id;
                            this.success();
                            this.imedClaimServiceFollowupService.emitChange(this.imedClaimServiceFollowup);
                            if (EmailTemplates && EmailTemplates.length > 0) {
                                this.handleEmailTemplate(EmailTemplates);
                            } else {
                                this.navigateToNewFollowup(this.followUpId);
                            }
                        });
                } else {
                    // handle existing imedClaimServiceFollowup save
                    this.imedClaimServiceFollowupService.update(this.imedClaimServiceFollowup).subscribe(() => {
                        this.isEditing = false;
                        this.success();
                        this.imedClaimServiceFollowupService.emitChange(this.imedClaimServiceFollowup);
                        this.setConfig();
                    });
                }
            } else {
                this.notificationsService.error('Maximum note length is 500 characters');
            }
        } else {
            markAllFormFieldsAsTouched(this.form);
            this.notificationsService.error('FollowUp Save Failed.');
        }
    }

    navigateToNewFollowup(newId: number): void {
        const url = this.router.url.replace('add', newId.toString());

        void this.router.navigateByUrl(url);
    }

    handleEmailTemplate(templates: IEmailTemplate[]): void {
        this.emailComponent.emailTemplates = templates;
        this.emailComponent.imedClaimServiceId = this.imedClaimServiceFollowup.ImedClaimServiceId;
        this.emailComponent.showEmailForm();
    }

    formatDataForSave(): void {
        if (this.imedClaimServiceFollowup.Id) {
            this.imedClaimServiceFollowup.ModifiedById = this.authService.currentUser.getValue().Id;
            this.imedClaimServiceFollowup.DateModified = new Date();
        } else {
            this.imedClaimServiceFollowup.CreatedById = this.authService.currentUser.getValue().Id;
            this.imedClaimServiceFollowup.DateCreated = new Date();
        }

        this.imedClaimServiceFollowup.ImedClaimServiceId = this.serviceId;
        this.imedClaimServiceFollowup.Archived = false;
        this.nullOutExtraneousFields();
    }

    followUpDateChanges(followUpDate: Date): any {
        this.updatedFollowupDate = new Date(followUpDate.getTime() + followUpDate.getTimezoneOffset() * 60000);
        this.updatedFollowupDate.setHours(new Date().getHours());
        this.updatedFollowupDate.setMinutes(new Date().getMinutes());
        this.updatedFollowupDate.setSeconds(new Date().getSeconds());
        return this.updatedFollowupDate;
    }

    nullOutExtraneousFields(): void {
        if (!this.renderAdditionalInfoComponent) {
            this.imedClaimServiceFollowup.ImeAppointmentConfirmedWithPlaintiffsAttorneyOfficeByName = null;
            this.imedClaimServiceFollowup.ReceiptOfNoticeOfImeConfirmedByName = null;
            this.imedClaimServiceFollowup.ReceivedAppointmentDateTime = null;
        } else if (
            this.imedClaimServiceFollowup.FollowupTypeId === +FollowUpTypes.IME_IME_appointment_confirmed_with_plaintiffs_attorneys_office ||
            this.imedClaimServiceFollowup.FollowupTypeId === +FollowUpTypes.IME_Re_Exam_IME_appointment_confirmed_with_plaintiffs_attorneys_office ||
            this.imedClaimServiceFollowup.FollowupTypeId ===
                +FollowUpTypes.IME_Expert_Consultation_IME_appointment_confirmed_with_plaintiffs_attorneys_office
        ) {
            this.imedClaimServiceFollowup.ReceivedAppointmentDateTime = null;
            this.imedClaimServiceFollowup.ReceiptOfNoticeOfImeConfirmedByName = null;
        } else if (this.imedClaimServiceFollowup.FollowupTypeId === +FollowUpTypes.IME_Receipt_of_notification_of_appointment_confirmed) {
            this.imedClaimServiceFollowup.ReceivedAppointmentDateTime = null;
            this.imedClaimServiceFollowup.ImeAppointmentConfirmedWithPlaintiffsAttorneyOfficeByName = null;
        } else if (this.imedClaimServiceFollowup.FollowupTypeId === +FollowUpTypes.IME_Appointment_scheduled) {
            this.imedClaimServiceFollowup.ImeAppointmentConfirmedWithPlaintiffsAttorneyOfficeByName = null;
            this.imedClaimServiceFollowup.ReceiptOfNoticeOfImeConfirmedByName = null;
        }
    }

    error(msg?: string): void {
        if (!msg) {
            this.notificationsService.error('Estimate save failed.');
        } else {
            this.notificationsService.error(msg);
        }
    }

    success(): void {
        this.notificationsService.success('FollowUp Saved Successfully');
    }

    assignRenderAdditionalInfo(): void {
        this.renderAdditionalInfoComponent =
            this.followUpTypeIdValue === +FollowUpTypes.IME_Appointment_scheduled ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Receipt_of_notification_of_appointment_confirmed ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_IME_appointment_confirmed_with_plaintiffs_attorneys_office ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Request_for_rescheduling_from_claimant ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Request_for_rescheduling_from_defense_counsel ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Request_for_rescheduling_from_physician ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Request_for_rescheduling_from_plaintiffs_attorney ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Claimant_failed_to_attend_as_scheduled ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Appointment_scheduled ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Receipt_of_notification_of_appointment_confirmed ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_IME_appointment_confirmed_with_plaintiffs_attorneys_office ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Request_for_rescheduling_from_claimant ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Request_for_rescheduling_from_defense_counsel ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Request_for_rescheduling_from_physician ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Request_for_rescheduling_from_plaintiffs_attorney ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Claimant_failed_to_attend_as_scheduled ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_Appointment_scheduled ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_Receipt_of_notification_of_appointment_confirmed ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_IME_appointment_confirmed_with_plaintiffs_attorneys_office ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_Request_for_rescheduling_from_claimant ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_Request_for_rescheduling_from_defense_counsel ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_Request_for_rescheduling_from_physician ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_Request_for_rescheduling_from_plaintiffs_attorney ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_Claimant_failed_to_attend_as_scheduled ||
            this.followUpTypeIdValue === +FollowUpTypes.Radiology_review_Report_forwarded_to_client ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Service_evaluated_for_further_follow_up ||
            this.followUpTypeIdValue === +FollowUpTypes.Record_Retrievals_HIPAA_Processing_Followed_up_with_facility_regarding_records_requested ||
            this.followUpTypeIdValue === +FollowUpTypes.Record_Retrievals_HIPAA_Processing_Bill_paid_pending_check_clearance ||
            this.followUpTypeIdValue === +FollowUpTypes.RadiologyReceivedFromClient_Followed_Up_With_Facility_Regarding_Records_Requested ||
            this.followUpTypeIdValue === +FollowUpTypes.RadiologyReceivedFromClient_Bill_Paid_Pending_Check_Clearance ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Check_cleared ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Check_cleared ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_Check_cleared ||
            this.followUpTypeIdValue === +FollowUpTypes.Record_Retrievals_HIPAA_Processing_Check_cleared ||
            this.followUpTypeIdValue === +FollowUpTypes.Record_Retrievals_HIPAA_Processing_Records_forwarded_to_client ||
            this.followUpTypeIdValue === +FollowUpTypes.RadiologyReceivedFromClient_Check_Cleared ||
            this.followUpTypeIdValue === +FollowUpTypes.RadiologyReceivedFromClient_Records_Forwarded_To_Client ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Defense_counsel_has_requested_an_extended_deferment ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Defense_counsel_has_requested_an_extended_deferment ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_Defense_counsel_has_requested_an_extended_deferment ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Appointment_scheduled ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Appointment_scheduled ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_Appointment_scheduled ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Defense_counsel_has_requested_deferment_of_notification_of_appointment ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Defense_counsel_has_requested_deferment_of_notification_of_appointment ||
            this.followUpTypeIdValue ===
                +FollowUpTypes.IME_Expert_Consultation_Defense_counsel_has_requested_deferment_of_notification_of_appointment ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Records_compiled_and_forwarded_to_physician__expert ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Addendum_Records_compiled_and_forwarded_to_physician__expert ||
            this.followUpTypeIdValue === +FollowUpTypes.Record_review_Records_compiled_and_forwarded_to_physician__expert ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Records_compiled_and_forwarded_to_physician__expert ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_Records_compiled_and_forwarded_to_physician__expert ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Conference_Call_Records_compiled_and_forwarded_to_physician__expert ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Addendum_Medical_records_forwarded_to_physician_again_as_they_were_not_received ||
            this.followUpTypeIdValue ===
                +FollowUpTypes.IME_Expert_Consultation_Medical_records_forwarded_to_physician_again_as_they_were_not_received ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Medical_records_forwarded_to_physician_again_as_they_were_not_received ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Medical_records_forwarded_to_physician_again_as_they_were_not_received ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Medical_records_forwarded_to_physician_again_as_they_were_not_received ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Medical_records_confirmed_received_by_physicians_office ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Medical_records_confirmed_received_by_physicians_office ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Addendum_Medical_records_confirmed_received_by_physicians_office ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_Medical_records_confirmed_received_by_physicians_office ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_IME_appointment_confirmed_with_plaintiffs_attorneys_office ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_IME_appointment_confirmed_with_plaintiffs_attorneys_office ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_IME_appointment_confirmed_with_plaintiffs_attorneys_office ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Attendance_at_examination_confirmed ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Attendance_at_examination_confirmed ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Pending_additional_records_report_will_be_held_pending_receipt ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Pending_additional_records_report_will_be_held_pending_receipt ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Addendum_Pending_additional_records_report_will_be_held_pending_receipt ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_Pending_additional_records_report_will_be_held_pending_receipt ||
            this.followUpTypeIdValue === +FollowUpTypes.Record_review_Pending_additional_records_report_will_be_held_pending_receipt ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Addendum_Pending_additional_records_report_will_be_finalized_and_addendum_requested ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Pending_additional_records_report_will_be_finalized_and_addendum_requested ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Pending_additional_records_report_will_be_finalized_and_addendum_requested ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Addendum_Pending_additional_records_report_will_be_finalized_and_addendum_requested ||
            this.followUpTypeIdValue === +FollowUpTypes.Record_review_Pending_additional_records_report_will_be_finalized_and_addendum_requested ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Physican_has_advised_that_report_will_be_ready_on_date_specified ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Physican_has_advised_that_report_will_be_ready_on_date_specified ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Addendum_Physican_has_advised_that_report_will_be_ready_on_date_specified ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_Physican_has_advised_that_report_will_be_ready_on_date_specified ||
            this.followUpTypeIdValue === +FollowUpTypes.Radiology_review_Physican_has_advised_that_report_will_be_ready_on_date_specified ||
            this.followUpTypeIdValue === +FollowUpTypes.Record_review_Physican_has_advised_that_report_will_be_ready_on_date_specified ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Prepayment_processed_pending_check_clearance ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Prepayment_processed_pending_check_clearance ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_Prepayment_processed_pending_check_clearance ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Payment_processed_pending_check_clearance ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Payment_processed_pending_check_clearance ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_Payment_processed_pending_check_clearance ||
            this.followUpTypeIdValue === +FollowUpTypes.Radiology_review_Films_copied_and_forwarded_to_physician ||
            this.followUpTypeIdValue === +FollowUpTypes.Radiology_review_Original_films_forwarded_to_physician ||
            this.followUpTypeIdValue === +FollowUpTypes.Radiology_review_Films_confirmed_received_by_physicians_office ||
            this.followUpTypeIdValue === +FollowUpTypes.Radiology_review_Original_films_forwarded_to_physician ||
            this.followUpTypeIdValue === +FollowUpTypes.CRS_Full_Day_Testimony_Testimony_paid ||
            this.followUpTypeIdValue === +FollowUpTypes.CRS_Half_Day_Testimony_Testimony_paid ||
            this.followUpTypeIdValue === +FollowUpTypes.CRS_Deposition_Testimony_paid ||
            this.followUpTypeIdValue === +FollowUpTypes.CRS_Full_Day_Testimony_Testimony_cancelled ||
            this.followUpTypeIdValue === +FollowUpTypes.CRS_Full_Day_Testimony_Testimony_cancelled ||
            this.followUpTypeIdValue === +FollowUpTypes.CRS_Deposition_Testimony_cancelled ||
            this.followUpTypeIdValue === +FollowUpTypes.CRS_Conference_Call__Confirmation_that_call_was_completed ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Service_evaluated_for_further_follow_up ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Service_evaluated_for_further_follow_up ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Addendum_Service_evaluated_for_further_follow_up ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_Service_evaluated_for_further_follow_up ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Conference_Call_Service_evaluated_for_further_follow_up ||
            this.followUpTypeIdValue === +FollowUpTypes.Billing__desk_review_Service_evaluated_for_further_follow_up ||
            this.followUpTypeIdValue === +FollowUpTypes.Record_review_Service_evaluated_for_further_follow_up ||
            this.followUpTypeIdValue === +FollowUpTypes.CRS_Conference_Call__Service_evaluated_for_further_follow_up ||
            this.followUpTypeIdValue === +FollowUpTypes.CRS_Deposition__Service_evaluated_for_further_follow_up ||
            this.followUpTypeIdValue === +FollowUpTypes.CRS_Full_Day_Testimony__Service_evaluated_for_further_follow_up ||
            this.followUpTypeIdValue === +FollowUpTypes.CRS_Half_Day_Testimony__Service_evaluated_for_further_follow_up ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Fee_approved_and_forwarded_to_accounting_ ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_ReExam_Fee_approved_and_forwarded_to_accounting ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Addendum_Fee_approved_and_forwarded_to_accounting ||
            this.followUpTypeIdValue === +FollowUpTypes.Billing__desk_review_Fee_approved_and_forwarded_to_accounting ||
            this.followUpTypeIdValue === +FollowUpTypes.Record_review_Fee_approved_and_forwarded_to_accounting ||
            this.followUpTypeIdValue === +FollowUpTypes.Radiology_review_Fee_approved_and_forwarded_to_accounting_ ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Conference_Call_Fee_approved_and_forwarded_to_accounting ||
            this.followUpTypeIdValue === +FollowUpTypes.CRS_Conference_Call__Fee_approved_and_forwarded_to_accounting ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_Fee_approved_and_forwarded_to_accounting_ ||
            this.followUpTypeIdValue === +FollowUpTypes.Record_Retrievals_HIPAA_Processing_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.RadiologyReceivedFromClient_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.Independent_Medical_Examination_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.Radiology_review_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.Record_review_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.Billing_desk_review_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.Full_Day_Testimony_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.Half_Day_Testimony_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.Deposition_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.Conference_Call_between_attorney_physician_or_expert_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.Re_Examination_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.Addendum_Additional_Records_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.Expert_Consultation_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.Conference_Call_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.WC_Independent_Medical_Examination_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.No_Fault_PIP_Independent_Medical_Examination_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.WC_Record_Review_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.No_Fault_PIP_Record_Review_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.WC_Re_Examination_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.WC_Radiology_Review_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.WC_Functional_Capacity_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.No_Fault_PIP_Peer_Review_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.WC_Addendum_Additional_Records_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.WC_Conference_Call_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.No_Fault_PIP_Radiology_Review_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.No_Fault_PIP_Addendum_Additional_Records_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.No_Fault_PIP_Conference_Call_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.No_Fault_PIP_Re_Examination_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.No_Fault_PIP_NF10_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.Record_Retrievals_HIPAA_Processing_Retrieval_documents_processed_and_submitted ||
            this.followUpTypeIdValue === +FollowUpTypes.Record_Retrievals_HIPAA_Processing_Retrieval_documents_resubmitted ||
            this.followUpTypeIdValue === +FollowUpTypes.Record_Retrievals_HIPAA_Invoice_forwarded_to_client ||
            this.followUpTypeIdValue === +FollowUpTypes.RadiologyReceivedFromClient_Retrieval_Documents_Processed_And_Submitted ||
            this.followUpTypeIdValue === +FollowUpTypes.RadiologyReceivedFromClient_Retrieval_Documents_Resubmitted ||
            this.followUpTypeIdValue === +FollowUpTypes.RadiologyReceivedFromClient_Invoice_Forwarded_To_Client ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Contacted_physicians_office_to_confirm_receipt_of_records ||
            this.followUpTypeIdValue === +FollowUpTypes.Radiology_Review_Contacted_physicians_office_to_confirm_receipt_of_records ||
            this.followUpTypeIdValue === +FollowUpTypes.Record_Review_Contacted_physicians_office_to_confirm_receipt_of_records ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Contacted_physicians_office_to_confirm_receipt_of_records ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_Contacted_physicians_office_to_confirm_receipt_of_records ||
            this.followUpTypeIdValue === +FollowUpTypes.WC_IME_Contacted_physicians_office_to_confirm_receipt_of_records ||
            this.followUpTypeIdValue === +FollowUpTypes.Auto_IME_Contacted_physicians_office_to_confirm_receipt_of_records ||
            this.followUpTypeIdValue === +FollowUpTypes.WC_Record_Review_Contacted_physicians_office_to_confirm_receipt_of_records ||
            this.followUpTypeIdValue === +FollowUpTypes.Auto_Record_Review_Contacted_physicians_office_to_confirm_receipt_of_records ||
            this.followUpTypeIdValue === +FollowUpTypes.WC_IME_Re_Exam_Contacted_physicians_office_to_confirm_receipt_of_records ||
            this.followUpTypeIdValue === +FollowUpTypes.Auto_IME_Re_Exam_Contacted_physicians_office_to_confirm_receipt_of_records ||
            this.followUpTypeIdValue === +FollowUpTypes.WC_Radiology_Review_Contacted_physicians_office_to_confirm_receipt_of_records ||
            this.followUpTypeIdValue === +FollowUpTypes.Auto_Radiology_Review_Contacted_physicians_office_to_confirm_receipt_of_records ||
            this.followUpTypeIdValue === +FollowUpTypes.Auto_Peer_Review_Contacted_physicians_office_to_confirm_receipt_of_records ||
            this.followUpTypeIdValue === +FollowUpTypes.WC_IME_Appointment_scheduled ||
            this.followUpTypeIdValue === +FollowUpTypes.WC_IME_Re_Exam_Appointment_scheduled ||
            this.followUpTypeIdValue === +FollowUpTypes.Auto_IME_Appointment_scheduled ||
            this.followUpTypeIdValue === +FollowUpTypes.Auto_IME_Re_Exam_Appointment_scheduled ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Appointment_rescheduled ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Appointment_rescheduled ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_Appointment_rescheduled ||
            this.followUpTypeIdValue === +FollowUpTypes.WC_IME_Appointment_rescheduled ||
            this.followUpTypeIdValue === +FollowUpTypes.WC_IME_Re_Exam_Appointment_rescheduled ||
            this.followUpTypeIdValue === +FollowUpTypes.Auto_IME_Appointment_rescheduled ||
            this.followUpTypeIdValue === +FollowUpTypes.Auto_IME_Re_Exam_Appointment_rescheduled;

        this.renderReceiptOfNoticeOfImeConfirmedByName =
            this.followUpTypeIdValue === +FollowUpTypes.IME_Receipt_of_notification_of_appointment_confirmed ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Prepayment_processed_pending_check_clearance ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_Receipt_of_notification_of_appointment_confirmed;

        this.renderImeAppointmentConfirmedWithPlaintiffsAttorneyOfficeByName =
            this.followUpTypeIdValue === +FollowUpTypes.IME_IME_appointment_confirmed_with_plaintiffs_attorneys_office ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_IME_appointment_confirmed_with_plaintiffs_attorneys_office ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_IME_appointment_confirmed_with_plaintiffs_attorneys_office;

        this.renderReceivedAppointmentDateTime =
            this.followUpTypeIdValue === +FollowUpTypes.IME_Appointment_scheduled ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Appointment_scheduled ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_Appointment_scheduled ||
            this.followUpTypeIdValue === +FollowUpTypes.WC_IME_Appointment_scheduled ||
            this.followUpTypeIdValue === +FollowUpTypes.WC_IME_Re_Exam_Appointment_scheduled ||
            this.followUpTypeIdValue === +FollowUpTypes.Auto_IME_Appointment_scheduled ||
            this.followUpTypeIdValue === +FollowUpTypes.Auto_IME_Re_Exam_Appointment_scheduled ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Appointment_rescheduled ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Appointment_rescheduled ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_Appointment_rescheduled ||
            this.followUpTypeIdValue === +FollowUpTypes.WC_IME_Appointment_rescheduled ||
            this.followUpTypeIdValue === +FollowUpTypes.WC_IME_Re_Exam_Appointment_rescheduled ||
            this.followUpTypeIdValue === +FollowUpTypes.Auto_IME_Appointment_rescheduled ||
            this.followUpTypeIdValue === +FollowUpTypes.Auto_IME_Re_Exam_Appointment_rescheduled;

        this.renderLastAppointment =
            this.followUpTypeIdValue === +FollowUpTypes.IME_Claimant_failed_to_attend_as_scheduled ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Claimant_failed_to_attend_as_scheduled ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_Claimant_failed_to_attend_as_scheduled;

        this.renderFilmReviewReportForwardedToClientDate = this.followUpTypeIdValue === +FollowUpTypes.Radiology_review_Report_forwarded_to_client;

        this.renderCheckNumber =
            this.followUpTypeIdValue === +FollowUpTypes.Record_Retrievals_HIPAA_Processing_Bill_paid_pending_check_clearance ||
            this.followUpTypeIdValue === +FollowUpTypes.RadiologyReceivedFromClient_Bill_Paid_Pending_Check_Clearance ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Prepayment_processed_pending_check_clearance ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Payment_processed_pending_check_clearance ||
            this.followUpTypeIdValue === +FollowUpTypes.CRS_Full_Day_Testimony_Testimony_paid ||
            this.followUpTypeIdValue === +FollowUpTypes.CRS_Half_Day_Testimony_Testimony_paid ||
            this.followUpTypeIdValue === +FollowUpTypes.CRS_Deposition_Testimony_paid ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Prepayment_processed_pending_check_clearance ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Payment_processed_pending_check_clearance ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_Prepayment_processed_pending_check_clearance ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_Payment_processed_pending_check_clearance;

        this.renderClearanceDate =
            this.followUpTypeIdValue === +FollowUpTypes.IME_Check_cleared ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_Check_cleared ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_Check_cleared ||
            this.followUpTypeIdValue === +FollowUpTypes.Record_Retrievals_HIPAA_Processing_Check_cleared ||
            this.followUpTypeIdValue === +FollowUpTypes.RadiologyReceivedFromClient_Check_Cleared;

        this.renderCommunicationMethod =
            this.followUpTypeIdValue === +FollowUpTypes.Record_Retrievals_HIPAA_Processing_Followed_up_with_facility_regarding_records_requested ||
            this.followUpTypeIdValue === +FollowUpTypes.Record_Retrievals_HIPAA_Processing_Retrieval_documents_processed_and_submitted ||
            this.followUpTypeIdValue === +FollowUpTypes.Record_Retrievals_HIPAA_Processing_Retrieval_documents_resubmitted ||
            this.followUpTypeIdValue === +FollowUpTypes.RadiologyReceivedFromClient_Followed_Up_With_Facility_Regarding_Records_Requested ||
            this.followUpTypeIdValue === +FollowUpTypes.RadiologyReceivedFromClient_Retrieval_Documents_Processed_And_Submitted ||
            this.followUpTypeIdValue === +FollowUpTypes.RadiologyReceivedFromClient_Retrieval_Documents_Resubmitted ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Contacted_physicians_office_to_confirm_receipt_of_records ||
            this.followUpTypeIdValue === +FollowUpTypes.Radiology_Review_Contacted_physicians_office_to_confirm_receipt_of_records ||
            this.followUpTypeIdValue === +FollowUpTypes.Record_Review_Contacted_physicians_office_to_confirm_receipt_of_records ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Contacted_physicians_office_to_confirm_receipt_of_records ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_Contacted_physicians_office_to_confirm_receipt_of_records ||
            this.followUpTypeIdValue === +FollowUpTypes.WC_IME_Contacted_physicians_office_to_confirm_receipt_of_records ||
            this.followUpTypeIdValue === +FollowUpTypes.Auto_IME_Contacted_physicians_office_to_confirm_receipt_of_records ||
            this.followUpTypeIdValue === +FollowUpTypes.WC_Record_Review_Contacted_physicians_office_to_confirm_receipt_of_records ||
            this.followUpTypeIdValue === +FollowUpTypes.Auto_Record_Review_Contacted_physicians_office_to_confirm_receipt_of_records ||
            this.followUpTypeIdValue === +FollowUpTypes.WC_IME_Re_Exam_Contacted_physicians_office_to_confirm_receipt_of_records ||
            this.followUpTypeIdValue === +FollowUpTypes.Auto_IME_Re_Exam_Contacted_physicians_office_to_confirm_receipt_of_records ||
            this.followUpTypeIdValue === +FollowUpTypes.WC_Radiology_Review_Contacted_physicians_office_to_confirm_receipt_of_records ||
            this.followUpTypeIdValue === +FollowUpTypes.Auto_Radiology_Review_Contacted_physicians_office_to_confirm_receipt_of_records ||
            this.followUpTypeIdValue === +FollowUpTypes.Auto_Peer_Review_Contacted_physicians_office_to_confirm_receipt_of_records;

        this.renderConfirmedCancellationWith =
            this.followUpTypeIdValue === +FollowUpTypes.CRS_Deposition_Testimony_cancelled ||
            this.followUpTypeIdValue === +FollowUpTypes.CRS_Full_Day_Testimony_Testimony_cancelled ||
            this.followUpTypeIdValue === +FollowUpTypes.CRS_Half_Day_Testimony_Testimony_cancelled;

        this.renderConfirmedWithPhysiciansOffice =
            this.followUpTypeIdValue === +FollowUpTypes.IME_Pending_additional_records_report_will_be_held_pending_receipt ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Pending_additional_records_report_will_be_finalized_and_addendum_requested ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Pending_additional_records_report_will_be_held_pending_receipt ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Pending_additional_records_report_will_be_finalized_and_addendum_requested ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Addendum_Pending_additional_records_report_will_be_held_pending_receipt ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Addendum_Pending_additional_records_report_will_be_finalized_and_addendum_requested ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_Pending_additional_records_report_will_be_held_pending_receipt ||
            this.followUpTypeIdValue ===
                +FollowUpTypes.IME_Expert_Consultation_Pending_additional_records_report_will_be_finalized_and_addendum_requested ||
            this.followUpTypeIdValue === +FollowUpTypes.Record_review_Pending_additional_records_report_will_be_held_pending_receipt ||
            this.followUpTypeIdValue === +FollowUpTypes.Record_review_Pending_additional_records_report_will_be_finalized_and_addendum_requested;

        this.renderDeferredUntil =
            this.followUpTypeIdValue === +FollowUpTypes.IME_Defense_counsel_has_requested_an_extended_deferment ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Defense_counsel_has_requested_an_extended_deferment ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_Defense_counsel_has_requested_an_extended_deferment ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Defense_counsel_has_requested_deferment_of_notification_of_appointment ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Defense_counsel_has_requested_deferment_of_notification_of_appointment ||
            this.followUpTypeIdValue ===
                +FollowUpTypes.IME_Expert_Consultation_Defense_counsel_has_requested_deferment_of_notification_of_appointment;

        this.renderReportReadyOnOrAround =
            this.followUpTypeIdValue === +FollowUpTypes.IME_Physican_has_advised_that_report_will_be_ready_on_date_specified ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Physican_has_advised_that_report_will_be_ready_on_date_specified ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_Physican_has_advised_that_report_will_be_ready_on_date_specified ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Addendum_Physican_has_advised_that_report_will_be_ready_on_date_specified ||
            this.followUpTypeIdValue === +FollowUpTypes.Radiology_review_Physican_has_advised_that_report_will_be_ready_on_date_specified ||
            this.followUpTypeIdValue === +FollowUpTypes.Record_review_Physican_has_advised_that_report_will_be_ready_on_date_specified;

        this.renderPersonContactedOrSpokenTo =
            this.followUpTypeIdValue === +FollowUpTypes.IME_Claimant_failed_to_attend_as_scheduled ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Request_for_rescheduling_from_claimant ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Request_for_rescheduling_from_defense_counsel ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Request_for_rescheduling_from_physician ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Request_for_rescheduling_from_plaintiffs_attorney ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Claimant_failed_to_attend_as_scheduled ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Request_for_rescheduling_from_claimant ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Request_for_rescheduling_from_defense_counsel ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Request_for_rescheduling_from_physician ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Request_for_rescheduling_from_plaintiffs_attorney ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_Claimant_failed_to_attend_as_scheduled ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_Request_for_rescheduling_from_claimant ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_Request_for_rescheduling_from_defense_counsel ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_Request_for_rescheduling_from_physician ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_Request_for_rescheduling_from_plaintiffs_attorney ||
            this.followUpTypeIdValue === +FollowUpTypes.Radiology_review_Films_confirmed_received_by_physicians_office ||
            this.followUpTypeIdValue === +FollowUpTypes.Radiology_review_Films_forwarded_to_physician_again_as_they_were_not_received ||
            this.followUpTypeIdValue === +FollowUpTypes.Record_review_Medical_records_confirmed_received_by_physicians_office_by_physicians_office ||
            this.followUpTypeIdValue === +FollowUpTypes.Record_review_Medical_records_forwarded_to_physician_again_as_they_were_not_received ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Addendum_Medical_records_forwarded_to_physician_again_as_they_were_not_received ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Addendum_Medical_records_confirmed_received_by_physicians_office;

        this.renderTrackingNumber =
            this.followUpTypeIdValue === +FollowUpTypes.Radiology_review_Films_copied_and_forwarded_to_physician ||
            this.followUpTypeIdValue === +FollowUpTypes.Radiology_review_Original_films_forwarded_to_physician;

        this.renderVia =
            this.followUpTypeIdValue === +FollowUpTypes.Record_Retrievals_HIPAA_Processing_Records_forwarded_to_client ||
            this.followUpTypeIdValue === +FollowUpTypes.RadiologyReceivedFromClient_Records_Forwarded_To_Client ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Records_compiled_and_forwarded_to_physician__expert ||
            this.followUpTypeIdValue === +FollowUpTypes.Record_review_Records_compiled_and_forwarded_to_physician__expert ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_Records_compiled_and_forwarded_to_physician__expert ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Records_compiled_and_forwarded_to_physician__expert ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Addendum_Records_compiled_and_forwarded_to_physician__expert ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Conference_Call_Records_compiled_and_forwarded_to_physician__expert;

        this.renderAmount =
            this.followUpTypeIdValue === +FollowUpTypes.IME_Fee_approved_and_forwarded_to_accounting_ ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_ReExam_Fee_approved_and_forwarded_to_accounting ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Addendum_Fee_approved_and_forwarded_to_accounting ||
            this.followUpTypeIdValue === +FollowUpTypes.Billing__desk_review_Fee_approved_and_forwarded_to_accounting ||
            this.followUpTypeIdValue === +FollowUpTypes.Record_review_Fee_approved_and_forwarded_to_accounting ||
            this.followUpTypeIdValue === +FollowUpTypes.Radiology_review_Fee_approved_and_forwarded_to_accounting_ ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Conference_Call_Fee_approved_and_forwarded_to_accounting ||
            this.followUpTypeIdValue === +FollowUpTypes.CRS_Conference_Call__Fee_approved_and_forwarded_to_accounting ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_Fee_approved_and_forwarded_to_accounting_;

        this.renderReportDueBy =
            this.followUpTypeIdValue === +FollowUpTypes.Record_Retrievals_HIPAA_Processing_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.RadiologyReceivedFromClient_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.Independent_Medical_Examination_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.Radiology_review_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.Record_review_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.Billing_desk_review_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.Full_Day_Testimony_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.Half_Day_Testimony_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.Deposition_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.Conference_Call_between_attorney_physician_or_expert_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.Re_Examination_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.Addendum_Additional_Records_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.Expert_Consultation_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.Conference_Call_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.WC_Independent_Medical_Examination_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.No_Fault_PIP_Independent_Medical_Examination_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.WC_Record_Review_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.No_Fault_PIP_Record_Review_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.WC_Re_Examination_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.WC_Radiology_Review_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.WC_Functional_Capacity_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.No_Fault_PIP_Peer_Review_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.WC_Addendum_Additional_Records_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.WC_Conference_Call_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.No_Fault_PIP_Radiology_Review_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.No_Fault_PIP_Addendum_Additional_Records_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.No_Fault_PIP_Conference_Call_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.No_Fault_PIP_Re_Examination_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.No_Fault_PIP_NF10_Report_Due_By ||
            this.followUpTypeIdValue === +FollowUpTypes.Independent_Radiology_Review_Report_Due_By;

        safeDetectChanges(this.cdr);
    }

    followUpTypeChanges(value: number): void {
        this.followUpTypeIdValue = value;
        this.assignRenderAdditionalInfo();
    }

    getAppointmentTitle(): string {
        let result = '';
        const requestString = 'Request for rescheduling from ';
        let requesteeString = '';
        const claimantFailedToAttendString = 'Claimant failed to attend as scheduled';
        if (
            this.followUpTypeIdValue === +FollowUpTypes.IME_Request_for_rescheduling_from_claimant ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Request_for_rescheduling_from_claimant ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_Request_for_rescheduling_from_claimant
        ) {
            requesteeString = 'claimant';
            result = requestString + requesteeString;
        } else if (
            this.followUpTypeIdValue === +FollowUpTypes.IME_Request_for_rescheduling_from_defense_counsel ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Request_for_rescheduling_from_defense_counsel ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_Request_for_rescheduling_from_defense_counsel
        ) {
            requesteeString = 'defense counsel';
            result = requestString + requesteeString;
        } else if (
            this.followUpTypeIdValue === +FollowUpTypes.IME_Request_for_rescheduling_from_physician ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Request_for_rescheduling_from_physician ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_Request_for_rescheduling_from_physician
        ) {
            requesteeString = 'physician';
            result = requestString + requesteeString;
        } else if (
            this.followUpTypeIdValue === +FollowUpTypes.IME_Request_for_rescheduling_from_plaintiffs_attorney ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Request_for_rescheduling_from_plaintiffs_attorney ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_Request_for_rescheduling_from_plaintiffs_attorney
        ) {
            requesteeString = "plaintiff's attorney";
            result = requestString + requesteeString;
        } else if (
            this.followUpTypeIdValue === +FollowUpTypes.IME_Claimant_failed_to_attend_as_scheduled ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Re_Exam_Claimant_failed_to_attend_as_scheduled ||
            this.followUpTypeIdValue === +FollowUpTypes.IME_Expert_Consultation_Claimant_failed_to_attend_as_scheduled
        ) {
            result = claimantFailedToAttendString;
        }
        return result;
    }
}
