// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.invalid {
  border-color: red;
}
.error {
  color: red;
  font-size: 10px;
}
textarea {
  resize: none;
}
.panel-body {
  border: 1px solid #ddd;
  border-top: none;
}
.merging {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(68, 68, 68, 0.3);
  top: 0;
  left: 0;
  z-index: 9999;
  padding: 1.25em;
  border-radius: 0.3125em;
}
.generating {
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 100%;
  font-size: 50px;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.template-select {
  max-width: 100%;
  margin: auto;
}
`, "",{"version":3,"sources":["webpack://./src/app/common/base-fax-email/base-fax-email.component.less"],"names":[],"mappings":"AAAA;EACI,iBAAA;AACJ;AACA;EACI,UAAA;EACA,eAAA;AACJ;AACA;EACI,YAAA;AACJ;AACA;EACI,sBAAA;EACA,gBAAA;AACJ;AACA;EACI,kBAAA;EACA,YAAA;EACA,WAAA;EACA,iCAAA;EACA,MAAA;EACA,OAAA;EACA,aAAA;EACA,eAAA;EACA,uBAAA;AACJ;AACA;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,aAAA;EACA,eAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;AACJ;AACA;EACI,eAAA;EACA,YAAA;AACJ","sourcesContent":[".invalid {\n    border-color: red;\n}\n.error {\n    color: red;\n    font-size: 10px;\n}\ntextarea {\n    resize: none;\n}\n.panel-body {\n    border: 1px solid #ddd;\n    border-top: none;\n}\n.merging {\n    position: absolute;\n    height: 100%;\n    width: 100%;\n    background: rgba(68, 68, 68, 0.3);\n    top: 0;\n    left: 0;\n    z-index: 9999;\n    padding: 1.25em;\n    border-radius: 0.3125em;\n}\n.generating {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    opacity: 100%;\n    font-size: 50px;\n    align-items: center;\n    justify-content: center;\n    text-align: center;\n}\n.template-select {\n    max-width: 100%;\n    margin: auto;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
