// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            @media only screen and (min-width: 992px) {
                #verticalArrows {
                    display: none;
                }
            }
        `, "",{"version":3,"sources":["webpack://./src/app/imed-claims/imed-claim-merge-cases/imed-claim-merge-cases.component.ts"],"names":[],"mappings":";YACY;gBACI;oBACI,aAAa;gBACjB;YACJ","sourcesContent":["\n            @media only screen and (min-width: 992px) {\n                #verticalArrows {\n                    display: none;\n                }\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
