import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { IExpandableObject } from '../expandable-object';
import { IMasterFollowupType } from '../interfaces/master-followup-type';

export interface IMasterFollowupTypeDynamicControlsParameters {
    formGroup?: string;
}

export class MasterFollowupTypeDynamicControls {

    formGroup: string;

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private masterfollowuptype?: IMasterFollowupType, additionalParameters?: IMasterFollowupTypeDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'MasterFollowupType';

        this.Form = {
            Archived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived',
                name: 'Archived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.masterfollowuptype && this.masterfollowuptype.hasOwnProperty('Archived') && this.masterfollowuptype.Archived !== null ? this.masterfollowuptype.Archived : false,
            }),
            Auto: new DynamicField({
                formGroup: this.formGroup,
                label: 'Auto',
                name: 'Auto',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.masterfollowuptype && this.masterfollowuptype.hasOwnProperty('Auto') && this.masterfollowuptype.Auto !== null ? this.masterfollowuptype.Auto : false,
            }),
            GeneralLiability: new DynamicField({
                formGroup: this.formGroup,
                label: 'General Liability',
                name: 'GeneralLiability',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.masterfollowuptype && this.masterfollowuptype.hasOwnProperty('GeneralLiability') && this.masterfollowuptype.GeneralLiability !== null ? this.masterfollowuptype.GeneralLiability : false,
            }),
            IsCaseFollowup: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Case Followup',
                name: 'IsCaseFollowup',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.masterfollowuptype && this.masterfollowuptype.hasOwnProperty('IsCaseFollowup') && this.masterfollowuptype.IsCaseFollowup !== null ? this.masterfollowuptype.IsCaseFollowup : false,
            }),
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(250) ],
                validators: { 'required': true, 'maxlength': 250 },
                value: this.masterfollowuptype && this.masterfollowuptype.hasOwnProperty('Name') && this.masterfollowuptype.Name !== null ? this.masterfollowuptype.Name.toString() : '',
            }),
            ShowNotesOnClientPortal: new DynamicField({
                formGroup: this.formGroup,
                label: 'Show Notes On Client Portal',
                name: 'ShowNotesOnClientPortal',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.masterfollowuptype && this.masterfollowuptype.hasOwnProperty('ShowNotesOnClientPortal') && this.masterfollowuptype.ShowNotesOnClientPortal !== null ? this.masterfollowuptype.ShowNotesOnClientPortal : false,
            }),
            WorkersComp: new DynamicField({
                formGroup: this.formGroup,
                label: 'Workers Comp',
                name: 'WorkersComp',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.masterfollowuptype && this.masterfollowuptype.hasOwnProperty('WorkersComp') && this.masterfollowuptype.WorkersComp !== null ? this.masterfollowuptype.WorkersComp : false,
            }),
        };

        this.View = {
            Archived: new DynamicLabel({
			    label: 'Archived',
			    value: this.masterfollowuptype && this.masterfollowuptype.hasOwnProperty('Archived') && this.masterfollowuptype.Archived !== null ? this.masterfollowuptype.Archived : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Auto: new DynamicLabel({
			    label: 'Auto',
			    value: this.masterfollowuptype && this.masterfollowuptype.hasOwnProperty('Auto') && this.masterfollowuptype.Auto !== null ? this.masterfollowuptype.Auto : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            GeneralLiability: new DynamicLabel({
			    label: 'General Liability',
			    value: this.masterfollowuptype && this.masterfollowuptype.hasOwnProperty('GeneralLiability') && this.masterfollowuptype.GeneralLiability !== null ? this.masterfollowuptype.GeneralLiability : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            IsCaseFollowup: new DynamicLabel({
			    label: 'Is Case Followup',
			    value: this.masterfollowuptype && this.masterfollowuptype.hasOwnProperty('IsCaseFollowup') && this.masterfollowuptype.IsCaseFollowup !== null ? this.masterfollowuptype.IsCaseFollowup : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Name: new DynamicLabel({
			    label: 'Name',
			    value: this.masterfollowuptype && this.masterfollowuptype.hasOwnProperty('Name') && this.masterfollowuptype.Name !== null ? this.masterfollowuptype.Name.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ShowNotesOnClientPortal: new DynamicLabel({
			    label: 'Show Notes On Client Portal',
			    value: this.masterfollowuptype && this.masterfollowuptype.hasOwnProperty('ShowNotesOnClientPortal') && this.masterfollowuptype.ShowNotesOnClientPortal !== null ? this.masterfollowuptype.ShowNotesOnClientPortal : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            WorkersComp: new DynamicLabel({
			    label: 'Workers Comp',
			    value: this.masterfollowuptype && this.masterfollowuptype.hasOwnProperty('WorkersComp') && this.masterfollowuptype.WorkersComp !== null ? this.masterfollowuptype.WorkersComp : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };

    }
}
