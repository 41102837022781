import { Component } from '@angular/core';
import { IEntityListDynamicCellComponent, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { IEntity } from '@mt-ng2/base-service';
import { IEmailAddress } from '@model/interfaces/email-address';
import { IModalOptions } from '@mt-ng2/modal-module';

@Component({
    template: `
        <div *ngIf="emailAddress && emailAddress.Archived" class="text-center">
            <i (mtConfirm)="archiveEmailAddress($event)" class="fa fa-trash fa-2x" aria-hidden="true" [mtConfirmOptions]="unarchiveConfirm"></i
            ><i class="fa fa-lg fa-undo overlay-fa"></i>
        </div>
        <div *ngIf="emailAddress && !emailAddress.Archived" class="text-center">
            <i (mtConfirm)="archiveEmailAddress($event)" class="fa fa-trash fa-2x" aria-hidden="true" [mtConfirmOptions]="archiveConfirm"></i>
        </div>
    `,
})
export class EmailAddressDeleteCellComponent implements IEntityListDynamicCellComponent {
    entityListComponentMembers: IEntityListComponentMembers;
    set entity(value: IEntity) {
        this.emailAddress = value as IEmailAddress;
    }

    emailAddress: IEmailAddress;

    archiveConfirm: IModalOptions = {
        confirmButtonText: 'Yes, I am sure!',
        text: `Are you sure you want to delete this email address?`,
        title: 'Delete Email Address',
    	icon: 'question',
};

    unarchiveConfirm: IModalOptions = {
        confirmButtonText: 'Yes, I am sure!',
        text: `Are you sure you want to restore this email address?`,
        title: 'Restore Email Address',
    	icon: 'question',
};

    constructor() { }

    archiveEmailAddress(event: Event): void {
        this.entityListComponentMembers.itemDeleted(this.emailAddress as IEntity, event);
    }
}
