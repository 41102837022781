import { DynamicField } from '@mt-ng2/dynamic-form';
import { UserDynamicControls, IUserDynamicControlsParameters } from '../form-controls/user.form-controls';
import { IUser } from '../interfaces/user';
import { UserRoleIdEnums } from '@common/constants/Enums';

export class UserDynamicControlsPartial extends UserDynamicControls {
    constructor(userPartial?: IUser, additionalParameters?: IUserDynamicControlsParameters) {
        super(userPartial, additionalParameters);

        (<DynamicField>this.Form.FirstName).setRequired(true);
        (<DynamicField>this.Form.LastName).setRequired(true);
    }
}
