import { ILabelGenerator } from '../../../../common/interfaces/ILabelGenerator';
import { DatePipe } from '@angular/common';
import { ICommunicationLogResponse } from '@model/interfaces/custom/communication-request';

export class CommunicationLogLabelGenerator implements ILabelGenerator {
    constructor() {}

    GetLabel(entity: ICommunicationLogResponse): string {
        let label = ` <ul class="details-list">
                            <li><strong>To: </strong>${entity.Recepient}</li> `;
        label += entity.CC ? `<li><strong>CC: </strong>${entity.CC}</li>` : ``;
        label += entity.BCC ? `<li><strong>BCC: </strong>${entity.BCC}</li>` : ``;
        label += `<li><strong>Sent By: </strong>${entity.SentBy}</li>
        </ul>
        <ul class="details-list">
                            <li *ngIf="entity.DateSent !== null"><strong>Date and Time: </strong>${
                                new DatePipe('en-US').transform(entity.DateSent, 'MMMM d, yyyy hh:mm a') || 'Pending'
                            }</li>
                            <li><strong>Type: </strong>${entity.Type}</li>
                            <li><strong>Status: </strong> ${entity.Status}</li>
                        </ul>`;
        return label;
    }
}
