import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '@model/ClaimTypes';
import { GeneratedLetterTemplateDetailComponent } from './generated-letter-template-detail/generated-letter-template-detail.component';
import { GeneratedLetterTemplateHeaderComponent } from './generated-letter-template-header/generated-letter-template-header.component';
import { GeneratedLetterTemplatesComponent } from './generated-letter-template-list/generated-letter-templates.component';
import { GeneratedLetterTemplateService } from './generated-letter-template.service';

const generatedLetterTemplateEntityConfig = {
    claimType: ClaimTypes.LetterTemplates,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'generatedLetterTemplateId',
    service: GeneratedLetterTemplateService,
    title: 'Letter Template Detail',
};

const generatedLetterTemplateListRoleGuard = {
    claimType: ClaimTypes.LetterTemplates,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Letter Templates',
};

const generatedLetterTemplateRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: GeneratedLetterTemplatesComponent,
        data: generatedLetterTemplateListRoleGuard,
        path: 'letter-templates',
    },
    {
        canActivate: [AuthGuard],
        children: [
            {
                component: GeneratedLetterTemplateDetailComponent,
                path: '',
                pathMatch: 'full',
            },
        ],
        component: GeneratedLetterTemplateHeaderComponent,
        data: generatedLetterTemplateEntityConfig,
        path: `letter-templates/:${generatedLetterTemplateEntityConfig.entityIdParam}`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(generatedLetterTemplateRoutes)],
})
export class GeneratedLetterTemplateRoutingModule { }
