import { Validators } from '@angular/forms';
import { IExpandableObject } from '../expandable-object';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { PhoneNumberDynamicControls, IPhoneNumberDynamicControlsParameters } from '../form-controls/phone-number.form-controls';
import { IPhoneNumber } from '../interfaces/phone-number';
import { IUser } from '../interfaces/user';
import { IPhoneType } from '../interfaces/phone-type';

export class PhoneNumberDynamicControlsPartial extends PhoneNumberDynamicControls {
    constructor(phonenumberPartial?: IPhoneNumber, additionalParameters?: IPhoneNumberDynamicControlsParameters) {
        super(phonenumberPartial, additionalParameters);

        (<DynamicField>this.Form.Phone).type.inputType = InputTypes.Phone;
    }

    setRequired(isRequired: boolean): void {
        (<DynamicField>this.Form.Phone).setRequired(isRequired);
        (<DynamicField>this.Form.PhoneTypeId).setRequired(isRequired);

        if (isRequired) {
            (<DynamicField>this.Form.PhoneTypeId).validation.push(noZeroRequiredValidator);
        } else {
            (<DynamicField>this.Form.PhoneTypeId).validation = [];
        }
    }
}
