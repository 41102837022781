import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { IMasterServiceStatusTaskTemplate } from '@model/interfaces/master-service-status-task-template';
import { Observable } from 'rxjs';
import { IGroupedTaskTemplatesByStatusDTO } from '@model/interfaces/custom/grouped-task-templates-by-status-dto';

export const emptyServiceTaskAssociation: IMasterServiceStatusTaskTemplate = {
    Id: 0,
    MasterServiceStatusId: null,
    ServiceId: null,
    TaskTemplateId: null,
};

@Injectable()
export class MasterServiceStatusTaskTemplateService extends BaseService<IMasterServiceStatusTaskTemplate> {
    constructor(public http: HttpClient) {
        super('/masterservicestatustasktemplates', http);
    }

    getEmptyServiceTaskAssociation(): IMasterServiceStatusTaskTemplate {
        return emptyServiceTaskAssociation;
    }

    getGroupedTaskTemplatesByService(serviceId: number): Observable<IGroupedTaskTemplatesByStatusDTO[]> {
        return this.http.get<IGroupedTaskTemplatesByStatusDTO[]>(`/masterservicestatustasktemplates/${serviceId}/groupedTaskTemplates`);
    }
}
