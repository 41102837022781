import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { IImedClaimService } from '@model/partials/imed-claim-service.partial';
import { ClosedServiceStatusEnums } from '../constants/Enums';

@Pipe({
    name: 'ClosedServiceFilter',
})
@Injectable()
export class ClosedServiceFilterPipe implements PipeTransform {
    transform(items: IImedClaimService[]): IImedClaimService[] {
        if (!items) {
            return [];
        } else {
            const closedStatusIds = Object.values(ClosedServiceStatusEnums);
            return items.filter((service: IImedClaimService) => {
               return closedStatusIds.indexOf(service.ServiceStatus.MasterServiceStatusId) === -1;
            });
        }
    }
}
