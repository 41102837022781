
import { environment } from '../../environments/environment';
import { ILoginConfig, LoginComponentLink } from '@mt-ng2/login-module';

export const LoginConfigOverride: ILoginConfig = {
    googleAuthConfig: environment.googleApiKey ? { googleApiKey: environment.googleApiKey } : null,
    loginComponentLinks: [
        {linkHtml: '<a>I forgot my password</a>', routerLinkPath: '/forgotpassword' },
        new LoginComponentLink(
            '<a href="https://imedview.com/terms-and-conditions/" target="_blank">Terms and Conditions</a>',
            '',
        ),
        new LoginComponentLink(
            '<a href="https://imedview.com/privacy-policy/" target="_blank">Privacy Policy</a>',
            '',
        ),
        new LoginComponentLink(
            '<br><i>Password is case sensitive and must include a combination of upper case, lower case, numbers, and special characters. Password must be a minimum of 8 characters in length.</i>',
            '',
        ),
        ],
    messageOverrides: {
        failedPattern: 'Valid passwords must be at least 8 characters, have  upper case, lower case, a number, and a special character.',
    },
    passwordPattern : '(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}',
};
