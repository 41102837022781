import { BaseService } from '@mt-ng2/base-service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/operators';
import { IPhysicianSpecialtyDetail } from '@model/interfaces/physician-specialty-detail';

@Injectable()
export class PhysicianSpecialityDetailService extends BaseService<IPhysicianSpecialtyDetail> {
    constructor(public http: HttpClient) {
        super('/physicianspecialitydetails', http);
    }
}
