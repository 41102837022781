import { IAddressBookWithPrimaryAddress } from '@model/interfaces/custom/address-book-with-primary-address';
import { IAddressBook } from '@model/interfaces/address-book';
import { IAddress } from '@model/interfaces/address';
import { formatAddress } from '../common/services/address-formatter.service';

export class AddressBookWithPrimaryAddress implements IAddressBookWithPrimaryAddress {
    HasPhysicians: boolean;
    AddressBook: IAddressBook;
    PrimaryAddress: IAddress;
    CountyName: string;
    StateCode: string;
    FormattedAddress: string;

    constructor(addressBook: IAddressBookWithPrimaryAddress) {
        Object.assign(this, addressBook);
    }

    getNameWithAddress(): string {
        return `${this.AddressBook.FacilityName} - ${formatAddress(this.PrimaryAddress)}`;
    }
}
