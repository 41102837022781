import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { MasterServiceStatusService } from '../services/master-service-status.service';
import { IMasterServiceStatus } from '@model/interfaces/master-service-status';

@Component({
    templateUrl: './master-service-status-header.component.html',
})
export class MasterServiceStatusHeaderComponent implements OnInit {
    masterServiceStatus: IMasterServiceStatus;
    header$: Observable<string>;

    readonly masterServiceStatusService = inject(MasterServiceStatusService);
    readonly route = inject(ActivatedRoute);

    ngOnInit(): void {
        // get the id from the route
        const id = +this.route.snapshot.paramMap.get('ServiceStatusId');

        this.masterServiceStatusService.setInitialTitle(id);
        this.header$ = this.masterServiceStatusService.title$;
    }
}
