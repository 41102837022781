import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { IEntitySearchParams, SearchParams, ExtraSearchParams } from '@mt-ng2/common-classes';
import { CommonSearchService, ICommonSearch } from '../../common/services/common-search.service';
import { DynamicField } from '@mt-ng2/dynamic-form';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { IMetaItem } from '@mt-ng2/base-service';

@Component({
    selector: 'app-claimant-name-search-widget',
    styles: [
        `
            .search-form {
                cursor: pointer;
            }
            .show {
                display: block;
            }
            .hide {
                display: none;
            }
        `,
    ],
    templateUrl: './claimant-name-search-widget.component.html',
})
export class ClaimantNameSearchWidget implements OnInit, AfterViewInit {
    selectedClaim: IMetaItem;
    inputTextbox: DynamicField;
    isEditing = false;
    searchClaimsForm: UntypedFormGroup;
    subscriptions: Subscription = new Subscription();
    claimantName: string;

    @ViewChild('searchClaims') searchClaimsElement: ElementRef;

    constructor(private router: Router, private commonSearchService: CommonSearchService) {}

    ngAfterViewInit(): void {
        setTimeout(() => this.searchClaimsElement?.nativeElement?.focus?.());
    }

    ngOnInit(): void {
        this.searchClaimsForm = new UntypedFormGroup({ searchClaimantName: new UntypedFormControl('') });
        this.subscriptions.add(
            this.searchClaimsForm.get('searchClaimantName').valueChanges.subscribe((value) => {
                this.claimantName = value;
            }),
        );
    }

    toggleSearch(): void {
        this.isEditing = !this.isEditing;
    }

    search(): void {
        if (this.claimantName) {
            const _extraSearchParams: ExtraSearchParams[] = [];
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'claimantName',
                    value: this.claimantName,
                }),
            );

            const searchEntity: IEntitySearchParams = {
                extraParams: _extraSearchParams,
                query: '',
            };

            const searchFilter: ICommonSearch = {
                dashboardName: 'Claimant Name Search',
                searchFilters: new SearchParams(searchEntity),
            };

            this.commonSearchService.setCommonSearchFilters(searchFilter);
            void this.router.navigate(['/cases']);
        }
    }
}
