import { ImedClaimServiceProcessServerFeeDynamicControls } from '@model/form-controls/imed-claim-service-process-server-fee.form-controls';
import { IImedClaimServiceProcessServerFee } from '@model/interfaces/imed-claim-service-process-server-fee';
import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';

export class ImedClaimServiceProcessServerFeesDynamicConfig<T extends IImedClaimServiceProcessServerFee> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private imedClaimServiceProcessServerFee: T, private configControls?: string[]) {
        super();
        const dynamicControls = new ImedClaimServiceProcessServerFeeDynamicControls(this.imedClaimServiceProcessServerFee);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['Fees', 'DateCreated', 'CreatedById'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
