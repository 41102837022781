import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICommunicationMethod } from '@model/interfaces/communication-method';
import { ITaskTemplateCommunicationMethod } from '@model/interfaces/task-template-communication-method';
import { BaseService, MetaItemService } from '@mt-ng2/base-service';

@Injectable()
export class CommunicationMethodService extends MetaItemService<ICommunicationMethod> {
    constructor(public http: HttpClient) {
        super('CommunicationMethodService', 'CommunicationMethod', 'CommunicationMethodIds', '/communication-methods', http);
    }

    getEmptyDayOffsetArray(communicationMethods: ICommunicationMethod[]): number[] {
        const retArray: number[] = [];
        communicationMethods.forEach((x) => {
            retArray.push(0);
        });
        return retArray;
    }
}
