import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, OperatorFunction } from 'rxjs';

import { BaseService } from '@mt-ng2/base-service';
import { catchError } from 'rxjs/operators';
import { IImedClaimAdditionalOtherDTO } from '@model/interfaces/custom/imed-claim-additional-other.dto';
import { IAdditionalOther } from '@model/interfaces/additional-other';

export const emptyImedClaimAdditionalOtherDTO: IImedClaimAdditionalOtherDTO = {
    AddressBookId: 0,
    Id: 0,
    IndividualId: 0,
    Name: '',
};

@Injectable()
export class ImedClaimAdditionalOtherService extends BaseService<IAdditionalOther> {
    newAdditionalOthers: IImedClaimAdditionalOtherDTO[] = [];

    constructor(public http: HttpClient) {
        super('/imedclaims', http);
    }

    resetAdditionalOthers(): void {
        this.newAdditionalOthers = [];
    }
    getEmptyImedClaimAdditionalOther(): IImedClaimAdditionalOtherDTO {
        return { ...emptyImedClaimAdditionalOtherDTO };
    }

    getAdditionalOthers(imedClaimId: number): Observable<IImedClaimAdditionalOtherDTO[]> {
        return this.http.get<IImedClaimAdditionalOtherDTO[]>(`/imedclaims/${imedClaimId}/additional-others`);
    }

    AddAdditionalOther(imedClaimId: number, dtos: IImedClaimAdditionalOtherDTO[]): Observable<number> {
        return this.http.post<number>(`/imedclaims/${imedClaimId}/additional-others`, dtos);
    }

    delete(id: number): Observable<any> {
        return this.http.delete(`/imedclaims/${id}/additional-others`).pipe(catchError((e: Response) => this.handleError(e)) as OperatorFunction<unknown, any>);
    }
}
