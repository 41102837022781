import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { IEmailTemplate } from '@model/interfaces/email-template';
import { Observable } from 'rxjs';
import { SearchParams } from '@mt-ng2/common-classes';

export const emptyEmailTemplate: IEmailTemplate = {
    Archived: false,
    Bcc: null,
    Body: null,
    CanHaveAttachment: true,
    Cc: null,
    CreatedById: 0,
    DateCreated: new Date(),
    EmailTemplateAddressBookTypes: [],
    EmailTemplateAttachments: [],
    EmailTemplateServices: [],
    EmailTemplateIndependentlyLinkedAttachments: [],
    EmailTemplateTypeId: 1,
    Id: 0,
    IsAttachmentsEditable: true,
    Name: null,
    Subject: null,
    SystemGenerated: false,
    To: null,
    IsAttachmentsLinkedIndependently: false,
};

@Injectable()
export class EmailTemplateService extends BaseService<IEmailTemplate> {
    constructor(public http: HttpClient) {
        super('/email-templates', http);
    }

    getEmptyEmailTemplate(): IEmailTemplate {
        return { ...emptyEmailTemplate };
    }

    archive(id: number): any {
        return this.http.put<number>(`/email-templates/archive/${id}`, {});
    }

    search(csp: SearchParams): Observable<any> {
        return this.http.get<any>(`/email-templates/search`, { observe: 'response', params: this.getHttpParams(csp) });
    }

    mergeEmailTemplate(emailTemplateId: number, imedClaimServiceId: number): Observable<IEmailTemplate> {
        return this.http.get<IEmailTemplate>(`/email-templates/template/${emailTemplateId}/service/${imedClaimServiceId}`);
    }

    mergeIndividualEmailTemplate(emailTemplateId: number, individualId: number): Observable<IEmailTemplate> {
        return this.http.get<IEmailTemplate>(`/email-templates/template/${emailTemplateId}/individual/${individualId}`);
    }

    mergeAddressBookEmailTemplate(emailTemplateId: number, addressBookId: number): Observable<IEmailTemplate> {
        return this.http.get<IEmailTemplate>(`/email-templates/template/${emailTemplateId}/address-book/${addressBookId}`);
    }

    mergeCaseEmailTemplate(emailTemplateId: number, imedClaimId: number): Observable<IEmailTemplate> {
        return this.http.get<IEmailTemplate>(`/email-templates/template/${emailTemplateId}/case/${imedClaimId}`);
    }

    getFeeScheduleLetter( imedClaimServiceId: number): Observable<IEmailTemplate> {
        return this.http.get<IEmailTemplate>(`/email-templates/feeScheduleLetter/${imedClaimServiceId}`);
    }

    getImedClaimTypeId(imedClaimServiceId: number): any {
        return this.http.get<any>(`/email-templates/imedClaimTypeId/${imedClaimServiceId}`);
    }

    getImedClaimEmailTemplates(): Observable<IEmailTemplate[]> {
        return this.http.get<IEmailTemplate[]>(`/email-templates/imedclaim`);
    }
}
