import { DatePipe } from '@angular/common';
import { MtSearchFilterDaterangeComponent, DaysOfTheWeek, ISearchFilterDaterangeValue, IContextualDate, IMtSearchFilterDaterangeModuleConfig, MtSearchFilterDateRangeModuleConfigToken } from '@mt-ng2/search-filter-daterange-control';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Component, Input, ViewChild, Output, EventEmitter, OnInit, OnChanges, OnDestroy, Inject } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { DynamicField } from '@mt-ng2/dynamic-form';

@Component({
    selector: 'mt-search-filter-daterange-override',
    styles: [
        `
            .show.dropdown {
                display: inline !important;
            }
            .form-padding {
                padding: 10px;
                width: 400px;
            }
            .start-date {
                float: left;
            }
            .end-date {
                float: right;
            }
            .action-div {
                min-height: 30px;
            }
            .btn-clear {
                margin-right: 5px;
            }
            #start-after-end-error {
                position: absolute;
                bottom: 0;
                left: 10px;
            }
        `,
    ],
    template: `
        <span ngbDropdown autoClose="outside" class="dropdown" #ngbDropdown="ngbDropdown">
            <button ngbDropdownToggle class="btn btn-transparent dropdown-toggle" type="button">
                <span [innerHtml]="selectedItemsText()"></span>
                <span class="caret"></span>
            </button>
            <div ngbDropdownMenu class="dropdown-menu">
                <form [formGroup]="daterangeForm" class="form-padding">
                    <div class="action-div row">
                        <div class="col-md-6">
                            <label for="ContextualDateType">Other Dates</label>
                            <select formControlName="ContextualDateType" name="ContextualDateType">
                                <option *ngFor="let type of contextualDateTypes" [ngValue]="type.Id">{{ type.Name }}</option>
                            </select>
                        </div>
                        <div class="col-md-6">
                            <button (click)="applyChanges()" class="btn btn-primary btn-sm pull-right" type="button">Apply</button>
                            <button (click)="clearValues()" class="btn btn-default btn-sm pull-right btn-clear" type="button">Clear</button>
                        </div>
                    </div>
                    <div [formGroup]="daterangeForm">
                        <mt-dynamic-field [field]="startDateField" [form]="daterangeForm" class="start-date"></mt-dynamic-field>
                        <mt-dynamic-field [field]="endDateField" [form]="daterangeForm" class="end-date"></mt-dynamic-field>
                        <br />
                        <span *ngIf="hasDatesReversedError()" id="start-after-end-error" class="small errortext">Start must be earlier than end</span>
                    </div>
                </form>
            </div>
        </span>
    `,
})
export class MtSearchFilterDaterangeComponentOverride extends MtSearchFilterDaterangeComponent implements OnInit, OnChanges, OnDestroy {
    @Input() startDate: Date = null;
    @Input() endDate: Date = null;
    @Input() minDate: Date = null;
    @Input() maxDate: Date = null;
    @Input() contextualDateType: IContextualDate;
    @Input() entity: string;
    @Output('onSelectionChanged') selectionChanged: EventEmitter<ISearchFilterDaterangeValue> = new EventEmitter<ISearchFilterDaterangeValue>();
    @ViewChild('ngbDropdown') ngbDropdown: NgbDropdown;

    firstDayOfTheWeek: DaysOfTheWeek;
    daterangeForm: UntypedFormGroup;
    startDateField: DynamicField;
    endDateField: DynamicField;
    contextualDateTypes = [];

    constructor(
        fb: UntypedFormBuilder,
        private datePipe: DatePipe,
        @Inject(MtSearchFilterDateRangeModuleConfigToken)
        searchFilterModuleConfig?: IMtSearchFilterDaterangeModuleConfig,
    ) {
        super(searchFilterModuleConfig);
    }

    getDateString(date: Date): string {
        return this.datePipe.transform(date, 'mediumDate', 'UTC');
    }

    applyChanges(): void {
        if (this.daterangeForm.valid) {
            this.startDate = this.daterangeForm.value.start ? new Date(this.getDateString(this.daterangeForm.value.start as Date)) : null;
            this.endDate = this.daterangeForm.value.end ? new Date(this.getDateString(this.daterangeForm.value.end as Date)) : null;
        }
        this.emitChange();
        this.closeDropdown();
    }
}
