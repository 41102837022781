import { IAuthUser } from '@model/interfaces/auth-user.d';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { BaseService } from '@mt-ng2/base-service';
import { IUser } from '@model/interfaces/user';
import { IPhone } from '@model/interfaces/base';
import { ICreateUserPayload } from '@model/interfaces/custom/create-user-payload';
import { tap, catchError } from 'rxjs/operators';
import { IExternalPortalUserPayload } from '@model/interfaces/custom/external-portal-user-payload';
import { UserTypeValues } from '../../client-portal/common/constants/user-type.enum';
import { IAddress } from '@model/interfaces/address';

@Injectable({
    providedIn: 'root',
})
export class UserService extends BaseService<IUser> {
    private _allUsers: BehaviorSubject<IUser[]> = new BehaviorSubject([]);
    private emptyUser: IUser = {
        Archived: false,
        AuthUserId: 0,
        CanBeAssignedTasks: false,
        CanCompleteOthersTasks: false,
        ClientUploadedStudyTask: false,
        CreatedById: 0,
        DateCreated: new Date(),
        Email: null,
        FirstName: null,
        Id: 0,
        LastName: null,
        TwoFactorEnabled: true,
        UserTypeId: null,
        Version: null,
    };

    private externalUserCreatedSubject: Subject<number>;

    externalUserCreated$: Observable<number>;

    constructor(public http: HttpClient) {
        super('/users', http);
        this.refreshAllUsers();
        this.externalUserCreatedSubject = new Subject();
        this.externalUserCreated$ = this.externalUserCreatedSubject.asObservable();
    }

    getEmptyUser(): IUser {
        return { ...this.emptyUser };
    }

    requestClientAccess(userId: number): Observable<string> {
        return this.http.get<string>(`/users/getclientaccess/${userId}/`);
    }

    getByIndividualId(individualId: number): Observable<IUser> {
        return this.http.get<IUser>(`/users/getByIndividualId/${individualId}/`);
    }

    getSchedulingAssignedToUsers(imedClaimType: number): Observable<IUser[]> {
        return this.http.get<IUser[]>(`/imedclaims/scheduling-assigned-to/${imedClaimType}`);
    }

    getCompilationAssignedToUsers(imedClaimType: number): Observable<IUser[]> {
        return this.http.get<IUser[]>(`/imedclaims/compilation-assigned-to/${imedClaimType}`);
    }

    getRetrievalAssignedToUsers(imedClaimType: number): Observable<IUser[]> {
        return this.http.get<IUser[]>(`/imedclaims/retrieval-assigned-to/${imedClaimType}`);
    }

    getSubpoenaAssignedToUsers(): Observable<IUser[]> {
        return this.http.get<IUser[]>(`/imedclaims/subpoena-assigned-to`);
    }

    getReportAssignedToUsers(imedClaimType: number): Observable<IUser[]> {
        return this.http.get<IUser[]>(`/imedclaims/report-assigned-to/${imedClaimType}`);
    }

    GetUsersByTypeId(typeId: number): Observable<IUser[]> {
        return this.http.get<IUser[]>(`/users/getUsersByTypeId/${typeId}/`);
    }

    setUsers(value: IUser[]): void {
        this._allUsers.next(value);
    }

    getAll(): Observable<IUser[]> {
        return this._allUsers.asObservable();
    }

    getAllUsers(): Observable<IUser[]> {
        return this.http.get<IUser[]>(`/users/getAll/`);
    }

    refreshAllUsers(): void {
        this.GetUsersByTypeId(UserTypeValues.Admin).subscribe((users) => {
            this.setUsers(users);
        });
    }

    createUser(data: ICreateUserPayload): Observable<number> {
        return this.http.post<number>(`/users/create`, data).pipe(tap(() => this.refreshAllUsers()));
    }

    saveAddress(userId: number, address: IAddress): Observable<number> {
        if (!address.Id) {
            address.Id = 0;
            return this.http.post<number>(`/users/${userId}/address`, address);
        } else {
            return this.http.put<number>(`/users/${userId}/address`, address);
        }
    }

    deleteAddress(userId: number): Observable<object> {
        return this.http.delete('/users/' + userId + '/address', {
            responseType: 'text' as 'json',
        });
    }

    savePhones(userId: number, phones: IPhone): any {
        return this.http.put<number>(`/users/${userId}/phones`, phones);
    }

    savePhoto(userId: number, photo: File): any {
        const formData: FormData = new FormData();
        formData.append('file', photo, photo.name);

        return this.http.post(`/users/${userId}/pic`, formData);
    }

    saveSignaturePhoto(userId: number, photo: File): any {
        const formData: FormData = new FormData();
        formData.append('file', photo, photo.name);

        return this.http.post(`/users/${userId}/signaturePic`, formData);
    }

    getByAuthUserId(authId: number): Observable<IAuthUser> {
        return this.http.get<IAuthUser>('/users/' + authId + '/authuser');
    }

    deletePhoto(userId: number): any {
        return this.http.delete(`/users/${userId}/pic`);
    }

    deleteSignaturePhoto(userId: number): any {
        return this.http.delete(`/users/${userId}/signaturePic`);
    }

    createExternalUser(individualId: number, user: IExternalPortalUserPayload): Observable<IUser> {
        return this.http.post<IUser>(`/users/${individualId}/external-portal`, user).pipe(
            catchError((e: Response) => this.handleError(e)),
            tap(() => {
                this.externalUserCreatedSubject.next(individualId);
            }),
        );
    }

    updateExternalUserProfile(id: number, data: IExternalPortalUserPayload): Observable<IUser> {
        return this.http.put<IUser>(`/users/external-portal/${id}`, data);
    }

    resendCredentialsEmail(individualId: number): Observable<number> {
        return this.http.post<number>(`/users/external-portal/${individualId}/resendCredentialsEmail`, {});
    }

    getRoleId(userId: number): Observable<number> {
        return this.http.get<number>(`/users/getUserRoleId/${userId}/`);
    }

    reassignTasksAndAssignedTo(userId: number, data: any): Observable<void> {
        return this.http.post<void>(`/users/${userId}/reassign`, data);
    }
}
