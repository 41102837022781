import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';
import { IMasterFollowupType } from '@model/interfaces/master-followup-type';
import { IMasterFollowupTypeDynamicControlsParametersPartial, MasterFollowupTypeDynamicControlsPartial } from '@model/partials/master-followup-type.form-controls.partial';

export class MasterFollowupTypeDynamicConfig<T extends IMasterFollowupType> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(
        private masterFollowupType: T,
        private additionalParams?: IMasterFollowupTypeDynamicControlsParametersPartial,
        private configControls?: string[],
    ) {
        super();

        const dynamicControls = new MasterFollowupTypeDynamicControlsPartial(this.masterFollowupType, this.additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['Name', 'ShowNotesOnClientPortal', 'IsCaseFollowup'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
