import { ICommunicationLog } from './../../model/interfaces/communication-log.d';
import { CurrencyFormatterService } from './../../common/services/currency-formatter.service';
import { AddressBookSelectionEnums, AttachmentTypeEnums } from './../../common/constants/Enums';
import { IAddressBookSelectEntity } from './../../common/address-book-select-list/address-books-select-entity';
import {
    AddressBooksSelectService,
    emptyImeContactsInfo,
    emptyImedClaimServiceContactInfo,
} from './../../common/address-book-select-list/address-books-select.service';
import { CommunicationLogService } from '../../common/services/communication-log.service';
import { ServiceStatusService } from './../../imed-claims/services/common-components/service-status/servicestatus.service';
import { SubServiceService } from './../../imed-claims/services/common-components/sub-services/sub-service.service';
import { ImeAppointmentService } from './../../imed-claims/services/service-specifics/ime-service/ime-appointments/ime-appointment.service';
import { ImedClaimServiceFollowupService } from './../../imed-claims/services/common-components/service-followups/imedclaimservicefollowup.service';
import { ImedClaimServiceStatusLogService } from './../../imed-claims/services/common-components/service-status/imed-claim-service-status-log.service';
import { FollowupLabelGenerator } from './../../imed-claims/services/common-components/service-followups/followupLabelGenerator';
import { ImedClaimServiceStatusLogLabelGenerator } from './../../imed-claims/services/common-components/service-status/imed-claim-service-status-log-label-generator';
import { CommunicationLogLabelGenerator } from './../../imed-claims/services/common-components/communication-log/communication-log-label-generator';
import { IServiceStatus } from './../../model/interfaces/service-status.d';
import { ImedClaimService } from './../../imed-claims/imedclaim.service';
import { IImeAppointment } from '../../model/interfaces/ime-appointment';
import { IEmailAddress } from '../../model/interfaces/email-address';
import { ISubService } from '../../model/interfaces/sub-service';
import { IImeContactInfo } from '../../imed-claims/imed-claim-basic-info/imed-claim-contacts-info';
import { PhysicianService } from '../../address-books/individuals/physicians/physician.service';
import { ImedClaimServiceService } from '../../imed-claims/services/imedclaimservice.service';
import { IPhysicianVacation } from '../../model/interfaces/physician-vacation';
import { Subject } from 'rxjs';
import { IImedClaimServiceFollowup } from '../../model/interfaces/imed-claim-service-followup';
import { IImedClaimServiceStatusLog } from '../../model/interfaces/imed-claim-service-status-log';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { IImedClaimService } from '../../model/interfaces/imed-claim-service';
import { Input, Output, EventEmitter, OnDestroy, Component } from '@angular/core';
import { SearchParams, ExtraSearchParams } from '@mt-ng2/common-classes';
import { Subscription } from 'rxjs';
import { IImedClaimServiceContactInfo } from '../../imed-claims/imed-claim-basic-info/imed-claim-contacts-info';
import { BaseAttachmentsService } from '../../common/attachments/base-attachments.service';
import { tap } from 'rxjs/operators';
import { ModalService } from '../../common/services/modal.service';
import { AuthService, ClaimsService } from '@mt-ng2/auth-module';
import { ICommunicationLogResponse } from '../../model/interfaces/custom/communication-request';
import { DatePipe } from '@angular/common';
import { IFollowupsWithSpecialInformationDto } from '@model/interfaces/custom/followups-with-prelim-invoice-date-dto';

// Still not a big fan of this, since this is doing a lot of different things
// Centrailized in a common place.
// Better way could be adding interface as for different component functionality, implement them and then inject them
// Not sure if typescript could do a DI like this.

@Component({
    template: '',
})
export class CommonImedClaimService implements OnDestroy {
    @Input() imedClaimServiceModel: IImedClaimService;
    @Input() canEdit: boolean;
    @Output('onRefreshStatus') onRefreshStatus: EventEmitter<any> = new EventEmitter<any>();
    @Output('loadResendComponent') loadResendComponent: EventEmitter<ICommunicationLogResponse> = new EventEmitter();
    @Output('onGenerateTemplateClick') onGenerateTemplateClick: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output('onGenerateSubpoenaClick') onGenerateSubpoenaClick: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output('onGenerateSubpoenaPackageClick') onGenerateSubpoenaPackageClick: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output('onGenerateCompletedSubpoenaPackageClick') onGenerateCompletedSubpoenaPackageClick: EventEmitter<boolean> = new EventEmitter<boolean>();

    formRendered = false;
    imedClaimServiceId: number;

    editingComponent: Subject<any> = new Subject();

    //#region Email Declarations

    communicationLogCardName = 'Communication Logs';
    communicationlLogLabelGenerator: CommunicationLogLabelGenerator;
    communicationlLogs: ICommunicationLogResponse[];
    imedclaimCommunicationLogsCount: number;

    //#endregion

    //#region Service Status

    serviceStatusLogCardName = 'Service Status Log';
    serviceStatusLogLabelGenerator = new ImedClaimServiceStatusLogLabelGenerator();
    imedclaimServiceStausLogs: IImedClaimServiceStatusLog[];
    imedclaimServiceStausLogsCount: number;

    //#endregion

    //#region Service Status

    followupsCardName = 'Follow-ups';
    followupsLabelGenerator = new FollowupLabelGenerator();
    followups: IImedClaimServiceFollowup[];
    followUpCount: number;

    //#endregion

    //#region addressbook related declarations
    searching = false;
    adding = false;

    addressBookType = '';
    imedClaimServiceContactInfo: IImedClaimServiceContactInfo;
    subscriptions: Subscription = new Subscription();

    // Physician related information - eventually all these should go inside the physician component and be determined there
    isPhysicianSelected = false;
    specialNote: string;
    detailsHtml: string;
    vacations: IPhysicianVacation[];

    //#endregion

    //#region sub service
    subService: ISubService[];
    //#endregion

    //#region status
    serviceStaus: IServiceStatus[];
    defenseAttorneyEmailAddress: IEmailAddress;
    latestAppointment: IImeAppointment;
    //#endregion

    imeContactInfo: IImeContactInfo;

    disablePhysicianFacilityControl = false;
    feeScheduleAttachment: number = AttachmentTypeEnums.Fee_Schedule;

    specialFollowupText: string;
    bodyPartsToBeExamined: string;
    reportDueBy: string;

    appFaxEmailComponentExists = true;

    constructor(
        private commonCommunicationLogService: CommunicationLogService,
        private commonRouter: Router,
        private commonRoute: ActivatedRoute,
        private commonImedclaimServiceStausLogService: ImedClaimServiceStatusLogService,
        private commonImedClaimServiceFollowupService: ImedClaimServiceFollowupService,
        private commonImedClaimServiceService: ImedClaimServiceService,
        private commonAddressBookSelectService: AddressBooksSelectService,
        private commonCurrencyFormatterService: CurrencyFormatterService,
        private commonPhysicianService: PhysicianService,
        private commonImeAppointmentService: ImeAppointmentService,
        private commomSubServiceService: SubServiceService,
        private commonImedClaimService: ImedClaimService,
        private commonServiceStatusService: ServiceStatusService,
        private commonattachmentsService: BaseAttachmentsService,
        private commonModalService: ModalService,
        private commonClaimsService: ClaimsService,
        private commonAuthService: AuthService,
    ) {
        this.imedClaimServiceId = +this.commonRoute.snapshot.paramMap.get('serviceId');
        this.imeContactInfo = { ...emptyImeContactsInfo };

        this.imedClaimServiceContactInfo = { ...emptyImedClaimServiceContactInfo };
        this.communicationlLogLabelGenerator = new CommunicationLogLabelGenerator();
        this.subscriptions.add(
            this.commonImedClaimServiceService.changeEmitted$.subscribe((imedClaimService: IImedClaimService) => {
                this.imedClaimServiceModel = imedClaimService;
                this.setImedClaimServiceModel(imedClaimService);
                this.setIsPhysicianSelected();
                this.setPhysicianInformations();
            }),
        );
        this.subscriptions.add(
            this.commonAddressBookSelectService.changeEmitted$.subscribe((addressBookSelect) => {
                this.setAddressBook(addressBookSelect);
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    setDisablePhysicianFacilityControl(): void {
        this.commonImedClaimServiceService
            .getPhysicianFacilityControlIsDisabled(this.imedClaimServiceModel.Id, this.imedClaimServiceModel.ServiceId)
            .subscribe((response) => {
                this.disablePhysicianFacilityControl = response;
            });
    }

    //#region "Sub Services"

    getSubServices(): Observable<ISubService[]> {
        return this.commomSubServiceService
            .getAll()
            .pipe(
                tap(
                    (subService: ISubService[]) =>
                        (this.subService = subService.filter((x: ISubService) => x.ServiceId === this.imedClaimServiceModel.ServiceId)),
                ),
            );
    }
    //#endregion

    //#region "Service Status"

    getPrimaryEmailForImedClaimDefenseAttorney(): Observable<IEmailAddress> {
        return this.commonImedClaimService
            .getPrimaryEmailForImedClaimDefenseAttorney(this.imedClaimServiceModel.ImedClaim.Id)
            .pipe(tap((emailAddress: IEmailAddress) => (this.defenseAttorneyEmailAddress = emailAddress)));
    }

    getLatestAppointmentByImedClaimServiceId(): Observable<IImeAppointment> {
        return this.commonImedClaimServiceService
            .getLatestAppointmentByImedClaimServiceId(this.imedClaimServiceModel.Id)
            .pipe(tap((imeAppointment: IImeAppointment) => (this.latestAppointment = imeAppointment)));
    }

    getServiceStatuses(): Observable<IServiceStatus[]> {
        return this.commonServiceStatusService
            .getAll()
            .pipe(
                tap((serviceStaus: IServiceStatus[]) =>
                    (this.serviceStaus = serviceStaus.filter((x: IServiceStatus) => x.ServiceId === this.imedClaimServiceModel.ServiceId))?.sort(
                        (a, b) => (a.Name < b.Name ? -1 : 1),
                    ),
                ),
            );
    }

    refreshServiceStatusLogs(): void {
        this.getImedClaimServiceStatusLog().subscribe();
        this.getFollowups().subscribe();
    }

    //#endregion

    //#region "Physician Details"

    setPhysicianInformations(): void {
        this.imedClaimServiceContactInfo = {
            ...this.commonImedClaimServiceService.assignImedClaimServicetoContacts(this.imedClaimServiceContactInfo, this.imedClaimServiceModel),
        };
        if (this.imedClaimServiceModel.PhysicianId) {
            this.getPhysicianVacations(this.imedClaimServiceModel.PhysicianId);
        }
    }

    getPhysicianVacations(id: number): void {
        this.commonImeAppointmentService.getPhysicianVacations(id).subscribe((response) => {
            this.vacations = response;
        });
    }

    setAddressBook(addressBookSelect: IAddressBookSelectEntity): void {
        this.searching = false;
        this.adding = false;

        if (addressBookSelect.IndividualId === null && addressBookSelect.AddressBookId === null) {
            this.adding = true;
        } else if (this.commonAddressBookSelectService.typeFilter !== AddressBookSelectionEnums.Physician.toString()) {
            this.imeContactInfo[this.commonAddressBookSelectService.typeFilter + 'Name'] = addressBookSelect.IndividualId
                ? addressBookSelect.IndividualName
                : addressBookSelect.AddressBookName;
            this.imeContactInfo[this.commonAddressBookSelectService.typeFilter + 'IndividualId'] = addressBookSelect.IndividualId;
            this.imeContactInfo[this.commonAddressBookSelectService.typeFilter + 'AddressBookId'] = addressBookSelect.AddressBookId;
        } else {
            this.imedClaimServiceContactInfo[this.commonAddressBookSelectService.typeFilter + 'Name'] = addressBookSelect.IndividualName;
            this.imedClaimServiceContactInfo[this.commonAddressBookSelectService.typeFilter + 'Id'] = addressBookSelect.PhysicianId;
        }
    }

    setImedClaimServiceModel(imedClaimService: IImedClaimService): void {
        this.imedClaimServiceModel = imedClaimService;
    }

    setIsPhysicianSelected(): void {
        this.isPhysicianSelected = this.imedClaimServiceModel && this.imedClaimServiceModel.PhysicianId > 0;
    }

    //#endregion

    //#region Email Log

    getCommunicationLog(): Observable<ICommunicationLog[]> {
        const _extraSearchParams: ExtraSearchParams[] = [];
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'imedClaimServiceId',
                value: this.imedClaimServiceId.toString(),
            }),
        );
        const searchParams: SearchParams = new SearchParams({
            extraParams: _extraSearchParams,
            order: 'DateSent',
            orderDirection: 'desc',
            query: '',
            skip: 0,
            take: 3,
        });

        return this.commonCommunicationLogService.searchLogs(searchParams, 'imedclaimservice').pipe(
            tap((answer: any) => {
                this.communicationlLogs = answer.body;
                this.imedclaimCommunicationLogsCount = +answer.headers.get('X-List-Count');
            }),
        );
    }

    seeAllCommunicationLogs(): void {
        void this.commonRouter.navigate([`cases/${this.imedClaimServiceModel.ImedClaimId}/services/${this.imedClaimServiceId}/communication-logs/`]);
    }

    subscribeToCommunicationLogs(): void {
        this.subscriptions.add(this.commonCommunicationLogService.emitLogChange.subscribe(() => this.getCommunicationLog().subscribe()));
    }

    //#endregion

    //#region Staus Log

    getImedClaimServiceStatusLog(): Observable<IImedClaimServiceStatusLog[]> {
        const searchParams: SearchParams = new SearchParams({
            order: 'ChangedDate',
            orderDirection: 'desc',
            query: '',
            skip: 0,
            take: 3,
        });

        return this.commonImedclaimServiceStausLogService.searchStatusLogs(this.imedClaimServiceId, searchParams).pipe(
            tap((answer: any) => {
                this.imedclaimServiceStausLogs = answer.body;
                this.imedclaimServiceStausLogsCount = +answer.headers.get('X-List-Count');
            }),
        );
    }

    seeAllServiceStatusLogs(): void {
        void this.commonRouter.navigate([`cases/${this.imedClaimServiceModel.ImedClaimId}/services/${this.imedClaimServiceId}/status-logs/`]);
    }

    //#endregion

    //#region Staus Log

    getFollowups(): Observable<IFollowupsWithSpecialInformationDto> {
        const searchParams: SearchParams = new SearchParams({
            extraParams: [
                new ExtraSearchParams({
                    name: 'includeArchived',
                    value: '0',
                }),
            ],
            order: 'ImedClaimServiceFollowUpId',
            orderDirection: 'desc',
            query: '',
            skip: 0,
            take: 3,
        });

        return this.commonImedClaimServiceFollowupService.getFollowupsAndPrelimInvoiceDate(searchParams, this.imedClaimServiceId).pipe(
            tap((answer) => {
                this.followups = answer.Followups;
                this.followUpCount = +answer.Count;
                if (answer.PreliminaryInvoiceDate) {
                    this.specialFollowupText = `Preliminary invoice forwarded to client on ${new DatePipe('en-US').transform(
                        answer.PreliminaryInvoiceDate,
                    )}`;
                }
                if (answer.InvoiceForwardedDate) {
                    this.specialFollowupText = `Invoice Forwarded to client on ${new DatePipe('en-US').transform(answer.InvoiceForwardedDate)}`;
                }
                if (answer.CourtFees) {
                    this.specialFollowupText = this.specialFollowupText = this.specialFollowupText ? this.specialFollowupText + ' Court Case Fee - $' + answer.CourtFees.Fees : 'Court Case Fee - $' + answer.CourtFees.Fees.toFixed(2);
                }
                if (answer.HasRequestProcessedViaSubpoena) {
                    this.specialFollowupText = this.specialFollowupText ? this.specialFollowupText + `;Request processed via subpoena` : `Request processed via subpoena` ;
                }
                if (answer.BodyPartsToBeExamined) {
                    this.bodyPartsToBeExamined = answer.BodyPartsToBeExamined;
                }
                if (answer.ReportDueBy) {
                    this.reportDueBy = this.getDateWithNoOffset(new Date(answer.ReportDueBy.toString())).toLocaleDateString();
                }
                if (answer.ShouldSplitBilling) {
                     this.specialFollowupText = this.specialFollowupText
                         ? `${this.specialFollowupText} \nCost of Service is being split amongst multiple parties`
                         : 'Cost of Service is being split amongst multiple parties';
                }
            }),
        );
    }

    seeAllFollowups(): void {
        void this.commonRouter.navigate([`cases/${this.imedClaimServiceModel.ImedClaimId}/services/${this.imedClaimServiceId}/follow-ups/`]);
    }

    addFollowup(): void {
        void this.commonRouter.navigate([`cases/${this.imedClaimServiceModel.ImedClaimId}/services/${this.imedClaimServiceId}/follow-ups/add`]);
    }

    //#endregion

    //#region Staus Log
    close(): void {
        void this.commonRouter.navigate([`cases/${this.imedClaimServiceModel.ImedClaimId}/services`]);
    }
    //#endregion

    //#region Set Form Rendered
    setFormRendered(): void {
        this.formRendered = true;
    }
    //#endregion

    otherAttachmentChanged(_): void {
        this.commonImedClaimServiceService.emitChange(this.imedClaimServiceModel);
    }

    //#region Attachments
    attachmentChanged(_): void {
        this.commonImedClaimServiceService.emitChange(this.imedClaimServiceModel);
    }
    //#endregion

    emitServiceRefreshForStatus(): void {
        this.onRefreshStatus.emit();
    }

    openAutomatedTemplatesModal(): void {
        this.onGenerateTemplateClick.emit(false);
    }

    openSubpoenaPackageTemplatesModel(): void {
        this.onGenerateSubpoenaPackageClick.emit();
    }

    openCompletedSubpoenaPackageTemplatesModel(): void {
        this.onGenerateCompletedSubpoenaPackageClick.emit();
    }

    openSubpoenaTemplatesModel(replaceSignature: boolean): void {
        this.onGenerateSubpoenaClick.emit(replaceSignature);
    }

    openGenerateNF10Form(): void {
        this.onGenerateTemplateClick.emit(true);
    }

    resetFaxComponents(): void {
        // force destroy and recreate app-fax-email components so that CKEditor doesn't error out
        this.appFaxEmailComponentExists = false;
        setTimeout(() => {
            this.appFaxEmailComponentExists = true;
        });
    }

    getDateWithNoOffset(date: Date): Date {
        const userTimezoneOffset = date.getTimezoneOffset() * 60000;
        return new Date(date.getTime() + userTimezoneOffset);
    }
}
