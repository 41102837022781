// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            h3.section-divider {
                color: #858a9f;
                border-bottom: solid 1px #ccc;
            }
        `, "",{"version":3,"sources":["webpack://./src/app/address-books/add/address-books-add-facility.component.ts"],"names":[],"mappings":";YACY;gBACI,cAAc;gBACd,6BAA6B;YACjC","sourcesContent":["\n            h3.section-divider {\n                color: #858a9f;\n                border-bottom: solid 1px #ccc;\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
