import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { IRecordReviewService } from '../interfaces/record-review-service';
import { IAddressBook } from '../interfaces/address-book';
import { IIndividual } from '../interfaces/individual';
import { IUser } from '../interfaces/user';
import { IImedClaimService } from '../interfaces/imed-claim-service';
import { ILanguage } from '../interfaces/language';

export interface IRecordReviewServiceDynamicControlsParameters {
    formGroup?: string;
    imedClaimServices?: IImedClaimService[];
    createdBies?: IUser[];
    modifiedBies?: IUser[];
    translationServicesVendorAddressBooks?: IAddressBook[];
    translationServicesVendorIndividuals?: IIndividual[];
    languageTypes?: ILanguage[];
    backgroundInvestigationVendorAddressBooks?: IAddressBook[];
    backgroundInvestigationVendorIndividuals?: IIndividual[];
    transportationServicesVendorAddressBooks?: IAddressBook[];
    transportationServicesVendorIndividuals?: IIndividual[];
    surveillanceVendorAddressBooks?: IAddressBook[];
    surveillanceVendorIndividuals?: IIndividual[];
    requestDateChangedBies?: IUser[];
    rentalFacilityVendorAddressBooks?: IAddressBook[];
    rentalFacilityVendorIndividuals?: IIndividual[];
    transcriptionServicesVendorAddressBooks?: IAddressBook[];
    transcriptionServicesVendorIndividuals?: IIndividual[];
}

export class RecordReviewServiceDynamicControls {

    formGroup: string;
    imedClaimServices?: IImedClaimService[];
    createdBies?: IUser[];
    modifiedBies?: IUser[];
    translationServicesVendorAddressBooks?: IAddressBook[];
    translationServicesVendorIndividuals?: IIndividual[];
    languageTypes?: ILanguage[];
    backgroundInvestigationVendorAddressBooks?: IAddressBook[];
    backgroundInvestigationVendorIndividuals?: IIndividual[];
    transportationServicesVendorAddressBooks?: IAddressBook[];
    transportationServicesVendorIndividuals?: IIndividual[];
    surveillanceVendorAddressBooks?: IAddressBook[];
    surveillanceVendorIndividuals?: IIndividual[];
    requestDateChangedBies?: IUser[];
    rentalFacilityVendorAddressBooks?: IAddressBook[];
    rentalFacilityVendorIndividuals?: IIndividual[];
    transcriptionServicesVendorAddressBooks?: IAddressBook[];
    transcriptionServicesVendorIndividuals?: IIndividual[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private recordreviewservice?: IRecordReviewService, additionalParameters?: IRecordReviewServiceDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'RecordReviewService';
        this.imedClaimServices = additionalParameters && additionalParameters.imedClaimServices || undefined;
        this.createdBies = additionalParameters && additionalParameters.createdBies || undefined;
        this.modifiedBies = additionalParameters && additionalParameters.modifiedBies || undefined;
        this.translationServicesVendorAddressBooks = additionalParameters && additionalParameters.translationServicesVendorAddressBooks || undefined;
        this.translationServicesVendorIndividuals = additionalParameters && additionalParameters.translationServicesVendorIndividuals || undefined;
        this.languageTypes = additionalParameters && additionalParameters.languageTypes || undefined;
        this.backgroundInvestigationVendorAddressBooks = additionalParameters && additionalParameters.backgroundInvestigationVendorAddressBooks || undefined;
        this.backgroundInvestigationVendorIndividuals = additionalParameters && additionalParameters.backgroundInvestigationVendorIndividuals || undefined;
        this.transportationServicesVendorAddressBooks = additionalParameters && additionalParameters.transportationServicesVendorAddressBooks || undefined;
        this.transportationServicesVendorIndividuals = additionalParameters && additionalParameters.transportationServicesVendorIndividuals || undefined;
        this.surveillanceVendorAddressBooks = additionalParameters && additionalParameters.surveillanceVendorAddressBooks || undefined;
        this.surveillanceVendorIndividuals = additionalParameters && additionalParameters.surveillanceVendorIndividuals || undefined;
        this.requestDateChangedBies = additionalParameters && additionalParameters.requestDateChangedBies || undefined;
        this.rentalFacilityVendorAddressBooks = additionalParameters && additionalParameters.rentalFacilityVendorAddressBooks || undefined;
        this.rentalFacilityVendorIndividuals = additionalParameters && additionalParameters.rentalFacilityVendorIndividuals || undefined;
        this.transcriptionServicesVendorAddressBooks = additionalParameters && additionalParameters.transcriptionServicesVendorAddressBooks || undefined;
        this.transcriptionServicesVendorIndividuals = additionalParameters && additionalParameters.transcriptionServicesVendorIndividuals || undefined;

        this.Form = {
            Archived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived',
                name: 'Archived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordreviewservice && this.recordreviewservice.hasOwnProperty('Archived') && this.recordreviewservice.Archived != null ? this.recordreviewservice.Archived : false,
            }),
            BackgroundInvestigationNotes: new DynamicField({
                formGroup: this.formGroup,
                label: 'Background Investigation Notes',
                name: 'BackgroundInvestigationNotes',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.recordreviewservice && this.recordreviewservice.hasOwnProperty('BackgroundInvestigationNotes') && this.recordreviewservice.BackgroundInvestigationNotes != null ? this.recordreviewservice.BackgroundInvestigationNotes.toString() : '',
            }),
            BackgroundInvestigationRequested: new DynamicField({
                formGroup: this.formGroup,
                label: 'Background Investigation Requested',
                name: 'BackgroundInvestigationRequested',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordreviewservice && this.recordreviewservice.hasOwnProperty('BackgroundInvestigationRequested') && this.recordreviewservice.BackgroundInvestigationRequested != null ? this.recordreviewservice.BackgroundInvestigationRequested : false,
            }),
            BackgroundInvestigationScheduledDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Background Investigation Scheduled Date',
                name: 'BackgroundInvestigationScheduledDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordreviewservice && this.recordreviewservice.BackgroundInvestigationScheduledDate || null,
            }),
            BackgroundInvestigationVendorAddressBookId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Background Investigation Vendor Address Book',
                name: 'BackgroundInvestigationVendorAddressBookId',
                options: this.backgroundInvestigationVendorAddressBooks,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordreviewservice && this.recordreviewservice.BackgroundInvestigationVendorAddressBookId || null,
            }),
            BackgroundInvestigationVendorIndividualId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Background Investigation Vendor Individual',
                name: 'BackgroundInvestigationVendorIndividualId',
                options: this.backgroundInvestigationVendorIndividuals,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordreviewservice && this.recordreviewservice.BackgroundInvestigationVendorIndividualId || null,
            }),
            ConflictOfInterestApproved: new DynamicField({
                formGroup: this.formGroup,
                label: 'Conflict Of Interest Approved',
                name: 'ConflictOfInterestApproved',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordreviewservice && this.recordreviewservice.hasOwnProperty('ConflictOfInterestApproved') && this.recordreviewservice.ConflictOfInterestApproved != null ? this.recordreviewservice.ConflictOfInterestApproved : false,
            }),
            CreatedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Created By',
                name: 'CreatedById',
                options: this.createdBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.recordreviewservice && this.recordreviewservice.CreatedById || null,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordreviewservice && this.recordreviewservice.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordreviewservice && this.recordreviewservice.DateModified || null,
            }),
            HasPreLitigation: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Pre Litigation',
                name: 'HasPreLitigation',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordreviewservice && this.recordreviewservice.hasOwnProperty('HasPreLitigation') && this.recordreviewservice.HasPreLitigation != null ? this.recordreviewservice.HasPreLitigation : false,
            }),
            ImedClaimServiceId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Imed Claim Service',
                name: 'ImedClaimServiceId',
                options: this.imedClaimServices,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.recordreviewservice && this.recordreviewservice.ImedClaimServiceId || null,
            }),
            IsFederalCase: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Federal Case',
                name: 'IsFederalCase',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordreviewservice && this.recordreviewservice.hasOwnProperty('IsFederalCase') && this.recordreviewservice.IsFederalCase != null ? this.recordreviewservice.IsFederalCase : false,
            }),
            LanguageTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Language Type',
                name: 'LanguageTypeId',
                options: this.languageTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordreviewservice && this.recordreviewservice.LanguageTypeId || null,
            }),
            LegacyImedClaimServiceId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Legacy Imed Claim Service',
                name: 'LegacyImedClaimServiceId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(10) ],
                validators: { 'maxlength': 10 },
                value: this.recordreviewservice && this.recordreviewservice.hasOwnProperty('LegacyImedClaimServiceId') && this.recordreviewservice.LegacyImedClaimServiceId != null ? this.recordreviewservice.LegacyImedClaimServiceId.toString() : '',
            }),
            ModifiedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Modified By',
                name: 'ModifiedById',
                options: this.modifiedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordreviewservice && this.recordreviewservice.ModifiedById || null,
            }),
            PatientPreviouslyTreatedByPhysician: new DynamicField({
                formGroup: this.formGroup,
                label: 'Patient Previously Treated By Physician',
                name: 'PatientPreviouslyTreatedByPhysician',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordreviewservice && this.recordreviewservice.hasOwnProperty('PatientPreviouslyTreatedByPhysician') && this.recordreviewservice.PatientPreviouslyTreatedByPhysician != null ? this.recordreviewservice.PatientPreviouslyTreatedByPhysician : false,
            }),
            RentalFacilityNotes: new DynamicField({
                formGroup: this.formGroup,
                label: 'Rental Facility Notes',
                name: 'RentalFacilityNotes',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.recordreviewservice && this.recordreviewservice.hasOwnProperty('RentalFacilityNotes') && this.recordreviewservice.RentalFacilityNotes != null ? this.recordreviewservice.RentalFacilityNotes.toString() : '',
            }),
            RentalFacilityRequested: new DynamicField({
                formGroup: this.formGroup,
                label: 'Rental Facility Requested',
                name: 'RentalFacilityRequested',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordreviewservice && this.recordreviewservice.hasOwnProperty('RentalFacilityRequested') && this.recordreviewservice.RentalFacilityRequested != null ? this.recordreviewservice.RentalFacilityRequested : false,
            }),
            RentalFacilityScheduledDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Rental Facility Scheduled Date',
                name: 'RentalFacilityScheduledDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordreviewservice && this.recordreviewservice.RentalFacilityScheduledDate || null,
            }),
            RentalFacilityVendorAddressBookId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Rental Facility Vendor Address Book',
                name: 'RentalFacilityVendorAddressBookId',
                options: this.rentalFacilityVendorAddressBooks,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordreviewservice && this.recordreviewservice.RentalFacilityVendorAddressBookId || null,
            }),
            RentalFacilityVendorIndividualId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Rental Facility Vendor Individual',
                name: 'RentalFacilityVendorIndividualId',
                options: this.rentalFacilityVendorIndividuals,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordreviewservice && this.recordreviewservice.RentalFacilityVendorIndividualId || null,
            }),
            RequestDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Request Date',
                name: 'RequestDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordreviewservice && this.recordreviewservice.RequestDate || null,
            }),
            RequestDateChangedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Request Date Changed By',
                name: 'RequestDateChangedById',
                options: this.requestDateChangedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordreviewservice && this.recordreviewservice.RequestDateChangedById || null,
            }),
            RequestDateChangedDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Request Date Changed Date',
                name: 'RequestDateChangedDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordreviewservice && this.recordreviewservice.RequestDateChangedDate || null,
            }),
            Surveillance: new DynamicField({
                formGroup: this.formGroup,
                label: 'Surveillance',
                name: 'Surveillance',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordreviewservice && this.recordreviewservice.hasOwnProperty('Surveillance') && this.recordreviewservice.Surveillance != null ? this.recordreviewservice.Surveillance : false,
            }),
            SurveillanceNumHoursAuthorized: new DynamicField({
                formGroup: this.formGroup,
                label: 'Surveillance Num Hours Authorized',
                name: 'SurveillanceNumHoursAuthorized',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordreviewservice && this.recordreviewservice.SurveillanceNumHoursAuthorized || null,
            }),
            SurveillanceNumOperativesAuthorized: new DynamicField({
                formGroup: this.formGroup,
                label: 'Surveillance Num Operatives Authorized',
                name: 'SurveillanceNumOperativesAuthorized',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordreviewservice && this.recordreviewservice.SurveillanceNumOperativesAuthorized || null,
            }),
            SurveillanceScheduledDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Surveillance Scheduled Date',
                name: 'SurveillanceScheduledDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordreviewservice && this.recordreviewservice.SurveillanceScheduledDate || null,
            }),
            SurveillanceVendorAddressBookId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Surveillance Vendor Address Book',
                name: 'SurveillanceVendorAddressBookId',
                options: this.surveillanceVendorAddressBooks,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordreviewservice && this.recordreviewservice.SurveillanceVendorAddressBookId || null,
            }),
            SurveillanceVendorIndividualId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Surveillance Vendor Individual',
                name: 'SurveillanceVendorIndividualId',
                options: this.surveillanceVendorIndividuals,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordreviewservice && this.recordreviewservice.SurveillanceVendorIndividualId || null,
            }),
            TranscriptionServicesNotes: new DynamicField({
                formGroup: this.formGroup,
                label: 'Transcription Services Notes',
                name: 'TranscriptionServicesNotes',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.recordreviewservice && this.recordreviewservice.hasOwnProperty('TranscriptionServicesNotes') && this.recordreviewservice.TranscriptionServicesNotes != null ? this.recordreviewservice.TranscriptionServicesNotes.toString() : '',
            }),
            TranscriptionServicesRequested: new DynamicField({
                formGroup: this.formGroup,
                label: 'Transcription Services Requested',
                name: 'TranscriptionServicesRequested',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordreviewservice && this.recordreviewservice.hasOwnProperty('TranscriptionServicesRequested') && this.recordreviewservice.TranscriptionServicesRequested != null ? this.recordreviewservice.TranscriptionServicesRequested : false,
            }),
            TranscriptionServicesScheduledDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Transcription Services Scheduled Date',
                name: 'TranscriptionServicesScheduledDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordreviewservice && this.recordreviewservice.TranscriptionServicesScheduledDate || null,
            }),
            TranscriptionServicesVendorAddressBookId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Transcription Services Vendor Address Book',
                name: 'TranscriptionServicesVendorAddressBookId',
                options: this.transcriptionServicesVendorAddressBooks,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordreviewservice && this.recordreviewservice.TranscriptionServicesVendorAddressBookId || null,
            }),
            TranscriptionServicesVendorIndividualId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Transcription Services Vendor Individual',
                name: 'TranscriptionServicesVendorIndividualId',
                options: this.transcriptionServicesVendorIndividuals,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordreviewservice && this.recordreviewservice.TranscriptionServicesVendorIndividualId || null,
            }),
            TranslationServicesNotes: new DynamicField({
                formGroup: this.formGroup,
                label: 'Translation Services Notes',
                name: 'TranslationServicesNotes',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.recordreviewservice && this.recordreviewservice.hasOwnProperty('TranslationServicesNotes') && this.recordreviewservice.TranslationServicesNotes != null ? this.recordreviewservice.TranslationServicesNotes.toString() : '',
            }),
            TranslationServicesRequested: new DynamicField({
                formGroup: this.formGroup,
                label: 'Translation Services Requested',
                name: 'TranslationServicesRequested',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordreviewservice && this.recordreviewservice.hasOwnProperty('TranslationServicesRequested') && this.recordreviewservice.TranslationServicesRequested != null ? this.recordreviewservice.TranslationServicesRequested : false,
            }),
            TranslationServicesScheduledDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Translation Services Scheduled Date',
                name: 'TranslationServicesScheduledDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordreviewservice && this.recordreviewservice.TranslationServicesScheduledDate || null,
            }),
            TranslationServicesVendorAddressBookId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Translation Services Vendor Address Book',
                name: 'TranslationServicesVendorAddressBookId',
                options: this.translationServicesVendorAddressBooks,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordreviewservice && this.recordreviewservice.TranslationServicesVendorAddressBookId || null,
            }),
            TranslationServicesVendorIndividualId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Translation Services Vendor Individual',
                name: 'TranslationServicesVendorIndividualId',
                options: this.translationServicesVendorIndividuals,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordreviewservice && this.recordreviewservice.TranslationServicesVendorIndividualId || null,
            }),
            TransportationServicesNotes: new DynamicField({
                formGroup: this.formGroup,
                label: 'Transportation Services Notes',
                name: 'TransportationServicesNotes',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.recordreviewservice && this.recordreviewservice.hasOwnProperty('TransportationServicesNotes') && this.recordreviewservice.TransportationServicesNotes != null ? this.recordreviewservice.TransportationServicesNotes.toString() : '',
            }),
            TransportationServicesRequested: new DynamicField({
                formGroup: this.formGroup,
                label: 'Transportation Services Requested',
                name: 'TransportationServicesRequested',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordreviewservice && this.recordreviewservice.hasOwnProperty('TransportationServicesRequested') && this.recordreviewservice.TransportationServicesRequested != null ? this.recordreviewservice.TransportationServicesRequested : false,
            }),
            TransportationServicesScheduledDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Transportation Services Scheduled Date',
                name: 'TransportationServicesScheduledDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordreviewservice && this.recordreviewservice.TransportationServicesScheduledDate || null,
            }),
            TransportationServicesVendorAddressBookId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Transportation Services Vendor Address Book',
                name: 'TransportationServicesVendorAddressBookId',
                options: this.transportationServicesVendorAddressBooks,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordreviewservice && this.recordreviewservice.TransportationServicesVendorAddressBookId || null,
            }),
            TransportationServicesVendorIndividualId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Transportation Services Vendor Individual',
                name: 'TransportationServicesVendorIndividualId',
                options: this.transportationServicesVendorIndividuals,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordreviewservice && this.recordreviewservice.TransportationServicesVendorIndividualId || null,
            }),
        };

        this.View = {
            Archived: new DynamicLabel({
                label: 'Archived',
                value: this.recordreviewservice && this.recordreviewservice.hasOwnProperty('Archived') && this.recordreviewservice.Archived != null ? this.recordreviewservice.Archived : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            BackgroundInvestigationNotes: new DynamicLabel({
                label: 'Background Investigation Notes',
                value: this.recordreviewservice && this.recordreviewservice.hasOwnProperty('BackgroundInvestigationNotes') && this.recordreviewservice.BackgroundInvestigationNotes != null ? this.recordreviewservice.BackgroundInvestigationNotes.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            BackgroundInvestigationRequested: new DynamicLabel({
                label: 'Background Investigation Requested',
                value: this.recordreviewservice && this.recordreviewservice.hasOwnProperty('BackgroundInvestigationRequested') && this.recordreviewservice.BackgroundInvestigationRequested != null ? this.recordreviewservice.BackgroundInvestigationRequested : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            BackgroundInvestigationScheduledDate: new DynamicLabel({
                label: 'Background Investigation Scheduled Date',
                value: this.recordreviewservice && this.recordreviewservice.BackgroundInvestigationScheduledDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            BackgroundInvestigationVendorAddressBookId: new DynamicLabel({
                label: 'Background Investigation Vendor Address Book',
                value: getMetaItemValue(this.backgroundInvestigationVendorAddressBooks as unknown as IMetaItem[], this.recordreviewservice && this.recordreviewservice.hasOwnProperty('BackgroundInvestigationVendorAddressBookId') ? this.recordreviewservice.BackgroundInvestigationVendorAddressBookId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            BackgroundInvestigationVendorIndividualId: new DynamicLabel({
                label: 'Background Investigation Vendor Individual',
                value: getMetaItemValue(this.backgroundInvestigationVendorIndividuals as unknown as IMetaItem[], this.recordreviewservice && this.recordreviewservice.hasOwnProperty('BackgroundInvestigationVendorIndividualId') ? this.recordreviewservice.BackgroundInvestigationVendorIndividualId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            ConflictOfInterestApproved: new DynamicLabel({
                label: 'Conflict Of Interest Approved',
                value: this.recordreviewservice && this.recordreviewservice.hasOwnProperty('ConflictOfInterestApproved') && this.recordreviewservice.ConflictOfInterestApproved != null ? this.recordreviewservice.ConflictOfInterestApproved : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            CreatedById: new DynamicLabel({
                label: 'Created By',
                value: getMetaItemValue(this.createdBies as unknown as IMetaItem[], this.recordreviewservice && this.recordreviewservice.hasOwnProperty('CreatedById') ? this.recordreviewservice.CreatedById : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            DateCreated: new DynamicLabel({
                label: 'Date Created',
                value: this.recordreviewservice && this.recordreviewservice.DateCreated || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            DateModified: new DynamicLabel({
                label: 'Date Modified',
                value: this.recordreviewservice && this.recordreviewservice.DateModified || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            HasPreLitigation: new DynamicLabel({
                label: 'Has Pre Litigation',
                value: this.recordreviewservice && this.recordreviewservice.hasOwnProperty('HasPreLitigation') && this.recordreviewservice.HasPreLitigation != null ? this.recordreviewservice.HasPreLitigation : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            ImedClaimServiceId: new DynamicLabel({
                label: 'Imed Claim Service',
                value: getMetaItemValue(this.imedClaimServices as unknown as IMetaItem[], this.recordreviewservice && this.recordreviewservice.hasOwnProperty('ImedClaimServiceId') ? this.recordreviewservice.ImedClaimServiceId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            IsFederalCase: new DynamicLabel({
                label: 'Is Federal Case',
                value: this.recordreviewservice && this.recordreviewservice.hasOwnProperty('IsFederalCase') && this.recordreviewservice.IsFederalCase != null ? this.recordreviewservice.IsFederalCase : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            LanguageTypeId: new DynamicLabel({
                label: 'Language Type',
                value: getMetaItemValue(this.languageTypes as unknown as IMetaItem[], this.recordreviewservice && this.recordreviewservice.hasOwnProperty('LanguageTypeId') ? this.recordreviewservice.LanguageTypeId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            LegacyImedClaimServiceId: new DynamicLabel({
                label: 'Legacy Imed Claim Service',
                value: this.recordreviewservice && this.recordreviewservice.hasOwnProperty('LegacyImedClaimServiceId') && this.recordreviewservice.LegacyImedClaimServiceId != null ? this.recordreviewservice.LegacyImedClaimServiceId.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            ModifiedById: new DynamicLabel({
                label: 'Modified By',
                value: getMetaItemValue(this.modifiedBies as unknown as IMetaItem[], this.recordreviewservice && this.recordreviewservice.hasOwnProperty('ModifiedById') ? this.recordreviewservice.ModifiedById : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            PatientPreviouslyTreatedByPhysician: new DynamicLabel({
                label: 'Patient Previously Treated By Physician',
                value: this.recordreviewservice && this.recordreviewservice.hasOwnProperty('PatientPreviouslyTreatedByPhysician') && this.recordreviewservice.PatientPreviouslyTreatedByPhysician != null ? this.recordreviewservice.PatientPreviouslyTreatedByPhysician : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            RentalFacilityNotes: new DynamicLabel({
                label: 'Rental Facility Notes',
                value: this.recordreviewservice && this.recordreviewservice.hasOwnProperty('RentalFacilityNotes') && this.recordreviewservice.RentalFacilityNotes != null ? this.recordreviewservice.RentalFacilityNotes.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            RentalFacilityRequested: new DynamicLabel({
                label: 'Rental Facility Requested',
                value: this.recordreviewservice && this.recordreviewservice.hasOwnProperty('RentalFacilityRequested') && this.recordreviewservice.RentalFacilityRequested != null ? this.recordreviewservice.RentalFacilityRequested : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            RentalFacilityScheduledDate: new DynamicLabel({
                label: 'Rental Facility Scheduled Date',
                value: this.recordreviewservice && this.recordreviewservice.RentalFacilityScheduledDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            RentalFacilityVendorAddressBookId: new DynamicLabel({
                label: 'Rental Facility Vendor Address Book',
                value: getMetaItemValue(this.rentalFacilityVendorAddressBooks as unknown as IMetaItem[], this.recordreviewservice && this.recordreviewservice.hasOwnProperty('RentalFacilityVendorAddressBookId') ? this.recordreviewservice.RentalFacilityVendorAddressBookId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            RentalFacilityVendorIndividualId: new DynamicLabel({
                label: 'Rental Facility Vendor Individual',
                value: getMetaItemValue(this.rentalFacilityVendorIndividuals as unknown as IMetaItem[], this.recordreviewservice && this.recordreviewservice.hasOwnProperty('RentalFacilityVendorIndividualId') ? this.recordreviewservice.RentalFacilityVendorIndividualId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            RequestDate: new DynamicLabel({
                label: 'Request Date',
                value: this.recordreviewservice && this.recordreviewservice.RequestDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            RequestDateChangedById: new DynamicLabel({
                label: 'Request Date Changed By',
                value: getMetaItemValue(this.requestDateChangedBies as unknown as IMetaItem[], this.recordreviewservice && this.recordreviewservice.hasOwnProperty('RequestDateChangedById') ? this.recordreviewservice.RequestDateChangedById : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            RequestDateChangedDate: new DynamicLabel({
                label: 'Request Date Changed Date',
                value: this.recordreviewservice && this.recordreviewservice.RequestDateChangedDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            Surveillance: new DynamicLabel({
                label: 'Surveillance',
                value: this.recordreviewservice && this.recordreviewservice.hasOwnProperty('Surveillance') && this.recordreviewservice.Surveillance != null ? this.recordreviewservice.Surveillance : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            SurveillanceNumHoursAuthorized: new DynamicLabel({
                label: 'Surveillance Num Hours Authorized',
                value: this.recordreviewservice && this.recordreviewservice.SurveillanceNumHoursAuthorized || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            SurveillanceNumOperativesAuthorized: new DynamicLabel({
                label: 'Surveillance Num Operatives Authorized',
                value: this.recordreviewservice && this.recordreviewservice.SurveillanceNumOperativesAuthorized || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            SurveillanceScheduledDate: new DynamicLabel({
                label: 'Surveillance Scheduled Date',
                value: this.recordreviewservice && this.recordreviewservice.SurveillanceScheduledDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            SurveillanceVendorAddressBookId: new DynamicLabel({
                label: 'Surveillance Vendor Address Book',
                value: getMetaItemValue(this.surveillanceVendorAddressBooks as unknown as IMetaItem[], this.recordreviewservice && this.recordreviewservice.hasOwnProperty('SurveillanceVendorAddressBookId') ? this.recordreviewservice.SurveillanceVendorAddressBookId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            SurveillanceVendorIndividualId: new DynamicLabel({
                label: 'Surveillance Vendor Individual',
                value: getMetaItemValue(this.surveillanceVendorIndividuals as unknown as IMetaItem[], this.recordreviewservice && this.recordreviewservice.hasOwnProperty('SurveillanceVendorIndividualId') ? this.recordreviewservice.SurveillanceVendorIndividualId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            TranscriptionServicesNotes: new DynamicLabel({
                label: 'Transcription Services Notes',
                value: this.recordreviewservice && this.recordreviewservice.hasOwnProperty('TranscriptionServicesNotes') && this.recordreviewservice.TranscriptionServicesNotes != null ? this.recordreviewservice.TranscriptionServicesNotes.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            TranscriptionServicesRequested: new DynamicLabel({
                label: 'Transcription Services Requested',
                value: this.recordreviewservice && this.recordreviewservice.hasOwnProperty('TranscriptionServicesRequested') && this.recordreviewservice.TranscriptionServicesRequested != null ? this.recordreviewservice.TranscriptionServicesRequested : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            TranscriptionServicesScheduledDate: new DynamicLabel({
                label: 'Transcription Services Scheduled Date',
                value: this.recordreviewservice && this.recordreviewservice.TranscriptionServicesScheduledDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            TranscriptionServicesVendorAddressBookId: new DynamicLabel({
                label: 'Transcription Services Vendor Address Book',
                value: getMetaItemValue(this.transcriptionServicesVendorAddressBooks as unknown as IMetaItem[], this.recordreviewservice && this.recordreviewservice.hasOwnProperty('TranscriptionServicesVendorAddressBookId') ? this.recordreviewservice.TranscriptionServicesVendorAddressBookId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            TranscriptionServicesVendorIndividualId: new DynamicLabel({
                label: 'Transcription Services Vendor Individual',
                value: getMetaItemValue(this.transcriptionServicesVendorIndividuals as unknown as IMetaItem[], this.recordreviewservice && this.recordreviewservice.hasOwnProperty('TranscriptionServicesVendorIndividualId') ? this.recordreviewservice.TranscriptionServicesVendorIndividualId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            TranslationServicesNotes: new DynamicLabel({
                label: 'Translation Services Notes',
                value: this.recordreviewservice && this.recordreviewservice.hasOwnProperty('TranslationServicesNotes') && this.recordreviewservice.TranslationServicesNotes != null ? this.recordreviewservice.TranslationServicesNotes.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            TranslationServicesRequested: new DynamicLabel({
                label: 'Translation Services Requested',
                value: this.recordreviewservice && this.recordreviewservice.hasOwnProperty('TranslationServicesRequested') && this.recordreviewservice.TranslationServicesRequested != null ? this.recordreviewservice.TranslationServicesRequested : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            TranslationServicesScheduledDate: new DynamicLabel({
                label: 'Translation Services Scheduled Date',
                value: this.recordreviewservice && this.recordreviewservice.TranslationServicesScheduledDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            TranslationServicesVendorAddressBookId: new DynamicLabel({
                label: 'Translation Services Vendor Address Book',
                value: getMetaItemValue(this.translationServicesVendorAddressBooks as unknown as IMetaItem[], this.recordreviewservice && this.recordreviewservice.hasOwnProperty('TranslationServicesVendorAddressBookId') ? this.recordreviewservice.TranslationServicesVendorAddressBookId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            TranslationServicesVendorIndividualId: new DynamicLabel({
                label: 'Translation Services Vendor Individual',
                value: getMetaItemValue(this.translationServicesVendorIndividuals as unknown as IMetaItem[], this.recordreviewservice && this.recordreviewservice.hasOwnProperty('TranslationServicesVendorIndividualId') ? this.recordreviewservice.TranslationServicesVendorIndividualId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            TransportationServicesNotes: new DynamicLabel({
                label: 'Transportation Services Notes',
                value: this.recordreviewservice && this.recordreviewservice.hasOwnProperty('TransportationServicesNotes') && this.recordreviewservice.TransportationServicesNotes != null ? this.recordreviewservice.TransportationServicesNotes.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            TransportationServicesRequested: new DynamicLabel({
                label: 'Transportation Services Requested',
                value: this.recordreviewservice && this.recordreviewservice.hasOwnProperty('TransportationServicesRequested') && this.recordreviewservice.TransportationServicesRequested != null ? this.recordreviewservice.TransportationServicesRequested : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            TransportationServicesScheduledDate: new DynamicLabel({
                label: 'Transportation Services Scheduled Date',
                value: this.recordreviewservice && this.recordreviewservice.TransportationServicesScheduledDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            TransportationServicesVendorAddressBookId: new DynamicLabel({
                label: 'Transportation Services Vendor Address Book',
                value: getMetaItemValue(this.transportationServicesVendorAddressBooks as unknown as IMetaItem[], this.recordreviewservice && this.recordreviewservice.hasOwnProperty('TransportationServicesVendorAddressBookId') ? this.recordreviewservice.TransportationServicesVendorAddressBookId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            TransportationServicesVendorIndividualId: new DynamicLabel({
                label: 'Transportation Services Vendor Individual',
                value: getMetaItemValue(this.transportationServicesVendorIndividuals as unknown as IMetaItem[], this.recordreviewservice && this.recordreviewservice.hasOwnProperty('TransportationServicesVendorIndividualId') ? this.recordreviewservice.TransportationServicesVendorIndividualId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
        };

    }
}
