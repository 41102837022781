import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, OperatorFunction } from 'rxjs';
import { BaseService } from '@mt-ng2/base-service';
import { catchError } from 'rxjs/operators';
import { IImedClaimServiceSplitBillingDetail } from '@model/interfaces/imed-claim-service-split-billing-detail';
import { IImedClaimServiceSplitBillingDetailDTO } from '@model/interfaces/custom/imed-claim-service-split-billing-detail.dto';

@Injectable()
export class ImedClaimServiceSplitBillingDetailService extends BaseService<IImedClaimServiceSplitBillingDetail> {

    constructor(public http: HttpClient) {
        super('/imedclaimservices', http);
    }

    getSplitBillingDetails(imedClaimServiceId: number): Observable<IImedClaimServiceSplitBillingDetailDTO[]> {
        return this.http.get<IImedClaimServiceSplitBillingDetailDTO[]>(`/imedclaimservices/${imedClaimServiceId}/split-billing-details`);
    }

    delete(id: number): Observable<any> {
        return this.http
            .delete(`/imedclaimservices/${id}/split-billing-detail`)
            .pipe(catchError((e: Response) => this.handleError(e)) as OperatorFunction<unknown, any>);
    }
}
