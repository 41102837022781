import { ITestimonyDepositionServiceDate } from '@model/interfaces/testimony-deposition-service-date.d';
import { UntypedFormBuilder, UntypedFormArray } from '@angular/forms';

import { Component, OnInit, Input, ChangeDetectorRef, SimpleChanges, OnChanges, ViewRef, OnDestroy } from '@angular/core';
import { TestimonyDepositionServiceService } from '../testimony-deposition-service.service';
import { TestimonyDepositionServiceDateDynamicControlsPartial } from '@model/partials/testimony-deposition-service-date.form-controls.partials';
import { safeDetectChanges } from '../../../../../common/cdr-safety/cdr-safety.library';

@Component({
    selector: 'app-testimony-deposition-service-date-requested',
    templateUrl: './date-requested.component.html',
})
export class TestimonyDepositionServiceDateRequestedComponent implements OnInit, OnChanges, OnDestroy {
    @Input() datesRequestedFormArray: UntypedFormArray;
    @Input() datesRequested: ITestimonyDepositionServiceDate[];
    abstractDateRequestedControls: any;
    abstractDateRequestedControlsFromValues: any = [];
    formSubmitted = false;
    private emptyDateRequested: ITestimonyDepositionServiceDate;

    constructor(
        private fb: UntypedFormBuilder,
        private cdr: ChangeDetectorRef,
        private testimonyDepositionServiceService: TestimonyDepositionServiceService,
    ) { }

    ngOnInit(): void {
        this.emptyDateRequested = this.testimonyDepositionServiceService.getEmptyTestimonyDepositionServiceDate();
        this.abstractDateRequestedControls = new TestimonyDepositionServiceDateDynamicControlsPartial(this.emptyDateRequested);
        if (!this.datesRequested.length) {
            this.addRow();
            this.addRow();
            this.addRow();
            this.addRow();
            this.addRow();
        } else {
            this.datesRequested.forEach((line) => {
                const abstractControl = new TestimonyDepositionServiceDateDynamicControlsPartial(line);
                this.abstractDateRequestedControlsFromValues.push(abstractControl);
                this.datesRequestedFormArray.controls.push(this.fb.group(line));
            });
        }
    }

    ngOnDestroy(): void {
        this.cdr.detach();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.InputValues && changes.InputValues.previousValue && changes.InputValues.previousValue.length === 0) {
            this.formSubmitted = true;
            // we know the form has been submitted if the input Order detail changes due to how we handle the save
            // I'm including this because the rows have been disappearing on save when there aren't any initial input values
        }
    }

    addRow(): void {
        this.datesRequestedFormArray.controls.push(this.fb.group({}));
        if (this.datesRequested.length) {
            this.abstractDateRequestedControlsFromValues.push(this.abstractDateRequestedControls);
        }
        safeDetectChanges(this.cdr);
    }

    removeRow(i: number): void {
        this.datesRequestedFormArray.controls.splice(i, 1);
        if (this.datesRequested.length) {
            this.abstractDateRequestedControlsFromValues.splice(i, 1);
        }
        safeDetectChanges(this.cdr);
    }
}
