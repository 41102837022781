import { Component, OnInit } from '@angular/core';
import { IImedClaimType } from '@model/interfaces/imed-claim-type';
import { Router } from '@angular/router';
import { ImedClaimTypeService } from '../../common/services/imed-claim-type.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ImedClaimTypes } from '@model/ImedClaimTypes';
import { PopUpTypeEnums } from '../../common/constants/Enums';
import { IPopUpParams, PopUpService } from '../../common/services/popup-service';
import { AuthService } from '@mt-ng2/auth-module';
import { NavContentDynamicRowComponent } from '@mt-ng2/nav-module';

@Component({
    selector: 'nav-cases-menu-item',
    templateUrl: 'nav-cases-menu-item.component.html',
})
export class NavCasesMenuItemComponent extends NavContentDynamicRowComponent implements OnInit {
    imedClaimTypes: IImedClaimType[];
    currentUser: any;

    dropdownIsOpen = false;

    timeout: NodeJS.Timeout = null;

    get rowLinkIsActive(): boolean {
        return this.row.link ? this.router.isActive(this.row.link.link, true) : false;
    }

    constructor(
        private imedClaimTypeService: ImedClaimTypeService,
        private router: Router,
        private notificationsService: NotificationsService,
        private popUpService: PopUpService,
        private authService: AuthService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.currentUser = this.authService.currentUser.getValue();
        this.imedClaimTypeService.getAll().subscribe((imedClaimTypes) => {
            this.imedClaimTypes = imedClaimTypes;
        });
    }

    showPopupWarning(popUp: IPopUpParams, caseType: number): void {
        this.popUpService.showPopUp(popUp).subscribe((response) => {
            if (response.value) {
                void this.router.navigate([`/cases/${caseType}/add/`]);
            } else {
                return;
            }
        });
    }

    toggle(): void {
        this.dropdownIsOpen = !this.dropdownIsOpen;
    }

    setTimeout(): void {
        if (this.dropdownIsOpen) {
            this.timeout = setTimeout(() => (this.dropdownIsOpen = false), 300);
        }
    }

    clearTimeout(): void {
        if (this.dropdownIsOpen) {
            clearTimeout(this.timeout);
        }
    }

    addImedClaim(type: IImedClaimType): void {
        const popUp: IPopUpParams = {
            confirmButtonText: 'Continue',
            html: 'You are trying to add a case outside of your defined role. Do you want to continue?',
            title: 'Case Alert',
            type: PopUpTypeEnums.Soft_Pop_Up,
        };

        switch (type.Id as ImedClaimTypes) {
            case ImedClaimTypes.GeneralLiability:
                if (!this.currentUser.CustomOptions.GeneralLiability) {
                    this.showPopupWarning(popUp, ImedClaimTypes.GeneralLiability);
                } else {
                    void this.router.navigate([`/cases/${ImedClaimTypes.GeneralLiability}/add/`]);
                }
                break;
            case ImedClaimTypes.WorkersCompensation:
                if (!this.currentUser.CustomOptions.WorkersComp) {
                    this.showPopupWarning(popUp, ImedClaimTypes.WorkersCompensation);
                } else {
                    void this.router.navigate([`/cases/${ImedClaimTypes.WorkersCompensation}/add/`]);
                }
                break;
            case ImedClaimTypes.Auto:
                if (!this.currentUser.CustomOptions.Auto) {
                    this.showPopupWarning(popUp, ImedClaimTypes.Auto);
                } else {
                    void this.router.navigate([`/cases/${ImedClaimTypes.Auto}/add/`]);
                }
                break;
            default:
                this.notificationsService.error(`Unknown claim type: ${type.Name}`);
        }
    }

    navigateToLink(): void {
        void this.router.navigate([this.row.link.link]);
    }
}
