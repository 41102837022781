import { IAppFaxEmailComponentApi } from './../../../../../common/fax-email/fax-email-component';
import { CurrencyFormatterService } from './../../../../../common/services/currency-formatter.service';
import {
    SubServiceEnums,
    ServiceStatusIdEnums,
    AttachmentTypeEnums,
    AttachmentServiceTypeEnums,
    ServiceIdEnums,
    FaxEmailEntityTypeEnums,
    PopUpTypeEnums,
    SubpoenaPackageTypeEnums,
} from './../../../../../common/constants/Enums';
import { CommonImedClaimService } from './../../../../shared/common-imed-claim-service';
import { SubServiceService } from './../../../common-components/sub-services/sub-service.service';
import { ImeAppointmentService } from './../../ime-service/ime-appointments/ime-appointment.service';
import { ImedClaimServiceFollowupService } from './../../../common-components/service-followups/imedclaimservicefollowup.service';
import { ImedClaimServiceStatusLogService } from './../../../common-components/service-status/imed-claim-service-status-log.service';
import { ImedClaimService } from './../../../../imedclaim.service';
import { IRecordRetrievalService } from '@model/interfaces/record-retrieval-service.d';
import { AddressBooksSelectService } from './../../../../../common/address-book-select-list/address-books-select.service';
import { CommunicationLogService } from '../../../../../common/services/communication-log.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { ImedClaimServiceService } from '../../../imedclaimservice.service';
import { PhysicianService } from '../../../../../address-books/individuals/physicians/physician.service';
import { ServiceStatusService } from '../../../common-components/service-status/servicestatus.service';
import { BaseAttachmentsService } from '../../../../../common/attachments/base-attachments.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { finalize, tap } from 'rxjs/operators';
import { IFilmInformationDTO } from '@model/interfaces/custom/film-information-dto';
import { ModalService } from '../../../../../common/services/modal.service';
import { AuthService, ClaimsService } from '@mt-ng2/auth-module';
import { IAttachmentDTO } from '@model/interfaces/custom/attachment-dto';
import { FilmInformationService } from '@app-shared/services/film-information.service';
import { FaxEmailService } from '@common/fax-email/fax-email.service';
import { IFilmRetrievalExtrasDTO } from '@model/interfaces/custom/film-retrieval-extras-dto';
import { IDocumentGenerationDTO } from '@model/interfaces/custom/document-generation-dto';
import { HttpResponse } from '@angular/common/http';
import { IName, NameTypeEnum } from '@model/interfaces/custom/name';
import { ISubpoenaService } from '@model/interfaces/subpoena-service';
import { RecordRetrievalServiceService } from '../../record-retrieval-service/record-retrieval-service.service';
import { SubpoenaServiceService } from '../subpoena-service.service';
import { IImedClaimCourtFee } from '@model/interfaces/imed-claim-court-fee';
import { IImedClaimServiceCourtFee } from '@model/interfaces/imed-claim-service-court-fee';
import { IImedClaimServiceProcessServerFee } from '@model/interfaces/imed-claim-service-process-server-fee';
import { IImedClaimServiceWitnessFee } from '@model/interfaces/imed-claim-service-witness-fee';
import { ISubpoenaSignatureStatusDto } from '@model/interfaces/custom/subpoena-signature-status.dto';
import { IPopUpParams, PopUpService } from '@common/services/popup-service';
import { IImedClaimServiceFacilityFee } from '@model/interfaces/imed-claim-service-facility-fee';

@Component({
    selector: 'app-subpoena-service-detail',
    templateUrl: './subpoena-service-detail.component.html',
})
export class SubpoenaServiceDetailComponent extends CommonImedClaimService implements OnInit, OnDestroy {
    subpoenaServiceModel: ISubpoenaService;
    recordRetrievalServiceModel: IRecordRetrievalService;
    isPhysicianOrFacilitySelected = false;
    doubleClickIsDisabled = false;
    showSubpoenaPackage = false;
    showCompletedSubpoenaPackage = false;
    plaintiffAttorneyRequireCopy = false;
    subpoenaSignatureStatus: ISubpoenaSignatureStatusDto;

    faxEmailComponentApi: IAppFaxEmailComponentApi;
    rejectionFaxEmailComponentApi: IAppFaxEmailComponentApi;
    complexFaxEmailComponentApi: IAppFaxEmailComponentApi;
    appLabelFaxEmailComponentApi: IAppFaxEmailComponentApi;
    noRecordOnFileFaxEmailComponentApi: IAppFaxEmailComponentApi;
    undeliverableRecordsFaxEmailComponentApi: IAppFaxEmailComponentApi;
    additionalRecordsFaxEmailComponentApi: IAppFaxEmailComponentApi;

    attachmentServiceTypeEnum: AttachmentServiceTypeEnums = AttachmentServiceTypeEnums.Subpoena_Service;
    entityTypeEnums = FaxEmailEntityTypeEnums;

    documentArray: IAttachmentDTO[] = [];

    // popups
    attorneySignaturePresentPopUp = 'Is Attorney signature present?';
    domesticationServicePopup =
        'Please send generated subpoenas to the clerk to sign and upload them back to service when received from the clerk. Don’t forget to apply “Executed Subpoena” type to both subpoenas when uploading them back to the system.';
    noSignaturePopup =
        'No signature present on document.  Please upload a Signature and Signature authorization for the Client in the Address Book and try again or upload an Executed Subpoena to the Service.';
    electronicSignaturePopup =
        'There is no signature available for the Defense Attorney.  Click CONTINUE to apply an electronic signature or CANCEL to upload an Executed Subpoena';
    plaintiffAttorneyPopup =
        'Plaintiff’s Attorney has requested a copy of the records as well.  Please add them to the CC of the email that you are sending or send the records to them separately, immediately';

    constructor(
        private communicationLogService: CommunicationLogService,
        private router: Router,
        private route: ActivatedRoute,
        private imedclaimServiceStausLogService: ImedClaimServiceStatusLogService,
        private imedClaimServiceFollowupService: ImedClaimServiceFollowupService,
        private imedClaimServiceService: ImedClaimServiceService,
        private addressBookSelectService: AddressBooksSelectService,
        private currencyFormatterService: CurrencyFormatterService,
        private physicianService: PhysicianService,
        private imeAppointmentService: ImeAppointmentService,
        private subServiceService: SubServiceService,
        private imedClaimService: ImedClaimService,
        private serviceStatusService: ServiceStatusService,
        private recordRetrievalServiceService: RecordRetrievalServiceService,
        private subpoenaServiceService: SubpoenaServiceService,
        private filmInformationService: FilmInformationService,
        private attachmentsService: BaseAttachmentsService,
        private notificationsService: NotificationsService,
        private modalService: ModalService,
        private claimService: ClaimsService,
        private authService: AuthService,
        protected faxEmailService: FaxEmailService,
        private popUpService: PopUpService,
    ) {
        super(
            communicationLogService,
            router,
            route,
            imedclaimServiceStausLogService,
            imedClaimServiceFollowupService,
            imedClaimServiceService,
            addressBookSelectService,
            currencyFormatterService,
            physicianService,
            imeAppointmentService,
            subServiceService,
            imedClaimService,
            serviceStatusService,
            attachmentsService,
            modalService,
            claimService,
            authService,
        );
    }

    ngOnInit(): void {
        this.subscriptions.add(
            this.imedClaimServiceService.changeEmitted$.subscribe((imedClaimService) => {
                this.imedClaimServiceModel = imedClaimService;
                this.setIsPhysicianOrFacilitySelected();
                this.getIsValidSubpoenaUploaded().subscribe();
                this.getIsValidCompletedSubpoenaUploaded().subscribe();
            }),
        );

        forkJoin(
            this.getImedClaimServiceStatusLog(),
            this.getCommunicationLog(),
            this.getFollowups(),
            this.getServiceStatuses(),
            this.getPrimaryEmailForImedClaimDefenseAttorney(),
            this.getLatestAppointmentByImedClaimServiceId(),
            this.getSubpoenaServiceByImedClaimServiceId(),
            this.getServiceCourtFees(),
            this.getServiceProcessFees(),
            this.getWitnessFees(),
            this.getFacilityFees(),
            this.getDocuments(),
            this.getIsValidSubpoenaUploaded(),
            this.getIsValidCompletedSubpoenaUploaded(),
            this.doesPlaintiffAttorneyRequireCopy(),
        ).subscribe(() => {
            this.subscribeToCommunicationLogs();
            this.setPhysicianInformations();
            this.setIsPhysicianOrFacilitySelected(); // 1 - 1 there is not going to be more than 1 ImeServices_ImedClaimServiceId records
            this.setFormRendered();
            this.setDisablePhysicianFacilityControl();
        });

        this.editingComponent.next('');
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    getDocuments(): Observable<HttpResponse<IAttachmentDTO[]>> {
        return this.attachmentsService.all('imed-claim-service', this.imedClaimServiceModel.Id, '').pipe(
            tap((response) => {
                this.documentArray = response.body;
            }),
        );
    }

    getClaimantName(): IName {
        return {
            firstName: this.imedClaimServiceModel.ImedClaim.FirstName,
            lastName: this.imedClaimServiceModel.ImedClaim.LastName,
            nameType: NameTypeEnum.Individual,
        };
    }

    getSubpoenaServiceByImedClaimServiceId(): Observable<ISubpoenaService> {
        return this.subpoenaServiceService
            .getByImedClaimServiceId(this.imedClaimServiceModel.Id)
            .pipe(tap((subpoenaService: ISubpoenaService) => (this.subpoenaServiceModel = subpoenaService)));
    }

    getCaseCourtFees(): Observable<IImedClaimCourtFee> {
        return this.subpoenaServiceService.getCaseCourtFee(this.imedClaimServiceModel.Id).pipe(
            tap((caseCourtFee: IImedClaimCourtFee) => {
                if (caseCourtFee != null) {
                    this.specialFollowupText = this.specialFollowupText
                        ? this.specialFollowupText + ' Court Case Fee - $' + caseCourtFee.Fees
                        : 'Court Case Fee - $' + caseCourtFee.Fees.toFixed(2);
                }
            }),
        );
    }

    getServiceCourtFees(): Observable<IImedClaimServiceCourtFee> {
        return this.subpoenaServiceService.getCourtServiceFee(this.imedClaimServiceModel.Id).pipe(
            tap((serviceCourtFee: IImedClaimServiceCourtFee) => {
                if (serviceCourtFee != null) {
                    this.specialFollowupText = this.specialFollowupText
                        ? this.specialFollowupText + '| Court Service Fee - $' + serviceCourtFee.Fees.toFixed(2)
                        : 'Court Service Fee - $' + serviceCourtFee.Fees.toFixed(2);
                }
            }),
        );
    }

    getServiceProcessFees(): Observable<IImedClaimServiceProcessServerFee> {
        return this.subpoenaServiceService.getProcessServerServiceFee(this.imedClaimServiceModel.Id).pipe(
            tap((serviceProcessServerFee: IImedClaimServiceCourtFee) => {
                if (serviceProcessServerFee != null) {
                    this.specialFollowupText = this.specialFollowupText
                        ? this.specialFollowupText + '| Process Server Fee - $' + serviceProcessServerFee.Fees.toFixed(2)
                        : 'Process Service Fee - $' + serviceProcessServerFee.Fees.toFixed(2);
                }
            }),
        );
    }

    getWitnessFees(): Observable<IImedClaimServiceWitnessFee> {
        return this.subpoenaServiceService.getWitnessFee(this.imedClaimServiceModel.Id).pipe(
            tap((witnessFee: IImedClaimServiceWitnessFee) => {
                if (witnessFee != null) {
                    this.specialFollowupText = this.specialFollowupText
                        ? this.specialFollowupText + '| Witness Fee - $' + witnessFee.Fees.toFixed(2)
                        : 'Witness Fee - $' + witnessFee.Fees.toFixed(2);
                }
            }),
        );
    }

    getFacilityFees(): Observable<IImedClaimServiceFacilityFee> {
        return this.subpoenaServiceService.getLatestFacilityFee(this.imedClaimServiceModel.Id).pipe(
            tap((facilityFee: IImedClaimServiceFacilityFee) => {
                if (facilityFee != null) {
                    this.specialFollowupText = this.specialFollowupText
                        ? this.specialFollowupText + '| Facility Fee - $' + facilityFee.Fees.toFixed(2)
                        : 'Facility Fee - $' + facilityFee.Fees.toFixed(2);
                }
            }),
        );
    }

    error(errorMessage: string): void {
        this.notificationsService.error(errorMessage);
    }

    success(): void {
        this.notificationsService.success('Fax sent Successfully');
    }

    otherAttachmentChanged(attachmentTypeId?: any): void {
        super.otherAttachmentChanged(attachmentTypeId);
        if (attachmentTypeId === AttachmentTypeEnums.Records_Retrieved) {
            this.showRecordRetrievedFaxDialog();
        } else if (attachmentTypeId === AttachmentTypeEnums.Rejection) {
            this.rejectionFaxEmailComponentApi.show();
        }
    }

    attachmentChanged(value: number): void {
        super.attachmentChanged(value);
        //  if (value === AttachmentTypeEnums.Records_Retrieved) {
        //     this.generateAndUploadRetrievalRelatedCover('records-retrieval-simple');
        //  } else if (value === AttachmentTypeEnums.Rejection) {
        //      this.generateAndUploadRetrievalRelatedCover('records-rejection-letter');
        //  } else if (value === AttachmentTypeEnums.No_Records_On_File) {
        //      this.generateAndUploadRetrievalRelatedCover('no-records-on-file');
        // } else
        if (value === +AttachmentTypeEnums.Undeliverable) {
            this.generateAndUploadRetrievalRelatedCover('undeliverable-records');
        } else if (value === +AttachmentTypeEnums.Addl_Records_Retrieved) {
            this.generateAndUploadRetrievalRelatedCover('additional-records-retrieved');
        }
    }

    generateAndUploadRetrievalRelatedCover(documentType: string): void {
        this.faxEmailService.generateDocuments(documentType, this.createDocDTO()).subscribe(() => this.attachmentsService.requestRefresh());
    }

    createDocDTO(): IDocumentGenerationDTO {
        return { EntityId: this.imedClaimServiceModel.Id, Extras: null };
    }

    emitStatusChange(): void {
        this.imedClaimServiceService.update(this.imedClaimServiceModel).subscribe(() => {
            this.imedClaimServiceService.emitChange(this.imedClaimServiceModel);
        });
    }

    showComplexRecordRetrievalPackageDialog(): void {
        this.complexFaxEmailComponentApi.show();
    }

    faxComplexRecordRetrievalPackage(): void {
        this.imedClaimServiceModel.ServiceStatusId = ServiceStatusIdEnums.Record_Retrievals_HIPAA_Processing_Pending_Bill_From_Facility;
        this.emitStatusChange();
    }

    showRecordRetrievedFaxDialog(): void {
        this.faxEmailComponentApi.show();
    }

    setIsPhysicianOrFacilitySelected(): void {
        this.isPhysicianOrFacilitySelected =
            this.imedClaimServiceModel && (this.imedClaimServiceModel.PhysicianId > 0 || this.imedClaimServiceModel.FacilityAddressBookId > 0);
    }

    updateRecordRetrievalStatusForSimpleFaxOrEmail(): void {
        const BillTypeAttachments = this.documentArray.filter((item) => {
            return item.AttachmentTypeId === +AttachmentTypeEnums.Bill_Physician_Expert;
        });
        if (!BillTypeAttachments.length) {
            this.imedClaimServiceModel.ServiceStatusId =
                ServiceStatusIdEnums.Record_Retrievals_HIPAA_Processing_Medical_Records_Received_Pending_Submission_To_Client;
        }
        this.emitStatusChange();
    }

    faxSimpleRecordRetrievalPackage(): void {
        this.emitStatusChange();
    }

    faxRejectionRecordRetrievalPackage(): void {
        this.emitStatusChange();
    }

    showFilmRetrievalLabelFaxEmailDialog(): void {
        this.appLabelFaxEmailComponentApi.show();
    }

    printSimpleRecordRetrievalPackage(): void {
        this.updateRecordRetrievalStatusForSimpleFaxOrEmail();
    }

    refreshDocuments(): void {
        this.attachmentsService.requestRefresh();
        this.getDocuments().subscribe(() => this.setDisablePhysicianFacilityControl());
    }

    getSubpoenaSignatureStatus(): Observable<ISubpoenaSignatureStatusDto> {
        return this.subpoenaServiceService.getSubpoenaSignatureStatus(this.imedClaimServiceModel.ImedClaim).pipe(
            tap((subpoenaSignatureStatus: ISubpoenaSignatureStatusDto) => {
                if (subpoenaSignatureStatus != null) {
                    this.subpoenaSignatureStatus = subpoenaSignatureStatus;
                }
            }),
        );
    }

    confirmAndOpenSubpoenaPackageTemplatesModel(): void {
        if (this.plaintiffAttorneyRequireCopy) {
            const popUp: IPopUpParams = {
                confirmButtonText: 'OK',
                html: this.plaintiffAttorneyPopup,
                title: 'Plaintiff Attorney Information',
                type: PopUpTypeEnums.Soft_Pop_Up,
            };
            this.popUpService.showPopUp(popUp).subscribe((response) => {
                if (response.value) {
                    this.openSubpoenaPackageTemplatesModel();
                } else {
                    setTimeout(() => (this.doubleClickIsDisabled = false));
                }
            });
        } else {
            this.openSubpoenaPackageTemplatesModel();
        }
    }

    confirmAndOpenCompletedSubpoenaPackageTemplatesModel(): void {
        if (this.plaintiffAttorneyRequireCopy) {
            const popUp: IPopUpParams = {
                confirmButtonText: 'OK',
                html: this.plaintiffAttorneyPopup,
                title: 'Plaintiff Attorney Information',
                type: PopUpTypeEnums.Soft_Pop_Up,
            };
            this.popUpService.showPopUp(popUp).subscribe((response) => {
                if (response.value) {
                    this.openCompletedSubpoenaPackageTemplatesModel();
                } else {
                    setTimeout(() => (this.doubleClickIsDisabled = false));
                }
            });
        } else {
            this.openCompletedSubpoenaPackageTemplatesModel();
        }
    }

    generateAndUploadSubpoena(replaceSignature: boolean): void {
        this.subpoenaServiceService
            .generateAndUploadSubpoena(replaceSignature, 0, this.imedClaimServiceModel.Id)
            .pipe()
            .subscribe((document) => {
                this.notificationsService.success('Uploaded Successfully');
                this.emitStatusChange();
                this.attachmentsService.requestRefresh();
            });
    }

    validateAndOpenSubpoenaTemplatesModel(): void {
        this.getSubpoenaSignatureStatus().subscribe(() => {
            if (this.subpoenaSignatureStatus.WetSignatureUploaded && this.subpoenaSignatureStatus.SignatureAuthFormUploaded) {
                if (this.subpoenaServiceModel.DomesticationRequired) {
                    this.showDomesticationServicePopUp(this.domesticationServicePopup, true);
                } else {
                    this.generateAndUploadSubpoena(true);
                }
            } else if (
                this.subpoenaSignatureStatus.CourtRequiresWetSignature &&
                (!this.subpoenaSignatureStatus.WetSignatureUploaded || !this.subpoenaSignatureStatus.SignatureAuthFormUploaded)
            ) {
                this.showNoSignaturePopUp(this.noSignaturePopup, false);
            } else if (
                (!this.subpoenaSignatureStatus.WetSignatureUploaded || !this.subpoenaSignatureStatus.SignatureAuthFormUploaded) &&
                this.subpoenaSignatureStatus.AttorneyWithoutWetSignature &&
                !this.subpoenaSignatureStatus.CourtRequiresWetSignature
            ) {
                this.showElectronicSignaturePopUp();
            }
        });
    }

    showNoSignaturePopUp(message: string, replaceSignature: boolean): void {
        const popUp: IPopUpParams = {
            confirmButtonText: 'OK',
            html: message,
            title: 'No Signature Present',
            type: PopUpTypeEnums.Soft_Pop_Up,
        };
        this.popUpService.showPopUp(popUp).subscribe((response) => {
            if (response.value) {
                this.generateAndUploadSubpoena(replaceSignature);
            } else {
                setTimeout(() => (this.doubleClickIsDisabled = false));
            }
        });
    }

    showDomesticationServicePopUp(message: string, replaceSignature: boolean): void {
        const popUp: IPopUpParams = {
            confirmButtonText: 'OK',
            html: message,
            title: 'Domesticated Service',
            type: PopUpTypeEnums.Soft_Pop_Up,
        };
        this.popUpService.showPopUp(popUp).subscribe((response) => {
            if (response.value) {
                this.generateAndUploadSubpoena(replaceSignature);
            } else {
                setTimeout(() => (this.doubleClickIsDisabled = false));
            }
        });
    }

    showElectronicSignaturePopUp(): void {
        const popUp: IPopUpParams = {
            confirmButtonText: 'Continue',
            html: this.electronicSignaturePopup,
            title: 'Apply Electronic Signature',
            type: PopUpTypeEnums.Soft_Pop_Up,
        };
        this.popUpService.showPopUp(popUp).subscribe((response) => {
            if (response.value) {
                if (this.subpoenaServiceModel.DomesticationRequired) {
                    this.showDomesticationServicePopUp(this.domesticationServicePopup, true);
                } else {
                    this.generateAndUploadSubpoena(true);
                }
            } else {
                setTimeout(() => (this.doubleClickIsDisabled = false));
            }
        });
    }

    getIsValidSubpoenaUploaded(): Observable<any> {
        return this.subpoenaServiceService
            .IsValidSubpoenaUploaded(this.imedClaimServiceModel.Id)
            .pipe(tap((response) => (this.showSubpoenaPackage = response)));
    }

    getIsValidCompletedSubpoenaUploaded(): Observable<any> {
        return this.subpoenaServiceService
            .IsValidCompletedSubpoenaUploaded(this.imedClaimServiceModel.Id)
            .pipe(tap((response) => (this.showCompletedSubpoenaPackage = response)));
    }

    doesPlaintiffAttorneyRequireCopy(): Observable<any> {
        return this.subpoenaServiceService
            .DoesPlaintiffAttorneyRequireCopy(this.imedClaimServiceModel.Id)
            .pipe(tap((response) => (this.plaintiffAttorneyRequireCopy = response)));
    }
}
