import { Subscription, interval } from 'rxjs';
import { ClaimTypes } from '@model/ClaimTypes';
import { ClaimValues, ClaimsService } from '@mt-ng2/auth-module';
import { Injectable } from '@angular/core';
import { AuthService } from '@mt-ng2/auth-module';
import { SideBarService } from '../nav/sidebar.service';
import { TaskService } from './task.service';
import { filter } from 'rxjs/operators';
import { NavService } from '@mt-ng2/nav-module';

@Injectable({
    providedIn: 'root',
})
export class TaskSideBarService {
    subscriptions: Subscription = new Subscription();
    refreshThreshold = 600000;

    constructor(
        private sidebarService: SideBarService,
        private authService: AuthService,
        private taskService: TaskService,
        private claimService: ClaimsService,
        private navService: NavService,
    ) {
        const sidebarTaskListRefreshTimer = interval(10000);
        this.subscriptions.add(
            sidebarTaskListRefreshTimer
                .pipe(
                    filter(() => {
                        return new Date().getTime() - this.taskService.getTimeOfLastUpdate().getTime() >= this.refreshThreshold;
                    }),
                )
                .subscribe(() => {
                    const isAutomated = true;
                    this.taskService.updateSidebarList(isAutomated);
                }),
        );

        this.subscriptions.add(
            this.authService.currentUser.subscribe((user) => {
                if (user && user.Id) {
                    const showTasks = this.claimService.hasClaim(ClaimTypes.Tasks, [ClaimValues.ReadOnly, ClaimValues.FullAccess]);
                    this.sidebarService.updateContent(this.navService.sidebarCollapsed.value, showTasks);
                    if (showTasks) {
                        setTimeout(() => this.taskService.updateSidebarList());
                    }
                } else {
                    this.sidebarService.updateContent(this.navService.sidebarCollapsed.value, false);
                }
            }),
        );

        this.subscriptions.add(
            this.navService.sidebarCollapsed.subscribe((sidebarCollapsed) => {
                const showTasks = this.claimService.hasClaim(ClaimTypes.Tasks, [ClaimValues.ReadOnly, ClaimValues.FullAccess]);
                this.sidebarService.updateContent(sidebarCollapsed, showTasks);
            }),
        );
    }
}
