import { Component, Input } from '@angular/core';
import { AddressBookService } from '../../address-books/addressbook.service';
import { IndividualService } from '../../address-books/individuals/individual.service';
import { PhonePipe } from '@mt-ng2/phone-control';
import { IPhoneNumber } from '@model/interfaces/phone-number';
import { IAddressBookTooltipEntity } from '../../imed-claims/imed-claim-basic-info/imed-claim-basic-info.component';
import { forkJoin } from 'rxjs';
import { IEmailAddress } from '@model/interfaces/email-address';
import { PhoneTypes } from '@common/constants/Enums';

@Component({
    selector: 'tooltip',
    styles: [
        `
            div {
                background-color: #4c5166;
                color: white;
                border-radius: 4px;
                padding: 10px;
            }
        `,
    ],
    template: `<div *ngIf="html" [innerHTML]="html"></div>`,
})
export class AddressBookInfoTooltipComponent {
    @Input() addressBookToolTipEntity: IAddressBookTooltipEntity;
    html: string;

    constructor(private individualService: IndividualService, private addressBookService: AddressBookService, private phonePipe: PhonePipe) {}

    ngOnInit(): void {
        this.getPhoneNumbers();
    }

    formatPhones(phones: IPhoneNumber[]): string {
        return phones
            .map((phone) => {
                return `[${phone.PhoneType ? phone.PhoneType.Name : ''}] ${this.phonePipe.transform(phone)}`;
            })
            .join('<br />');
    }

    formatEmails(emails: IEmailAddress[]): string {
        return emails
            .map((email) => {
                return `[${email.EmailType ? email.EmailType.Name : ''}] ${email.Email}`;
            })
            .join('<br />');
    }

    formatIndividualEmails(emails: IEmailAddress[]): string {
        return emails
            .map((email) => {
                return `[${email.EmailType ? email.EmailType.Name : ''}] ${email.Email} ${email.Notes ? '(' + email.Notes + ')' : ''}`;
            })
            .join('<br />');
    }

    getPhoneNumbers(): void {
        if (this.addressBookToolTipEntity.type === 'individual') {
            forkJoin(
                this.individualService.getIndividualPhones(this.addressBookToolTipEntity.id),
                this.individualService.getIndividualEmails(this.addressBookToolTipEntity.id),
                this.individualService.getIndividualFacility(this.addressBookToolTipEntity.id),
                this.individualService.getIndividualFacilityPhones(this.addressBookToolTipEntity.id),
                this.individualService.getIndividualFacilityEmails(this.addressBookToolTipEntity.id),
            ).subscribe(([phones, emails, facility, facilityPhones, facilityEmails]) => {
                this.buildHtml(facility, phones, emails, facilityPhones, facilityEmails);
            });
        }
        if (this.addressBookToolTipEntity.type === 'addressbook') {
            forkJoin(
                this.addressBookService.getFacilityPhones(this.addressBookToolTipEntity.id),
                this.addressBookService.getFacilityEmails(this.addressBookToolTipEntity.id),
                this.addressBookService.getFacilityName(this.addressBookToolTipEntity.id),
            ).subscribe(([phones, emails, facility]) => {
                this.buildHtml(facility, phones, emails, [], []);
            });
        }
    }

    buildHtml(
        facility: string,
        phones: IPhoneNumber[],
        emails: IEmailAddress[],
        facilityPhones: IPhoneNumber[],
        facilityEmails: IEmailAddress[],
    ): void {
        this.html = '';
        const phoneNumbers = phones.filter((x) => x.PhoneTypeId !== +PhoneTypes.Office_fax);
        const faxNumbers = phones.filter((x) => x.PhoneTypeId === +PhoneTypes.Office_fax);
        const facilityPhoneNumbers = facilityPhones.filter((x) => x.PhoneTypeId !== +PhoneTypes.Office_fax);
        const facilityFaxNumbers = facilityPhones.filter((x) => x.PhoneTypeId === +PhoneTypes.Office_fax);
        if (facility) {
            this.html += '<u><b>Firm Name: </b></u> <br />' + facility + '<br />';
        }
        if (facilityPhoneNumbers.length) {
            this.html += `<em><b>Firm Phones: </b></em> <br />${this.formatPhones(facilityPhoneNumbers)}<br />`;
        }
        if (facilityFaxNumbers.length) {
            this.html += `<em><b>Firm Fax Numbers: </b></em> <br />${this.formatPhones(facilityFaxNumbers)}<br />`;
        }
        if (facilityEmails.length) {
            this.html += '<em><b>Firm Emails: </b></em> <br />' + this.formatEmails(facilityEmails) + '<br />';
        }
        if (phoneNumbers.length) {
            this.html += '<em><b>Phones: </b></em> <br />' + this.formatPhones(phoneNumbers) + '<br />';
        }
        if (faxNumbers.length) {
            this.html += '<em><b>Fax Numbers: </b></em> <br />' + this.formatPhones(faxNumbers) + '<br />';
        }
        if (emails.length) {
            this.html += '<em><b>Emails: </b></em> <br />' + this.formatIndividualEmails(emails) + '<br />';
        }
        if (!facility && !phones.length && !emails.length) {
            this.html = 'No information available.';
        }
    }
}
