import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ImedClaimServiceService } from '../../imed-claims/services/imedclaimservice.service';
import { AuditLogRedirectTypeEnums, DocumentEntityTypeEnums, MetaAddressBookTypeIdEnums } from '../constants/Enums';
import { PhoneNumberService } from '../services/phonenumber.service';
import { ViewLogService } from '../services/viewlog.service';
import { BaseAttachmentsService } from '../attachments/base-attachments.service';
import { BillDeskReviewServiceService } from '../../imed-claims/services/service-specifics/bill-desk-review-service/bill-desk-review-service.service';
import { ImeServiceService } from '../../imed-claims/services/service-specifics/ime-service/imeservice.service';
import { ImeAppointmentService } from '../../imed-claims/services/service-specifics/ime-service/ime-appointments/ime-appointment.service';
import { AddressService } from '../../address-books/addresses/address.service';
import { EmailService } from '../services/email-service';
import { PhysicianSpecialityService } from '../services/physician-speciality-service';
import { PhysicianSpecialityDetailService } from '../services/physician-speciality-detail-service';
import { RecordReviewServiceService } from '../../imed-claims/services/service-specifics/record-review-service/record-review-service.service';
import { IAddressBook } from '@model/partials/address-book.partial';
import { IIndividual } from '@model/interfaces/individual';
import { RecordRetrievalServiceService } from '../../imed-claims/services/service-specifics/record-retrieval-service/record-retrieval-service.service';
import { ImedClaimSubServiceService } from '@app-shared/services/imed-claim-sub-service.service';
import { ConferenceCallServiceService } from '../../imed-claims/services/service-specifics/conference-call-service/conference-call-service.service';
import { TestimonyDepositionServiceService } from '../../imed-claims/services/service-specifics/testimony-deposition-service/testimony-deposition-service.service';
import { PhysicianService } from '../../address-books/individuals/physicians/physician.service';
import { IndividualService } from '../../address-books/individuals/individual.service';
import { AddressBookService } from '../../address-books/addressbook.service';
import { CommonSearchService, ICommonSearch } from '../services/common-search.service';
import { SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ImedClaimServiceFollowupService } from '../../imed-claims/services/common-components/service-followups/imedclaimservicefollowup.service';
import { FilmInformationService } from '@app-shared/services/film-information.service';
import { FilmReviewServiceService } from '../../imed-claims/services/common-components/film-review/film-review-service.service';

@Component({
    selector: 'app-audit-log-redirect',
    template: ``,
})
export class AuditLogRedirectComponent implements OnInit {
    entityType: AuditLogRedirectTypeEnums;
    entityId: number;
    previousUrl: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private imedClaimService: ImedClaimServiceService,
        private phoneNumberService: PhoneNumberService,
        private viewLogService: ViewLogService,
        private attachmentService: BaseAttachmentsService,
        private billDeskReviewService: BillDeskReviewServiceService,
        private imeServiceService: ImeServiceService,
        private imeAppointmentService: ImeAppointmentService,
        private addressService: AddressService,
        private emailService: EmailService,
        private physicianSpecialtyService: PhysicianSpecialityService,
        private physicianSpecialtyDetailService: PhysicianSpecialityDetailService,
        private radiologyReviewService: FilmReviewServiceService,
        private recordReviewService: RecordReviewServiceService,
        private filmInformationService: FilmInformationService,
        private recordRetrievalService: RecordRetrievalServiceService,
        private imedClaimSubService: ImedClaimSubServiceService,
        private conferenceCallService: ConferenceCallServiceService,
        private testimonyDepositionService: TestimonyDepositionServiceService,
        private physicianService: PhysicianService,
        private individualService: IndividualService,
        private addressBookService: AddressBookService,
        private commonSearchService: CommonSearchService,
        private followupService: ImedClaimServiceFollowupService,
    ) {}

    ngOnInit(): void {
        this.entityType = parseInt(this.route.snapshot.paramMap.get('entityType'), 10);
        this.entityId = parseInt(this.route.snapshot.paramMap.get('entityId'), 10);

        this.redirect();
    }

    // Reusable function to build routes for services
    private serviceRoute(imedClaimId: number, imedClaimServiceId: number): Promise<boolean> {
        return this.router.navigate([`cases/${imedClaimId}/services/${imedClaimServiceId}`], { replaceUrl: true });
    }

    // Reusable function to build routes for address books
    private addressBookRoute(AddressBooks: IAddressBook[], Individuals: IIndividual[]): Promise<boolean> {
        if (AddressBooks && AddressBooks.length && !Individuals && !Individuals.length) {
            return this.router.navigate([`address-books/${AddressBooks[0].Id}`], { replaceUrl: true });
        } else {
            return this.router.navigate([`address-books/${Individuals[0].AddressBookId}/individuals/${Individuals[0].Id}`], { replaceUrl: true });
        }
    }

    redirect(): void {
        const searchEntity: IEntitySearchParams = {
            query: this.entityId.toString(),
        };

        const searchFilter: ICommonSearch = {
            searchFilters: new SearchParams(searchEntity),
        };
        switch (this.entityType) {
            case AuditLogRedirectTypeEnums.IMED_CLAIM_SERVICE:
                this.imedClaimService.getById(this.entityId).subscribe(({ ImedClaimId }) => {
                    void this.router.navigate([`cases/${ImedClaimId}/services/${this.entityId}`], { replaceUrl: true });
                });
                break;
            case AuditLogRedirectTypeEnums.PHONE_NUMBER:
                this.phoneNumberService.getById(this.entityId).subscribe(({ AddressBooks, Individuals }) => {
                    void this.addressBookRoute(AddressBooks, Individuals);
                });
                break;
            case AuditLogRedirectTypeEnums.VIEW_LOG:
                this.viewLogService.getById(this.entityId).subscribe(({ Route }) => {
                    void this.router.navigate([Route], { replaceUrl: true });
                });
                break;
            case AuditLogRedirectTypeEnums.DOCUMENT_QUEUE:
                this.commonSearchService.setCommonSearchFilters(searchFilter);
                void this.router.navigate(['/documentqueue']);

                break;
            case AuditLogRedirectTypeEnums.DOCUMENT:
                this.attachmentService
                    .getDocumentDataForRouting(this.entityId)
                    .subscribe(({ Item1: entityType, Item2: childEntityId, Item3: parentEntityId }) => {
                        if (entityType === DocumentEntityTypeEnums.CASE) {
                            void this.router.navigate([`cases/${childEntityId}`], { replaceUrl: true });
                        }
                        if (entityType === DocumentEntityTypeEnums.ADDRESS_BOOK) {
                            void this.router.navigate([`address-books/${childEntityId}`], { replaceUrl: true });
                        }
                        if (entityType === DocumentEntityTypeEnums.INDIVIDUAL || entityType === DocumentEntityTypeEnums.PHYSICIAN) {
                            void this.router.navigate([`address-books/${parentEntityId}/individuals/${childEntityId}`], { replaceUrl: true });
                        }
                        if (entityType === DocumentEntityTypeEnums.SERVICE) {
                            void this.router.navigate([`cases/${parentEntityId}/services/${childEntityId}`], { replaceUrl: true });
                        }
                    });
                break;
            case AuditLogRedirectTypeEnums.BILL_DESK_REVIEW:
                this.billDeskReviewService.getById(this.entityId).subscribe(({ ImedClaimServiceId, ImedClaimService: { ImedClaimId } }) => {
                    void this.serviceRoute(ImedClaimId, ImedClaimServiceId);
                });
                break;
            case AuditLogRedirectTypeEnums.IME_SERVICE:
                this.imeServiceService
                    .getById(this.entityId)
                    .subscribe(({ ImedClaimServiceId, ImedClaimService_ImedClaimServiceId: { ImedClaimId } }: any) => {
                        void this.serviceRoute(ImedClaimId as number, ImedClaimServiceId as number);
                    });
                break;
            case AuditLogRedirectTypeEnums.IME_APPOINTMENT:
                this.imeAppointmentService.getById(this.entityId).subscribe(({ ImedClaimServiceId, ImedClaimService }: any) => {
                    void this.router.navigate([`cases/${ImedClaimService.ImedClaimId}/services/${ImedClaimServiceId}/appointments`], { replaceUrl: true });
                });
                break;
            case AuditLogRedirectTypeEnums.ADDRESS:
                this.addressService.getById(this.entityId).subscribe((address) => {
                    if (address.ImedClaims && address.ImedClaims.length) {
                        void this.router.navigate([`cases/${address.ImedClaims[0].Id}/`], { replaceUrl: true });
                    }
                    if (address.AddressBooks_AddressBookId && address.AddressBooks_AddressBookId.length) {
                        void this.router.navigate([`address-books/${address.AddressBooks_AddressBookId[0].Id}/addresses/${this.entityId}`], { replaceUrl: true });
                    }
                });
                break;
            case AuditLogRedirectTypeEnums.EMAIL_ADDRESS:
                this.emailService.getById(this.entityId).subscribe(({ Individuals, AddressBooks }) => {
                    void this.addressBookRoute(AddressBooks, Individuals);
                });
                break;
            case AuditLogRedirectTypeEnums.PHYSICIAN_SPECIALTY_DETAILS:
                this.physicianSpecialtyDetailService.getById(this.entityId).subscribe(({ Physician: { Individual } }) => {
                    void this.addressBookRoute([Individual.AddressBook], [Individual]);
                });
                break;
            case AuditLogRedirectTypeEnums.PHYSICIAN_SPECIALTY:
                this.physicianSpecialtyService.getById(this.entityId).subscribe(({ Physician: { Individual } }) => {
                    void this.addressBookRoute([Individual.AddressBook], [Individual]);
                });
                break;
            case AuditLogRedirectTypeEnums.FILM_INFORMATION:
                this.filmInformationService.getById(this.entityId).subscribe(({ ImedClaimServiceId, ImedClaimService: { ImedClaimId } }) => {
                    void this.serviceRoute(ImedClaimId, ImedClaimServiceId);
                });
                break;
            case AuditLogRedirectTypeEnums.RADIOLOGY_REVIEW_SERVICE:
                this.radiologyReviewService
                    .getById(this.entityId)
                    .subscribe(({ ImedClaimServiceId, ImedClaimService: { ImedClaimId } }) => void this.serviceRoute(ImedClaimId, ImedClaimServiceId));
                break;
            case AuditLogRedirectTypeEnums.RECORD_REVIEW_SERVICE:
                this.recordReviewService
                    .getById(this.entityId)
                    .subscribe(({ ImedClaimServiceId, ImedClaimService: { ImedClaimId } }) => void this.serviceRoute(ImedClaimId, ImedClaimServiceId));
                break;
            case AuditLogRedirectTypeEnums.RECORD_RETRIEVAL_SERVICE:
                this.recordRetrievalService
                    .getById(this.entityId)
                    .subscribe(({ ImedClaimServiceId, ImedClaimService: { ImedClaimId } }) => void this.serviceRoute(ImedClaimId, ImedClaimServiceId));
                break;
            case AuditLogRedirectTypeEnums.IMED_CLAIM_SUBSERVICE:
                this.imedClaimSubService
                    .getById(this.entityId)
                    .subscribe(({ ImedClaimServiceId, ImedClaimService: { ImedClaimId } }) => void this.serviceRoute(ImedClaimId, ImedClaimServiceId));
                break;
            case AuditLogRedirectTypeEnums.CONFERENCE_CALL_SERVICE:
                this.conferenceCallService
                    .getById(this.entityId)
                    .subscribe(({ ImedClaimServiceId, ImedClaimService: { ImedClaimId } }) => void this.serviceRoute(ImedClaimId, ImedClaimServiceId));
                break;
            case AuditLogRedirectTypeEnums.TESTIMONY_DEPOSITION_SERVICE:
                this.testimonyDepositionService
                    .getById(this.entityId)
                    .subscribe(({ ImedClaimServiceId, ImedClaimService: { ImedClaimId } }) => void this.serviceRoute(ImedClaimId, ImedClaimServiceId));
                break;
            case AuditLogRedirectTypeEnums.CRS_CONFERENCE_CALL_DATE_TIME:
                this.conferenceCallService
                    .getConferenceCallDateTimeForAuditLog(this.entityId)
                    .subscribe(({ Item1: ImedClaimId, Item2: ImedClaimServiceId }) => void this.serviceRoute(ImedClaimId as number, ImedClaimServiceId as number));
                break;
            case AuditLogRedirectTypeEnums.CRS_TESTIMONY_DEPOSITION_DATE:
                this.testimonyDepositionService
                    .getTestimonyDepositionDateForAuditLog(this.entityId)
                    .subscribe(({ Item1: ImedClaimId, Item2: ImedClaimServiceId }) => void this.serviceRoute(ImedClaimId as number, ImedClaimServiceId as number));
                break;
            case AuditLogRedirectTypeEnums.PHYSICIAN:
                this.physicianService.getById(this.entityId).subscribe(({ Individual: { AddressBook }, Individual }) => {
                    void this.addressBookRoute([AddressBook], [Individual]);
                });
                break;
            case AuditLogRedirectTypeEnums.INDIVIDUAL:
                this.individualService.getById(this.entityId).subscribe(({ Id, AddressBookId }) => {
                    void this.router.navigate([`address-books/${AddressBookId}/individuals/${Id}`], { replaceUrl: true });
                });
                break;
            case AuditLogRedirectTypeEnums.ADDRESS_BOOK:
                this.addressBookService.getById(this.entityId).subscribe((addressBook) => {
                    if (addressBook.MetaAddressBookTypeId === +MetaAddressBookTypeIdEnums.Individual) {
                        void this.router.navigate([`address-books/${addressBook.Id}/individuals/${addressBook.Individuals[0].Id}`], { replaceUrl: true });
                    } else {
                        void this.router.navigate([`address-books/${addressBook.Id}`], { replaceUrl: true });
                    }
                });
                break;
            case AuditLogRedirectTypeEnums.FOLLOWUP:
                this.followupService.getById(this.entityId).subscribe(({ ImedClaimServiceId, ImedClaimService: { ImedClaimId } }) =>
                    void this.router.navigate(['cases', ImedClaimId, 'services', ImedClaimServiceId, 'follow-ups', this.entityId], {
                        replaceUrl: true,
                    }),
                );
                break;
            default:
        }
    }
}
