import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IEmailTemplate } from '@model/interfaces/email-template';
import { IGroupedEmailTemplatesByFollowupDTO } from '@model/interfaces/custom/grouped-email-templates-by-followup-dto';
import { IMasterFollowupType } from '@model/interfaces/master-followup-type';

export interface IFollowupEmailTemplateAssociation {
    Followup: IMasterFollowupType;
    EmailTemplate: IEmailTemplate;
}

@Component({
    selector: 'followup-email-template-row',
    styleUrls: ['../../../system-task-associations/row-styles.less'],
    templateUrl: './followup-email-template-row.component.html',
})
export class FollowupEmailTemplateRowComponent implements OnInit {
    private _filteredEmailTemplates: IEmailTemplate[] = [];
    @Input('filteredEmailTemplates')
    set filteredEmailTemplates(val: IEmailTemplate[]) {
        this._filteredEmailTemplates = [...val];
    }
    get filteredEmailTemplates(): IEmailTemplate[] {
        return this._filteredEmailTemplates;
    }
    @Input() filteredFollowupTypes: IMasterFollowupType[];
    @Input() groupedEmailTemplates: IGroupedEmailTemplatesByFollowupDTO;
    @Input() className = '';
    @Output() onTemplateSelected: EventEmitter<IFollowupEmailTemplateAssociation> = new EventEmitter();
    @Output() onTemplateRemoved: EventEmitter<IFollowupEmailTemplateAssociation> = new EventEmitter();

    selectedEmailTemplate: IEmailTemplate;

    constructor() { }

    ngOnInit(): void {
        this.filteredEmailTemplates = this.filteredEmailTemplates?.filter(
            (x) => !this.groupedEmailTemplates.EmailTemplates.some((y) => y.Id === x.Id),
        );
        if (this.groupedEmailTemplates.FollowupType) {
            this.filteredFollowupTypes = this.filteredFollowupTypes?.filter((x) => !(this.groupedEmailTemplates.FollowupType.Id === x.Id));
        }
    }

    emailTemplateSelected(): void {
        this.filteredEmailTemplates = this.filteredEmailTemplates.filter((x) => x.Id !== this.selectedEmailTemplate.Id);
        this.groupedEmailTemplates.EmailTemplates.push(this.selectedEmailTemplate);
        this.onTemplateSelected.emit({
            EmailTemplate: this.selectedEmailTemplate,
            Followup: this.groupedEmailTemplates.FollowupType,
        } as IFollowupEmailTemplateAssociation);
        this.selectedEmailTemplate = null;
    }

    emailTemplateRemoved(index: number, emailTemplate: IEmailTemplate): void {
        this.groupedEmailTemplates.EmailTemplates.splice(index, 1);
        this.filteredEmailTemplates.push(emailTemplate);
        this.onTemplateRemoved.emit({
            EmailTemplate: emailTemplate,
            Followup: this.groupedEmailTemplates.FollowupType,
        } as IFollowupEmailTemplateAssociation);
    }
}
