import { ChangeDetectorRef, Component, OnInit, Input, ViewRef, OnDestroy, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { SpecialityService } from '../../../imed-managed-list/speciality.service';
import { SpecialityDetailService } from '../../../imed-managed-list/speciality-detail.service';
import { IAddressBook } from '@model/interfaces/address-book';
import { IIndividual } from '@model/interfaces/individual';
import { PhysicianSpecialtyDynamicControlsPartial } from '@model/partials/physician-specialty-partial.form-controls';
import { PhysicianSpecialtyDetailDynamicControlsPartial } from '@model/partials/physician-specialty-detail-partial.form-controls';
import { PhysicianDynamicControlsPartial } from '@model/partials/physician-partial.form-controls';
import { AuthService } from '@mt-ng2/auth-module';
import { PhysicianService } from '../physicians/physician.service';
import { IndividualService } from '../individual.service';
import { finalize } from 'rxjs/operators';
import { safeDetectChanges } from '../../../common/cdr-safety/cdr-safety.library';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { ISpecialty } from '@model/interfaces/specialty';
import { ISpecialtyDetail } from '@model/interfaces/specialty-detail';
import { IPhysician } from '@model/interfaces/physician';
import { AddressBookTypeIdEnums } from '../../../common/constants/Enums';

@Component({
    selector: 'individual-specialties',
    templateUrl: './individual-specialties.component.html',
})
export class IndividualSpecialtiesComponent implements OnInit, OnDestroy {
    @Input('addressBook') addressBook: IAddressBook;
    @Input('individual') individual: IIndividual;
    @Input('canEdit') canEdit: boolean;
    @Output('onPhysicianSaved') onPhysicianSaved: EventEmitter<IPhysician> = new EventEmitter<IPhysician>();

    isHovered: boolean;

    // abstract controls
    abstractPhysicianControls: any;
    abstractPhysicianSpecialtyControls: any;
    abstractPhysicianSpecialtyDetailControls: any;
    abstractPhysicianControlsView: any;
    abstractPhysicianSpecialtyControlsView: any;
    abstractPhysicianSpecialtyDetailControlsView: any;

    individualSpecialtiesForm: UntypedFormGroup;
    doubleClickIsDisabled = false;
    formCreated = false;
    isEditing = false;

    specialties: ISpecialty[];
    specialtyDetails: ISpecialtyDetail[];

    get isPhysicianPanelType(): boolean {
        return this.addressBook.AddressBookTypeId === +AddressBookTypeIdEnums.Physician_panel;
    }
    get isExpertPanelType(): boolean {
        return this.addressBook.AddressBookTypeId === +AddressBookTypeIdEnums.Expert_panel;
    }
    get hasGeneralLiabilitySpecialty(): boolean {
        return this.individual.Physicians[0].GeneralLiability;
    }
    get hasWorkersCompSpecialty(): boolean {
        return this.individual.Physicians[0].WorkersComp;
    }
    get hasAutoSpecialty(): boolean {
        return this.individual.Physicians[0].Auto;
    }

    constructor(
        private fb: UntypedFormBuilder,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private specialtyService: SpecialityService,
        public specialtyDetailService: SpecialityDetailService,
        private physicianService: PhysicianService,
        private individualService: IndividualService,
        private authService: AuthService,
    ) {}

    ngOnInit(): void {
        forkJoin(this.specialtyService.getItems(), this.specialtyDetailService.getItems()).subscribe(([specialties, specialtyDetails]) => {
            this.specialties = specialties;
            this.specialtyDetails = specialtyDetails;
            this.createForm();
        });
    }

    ngOnDestroy(): void {
        this.cdr.detach();
    }

    createForm(): void {
        this.getControls();
        this.individualSpecialtiesForm = this.assignFormGroups();
        this.formCreated = true;
        safeDetectChanges(this.cdr);
    }

    getControls(): void {
        const abstractPhysicianControls = new PhysicianDynamicControlsPartial(this.individual.Physicians[0] ? this.individual.Physicians[0] : null, {
            formGroup: 'Physician',
            individuals: null,
        });
        this.abstractPhysicianControls = abstractPhysicianControls.Form;
        this.abstractPhysicianControlsView = abstractPhysicianControls.View;
        const abstractPhysicianSpecialtyControls = new PhysicianSpecialtyDynamicControlsPartial(null, {
            createdBies: null,
            formGroup: 'PhysicianSpecialty',
            modifiedBies: null,
            physicians: null,
            specialties: this.specialties,
        });
        this.abstractPhysicianSpecialtyControls = abstractPhysicianSpecialtyControls.Form;
        this.abstractPhysicianSpecialtyControlsView = abstractPhysicianSpecialtyControls.View;
        const abstractPhysicianSpecialtyDetailControls = new PhysicianSpecialtyDetailDynamicControlsPartial(null, {
            createdBies: null,
            formGroup: 'PhysicianSpecialtyDetail',
            modifiedBies: null,
            physicians: null,
            specialtyDetails: this.specialtyDetails,
        });
        this.abstractPhysicianSpecialtyDetailControls = abstractPhysicianSpecialtyDetailControls.Form;
        this.abstractPhysicianSpecialtyDetailControlsView = abstractPhysicianSpecialtyDetailControls.View;

        this.abstractPhysicianSpecialtyControls.SpecialtyId.value = this.individual.Physicians[0].PhysicianSpecialties.map((physicianSpecialty) => {
            return physicianSpecialty.Specialty.Id;
        });
        this.abstractPhysicianSpecialtyDetailControls.SpecialtyDetailId.value = this.individual.Physicians[0].PhysicianSpecialtyDetails.map(
            (physicianSpecialtyDetail) => {
                return physicianSpecialtyDetail.SpecialtyDetail.Id;
            },
        );
    }

    assignFormGroups(): UntypedFormGroup {
        return this.fb.group({
            Physician: this.fb.group({}),
            PhysicianSpecialty: this.fb.group({}),
            PhysicianSpecialtyDetail: this.fb.group({
                SpecialtyDetailId: this.fb.control(''), // over wrtiting this to use multi checkboxes
            }),
        });
    }

    formSubmitted(): void {
        if (this.individualSpecialtiesForm.valid) {
            this.formatDataForSave();
            this.physicianService.saveSpecialties(this.individual.Physicians[0]).subscribe(() => {
                this.notificationsService.success('Specialties Saved Successfully');
                this.individualService.getById(this.individual.Id).subscribe((individual) => {
                    this.individual = individual;
                    this.onPhysicianSaved.emit(this.individual.Physicians[0]);
                    this.isEditing = false;
                    this.getControls();
                });
            });
        } else {
            markAllFormFieldsAsTouched(this.individualSpecialtiesForm);
            this.error();
        }
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }

    formatDataForSave(): void {
        Object.assign(this.individual.Physicians[0], this.individualSpecialtiesForm.value.Physician);
        const specialtyIds: number[] = this.individualSpecialtiesForm.value.PhysicianSpecialty.SpecialtyId;
        const specialtyDetailIds: number[] = this.individualSpecialtiesForm.value.PhysicianSpecialtyDetail.SpecialtyDetailId;
        this.mergeSpecialties(specialtyIds);
        this.mergeSpecialtyDetails(specialtyDetailIds);
    }

    mergeSpecialties(specialtyIds: number[]): void {
        if (specialtyIds) {
            this.individual.Physicians[0].PhysicianSpecialties = this.individual.Physicians[0].PhysicianSpecialties.filter((physicianSpecialty) => {
                return specialtyIds.indexOf(physicianSpecialty.Id) > -1; // filter out specialties that aren't in the new array of Ids
            });
            specialtyIds.forEach((id) => {
                if (this.individual.Physicians[0].PhysicianSpecialties.findIndex((specialty) => specialty.Id === id) === -1) {
                    // if a specialty should be added, add a new one
                    this.individual.Physicians[0].PhysicianSpecialties.push({
                        Archived: false,
                        CreatedById: this.authService.currentUser.getValue().Id,
                        DateCreated: new Date(),
                        Id: 0,
                        PhysicianId: this.individual.Physicians[0].Id,
                        SpecialtyId: id,
                    });
                } // don't do anything if keeping an existing specialty
            });
        } else {
            this.individual.Physicians[0].PhysicianSpecialties = [];
        }
    }

    mergeSpecialtyDetails(specialtyDetailIds: number[]): void {
        if (specialtyDetailIds) {
            this.individual.Physicians[0].PhysicianSpecialtyDetails = this.individual.Physicians[0].PhysicianSpecialtyDetails.filter(
                (physicianSpecialty) => {
                    return specialtyDetailIds.indexOf(physicianSpecialty.Id) > -1; // filter out specialties that aren't in the new array of Ids
                },
            );
            specialtyDetailIds.forEach((id) => {
                if (this.individual.Physicians[0].PhysicianSpecialtyDetails.findIndex((specialtyDetail) => specialtyDetail.Id === id) === -1) {
                    // if a specialty detail should be added, add a new one
                    this.individual.Physicians[0].PhysicianSpecialtyDetails.push({
                        Archived: false,
                        CreatedById: this.authService.currentUser.getValue().Id,
                        DateCreated: new Date(),
                        Id: 0,
                        PhysicianId: this.individual.Physicians[0].Id,
                        SpecialtyDetailId: id,
                    });
                } // don't do anything if keeping an existing specialty detail
            });
        } else {
            this.individual.Physicians[0].PhysicianSpecialtyDetails = [];
        }
    }

    getValueToDisplayForSpecialties(): string {
        const specialties = this.individual.Physicians[0].PhysicianSpecialties;
        const result = specialties
            .map((spec) => {
                return spec.Specialty ? spec.Specialty.Name : '';
            })
            .join(', ');
        return result;
    }

    getValueToDisplayForSpecialtyDetails(): string {
        const specialtyDetails = this.individual.Physicians[0].PhysicianSpecialtyDetails;
        const result = specialtyDetails
            .map((spec) => {
                return spec.SpecialtyDetail ? spec.SpecialtyDetail.Name : '';
            })
            .join(', ');
        return result;
    }
}
