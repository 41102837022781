import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { IEmailTemplate } from '@model/interfaces/email-template';
import { EmailTemplateService } from '../emailtemplate.service';

@Component({
    selector: 'app-email-template-header',
    templateUrl: './email-template-header.component.html',
})
export class EmailTemplateHeaderComponent implements OnInit, OnDestroy {
    emailTemplate: IEmailTemplate;
    header: string;
    emailTemplateSubscriptions: Subscription = new Subscription();

    constructor(private emailTemplateService: EmailTemplateService, private route: ActivatedRoute) { }

    ngOnInit(): void {
        this.emailTemplateSubscriptions.add(
            this.emailTemplateService.changeEmitted$.subscribe((emailTemplate) => {
                this.setHeader(emailTemplate);
            }),
        );
        const id = +this.route.snapshot.paramMap.get('emailTemplateId');
        if (id > 0) {
            this.emailTemplateService.getById(id).subscribe((emailTemplate) => {
                this.setHeader(emailTemplate);
            });
        } else {
            this.header = 'Add Email Template';
            this.emailTemplate = this.emailTemplateService.getEmptyEmailTemplate();
        }
    }

    ngOnDestroy(): void {
        this.emailTemplateSubscriptions.unsubscribe();
    }

    setHeader(emailTemplate: IEmailTemplate): void {
        this.emailTemplate = emailTemplate;
        this.header = `Email Template: ${emailTemplate.Name}`;
    }
}
