import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IPhoneNumber } from '../interfaces/phone-number';
import { IUser } from '../interfaces/user';
import { IPhoneType } from '../interfaces/phone-type';

export interface IPhoneNumberDynamicControlsParameters {
    formGroup?: string;
    phoneTypes?: IPhoneType[];
    createdBies?: IUser[];
    modifiedBies?: IUser[];
}

export class PhoneNumberDynamicControls {

    formGroup: string;
    phoneTypes: IPhoneType[];
    createdBies: IUser[];
    modifiedBies: IUser[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private phonenumber?: IPhoneNumber, additionalParameters?: IPhoneNumberDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'PhoneNumber';
        this.phoneTypes = additionalParameters && additionalParameters.phoneTypes || undefined;
        this.createdBies = additionalParameters && additionalParameters.createdBies || undefined;
        this.modifiedBies = additionalParameters && additionalParameters.modifiedBies || undefined;

        this.Form = {
            Archived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived',
                name: 'Archived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.phonenumber && this.phonenumber.hasOwnProperty('Archived') && this.phonenumber.Archived !== null ? this.phonenumber.Archived : false,
            }),
            CreatedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Created By',
                name: 'CreatedById',
                options: this.createdBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.phonenumber && this.phonenumber.CreatedById || null,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.phonenumber && this.phonenumber.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.phonenumber && this.phonenumber.DateModified || null,
            }),
            Extension: new DynamicField({
                formGroup: this.formGroup,
                label: 'Extension',
                name: 'Extension',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(10) ],
                validators: { 'maxlength': 10 },
                value: this.phonenumber && this.phonenumber.hasOwnProperty('Extension') && this.phonenumber.Extension !== null ? this.phonenumber.Extension.toString() : '',
            }),
            FacilityPhoneLegacyId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Facility Phone Legacy',
                name: 'FacilityPhoneLegacyId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.phonenumber && this.phonenumber.hasOwnProperty('FacilityPhoneLegacyId') && this.phonenumber.FacilityPhoneLegacyId !== null ? this.phonenumber.FacilityPhoneLegacyId.toString() : '',
            }),
            IndividualPhoneLegacyId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Individual Phone Legacy',
                name: 'IndividualPhoneLegacyId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.phonenumber && this.phonenumber.hasOwnProperty('IndividualPhoneLegacyId') && this.phonenumber.IndividualPhoneLegacyId !== null ? this.phonenumber.IndividualPhoneLegacyId.toString() : '',
            }),
            IsPrimary: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Primary',
                name: 'IsPrimary',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.phonenumber && this.phonenumber.hasOwnProperty('IsPrimary') && this.phonenumber.IsPrimary !== null ? this.phonenumber.IsPrimary : false,
            }),
            ModifiedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Modified By',
                name: 'ModifiedById',
                options: this.modifiedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.phonenumber && this.phonenumber.ModifiedById || null,
            }),
            Notes: new DynamicField({
                formGroup: this.formGroup,
                label: 'Notes',
                name: 'Notes',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(400) ],
                validators: { 'maxlength': 400 },
                value: this.phonenumber && this.phonenumber.hasOwnProperty('Notes') && this.phonenumber.Notes !== null ? this.phonenumber.Notes.toString() : '',
            }),
            Phone: new DynamicField({
                formGroup: this.formGroup,
                label: 'Phone',
                name: 'Phone',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(20) ],
                validators: { 'required': true, 'maxlength': 20 },
                value: this.phonenumber && this.phonenumber.hasOwnProperty('Phone') && this.phonenumber.Phone !== null ? this.phonenumber.Phone.toString() : '',
            }),
            PhoneTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Phone Type',
                name: 'PhoneTypeId',
                options: this.phoneTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.phonenumber && this.phonenumber.PhoneTypeId || null,
            }),
        };

        this.View = {
            Archived: new DynamicLabel({
			    label: 'Archived',
			    value: this.phonenumber && this.phonenumber.hasOwnProperty('Archived') && this.phonenumber.Archived !== null ? this.phonenumber.Archived : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            CreatedById: new DynamicLabel({
			    label: 'Created By',
			    value: getMetaItemValue(this.createdBies as unknown as IMetaItem[], this.phonenumber && this.phonenumber.hasOwnProperty('CreatedById') && this.phonenumber.CreatedById !== null ? this.phonenumber.CreatedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            DateCreated: new DynamicLabel({
			    label: 'Date Created',
			    value: this.phonenumber && this.phonenumber.DateCreated || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DateModified: new DynamicLabel({
			    label: 'Date Modified',
			    value: this.phonenumber && this.phonenumber.DateModified || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            Extension: new DynamicLabel({
			    label: 'Extension',
			    value: this.phonenumber && this.phonenumber.hasOwnProperty('Extension') && this.phonenumber.Extension !== null ? this.phonenumber.Extension.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            FacilityPhoneLegacyId: new DynamicLabel({
			    label: 'Facility Phone Legacy',
			    value: this.phonenumber && this.phonenumber.hasOwnProperty('FacilityPhoneLegacyId') && this.phonenumber.FacilityPhoneLegacyId !== null ? this.phonenumber.FacilityPhoneLegacyId.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            IndividualPhoneLegacyId: new DynamicLabel({
			    label: 'Individual Phone Legacy',
			    value: this.phonenumber && this.phonenumber.hasOwnProperty('IndividualPhoneLegacyId') && this.phonenumber.IndividualPhoneLegacyId !== null ? this.phonenumber.IndividualPhoneLegacyId.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            IsPrimary: new DynamicLabel({
			    label: 'Is Primary',
			    value: this.phonenumber && this.phonenumber.hasOwnProperty('IsPrimary') && this.phonenumber.IsPrimary !== null ? this.phonenumber.IsPrimary : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ModifiedById: new DynamicLabel({
			    label: 'Modified By',
			    value: getMetaItemValue(this.modifiedBies as unknown as IMetaItem[], this.phonenumber && this.phonenumber.hasOwnProperty('ModifiedById') && this.phonenumber.ModifiedById !== null ? this.phonenumber.ModifiedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Notes: new DynamicLabel({
			    label: 'Notes',
			    value: this.phonenumber && this.phonenumber.hasOwnProperty('Notes') && this.phonenumber.Notes !== null ? this.phonenumber.Notes.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Phone: new DynamicLabel({
			    label: 'Phone',
			    value: this.phonenumber && this.phonenumber.hasOwnProperty('Phone') && this.phonenumber.Phone !== null ? this.phonenumber.Phone.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            PhoneTypeId: new DynamicLabel({
			    label: 'Phone Type',
			    value: getMetaItemValue(this.phoneTypes as unknown as IMetaItem[], this.phonenumber && this.phonenumber.hasOwnProperty('PhoneTypeId') && this.phonenumber.PhoneTypeId !== null ? this.phonenumber.PhoneTypeId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };

    }
}
