import { ILabelGenerator } from './../../../../common/interfaces/ILabelGenerator.d';
import { IImedClaimServiceStatusLog } from '@model/interfaces/imed-claim-service-status-log.d';

import { DatePipe } from '@angular/common';

export class ImedClaimServiceStatusLogLabelGenerator implements ILabelGenerator {
    constructor() { }

    GetLabel(entity: IImedClaimServiceStatusLog): string {
        return `<div class="status-log-info">Changed to Status <b>${entity.ServiceStatus.Name}</b></div><div class="status-log-subtext">by User <b>${entity.User.FirstName + ' ' + entity.User.LastName
            }</b> on ${new DatePipe('en-US').transform(entity.ChangedDate, 'MMMM d, yyyy', 'UTC')}</div><div class="status-log-clear"></div>`;
    }
}
