import { NotificationsService } from '@mt-ng2/notifications-module';
import { IUser } from '@model/interfaces/user.d';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import 'rxjs/operators';

import { ExtraSearchParams, SearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection, IItemDeletedEvent } from '@mt-ng2/entity-list-module';
import { IEntitySearchParams } from '@mt-ng2/common-classes';

import { UserService } from '../user.service';
import { ClaimTypes } from '@model/ClaimTypes';
import { UsersEntityListConfig } from './users.entity-list-config';
import { entityListModuleConfig } from '../../common/shared.module';
import { UserTypeValues } from '../../common/constants/user-type.enum';
import { AuthEntityService } from '../../auth-entity/auth-entity.service';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
})
export class UsersComponent implements OnInit {
    searchControl = new UntypedFormControl();
    users: IUser[];
    currentPage = 1;
    query = '';
    total: number;
    canAddUser = false;

    entityListConfig = new UsersEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();

    constructor(
        private userService: UserService,
        private claimsService: ClaimsService,
        private router: Router,
        private authEntityService: AuthEntityService,
        private notificationsService: NotificationsService,
    ) {}

    ngOnInit(): void {
        this.canAddUser = this.claimsService.hasClaim(ClaimTypes.Users, [ClaimValues.FullAccess]);

        this.getUsers();
    }

    getUsers(): void {
        const search = this.query;

        const extraParams: ExtraSearchParams[] = [];
        extraParams.push(
            new ExtraSearchParams({
                name: 'UserTypeId',
                value: UserTypeValues.Admin.toString(),
            }),
            new ExtraSearchParams({
                name: 'InactiveUsers',
                value: 'true',
            }),
        );

        const searchparams: IEntitySearchParams = {
            extraParams: extraParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: entityListModuleConfig.itemsPerPage,
        };
        const searchEntity = new SearchParams(searchparams);

        this.userService.get(searchEntity).subscribe((answer) => {
            this.users = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.query = query;
        this.currentPage = 1;
        this.getUsers();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getUsers();
    }

    userSelected(event: IItemSelectedEvent): void {
        void this.router.navigate(['/users', event.entity.Id]);
    }

    onItemDeleted(event: IItemDeletedEvent): void {
        const selectedUser: IUser = event.entity;
        if (selectedUser.Id !== 1) {
            this.authEntityService.changeAccess(selectedUser.AuthUserId, selectedUser.Archived).subscribe(() => {
                this.notificationsService.success('User Updated Successfully');
                this.userService.refreshAllUsers();
                this.getUsers();
            });
        } else {
            this.notificationsService.error('User cannot be Disabled');
        }
    }
}
