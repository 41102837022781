import { DynamicField, DynamicLabel } from '@mt-ng2/dynamic-form';
import { BillsForReviewDynamicControls, IBillsForReviewDynamicControlsParameters } from '../form-controls/bills-for-review.form-controls';
import { IBillsForReview } from '../interfaces/bills-for-review';

export class BillsForReviewDynamicControlsPartial extends BillsForReviewDynamicControls {

    constructor(billsforreviewPartial?: IBillsForReview, additionalParameters?: IBillsForReviewDynamicControlsParameters) {
        super(billsforreviewPartial, additionalParameters);

        (<DynamicField>this.Form.Dos).labelHtml = '<label>Date of Service</label>';
        (<DynamicField>this.Form.HcpcsCode).labelHtml = '<label>HCPCS Code</label>';

        (<DynamicLabel>this.View.Dos).label = 'Date of Service';
        (<DynamicLabel>this.View.HcpcsCode).label = 'HCPCS Code';
    }
}
