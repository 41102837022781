import { IBillDeskReviewService } from '@model/interfaces/bill-desk-review-service.d';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '@mt-ng2/base-service';
import { IServicePayload } from '@model/interfaces/custom/update-service-payload';
import { nullOutNestedObjects } from '../../../../common/custom/nullOutNestedObjects';

export const emptyBillDeskReviewService: IBillDeskReviewService = {
    Archived: false,
    CreatedById: 0,
    DateCreated: null,
    HasPreLitigation: false,
    Id: 0,
    ImedClaimServiceId: 0,
    IsFederalCase: false,
    RequestDate: null,
    RequestDateChangedById: null,
    RequestDateChangedDate: null,
};

@Injectable()
export class BillDeskReviewServiceService extends BaseService<IBillDeskReviewService> {
    constructor(public http: HttpClient) {
        super('/billdeskreviewservices', http);
    }

    getEmptyBillDeskReviewServiceService(): IBillDeskReviewService {
        return { ...emptyBillDeskReviewService };
    }

    getByImedClaimServiceId(imedClaimServiceId: number): Observable<IBillDeskReviewService> {
        return this.http.get<IBillDeskReviewService>(`/billdeskreviewservices/getByImedClaimServiceId/${imedClaimServiceId}`);
    }

    updateService(servicePayLoad: IServicePayload<IBillDeskReviewService>): any {
        const clone: IServicePayload<IBillDeskReviewService> = JSON.parse(JSON.stringify(servicePayLoad));
        nullOutNestedObjects(clone.ParentService);
        nullOutNestedObjects(clone.ChildService);
        return this.http.put<number>(`/billdeskreviewservices/update-service/${clone.ParentService.Id}`, clone);
    }
}
