import { ConferenceCallServiceDateTimeDynamicControlsPartial } from '@model/partials/conference-call-service-date-time.form-controls.partials';
import { ConferenceCallServiceService } from '../conference-call-service.service';
import { IConferenceCallServiceDateTime } from '@model/interfaces/conference-call-service-date-time';
import { UntypedFormBuilder, UntypedFormArray } from '@angular/forms';

import { Component, OnInit, Input, ChangeDetectorRef, SimpleChanges, OnChanges, ViewRef, OnDestroy } from '@angular/core';
import { safeDetectChanges } from '../../../../../common/cdr-safety/cdr-safety.library';

@Component({
    selector: 'app-conference-call-service-date-time-requested',
    templateUrl: './date-time-requested.component.html',
})
export class ConferenceCallDateTimeRequestedComponent implements OnInit, OnChanges, OnDestroy {
    @Input() dateTimesRequestedFormArray: UntypedFormArray;
    @Input() dateTimesRequested: IConferenceCallServiceDateTime[];
    abstractDateTimeRequestedControls: any;
    abstractDateTimeRequestedControlsFromValues: any = [];
    formSubmitted = false;
    formRendered = false;
    private emptyDateTimeRequested: IConferenceCallServiceDateTime;

    constructor(private fb: UntypedFormBuilder, private cdr: ChangeDetectorRef, private conferenceCallServiceService: ConferenceCallServiceService) { }

    ngOnInit(): void {
        this.emptyDateTimeRequested = this.conferenceCallServiceService.getEmptyConferenceCallDateTimeRequested();
        this.abstractDateTimeRequestedControls = new ConferenceCallServiceDateTimeDynamicControlsPartial(this.emptyDateTimeRequested);

        if (!this.dateTimesRequested.length) {
            this.addRow();
            this.addRow();
            this.addRow();
            this.addRow();
            this.addRow();
        } else {
            this.dateTimesRequested.forEach((line) => {
                const abstractControl = new ConferenceCallServiceDateTimeDynamicControlsPartial(line);
                this.abstractDateTimeRequestedControlsFromValues.push(abstractControl);
                this.dateTimesRequestedFormArray.controls.push(this.fb.group(line));
            });
        }
        this.formRendered = true;
    }

    ngOnDestroy(): void {
        this.cdr.detach();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.InputValues && changes.InputValues.previousValue && changes.InputValues.previousValue.length === 0) {
            this.formSubmitted = true;
            // we know the form has been submitted if the input Order detail changes due to how we handle the save
            // I'm including this because the rows have been disappearing on save when there aren't any initial input values
        }
    }

    addRow(): void {
        this.dateTimesRequestedFormArray.controls.push(this.fb.group({}));
        if (this.dateTimesRequested.length) {
            this.abstractDateTimeRequestedControlsFromValues.push(this.abstractDateTimeRequestedControls);
        }
        safeDetectChanges(this.cdr);
    }

    removeRow(i: number): void {
        this.dateTimesRequestedFormArray.controls.splice(i, 1);
        if (this.dateTimesRequested.length) {
            this.abstractDateTimeRequestedControlsFromValues.splice(i, 1);
        }
        safeDetectChanges(this.cdr);
    }
}
