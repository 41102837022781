import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export enum AssociationTypeEnums {
    SERVICE_STATUS = 1,
    FOLLOWUP_Type = 2,
    FOLLOWUP_Type_Exemptions = 3,
    EMAIL_ASSOCIATIONS = 4,
}

@Injectable()
export class AssociationsToggleService {
    private _associationType: BehaviorSubject<AssociationTypeEnums> = new BehaviorSubject<AssociationTypeEnums>(AssociationTypeEnums.SERVICE_STATUS);

    constructor() {}

    setAssociationType(value: AssociationTypeEnums): void {
        this._associationType.next(value);
    }

    getAssociationType(): Observable<AssociationTypeEnums> {
        return this._associationType.asObservable();
    }
}
