import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IModalResult, ModalService } from '@mt-ng2/modal-module';
import { format } from '@mt-ng2/format-functions';

import { IDuplicateAddressBook } from '@model/interfaces/custom/duplicate-address-book';
import { formatAddress } from '../../common/services/address-formatter.service';

@Injectable({
    providedIn: 'root',
})
export class DuplicatesConfirmService {

    constructor(private modalService: ModalService) {}

    showDuplicatesConfirmationModal(duplicates: IDuplicateAddressBook[], isIndividual: boolean): Observable<IModalResult> {
        return this.modalService.showModal({
            cancelButtonColor: '#d33',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Save',
            focusConfirm: false,
            html: getDuplicateTable(duplicates, isIndividual),
            showCancelButton: true,
            showCloseButton: true,
            title: `<strong>Duplicate ${duplicates.length > 1 ? 'Entries' : 'Entry'} Found!</strong>`,
            width: 800,
        });
    }

}

function getDuplicateTable(duplicates: IDuplicateAddressBook[], isIndividual: boolean): string {
    return `<div class="table-responsive">
            <table class="table table-hover table-striped">
            ${isIndividual ? getIndividualHeader() : getAddressBookHeader()}
            ${isIndividual ? getIndividuaBody(duplicates) : getAddressBookBody(duplicates)}
            </table>
            </div>
            <br />
            Are you sure you want to continue?`;
}

function getIndividualHeader(): string {
    return `<thead>
                <tr class="no-pointer">
                <th>
                    <b>Name</b>
                </th>
                <th>
                    <b>Primary Phone</b>
                </th>
                <th>
                    <b>Primary Email</b>
                </th>
               </tr>
            </thead>`;
}

function getIndividuaBody(duplicates: IDuplicateAddressBook[]): string {
    let tBody = '';
    duplicates.forEach((item: IDuplicateAddressBook) => {
        const linkAnchor = `<a target="_blank" href="/#/address-books/${item.AddressBook.Id}/individuals/${item.Individual.Id}"> `;
        const linkText = item.Individual ? item.Individual.FirstName + ' ' + item.Individual.LastName : '';
        const link = linkAnchor + linkText + ' </a>';

        tBody += `<tr class="no-pointer">
            <td class="floatLeft">${link}</td>
            <td class="floatLeft">${item.IndividualPhone ? format.phone(item.IndividualPhone.Phone) : ''} </td>
            <td class="floatLeft">${item.IndividualEmail ? item.IndividualEmail.Email : ''}</td>
            </tr>`;
    });
    return `<tbody>${tBody}</tbody>`;
}

function getAddressBookHeader(): string {
    return `<thead>
                <tr class="no-pointer">
                <th>
                    <b>Facility Name</b>
                </th>
                <th>
                    <b>Address</b>
                </th>
                <th>
                    <b>Primary Phone</b>
                </th>
                <th>
                    <b>Primary Email</b>
                </th>
               </tr>
            </thead>`;
}

function getAddressBookBody(duplicates: IDuplicateAddressBook[]): string {
    let tBody = '';
    duplicates.forEach((item: IDuplicateAddressBook) => {
        const linkAnchor = `<a target="_blank" href="/#/address-books/${item.AddressBook.Id}"> `;
        const linkText = item.AddressBook ? item.AddressBook.FacilityName : '';
        const link = linkAnchor + linkText + ' </a>';
        tBody += `<tr class="no-pointer">
            <td class="floatLeft">${link}</td>
            <td class="floatLeft">${item.Address ? formatAddress(item.Address) : ''}</td>
            <td class="floatLeft">${item.AddressBookPhone ? format.phone(item.AddressBookPhone.Phone) : ''} </td>
            <td class="floatLeft">${item.AddressBookEmail ? item.AddressBookEmail.Email : ''}</td>
            </tr>`;
    });
    return `<tbody>${tBody}</tbody>`;
}
