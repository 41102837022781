import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmailAddressService } from '@model/shared-entities/email-addresses/email-address.service';
import { AuthService } from '../../../../../node_modules/@mt-ng2/auth-module';
import { IEmailAddress } from '@model/interfaces/email-address';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class IndividualEmailService extends EmailAddressService {
    private individualEmailsSubject: Subject<number>;

    // Provides notification that the individual with the given id
    // has had its emails updated
    individualEmailsChanged$: Observable<number>;

    constructor(public http: HttpClient, public authService: AuthService) {
        super('/emails/individuals', http, authService);
        this.individualEmailsSubject = new Subject();
        this.individualEmailsChanged$ = this.individualEmailsSubject.asObservable();
    }

    saveEntity(parentId: number, entity: IEmailAddress): Observable<number> {
        return super.saveEntity(parentId, entity).pipe(
            tap((success) => {
                this.individualEmailsSubject.next(parentId);
            }),
        );
    }
}
