import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { PhonePipe } from '@mt-ng2/phone-control';
import { ImedClaimServiceService } from '../../imed-claims/services/imedclaimservice.service';

export interface IFaxEmailDataDTO {
    Name: string;
    Selected: boolean;
    Type: string;
    Values: IFaxEmailDataDetail[];
}

export interface IFaxEmailDataDetail {
    Id: number;
    Value: string;
    Type: string;
    IsPrimary: boolean;
    Selected: boolean;
}

@Component({
    selector: 'select-contact-faxes',
    styles: [
        `
            .input-list {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            .type {
                display: flex;
                justify-content: flex-start;
            }
            .input-list > *,
            .type > * {
                margin: 5px;
            }
            .small-text {
                font-size: 12px;
                color: #777;
            }
            .scrollable {
                max-height: 710px;
                overflow-y: auto;
                overflow-x: hidden;
            }
        `,
    ],
    templateUrl: './select-contact-faxes.component.html',
})
export class SelectContactFaxes implements OnInit {
    items: IFaxEmailDataDTO[] = [];
    includeNoContactFound = false;

    @Input() imedclaimserviceid: number;
    @Input() imedclaimid: number;
    @ViewChild('default') default;
    @Output() close: EventEmitter<any> = new EventEmitter();
    @Output() selectItems: EventEmitter<string[]> = new EventEmitter();

    constructor(
        private imedClaimServiceService: ImedClaimServiceService,
        private phonePipe: PhonePipe,
        private notificationsService: NotificationsService,
    ) {}

    ngOnInit(): void {
        if (this.imedclaimserviceid) {
            this.imedClaimServiceService.getAllContactFaxes(this.imedclaimserviceid).subscribe((contacts) => {
                this.items = contacts;
                if (contacts.length === 0) {
                    this.notificationsService.warning('No faxes were found for this Service.');
                }
            });
        } else {
            this.imedClaimServiceService.getAllContactFaxesForCase(this.imedclaimid).subscribe((contacts) => {
                this.items = contacts;
                if (contacts.length === 0) {
                    this.notificationsService.warning('No faxes were found for this Claim.');
                }
            });
        }
    }

    emitClose(): void {
        this.close.emit();
    }

    emitSelectedItems(): void {
        const selectedItems = this.items
            .filter((x) => x.Selected)
            .map((x) => x.Values.filter((y) => y.Selected).map((z) => this.phonePipe.transform(z.Value)))
            .reduce((acc, val) => acc.concat(val), []);
        this.selectItems.emit(selectedItems);
        this.emitClose();
    }

    selectItem(index: number, value: string): void {
        this.items[index].Values.forEach((x) => (x.Id === parseInt(value, 10) ? (x.Selected = true) : (x.Selected = false)));
    }

    selectContact(index): void {
        this.items.forEach((x) => (x.Selected = false));
        this.items[index].Selected = true;
    }

    checkIfNoFaxesPresent(index): boolean {
        const contact = this.items[index];
        return contact.Values.length === 1 && contact.Values[0].Id === -1;
    }

    toggleIncludeNoContactFound(): void {
        this.includeNoContactFound = !this.includeNoContactFound;
    }
}
