// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            .form-select > dynamic-form-label {
                display: none;
            }
        `, "",{"version":3,"sources":["webpack://./src/app/master-service-statuses/master-service-status-form/master-service-status-form.component.ts"],"names":[],"mappings":";YACY;gBACI,aAAa;YACjB","sourcesContent":["\n            .form-select > dynamic-form-label {\n                display: none;\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
