import { Validators } from '@angular/forms';
import { IExpandableObject } from '../expandable-object';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
    ITimepickerOptions,
} from '@mt-ng2/dynamic-form';

import { ImeAppointmentDynamicControls, IImeAppointmentDynamicControlsParameters } from '../form-controls/ime-appointment.form-controls';
import { IImeAppointment } from '../interfaces/ime-appointment';
import { IUser } from '../interfaces/user';
import { IImeService } from '../interfaces/ime-service';
import { CkEditorDynamicFieldComponent } from '../../common/ckeditor-dynamic-field/ckeditor-dynamic-field.component';

export class ImeAppointmentDynamicControlsPartial extends ImeAppointmentDynamicControls {
    constructor(imeappointmentPartial?: IImeAppointment, additionalParameters?: IImeAppointmentDynamicControlsParameters) {
        super(imeappointmentPartial, additionalParameters);

        ((<DynamicField>this.Form.AppointmentDateTime).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Input,
            inputType: InputTypes.DateTimepicker,
            timepickerOptions: { meridian: true } as ITimepickerOptions,
        })),
            (<DynamicField>this.Form.AppointmentDateTime).setRequired(true);
        (<DynamicField>this.Form.AppointmentDateTime).label = 'Appointment Date / Time';
        (<DynamicField>this.Form.AppointmentDateTime).labelHtml = '<label>Appointment Date / Time</label>';

        (<DynamicField>this.Form.Notes).component = CkEditorDynamicFieldComponent;

        // if (!(imeappointmentPartial.AppointmentDateTime instanceof Date) && imeappointmentPartial.AppointmentDateTime) {
        //     let offsetDate = new Date(imeappointmentPartial.AppointmentDateTime);
        //     offsetDate.setHours(offsetDate.getHours() - 4); // this is bad, offsetting 4 hours from db
        //     (<DynamicField>this.Form.AppointmentDateTime).value = offsetDate;
        //     (<DynamicField>this.View.AppointmentDateTime).value = offsetDate;
        // }
    }
}
