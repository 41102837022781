import { Validators } from '@angular/forms';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, DynamicLabel, InputTypes } from '@mt-ng2/dynamic-form';
import { ImedClaimDynamicControls, IImedClaimDynamicControlsParameters } from '../form-controls/imed-claim.form-controls';
import { IImedClaim } from '../interfaces/imed-claim';

export class ImedClaimDynamicControlsPartial extends ImedClaimDynamicControls {
    constructor(imedclaimPartial?: IImedClaim, additionalParameters?: IImedClaimDynamicControlsParameters) {
        super(imedclaimPartial, additionalParameters);

        (<DynamicField>this.Form.FirstName).setMaxLength(100);
        (<DynamicField>this.Form.LastName).setMaxLength(100);
        (<DynamicField>this.Form.Ssn).setMaxLength(4);
        (<DynamicField>this.Form.Birthdate).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.Birthdate).component = FormDateInputComponent;
        (<DynamicField>this.Form.Birthdate).setOptionsForDateOfBirth();
        (<DynamicField>this.Form.DateofAllegedAccidentOrInjury).labelHtml = '<label>Date of Alleged Accident or Injury</label>';
        (<DynamicField>this.Form.Ssn).labelHtml = '<label>Last 4 of SSN</label>';
        (<DynamicField>this.Form.SchedulingAssignedToId).validation = [Validators.required];
        (<DynamicField>this.Form.SchedulingAssignedToId).validators = { required: true };
        (<DynamicField>this.Form.CompilationAssignedToId).validation = [Validators.required];
        (<DynamicField>this.Form.CompilationAssignedToId).validators = { required: true };
        (<DynamicField>this.Form.ReportAssignedToId).validation = [Validators.required];
        (<DynamicField>this.Form.ReportAssignedToId).validators = { required: true };

        (<DynamicField>this.Form.Notes).validation = [];
        (<DynamicField>this.Form.Notes).validators = {};

        (<DynamicField>this.Form.PastMedicalHistory).disabled = true;
        (<DynamicField>this.Form.PastSubsequentHistory).disabled = true;
        (<DynamicField>this.Form.OccupationalStatus).disabled = true;
        (<DynamicField>this.Form.CurrentCourseOfTreatment).disabled = true;
        (<DynamicField>this.Form.Impression).disabled = true;
        (<DynamicField>this.Form.OccupationalStatus).label = 'Occupational History';
        (<DynamicField>this.Form.CurrentSymptomsAndCompliants).disabled = true;
        (<DynamicField>this.Form.CurrentSymptomsAndCompliants).label = 'Current Symptoms and Complaints';
        (<DynamicField>this.View.CurrentSymptomsAndCompliants).label = 'Current Symptoms and Complaints';
        (<DynamicField>this.Form.HistoryOfAllegedInjury).label = 'History of Alleged Accident';
        (<DynamicField>this.Form.HistoryOfAllegedInjury).disabled = true;

        (<DynamicField>this.View.HistoryOfAllegedInjury).label = 'History of Alleged Accident';
        (<DynamicField>this.View.OccupationalStatus).label = 'Occupational History';

        (<DynamicLabel>this.View.DateofAllegedAccidentOrInjury).label = 'Date of Alleged Accident or Injury';
        (<DynamicLabel>this.View.Ssn).label = 'Last 4 of SSN';

        (<DynamicField>this.Form.AdditionalInformation).type.inputType = InputTypes.Textarea;
        (<DynamicField>this.Form.RequestorTypeId).validation = [Validators.required];
        (<DynamicField>this.Form.RequestorTypeId).validators = { required: true };
    }
}
