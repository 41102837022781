import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IImedClaimServiceFollowup } from '../interfaces/imed-claim-service-followup';
import { IUser } from '../interfaces/user';
import { IFollowupType } from '../interfaces/followup-type';
import { IImedClaimFollowup } from '../interfaces/imed-claim-followup';
import { IImedClaimService } from '../interfaces/imed-claim-service';

export interface IImedClaimServiceFollowupDynamicControlsParameters {
    formGroup?: string;
    imedClaimServices?: IImedClaimService[];
    followupTypes?: IFollowupType[];
    createdBies?: IUser[];
    modifiedBies?: IUser[];
    imedClaimFollowups?: IImedClaimFollowup[];
}

export class ImedClaimServiceFollowupDynamicControls {

    formGroup: string;
    imedClaimServices: IImedClaimService[];
    followupTypes: IFollowupType[];
    createdBies: IUser[];
    modifiedBies: IUser[];
    imedClaimFollowups: IImedClaimFollowup[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private imedclaimservicefollowup?: IImedClaimServiceFollowup, additionalParameters?: IImedClaimServiceFollowupDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'ImedClaimServiceFollowup';
        this.imedClaimServices = additionalParameters && additionalParameters.imedClaimServices || undefined;
        this.followupTypes = additionalParameters && additionalParameters.followupTypes || undefined;
        this.createdBies = additionalParameters && additionalParameters.createdBies || undefined;
        this.modifiedBies = additionalParameters && additionalParameters.modifiedBies || undefined;
        this.imedClaimFollowups = additionalParameters && additionalParameters.imedClaimFollowups || undefined;

        this.Form = {
            Amount: new DynamicField({
                formGroup: this.formGroup,
                label: 'Amount',
                name: 'Amount',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.Amount || null,
            }),
            Archived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived',
                name: 'Archived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.hasOwnProperty('Archived') && this.imedclaimservicefollowup.Archived !== null ? this.imedclaimservicefollowup.Archived : false,
            }),
            CheckNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Check Number',
                name: 'CheckNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.hasOwnProperty('CheckNumber') && this.imedclaimservicefollowup.CheckNumber !== null ? this.imedclaimservicefollowup.CheckNumber.toString() : '',
            }),
            ClearanceDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Clearance Date',
                name: 'ClearanceDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.ClearanceDate || null,
            }),
            CommunicationMethod: new DynamicField({
                formGroup: this.formGroup,
                label: 'Communication Method',
                name: 'CommunicationMethod',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.CommunicationMethod || null,
            }),
            ConfirmedCancellationWithPhysiciansOfficeSpokeWith: new DynamicField({
                formGroup: this.formGroup,
                label: 'Confirmed Cancellation With Physicians Office Spoke With',
                name: 'ConfirmedCancellationWithPhysiciansOfficeSpokeWith',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(75) ],
                validators: { 'maxlength': 75 },
                value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.hasOwnProperty('ConfirmedCancellationWithPhysiciansOfficeSpokeWith') && this.imedclaimservicefollowup.ConfirmedCancellationWithPhysiciansOfficeSpokeWith !== null ? this.imedclaimservicefollowup.ConfirmedCancellationWithPhysiciansOfficeSpokeWith.toString() : '',
            }),
            ConfirmedWithPhysiciansOfficeSpokeWith: new DynamicField({
                formGroup: this.formGroup,
                label: 'Confirmed With Physicians Office Spoke With',
                name: 'ConfirmedWithPhysiciansOfficeSpokeWith',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(75) ],
                validators: { 'maxlength': 75 },
                value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.hasOwnProperty('ConfirmedWithPhysiciansOfficeSpokeWith') && this.imedclaimservicefollowup.ConfirmedWithPhysiciansOfficeSpokeWith !== null ? this.imedclaimservicefollowup.ConfirmedWithPhysiciansOfficeSpokeWith.toString() : '',
            }),
            CreatedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Created By',
                name: 'CreatedById',
                options: this.createdBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.CreatedById || null,
            }),
            Date: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date',
                name: 'Date',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.Date || null,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.DateModified || null,
            }),
            DeferredUntil: new DynamicField({
                formGroup: this.formGroup,
                label: 'Deferred Until',
                name: 'DeferredUntil',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.DeferredUntil || null,
            }),
            FollowupTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Followup Type',
                name: 'FollowupTypeId',
                options: this.followupTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.FollowupTypeId || null,
            }),
            ImeAppointmentConfirmedWithPlaintiffsAttorneyOfficeByName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Ime Appointment Confirmed With Plaintiffs Attorney Office By Name',
                name: 'ImeAppointmentConfirmedWithPlaintiffsAttorneyOfficeByName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.hasOwnProperty('ImeAppointmentConfirmedWithPlaintiffsAttorneyOfficeByName') && this.imedclaimservicefollowup.ImeAppointmentConfirmedWithPlaintiffsAttorneyOfficeByName !== null ? this.imedclaimservicefollowup.ImeAppointmentConfirmedWithPlaintiffsAttorneyOfficeByName.toString() : '',
            }),
            ImedClaimFollowupId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Imed Claim Followup',
                name: 'ImedClaimFollowupId',
                options: this.imedClaimFollowups,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.ImedClaimFollowupId || null,
            }),
            ImedClaimServiceId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Imed Claim Service',
                name: 'ImedClaimServiceId',
                options: this.imedClaimServices,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.ImedClaimServiceId || null,
            }),
            LegacyFollowUpId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Legacy Follow Up',
                name: 'LegacyFollowUpId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(25) ],
                validators: { 'maxlength': 25 },
                value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.hasOwnProperty('LegacyFollowUpId') && this.imedclaimservicefollowup.LegacyFollowUpId !== null ? this.imedclaimservicefollowup.LegacyFollowUpId.toString() : '',
            }),
            ModifiedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Modified By',
                name: 'ModifiedById',
                options: this.modifiedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.ModifiedById || null,
            }),
            Notes: new DynamicField({
                formGroup: this.formGroup,
                label: 'Notes',
                name: 'Notes',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.hasOwnProperty('Notes') && this.imedclaimservicefollowup.Notes !== null ? this.imedclaimservicefollowup.Notes.toString() : '',
            }),
            PersonContactedOrSpokenTo: new DynamicField({
                formGroup: this.formGroup,
                label: 'Person Contacted Or Spoken To',
                name: 'PersonContactedOrSpokenTo',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.hasOwnProperty('PersonContactedOrSpokenTo') && this.imedclaimservicefollowup.PersonContactedOrSpokenTo !== null ? this.imedclaimservicefollowup.PersonContactedOrSpokenTo.toString() : '',
            }),
            ReceiptOfNoticeOfImeConfirmedByName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Receipt Of Notice Of Ime Confirmed By Name',
                name: 'ReceiptOfNoticeOfImeConfirmedByName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.hasOwnProperty('ReceiptOfNoticeOfImeConfirmedByName') && this.imedclaimservicefollowup.ReceiptOfNoticeOfImeConfirmedByName !== null ? this.imedclaimservicefollowup.ReceiptOfNoticeOfImeConfirmedByName.toString() : '',
            }),
            ReceivedAppointmentDateTime: new DynamicField({
                formGroup: this.formGroup,
                label: 'Received Appointment Date Time',
                name: 'ReceivedAppointmentDateTime',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.ReceivedAppointmentDateTime || null,
            }),
            ReportDueBy: new DynamicField({
                formGroup: this.formGroup,
                label: 'Report Due By',
                name: 'ReportDueBy',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.ReportDueBy || null,
            }),
            ReportForwardedToClientDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Report Forwarded To Client Date',
                name: 'ReportForwardedToClientDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.ReportForwardedToClientDate || null,
            }),
            ReportReadyOnOrAround: new DynamicField({
                formGroup: this.formGroup,
                label: 'Report Ready On Or Around',
                name: 'ReportReadyOnOrAround',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.ReportReadyOnOrAround || null,
            }),
            TrackingNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Tracking Number',
                name: 'TrackingNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.hasOwnProperty('TrackingNumber') && this.imedclaimservicefollowup.TrackingNumber !== null ? this.imedclaimservicefollowup.TrackingNumber.toString() : '',
            }),
            Via: new DynamicField({
                formGroup: this.formGroup,
                label: 'Via',
                name: 'Via',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.Via || null,
            }),
        };

        this.View = {
            Amount: new DynamicLabel({
			    label: 'Amount',
			    value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.Amount || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: null,
			        scale: 2,
			    })
			}
            ),
            Archived: new DynamicLabel({
			    label: 'Archived',
			    value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.hasOwnProperty('Archived') && this.imedclaimservicefollowup.Archived !== null ? this.imedclaimservicefollowup.Archived : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            CheckNumber: new DynamicLabel({
			    label: 'Check Number',
			    value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.hasOwnProperty('CheckNumber') && this.imedclaimservicefollowup.CheckNumber !== null ? this.imedclaimservicefollowup.CheckNumber.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ClearanceDate: new DynamicLabel({
			    label: 'Clearance Date',
			    value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.ClearanceDate || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            CommunicationMethod: new DynamicLabel({
			    label: 'Communication Method',
			    value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.CommunicationMethod || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: NumericInputTypes.Integer,
			        scale: null,
			    })
			}
            ),
            ConfirmedCancellationWithPhysiciansOfficeSpokeWith: new DynamicLabel({
			    label: 'Confirmed Cancellation With Physicians Office Spoke With',
			    value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.hasOwnProperty('ConfirmedCancellationWithPhysiciansOfficeSpokeWith') && this.imedclaimservicefollowup.ConfirmedCancellationWithPhysiciansOfficeSpokeWith !== null ? this.imedclaimservicefollowup.ConfirmedCancellationWithPhysiciansOfficeSpokeWith.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ConfirmedWithPhysiciansOfficeSpokeWith: new DynamicLabel({
			    label: 'Confirmed With Physicians Office Spoke With',
			    value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.hasOwnProperty('ConfirmedWithPhysiciansOfficeSpokeWith') && this.imedclaimservicefollowup.ConfirmedWithPhysiciansOfficeSpokeWith !== null ? this.imedclaimservicefollowup.ConfirmedWithPhysiciansOfficeSpokeWith.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            CreatedById: new DynamicLabel({
			    label: 'Created By',
			    value: getMetaItemValue(this.createdBies as unknown as IMetaItem[], this.imedclaimservicefollowup && this.imedclaimservicefollowup.hasOwnProperty('CreatedById') && this.imedclaimservicefollowup.CreatedById !== null ? this.imedclaimservicefollowup.CreatedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Date: new DynamicLabel({
			    label: 'Date',
			    value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.Date || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DateCreated: new DynamicLabel({
			    label: 'Date Created',
			    value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.DateCreated || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DateModified: new DynamicLabel({
			    label: 'Date Modified',
			    value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.DateModified || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DeferredUntil: new DynamicLabel({
			    label: 'Deferred Until',
			    value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.DeferredUntil || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            FollowupTypeId: new DynamicLabel({
			    label: 'Followup Type',
			    value: getMetaItemValue(this.followupTypes as unknown as IMetaItem[], this.imedclaimservicefollowup && this.imedclaimservicefollowup.hasOwnProperty('FollowupTypeId') && this.imedclaimservicefollowup.FollowupTypeId !== null ? this.imedclaimservicefollowup.FollowupTypeId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ImeAppointmentConfirmedWithPlaintiffsAttorneyOfficeByName: new DynamicLabel({
			    label: 'Ime Appointment Confirmed With Plaintiffs Attorney Office By Name',
			    value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.hasOwnProperty('ImeAppointmentConfirmedWithPlaintiffsAttorneyOfficeByName') && this.imedclaimservicefollowup.ImeAppointmentConfirmedWithPlaintiffsAttorneyOfficeByName !== null ? this.imedclaimservicefollowup.ImeAppointmentConfirmedWithPlaintiffsAttorneyOfficeByName.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ImedClaimFollowupId: new DynamicLabel({
			    label: 'Imed Claim Followup',
			    value: getMetaItemValue(this.imedClaimFollowups as unknown as IMetaItem[], this.imedclaimservicefollowup && this.imedclaimservicefollowup.hasOwnProperty('ImedClaimFollowupId') && this.imedclaimservicefollowup.ImedClaimFollowupId !== null ? this.imedclaimservicefollowup.ImedClaimFollowupId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ImedClaimServiceId: new DynamicLabel({
			    label: 'Imed Claim Service',
			    value: getMetaItemValue(this.imedClaimServices as unknown as IMetaItem[], this.imedclaimservicefollowup && this.imedclaimservicefollowup.hasOwnProperty('ImedClaimServiceId') && this.imedclaimservicefollowup.ImedClaimServiceId !== null ? this.imedclaimservicefollowup.ImedClaimServiceId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            LegacyFollowUpId: new DynamicLabel({
			    label: 'Legacy Follow Up',
			    value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.hasOwnProperty('LegacyFollowUpId') && this.imedclaimservicefollowup.LegacyFollowUpId !== null ? this.imedclaimservicefollowup.LegacyFollowUpId.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ModifiedById: new DynamicLabel({
			    label: 'Modified By',
			    value: getMetaItemValue(this.modifiedBies as unknown as IMetaItem[], this.imedclaimservicefollowup && this.imedclaimservicefollowup.hasOwnProperty('ModifiedById') && this.imedclaimservicefollowup.ModifiedById !== null ? this.imedclaimservicefollowup.ModifiedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Notes: new DynamicLabel({
			    label: 'Notes',
			    value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.hasOwnProperty('Notes') && this.imedclaimservicefollowup.Notes !== null ? this.imedclaimservicefollowup.Notes.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Textarea,
			        scale: null,
			    })
			}
            ),
            PersonContactedOrSpokenTo: new DynamicLabel({
			    label: 'Person Contacted Or Spoken To',
			    value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.hasOwnProperty('PersonContactedOrSpokenTo') && this.imedclaimservicefollowup.PersonContactedOrSpokenTo !== null ? this.imedclaimservicefollowup.PersonContactedOrSpokenTo.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ReceiptOfNoticeOfImeConfirmedByName: new DynamicLabel({
			    label: 'Receipt Of Notice Of Ime Confirmed By Name',
			    value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.hasOwnProperty('ReceiptOfNoticeOfImeConfirmedByName') && this.imedclaimservicefollowup.ReceiptOfNoticeOfImeConfirmedByName !== null ? this.imedclaimservicefollowup.ReceiptOfNoticeOfImeConfirmedByName.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ReceivedAppointmentDateTime: new DynamicLabel({
			    label: 'Received Appointment Date Time',
			    value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.ReceivedAppointmentDateTime || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            ReportDueBy: new DynamicLabel({
			    label: 'Report Due By',
			    value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.ReportDueBy || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            ReportForwardedToClientDate: new DynamicLabel({
			    label: 'Report Forwarded To Client Date',
			    value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.ReportForwardedToClientDate || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            ReportReadyOnOrAround: new DynamicLabel({
			    label: 'Report Ready On Or Around',
			    value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.ReportReadyOnOrAround || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            TrackingNumber: new DynamicLabel({
			    label: 'Tracking Number',
			    value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.hasOwnProperty('TrackingNumber') && this.imedclaimservicefollowup.TrackingNumber !== null ? this.imedclaimservicefollowup.TrackingNumber.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Via: new DynamicLabel({
			    label: 'Via',
			    value: this.imedclaimservicefollowup && this.imedclaimservicefollowup.Via || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: NumericInputTypes.Integer,
			        scale: null,
			    })
			}
            ),
        };

    }
}
