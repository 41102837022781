import { OnInit, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TaskTemplateService } from '../services/tasktemplate.service';
import { ITaskTemplate } from '@model/interfaces/task-template';

@Component({
    selector: 'app-system-task-detail',
    templateUrl: './system-task-detail.component.html',
    styles: [`
        .m-2 {
            margin-bottom: 2rem;
        }
    `]
})
export class SystemTaskDetailComponent implements OnInit {
    taskId: number;
    task: ITaskTemplate;

    constructor(private activatedRoute: ActivatedRoute, private taskTemplateService: TaskTemplateService) { }

    ngOnInit(): void {
        this.taskId = +this.activatedRoute.snapshot.paramMap.get('taskTemplateId');
        this.taskTemplateService.getById(this.taskId).subscribe((task) => (this.task = task));
    }
}
