import { Component, Input } from '@angular/core';
import { PhysicianService } from '../../address-books/individuals/physicians/physician.service';
import { AddressBookService } from '../../address-books/addressbook.service';
import { IAddressBooksForList } from '@model/interfaces/address-books-for-list';
import { MetaAddressBookTypeIdEnums } from '../constants/Enums';
import { IndividualService } from '../../address-books/individuals/individual.service';

@Component({
    selector: 'tooltip',
    styles: [
        `
            div {
                background-color: #4c5166;
                color: white;
                border-radius: 4px;
                padding: 10px;
            }
        `,
    ],
    template: `<div *ngIf="html" [innerHTML]="html"></div>`,
})
export class AddressesTooltipComponent {
    @Input() addressBook: IAddressBooksForList;
    html: string;

    constructor(
        private physicianService: PhysicianService,
        private addressBookService: AddressBookService,
        private individualService: IndividualService,
    ) {}

    ngOnInit(): void {
        this.getAddresses();
    }

    formatAddress(addresses): string {
        return addresses
            .map((address) => {
                if (address && address.Address1) {
                    return `<span style="padding-top: 5px;">${address.AddressTypes ? '[' + address.AddressTypes.map((at) => at.Name).join(', ') + '] ' : ''}${address.Address1 ? address.Address1 + ', ' : ''} ${address.Address2 ? address.Address2 + ', ' : ''
                        }
                ${address.City ? address.City + ', ' : ''} ${address.State ? address.State.StateCode : ''
                        } ${address.Zip ? address.Zip : ''}${address.County ? '-' + address.County.CountyName + ' County' : ''} </span>`;
                } else {
                    return '';
                }
            })
            .join('<br />');
    }

    getAddresses(): void {
        if (this.addressBook.MetaAddressBookTypeId === +MetaAddressBookTypeIdEnums.Facility_Organization && this.addressBook.IndividualId) {
            this.individualService.getIndividualAddresses(this.addressBook.IndividualId).subscribe((ia) => {
                if (ia.length) {
                    this.html = this.formatAddress(ia);
                } else {
                    this.addressBookService
                        .getFacilityAddresses(this.addressBook.AddressBookId)
                        .subscribe((fa) => {
                            this.html = this.formatAddress(fa);
                        });
                }
            });
        } else {
            this.addressBookService.getFacilityAddresses(this.addressBook.AddressBookId).subscribe((x) => (this.html = this.formatAddress(x)));
        }
    }
}
