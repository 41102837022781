// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            .delete-requested {
                background-color: #ffc0cb;
            }
        `, "",{"version":3,"sources":["webpack://./src/app/common/film-information/imed-claim-service-film-information-list.component.ts"],"names":[],"mappings":";YACY;gBACI,yBAAyB;YAC7B","sourcesContent":["\n            .delete-requested {\n                background-color: #ffc0cb;\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
