import { Component, OnInit, Input } from '@angular/core';
import { IEntityUser } from '@common/hub/user-on-service-socket.service';

@Component({
    selector: 'app-concurrent-user',
    styleUrls: ['./concurrent-users.component.less'],
    template: `
        <div *ngIf="concurrentUsers && concurrentUsers.length" class="sticky">
            <ul class="user-list">
                <li>Warning!</li>
                <li>Concurrent</li>
                <li>Users:</li>
                <li class="user" *ngFor="let user of concurrentUsers">
                    {{ user.UserName }}
                </li>
            </ul>
        </div>
    `,
})
export class ConcurrentUserComponent {
    @Input() concurrentUsers: IEntityUser[] = [];
    constructor() {}
}
