// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            .remove-dynamic-template-column {
                text-align: right;
                padding-right: 30px !important;
            }
        `, "",{"version":3,"sources":["webpack://./src/app/master-followup-types/master-followup-types-list/master-followup-types.component.ts"],"names":[],"mappings":";YACY;gBACI,iBAAiB;gBACjB,8BAA8B;YAClC","sourcesContent":["\n            .remove-dynamic-template-column {\n                text-align: right;\n                padding-right: 30px !important;\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
