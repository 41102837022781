import { Directive, HostListener, Input, EventEmitter, Output } from '@angular/core';

import { ISoftPopUpValidationFunctionResult, SoftPopUpResults } from '@model/interfaces/custom/soft-popup';
import { ModalService } from '@mt-ng2/modal-module';

export type SoftPopUpValidationFunction = () => ISoftPopUpValidationFunctionResult;

@Directive({
    selector: '[selectpopup]',
})
export class SelectPopUpDirective {
    @Input('validationFunction') validationFunction: SoftPopUpValidationFunction;

    @Output('selectpopup') passed: EventEmitter<string> = new EventEmitter<string>();
    @Output('cancel') cancel: EventEmitter<string> = new EventEmitter<string>();

    constructor(private modalService: ModalService) {}

    @HostListener('change', ['$event']) onchange(event: MouseEvent): void {
        event.preventDefault();
        event.stopImmediatePropagation();
        event.stopPropagation();

        const validationResult = this.validationFunction();

        if (validationResult.result === SoftPopUpResults.Passed) {
            this.passed.emit();
            return;
        }

        if (validationResult.popup && validationResult.popup.Messages.length) {
            const popup = validationResult.popup;
            this.modalService.showModal({
                cancelButtonColor: '#d33',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Save',
                focusConfirm: false,
                html: popup.Messages.join('<br/>'),
                showCancelButton: true,
                showCloseButton: false,
                showConfirmButton: validationResult.isHardPopup ? false : true,
                title: 'Are you sure you want to continue?',
                width: 800,
            }).subscribe((result) => {
                if (result.value) {
                    this.passed.emit();
                } else {
                    this.cancel.emit();
                }
            });
        }
    }
}
