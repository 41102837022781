// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            .attachment-table {
                font-size: 12px;
            }
        `, "",{"version":3,"sources":["webpack://./src/app/common/communication-attachment-control/communication-attachment-control.component.ts"],"names":[],"mappings":";YACY;gBACI,eAAe;YACnB","sourcesContent":["\n            .attachment-table {\n                font-size: 12px;\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
