import { IFilmInformationDynamicControlsParameters, FilmInformationDynamicControls } from '@model/form-controls/film-information.form-controls';
import { IFilmInformation } from '@model/interfaces/film-information.d';
import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';
import { FilmInformationDynamicControlsPartial } from '@model/partials/film-information-partial.form-controls';
import { IBodyPart } from '@model/interfaces/body-part';
import { IImageType } from '@model/interfaces/image-type';
import { IFilmInformationDynamicControlsParametersPartial } from '@model/partials/film-information-dynamic-controls.partial';

export class FilmInformationDynamicConfig<T extends IFilmInformation> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private bodyParts: IBodyPart[], private imageTypes: IImageType[], private filmInformation: T, private radiologyFacilityName: string, private configControls?: string[]) {
        super();

        const additionalParams: IFilmInformationDynamicControlsParametersPartial = {
            radiologyFacilityName: this.radiologyFacilityName,
        };
        const dynamicControls = new FilmInformationDynamicControlsPartial(this.bodyParts, this.imageTypes, this.filmInformation, additionalParams);

        if (!configControls) {
            this.configControls = [
                'ImedClaimServiceId',
                'ImageDate',
                'ImageType',
                'BodyPartImaged',
                'CreatedById',
                'ModifiedById',
                'DateCreated',
                'DateModified',
                'DateProcessed',
                'Archived',
                'FacilityName',
            ];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
