import { IUser } from '@model/interfaces/user';
import {
    EntityListConfig,
    EntityListColumn,
    IEntityListColumn,
    IEntityListConfig,
    EntityListDeleteColumn,
} from '@mt-ng2/entity-list-module';

export class UsersEntityListConfig extends EntityListConfig {
    columns: IEntityListColumn[];

    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessorFunction: function (user: IUser): string {
                        return `${user.FirstName} ${user.LastName}`;
                    },
                    name: 'Name',
                    sort: {
                        sortProperty: 'LastName',
                    },
                }),
                new EntityListColumn({
                    linkFunction: function (user: IUser): void {
                        window.open(`mailto:${user.Email}`);
                    },
                    name: 'Email',
                }),
            ],
            delete: new EntityListDeleteColumn({
                columnHtml: (entity: IUser) =>
                    entity.Archived
                        ? '<div class="text-center"><i class="fa fa-trash fa-lg aria-hidden="true"></i><i class="fa fa-lg fa-undo overlay-fa" ></i></div>'
                        : '<div class="text-center"><i class="fa fa-trash fa-lg" aria-hidden="true"></i></div>',
                confirm: false,
                headerText: 'Delete',
            }),
            rowClass: (entity: IUser) => (entity.Archived ? 'archived' : null),
        };
        super(listConfig);
    }
}
