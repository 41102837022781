import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';

import { IndividualDynamicControls, IIndividualDynamicControlsParameters } from '@model/form-controls/individual.form-controls';
import { IIndividual } from '@model/interfaces/individual';

export class IndividualDynamicConfig<T extends IIndividual> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private individual: T, private configControls?: string[]) {
        super();

        const dynamicControls = new IndividualDynamicControls(this.individual);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['Title', 'FirstName', 'LastName', 'Suffix', 'Attention'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
