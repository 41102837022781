import { Subscription, forkJoin } from 'rxjs';
import { IUser } from '@model/interfaces/user.d';
import { IFilmReviewService } from '@model/interfaces/film-review-service.d';
import { AuthService, ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IImedClaimServiceContactInfo } from './../../../../imed-claim-basic-info/imed-claim-contacts-info';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { ImedClaimServiceService } from '../../../imedclaimservice.service';
import { IImedClaimService } from '@model/interfaces/imed-claim-service';
import { UserService } from '../../../../../users/user.service';
import { IServicePayload } from '@model/interfaces/custom/update-service-payload';
import { finalize } from 'rxjs/operators';
import { ClaimTypes } from '@model/ClaimTypes';
import { PopUpService } from '../../../../../common/services/popup-service';
import { FilmReviewServiceDynamicConfig } from '../../../common-components/film-review/film-review-service.dynamic-config';
import { FilmReviewServiceService } from '../../../common-components/film-review/film-review-service.service';
import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    IDynamicField,
    IDynamicFieldType,
    InputTypes,
    NumericInputTypes,
} from '@mt-ng2/dynamic-form';
import { IImeService } from '@model/interfaces/ime-service';

@Component({
    selector: 'app-wc-film-review-service-basic-info',
    templateUrl: './wc-film-review-service-basic-info.component.html',
})
export class WCFilmReviewServiceBasicInfoComponent implements OnInit {
    @Input() iImedClaimServiceModel: IImedClaimService;
    @Input() filmReviewServiceModel: IFilmReviewService;
    @Input() canEdit: boolean;
    @Input() bodyPartsToBeExamined: string;
    @Input() reportDueBy: string;
    @Input() iImedClaimServiceContactInfo: IImedClaimServiceContactInfo;
    @Output('onRefreshStatus') onRefreshStatus: EventEmitter<any> = new EventEmitter<any>();
    @Output('physicianFaxSent') physicianFaxSent: EventEmitter<any> = new EventEmitter<any>();
    @Input() iImeService: IImeService;
    @Input() disablePhysicianFacilityControl: boolean;

    isEditing: boolean;
    isHovered: boolean;
    formRendered = false;

    filmReviewServiceFirstConfig: any = {};
    filmReviewServiceSecondConfig: any = {};

    filmReviewServiceForm: FormGroup;

    filmReviewFormFirstFactory: FilmReviewServiceDynamicConfig<IFilmReviewService>;
    filmReviewFormSecondFactory: FilmReviewServiceDynamicConfig<IFilmReviewService>;

    doubleClickIsDisabled = false;

    users = [];
    selectedDiscCopiedUser: IUser;
    selectedDiscMailedUser: IUser;
    imedClaimServiceId: number;
    ServiceId: number;
    subscriptions = new Subscription();
    canSeeAuditLog = false;

    contractRateFrom: number;
    contractRateTo: number;
    purposeOfExam: string;
    initialContractRateFrom: number;
    initialContractRateTo: number;
    formatter: any;
    initialImedClaimServiceContactInfo: IImedClaimServiceContactInfo;

    constructor(
        private imedClaimServiceService: ImedClaimServiceService,
        private notificationsService: NotificationsService,
        private fb: UntypedFormBuilder,
        private authService: AuthService,
        private filmReviewService: FilmReviewServiceService,
        private userservice: UserService,
        private claimsService: ClaimsService,
        private popUpService: PopUpService,
    ) {}

    ngOnInit(): void {
        this.formatter = new Intl.NumberFormat('en-US', {
            currency: 'USD',
            style: 'currency',
        });
        this.isEditing = false;
        this.canSeeAuditLog = this.claimsService.hasClaim(ClaimTypes.Audit, [ClaimValues.FullAccess, ClaimValues.ReadOnly]);

        this.userservice.getAll().subscribe((users) => {
            this.users = users;
            this.setConfig();
        });
        this.subscriptions.add(
            this.imedClaimServiceService.changeEmitted$.subscribe((iImedClaimServiceModel) => {
                this.iImedClaimServiceModel = iImedClaimServiceModel;
                this.setConfig();
            }),
        );
        this.assignDefaultValues();
    }

    ngOnChanges(changes: any): void {
        if (changes.iImedClaimServiceContactInfo) {
            this.initialImedClaimServiceContactInfo = { ...this.iImedClaimServiceContactInfo };
        }
    }

    updateContractRateFromValue(value: number): void {
        this.contractRateFrom = value;
    }

    updateContractRateToValue(value: number): void {
        this.contractRateTo = value;
    }

    setConfig(): void {
        if (this.filmReviewServiceModel.DiscCopiedById > 0) {
            this.selectedDiscCopiedUser = this.users.filter((x) => x.Id === this.filmReviewServiceModel.DiscCopiedById)[0];
        } else {
            this.selectedDiscCopiedUser = this.users.filter((x) => x.Id === this.authService.currentUser.getValue().Id)[0];
        }

        if (this.filmReviewServiceModel.DiscCopiedById > 0) {
            this.selectedDiscMailedUser = this.users.filter((x) => x.Id === this.filmReviewServiceModel.DiscMailedById)[0];
        } else {
            this.selectedDiscMailedUser = this.users.filter((x) => x.Id === this.authService.currentUser.getValue().Id)[0];
        }

        this.filmReviewServiceForm = this.fb.group({ FilmReviewService: this.fb.group({}) });

        this.filmReviewServiceFirstConfig = { formObject: [], viewOnly: [] };
        this.filmReviewServiceSecondConfig = { formObject: [], viewOnly: [] };

        this.filmReviewFormFirstFactory = new FilmReviewServiceDynamicConfig<IFilmReviewService>(this.filmReviewServiceModel, [
            'RequestDate',
            'DiscCopiedDate',
        ]);

        this.filmReviewFormSecondFactory = new FilmReviewServiceDynamicConfig<IFilmReviewService>(this.filmReviewServiceModel, [
            'OriginalFiledForReference',
            'DiscMailedDate',
        ]);

        this.filmReviewServiceFirstConfig = this.filmReviewFormFirstFactory.getForUpdate();
        this.filmReviewServiceSecondConfig = this.filmReviewFormSecondFactory.getForUpdate();
        this.formRendered = true;
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        this.isEditing = false;
        this.iImedClaimServiceContactInfo = { ...this.initialImedClaimServiceContactInfo };
    }

    private assignDefaultValues(): void {
        this.assignPurposeOfExam();
        this.checkForTranslationService();
        if (this.iImedClaimServiceModel.ContractRateFrom !== null) {
            this.contractRateFrom = this.iImedClaimServiceModel.ContractRateFrom;
        } else {
            this.contractRateFrom = this.getDefaultContractRate('ContractRateFrom');
        }
        if (this.iImedClaimServiceModel.ContractRateTo !== null) {
            this.contractRateTo = this.iImedClaimServiceModel.ContractRateTo;
        } else {
            this.contractRateTo = this.getDefaultContractRate('ContractRateTo');
        }
    }

    private assignPurposeOfExam(): void {
        if (this.iImedClaimServiceModel.PurposeOfExam !== null) {
            this.purposeOfExam = this.iImedClaimServiceModel.PurposeOfExam;
        } else {
            this.purposeOfExam = this.getPurposeOfExamDefault();
        }
    }

    private checkForTranslationService(): void {
        if (this.iImeService.TranslationServicesRequested && this.purposeOfExam.indexOf('interpreter has been arranged') === -1) {
            this.purposeOfExam += ' Please note that a ' + this.iImeService.Language.Name + ' interpreter has been arranged.';
        } else {
            this.assignPurposeOfExam();
        }
    }

    getDefaultContractRate(contractKey: string): number {
        const rates = [];
        const keys = [
            'CarrierPartyIndividual',
            'CarriersCounselIndividual',
            'ClaimantsAttorneyIndividual',
            'DefenseAttorneyIndividual',
            'EmployerIndividual',
            'PlantiffAttorneyIndividual',
        ];
        keys.forEach((key) => {
            if (this.iImedClaimServiceModel.ImedClaim[key] && this.iImedClaimServiceModel.ImedClaim[key][contractKey]) {
                rates.push(this.iImedClaimServiceModel.ImedClaim[key][contractKey]);
            }
        });
        if (rates.length > 0) {
            return rates.sort()[rates.length - 1];
        } else {
            return null;
        }
    }

    formSubmitted(): void {
        this.initialContractRateFrom = this.iImedClaimServiceModel.ContractRateFrom;
        this.initialContractRateTo = this.iImedClaimServiceModel.ContractRateTo;
        this.iImedClaimServiceModel.ContractRateFrom = this.contractRateFrom;
        this.iImedClaimServiceModel.ContractRateTo = this.contractRateTo;
        const invalidServiceAddress = this.iImedClaimServiceContactInfo.PhysicianId > 0 && !this.iImedClaimServiceContactInfo.AddressId;

        if (this.isContractRangeValid(this.iImedClaimServiceModel) && !invalidServiceAddress) {
            if (this.filmReviewServiceForm.valid) {
                this.iImedClaimServiceModel = this.imedClaimServiceService.assignContactsToImedClaimService(
                    this.iImedClaimServiceModel,
                    this.iImedClaimServiceContactInfo,
                );
                this.filmReviewFormFirstFactory.assignFormValues(
                    this.filmReviewServiceModel,
                    this.filmReviewServiceForm.value.FilmReviewService as IFilmReviewService,
                );
                this.filmReviewServiceModel.RequestDateChangedById = this.authService.currentUser.getValue().Id;
                this.filmReviewServiceModel.RequestDateChangedDate = new Date();

                const updatePayload = <IServicePayload<IFilmReviewService>>{};
                this.filmReviewServiceModel.DiscCopiedById = this.selectedDiscCopiedUser.Id;
                this.filmReviewServiceModel.DiscMailedById = this.selectedDiscMailedUser.Id;
                updatePayload.ChildService = this.filmReviewServiceModel;
                updatePayload.ParentService = this.iImedClaimServiceModel;

                this.filmReviewService.updateService(updatePayload).subscribe(() => {
                    forkJoin(
                        this.imedClaimServiceService.getById(this.iImedClaimServiceModel.Id),
                        this.filmReviewService.getByImedClaimServiceId(this.iImedClaimServiceModel.Id),
                    ).subscribe((response) => {
                        const [imedClaimServiceResponse, filmReviewServiceResponse] = response;
                        this.filmReviewServiceModel = filmReviewServiceResponse;
                        // this.onRefreshStatus.emit();
                        this.iImedClaimServiceModel = imedClaimServiceResponse;
                        this.imedClaimServiceService.emitChange(this.iImedClaimServiceModel);
                        this.filmReviewService.emitChange(this.filmReviewServiceModel);
                        this.isEditing = false;
                        this.success();
                        this.setConfig();
                    });
                });
            } else {
                markAllFormFieldsAsTouched(this.filmReviewServiceForm);
                this.error();
            }
        } else {
            setTimeout(() => (this.doubleClickIsDisabled = false), 0);
        }
    }

    isContractRangeValid(iImedClaimServiceModel: IImedClaimService): boolean {
        if (
            iImedClaimServiceModel.ContractRateFrom &&
            iImedClaimServiceModel.ContractRateTo &&
            iImedClaimServiceModel.ContractRateFrom > iImedClaimServiceModel.ContractRateTo
        ) {
            this.notificationsService.error('Contract Range From cannot be higher than Contract Range To');
            this.iImedClaimServiceModel.ContractRateFrom = this.initialContractRateFrom;
            this.iImedClaimServiceModel.ContractRateTo = this.initialContractRateTo;
            this.contractRateFrom = this.initialContractRateFrom;
            this.contractRateTo = this.initialContractRateTo;
            return false;
        }
        return true;
    }

    getPurposeOfExamDynamicField(): DynamicField {
        return new DynamicField({
            formGroup: null,
            label: 'Purpose of Exam',
            name: 'PurposeOfExam',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Textarea,
            } as IDynamicFieldType),
            value: this.purposeOfExam,
        } as IDynamicField);
    }

    getContractRateFromDynamicField(): DynamicField {
        return new DynamicField({
            formGroup: null,
            label: 'Contract Rate From',
            name: 'ContractRateFrom',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: NumericInputTypes.Currency,
                scale: 2,
            } as IDynamicFieldType),
            validation: [Validators.min(0.01), Validators.max(100000000000.0)],
            validators: { min: 0.01, max: 100000000000.0 },
            value: this.contractRateFrom,
        } as IDynamicField);
    }

    getContractRateToDynamicField(): DynamicField {
        return new DynamicField({
            formGroup: null,
            label: 'Contract Rate To',
            name: 'ContractRateTo',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: NumericInputTypes.Currency,
                scale: 2,
            } as IDynamicFieldType),
            validation: [Validators.min(0.01), Validators.max(100000000000.0)],
            validators: { min: 0.01, max: 100000000000.0 },
            value: this.contractRateTo,
        } as IDynamicField);
    }

    updatePurposeOfExamValue(value: string): void {
        this.purposeOfExam = value;
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }

    getPurposeOfExamDefault(): string {
        return 'Please bring all available recent medical records, MRIs, CT scans, x-rays, and other diagnostic test results to the examiner at the time of the exam for review. Please comply with all local ordinances regarding COVID 19 and be sure to wear a face covering and bring Photo ID.';
    }
}
