import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IPhysician } from '../interfaces/physician';
import { IIndividual } from '../interfaces/individual';

export interface IPhysicianDynamicControlsParameters {
    formGroup?: string;
    individuals?: IIndividual[];
}

export class PhysicianDynamicControls {

    formGroup: string;
    individuals: IIndividual[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private physician?: IPhysician, additionalParameters?: IPhysicianDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'Physician';
        this.individuals = additionalParameters && additionalParameters.individuals || undefined;

        this.Form = {
            Auto: new DynamicField({
                formGroup: this.formGroup,
                label: 'Auto',
                name: 'Auto',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physician && this.physician.hasOwnProperty('Auto') && this.physician.Auto !== null ? this.physician.Auto : false,
            }),
            BoardCertification: new DynamicField({
                formGroup: this.formGroup,
                label: 'Board Certification',
                name: 'BoardCertification',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physician && this.physician.hasOwnProperty('BoardCertification') && this.physician.BoardCertification !== null ? this.physician.BoardCertification.toString() : '',
            }),
            GeneralLiability: new DynamicField({
                formGroup: this.formGroup,
                label: 'General Liability',
                name: 'GeneralLiability',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physician && this.physician.hasOwnProperty('GeneralLiability') && this.physician.GeneralLiability !== null ? this.physician.GeneralLiability : true,
            }),
            IndividualId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Individual',
                name: 'IndividualId',
                options: this.individuals,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.physician && this.physician.IndividualId || null,
            }),
            RecordPreferences: new DynamicField({
                formGroup: this.formGroup,
                label: 'Record Preferences',
                name: 'RecordPreferences',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physician && this.physician.hasOwnProperty('RecordPreferences') && this.physician.RecordPreferences !== null ? this.physician.RecordPreferences.toString() : '',
            }),
            ReportDays: new DynamicField({
                formGroup: this.formGroup,
                label: 'Report Days',
                name: 'ReportDays',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physician && this.physician.ReportDays || 10,
            }),
            SubspecialtyNotes: new DynamicField({
                formGroup: this.formGroup,
                label: 'Subspecialty Notes',
                name: 'SubspecialtyNotes',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physician && this.physician.hasOwnProperty('SubspecialtyNotes') && this.physician.SubspecialtyNotes !== null ? this.physician.SubspecialtyNotes.toString() : '',
            }),
            WorkersComp: new DynamicField({
                formGroup: this.formGroup,
                label: 'Workers Comp',
                name: 'WorkersComp',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physician && this.physician.hasOwnProperty('WorkersComp') && this.physician.WorkersComp !== null ? this.physician.WorkersComp : false,
            }),
        };

        this.View = {
            Auto: new DynamicLabel({
			    label: 'Auto',
			    value: this.physician && this.physician.hasOwnProperty('Auto') && this.physician.Auto !== null ? this.physician.Auto : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            BoardCertification: new DynamicLabel({
			    label: 'Board Certification',
			    value: this.physician && this.physician.hasOwnProperty('BoardCertification') && this.physician.BoardCertification !== null ? this.physician.BoardCertification.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Textarea,
			        scale: null,
			    })
			}
            ),
            GeneralLiability: new DynamicLabel({
			    label: 'General Liability',
			    value: this.physician && this.physician.hasOwnProperty('GeneralLiability') && this.physician.GeneralLiability !== null ? this.physician.GeneralLiability : true,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            IndividualId: new DynamicLabel({
			    label: 'Individual',
			    value: getMetaItemValue(this.individuals as unknown as IMetaItem[], this.physician && this.physician.hasOwnProperty('IndividualId') && this.physician.IndividualId !== null ? this.physician.IndividualId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            RecordPreferences: new DynamicLabel({
			    label: 'Record Preferences',
			    value: this.physician && this.physician.hasOwnProperty('RecordPreferences') && this.physician.RecordPreferences !== null ? this.physician.RecordPreferences.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Textarea,
			        scale: null,
			    })
			}
            ),
            ReportDays: new DynamicLabel({
			    label: 'Report Days',
			    value: this.physician && this.physician.ReportDays || 10,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: NumericInputTypes.Integer,
			        scale: null,
			    })
			}
            ),
            SubspecialtyNotes: new DynamicLabel({
			    label: 'Subspecialty Notes',
			    value: this.physician && this.physician.hasOwnProperty('SubspecialtyNotes') && this.physician.SubspecialtyNotes !== null ? this.physician.SubspecialtyNotes.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Textarea,
			        scale: null,
			    })
			}
            ),
            WorkersComp: new DynamicLabel({
			    label: 'Workers Comp',
			    value: this.physician && this.physician.hasOwnProperty('WorkersComp') && this.physician.WorkersComp !== null ? this.physician.WorkersComp : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };

    }
}
