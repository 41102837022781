import { OnInit, Component, Input } from '@angular/core';
import { TaskTemplateService } from '../services/tasktemplate.service';
import { ITaskTemplate } from '@model/interfaces/task-template';

export interface IAssociationsGroupByService {
    name: string;
    services: string[];
}

@Component({
    selector: 'app-system-task-template-associations',
    styles: [
        `
            * {
                cursor: default;
            }
            .table-heading {
                font-size: 14px;
                font-weight: 800;
                padding: 5px;
                border: 1px solid #ccc;
            }
        `,
    ],
    templateUrl: './system-task-template-associations.component.html',
})
export class SystemTaskTemplateAssociationsComponent implements OnInit {
    @Input('task') task: ITaskTemplate;
    followUpsForTable: IAssociationsGroupByService[] = [];
    serviceStatusesForTable: IAssociationsGroupByService[] = [];

    constructor(private taskTemplateService: TaskTemplateService) { }

    ngOnInit(): void {
        this.taskTemplateService
            .getTaskTemplateAssociations(this.task.Id)
            .subscribe(({ MasterFollowupTypeAssociations, MasterServiceStatusAssociations }) => {
                if (MasterFollowupTypeAssociations.length) {
                    this.mapDTOtoTableStructure(MasterFollowupTypeAssociations);
                }
                if (MasterServiceStatusAssociations.length) {
                    this.mapDTOtoTableStructure(MasterServiceStatusAssociations);
                }
            });
    }

    mapDTOtoTableStructure(associations: any): void {
        const types: { id: number; name: string }[] = [];
        const associationType = Object.prototype.hasOwnProperty.call(associations[0], 'MasterFollowupType') ? 'MasterFollowupType' : 'MasterServiceStatus';
        const tableData: IAssociationsGroupByService[] = [];

        associations.forEach((x) => {
            if (!types.some((type) => type.id === x[associationType].Id)) {
                types.push({
                    id: x[associationType].Id,
                    name: x[associationType].Name,
                });
            }
        });

        types.forEach((type) => {
            const association: IAssociationsGroupByService = { name: '', services: [] };
            association.name = type.name;
            association.services = associations.filter((x) => x[associationType].Id === type.id).map((x) => x.Service.Name);
            tableData.push(association);
        });

        if (associationType === 'MasterFollowupType') {
            this.followUpsForTable = tableData;
        } else {
            this.serviceStatusesForTable = tableData;
        }
    }
}
