import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef, ViewRef } from '@angular/core';
import { UntypedFormGroup, AbstractControl } from '@angular/forms';
import { forkJoin, Subscription } from 'rxjs';
import { IDynamicField, DynamicField } from '@mt-ng2/dynamic-form';
import { emptyAddress } from '../addresses/address.service';
import { AddressDynamicConfig } from '../addresses/address.dynamic-config';
import { IState } from '@model/interfaces/state';
import { IAddressType } from '@model/interfaces/address-type';
import { AddressTypeService } from '../addresses/addresstype.service';
import { CommonService } from '../../common/services/common.service';
import { CountyService } from '@app-shared/services/county.service';
import { debounceTime } from 'rxjs/operators';
import { safeDetectChanges } from '../../common/cdr-safety/cdr-safety.library';

@Component({
    selector: 'app-primary-address',
    templateUrl: './primary-address.component.html',
})
export class PrimaryAddressComponent implements OnInit, OnDestroy {
    @Input() form: UntypedFormGroup;
    @Input() overrideFormGroup = 'Address';

    private _required = true;
    @Input('required')
    get required(): boolean {
        return this._required;
    }
    set required(value: boolean) {
        this._required = value;
        this.updateRequired();
    }

    addressConfig: IDynamicField[];

    county: string;

    subscriptions = new Subscription();

    constructor(
        private addressTypeService: AddressTypeService,
        private commonService: CommonService,
        private countyService: CountyService,
        private cdr: ChangeDetectorRef,
    ) { }

    ngOnInit(): void {
        forkJoin(this.addressTypeService.getItems(), this.commonService.getStates()).subscribe((forkJoinResponses) => {
            const [addressTypes, states] = forkJoinResponses;
            this.setAddressConfig(addressTypes, states as IState[]);
            this.setCountySubscription();
            safeDetectChanges(this.cdr);
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        this.cdr.detach();
    }

    setAddressConfig(addressTypes: IAddressType[], states: IState[]): void {
        const addressControls = ['AddressTypeId', 'Address1', 'Address2', 'City', 'StateId', 'Zip'];
        const addressConfig = new AddressDynamicConfig(emptyAddress, addressTypes, states, null, null, addressControls);
        const formObject = addressConfig.getForCreate().formObject;
        formObject.forEach((field) => {
            (field ).formGroup = this.overrideFormGroup;
            (field ).setRequired(this.required && field.name !== 'Address2');
        });
        this.addressConfig = formObject;
    }

    setCountySubscription(): void {
        setTimeout(() => {
            const zipControl = this.form.get(`${this.overrideFormGroup}.Zip`);
            this.subscriptions.add(
                zipControl.valueChanges.pipe(debounceTime(300)).subscribe((value: string) => {
                    this.setCountyValue(value);
                }),
            );
        }, 0);
    }

    setCountyValue(zipValue: string): void {
        if (!zipValue || zipValue.length < 5) {
            this.county = '';
            return;
        }
        this.countyService.getByZip(zipValue).subscribe((answer) => {
            this.county = answer ? answer.CountyName : '';
        });
    }

    updateRequired(): void {
        const phoneFormGroup = this.form.get(this.overrideFormGroup) as UntypedFormGroup;
        if (phoneFormGroup) {
            for (const key in phoneFormGroup.controls) {
                if (Object.prototype.hasOwnProperty.call(phoneFormGroup.controls, key)) {
                    (phoneFormGroup.controls[key] ).mtSetRequired(this.required);
                }
            }
        }
    }
}
