import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';
import {
    ImedClaimServiceFollowupDynamicControls,
    IImedClaimServiceFollowupDynamicControlsParameters,
} from '@model/form-controls/imed-claim-service-followup.form-controls';
import { IImedClaimServiceFollowup } from '@model/interfaces/imed-claim-service-followup';
import { IFollowupType } from '@model/interfaces/followup-type';
import { ImedClaimServiceFollowupDynamicControlsPartial } from '@model/partials/imed-claim-service-followup-partial.form-controls';

export class ImedClaimServiceFollowupDynamicConfig<T extends IImedClaimServiceFollowup> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private imedClaimServiceFollowup: T, private followupTypes?: IFollowupType[], private configControls?: string[]) {
        super();
        const additionalParams: IImedClaimServiceFollowupDynamicControlsParameters = {
            followupTypes: this.followupTypes,
        };
        const dynamicControls = new ImedClaimServiceFollowupDynamicControlsPartial(this.imedClaimServiceFollowup, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['Notes'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
