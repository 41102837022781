import { NgModule } from '@angular/core';

import { SharedModule } from '../common/shared.module';
import { UserRoutingModule } from './user-routing.module';

import { UserBasicInfoComponent } from './user-basic-info/user-basic-info.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { UsersComponent } from './user-list/users.component';
import { UserHeaderComponent } from './user-header/user-header.component';
import { UserPhotoComponent } from './user-photo/user-photo.component';
import { UserSignaturePhotoComponent } from './user-signature/user-signature.component';
import { ModalModule } from '@mt-ng2/modal-module';
import { UserReassignComponent } from './user-reassign/user-reassign.component';

@NgModule({
    declarations: [
        UsersComponent,
        UserHeaderComponent,
        UserDetailComponent,
        UserBasicInfoComponent,
        UserPhotoComponent,
        UserSignaturePhotoComponent,
        UserReassignComponent,
    ],
    imports: [SharedModule, UserRoutingModule, ModalModule],
})
export class UserModule {
    static forRoot(): any {
        return {
            ngModule: UserModule,
            providers: [],
        };
    }
}
