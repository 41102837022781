// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            .invalid {
                border-color: red;
            }
            .btn-assignment {
                margin-top: 20px;
            }
        `, "",{"version":3,"sources":["webpack://./src/app/common/multiselect-table-control/multiselect-table-control.component.ts"],"names":[],"mappings":";YACY;gBACI,iBAAiB;YACrB;YACA;gBACI,gBAAgB;YACpB","sourcesContent":["\n            .invalid {\n                border-color: red;\n            }\n            .btn-assignment {\n                margin-top: 20px;\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
