import { IWcIssue } from '@model/interfaces/wc-issue';
import { WcIssueDynamicControlsPartial } from '@model/partials/wc-issue.fom-controls';
import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';

export class WcIssueDynamicConfig<T extends IWcIssue> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private wcIssue: T, private configControls?: string[]) {
        super();

        const dynamicControls = new WcIssueDynamicControlsPartial(this.wcIssue);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = [
                'ReviewOfRecords',
                'CompleteHistory',
                'PresentComplaints',
                'PreExistingConditions',
                'ObjectiveFindingsOnExamDiagnosis',
                'CausalRelationship',
                'AppropriatenessOfTreatment',
                'NeedForFurtherTreatment',
                'NeedForContinuedFollowUp',
                'NeedForPhysicalTherapy',
                'NeedForMassage',
                'NeedForPrescriptionMedication',
                'NeedForSurgery',
                'NeedForInjections',
                'NeedForDme',
                'NeedForEssentialServicesHouseholdHelp',
                'NeedForTransportation',
                'NeedForFurtherDiagnosticTesting',
                'DegreeOfDisability',
                'ReturnToWorkRestrictionsAdl',
                'Apportionment',
                'Mmi',
                'Permanency',
                'Slu',
                'SpecificTreatmentQuestions',
            ];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
