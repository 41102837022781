import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ICommunicationRequest } from '@model/interfaces/custom/communication-request';
import { IAttachmentListWithFaxNumberDTO } from '@model/interfaces/custom/attachment-list-with-fax-number';

@Injectable()
export class ATFaxEmailService {
    constructor(private http: HttpClient) {}

    sendEmail(email: ICommunicationRequest): any {
        return this.http.post('/emails/send', email);
    }

    resendEmail(communicationLogId: number, email: ICommunicationRequest): any {
        return this.http.post(`/emails/${communicationLogId}/resend`, email);
    }

    sendFax(fax: IAttachmentListWithFaxNumberDTO): any {
        return this.http.post('/fax/send', fax);
    }

    resendFax(communicationLogId: number, faxNumber: string, fax: ICommunicationRequest): any {
        return this.http.post(`/fax/${communicationLogId}/resend/${faxNumber}`, fax);
    }

    generateDocuments(entityTypeName: string, templateId: number, entityId: number): Observable<any> {
        return this.http.post(`/${entityTypeName}/download/${templateId}/${entityId}`, null, {
            observe: 'response',
            responseType: 'blob',
        });
    }

    generateDocumentsAsDocX(entityTypeName: string, templateId: number, entityId: number): Observable<any> {
        return this.http.post(`/${entityTypeName}/download-as-docx/${templateId}/${entityId}`, null, {
            observe: 'response',
            responseType: 'blob',
        });
    }

    faxDocuments(
        entityTypeName: string,
        emailDTO: ICommunicationRequest,
        faxNumber: string,
        templateId: number,
        entityId: number,
    ): any {
        const payload = {
            communicationRequest: emailDTO,
            documentGeneratorRequest: null,
        };
        return this.http.post(`/${entityTypeName}/fax/${faxNumber}/${templateId}/${entityId}`, payload);
    }

    faxDocumentsWithAttachment(entityTypeName: string, faxDTO: ICommunicationRequest, faxNumber: string): any {
        const emailPayload = {
            communicationRequest: faxDTO,
            documentGeneratorRequest: null,
        };
        return this.http.post(`/${entityTypeName}/fax-with-attachment/${faxNumber}`, emailPayload);
    }

    emailDocuments(
        entityTypeName: string,
        emailDTO: ICommunicationRequest,
        templateId: number,
        entityId: number,
    ): any {
        const emailPayload = {
            communicationRequest: emailDTO,
            documentGeneratorRequest: null,
        };
        return this.http.post(`/${entityTypeName}/email/${templateId}/${entityId}`, emailPayload);
    }

    sendEmailWithAttachment(entityTypeName: string, emailDTO: ICommunicationRequest): any {
        const emailPayload = {
            communicationRequest: emailDTO,
            documentGeneratorRequest: null,
        };
        return this.http.post(`/${entityTypeName}/send-email-with-attachment`, emailPayload);
    }
}
