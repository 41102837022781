import { IAddressBookType } from '@model/interfaces/address-book-type.d';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { ICourtSubpoenaDocumentType } from '@model/interfaces/court-subpoena-document-type';

@Injectable()
export class CourtSubpoenaDocumentTypeService extends StaticMetaItemService<ICourtSubpoenaDocumentType> {
    constructor(public http: HttpClient) {
        super('CourtSubpoenaDocumentTypeService', 'CourtSubpoenaDocumentType', 'CourtSubpoenaDocumentTypeIds', '/options/courtSubpoenaDocumentTypes', http);
    }
}
