import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '@model/ClaimTypes';
import { IImedClaimFollowup } from '@model/interfaces/imed-claim-followup';
import { ImedClaimFollowupService } from '../../imedclaim-followup.service';

@Component({
    selector: 'app-imed-claim-followup-detail',
    templateUrl: './imed-claim-followup-detail.component.html',
})
export class ImedClaimFollowupDetailComponent implements OnInit {
    imedClaimFollowup: IImedClaimFollowup;
    canEdit: boolean;
    editingComponent: Subject<any> = new Subject();
    canAdd: boolean;
    id: number;

    constructor(
        private imedClaimFollowupService: ImedClaimFollowupService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private router: Router,
    ) { }

    ngOnInit(): void {
        // check claims
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.CaseManagement, [ClaimValues.FullAccess]);
        this.canAdd = this.canEdit;
        // get current id from route
        this.id = +this.route.snapshot.paramMap.get('followupId');
        // try load if id > 0
        if (this.id > 0) {
            this.getImedClaimFollowupById(this.id);
        } else {
            // set imedClaimFollowup to emptyImedClaimFollowup
            this.imedClaimFollowup = this.imedClaimFollowupService.getEmptyImedClaimFollowup();

            // set the date to be with Date Part plus UTC Offset without time in it
            this.imedClaimFollowup.Date = new Date(new Date().mtDate.toMoment().format('MM/DD/YYYY'));
        }
        this.editingComponent.next('');
    }

    getImedClaimFollowupById(id: number): void {
        this.imedClaimFollowupService.getById(id).subscribe((imedClaimFollowup) => {
            this.imedClaimFollowup = imedClaimFollowup;
        });
    }

    clickClose(): void {
        if (this.route.snapshot.parent.parent.parent.paramMap.get('imedClaimId') === '0') {
            void this.router.navigateByUrl('cases/0/follow-ups');
        } else {
            void this.router.navigateByUrl(this.router.url.replace('/follow-ups/' + this.id, ''));
        }
    }
}
