import { formatAddress } from '../common/services/address-formatter.service';
import { IAddressBookOptions } from '@model/interfaces/custom/address-book-options';
import { IFacilityAddress } from '@model/interfaces/custom/facility-address';

export class AddressBookOptions implements IAddressBookOptions {
    Id: number;
    Name: string;
    ShortName: string;
    TypeId: number;
    Addresses: IFacilityAddress[];
    HasPhysicians: boolean;

    constructor(addressBook: IAddressBookOptions) {
        Object.assign(this, addressBook);
        this.ShortName = this.Name;
        this.Name = this.getNameWithAddress();
    }

    getNameWithAddress(): string {
        if (this.Addresses && this.Addresses.length) {
            return `${this.Name} - ${formatAddress(this.Addresses[0])}`;
        }
        return `${this.Name}`;
    }
}
