import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { IImedClaimAdditionalBillToPartyDTO } from '@model/interfaces/custom/imed-claim-additional-btp.dto';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { finalize } from 'rxjs/operators';
import { UntypedFormGroup } from '@angular/forms';
import { AuthService } from '@mt-ng2/auth-module';
import { IImedClaimService } from '@model/interfaces/imed-claim-service';
import { ISubpoenaService } from '@model/interfaces/subpoena-service';
import { ImedClaimServiceProcessServerFeeDynamicControls } from '@model/form-controls/imed-claim-service-process-server-fee.form-controls';
import { IImedClaimServiceProcessServerFee } from '@model/interfaces/imed-claim-service-process-server-fee';
import { ImedClaimServiceProcessServerFeesDynamicConfig } from './subpoena-service-process-server-fees-dynamic-config';
import { ImedClaimServiceProcessServerFeeService } from './subpoena-service-process-server-fees-service';

@Component({
    selector: 'app-imed-claim-service-process-server-fees',
    styles: ['.custom-padded { margin: 0 0 0.5em 0; } ', '.highlight {background-color: #FF45B5;}'],
    templateUrl: './subpoena-service-process-server-fees-component.html',
})
export class SubpoenaServiceProcessServerFeesComponent implements OnInit, OnDestroy {
    @Input() imedClaimServiceId: number;
    @Input() subpoenaService: ISubpoenaService;
    @Input() canEdit: boolean;
    isHovered = false;
    doubleClickIsDisabled = false;
    ProcessServerFeesForm = new UntypedFormGroup({ ImedClaimServiceProcessServerFee: new UntypedFormGroup({}) });
    formFactory: any;
    controls: any;

    processServerFees: IImedClaimServiceProcessServerFee[] = [];
    newProcessServerFees: IImedClaimServiceProcessServerFee;
    _imedClaimServiceProcessServerFeesService: ImedClaimServiceProcessServerFeeService;
    selectedProcessServerFee: IImedClaimServiceProcessServerFee;

    _isEditing: boolean;
    public set isEditing(v: boolean) {
        this._isEditing = v;
    }

    public get isEditing(): boolean {
        return this._isEditing;
    }

    constructor(
        private imedClaimServiceProcessServerFeesService: ImedClaimServiceProcessServerFeeService,
        private notificationsService: NotificationsService,
        private authService: AuthService,
    ) {
        this._imedClaimServiceProcessServerFeesService = imedClaimServiceProcessServerFeesService;
    }

    ngOnInit(): void {
        this.isEditing = false;
        this.getImedClaimServiceProcessServerFees();
        this.setConfig();
    }

    setConfig(): void {
        this.newProcessServerFees = this.imedClaimServiceProcessServerFeesService.getEmptyImedClaimProcessServerServiceFee();
        this.controls = new ImedClaimServiceProcessServerFeeDynamicControls(this.newProcessServerFees, null).Form;
        this.formFactory = new ImedClaimServiceProcessServerFeesDynamicConfig<IImedClaimServiceProcessServerFee>(this.newProcessServerFees, []);
    }

    ngOnDestroy(): void {
        this.selectedProcessServerFee = null;
    }

    add(): void {
        if (this.canEdit) {
            this.isEditing = true;
            this.selectedProcessServerFee = null;
            this.setConfig();
        }
    }

    getImedClaimServiceProcessServerFees(): void {
        this.imedClaimServiceProcessServerFeesService
            .getProcessServerFees(this.imedClaimServiceId)
            .subscribe((ProcessServerFees: IImedClaimServiceProcessServerFee[]) => {
                this.processServerFees = ProcessServerFees;
            });
    }

    onPhysicalCheckUpdate(): void {
        this.imedClaimServiceProcessServerFeesService
            .togglePhysicalCheckRequired(this.imedClaimServiceId)
            .pipe()
            .subscribe(() => {
                this.notificationsService.success('updated successfully');
            });
    }

    cancel(): void {
        this.isEditing = false;
        this.selectedProcessServerFee = null;
    }

    editProcessServerFees(ProcessServerFee: IImedClaimServiceProcessServerFee): void {
        if (!this.canEdit) {
            return;
        }
        this.isEditing = true;
        this.selectedProcessServerFee = { ...ProcessServerFee };
        this.controls = new ImedClaimServiceProcessServerFeeDynamicControls(this.selectedProcessServerFee, null).Form;
    }

    save(): void {
        const form = this.ProcessServerFeesForm;
        if (this.selectedProcessServerFee && this.selectedProcessServerFee.Id > 0) {
            this.formFactory.assignFormValues(this.selectedProcessServerFee, form.value.ImedClaimServiceProcessServerFee);
            this.selectedProcessServerFee.DateModified = new Date();
            this.selectedProcessServerFee.ModifiedById = this.authService.currentUser.getValue().Id;
            this.imedClaimServiceProcessServerFeesService
                .editProcessServerFee(this.imedClaimServiceId, this.selectedProcessServerFee)
                .subscribe(() => {
                    this.notificationsService.success('ProcessServer Fees edited successfully');
                    this.isEditing = false;
                    this.selectedProcessServerFee = null;
                    this.getImedClaimServiceProcessServerFees();
                });
        } else {
            this.formFactory.assignFormValues(this.newProcessServerFees, form.value.ImedClaimServiceProcessServerFee);
            this.newProcessServerFees.ImedClaimServiceId = this.imedClaimServiceId;
            this.newProcessServerFees.DateCreated = new Date();
            this.newProcessServerFees.CreatedById = this.authService.currentUser.getValue().Id;
            this.imedClaimServiceProcessServerFeesService.addProcessServerFee(this.imedClaimServiceId, this.newProcessServerFees).subscribe(() => {
                this.notificationsService.success('ProcessServer Fees added successfully');
                this.isEditing = false;
                this.getImedClaimServiceProcessServerFees();
            });
        }
    }

    remove(item: IImedClaimAdditionalBillToPartyDTO, event: Event): void {
        if (event && event.stopPropagation) {
            event.stopPropagation();
        }

        this.imedClaimServiceProcessServerFeesService.delete(item.Id).subscribe(() => {
            this.getImedClaimServiceProcessServerFees();
            this.notificationsService.success('ProcessServer Fees deleted successfully');
        });
    }
}
