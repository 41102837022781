import { Component } from '@angular/core';
import { ImedClaimService } from '../imedclaim.service';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { IPopUpParams, PopUpService } from '../../common/services/popup-service';
import { PopUpTypeEnums } from '../../common/constants/Enums';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { Router } from '@angular/router';

@Component({
    selector: 'app-imed-claim-merge-cases',
    styles: [
        `
            @media only screen and (min-width: 992px) {
                #verticalArrows {
                    display: none;
                }
            }
        `,

        `
            @media only screen and (max-width: 991px) {
                #horizontalArrows {
                    display: none;
                }
            }
        `,
    ],
    templateUrl: './imed-claim-merge-cases.component.html',
})
export class ImedClaimMergeCasesComponent {
    mergeFormGroup: UntypedFormGroup;

    constructor(
        private imedClaimService: ImedClaimService,
        private popUpService: PopUpService,
        private notificationsService: NotificationsService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.mergeFormGroup = new UntypedFormGroup({
            primaryCaseControl: new UntypedFormControl(''),
            secondaryCaseControl: new UntypedFormControl(''),
        });
    }

    mergeCases(): void {
        this.imedClaimService
            .mergeCases(this.mergeFormGroup.get('primaryCaseControl').value as number, this.mergeFormGroup.get('secondaryCaseControl').value as number)
            .subscribe(
                () => {
                    this.notificationsService.success('Claims Merged Successfully');
                },
                (error: HttpErrorResponse) => {
                    if (error.status === 400) {
                        const popUp: IPopUpParams = {
                            html: error.error,
                            type: PopUpTypeEnums.Hard_Pop_Up,
                        };
                        this.popUpService.showPopUp(popUp).subscribe();
                    }
                },
            );
    }

    cancelClick(): void {
        void this.router.navigate(['/home']);
    }
}
