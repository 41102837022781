import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { IImedClaimAdditionalBillToPartyDTO } from '@model/interfaces/custom/imed-claim-additional-btp.dto';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { finalize } from 'rxjs/operators';
import { UntypedFormGroup } from '@angular/forms';
import { AuthService } from '@mt-ng2/auth-module';
import { IImedClaimService } from '@model/interfaces/imed-claim-service';
import { ISubpoenaService } from '@model/interfaces/subpoena-service';
import { ImedClaimServiceWitnessFeeDynamicControls } from '@model/form-controls/imed-claim-service-witness-fee.form-controls';
import { IImedClaimServiceWitnessFee } from '@model/interfaces/imed-claim-service-witness-fee';
import { ImedClaimServiceWitnessFeesDynamicConfig } from './subpoena-service-witness-fees-dynamic-config';
import { ImedClaimServiceWitnessService } from './subpoena-service-witness-fees-service';

@Component({
    selector: 'app-imed-claim-service-witness-fees',
    styles: ['.custom-padded { margin: 0 0 0.5em 0; } ', '.highlight {background-color: #FF45B5;}'],
    templateUrl: './subpoena-service-witness-fees-component.html',
})
export class SubpoenaServiceWitnessFeesComponent implements OnInit, OnDestroy {
    @Input() imedClaimServiceId: number;
    @Input() subpoenaService: ISubpoenaService;
    @Input() canEdit: boolean;
    isHovered = false;
    doubleClickIsDisabled = false;
    WitnessFeesForm = new UntypedFormGroup({ ImedClaimServiceWitnessFee: new UntypedFormGroup({}) });
    formFactory: any;
    controls: any;

    witnessFees: IImedClaimServiceWitnessFee[] = [];
    newWitnessFees: IImedClaimServiceWitnessFee;
    _imedClaimServiceWitnessFeesService: ImedClaimServiceWitnessService;
    selectedWitnessFee: IImedClaimServiceWitnessFee;

    _isEditing: boolean;
    public set isEditing(v: boolean) {
        this._isEditing = v;
    }

    public get isEditing(): boolean {
        return this._isEditing;
    }

    constructor(
        private imedClaimServiceWitnessFeesService: ImedClaimServiceWitnessService,
        private notificationsService: NotificationsService,
        private authService: AuthService,
    ) {
        this._imedClaimServiceWitnessFeesService = imedClaimServiceWitnessFeesService;
    }

    ngOnInit(): void {
        this.isEditing = false;
        this.getImedClaimServiceWitnessFees();
        this.setConfig();
    }

    setConfig(): void {
        this.newWitnessFees = this.imedClaimServiceWitnessFeesService.getEmptyImedClaimWitnessFee();
        this.controls = new ImedClaimServiceWitnessFeeDynamicControls(this.newWitnessFees, null).Form;
        this.formFactory = new ImedClaimServiceWitnessFeesDynamicConfig<IImedClaimServiceWitnessFee>(this.newWitnessFees, []);
    }

    ngOnDestroy(): void {
        this.selectedWitnessFee = null;
    }

    add(): void {
        if (this.canEdit) {
            this.isEditing = true;
            this.selectedWitnessFee = null;
            this.setConfig();
        }
    }

    getImedClaimServiceWitnessFees(): void {
        this.imedClaimServiceWitnessFeesService.getWitnessFees(this.imedClaimServiceId).subscribe((witnessFees: IImedClaimServiceWitnessFee[]) => {
            this.witnessFees = witnessFees;
        });
    }

    onPhysicalCheckUpdate(): void {
        this.imedClaimServiceWitnessFeesService
            .togglePhysicalCheckRequired(this.imedClaimServiceId)
            .pipe()
            .subscribe(() => {
                this.notificationsService.success('updated successfully');
            });
    }

    onPhysicalCheckToForeignCourtUpdate(): void {
        this.imedClaimServiceWitnessFeesService
            .togglePhysicalCheckToForeignCourtRequired(this.imedClaimServiceId)
            .pipe()
            .subscribe(() => {
                this.notificationsService.success('updated successfully');
            });
    }

    cancel(): void {
        this.isEditing = false;
        this.selectedWitnessFee = null;
    }

    editWitnessFees(witnessFee: IImedClaimServiceWitnessFee): void {
        if (!this.canEdit) {
            return;
        }
        this.isEditing = true;
        this.selectedWitnessFee = { ...witnessFee };
        this.controls = new ImedClaimServiceWitnessFeeDynamicControls(this.selectedWitnessFee, null).Form;
    }

    save(): void {
        const form = this.WitnessFeesForm;
        if (this.selectedWitnessFee && this.selectedWitnessFee.Id > 0) {
            this.formFactory.assignFormValues(this.selectedWitnessFee, form.value.ImedClaimServiceWitnessFee);
            this.selectedWitnessFee.DateModified = new Date();
            this.selectedWitnessFee.ModifiedById = this.authService.currentUser.getValue().Id;
            this.imedClaimServiceWitnessFeesService.editWitnessFee(this.imedClaimServiceId, this.selectedWitnessFee).subscribe(() => {
                this.notificationsService.success('Witness Fees edited successfully');
                this.isEditing = false;
                this.selectedWitnessFee = null;
                this.getImedClaimServiceWitnessFees();
            });
        } else {
            this.formFactory.assignFormValues(this.newWitnessFees, form.value.ImedClaimServiceWitnessFee);
            this.newWitnessFees.ImedClaimServiceId = this.imedClaimServiceId;
            this.newWitnessFees.DateCreated = new Date();
            this.newWitnessFees.CreatedById = this.authService.currentUser.getValue().Id;
            this.imedClaimServiceWitnessFeesService.addWitnessFee(this.imedClaimServiceId, this.newWitnessFees).subscribe(() => {
                this.notificationsService.success('Witness Fees added successfully');
                this.isEditing = false;
                this.getImedClaimServiceWitnessFees();
            });
        }
    }

    remove(item: IImedClaimAdditionalBillToPartyDTO, event: Event): void {
        if (event && event.stopPropagation) {
            event.stopPropagation();
        }

        this.imedClaimServiceWitnessFeesService.delete(item.Id).subscribe(() => {
            this.getImedClaimServiceWitnessFees();
            this.notificationsService.success('Witness Fees deleted successfully');
        });
    }
}
