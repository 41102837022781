import { formatDate } from '@angular/common';
import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IImedClaimService } from '@model/interfaces/imed-claim-service';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AddressBooksSelectService } from '../../../../../common/address-book-select-list/address-books-select.service';
import { IAddressBookSelectEntity } from '../../../../../common/address-book-select-list/address-books-select-entity';
import { ImedClaimServiceService } from '../../../imedclaimservice.service';
import { Nf10ServicesService } from '../../../nf10-services.service';
import { IndividualService } from '../../../../../address-books/individuals/individual.service';
import { AddressBookService } from '../../../../../address-books/addressbook.service';
import { INf10ServicesDTO } from '@model/interfaces/custom/nf10-services-dto';
import { IIndividual } from '@model/interfaces/individual';
import { IAddressBook } from '@model/interfaces/address-book';
import { Nf10ServicesDynamicControlsPartial } from '@model/partials/nf10-services-partial.form-controls';

@Component({
    selector: 'app-auto-nf10-form-info',
    templateUrl: './auto-nf10-form-info.component.html',
})
export class AutoNF10FormInfoComponent {
    @Input() canEdit: boolean;
    @Input() imedClaimService: IImedClaimService;

    isHovered: boolean;
    isEditing = false;
    isDoubleClickDisabled = false;

    _nf10Service: INf10ServicesDTO;
    set nf10Service(value: INf10ServicesDTO) {
        this._nf10Service = value;
        this.setConfig();
    }

    nf10ServiceClone: INf10ServicesDTO;
    nf10FormControls: Nf10ServicesDynamicControlsPartial;
    form: UntypedFormGroup;

    parentService: IImedClaimService;

    get specialty(): string {
        return this.imedClaimService.Specialty ? this.imedClaimService.Specialty.Name : '[SPECIALTY]';
    }

    get physician(): string {
        return this.imedClaimService.Physician && this.imedClaimService.Physician.Individual
            ? `${this.imedClaimService.Physician.Individual.FirstName} ${this.imedClaimService.Physician.Individual.LastName}`
            : '[PHYSICIAN]';
    }

    get apptDate(): string {
        let apptDate: Date = null;
        if (this.parentService && this.parentService.ImeAppointments) {
            const appointments = this.parentService.ImeAppointments.filter((a) => !a.Archived).sort((a, b) => b.Id - a.Id);
            if (appointments.length) {
                apptDate = appointments[0].AppointmentDateTime;
            }
        }
        return apptDate ? formatDate(apptDate, 'MMMM d, y, h:mm a', 'en-US') : '[APPTDATE]';
    }

    constructor(
        private notificationsService: NotificationsService,
        private nf10ServicesService: Nf10ServicesService,
        private imedClaimServiceService: ImedClaimServiceService,
        private addressBooksSelectService: AddressBooksSelectService,
        private individualService: IndividualService,
        private addressBookService: AddressBookService,
    ) {}

    ngOnInit(): void {
        this.form = new UntypedFormGroup({
            Nf10Services: new UntypedFormGroup({}),
        });
        forkJoin([
            this.imedClaimServiceService.getParentForId(this.imedClaimService.Id),
            this.nf10ServicesService.getByImedClaimServiceId(this.imedClaimService.Id),
        ]).subscribe(([parentService, nf10Service]) => {
            this.parentService = parentService;
            this.nf10Service = nf10Service;
        });
    }

    setConfig(): void {
        this.nf10FormControls = new Nf10ServicesDynamicControlsPartial(this._nf10Service.Nf10Services);
    }

    cloneNf10Service(nf10ServiceToClone: INf10ServicesDTO): INf10ServicesDTO {
        const clonedNf10Service: INf10ServicesDTO = {
            Nf10CCs: nf10ServiceToClone.Nf10CCs.map((cc) => ({ ...cc })),
            Nf10Services: { ...nf10ServiceToClone.Nf10Services },
        };
        return clonedNf10Service;
    }

    edit(): void {
        this.nf10ServiceClone = this.cloneNf10Service(this._nf10Service);
        this.isEditing = true;
    }

    onIsEntireClaimDenied(checked): void {
        if (checked && this._nf10Service.Nf10Services.Ime) {
            this.form
                .get('Nf10Services')
                .get('SuggestedExplanationOfDenial')
                .setValue(
                    `Based on the results of an ${this.specialty} physical examination conducted on ${this.apptDate}, ` +
                        'it was determined that the claimant is no longer disabled or in need of active medical treatment. ' +
                        `All ${this.specialty} related benefits are denied effective the date of this denial. We attach herein, ` +
                        `for your review, the ${this.specialty} IME conducted by ${this.physician} upon which our denial is based.`,
                );
        } else if (checked && this._nf10Service.Nf10Services.Peer) {
            this.form
                .get('Nf10Services')
                .get('SuggestedExplanationOfDenial')
                .setValue(
                    'No Fault benefits for the above provider are being denied because the treatment was not medically ' +
                        'necessary, and documentation submitted failed to substantiate the necessity for the services billed ' +
                        `on this date, and all associated services. We attach herein, for your review, the report by ${this.physician} ` +
                        'upon which our denial is based.',
                );
        }
    }

    onIsPortionOfClaimDenied(checked): void {
        const nf10ServiceGroup = this.form.get('Nf10Services') as UntypedFormGroup;

        if (checked) {
            if (this._nf10Service.Nf10Services.Ime) {
                nf10ServiceGroup
                    .get('SuggestedExplanationOfDenial')
                    .setValue(
                        `In accordance with the NYS No Fault Law, an independent ${this.specialty} medical examination was conducted ` +
                            `on ${this.apptDate} by ${this.physician}. After completion of exam and review of medical records submitted, ` +
                            `${this.physician} indicated: (ie - follow up with claimant’s treating orthopedist within 6 weeks is indicated, ` +
                            'followed by physical therapy three time per week for six weeks, after which time the claimant should be ' +
                            're-evaluated. There is no need for massage therapy, household help, prescription medication, or surgery.)',
                    );
            } else if (this._nf10Service.Nf10Services.Peer) {
                nf10ServiceGroup
                    .get('SuggestedExplanationOfDenial')
                    .setValue(
                        `In accordance with the NYS No Fault Law, a review of available documentation was provided by ${this.physician} ` +
                            'and it has been determined that: (ie - follow up with claimant’s treating orthopedist within 6 weeks is ' +
                            'indicated, followed by physical therapy three time per week for six weeks, after which time the claimant ' +
                            'should be re-evaluated.There is no need for massage therapy, household help, prescription medication, or surgery.)',
                    );
            }
        } else {
            nf10ServiceGroup.get('LossOfEarnings').setValue(false);
            nf10ServiceGroup.get('HealthServiceBenefits').setValue(false);
            nf10ServiceGroup.get('OtherNecessaryExpenses').setValue(false);
            nf10ServiceGroup.get('Interest').setValue(false);
            nf10ServiceGroup.get('Attorneyfee').setValue(false);
            nf10ServiceGroup.get('DeathBenefit').setValue(false);
        }
    }

    onLossOfEarnings(checked): void {
        if (!checked) {
            this.form.get('Nf10Services').get('LossOfEarningsAmount').setValue(0);
        }
    }

    onHealthServiceBenefits(checked): void {
        if (!checked) {
            this.form.get('Nf10Services').get('HealthServiceBenefitsAmount').setValue(0);
        }
    }

    onOtherNecessaryExpenses(checked): void {
        if (!checked) {
            this.form.get('Nf10Services').get('OtherNecessaryExpensesAmount').setValue(0);
        }
    }

    onInterest(checked): void {
        if (!checked) {
            this.form.get('Nf10Services').get('InterestAmount').setValue(0);
        }
    }

    onAttorneyfee(checked): void {
        if (!checked) {
            this.form.get('Nf10Services').get('AttorneyfeeAmount').setValue(0);
        }
    }

    onDeathBenefit(checked): void {
        if (!checked) {
            this.form.get('Nf10Services').get('DeathBenefitAmount').setValue(0);
        }
    }

    onPolicyConditionsViolated(checked): void {
        if (!checked) {
            this.form.get('Nf10Services').get('NoReasonableJustificationGivenForLateNoticeOfClaim').setValue(false);
            this.form.get('Nf10Services').get('ReasonableJustificationNotEstablished').setValue(false);
        }
    }

    onPeriodOfDisability(checked): void {
        if (!checked) {
            this.form.get('Nf10Services').get('PeriodOfDisabilityFrom').setValue(null);
            this.form.get('Nf10Services').get('PeriodOfDisabilityTo').setValue(null);
        }
    }

    onExaggeratedEarningClaimDenied(checked): void {
        if (!checked) {
            this.form.get('Nf10Services').get('ExaggeratedEarningClaimPerMonth').setValue(0);
        }
    }
    onExcessiveTreatment(checked): void {
        if (!checked) {
            this.form.get('Nf10Services').get('ExcessiveTreatmentFromDate').setValue(null);
            this.form.get('Nf10Services').get('ExcessiveTreatmentToDate').setValue(null);
        }
    }

    onUnnecessaryTreatment(checked): void {
        if (!checked) {
            this.form.get('Nf10Services').get('UnnecessaryTreatmentFromDate').setValue(null);
            this.form.get('Nf10Services').get('UnnecessaryTreatmentToDate').setValue(null);
        }
    }

    save(): void {
        if (this.form.valid) {
            for (const prop in this.form.value.Nf10Services) {
                if (Object.prototype.hasOwnProperty.call(this.nf10ServiceClone.Nf10Services, prop)) {
                    this.nf10ServiceClone.Nf10Services[prop] = this.form.value.Nf10Services[prop];
                }
            }
            this.nf10ServiceClone.Nf10Services.Nf10CCs = this.nf10ServiceClone.Nf10CCs.map((cc) => {
                return {
                    AddressBookId: cc.AddressBookId,
                    Checked: cc.Checked,
                    Id: cc.Id,
                    IndividualId: cc.IndividualId,
                    Nf10ServiceId: cc.Nf10ServiceId,
                };
            });
            this.nf10ServicesService
                .updateWithFks(this.nf10ServiceClone.Nf10Services)
                .pipe(finalize(() => (this.isDoubleClickDisabled = false)))
                .subscribe(
                    (response: INf10ServicesDTO) => {
                        this.nf10Service = response;
                        this.success();
                    },
                    () => {
                        this.error();
                    },
                );
        } else {
            markAllFormFieldsAsTouched(this.form);
            setTimeout(() => (this.isDoubleClickDisabled = false));
        }
    }

    cancel(): void {
        this.isEditing = false;
    }

    success(): void {
        this.notificationsService.success('NF10 Form Info successfully saved.');
        this.isEditing = false;
    }

    error(): void {
        this.notificationsService.error('Error occurred while saving NF10 Form Info.');
    }

    searchAddressBooks(): void {
        this.addressBooksSelectService.openAddressBookSelect('AddressBook', this.addToCC);
    }

    addToCC = (addressBookSelect: IAddressBookSelectEntity): void => {
        if (addressBookSelect.IndividualId) {
            this.individualService.createNF10CC(addressBookSelect.IndividualId).subscribe((nf10CC) => {
                nf10CC.Nf10ServiceId = this._nf10Service.Nf10Services.Id;
                this.nf10ServiceClone.Nf10CCs.push(nf10CC);
            });
        } else {
            this.addressBookService.createNF10CC(addressBookSelect.AddressBookId).subscribe((nf10CC) => {
                nf10CC.Nf10ServiceId = this._nf10Service.Nf10Services.Id;
                this.nf10ServiceClone.Nf10CCs.push(nf10CC);
            });
        }
    }

    getCCName(Individual: IIndividual, AddressBook: IAddressBook): string {
        return Individual ? `${Individual.FirstName} ${Individual.LastName}` : AddressBook ? `${AddressBook.FacilityName}` : '<No Name>';
    }
}
