import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpBackend,
    HttpClient,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './environments/environment';

@Injectable()
export class TaskRefreshInterceptor implements HttpInterceptor {
    constructor(private httpBackend: HttpBackend) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Check if request is for refreshing the navigation task list
        if (request.url.endsWith('_getTasksForNavigationPanel') && request.params.has('automated')) {
            return next.handle(request).pipe(
                catchError((error) => {
                    // If user is unauthorized redirect to logout page
                    if ((<HttpErrorResponse>error).headers.has('X-Update-Roles')) {
                        const newClient = new HttpClient(this.httpBackend);
                        return newClient.get<any>(`${environment.baseApiUrl}/api/v1/heartbeat`);
                    }
                    // Go to next interceptor
                    return next.handle(request);
                }),
            );
        }
        // Default - go to next HTTP interceptor
        return next.handle(request);
    }
}
