import { DynamicConfig, IDynamicConfig, IDynamicFormConfig } from '@mt-ng2/dynamic-form';

import { IEmailAddress } from '@model/interfaces/email-address';
import { IEmailType } from '@model/interfaces/email-type';
import { IEmailAddressDynamicControlsParameters } from '@model/form-controls/email-address.form-controls';
import { EmailAddressDynamicControlsPartial } from '@model/partials/email-address-partial.form-controls';

export class EmailAddressDynamicConfig<T extends IEmailAddress> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private emailAddress: T, private emailTypes: IEmailType[], private configControls?: string[]) {
        super();
        const additionalParams: IEmailAddressDynamicControlsParameters = {
            emailTypes: emailTypes,
        };
        const dynamicControls = new EmailAddressDynamicControlsPartial(this.emailAddress, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['Email', 'EmailTypeId', 'IsPrimary', 'Notes'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
