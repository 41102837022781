import { IAppFaxEmailComponentApi } from './../../../../../common/fax-email/fax-email-component';
import { CurrencyFormatterService } from './../../../../../common/services/currency-formatter.service';
import { ImedClaimService } from './../../../../imedclaim.service';
import { SubServiceService } from './../../../common-components/sub-services/sub-service.service';
import { ImedClaimServiceService } from './../../../imedclaimservice.service';
import { ImedClaimServiceFollowupService } from './../../../common-components/service-followups/imedclaimservicefollowup.service';
import { ImedClaimServiceStatusLogService } from './../../../common-components/service-status/imed-claim-service-status-log.service';
import { CommonImedClaimService } from './../../../../shared/common-imed-claim-service';
import { AddressBooksSelectService } from './../../../../../common/address-book-select-list/address-books-select.service';
import { IImeService } from '@model/interfaces/ime-service.d';
import { IImeAppointment } from '@model/interfaces/ime-appointment.d';
import { CommunicationLogService } from '../../../../../common/services/communication-log.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { ExtraSearchParams, IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { PhysicianService } from './../../../../../address-books/individuals/physicians/physician.service';
import { ServiceStatusService } from '../../../common-components/service-status/servicestatus.service';
import { BaseAttachmentsService } from '../../../../../common/attachments/base-attachments.service';
import {
    AttachmentTypeEnums,
    ServiceIdEnums,
    AttachmentServiceTypeEnums,
    PopUpTypeEnums,
    AdditionalServiceTypeEnums,
    IMedClaimStatusIdEnums,
    FaxEmailEntityTypeEnums,
} from '../../../../../common/constants/Enums';
import { AttachmentTypeService } from '../../../../../common/attachments/attachment-type.service';
import { tap } from 'rxjs/operators';
import { PopUpService, IPopUpParams } from '../../../../../common/services/popup-service';
import { DateTimeConverterService } from '../../../../../common/services/date-time-converter.service';
import { ModalService } from '../../../../../common/services/modal.service';
import { AuthService, ClaimsService } from '@mt-ng2/auth-module';
import { ImeAppointmentService } from '../../ime-service/ime-appointments/ime-appointment.service';
import { ImeServiceService } from '../../ime-service/imeservice.service';
import { AppointmentLabelGenerator } from '../../ime-service/ime-appointments/appointment-label-generator';
import { ImedClaimServiceLabelGenerator } from '../../../../../imed-claims/imed-claim-service-label-generator';
import { IViewGetServicesForList } from '@model/interfaces/custom/view-get-services-for-list';
import { IService } from '@model/interfaces/service';
import { UserRoleService } from '../../../../../user-roles/user-role.service';
import { ServiceService } from '../../../service.service';
import { AdditionalServicesModalService } from '../../../../../common/services/additional-services-modal.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IImedClaimService } from '@model/partials/imed-claim-service.partial';
import { ImeAdditionalServiceComponent } from '../../../common-components/ime-additional-service/ime-additional-service.component';
import { AdditionalServicesSharedService } from '../../additional-services-shared.service';
import { IName, NameTypeEnum } from '@model/interfaces/custom/name';

@Component({
    selector: 'app-wc-ime-service-detail',
    templateUrl: './wc-ime-service-detail.component.html',
})
export class WcImeServiceDetailComponent extends CommonImedClaimService implements OnInit, OnDestroy {
    iImeServiceModel: IImeService;
    @ViewChild('imeAdditionalServiceComponent') imeAdditionalServiceComponent: ImeAdditionalServiceComponent;

    appointmentLabelGenerator: any;
    appointmentCardName = 'Appointments';
    appointments: IImeAppointment[];
    showingAppointments = true;
    appointmentsCount: number;

    appFaxEmailComponentApi: IAppFaxEmailComponentApi;
    appAddendumFaxEmailComponentApi: IAppFaxEmailComponentApi;
    appRadiologyReviewFaxEmailComponentApi: IAppFaxEmailComponentApi;
    appApptTbdFaxEmailComponentApi: IAppFaxEmailComponentApi;

    attachmentServiceTypeEnum: AttachmentServiceTypeEnums;
    filmReviewSelected = false;
    name: string;
    ServiceTypes = ServiceIdEnums;
    serviceLabelGenerator = new ImedClaimServiceLabelGenerator();
    allChildServices: IViewGetServicesForList[];
    selectedChildServies: IViewGetServicesForList[];
    selectedAdditionalServiceType: number;
    selectedAdditionalServiceTypeHeader: string;
    currentAdditionalServiceTypeId: number;
    newAdditionalServiceStatusId: number;
    newAdditionalService: IService;
    newAdditionalServiceQueue: number[];
    userRoleServiceTypes: IService[] = [];
    serviceAlert = 'You are trying to add a service outside of your defined role. Do you want to continue?';
    closedAlert = 'You are trying to add a service to a closed case. Do you want to continue?';
    popupDismissed = false;

    entityTypeEnums = FaxEmailEntityTypeEnums;

    constructor(
        private communicationLogService: CommunicationLogService,
        private router: Router,
        private route: ActivatedRoute,
        private imedclaimServiceStausLogService: ImedClaimServiceStatusLogService,
        private imedClaimServiceFollowupService: ImedClaimServiceFollowupService,
        private imedClaimServiceService: ImedClaimServiceService,
        private addressBookSelectService: AddressBooksSelectService,
        private currencyFormatterService: CurrencyFormatterService,
        private physicianService: PhysicianService,
        private imeAppointmentService: ImeAppointmentService,
        private subServiceService: SubServiceService,
        private imedClaimService: ImedClaimService,
        private serviceStatusService: ServiceStatusService,
        private imeServiceService: ImeServiceService,
        private apptLabelGenerator: AppointmentLabelGenerator,
        private attachmentsService: BaseAttachmentsService,
        private attachmentTypeService: AttachmentTypeService,
        private popUpService: PopUpService,
        private dateTimeConverterService: DateTimeConverterService,
        private modalService: ModalService,
        private claimService: ClaimsService,
        private authService: AuthService,
        private userRoleService: UserRoleService,
        private serviceService: ServiceService,
        private serviceModalService: AdditionalServicesModalService,
        private notificationsService: NotificationsService,
        private additionalServicesSharedService: AdditionalServicesSharedService,
    ) {
        super(
            communicationLogService,
            router,
            route,
            imedclaimServiceStausLogService,
            imedClaimServiceFollowupService,
            imedClaimServiceService,
            addressBookSelectService,
            currencyFormatterService,
            physicianService,
            imeAppointmentService,
            subServiceService,
            imedClaimService,
            serviceStatusService,
            attachmentsService,
            modalService,
            claimService,
            authService,
        );
        this.appointmentLabelGenerator = apptLabelGenerator;
        this.subscriptions.add(
            this.imedClaimServiceService.changeEmitted$.subscribe(() => {
                this.getCommunicationLog().subscribe();
            }),
        );
    }

    ngOnInit(): void {
        // this could possible be moved out to a service in itself
        forkJoin([
            this.getImedClaimServiceStatusLog(),
            this.getCommunicationLog(),
            this.getFollowups(),
            this.getSubServices(),
            this.getServiceStatuses(),
            this.getImeServiceByImedClaimServiceId(),
            this.getAppointments(),
            this.getPrimaryEmailForImedClaimDefenseAttorney(),
            this.getLatestAppointmentByImedClaimServiceId(),
            this.getChildServices(),
            this.getRolesServices(),
        ]).subscribe(
            ([
                ,
                ,
                ,
                ,
                ,
                ,
                ,
                ,
                ,
                childServices,
                ,
            ]) => {
                this.allChildServices = (<any>childServices).body;
                this.subscribeToCommunicationLogs();
                this.setPhysicianInformations();
                this.setIsPhysicianSelected(); // 1 - 1 there is not going to be more than 1 ImeServices_ImedClaimServiceId records
                this.setFormRendered();
                this.setDisablePhysicianFacilityControl();
            },
        );

        // adding subscription for IME service to dynamically show add appointment button
        this.subscriptions.add(
            this.imeServiceService.changeEmitted$.subscribe((imeService) => {
                this.iImeServiceModel = imeService;
                this.filmReviewSelected = imeService.FilmReviewId ? true : false;
            }),
        );
        this.editingComponent.next('');

        this.imeAppointmentService.appointmentCreated$.subscribe((newAppointment) => {
            if (newAppointment.ImedClaimServiceId === this.imedClaimServiceId) {
                this.appointmentCreated();
            }
        });

        this.getIMEtypeForAttachmentFilter();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    getClaimantName(): IName {
        return { firstName: this.imedClaimServiceModel.ImedClaim.FirstName, lastName: this.imedClaimServiceModel.ImedClaim.LastName, nameType: NameTypeEnum.Individual };
    }

    getImeServiceByImedClaimServiceId(): Observable<IImeService> {
        return this.imeServiceService.getByImedClaimServiceId(this.imedClaimServiceModel.Id).pipe(
            tap((imeService: IImeService) => {
                this.filmReviewSelected = imeService.FilmReviewId ? true : false;
                this.iImeServiceModel = imeService; // 1 - 1 there is not going to be more than 1 ImeServices_ImedClaimServiceId records
                this.imeServiceService.assignImetoContacts(this.imeContactInfo, this.iImeServiceModel);
            }),
        );
    }

    getRolesServices(): Observable<IService[]> {
        return this.userRoleService
            .getRolesServicesByAuthUserId(this.authService.currentUser.getValue().AuthId)
            .pipe(tap((userRoles: any) => (this.userRoleServiceTypes = userRoles)));
    }

    getIMEtypeForAttachmentFilter(): void {
        this.attachmentTypeService.mapServiceToAttachmentServiceType(this.imedClaimServiceModel.ServiceId).subscribe((type) => {
            this.attachmentServiceTypeEnum = type;
        });
    }

    UpdateImeServiceModel(model: IImeService): void {
        this.iImeServiceModel = model;
    }

    //#region Appointments
    getAppointments(): Observable<IImeAppointment> {
        const searchParams: SearchParams = new SearchParams({
            extraParams: [{ name: 'includeArchived', value: 'false' }],
            order: 'AppointmentDateTime',
            orderDirection: 'desc',
            query: '',
            skip: 0,
            take: 1,
        });
        return this.imeAppointmentService.searchAppointmentLogs(this.imedClaimServiceModel.Id, searchParams).pipe(
            tap((appointments: any) => {
                this.appointments = appointments.body;
                this.appointments.forEach((x) => this.dateTimeConverterService.convertToBrowserTimeZoneString(x.AppointmentDateTime));
                this.appointmentsCount = +appointments.headers.get('X-List-Count');
            }),
        );
    }

    appointmentCreated(): void {
        this.getLatestAppointmentByImedClaimServiceId();
    }

    seeAllAppointments(): void {
        void this.router.navigate([`cases/${this.imedClaimServiceModel.ImedClaimId}/services/${this.imedClaimServiceId}/appointments/`]);
    }

    toggleAppointmentsControl(showLogs: boolean): void {
        if (showLogs) {
            forkJoin(this.getAppointments(), this.getLatestAppointmentByImedClaimServiceId(), this.getImeServiceByImedClaimServiceId()).subscribe(
                () => {
                    this.showingAppointments = showLogs;
                },
            );
        } else {
            this.showingAppointments = showLogs;
        }
    }

    otherAttachmentChanged(value?: any): void {
        super.otherAttachmentChanged(value);
        if (value === AttachmentTypeEnums.Report_From_Physician) {
            this.getFollowups().subscribe();
        }
    }

    attachmentChanged(value: number): void {
        super.attachmentChanged(value);
        if (value === +AttachmentTypeEnums.Report_From_Physician) {
            this.getFollowups().subscribe();
        }
    }

    showIMEFaxEmailDialogue(): void {
        this.imeServiceService.checkForReportFromPhysician(this.imedClaimServiceId).subscribe((hasReports) => {
            if (hasReports) {
                if (this.imedClaimServiceModel.ServiceId === +ServiceIdEnums.IME_Addendum_Additional_Records) {
                    this.appAddendumFaxEmailComponentApi.show();
                } else {
                    this.appFaxEmailComponentApi.show();
                }
            } else {
                const message =
                    'There are no physician reports associated with any IME services performed by this physician on this case. Do you want to continue?';
                const popUp: IPopUpParams = {
                    confirmButtonText: 'Continue',
                    html: message,
                    title: 'Missing Physician Reports',
                    type: PopUpTypeEnums.Soft_Pop_Up,
                };
                this.popUpService.showPopUp(popUp).subscribe((response) => {
                    if (response.value) {
                        if (this.imedClaimServiceModel.ServiceId === +ServiceIdEnums.IME_Addendum_Additional_Records) {
                            this.appAddendumFaxEmailComponentApi.show();
                        } else {
                            this.appFaxEmailComponentApi.show();
                        }
                    }
                });
            }
        });
    }

    showAppointmentTBDFaxEmailDialog(): void {
        this.appApptTbdFaxEmailComponentApi.show();
    }

    showRadiologyReviewFaxEmailDialog(): void {
        this.appRadiologyReviewFaxEmailComponentApi.show();
    }

    showReExamButton(): boolean {
        return this.imedClaimServiceModel.ServiceId === +ServiceIdEnums.IME_Re_Examination;
    }

    showAddendumButton(): boolean {
        return this.imedClaimServiceModel.ServiceId === +ServiceIdEnums.IME_Addendum_Additional_Records;
    }

    get conflictOfInterestExists(): boolean {
        return !this.iImeServiceModel.ConflictOfInterestApproved && this.iImeServiceModel.PatientPreviouslyTreatedByPhysician;
    }

    refreshDocuments(): void {
        this.attachmentsService.requestRefresh();
        this.setDisablePhysicianFacilityControl();
    }
    selectService(event: IImedClaimService): void {
        window.open(`/#/cases/${this.imedClaimServiceModel.ImedClaimId}/services/${event.Id}`);
    }
    openServicesModal(modal: any, additionalServiceTypeId: number): void {
        this.selectedAdditionalServiceType = additionalServiceTypeId;
        this.setCurrentAdditionalServiceTypeIdAndStatusId(additionalServiceTypeId);
        this.updateSelectedChildServices();
        this.serviceModalService.showModal(modal);
    }
    updateSelectedChildServices(): void {
        this.selectedChildServies = this.additionalServicesSharedService.sortByDateAndFilter(
            this.allChildServices,
            this.currentAdditionalServiceTypeId,
        );
    }
    hideServicesModal(): void {
        this.serviceModalService.hideModal();
    }
    getChildServices(): Observable<IViewGetServicesForList[]> {
        const _extraSearchParams: ExtraSearchParams[] = [];
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'includeArchived',
                value: '0',
            }),
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'ParentServiceId',
                value: this.imedClaimServiceModel.Id.toString(),
            }),
        );
        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: 'Id',
            orderDirection: 'asc',
            query: '',
            skip: 0,
            take: 1000,
        };
        const searchparams = new SearchParams(searchEntity);
        return this.imedClaimServiceService.searchImedClaimServices(this.imedClaimServiceModel.ImedClaimId, searchparams);
    }
    claimStatusClosedOrArchived(): boolean {
        return (
            this.imedClaimServiceModel.ImedClaim.ImedClaimStatusId === +IMedClaimStatusIdEnums.Close ||
            this.imedClaimServiceModel.ImedClaim.ImedClaimStatusId === +IMedClaimStatusIdEnums.Archived
        );
    }
    userRoleDoesNotIncludeServiceType(serviceId: number): boolean {
        return !this.userRoleServiceTypes.some((usrServices) => usrServices.Id === serviceId);
    }
    setDatesToTimeZoneRelatedDate(dateToBeConverted: string): Date {
        const dateFromFormAsISOString = new Date(dateToBeConverted).toISOString();
        const dateWithoutUTCIdentifier = new Date(dateFromFormAsISOString.substring(0, dateFromFormAsISOString.length - 1));
        return new Date(dateWithoutUTCIdentifier.mtDate.toMoment().format('MM/DD/YYYY'));
    }

    anyAdditionalServices(additionalServiceId: number): boolean {
        return this.selectedChildServies?.filter((x) => x.ServiceTypeId === additionalServiceId).length > 0;
    }

    removeAdditionalService(serviceId: number): void {
        this.imedClaimServiceService.archive(serviceId, this.authService.currentUser.getValue().Id).subscribe(() => {
            this.notificationsService.success('Service Removed Successfully');
            this.getChildServices().subscribe((response) => {
                this.allChildServices = (<any>response).body;
                this.updateSelectedChildServices();
                switch (this.selectedAdditionalServiceType as AdditionalServiceTypeEnums) {
                    case AdditionalServiceTypeEnums.RadiologyReview:
                        if (!this.anyAdditionalServices(ServiceIdEnums.WC_Film_Review)) {
                            this.imeAdditionalServiceComponent.isFilmReviewSelected = false;
                            this.iImeServiceModel.FilmReviewId = null;
                            this.hideServicesModal();
                        } else {
                            this.iImeServiceModel.FilmReviewId = this.selectedChildServies[0].Id;
                        }
                        break;
                    case AdditionalServiceTypeEnums.Addendum:
                        if (!this.anyAdditionalServices(ServiceIdEnums.WC_Addendum)) {
                            this.imeAdditionalServiceComponent.isAddendumServiceSelected = false;
                            this.hideServicesModal();
                        }
                        break;
                    case AdditionalServiceTypeEnums.ConferenceCall:
                        if (!this.anyAdditionalServices(ServiceIdEnums.WC_Conference_Call)) {
                            this.imeAdditionalServiceComponent.isConferenceCallSelected = false;
                            this.hideServicesModal();
                        }
                        break;
                    default:
                        break;
                }
                this.imeAdditionalServiceComponent.updateIMEServiceWhileEditing();
            });
        });
    }
    validateAndSaveNewService(exitEditMode: boolean): void {
        this.serviceService.getServiceById(this.currentAdditionalServiceTypeId).subscribe((service) => {
            this.newAdditionalService = service;
            if (
                (this.claimStatusClosedOrArchived() || this.userRoleDoesNotIncludeServiceType(this.newAdditionalService.Id)) &&
                !this.popupDismissed
            ) {
                const message = this.claimStatusClosedOrArchived() ? this.closedAlert : this.serviceAlert;
                const popUp: IPopUpParams = {
                    confirmButtonText: 'Continue',
                    html: message,
                    title: 'Service Alert',
                    type: PopUpTypeEnums.Soft_Pop_Up,
                };
                this.popUpService.showPopUp(popUp).subscribe((response) => {
                    if (response.value) {
                        this.popupDismissed = true;
                        this.saveService(this.newAdditionalService, exitEditMode);
                        this.imedClaimServiceModel.ImedClaim.ImedClaimStatusId = IMedClaimStatusIdEnums.Active;
                        this.imedClaimServiceModel.ImedClaim.ModifiedById = this.authService.currentUser.getValue().Id;
                        this.imedClaimServiceModel.ImedClaim.Birthdate = this.imedClaimServiceModel.ImedClaim.Birthdate
                            ? this.setDatesToTimeZoneRelatedDate(this.imedClaimServiceModel.ImedClaim.Birthdate.toString())
                            : null;
                        this.imedClaimServiceModel.ImedClaim.DateofAllegedAccidentOrInjury = this.imedClaimServiceModel.ImedClaim
                            .DateofAllegedAccidentOrInjury
                            ? this.setDatesToTimeZoneRelatedDate(this.imedClaimServiceModel.ImedClaim.DateofAllegedAccidentOrInjury.toString())
                            : null;
                        this.imedClaimService.update(this.imedClaimServiceModel.ImedClaim).subscribe();
                    } else {
                        this.imeAdditionalServiceComponent.resetDoubleClickIsDisabled();
                    }
                });
            } else {
                this.saveService(this.newAdditionalService, exitEditMode);
            }
        });
    }
    saveService(service: IService, exitEditMode: boolean): void {
        let newService = this.imedClaimService.getNewService(
            service.Id,
            this.authService.currentUser.getValue().Id,
            this.imedClaimServiceModel.ImedClaimId,
        );
        newService = this.additionalServicesSharedService.assignValuesToNewService(
            newService,
            this.imedClaimServiceModel,
            this.newAdditionalServiceStatusId,
            this.selectedAdditionalServiceType,
            this.iImeServiceModel,
        );
        this.imedClaimServiceService.createWithFks(newService).subscribe((imedClaimServiceId) => {
            this.imedClaimServiceService.assignParentService(imedClaimServiceId, this.imedClaimServiceModel.Id).subscribe(() => {
                this.getImeServiceByImedClaimServiceId();
                this.getChildServices().subscribe((response) => {
                    this.allChildServices = (<any>response).body;
                    this.updateSelectedChildServices();
                    if (this.selectedAdditionalServiceType === +AdditionalServiceTypeEnums.RadiologyReview) {
                        this.iImeServiceModel.FilmReviewId = imedClaimServiceId;
                    }
                    if (exitEditMode) {
                        this.imeAdditionalServiceComponent.updateIMEService();
                    } else {
                        this.imeAdditionalServiceComponent.updateIMEServiceWhileEditing();
                    }
                    this.popupDismissed = false;
                    if (this.newAdditionalServiceQueue?.length) {
                        this.popupDismissed = true;
                        this.addAdditionalService(this.newAdditionalServiceQueue);
                    }
                });
            });
        });
    }
    seeAllServices(selectedAdditionalServiceTypeId: number): void {
        window.open(
            `/#/cases/${this.imedClaimServiceModel.ImedClaimId}/services?ParentServiceId=${this.imedClaimServiceModel.Id}&AdditionalServiceType=${selectedAdditionalServiceTypeId}`,
        );
    }
    addAdditionalService(additionalServiceTypeIds: number[], exitEditMode = false): void {
        if (additionalServiceTypeIds.length > 1) {
            this.newAdditionalServiceQueue = additionalServiceTypeIds;
            this.selectedAdditionalServiceType = this.newAdditionalServiceQueue.shift();
        } else if (additionalServiceTypeIds?.length >= 0) {
            this.selectedAdditionalServiceType = additionalServiceTypeIds.shift();
        }
        this.setCurrentAdditionalServiceTypeIdAndStatusId(this.selectedAdditionalServiceType);
        this.validateAndSaveNewService(exitEditMode);
    }
    setCurrentAdditionalServiceTypeIdAndStatusId(selectedAdditionalServiceType: number): void {
        this.selectedAdditionalServiceTypeHeader = this.additionalServicesSharedService.getAdditionalServiceHeaderName(selectedAdditionalServiceType);
        this.currentAdditionalServiceTypeId = this.additionalServicesSharedService.getAdditionalServiceId(
            selectedAdditionalServiceType,
            this.imedClaimServiceModel.ImedClaim.ClaimTypeId,
        );
        this.newAdditionalServiceStatusId = this.additionalServicesSharedService.getAdditionalWCServiceStatus(
            selectedAdditionalServiceType,
            this.imedClaimServiceModel.ServiceId,
        );
    }
}
