import { NgModule } from '@angular/core';
import { SharedModule } from '../common/shared.module';
import { TaskRoutingModule } from './task-routing.module';
import { TaskDetailComponent } from './task-detail/task-detail.component';
import { TasksComponent } from './task-list/tasks.component';
import { TaskStatusService } from './taskstatus.service';
import { TaskAddComponent } from './task-add/task-add.component';
import { ImedCommonModule } from '../common/imed-common.module';
import { TaskActionsDynamicCellComponent } from './task-list/task-actions.component';

@NgModule({
    declarations: [TasksComponent, TaskDetailComponent, TaskAddComponent, TaskActionsDynamicCellComponent],
    imports: [ImedCommonModule, SharedModule, TaskRoutingModule]
})
export class TaskModule {
    static forRoot(): any {
        return {
            ngModule: TaskModule,
            providers: [TaskStatusService],
        };
    }
}
