import { Component, Input, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { FileItem, FileLikeObject } from 'ng2-file-upload';
import { saveAs } from 'file-saver';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IAttachmentDTO } from '@model/interfaces/custom/attachment-dto';
import { AttachmentTypeService } from './attachment-type.service';
import { IAttachmentType } from '@model/interfaces/attachment-type';
import {
    BaseAttachmentsService,
    DocumentCardSortPropertyEnums,
    IDocumentSortParams,
    DocumentCardSortDirectionEnums,
    EntityTypeEnums,
} from './base-attachments.service';
import { AttachmentControlComponent } from '../attachment-control/attachment-control.component';
import { AttachmentTypeEnums, AttachmentServiceTypeEnums, DocumentTransactionTypeEnums, PopUpTypeEnums, ServiceIdEnums } from '../constants/Enums';
import { DocumentDynamicControlsPartial } from '@model/partials/document-partial.form-controls';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { IImeService } from '@model/interfaces/ime-service';
import { ImeServiceService } from '../../imed-claims/services/service-specifics/ime-service/imeservice.service';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { FaxEmailService } from '../fax-email/fax-email.service';
import { environment } from '../../environments/environment';
import { finalize, debounceTime } from 'rxjs/operators';
import { DocumentTransactionLogService } from './document-transaction-log.service';
import { ModalService, ModalTypes } from '../services/modal.service';
import { DocumentsCrudService } from './documents-crud.service';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, IDynamicField, IDynamicFieldType } from '@mt-ng2/dynamic-form';
import { PhysicianService } from '../../address-books/individuals/physicians/physician.service';
import { IPopUpParams, PopUpService } from '@common/services/popup-service';
import { SubpoenaServiceService } from '../../imed-claims/services/service-specifics/subpoena-service/subpoena-service.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonAttachmentsService } from '../../../app-shared/services/common-attachments.service';
import { ClaimValues, ClaimsService } from '@mt-ng2/auth-module';
import { ClaimTypes } from '@model/ClaimTypes';
import e from 'express';
import { IModalOptions, IModalWrapperApi } from '@mt-ng2/modal-module';
import { ImedClaimServiceService } from '../../imed-claims/services/imedclaimservice.service';

@Component({
    selector: 'app-common-attachments',
    styles: [
        `
            .cdk-drop-list-dragging .cdk-drag {
                transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
            }
            .cdk-drag-animating {
                transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
            }
            td {
                border: 1px solid #ccc;
            }
        `,
    ],
    templateUrl: './common-attachments.component.html',
})
export class CommonAttachmentsComponent implements OnInit {
    @Input('canEdit') canEdit: boolean;
    @Input('entityId') entityId: number;
    @Input('type') type: string;
    @Input('expandable') expandable = true;
    @Input('attachmentServiceTypeEnum') attachmentServiceTypeEnum: AttachmentServiceTypeEnums;

    // Inputs below are for the expanded modal
    @Input('expanded') expanded: boolean;
    @Input('documentArray') documentArray: IAttachmentDTO[] = [];
    @Input('allDocsLoaded') allDocsLoaded = false;
    // Inputs above are for the expanded modal

    @Output('onDocumentChanged') onDocumentChanged: EventEmitter<number> = new EventEmitter<number>();
    @Output('onDocumentDeleted') onDocumentDeleted: EventEmitter<number> = new EventEmitter<number>();
    @Output('onCloseExpandedModal') onCloseExpandedModal: EventEmitter<any> = new EventEmitter<any>();
    @Output('onLoadMore') onLoadMore: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('docComponent') docComponent: AttachmentControlComponent;

    attachmentTypeEnums = AttachmentTypeEnums;
    errorMessage: string;

    attachmentTypes: IAttachmentType[] = [];
    documentForm: UntypedFormGroup;
    abstractDocumentControls: any;
    formControlsWithFilmReview: any;
    formControlsWithoutfilmReview: any;
    currentDownloadDocsSort: any = {};
    formCreated = false;
    isEditing = false;
    isEditingPageNumbers = false;
    showNumPagesField = false;
    isPhysicianFeeSchedule = false;
    subscriptions = new Subscription();
    imeWithFilmReview?: boolean;
    imeService?: IImeService;
    imeRelatedFilmReview?: boolean;
    selectedAttachmentType: number;
    docsToDownload: IAttachmentDTO[];
    noOfPagesPerDoc: number[] = [];
    closeResult: string;
    doubleClickIsDisabled = false;
    documentUploadQueue: FileItem[] = [];
    sortPropertyEnums = DocumentCardSortPropertyEnums;
    currentSort: IDocumentSortParams;
    sortOrderEnums = DocumentCardSortDirectionEnums;
    documentBeingEditedIndex: number;
    tempNumPages: number;
    searchControl = new UntypedFormControl('');

    $documentsSubscription: Subscription;
    fetchingDocuments: boolean;
    name = '';
    loaded = 0;
    toTake = 25;
    // Used to prevent calling getDocuments twice on init
    initialLoad = true;
    attorneySignaturePresentPopUp = 'Is Attorney signature present?';
    courtAndAttorneySignaturePresentPopUp = 'Are both Attorney and Court Clerk signatures present?';
    isValidBillDocExtension: boolean = false;
    selectedDocuments: IAttachmentDTO[] = [];
    canBulkDeleteAddressBookDocs: boolean = false;
    canBulkDeleteImedClaimDocs: boolean = false;
    canBulkDeleteImedClaimServiceDocs: boolean = false;
    shouldSelectAllDocuments = false;
    shouldLoadAllDocuments: boolean = false;
    isPasswordProtected: boolean = false;
    checkDuplicateBillDocuments: boolean = false;

    modalOptions: IModalOptions = {
        customClass: 'modal-hide-actions' as any,
        width: 800,
    };
    additionalBillDocumentsModalApi: IModalWrapperApi;
    duplicateBillDocuments: IAttachmentDTO[] = [];

    constructor(
        private attachmentsService: BaseAttachmentsService,
        private attachmentTypesService: AttachmentTypeService,
        private imeServiceService: ImeServiceService,
        private subpoenaServiceService: SubpoenaServiceService,
        private fb: UntypedFormBuilder,
        private notificationsService: NotificationsService,
        private router: Router,
        private modalService: ModalService,
        private faxEmailService: FaxEmailService,
        private docTransactionLogService: DocumentTransactionLogService,
        private documentCrudService: DocumentsCrudService,
        private physicianService: PhysicianService,
        private popUpService: PopUpService,
        private commonAttachmentsService: CommonAttachmentsService,
        private claimsService: ClaimsService,
        private imedClaimServiceService: ImedClaimServiceService,
    ) {}

    ngOnInit(): void {
        this.isEditing = false;
        this.getAttachmentTypesAndBuildForm();
        this.subscriptions.add(
            this.imeServiceService.changeEmitted$.subscribe((imeService) => {
                this.getAttachmentTypesAndBuildForm();
                this.imeService = imeService;
            }),
        );
        this.subscriptions.add(
            this.attachmentsService.currentSort().subscribe((sort) => {
                this.currentSort = sort;
                if (!this.expanded && !this.initialLoad) {
                    this.getDocuments(true);
                }
                if (this.initialLoad) {
                    this.initialLoad = false;
                }
            }),
        );
        if (!this.expanded) {
            this.searchControl.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
                this.name = value;
                this.getDocuments(true);
            });

            this.subscriptions.add(
                this.attachmentsService.refreshRequested.subscribe((refreshRequested) => {
                    if (refreshRequested) {
                        this.attachmentsService.refreshRequested.next(false);
                        this.getDocuments(true);
                    }
                }),
            );
            this.getDocuments();
        }

        this.canBulkDeleteAddressBookDocs =
            (this.type === (EntityTypeEnums.addressBook as string) ||
                this.type === (EntityTypeEnums.physician as string) ||
                this.type === (EntityTypeEnums.individual as string)) &&
            this.claimsService.hasClaim(ClaimTypes.BulkDeleteAddressBookDocuments, [ClaimValues.FullAccess]);

        this.canBulkDeleteImedClaimDocs =
            this.type === (EntityTypeEnums.imedClaim as string) &&
            this.claimsService.hasClaim(ClaimTypes.BulkDeleteCaseDocuments, [ClaimValues.FullAccess]);

        this.canBulkDeleteImedClaimServiceDocs =
            this.type === (EntityTypeEnums.imedClaimService as string) &&
            this.claimsService.hasClaim(ClaimTypes.BulkDeleteServiceDocuments, [ClaimValues.FullAccess]);
    }

    setFailureFunctionOnDocComponent(): void {
        if (this.docComponent) {
            this.docComponent.uploader.onWhenAddingFileFailed = (item, filter, options) => this.onWhenAddingFileFailed(item, filter, options);
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        this.$documentsSubscription?.unsubscribe();
        if (!this.expanded) {
            this.attachmentsService.resetSortProperty();
        }
    }

    getAttachmentTypesAndBuildForm(): void {
        this.attachmentTypesService.getAttachmentTypes(this.attachmentServiceTypeEnum).subscribe((types) => {
            if (
                this.attachmentServiceTypeEnum === AttachmentServiceTypeEnums.IME ||
                this.attachmentServiceTypeEnum === AttachmentServiceTypeEnums.IME_Addendum ||
                this.attachmentServiceTypeEnum === AttachmentServiceTypeEnums.IME_Re_Exam ||
                this.attachmentServiceTypeEnum === AttachmentServiceTypeEnums.IME_Expert_Consultation ||
                this.attachmentServiceTypeEnum === AttachmentServiceTypeEnums.IME_Conference_Call ||
                this.attachmentServiceTypeEnum === AttachmentServiceTypeEnums.WC_IME_Re_Exam ||
                this.attachmentServiceTypeEnum === AttachmentServiceTypeEnums.WC_Addendum ||
                this.attachmentServiceTypeEnum === AttachmentServiceTypeEnums.WC_Conference_Call
            ) {
                this.checkIfFilmReviewIsRequested(types);
            } else {
                this.attachmentTypes = types;
                this.buildForm();
            }
        });
    }

    checkIfFilmReviewIsRequested(types: IAttachmentType[]): void {
        this.imeServiceService.getByImedClaimServiceId(this.entityId).subscribe((service) => {
            if (service.FilmReviewId == null) {
                this.imeWithFilmReview = false;
                this.attachmentTypes = types.filter((type) => type.Id !== +AttachmentTypeEnums.Film_Review_Cover_Letter);
            } else {
                this.imeWithFilmReview = true;
                this.attachmentTypes = types;
            }
            this.buildForm();
        });
    }

    checkIfSubpoenaServiceIsDomesticated(): void {
        this.subpoenaServiceService.checkForDomestication(this.entityId).subscribe((domesticated) => {
            if (domesticated) {
                this.showPopUp(this.courtAndAttorneySignaturePresentPopUp);
            } else {
                this.showPopUp(this.attorneySignaturePresentPopUp);
            }
        });
    }

    buildForm(): void {
        if (this.imeWithFilmReview) {
            this.formControlsWithFilmReview = new DocumentDynamicControlsPartial(null, {
                attachmentTypes: this.attachmentTypes,
            }).Form;
        } else {
            this.abstractDocumentControls = new DocumentDynamicControlsPartial(null, {
                attachmentTypes: this.attachmentTypes,
            }).Form;
        }
        this.documentForm = this.fb.group({
            Document: this.fb.group({}),
        });
        this.setFailureFunctionOnDocComponent();
        this.formCreated = true;
    }

    get showUploadArea(): boolean {
        return this.isEditing && this.canEdit;
    }

    toggleUploadArea(): void {
        this.isEditing = true;
        this.noOfPagesPerDoc = [];
    }

    ClearSearch(): void {
        this.searchControl.setValue('');
    }
    attachmentTypeChanges(value: number): void {
        this.setIsPhysicianFeeSchedule(value);
        this.showNumPagesField =
            value === +AttachmentTypeEnums.Medical_Records_Received ||
            value === +AttachmentTypeEnums.Records_Retrieved ||
            value === +AttachmentTypeEnums.Bill_Physician_Expert ||
            value === +AttachmentTypeEnums.Addl_Records_Retrieved;

        this.selectedAttachmentType = value;
    }

    setIsPhysicianFeeSchedule(selectedAttachmentTypeId: number): void {
        this.isPhysicianFeeSchedule = this.type === 'physician' && selectedAttachmentTypeId === +AttachmentTypeEnums.Fee_Schedule;
    }

    onWhenAddingFileFailed(item: FileLikeObject, filter: any, options: any): void {
        const allowedTypes = this.docComponent.allowedMimeType.join();
        switch (filter.name as string) {
            case 'fileSize':
                this.errorMessage = `Maximum upload size exceeded (${item.size} of ${this.docComponent.maxFileSize} allowed)`;
                break;
            case 'mimeType':
                this.errorMessage = `Type "${item.type} is not allowed. Allowed types: "${allowedTypes}"`;
                break;
            default:
                this.errorMessage = `Unknown error (filter is ${filter.name})`;
        }
        this.notificationsService.error(this.errorMessage);
    }

    validateAndSave(): void {
        if (this.selectedAttachmentType === +AttachmentTypeEnums.Bill_Physician_Expert) {
            this.isValidBillDocExtension = this.commonAttachmentsService.IsValidDocumentExtension(this.docComponent.uploader.queue);
            if (!this.isValidBillDocExtension) return;
        }

        if (this.type !== (EntityTypeEnums.imedClaimService as string)) {
            this.save();
            return;
        }

        if (this.type === (EntityTypeEnums.imedClaimService as string)) {
            if (!this.isBillDocumentUploaded()) {
                this.validateSubpoenaDocuments();
                return;
            }

            this.checkCorrespondingBillVendor(); // when bill uploaded check if service has corresponsing vendor
        }
    }

    private async checkForDuplicateBillDoc(): Promise<void> {
        this.shouldLoadAllDocuments = true;
        this.checkDuplicateBillDocuments = true;
        this.getDocuments(true);

        // Wait for getDocuments to load
        await this.waitForDocuments();

        if (this.duplicateBillDocuments.length > 0) {
            this.showAdditionalBillPopup();
        } else {
            this.save();
        }
    }

    private waitForDocuments(timeout: number = 60000): Promise<void> {
        // Default timeout of 60 seconds
        return new Promise((resolve, reject) => {
            const startTime = Date.now();

            const checkInterval = setInterval(() => {
                if (!this.fetchingDocuments) {
                    clearInterval(checkInterval);
                    resolve();
                } else if (Date.now() - startTime > timeout) {
                    clearInterval(checkInterval);
                    reject('Timeout');
                }
            }, 100); // Check every 100ms
        });
    }

    validateSubpoenaDocuments(): void {
        switch (this.selectedAttachmentType as AttachmentTypeEnums) {
            case AttachmentTypeEnums.Attorney_Signed_Deposition_Subpoena:
            case AttachmentTypeEnums.Attorney_Signed_Duces_Tecum_Subpoena:
            case AttachmentTypeEnums.Attorney_Signed_Other_Subpoena:
            case AttachmentTypeEnums.Attorney_Signed_Trial_Subpoena:
                this.showPopUp(this.attorneySignaturePresentPopUp);
                break;
            case AttachmentTypeEnums.Executed_Deposition_Subpoena:
            case AttachmentTypeEnums.Executed_Subpoena_Duces_Tecum:
            case AttachmentTypeEnums.Executed_Other_Subpoena:
            case AttachmentTypeEnums.Executed_Trial_Subpoena:
                this.checkIfSubpoenaServiceIsDomesticated();
                break;
            default:
                this.save();
        }
    }

    showPopUp(message: string): void {
        const popUp: IPopUpParams = {
            cancelButtonText: 'NO',
            confirmButtonText: 'YES',
            html: message,
            title: 'Alert',
            type: PopUpTypeEnums.Soft_Pop_Up,
        };
        this.popUpService.showPopUp(popUp).subscribe((response) => {
            if (response.value) {
                this.save();
            } else {
                setTimeout(() => (this.doubleClickIsDisabled = false));
            }
        });
    }

    save(): void {
        this.documentUploadQueue = this.docComponent.uploader.queue;
        if (this.isPhysicianFeeSchedule && !this.isCaseTypeSelected()) {
            setTimeout(() => (this.doubleClickIsDisabled = false));
            this.notificationsService.error('No case type chosen');
        } else {
            if (this.documentUploadQueue && this.documentUploadQueue.length) {
                void this.tryUploadDocuments();
            } else {
                setTimeout(() => (this.doubleClickIsDisabled = false));
                this.notificationsService.error('No Files Chosen');
            }
        }
    }

    isCaseTypeSelected(): boolean {
        return this.documentForm.get('GL').value || this.documentForm.get('WC').value || this.documentForm.get('Auto').value;
    }

    // Async upload function that sets file upload status upon successful or unsuccessful upload
    async upload(file: FileItem): Promise<any> {
        file.isUploading = true;
        file.isError = false;
        const index = this.documentUploadQueue.findIndex((f) => f._file.name === file._file.name);
        const numPages = this.noOfPagesPerDoc[index] ? this.noOfPagesPerDoc[index] : 0;
        await this.attachmentsService.createAndUpload(this.type, this.entityId, file._file, this.selectedAttachmentType, 0, numPages).toPromise();
    }

    // Iterate over file array and upload each file sequentially, log error in db
    async tryUploadDocuments(): Promise<void> {
        for (const file of this.documentUploadQueue) {
            if (!file.isUploaded) {
                try {
                    await this.upload(file).then(() => {
                        this.setDocumentUploadStatus(file, true);
                    });
                } catch (err) {
                    if (err.error === 'Password Protected.') {
                        this.isPasswordProtected = true;
                    }
                    this.setDocumentUploadStatus(file, false);
                    this.docTransactionLogService.logError(err as HttpErrorResponse, null, DocumentTransactionTypeEnums.MANUAL_UPLOAD_FE).subscribe();
                }
            }
        }
        this.completeDocumentsSave(this.selectedAttachmentType);
    }

    // Check if any of the queued documents are in error status, if so show error and allow for re-upload
    completeDocumentsSave(attachmentType: number): void {
        setTimeout(() => (this.doubleClickIsDisabled = false), 200);
        if (this.queueHasErrors) {
            if (this.isPasswordProtected) {
                this.notificationsService.error(
                    `One or more documents have been uploaded with protection. Please re-upload the document without protection.`,
                );
                this.isPasswordProtected = false;
            } else {
                this.notificationsService.error(`The following files marked with a red 'X' failed to upload, click 'Try Again' to retry the upload.`);
            }
        } else {
            if (this.isPhysicianFeeSchedule) {
                this.physicianService
                    .updateFeeScheduleReceivedDate(
                        this.entityId,
                        this.documentForm.get('GL').value as boolean,
                        this.documentForm.get('WC').value as boolean,
                        this.documentForm.get('Auto').value as boolean,
                    )
                    .subscribe((service) => {
                        this.notifyAndResetDocumentUpload(attachmentType);
                    });
            } else {
                this.notifyAndResetDocumentUpload(attachmentType);
            }
        }
    }

    notifyAndResetDocumentUpload(attachmentType: number): void {
        this.onDocumentChanged.emit(attachmentType);
        this.ClearSearch();
        this.clearDocumentUploadQueue();
        this.isEditing = false;
    }

    // Set queued document's upload status
    setDocumentUploadStatus(doc: FileItem, uploaded: boolean): void {
        doc.isUploading = false;
        doc.isUploaded = uploaded;
        doc.isError = !uploaded;
    }

    get queueHasErrors(): boolean {
        return this.documentUploadQueue.some((doc) => doc.isError);
    }

    cancel(): void {
        this.isEditing = false;
        this.clearDocumentUploadQueue();
    }

    clearDocumentUploadQueue(): void {
        this.docComponent.uploader.queue = [];
        this.documentUploadQueue = [];
        if (this.documentForm.get('Document?.AttachmentTypeId')) {
            this.documentForm.get('Document.AttachmentTypeId').reset();
        }
    }

    deleteDoc(index: number): void {
        if (this.expanded) {
            this.onDocumentDeleted.emit(index);
        } else {
            this.attachmentsService.deleteAttachment(this.type, this.entityId, this.documentArray[index].Id).subscribe(() => {
                this.notificationsService.success('Document Deleted');
                this.getDocuments(true);
                this.onDocumentDeleted.emit(index);
            });
        }
    }

    downloadDoc(document: IAttachmentDTO): void {
        if (document.IsManual) {
            this.attachmentsService.downloadUploadedDocuments(this.type, document.Id).subscribe(
                (x) => {
                    const thefile = new Blob([x], { type: 'application/octet-stream' });
                    saveAs(thefile, document.Name);
                },
                (err) => {
                    this.docTransactionLogService
                        .logError(err as HttpErrorResponse, document.Id, DocumentTransactionTypeEnums.MANUAL_DOWNLOAD_FE)
                        .subscribe();
                    this.notificationsService.error('Document download failed');
                },
            );
        } else {
            this.attachmentsService.downloadEmailedDocument(this.type, document.Id).subscribe(
                (x) => {
                    const thefile = new Blob([x], { type: 'application/octet-stream' });
                    saveAs(thefile, document.Name);
                },
                (err) => {
                    this.docTransactionLogService
                        .logError(err as HttpErrorResponse, document.Id, DocumentTransactionTypeEnums.MANUAL_DOWNLOAD_FE)
                        .subscribe();
                    this.notificationsService.error('Document download failed');
                },
            );
        }
    }

    navigateToService(document: IAttachmentDTO): void {
        void this.router.navigate([`cases/${this.entityId}/services/${document.EntityId}`]);
    }

    openPDFInNewTab(document: IAttachmentDTO): void {
        window.open(environment.docPath + document.FilePath, '_blank');
    }

    // Records download modal

    openDocDownloadModal(content): void {
        this.attachmentsService.search(this.type, this.entityId, null, null, this.name, true).subscribe((response) => {
            this.docsToDownload = response.body;
            this.currentDownloadDocsSort.direction = DocumentCardSortDirectionEnums.Asc;
            this.modalService.showDocumentModal(content, ModalTypes.DOCUMENTS);
        });
    }

    removeDoc(index: number): void {
        this.docsToDownload = this.docsToDownload.filter((x, i) => i !== index);
        this.documentUploadQueue = this.documentUploadQueue.filter((x, i) => i !== index);
        this.docComponent.uploader.queue = this.docComponent.uploader.queue.filter((x, i) => i !== index);
    }

    downloadDocumentPackage(): void {
        this.faxEmailService
            .generateDocuments('records-package', {
                EntityId: this.entityId,
                Extras: { records: this.docsToDownload },
            })
            .pipe(finalize(() => setTimeout(() => (this.doubleClickIsDisabled = false))))
            .subscribe(
                (data) => {
                    const thefile = new Blob([data.body as BlobPart], {
                        type: 'application/octet-stream',
                    });
                    saveAs(thefile, data.headers.get('X-File-Name'));
                },
                () => {
                    this.notificationsService.error('Document Download failed.');
                },
            );
    }

    getClaimTypeDynamicField(label: string, fieldName: string): DynamicField {
        return new DynamicField({
            formGroup: null,
            label: label,
            name: fieldName,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Checkbox,
            } as IDynamicFieldType),
            value: false,
        } as IDynamicField);
    }

    sortDocsToDownload(event: CdkDragDrop<string[]>): void {
        moveItemInArray(this.docsToDownload, event.previousIndex, event.currentIndex);
    }

    sortDocsToDownloadByDateUploaded(): void {
        const direction =
            this.currentDownloadDocsSort.direction === 0 ? 1 - this.currentDownloadDocsSort.direction : DocumentCardSortDirectionEnums.Asc;
        this.currentDownloadDocsSort.direction = direction;
        if (direction) {
            this.docsToDownload.sort((a, b) => +new Date(b.DateUploaded) - +new Date(a.DateUploaded));
        } else {
            this.docsToDownload.sort((a, b) => +new Date(a.DateUploaded) - +new Date(b.DateUploaded));
        }
    }

    getOpacityForDownloadDocsArrow(selectedDirection: DocumentCardSortDirectionEnums): number {
        return this.currentDownloadDocsSort.direction === selectedDirection ? 1 : 0.3;
    }

    sort(sortProperty: DocumentCardSortPropertyEnums): void {
        const direction = this.currentSort.property === sortProperty ? 1 - this.currentSort.direction : DocumentCardSortDirectionEnums.Asc;
        this.attachmentsService.sortProperty.next({ property: sortProperty, direction: direction });
    }

    getOpacityForArrow(selectedProperty: DocumentCardSortPropertyEnums, selectedDirection: DocumentCardSortDirectionEnums): number {
        if (!selectedProperty) {
            return 0.3;
        }
        return this.currentSort.direction === selectedDirection && this.currentSort.property === selectedProperty ? 1 : 0.3;
    }

    editPageNumbers(i): void {
        this.isEditingPageNumbers = true;
        this.documentBeingEditedIndex = i;
    }

    clearPageNumberUpdate(): void {
        this.tempNumPages = null;
        this.documentBeingEditedIndex = null;
        this.isEditingPageNumbers = false;
    }

    savePageNumbers(doc: IAttachmentDTO): void {
        doc.NoOfPages = this.tempNumPages;
        this.documentCrudService.update(doc).subscribe(() => {
            this.notificationsService.success('Document updated successfully.');
            const document = this.documentArray.find((d) => d.Id === doc.Id);

            if (document) {
                document.NoOfPages = doc.NoOfPages;
            }
            this.clearPageNumberUpdate();
        });
    }

    getAttachmentTypeDisplayName(document: IAttachmentDTO): string {
        if (document.AttachmentTypeId === +AttachmentTypeEnums.Automated_Templates && document.AutomatedTemplate) {
            return document.AutomatedTemplate.Name;
        } else if (document.AttachmentType) {
            return document.AttachmentType.Name;
        } else {
            return '';
        }
    }

    getDocuments(reset = false): void {
        if (!this.fetchingDocuments) {
            this.fetchingDocuments = true;

            if (reset) {
                this.documentArray = [];
                this.$documentsSubscription?.unsubscribe();
                this.fetchingDocuments = false;
                this.loaded = 0;
            }

            if (this.checkDuplicateBillDocuments) {
                this.fetchingDocuments = true;
            }

            this.$documentsSubscription = this.attachmentsService
                .search(this.type, this.entityId, this.loaded, this.toTake, this.name, false, this.shouldLoadAllDocuments)
                .pipe(finalize(() => (this.fetchingDocuments = false)))
                .subscribe((response) => {
                    this.documentArray = [...this.documentArray];
                    this.documentArray.push(...response.body);
                    this.loaded += response.body.length;
                    this.allDocsLoaded = response.body.length < this.toTake;

                    if (this.shouldSelectAllDocuments) {
                        this.selectedDocuments = [...response.body];
                        this.documentArray.forEach((document) => (document.Selected = this.shouldSelectAllDocuments));
                        this.allDocsLoaded = true;
                        this.shouldLoadAllDocuments = false;
                    }

                    if (this.checkDuplicateBillDocuments) {
                        this.duplicateBillDocuments = this.documentArray.filter((doc) => doc.AttachmentTypeId === this.selectedAttachmentType);
                        this.checkDuplicateBillDocuments = false;
                        this.shouldLoadAllDocuments = false;
                    }
                });
        }
    }

    loadMoreDocuments(): void {
        if (this.expanded) {
            this.onLoadMore.emit();
        } else {
            this.getDocuments();
        }
    }

    toggleCardSize(modal): void {
        if (this.expanded) {
            this.onCloseExpandedModal.emit();
        } else {
            this.modalService.showDocumentModal(modal, ModalTypes.DOCUMENTS);
        }
    }

    allDocsSelected() {
        this.shouldSelectAllDocuments = !this.shouldSelectAllDocuments;
        if (this.shouldSelectAllDocuments) {
            this.shouldLoadAllDocuments = true;
            this.getDocuments(true);
        } else {
            this.selectedDocuments = [];
            this.documentArray.forEach((document) => (document.Selected = this.shouldSelectAllDocuments));
        }
    }

    itemSelected(document: IAttachmentDTO, event) {
        document.Selected = event.target.checked;
        if (document.Selected) {
            this.selectedDocuments.push(document);
        } else {
            this.selectedDocuments = this.selectedDocuments.filter((doc) => doc !== document);
        }
        this.shouldSelectAllDocuments = this.selectedDocuments.length === this.documentArray.length;
    }

    async bulkDeleteDocs(): Promise<void> {
        if (this.selectedDocuments.length === 0) {
            this.notificationsService.error('No document selected');
            return;
        }

        const documentIds = this.selectedDocuments.filter((document) => document.CanDelete).map((document) => document.Id);

        try {
            await this.attachmentsService.deleteBulkAttachments(this.type, this.entityId, documentIds).toPromise();
            this.notificationsService.success('Documents deleted successfully');
        } catch (error) {
            this.notificationsService.error('Failed to delete one or more documents');
        }

        this.getDocuments(true);
        this.selectedDocuments = [];
        this.shouldSelectAllDocuments = false;
    }

    isBillDocumentUploaded(): boolean {
        if (
            this.selectedAttachmentType === +AttachmentTypeEnums.Bill_Physician_Expert ||
            this.selectedAttachmentType === +AttachmentTypeEnums.Bill_Transcription ||
            this.selectedAttachmentType === +AttachmentTypeEnums.Bill_Transportation ||
            this.selectedAttachmentType === +AttachmentTypeEnums.Bill_Translation ||
            this.selectedAttachmentType === +AttachmentTypeEnums.Bill_Radiology ||
            this.selectedAttachmentType === +AttachmentTypeEnums.Bill_Surveillance_Background_Check ||
            this.selectedAttachmentType === +AttachmentTypeEnums.Bill_Rental_Facility
        ) {
            return true;
        }
        return false;
    }

    showAdditionalBillPopup(): void {
        this.additionalBillDocumentsModalApi.show();
    }

    onContinue(): void {
        this.additionalBillDocumentsModalApi.close();
        this.save();
    }

    onCancle(): void {
        this.duplicateBillDocuments = [];
        this.additionalBillDocumentsModalApi.close();
    }

    private checkCorrespondingBillVendor() {
        switch (this.selectedAttachmentType as AttachmentTypeEnums) {
            case AttachmentTypeEnums.Bill_Physician_Expert:
                this.checkCorrespondingPhysicianFacilityIndividual();
                break;

            case AttachmentTypeEnums.Bill_Radiology:
                this.isRadiologyReviewService();
                break;

            case AttachmentTypeEnums.Bill_Transcription:
                this.checkCorrespondingTranscriptionServicesVendor();
                break;

            case AttachmentTypeEnums.Bill_Transportation:
                this.checkCorrespondingTransportationServicesVendor();
                break;

            case AttachmentTypeEnums.Bill_Translation:
                this.checkCorrespondingTranslationServicesVendor();
                break;

            case AttachmentTypeEnums.Bill_Surveillance_Background_Check:
                this.checkCorrespondingBackgroundInvestigationORSurveillanceVendor();
                break;

            case AttachmentTypeEnums.Bill_Rental_Facility:
                this.checkCorrespondingRentalFacility();
                break;

            default:
                this.save();
        }
    }

    private checkCorrespondingPhysicianFacilityIndividual(): void {
        this.imedClaimServiceService.checkForPhysicianFacilityIndividual(this.entityId).subscribe((facilityPresent) => {
            if (!facilityPresent) {
                this.vendorError();
            } else {
                this.checkForDuplicateBillDoc().catch((error) => {
                    this.notificationsService.error(error as string);
                });
            }
        });
    }

    private isRadiologyReviewService(): void {
        this.imedClaimServiceService.isRadiologyReviewService(this.entityId).subscribe((isRadiologyService) => {
            if (isRadiologyService) {
                this.checkCorrespondingPhysicianFacilityIndividual();
            } else {
                this.checkForDuplicateBillDoc().catch((error) => {
                    this.notificationsService.error(error as string);
                });
            }
        });
    }

    private checkCorrespondingTranscriptionServicesVendor(): void {
        this.imedClaimServiceService.checkForTranscriptionServiceVendor(this.entityId).subscribe((vendorPresent) => {
            if (!vendorPresent) {
                this.vendorError();
            } else {
                this.checkForDuplicateBillDoc().catch((error) => {
                    this.notificationsService.error(error as string);
                });
            }
        });
    }

    private checkCorrespondingTransportationServicesVendor(): void {
        this.imedClaimServiceService.checkForTransportationServiceVendor(this.entityId).subscribe((vendorPresent) => {
            if (!vendorPresent) {
                this.vendorError();
            } else {
                this.checkForDuplicateBillDoc().catch((error) => {
                    this.notificationsService.error(error as string);
                });
            }
        });
    }

    private checkCorrespondingTranslationServicesVendor(): void {
        this.imedClaimServiceService.checkForTranslationServiceVendor(this.entityId).subscribe((vendorPresent) => {
            if (!vendorPresent) {
                this.vendorError();
            } else {
                this.checkForDuplicateBillDoc().catch((error) => {
                    this.notificationsService.error(error as string);
                });
            }
        });
    }

    private checkCorrespondingBackgroundInvestigationORSurveillanceVendor(): void {
        this.imedClaimServiceService.checkForBackgroundInvestigationORSurveillanceVendor(this.entityId).subscribe((vendorPresent) => {
            if (!vendorPresent) {
                this.vendorError();
            } else {
                this.checkForDuplicateBillDoc().catch((error) => {
                    this.notificationsService.error(error as string);
                });
            }
        });
    }

    private checkCorrespondingRentalFacility(): void {
        this.imedClaimServiceService.checkForRentalFacility(this.entityId).subscribe((present) => {
            if (!present) {
                this.vendorError();
            } else {
                this.checkForDuplicateBillDoc().catch((error) => {
                    this.notificationsService.error(error as string);
                });
            }
        });
    }

    private vendorError(): void {
        this.notificationsService.error(
            'No vendor of this type is entered in this service. Please double check the  information and retry your upload',
        );
    }
}
