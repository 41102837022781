import { DynamicConfig, IDynamicConfig, IDynamicFormConfig } from '@mt-ng2/dynamic-form';

import { IAddress } from '../../interfaces/address';
import { IState } from '../../interfaces/state';
import { AddressDynamicControls } from '../../form-controls/address.form-controls';
import { IAddressType } from '../../interfaces/address-type';

export class AddressDynamicConfig<T extends IAddress> extends DynamicConfig<T> implements IDynamicConfig<T> {
    configControls = ['Address1', 'Address2', 'City', 'StateId', 'Zip', 'IsPrimary', 'AddressTypeId'];

    constructor(private address: T, private states: IState[], private addressTypes: IAddressType[]) {
        super();
        const dynamicControls = new AddressDynamicControls(this.address, {
            formGroup: 'Address',
            states: this.states,
        });
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
