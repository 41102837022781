import { IMasterFollowupType } from '@model/interfaces/master-followup-type';
import { IEntityListConfig, EntityListConfig, EntityListColumn, EntityListDeleteColumn } from '@mt-ng2/entity-list-module';
import { MasterFollowUpTypeAssociatedServicesComponent } from './master-followup-type-associated-services.component';

export class MasterFollowUpTypeEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    fireOnColumnSelected: true,
                    name: 'Name',
                    sort: {
                        sortProperty: 'Name',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (mft: IMasterFollowupType): string {
                        let associatedServices = ' ';
                        if (mft && mft.FollowupTypes) {
                            associatedServices = mft.FollowupTypes.filter((ft) => !ft.Archived).slice(0, 3).map((ft) => ft.Service.Name).join(', ');
                        }
                        return associatedServices;
                    },
                    name: 'Associated Services',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    component: MasterFollowUpTypeAssociatedServicesComponent,
                    fireOnColumnSelected: false,
                    name: '',
                    sort: { disableSort: true },
                    style: { width: 140 },
                }),
                new EntityListColumn({
                    accessorFunction: (mft: IMasterFollowupType) => mft.GeneralLiability ? 'Yes' : 'No',
                    name: 'General Liability',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (mft: IMasterFollowupType) => mft.WorkersComp ? 'Yes' : 'No',
                    name: 'Workers Compensation',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (mft: IMasterFollowupType) => mft.Auto ? 'Yes' : 'No',
                    name: 'No Fault/PIP',
                    sort: {
                        disableSort: true,
                    },
                }),
            ],
            // TODO: add archiving capability
            delete: new EntityListDeleteColumn({
                columnHtml: (entity: IMasterFollowupType) =>
                    entity.Archived
                        ? '<div class="text-center"><i class="fa fa-trash fa-lg aria-hidden="true"></i><i class="fa fa-lg fa-undo overlay-fa" ></i></div>'
                        : '<div class="text-center"><i class="fa fa-trash fa-lg" aria-hidden="true"></i></div>',
                confirm: false,
                headerText: '',
            }),
            rowClass: (entity: IMasterFollowupType) => (entity.Archived ? 'archived' : null),
        };
        super(listConfig);
    }
}
