import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { IService } from '@model/interfaces/service';
import { forkJoin } from 'rxjs';
import { TaskTemplateService } from '../../services/tasktemplate.service';
import { IFilteredServiceFollowupStatusTaskDTO } from '@model/interfaces/custom/filtered-service-followup-status-task-dto';
import { IMasterServiceStatusFollowupType } from '@model/interfaces/master-service-status-followup-type';
import { MasterServiceStatusFollowupTypeService } from '../../services/service-status-followup.service';
import { MasterServiceStatusTaskTemplateService } from '../../services/service-status-task.service';
import { IGroupedTaskTemplatesByStatusDTO } from '@model/interfaces/custom/grouped-task-templates-by-status-dto';
import { IStatusTaskAssociation } from './status-task-row/status-task-row.component';
import { IStatusFollowupAssociation } from '../base-row/base-row.component';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IMasterServiceStatusTaskTemplate } from '@model/interfaces/master-service-status-task-template';

@Component({
    selector: 'system-task-status-associations',
    styles: [
        `
            .clear-padding {
                padding-left: 0;
            }
        `,
    ],
    templateUrl: './system-task-status-associations.component.html',
})
export class SystemTaskStatusAssociationsComponent implements OnInit, OnChanges {
    @Input() selectedService: IService;
    filteredServiceDataForTasks: IFilteredServiceFollowupStatusTaskDTO;
    filteredServiceDataForFollowups: IFilteredServiceFollowupStatusTaskDTO;
    tasksGroupedByServiceStatus: IGroupedTaskTemplatesByStatusDTO[] = [];
    filteredStatusFollowupAssociations: IMasterServiceStatusFollowupType[] = [];
    filteredStatusTaskAssociations: IMasterServiceStatusTaskTemplate[] = [];

    constructor(
        private taskTemplateService: TaskTemplateService,
        private statusFollowupService: MasterServiceStatusFollowupTypeService,
        private statusTaskService: MasterServiceStatusTaskTemplateService,
        private notificationService: NotificationsService,
    ) { }

    ngOnInit(): void {
        this.getFilteredServiceData();
    }

    ngOnChanges(): void {
        this.getFilteredServiceData();
    }

    getFilteredServiceData(): void {
        this.filteredServiceDataForFollowups = null;
        this.filteredServiceDataForTasks = null;
        forkJoin(
            this.statusFollowupService.getAll(),
            this.statusTaskService.getAll(),
            this.taskTemplateService.getFilteredServiceStatusFollowupTasks(this.selectedService.Id),
            this.statusTaskService.getGroupedTaskTemplatesByService(this.selectedService.Id),
        ).subscribe(([statusFollowupAssociations, statusTaskAssociations, filteredServiceData, groupedTasks]) => {
            this.tasksGroupedByServiceStatus = groupedTasks;
            this.filteredServiceDataForFollowups = { ...filteredServiceData };
            this.filteredServiceDataForTasks = { ...filteredServiceData };
            this.filteredStatusFollowupAssociations = statusFollowupAssociations.filter((x) => x.ServiceId === this.selectedService.Id);
            this.filteredStatusTaskAssociations = statusTaskAssociations.filter((x) => x.ServiceId === this.selectedService.Id);
            this.filteredServiceDataForFollowups.ServiceStatuses = this.filteredServiceDataForFollowups.ServiceStatuses.filter(
                (x) => !this.filteredStatusFollowupAssociations.some((y) => y.MasterServiceStatusId === x.Id),
            );
            this.filteredServiceDataForTasks.ServiceStatuses = this.filteredServiceDataForTasks.ServiceStatuses.filter(
                (x) => !this.filteredStatusTaskAssociations.some((y) => y.MasterServiceStatusId === x.Id),
            );
        });
    }

    saveFollowupAssociation(event: IStatusFollowupAssociation, index: number): void {
        this.filteredServiceDataForFollowups.ServiceStatuses = this.filteredServiceDataForFollowups.ServiceStatuses.filter(
            (x) => x.Id !== event.Status.Id,
        );
        const statusFollowupAssociation: IMasterServiceStatusFollowupType = {
            Id: 0,
            MasterFollowupTypeId: event.Followup.Id,
            MasterServiceStatusId: event.Status.Id,
            ServiceId: this.selectedService.Id,
        };

        this.statusFollowupService.create(statusFollowupAssociation).subscribe((id) => {
            this.filteredStatusFollowupAssociations[index].Id = id;
            this.notificationService.success('Status followup association saved successfully.');
        });
    }

    saveTaskAssociation(event: IStatusTaskAssociation): void {
        const statusTaskAssociation: IMasterServiceStatusTaskTemplate = {
            Id: 0,
            MasterServiceStatusId: event.Status.Id,
            ServiceId: this.selectedService.Id,
            TaskTemplateId: event.TaskTemplate.Id,
        };

        this.statusTaskService.create(statusTaskAssociation).subscribe((id) => {
            statusTaskAssociation.Id = id;
            this.filteredStatusTaskAssociations.push(statusTaskAssociation);
            this.filteredServiceDataForTasks.ServiceStatuses = this.filteredServiceDataForTasks.ServiceStatuses.filter(
                (x) => x.Id !== statusTaskAssociation.MasterServiceStatusId,
            );
            this.notificationService.success('Status task association saved successfully.');
        });
    }

    addStatusFollowupAssociation(): void {
        this.filteredStatusFollowupAssociations.push({ ...this.statusFollowupService.getEmptyServiceFollowupAssociation() });
    }

    addStatusTaskAssociation(): void {
        this.tasksGroupedByServiceStatus.push({
            Status: null,
            TaskTemplates: [],
        } as IGroupedTaskTemplatesByStatusDTO);
    }

    deleteStatusFollowupAssociaton(index: number): void {
        this.statusFollowupService.delete(this.filteredStatusFollowupAssociations[index].Id).subscribe(() => {
            this.filteredStatusFollowupAssociations.splice(index, 1);
            this.notificationService.info('Status followup association deleted successfully.');
        });
    }

    deleteStatusTaskAssociation(statusTaskAssociation: IStatusTaskAssociation, index: number): void {
        const id = this.filteredStatusTaskAssociations.find(
            (x) => x.MasterServiceStatusId === statusTaskAssociation.Status.Id && x.TaskTemplateId === statusTaskAssociation.TaskTemplate.Id,
        ).Id;
        this.statusTaskService.delete(id).subscribe(() => {
            this.filteredStatusTaskAssociations = this.filteredStatusTaskAssociations.filter((x) => x.Id !== id);
            this.tasksGroupedByServiceStatus[index].TaskTemplates = this.tasksGroupedByServiceStatus[index].TaskTemplates.filter(
                (x) => x.Id !== statusTaskAssociation.TaskTemplate.Id,
            );

            if (!this.tasksGroupedByServiceStatus[index].TaskTemplates.length) {
                this.tasksGroupedByServiceStatus.splice(index, 1);
                this.filteredServiceDataForTasks.ServiceStatuses.push(statusTaskAssociation.Status);
            }
            this.notificationService.info('Status task association deleted successfully.');
        });
    }
}
