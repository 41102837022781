import { ISetting } from '@model/interfaces/setting.d';
import { AppSettingsService } from './../../app-settings.service';
import { TaskStatusEnums, UserRoleIdEnums } from './../../common/constants/Enums';
import { UserRoleService } from './../../user-roles/user-role.service';
import { IUser } from '@model/interfaces/user.d';
import { TaskStatusService } from './../taskstatus.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import 'rxjs/operators';
import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { TaskService } from '../task.service';
import { entityListModuleConfig } from '../../common/shared.module';
import { ClaimTypes } from '@model/ClaimTypes';
import { TasksEntityListConfig } from './tasks.entity-list-config';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { UserService } from '../../users/user.service';
import { AuthService } from '@mt-ng2/auth-module';
import { Subscription, forkJoin, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { ServiceService } from '../../imed-claims/services/service.service';
import { debounceTime, filter, finalize, map, tap } from 'rxjs/operators';
import { MtSearchFilterDaterangeComponent, ISearchFilterDaterangeValue } from '@mt-ng2/search-filter-daterange-control';
import { MetaItem } from '@mt-ng2/base-service';
import { ITaskDTO } from '@model/interfaces/custom/task-dto';
import { ReuseRoute } from '../../common/cache-search-reuse-strategy/reuse-route.library';
import { SpecialityService } from '../../imed-managed-list/speciality.service';
import { HttpResponse } from '@angular/common/http';
import { EntityListComponent, IColumnSortedEvent, IItemDeletedEvent, SortDirection } from '@mt-ng2/entity-list-module';
import { ImedClaimService } from '../../imed-claims/imedclaim.service';
import { IImedClaim } from '@model/interfaces/imed-claim';
import { ImedClaimTypeService } from '../../common/services/imed-claim-type.service';
import { CommonSearchService, ICommonSearch } from '@common/services/common-search.service';
import { ModalService } from '@mt-ng2/modal-module';
import { IUserRole } from '@model/interfaces/user-role';
import { ISpecialty } from '@model/interfaces/specialty';
import { IImedClaimType } from '@model/interfaces/imed-claim-type';
import { IService } from '@model/interfaces/service';
import { AssignedToTypes } from '../../../client-portal/common/constants/assigned-to-type.enum';

@Component({
    selector: 'app-tasks',
    styles: [
        `
            .search-bar-container {
                padding-bottom: 5px;
            }
            .user-or-user-role {
                margin-left: 0;
                padding-bottom: 4px;
                border: 2px dashed rgb(204, 204, 204);
                border-radius: 10px;
            }
            .user-or-user-role .or-label {
                color: grey;
            }
            .notes-column {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 150px;
                max-width: 150px;
            }
            .task-action-button {
                padding: 2px 6px;
            }
            .task-action-button:hover {
                background-color: transparent;
                color: inherit;
            }
            .task-actions {
                width: 120px;
            }
            .back-button {
                padding-left: 4px;
                padding-right: 6px;
                padding-top: 3px;
                padding-bottom: 0;
                margin-top: -3px;
            }
            .btn-red {
                background: red;
            }
        `,
    ],
    templateUrl: './tasks.component.html',
})
export class TasksComponent implements OnInit, OnDestroy, ReuseRoute {
    @ViewChild('datePicker') datePicker: MtSearchFilterDaterangeComponent;
    @ViewChild('completedDatePicker') completedDatePicker: MtSearchFilterDaterangeComponent;
    @ViewChild('taskList') taskList: EntityListComponent;
    searchControl = new UntypedFormControl();
    tasks: ITaskDTO[];
    currentPage = 1;
    itemsPerPage = entityListModuleConfig.itemsPerPage;

    query = '';
    total: number;
    taskStatuses: MtSearchFilterItem[] = [];
    entityListConfig = new TasksEntityListConfig(this.authService?.currentUser?.getValue()?.CustomOptions?.UserRoleId as number);
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();

    canAddTask = false;

    includeArchived = false;
    highValueOnly = false;
    rushOnly = false;

    pipe = new DatePipe('en-US');
    dueDateFromTo: ISearchFilterDaterangeValue;
    defaultDueDateTo: Date;

    placeholderAllUserRole = 'All User Roles';
    placeholderAllServices = 'All Services';
    appSettings: ISetting[] = [];
    subscription: Subscription = new Subscription();
    clickInTask: ITaskDTO;
    loading = true;
    searchTasksForm: UntypedFormGroup;
    claimName: string;
    physicianFacilityIndividual: string;
    defenseCounsel: string;
    plantiffAttorney: string;
    taskDescription: string;
    imedClaimServiceId: number;

    selectedUserRoleId: number;
    selectedUser: IUser;

    allServiceTypes: MtSearchFilterItem<IService>[] = [];
    serviceTypes: MtSearchFilterItem<IService>[] = [];
    userRoles: MtSearchFilterItem[] = [];
    allUsers = [];
    users: MtSearchFilterItem[] = [];
    serviceAssignedToUsers: MtSearchFilterItem[] = [];
    claimIdFromClaimDetail: number;
    completedByUsers: MtSearchFilterItem[] = [];
    physicianFacilityIndividualFirstLetter: MtSearchFilterItem[] = [];
    imedClaimTypes: MtSearchFilterItem[] = [];
    completionDateFromTo: ISearchFilterDaterangeValue = { startDate: null, endDate: null };
    userCustomOptions: any;

    physicianSpecialties: MtSearchFilterItem[] = [];
    previousUrl: string;

    commonSearchParams: SearchParams;
    userId: number;
    currentSearch: SearchParams;
    searchChanged: boolean;

    selectedTaskIds: number[] = [];
    userRoleEntities: IUserRole[] = [];
    showTaskAssignModal = false;

    accountingUserRoleTasks: ITaskDTO[];
    tasksList: ITaskDTO[];
    imedClaim: IImedClaim;
    alphabets: { Id: number; Name: string }[] = [];
    currentLetterSelection: any;
    exportTaskList = false;
    showAssignTasksButton = false;
    showCompleteTasksButton = false;
    userRoleId: number;

    constructor(
        private taskService: TaskService,
        private claimsService: ClaimsService,
        private router: Router,
        private notificationService: NotificationsService,
        private userservice: UserService,
        private taskStatusService: TaskStatusService,
        private authService: AuthService,
        private userRoleService: UserRoleService,
        private appSettingsService: AppSettingsService,
        private serviceService: ServiceService,
        private physicianSpecialtyService: SpecialityService,
        private route: ActivatedRoute,
        private imedClaimService: ImedClaimService,
        private imedClaimTypeService: ImedClaimTypeService,
        private commonSearchService: CommonSearchService,
        private modalService: ModalService,
        private userService: UserService,
    ) {
        for (let charCode = 65; charCode <= 90; charCode++) {
            this.alphabets.push({ Id: charCode, Name: String.fromCharCode(charCode) });
        }
    }

    ngOnInit(): void {
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            this.previousUrl = event.url;
        });
        this.entityListConfig.export = {
            exportName: 'Task List',
            getDataForExport: () => this.getTaskSubscription(true, true).pipe(map((x) => x.body)),
        };
        if (this.route.snapshot.queryParamMap.get('caseId')) {
            this.claimIdFromClaimDetail = +this.route.snapshot.queryParamMap.get('caseId');
            this.getImedClaimById();
            this.taskService.setClaimIdFromClaimDetail(this.claimIdFromClaimDetail);
        } else if (this.taskService.getClaimIdFromClaimDetail() !== null && this.taskService.getClaimIdFromClaimDetail() !== undefined) {
            this.claimIdFromClaimDetail = this.taskService.getClaimIdFromClaimDetail();
            this.getImedClaimById();
        } else {
            this.taskService.setClaimIdFromClaimDetail(null);
        }
        this.subscribeToUsers();
        this.subscribeToTaskRefresh();
        this.searchTasksForm = new UntypedFormGroup({
            searchClaimName: new UntypedFormControl(''),
            searchDefenseCounsel: new UntypedFormControl(''),
            searchHighValueOnly: new UntypedFormControl(false),
            searchIncludeArchived: new UntypedFormControl(false),
            searchPhysicianFacilityIndividual: new UntypedFormControl(''),
            searchPlantiffAttorney: new UntypedFormControl(''),
            searchRushOnly: new UntypedFormControl(false),
            searchServiceId: new UntypedFormControl(''),
            searchTaskDescription: new UntypedFormControl(''),
        });
        this.canAddTask = this.claimsService.hasClaim(ClaimTypes.Tasks, [ClaimValues.FullAccess]);
        this.subscribeToValueChanges();
        this.setDefaultDueDateFilters();
        this.setUserCustomOptions();
        this.userId = this.authService.currentUser.getValue().Id;
        forkJoin([
            this.taskStatusService.getSearchFilterItems(),
            this.userRoleService.getAll(),
            this.appSettingsService.getAll(),
            this.serviceService.getServiceTypeSortedList(),
            this.physicianSpecialtyService.getAll(),
            this.imedClaimTypeService.getAll(),
            this.commonSearchService.getCommonSearchFilters(),
            this.userService.getRoleId(this.authService.currentUser.getValue().Id),
        ]).subscribe(
            ([taskStatus, userroles, appsettings, serviceTypes, specialities, imedClaimTypes, commonFilters, userRoleId]: [
                MtSearchFilterItem[],
                IUserRole[],
                ISetting[],
                MtSearchFilterItem<IService>[],
                ISpecialty[],
                IImedClaimType[],
                ICommonSearch,
                number,
            ]) => {
                this.taskStatuses = taskStatus;
                this.preSelectTaskStatusFilter();
                this.userRoleEntities = userroles;
                this.userRoles = userroles.map(
                    (item) =>
                        new MtSearchFilterItem(
                            new MetaItem(item.Id, `${item.Name}`),
                            item.Id === this.selectedUserRoleId && !this.claimIdFromClaimDetail,
                        ),
                );
                this.appSettings = appsettings;

                this.allServiceTypes = serviceTypes;

                this.physicianSpecialties = specialities.map(
                    (speciality) => new MtSearchFilterItem(new MetaItem(speciality.Id, speciality.Name), false),
                );

                this.imedClaimTypes = imedClaimTypes.map((type) => new MtSearchFilterItem(type, false));
                this.imedClaimTypes.forEach((x) => (x.Selected = this.checkUserCustomOption(x)));

                this.commonSearchParams = commonFilters ? commonFilters.searchFilters : null;

                this.updateServiceFilter();
                this.getTasks();
                this.userRoleId = userRoleId;

                this.showAssignTasksButton = this.claimsService.hasClaim(ClaimTypes.BulkTaskReassignment, [ClaimValues.FullAccess]);
                this.showCompleteTasksButton = this.claimsService.hasClaim(ClaimTypes.BulkTaskCompletion, [ClaimValues.FullAccess]);
            },
        );
        this.physicianFacilityIndividualFirstLetter = this.alphabets.map(
            (alphabet) => new MtSearchFilterItem(new MetaItem(alphabet.Id, `${alphabet.Name}`), alphabet.Id === 0),
        );
    }

    ngOnDestroy(): void {
        this.taskService.setClaimIdFromClaimDetail(null);
        this.subscription.unsubscribe();
    }

    onRouteReuse(): void {
        // Should clear case if user navigates to tasks page from pages other than task details or case details
        if (!this.previousUrl.match('(tasks{1}|cases{1})W[0-9]+$')) {
            this.taskService.setClaimIdFromClaimDetail(null);
        }
        this.getTasks();
    }

    getImedClaimById(): void {
        this.imedClaimService
            .getById(this.claimIdFromClaimDetail)
            .pipe(
                tap((imedClaim) => {
                    this.imedClaim = imedClaim;
                }),
            )
            .subscribe();
    }

    setUserCustomOptions(): void {
        this.userCustomOptions = this.authService.currentUser.getValue().CustomOptions;
    }

    checkUserCustomOption(filterItem: MtSearchFilterItem): boolean {
        switch (filterItem.Item.Name) {
            case 'General Liability':
                return this.userCustomOptions.GeneralLiability;
            case 'Workers Compensation':
                return this.userCustomOptions.WorkersComp;
            case 'No Fault / PIP':
                return this.userCustomOptions.Auto;
            default:
                return false;
        }
    }

    subscribeToTaskRefresh(): void {
        this.subscription.add(this.taskService.refreshTasks.subscribe(() => this.getTasks()));
    }

    subscribeToUsers(): void {
        this.subscription.add(
            this.userservice.getAll().subscribe((users) => {
                this.allUsers = users;
                this.assignDefaultUser();
                (this.users = users.map(
                    (user) =>
                        new MtSearchFilterItem(
                            new MetaItem(user.Id, `${user.FirstName} ${user.LastName}`),
                            user.Id === this.authService.currentUser.getValue().Id && !this.claimIdFromClaimDetail,
                        ),
                )),
                    (this.serviceAssignedToUsers = users.map(
                        (user) => new MtSearchFilterItem(new MetaItem(user.Id, `${user.FirstName} ${user.LastName}`), false),
                    )),
                    (this.completedByUsers = JSON.parse(JSON.stringify(this.users)).map((x) => {
                        return { Item: x.Item, Selected: false };
                    }));
            }),
        );
    }

    assignDefaultUser(): void {
        if (!this.claimIdFromClaimDetail) {
            this.selectedUser = this.allUsers.filter((x) => x.Id === this.authService.currentUser.getValue().Id)[0];
            this.selectedUserRoleId = this.selectedUser ? this.selectedUser.AuthUser.RoleId : null;
        }
    }

    private subscribeToValueChanges(): void {
        this.subscription.add(
            this.searchTasksForm
                .get('searchClaimName')
                .valueChanges.pipe(debounceTime(400))
                .subscribe((value) => {
                    this.claimIdFromClaimDetail = null;
                    this.taskService.setClaimIdFromClaimDetail(null);
                    this.claimName = value;
                }),
        );

        this.subscription.add(
            this.searchTasksForm
                .get('searchPhysicianFacilityIndividual')
                .valueChanges.pipe(debounceTime(400))
                .subscribe((value) => {
                    this.physicianFacilityIndividual = value;
                }),
        );

        this.subscription.add(
            this.searchTasksForm
                .get('searchDefenseCounsel')
                .valueChanges.pipe(debounceTime(400))
                .subscribe((value) => {
                    this.defenseCounsel = value;
                }),
        );

        this.subscription.add(
            this.searchTasksForm
                .get('searchPlantiffAttorney')
                .valueChanges.pipe(debounceTime(400))
                .subscribe((value) => {
                    this.plantiffAttorney = value;
                }),
        );

        this.subscription.add(
            this.searchTasksForm
                .get('searchTaskDescription')
                .valueChanges.pipe(debounceTime(400))
                .subscribe((value) => {
                    this.taskDescription = value;
                }),
        );

        this.subscription.add(
            this.searchTasksForm
                .get('searchHighValueOnly')
                .valueChanges.pipe(debounceTime(400))
                .subscribe((value) => {
                    this.highValueOnly = value;
                }),
        );

        this.subscription.add(
            this.searchTasksForm
                .get('searchRushOnly')
                .valueChanges.pipe(debounceTime(400))
                .subscribe((value) => {
                    this.rushOnly = value;
                }),
        );

        this.subscription.add(
            this.searchTasksForm
                .get('searchIncludeArchived')
                .valueChanges.pipe(debounceTime(400))
                .subscribe((value) => {
                    this.includeArchived = value;
                }),
        );

        this.subscription.add(
            this.searchTasksForm
                .get('searchServiceId')
                .valueChanges.pipe(debounceTime(400))
                .subscribe((value) => {
                    this.imedClaimServiceId = value;
                }),
        );
    }

    private setDefaultDueDateFilters(): void {
        this.defaultDueDateTo = new Date();

        this.dueDateFromTo = {
            endDate: this.defaultDueDateTo,
            startDate: null,
        };

        if (this.datePicker) {
            this.datePicker.startDate = null;
            this.datePicker.endDate = this.defaultDueDateTo;
        }
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    returnToFirstPageAndResetSelection(): void {
        this.currentPage = 1;
        this.taskList.selectedEntities = [];
        this.selectedTaskIds = [];
    }

    resetFilters(): void {
        this.clearCommonSearch();
        this.imedClaim = null;
        this.claimIdFromClaimDetail = null;
        this.taskService.setClaimIdFromClaimDetail(null);
        if (this.claimName) {
            this.claimName = '';
            this.searchTasksForm.get('searchClaimName').setValue(null);
        }
        if (this.physicianFacilityIndividual) {
            this.physicianFacilityIndividual = '';
            this.searchTasksForm.get('searchPhysicianFacilityIndividual').setValue(null);
        }

        if (this.defenseCounsel) {
            this.defenseCounsel = '';
            this.searchTasksForm.get('searchDefenseCounsel').setValue(null);
        }

        if (this.plantiffAttorney) {
            this.plantiffAttorney = '';
            this.searchTasksForm.get('searchPlantiffAttorney').setValue(null);
        }
        if (this.taskDescription) {
            this.taskDescription = '';
            this.searchTasksForm.get('searchTaskDescription').setValue(null);
        }
        if (this.imedClaimServiceId) {
            this.imedClaimServiceId = null;
            this.searchTasksForm.get('searchServiceId').setValue(null);
        }
        this.serviceAssignedToUsers.forEach((x) => (x.Selected = false));
        if (this.selectedUser) {
            this.users.forEach((x) => (x.Item.Id !== this.selectedUser.Id ? (x.Selected = false) : (x.Selected = true)));
            this.userRoles.forEach((x) => (x.Item.Id !== this.selectedUser.AuthUser.RoleId ? (x.Selected = false) : (x.Selected = true)));
        } else {
            this.users.forEach((x) => (x.Selected = false));
            this.userRoles.forEach((x) => (x.Selected = false));
        }
        this.imedClaimTypes.forEach((x) => (x.Selected = this.checkUserCustomOption(x)));
        if (this.includeArchived) {
            this.searchTasksForm.get('searchIncludeArchived').setValue(false);
        }
        if (this.rushOnly) {
            this.searchTasksForm.get('searchRushOnly').setValue(false);
        }
        if (this.highValueOnly) {
            this.searchTasksForm.get('searchHighValueOnly').setValue(false);
        }
        this.preSelectTaskStatusFilter();
        this.setDefaultDueDateFilters();
        if (this.completionDateFromTo) {
            this.completionDateFromTo.startDate = null;
            this.completionDateFromTo.endDate = null;
            this.completedDatePicker.startDate = null;
            this.completedDatePicker.endDate = null;
        }
        this.serviceTypes.forEach((x) => (x.Selected = false));
        this.completedByUsers.forEach((x) => (x.Selected = false));
        this.physicianFacilityIndividualFirstLetter.forEach((x) => (x.Selected = false));
        this.physicianSpecialties.forEach((x) => (x.Selected = false));
        this.commonSearchParams = null;
        this.searchChanged = !this.searchChanged;
        this.returnToFirstPageAndResetSelection();
        this.getTasks();
    }

    private buildSearch(exportTaskList: boolean): ExtraSearchParams[] {
        const _extraSearchParams: ExtraSearchParams[] = [];

        const caseId: string = this.claimIdFromClaimDetail ? this.claimIdFromClaimDetail.toString() : null;
        const selectedTaskStatusesIds: number[] = this.getSelectedFilters(this.taskStatuses);
        const selectedServiceTypeIds: number[] = this.getSelectedFilters(this.serviceTypes);
        const selectedServiceAssignedUsers: number[] = this.getSelectedFilters(this.serviceAssignedToUsers);
        const selectedUsers: number[] = this.getSelectedFilters(this.users);
        const selectedRoles: number[] = this.getSelectedFilters(this.userRoles);
        const selectedCompletedByUsers: number[] = this.getSelectedFilters(this.completedByUsers);
        const selectedPhysicianFacilityIndividualFirstLetter: number[] = this.getSelectedFilters(this.physicianFacilityIndividualFirstLetter);
        const selectedSpecialitiies: number[] = this.getSelectedFilters(this.physicianSpecialties);
        const selectedImedClaimTypeIds: number[] = this.getSelectedFilters(this.imedClaimTypes);

        if (caseId) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'ClaimId',
                    value: caseId,
                }),
            );
        }

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'TaskStatusIds',
                valueArray: selectedTaskStatusesIds,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'ServiceIds',
                valueArray: selectedServiceTypeIds,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'UserId',
                valueArray: selectedUsers,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'ServiceAssignedTo',
                valueArray: selectedServiceAssignedUsers,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'UserRoleId',
                valueArray: selectedRoles,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'CompletedByUserIds',
                valueArray: selectedCompletedByUsers,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'PhysicianFacilityIndividualFirstLetter',
                valueArray: selectedPhysicianFacilityIndividualFirstLetter,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'SpecialityIds',
                valueArray: selectedSpecialitiies,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'imedClaimTypeIds',
                valueArray: selectedImedClaimTypeIds,
            }),
        );

        if (this.dueDateFromTo && this.dueDateFromTo.startDate) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'DueDateFrom',
                    value: this.pipe.transform(this.dueDateFromTo.startDate, 'M/d/yyyy', 'UTC'),
                }),
            );
        }

        if (this.dueDateFromTo && this.dueDateFromTo.endDate) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'DueDateTo',
                    value: this.pipe.transform(this.dueDateFromTo.endDate, 'M/d/yyyy', 'UTC'),
                }),
            );
        }

        if (this.completionDateFromTo && this.completionDateFromTo.startDate) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'CompletionDateFrom',
                    value: this.pipe.transform(this.completionDateFromTo.startDate, 'M/d/yyyy', 'UTC'),
                }),
            );
        }

        if (this.completionDateFromTo && this.completionDateFromTo.endDate) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'CompletionDateTo',
                    value: this.pipe.transform(this.completionDateFromTo.endDate, 'M/d/yyyy', 'UTC'),
                }),
            );
        }

        if (this.highValueOnly) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'highValueOnly',
                    value: 'true',
                }),
            );
        }

        if (this.rushOnly) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'rushOnly',
                    value: 'true',
                }),
            );
        }

        if (this.includeArchived) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'includeArchived',
                    value: 'true',
                }),
            );
        }

        if (this.claimName) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'ClaimName',
                    value: this.claimName,
                }),
            );
        }

        if (this.physicianFacilityIndividual) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'PhysicianFacilityIndividual',
                    value: this.physicianFacilityIndividual,
                }),
            );
        }

        if (this.defenseCounsel) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'DefenseCounsel',
                    value: this.defenseCounsel,
                }),
            );
        }

        if (this.plantiffAttorney) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'PlantiffAttorney',
                    value: this.plantiffAttorney,
                }),
            );
        }

        if (this.taskDescription) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'TaskDescription',
                    value: this.taskDescription,
                }),
            );
        }

        if (this.imedClaimServiceId) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'ImedClaimServiceId',
                    value: this.imedClaimServiceId.toString(),
                }),
            );
        }

        if (exportTaskList) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'exportTaskList',
                    value: 'true',
                }),
            );
        }

        return _extraSearchParams;
    }

    preFillFiltersWithSavedSearch(): void {
        this.autoFillSelectedFilters(this.taskStatuses, 'TaskStatusIds');
        this.autoFillSelectedFilters(this.imedClaimTypes, 'imedClaimTypeIds');
        this.autoFillSelectedFilters(this.serviceTypes, 'ServiceIds');
        this.autoFillSelectedFilters(this.serviceAssignedToUsers, 'ServiceAssignedTo');
        this.autoFillSelectedFilters(this.users, 'UserId');
        this.autoFillSelectedFilters(this.userRoles, 'UserRoleId');
        this.autoFillSelectedFilters(this.completedByUsers, 'CompletedByUserIds');
        this.autoFillSelectedFilters(this.physicianFacilityIndividualFirstLetter, 'PhysicianFacilityIndividualFirstLetter');
        this.autoFillSelectedFilters(this.physicianSpecialties, 'SpecialityIds');
        if (this.setCheckBoxFilter('highValueOnly', 'searchHighValueOnly', this.highValueOnly)) {
            this.highValueOnly = !this.highValueOnly;
        }
        if (this.setCheckBoxFilter('rushOnly', 'searchRushOnly', this.rushOnly)) {
            this.rushOnly = !this.rushOnly;
        }
        if (this.setCheckBoxFilter('includeArchived', 'searchIncludeArchived', this.includeArchived)) {
            this.includeArchived = !this.includeArchived;
        }
        let updatedVal = this.setTextFilter('PhysicianFacilityIndividual', 'searchPhysicianFacilityIndividual', this.physicianFacilityIndividual);
        if (updatedVal !== this.physicianFacilityIndividual) {
            this.physicianFacilityIndividual = updatedVal;
        }
        updatedVal = this.setTextFilter('DefenseCounsel', 'searchDefenseCounsel', this.defenseCounsel);
        if (updatedVal !== this.defenseCounsel) {
            this.defenseCounsel = updatedVal;
        }
        updatedVal = this.setTextFilter('PlantiffAttorney', 'searchPlantiffAttorney', this.plantiffAttorney);
        if (updatedVal !== this.plantiffAttorney) {
            this.plantiffAttorney = updatedVal;
        }
        updatedVal = this.setTextFilter('TaskDescription', 'searchTaskDescription', this.taskDescription);
        if (updatedVal !== this.taskDescription) {
            this.taskDescription = updatedVal;
        }
        updatedVal = this.setTextFilter('ImedClaimServiceId', 'searchServiceId', this.imedClaimServiceId);
        if (updatedVal !== this.imedClaimServiceId) {
            this.imedClaimServiceId = updatedVal;
        }
        updatedVal = this.setTextFilter('ClaimName', 'searchClaimName', this.claimName);
        if (updatedVal !== this.claimName) {
            this.claimName = updatedVal;
        }
        if (this.getDateFilter('DueDateFrom') !== this.dueDateFromTo.startDate) {
            this.dueDateFromTo.startDate = this.getDateFilter('DueDateFrom');
            this.datePicker.startDate = new Date(this.dueDateFromTo.startDate);
        }
        if (this.getDateFilter('DueDateTo') !== this.dueDateFromTo.endDate) {
            this.dueDateFromTo.endDate = this.getDateFilter('DueDateTo');
            this.datePicker.endDate = new Date(this.dueDateFromTo.endDate);
        }
        if (this.getDateFilter('CompletionDateFrom') !== null) {
            this.completionDateFromTo.startDate = this.getDateFilter('CompletionDateFrom');
            this.completedDatePicker.startDate = new Date(this.completionDateFromTo.startDate);
        }
        if (this.getDateFilter('CompletionDateTo') !== null) {
            this.completionDateFromTo.endDate = this.getDateFilter('CompletionDateTo');
            this.completedDatePicker.endDate = new Date(this.completionDateFromTo.endDate);
        }
    }

    getDateFilter(pptyName: string): any {
        const extrasearchParams = this.commonSearchParams.extraParams;
        const filteredParam = extrasearchParams?.find((extraParam) => {
            return extraParam.name === pptyName;
        });
        if (filteredParam && filteredParam.value) {
            return this.pipe.transform(filteredParam.value, 'M/d/yyyy', 'UTC');
        }
        return null;
    }

    setCheckBoxFilter(pptyName: string, formControlName: string, ppty: any): boolean {
        const extrasearchParams = this.commonSearchParams.extraParams;
        const filteredParam = extrasearchParams?.find((extraParam) => {
            return extraParam.name === pptyName;
        });
        if (filteredParam && filteredParam.value) {
            if (filteredParam.value !== ppty) {
                this.searchTasksForm.get(formControlName).setValue(filteredParam.value);
                return true;
            }
        }
        return false;
    }

    setTextFilter(pptyName: string, formControlName: string, currentVal: any): any {
        const extrasearchParams = this.commonSearchParams.extraParams;
        const filteredParam = extrasearchParams?.find((extraParam) => {
            return extraParam.name === pptyName;
        });
        if (filteredParam && filteredParam.value) {
            if (currentVal !== filteredParam.value) {
                this.searchTasksForm.get(formControlName).setValue(filteredParam.value);
                return filteredParam.value;
            }
        }
        return '';
    }

    private autoFillSelectedFilters(filterObj: MtSearchFilterItem[], pptyName: string): void {
        const extrasearchParams = this.commonSearchParams.extraParams;
        const filteredParam = extrasearchParams?.find((extraParam) => {
            return extraParam.name === pptyName;
        });
        if (filteredParam && filteredParam.valueArray && filteredParam.valueArray.length > 0) {
            filterObj.forEach((x) => (x.Selected = false));
            filterObj.map((item) => {
                filteredParam?.valueArray?.forEach((element) => {
                    if (element === item.Item.Id) {
                        item.Selected = true;
                    }
                });
            });
        }
    }

    preSelectTaskStatusFilter(): void {
        this.taskStatuses.forEach((element) => {
            if (element.Item.Id === (TaskStatusEnums.Pending as number)) {
                element.Selected = true;
            } else {
                element.Selected = false;
            }
        });
    }

    getTasks(): void {
        this.loading = true;
        this.getTaskSubscription()
            .pipe(finalize(() => (this.loading = false)))
            .subscribe((response) => {
                this.tasks = response.body;
                this.total = +response.headers.get('X-List-Count');
            });
    }

    getTaskSubscription(skipPaging?: boolean, exportTaskList?: boolean): Observable<HttpResponse<ITaskDTO[]>> {
        const search = this.commonSearchParams && this.commonSearchParams.query ? this.commonSearchParams.query : this.query;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch(exportTaskList);
        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.commonSearchParams && this.commonSearchParams.order ? this.commonSearchParams.order : this.order,
            orderDirection:
                this.commonSearchParams && this.commonSearchParams.orderDirection ? this.commonSearchParams.orderDirection : this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: skipPaging ? 0 : (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: skipPaging ? 0 : entityListModuleConfig.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        this.currentSearch = searchparams;
        return this.taskService.getTaskList(searchparams);
    }

    setDueDates(event: ISearchFilterDaterangeValue): void {
        this.dueDateFromTo = event;
    }

    setCompletionDates(event: ISearchFilterDaterangeValue): void {
        this.completionDateFromTo = event;
    }

    search(query: string): void {
        this.query = query;
        this.returnToFirstPageAndResetSelection();
        this.clearCommonSearchParamsAndSearch();
    }

    // Clear out common search params before getting next results
    clearCommonSearchParamsAndSearch(): void {
        this.returnToFirstPageAndResetSelection();
        this.getTasks();
    }

    clearCommonSearch(): void {
        this.commonSearchParams = null;
        this.searchChanged = !this.searchChanged;
    }

    toggleArchive(event: IItemDeletedEvent): void {
        this.taskService.archive(event.entity.Id as number, this.authService.currentUser.getValue().Id).subscribe(() => {
            this.notificationService.success(`Task ${event.entity.Archived ? 'Undo Delete' : 'Delete'}`);
            this.getTasks();
        });
    }

    selectCaseTypeAndFilterServices(): void {
        this.updateServiceFilter();
    }

    updateServiceFilter(): void {
        const selectedClaimTypes = this.imedClaimTypes.filter((ct) => ct.Selected);

        this.serviceTypes = this.allServiceTypes.filter((s) => {
            if (selectedClaimTypes.length > 0) {
                const returnValue = selectedClaimTypes.some((c) => c.Item.Id === s.Item.ClaimTypeId);
                return returnValue;
            } else {
                return true;
            }
        });
    }

    searchBySavedSearch(): void {
        this.commonSearchService.getCommonSearchFilters().subscribe((filters) => {
            this.users.forEach((x) => (x.Selected = false));
            this.userRoles.forEach((x) => (x.Selected = false));
            if (this.commonSearchParams) {
                this.resetFilters();
            }
            this.query = '';
            this.returnToFirstPageAndResetSelection();
            this.commonSearchParams = filters ? filters.searchFilters : null;
            this.preFillFiltersWithSavedSearch();
            this.getTasks();
            this.commonSearchService.clearCommonSearchFilters();
        });
    }

    onSelectionChanged(event: any): void {
        if (event.selectedEntities?.length >= 0) {
            this.tasksList = event.selectedEntities;
        }
        if (event.selectedEntities?.length >= 0) {
            this.selectedTaskIds = event.selectedEntities.map((x) => x.Id);
        }
    }

    assignTasks(): void {
        this.showTaskAssignModal = true;
    }

    async completeTasks(): Promise<void> {
        this.accountingUserRoleTasks = this.tasksList.filter((task) => task.IsAccountingTask == true);
        for (const task of this.tasksList) {
            if (!task.CompletedById) {
                event.stopPropagation();
                if (task.IsAccountingTask) {
                    const roleId = await this.userService.getRoleId(this.authService.currentUser.getValue().Id).toPromise();
                    if (roleId !== +UserRoleIdEnums.Accounting && roleId !== +UserRoleIdEnums.Admin) {
                        if (this.accountingUserRoleTasks.length == this.tasksList.length) {
                            this.notificationService.error(`Accounting Tasks can only be completed by Accounting or Admin.`);
                            return;
                        } else {
                            this.notificationService.error(
                                `Task with Claimant as "${task.Claimant}" Can't be Completed. Accounting Tasks can only be completed by Accounting or Admin.`,
                            );
                        }
                    } else {
                        task.TaskStatusId = TaskStatusEnums.Completed;
                        this.taskService.completeTask(this.authService.currentUser.getValue().Id, task).subscribe(() => {
                            this.taskService.refreshTasks.emit();
                        });
                    }
                } else if (!this.taskService.canCompleteTask(task, this.userRoleId, this.selectedUser)) {
                    continue;
                } else {
                    task.TaskStatusId = TaskStatusEnums.Completed;
                    this.taskService.completeTask(this.authService.currentUser.getValue().Id, task).subscribe(() => {
                        this.taskService.refreshTasks.emit();
                    });
                }
            }
        }
        this.returnToFirstPageAndResetSelection();
        this.notificationService.success('Task List Updated.');
    }

    refreshModal(): void {
        setTimeout(() => {
            this.showTaskAssignModal = false;
        });
    }

    taskSelected(event): void {
        if (!event?.$event?.target?.className?.includes('fa-square-o') && !event?.$event?.target?.className?.includes('fa-check-square-o')) {
            void this.router.navigate(['/tasks', event.entity.Id]);
            // deselect (or select again if already selected) the task user clicked on by imitating a click on the checkbox in select column
            // this will keep the task in the same state when the user navigates back to the task list
            event.$event.target?.classList.add('fa-square-o');
            this.taskList.itemSelected(event.entity, event.$event as MouseEvent);
        }
    }

    columnSorted(event: IColumnSortedEvent): void {
        if (this.commonSearchParams) {
            this.commonSearchParams.order = event.column.sort.sortProperty;
            this.commonSearchParams.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        } else {
            this.order = event.column.sort.sortProperty;
            this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        }
        this.getTasks();
    }
}
