import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IAuthUser } from '@model/interfaces/auth-user';
import { IUserRole } from '@model/interfaces/user-role';
import { AuthEntityService } from '../../auth-entity.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ActivatedRoute } from '@angular/router';
import { AuthUserDynamicConfig } from '../auth-user.dynamic-config';
import { finalize } from 'rxjs/operators';
import { UserRoleService } from '../../../user-roles/user-role.service';
import { UserTypeValues } from '../../../common/constants/user-type.enum';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { DynamicField, DynamicLabel, IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-auth-user-portal-access',
    templateUrl: './auth-user-portal-access.html',
})
export class AuthUserPortalAccessComponent implements OnInit {
    @Input('AuthUser') authUser: IAuthUser;
    @Input('canEdit') canEdit: boolean;
    @Output('updateVersion') updateVersion: EventEmitter<string> = new EventEmitter<string>();
    isHovered = false;
    isEditing: boolean;
    roles: IUserRole[];
    config: IDynamicFormConfig;
    userId: number;
    formFactory: AuthUserDynamicConfig<IAuthUser>;
    doubleClickIsDisabled = false;
    configControls: string[] = ['Username', 'RoleId'];

    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    constructor(
        private route: ActivatedRoute,
        private authEntitiyService: AuthEntityService,
        private userRolesService: UserRoleService,
        private notificationsService: NotificationsService,
    ) {}

    ngOnInit(): void {
        this.isEditing = false;
        this.config = { formObject: [], viewOnly: [] };
        this.userRolesService.getRolesByType(UserTypeValues.Admin).subscribe((answer) => {
            this.roles = answer;
            this.setConfig();
        });
        this.userId = +this.route.snapshot.params.userId;
    }

    setConfig(): void {
        this.formFactory = new AuthUserDynamicConfig<IAuthUser>(this.authUser, this.roles, this.configControls);
        this.config = this.formFactory.getForUpdate();

        this.viewOnly = this.config?.viewOnly?.map((x) => new DynamicLabel(x));
        this.formObject = this.config.formObject?.map((x) => new DynamicField(x));
    }

    formSubmitted(form: FormGroup): void {
        if (form.valid) {
            this.authEntitiyService
                .updatePortalAccess(this.authUser.Id, form.value.AuthUser.Username as string, form.value.AuthUser.RoleId as number)
                .subscribe(() => {
                    this.success();
                    this.authUser.RoleId = form.value.AuthUser.RoleId;
                    this.authUser.Username = form.value.AuthUser.Username;
                    this.setConfig();
                    this.isEditing = false;
                });
        } else {
            markAllFormFieldsAsTouched(form);
            this.error();
        }
    }

    edit(): void {
        this.isEditing = true;
    }

    cancel(): void {
        this.isEditing = false;
    }

    error(): void {
        this.notificationsService.error('Save failed. Please check the form and try again');
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }
}
