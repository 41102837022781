import { DateTimeConverterService } from './../../../../../common/services/date-time-converter.service';
import { Injectable } from '@angular/core';
import { IImeAppointment } from '@model/interfaces/ime-appointment.d';
import { ILabelGenerator } from '../../../../../common/interfaces/ILabelGenerator';
import { DatePipe } from '@angular/common';
import { FollowUpTypes } from '../../../../../common/constants/Enums';

@Injectable()
export class AppointmentLabelGenerator implements ILabelGenerator {
    private claimantRescheduleTypes: FollowUpTypes[] = [
        FollowUpTypes.IME_Request_for_rescheduling_from_claimant,
        FollowUpTypes.IME_Re_Exam_Request_for_rescheduling_from_claimant,
        FollowUpTypes.IME_Expert_Consultation_Request_for_rescheduling_from_claimant,
    ];

    private defenseCounselRescheduleTypes: FollowUpTypes[] = [
        FollowUpTypes.IME_Request_for_rescheduling_from_defense_counsel,
        FollowUpTypes.IME_Re_Exam_Request_for_rescheduling_from_defense_counsel,
        FollowUpTypes.IME_Expert_Consultation_Request_for_rescheduling_from_defense_counsel,
    ];

    private physicianRescheduleTypes: FollowUpTypes[] = [
        FollowUpTypes.IME_Request_for_rescheduling_from_physician,
        FollowUpTypes.IME_Re_Exam_Request_for_rescheduling_from_physician,
        FollowUpTypes.IME_Expert_Consultation_Request_for_rescheduling_from_physician,
    ];

    private plantiffAttorneyRescheduleTypes: FollowUpTypes[] = [
        FollowUpTypes.IME_Request_for_rescheduling_from_plaintiffs_attorney,
        FollowUpTypes.IME_Re_Exam_Request_for_rescheduling_from_plaintiffs_attorney,
        FollowUpTypes.IME_Expert_Consultation_Request_for_rescheduling_from_plaintiffs_attorney,
    ];

    constructor(private dateTimeConverterService: DateTimeConverterService, private datePipe: DatePipe) { }

    GetLabel(entity: IImeAppointment): string {
        if (entity.ImedClaimService.ImedClaimServiceFollowups.length > 0) {
            let note = '';
            if (this.claimantRescheduleTypes.includes(entity.ImedClaimService.ImedClaimServiceFollowups[0].FollowupTypeId)) {
                note = 'Request for rescheduling from claimant';
            } else if (this.defenseCounselRescheduleTypes.includes(entity.ImedClaimService.ImedClaimServiceFollowups[0].FollowupTypeId)) {
                note = 'Request for rescheduling from defense counsel';
            } else if (this.physicianRescheduleTypes.includes(entity.ImedClaimService.ImedClaimServiceFollowups[0].FollowupTypeId)) {
                note = 'Request for rescheduling from physician';
            } else if (this.plantiffAttorneyRescheduleTypes.includes(entity.ImedClaimService.ImedClaimServiceFollowups[0].FollowupTypeId)) {
                note = 'Request for rescheduling from plaintiffs attorney';
            }
            return `<div class="status-log-info">Appointment set on  <b>${this.dateTimeConverterService
                .applyTimezoneToAppointment(entity)
                .format('LLL')}</b> by <b>${entity.CreatedBy.FirstName}  ${entity.CreatedBy.LastName
                }</b><br/> <span class="errortext"><i>${note}</i></span></div><div class="status-log-clear"></div>`;
        } else {
            return `<div class="status-log-info">Appointment set on  <b>${this.dateTimeConverterService
                .applyTimezoneToAppointment(entity)
                .format('LLL')}</b> by <b>${entity.CreatedBy.FirstName}  ${entity.CreatedBy.LastName}</b> </div><div class="status-log-clear"></div>`;
        }
    }
}
