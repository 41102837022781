import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IImedClaimSubpoenaDetail } from '../interfaces/imed-claim-subpoena-detail';
import { IAddress } from '../interfaces/address';
import { IImedClaim } from '../interfaces/imed-claim';
import { IAddressBook } from '../interfaces/address-book';

export interface IImedClaimSubpoenaDetailDynamicControlsParameters {
    formGroup?: string;
    imedClaims?: IImedClaim[];
    residingCourtAddressBooks?: IAddressBook[];
    hearingAddresses?: IAddress[];
}

export class ImedClaimSubpoenaDetailDynamicControls {

    formGroup: string;
    imedClaims: IImedClaim[];
    residingCourtAddressBooks: IAddressBook[];
    hearingAddresses: IAddress[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private imedclaimsubpoenadetail?: IImedClaimSubpoenaDetail, additionalParameters?: IImedClaimSubpoenaDetailDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'ImedClaimSubpoenaDetail';
        this.imedClaims = additionalParameters && additionalParameters.imedClaims || undefined;
        this.residingCourtAddressBooks = additionalParameters && additionalParameters.residingCourtAddressBooks || undefined;
        this.hearingAddresses = additionalParameters && additionalParameters.hearingAddresses || undefined;

        this.Form = {
            BranchName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Branch Name',
                name: 'BranchName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.imedclaimsubpoenadetail && this.imedclaimsubpoenadetail.hasOwnProperty('BranchName') && this.imedclaimsubpoenadetail.BranchName !== null ? this.imedclaimsubpoenadetail.BranchName.toString() : '',
            }),
            ClerkName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Clerk Name',
                name: 'ClerkName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.imedclaimsubpoenadetail && this.imedclaimsubpoenadetail.hasOwnProperty('ClerkName') && this.imedclaimsubpoenadetail.ClerkName !== null ? this.imedclaimsubpoenadetail.ClerkName.toString() : '',
            }),
            ClientApprovalDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Client Approval Date',
                name: 'ClientApprovalDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.imedclaimsubpoenadetail && this.imedclaimsubpoenadetail.ClientApprovalDate || null,
            }),
            DefenseAttorneyIdBarNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Defense Attorney Id Bar Number',
                name: 'DefenseAttorneyIdBarNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.imedclaimsubpoenadetail && this.imedclaimsubpoenadetail.hasOwnProperty('DefenseAttorneyIdBarNumber') && this.imedclaimsubpoenadetail.DefenseAttorneyIdBarNumber !== null ? this.imedclaimsubpoenadetail.DefenseAttorneyIdBarNumber.toString() : '',
            }),
            Division: new DynamicField({
                formGroup: this.formGroup,
                label: 'Division',
                name: 'Division',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.imedclaimsubpoenadetail && this.imedclaimsubpoenadetail.hasOwnProperty('Division') && this.imedclaimsubpoenadetail.Division !== null ? this.imedclaimsubpoenadetail.Division.toString() : '',
            }),
            DocumentReason: new DynamicField({
                formGroup: this.formGroup,
                label: 'Document Reason',
                name: 'DocumentReason',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(500) ],
                validators: { 'maxlength': 500 },
                value: this.imedclaimsubpoenadetail && this.imedclaimsubpoenadetail.hasOwnProperty('DocumentReason') && this.imedclaimsubpoenadetail.DocumentReason !== null ? this.imedclaimsubpoenadetail.DocumentReason.toString() : '',
            }),
            GoodCause: new DynamicField({
                formGroup: this.formGroup,
                label: 'Good Cause',
                name: 'GoodCause',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(500) ],
                validators: { 'maxlength': 500 },
                value: this.imedclaimsubpoenadetail && this.imedclaimsubpoenadetail.hasOwnProperty('GoodCause') && this.imedclaimsubpoenadetail.GoodCause !== null ? this.imedclaimsubpoenadetail.GoodCause.toString() : '',
            }),
            HearingAddressId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Hearing Address',
                name: 'HearingAddressId',
                options: this.hearingAddresses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.imedclaimsubpoenadetail && this.imedclaimsubpoenadetail.HearingAddressId || null,
            }),
            HearingDateTime: new DynamicField({
                formGroup: this.formGroup,
                label: 'Hearing Date Time',
                name: 'HearingDateTime',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimsubpoenadetail && this.imedclaimsubpoenadetail.HearingDateTime || null,
            }),
            ImedClaimId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Imed Claim',
                name: 'ImedClaimId',
                options: this.imedClaims,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.imedclaimsubpoenadetail && this.imedclaimsubpoenadetail.ImedClaimId || null,
            }),
            IndexDocketCaseNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Index Docket Case Number',
                name: 'IndexDocketCaseNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.imedclaimsubpoenadetail && this.imedclaimsubpoenadetail.hasOwnProperty('IndexDocketCaseNumber') && this.imedclaimsubpoenadetail.IndexDocketCaseNumber !== null ? this.imedclaimsubpoenadetail.IndexDocketCaseNumber.toString() : '',
            }),
            JusticeJudgeName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Justice Judge Name',
                name: 'JusticeJudgeName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.imedclaimsubpoenadetail && this.imedclaimsubpoenadetail.hasOwnProperty('JusticeJudgeName') && this.imedclaimsubpoenadetail.JusticeJudgeName !== null ? this.imedclaimsubpoenadetail.JusticeJudgeName.toString() : '',
            }),
            Other1: new DynamicField({
                formGroup: this.formGroup,
                label: 'Other1',
                name: 'Other1',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.imedclaimsubpoenadetail && this.imedclaimsubpoenadetail.hasOwnProperty('Other1') && this.imedclaimsubpoenadetail.Other1 !== null ? this.imedclaimsubpoenadetail.Other1.toString() : '',
            }),
            Other2: new DynamicField({
                formGroup: this.formGroup,
                label: 'Other2',
                name: 'Other2',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.imedclaimsubpoenadetail && this.imedclaimsubpoenadetail.hasOwnProperty('Other2') && this.imedclaimsubpoenadetail.Other2 !== null ? this.imedclaimsubpoenadetail.Other2.toString() : '',
            }),
            Other3: new DynamicField({
                formGroup: this.formGroup,
                label: 'Other3',
                name: 'Other3',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(500) ],
                validators: { 'maxlength': 500 },
                value: this.imedclaimsubpoenadetail && this.imedclaimsubpoenadetail.hasOwnProperty('Other3') && this.imedclaimsubpoenadetail.Other3 !== null ? this.imedclaimsubpoenadetail.Other3.toString() : '',
            }),
            PartOrDepartment: new DynamicField({
                formGroup: this.formGroup,
                label: 'Part Or Department',
                name: 'PartOrDepartment',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.imedclaimsubpoenadetail && this.imedclaimsubpoenadetail.hasOwnProperty('PartOrDepartment') && this.imedclaimsubpoenadetail.PartOrDepartment !== null ? this.imedclaimsubpoenadetail.PartOrDepartment.toString() : '',
            }),
            PlaintiffAttorneyRequiresRecords: new DynamicField({
                formGroup: this.formGroup,
                label: 'Plaintiff Attorney Requires Records',
                name: 'PlaintiffAttorneyRequiresRecords',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimsubpoenadetail && this.imedclaimsubpoenadetail.hasOwnProperty('PlaintiffAttorneyRequiresRecords') && this.imedclaimsubpoenadetail.PlaintiffAttorneyRequiresRecords !== null ? this.imedclaimsubpoenadetail.PlaintiffAttorneyRequiresRecords : false,
            }),
            PlaintiffLodgedObjectionDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Plaintiff Lodged Objection Date',
                name: 'PlaintiffLodgedObjectionDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimsubpoenadetail && this.imedclaimsubpoenadetail.PlaintiffLodgedObjectionDate || null,
            }),
            ResidingCourtAddressBookId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Residing Court Address Book',
                name: 'ResidingCourtAddressBookId',
                options: this.residingCourtAddressBooks,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.imedclaimsubpoenadetail && this.imedclaimsubpoenadetail.ResidingCourtAddressBookId || null,
            }),
            Room: new DynamicField({
                formGroup: this.formGroup,
                label: 'Room',
                name: 'Room',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.imedclaimsubpoenadetail && this.imedclaimsubpoenadetail.hasOwnProperty('Room') && this.imedclaimsubpoenadetail.Room !== null ? this.imedclaimsubpoenadetail.Room.toString() : '',
            }),
            Title: new DynamicField({
                formGroup: this.formGroup,
                label: 'Title',
                name: 'Title',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.imedclaimsubpoenadetail && this.imedclaimsubpoenadetail.hasOwnProperty('Title') && this.imedclaimsubpoenadetail.Title !== null ? this.imedclaimsubpoenadetail.Title.toString() : '',
            }),
        };

        this.View = {
            BranchName: new DynamicLabel({
			    label: 'Branch Name',
			    value: this.imedclaimsubpoenadetail && this.imedclaimsubpoenadetail.hasOwnProperty('BranchName') && this.imedclaimsubpoenadetail.BranchName !== null ? this.imedclaimsubpoenadetail.BranchName.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ClerkName: new DynamicLabel({
			    label: 'Clerk Name',
			    value: this.imedclaimsubpoenadetail && this.imedclaimsubpoenadetail.hasOwnProperty('ClerkName') && this.imedclaimsubpoenadetail.ClerkName !== null ? this.imedclaimsubpoenadetail.ClerkName.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ClientApprovalDate: new DynamicLabel({
			    label: 'Client Approval Date',
			    value: this.imedclaimsubpoenadetail && this.imedclaimsubpoenadetail.ClientApprovalDate || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DefenseAttorneyIdBarNumber: new DynamicLabel({
			    label: 'Defense Attorney Id Bar Number',
			    value: this.imedclaimsubpoenadetail && this.imedclaimsubpoenadetail.hasOwnProperty('DefenseAttorneyIdBarNumber') && this.imedclaimsubpoenadetail.DefenseAttorneyIdBarNumber !== null ? this.imedclaimsubpoenadetail.DefenseAttorneyIdBarNumber.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Division: new DynamicLabel({
			    label: 'Division',
			    value: this.imedclaimsubpoenadetail && this.imedclaimsubpoenadetail.hasOwnProperty('Division') && this.imedclaimsubpoenadetail.Division !== null ? this.imedclaimsubpoenadetail.Division.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            DocumentReason: new DynamicLabel({
			    label: 'Document Reason',
			    value: this.imedclaimsubpoenadetail && this.imedclaimsubpoenadetail.hasOwnProperty('DocumentReason') && this.imedclaimsubpoenadetail.DocumentReason !== null ? this.imedclaimsubpoenadetail.DocumentReason.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            GoodCause: new DynamicLabel({
			    label: 'Good Cause',
			    value: this.imedclaimsubpoenadetail && this.imedclaimsubpoenadetail.hasOwnProperty('GoodCause') && this.imedclaimsubpoenadetail.GoodCause !== null ? this.imedclaimsubpoenadetail.GoodCause.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            HearingAddressId: new DynamicLabel({
			    label: 'Hearing Address',
			    value: getMetaItemValue(this.hearingAddresses as unknown as IMetaItem[], this.imedclaimsubpoenadetail && this.imedclaimsubpoenadetail.hasOwnProperty('HearingAddressId') && this.imedclaimsubpoenadetail.HearingAddressId !== null ? this.imedclaimsubpoenadetail.HearingAddressId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            HearingDateTime: new DynamicLabel({
			    label: 'Hearing Date Time',
			    value: this.imedclaimsubpoenadetail && this.imedclaimsubpoenadetail.HearingDateTime || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            ImedClaimId: new DynamicLabel({
			    label: 'Imed Claim',
			    value: getMetaItemValue(this.imedClaims as unknown as IMetaItem[], this.imedclaimsubpoenadetail && this.imedclaimsubpoenadetail.hasOwnProperty('ImedClaimId') && this.imedclaimsubpoenadetail.ImedClaimId !== null ? this.imedclaimsubpoenadetail.ImedClaimId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            IndexDocketCaseNumber: new DynamicLabel({
			    label: 'Index Docket Case Number',
			    value: this.imedclaimsubpoenadetail && this.imedclaimsubpoenadetail.hasOwnProperty('IndexDocketCaseNumber') && this.imedclaimsubpoenadetail.IndexDocketCaseNumber !== null ? this.imedclaimsubpoenadetail.IndexDocketCaseNumber.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            JusticeJudgeName: new DynamicLabel({
			    label: 'Justice Judge Name',
			    value: this.imedclaimsubpoenadetail && this.imedclaimsubpoenadetail.hasOwnProperty('JusticeJudgeName') && this.imedclaimsubpoenadetail.JusticeJudgeName !== null ? this.imedclaimsubpoenadetail.JusticeJudgeName.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Other1: new DynamicLabel({
			    label: 'Other1',
			    value: this.imedclaimsubpoenadetail && this.imedclaimsubpoenadetail.hasOwnProperty('Other1') && this.imedclaimsubpoenadetail.Other1 !== null ? this.imedclaimsubpoenadetail.Other1.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Other2: new DynamicLabel({
			    label: 'Other2',
			    value: this.imedclaimsubpoenadetail && this.imedclaimsubpoenadetail.hasOwnProperty('Other2') && this.imedclaimsubpoenadetail.Other2 !== null ? this.imedclaimsubpoenadetail.Other2.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Other3: new DynamicLabel({
			    label: 'Other3',
			    value: this.imedclaimsubpoenadetail && this.imedclaimsubpoenadetail.hasOwnProperty('Other3') && this.imedclaimsubpoenadetail.Other3 !== null ? this.imedclaimsubpoenadetail.Other3.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            PartOrDepartment: new DynamicLabel({
			    label: 'Part Or Department',
			    value: this.imedclaimsubpoenadetail && this.imedclaimsubpoenadetail.hasOwnProperty('PartOrDepartment') && this.imedclaimsubpoenadetail.PartOrDepartment !== null ? this.imedclaimsubpoenadetail.PartOrDepartment.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            PlaintiffAttorneyRequiresRecords: new DynamicLabel({
			    label: 'Plaintiff Attorney Requires Records',
			    value: this.imedclaimsubpoenadetail && this.imedclaimsubpoenadetail.hasOwnProperty('PlaintiffAttorneyRequiresRecords') && this.imedclaimsubpoenadetail.PlaintiffAttorneyRequiresRecords !== null ? this.imedclaimsubpoenadetail.PlaintiffAttorneyRequiresRecords : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            PlaintiffLodgedObjectionDate: new DynamicLabel({
			    label: 'Plaintiff Lodged Objection Date',
			    value: this.imedclaimsubpoenadetail && this.imedclaimsubpoenadetail.PlaintiffLodgedObjectionDate || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            ResidingCourtAddressBookId: new DynamicLabel({
			    label: 'Residing Court Address Book',
			    value: getMetaItemValue(this.residingCourtAddressBooks as unknown as IMetaItem[], this.imedclaimsubpoenadetail && this.imedclaimsubpoenadetail.hasOwnProperty('ResidingCourtAddressBookId') && this.imedclaimsubpoenadetail.ResidingCourtAddressBookId !== null ? this.imedclaimsubpoenadetail.ResidingCourtAddressBookId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Room: new DynamicLabel({
			    label: 'Room',
			    value: this.imedclaimsubpoenadetail && this.imedclaimsubpoenadetail.hasOwnProperty('Room') && this.imedclaimsubpoenadetail.Room !== null ? this.imedclaimsubpoenadetail.Room.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Title: new DynamicLabel({
			    label: 'Title',
			    value: this.imedclaimsubpoenadetail && this.imedclaimsubpoenadetail.hasOwnProperty('Title') && this.imedclaimsubpoenadetail.Title !== null ? this.imedclaimsubpoenadetail.Title.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };

    }
}
