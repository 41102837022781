import { EntityListColumn, IEntityListConfig, EntityListConfig } from '@mt-ng2/entity-list-module';

export class AddressListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    name: 'Address1',
                }),
                new EntityListColumn({
                    name: 'Address2',
                }),
                new EntityListColumn({
                    name: 'City',
                }),
                new EntityListColumn({
                    accessors: ['State', 'Name'],
                    name: 'State',
                }),
                new EntityListColumn({
                    name: 'Zip',
                }),
                new EntityListColumn({
                    name: 'IsPrimary',
                }),
                new EntityListColumn({
                    accessors: ['AddressType', 'Name'],
                    name: 'AddressType',
                }),
            ],
        };
        super(listConfig);
    }
}
