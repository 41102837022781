import { TaskService } from './../tasks/task.service';
import { Component, Injector } from '@angular/core';
import { TaskStatusEnums } from '../common/constants/Enums';
import { Router } from '@angular/router';
import { AuthService } from '@mt-ng2/auth-module';
import { ITaskDTO } from '@model/interfaces/custom/task-dto';
import { NavContentDynamicRowComponent } from '@mt-ng2/nav-module';

@Component({
    styles: [
        `
            .task-display {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 200px;
                max-width: 200px;
                display: inline-block;
                vertical-align: middle;
                font-size: 13px;
                cursor: pointer;
                margin-left: 6px;
            }
            .complete-task-button {
                float: right;
                margin-top: 3px;
                zoom: 1.3;
            }
            .btn-transparent:hover {
                background-color: transparent;
            }
            .marginBottom {
                margin-bottom: 3px;
            }
        `,
    ],
    template: ` <div class="row-item marginBottom" [ngClass]="task.TaskColor">
        <span (click)="goToTask()" class="task-display" ngbTooltip="adada">
            <span [innerHTML]="task.TaskDescriptions"></span>
        </span>
        <span>
            <a (click)="completeTask()" class="btn btn-xs btn-transparent" title="Mark Complete">
                <i class="fa fa-check-circle"></i>
            </a>
        </span>
    </div>`,
})
export class SidebarTaskComponent extends NavContentDynamicRowComponent {
    taskService: TaskService;

    get task(): ITaskDTO {
        return (<any>this.row.content) as ITaskDTO;
    }

    constructor(private router: Router, private injector: Injector, private authService: AuthService) {
        super();
        this.taskService = this.injector.get(TaskService);
    }

    goToTask(): void {
        void this.router.navigate(['/tasks', `${this.task.Id}`]);
    }

    updateTasks(): void {
        this.taskService.updateSidebarList();
    }

    completeTask(): void {
        this.task.TaskStatusId = TaskStatusEnums.Completed;
        this.taskService.completeTask(this.authService.currentUser.getValue().Id, this.task).subscribe(() => {
            this.updateTasks();
        });
    }
}
