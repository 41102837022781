import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IPhysicianSpecialtyDetail } from '../interfaces/physician-specialty-detail';
import { IUser } from '../interfaces/user';
import { IPhysician } from '../interfaces/physician';
import { ISpecialtyDetail } from '../interfaces/specialty-detail';

export interface IPhysicianSpecialtyDetailDynamicControlsParameters {
    formGroup?: string;
    physicians?: IPhysician[];
    specialtyDetails?: ISpecialtyDetail[];
    createdBies?: IUser[];
    modifiedBies?: IUser[];
}

export class PhysicianSpecialtyDetailDynamicControls {

    formGroup: string;
    physicians: IPhysician[];
    specialtyDetails: ISpecialtyDetail[];
    createdBies: IUser[];
    modifiedBies: IUser[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private physicianspecialtydetail?: IPhysicianSpecialtyDetail, additionalParameters?: IPhysicianSpecialtyDetailDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'PhysicianSpecialtyDetail';
        this.physicians = additionalParameters && additionalParameters.physicians || undefined;
        this.specialtyDetails = additionalParameters && additionalParameters.specialtyDetails || undefined;
        this.createdBies = additionalParameters && additionalParameters.createdBies || undefined;
        this.modifiedBies = additionalParameters && additionalParameters.modifiedBies || undefined;

        this.Form = {
            Archived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived',
                name: 'Archived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physicianspecialtydetail && this.physicianspecialtydetail.hasOwnProperty('Archived') && this.physicianspecialtydetail.Archived !== null ? this.physicianspecialtydetail.Archived : false,
            }),
            CreatedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Created By',
                name: 'CreatedById',
                options: this.createdBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.physicianspecialtydetail && this.physicianspecialtydetail.CreatedById || null,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physicianspecialtydetail && this.physicianspecialtydetail.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physicianspecialtydetail && this.physicianspecialtydetail.DateModified || null,
            }),
            ModifiedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Modified By',
                name: 'ModifiedById',
                options: this.modifiedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physicianspecialtydetail && this.physicianspecialtydetail.ModifiedById || null,
            }),
            PhysicianId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Physician',
                name: 'PhysicianId',
                options: this.physicians,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.physicianspecialtydetail && this.physicianspecialtydetail.PhysicianId || null,
            }),
            SpecialtyDetailId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Specialty Detail',
                name: 'SpecialtyDetailId',
                options: this.specialtyDetails,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.physicianspecialtydetail && this.physicianspecialtydetail.SpecialtyDetailId || null,
            }),
        };

        this.View = {
            Archived: new DynamicLabel({
			    label: 'Archived',
			    value: this.physicianspecialtydetail && this.physicianspecialtydetail.hasOwnProperty('Archived') && this.physicianspecialtydetail.Archived !== null ? this.physicianspecialtydetail.Archived : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            CreatedById: new DynamicLabel({
			    label: 'Created By',
			    value: getMetaItemValue(this.createdBies as unknown as IMetaItem[], this.physicianspecialtydetail && this.physicianspecialtydetail.hasOwnProperty('CreatedById') && this.physicianspecialtydetail.CreatedById !== null ? this.physicianspecialtydetail.CreatedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            DateCreated: new DynamicLabel({
			    label: 'Date Created',
			    value: this.physicianspecialtydetail && this.physicianspecialtydetail.DateCreated || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DateModified: new DynamicLabel({
			    label: 'Date Modified',
			    value: this.physicianspecialtydetail && this.physicianspecialtydetail.DateModified || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            ModifiedById: new DynamicLabel({
			    label: 'Modified By',
			    value: getMetaItemValue(this.modifiedBies as unknown as IMetaItem[], this.physicianspecialtydetail && this.physicianspecialtydetail.hasOwnProperty('ModifiedById') && this.physicianspecialtydetail.ModifiedById !== null ? this.physicianspecialtydetail.ModifiedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            PhysicianId: new DynamicLabel({
			    label: 'Physician',
			    value: getMetaItemValue(this.physicians as unknown as IMetaItem[], this.physicianspecialtydetail && this.physicianspecialtydetail.hasOwnProperty('PhysicianId') && this.physicianspecialtydetail.PhysicianId !== null ? this.physicianspecialtydetail.PhysicianId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            SpecialtyDetailId: new DynamicLabel({
			    label: 'Specialty Detail',
			    value: getMetaItemValue(this.specialtyDetails as unknown as IMetaItem[], this.physicianspecialtydetail && this.physicianspecialtydetail.hasOwnProperty('SpecialtyDetailId') && this.physicianspecialtydetail.SpecialtyDetailId !== null ? this.physicianspecialtydetail.SpecialtyDetailId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };

    }
}
