// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
        .delete-action-button {
            padding: 2px 6px;
        }
        .delete-action-button:hover {
            background-color: transparent;
            color: inherit;
        }
    `, "",{"version":3,"sources":["webpack://./src/app/imed-claims/imed-claim-additional-records/imed-claim-additional-records.component.ts"],"names":[],"mappings":";QACQ;YACI,gBAAgB;QACpB;QACA;YACI,6BAA6B;YAC7B,cAAc;QAClB","sourcesContent":["\n        .delete-action-button {\n            padding: 2px 6px;\n        }\n        .delete-action-button:hover {\n            background-color: transparent;\n            color: inherit;\n        }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
