import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { forkJoin, Subscription } from 'rxjs';

import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IAutomatedTemplate } from '@model/interfaces/automated-template';
import { IService } from '@model/interfaces/service';
import { IState } from '@model/interfaces/state';
import { IAddressBookType } from '@model/interfaces/address-book-type';
import { IAddressBook } from '@model/interfaces/address-book';
import { IIndividual } from '@model/interfaces/individual';
import { AutomatedTemplateDynamicControlsPartial } from '@model/partials/automated-template.form-controls.partial';
import { ClaimTypeEnums } from '../../common/constants/Enums';
import { AddressBooksSelectService } from '../../common/address-book-select-list/address-books-select.service';
import { IAddressBookSelectEntity } from '../../common/address-book-select-list/address-books-select-entity';
import { IndividualService } from '../../address-books/individuals/individual.service';
import { AddressBookService } from '../../address-books/addressbook.service';
import { IEmailTemplate } from '@model/interfaces/email-template';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'automated-template-form',
    styles: [
        `
            .form-select > dynamic-form-label {
                display: none;
            }
            .addressbook-row {
                display: flex;
                flex-direction: row;
            }
            .addressbook-row > div {
                flex: 0 0 80%;
            }
        `,
    ],
    templateUrl: './automated-template-form.component.html',
})
export class AutomatedTemplateFormComponent implements OnInit {
    // abstract controls
    abstractAutomatedTemplateControls: any;
    abstractAutomatedTemplateAddressBookControls: any;
    abstractAutomatedTemplateAddressBookTypeControls: any;
    abstractAutomatedTemplateEmailTemplateControls: any;
    abstractAutomatedTemplateIndividualControls: any;
    abstractAutomatedTemplateServiceControls: any;
    abstractAutomatedTemplateStateControls: any;

    @Input() automatedTemplate: IAutomatedTemplate;
    @Input() Services: IService[];
    @Input() States: IState[];
    @Input() AddressBookTypes: IAddressBookType[];
    @Input() EmailTemplates: IEmailTemplate[];

    templateServices: IService[];
    selectedStateIdsArray: number[] = [];
    selectedServiceIdsArray: number[] = [];
    selectedAddressBookTypeIdsArray: number[] = [];
    selectedEmailTemplateIdsArray: number[] = [];
    addressBooks: IAddressBookSelectEntity[] = [];
    individualIds: number[] = [];
    facilityIds: number[] = [];
    currentAddressBookIndex: number;

    @Output('formSubmit') formSubmit: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();
    @Output('attachedFks') attachedFks: EventEmitter<IAutomatedTemplate> = new EventEmitter<IAutomatedTemplate>();
    @Output('onCancelClick') onCancelClick: EventEmitter<void> = new EventEmitter<void>();

    automatedTemplateFormForm: UntypedFormGroup;
    doubleClickIsDisabled = false;
    showSelectStateDropdown = false;
    autoCaseTypeSelected = false;
    workersCompCaseTypeSelected = false;
    generalLiabilityCaseTypeSelected = false;
    showSelectServiceDropdown = false;
    formCreated = false;
    subscriptions$ = new Subscription();

    constructor(
        private fb: UntypedFormBuilder,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private addressBookSelectService: AddressBooksSelectService,
        private individualService: IndividualService,
        private addressBookService: AddressBookService,
    ) {}

    ngOnInit(): void {
        this.templateServices = this.Services;
        this.setServicesDropdownVisibility();
        this.createForm();
    }

    createForm(): void {
        this.getControls();
        this.automatedTemplateFormForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
        this.addSubscriptions();
    }

    addSubscriptions(): void {
        this.subscriptions$.add(
            this.automatedTemplateFormForm.controls.AutomatedTemplate.get('IsForAllStates').valueChanges.subscribe((value) => {
                this.showSelectStateDropdown = !value;
                if (value) {
                    this.selectedStateIdsArray = [];
                    this.getControls();
                }
                this.cdr.detectChanges();
            }),
        );
    }

    getControls(): void {
        if (this.automatedTemplate.IsForAllStates === undefined) {
            this.automatedTemplate.IsForAllStates = true;
        }
        if (!this.formCreated) {
            if (!this.automatedTemplate.AddressBookTypes) {
                this.automatedTemplate.AddressBookTypes = [];
            } else {
                this.automatedTemplate.AddressBookTypes.forEach((abt) => this.selectedAddressBookTypeIdsArray.push(abt.Id));
            }
            if (!this.automatedTemplate.EmailTemplates) {
                this.automatedTemplate.EmailTemplates = [];
            } else {
                this.automatedTemplate.EmailTemplates.forEach((et) => this.selectedEmailTemplateIdsArray.push(et.Id));
            }
            if (!this.automatedTemplate.States) {
                this.automatedTemplate.States = [];
            } else {
                this.automatedTemplate.States.forEach((state) => this.selectedStateIdsArray.push(state.Id));
            }
            if (!this.automatedTemplate.Services) {
                this.automatedTemplate.Services = [];
            } else {
                this.automatedTemplate.Services.forEach((service) => this.selectedServiceIdsArray.push(service.Id));
            }
            if (this.automatedTemplate.AddressBooks?.length > 0) {
                this.automatedTemplate.AddressBooks.forEach((ab) => {
                    this.generateAddressBookSelectEntity(ab);
                });
            }
            if (this.automatedTemplate.Individuals?.length > 0) {
                this.automatedTemplate.Individuals.forEach((ab) => {
                    this.generateAddressBookSelectEntity(ab);
                });
            }
            this.showSelectStateDropdown = !this.automatedTemplate.IsForAllStates;
        }
        this.abstractAutomatedTemplateControls = new AutomatedTemplateDynamicControlsPartial(this.automatedTemplate, {
            addressBookTypes: this.AddressBookTypes,
            emailTemplates: this.EmailTemplates,
            formGroup: 'AutomatedTemplate',
            selectedAddressBookTypeIdsArray: this.selectedAddressBookTypeIdsArray,
            selectedEmailTemplateIdsArray: this.selectedEmailTemplateIdsArray,
            selectedServiceIdsArray: this.selectedServiceIdsArray,
            selectedStateIdsArray: this.selectedStateIdsArray,
            services: this.templateServices,
            states: this.States,
        }).Form;
    }

    assignFormGroups(): UntypedFormGroup {
        return this.fb.group({
            AutomatedTemplate: this.fb.group({}),
            AutomatedTemplateAddressBooks: this.fb.group({}),
            AutomatedTemplateAddressBookTypes: this.fb.group({}),
            AutomatedTemplateEmailTemplates: this.fb.group({}),
            AutomatedTemplateIndividuals: this.fb.group({}),
            AutomatedTemplateServices: this.fb.group({}),
            AutomatedTemplateStates: this.fb.group({}),
        });
    }

    formSubmitted(): void {
        if (this.automatedTemplateFormForm.valid) {
            this.automatedTemplate.States = [];
            if (this.automatedTemplateFormForm.value.AutomatedTemplate.associatedStates) {
                this.automatedTemplateFormForm.value.AutomatedTemplate.associatedStates.forEach((stateId) =>
                    this.automatedTemplate.States.push(this.States.find((x) => x.Id === stateId)),
                );
            }
            this.automatedTemplate.Services = [];
            if (this.automatedTemplateFormForm.value.AutomatedTemplate.associatedServices) {
                this.automatedTemplateFormForm.value.AutomatedTemplate.associatedServices.forEach((serviceId) =>
                    this.automatedTemplate.Services.push(this.Services.find((x) => x.Id === serviceId)),
                );
            }
            this.automatedTemplate.AddressBookTypes = [];
            if (this.automatedTemplateFormForm.value.AutomatedTemplate.associatedAddressBookTypes) {
                this.automatedTemplateFormForm.value.AutomatedTemplate.associatedAddressBookTypes.forEach((addressBookTypeId) =>
                    this.automatedTemplate.AddressBookTypes.push(this.AddressBookTypes.find((x) => x.Id === addressBookTypeId)),
                );
            }
            this.automatedTemplate.EmailTemplates = [];
            if (this.automatedTemplateFormForm.value.AutomatedTemplate.associatedEmailTemplates) {
                this.automatedTemplateFormForm.value.AutomatedTemplate.associatedEmailTemplates.forEach((emailTemplateId) =>
                    this.automatedTemplate.EmailTemplates.push(this.EmailTemplates.find((x) => x.Id === emailTemplateId)),
                );
            }
            if (this.automatedTemplateFormForm.value.AutomatedTemplate.IsForAllStates) {
                this.automatedTemplate.States = [];
            }
            if (this.addressBooks.length > 0) {
                this.addressBooks.forEach((ab) => {
                    if (ab.AddressBookId) {
                        if (ab.IndividualId) {
                            this.individualIds.push(ab.IndividualId);
                        } else {
                            this.facilityIds.push(ab.AddressBookId);
                        }
                    }
                });
                forkJoin(
                    this.individualService.getIndividualsByIds(this.individualIds),
                    this.addressBookService.getAddressBooksByIds(this.facilityIds),
                ).subscribe(([individuals, addressBooks]) => {
                    (this.automatedTemplate.Individuals = individuals), (this.automatedTemplate.AddressBooks = addressBooks);
                    this.attachedFks.emit(this.automatedTemplate);
                    this.formSubmit.emit(this.automatedTemplateFormForm);
                });
            } else {
                this.automatedTemplate.Individuals = [];
                this.automatedTemplate.AddressBooks = [];
                this.attachedFks.emit(this.automatedTemplate);
                this.formSubmit.emit(this.automatedTemplateFormForm);
            }
        } else {
            markAllFormFieldsAsTouched(this.automatedTemplateFormForm);
            this.error();
        }
    }

    cancelClick(): void {
        this.onCancelClick.emit();
    }

    error(): void {
        this.notificationsService.error('Save failed.  Please check the form and try again.');
    }

    success(): void {
        this.notificationsService.success('Dynamic Template saved successfully.');
    }

    showHideServiceSelectDropdown(): void {
        if (this.autoCaseTypeSelected || this.generalLiabilityCaseTypeSelected || this.workersCompCaseTypeSelected) {
            this.showSelectServiceDropdown = false;
            setTimeout(() => {
                this.showSelectServiceDropdown = true;
            }, 0);
        } else {
            this.showSelectServiceDropdown = false;
        }
        this.filterServicesSelectOptions();
    }

    filterServicesSelectOptions(): void {
        this.templateServices = this.Services;
        if (!this.autoCaseTypeSelected) {
            this.templateServices = this.templateServices.filter((s) => s.ClaimTypeId !== +ClaimTypeEnums.Auto);
        }
        if (!this.generalLiabilityCaseTypeSelected) {
            this.templateServices = this.templateServices.filter((s) => s.ClaimTypeId !== +ClaimTypeEnums.GL);
        }
        if (!this.workersCompCaseTypeSelected) {
            this.templateServices = this.templateServices.filter((s) => s.ClaimTypeId !== +ClaimTypeEnums.WC);
        }
        const availableServiceIds = [];
        this.templateServices.forEach((templateService) => {
            availableServiceIds.push(templateService.Id);
        });
        if (availableServiceIds.length === 0) {
            this.selectedServiceIdsArray = [];
        }
        this.selectedServiceIdsArray.forEach((id) => {
            if (availableServiceIds.indexOf(id) === -1) {
                this.selectedServiceIdsArray = this.selectedServiceIdsArray.filter((x) => x !== id);
            }
        });
        this.getControls();
    }

    setServicesDropdownVisibility(): void {
        this.autoCaseTypeSelected = this.automatedTemplate.Auto;
        this.workersCompCaseTypeSelected = this.automatedTemplate.WorkersComp;
        this.generalLiabilityCaseTypeSelected = this.automatedTemplate.GeneralLiability;
        if (this.autoCaseTypeSelected || this.generalLiabilityCaseTypeSelected || this.workersCompCaseTypeSelected) {
            this.showSelectServiceDropdown = false;
            setTimeout(() => {
                this.showSelectServiceDropdown = true;
            }, 0);
        } else {
            this.showSelectServiceDropdown = false;
        }
        this.templateServices = this.Services;
        if (!this.autoCaseTypeSelected) {
            this.templateServices = this.templateServices.filter((s) => s.ClaimTypeId !== +ClaimTypeEnums.Auto);
        }
        if (!this.generalLiabilityCaseTypeSelected) {
            this.templateServices = this.templateServices.filter((s) => s.ClaimTypeId !== +ClaimTypeEnums.GL);
        }
        if (!this.workersCompCaseTypeSelected) {
            this.templateServices = this.templateServices.filter((s) => s.ClaimTypeId !== +ClaimTypeEnums.WC);
        }
    }

    checkGLCaseType(event: any): void {
        this.generalLiabilityCaseTypeSelected = event;
        this.showHideServiceSelectDropdown();
    }

    checkWCCaseType(event: any): void {
        this.workersCompCaseTypeSelected = event;
        this.showHideServiceSelectDropdown();
    }

    checkAutoCaseType(event: any): void {
        this.autoCaseTypeSelected = event;
        this.showHideServiceSelectDropdown();
    }

    serviceSelectionChanged(event: any): void {
        this.selectedServiceIdsArray = event === null ? [] : event;
        this.getControls();
    }
    stateSelectionChanged(event: any): void {
        this.selectedStateIdsArray = event;
        this.getControls();
    }
    addressBookTypeSelectionChanged(event: any): void {
        this.selectedAddressBookTypeIdsArray = event;
        this.getControls();
    }
    emailTemplateSelectionChanged(event: any): void {
        this.selectedEmailTemplateIdsArray = event;
        this.getControls();
    }

    addMoreAddressBooks(): void {
        this.addressBooks.push(this.addressBookSelectService.getEmptyAddressBookSelect());
    }

    removeAddressBook(index: number): void {
        this.addressBooks = this.addressBooks.filter((x) => x !== this.addressBooks[index]);
    }

    SearchAddressBooks(i: number): void {
        this.addressBookSelectService.openAddressBookSelect('', this.selectAddressBook);
    }

    selectAddressBook = (addressBookSelect: IAddressBookSelectEntity): void => {
        this.addressBooks[this.currentAddressBookIndex] = addressBookSelect;
    }

    generateAddressBookSelectEntity(addressBook: any): void {
        const addressBookSelectEntity = this.addressBookSelectService.getEmptyAddressBookSelect();
        if (addressBook?.AddressBookId) {
            addressBookSelectEntity.AddressBookId = addressBook.AddressBook.Id;
            addressBookSelectEntity.AddressBookName = addressBook.AddressBook.FacilityName;
            addressBookSelectEntity.IndividualId = addressBook.Id;
            addressBookSelectEntity.IndividualName = `${addressBook.FirstName} ${addressBook.LastName}`;
            this.addressBooks.push(addressBookSelectEntity);
        } else {
            addressBookSelectEntity.AddressBookId = addressBook.Id;
            addressBookSelectEntity.AddressBookName = addressBook.FacilityName;
            this.addressBooks.push(addressBookSelectEntity);
        }
    }
}
