import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { ITestimonyDepositionServiceDate } from '../interfaces/testimony-deposition-service-date';
import { ITestimonyDepositionService } from '../interfaces/testimony-deposition-service';

export interface ITestimonyDepositionServiceDateDynamicControlsParameters {
    formGroup?: string;
    testimonyDepositionServices?: ITestimonyDepositionService[];
}

export class TestimonyDepositionServiceDateDynamicControls {

    formGroup: string;
    testimonyDepositionServices: ITestimonyDepositionService[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private testimonydepositionservicedate?: ITestimonyDepositionServiceDate, additionalParameters?: ITestimonyDepositionServiceDateDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'TestimonyDepositionServiceDate';
        this.testimonyDepositionServices = additionalParameters && additionalParameters.testimonyDepositionServices || undefined;

        this.Form = {
            DateRequested: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Requested',
                name: 'DateRequested',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.testimonydepositionservicedate && this.testimonydepositionservicedate.DateRequested || null,
            }),
            TestimonyDepositionServiceId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Testimony Deposition Service',
                name: 'TestimonyDepositionServiceId',
                options: this.testimonyDepositionServices,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.testimonydepositionservicedate && this.testimonydepositionservicedate.TestimonyDepositionServiceId || null,
            }),
        };

        this.View = {
            DateRequested: new DynamicLabel({
			    label: 'Date Requested',
			    value: this.testimonydepositionservicedate && this.testimonydepositionservicedate.DateRequested || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            TestimonyDepositionServiceId: new DynamicLabel({
			    label: 'Testimony Deposition Service',
			    value: getMetaItemValue(this.testimonyDepositionServices as unknown as IMetaItem[], this.testimonydepositionservicedate && this.testimonydepositionservicedate.hasOwnProperty('TestimonyDepositionServiceId') && this.testimonydepositionservicedate.TestimonyDepositionServiceId !== null ? this.testimonydepositionservicedate.TestimonyDepositionServiceId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };

    }
}
