import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

import { DynamicField, DynamicFieldTypes, LabelPosition, LabelPositions, IDynamicField } from '@mt-ng2/dynamic-form';
import { common, markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { MetaItemService } from '@mt-ng2/base-service';
import { finalize } from 'rxjs/operators';
import { NotificationsService } from '@mt-ng2/notifications-module';

/**
 * This is a clone of the mt-managed-list component so that we can display the Ids if needed
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-meta-item-managed-list',
    styleUrls: ['./app-meta-item-managed-list.component.less'],
    templateUrl: './app-meta-item-managed-list.component.html',
})
export class AppMetaItemManagedListComponent implements OnInit {
    copy: { [key: string]: any; };
    form: UntypedFormGroup;
    formArray: UntypedFormArray;

    @Input('dynamicForm') dynamicForm: any;
    @Input('service') service: MetaItemService<any>;
    @Input('sortPropertyName') sortPropertyName = 'Sort';
    @Input('fields') fields: string[];
    @Input('duplicateCheck') duplicateCheck = true;
    @Input('duplicateCheckFields') duplicateCheckFields: string[];
    @Input('componentTitle') title: string;
    @Input('sortable') sortable;
    @Input('canAdd') canAdd = true;
    @Input('canRemove') canRemove = true;
    @Input('canEdit') canEdit = true;
    @Input('showId') showId = false;
    @Output('onSave') onSave: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();

    private _items: { [key: string]: any; }[] = [];
    @Input('items')
    set items(value: { [key: string]: any; }[]) {
        value = !value ? [] : value;
        // ensure one if we have the copy ready to push
        if (this.copy && value.length === 0) {
            value.push({ ...this.copy });
        }
        // set the variable
        this._items = value;

        // if we have already created the form object
        // then set it again with the new value
        // i.e. (when we have items updated)
        if (this.form) {
            this.setForm();
        }
    }
    get items(): { [key: string]: any; }[] {
        return this._items;
    }

    constructor(private fb: UntypedFormBuilder, private cdr: ChangeDetectorRef, private notificationsService: NotificationsService) {}

    ngOnInit(): void {
        if (!this.canEdit) {
            // if cannot edit, then can't add
            this.canAdd = false;
        }
        if (this.sortable === undefined) {
            this.sortable = Object.prototype.hasOwnProperty.call(this.dynamicForm, this.sortPropertyName);
        }
        if (this.fields === undefined) {
            const fields: string[] = [];
            for (const key in this.dynamicForm) {
                if (this.dynamicForm[key] && key !== this.sortPropertyName) {
                    fields.push(key);
                }
            }
            this.fields = fields;
        }
        if (this.duplicateCheck && this.duplicateCheckFields === undefined) {
            this.duplicateCheckFields = this.fields;
        }
        const item = { Id: 0 };
        for (const key in this.dynamicForm) {
            if (this.dynamicForm[key]) {
                item[key] = this.dynamicForm[key].value;
            }
        }
        this.copy = item;
        this.setForm();
        this.getItems();
    }

    getItems(): void {
        this.service.getAll().subscribe((answer) => {
            this.items = answer;
        });
    }

    onlyUnique(value, index, self): any {
        const firstItem = self.find((item) => {
            let isMatch = true;
            this.duplicateCheckFields.forEach((field) => {
                if (value[field] !== item[field]) {
                    isMatch = false;
                }
            });
            return isMatch;
        });
        return self.indexOf(firstItem) === index;
    }

    duplicateItemsCheckerFunction = (): any => {
        if (!(this.duplicateCheck && this.currentFormArray && (<any>this.currentFormArray).value)) {
            return null;
        }
        const items = (<any>this.currentFormArray).value;
        const uniqueItems = items.filter(this.onlyUnique.bind(this));
        if (uniqueItems.length === items.length) {
            return null;
        }
        return {
            duplicateItems: true,
        };
    }

    hasDuplicateItems(): boolean {
        return this.currentFormArray && this.currentFormArray.errors && this.currentFormArray.errors.duplicateItems;
    }

    setForm(): void {
        // sort if sortable
        if (this.sortable) {
            common.sortByProperty(this.items, this.sortPropertyName);
        }
        this.form = this.fb.group({});
        const formGroups = this.items.map((item) => this.fb.group(item));
        this.formArray = this.fb.array(formGroups, this.duplicateItemsCheckerFunction);
        this.form.addControl(this.cleanTitle, this.formArray);
        this.cdr.detectChanges();
    }

    get currentFormArray(): UntypedFormArray {
        return this.form.get(this.cleanTitle) as UntypedFormArray;
    }

    get cleanTitle(): string {
        return this.title.replace(/ +/g, '');
    }

    create(): void {
        const newItem = {};
        for (const key in this.copy) {
            if (Object.prototype.hasOwnProperty.call(this.copy, key)) {
                if (key === 'Id') {
                    newItem[key] = 0;
                } else {
                    const df: DynamicField = this.dynamicForm[key];
                    if (df.type.fieldType === DynamicFieldTypes.Numeric) {
                        newItem[key] = 0;
                    } else {
                        newItem[key] = '';
                    }
                }
            }
        }
        const formGroup = this.fb.group(newItem);
        this.formArray.push(formGroup);
    }

    getColClass(): string {
        if (this.fields.length === 1) {
            return 'col-md-12';
        }
        if (this.fields.length === 2) {
            return 'col-md-6';
        }
        return 'col-md-4';
    }

    getLabel(fieldName: string): string {
        const dynamicField = <DynamicField>{ ...this.dynamicForm[fieldName] };
        return dynamicField.label;
    }

    getField(form: UntypedFormGroup, fieldName: string): DynamicField {
        const fieldToCopy = <IDynamicField>{ ...this.dynamicForm[fieldName] };
        const dynamicField: DynamicField = new DynamicField({
            disabled: this.canEdit ? false : true,
            formGroup: fieldToCopy.formGroup,
            label: fieldToCopy.label,
            name: fieldToCopy.name,
            options: fieldToCopy.options,
            placeholder: fieldToCopy.placeholder,
            type: fieldToCopy.type,
            validation: fieldToCopy.validation,
            validators: fieldToCopy.validators,
            value: form.controls[fieldName].value,
        });
        dynamicField.labelPosition = new LabelPosition({
            position: LabelPositions.Hidden,
        });
        dynamicField.insideBoxValidation = true;
        return dynamicField;
    }

    delete(index: number): void {
        this.formArray.removeAt(index);
    }

    save(): void {
        if (this.sortable) {
            let count = 1;
            (<any>this.form.controls[this.cleanTitle]).controls.forEach((element) => {
                const controlItem = (<any[]>this.form.value[this.cleanTitle]).find((eachItem) => eachItem === element.value);
                controlItem[this.sortPropertyName] = count;
                count++;
            });
        }
        if (this.form.valid) {
            this.service
                .updateItems(this.form.value[this.cleanTitle] as any[])
                .pipe(finalize(() => this.getItems()))
                .subscribe(() => {
                    this.notificationsService.success(`${this.title} saved successfully`);
                });
        } else {
            markAllFormFieldsAsTouched(this.form);
            this.notificationsService.error('Save failed.  Please check the form and try again.');
        }
    }

    drop(event: CdkDragDrop<any, any>): void {
        this.formArray.controls.splice(event.currentIndex, 0, this.formArray.controls.splice(event.previousIndex, 1)[0]);
    }
}
