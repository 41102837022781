import { NgModule } from '@angular/core';
import { SharedModule } from '../common/shared.module';
import { GeneratedLetterTemplateBasicInfoComponent } from './generated-letter-template-basic-info/generated-letter-template-basic-info.componen';
import { GeneratedLetterTemplateDetailComponent } from './generated-letter-template-detail/generated-letter-template-detail.component';
import { GeneratedLetterTemplateHeaderComponent } from './generated-letter-template-header/generated-letter-template-header.component';
import { GeneratedLetterTemplatesComponent } from './generated-letter-template-list/generated-letter-templates.component';
import { GeneratedLetterTemplateRoutingModule } from './generated-letter-template-routing.module';

@NgModule({
    declarations: [
        GeneratedLetterTemplatesComponent,
        GeneratedLetterTemplateHeaderComponent,
        GeneratedLetterTemplateBasicInfoComponent,
        GeneratedLetterTemplateDetailComponent,
    ],
    imports: [SharedModule, GeneratedLetterTemplateRoutingModule],
})
export class GenerateLetterTemplateModule {
    static forRoot(): any {
        return {
            ngModule: GenerateLetterTemplateModule,
            providers: [],
        };
    }
}
