import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { UserService } from '../user.service';
import { IUser } from '@model/interfaces/user';
import { UserDynamicConfig } from '../user.dynamic-config';
import { IUserRole } from '@model/interfaces/user-role'; // added
import { IAuthUser } from '@model/interfaces/auth-user'; // added
import { AuthUserDynamicConfig } from '../../auth-entity/auth-user/auth-user.dynamic-config';
import { ICreateUserPayload } from '@model/interfaces/custom/create-user-payload';
import { AuthService } from '@mt-ng2/auth-module';
import { finalize } from 'rxjs/operators';
import { UserRoleService } from '../../user-roles/user-role.service';
import { UserTypeValues } from '../../common/constants/user-type.enum';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { trimObjectStringProperties } from '../../common/custom/trimObjectStringProperties';
import { DynamicField, DynamicLabel, IDynamicField, IDynamicFormConfig, IDynamicLabel } from '@mt-ng2/dynamic-form';
import { UntypedFormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { UserRoleIdEnums } from '@common/constants/Enums';

@Component({
    selector: 'app-user-basic-info',
    templateUrl: './user-basic-info.component.html',
})
export class UserBasicInfoComponent implements OnInit {
    @Input() user: IUser;
    @Input() canEdit: boolean;
    authUser: IAuthUser;
    roles: IUserRole[];
    additionalConfigs: any[] = [];
    isEditing: boolean;
    isHovered: boolean;
    config: IDynamicFormConfig;
    userForm: any;
    formFactory: UserDynamicConfig<IUser>;
    doubleClickIsDisabled = false;
    userRoleId: number;
    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    constructor(
        private userService: UserService,
        private notificationsService: NotificationsService,
        private userRoleService: UserRoleService,
        private authService: AuthService,
        private router: Router,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.isEditing = false;
        this.config = { formObject: [], viewOnly: [] };

        forkJoin(this.userService.getRoleId(this.authService.currentUser.getValue().Id)).subscribe((roleId) => {
            [this.userRoleId] = roleId;
        });

        if (this.isNewUser()) {
            this.userRoleService.getRolesByType(UserTypeValues.Admin).subscribe((answer) => {
                this.roles = answer;
                this.setConfig();
            });
        } else {
            this.setConfig();
        }
    }

    private isNewUser(): boolean {
        return this.user && this.user.Id && this.user.Id > 0 ? false : true;
    }

    getAdditionalConfigs(): AuthUserDynamicConfig<IAuthUser>[] {
        const pwConfigControls: string[] = ['SendResetEmail', 'Password', 'ConfirmPassword'];
        const authUser = this.isNewUser() ? null : this.user.AuthUser;
        const pwConfig = new AuthUserDynamicConfig<IAuthUser>(authUser, null, pwConfigControls);
        const roleConfig = new AuthUserDynamicConfig<IAuthUser>(authUser, this.roles);
        return [pwConfig, roleConfig];
    }

    setConfig(): void {
        const configControls = ['FirstName', 'LastName', 'Email', 'CanBeAssignedTasks', 'ClientUploadedStudyTask', 'CanCompleteOthersTasks'];
        this.formFactory = new UserDynamicConfig<IUser>(this.user, configControls);
        this.additionalConfigs = this.getAdditionalConfigs();
        if (this.isNewUser()) {
            this.isEditing = true;
            this.config = this.formFactory.getForCreate(this.additionalConfigs);
        } else {
            this.config = this.formFactory.getForUpdate();
        }

        this.viewOnly = this.config?.viewOnly?.map((x: IDynamicLabel) => new DynamicLabel(x));
        this.formObject = this.config.formObject?.map((x: IDynamicField) => new DynamicField(x));
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.isNewUser()) {
            void this.router.navigate(['/users']);
        } else {
            this.isEditing = false;
        }
    }

    formSubmitted(form: UntypedFormGroup): void {
        let passwordmatch = true;
        if (this.isNewUser()) {
            if (!this.authService.matchPassword(form)) {
                passwordmatch = false;
            }
        }

        if (form.valid && passwordmatch) {
            this.formFactory.assignFormValues(this.user, form.value.User as IUser);
            trimObjectStringProperties(this.user);
            if (this.isNewUser()) {
                this.user.CreatedById = this.authService.currentUser.getValue().Id;
                this.user.DateCreated = new Date();
                this.user.UserTypeId = UserTypeValues.Admin;

                const data: ICreateUserPayload = {
                    Password: form.value.AuthUser.Password,
                    SendEmail: form.value.AuthUser.SendResetEmail || false,
                    User: this.user,
                    Username: form.value.AuthUser.Username,
                    UserRoleId: form.value.AuthUser.RoleId,
                };
                // handle new user save
                this.userService.createUser(data).subscribe((answer) => {
                    void this.router.navigate(['/users/' + answer]);
                    this.userService.emitChange(this.user);
                    this.success();
                });
            } else {
                // handle existing user save
                this.userService.updateVersion(this.user).subscribe(
                    (answer) => {
                        answer
                            ? ((this.user.Version = answer),
                              (this.isEditing = false),
                              this.success(),
                              this.userService.emitChange(this.user),
                              this.setConfig(),
                              this.userService.refreshAllUsers())
                            : this.error();
                    },
                    (errorResponse) => {
                        this.error(errorResponse.error as string);
                    },
                );
            }
        } else {
            if (!passwordmatch) {
                this.error('Passwords do not match');
            } else {
                markAllFormFieldsAsTouched(form);
                this.error();
            }
        }
    }

    onFormCreated(group: UntypedFormGroup): void {
        const userGroup = group.controls.User as UntypedFormGroup;
        if (this.userRoleId === UserRoleIdEnums.Admin as number) {
            userGroup.controls.CanCompleteOthersTasks.enable();
        } else {
            userGroup.controls.CanCompleteOthersTasks.disable();
        }
        this.cdr.detectChanges();
    }

    error(msg?: string): void {
        if (!msg) {
            this.notificationsService.error(`Save failed.  Please check the form and try again.`);
        } else {
            this.notificationsService.error(msg);
        }
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }

    updateVersion(version): void {
        this.user.Version = version;
    }
}
