import { BaseService } from '@mt-ng2/base-service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/operators';
import { IPhysicianSpecialty } from '@model/interfaces/physician-specialty';

@Injectable()
export class PhysicianSpecialityService extends BaseService<IPhysicianSpecialty> {
    constructor(public http: HttpClient) {
        super('/physicianspecialities', http);
    }
}
