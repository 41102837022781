import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService, ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, SelectInputTypes, IDynamicFieldType, IDynamicField } from '@mt-ng2/dynamic-form';
import { DashboardService } from '../dashboard.service';
import { IDashboardType } from '@model/interfaces/dashboard-type';
import { UserRoleService } from '../../user-roles/user-role.service';
import { forkJoin } from 'rxjs';
import { DashboardTypeEnums } from '../../common/constants/Enums';
import { AbstractControl } from '@angular/forms';
import { ClaimTypes } from '@model/ClaimTypes';

@Component({
    selector: 'app-dashboard-header',
    templateUrl: './dashboard-header.component.html',
})
export class DashboardHeaderComponent implements OnInit, OnDestroy {
    header: string;
    dashboardToggle: DynamicField;
    dashboardSubscription: Subscription;
    selectedDashboardType: DashboardTypeEnums;
    buttons: AbstractControl;

    constructor(
        private authService: AuthService,
        private dashboardService: DashboardService,
        private userRoleService: UserRoleService,
        private claimsService: ClaimsService,
    ) {}

    ngOnInit(): void {
        if (this.claimsService.hasClaim(ClaimTypes.CaseManagement, [ClaimValues.ReadOnly, ClaimValues.FullAccess])) {
            forkJoin(
                this.dashboardService.getDashboardTypes(),
                this.userRoleService.getDashboardType(this.authService.currentUser.getValue().AuthId),
            ).subscribe(([types, defaultDashboard]) => {
                this.dashboardService.setUserDashboardType(defaultDashboard);
                this.selectedDashboardType = defaultDashboard;
                if (defaultDashboard === DashboardTypeEnums.Both) {
                    this.createDashboardToggle(types);
                }
            });
            this.dashboardSubscription = this.dashboardService.getUserDashboardtype().subscribe((type) => {
                this.selectedDashboardType = type;
                this.setHeader();
            });
        } else {
            this.dashboardService.setUserDashboardType(DashboardTypeEnums.NoWidgets);
        }
    }

    ngOnDestroy(): void {
        if (this.dashboardSubscription) {
            this.dashboardSubscription.unsubscribe();
        }
    }

    createDashboardToggle(types: IDashboardType[]): void {
        this.dashboardToggle = new DynamicField({
            formGroup: null,
            label: '',
            name: 'dashboardToggle',
            options: types,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.RadioButtonList,
            } as IDynamicFieldType),
            value: 0,
        } as IDynamicField);
    }

    setFocus(evt): void {
        this.buttons = evt;
        this.buttons.setValue(this.selectedDashboardType);
    }

    setHeader(): void {
        this.header =
            this.selectedDashboardType === DashboardTypeEnums.Both
                ? 'Management/Operational Dashboard'
                : this.selectedDashboardType === DashboardTypeEnums.Management
                ? 'Management Dashboard'
                : 'Operational Dashboard';
    }

    selectionChanged(event: DashboardTypeEnums): void {
        this.dashboardService.setUserDashboardType(event);
    }
}
