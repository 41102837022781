import { TaskStatusEnums } from './../../../../common/constants/Enums';
import { ITask } from '@model/interfaces/task.d';
import { IImedClaimServiceStatusLog } from '@model/interfaces/imed-claim-service-status-log.d';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { SearchParams } from '@mt-ng2/common-classes';

export const emptyImedClaimServiceStatusLog: IImedClaimServiceStatusLog = {
    ChangedById: 0,
    ChangedDate: null,
    Id: 0,
    ImedClaimServiceId: 0,
    ImedClaimServiceStatusId: 0,
};

export const emptyTask: ITask = {
    Archived: false,
    AssignedTypeId: null,
    CreatedById: null,
    DateCreated: null,
    DateModified: null,
    DueDate: null,
    FollowupTypeId: null,
    Id: 0,
    ImedClaimId: 0,
    ImedClaimServiceFollowupId: null,
    ImedClaimServiceId: null,
    IsAutomated: true,
    IsPhysicalCheckRequiredToFacility: false,
    ModifiedById: null,
    Notes: null,
    ParentTaskId: null,
    ServiceStatusId: null,
    TaskDescriptions: null,
    TaskStatusId: TaskStatusEnums.Pending,
    TriggeredFromServicePhysicalCheckRequiredToFacility: false,
    TriggeredFromServiceProcessServerFee: false,
    TriggeredFromServiceWitnessFee: false,
    TriggeredFromServiceWitnessFeePhysicalCheckToForeignCourtRequired: false,
    TriggeredFromServiceFacilityFeePhysicalCheckToFacilityRequired: false,
};

@Injectable()
export class ImedClaimServiceStatusLogService extends BaseService<IImedClaimServiceStatusLog> {
    constructor(public http: HttpClient) {
        super('/imedclaimservicestatuslog', http);
    }

    getByImedClaimServiceId(imedClaimServiceId: number): any {
        return this.http.get<IImedClaimServiceStatusLog[]>(`/imedclaimservicestatuslog/getByImedClaimServiceId/${imedClaimServiceId}`);
    }

    searchStatusLogs(imedClaimServiceId: number, data: SearchParams): any {
        const params = this.getHttpParams(data);
        return this.http.get<IImedClaimServiceStatusLog>(`/imedclaimservicestatuslog/${imedClaimServiceId}/_search`, {
            observe: 'response',
            params: params,
        });
    }
}
