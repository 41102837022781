import { Component, OnInit, Output, EventEmitter, OnDestroy, ChangeDetectorRef, Input, OnChanges } from '@angular/core';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ImedClaimServiceService } from '../../imed-claims/services/imedclaimservice.service';
import { IImedClaimService } from '@model/partials/imed-claim-service.partial';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { ICommunicationRequest } from '@model/interfaces/custom/communication-request';
import { OverlayPositionBuilder, Overlay } from '@angular/cdk/overlay';
import { AttachmentTypeEnums, AutomatedTemplateFaxEmailEntityTypeEnums, EmailTemplateTypes, FaxEmailEnum } from '../constants/Enums';
import { CommunicationLogService } from '../services/communication-log.service';
import { BaseAttachmentsService } from '../attachments/base-attachments.service';
import { IAutomatedTemplate } from '@model/interfaces/automated-template';
import { AutomatedTemplateService } from '../../automated-templates/services/automated-template.service';
import { ATFaxEmailService } from './at-fax-email.service';
import { IImedClaimPartial } from '@model/partials/imed-claim-partial';
import { BaseFaxEmailComponent } from '@common/base-fax-email/base-fax-email.component';
import { BaseFaxEmailHelperService } from '@common/base-fax-email/base-fax-email-helper.service';
import { AuthService } from '@mt-ng2/auth-module';
import { UserRoleService } from '../../user-roles/user-role.service';
import { IUserRole } from '@model/interfaces/user-role';
import { IEmailTemplate } from '@model/interfaces/email-template';
import { EmailTemplateService } from '../../email-templates/emailtemplate.service';
import { ISelectionChangedEvent as ITypeAheadSelectionChangedEvent } from '@mt-ng2/type-ahead-control';
import { StateService } from '@app-shared/services/state.service';
import { MultiselectItem, ISelectionChangedEvent } from '@mt-ng2/multiselect-control';

export interface IAppATFaxEmailComponentApi {
    close: () => void;
    createEmail: (entityTypeId?: AutomatedTemplateFaxEmailEntityTypeEnums) => void;
    setSelectedType: (type: FaxEmailEnum) => void;
    show: () => void;
}

@Component({
    selector: 'app-at-fax-email',
    styleUrls: ['../base-fax-email/base-fax-email.component.less'],
    templateUrl: './at-fax-email-component.html',
})
export class ATFaxEmailComponent extends BaseFaxEmailComponent implements OnInit, OnDestroy {
    automatedTemplates: IAutomatedTemplate[] = [];
    selectedAutomatedTemplate: IAutomatedTemplate;
    selectedEmailTemplate: IEmailTemplate;
    showSendAsAttachment = false;
    sendAsAttachment = false;
    userRole: IUserRole;
    faxEmailEnum = FaxEmailEnum;
    automatedTemplateFaxEmailEntityTypeEnums = AutomatedTemplateFaxEmailEntityTypeEnums;
    states: MultiselectItem[] = [];
    selectedStateIds: number[] = [];
    filterAutomatedTemplates: IAutomatedTemplate[] = [];

    set emailTemplate(val: IEmailTemplate) {
        this.emailSubject = val.Subject;
        this.emailId = val.To.trim();
        this.ccEmails = val.Cc?.trim();
        this.bccEmails = val.Bcc?.trim();
        this.emailBody = val.Body;
    }

    get isAutomatedTemplateSelected(): boolean {
        return this.selectedAutomatedTemplate !== undefined;
    }

    @Input() entityTypeId: AutomatedTemplateFaxEmailEntityTypeEnums;

    @Output() ready = new EventEmitter<IAppATFaxEmailComponentApi>();

    constructor(
        protected notificationsService: NotificationsService,
        protected atFaxEmailService: ATFaxEmailService,
        private communicationLogService: CommunicationLogService,
        private imedClaimServiceService: ImedClaimServiceService,
        private route: Router,
        protected overlay: Overlay,
        protected overlayPositionBuilder: OverlayPositionBuilder,
        protected cdr: ChangeDetectorRef,
        private baseAttachmentService: BaseAttachmentsService,
        private automatedTemplateService: AutomatedTemplateService,
        protected baseFaxEmailHelperService: BaseFaxEmailHelperService,
        private userRoleService: UserRoleService,
        private authService: AuthService,
        private emailTemplateService: EmailTemplateService,
        private stateService: StateService,
    ) {
        super(cdr, baseFaxEmailHelperService, notificationsService, overlay, overlayPositionBuilder);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.setInitialContent();

        this.ready.emit({
            close: this.closeFaxEmailModal.bind(this),
            createEmail: this.createEmail.bind(this),
            setSelectedType: this.setSelectedType.bind(this),
            show: this.showModal.bind(this),
        });
    }

    setInitialContent(): void {
        if (this.isNF10DocPopup) {
            this.selectedAutomatedTemplate = this.automatedTemplateService.getEmptyAutomatedTemplate();
            this.selectedAutomatedTemplate.Name = 'NEW YORK MOTOR VEHICLE NO-FAULT INSURANCE LAW DENIAL OF CLAIM';
            this.selectedAutomatedTemplate.Id = 0;
        } else if (this.isSubpoenaDocPopup) {
            this.selectedAutomatedTemplate = this.automatedTemplateService.getEmptyAutomatedTemplate();
            this.selectedAutomatedTemplate.Name = 'Subpoena';
            this.selectedAutomatedTemplate.Id = 0;
        } else if (this.isCompletedSubpoenaDocPopup) {
            this.selectedAutomatedTemplate = this.automatedTemplateService.getEmptyAutomatedTemplate();
            this.selectedAutomatedTemplate.Name = 'Completed Subpoena';
            this.selectedAutomatedTemplate.Id = 0;
        } else if (this.isAllSubpoenasDocPopup) {
            this.selectedAutomatedTemplate = this.automatedTemplateService.getEmptyAutomatedTemplate();
            this.selectedAutomatedTemplate.Name = 'All Subpoenas';
            this.selectedAutomatedTemplate.Id = 0;
        } else {
            this.getAutomatedTemplates();
        }
        if (this.route.url.includes('/services')) {
            this.imedClaimServiceService.getDefaultContactEmails(this.imedclaimServiceId).subscribe((defaultEmailString) => {
                this.emailId = defaultEmailString;
                if (this.fromServicesListPage) {
                    this.claimNumber = this.entity.ClaimNumber;
                    this.fileNumber = this.entity.FileNumber;
                } else {
                    this.claimNumber = this.entity.ImedClaim.ClaimNumber ? this.entity.ImedClaim.ClaimNumber : '';
                    this.fileNumber = this.entity.ImedClaim.FileNumber ? this.entity.ImedClaim.FileNumber : '';
                    this.subscriptions.add(
                        this.imedClaimServiceService.changeEmitted$.subscribe((service) => {
                            this.imedClaimService = service;
                        }),
                    );
                }
                if (this.isNF10DocPopup || this.isSubpoenaDocPopup || this.isCompletedSubpoenaDocPopup) {
                    this.createEmail();
                }
            });
        } else if (this.route.url.includes('/cases')) {
            if (this.isAllSubpoenasDocPopup) {
                this.createEmail();
            }
        }
        const currentUserId = this.authService.currentUser.getValue().Id;
        this.userRoleService.getRoleByUserId(currentUserId).subscribe((response) => {
            this.userRole = response;
            this.showSendAsAttachment = this.userRole.AllowToSendAsAttachment;
            if (this.showSendAsAttachment) {
                this.sendAsAttachment = true;
            }
        });
    }

    createEmail(entityTypeId?: AutomatedTemplateFaxEmailEntityTypeEnums): void {
        if (entityTypeId) {
            this.entityTypeId = entityTypeId;
        }
        this.emailSubject = this.getEmailSubject(this.entityTypeId);
        this.populateEmailBody();
        this.filterEmailTemplates();
    }

    automatedTemplateSelectionChanged(event: ITypeAheadSelectionChangedEvent): void {
        if (event.selection === null) {
            return;
        }
        this.selectedAutomatedTemplate = event.selection;
        this.createEmail();
    }

    stateSelectionChanged(event: ISelectionChangedEvent): void {
        this.selectedStateIds = event.selectedItems.map(state => state.Id);

        if (this.automatedTemplates == null ) {
            return;
        }

        if (this.selectedStateIds && this.selectedStateIds.length > 0) {
            this.filterAutomatedTemplates = this.automatedTemplates.filter (
                (at) => at.IsForAllStates || (at.States && at.States.some((state) => this.selectedStateIds.includes(state.Id))),
            );
        } else {
            this.filterAutomatedTemplates = this.automatedTemplates;
        }
        this.cdr.detectChanges();
    }

    getSelectedEmailTemplateData(): void {
        if (this.entityTypeId === AutomatedTemplateFaxEmailEntityTypeEnums.ImedService) {
            this.emailTemplateService.mergeEmailTemplate(this.selectedEmailTemplate.Id, this.imedclaimServiceId).subscribe((mergedTemplate) => {
                this.emailTemplate = mergedTemplate;
            });
        } else if (this.entityTypeId === AutomatedTemplateFaxEmailEntityTypeEnums.AddressBookIndividual) {
            this.emailTemplateService.mergeIndividualEmailTemplate(this.selectedEmailTemplate.Id, this.entityId).subscribe((mergedTemplate) => {
                this.emailTemplate = mergedTemplate;
            });
        } else if (this.entityTypeId === AutomatedTemplateFaxEmailEntityTypeEnums.AddressBookFacility) {
            this.emailTemplateService.mergeAddressBookEmailTemplate(this.selectedEmailTemplate.Id, this.entityId).subscribe((mergedTemplate) => {
                this.emailTemplate = mergedTemplate;
            });
        } else if (this.entityTypeId === AutomatedTemplateFaxEmailEntityTypeEnums.ImedClaim) {
            this.emailTemplateService.mergeCaseEmailTemplate(this.selectedEmailTemplate.Id, this.imedClaimId).subscribe((mergedTemplate) => {
                this.emailTemplate = mergedTemplate;
            });
        }
    }

    filterEmailTemplates(): void {
        if (this.entityId === +AutomatedTemplateFaxEmailEntityTypeEnums.ImedClaim) {
            this.selectedAutomatedTemplate.EmailTemplates = this.selectedAutomatedTemplate.EmailTemplates.filter(
                (et) => et.EmailTemplateTypeId === +EmailTemplateTypes.CASE,
            );
        } else if (this.entityId === +AutomatedTemplateFaxEmailEntityTypeEnums.ImedService) {
            this.selectedAutomatedTemplate.EmailTemplates = this.selectedAutomatedTemplate.EmailTemplates.filter(
                (et) => et.EmailTemplateTypeId === +EmailTemplateTypes.SERVICE,
            );
        } else if (
            this.entityId === +AutomatedTemplateFaxEmailEntityTypeEnums.AddressBookIndividual ||
            this.entityId === +AutomatedTemplateFaxEmailEntityTypeEnums.AddressBookFacility
        ) {
            this.selectedAutomatedTemplate.EmailTemplates = this.selectedAutomatedTemplate.EmailTemplates.filter(
                (et) => et.EmailTemplateTypeId === +EmailTemplateTypes.ADDRESS_BOOK,
            );
        }
    }

    getEmailSubject(entityTypeId: AutomatedTemplateFaxEmailEntityTypeEnums): string {
        let emailSubjectString = '';
        let imedClaim: IImedClaimPartial;
        const physician = (<IImedClaimService>this.entity).Physician;
        const individual = (<IImedClaimService>this.entity).Individual;
        const facility = (<IImedClaimService>this.entity).AddressBook;
        switch (entityTypeId) {
            case AutomatedTemplateFaxEmailEntityTypeEnums.ImedClaim:
                imedClaim = <IImedClaimPartial>this.entity;
                emailSubjectString = imedClaim.JobNumber ? imedClaim.JobNumber : '';
                if (imedClaim.ClaimNumber) {
                    emailSubjectString += emailSubjectString !== '' ? ` / ${imedClaim.ClaimNumber}` : `${imedClaim.ClaimNumber}`;
                }
                if (imedClaim.FileNumber) {
                    emailSubjectString += emailSubjectString !== '' ? ` / ${imedClaim.FileNumber}` : `${imedClaim.FileNumber}`;
                }
                if (imedClaim.LastName) {
                    emailSubjectString += emailSubjectString !== '' ? ` - ${imedClaim.LastName}` : `${imedClaim.LastName}`;
                }
                if (imedClaim.FirstName) {
                    emailSubjectString += ` ${imedClaim.FirstName}`;
                }
                if (imedClaim.ImedClaimType?.Name) {
                    emailSubjectString += ` - ${imedClaim.ImedClaimType?.Name}`;
                }
                break;
            case AutomatedTemplateFaxEmailEntityTypeEnums.ImedService:
                imedClaim = <IImedClaimPartial>this.entity.ImedClaim;
                emailSubjectString = imedClaim.JobNumber ? imedClaim.JobNumber : '';
                if (imedClaim.ClaimNumber) {
                    emailSubjectString += emailSubjectString !== '' ? ` / ${imedClaim.ClaimNumber}` : `${imedClaim.ClaimNumber}`;
                }
                if (imedClaim.FileNumber) {
                    emailSubjectString += emailSubjectString !== '' ? ` / ${imedClaim.FileNumber}` : `${imedClaim.FileNumber}`;
                }
                if (imedClaim.LastName) {
                    emailSubjectString += emailSubjectString !== '' ? ` - ${imedClaim.LastName}` : `${imedClaim.LastName}`;
                }
                if (imedClaim.FirstName) {
                    emailSubjectString += ` ${imedClaim.FirstName}`;
                }
                if ((<IImedClaimService>this.entity).Service?.Name) {
                    emailSubjectString += ` - ${(<IImedClaimService>this.entity).Service?.Name}`;
                }
                if (physician?.Individual) {
                    emailSubjectString += ` - ${physician?.Individual.FirstName} ${physician?.Individual.LastName}`;
                } else if (individual) {
                    emailSubjectString += ` - ${individual.FirstName} ${individual.LastName}`;
                } else if (facility) {
                    emailSubjectString += ` - ${facility.FacilityName}`;
                }
                if ((<IImedClaimService>this.entity).ImeAppointments?.length > 0) {
                    emailSubjectString += ` - ${new Date(
                        (<IImedClaimService>this.entity).ImeAppointments[
                            (<IImedClaimService>this.entity).ImeAppointments.length - 1
                        ].AppointmentDateTime,
                    ).toLocaleString()}`;
                }
                break;
            case AutomatedTemplateFaxEmailEntityTypeEnums.AddressBookIndividual:
            case AutomatedTemplateFaxEmailEntityTypeEnums.AddressBookFacility:
                emailSubjectString = `IMEDVIEW: ${this.selectedAutomatedTemplate.Name}`;
                break;
            default:
                emailSubjectString = '';
                break;
        }
        return emailSubjectString;
    }

    populateEmailBody(): void {
        this.emailBody = `Below, please find a link to documents relevant to the subject matter.`;
        this.emailBody +=
            '<br/><br/>The link shown below will open simply in another browser tab to enable you to review and print the request on the subject claimant.  It does not require a log-in or password, and will not bring you to another site or program.  No executable files are necessary.';
        this.emailBody += '<br/><br/>Please click the link below to view the necessary documents.';
        this.emailBody += '<br/><br/>[ATTACHMENT LINK]';
        this.emailBody += '<br/><br/>[SIGNATURE BLOCK USER]';
    }

    closeFaxEmailModal(): void {
        this.fileToUpload = undefined;
        this.invalidEmails = false;
        this.emailId = null;
        this.emailSubject = '';
        this.emailBody = '';
        this.selectedAutomatedTemplate = undefined;
        this.selectedType = null;
        if (this.isNF10DocPopup || this.isSubpoenaDocPopup || this.isCompletedSubpoenaDocPopup || this.isAllSubpoenasDocPopup) {
            this.closeModal.emit(true);
        } else {
            this.closeModal.emit(false);
        }
        this.modalWrapperApi.close();
    }

    SendFax(): void {
        if (this.faxNumber === undefined || this.faxNumber === null || this.faxNumber === '') {
            this.notificationsService.error('Fax number is required.');
            setTimeout(() => (this.doubleClickIsDisabled = false));
            return;
        }

        const emailDTO: ICommunicationRequest = {
            Body: null,
            ImedClaimServiceId: this.entityTypeId === AutomatedTemplateFaxEmailEntityTypeEnums.ImedService ? this.entityId : null,
            IndividualId: this.entityTypeId === AutomatedTemplateFaxEmailEntityTypeEnums.AddressBookIndividual ? this.entityId : null,
            Recepient: null,
            Subject: null,
        };

        this.atFaxEmailService
            .faxDocuments(
                this.baseFaxEmailHelperService.getATEntityPath(
                    this.entityTypeId,
                    this.isNF10DocPopup,
                    this.isSubpoenaDocPopup,
                    this.isCompletedSubpoenaDocPopup,
                    this.isAllSubpoenasDocPopup,
                    this.replaceSignature,
                ),
                emailDTO,
                this.faxNumber,
                this.selectedAutomatedTemplate.Id,
                this.entityId,
            )
            .pipe(
                finalize(() => {
                    this.isProcessing = false;
                }),
            )
            .subscribe(
                () => {
                    this.communicationLogService.notifySubscribersOfNewLogs();
                    this.handleFaxEmailResponse();
                },
                () => {
                    this.notificationsService.error('Sending Fax failed.');
                    this.selectedType = FaxEmailEnum.EMAIL;
                },
            );
    }

    SendEmail(): void {
        this.setInvalidEmails();
        this.setInvalidCCEmails();
        this.setInvalidBCCEmails();
        if (!this.invalidEmails && !this.invalidCCEmails && !this.invalidBCCEmails) {
            this.isProcessing = true;
            const emailDTO: ICommunicationRequest = this.buildEmailDto();
            this.atFaxEmailService
                .emailDocuments(
                    this.baseFaxEmailHelperService.getATEntityPath(
                        this.entityTypeId,
                        this.isNF10DocPopup,
                        this.isSubpoenaDocPopup,
                        this.isCompletedSubpoenaDocPopup,
                        this.isAllSubpoenasDocPopup,
                        this.replaceSignature,
                    ),
                    emailDTO,
                    this.selectedAutomatedTemplate.Id,
                    this.entityId,
                )
                .pipe(
                    finalize(() => {
                        this.isProcessing = false;
                    }),
                )
                .subscribe(
                    () => {
                        this.communicationLogService.notifySubscribersOfNewLogs();
                        this.baseAttachmentService.requestRefresh();
                        this.handleDocumentEmailResponse();
                    },
                    () => {
                        this.notificationsService.error('Sending Email failed.');
                        this.selectedType = FaxEmailEnum.EMAIL;
                    },
                );
        } else {
            this.notificationsService.error('A valid email address must be provided.');
            setTimeout(() => {
                this.isProcessing = false;
            });
        }
    }

    uploadPdfAndHandleResponse(callback: (document) => void, isFax: boolean): void {
        this.setInvalidEmails();
        this.setInvalidCCEmails();
        this.setInvalidBCCEmails();
        if (
            (!this.invalidEmails && !this.invalidCCEmails && !this.invalidBCCEmails && !isFax && this.fileToUpload) ||
            (isFax && this.fileToUpload && !(this.faxNumber === undefined || this.faxNumber === null || this.faxNumber === ''))
        ) {
            this.isProcessing = true;
            this.baseAttachmentService
                .createAndUpload(
                    this.baseFaxEmailHelperService.getEntityType(this.entityTypeId),
                    this.entityId,
                    this.fileToUpload,
                    AttachmentTypeEnums.Automated_Templates,
                    this.selectedAutomatedTemplate.Id,
                )
                .pipe(
                    finalize(() => {
                        this.isProcessing = false;
                    }),
                )
                .subscribe((document) => {
                    const emailDTO: ICommunicationRequest = this.buildEmailDto();
                    emailDTO.DocumentId = document.Id;
                    callback.call(this, emailDTO);
                    this.closeFaxEmailModal();
                });
        } else {
            if (!isFax && (this.invalidEmails || this.invalidCCEmails || this.invalidBCCEmails)) {
                this.notificationsService.error('Please check emails in recipients, CC, and BCC fields. An invalid email(s) present.');
            } else if (isFax && (this.faxNumber === undefined || this.faxNumber === null || this.faxNumber === '')) {
                this.notificationsService.error('Fax number is required.');
            } else {
                this.notificationsService.error('Please upload a PDF.');
            }
            setTimeout(() => {
                this.isProcessing = false;
            });
        }
    }

    buildEmailDto(): ICommunicationRequest {
        return {
            BCC: this.bccEmails,
            Body: this.emailBody,
            CC: this.ccEmails,
            ImedClaimServiceId: this.entityTypeId === AutomatedTemplateFaxEmailEntityTypeEnums.ImedService ? this.entityId : null,
            ImedClaimId: this.entityTypeId === AutomatedTemplateFaxEmailEntityTypeEnums.ImedClaim ? this.entityId : null,
            IndividualId: this.entityTypeId === AutomatedTemplateFaxEmailEntityTypeEnums.AddressBookIndividual ? this.entityId : null,
            Recepient: this.emailId,
            SendAsAnAttachment: this.sendAsAttachment,
            Subject: this.emailSubject,
        };
    }

    sendEmailAfterUpload(emailDTO: ICommunicationRequest): void {
        this.atFaxEmailService
            .sendEmailWithAttachment(
                this.baseFaxEmailHelperService.getATEntityPath(
                    this.entityTypeId,
                    this.isNF10DocPopup,
                    this.isSubpoenaDocPopup,
                    this.isCompletedSubpoenaDocPopup,
                    this.isAllSubpoenasDocPopup,
                    this.replaceSignature,
                ),
                emailDTO,
            )
            .pipe(
                finalize(() => {
                    this.isProcessing = false;
                }),
            )
            .subscribe(
                () => {
                    this.communicationLogService.notifySubscribersOfNewLogs();
                    this.handleDocumentEmailResponse();
                    this.fileToUpload = null;
                },
                () => {
                    this.notificationsService.error('Sending Email failed.');
                    this.selectedType = FaxEmailEnum.EMAIL;
                },
            );
    }

    sendFaxAfterUpload(emailDTO: ICommunicationRequest): void {
        this.atFaxEmailService
            .faxDocumentsWithAttachment(
                this.baseFaxEmailHelperService.getATEntityPath(
                    this.entityTypeId,
                    this.isNF10DocPopup,
                    this.isSubpoenaDocPopup,
                    this.isCompletedSubpoenaDocPopup,
                    this.isAllSubpoenasDocPopup,
                    this.replaceSignature,
                ),
                emailDTO,
                this.faxNumber,
            )
            .pipe(
                finalize(() => {
                    this.isProcessing = false;
                }),
            )
            .subscribe(
                () => {
                    this.communicationLogService.notifySubscribersOfNewLogs();
                    this.handleFaxEmailResponse();
                    this.fileToUpload = null;
                },
                () => {
                    this.notificationsService.error('Sending Fax failed.');
                    this.selectedType = FaxEmailEnum.EMAIL;
                },
            );
    }

    downloadDocument = (): void => {
        this.isProcessing = true;

        this.atFaxEmailService
            .generateDocuments(
                this.baseFaxEmailHelperService.getATEntityPath(
                    this.entityTypeId,
                    this.isNF10DocPopup,
                    this.isSubpoenaDocPopup,
                    this.isCompletedSubpoenaDocPopup,
                    this.isAllSubpoenasDocPopup,
                    this.replaceSignature,
                ),
                this.selectedAutomatedTemplate.Id,
                this.entityId,
            )
            .pipe(
                finalize(() => {
                    this.isProcessing = false;
                }),
            )
            .subscribe(
                (data) => {
                    this.handleDocumentDownloadResponse(data.body as Blob, data.headers.get('X-File-Name') as string);
                    this.baseAttachmentService.requestRefresh();
                    this.closeFaxEmailModal();
                },
                () => {
                    this.notificationsService.error('Document Download failed.');
                    this.selectedType = FaxEmailEnum.EMAIL;
                },
            );
    };

    downloadAsDocX(): void {
        this.isProcessing = true;
        this.atFaxEmailService
            .generateDocumentsAsDocX(
                this.baseFaxEmailHelperService.getATEntityPath(
                    this.entityTypeId,
                    this.isNF10DocPopup,
                    this.isSubpoenaDocPopup,
                    this.isCompletedSubpoenaDocPopup,
                    this.isAllSubpoenasDocPopup,
                    this.replaceSignature,
                ),
                this.selectedAutomatedTemplate.Id,
                this.entityId,
            )
            .pipe(
                finalize(() => {
                    this.isProcessing = false;
                }),
            )
            .subscribe(
                (data) => {
                    this.handleDocumentDownloadResponse(data.body as Blob, data.headers.get('X-File-Name') as string);
                },
                () => {
                    this.notificationsService.error('Document Download failed.');

                    this.isProcessing = false;
                },
            );
    }

    handleDocumentEmailResponse(): void {
        this.modalWrapperApi.close();
        this.notificationsService.success('Email sent successfully');
        this.emailBody = '';
        this.emailSubject = '';
        this.emailId = '';
        this.selectedType = FaxEmailEnum.EMAIL;
        this.closeFaxEmailModal();
    }

    handleFaxEmailResponse(): void {
        this.sendFax.emit();
        this.faxNumber = '';
        this.modalWrapperApi.close();
        this.notificationsService.success('Fax sent successfully');
        this.selectedType = FaxEmailEnum.EMAIL;
        if (this.isNF10DocPopup || this.isSubpoenaDocPopup || this.isCompletedSubpoenaDocPopup || this.isAllSubpoenasDocPopup) {
            this.closeModal.emit(true);
        } else {
            this.closeModal.emit(false);
        }
    }

    getAutomatedTemplates(): void {
        switch (this.entityTypeId) {
            case AutomatedTemplateFaxEmailEntityTypeEnums.ImedClaim:
                this.automatedTemplateService.getByImedClaimId(this.imedClaimTypeId, this.entityId).subscribe((response) => {
                    this.automatedTemplates =  this.filterAutomatedTemplates = response;
                });

                this.getAllStates();
                break;
            case AutomatedTemplateFaxEmailEntityTypeEnums.ImedService:
                this.automatedTemplateService.getByImedClaimServiceId(this.entityId).subscribe((response) => {
                    this.automatedTemplates = this.filterAutomatedTemplates = response;
                });

                this.getAllStates();
                break;
            case AutomatedTemplateFaxEmailEntityTypeEnums.AddressBookIndividual:
                this.automatedTemplateService.getByIndividualId(this.entityId).subscribe((response) => {
                    this.automatedTemplates = response;
                });
                break;
            case AutomatedTemplateFaxEmailEntityTypeEnums.AddressBookFacility:
                this.automatedTemplateService.getByAddressBookId(this.entityId).subscribe((response) => {
                    this.automatedTemplates = response;
                });
                break;
            default:
                break;
        }
    }

    getAllStates(): void {
        this.stateService.getAll().subscribe((response) => {
            this.states = response.map((state) => {
               return new MultiselectItem({ Id: state.Id, Name: state.Name }, true);
            });
        });
    }
}
