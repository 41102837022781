import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IImedClaimAdditionalRecord } from "@model/interfaces/imed-claim-additional-record";
import { BaseService } from "@mt-ng2/base-service";

@Injectable({
    providedIn: 'root',
})
export class ImedClaimAdditionalRecordsService extends BaseService<IImedClaimAdditionalRecord> {
    constructor(public http: HttpClient) {
        super('/imedclaims/additional-records', http);
    }
}