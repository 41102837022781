import { Component, Input, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, AbstractControl, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize, tap } from 'rxjs/operators';
import { Observable, forkJoin, Subscription } from 'rxjs';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { EmailTemplateService } from '../emailtemplate.service';
import { IEmailTemplate } from '@model/interfaces/email-template';
import { EmailTemplateDynamicConfig } from '../email-template.dynamic-config';
import { IMergeField } from '@model/interfaces/merge-field';
import { MergeFieldService } from '../../common/services/mergefield.service';
import { MergeFieldTypeEnums, EmailTemplateTypes } from '../../common/constants/Enums';
import { IDynamicField, DynamicField, DynamicLabel, IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { IExpandableObject } from '@model/expandable-object';
import { EmailTemplateDynamicControlsPartial } from '@model/partials/email-template-partial.form-controls';
import { safeDetectChanges } from '../../common/cdr-safety/cdr-safety.library';
import { ServiceService } from '../../imed-claims/services/service.service';
import { IEmailTemplateService } from '@model/interfaces/email-template-service';
import { IService } from '@model/interfaces/service';
import { AttachmentTypeService } from '../../common/attachments/attachment-type.service';
import { IAttachmentType } from '@model/interfaces/attachment-type';
import { IEmailTemplateAttachment } from '@model/interfaces/email-template-attachment';
import { IAddressBookType } from '@model/interfaces/address-book-type';
import { AddressBookTypeService } from '../../address-books/addressbooktype.service';
import { IEmailTemplateAddressBookType } from '@model/interfaces/email-template-address-book-type';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-email-template-basic-info',
    templateUrl: './email-template-basic-info.component.html',
})
export class EmailTemplateBasicInfoComponent implements OnInit, OnDestroy {
    @Input() emailTemplate: IEmailTemplate;
    @Input() canEdit: boolean;

    isEditing: boolean;
    isHovered: boolean;
    config: IDynamicFormConfig;
    form: any;
    formFactory: EmailTemplateDynamicConfig<IEmailTemplate>;
    doubleClickIsDisabled = false;
    emailTemplateForm: UntypedFormGroup;
    bodyDynamicField: IDynamicField;
    allMergeFields: IMergeField[] = [];
    mergeFieldsToDisplay: IMergeField[] = [];
    mergeFieldsHeader = 'Merge Fields';
    controls: IExpandableObject;
    focusedControl: AbstractControl;
    mergeFieldTypes = MergeFieldTypeEnums;
    emailTemplateTypes = EmailTemplateTypes;
    serviceTypes: IService[];
    selectedServiceTypeIds = new UntypedFormControl([], []);
    mergeFieldTypeTo: MergeFieldTypeEnums;
    mergeFieldTypeSubject: MergeFieldTypeEnums;
    mergeFieldTypeBody: MergeFieldTypeEnums;
    mergeFieldTypeBoth: MergeFieldTypeEnums;

    attachmentTypes: IAttachmentType[];
    addressBookTypes: IAddressBookType[];
    filteredAttachmentTypes: IAttachmentType[] = [];
    filteredCaseAttachmentTypes: IAttachmentType[] = [];
    selectedAttachmentTypeIds = new UntypedFormControl([], []);
    selectedAddressBookTypeIds = new UntypedFormControl([], []);

    subscription: Subscription = new Subscription();
    needsAttachmentLink = false;

    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    constructor(
        private emailTemplateService: EmailTemplateService,
        private notificationsService: NotificationsService,
        private router: Router,
        private mergeFieldService: MergeFieldService,
        private fb: UntypedFormBuilder,
        private cdr: ChangeDetectorRef,
        private serviceService: ServiceService,
        private attachmentTypeService: AttachmentTypeService,
        private addressBookTypeService: AddressBookTypeService,
    ) {}

    ngOnInit(): void {
        this.isEditing = false;
        this.emailTemplateForm = this.fb.group({
            EmailTemplate: this.fb.group({
                AddressBookTypeAssociations: this.selectedAddressBookTypeIds,
                AttachmentTypeAssociations: this.selectedAttachmentTypeIds,
                ServiceAssociations: this.selectedServiceTypeIds,
            }),
        });
        this.config = { formObject: [], viewOnly: [] };
        forkJoin([
            this.getMergeFields(),
            this.serviceService.getItems(),
            this.attachmentTypeService.getItems(),
            this.addressBookTypeService.getItems(),
        ]).subscribe(([_, services, attachmentTypes, addressBookTypes]) => {
            this.serviceTypes = services;
            this.attachmentTypes = attachmentTypes;
            this.addressBookTypes = addressBookTypes;
            this.filterAttachmentTypes();
            this.filterCaseAttachmentTypes();
            this.setConfig();
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    setConfig(): void {
        this.controls = this.createControls();
        this.formFactory = new EmailTemplateDynamicConfig<IEmailTemplate>(this.emailTemplate, null, null, [
            'Name',
            'To',
            'Cc',
            'Bcc',
            'Subject',
            'Body',
        ]);
        if (this.emailTemplate.Id === 0) {
            void Swal.fire({
                cancelButtonText: 'Service Email',
                confirmButtonText: 'Recruitment',
                denyButtonColor: 'purple',
                denyButtonText: 'Case Email',
                showCancelButton: true,
                showDenyButton: true,
                title: 'Select Email Template Type',
                width: '500px',
            }).then((answer) => {
                if (answer.isConfirmed) {
                    this.emailTemplate.EmailTemplateTypeId = EmailTemplateTypes.ADDRESS_BOOK;
                    setTimeout(() => {
                        this.emailTemplateForm.get('EmailTemplate.To').setValue('[INDIVIDUAL/INDIVIDUALS]');
                    });
                }
                if (answer.isDenied) {
                    this.emailTemplate.EmailTemplateTypeId = EmailTemplateTypes.CASE;
                }
                this.setSuggestedMergeFields(this.emailTemplate.EmailTemplateTypeId);
            });
            this.isEditing = true;
        } else {
            this.setSuggestedMergeFields(this.emailTemplate.EmailTemplateTypeId);
            this.config = this.formFactory.getForUpdate();
        }
        this.subscribeToSelectedServiceTypes();
        this.subscribeToAttachmentTypes();

        this.viewOnly = this.config?.viewOnly?.map((x) => new DynamicLabel(x));
        this.formObject = this.config.formObject?.map((x) => new DynamicField(x));
    }

    setSuggestedMergeFields(emailTemplateType: EmailTemplateTypes): void {
        if (emailTemplateType === EmailTemplateTypes.ADDRESS_BOOK) {
            this.mergeFieldTypeTo = MergeFieldTypeEnums.ADDRESS_BOOK_EMAIL_ADDRESS;
            this.mergeFieldTypeSubject = MergeFieldTypeEnums.ADDRESS_BOOK_EMAIL_SUBJECT;
            this.mergeFieldTypeBody = MergeFieldTypeEnums.ADDRESS_BOOK_EMAIL_BODY;
            this.mergeFieldTypeBoth = MergeFieldTypeEnums.ADDRESS_BOOK_EMAIL_BOTH;
        } else if (emailTemplateType === EmailTemplateTypes.SERVICE) {
            this.mergeFieldTypeTo = MergeFieldTypeEnums.EMAIL_ADDRESS;
            this.mergeFieldTypeSubject = MergeFieldTypeEnums.EMAIL_SUBJECT;
            this.mergeFieldTypeBody = MergeFieldTypeEnums.EMAIL_BODY;
            this.mergeFieldTypeBoth = MergeFieldTypeEnums.EMAIL_BOTH;
        } else {
            this.mergeFieldTypeTo = MergeFieldTypeEnums.CASE_EMAIL_ADDRESS;
            this.mergeFieldTypeSubject = MergeFieldTypeEnums.CASE_EMAIL_SUBJECT;
            this.mergeFieldTypeBody = MergeFieldTypeEnums.CASE_EMAIL_BODY;
            this.mergeFieldTypeBoth = MergeFieldTypeEnums.CASE_EMAIL_BOTH;
        }
    }

    createControls(): IExpandableObject {
        return new EmailTemplateDynamicControlsPartial(this.emailTemplate).Form;
    }

    filterAttachmentTypes(): void {
        this.filteredAttachmentTypes = this.attachmentTypes.filter((at) => {
            return at.Services.some((s) => this.selectedServiceTypeIds.value.includes(s.Id));
        });
        safeDetectChanges(this.cdr);
    }

    filterCaseAttachmentTypes(): void {
        this.filteredCaseAttachmentTypes = this.attachmentTypes.filter((at) => {
            return at.IsForClaims === true;
        });
        safeDetectChanges(this.cdr);
    }

    subscribeToSelectedServiceTypes(): void {
        this.subscription.add(
            this.selectedServiceTypeIds.valueChanges.subscribe(() => {
                // Filter all attachment types
                this.filterAttachmentTypes();
                // Filter selected attachment types
                this.emailTemplate.EmailTemplateAttachments = this.emailTemplate.EmailTemplateAttachments.filter((eta) =>
                    this.filteredAttachmentTypes.some((at) => at.Id === eta.AttachmentTypeId),
                );
            }),
        );
    }

    subscribeToAttachmentTypes(): void {
        this.subscription.add(
            this.selectedAttachmentTypeIds.valueChanges.subscribe((types) => {
                const value = this.emailTemplateForm.get('EmailTemplate.Body').value;
                if (types.length) {
                    this.needsAttachmentLink = !value.includes('[ATTACHMENT LINK]');
                } else {
                    this.needsAttachmentLink = false;
                }
            }),
        );
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    getMergeFields(): Observable<IMergeField[]> {
        return this.mergeFieldService.getAll().pipe(
            tap((fields) => {
                this.allMergeFields = fields;
            }),
        );
    }

    cancelClick(): void {
        if (this.emailTemplate.Id === 0) {
            void this.router.navigate(['/email-templates']);
        } else {
            this.isEditing = false;
            this.clearFocus();
        }
    }

    checkForAttachmentLink(value: string): void {
        if (this.selectedAttachmentTypeIds.value > 0) {
            this.needsAttachmentLink = !value.includes('[ATTACHMENT LINK]');
        } else {
            this.needsAttachmentLink = false;
        }
    }

    buildEmailTemplateForSave(): void {
        this.formFactory.assignFormValues(this.emailTemplate, this.emailTemplateForm.value.EmailTemplate as IEmailTemplate);
        this.emailTemplate.EmailTemplateServices = this.emailTemplateForm.get('EmailTemplate.ServiceAssociations').value.map((id) => {
            const existingAssociation = this.emailTemplate.EmailTemplateServices.find((ets) => ets.ServiceId === id);
            return {
                EmailTemplateId: this.emailTemplate.Id,
                Id: existingAssociation ? existingAssociation.Id : 0,
                ServiceId: id,
            } as IEmailTemplateService;
        });
        this.emailTemplate.EmailTemplateAttachments = this.emailTemplateForm.get('EmailTemplate.AttachmentTypeAssociations').value.map((id) => {
            const existingAssociation = this.emailTemplate.EmailTemplateAttachments.find((eta) => eta.AttachmentTypeId === id);
            return {
                AttachmentTypeId: id,
                EmailTemplateId: this.emailTemplate.Id,
                Id: existingAssociation ? existingAssociation.Id : 0,
            } as IEmailTemplateAttachment;
        });
        this.emailTemplate.EmailTemplateAddressBookTypes = this.emailTemplateForm.get('EmailTemplate.AddressBookTypeAssociations').value.map((id) => {
            const existingAssociation = this.emailTemplate.EmailTemplateAddressBookTypes.find((eta) => eta.AddressBookTypeId === id);
            return {
                AddressBookTypeId: id,
                EmailTemplateId: this.emailTemplate.Id,
                Id: existingAssociation ? existingAssociation.Id : 0,
            } as IEmailTemplateAddressBookType;
        });
    }

    formSubmitted(): void {
        if (this.emailTemplateForm.valid) {
            this.buildEmailTemplateForSave();
            this.emailTemplate.Body = this.emailTemplate.Body.replace(/[^(\x20-\x7F)]*/g, '');
            if (!this.emailTemplate.Id || this.emailTemplate.Id === 0) {
                // handle new emailTemplate save
                this.emailTemplateService.createWithFks(this.emailTemplate).subscribe((answer) => {
                    void this.router.navigate(['/email-templates/' + answer]);
                    this.success();
                    this.emailTemplateService.emitChange(this.emailTemplate);
                });
            } else {
                // handle existing emailTemplate save
                this.emailTemplateService.updateWithFks(this.emailTemplate).subscribe(() => {
                    this.isEditing = false;
                    this.success();
                    this.emailTemplateService.emitChange(this.emailTemplate);
                    // Set associate the service type to email template services so template displays properly
                    this.emailTemplate.EmailTemplateServices.forEach((ets) => (ets.Service = this.serviceTypes.find((s) => s.Id === ets.ServiceId)));
                    this.emailTemplate.EmailTemplateAttachments.forEach(
                        (eta) => (eta.AttachmentType = this.attachmentTypes.find((a) => a.Id === eta.AttachmentTypeId)),
                    );
                    this.emailTemplate.EmailTemplateAddressBookTypes.forEach(
                        (eta) => (eta.AddressBookType = this.addressBookTypes.find((a) => a.Id === eta.AddressBookTypeId)),
                    );
                    this.setConfig();
                });
            }
        } else {
            markAllFormFieldsAsTouched(this.emailTemplateForm);
            this.error();
        }
    }

    error(): void {
        this.notificationsService.error('Save failed.  Please check the form and try again.');
    }

    success(): void {
        this.notificationsService.success('Email Template saved successfully.');
    }

    clearFocus(): void {
        this.focusedControl = null;
        this.mergeFieldsToDisplay = [];
        this.mergeFieldsHeader = 'Merge Fields';
    }

    controlFocused(control: DynamicField, mergefieldTypes: number[], mergeFieldHeader: string): void {
        this.focusedControl = this.emailTemplateForm.get(`${control.formGroup}.${control.label}`);
        this.mergeFieldsToDisplay = this.allMergeFields
            .filter((field) => mergefieldTypes.indexOf(field.MergeFieldTypeId) > -1)
            ?.sort((a, b) => (a.Name < b.Name ? -1 : 1));
        this.mergeFieldsHeader = `${mergeFieldHeader} Merge Fields`;
        safeDetectChanges(this.cdr);
    }

    appendMergeField(field: string): void {
        this.focusedControl.setValue(this.focusedControl.value + ' ' + field);
    }
}
