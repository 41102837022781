import { AddressBookService } from './../addressbook.service';
import { Component, Input, ViewChild } from '@angular/core';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { UserService } from '../../users/user.service';
import { environment } from '../../environments/environment';
import { FileItem } from 'ng2-file-upload';
import { AttachmentControlComponent } from '../../common/attachment-control/attachment-control.component';
import { IIndividual } from '@model/interfaces/individual';
import { IndividualService } from '../individuals/individual.service';

@Component({
    selector: 'app-wetsignature-upload',
    templateUrl: './wetsignature-upload-component.html',
})
export class WetSignatureUploadComponent {
    @Input() individual: any;
    @Input() canUploadWetSignature: any;
    @Input() canEdit: any;
    @ViewChild('docComponent') docComponent: AttachmentControlComponent;

    isHovered: boolean;
    isEditing: boolean;
    errorMessage: string;
    imagePath: string;
    croppedFile: any;

    constructor(
        private notificationsService: NotificationsService,
        private userService: UserService,
        private individualService: IndividualService,
    ) {}

    ngOnInit(): void {
        this.imagePath = environment.imgPath;
        this.isEditing = false;
    }

    edit(): void {
        if (this.canEdit && this.canUploadWetSignature) {
            this.isEditing = true;
        }
    }

    savePhoto(): any {
        const file: FileItem = this.docComponent.uploader.queue[this.docComponent.uploader.queue.length - 1];
        if (file) {
            this.individualService.saveWetSignaturePhoto(this.individual.Id as number, file._file).subscribe((answer) => {
                this.individual.Image = answer.Image;
                this.individual.WetSignatureImageId = answer.Image.Id;
                this.isEditing = false;
                this.notificationsService.success('Wet Signature Image Saved Successfully');
            });
        }
    }

    deletePhoto(): void {
        this.individualService.deleteWetSignaturePhoto(this.individual.Id as number).subscribe((answer) => {
            this.individual.Image = null;
            this.individual.WetSignatureImageId = null;
            this.isEditing = false;
            this.notificationsService.success('Wet Signature Image Deleted Successfully');
        });
    }
}
