import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IBillDeskReviewService } from '../interfaces/bill-desk-review-service';
import { IUser } from '../interfaces/user';
import { IImedClaimService } from '../interfaces/imed-claim-service';

export interface IBillDeskReviewServiceDynamicControlsParameters {
    formGroup?: string;
    imedClaimServices?: IImedClaimService[];
    createdBies?: IUser[];
    modifiedBies?: IUser[];
    requestDateChangedBies?: IUser[];
}

export class BillDeskReviewServiceDynamicControls {

    formGroup: string;
    imedClaimServices: IImedClaimService[];
    createdBies: IUser[];
    modifiedBies: IUser[];
    requestDateChangedBies: IUser[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private billdeskreviewservice?: IBillDeskReviewService, additionalParameters?: IBillDeskReviewServiceDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'BillDeskReviewService';
        this.imedClaimServices = additionalParameters && additionalParameters.imedClaimServices || undefined;
        this.createdBies = additionalParameters && additionalParameters.createdBies || undefined;
        this.modifiedBies = additionalParameters && additionalParameters.modifiedBies || undefined;
        this.requestDateChangedBies = additionalParameters && additionalParameters.requestDateChangedBies || undefined;

        this.Form = {
            Archived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived',
                name: 'Archived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.billdeskreviewservice && this.billdeskreviewservice.hasOwnProperty('Archived') && this.billdeskreviewservice.Archived !== null ? this.billdeskreviewservice.Archived : false,
            }),
            CreatedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Created By',
                name: 'CreatedById',
                options: this.createdBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.billdeskreviewservice && this.billdeskreviewservice.CreatedById || null,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.billdeskreviewservice && this.billdeskreviewservice.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.billdeskreviewservice && this.billdeskreviewservice.DateModified || null,
            }),
            HasPreLitigation: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Pre Litigation',
                name: 'HasPreLitigation',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.billdeskreviewservice && this.billdeskreviewservice.hasOwnProperty('HasPreLitigation') && this.billdeskreviewservice.HasPreLitigation !== null ? this.billdeskreviewservice.HasPreLitigation : false,
            }),
            ImedClaimServiceId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Imed Claim Service',
                name: 'ImedClaimServiceId',
                options: this.imedClaimServices,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.billdeskreviewservice && this.billdeskreviewservice.ImedClaimServiceId || null,
            }),
            IsFederalCase: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Federal Case',
                name: 'IsFederalCase',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.billdeskreviewservice && this.billdeskreviewservice.hasOwnProperty('IsFederalCase') && this.billdeskreviewservice.IsFederalCase !== null ? this.billdeskreviewservice.IsFederalCase : false,
            }),
            LegacyImedClaimServiceId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Legacy Imed Claim Service',
                name: 'LegacyImedClaimServiceId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(10) ],
                validators: { 'maxlength': 10 },
                value: this.billdeskreviewservice && this.billdeskreviewservice.hasOwnProperty('LegacyImedClaimServiceId') && this.billdeskreviewservice.LegacyImedClaimServiceId !== null ? this.billdeskreviewservice.LegacyImedClaimServiceId.toString() : '',
            }),
            ModifiedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Modified By',
                name: 'ModifiedById',
                options: this.modifiedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.billdeskreviewservice && this.billdeskreviewservice.ModifiedById || null,
            }),
            RequestDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Request Date',
                name: 'RequestDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.billdeskreviewservice && this.billdeskreviewservice.RequestDate || null,
            }),
            RequestDateChangedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Request Date Changed By',
                name: 'RequestDateChangedById',
                options: this.requestDateChangedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.billdeskreviewservice && this.billdeskreviewservice.RequestDateChangedById || null,
            }),
            RequestDateChangedDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Request Date Changed Date',
                name: 'RequestDateChangedDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.billdeskreviewservice && this.billdeskreviewservice.RequestDateChangedDate || null,
            }),
        };

        this.View = {
            Archived: new DynamicLabel({
			    label: 'Archived',
			    value: this.billdeskreviewservice && this.billdeskreviewservice.hasOwnProperty('Archived') && this.billdeskreviewservice.Archived !== null ? this.billdeskreviewservice.Archived : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            CreatedById: new DynamicLabel({
			    label: 'Created By',
			    value: getMetaItemValue(this.createdBies as unknown as IMetaItem[], this.billdeskreviewservice && this.billdeskreviewservice.hasOwnProperty('CreatedById') && this.billdeskreviewservice.CreatedById !== null ? this.billdeskreviewservice.CreatedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            DateCreated: new DynamicLabel({
			    label: 'Date Created',
			    value: this.billdeskreviewservice && this.billdeskreviewservice.DateCreated || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DateModified: new DynamicLabel({
			    label: 'Date Modified',
			    value: this.billdeskreviewservice && this.billdeskreviewservice.DateModified || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            HasPreLitigation: new DynamicLabel({
			    label: 'Has Pre Litigation',
			    value: this.billdeskreviewservice && this.billdeskreviewservice.hasOwnProperty('HasPreLitigation') && this.billdeskreviewservice.HasPreLitigation !== null ? this.billdeskreviewservice.HasPreLitigation : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ImedClaimServiceId: new DynamicLabel({
			    label: 'Imed Claim Service',
			    value: getMetaItemValue(this.imedClaimServices as unknown as IMetaItem[], this.billdeskreviewservice && this.billdeskreviewservice.hasOwnProperty('ImedClaimServiceId') && this.billdeskreviewservice.ImedClaimServiceId !== null ? this.billdeskreviewservice.ImedClaimServiceId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            IsFederalCase: new DynamicLabel({
			    label: 'Is Federal Case',
			    value: this.billdeskreviewservice && this.billdeskreviewservice.hasOwnProperty('IsFederalCase') && this.billdeskreviewservice.IsFederalCase !== null ? this.billdeskreviewservice.IsFederalCase : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            LegacyImedClaimServiceId: new DynamicLabel({
			    label: 'Legacy Imed Claim Service',
			    value: this.billdeskreviewservice && this.billdeskreviewservice.hasOwnProperty('LegacyImedClaimServiceId') && this.billdeskreviewservice.LegacyImedClaimServiceId !== null ? this.billdeskreviewservice.LegacyImedClaimServiceId.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ModifiedById: new DynamicLabel({
			    label: 'Modified By',
			    value: getMetaItemValue(this.modifiedBies as unknown as IMetaItem[], this.billdeskreviewservice && this.billdeskreviewservice.hasOwnProperty('ModifiedById') && this.billdeskreviewservice.ModifiedById !== null ? this.billdeskreviewservice.ModifiedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            RequestDate: new DynamicLabel({
			    label: 'Request Date',
			    value: this.billdeskreviewservice && this.billdeskreviewservice.RequestDate || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            RequestDateChangedById: new DynamicLabel({
			    label: 'Request Date Changed By',
			    value: getMetaItemValue(this.requestDateChangedBies as unknown as IMetaItem[], this.billdeskreviewservice && this.billdeskreviewservice.hasOwnProperty('RequestDateChangedById') && this.billdeskreviewservice.RequestDateChangedById !== null ? this.billdeskreviewservice.RequestDateChangedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            RequestDateChangedDate: new DynamicLabel({
			    label: 'Request Date Changed Date',
			    value: this.billdeskreviewservice && this.billdeskreviewservice.RequestDateChangedDate || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
        };

    }
}
