import { Component } from '@angular/core';
import { IEntityListDynamicCellComponent, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { IEntity } from '@mt-ng2/base-service';
import { IViewGetServicesForList } from '@model/interfaces/custom/view-get-services-for-list';
import { ExtraSearchParams, IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { CommonSearchService, ICommonSearch } from '../../../common/services/common-search.service';
import { Router } from '@angular/router';
import { OtherServiceTypeEnums } from './../../../common/constants/Enums';

@Component({
    template: `
        <div *ngIf="imedClaimService">
            <div *ngIf="imedClaimService.HasTSServices"><a (click)="search(otherServiceTypeEnums.TranslationServices.toString())">TS</a></div>
            <div *ngIf="imedClaimService.HasCRSServices"><a (click)="search(otherServiceTypeEnums.CRS.toString())">C</a></div>
            <div *ngIf="imedClaimService.HasRetrievalServices"><a (click)="search(otherServiceTypeEnums.Retrievals.toString())">R</a></div>
            <div *ngIf="imedClaimService.HasReviewServices"><a (click)="search(otherServiceTypeEnums.Examination.toString())">I</a></div>
        </div>
    `,
})
export class OtherServiceDynamicCellComponent implements IEntityListDynamicCellComponent {
    otherServiceTypeEnums = OtherServiceTypeEnums;
    entityListComponentMembers: IEntityListComponentMembers;
    set entity(value: IEntity) {
        this.imedClaimService = value as IViewGetServicesForList;
    }

    imedClaimService: IViewGetServicesForList;

    constructor(private commonSearchService: CommonSearchService, private router: Router) {}

    search(type: string): void {
        const _extraSearchParams: ExtraSearchParams[] = [];
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'includeArchived',
                value: '0',
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'OtherServiceType',
                value: type,
            }),
        );

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            query: '',
        };

        const searchFilter: ICommonSearch = {
            searchFilters: new SearchParams(searchEntity),
        };

        this.commonSearchService.setCommonSearchFilters(searchFilter);
        void this.router.navigate([`/redirect/${this.imedClaimService.ImedClaimId}`]);
    }
}
