import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { MasterFollowUpTypeService } from '../services/master-followup-type.service';
import { IMasterFollowupType } from '@model/interfaces/master-followup-type';

@Component({
    templateUrl: './master-followup-type-header.component.html',
})
export class MasterFollowupTypeHeaderComponent implements OnInit {
    masterFollowUpType: IMasterFollowupType;
    header$: Observable<string>;

    readonly masterFollowUpTypeService = inject(MasterFollowUpTypeService);
    readonly route = inject(ActivatedRoute);

    ngOnInit(): void {
        // get the id from the route
        const id = +this.route.snapshot.paramMap.get('followupTypeId');
        this.masterFollowUpTypeService.setInitialTitle(id);
        this.header$ = this.masterFollowUpTypeService.title$;
    }
}
