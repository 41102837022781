import { CommunicationLogService } from '../../../../common/services/communication-log.service';
import { ICommunicationLog } from '@model/interfaces/communication-log';
import { CommunicationLogEntityListConfig } from './communication-logs.entity-list-config';
import { entityListModuleConfig } from '../../../../common/shared.module';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import 'rxjs/operators';
import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { CommunicationLogTypeEnums } from '../../../../common/constants/Enums';
import { ICommunicationLogResponse } from '@model/interfaces/custom/communication-request';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-communication-logs',
    templateUrl: './communication-logs.component.html',
})
export class CommunicationLogsComponent implements OnInit {
    searchControl = new UntypedFormControl();
    communicationLogResponse: ICommunicationLogResponse[];
    currentPage = 1;
    query = '';
    total: number;
    entityListConfig = new CommunicationLogEntityListConfig();
    order = 'Id';
    orderDirection = 'desc';
    id: string;
    imedClaimId: string;
    serviceId: string;
    taskId: string;
    individualId: string;
    addressBookId: string;
    statuses: MtSearchFilterItem[] = [];
    logType: CommunicationLogTypeEnums;
    subscription: Subscription = new Subscription();

    constructor(private communicationLogService: CommunicationLogService, private activatedRoute: ActivatedRoute, private router: Router) { }

    ngOnInit(): void {
        this.setCommunicationLogTypeAndSearch();
        this.subscription.add(this.communicationLogService.emitLogChange.subscribe(() => this.getCommunicationLogs()));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    setCommunicationLogTypeAndSearch(): void {
        // Set email log type for search
        this.activatedRoute.data.subscribe((data) => {
            this.logType = data.logType;
            if (this.logType === CommunicationLogTypeEnums.IMED_CLAIM_SERVICE) {
                this.imedClaimId = this.activatedRoute.snapshot.parent.parent.paramMap.get('imedClaimId');
                this.serviceId = this.activatedRoute.snapshot.parent.paramMap.get('serviceId');
            }
            if (this.logType === CommunicationLogTypeEnums.CASE) {
                this.imedClaimId = this.activatedRoute.snapshot.paramMap.get('imedClaimId');
            }
            if (this.logType === CommunicationLogTypeEnums.TASK) {
                this.taskId = this.activatedRoute.snapshot.paramMap.get('taskId');
            }
            if (this.logType === CommunicationLogTypeEnums.INDIVIDUAL) {
                this.individualId = this.activatedRoute.snapshot.parent.paramMap.get('individualId');
                this.addressBookId = this.activatedRoute.snapshot.parent.parent.paramMap.get('addressBookId');
            }
            this.getCommunicationLogs();
        });
    }

    getCommunicationLogs(): void {
        const search = this.query;
        let routeType = '';
        const _extraSearchParams: ExtraSearchParams[] = [];
        if (this.logType === CommunicationLogTypeEnums.IMED_CLAIM_SERVICE) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'imedClaimServiceId',
                    value: this.serviceId,
                }),
            );
            routeType = 'imedclaimservice';
        }
        if (this.logType === CommunicationLogTypeEnums.TASK) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'taskId',
                    value: this.taskId,
                }),
            );
            routeType = 'task';
        }
        if (this.logType === CommunicationLogTypeEnums.INDIVIDUAL) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'individualId',
                    value: this.individualId,
                }),
            );
            routeType = 'individual';
        }
        if (this.logType === CommunicationLogTypeEnums.CASE) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'imedClaimId',
                    value: this.imedClaimId,
                }),
            );
            routeType = 'imed-claim';
        }
        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: entityListModuleConfig.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);

        this.communicationLogService.searchLogs(searchparams, routeType).subscribe((answer) => {
            this.communicationLogResponse = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.query = query;
        this.currentPage = 1;
        this.getCommunicationLogs();
    }

    cancelClick(): void {
        if (this.logType === CommunicationLogTypeEnums.IMED_CLAIM_SERVICE) {
            void this.router.navigate([`cases/${this.imedClaimId}/services/${this.serviceId}`]);
        }
        if (this.logType === CommunicationLogTypeEnums.CASE) {
            void this.router.navigate([`cases/${this.imedClaimId}`]);
        }
        if (this.logType === CommunicationLogTypeEnums.TASK) {
            void this.router.navigate([`tasks/${this.taskId}`]);
        }
        if (this.logType === CommunicationLogTypeEnums.INDIVIDUAL) {
            void this.router.navigate([`address-books/${this.addressBookId}/individuals/${this.individualId}`]);
        }
    }
}
