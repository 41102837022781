import { TaskService } from './../../tasks/task.service';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ITask } from '@model/interfaces/task';
import { Subject, forkJoin, Subscription } from 'rxjs';
import { IUser } from '@model/interfaces/user';
import { UntypedFormGroup } from '@angular/forms';
import { TaskDynamicConfig } from '../../tasks/task.dynamic-config';
import { MultiselectItem, ISelectionChangedEvent } from '@mt-ng2/multiselect-control';
import { ITaskStatus } from '@model/interfaces/task-status';
import { TaskDynamicControlsPartial } from '@model/partials/task-partial.form-controls';
import { ITaskDynamicControlsParameters } from '@model/form-controls/task.form-controls';
import { ITaskAssignedUserRole } from '@model/interfaces/task-assigned-user-role';
import { ITaskAssignedUser } from '@model/interfaces/task-assigned-user';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { UserService } from '../../users/user.service';
import { UserRoleService } from '../../user-roles/user-role.service';
import { ClaimsService, AuthService, ClaimValues } from '@mt-ng2/auth-module';
import { TaskStatusService } from '../../tasks/taskstatus.service';
import { TaskStatusEnums, UserRoleIdEnums } from '../../common/constants/Enums';
import { IImedClaimPartial } from '@model/partials/imed-claim-partial';
import { ClaimTypes } from '@model/ClaimTypes';
import { MetaItem } from '@mt-ng2/base-service';
import { Router } from '@angular/router';
import { ITaskDTO } from '@model/interfaces/custom/task-dto';
import { UserTypeValues } from '../../../client-portal/common/constants/user-type.enum';
import { AssignedToTypes } from '../../../client-portal/common/constants/assigned-to-type.enum';
import { IDynamicFormConfig } from '@mt-ng2/dynamic-form';

@Component({
    selector: 'app-imed-claim-tasks',
    templateUrl: './imed-claim-task.component.html',
})
export class ImedClaimTaskComponent implements OnInit, OnDestroy {
    @Input('claimId') claimId: number;
    @Input('imedClaim') imedClaim: IImedClaimPartial;

    tasks: ITaskDTO[];
    isAdd = false;
    isEditing = false;
    canEdit = false;
    task: ITask;
    taskDTO: ITaskDTO;
    editingComponent: Subject<any> = new Subject();
    canAdd: boolean;
    id: number;
    users: IUser[];
    isHovered: boolean;
    config: IDynamicFormConfig;
    taskForm = new UntypedFormGroup({ Task: new UntypedFormGroup({}) });
    formFactory: TaskDynamicConfig<ITask>;
    doubleClickIsDisabled = false;
    controls: any;
    usersSelectItems: MultiselectItem[];
    userRolesSelectItems: MultiselectItem[];
    taskStatuses: ITaskStatus[];
    selectedUserRoles: MetaItem[];
    selectedUsers: MetaItem[];
    selectedAssignedToTypeId: number;
    claims: any[];
    // Removing at imedviews request
    // dueDateUpperBound: any;
    isLoading = false;
    selectedTask: ITask;
    selectedUser: IUser;
    userRoleId: number;
    userSubscription: Subscription;
    allUsers: IUser[] = [];
    clientUser: number = UserTypeValues.Client;
    selectedUserTypeId: number;

    constructor(
        private taskService: TaskService,
        private notificationsService: NotificationsService,
        private userService: UserService,
        private userRoleService: UserRoleService,
        private claimsService: ClaimsService,
        private taskStatusService: TaskStatusService,
        private authService: AuthService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        // get the tasks for the given claim
        this.getTaskForClaimId();
        this.subscribeToUsers();
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.Tasks, [ClaimValues.FullAccess]);
    }

    ngOnDestroy(): void {
        this.userSubscription.unsubscribe();
    }

    subscribeToUsers(): void {
        this.userSubscription = this.userService.getAll().subscribe((users) => {
            this.allUsers = users;
            this.selectedUser = this.allUsers.filter((x) => x.Id === this.authService.currentUser.getValue().Id)[0];
        });
    }

    getTaskForClaimId(): void {
        this.taskService.getTasksForClaim(this.claimId).subscribe((result) => {
            this.tasks = result;
        });
    }

    emitValue(): void {
        this.isAdd = false;
        this.isEditing = false;
        this.getTaskForClaimId();
    }

    editTask(taskId: number): void {
        if (!this.canEdit) {
            return;
        }
        this.isLoading = false;
        this.isEditing = true;
        // get current id from route
        this.id = taskId;
        forkJoin(
            this.userRoleService.getAll(),
            this.taskStatusService.getAll(),
            this.taskService.getTaskById(this.id),
            this.userService.getRoleId(this.authService.currentUser.getValue().Id),
        ).subscribe((responses) => {
            const [userRoles, taskStatus, taskDTO, roleId] = responses;
            this.taskStatuses = taskStatus.filter((x) => x.Id !== +TaskStatusEnums.CompletedBySystem);
            this.claims = [];
            this.taskDTO = taskDTO;
            this.userRoleId = roleId;
            this.selectedTask = this.taskService.mapDTOtoTask(taskDTO);
            this.selectedUserTypeId = taskDTO.UserTypeId;

            // Removing at imedviews request
            // this.dueDateUpperBound = this.selectedTask.DueDate;
            this.selectedUsers = [];
            this.selectedUserRoles = [];
            this.selectedUsers = this.taskDTO.TaskUsers.map((item) => new MetaItem(item.UserId, `${item.FirstName} ${item.LastName}`));
            this.selectedUserRoles = this.taskDTO.TaskUserRoles.map((item) => new MetaItem(item.UserRoleId, item.Name));
            this.usersSelectItems = this.allUsers
                .filter((u) => !u.Archived)
                .map(
                    (item) =>
                        new MultiselectItem(
                            new MetaItem(item.Id, `${item.FirstName} ${item.LastName}`),
                            this.taskDTO.TaskUsers.some((user) => user.UserId === item.Id),
                        ),
                );
            this.userRolesSelectItems = userRoles.map(
                (item) =>
                    new MultiselectItem(
                        new MetaItem(item.Id, item.Name),
                        this.taskDTO.TaskUserRoles.some((userRole) => userRole.UserRoleId === item.Id),
                    ),
            );
            this.config = { formObject: [], viewOnly: [] };
            this.setConfig();
        });
    }

    setConfig(): void {
        this.controls = new TaskDynamicControlsPartial(this.selectedTask, {
            imedClaims: this.claims,
            taskStatuses: this.taskStatuses,
        } as ITaskDynamicControlsParameters).Form;
        this.formFactory = new TaskDynamicConfig<ITask>(this.selectedTask, [], []);

        this.isLoading = true;
    }

    cancelClick(): void {
        this.isEditing = false;
        this.isAdd = false;
    }

    completeTask(task: ITaskDTO, event: Event): void {
        if (event && event.stopPropagation) {
            event.stopPropagation();
        }

        if (task.IsAccountingTask) {
            if (this.userRoleId !== +UserRoleIdEnums.Accounting && this.userRoleId !== +UserRoleIdEnums.Admin) {
                this.notificationsService.error('Accounting Tasks can only be completed by Accounting or Admin');
                return;
            }
        } else if (!this.taskService.canCompleteTask(task, this.selectedUser.AuthUser.RoleId, this.selectedUser)) {
            return;
        }

        task.TaskStatusId = TaskStatusEnums.Completed;
        this.taskService.completeTask(this.authService.currentUser.getValue().Id, task).subscribe(() => {
            this.notificationsService.success('Task Status Updated');
            this.getTaskForClaimId();
        });
    }

    formSubmitted(): void {
        const form = this.taskForm;
        this.selectedTask.AssignedToTypeId = this.selectedAssignedToTypeId;
        if (form.valid && this.validateAssignedTypes(form)) {
            this.formFactory.assignFormValues(this.selectedTask, form.value.Task as ITask);
            if (this.selectedTask.AssignedToTypeId === +AssignedToTypes.UserRoles) {
                this.selectedTask.TaskAssignedUsers = [];
                this.selectedTask.TaskAssignedUserRoles = this.selectedUserRoles.map(
                    (UserRole) =>
                        <ITaskAssignedUserRole>{
                            Id: 0,
                            Task: null,
                            TaskId: this.selectedTask.Id,
                            UserRole: null,
                            UserRoleId: UserRole.Id,
                        },
                );
            } else {
                this.selectedTask.TaskAssignedUserRoles = [];
                this.selectedTask.TaskAssignedUsers = this.selectedUsers.map(
                    (User) =>
                        <ITaskAssignedUser>{
                            Id: 0,
                            Task: null,
                            TaskId: this.selectedTask.Id,
                            User: null,
                            UserId: User.Id,
                        },
                );
            }

            if (
                this.selectedTask.TaskStatusId == (TaskStatusEnums.Completed as number) &&
                !this.selectedTask.CompletedById &&
                !this.taskDTO.IsAccountingTask
            ) {
                if (!this.taskService.canCompleteTask(this.taskDTO, this.selectedUser.AuthUser.RoleId, this.selectedUser)) {
                    return;
                }
            }

            this.taskService.updateTask(this.selectedTask).subscribe(() => {
                this.isEditing = false;
                this.success();
                this.getTaskForClaimId();
                this.setConfig();
            });
        } else {
            markAllFormFieldsAsTouched(form);
            this.error();
        }
    }
    userRoleSelectionChanged(event: ISelectionChangedEvent): void {
        this.selectedUserRoles = event.selectedItems;
    }

    userSelectionChanged(event: ISelectionChangedEvent): void {
        this.selectedUsers = event.selectedItems;
    }

    error(message = 'Save Failed'): void {
        this.notificationsService.error(message);
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }

    getValueToDisplayForAssignments(): string {
        let result = '';
        if (this.task.AssignedToTypeId === (AssignedToTypes.UserRoles as number)) {
            const userRoles = this.selectedUserRoles;
            result = userRoles
                .map((ur) => {
                    return ur.Name ? ur.Name : '';
                })
                .join(', ');
        } else {
            const users = this.selectedUsers;
            result = users
                .map((ur) => {
                    return ur.Name ? `${ur.Name}` : '';
                })
                .join(', ');
        }
        return result;
    }

    validateAssignedTypes(form): boolean {
        let isValid = false;
        if (form.value.Task.AssignedToTypeId === AssignedToTypes.UserRoles && this.selectedUserRoles.length !== 0) {
            isValid = true;
        }
        if (form.value.Task.AssignedToTypeId === AssignedToTypes.Users && this.selectedUsers.length !== 0) {
            isValid = true;
        }
        return isValid;
    }

    seeAll(): void {
        this.taskService.setClaimIdFromClaimDetail(this.claimId);
        void this.router.navigate(['../tasks'], { queryParams: { caseId: `${this.claimId}` } });
    }
}
