import { StateService } from '@app-shared/services/state.service';
import { CountyService } from '@app-shared/services/county.service';
import { AddressService } from './addresses/address.service';
import { AddressTypeService } from './addresses/addresstype.service';
import { PhysicianStatusesComponent } from './individuals/physicians/status/physician-statuses.component';
import { NgModule } from '@angular/core';
import { AddressBookRoutingModule } from './address-book-routing.module';
import { AddressBookDetailComponent } from './address-book-detail/address-book-detail.component';
import { AddressBooksComponent } from './address-book-list/address-books.component';
import { AddressBookHeaderComponent } from './address-book-header/address-book-header.component';
import { IndividualsComponent } from './individuals/individual-list/individuals.component';
import { IndividualHeaderComponent } from './individuals/individual-header/individual-header.component';
import { IndividualDetailComponent } from './individuals/individual-detail/individual-detail.component';
import { AddressBookIndividualBasicInfoComponent } from './shared/addressbook-individual-basic-info.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { PhoneTypeService } from './phonetype.service';
import { PhysicianService } from './individuals/physicians/physician.service';
import { PhysicianStatusService } from './individuals/physicians/physicianstatus.service';
import { PhysicianFollowupService } from './individuals/physicians/physician-followup-log.service';
import { DatePipe } from '../../../node_modules/@angular/common';
import { PhysicianFollowupsComponent } from './individuals/physicians/physician-followup-list/physician-followup.component';
import { PhysicianStatusLogsComponent } from './individuals/physicians/physician-status-list/physician-status-logs.component';
import { PhysicianStatusLogService } from './individuals/physicians/physician-status-log.service';
import { IndividualSpecialtiesComponent } from './individuals/individual-specialties/individual-specialties.component';
import { PhysicianRecruitmentsComponent } from './individuals/physicians/physician-recruitment/physician-recruitment.component';
import { PhysicianVacationBasicInfoComponent } from './individuals/physicians/physician-vacations/physician-vacation-basic-info/physician-vacation-basic-info.component';
import { PhysicianVacationDetailComponent } from './individuals/physicians/physician-vacations/physician-vacation-detail/physician-vacation-detail.component';
import { PhysicianVacationHeaderComponent } from './individuals/physicians/physician-vacations/physician-vacation-header/physician-vacation-header.component';
import { PhysicianVacationsComponent } from './individuals/physicians/physician-vacations/physician-vacation-list/physician-vacations.component';
import { PhysicianVacationService } from './individuals/physicians/physician-vacations/physicianvacation.service';
import { FileUploadModule } from 'ng2-file-upload';
import { ImedCommonModule } from '../common/imed-common.module';
import { LetterHeadUploadComponent } from './shared/letterhead-upload-component';
import { AddressBookDeleteDynamicCellComponent } from './address-book-list/address-book-delete-cell.component';
import { PhoneDeleteCellComponent } from './phone-delete-cell/phone-delete-cell.component';
import { AddressBookAddressesDynamicCellComponent } from './address-book-list/address-book-addresses-cell.component';
import { SharedModule } from '../common/shared.module';
import { EmailAddressDeleteCellComponent } from './email-address-delete-cell/email-address-delete-cell.component';
import { ExternalUserComponent } from './individuals/external-user/external-user.component';
import { SharedEmailsComponent } from './shared-emails/shared-emails.component';
import { AddressDeleteDynamicCellComponent } from './addresses/address-list/address-delete-dynamic-cell.component';
import { AvailableLocationsComponent } from './individuals/available-locations/available-locations.component';
import { SharedPhonesComponent } from './shared-phones/shared-phones.component';
import { CourtSealUploadComponent } from './shared/courtseal-upload-component';
import { CourtSubpoenaDocumentsComponent } from './court-subpoena-documents/court-subpoena-documents.component';
import { CourtSubpoenaDocumentTypeService } from './addresses/courtsubpoenadocumenttype.service';
import { CourtProcessServersComponent } from './court-process-server/court-process-server.component';
import { CourtProcessServerService } from './court-process-server/court-process-server.service';
import { AddressBookProcessServerComponent } from './address-book-process-server/address-book-process-server.component';
import { WetSignatureUploadComponent } from './shared/wetsignature-upload.component';
import { WetSignatureAddressbookUploadComponent } from './shared/wetsignature-addressbook-upload.component';
import { IndividualStrivenCreationModal } from './individuals/individual-striven-creation-modal/individual-striven-creation-modal.component';
import { AddressBookStrivenCreationModal } from './address-book-striven-creation-modal/address-book-striven-creation-modal.component';

@NgModule({
    declarations: [
        AddressBooksComponent,
        AddressBookHeaderComponent,
        AddressBookDetailComponent,
        IndividualsComponent,
        IndividualHeaderComponent,
        IndividualDetailComponent,
        IndividualSpecialtiesComponent,
        AddressBookIndividualBasicInfoComponent,
        PhysicianFollowupsComponent,
        PhysicianStatusesComponent,
        PhysicianStatusLogsComponent,
        PhysicianRecruitmentsComponent,
        PhysicianVacationBasicInfoComponent,
        PhysicianVacationDetailComponent,
        PhysicianVacationHeaderComponent,
        PhysicianVacationsComponent,
        LetterHeadUploadComponent,
        CourtSealUploadComponent,
        WetSignatureUploadComponent,
        WetSignatureAddressbookUploadComponent,
        CourtSubpoenaDocumentsComponent,
        AddressBookDeleteDynamicCellComponent,
        PhoneDeleteCellComponent,
        AddressBookAddressesDynamicCellComponent,
        EmailAddressDeleteCellComponent,
        ExternalUserComponent,
        SharedEmailsComponent,
        AddressDeleteDynamicCellComponent,
        AvailableLocationsComponent,
        SharedPhonesComponent,
        CourtProcessServersComponent,
        AddressBookProcessServerComponent,
        IndividualStrivenCreationModal,
        AddressBookStrivenCreationModal,
    ],
    imports: [ImedCommonModule, AddressBookRoutingModule, NgSelectModule, FileUploadModule, SharedModule],
})
export class AddressBookModule {
    static forRoot(): any {
        return {
            ngModule: AddressBookModule,
            providers: [
                PhoneTypeService,
                AddressTypeService,
                CourtSubpoenaDocumentTypeService,
                AddressService,
                StateService,
                CountyService,
                PhysicianService,
                PhysicianStatusService,
                PhysicianStatusLogService,
                PhysicianFollowupService,
                PhysicianVacationService,
                DatePipe,
                CourtProcessServerService,
            ],
        };
    }
}
