import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IConferenceCallServiceDateTime } from '../interfaces/conference-call-service-date-time';
import { IConferenceCallService } from '../interfaces/conference-call-service';

export interface IConferenceCallServiceDateTimeDynamicControlsParameters {
    formGroup?: string;
    conferenceCallServices?: IConferenceCallService[];
}

export class ConferenceCallServiceDateTimeDynamicControls {

    formGroup: string;
    conferenceCallServices: IConferenceCallService[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private conferencecallservicedatetime?: IConferenceCallServiceDateTime, additionalParameters?: IConferenceCallServiceDateTimeDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'ConferenceCallServiceDateTime';
        this.conferenceCallServices = additionalParameters && additionalParameters.conferenceCallServices || undefined;

        this.Form = {
            ConferenceCallServiceId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Conference Call Service',
                name: 'ConferenceCallServiceId',
                options: this.conferenceCallServices,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.conferencecallservicedatetime && this.conferencecallservicedatetime.ConferenceCallServiceId || null,
            }),
            DateTimeRequested: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Time Requested',
                name: 'DateTimeRequested',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.conferencecallservicedatetime && this.conferencecallservicedatetime.DateTimeRequested || null,
            }),
        };

        this.View = {
            ConferenceCallServiceId: new DynamicLabel({
			    label: 'Conference Call Service',
			    value: getMetaItemValue(this.conferenceCallServices as unknown as IMetaItem[], this.conferencecallservicedatetime && this.conferencecallservicedatetime.hasOwnProperty('ConferenceCallServiceId') && this.conferencecallservicedatetime.ConferenceCallServiceId !== null ? this.conferencecallservicedatetime.ConferenceCallServiceId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            DateTimeRequested: new DynamicLabel({
			    label: 'Date Time Requested',
			    value: this.conferencecallservicedatetime && this.conferencecallservicedatetime.DateTimeRequested || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
        };

    }
}
