import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Location } from '@angular/common';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { AddressService } from '../address.service';
import { IAddress } from '@model/interfaces/address';
import { AddressDynamicConfig } from '../address.dynamic-config';
import { AddressDynamicControlsPartial } from '@model/partials/address-partial.form-controls';
import { AddressTypeService } from '../addresstype.service';
import { IState } from '@model/interfaces/state';
import { IAddressType } from '@model/interfaces/address-type';
import { AuthService } from '../../../../../node_modules/@mt-ng2/auth-module';
import { StateService } from '@app-shared/services/state.service';
import { CountyService } from '@app-shared/services/county.service';
import { AddressTypeIdEnums } from '../../../common/constants/Enums';
import { IDynamicFormConfig } from '@mt-ng2/dynamic-form';

@Component({
    selector: 'app-address-basic-info',
    templateUrl: './address-basic-info.component.html',
})
export class AddressBasicInfoComponent implements OnInit {
    @Input() address: IAddress;
    @Input() canEdit: boolean;
    @Input() listRoute: string;

    isEditing: boolean;
    isHovered: boolean;
    config: IDynamicFormConfig;
    formFactory: AddressDynamicConfig<IAddress>;
    addressForm: UntypedFormGroup;
    doubleClickIsDisabled = false;
    addressControls: any;
    addressControlsView: any;
    selectedAddressTypeIdsArray: number[] = [];
    addressTypes: IAddressType[];
    states: IState[];
    addressBookId: number;
    formCreated = false;
    form: UntypedFormGroup;

    countyName: string;

    constructor(
        private addressService: AddressService,
        private addressTypeService: AddressTypeService,
        private authService: AuthService,
        private statesService: StateService,
        private notificationsService: NotificationsService,
        private router: Router,
        private fb: UntypedFormBuilder,
        private cdr: ChangeDetectorRef,
        private activatedRoute: ActivatedRoute,
        private countyService: CountyService,
        private location: Location,
    ) {}

    ngOnInit(): void {
        this.addressBookId = +this.activatedRoute.parent.parent.snapshot.params.addressBookId;
        this.addressControls = <AddressDynamicControlsPartial>this.addressControls;
        this.isEditing = false;
        forkJoin(this.addressTypeService.getItems(), this.statesService.getItems()).subscribe(() => this.createForm());
        const isUnarchive = this.activatedRoute.snapshot.queryParamMap.get('isUnarchive');
        if (isUnarchive) {
            this.isEditing = true;
            const url: string = this.router.url.substring(0, this.router.url.indexOf('?'));
            void this.router.navigateByUrl(url);
        }
    }

    createForm(): void {
        // Get Current County if applicable
        this.countyService.getByZip(this.address.Zip).subscribe((answer) => {
            if (answer) {
                this.countyName = answer.CountyName;
            }
        });

        this.formFactory = new AddressDynamicConfig<IAddress>(this.address, null);

        if (this.address.Id === 0) {
            // new address
            this.isEditing = true;
        }

        this.addressTypes = this.addressTypeService.items;
        this.states = this.statesService.items;
        this.getControls();
        this.addressForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    assignFormGroups(): UntypedFormGroup {
        return this.fb.group({
            Address: this.fb.group({}),
            AddressTypes: this.fb.group({}),
        });
    }

    // setConfig(): void {
    //     // Get Current County if applicable
    //     this.countyService.getByZip(this.address.Zip).subscribe((answer) => {
    //         if (answer) {
    //             this.countyName = answer.CountyName;
    //         }
    //     });

    //     this.formFactory = new AddressDynamicConfig<IAddress>(this.address, null);

    //     if (this.address.Id === 0) {
    //         // new address
    //         this.isEditing = true;
    //     }

    //     this.addressTypes = this.addressTypeService.items;
    //     this.states = this.statesService.items;
    //     this.getControls();
    //     this.formCreated = true;
    // }

    getControls(): void {
        if (!this.formCreated) {
            if (!this.address.AddressTypes) {
                this.address.AddressTypes = [];
            } else {
                this.address.AddressTypes.forEach((addressType) => this.selectedAddressTypeIdsArray.push(addressType.Id));
            }
        }
        const viewAndFormAddressControl = new AddressDynamicControlsPartial(this.address, {
            addressTypes: this.addressTypes,
            formGroup: 'Address',
            selectedAddressTypeIdsArray: this.selectedAddressTypeIdsArray,
            states: this.states,
        });
        this.addressControls = viewAndFormAddressControl.Form;
        this.addressControlsView = viewAndFormAddressControl.View;
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.address.Id === 0 && this.addressBookId === 0) {
            void this.router.navigate([this.listRoute]);
        } else if (this.addressBookId > 0 && this.location.path().endsWith('add')) {
            this.location.back();
        } else {
            this.isEditing = false;
        }
    }

    formSubmitted(): void {
        if (this.addressForm.valid) {
            this.formFactory.assignFormValues(this.address, this.addressForm.value.Address as IAddress);
            this.address.AddressTypes = [];
            if (this.addressForm.value.Address.AssociatedAddressTypes) {
                this.addressForm.value.Address.AssociatedAddressTypes.forEach((addressTypeId: number) => {
                    this.address.AddressTypes.push(this.addressTypes.find((x) => x.Id === addressTypeId));
                });
                if (this.address.AddressTypes.some((at) => at.Id === +AddressTypeIdEnums.OldAddress)) {
                    this.address.AddressTypes = this.address.AddressTypes.filter((at) => at.Id === +AddressTypeIdEnums.OldAddress);
                    this.address.Archived = true;
                    this.selectedAddressTypeIdsArray = this.address.AddressTypes.map((at) => at.Id);
                }
            }
            if (!this.address.Id || this.address.Id === 0) {
                this.address.CreatedById = this.authService.currentUser.getValue().Id;
                // handle new address save
                this.addressService.saveAddress(this.addressBookId, this.address).subscribe((answer) => {
                    void this.router.navigate([`/address-books/${this.addressBookId}/addresses/` + answer]);
                    this.success();
                    this.addressService.emitChange(this.address);
                });
            } else {
                this.address.ModifiedById = this.authService.currentUser.getValue().Id;
                // handle existing address save
                this.addressService.updateAddress(this.addressBookId, this.address).subscribe(() => {
                    this.isEditing = false;
                    this.success();
                    this.addressService.emitChange(this.address);
                    this.createForm();
                });
            }
        } else {
            this.error();
        }
    }

    getCountyName(): void {
        this.countyService.getByZip(this.addressForm.value.Address.Zip as string).subscribe((answer) => {
            if (answer) {
                this.countyName = answer.CountyName;
            }
        });
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }

    addressTypeSelectionChanged(event: any): void {
        this.selectedAddressTypeIdsArray = event === null ? [] : event;
        this.getControls();
    }
}
