import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { AuthService, ClaimsService, ClaimValues } from '@mt-ng2/auth-module';

import { AutomatedTemplateService } from '../services/automated-template.service';
import { IAutomatedTemplate } from '@model/interfaces/automated-template';
import { ClaimTypes } from '@model/ClaimTypes';
import { Subscription } from 'rxjs';
import { IEntityUser, ConcurrentUsersSocketService } from '@common/hub/user-on-service-socket.service';

@Component({
    templateUrl: './automated-template-detail.component.html',
})
export class AutomatedTemplateDetailComponent implements OnInit {
    automatedTemplate: IAutomatedTemplate;
    canEdit: boolean;
    canAdd: boolean;
    ConcurrentUsers: IEntityUser[] = [];
    socketOn = false;
    subscriptions: Subscription = new Subscription();
    overrideMargins = false;
    dynamictemplateId: number;
    routeSubscription: Subscription;

    get canDuplicate(): boolean {
        return this.canEdit && this.automatedTemplate && this.automatedTemplate.Id > 0;
    }

    constructor(
        private automatedTemplateService: AutomatedTemplateService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private ConcurrentUsersSocketService: ConcurrentUsersSocketService,
        private authService: AuthService,
    ) {}

    ngOnInit(): void {
        // check claims
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.AutomatedTemplates, [ClaimValues.FullAccess]);
        this.canAdd = this.canEdit;
        // get current id from route
        const id = this.getIdFromRoute();
        this.getAutomatedTemplateById(id);

        this.routeSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const id = this.getIdFromRoute();
                if (id !== this.automatedTemplate.Id) {
                    this.automatedTemplate = null;
                    this.getAutomatedTemplateById(id);
                }
            }
        });

        this.dynamictemplateId = +this.route.snapshot.paramMap.get('automatedTemplateId');
        this.initConcurrentUsersSocket();
        if (!this.socketOn) {
            this.initConcurrentUsersSocket();
        }
    }

    ngOnDestroy(): void {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
        }
        this.subscriptions.unsubscribe();
        this.ConcurrentUsersSocketService.disconnect();
    }

    getAutomatedTemplateById(id: number): void {
        this.automatedTemplateService.getById(id).subscribe((automatedTemplate) => {
            if (automatedTemplate === null) {
                this.notificationsService.error('Dynamic Template not found');
                void this.router.navigate(['dynamic-templates']);
            }
            this.automatedTemplate = automatedTemplate;
        });
    }

    getIdFromRoute(): number {
        const id = +this.route.snapshot.paramMap.get('automatedTemplateId');
        if (!id) {
            void this.router.navigate(['dynamic-templates']); // if no id found, go back to list
        }
        return id;
    }

    duplicate(): void {
        this.automatedTemplateService.duplicateTemplate(this.automatedTemplate.Id).subscribe(
            (duplicateId) => {
                this.notificationsService.success('Template successfully duplicated');
                void this.router.navigate(['/dynamic-templates', duplicateId]);
            },
            () => {
                this.notificationsService.error('An error occurred while duplicating the template.');
            },
        );
    }

    downloadTemplate(): void {
        this.automatedTemplateService.downloadTemplate(this.automatedTemplate.Id).subscribe(
            (response) => {
                const blob = new Blob([response.body as BlobPart], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.target = '_blank';
                a.click();
            },
            () => {
                this.notificationsService.error('Document Download failed.');
            },
        );
    }

    initConcurrentUsersSocket(): void {
        this.socketOn = true;
        this.ConcurrentUsersSocketService.connect();
        this.ConcurrentUsersSocketService.join({
            ServiceId: this.dynamictemplateId,
            UserId: this.authService.currentUser.getValue().Id,
            UserName: this.authService.currentUser.getValue().Name,
        });
        this.ConcurrentUsersSocketService.afterJoin().subscribe((users: IEntityUser[]) => {
            this.ConcurrentUsers = users.filter((user) => user.UserId !== this.authService.currentUser.getValue().Id);
        });

        this.ConcurrentUsersSocketService.afterLeave().subscribe((users: IEntityUser[]) => {
            this.ConcurrentUsers = users.filter((user) => user.UserId !== this.authService.currentUser.getValue().Id);
        });
    }
}
