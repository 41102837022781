import { ICommunicationLog } from '@model/interfaces/communication-log';
import { SearchParams } from '@mt-ng2/common-classes';
import { BaseService } from '@mt-ng2/base-service';
import { Injectable, EventEmitter, ComponentFactoryResolver, Type, ComponentRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/operators';
import { ICommunicationLogResponse } from '@model/interfaces/custom/communication-request';
import { NullHostDirective } from '../directives/null-host.directive';
import { CommonEmailComponent } from '../common-email/common-email.component';
import { debounceTime, map } from 'rxjs/operators';
import { BaseAttachmentsService } from '../attachments/base-attachments.service';
import { Observable, of } from 'rxjs';
import { IAttachmentDTO } from '@model/interfaces/custom/attachment-dto';
import { CommonFaxComponent } from '../common-fax/common-fax.component';
import { IAddressBookSelectEntity } from '../address-book-select-list/address-books-select-entity';

export enum ResendCommunicationAttachmentTypes {
    SERVICE,
    PHYSICIAN,
    TASK,
    FOLLOWUP,
    INDIVIDUAL,
    CASE,
}

export interface IResendCommunicationConfig {
    data: ICommunicationLogResponse;
    component: Type<any>;
    attachmentType: ResendCommunicationAttachmentTypes;
}

@Injectable()
export class CommunicationLogService extends BaseService<ICommunicationLog> {
    emitLogChange: EventEmitter<any> = new EventEmitter();
    resendCommunicationTypeEnumStringMap = {
        [ResendCommunicationAttachmentTypes.SERVICE]: 'imed-claim-service',
        [ResendCommunicationAttachmentTypes.CASE]: 'imed-claim',
        [ResendCommunicationAttachmentTypes.PHYSICIAN]: 'physician',
        [ResendCommunicationAttachmentTypes.INDIVIDUAL]: 'individual',
        [ResendCommunicationAttachmentTypes.TASK]: null,
        [ResendCommunicationAttachmentTypes.FOLLOWUP]: null,
    };

    constructor(
        public http: HttpClient,
        private componentFactoryResolver: ComponentFactoryResolver,
        private attachmentService: BaseAttachmentsService,
    ) {
        super('/communicationlogs', http);
    }

    searchLogs(data: SearchParams, type: string): any {
        const params = this.getHttpParams(data);
        return this.http.get<ICommunicationLogResponse>(`/communicationlogs/${type}/search`, { observe: 'response', params: params });
    }

    notifySubscribersOfNewLogs(): void {
        this.emitLogChange.emit();
    }

    loadResendCommunicationComponent(emailDirectiveHost: NullHostDirective, config: IResendCommunicationConfig): void {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(config.component);
        const viewContainerRef = emailDirectiveHost.viewContainerRef;
        viewContainerRef.clear();
        const componentRef = viewContainerRef.createComponent(componentFactory);
        if (config.data.Type === 'Fax') {
            this.resendFax(componentRef as ComponentRef<CommonFaxComponent>, config);
        } else {
            this.resendEmail(componentRef as ComponentRef<CommonEmailComponent>, config);
        }
    }

    resendFax(componentRef: ComponentRef<CommonFaxComponent>, config: IResendCommunicationConfig): void {
        const component = componentRef.instance;
        this.getAttachmentsForResend(config.attachmentType, this.getIdProperty(config)).subscribe((attachments) => {
            component.attachments = attachments;
            component.canEditAttachments = true;
            component.imedClaimServiceId = config.data.ImedClaimServiceId;
            component.originalCommunicationLogId = config.data.Id;
            component.defaultAttachments = config.data.AttachmentLinks.map((l) => l.AttachmentPath);
            const faxNumber = config.data.Recepient.replace(/[^0-9]/g, ''); // Fax number uses phoneMask on fax component, no special charaters are allowed
            component.faxTo.setValue(faxNumber);
            component.onClose.pipe(debounceTime(200)).subscribe(() => {
                componentRef.destroy();
                this.emitLogChange.emit();
            });
            setTimeout(() => component.showFaxForm(), 200);
        });
    }

    resendEmail(componentRef: ComponentRef<CommonEmailComponent>, config: IResendCommunicationConfig): void {
        const component = componentRef.instance;
        this.getAttachmentsForResend(config.attachmentType, this.getIdProperty(config)).subscribe((attachments) => {
            component.attachments = attachments;
            component.canEditAttachments = true;
            component.selectedPhysicians = config.data.Physicians
                ? config.data.Physicians.map((p) => {
                      return {
                          IndividualId: p.IndividualId,
                          IndividualName: p.Individual.FirstName + ' ' + p.Individual.LastName,
                          PhysicianId: p.Id,
                      } as IAddressBookSelectEntity;
                  })
                : null;
            component.resendEmailTemplateId = config.data.EmailTemplateId;
            component.imedClaimServiceId = config.data.ImedClaimServiceId;
            component.imedClaimId = config.data.ImedClaimId;
            component.originalCommunicationLogId = config.data.Id;
            component.defaultAttachments = config.data.AttachmentLinks.map((l) => l.AttachmentPath);
            component.emailBody.setValue(config.data.Body);
            component.emailSubject.setValue(config.data.Subject);
            component.emailCC.setValue(config.data.CC);
            component.emailBCC.setValue(config.data.BCC);
            component.emailTo.setValue(config.data.Recepient);
            component.onClose.pipe(debounceTime(200)).subscribe(() => {
                componentRef.destroy();
                this.emitLogChange.emit();
            });
            setTimeout(() => component.showEmailForm(), 200);
        });
    }
    getIdProperty(config: IResendCommunicationConfig): number {
        if (config.attachmentType === ResendCommunicationAttachmentTypes.SERVICE) {
            return config.data.ImedClaimServiceId;
        }
        if (config.attachmentType === ResendCommunicationAttachmentTypes.PHYSICIAN) {
            return config.data.Physicians[0].Id;
        }
        if (config.attachmentType === ResendCommunicationAttachmentTypes.INDIVIDUAL) {
            return config.data.IndividualId;
        }
        if (config.attachmentType === ResendCommunicationAttachmentTypes.CASE) {
            return config.data.ImedClaimId;
        }
        return 0;
    }

    getAttachmentsForResend(type: ResendCommunicationAttachmentTypes, id: number): Observable<IAttachmentDTO[]> {
        if (this.resendCommunicationTypeEnumStringMap[type] === null) {
            return of([]);
        }
        return this.attachmentService.all(this.resendCommunicationTypeEnumStringMap[type] as string, id, '').pipe(map(({ body: attachments }) => attachments));
    }
}
