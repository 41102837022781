import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IPhysicianFollowupLog } from '@model/interfaces/physician-followup-log';
import { BaseService } from '../../../../../node_modules/@mt-ng2/base-service';
import { SearchParams } from '../../../../../node_modules/@mt-ng2/common-classes';
import { IPhysicianFollowupLogPartial } from '@model/partials/physician-followup-log-partial';
import { Observable } from 'rxjs';

export const emptyPhysicianFollowupLog: IPhysicianFollowupLogPartial = {
    AddedById: 0,
    AddedDate: new Date(),
    FollowupDate: new Date(),
    FollowupNotes: null,
    Id: 0,
    ImedClaimType: null,
    ImedClaimTypeId: 1,
    PhysicianId: 0,
    User: null,
};

@Injectable()
export class PhysicianFollowupService extends BaseService<IPhysicianFollowupLog> {
    constructor(public http: HttpClient) {
        super('/physicianfollowuplogs', http);
    }

    searchFollowups(individualId: number, data: SearchParams): any {
        const params = this.getHttpParams(data);
        return this.http.get<IPhysicianFollowupLog>(`/physicianfollowuplogs/${individualId}/_search`, { observe: 'response', params: params });
    }

    getByIndividualId(individualId: number): Observable<IPhysicianFollowupLogPartial[]> {
        return this.http.get<IPhysicianFollowupLogPartial[]>(`/physicianfollowuplogs/getByIndividualId/${individualId}`);
    }
}
