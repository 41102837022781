import { DynamicField, DynamicFieldType, ITimepickerOptions, DynamicFieldTypes, InputTypes, DynamicLabel } from '@mt-ng2/dynamic-form';
import {
    IConferenceCallServiceDynamicControlsParameters,
    ConferenceCallServiceDynamicControls,
} from '../form-controls/conference-call-service.form-controls';
import { IConferenceCallService } from '../interfaces/conference-call-service';

export class ConferenceCallServiceDynamicControlsPartial extends ConferenceCallServiceDynamicControls {
    constructor(
        private conferencecallService?: IConferenceCallService,
        private additionalParameters?: IConferenceCallServiceDynamicControlsParameters,
    ) {
        super(conferencecallService, additionalParameters);

        ((<DynamicField>this.Form.DateTimeAgreedByPhysician).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Input,
            inputType: InputTypes.DateTimepicker,
            timepickerOptions: { meridian: true } as ITimepickerOptions,
        })),
            ((<DynamicField>this.Form.DateTimeAgreedByPhysician).labelHtml = `<label>Date & time agreed upon by physician</label>`);
        (<DynamicLabel>this.View.DateTimeAgreedByPhysician).label = `Date & time agreed upon by physician`;

        // if (!(conferencecallService.DateTimeAgreedByPhysician instanceof Date) && conferencecallService.DateTimeAgreedByPhysician) {
        //     let offsetDate = new Date(conferencecallService.DateTimeAgreedByPhysician);
        //     offsetDate.setHours(offsetDate.getHours() - 4); // this is bad, offsetting 4 hours from db
        //     (<DynamicField>this.Form.DateTimeAgreedByPhysician).value = offsetDate;
        //     (<DynamicField>this.View.DateTimeAgreedByPhysician).value = offsetDate;
        // }
    }
}
