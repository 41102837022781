import { IRecordReviewService } from '@model/interfaces/record-review-service.d';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '@mt-ng2/base-service';
import { IServicePayload } from '@model/interfaces/custom/update-service-payload';
import { nullOutNestedObjects } from '../../../../common/custom/nullOutNestedObjects';
import { IRecordReviewContactInfo } from '../../../../imed-claims/imed-claim-basic-info/imed-claim-contacts-info';
import { AddressBookSelectionEnums } from '../../../../common/constants/Enums';
import { INote } from '@model/interfaces/base';

export const emptyRecordReviewService: IRecordReviewService = {
    Archived: false,
    ConflictOfInterestApproved: false,
    CreatedById: 0,
    DateCreated: null,
    HasPreLitigation: false,
    Id: 0,
    ImedClaimServiceId: 0,
    IsFederalCase: false,
    PatientPreviouslyTreatedByPhysician: null,
    RequestDate: null,
    RequestDateChangedById: null,
    RequestDateChangedDate: null,
    Surveillance: false,
};

@Injectable()
export class RecordReviewServiceService extends BaseService<IRecordReviewService> {
    constructor(public http: HttpClient) {
        super('/recordreviewservices', http);
    }

    getEmptyRecordReviewServiceService(): IRecordReviewService {
        return { ...emptyRecordReviewService };
    }

    getByImedClaimServiceId(imedClaimServiceId: number): Observable<IRecordReviewService> {
        return this.http.get<IRecordReviewService>(`/recordreviewservices/getByImedClaimServiceId/${imedClaimServiceId}`);
    }

    assignContactsToRecordReview(recordReviewService: IRecordReviewService, recordReviewContact: IRecordReviewContactInfo): IRecordReviewService {
        recordReviewService.TranslationServicesVendorAddressBookId = recordReviewContact.TranslationServicesVendorAddressBookId;
        recordReviewService.TranslationServicesVendorIndividualId = recordReviewContact.TranslationServicesVendorIndividualId;

        recordReviewService.BackgroundInvestigationVendorAddressBookId = recordReviewContact.BackgroundInvestigationVendorAddressBookId;
        recordReviewService.BackgroundInvestigationVendorIndividualId = recordReviewContact.BackgroundInvestigationVendorIndividualId;

        recordReviewService.TransportationServicesVendorAddressBookId = recordReviewContact.TransportationServicesVendorAddressBookId;
        recordReviewService.TransportationServicesVendorIndividualId = recordReviewContact.TransportationServicesVendorIndividualId;

        recordReviewService.SurveillanceVendorAddressBookId = recordReviewContact.SurveillanceVendorAddressBookId;
        recordReviewService.SurveillanceVendorIndividualId = recordReviewContact.SurveillanceVendorIndividualId;

        recordReviewService.TranscriptionServicesVendorAddressBookId = recordReviewContact.TranscriptionServicesVendorAddressBookId;
        recordReviewService.TranscriptionServicesVendorIndividualId = recordReviewContact.TranscriptionServicesVendorIndividualId;

        recordReviewService.RentalFacilityVendorAddressBookId = recordReviewContact.SurveillanceVendorAddressBookId;
        recordReviewService.RentalFacilityVendorIndividualId = recordReviewContact.SurveillanceVendorIndividualId;

        return recordReviewService;
    }

    assignRecordReviewToContacts(recordReviewContact: IRecordReviewContactInfo, recordReviewService: IRecordReviewService): IRecordReviewContactInfo {
        if (recordReviewService) {
            recordReviewContact.TransportationServicesVendorName = recordReviewService.TransportationServicesVendorIndividual
                ? recordReviewService.TransportationServicesVendorIndividual.FirstName +
                  ' ' +
                  recordReviewService.TransportationServicesVendorIndividual.LastName
                : recordReviewService.TransportationServicesVendorAddressBook
                ? recordReviewService.TransportationServicesVendorAddressBook.FacilityName
                : 'N/A';

            recordReviewContact.TranslationServicesVendorName = recordReviewService.TranslationServicesVendorIndividual
                ? recordReviewService.TranslationServicesVendorIndividual.FirstName +
                  ' ' +
                  recordReviewService.TranslationServicesVendorIndividual.LastName
                : recordReviewService.TranslationServicesVendorAddressBook
                ? recordReviewService.TranslationServicesVendorAddressBook.FacilityName
                : 'N/A';

            recordReviewContact.BackgroundInvestigationVendorName = recordReviewService.BackgroundInvestigationVendorIndividual
                ? recordReviewService.BackgroundInvestigationVendorIndividual.FirstName +
                  ' ' +
                  recordReviewService.BackgroundInvestigationVendorIndividual.LastName
                : recordReviewService.BackgroundInvestigationVendorAddressBook
                ? recordReviewService.BackgroundInvestigationVendorAddressBook.FacilityName
                : 'N/A';

            recordReviewContact.SurveillanceVendorName = recordReviewService.SurveillanceVendorIndividual
                ? recordReviewService.SurveillanceVendorIndividual.FirstName + ' ' + recordReviewService.SurveillanceVendorIndividual.LastName
                : recordReviewService.SurveillanceVendorAddressBook
                ? recordReviewService.SurveillanceVendorAddressBook.FacilityName
                : 'N/A';

            recordReviewContact.TranscriptionServicesVendorName = recordReviewService.TranscriptionServicesVendorIndividual
                ? recordReviewService.TranscriptionServicesVendorIndividual.FirstName +
                  ' ' +
                  recordReviewService.TranscriptionServicesVendorIndividual.LastName
                : recordReviewService.TranscriptionServicesVendorAddressBook
                ? recordReviewService.TranscriptionServicesVendorAddressBook.FacilityName
                : 'N/A';

            recordReviewContact.RentalFacilityVendorName = recordReviewService.RentalFacilityVendorIndividual
                ? recordReviewService.RentalFacilityVendorIndividual.FirstName + ' ' + recordReviewService.RentalFacilityVendorIndividual.LastName
                : recordReviewService.RentalFacilityVendorAddressBook
                ? recordReviewService.RentalFacilityVendorAddressBook.FacilityName
                : 'N/A';

            recordReviewContact.TranslationServicesVendorAddressBookId = recordReviewService.TranslationServicesVendorAddressBookId;
            recordReviewContact.TranslationServicesVendorIndividualId = recordReviewService.TranslationServicesVendorIndividualId;

            recordReviewContact.BackgroundInvestigationVendorAddressBookId = recordReviewService.BackgroundInvestigationVendorAddressBookId;
            recordReviewContact.BackgroundInvestigationVendorIndividualId = recordReviewService.BackgroundInvestigationVendorIndividualId;

            recordReviewContact.TransportationServicesVendorAddressBookId = recordReviewService.TransportationServicesVendorAddressBookId;
            recordReviewContact.TransportationServicesVendorIndividualId = recordReviewService.TransportationServicesVendorIndividualId;

            recordReviewContact.SurveillanceVendorAddressBookId = recordReviewService.SurveillanceVendorAddressBookId;
            recordReviewContact.SurveillanceVendorIndividualId = recordReviewService.SurveillanceVendorIndividualId;

            recordReviewContact.TranscriptionServicesVendorAddressBookId = recordReviewService.TranscriptionServicesVendorAddressBookId;
            recordReviewContact.TranscriptionServicesVendorIndividualId = recordReviewService.TranscriptionServicesVendorIndividualId;

            recordReviewContact.RentalFacilityVendorAddressBookId = recordReviewService.RentalFacilityVendorAddressBookId;
            recordReviewContact.RentalFacilityVendorIndividualId = recordReviewService.RentalFacilityVendorIndividualId;
        }

        return recordReviewContact;
    }

    unAssignContactFromRecordReview(recordReviewContactinfo: IRecordReviewContactInfo, name: string): IRecordReviewContactInfo {
        switch (name as AddressBookSelectionEnums) {
            case AddressBookSelectionEnums.BackgroundInvestigationVendor:
                recordReviewContactinfo.BackgroundInvestigationVendorAddressBookId = null;
                recordReviewContactinfo.BackgroundInvestigationVendorIndividualId = null;
                recordReviewContactinfo.BackgroundInvestigationVendorName = null;
                break;
            case AddressBookSelectionEnums.TranslationServicesVendor:
                recordReviewContactinfo.TranslationServicesVendorAddressBookId = null;
                recordReviewContactinfo.TranslationServicesVendorIndividualId = null;
                recordReviewContactinfo.TranslationServicesVendorName = null;
                break;
            case AddressBookSelectionEnums.TransportationServicesVendor:
                recordReviewContactinfo.TransportationServicesVendorAddressBookId = null;
                recordReviewContactinfo.TransportationServicesVendorIndividualId = null;
                recordReviewContactinfo.TransportationServicesVendorName = null;
                break;
            case AddressBookSelectionEnums.SurveillanceVendor:
                recordReviewContactinfo.SurveillanceVendorName = null;
                recordReviewContactinfo.SurveillanceVendorAddressBookId = null;
                recordReviewContactinfo.SurveillanceVendorIndividualId = null;
                break;
            case AddressBookSelectionEnums.TranscriptionServicesVendor:
                recordReviewContactinfo.TranscriptionServicesVendorAddressBookId = null;
                recordReviewContactinfo.TranscriptionServicesVendorIndividualId = null;
                recordReviewContactinfo.TranscriptionServicesVendorName = null;
                break;
            case AddressBookSelectionEnums.RentalFacilityVendor:
                recordReviewContactinfo.RentalFacilityVendorAddressBookId = null;
                recordReviewContactinfo.RentalFacilityVendorIndividualId = null;
                recordReviewContactinfo.RentalFacilityVendorName = null;
                break;
            default:
                break;
        }
        return recordReviewContactinfo;
    }

    updateService(servicePayLoad: IServicePayload<IRecordReviewService>): Observable<number> {
        const clone: IServicePayload<IRecordReviewService> = JSON.parse(JSON.stringify(servicePayLoad));
        nullOutNestedObjects(clone.ParentService);
        nullOutNestedObjects(clone.ChildService);
        return this.http.put<number>(`/recordreviewservices/update-service/${clone.ParentService.Id}`, clone);
    }

    updateServiceConflictOfInterest(Id: number, servicePayLoad: IServicePayload<IRecordReviewService>): Observable<number> {
        const clone: IServicePayload<IRecordReviewService> = JSON.parse(JSON.stringify(servicePayLoad));
        nullOutNestedObjects(clone.ParentService);
        nullOutNestedObjects(clone.ChildService);
        return this.http.put<number>(`/recordreviewservices/update-service-conflict/${Id}`, servicePayLoad);
    }

    updateAdjusterInquiries(imedClaimServiceId: number, notes: INote[]): Observable<any> {
        return this.http.put<any>(`/imedclaimservices/${imedClaimServiceId}/notes/update`, notes);
    }
}
