import { Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { IExpandableObject } from '../expandable-object';

import { DynamicField, noZeroRequiredValidator } from '@mt-ng2/dynamic-form';

import { EmailAddressDynamicControls, IEmailAddressDynamicControlsParameters } from '../form-controls/email-address.form-controls';
import { IEmailAddress } from '../interfaces/email-address';
import { IUser } from '../interfaces/user';
import { IEmailType } from '../interfaces/email-type';

export function customEmailValidator(control: AbstractControl): ValidationErrors {
    if (!control.value) {
        return null;
    }

    return Validators.email(control);
}

export class EmailAddressDynamicControlsPartial extends EmailAddressDynamicControls {
    constructor(emailaddressPartial?: IEmailAddress, additionalParameters?: IEmailAddressDynamicControlsParameters) {
        super(emailaddressPartial, additionalParameters);

        (<DynamicField>this.Form.Email).validation = [];
        (<DynamicField>this.Form.Email).validation.push(Validators.required);
        (<DynamicField>this.Form.Email).validation.push(customEmailValidator);
        (<DynamicField>this.Form.Email).validation.push(Validators.maxLength(100));
    }

    setRequired(isRequired: boolean): void {
        (<DynamicField>this.Form.Email).setRequired(isRequired);
        (<DynamicField>this.Form.EmailTypeId).setRequired(isRequired);

        if (isRequired) {
            (<DynamicField>this.Form.EmailTypeId).validation.push(noZeroRequiredValidator);

            (<DynamicField>this.Form.Email).validation.push(Validators.required);
            (<DynamicField>this.Form.Email).validation.push(customEmailValidator);
            (<DynamicField>this.Form.Email).validation.push(Validators.maxLength(100));
        } else {
            (<DynamicField>this.Form.EmailTypeId).validation = [];
            (<DynamicField>this.Form.Email).validation = [];
            (<DynamicField>this.Form.Email).validation.push(customEmailValidator);
            (<DynamicField>this.Form.Email).validation.push(Validators.maxLength(100));
        }
    }
}
