import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from '@mt-ng2/base-service';
import { SearchParams } from '@mt-ng2/common-classes';
import { IPhysicianVacation } from '@model/interfaces/physician-vacation';
import { DateTimeConverterService } from '../../../../common/services/date-time-converter.service';
import { map } from 'rxjs/operators';

export const emptyPhysicianVacation: IPhysicianVacation = {
    Archived: false,
    CreatedById: 0,
    DateCreated: new Date(),
    FromDate: null,
    Id: 0,
    PhysicianId: 0,
    ToDate: null,
};

@Injectable()
export class PhysicianVacationService extends BaseService<IPhysicianVacation> {
    constructor(public http: HttpClient, private dateService: DateTimeConverterService) {
        super('/physicianvacations', http);
    }

    private fixDatesForVacation = (vacation: IPhysicianVacation): IPhysicianVacation => {
        vacation.FromDate = this.dateService.assumeLocalTimeForUTCDateObject(vacation.FromDate);
        vacation.ToDate = this.dateService.assumeLocalTimeForUTCDateObject(vacation.ToDate);
        return vacation;
    }

    getEmptyPhysicianVacation(): IPhysicianVacation {
        return { ...emptyPhysicianVacation };
    }

    // overriding the getById so that I can update the dates that should not have time
    // to remove the 'Z' and therefore consider them already in browser's time zone
    getById(id: number): Observable<IPhysicianVacation> {
        return super.getById(id).pipe(map(this.fixDatesForVacation));
    }

    // overriding the get so that I can update the dates that should not have time
    // to remove the 'Z' and therefore consider them already in browser's time zone
    get(searchparameters: SearchParams): Observable<HttpResponse<IPhysicianVacation[]>> {
        return super.get(searchparameters).pipe(
            map((response) => {
                (<any>response.body) = response.body.map(this.fixDatesForVacation);
                return response;
            }),
        );
    }
}
