import { IRecordRetrievalService } from '@model/interfaces/record-retrieval-service';
import { IImedClaimService } from '@model/interfaces/imed-claim-service';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import 'rxjs/operators';
import { IBillsForReview } from '@model/interfaces/bills-for-review';
import { BillsForReviewService, emptyBillsForReview } from '../bills-for-review.service';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Component({
    selector: 'app-bills-for-review',
    templateUrl: './bills-for-review.component.html',
})
export class BillsForReviewComponent {
    @Input() recordRetrievalServiceModel: IRecordRetrievalService;
    @Input() iImedClaimServiceModel: IImedClaimService;
    @Input() canEdit: boolean;
    @Input() billsForReview: IBillsForReview[] = [];
    @Output('refreshBillsForReview') refreshBillsForReview: EventEmitter<any> = new EventEmitter<any>();

    isEditing: boolean;
    isHovered: boolean;

    isAdding: boolean;
    billForReview: IBillsForReview;

    constructor(private billsForReviewService: BillsForReviewService, private notificationsService: NotificationsService) {}

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    addItem(): void {
        this.billForReview = { ...emptyBillsForReview };
        this.isAdding = true;
    }

    deleteItem(billForReview: IBillsForReview): void {
        this.billsForReviewService.delete(billForReview.Id).subscribe(() => {
            this.notificationsService.success('Bill for Review successfully deleted.');
            if (billForReview.Id === this.billForReview.Id) {
                this.billForReview.Id = 0;
            }
            this.refreshBillsForReview.emit();
        });
    }

    selectItem(billForReview: IBillsForReview): void {
        this.billForReview = billForReview;
        this.isAdding = true;
    }

    cancel(): void {
        this.isEditing = false;
        this.isAdding = false;
        this.billForReview = null;
    }
}
