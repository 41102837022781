import { Component, Input, OnInit } from '@angular/core';
import { IAddress } from '@model/interfaces/address';
import { IIndividual } from '@model/interfaces/individual';
import { MultiselectItem } from '@mt-ng2/multiselect-control';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AddressFormatterService } from '../../../common/services/address-formatter.service';
import { AddressBookService } from '../../addressbook.service';
import { IndividualService } from '../individual.service';

@Component({
    selector: 'app-available-locations',
    templateUrl: './available-locations.component.html',
})
export class AvailableLocationsComponent implements OnInit {
    @Input() individual: IIndividual;
    @Input() canEdit = true;

    isHovered = false;
    isEditing = false;
    doubleClickIsDisabled = false;

    availableLocations: IAddress[] = [];

    items: MultiselectItem[] = [];
    selected: IAddress[] = [];

    constructor(
        private individualService: IndividualService,
        private addressBookService: AddressBookService,
        private addressFormatterService: AddressFormatterService,
        private notificationsService: NotificationsService,
    ) {}

    ngOnInit(): void {
        forkJoin([
            this.addressBookService.getFacilityAddresses(this.individual.AddressBookId),
            this.individualService.getIndividualAvailableLocations(this.individual.Id),
        ]).subscribe(([facilityAddresses, availableLocations]) => {
            this.availableLocations = availableLocations;
            this.items = facilityAddresses.map((fa) => {
                return new MultiselectItem({ Id:fa.Id, Value: fa, Name: this.getAddressLabel(fa) }, false);
            });
        });
    }

    getAddressLabel(address: IAddress): string {
        return `${address.IsPrimary ? '(primary) ' : ''}` + this.addressFormatterService.formatAddress(address);
    }

    getHtmlAddressLabel(address: IAddress): string {
        return `${address.IsPrimary ? '<i class="fa fa-star btn selected"></i> ' : ' '}` + this.addressFormatterService.formatAddress(address);
    }

    edit(): void {
        if (this.canEdit) {
            this.items.forEach((fa) => (fa.Selected = this.availableLocations.some((al) => al.Id === fa.Item.Id)));
            this.isEditing = true;
        }
    }

    selectionChanged(event): void {
        this.selected = event.selectedItems.map((i) => i.Value);
    }

    save(): void {
        this.individualService
            .saveAvailableLocations(this.individual.Id, this.selected)            .subscribe(
                (success) => {
                    this.success();
                },
                (error) => {
                    this.error();
                },
            );
    }

    success(): void {
        this.availableLocations = this.selected;
        this.isEditing = false;
        this.notificationsService.success('Saved Successfully');
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    cancel(): void {
        this.isEditing = false;
    }
}
