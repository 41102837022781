import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CommonSearchService, ICommonSearch } from './services/common-search.service';
import { SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';

@Component({
    selector: 'app-redirect',
    template: ``,
})
export class RedirectComponent implements OnInit {
    entityId: number;
    previousUrl: string;

    constructor(private route: ActivatedRoute, private router: Router, private commonSearchService: CommonSearchService) {}

    ngOnInit(): void {
        this.entityId = parseInt(this.route.snapshot.paramMap.get('entityId'), 10);

        this.redirect();
    }

    redirect(): void {
        void this.router.navigate([`/cases/${this.entityId}/services`]);
    }
}
