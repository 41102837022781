import { Component, inject, Input, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { IImedClaimAdditionalRecord } from "@model/interfaces/imed-claim-additional-record";
import { IDynamicFormConfig } from "@mt-ng2/dynamic-form";
import { NotificationsService } from "@mt-ng2/notifications-module";
import { finalize } from "rxjs";
import { ImedClaimService } from "../imedclaim.service";
import { ImedClaimAdditionalRecordsService } from "../services/imed-claim-additional-records.service";
import { ImedClaimAdditionalRecordDynamicConfig } from "./imed-claim-additional-records-dynamic-config";

@Component({
    selector: 'app-imed-claim-additional-records',
    templateUrl: './imed-claim-additional-records.component.html',
    styles: [`
        .delete-action-button {
            padding: 2px 6px;
        }
        .delete-action-button:hover {
            background-color: transparent;
            color: inherit;
        }
    `],
})
export class ImedClaimAdditionalRecordsComponent implements OnInit {
    private imedClaimAdditionalRecordsService = inject(ImedClaimAdditionalRecordsService);
    private imedClaimService = inject(ImedClaimService);
    private notificationService = inject(NotificationsService);

    @Input() imedClaimId: number;
    @Input() canEdit: boolean;

    imedClaimAdditionalRecords: IImedClaimAdditionalRecord[];
    imedClaimAdditionalRecord: IImedClaimAdditionalRecord;

    isEditMode = false;
    doubleClickIsDisabled = false;

    formFactory: ImedClaimAdditionalRecordDynamicConfig;

    config = {
        formObject: [],
        viewOnly: [],
    } as IDynamicFormConfig;

    ngOnInit(): void {
        this.getAdditionalRecords();
        this.setConfig();
    }

    getAdditionalRecords(): void {
        this.imedClaimService.getAdditionalRecords(this.imedClaimId).subscribe((imedClaimAdditionalRecords) => {
            this.imedClaimAdditionalRecords = imedClaimAdditionalRecords;
        });
    }

    setConfig(): void {
        this.formFactory = new ImedClaimAdditionalRecordDynamicConfig(this.imedClaimAdditionalRecord);

        if (this.imedClaimAdditionalRecord) {
            this.config = this.formFactory.getForUpdate();
        } else {
            this.config = this.formFactory.getForCreate();
        }
    }

    toggleEdit(): void {
        
        this.isEditMode = !this.isEditMode;
    }

    onAdd(): void {
        this.imedClaimAdditionalRecord = null;
        this.setConfig();
        this.toggleEdit();
    }

    onEdit(imedClaimAdditionalRecord: IImedClaimAdditionalRecord): void {
        this.imedClaimAdditionalRecord = imedClaimAdditionalRecord;
        this.setConfig();
        this.toggleEdit();
    }

    onDelete(imedClaimAdditionalRecord: IImedClaimAdditionalRecord, event:Event): void {
        event.stopPropagation();
        this.imedClaimAdditionalRecordsService.delete(imedClaimAdditionalRecord.Id)
            .subscribe({
                complete: () => {
                    this.getAdditionalRecords();
                    this.notificationService.success('Additional Record Deleted');
                },
                error: () => {
                    this.notificationService.error('Error Deleting Additional Record');
                },
            });
    }

    setDatesToTimeZoneRelatedDate(dateToBeConverted: Date): Date {
        dateToBeConverted = new Date(dateToBeConverted);
        const dateFromFormAsISOString = dateToBeConverted.toISOString();
        const dateWithoutUTCIdentifier = new Date(dateFromFormAsISOString.substring(0, dateFromFormAsISOString.length - 1));
        return new Date(dateWithoutUTCIdentifier.mtDate.toMoment().format('MM/DD/YYYY'));
    }

    handleSubmit(form: UntypedFormGroup): void {
        form.markAllAsTouched();

        if (form.invalid) {
            
            return;
        }

        

        const additionalRecord = form.value.ImedClaimAdditionalRecord as IImedClaimAdditionalRecord;

        if (this.imedClaimAdditionalRecord) {
            this.imedClaimAdditionalRecordsService.update({
                Id: this.imedClaimAdditionalRecord.Id,
                ImedClaimId: this.imedClaimId,
                BeginningPageCount: additionalRecord.BeginningPageCount,
                EndingPageCount: additionalRecord.EndingPageCount,
                Date: this.setDatesToTimeZoneRelatedDate(additionalRecord.Date),
            })
                .pipe(finalize(() => this.doubleClickIsDisabled = false))
                .subscribe({
                    complete: () => {
                        this.notificationService.success('Additional Record Updated');
                        this.isEditMode = false;
                        this.getAdditionalRecords();
                    },
                    error: () => {
                        this.notificationService.error('Error Updating Additional Record');
                    },
                });
        } else {
            this.imedClaimAdditionalRecordsService.create({
                Id: 0,
                ImedClaimId: this.imedClaimId,
                BeginningPageCount: additionalRecord.BeginningPageCount,
                EndingPageCount: additionalRecord.EndingPageCount,
                Date: this.setDatesToTimeZoneRelatedDate(additionalRecord.Date),
            })
                .pipe(finalize(() => this.doubleClickIsDisabled = false))
                .subscribe({
                    complete: () => {
                        this.notificationService.success('Additional Record Created');
                        this.isEditMode = false;
                        this.getAdditionalRecords();
                    },
                    error: () => {
                        this.notificationService.error('Error Creating Additional Record');
                    },
                })
        }
    }

}
