import { Component, Input, OnInit } from '@angular/core';
import { IImedClaimService } from '@model/interfaces/imed-claim-service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { AutoIssueDynamicConfig } from './auto-issue.dynamic-config';
import { IAutoIssue } from '@model/interfaces/auto-issue';
import { finalize } from 'rxjs/operators';
import { AutoIssueService } from '../../auto-issue.service';
import { ServiceIdEnums } from '../../../../common/constants/Enums';
import { DynamicField, DynamicLabel, IDynamicFormConfig } from '@mt-ng2/dynamic-form';

// Issues related to claims need to be shown at IME service level as Issues are  related to IME Service ONLY so we created a seperate component inside services and update claims table from there.
// If there are multiple IMES in a claim, updating one will update all based on the following task
// Task id:962201
@Component({
    selector: 'app-auto-issues',
    templateUrl: './auto-issues.component.html',
})
export class AutoIssuesComponent implements OnInit {
    @Input() iImedClaimServiceModel: IImedClaimService;
    @Input() canEdit: boolean;
    serviceForm: UntypedFormGroup;
    autoIssueFormFactory: AutoIssueDynamicConfig<IAutoIssue>;
    config: IDynamicFormConfig;
    isEditing = false;
    isHovered = false;
    doubleClickIsDisabled: boolean;

    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    constructor(private fb: UntypedFormBuilder, private notificationsService: NotificationsService, private autoIssueService: AutoIssueService) {}

    ngOnInit(): void {
        this.serviceForm = this.fb.group({});
        this.config = { formObject: [], viewOnly: [] };
        this.setConfig();
    }

    setConfig(): void {
        let configControls: string[] = null;

        if (this.iImedClaimServiceModel.ServiceId === +ServiceIdEnums.AUTO_Peer_Review) {
            configControls = [
                'AdLsThatMayAffectThisClaim',
                'Apportionment',
                'AppropriatenessOfTreatment',
                'CausalRelationship',
                'CompleteHistory',
                'DegreeOfDisability',
                'Diagnosis',
                'FunctionalCapabilityEvaluation',
                'HistoryOfAllegedInjury',
                'Mmi',
                'NeedForContinuedFollowUp',
                'NeedForDme',
                'NeedForEssentialServicesHouseholdHelp',
                'NeedForFurtherDiagnosticTesting',
                'NeedForFurtherTreatment',
                'NeedForFurtherTreatmentWithinYourScope',
                'NeedForInjections',
                'NeedForMassage',
                'NeedForPhysicalTherapy',
                'NeedForPrescriptionMedication',
                'NeedForSurgery',
                'NeedForTransportation',
                'ObjectiveFindingsOnExamDiagnosis',
                'OccupationalHistory',
                'PastSubsequentMedicalHistory',
                'Permanency',
                'PreExistingConditions',
                'PresentComplaints',
                'RecommendationForFurtherTreatment',
                'ReturnToWorkRestrictionsAdl',
                'ReviewOfRecords',
                'Slu',
                'SpecificTreatmentQuestions',
            ];
        } else {
            configControls = [
                'Apportionment',
                'AppropriatenessOfTreatment',
                'CausalRelationship',
                'CompleteHistory',
                'DegreeOfDisability',
                'Mmi',
                'NeedForContinuedFollowUp',
                'NeedForDme',
                'NeedForEssentialServicesHouseholdHelp',
                'NeedForFurtherDiagnosticTesting',
                'NeedForFurtherTreatment',
                'NeedForInjections',
                'NeedForMassage',
                'NeedForPhysicalTherapy',
                'NeedForPrescriptionMedication',
                'NeedForSurgery',
                'NeedForTransportation',
                'ObjectiveFindingsOnExamDiagnosis',
                'Permanency',
                'PreExistingConditions',
                'PresentComplaints',
                'ReturnToWorkRestrictionsAdl',
                'ReviewOfRecords',
                'Slu',
                'SpecificTreatmentQuestions',
            ];
        }

        this.autoIssueFormFactory = new AutoIssueDynamicConfig(this.iImedClaimServiceModel.ImedClaim.AutoIssue, configControls);
        this.config = this.autoIssueFormFactory.getForUpdate();
        
        this.viewOnly = this.config?.viewOnly?.map((x) => new DynamicLabel(x));
        this.formObject = this.config.formObject?.map((x) => new DynamicField(x));
    }

    edit(): void {
        this.isEditing = true;
    }

    cancelClick(): void {
        this.isEditing = false;
    }

    formSubmitted(): void {
        this.autoIssueFormFactory.assignFormValues(this.iImedClaimServiceModel.ImedClaim.AutoIssue, this.serviceForm.value.AutoIssue as IAutoIssue);
        this.autoIssueService
            .update(this.iImedClaimServiceModel.ImedClaim.AutoIssue)            .subscribe(() => {
                this.success();
            });
    }
    success(): void {
        this.setConfig();
        this.isEditing = false;
        this.notificationsService.success('Saved Successfully');
    }
}
