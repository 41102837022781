import { Validators } from '@angular/forms';
import { IExpandableObject } from '../expandable-object';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { UserRoleDynamicControls, IUserRoleDynamicControlsParameters } from '../form-controls/user-role.form-controls';
import { IUserRole } from '../interfaces/user-role';
import { IDashboardType } from '../interfaces/dashboard-type';
import { IUserType } from '../interfaces/user-type';
import { UserTypeValues } from '../../common/constants/user-type.enum';

export interface IUserRoleDynamicControlsPartialParameters extends IUserRoleDynamicControlsParameters {
    userTypes: IUserType[];
}

export class UserRoleDynamicControlsPartial extends UserRoleDynamicControls {
    constructor(userrolePartial?: IUserRole, additionalParameters?: IUserRoleDynamicControlsParameters) {
        super(userrolePartial, additionalParameters);

        const userTypes = (additionalParameters && additionalParameters.userTypes) || undefined;

        (<DynamicField>this.Form.UserTypeId).options = userTypes;

        const isExistingRole = userrolePartial.Id !== 0;
        (<DynamicField>this.Form.UserTypeId).disabled = isExistingRole;
        (<DynamicField>this.Form.UserTypeId).setRequired(!isExistingRole);
        (<DynamicField>this.Form.AssignManualContactTask).label = 'Assign Task When Contacts Are Added Via Client Portal';
        (<DynamicField>this.View.AssignManualContactTask).label = 'Assign Task When Contacts Are Added Via Client Portal';
        if (isExistingRole && userrolePartial.UserTypeId !== UserTypeValues.Admin) {
            (<DynamicField>this.Form.DashboardTypeId).disabled = true;
            (<DynamicField>this.Form.EmailForTemplates).disabled = true;
            (<DynamicField>this.Form.AssignManualContactTask).disabled = true;
        }

        (<DynamicField>this.Form.Auto).label = 'No Fault / PIP';
        (<DynamicField>this.Form.Auto).labelHtml = '<label>No Fault / PIP</label>';
        (<DynamicLabel>this.View.Auto).label = 'No Fault / PIP';

        (<DynamicField>this.Form.ReceiveAutoCaseClosingJobEmail).label = 'Auto Case Closing Job Email';
        (<DynamicField>this.Form.ReceiveAutoCaseClosingJobEmail).labelHtml = '<label>Auto Case Closing Job Email</label>';
        (<DynamicLabel>this.View.ReceiveAutoCaseClosingJobEmail).label = 'Auto Case Closing Job Email';

        (<DynamicField>this.Form.ReceiveCpFilmDeleteEmail).label = 'Client Portal Film Delete Email';
        (<DynamicField>this.Form.ReceiveCpFilmDeleteEmail).labelHtml = '<label>Client Portal Film Delete Email</label>';
        (<DynamicLabel>this.View.ReceiveCpFilmDeleteEmail).label = 'Client Portal Film Delete Email';

        (<DynamicField>this.Form.AssignTaskOnPhysicianExpertDocUpdate).label =
            'Assign Task when Physician/Expert documents are updated in Physician/Expert Portal';
        (<DynamicField>this.Form.AssignTaskOnPhysicianExpertDocUpdate).labelHtml =
            '<label>Assign Task when Physician/Expert documents are updated in Physician/Expert Portal</label>';
        (<DynamicField>this.View.AssignTaskOnPhysicianExpertDocUpdate).label =
            'Assign Task when Physician/Expert documents are updated in Physician/Expert Portal';
    }
}
