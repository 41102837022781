import { Component, Input } from '@angular/core';
import { IAuthUser } from '@model/interfaces/auth-user';
import { AuthEntityService } from '../../auth-entity/auth-entity.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { UserTypeValues } from '@common/constants/user-type.enum';

@Component({
    selector: 'app-change-auth-user-access',
    templateUrl: './change-auth-user-access.component.html',
})
export class ChangeAuthUserAccess {
    @Input('authUser') authUser: IAuthUser;
    @Input('isEditing') isEditing: boolean;

    constructor(private authEntitiyService: AuthEntityService, private notificationsService: NotificationsService) {}

    changeAccess(): void {
        this.authUser.HasAccess = !this.authUser.HasAccess;

        if (this.authUser.UserRole?.UserTypeId == +UserTypeValues.PhysicianExpertPortal) {
            this.authEntitiyService.changePhysicianPortalAccess(this.authUser.Id, this.authUser.HasAccess).subscribe(() => {
                this.isEditing = false;
                this.success(this.authUser);
            });
        } else {
            this.authEntitiyService.changeAccess(this.authUser.Id, this.authUser.HasAccess).subscribe(() => {
                this.isEditing = false;
                this.success(this.authUser);
            });
        }
    }

    success(authEntity: IAuthUser): void {
        this.notificationsService.success(`Access ${authEntity.HasAccess ? 'Granted' : 'Revoked'}`);
    }
}
