import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ImedClaimAdditionalBillToPartyService } from './imedclaimadditionalbilltoparty.service';
import { IImedClaimAdditionalBillToPartyDTO } from '@model/interfaces/custom/imed-claim-additional-btp.dto';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-imed-claim-additional-btp',
    styles: ['.custom-padded { margin: 0 0 0.5em 0; }'],
    templateUrl: './imed-claim-additional-btp.component.html',
})
export class ImedClaimAdditionalBTPComponent implements OnInit, OnDestroy {
    @Input() imedClaimId: number;
    @Input() canEdit: boolean;
    isHovered = false;
    doubleClickIsDisabled = false;
    editClaimANDFileNumber: boolean = false;

    additionalCarrierParties: IImedClaimAdditionalBillToPartyDTO[] = [];
    selectedAdditionalCarrierParty: IImedClaimAdditionalBillToPartyDTO;
    _imedClaimAdditionalBTPService: ImedClaimAdditionalBillToPartyService;

    _isEditing: boolean;
    public set isEditing(v: boolean) {
        this._isEditing = v;
    }

    public get isEditing(): boolean {
        return this._isEditing;
    }

    constructor(private imedClaimAdditionalBTPService: ImedClaimAdditionalBillToPartyService, private notificationsService: NotificationsService) {
        this._imedClaimAdditionalBTPService = imedClaimAdditionalBTPService;
    }

    ngOnInit(): void {
        this.isEditing = false;
        this.getCarrierParties();
        this.addMore();
    }

    ngOnDestroy(): void {
        this.imedClaimAdditionalBTPService.resetBillToParties();
    }

    addNew(): void {
        if (this.imedClaimAdditionalBTPService.newBillToParties.length === 0) {
            this.addMore();
        }
        if (this.canEdit) {
            this.isEditing = true;
        }
        this.editClaimANDFileNumber = false;
    }

    addMore(): void {
        if (this.imedClaimAdditionalBTPService.newBillToParties.length === 0 || this.isCarrierPartySelected()) {
            this.imedClaimAdditionalBTPService.newBillToParties.push(this.imedClaimAdditionalBTPService.getEmptyImedClaimAdditionalBillToParty());
        }
    }

    isCarrierPartySelected(): boolean {
        return (
            this.imedClaimAdditionalBTPService.newBillToParties.length > 0 &&
            !(this.imedClaimAdditionalBTPService.newBillToParties.filter((party) => party.AddressBookId === 0).length !== 0)
        );
    }

    getCarrierParties(): void {
        this.imedClaimAdditionalBTPService.getAdditionalBTPs(this.imedClaimId).subscribe((incomingDTOs: IImedClaimAdditionalBillToPartyDTO[]) => {
            this.additionalCarrierParties = incomingDTOs;
        });
    }

    cancel(): void {
        this.isEditing = false;
    }

    save(): void {
        // filter out empty object(s) with no address book selection
        this.imedClaimAdditionalBTPService.newBillToParties = this.imedClaimAdditionalBTPService.newBillToParties.filter(
            (x) => x.IndividualId !== 0 || x.AddressBookId !== 0,
        );
        // filter duplicates out
        this.imedClaimAdditionalBTPService.newBillToParties.forEach((newBTP) => {
            if (
                this.additionalCarrierParties.some(
                    (x) => x.IndividualId === newBTP.IndividualId || (x.AddressBookId === newBTP.AddressBookId && newBTP.IndividualId === 0),
                )
            ) {
                this.imedClaimAdditionalBTPService.newBillToParties.splice(this.imedClaimAdditionalBTPService.newBillToParties.indexOf(newBTP), 1);
                this.notificationsService.warning(
                    `${newBTP.IndividualId === 0 ? newBTP.FirmName : newBTP.Name} is already an Additional Carrier/Bill-To Party on this case`,
                );
            }
        });
        if (this.imedClaimAdditionalBTPService.newBillToParties.length > 0) {
            this.imedClaimAdditionalBTPService.AddBillToParty(this.imedClaimId, this.imedClaimAdditionalBTPService.newBillToParties).subscribe(() => {
                this.notificationsService.success('Additional Carrier/Bill-To Parties added successfully');
                this.isEditing = false;
                this.imedClaimAdditionalBTPService.newBillToParties = [];
                this.imedClaimAdditionalBTPService.newBillToParties.push(this.imedClaimAdditionalBTPService.getEmptyImedClaimAdditionalBillToParty());
                this.getCarrierParties();
            });
        } else {
            this.cancel();
        }
    }

    remove(item: IImedClaimAdditionalBillToPartyDTO, event: Event): void {
        if (event && event.stopPropagation) {
            event.stopPropagation();
        }

        this.imedClaimAdditionalBTPService.delete(item.Id).subscribe(() => {
            this.getCarrierParties();
            this.notificationsService.success('Carrier/Bill-To Party deleted successfully');
        });
    }

    onEdit(item: IImedClaimAdditionalBillToPartyDTO, event: Event): void {
        if (event && event.stopPropagation) {
            event.stopPropagation();
        }
        this.selectedAdditionalCarrierParty = item;
        if (this.canEdit) {
            this.isEditing = true;
        }
        this.editClaimANDFileNumber = true;
    }

    update(): void {
        this.imedClaimAdditionalBTPService.updateBillToParty(this.imedClaimId, this.selectedAdditionalCarrierParty).subscribe(() => {
            this.notificationsService.success('Carrier/Bill-To Party updated successfully');
            this.isEditing = false;
            this.editClaimANDFileNumber = false;
            this.getCarrierParties();
        });
    }
}
