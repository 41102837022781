import { Injectable } from '@angular/core';
import { ImedClaimTypes } from '@model/ImedClaimTypes';
import { IViewGetServicesForList } from '@model/interfaces/custom/view-get-services-for-list';
import { IImeService } from '@model/interfaces/ime-service';
import { IRecordReviewService } from '@model/interfaces/record-review-service';
import { IImedClaimService } from '@model/partials/imed-claim-service.partial';
import { AdditionalServiceTypeEnums, ServiceIdEnums, ServiceStatusIdEnums } from '../../../common/constants/Enums';

@Injectable()
export class AdditionalServicesSharedService {
    sortByDateAndFilter(additionalServicesArray: IViewGetServicesForList[], additionalServiceTypeId: number): IViewGetServicesForList[] {
        return additionalServicesArray
            .filter((x) => x.ServiceTypeId === additionalServiceTypeId)
            .sort(function (a: IViewGetServicesForList, b: IViewGetServicesForList): number {
                return new Date(b.DateCreated).getTime() - new Date(a.DateCreated).getTime();
            });
    }
    getAdditionalServiceHeaderName(additionalServiceTypeId: number): string {
        switch (additionalServiceTypeId as AdditionalServiceTypeEnums) {
            case AdditionalServiceTypeEnums.RadiologyReview:
                return 'Radiology Review Additional Services';
            case AdditionalServiceTypeEnums.Addendum:
                return 'Addendum/Additional Records Additional Services';
            case AdditionalServiceTypeEnums.ConferenceCall:
                return 'Conference Call Additional Services';
            case AdditionalServiceTypeEnums.NF10:
                return 'NF10 Additional Services';
            default:
                return 'Additional Services';
        }
    }
    getAdditionalServiceId(additionalServiceTypeId: number, claimTypeId: number): number {
        switch (additionalServiceTypeId as AdditionalServiceTypeEnums) {
            case AdditionalServiceTypeEnums.RadiologyReview:
                switch (claimTypeId as ImedClaimTypes) {
                    case ImedClaimTypes.GeneralLiability:
                        return ServiceIdEnums.Film_Review;
                    case ImedClaimTypes.WorkersCompensation:
                        return ServiceIdEnums.WC_Film_Review;
                    case ImedClaimTypes.Auto:
                        return ServiceIdEnums.Auto_Radiology_Review;
                    default:
                        return ServiceIdEnums.Film_Review;
                }
            case AdditionalServiceTypeEnums.Addendum:
                switch (claimTypeId as ImedClaimTypes) {
                    case ImedClaimTypes.GeneralLiability:
                        return ServiceIdEnums.IME_Addendum_Additional_Records;
                    case ImedClaimTypes.WorkersCompensation:
                        return ServiceIdEnums.WC_Addendum;
                    case ImedClaimTypes.Auto:
                        return ServiceIdEnums.Auto_Addendum;
                    default:
                        return ServiceIdEnums.IME_Addendum_Additional_Records;
                }
            case AdditionalServiceTypeEnums.ConferenceCall:
                switch (claimTypeId as ImedClaimTypes) {
                    case ImedClaimTypes.GeneralLiability:
                        return ServiceIdEnums.IME_Conference_Call;
                    case ImedClaimTypes.WorkersCompensation:
                        return ServiceIdEnums.WC_Conference_Call;
                    case ImedClaimTypes.Auto:
                        return ServiceIdEnums.Auto_Conference_Call;
                    default:
                        return ServiceIdEnums.IME_Conference_Call;
                }
            case AdditionalServiceTypeEnums.NF10:
                return ServiceIdEnums.Auto_NF_10;
            default:
                return ServiceIdEnums.Film_Review;
        }
    }
    getAdditionalGLServiceStatus(additionalServiceTypeId: number, parentServiceTypeId: number): number {
        switch (additionalServiceTypeId as AdditionalServiceTypeEnums) {
            case AdditionalServiceTypeEnums.RadiologyReview:
                switch (parentServiceTypeId as ServiceIdEnums) {
                    case ServiceIdEnums.Record_Review:
                        return ServiceStatusIdEnums.Radiology_Review_Record_Review_Related_Service;
                    case ServiceIdEnums.IME:
                        return ServiceStatusIdEnums.Radiology_Review_IME_related_service;
                    case ServiceIdEnums.IME_Re_Examination:
                        return ServiceStatusIdEnums.Radiology_Review_IME_re_exam_related_service;
                    case ServiceIdEnums.IME_Expert_Consultation:
                        return ServiceStatusIdEnums.Radiology_Review_Expert_Consultation_related_service;
                    case ServiceIdEnums.IME_Conference_Call:
                        return ServiceStatusIdEnums.Radiology_Review_IME_related_service;
                    default:
                        return ServiceStatusIdEnums.Radiology_Review_IME_related_service;
                }
            case AdditionalServiceTypeEnums.Addendum:
                switch (parentServiceTypeId as ServiceIdEnums) {
                    case ServiceIdEnums.Record_Review:
                        return ServiceStatusIdEnums.IME_Addendum_Record_Review_Related_Service;
                    case ServiceIdEnums.IME:
                        return ServiceStatusIdEnums.IME_Addendum_IME_related_service;
                    case ServiceIdEnums.IME_Re_Examination:
                        return ServiceStatusIdEnums.IME_Addendum_IME_re_exam_related_service;
                    case ServiceIdEnums.IME_Expert_Consultation:
                        return ServiceStatusIdEnums.IME_Addendum_IME_expert_consultation_related_service;
                    case ServiceIdEnums.IME_Conference_Call:
                        return ServiceStatusIdEnums.IME_Addendum_IME_related_service;
                    default:
                        return ServiceStatusIdEnums.IME_Addendum_IME_related_service;
                }
            case AdditionalServiceTypeEnums.ConferenceCall:
                switch (parentServiceTypeId as ServiceIdEnums) {
                    case ServiceIdEnums.Record_Review:
                        return ServiceStatusIdEnums.IME_Conference_Call_Record_Review_Related_Service;
                    case ServiceIdEnums.IME:
                        return ServiceStatusIdEnums.IME_Conference_Call_IME_related_service;
                    case ServiceIdEnums.IME_Re_Examination:
                        return ServiceStatusIdEnums.IME_Conference_Call_IME_re_exam_related_service;
                    case ServiceIdEnums.IME_Expert_Consultation:
                        return ServiceStatusIdEnums.IME_Conference_Call_IME_expert_consultation_related_service;
                    case ServiceIdEnums.IME_Conference_Call:
                        return ServiceStatusIdEnums.IME_Conference_Call_IME_related_service;
                    default:
                        return ServiceStatusIdEnums.IME_Conference_Call_IME_related_service;
                }
            default:
                return 0;
        }
    }
    getAdditionalWCServiceStatus(additionalServiceTypeId: number, parentServiceTypeId: number): number {
        switch (additionalServiceTypeId as AdditionalServiceTypeEnums) {
            case AdditionalServiceTypeEnums.RadiologyReview:
                switch (parentServiceTypeId as ServiceIdEnums) {
                    case ServiceIdEnums.WC_Record_Review:
                        return ServiceStatusIdEnums.WC_Radiology_Review_Record_Review_Related_Service;
                    case ServiceIdEnums.IME_WC:
                        return ServiceStatusIdEnums.WC_Radiology_Review_IME_related_service;
                    case ServiceIdEnums.WC_IME_Re_Examination:
                        return ServiceStatusIdEnums.WC_Radiology_Review_IME_re_exam_related_service;
                    case ServiceIdEnums.WC_Functional_Capacity:
                        return ServiceStatusIdEnums.WC_Radiology_Review_IME_related_service;
                    case ServiceIdEnums.WC_Conference_Call:
                        return ServiceStatusIdEnums.WC_Radiology_Review_IME_related_service;
                    default:
                        return ServiceStatusIdEnums.WC_Radiology_Review_IME_related_service;
                }
            case AdditionalServiceTypeEnums.Addendum:
                switch (parentServiceTypeId as ServiceIdEnums) {
                    case ServiceIdEnums.WC_Record_Review:
                        return ServiceStatusIdEnums.WC_Addendum_Record_Review_Related_Service;
                    case ServiceIdEnums.IME_WC:
                        return ServiceStatusIdEnums.WC_Addendum_IME_related_service;
                    case ServiceIdEnums.WC_IME_Re_Examination:
                        return ServiceStatusIdEnums.WC_Addendum_IME_re_exam_related_service;
                    case ServiceIdEnums.WC_Functional_Capacity:
                        return ServiceStatusIdEnums.WC_Addendum_IME_related_service;
                    case ServiceIdEnums.WC_Conference_Call:
                        return ServiceStatusIdEnums.WC_Addendum_IME_related_service;
                    default:
                        return ServiceStatusIdEnums.WC_Addendum_IME_related_service;
                }
            case AdditionalServiceTypeEnums.ConferenceCall:
                switch (parentServiceTypeId as ServiceIdEnums) {
                    case ServiceIdEnums.WC_Record_Review:
                        return ServiceStatusIdEnums.WC_Conference_Call_Record_Review_Related_Service;
                    case ServiceIdEnums.IME_WC:
                        return ServiceStatusIdEnums.WC_Conference_Call_IME_related_service;
                    case ServiceIdEnums.WC_IME_Re_Examination:
                        return ServiceStatusIdEnums.WC_Conference_Call_IME_re_exam_related_service;
                    case ServiceIdEnums.WC_Functional_Capacity:
                        return ServiceStatusIdEnums.WC_Conference_Call_IME_related_service;
                    case ServiceIdEnums.WC_Conference_Call:
                        return ServiceStatusIdEnums.WC_Conference_Call_IME_related_service;
                    default:
                        return ServiceStatusIdEnums.WC_Conference_Call_IME_related_service;
                }
            default:
                return 0;
        }
    }
    getAdditionalAutoServiceStatus(additionalServiceTypeId: number, parentServiceTypeId: number): number {
        switch (additionalServiceTypeId as AdditionalServiceTypeEnums) {
            case AdditionalServiceTypeEnums.RadiologyReview:
                switch (parentServiceTypeId as ServiceIdEnums) {
                    case ServiceIdEnums.AUTO_Record_Review:
                        return ServiceStatusIdEnums.Auto_Radiology_Review_Record_Review_Related_Service;
                    case ServiceIdEnums.IME_Auto:
                        return ServiceStatusIdEnums.Auto_Radiology_IME_related_service;
                    case ServiceIdEnums.Auto_Conference_Call:
                        return ServiceStatusIdEnums.Auto_Radiology_IME_related_service;
                    case ServiceIdEnums.Auto_Re_Exam:
                        return ServiceStatusIdEnums.Auto_Radiology_IME_re_exam_related_service;
                    default:
                        return ServiceStatusIdEnums.Auto_Radiology_IME_related_service;
                }
            case AdditionalServiceTypeEnums.Addendum:
                switch (parentServiceTypeId as ServiceIdEnums) {
                    case ServiceIdEnums.AUTO_Record_Review:
                        return ServiceStatusIdEnums.Auto_IME_Addendum_Record_Review_Related_Service;
                    case ServiceIdEnums.IME_Auto:
                        return ServiceStatusIdEnums.Auto_Addendum_IME_related_service;
                    case ServiceIdEnums.Auto_Conference_Call:
                        return ServiceStatusIdEnums.Auto_Addendum_IME_related_service;
                    case ServiceIdEnums.Auto_Re_Exam:
                        return ServiceStatusIdEnums.Auto_Addendum_IME_re_exam_related_service;
                    default:
                        return ServiceStatusIdEnums.Auto_Addendum_IME_related_service;
                }
            case AdditionalServiceTypeEnums.ConferenceCall:
                switch (parentServiceTypeId as ServiceIdEnums) {
                    case ServiceIdEnums.AUTO_Record_Review:
                        return ServiceStatusIdEnums.Auto_IME_Conference_Call_Record_Review_Related_Service;
                    case ServiceIdEnums.IME_Auto:
                        return ServiceStatusIdEnums.Auto_Conference_IME_related_service;
                    case ServiceIdEnums.Auto_Conference_Call:
                        return ServiceStatusIdEnums.Auto_Conference_IME_related_service;
                    case ServiceIdEnums.Auto_Re_Exam:
                        return ServiceStatusIdEnums.Auto_Conference_IME_re_exam_related_service;
                    default:
                        return ServiceStatusIdEnums.Auto_Conference_IME_related_service;
                }
            case AdditionalServiceTypeEnums.NF10:
                switch (parentServiceTypeId as ServiceIdEnums) {
                    case ServiceIdEnums.AUTO_Record_Review:
                        return ServiceStatusIdEnums.Auto_NF_10_Record_review_related_service;
                    case ServiceIdEnums.AUTO_Peer_Review:
                        return ServiceStatusIdEnums.Auto_NF_10_Record_review_related_service;
                    case ServiceIdEnums.IME_Auto:
                        return ServiceStatusIdEnums.Auto_NF_10_IME_related_service;
                    case ServiceIdEnums.Auto_Re_Exam:
                        return ServiceStatusIdEnums.Auto_NF_10_IME_re_exam_related_service;
                    default:
                        return ServiceStatusIdEnums.Auto_NF_10_IME_related_service;
                }
            default:
                return 0;
        }
    }

    getAdditionalServicesCount(additionalServiceTypeId: number, additionalServices: IViewGetServicesForList[]): number {
        switch (additionalServiceTypeId as AdditionalServiceTypeEnums) {
            case AdditionalServiceTypeEnums.RadiologyReview:
                return additionalServices.filter(
                    (x) =>
                        x.ServiceTypeId === +ServiceIdEnums.Film_Review ||
                        x.ServiceTypeId === +ServiceIdEnums.WC_Film_Review ||
                        x.ServiceTypeId === +ServiceIdEnums.Auto_Radiology_Review,
                ).length;
            case AdditionalServiceTypeEnums.Addendum:
                return additionalServices.filter(
                    (x) =>
                        x.ServiceTypeId === +ServiceIdEnums.IME_Addendum_Additional_Records ||
                        x.ServiceTypeId === +ServiceIdEnums.WC_Addendum ||
                        x.ServiceTypeId === +ServiceIdEnums.Auto_Addendum,
                ).length;
            case AdditionalServiceTypeEnums.ConferenceCall:
                return additionalServices.filter(
                    (x) =>
                        x.ServiceTypeId === +ServiceIdEnums.IME_Conference_Call ||
                        x.ServiceTypeId === +ServiceIdEnums.WC_Conference_Call ||
                        x.ServiceTypeId === +ServiceIdEnums.Auto_Conference_Call,
                ).length;
            case AdditionalServiceTypeEnums.NF10:
                return additionalServices.filter((x) => x.ServiceTypeId === +ServiceIdEnums.Auto_NF_10).length;
            default:
                return 0;
        }
    }

    assignValuesToNewService(
        newService: IImedClaimService,
        parentService: IImedClaimService,
        serviceStatusId: number,
        additionalServiceTypeId: number,
        imeOrRecordReviewService: IRecordReviewService | IImeService,
    ): IImedClaimService {
        newService.SpecialtyRequestedId = parentService.SpecialtyRequestedId;
        newService.PhysicianId = parentService.PhysicianId;
        newService.ServiceAddressId = parentService.ServiceAddressId;
        newService.ServiceAssignedToId = parentService.ServiceAssignedToId;
        newService.HighValueService = parentService.HighValueService;
        newService.RushService = parentService.RushService;
        newService.ServiceStatusId = serviceStatusId;
        switch (parentService.ImedClaim.ClaimTypeId as ImedClaimTypes) {
            case ImedClaimTypes.GeneralLiability:
                switch (additionalServiceTypeId as AdditionalServiceTypeEnums) {
                    case AdditionalServiceTypeEnums.RadiologyReview:
                        newService.FilmReviewServices[0].HasPreLitigation = imeOrRecordReviewService.HasPreLitigation;
                        newService.FilmReviewServices[0].IsFederalCase = imeOrRecordReviewService.IsFederalCase;
                        break;
                    case AdditionalServiceTypeEnums.Addendum:
                    case AdditionalServiceTypeEnums.ConferenceCall:
                        newService.ImeServices_ImedClaimServiceId[0].HasPreLitigation = imeOrRecordReviewService.HasPreLitigation;
                        newService.ImeServices_ImedClaimServiceId[0].IsFederalCase = imeOrRecordReviewService.IsFederalCase;
                        newService.ImeServices_ImedClaimServiceId[0].PatientPreviouslyTreatedByPhysician =
                            imeOrRecordReviewService.PatientPreviouslyTreatedByPhysician;
                        break;
                    default:
                        break;
                }
                break;
            case ImedClaimTypes.WorkersCompensation:
                newService.PurposeOfExam = parentService.PurposeOfExam;
                newService.ContractRateFrom = parentService.ContractRateFrom;
                newService.ContractRateTo = parentService.ContractRateTo;
                switch (additionalServiceTypeId as AdditionalServiceTypeEnums) {
                    case AdditionalServiceTypeEnums.Addendum:
                    case AdditionalServiceTypeEnums.ConferenceCall:
                        newService.ImeServices_ImedClaimServiceId[0].PatientPreviouslyTreatedByPhysician =
                            imeOrRecordReviewService.PatientPreviouslyTreatedByPhysician;
                        break;
                    default:
                        break;
                }
                break;
            case ImedClaimTypes.Auto:
                switch (additionalServiceTypeId as AdditionalServiceTypeEnums) {
                    case AdditionalServiceTypeEnums.Addendum:
                    case AdditionalServiceTypeEnums.ConferenceCall:
                        newService.ImeServices_ImedClaimServiceId[0].PatientPreviouslyTreatedByPhysician =
                            imeOrRecordReviewService.PatientPreviouslyTreatedByPhysician;
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }
        return newService;
    }
}
