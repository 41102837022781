import { RecordRetrievalServiceDynamicControls } from '@model/form-controls/record-retrieval-service.form-controls';
import { IRecordRetrievalService } from '@model/interfaces/record-retrieval-service.d';
import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { RecordRetrievalServiceDynamicConfig } from '../record-retrieval-service-client.dynamic-config';
import { RecordRetrievalServiceClientService } from '../record-retrieval-service-client.service';
import { finalize } from 'rxjs/operators';
import { DynamicField, DynamicLabel, IDynamicFormConfig } from '@mt-ng2/dynamic-form';

@Component({
    selector: 'app-record-retrieval-radiology-facility-info-client',
    templateUrl: './radiology-facility-info-client.component.html',
})
export class RecordRetrievalRadiologyFacilityClientComponent implements OnInit, OnChanges {
    @Input() recordRetrievalServiceModel: IRecordRetrievalService;
    @Input() isAddingFilmInfo = false;
    isEditing: boolean;

    config: IDynamicFormConfig;
    recordRetrievalServiceForm: any;
    recordRetrievalformFactory: RecordRetrievalServiceDynamicConfig<IRecordRetrievalService>;

    doubleClickIsDisabled = false;
    controls: any;

    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    constructor(
        private notificationsService: NotificationsService,
        private fb: UntypedFormBuilder,
        private recordRetrievalService: RecordRetrievalServiceClientService,
    ) { }

    ngOnInit(): void {
        this.config = { formObject: [], viewOnly: [] };
        this.isEditing = false;
        this.setConfig();
    }

    ngOnChanges(): void {
        this.setConfig();
    }

    setConfig(): void {
        this.recordRetrievalServiceForm = this.fb.group({
            RecordRetrievalService: this.fb.group({}),
        });

        this.controls = new RecordRetrievalServiceDynamicControls(this.recordRetrievalServiceModel);

        this.recordRetrievalformFactory = new RecordRetrievalServiceDynamicConfig<IRecordRetrievalService>(this.recordRetrievalServiceModel, [
            'RadiologyFacility',
        ]);
        this.config = this.recordRetrievalformFactory.getForUpdate();

        this.viewOnly = this.config?.viewOnly?.map((x) => new DynamicLabel(x));
        this.formObject = this.config.formObject?.map((x) => new DynamicField(x));
    }

    edit(): void {
        this.isEditing = true;
    }

    cancelClick(): void {
        this.isEditing = false;
    }

    formSubmitted(): void {
        this.recordRetrievalformFactory.assignFormValues(
            this.recordRetrievalServiceModel,
            this.recordRetrievalServiceForm.value.RecordRetrievalService as IRecordRetrievalService,
        );
        this.recordRetrievalService
            .update(this.recordRetrievalServiceModel)
            .subscribe(() => {
                this.isEditing = false;
                this.success();
                this.recordRetrievalService.emitChange(this.recordRetrievalServiceModel);
                this.setConfig();
            });
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }
}
