import { Component, OnInit } from '@angular/core';
import { IMasterServiceStatus } from '@model/interfaces/master-service-status';
import { MasterServiceStatusService } from '../services/master-service-status.service';

@Component({
    templateUrl: './master-service-statuses-add.component.html',
})
export class MasterServiceStatusAddComponent implements OnInit {
    masterServiceStatus: IMasterServiceStatus;
    canEdit = true; // route guard ensures this component wouldn't be loaded if user didn't have permission already

    constructor(private masterServiceStatusService: MasterServiceStatusService) {}

    ngOnInit(): void {
        this.masterServiceStatus = this.masterServiceStatusService.getEmptyMasterServiceStatus();
    }
}
