// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            .custom-padded {
                margin: 0 0 0.5em 0;
            }
            .small-text {
                font-size: 12px;
            }
        `, "",{"version":3,"sources":["webpack://./src/app/imed-claims/imed-claim-treating-physicians/imed-claim-treating-physicians.service.component.ts"],"names":[],"mappings":";YACY;gBACI,mBAAmB;YACvB;YACA;gBACI,eAAe;YACnB","sourcesContent":["\n            .custom-padded {\n                margin: 0 0 0.5em 0;\n            }\n            .small-text {\n                font-size: 12px;\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
