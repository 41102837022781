import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { Observable, BehaviorSubject, OperatorFunction } from 'rxjs';
import { SearchParams } from '@mt-ng2/common-classes';
import { IViewGetFollowUpsForList } from '@model/interfaces/view-get-follow-ups-for-list';
import { catchError } from 'rxjs/operators';
import { ICreateFollowupResponseDto } from '@model/interfaces/custom/create-followup-response-dto';
import { IFollowupsWithSpecialInformationDto } from '@model/interfaces/custom/followups-with-prelim-invoice-date-dto';
import { IImedClaimFollowup } from '@model/interfaces/imed-claim-followup';
import { IViewGetCaseFollowupsForList } from '@model/interfaces/view-get-case-followups-for-list';
import { IImedClaimServiceSplitBillingDetail } from '@model/interfaces/imed-claim-service-split-billing-detail';

export const emptyImedClaimFollowup: IImedClaimFollowup = {
    Amount: null,
    Archived: null,
    CheckNumber: null,
    ClearanceDate: null,
    CommunicationMethod: null,
    ConfirmedCancellationWithPhysiciansOfficeSpokeWith: null,
    ConfirmedWithPhysiciansOfficeSpokeWith: null,
    CreatedById: null,
    Date: new Date(), // default to today's date
    DateCreated: null,
    DeferredUntil: null,
    MasterFollowupTypeId: null,
    Id: 0,
    ImeAppointmentConfirmedWithPlaintiffsAttorneyOfficeByName: null,
    ImedClaimId: null,
    Notes: '',
    PersonContactedOrSpokenTo: null,
    ReceiptOfNoticeOfImeConfirmedByName: null,
    ReceivedAppointmentDateTime: null,
    ReportDueBy: null,
    ReportForwardedToClientDate: null,
    ReportReadyOnOrAround: null,
    TrackingNumber: null,
    Via: null,
};

@Injectable()
export class ImedClaimFollowupService extends BaseService<IImedClaimFollowup> {
    constructor(public http: HttpClient) {
        super('/imedclaimfollowups', http);
    }

    createFollowup(followUp: IImedClaimFollowup): Observable<HttpResponse<ICreateFollowupResponseDto>> {
        return this.http.post<ICreateFollowupResponseDto>('/imedclaimfollowups', followUp, { observe: 'response' });
    }

    getEmptyImedClaimFollowup(): IImedClaimFollowup {
        return { ...emptyImedClaimFollowup };
    }

    search(searchparameters: SearchParams, imedClaimId: number): Observable<HttpResponse<IImedClaimFollowup[]>> {
        const params = this.getHttpParams(searchparameters);
        return this.http
            .get<IImedClaimFollowup[]>('/imedclaimfollowups/_search/' + imedClaimId, { observe: 'response', params: params })
            .pipe(catchError((e: Response) => this.handleError(e)) as OperatorFunction<unknown, any>);
    }

    getFollowupsAndPrelimInvoiceDate(searchparameters: SearchParams, imedClaimId: number): Observable<IFollowupsWithSpecialInformationDto> {
        const params = this.getHttpParams(searchparameters);
        return this.http
            .get<IFollowupsWithSpecialInformationDto>('/imedclaimfollowups/followups-with-prelim-invoice-date/' + imedClaimId, {
                observe: 'body',
                params: params,
            })
            .pipe(catchError((e: Response) => this.handleError(e)) as OperatorFunction<unknown, any>);
    }

    searchForListPage(searchparameters: SearchParams, imedClaimId: number): Observable<HttpResponse<IViewGetCaseFollowupsForList[]>> {
        const params = this.getHttpParams(searchparameters);
        return this.http
            .get<IViewGetFollowUpsForList[]>('/imedclaimfollowups/_searchList/' + imedClaimId, { observe: 'response', params: params })
            .pipe(catchError((e: Response) => this.handleError(e)) as OperatorFunction<unknown, any>);
    }

    getLatestFollowup(imedClaimId: number): Observable<IImedClaimFollowup> {
        return this.http.get<IImedClaimFollowup>(`/imedclaimfollowups/get-latest-follow-up/${imedClaimId}`);
    }

    archive(id: number): any {
        return this.http.put<number>(`/imedclaimfollowups/archive/${id}`, {});
    }

    createImedClaimServiceSplitBillingDetails(splitBillingDetails: IImedClaimServiceSplitBillingDetail[], imedClaimId:number): Observable<HttpResponse<IImedClaimServiceSplitBillingDetail>> {
        return this.http.post<IImedClaimServiceSplitBillingDetail>(`/imedclaimfollowups/split-billing-details/${imedClaimId}`, splitBillingDetails, { observe: 'response' });
    }
}
