import { RouteReuseStrategy } from '@angular/router/';
import { ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';
import { CommonSearchService } from '../services/common-search.service';
import { TaskService } from '../../tasks/task.service';
import { Injector } from '@angular/core';
import { ReuseRoute } from './reuse-route.library';

// Class that implements Angular's RouteReuseStrategy abstract class
// Implementing these methods allows application state to be manually cached and retrieved
// https://angular.io/api/router/RouteReuseStrategy
export class CacheSearchReuseStrategy implements RouteReuseStrategy {
    storedRouteHandles = new Map<string, DetachedRouteHandle>();
    allowRetrieveCache = {
        'address-books': true,
        'cases': true,
        'services': true,
        'tasks': true,
    };

    constructor(private commonSearchService: CommonSearchService, private injector: Injector) {}

    // Determines if a route should be reused by setting values in the allowRetrieveCache dictionary.
    shouldReuseRoute(before: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        const beforePath = this.getPath(before);
        const currentPath = this.getPath(curr);

        // Don't retrieve cached service list page when coming from dashboard or when navigating to list page from claimant page
        this.allowRetrieveCache.services = !(
            (beforePath === 'cases/:imedClaimId' && currentPath === 'services') ||
            this.commonSearchService.searchFilters
        );

        // Conditon to prevent list page from being reused when coming from dashboard case search widget
        this.allowRetrieveCache.cases = this.commonSearchService.searchFilters === null;

        // Manually injecting TaskService because DI was not working through the constructor
        // Condition to prevent cached task list page from being reused when coming from case task list see all button
        const claimId = this.injector.get(TaskService).getClaimIdFromClaimDetail();
        this.allowRetrieveCache.tasks = claimId === null || claimId === undefined;

        return before.routeConfig === curr.routeConfig;
    }

    // Retrieves a previously stored route
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        const routeHandle: any = this.storedRouteHandles.get(this.getPath(route));
        if (routeHandle && this.componentHasOnReuseRoute(route)) {
            (routeHandle.componentRef.instance as ReuseRoute).onRouteReuse();
        }
        return routeHandle;
    }

    // Determines if this route (and its subtree) should be reattached
    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        const path = this.getPath(route);

        // Clear stored routes on log out
        if (path.endsWith('login')) {
            this.storedRouteHandles.clear();
            return false;
        }

        if (this.allowRetrieveCache[path]) {
            if (path === 'services' && route.parent && route.parent.paramMap.get('imedClaimId') === '0') {
                return this.storedRouteHandles.has(this.getPath(route));
            } else if (path !== 'services') {
                return this.storedRouteHandles.has(this.getPath(route));
            }
        }
        return false;
    }

    // Determines if this route (and its subtree) should be detached to be reused later
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        const path = this.getPath(route);
        return (
            Object.prototype.hasOwnProperty.call(this.allowRetrieveCache, path) &&
            (path !== 'services' || (route.parent && route.parent.paramMap.get('imedClaimId') === '0'))
        );
    }

    // Stores the detached route in the storeRouteHandles Map
    store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {
        this.storedRouteHandles.set(this.getPath(route), detachedTree);
    }

    /**
     * determines if the route has a component that is implementing the onRouteReuse method
     */
    private componentHasOnReuseRoute(route: ActivatedRouteSnapshot): boolean {
        const componentPrototype = (route && route.component && (route.component as any).prototype) || null;
        if (componentPrototype) {
            return (componentPrototype as ReuseRoute).onRouteReuse ? true : false;
        }
        return false;
    }

    // Gets the path string from ActivatedRouteSnapshot
    private getPath(route: ActivatedRouteSnapshot): string {
        if (route && route.routeConfig !== null && route.routeConfig.path !== null) {
            return route.routeConfig.path;
        }
        return '';
    }
}
