import { NgModule } from '@angular/core';
import { SharedModule } from '../common/shared.module';
import { EmailTemplateRoutingModule } from './email-template-routing.module';
import { EmailTemplateBasicInfoComponent } from './email-template-basic-info/email-template-basic-info.component';
import { EmailTemplateDetailComponent } from './email-template-detail/email-template-detail.component';
import { EmailTemplatesComponent } from './email-template-list/email-templates.component';
import { EmailTemplateHeaderComponent } from './email-template-header/email-template-header.component';
import { EmailTemplateService } from './emailtemplate.service';
import { EmailTemplateDeleteCellComponent } from './email-template-list/email-template-delete-cell.component';

@NgModule({
    declarations: [
        EmailTemplatesComponent,
        EmailTemplateHeaderComponent,
        EmailTemplateDetailComponent,
        EmailTemplateDeleteCellComponent,
        EmailTemplateBasicInfoComponent,
    ],
    imports: [SharedModule, EmailTemplateRoutingModule]
})
export class EmailTemplateModule {
    static forRoot(): any {
        return {
            ngModule: EmailTemplateModule,
            providers: [EmailTemplateService],
        };
    }
}
