import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, OperatorFunction } from 'rxjs';

import { BaseService } from '@mt-ng2/base-service';
import { IImedClaimAdditionalBillToParty } from '@model/interfaces/imed-claim-additional-bill-to-party';
import { IImedClaimAdditionalBillToPartyDTO } from '@model/interfaces/custom/imed-claim-additional-btp.dto';
import { catchError } from 'rxjs/operators';

export const emptyImedClaimAdditionalBillToPartyDTO: IImedClaimAdditionalBillToPartyDTO = {
    AddressBookId: 0,
    Id: 0,
    IndividualId: 0,
    Name: '',
};

@Injectable()
export class ImedClaimAdditionalBillToPartyService extends BaseService<IImedClaimAdditionalBillToParty> {
    newBillToParties: IImedClaimAdditionalBillToPartyDTO[] = [];

    constructor(public http: HttpClient) {
        super('/imedclaims', http);
    }

    resetBillToParties(): void {
        this.newBillToParties = [];
    }
    getEmptyImedClaimAdditionalBillToParty(): IImedClaimAdditionalBillToPartyDTO {
        return { ...emptyImedClaimAdditionalBillToPartyDTO };
    }

    getAdditionalBTPs(imedClaimId: number): Observable<IImedClaimAdditionalBillToPartyDTO[]> {
        return this.http.get<IImedClaimAdditionalBillToPartyDTO[]>(`/imedclaims/${imedClaimId}/additional-btps`);
    }

    AddBillToParty(imedClaimId: number, dtos: IImedClaimAdditionalBillToPartyDTO[]): Observable<number> {
        return this.http.post<number>(`/imedclaims/${imedClaimId}/additional-btps`, dtos);
    }

    delete(id: number): Observable<any> {
        return this.http
            .delete(`/imedclaims/${id}/additional-btps`)
            .pipe(catchError((e: Response) => this.handleError(e)) as OperatorFunction<unknown, any>);
    }

    updateBillToParty(imedClaimId: number, additionalCarrierParty: IImedClaimAdditionalBillToPartyDTO): Observable<number> {
        return this.http.put<number>(`/imedclaims/${imedClaimId}/update-additional-btp`, additionalCarrierParty);
    }
}
