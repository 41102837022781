import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IEmailAddress } from '../interfaces/email-address';
import { IUser } from '../interfaces/user';
import { IEmailType } from '../interfaces/email-type';

export interface IEmailAddressDynamicControlsParameters {
    formGroup?: string;
    emailTypes?: IEmailType[];
    createdBies?: IUser[];
    modifiedBies?: IUser[];
}

export class EmailAddressDynamicControls {

    formGroup: string;
    emailTypes: IEmailType[];
    createdBies: IUser[];
    modifiedBies: IUser[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private emailaddress?: IEmailAddress, additionalParameters?: IEmailAddressDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'EmailAddress';
        this.emailTypes = additionalParameters && additionalParameters.emailTypes || undefined;
        this.createdBies = additionalParameters && additionalParameters.createdBies || undefined;
        this.modifiedBies = additionalParameters && additionalParameters.modifiedBies || undefined;

        this.Form = {
            Archived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived',
                name: 'Archived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.emailaddress && this.emailaddress.hasOwnProperty('Archived') && this.emailaddress.Archived !== null ? this.emailaddress.Archived : false,
            }),
            CreatedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Created By',
                name: 'CreatedById',
                options: this.createdBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.emailaddress && this.emailaddress.CreatedById || null,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.emailaddress && this.emailaddress.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.emailaddress && this.emailaddress.DateModified || null,
            }),
            Email: new DynamicField({
                formGroup: this.formGroup,
                label: 'Email',
                name: 'Email',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(100), Validators.email ],
                validators: { 'required': true, 'maxlength': 100, 'email': true },
                value: this.emailaddress && this.emailaddress.hasOwnProperty('Email') && this.emailaddress.Email !== null ? this.emailaddress.Email.toString() : '',
            }),
            EmailTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Email Type',
                name: 'EmailTypeId',
                options: this.emailTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.emailaddress && this.emailaddress.EmailTypeId || null,
            }),
            FacilityEmailLegacyId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Facility Email Legacy',
                name: 'FacilityEmailLegacyId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(20) ],
                validators: { 'maxlength': 20 },
                value: this.emailaddress && this.emailaddress.hasOwnProperty('FacilityEmailLegacyId') && this.emailaddress.FacilityEmailLegacyId !== null ? this.emailaddress.FacilityEmailLegacyId.toString() : '',
            }),
            IndividualEmailLegacyId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Individual Email Legacy',
                name: 'IndividualEmailLegacyId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(20) ],
                validators: { 'maxlength': 20 },
                value: this.emailaddress && this.emailaddress.hasOwnProperty('IndividualEmailLegacyId') && this.emailaddress.IndividualEmailLegacyId !== null ? this.emailaddress.IndividualEmailLegacyId.toString() : '',
            }),
            IsPrimary: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Primary',
                name: 'IsPrimary',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.emailaddress && this.emailaddress.hasOwnProperty('IsPrimary') && this.emailaddress.IsPrimary !== null ? this.emailaddress.IsPrimary : false,
            }),
            ModifiedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Modified By',
                name: 'ModifiedById',
                options: this.modifiedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.emailaddress && this.emailaddress.ModifiedById || null,
            }),
            Notes: new DynamicField({
                formGroup: this.formGroup,
                label: 'Notes',
                name: 'Notes',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(400) ],
                validators: { 'maxlength': 400 },
                value: this.emailaddress && this.emailaddress.hasOwnProperty('Notes') && this.emailaddress.Notes !== null ? this.emailaddress.Notes.toString() : '',
            }),
        };

        this.View = {
            Archived: new DynamicLabel({
			    label: 'Archived',
			    value: this.emailaddress && this.emailaddress.hasOwnProperty('Archived') && this.emailaddress.Archived !== null ? this.emailaddress.Archived : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            CreatedById: new DynamicLabel({
			    label: 'Created By',
			    value: getMetaItemValue(this.createdBies as unknown as IMetaItem[], this.emailaddress && this.emailaddress.hasOwnProperty('CreatedById') && this.emailaddress.CreatedById !== null ? this.emailaddress.CreatedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            DateCreated: new DynamicLabel({
			    label: 'Date Created',
			    value: this.emailaddress && this.emailaddress.DateCreated || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DateModified: new DynamicLabel({
			    label: 'Date Modified',
			    value: this.emailaddress && this.emailaddress.DateModified || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            Email: new DynamicLabel({
			    label: 'Email',
			    value: this.emailaddress && this.emailaddress.hasOwnProperty('Email') && this.emailaddress.Email !== null ? this.emailaddress.Email.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            EmailTypeId: new DynamicLabel({
			    label: 'Email Type',
			    value: getMetaItemValue(this.emailTypes as unknown as IMetaItem[], this.emailaddress && this.emailaddress.hasOwnProperty('EmailTypeId') && this.emailaddress.EmailTypeId !== null ? this.emailaddress.EmailTypeId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            FacilityEmailLegacyId: new DynamicLabel({
			    label: 'Facility Email Legacy',
			    value: this.emailaddress && this.emailaddress.hasOwnProperty('FacilityEmailLegacyId') && this.emailaddress.FacilityEmailLegacyId !== null ? this.emailaddress.FacilityEmailLegacyId.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            IndividualEmailLegacyId: new DynamicLabel({
			    label: 'Individual Email Legacy',
			    value: this.emailaddress && this.emailaddress.hasOwnProperty('IndividualEmailLegacyId') && this.emailaddress.IndividualEmailLegacyId !== null ? this.emailaddress.IndividualEmailLegacyId.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            IsPrimary: new DynamicLabel({
			    label: 'Is Primary',
			    value: this.emailaddress && this.emailaddress.hasOwnProperty('IsPrimary') && this.emailaddress.IsPrimary !== null ? this.emailaddress.IsPrimary : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ModifiedById: new DynamicLabel({
			    label: 'Modified By',
			    value: getMetaItemValue(this.modifiedBies as unknown as IMetaItem[], this.emailaddress && this.emailaddress.hasOwnProperty('ModifiedById') && this.emailaddress.ModifiedById !== null ? this.emailaddress.ModifiedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Notes: new DynamicLabel({
			    label: 'Notes',
			    value: this.emailaddress && this.emailaddress.hasOwnProperty('Notes') && this.emailaddress.Notes !== null ? this.emailaddress.Notes.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };

    }
}
