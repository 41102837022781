import { ILabelGenerator } from '../../../common/interfaces/ILabelGenerator.d';
import { DatePipe } from '../../../../../node_modules/@angular/common';
import { IPhysicianStatusLogPartial } from '@model/partials/physician-status-log-partial';

export class PhysicianStatusLabelGenerator implements ILabelGenerator {
    constructor() {}

    GetLabel(entity: IPhysicianStatusLogPartial): string {
        return `<div class="status-log-info">Changed to Status <b>${entity.PhysicianStatus.Name}</b><div class="pull-right"><b><font color='red'>${
            entity.PhysicianDetail?.ImedClaimType?.Name
        }</font> </b></div>
        </div><div class="status-log-subtext">by User <b>${entity.ChangedById === 0 ? 'System' : entity.User?.FirstName ?? ''}</b> on ${new DatePipe(
            'en-US',
        ).transform(entity.ChangedDate, 'MMMM d, yyyy', 'UTC')}
         </div><div class="status-log-clear"></div>`;
    }
}
