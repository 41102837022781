import { AddressService } from './../addresses/address.service';
import { IEntity } from '@model/interfaces/base.d';
import { ExtraSearchParams, IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { IIndividual } from '@model/interfaces/individual.d';
import { IAddressBookWithPrimaryAddress } from '@model/interfaces/custom/address-book-with-primary-address';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IndividualService } from './../individuals/individual.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Observable, forkJoin, of, Subscription } from 'rxjs';
import { tap, catchError, finalize } from 'rxjs/operators';
import { IAddressBook } from '@model/interfaces/address-book';
import { AddressBookService } from '../addressbook.service';
import { ClaimsService, ClaimValues, AuthService } from '@mt-ng2/auth-module';
import { ClaimTypes } from '@model/ClaimTypes';
import {
    MetaAddressBookTypeIdEnums,
    AttachmentServiceTypeEnums,
    ViewLogEntityTypeEnums,
    AutomatedTemplateFaxEmailEntityTypeEnums,
    AddressBookTypeIdEnums,
} from '../../common/constants/Enums';
import { IndividualLabelGenerator } from '../individual.label';
import { AddressBookSharedEntities } from '../shared/addressbook.shared-entities';
import { PhoneTypeService } from '../phonetype.service';
import { AddressLabelGenerator } from '../addresses/address.label';
import { AddressFormatterService } from '../../common/services/address-formatter.service';
import { IPhoneType } from '@model/interfaces/phone-type';
import { BaseAttachmentsService } from '../../common/attachments/base-attachments.service';
import { ViewLogService } from '../../common/services/viewlog.service';
import { IModalOptions, IModalWrapperApi } from '@mt-ng2/modal-module';
import { AddressBookTypeService } from '../addressbooktype.service';
import { IAddressBookType } from '@model/interfaces/address-book-type';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, IDynamicField, IDynamicFieldType, SelectInputTypes } from '@mt-ng2/dynamic-form';
import { AbstractControl, UntypedFormGroup, Validators } from '@angular/forms';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { IDupAddressBookDto } from '@model/interfaces/custom/dup-address-book-dto';
import { IName, NameTypeEnum } from '@model/interfaces/custom/name';
import { IAddress } from '@model/interfaces/address';

@Component({
    selector: 'app-address-book-detail',
    styles: [':host ::ng-deep .max-of-total{display: none;}'],
    templateUrl: './address-book-detail.component.html',
})
export class AddressBookDetailComponent implements OnInit, OnDestroy {
    addressBook: IAddressBook;
    addresses: IAddress[];
    totalAddresses: number;
    phoneTypes: IPhoneType[];
    individual: IIndividual;
    editingComponent: Subject<any> = new Subject();
    canEdit: boolean;
    canAdd: boolean;
    id: number;
    isAddressBookOfIndividualType: boolean;
    isAddressBookOfCourtType: boolean;
    addressBookWithPrimaryAddress: IAddressBookWithPrimaryAddress[];
    individualCardName: string;
    addressCardName: string;
    individuals: IIndividual[];
    numbeOfIndivdualsToShow = 3;
    numberofAddressesToShow = 3;
    totalNumberOfIndivdualsToShow: number;
    showATPopup = false;

    emailSharedEntityId = AddressBookSharedEntities.EmailAddresses;
    phoneNumberSharedEntityId = AddressBookSharedEntities.PhoneNumbers;
    seeAllUrl: string;

    individualLabelGenerator = new IndividualLabelGenerator(); // TODO : PS Figure out a way to not instantiate
    addressLabelGenerator = new AddressLabelGenerator(this.addressFormatterService);

    subscriptions = new Subscription();

    attachmentServiceTypeEnum: AttachmentServiceTypeEnums = AttachmentServiceTypeEnums.Address_Book;

    entityTypeIdEnums = AutomatedTemplateFaxEmailEntityTypeEnums;

    canDuplicate: boolean;
    duplicateAddressBookForm = new UntypedFormGroup({});
    addressBookTypeSelectDropdown: DynamicField;
    doubleClickIsDisabled = false;

    modalWrapperApi: IModalWrapperApi;
    modalOptions: IModalOptions = {
        allowEscapeKey: false,
        allowOutsideClick: false,
        title: 'Duplicate Address Book?',
    };

    constructor(
        private addressBookService: AddressBookService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private router: Router,
        private individualService: IndividualService,
        private phoneTypeService: PhoneTypeService,
        private authService: AuthService,
        private notificationsService: NotificationsService,
        private addressService: AddressService,
        private addressFormatterService: AddressFormatterService,
        private attachmentsService: BaseAttachmentsService,
        private viewLogService: ViewLogService,
        private addressBookTypeService: AddressBookTypeService,
    ) {}

    ngOnInit(): void {
        this.isAddressBookOfIndividualType = false;
        this.isAddressBookOfCourtType = false;
        this.individualCardName = 'Individuals';
        this.addressCardName = 'Addresses';
        // check claims
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.AddressBooks, [ClaimValues.FullAccess]);
        this.canAdd = this.canEdit;
        this.canDuplicate = this.claimsService.hasClaim(ClaimTypes.DuplicateAddressBooks, [ClaimValues.FullAccess]);
        // get current id from route
        this.id = +this.route.snapshot.paramMap.get('addressBookId');
        // try load if id > 0
        if (this.id > 0) {
            forkJoin([this.addressBookTypeService.getItems(), this.getAddressBookById(this.id)]).subscribe(([addressBookTypes]) => {
                this.initAddressBookTypeSelector(addressBookTypes);
                this.createViewLog();
            });
        } else {
            this.addressBook = this.addressBookService.getEmptyAddressBook();
            this.individual = this.individualService.getEmptyIndividual();
            // set addressBook to emptyAddressBook
            this.addressBookService.getWithPrimaryAddressofFacilityWithOrganizationTypeONLY(false).subscribe((answer) => {
                this.addressBookWithPrimaryAddress = answer;
                this.formatAddressBookAddresses();
            });
        }

        this.phoneTypeService.getItems().subscribe(() => this.setConfig());

        this.editingComponent.next('');
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    initAddressBookTypeSelector(addressBookTypes: IAddressBookType[]): void {
        this.addressBookTypeSelectDropdown = new DynamicField({
            formGroup: '',
            label: 'Address Book Type',
            name: 'AddressBookType',
            options: addressBookTypes,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.Dropdown,
            } as IDynamicFieldType),
            validation: [(c: AbstractControl) => Validators.required(c)],
            validators: { required: true },
            value: '',
        } as IDynamicField);
    }

    createViewLog(): void {
        const log = this.viewLogService.getEmptyViewLog();
        log.EntityType = ViewLogEntityTypeEnums.AddressBook;
        log.Route = this.router.url;
        log.UserId = this.authService.currentUser.getValue().Id;
        log.View = 'Address Book: ' + this.addressBook.FacilityName;
        this.viewLogService
            .create(log)
            .pipe(
                // Catch error and return empty observable in case max int size is reached in ViewLog table
                catchError((err) => of([])),
            )
            .subscribe();
    }

    setConfig(): void {
        this.phoneTypes = this.phoneTypeService.items;
    }

    formatAddressBookAddresses(): void {
        this.addressBookWithPrimaryAddress.forEach((item: IAddressBookWithPrimaryAddress) => {
            if (item.PrimaryAddress !== null) {
                if (item.PrimaryAddress.County !== null) {
                    item.PrimaryAddress.County.CountyName = item.CountyName;
                }
                item.FormattedAddress = ' - ' + this.addressFormatterService.formatAddress(item.PrimaryAddress);
            } else {
                item.FormattedAddress = '';
            }
        });
    }

    private buildSearch(): ExtraSearchParams[] {
        const _extraSearchParams: ExtraSearchParams[] = [];
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'AddressBookId',
                value: this.addressBook.Id.toString(),
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'includeArchived',
                value: 'false',
            }),
        );
        return _extraSearchParams;
    }

    getAddressBookById(id: number): Observable<IAddressBook> {
        return this.addressBookService.getById(id).pipe(
            tap((addressBook) => {
                this.addressBook = addressBook;
                this.individual = this.individualService.getEmptyIndividual();
                this.isAddressBookOfIndividualType = this.addressBook.MetaAddressBookTypeId === +MetaAddressBookTypeIdEnums.Individual;
                this.isAddressBookOfCourtType = this.addressBook.AddressBookTypeId === +AddressBookTypeIdEnums.Courts;
                const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();
                const searchEntity: IEntitySearchParams = {
                    extraParams: _extraSearchParams,
                    order: 'Id',
                    orderDirection: 'desc',
                    query: '',
                    skip: 0,
                    take: this.numberofAddressesToShow,
                };

                const searchparams = new SearchParams(searchEntity);
                this.addressService.searchAddress(this.addressBook.Id, searchparams).subscribe((answer) => {
                    const addresses = answer.body.filter((element) => element.Archived === false);
                    this.addresses = addresses;
                    this.totalAddresses = +answer.headers.get('X-List-Count');
                    if (!this.isAddressBookOfIndividualType) {
                        this.getIndividuals();
                    }
                });
            }),
        );
    }

    getIndividuals(): void {
        const _extraSearchParams: ExtraSearchParams[] = [];

        // Since Individual is part of AddressBook, it should only show Individual for that Addressbook
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'AddressBookId',
                value: this.addressBook.Id.toString(),
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'includeArchived',
                value: 'false',
            }),
        );

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: 'Id',
            orderDirection: 'desc',
            query: '',
            skip: 0,
            take: this.numbeOfIndivdualsToShow,
        };

        const searchparams = new SearchParams(searchEntity);

        this.individualService.get(searchparams).subscribe((answer) => {
            this.individuals = answer.body;
            this.totalNumberOfIndivdualsToShow = +answer.headers.get('X-List-Count');
        });
    }

    addIndividual(): void {
        void this.router.navigate([`address-books/${this.id}/individuals/add`]);
    }

    seeAllIndividuals(): void {
        void this.router.navigate([`address-books/${this.id}/individuals`]);
    }

    getItem(individual: IIndividual): string {
        return individual.FirstName + ' ' + individual.LastName;
    }

    selectIndividual(individual: IIndividual): void {
        void this.router.navigate([`address-books/${this.id}/individuals/${individual.Id}`]);
    }

    addAddress(): void {
        void this.router.navigate([`address-books/${this.id}/addresses/add`]);
    }

    seeAllAddresses(): void {
        void this.router.navigate([`address-books/${this.id}/addresses`]);
    }

    selectAddress(address: IEntity): void {
        void this.router.navigate([`address-books/${this.id}/addresses/${address.Id}`]);
    }

    toggleAutomatedTemplateModal($event): void {
        this.showATPopup = !this.showATPopup;
    }

    getFacilityName(): IName {
        return { facilityName: this.addressBook.FacilityName, nameType: NameTypeEnum.Facility };
    }

    refreshDocuments(): void {
        this.attachmentsService.requestRefresh();
        setTimeout(() => {
            this.showATPopup = false;
        });
    }

    duplicate(): void {
        if (this.modalWrapperApi) {
            this.modalWrapperApi.show();
        }
    }

    saveDuplicate(): void {
        if (this.duplicateAddressBookForm.valid) {
            const addressBookType: number = this.duplicateAddressBookForm.value.AddressBookType;
            this.addressBookService.duplicateAddressBook(this.addressBook.Id, addressBookType).subscribe(
                (dupAddressBook: IDupAddressBookDto) => {
                    this.notificationsService.success('Address Book successfully duplicated!');
                    void this.router.navigate(['/address-books/', dupAddressBook.AddressBookId]).then(() => window.location.reload());
                },
                () => {
                    this.notificationsService.error('An error occurred while duplicating the Address Book.');
                },
            );
        } else {
            markAllFormFieldsAsTouched(this.duplicateAddressBookForm);
            setTimeout(() => (this.doubleClickIsDisabled = false));
        }
    }

    closeModal(): void {
        this.modalWrapperApi.close();
        this.duplicateAddressBookForm.reset();
    }
}
