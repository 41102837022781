import { SpecialityService } from './speciality.service';

import { NgModule } from '@angular/core';
import { SharedModule } from './../common/shared.module';
import { ImedManagedListRoutingModule } from './imed-managed-list-routing.module';

import { ImedManagedListComponent } from './imed-managed-list.component';
import { SpecialityDetailService } from './speciality-detail.service';
import { ImageTypeService } from './image-type.service';
import { BodyPartService } from './body-part.service';
import { LanguageService } from './language.service';
import { AppMetaItemManagedListComponent } from './app-meta-item-managed-list/app-meta-item-managed-list.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
    declarations: [ImedManagedListComponent, AppMetaItemManagedListComponent],
    imports: [SharedModule, ImedManagedListRoutingModule, DragDropModule],
})
export class ImedManagedListModule {
    static forRoot(): any {
        return {
            ngModule: ImedManagedListModule,
            providers: [SpecialityDetailService, SpecialityService, ImageTypeService, BodyPartService, LanguageService],
        };
    }
}
