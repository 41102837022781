import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { IImedClaimServiceFollowup } from '@model/interfaces/imed-claim-service-followup';
import { ImedClaimServiceFollowupService } from '../imedclaimservicefollowup.service';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '@model/ClaimTypes';

@Component({
    selector: 'app-imed-claim-service-followup-detail',
    templateUrl: './imed-claim-service-followup-detail.component.html',
})
export class ImedClaimServiceFollowupDetailComponent implements OnInit {
    imedClaimServiceFollowup: IImedClaimServiceFollowup;
    canEdit: boolean;
    editingComponent: Subject<any> = new Subject();
    canAdd: boolean;
    id: number;

    constructor(
        private imedClaimServiceFollowupService: ImedClaimServiceFollowupService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private router: Router,
    ) { }

    ngOnInit(): void {
        // check claims
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.CaseManagement, [ClaimValues.FullAccess]);
        this.canAdd = this.canEdit;
        // get current id from route
        this.id = +this.route.snapshot.paramMap.get('followupId');
        // try load if id > 0
        if (this.id > 0) {
            this.getImedClaimServiceFollowupById(this.id);
        } else {
            // set imedClaimServiceFollowup to emptyImedClaimServiceFollowup
            this.imedClaimServiceFollowup = this.imedClaimServiceFollowupService.getEmptyImedClaimServiceFollowup();

            // set the date to be with Date Part plus UTC Offset without time in it
            this.imedClaimServiceFollowup.Date = new Date(new Date().mtDate.toMoment().format('MM/DD/YYYY'));
        }
        this.editingComponent.next('');
    }

    getImedClaimServiceFollowupById(id: number): void {
        this.imedClaimServiceFollowupService.getById(id).subscribe((imedClaimServiceFollowup) => {
            this.imedClaimServiceFollowup = imedClaimServiceFollowup;
            if(this.imedClaimServiceFollowup.ImedClaimFollowupId) this.canEdit = false;
        });
    }

    clickClose(): void {
        if (this.route.snapshot.parent.parent.parent.paramMap.get('imedClaimId') === '0') {
            void this.router.navigateByUrl('cases/0/services/0/follow-ups');
        } else {
            void this.router.navigateByUrl(this.router.url.replace('/follow-ups/' + this.id, ''));
        }
    }
}
