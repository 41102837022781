import { SharedEntitiesComponentConfig, ISharedEntitiesComponentConfig, SharedEntitiesEditOptions } from '@mt-ng2/shared-entities-module';
import { emptyEmailAddress } from './email-address.service';
import { EmailAddressListConfig } from './email-address.entity-list-config';
import { EmailAddressDynamicConfig } from './email-address.dynamic-config';
import { entityListModuleConfig } from '@common/shared.module';
import { IEmailAddress } from '@model/interfaces/email-address';
import { EmailTypeService } from '../email-type/emailtype.service';
import { EmailTypes } from '@common/constants/Enums';

export class EmailAddressesForAttorneyAndCarrierEntitiesConfig
    extends SharedEntitiesComponentConfig<IEmailAddress>
    implements ISharedEntitiesComponentConfig<IEmailAddress> {
    emailTypes: any[];

    constructor() {
        super(
            emptyEmailAddress,
            'Email Address',
            'Email Addresses',
            {
                EntityListConfig: new EmailAddressListConfig(),
                FilterServices: [EmailTypeService],
            },
            entityListModuleConfig.itemsPerPage,
        );

        this.MetaItemServices = [EmailTypeService];

        // TODO CCC: handle this
        this.AdditionalSharedEntities = [];

        this.SharedEntitiesEditOption = SharedEntitiesEditOptions.InPlaceWithAdditionalInfoButton;
    }

    setEmailTypes(): void {
        this.emailTypes = this.getMetaItemValues('EmailTypeService');
    }

    getFormValues(emailAddress: IEmailAddress, formValue: any): IEmailAddress {
        this.setEmailTypes();
        const formFactory = new EmailAddressDynamicConfig<IEmailAddress>(emailAddress, this.emailTypes);
        formFactory.assignFormValues(emailAddress, formValue.EmailAddress);
        return emailAddress;
    }

    getDynamicFormConfig(emailAddress: IEmailAddress): any {
        this.setEmailTypes();
        const formFactory = new EmailAddressDynamicConfig<IEmailAddress>(emailAddress, this.emailTypes);
        if (emailAddress.Id === 0) {
            return formFactory.getForCreate();
        } else {
            return formFactory.getForUpdate();
        }
    }

    getRow = (emailAddress: IEmailAddress) =>
        `${emailAddress.IsPrimary ? '<i class="fa fa-star btn selected"></i>' : ''}
        ${emailAddress.EmailType ? `[${emailAddress.EmailType.Name}] ` : ''} <strong>${emailAddress.Email}</strong>
        ${emailAddress.Notes ? `<br /><div style="padding-top:15px"><label>Notes: </label> ${emailAddress.Notes}</div>` : ''}`
}
