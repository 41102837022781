import { Component } from '@angular/core';
import { IEntityListDynamicCellComponent, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { IEntity } from '@mt-ng2/base-service';
import { IModalOptions } from '@mt-ng2/modal-module';
import { IImeAppointment } from '@model/interfaces/ime-appointment';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '@model/ClaimTypes';

@Component({
    template: `
        <div *ngIf="canDelete && imeAppointment && imeAppointment.Archived" class="text-center">
            <i (mtConfirm)="archiveAppointment($event)" class="fa fa-trash fa-lg" aria-hidden="true" [mtConfirmOptions]="unarchiveConfirm"></i
            ><i class="fa fa-lg fa-undo overlay-fa"></i>
        </div>
        <div *ngIf="canDelete && imeAppointment && !imeAppointment.Archived" class="text-center">
            <i (mtConfirm)="archiveAppointment($event)" class="fa fa-trash fa-lg" aria-hidden="true" [mtConfirmOptions]="archiveConfirm"></i>
        </div>
    `,
})
export class AutoImeAppointmentDeleteDynamicCellComponent implements IEntityListDynamicCellComponent {
    entityListComponentMembers: IEntityListComponentMembers;
    set entity(value: IEntity) {
        this.imeAppointment = value as IImeAppointment;
    }

    imeAppointment: IImeAppointment;
    canDelete = false;

    archiveConfirm: IModalOptions = {
        confirmButtonText: 'Yes, I am sure!',
        text: `Are you sure you want to delete this appointment?`,
        title: 'Delete Appointment',
    	icon: 'question',
};

    unarchiveConfirm: IModalOptions = {
        confirmButtonText: 'Yes, I am sure!',
        text: `Are you sure you want to restore this appointment?`,
        title: 'Restore Appointment',
    	icon: 'question',
};

    constructor(private claimsService: ClaimsService) {}

    ngOnInit(): void {
        this.canDelete = this.claimsService.hasClaim(ClaimTypes.ArchiveAppointment, [ClaimValues.FullAccess]);
    }

    archiveAppointment(event: Event): void {
        this.entityListComponentMembers.itemDeleted(this.imeAppointment as IEntity, event);
    }
}
