import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IFilmInformationDTO } from '@model/interfaces/custom/film-information-dto';
import { FilmInformationService } from '@app-shared/services/film-information.service';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Component({
    selector: 'app-film-retrieval-selector-list',
    templateUrl: './film-retrieval-selector-list.component.html',
})
export class FilmReviewFilmRetrievalSelectorList {
    @Input() filmInformations: IFilmInformationDTO[];
    @Input() imedClaimServiceId: number;
    @Input() isEditing: boolean;

    @Output('refreshFilmInformation') refreshFilmInformation: EventEmitter<any> = new EventEmitter<any>();

    constructor(private filmInformationService: FilmInformationService, private notificationService: NotificationsService) { }

    filmSelected(film: IFilmInformationDTO, event: any): void {
        if (event.target.checked) {
            // Sets FilmInformationDTO.IsSelected to the RadiologyReviewFilmSelected.Id corresponding to record created
            this.filmInformationService.selectFilm(film.Id, this.imedClaimServiceId).subscribe((answer) => {
                film.IsSelected = answer;
            });
            this.notificationService.success('Film selected successfully!');
        } else {
            // Deletes associated RadiologyReviewFilmSelected record and sets property to null
            this.filmInformationService.unSelectFilm(film.IsSelected).subscribe(() => {
                film.IsSelected = null;
                this.notificationService.success('Film unselected successfully!');
            });
        }
    }
}
