// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  cursor: pointer;
}
.association-select {
  width: 100%;
  max-width: 100%;
}
.delete-button {
  padding-top: 4px;
}
.input-row {
  color: #333;
  margin: 0 auto;
  padding: 5px 0 5px 0;
}
.input-row:nth-child(2) {
  border-top: 1px solid #aaa;
}
.input-row:first-child {
  border: none;
}
.status-label {
  font-size: 16px;
  font-weight: 700;
}
.task-container {
  margin-top: 2px;
}
.grey {
  background-color: #f5f5f5;
}
.grey:hover {
  background-color: #ececec;
  transition: background-color 0.25s ease;
}
.green {
  color: green;
}
.white {
  background-color: #fff;
}
.white:hover {
  background-color: #f5f5f5;
  transition: background-color 0.25s ease;
}
`, "",{"version":3,"sources":["webpack://./src/app/task-status-followup-engine/addressbook-status-associations/row-styles.less"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ;AACA;EACI,WAAA;EACA,eAAA;AACJ;AACA;EACI,gBAAA;AACJ;AACA;EACI,WAAA;EACA,cAAA;EACA,oBAAA;AACJ;AACA;EACI,0BAAA;AACJ;AACA;EACI,YAAA;AACJ;AACA;EACI,eAAA;EACA,gBAAA;AACJ;AACA;EACI,eAAA;AACJ;AACA;EACI,yBAAA;AACJ;AACA;EACI,yBAAA;EACA,uCAAA;AACJ;AAEA;EACI,YAAA;AAAJ;AAGA;EACI,sBAAA;AADJ;AAGA;EACI,yBAAA;EACA,uCAAA;AADJ","sourcesContent":["* {\n    cursor: pointer;\n}\n.association-select {\n    width: 100%;\n    max-width: 100%;\n}\n.delete-button {\n    padding-top: 4px;\n}\n.input-row {\n    color: #333;\n    margin: 0 auto;\n    padding: 5px 0 5px 0;\n}\n.input-row:nth-child(2) {\n    border-top: 1px solid #aaa;\n}\n.input-row:first-child {\n    border: none;\n}\n.status-label {\n    font-size: 16px;\n    font-weight: 700;\n}\n.task-container {\n    margin-top: 2px;\n}\n.grey {\n    background-color: #f5f5f5;\n}\n.grey:hover {\n    background-color: #ececec;\n    transition: background-color 0.25s ease;\n}\n\n.green {\n    color: green;\n}\n\n.white {\n    background-color: #fff;\n}\n.white:hover {\n    background-color: #f5f5f5;\n    transition: background-color 0.25s ease;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
