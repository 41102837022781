import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { CommonEmailComponent } from '../../../../common/common-email/common-email.component';
import { EmailTemplateService } from '../../../../email-templates/emailtemplate.service';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'fee-schedule',
    template: `<button type="button" class="btn btn-primary" (click)="openEmailControl()">Send Fee Schedule</button>
        <common-email *ngIf="showEmailControl" (onClose)="showEmailControl = false" #emailComponent> </common-email>`,
})
export class FeeScheduleComponent {
    @ViewChild('emailComponent') emailComponent: CommonEmailComponent;
    @Input() imedClaimServiceId: number;
    @Output() onFeeScheduleClose: EventEmitter<void> = new EventEmitter();

    showEmailControl = false;

    constructor(private emailTemplateService: EmailTemplateService) {}

    openEmailControl(): void {
        this.showEmailControl = true;
        this.emailTemplateService.getFeeScheduleLetter(this.imedClaimServiceId).subscribe((template) => {

            this.emailComponent.emailTemplates = [template];
            this.emailComponent.imedClaimServiceId = this.imedClaimServiceId;
            this.emailComponent.selectedTemplate = template;
            this.emailTemplateService.getImedClaimTypeId(this.imedClaimServiceId).subscribe((claimTypeId) => {
                this.emailComponent.imedClaimTypeId = claimTypeId;
            });
            setTimeout(() => {
                this.emailComponent.showEmailForm();
            }, 0);
            this.emailComponent.onClose.pipe(debounceTime(200)).subscribe(() => {
                this.onFeeScheduleClose.emit();
            });
        });
    }
}
