import { ImedClaimServiceFollowupHeaderComponent } from './services/common-components/service-followups/imed-claim-service-followup-header/imed-claim-service-followup-header.component';
import { ImedClaimServiceFollowupDetailComponent } from './services/common-components/service-followups/imed-claim-service-followup-detail/imed-claim-service-followup-detail.component';
import { ImedClaimServiceFollowupsComponent } from './services/common-components/service-followups/imed-claim-service-followup-list/imed-claim-service-followups.component';
import { ImeAppointmentsComponent } from './services/service-specifics/ime-service/ime-appointments/ime-appointments.component';
import { ImedClaimServiceStatusLogsComponent } from './services/common-components/service-status/imed-claim-service-status-logs.component';
import { ImedClaimServiceHeaderComponent } from './services/service-header/imed-claim-service-header.component';
import { ImedClaimServiceDetailComponent } from './services/service-detail/imed-claim-service-detail.component';
import { ImedClaimServicesComponent } from './services/service-list/imed-claim-services.component';
import { ImedClaimServiceFollowupService } from './services/common-components/service-followups/imedclaimservicefollowup.service';

import { CommunicationLogsComponent } from './services/common-components/communication-log/communication-logs.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, ClaimValues } from '@mt-ng2/auth-module';
import { ImedClaimDetailComponent } from './imed-claim-detail/imed-claim-detail.component';
import { ImedClaimsComponent } from './imed-claim-list/imed-claims.component';
import { ImedClaimService } from './imedclaim.service';
import { ImedClaimHeaderComponent } from './imed-claim-header/imed-claim-header.component';
import { ClaimTypes } from '@model/ClaimTypes';
import { AddressBooksSelectComponent } from '../common/address-book-select-list/address-books-select.component';
import { AddressBooksSelectAddComponent } from '../common/address-book-select-list/address-books-select-add.component';
import { SharedEntitiesListComponent, SharedEntitiesInfoComponent } from '../../../node_modules/@mt-ng2/shared-entities-module';
import { IMedClaimAddressSharedEntity, IMedClaimEmailSharedEntity, IMedClaimPhoneNumberSharedEntity } from './shared/imed-claim.shared-entities';
import { ImedClaimServiceService } from './services/imedclaimservice.service';
import { AddressService } from '../address-books/addresses/address.service';
import { CommunicationLogTypeEnums } from '../common/constants/Enums';
import { ImedClaimMergeCasesComponent } from './imed-claim-merge-cases/imed-claim-merge-cases.component';
import { IMedClaimPrimaryPhoneComponent } from './primary-phone/primary-phone.component';
import { IMedClaimPrimaryEmailComponent } from './primary-email/primary-email.component';
import { ImedClaimServiceNotesService } from './services/imed-claim-service-notes.service';
import { ImedClaimAdjusterInquiriesListComponent } from './services/common-components/adjuster-inquiry-notes/adjuster-inquiry-notes-list/adjuster-inquiry-notes-list.component';
import { ImedClaimFollowupDetailComponent } from './imedclaim-followups/imed-claim-followup-detail/imed-claim-followup-detail.component';
import { ImedClaimFollowupsComponent } from './imedclaim-followups/imed-claim-followup-list/imed-claim-followups.component';
import { ImedClaimFollowupHeaderComponent } from './imedclaim-followups/imed-claim-followup-header/imed-claim-followup-header.component';

const imedClaimEntityConfig = {
    claimType: ClaimTypes.CaseManagement,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'imedClaimId',
    entityTypeIdParam: 'imedClaimTypeId',
    service: ImedClaimService,
    sharedEntities: [IMedClaimAddressSharedEntity, IMedClaimEmailSharedEntity, IMedClaimPhoneNumberSharedEntity],
    title: 'Case Detail',
};

const imedClaimListRoleGuard = {
    claimType: ClaimTypes.CaseManagement,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Cases',
};

const imedClaimAddRoleGuard = {
    claimType: ClaimTypes.CaseManagement,
    claimValues: [ClaimValues.FullAccess],
};

const imedClaimMergeRoleGuard = {
    claimType: ClaimTypes.MergeCases,
    claimValues: [ClaimValues.FullAccess],
};

const imedServiceEntityConfig = {
    claimType: ClaimTypes.CaseManagement,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'serviceId',
    notesPath: 'notes',
    service: ImedClaimServiceService,
    title: 'Service Detail',
};

const imedClaimServiceAddRoleGuard = {
    claimType: ClaimTypes.CaseManagement,
    claimValues: [ClaimValues.FullAccess],
};

const imedClaimServicesListRoleGuard = {
    claimType: ClaimTypes.CaseManagement,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Services',
};

const imedClaimServiceFollowupListRoleGuard = {
    claimType: ClaimTypes.CaseManagement,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Follow-ups',
};

const imedClaimServiceFollowupAddRoleGuard = {
    claimType: ClaimTypes.CaseManagement,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Add Follow-up',
};

const imedClaimServiceStatusLogsRoleGuard = {
    claimType: ClaimTypes.CaseManagement,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Status Logs',
};

const imeAppointmentsRoleGuard = {
    claimType: ClaimTypes.CaseManagement,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Upcoming Appointments',
};

const imedClaimServiceCommunicationLogsRoleGuard = {
    claimType: ClaimTypes.CaseManagement,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    logType: CommunicationLogTypeEnums.IMED_CLAIM_SERVICE,
    title: 'Communication Logs',
};

const caseCommunicationLogsConfig = {
    claimType: ClaimTypes.CaseManagement,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'imedClaimId',
    logType: CommunicationLogTypeEnums.CASE,
    title: 'CommunicationLogs',
};

const imedClaimsFollowUpEntityConfig = {
    claimType: ClaimTypes.CaseManagement,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'followupId',
    service: ImedClaimServiceFollowupService,
    title: 'Follow-up Detail',
};

const addressEntityConfig = {
    claimType: ClaimTypes.AddressBooks,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'addressId',
    service: AddressService,
    title: 'Address Detail',
};

const adjusterInquiriesEntityConfig = {
    claimType: ClaimTypes.CaseManagement,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    service: ImedClaimServiceNotesService,
    title: 'Adjuster Inquiries',
};

const imedClaimFollowupListRoleGuard = {
    claimType: ClaimTypes.CaseManagement,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Follow-ups',
};

const imedClaimFollowupAddRoleGuard = {
    claimType: ClaimTypes.CaseManagement,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Add Follow-up',
};

const imedClaimRoutes: Routes = [
    { path: 'cases', component: ImedClaimsComponent, canActivate: [AuthGuard], data: imedClaimListRoleGuard },
    {
        canActivate: [AuthGuard],
        children: [
            { path: '', component: ImedClaimDetailComponent, pathMatch: 'full' },
            { path: '', component: AddressBooksSelectComponent, pathMatch: 'full' },
            { path: '', component: AddressBooksSelectAddComponent, pathMatch: 'full' },
            { path: '', component: IMedClaimPrimaryPhoneComponent, pathMatch: 'full' },
            { path: '', component: IMedClaimPrimaryEmailComponent, pathMatch: 'full' },
        ],
        component: ImedClaimHeaderComponent,
        data: imedClaimAddRoleGuard,
        path: `cases/:${imedClaimEntityConfig.entityTypeIdParam}/add`,
    },
    {
        canActivate: [AuthGuard],
        component: CommunicationLogsComponent,
        data: caseCommunicationLogsConfig,
        path: `cases/:${imedClaimEntityConfig.entityIdParam}/communication-logs`,
    },
    {
        canActivate: [AuthGuard],
        children: [
            { path: '', component: ImedClaimDetailComponent, pathMatch: 'full' },
            { path: '', component: AddressBooksSelectComponent, pathMatch: 'full' },
            { path: '', component: AddressBooksSelectAddComponent, pathMatch: 'full' },
            { path: '', component: IMedClaimPrimaryPhoneComponent, pathMatch: 'full' },
            { path: '', component: IMedClaimPrimaryEmailComponent, pathMatch: 'full' },
            {
                // Follow-ups
                component: ImedClaimFollowupsComponent,
                data: imedClaimFollowupListRoleGuard,
                path: `follow-ups`,
            },
            {
                children: [{ path: '', component: ImedClaimFollowupDetailComponent, pathMatch: 'full' }],
                component: ImedClaimFollowupHeaderComponent,
                data: imedClaimFollowupAddRoleGuard,
                path: `follow-ups/add`,
            },
            {
                children: [{ path: '', component: ImedClaimFollowupDetailComponent, pathMatch: 'full' }],
                component: ImedClaimFollowupHeaderComponent,
                data: imedClaimsFollowUpEntityConfig,
                path: `follow-ups/:${imedClaimsFollowUpEntityConfig.entityIdParam}`,
            },
            {
                component: ImedClaimServicesComponent,
                data: imedClaimServicesListRoleGuard,
                path: `services`,
            },
            {
                children: [{ path: '', component: ImedClaimServiceDetailComponent, pathMatch: 'full' }],
                component: ImedClaimServiceHeaderComponent,
                data: imedClaimServiceAddRoleGuard,
                path: `services/add`,
            },
            {
                canActivate: [AuthGuard],
                children: [
                    { path: '', component: ImedClaimServiceDetailComponent, pathMatch: 'full' },
                    {
                        // Status Logs
                        component: ImedClaimServiceStatusLogsComponent,
                        data: imedClaimServiceStatusLogsRoleGuard,
                        path: `status-logs`,
                    },
                    {
                        // Status Logs
                        component: ImeAppointmentsComponent,
                        data: imeAppointmentsRoleGuard,
                        path: `appointments`,
                    },
                    {
                        // Status Logs
                        component: CommunicationLogsComponent,
                        data: imedClaimServiceCommunicationLogsRoleGuard,
                        path: `communication-logs`,
                    },
                    {
                        // Follow-ups
                        component: ImedClaimServiceFollowupsComponent,
                        data: imedClaimServiceFollowupListRoleGuard,
                        path: `follow-ups`,
                    },
                    {
                        children: [{ path: '', component: ImedClaimServiceFollowupDetailComponent, pathMatch: 'full' }],
                        component: ImedClaimServiceFollowupHeaderComponent,
                        data: imedClaimServiceFollowupAddRoleGuard,
                        path: `follow-ups/add`,
                    },
                    {
                        children: [{ path: '', component: ImedClaimServiceFollowupDetailComponent, pathMatch: 'full' }],
                        component: ImedClaimServiceFollowupHeaderComponent,
                        data: imedClaimsFollowUpEntityConfig,
                        path: `follow-ups/:${imedClaimsFollowUpEntityConfig.entityIdParam}`,
                    },
                    {
                        component: ImedClaimAdjusterInquiriesListComponent,
                        data: adjusterInquiriesEntityConfig,
                        path: imedServiceEntityConfig.notesPath,
                        pathMatch: 'full',
                    },
                ],
                component: ImedClaimServiceHeaderComponent,
                data: imedServiceEntityConfig,
                path: `services/:${imedServiceEntityConfig.entityIdParam}`,
            },
            {
                // Addresses
                component: SharedEntitiesListComponent,
                data: { title: 'Addresses' },
                path: IMedClaimAddressSharedEntity.path,
                pathMatch: 'full',
            },
            {
                // Email Addresses
                component: SharedEntitiesListComponent,
                data: { title: 'Email Addresses' },
                path: IMedClaimEmailSharedEntity.path,
                pathMatch: 'full',
            },
            {
                component: SharedEntitiesInfoComponent,
                path: `${IMedClaimEmailSharedEntity.path}/:${IMedClaimEmailSharedEntity.entityIdParam}`,
                pathMatch: 'full',
            },
            {
                component: SharedEntitiesInfoComponent,
                path: `${IMedClaimPhoneNumberSharedEntity.path}/:${IMedClaimPhoneNumberSharedEntity.entityIdParam}`,
                pathMatch: 'full',
            },
            {
                component: SharedEntitiesListComponent,
                data: { title: 'Phone Numbers' },
                path: IMedClaimPhoneNumberSharedEntity.path,
                pathMatch: 'full',
            },
            {
                component: SharedEntitiesInfoComponent,
                path: `${IMedClaimAddressSharedEntity.path}/:${IMedClaimAddressSharedEntity.entityIdParam}`,
                pathMatch: 'full',
            },
        ],
        component: ImedClaimHeaderComponent,
        data: imedClaimEntityConfig,
        path: `cases/:${imedClaimEntityConfig.entityIdParam}`,
    },
    { canActivate: [AuthGuard], component: ImedClaimMergeCasesComponent, data: imedClaimMergeRoleGuard, path: 'merge-cases' },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(imedClaimRoutes)],
})
export class ImedClaimRoutingModule {}
