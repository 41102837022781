import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import 'rxjs/operators';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { PhysicianStatusLogsEntityListConfig } from './physician-status-logs.entity-list-config';
import { IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';
import { entityListModuleConfig } from '../../../../common/shared.module';
import { PhysicianStatusLogService } from '../physician-status-log.service';
import { IPhysicianStatusLog } from '@model/interfaces/physician-status-log';
import { MtSearchFilterItem } from '../../../../../../node_modules/@mt-ng2/search-filter-select-control';
import { PhysicianStatusService } from '../physicianstatus.service';

@Component({
    selector: 'app-physician-statuses-logs',
    templateUrl: './physician-status-logs.component.html',
})
export class PhysicianStatusLogsComponent implements OnInit {
    searchControl = new UntypedFormControl();
    physicianStatusLogs: IPhysicianStatusLog[];
    currentPage = 1;
    query = '';
    total: number;
    entityListConfig = new PhysicianStatusLogsEntityListConfig();
    order = 'ChangedDate'; // this.entityListConfig.getDefaultSortProperty();
    orderDirection = 'desc';
    id: string;
    addressBookId: number;
    individualId: number;

    statuses: MtSearchFilterItem[] = [];

    constructor(
        private physicianStatusLogService: PhysicianStatusLogService,
        private physicianStatusService: PhysicianStatusService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.addressBookId = this.activatedRoute.parent.parent.snapshot.params.addressBookId;
        this.individualId = this.activatedRoute.parent.snapshot.params.individualId;

        this.physicianStatusService.getSearchFilterItems().subscribe((answer) => (this.statuses = answer));

        this.getPhysicianStatusLogs();
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    getPhysicianStatusLogs(): void {
        const search = this.query;
        const selectedSourceIds: number[] = this.getSelectedFilters(this.statuses);

        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'IndividualId', // We'll have to get the Physician later in the service
                value: this.individualId.toString(),
            }),
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'PhysicianStatusIds',
                valueArray: selectedSourceIds,
            }),
        );

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: entityListModuleConfig.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);

        this.physicianStatusLogService.searchStatuses(this.individualId, searchparams).subscribe((answer) => {
            this.physicianStatusLogs = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.query = query;
        this.currentPage = 1;
        this.getPhysicianStatusLogs();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getPhysicianStatusLogs();
    }

    cancelClick(): void {
        void this.router.navigate([`address-books/${this.addressBookId}/individuals/${this.individualId}`]);
    }
}
