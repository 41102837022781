import { Component, OnInit } from '@angular/core';

import { IAutomatedTemplate } from '@model/interfaces/automated-template';
import { AutomatedTemplateService } from '../services/automated-template.service';

@Component({
    templateUrl: './automated-template-add.component.html',
})
export class AutomatedTemplateAddComponent implements OnInit {
    automatedTemplate: IAutomatedTemplate;
    canEdit = true; // route guard ensures this component wouldn't be loaded if user didn't have permission already

    constructor(private automatedtemplateService: AutomatedTemplateService) {}

    ngOnInit(): void {
        this.automatedTemplate = this.automatedtemplateService.getEmptyAutomatedTemplate();
    }

    templateUpdated(template: string): void {
        this.automatedTemplate.Template = template;
    }
}
