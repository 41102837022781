import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';

import { AutomatedTemplateService } from '../services/automated-template.service';
import { IAutomatedTemplate } from '@model/interfaces/automated-template';
import { ClaimTypes } from '@model/ClaimTypes';
import { AutomatedTemplatesEntityListConfig } from './automated-templates.entity-list-config';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-automated-templates',
    styles: [
        `
            .remove-dynamic-template-column {
                text-align: right;
                padding-right: 30px !important;
            }
        `,
    ],
    templateUrl: './automated-templates.component.html',
})
export class AutomatedTemplatesComponent implements OnInit {
    automatedTemplates: IAutomatedTemplate[];
    currentPage = 1;
    itemsPerPage = 12;
    query = '';
    total: number;
    entityListConfig = new AutomatedTemplatesEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();

    canAddAutomatedTemplate = false;

    constructor(
        private automatedTemplateService: AutomatedTemplateService,
        private claimsService: ClaimsService,
        private router: Router,
        private notificationsService: NotificationsService,
    ) {}

    ngOnInit(): void {
        this.canAddAutomatedTemplate = this.claimsService.hasClaim(ClaimTypes.AutomatedTemplates, [ClaimValues.FullAccess]);
        this.getAutomatedTemplates();
    }

    getAutomatedTemplatesCall(): Observable<HttpResponse<IAutomatedTemplate[]>> {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = [];

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        return this.automatedTemplateService.get(searchparams);
    }

    getAutomatedTemplates(): void {
        this.getAutomatedTemplatesCall().subscribe((answer) => {
            this.automatedTemplates = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.currentPage = 1;
        this.query = query;
        this.getAutomatedTemplates();
    }

    filterSelectionChanged(): void {
        this.currentPage = 1;
        this.getAutomatedTemplates();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getAutomatedTemplates();
    }

    automatedTemplateSelected(event: IItemSelectedEvent): void {
        void this.router.navigate(['/dynamic-templates', event.entity.Id]);
    }

    onRemoveAutomatedTemplate(event: IItemSelectedEvent): void {
        this.automatedTemplateService.delete(event.entity.Id as number).subscribe((answer) => {
            if (event.entity.Archived) {
                this.notificationsService.success('Successfully restored Dynamic Template');
            } else {
                this.notificationsService.success('Successfully removed Dynamic Template');
            }
            this.getAutomatedTemplates();
        });
    }
}
