import { ISpecialty } from '@model/interfaces/specialty.d';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MetaItemService } from '@mt-ng2/base-service';
@Injectable()
export class SpecialityService extends MetaItemService<ISpecialty> {
    constructor(public http: HttpClient) {
        super('SpecialityService', 'Speciality', 'SpecialityIds', '/specialities', http);
    }
}
