import { IMasterFollowupTypeTaskCompletionExemption } from '@model/interfaces/master-followup-type-task-completion-exemption.d';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';

export const emptyMasterFollowupTypeTaskCompletionExemption: IMasterFollowupTypeTaskCompletionExemption = {
    Id: 0,
    MasterFollowupTypeId: null,
    ServiceId: null,
};

@Injectable()
export class FollowupTypeExecmptionService extends BaseService<IMasterFollowupTypeTaskCompletionExemption> {
    constructor(public http: HttpClient) {
        super('/masterfollowuptypetaskcompletionexemption', http);
    }

    getEmptyMasterFollowupTypeTaskCompletionExemption(): IMasterFollowupTypeTaskCompletionExemption {
        return emptyMasterFollowupTypeTaskCompletionExemption;
    }
}
