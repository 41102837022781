import { IImedClaimServiceStatusLog } from '@model/interfaces/imed-claim-service-status-log.d';
import { EntityListConfig, EntityListColumn, IEntityListColumn, IEntityListConfig, EntityListDeleteColumn } from '@mt-ng2/entity-list-module';

export class ImedClaimServiceStatusLogEntityListConfig extends EntityListConfig {
    columns: IEntityListColumn[];

    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['ChangedDate'],
                    name: 'Changed Date',
                    pipes: ['date'],
                    sort: {
                        sortProperty: 'ChangedDate',
                    },
                }),
                new EntityListColumn({
                    accessors: ['ServiceStatus', 'Name'],
                    name: 'Status',
                    sort: {
                        sortProperty: 'ServiceStatus.Name',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (user: IImedClaimServiceStatusLog): string {
                        if (user.User) {
                            return `${user.User.FirstName} ${user.User.LastName}`;
                        } else {
                            return 'System';
                        }
                    },
                    name: 'Name',
                    sort: {
                        sortProperty: '"User.FirstName  User.FirstName"',
                    },
                }),
            ],
        };
        super(listConfig);
    }
}
