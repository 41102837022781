import { FaxEmailWrapperComponent } from '../../common/fax-email/fax-email-wrapper-component';
import {
    EntityListConfig,
    EntityListColumn,
    IEntityListColumn,
    IEntityListConfig,
    IEntityListExportConfig,
} from '@mt-ng2/entity-list-module';
import { IAddressBooksForList } from '@model/interfaces/address-books-for-list';
import { AddressBookDeleteDynamicCellComponent } from './address-book-delete-cell.component';
import { AddressBookAddressesDynamicCellComponent } from './address-book-addresses-cell.component';
import { IAddressBookForList } from '@model/partials/address-books-for-list.partial';
import { ClaimTypeEnums } from '@common/constants/Enums';

export class AddressBooksEntityListExportConfig extends EntityListConfig {
    columns: IEntityListColumn[];

    export: IEntityListExportConfig = {
        exportName: 'Address Book List',
    };

    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    name: 'Facility Name',
                    accessorFunction: (ab: IAddressBooksForList) => {
                        return ab.FacilityName;
                    },
                }),
                new EntityListColumn({
                    accessors: ['Type'],
                    name: 'Facility Type',
                }),
                new EntityListColumn({
                    accessorFunction: function (ab: IAddressBooksForList): string {
                        if (ab.FirstName) {
                            const firstName = ab.FirstName ?? '';
                            const lastName = ab.LastName ?? '';
                            const suffix = ab.Suffix ?? '';
                            return `${lastName}, ${firstName} ${suffix}`;
                        } else {
                            return '';
                        }
                    },
                    name: 'Full Name',
                }),
                new EntityListColumn({
                    accessorFunction: function (addressBook: IAddressBooksForList): string {
                        return `${addressBook.Address1 ? addressBook.Address1 + ', ' : ''} ${
                            addressBook.Address2 ? addressBook.Address2 + ', ' : ''
                        } ${addressBook.City ? addressBook.City + ', ' : ''} ${addressBook.StateCode ? addressBook.StateCode : ''}
                            ${addressBook.Zip ? addressBook.Zip : ''}`;
                    },
                    excludeFromView: true,
                    name: 'Address',
                    sort: { disableSort: true },
                    style: { width: 230 },
                }),
                new EntityListColumn({
                    component: AddressBookAddressesDynamicCellComponent,
                    excludeFromExport: true,
                    name: 'Address',
                    sort: { disableSort: true },
                    style: { width: 230 },
                }),
                new EntityListColumn({
                    accessorFunction: function (ab: IAddressBooksForList): string {
                        return ab.CountyName ?? '';
                    },
                    name: 'County',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    accessorFunction: function (ab: IAddressBooksForList): string {
                        return ab.IndividualPhoneNumbers ?? ab.AddressBookPhoneNumbers ?? '';
                    },
                    name: 'Phone',
                    pipes: ['phone'],
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (ab: IAddressBooksForList): string {
                        return ab.IndividualEmail ?? ab.AddressBookEmail ?? '';
                    },
                    name: 'Email',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (ab: IAddressBooksForList): string {
                        if (ab.Specilaities) {
                            return `${ab.Specilaities.replace(/<[^>]*>/g, '')}`;
                        }
                        return '';
                    },
                    name: 'Specialty',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    accessorFunction: function (ab: IAddressBooksForList): string {
                        if (ab.SpecilaitiyDetails) {
                            return `${ab.SpecilaitiyDetails.replace(/<[^>]*>/g, '')}`;
                        }
                        return '';
                    },
                    name: 'Specialty Details',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    accessorFunction: (ab: IAddressBooksForList) => ab.SubspecialtyNotes?.replace(/<[^>]*>/g, '') || '',
                    name: 'Specialty Note',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    accessorFunction: function (ab: IAddressBookForList): string {
                        ab.PhysicianStatus = '';
                        if (ab.PhysicianStatuses != null) {
                            ab.PhysicianStatuses.forEach((ps) => {
                                const addCurrentPhysicianStatus = ps.PhysicianStatusName + ' ';
                                const currentImedClaimId = ps?.ImedClaimTypeId;
                                const addWCStatus = 'WC - ' + addCurrentPhysicianStatus + '<br/>';
                                const addNFPStatus = 'NFP - ' + addCurrentPhysicianStatus + '<br/>';
                                const addGLStatus = 'GL - ' + addCurrentPhysicianStatus + '<br/>';

                                if (currentImedClaimId === ClaimTypeEnums.GL) {
                                    ab.PhysicianStatus != null ? (ab.PhysicianStatus += addGLStatus) : (ab.PhysicianStatus = addGLStatus);
                                } else if (currentImedClaimId === ClaimTypeEnums.WC) {
                                    ab.PhysicianStatus != null ? (ab.PhysicianStatus += addWCStatus) : (ab.PhysicianStatus = addWCStatus);
                                } else {
                                    ab.PhysicianStatus != null ? (ab.PhysicianStatus += addNFPStatus) : (ab.PhysicianStatus = addNFPStatus);
                                }
                            });
                            return ab.PhysicianStatus.replace(/<[^>]*>/g, '\n');
                        }
                        return ' ';
                    },
                    bindToInnerHtml: true,
                    name: 'Status',
                    sort: { disableSort: true },
                    style: { width: 200 },
                }),
                new EntityListColumn({
                    accessorFunction: (ab: IAddressBooksForList) => ab.Wcb?.replace(/<[^>]*>/g, '') ?? 'N/A',
                    name: 'WCB#',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    component: FaxEmailWrapperComponent,
                    excludeFromExport: true,
                    name: 'Document',
                    sort: { disableSort: true },
                    style: { width: 140 },
                }),
                new EntityListColumn({
                    accessorFunction: (ab: IAddressBookForList) => `${ab.FacilityStrivenId || ''}`,
                    name: 'Facility StrivenID',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    accessorFunction: (ab: IAddressBookForList) => `${ab.IndividualStrivenId || ''}`,
                    name: 'Individual StrivenID',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    component: AddressBookDeleteDynamicCellComponent,
                    excludeFromExport: true,
                    name: 'Delete',
                    sort: { disableSort: true },
                    style: { width: 50 },
                }),
            ],
            rowClass: (entity: IAddressBooksForList) => (entity.FacilityArchived || entity.IndividualArchived ? 'archived' : null),
        };
        super(listConfig);
    }
}
