import { Component, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { IAutomatedTemplate } from '@model/interfaces/automated-template';
import { IDynamicFieldPartial } from '@model/partials/dynamic-field.partial';
import { CustomFormControlComponentBase } from '@mt-ng2/dynamic-form';
import { CKEditor4 } from 'ckeditor4-angular';
import { Subscription } from 'rxjs';
import { CkEditorSingletonService } from './namespaceLoad.service';
import { Router } from '@angular/router';

export interface IPlaceholder {
    id: number;
    name: string;
    title: string;
    description?: string;
}

export interface IAutoComplete {
    enable: boolean;
    mergeFields?: IPlaceholder[];
    openControlCharacter?: '[' | '{';
    closeControlCharacter?: ']' | '}';
    count?: number;
}

declare let CKEDITOR: any;

@Component({
    selector: 'ck-editor-dynamic-field-component',
    template: `
        <mt-dynamic-form-input-wrapper [parentControl]="thisControl" [formGroup]="getGroup()">
            <ckeditor
                [(ngModel)]="text"
                (dataChange)="onDataChange($event)"
                (blur)="onBlur()"
                (focus)="onFocus()"
                [ngModelOptions]="{ standalone: true }"
                [config]="editorConfig"
                (namespaceLoaded)="onNamespaceLoaded()"
                (ready)="onReady($event)"
            >
            </ckeditor>
        </mt-dynamic-form-input-wrapper>
    `,
})
export class CkEditorDynamicFieldComponent extends CustomFormControlComponentBase {
    text: string;
    editor: CKEditor4.Editor;
    controlSubscription: Subscription;

    advancedEditor: boolean;
    autoComplete: IAutoComplete = {
        closeControlCharacter: ']',
        count: 2,
        enable: false,
        mergeFields: [],
        openControlCharacter: '[',
    };

    automatedTemplate: IAutomatedTemplate;

    editorConfig: CKEditor4.Config = {
        allowedContent: true,
        autoParagraph: false,
        enterMode: 2,
        extraPlugins:
            'autocomplete,textmatch,font,save,preview,print,newpage,templates,copyformatting,div,justify,language,bidi,colorbutton,pagebreak,autolink',
        removeButtons: null,
        versionCheck: false,
    };

    constructor(changeDetectorRef: ChangeDetectorRef, private singletonService?: CkEditorSingletonService, private route?: Router) {
        super(changeDetectorRef);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.text = this.getControl().value;

        this.advancedEditor = (this.config as IDynamicFieldPartial).additionalOptions?.advancedEditor ?? false;
        this.automatedTemplate = (this.config as IDynamicFieldPartial).additionalOptions?.automatedTemplate;
        if (!this.route.url.includes('/dynamic-templates')) {
            this.singletonService.indicateCkeditorAsAlreadyUsed();
        }
        if ((this.config as IDynamicFieldPartial).additionalOptions?.autoComplete?.enable ?? false) {
            if (!this.singletonService.nameSpaceAlreadyLoaded() && !CKEDITOR.plugins?.registered.placeholder) {
                // ckeditor already being used instead external plugins are not yet registered
                this.onNamespaceLoaded();
            }
            this.editorConfig.extraPlugins += ',placeholder,pleading';
            Object.assign(this.autoComplete, (this.config as IDynamicFieldPartial).additionalOptions.autoComplete);
        }
        if (this.advancedEditor) {
            this.editorConfig.toolbarGroups = [
                { name: 'document', groups: ['mode', 'document', 'doctools'] },
                { name: 'clipboard', groups: ['clipboard', 'undo'] },
                '/',
                { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
                { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
                { name: 'pagebreak', groups: ['insert'] },
                { name: 'links', groups: ['links'] },
                { name: 'autolink', groups: ['autolink'] },
                '/',
                { name: 'styles', groups: ['styles'] },
                { name: 'colors', groups: ['colors'] },
            ];
        } else {
            this.editorConfig.toolbar = [
                ['Bold'],
                ['Italic'],
                ['Underline'],
                ['NumberedList'],
                ['BulletedList'],
                ['Source'],
                ['PageBreak'],
                ['Link'],
            ];
        }
        this.controlSubscription = this.thisControl.valueChanges.subscribe((value) => (this.text = value));
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    ngOnDestroy(): void {
        if (this.controlSubscription) {
            this.controlSubscription.unsubscribe();
        }
    }

    onBlur(): void {
        if (this.getControl().enabled) {
            this.setTextValue();
            this._isFocused = false;
            this.customBlur.emit();
        }
    }

    onFocus(): void {
        if (this.getControl().enabled) {
            this._isFocused = true;
            this.customFocus.emit();
        }
    }

    onDataChange(event): void {
        this.text = event;
        this.setTextValue();
    }

    setTextValue(): void {
        const control = this.getControl();
        if (control.value !== this.text) {
            control.setValue(this.text);
            control.markAsDirty();
        }
    }

    onNamespaceLoaded(): void {
        if (this.route.url.includes('/dynamic-templates')) {
            this.loadCustomPlaceholderWidget();
            this.loadCustomPleadingWidget();
        }
    }

    onReady(event: CKEditor4.EventInfo): void {
        this.editor = event.editor;

        if (this.autoComplete.enable) {
            this.loadAutocomplete();
        }

        event.editor.on('beforeCommandExec', function (event): void {
            // Show the paste dialog for the paste buttons and right-click paste
            if (event.data.name === 'paste') {
                event.editor._.forcePasteDialog = true;
            }
            // Don't show the paste dialog for Ctrl+Shift+V
            if (event.data.name === 'pastetext' && event.data.commandData.from === 'keystrokeHandler') {
                event.cancel();
            }
        });

        event.editor.on('afterCommandExec', (event) => {
            if (event.data.name === 'insertPleading') {
                this.automatedTemplate.OverrideMargins = true;
            }
        });
    }

    loadAutocomplete(): void {
        const openChar = this.autoComplete.openControlCharacter;
        const closeChar = this.autoComplete.closeControlCharacter;
        const count = this.autoComplete.count;
        const openControlString = openChar.repeat(count);
        const closeControlString = closeChar.repeat(count);

        const itemTemplate = '<li data-id="{id}"><div><strong class="item-title">{title}</strong></div><div><i>{description}</i></div></li>';
        // The space is necessary at the end of the outputTemplate to prevent the editor from getting stuck in the placeholder.
        const outputTemplate = `<span>${openControlString}{title}${closeControlString}</span>&nbsp;`;

        const autocompleteConfig = {
            dataCallback: (matchInfo, callback) => {
                const regex = `[\\${openChar}\\${closeChar}]*`;
                const data = this.autoComplete.mergeFields.filter((item) => {
                    const itemName = item.name.toLowerCase();
                    // Strip control characters if present to match anywhere in the string
                    let query: string = matchInfo.query.replace(new RegExp(regex, 'g'), '').toLowerCase();
                    // CKEditor uses non-breaking spaces, so we have to replace with a regular space before matching
                    query = query.replace(/\u00A0/g, ' ');
                    return itemName.indexOf(query) > -1;
                });

                callback(data);
            },
            itemTemplate: itemTemplate,
            outputTemplate: outputTemplate,
            textTestCallback: (range) => {
                if (!range.collapsed) {
                    return null;
                }

                return CKEDITOR.plugins.textMatch.match(range, (text, offset) => {
                    const regex = `\\${openChar}{${count}}[^\\${openChar}\\${closeChar}]*\\${closeChar}{0,${count}}$`;
                    const left = text.slice(0, offset);
                    const match = left.match(new RegExp(regex));
                    if (!match) {
                        return null;
                    }
                    return { start: match.index, end: offset };
                });
            },
        };

        const autocomplete = new CKEDITOR.plugins.autocomplete(this.editor, autocompleteConfig);
        autocomplete.getHtmlToInsert = function (item): void {
            return this.outputTemplate.output(item);
        };
    }

    loadCustomPleadingWidget(): void {
        CKEDITOR.plugins.add('pleading', {
            init: (editor) => {
                editor.addCommand('insertPleading', {
                    exec: (editor) => {
                        editor.insertHtml(`
                        <div class=pleading>
                            <div class=first-border>
                            <div class=second-border>
                            <div class="numbers">
                            <span>1</span>
                            <span>2</span>
                            <span>3</span>
                            <span>4</span>
                            <span>5</span>
                            <span>6</span>
                            <span>7</span>
                            <span>8</span>
                            <span>9</span>
                            <span>10</span>
                            <span>11</span>
                            <span>12</span>
                            <span>13</span>
                            <span>14</span>
                            <span>15</span>
                            <span>16</span>
                            <span>17</span>
                            <span>18</span>
                            <span>19</span>
                            <span>20</span>
                            <span>21</span>
                            <span>22</span>
                            <span>23</span>
                            <span>24</span>
                            <span>25</span>
                            <span>26</span>
                            <span>27</span>
                            <span>28</span>
                            </div>
                            </div>
                            </div>
                            <div class="right-border"></div>
                            <div class="pleading-content">Type here...</div>
                            </div>
                            <div style="page-break-after: always;"><span style="display: none;">&nbsp;</span></div>
                            <style>
                                * {
                                    box-sizing: border-box;
                                    margin: 0;
                                    padding: 0;
                                }
                                .pleading {
                                    position: relative;
                                    margin-left: 1in;
                                    margin-right: 1in;
                                    min-height: 13.6in;
                                }
                                .first-border {
                                    height:13.5in;
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    bottom: 0;
                                    border-left: 1px solid black;
                                }
                                .right-border {
                                    height:13.5in;
                                    position: absolute;
                                    right: 0;
                                    top: 0;
                                    bottom: 0;
                                    border-right: 1px solid black;
                                }
                                .second-border {
                                    position: absolute;
                                    left: 0.05in;
                                    top: 0;
                                    bottom: 0;
                                    border-left: 1px solid black;
                                }
                                .numbers {
                                    position: absolute;
                                    left: -0.4in;
                                    text-align: right;
                                    top: 1.2in;
                                    font-size: 13px;
                                }
                                .numbers > span {
                                    display: block;
                                    line-height: 3;
                                }
                                .pleading-content {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    right: 0;
                                    padding-left: 10px;
                                    padding-top: 1.2in;
                                    padding-bottom: 1.2in;
                                    padding-right: 1in;
                                    min-height: 13.5in;
                                    z-index: 100;
                                }
                            </style>
                        `);
                    },
                });
                editor.ui.addButton('Pleading', {
                    command: 'insertPleading',
                    icon: 'simplebox',
                    label: 'Insert pleading',
                    toolbar: 'insert',
                });
            },
        });
    }

    loadCustomPlaceholderWidget(): void {
        const openChar = this.autoComplete.openControlCharacter;
        const closeChar = this.autoComplete.closeControlCharacter;
        const count = this.autoComplete.count;
        const openControlString = openChar.repeat(count);
        const closeControlString = closeChar.repeat(count);
        const mergeFields = this.autoComplete.mergeFields;

        // Original plugin logic: https://github.com/ckeditor/ckeditor4/blob/master/plugins/placeholder/plugin.js
        // Modified to have a dynamic control character.
        CKEDITOR.plugins.add('placeholder', {
            afterInit: (editor) => {
                const placeholderReplaceRegex = `\\${openChar}{${count}}[^\\${openChar}\\${closeChar}]*\\${closeChar}{${count}}`;

                editor.dataProcessor.dataFilter.addRules({
                    text: (text, node) => {
                        const dtd = node.parent && CKEDITOR.dtd[node.parent.name];

                        if (dtd && !dtd.span) {
                            return;
                        }

                        return text.replace(new RegExp(placeholderReplaceRegex, 'g'), (match) => {
                            if (!mergeFields.some((mf) => mf.title === match.slice(count, -count))) {
                                // If match is not a merge field, do not make it a placeholder.
                                return match;
                            }
                            let widgetWrapper = null;
                            const innerElement = new CKEDITOR.htmlParser.element('span', {
                                class: 'cke_placeholder',
                            });

                            innerElement.add(new CKEDITOR.htmlParser.text(match));
                            widgetWrapper = editor.widgets.wrapElement(innerElement, 'placeholder');

                            return widgetWrapper.getOuterHtml();
                        });
                    },
                });
            },
            hidpi: true,
            icons: 'placeholder',
            init: (editor) => {
                const lang = editor.lang.placeholder;

                CKEDITOR.dialog.add('placeholder', editor.plugins.placeholder.path + 'dialogs/placeholder.js');

                editor.widgets.add('placeholder', {
                    dialog: 'placeholder',
                    pathName: lang.pathName,
                    template: `<span class="cke_placeholder">${openControlString + closeControlString}</span>`,

                    downcast: function (): void {
                        return new CKEDITOR.htmlParser.text(openControlString + this.data.name + closeControlString);
                    },

                    init: function (): void {
                        this.setData('name', this.element.getText().slice(count, -count));
                    },

                    data: function (): void {
                        this.element.setText(openControlString + this.data.name + closeControlString);
                    },

                    getLabel: function (): void {
                        return this.editor.lang.widget.label.replace(/%1/, this.data.name + ' ' + this.pathName);
                    },
                });

                editor.ui.addButton('CreatePlaceholder', {
                    command: 'placeholder',
                    icon: 'placeholder',
                    label: lang.toolbar,
                    toolbar: 'insert,5',
                });
            },
            lang: 'af,ar,az,bg,ca,cs,cy,da,de,de-ch,el,en,en-au,en-gb,eo,es,es-mx,et,eu,fa,fi,fr,fr-ca,gl,he,hr,hu,id,it,ja,km,ko,ku,lv,nb,nl,no,oc,pl,pt,pt-br,ro,ru,si,sk,sl,sq,sr,sr-latn,sv,th,tr,tt,ug,uk,vi,zh,zh-cn',
            onLoad: () => {
                CKEDITOR.addCss('span > .cke_placeholder { background-color: #8ac175; }');
            },
            requires: 'widget,dialog',
        });
    }
}
