import { IAttachmentType } from '@model/interfaces/attachment-type.d';
import { Injectable } from '@angular/core';
import { IUserRole } from '@model/interfaces/user-role';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from '@mt-ng2/base-service';
import { IService } from '@model/interfaces/service';
import { IUserRoleServiceFollowupsStatus } from '@model/interfaces/custom/userole-service-followups-status';
import { DashboardTypeEnums } from '../common/constants/Enums';
import { UserTypeValues } from '../common/constants/user-type.enum';

@Injectable()
export class UserRoleService extends BaseService<IUserRole> {
    private emptyUserRole: IUserRole = {
        AllowToSendAsAttachment: false,
        AssignManualContactTask: false,
        Auto: false,
        ClientCaseCreateEmail: false,
        ClientCaseUpdateEmail: false,
        CompilationAssignedTo: false,
        DashboardTypeId: null,
        Description: null,
        GeneralLiability: false,
        Id: 0,
        IsEditable: true,
        Name: null,
        ReceiveAutoCaseClosingJobEmail: false,
        ReceiveCpFilmDeleteEmail: false,
        ReportAssignedTo: false,
        RetrievalAssignedTo: false,
        SchedulingAssignedTo: false,
        TaskDistributionList: false,
        UserTypeId: null, // prevents accidental saves
        WorkersComp: false,
        SubpoenaAssignedTo: false,
        ClientPortalSubpoenaServiceTask: false,
        AssignTaskOnPhysicianExpertDocUpdate: false,
        ClientPortalRequestEmails: false,
        PhysicianExpertPortalRequestEmails: false,
    };

    constructor(public http: HttpClient) {
        super('/userRoles', http);
    }

    getEmptyUserRole(): IUserRole {
        return { ...this.emptyUserRole };
    }

    deleteRole(roleId: number): Observable<IUserRole> {
        return this.http.delete<IUserRole>(`/userRoles/delete/${roleId}`);
    }

    isUserRoleInUse(roleId: number): Observable<boolean> {
        return this.http.post<boolean>(`/userRoles/${roleId}/isUserRoleInUse`, {});
    }

    getRolesByType(typeId: UserTypeValues): Observable<IUserRole[]> {
        return this.http.get<IUserRole[]>(`/userRoles/_getByType/${typeId}`, { observe: 'body' });
    }

    getRolesWithClaims(): Observable<IUserRole[]> {
        return this.http.get<IUserRole[]>('/userRoles/withClaims');
    }

    getRolesServicesByAuthUserId(authUserId: number): Observable<IService[]> {
        return this.http.get<IService[]>(`/userRoles/withServices/${authUserId}`);
    }

    getRoleByUserId(userId: number): Observable<IUserRole> {
        return this.http.get<IUserRole>(`/userRoles/forUser/${userId}`);
    }

    // Until requirement changes, we will only show Admin User Roles
    getAll(): Observable<IUserRole[]> {
        return this.getRolesByType(UserTypeValues.Admin);
    }

    saveUserRole(role: IUserRole): Observable<IUserRole> {
        if (role.Id > 0) {
            return this.http.put<IUserRole>('/userRoles/update', role);
        } else {
            return this.http.post<IUserRole>('/userRoles/create', role);
        }
    }
    updateClaims(roleId: number, claims: any): Observable<unknown> {
        return this.http.put(`/userRoles/${roleId}/updateClaims`, claims);
    }
    updateUserRoleServices(roleId: number, userRoleServiceFollowupsStatus: IUserRoleServiceFollowupsStatus): Observable<any> {
        return this.http.put(`/userRoles/${roleId}/updateServices`, userRoleServiceFollowupsStatus);
    }

    updateUserRoleDocType(roleId: number, attachmentTypes: IAttachmentType[]): Observable<any> {
        return this.http.put(`/userRoles/${roleId}/updateDocTypes`, attachmentTypes);
    }

    getDashboardType(authUserId: number): Observable<DashboardTypeEnums> {
        return this.http.get<DashboardTypeEnums>(`/userRoles/${authUserId}/GetDashboardTypeByAuthUserId`);
    }
}
