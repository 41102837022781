import { AddressFormatterService } from './../../common/services/address-formatter.service';
import { IAddress } from '@model/interfaces/address.d';
import { ILabelGenerator } from '../../common/interfaces/ILabelGenerator.d';
export class AddressLabelGenerator implements ILabelGenerator {
    constructor(private addressFormatterService: AddressFormatterService) { }

    GetLabel(entity: IAddress): string {
        return `${entity.IsPrimary ? '<i class="fa fa-star btn selected"></i> ' : ' '}` + this.addressFormatterService.formatAddress(entity);
    }
}
