import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ITaskTemplateRecurrenceSchedule } from '@model/interfaces/task-template-recurrence-schedule';

@Component({
    selector: 'task-template-recurrence-schedule',
    styles: [
        `
            .frequency-table tr {
                background-color: #fff;
                cursor: default;
                border: 1px solid #aaa;
            }
            .frequency-table:hover,
            tr:hover {
                background-color: #fff;
                cursor: default;
                border: 1px solid #aaa;
            }
            .invalid {
                border: 1px solid red;
            }
        `,
    ],
    templateUrl: './task-template-recurrence-schedule.component.html',
})
export class TaskTemplateRecurrenceScheduleComponent implements OnInit {
    emptyTasktemplateRecurrenceSchedule: ITaskTemplateRecurrenceSchedule = {
        DaysUntilRecurrence: 1,
        Id: 0,
        NumberOfIterations: 0,
        TaskTemplateId: 0,
    };
    @Output() recurrenceChange: EventEmitter<ITaskTemplateRecurrenceSchedule[]> = new EventEmitter();
    @Input() copiedRecurrenceSchedules;
    private _recurrences: ITaskTemplateRecurrenceSchedule[];
    set recurrences(val: ITaskTemplateRecurrenceSchedule[]) {
        this._recurrences = val;
    }
    get recurrences(): ITaskTemplateRecurrenceSchedule[] {
        return this._recurrences;
    }

    constructor() { }

    ngOnInit(): void {
        this.recurrences = !this.copiedRecurrenceSchedules ? [this.emptyTasktemplateRecurrenceSchedule] : this.copiedRecurrenceSchedules;
        this.recurrenceChange.emit(this.recurrences);
    }

    addRow(): void {
        this.recurrences.push({ ...this.emptyTasktemplateRecurrenceSchedule });
        this.recurrenceChange.emit(this.recurrences);
    }

    removeRow(index: number): void {
        this.recurrences.splice(index, 1);
        this.recurrenceChange.emit(this.recurrences);
    }

    onChange(): void {
        if (
            this.recurrences.every(
                (x) => x.DaysUntilRecurrence && x.DaysUntilRecurrence >= 1 && x.NumberOfIterations !== null && x.NumberOfIterations >= 0,
            )
        ) {
            this.recurrenceChange.emit(this.recurrences);
        } else {
            this.recurrenceChange.emit([]);
        }
    }
}
