import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ClaimTypes } from '@model/ClaimTypes';
import { IIndividual } from '@model/interfaces/individual';
import { IStrivenCategory } from '@model/partials/striven-category';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IModalOptions, IModalWrapperApi, ModalService } from '@mt-ng2/modal-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { AddressBookService } from '../../addressbook.service';
import { IndividualService } from '../individual.service';
import { IAddress } from '@model/interfaces/address';

type IStrivenCreationOptions = 'client' | 'vendor';

@Component({
    selector: 'app-individual-striven-creation-modal',
    templateUrl: './individual-striven-creation-modal.component.html',
})
export class IndividualStrivenCreationModal {
    @Input() individual: IIndividual;

    modalWrapperApi: IModalWrapperApi;
    confirmModalWrapperApi: IModalWrapperApi;

    selectedCreationOption: IStrivenCreationOptions = 'client';
    categories: IStrivenCategory[] = [];
    selectedCategoryId: number;

    strivenCreationOptions = {
        client: 'client' as IStrivenCreationOptions,
        vendor: 'vendor' as IStrivenCreationOptions,
    };

    constructor(
        private claimsService: ClaimsService,
        private modalService: ModalService,
        private addressBookService: AddressBookService,
        private individualService: IndividualService,
        private notificationsService: NotificationsService,
    ) {}

    get canCreateStrivenClientVendor(): boolean {
        return this.claimsService.hasClaim(ClaimTypes.CreateClientVendorInStriven, [ClaimValues.FullAccess]);
    }

    showModal() {
        if (this.modalWrapperApi) {
            this.modalWrapperApi.show();
        }
    }

    createStrivenClient(primaryAddress: IAddress) {
        this.addressBookService
            .createStrivenClient(
                `${this.individual.LastName}, ${this.individual.FirstName} ${this.individual.Suffix ?? ''}`,
                primaryAddress,
                this.selectedCategoryId,
            )
            .subscribe(
                (res) => {
                    this.notificationsService.success('Client created in Striven successfully.');
                    if (res.Entity) {
                        this.individual.StrivenId = res.Entity.Id;
                        this.individualService.update(this.individual).subscribe(() => {
                            this.modalWrapperApi.close();
                            this.confirmModalWrapperApi.close();
                            window.location.reload();
                        });
                    }
                },
                (err: HttpErrorResponse) => {
                    if (err.status === +HttpStatusCode.BadRequest) {
                        this.notificationsService.error(err.error as string);
                    } else {
                        this.notificationsService.error("Couldn't create client in Striven. Please try again.");
                    }
                },
            );
    }

    createStrivenVendor(primaryAddress: IAddress) {
        this.addressBookService
            .createStrivenVendor(
                `${this.individual.LastName}, ${this.individual.FirstName} ${this.individual.Suffix ?? ''}`,
                primaryAddress,
                this.selectedCategoryId,
            )
            .subscribe(
                (res) => {
                    this.notificationsService.success('Vendor created in Striven successfully.');
                    if (res.Entity) {
                        this.individual.StrivenId = res.Entity.Id;
                        this.individualService.update(this.individual).subscribe(() => {
                            this.modalWrapperApi.close();
                            this.confirmModalWrapperApi.close();
                            window.location.reload();
                        });
                    }
                },
                (err: HttpErrorResponse) => {
                    if (err.status === +HttpStatusCode.BadRequest) {
                        this.notificationsService.error(err.error as string);
                    } else {
                        this.notificationsService.error("Couldn't create vendor in Striven. Please try again.");
                    }
                },
            );
    }

    onSelect(selectedOption: IStrivenCreationOptions) {
        this.selectedCreationOption = selectedOption;

        if (this.selectedCreationOption === 'client') {
            this.addressBookService.getStrivenClientCategories().subscribe((categories) => {
                this.categories = categories;
                if (this.confirmModalWrapperApi) {
                    this.confirmModalWrapperApi.show();
                }
            });
        } else {
            this.addressBookService.getStrivenVendorCategories().subscribe((categories) => {
                this.categories = categories;
                if (this.confirmModalWrapperApi) {
                    this.confirmModalWrapperApi.show();
                }
            });
        }
    }

    modalOptions: IModalOptions = {
        allowEscapeKey: false,
        allowOutsideClick: false,
        title: 'Create Client/Vendor in Striven',
    };

    confirmModalOptions: IModalOptions = {
        allowEscapeKey: false,
        allowOutsideClick: false,
        title: `Confirm Striven ${this.selectedCreationOption} Creation`,
    };

    setPrimaryAddressAndCreateStrivenClient() {
        this.individualService.getIndividualAddresses(this.individual.Id).subscribe((addresses) => {
            const primaryAddress = addresses.find((a) => a.IsPrimary && !a.Archived);
            this.createStrivenClient(primaryAddress);
        });
    }

    setPrimaryAddressAndCreateStrivenVendor() {
        this.individualService.getIndividualAddresses(this.individual.Id).subscribe((addresses) => {
            const primaryAddress = addresses.find((a) => a.IsPrimary && !a.Archived);
            this.createStrivenVendor(primaryAddress);
        });
    }

    confirm() {
        this.modalService
            .showModal({
                titleText: `Are you sure you want to create this ${this.selectedCreationOption} in Striven?`,
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            })
            .subscribe((res) => {
                if (res.isConfirmed) {
                    if (this.selectedCreationOption === 'client') {
                        this.setPrimaryAddressAndCreateStrivenClient();
                    } else {
                        this.setPrimaryAddressAndCreateStrivenVendor();
                    }
                }
            });
    }
}
