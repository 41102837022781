import { ImedClaimService } from './../../../../imedclaim.service';
import { ImedClaimDynamicConfig } from './../../../../imed-claim.dynamic-config';
import { Component, Input, OnInit } from '@angular/core';
import { IImedClaimService } from '@model/interfaces/imed-claim-service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { IImedClaim } from '@model/interfaces/imed-claim';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { HttpErrorResponse } from '@angular/common/http';
import { IPopUpParams, PopUpService } from '../../../../../common/services/popup-service';
import { PopUpTypeEnums } from '../../../../../common/constants/Enums';
import { DynamicField, DynamicLabel, IDynamicFormConfig } from '@mt-ng2/dynamic-form';

// Issues related to claims need to be shown at IME service level as Issues are  related to IME Service ONLY so we created a seperate component inside services and update claims table from there.
// If there are multiple IMES in a claim, updating one will update all based on the following task
// Task id:962201
@Component({
    selector: 'app-ime-service-issues',
    templateUrl: './ime-service-issues.component.html',
})
export class ImeServiceIssuesComponent implements OnInit {
    @Input() iImedClaimServiceModel: IImedClaimService;
    @Input() canEdit: boolean;
    serviceForm: UntypedFormGroup;
    issueFormFactory: ImedClaimDynamicConfig<IImedClaim>;
    config: IDynamicFormConfig;
    isEditing: boolean;
    isHovered: boolean;

    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    constructor(
        private fb: UntypedFormBuilder,
        private imedClaimService: ImedClaimService,
        private notificationsService: NotificationsService,
        private popUpService: PopUpService,
    ) {}

    ngOnInit(): void {
        this.config = { formObject: [], viewOnly: [] };
        this.setConfig();
    }
    setConfig(): void {
        this.isEditing = false;
        this.isHovered = false;
        this.serviceForm = this.fb.group({
            ImedClaim: this.fb.group({}),
        });
        this.issueFormFactory = new ImedClaimDynamicConfig<IImedClaim>(this.iImedClaimServiceModel.ImedClaim, null, null, null, null, null, null, null, [
            'PastMedicalHistory',
            'PastSubsequentHistory',
            'HistoryOfAllegedInjury',
            'OccupationalStatus',
            'CurrentSymptomsAndCompliants',
            'CurrentCourseOfTreatment',
            'Impression',
            'DegreeOfDisability',
            'NeedForFurtherTreatment',
            'WorkStatus',
            'CausalRelationship',
        ]);

        this.config = this.issueFormFactory.getForUpdate();
        this.viewOnly = this.config?.viewOnly?.map((x) => new DynamicLabel(x));
        this.formObject = this.config.formObject?.map((x) => new DynamicField(x));
    }

    edit(): void {
        this.isEditing = true;
    }
    cancelClick(): void {
        this.isEditing = false;
    }
    formSubmitted(): void {
        this.issueFormFactory.assignFormValues(this.iImedClaimServiceModel.ImedClaim, this.serviceForm.value.ImedClaim as IImedClaim);
        this.imedClaimService.update(this.iImedClaimServiceModel.ImedClaim).subscribe(
            () => {
                this.cancelClick();
                this.setConfig();
                this.success();
            },
            (error: HttpErrorResponse) => {
                if (error.status === 400) {
                    const popUp: IPopUpParams = {
                        html: error.error,
                        type: PopUpTypeEnums.Hard_Pop_Up,
                    };
                    this.popUpService.showPopUp(popUp).subscribe();
                }
            },
        );
    }
    success(): void {
        this.notificationsService.success('Saved Successfully');
    }
}
