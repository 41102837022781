import { IFilmReviewService } from '@model/interfaces/film-review-service';
import { IImedClaimService } from '@model/interfaces/imed-claim-service';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IFilmInformationDTO } from '@model/interfaces/custom/film-information-dto';
import 'rxjs/operators';
import { FilmInformationService } from '@app-shared/services/film-information.service';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Component({
    selector: 'app-film-review-film-retrieval-selector',
    templateUrl: './film-retrieval-selector.component.html',
})
export class FilmReviewFilmRetrievalSelectorComponent {
    @Input() filmReviewServiceModel: IFilmReviewService;
    @Input() iImedClaimServiceModel: IImedClaimService;
    @Input() canEdit: boolean;
    @Input() filmInformations: IFilmInformationDTO[];
    @Output('refreshFilmInformation') refreshFilmInformation: EventEmitter<any> = new EventEmitter<any>();

    isEditing: boolean;
    isHovered: boolean;

    constructor(private filmInformationService: FilmInformationService, private notificationService: NotificationsService) { }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    rebindFilmInformation(): void {
        this.refreshFilmInformation.emit();
    }

    cancelClick(): void {
        this.isEditing = false;
    }

    selectAll(): void {
        this.filmInformationService.selectAllFilms(this.iImedClaimServiceModel.Id).subscribe(() => {
            this.rebindFilmInformation();
            this.notificationService.success('Films selected successfully!');
        });
    }

    unSelectAll(): void {
        this.filmInformationService.unSelectAllFilms(this.iImedClaimServiceModel.Id).subscribe(() => {
            this.rebindFilmInformation();
            this.notificationService.success('Films unselected successfully!');
        });
    }

    anyUnselected(): boolean {
        return this.filmInformations.some((film) => !film.IsSelected);
    }

    anySelected(): boolean {
        return this.filmInformations.some((film) => film.IsSelected !== null);
    }
}
