import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { IImedClaimFollowup } from '@model/interfaces/imed-claim-followup';
import { ImedClaimFollowupService } from '../../imedclaim-followup.service';

@Component({
    selector: 'app-imed-claim-followup-header',
    templateUrl: './imed-claim-followup-header.component.html',
})
export class ImedClaimFollowupHeaderComponent implements OnInit, OnDestroy {
    imedClaimFollowup: IImedClaimFollowup;
    header: string;
    subscriptions: Subscription = new Subscription();

    constructor(private imedClaimFollowupService: ImedClaimFollowupService, private route: ActivatedRoute) { }

    ngOnInit(): void {
        this.subscriptions.add(
            this.imedClaimFollowupService.changeEmitted$.subscribe((imedClaimFollowup) => {
                this.setHeader(imedClaimFollowup);
            }),
        );
        const id = +this.route.snapshot.paramMap.get('followupId');
        if (id > 0) {
            this.imedClaimFollowupService.getById(id).subscribe((imedClaimFollowup) => {
                this.setHeader(imedClaimFollowup);
            });
        } else {
            this.header = 'Add Follow-up';
            this.imedClaimFollowup = this.imedClaimFollowupService.getEmptyImedClaimFollowup();
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    setHeader(imedClaimFollowup: IImedClaimFollowup): void {
        this.imedClaimFollowup = imedClaimFollowup;
        this.header = `Follow-up: ${this.imedClaimFollowup.MasterFollowupType && this.imedClaimFollowup.MasterFollowupType.Name}`;
    }
}
