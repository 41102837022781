import { NgModule } from '@angular/core';

import { AutomatedTemplateRoutingModule } from './automated-template-routing.module';
import { AutomatedTemplateAddComponent } from './automated-template-add/automated-template-add.component';
import { AutomatedTemplateBasicInfoComponent } from './automated-template-basic-info/automated-template-basic-info.component';
import { AutomatedTemplateDetailComponent } from './automated-template-detail/automated-template-detail.component';
import { AutomatedTemplatesComponent } from './automated-template-list/automated-templates.component';
import { AutomatedTemplateHeaderComponent } from './automated-template-header/automated-template-header.component';
import { AutomatedTemplateService } from './services/automated-template.service';
import { SharedModule } from '../common/shared.module';
import { AutomatedTemplateFormComponent } from './automated-template-form/automated-template-form.component';
import { AutomatedTemplateEditorComponent } from './automated-template-editor/automated-template-editor.component';

@NgModule({
    declarations: [
        AutomatedTemplatesComponent,
        AutomatedTemplateHeaderComponent,
        AutomatedTemplateAddComponent,
        AutomatedTemplateDetailComponent,
        AutomatedTemplateBasicInfoComponent,
        AutomatedTemplateFormComponent,
        AutomatedTemplateEditorComponent,
    ],
    imports: [SharedModule, AutomatedTemplateRoutingModule],
})
export class AutomatedTemplateModule {
    static forRoot(): any {
        return {
            ngModule: AutomatedTemplateModule,
            providers: [AutomatedTemplateService],
        };
    }
}
