import { Component, Input, OnInit, ChangeDetectorRef, OnDestroy, ViewChild, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { isNullOrWhitespace, markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { ClaimsService, ClaimValues, AuthService } from '@mt-ng2/auth-module';
import { BackButtonService } from '@mt-ng2/back-button-module';
import { IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { IFollowupType } from '@model/interfaces/followup-type';
import { finalize, tap } from 'rxjs/operators';
import { IEmailTemplate } from '@model/interfaces/email-template';
import { CommonService } from '@common/services/common.service';
import { ClaimTypes } from '@model/ClaimTypes';
import { IImedClaimFollowup } from '@model/interfaces/imed-claim-followup';
import { IImedClaim } from '@model/interfaces/imed-claim';
import { ImedClaimFollowupService } from '../../imedclaim-followup.service';
import { ImedClaimService } from '../../imedclaim.service';
import { CommonEmailComponent } from '@common/common-email/common-email.component';
import { FollowUpTypes, MasterFollowUpTypes, ServiceIdEnums } from '@common/constants/Enums';
import { safeDetectChanges } from '@common/cdr-safety/cdr-safety.library';
import { ImedClaimFollowupDynamicConfig } from '../imed-claim-followup.dynamic-config';
import { ImedClaimFollowupDynamicControlsPartial } from '@model/partials/imed-claim-followup-partial.form-controls';
import { Observable, forkJoin } from 'rxjs';
import { UserService } from '../../../users/user.service';
import { FollowupTypeService } from '../../services/common-components/service-followups/followuptype.service';
import { MasterFollowupTypeService } from '../../services/common-components/service-followups/master-follow-up-type-service';
import { IMasterFollowupType } from '@model/interfaces/master-followup-type';
import { IImedClaimService } from '@model/interfaces/imed-claim-service';
import { ClosedServiceFilterPipe } from '@common/filters/closed-service-filter';
import { MultiselectItem } from '@mt-ng2/multiselect-control';
import { MetaItem } from '@mt-ng2/base-service';
import { IImedClaimAdditionalBillToParty } from '@model/interfaces/imed-claim-additional-bill-to-party';
import { IImedClaimAdditionalBillToPartyPartial } from '@model/partials/imed-claim-additional-bill-to-party';
import { IImedClaimServiceSplitBillingDetail } from '@model/interfaces/imed-claim-service-split-billing-detail';
import { ThisReceiver } from '@angular/compiler';
import { IModalOptions, IModalWrapperApi } from '@mt-ng2/modal-module';

@Component({
    selector: 'app-imed-claim-followup-basic-info',
    templateUrl: './imed-claim-followup-basic-info.component.html',
})
export class ImedClaimFollowupBasicInfoComponent implements OnInit, OnDestroy {
    @Input()
    imedClaimFollowup: IImedClaimFollowup;
    @Input()
    canEdit: boolean;
    imedClaimModel: IImedClaim;
    isEditing: boolean;
    isHovered: boolean;
    config: IDynamicFormConfig;
    imedClaimId: number;
    followUpTypeIdValue: number;
    imedClaimFollowupForm: any;
    formFactory: ImedClaimFollowupDynamicConfig<IImedClaimFollowup>;
    services: IImedClaimService[] = [];
    serviceSelectItems: MetaItem[] = [];
    selectedServiceIdsArray: number[] = [];
    doubleClickIsDisabled = false;
    renderAdditionalInfoComponent = false;
    renderReceivedAppointmentDateTime = false;
    renderReceiptOfNoticeOfImeConfirmedByName = false;
    renderLastAppointment = false;
    renderImeAppointmentConfirmedWithPlaintiffsAttorneyOfficeByName = false;
    isDate = false;
    form: UntypedFormGroup;
    abstractControls: any;
    missedAppointmentText: string;
    canFollowupBeCreated = true;
    formRendered = false;
    claimIdFromRoute: number;
    userFullName: string;
    followupTypes: IMasterFollowupType[];
    notes: string;
    notesMaxLength = 500;
    showNotesLength = false;

    renderFilmReviewPersonContactedOrSpokenTo = false;
    renderFilmReviewReportForwardedToClientDate = false;
    renderRecordReviewPersonContactedOrSpokenTo = false;
    renderCheckNumber = false;
    renderClearanceDate = false;
    renderReportDueBy = false;
    renderCommunicationMethod = false;
    renderConfirmedWithPhysiciansOffice = false;
    renderConfirmedCancellationWith = false;
    renderDeferredUntil = false;
    renderFollowUpAgainOn = false;
    renderReportReadyOnOrAround = false;
    renderPersonContactedOrSpokenTo = false;
    renderTrackingNumber = false;
    renderVia = false;
    followUpId: number;
    followUpTypeSubscription;
    renderAmount = false;
    canEditFollowupDate = false;
    updatedFollowupDate: Date;
    isSplitBillingFollowup: boolean = false;
    imedClaimAdditionalBillToParties: IImedClaimAdditionalBillToPartyPartial[];
    selectedImedClaimAdditionalBillToPartyIdsArray: number[] = [];
    imedClaimServiceSplitBillingDetail: IImedClaimServiceSplitBillingDetail[];
    isConsentToChangeDocumentUploaded: boolean;
    modalOptions: IModalOptions = {
        customClass: 'modal-hide-actions' as any,
        width: 800,
    };
    uploadAttachmentsModalApi: IModalWrapperApi;
    isDefenseCounselChangedFollowup: boolean = false;

    filteredRecordRetrievalServiceSelectItems: MetaItem[] = [];

    @ViewChild('emailComponent') emailComponent: CommonEmailComponent;
    showEmailControl = false;

    get notesCurrentLength(): number {
        if (this.imedClaimFollowup && this.imedClaimFollowup.Notes) {
            const textWithNoHTML = this.commonService.stripHtml(this.imedClaimFollowup.Notes);
            return textWithNoHTML.length;
        } else {
            return 0;
        }
    }

    constructor(
        private imedClaimFollowupService: ImedClaimFollowupService,
        private masterFollowupTypeService: MasterFollowupTypeService,
        private notificationsService: NotificationsService,
        private authService: AuthService,
        private router: Router,
        private userService: UserService,
        private route: ActivatedRoute,
        private backButtonService: BackButtonService,
        private cdr: ChangeDetectorRef,
        private fb: UntypedFormBuilder,
        private imedClaimService: ImedClaimService,
        private commonService: CommonService,
        private claimsService: ClaimsService,
        private closedServiceFilter: ClosedServiceFilterPipe,
    ) {}

    ngOnInit(): void {
        this.canEditFollowupDate = this.claimsService.hasClaim(ClaimTypes.CanEditFollowupDate, [ClaimValues.FullAccess]);
        this.followUpTypeIdValue = this.imedClaimFollowup.MasterFollowupTypeId;
        this.form = this.fb.group({
            ImedClaimFollowup: this.fb.group({
                MasterFollowUpTypeId: this.fb.control(this.followUpTypeIdValue),
            }),
        });
        this.claimIdFromRoute = +this.route.snapshot.parent.parent.params.imedClaimId;
        this.imedClaimId = this.imedClaimFollowup && this.imedClaimFollowup.ImedClaimId ? this.imedClaimFollowup.ImedClaimId : this.claimIdFromRoute;
        this.isEditing = false;
        this.config = { formObject: [], viewOnly: [] };

        this.imedClaimService.getByIdForDocQueue(this.imedClaimId).subscribe((imedClaim) => {
            this.imedClaimModel = imedClaim;
            this.services = imedClaim.ImedClaimServices.filter((service) => service.Archived === false).sort((a, b) => {
                return a.Id - b.Id;
            });
            this.imedClaimAdditionalBillToParties = this.imedClaimModel.ImedClaimAdditionalBillToParties;

            this.services = this.closedServiceFilter.transform(this.services);
            this.serviceSelectItems = this.services.map((item) => new MetaItem(item.Id, this.getImedServiceName(item)));

            this.filteredRecordRetrievalServiceSelectItems = this.services
                .filter((item) => item.ServiceId !== +ServiceIdEnums.Record_Retrievals_HIPAA_Processing && item.ServiceId !== +ServiceIdEnums.Radiology_Received_From_Client)
                .map((item) => new MetaItem(item.Id, this.getImedServiceName(item)));

            forkJoin(
                this.masterFollowupTypeService.getFollowupTypeByCaseType(imedClaim.ClaimTypeId),
                this.userService.getById(this.authService.currentUser.getValue().Id),
            ).subscribe((response) => {
                const [followupTypes, user] = response;
                this.followupTypes = followupTypes;
                this.userFullName = user.FirstName + ' ' + user.LastName;
                this.setConfig();
            });
        });
    }

    getImedServiceName(imedClaimService: IImedClaimService): string {
        let serviceName = imedClaimService.Id.toString() + ' ' + imedClaimService.Service.Alias;
        if (imedClaimService.Physician && imedClaimService.Physician.Individual) {
            serviceName += ' /' + imedClaimService.Physician?.Individual?.FirstName + ' ' + imedClaimService.Physician?.Individual?.LastName;
        }
        if (imedClaimService.AddressBook) {
            serviceName += ' /' + imedClaimService.AddressBook?.FacilityName;
        }
        if (imedClaimService.Individual) {
            serviceName += ' /' + imedClaimService.Individual?.FirstName + ' ' + imedClaimService.Individual?.LastName;
        }
        return serviceName;
    }

    ngOnDestroy(): void {
        this.cdr.detach();
    }

    setConfig(): void {

        if (this.abstractControls) {
             this.abstractControls.Form.associatedServices = null;
             this.cdr.detectChanges();
        }

        this.abstractControls = new ImedClaimFollowupDynamicControlsPartial(
            this.imedClaimFollowup,
            {
                masterFollowupTypes: this.followupTypes,
                services: !this.isSplitBillingFollowup ? this.serviceSelectItems : this.filteredRecordRetrievalServiceSelectItems,
                selectedServiceIdsArray: this.selectedServiceIdsArray,
                additionalBTPs: this.imedClaimAdditionalBillToParties.map((btp) => new MetaItem(btp.Id, this.setBTPName(btp))),
                selectedAdditionalBTPIdsArray: this.selectedImedClaimAdditionalBillToPartyIdsArray,
            },
            this.canEditFollowupDate,
        );
        this.formFactory = new ImedClaimFollowupDynamicConfig<IImedClaimFollowup>(
            this.imedClaimFollowup,
            this.masterFollowupTypeService.items,
            !this.isSplitBillingFollowup ? this.serviceSelectItems : this.filteredRecordRetrievalServiceSelectItems,
            this.imedClaimAdditionalBillToParties.map((btp) => new MetaItem(btp.Id, this.setBTPName(btp))),
        );

        if (this.imedClaimFollowup.Id === 0) {
            // new imedClaimFollowup
            this.config = this.formFactory.getForCreate();
            this.edit();
        } else {
            // existing imedClaimFollowup
            this.config = this.formFactory.getForUpdate();
            this.notes = this.imedClaimFollowup.Notes;
        }
        this.assignRenderAdditionalInfo();
        this.formRendered = true;

    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.imedClaimFollowup.Id === 0) {
            this.backButtonService.goBack();
        } else {
            this.isEditing = false;
            this.followUpTypeIdValue = this.imedClaimFollowup.MasterFollowupTypeId;
            this.assignRenderAdditionalInfo();
        }
    }

    save(): void {
        if (this.form.valid) {
            if (this.notesCurrentLength <= this.notesMaxLength) {
                this.showEmailControl = true;
                this.formFactory.assignFormValues(this.imedClaimFollowup, this.form.value.ImedClaimFollowup as IImedClaimFollowup);
                if (this.updatedFollowupDate) {
                    this.imedClaimFollowup.Date = this.updatedFollowupDate;
                }
                this.showNotesLength = false;
                this.formatDataForSave(); // sets required fields like createdBy
                if (!this.imedClaimFollowup.Id || this.imedClaimFollowup.Id === 0) {
                    // handle new imedClaimFollowup save

                    this.imedClaimFollowup.Date = this.followUpDateChanges(this.imedClaimFollowup.Date);
                    this.imedClaimFollowupService.createFollowup(this.imedClaimFollowup).subscribe(({ body: { Id, EmailTemplates } }) => {
                        this.followUpId = Id;
                        this.success();
                        this.imedClaimFollowupService.emitChange(this.imedClaimFollowup);
                        if (EmailTemplates && EmailTemplates.length > 0) {
                            this.handleEmailTemplate(EmailTemplates);
                        } else {
                            this.navigateToNewFollowup(this.followUpId);
                        }
                    });
                } else {
                    // handle existing imedClaimFollowup save
                    this.imedClaimFollowupService.update(this.imedClaimFollowup).subscribe(() => {
                        this.isEditing = false;
                        this.success();
                        this.getImedClaimFollowupById(this.imedClaimFollowup.Id);
                        this.setConfig();
                        this.imedClaimFollowupService.emitChange(this.imedClaimFollowup);
                    });
                }
            } else {
                this.notificationsService.error('Maximum note length is 500 characters');
            }
        } else {
            markAllFormFieldsAsTouched(this.form);
            this.error();
        }
    }

    navigateToNewFollowup(newId: number): void {
        const url = this.router.url.replace('add', newId.toString());

        void this.router.navigateByUrl(url);
    }

    handleEmailTemplate(templates: IEmailTemplate[]): void {
        this.emailComponent.emailTemplates = templates;
        this.emailComponent.imedClaimId = this.imedClaimFollowup.ImedClaimId;
        this.emailComponent.showEmailForm();
    }

    formatDataForSave(): void {
        if (this.imedClaimFollowup.Id) {
            this.imedClaimFollowup.ModifiedById = this.authService.currentUser.getValue().Id;
            this.imedClaimFollowup.DateModified = new Date();
            this.imedClaimFollowup.ImedClaimServices = [];
        } else {
            this.imedClaimFollowup.ImedClaimServices = [];
            if (this.form.value.ImedClaimFollowup.associatedServices) {
                this.form.value.ImedClaimFollowup.associatedServices.forEach((serviceId) =>
                    this.imedClaimFollowup.ImedClaimServices.push(this.services.find((x) => x.Id === serviceId)),
                );

                if (this.form.value.ImedClaimFollowup.associatedBTPs) {
                    this.saveImedclaimServiceSplitBillingDetails();
                }
            }
            this.imedClaimFollowup.CreatedById = this.authService.currentUser.getValue().Id;
            this.imedClaimFollowup.DateCreated = new Date();
        }

        this.imedClaimFollowup.ImedClaimId = this.imedClaimId;
        this.imedClaimFollowup.Archived = false;
        this.nullOutExtraneousFields();
    }

    followUpDateChanges(followUpDate: Date): any {
        this.updatedFollowupDate = new Date(followUpDate.getTime() + followUpDate.getTimezoneOffset() * 60000);
        this.updatedFollowupDate.setHours(new Date().getHours());
        this.updatedFollowupDate.setMinutes(new Date().getMinutes());
        this.updatedFollowupDate.setSeconds(new Date().getSeconds());
        return this.updatedFollowupDate;
    }

    nullOutExtraneousFields(): void {
        if (!this.renderAdditionalInfoComponent) {
            this.imedClaimFollowup.ImeAppointmentConfirmedWithPlaintiffsAttorneyOfficeByName = null;
            this.imedClaimFollowup.ReceiptOfNoticeOfImeConfirmedByName = null;
            this.imedClaimFollowup.ReceivedAppointmentDateTime = null;
        }
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }

    followUpTypeChanges(value: number): void {
        this.followUpTypeIdValue = value;
        this.assignRenderAdditionalInfo();
        this.setConfig();
        this.cdr.detectChanges();
    }

    getAppointmentTitle(): string {
        let result = '';
        const requestString = 'Request for rescheduling from ';
        let requesteeString = '';
        const claimantFailedToAttendString = 'Claimant failed to attend as scheduled';
        if (this.followUpTypeIdValue === +MasterFollowUpTypes.Request_for_rescheduling_from_claimant) {
            requesteeString = 'claimant';
            result = requestString + requesteeString;
        } else if (this.followUpTypeIdValue === +MasterFollowUpTypes.Request_for_rescheduling_from_defense_counsel) {
            requesteeString = 'defense counsel';
            result = requestString + requesteeString;
        } else if (this.followUpTypeIdValue === +MasterFollowUpTypes.Request_for_rescheduling_from_physician) {
            requesteeString = 'physician';
            result = requestString + requesteeString;
        } else if (this.followUpTypeIdValue === +MasterFollowUpTypes.Request_for_rescheduling_from_plaintiff_attorney) {
            requesteeString = "plaintiff's attorney";
            result = requestString + requesteeString;
        } else if (this.followUpTypeIdValue === +MasterFollowUpTypes.Claimant_failed_to_attend_as_scheduled) {
            result = claimantFailedToAttendString;
        }
        return result;
    }

    serviceSelectionChanged(event: any): void {
        this.selectedServiceIdsArray = event === null ? [] : event;
    }

    getImedClaimFollowupById(id: number): void {
        this.imedClaimFollowupService.getById(id).subscribe((imedClaimFollowup) => {
            this.imedClaimFollowup = imedClaimFollowup;
        });
    }

    additionalBTPSelectionChanged(event: any): void {
        this.selectedImedClaimAdditionalBillToPartyIdsArray = event === null ? [] : event;
    }

    saveImedclaimServiceSplitBillingDetails(): any {
        this.imedClaimServiceSplitBillingDetail = [];

        this.form.value.ImedClaimFollowup.associatedServices.forEach((selectedImedClaimService) => {
            this.form.value.ImedClaimFollowup.associatedBTPs.forEach((selectedAdditionalBTP) => {
                const additionalBTP = this.imedClaimAdditionalBillToParties.find((btp) => btp.Id === selectedAdditionalBTP);

                this.imedClaimServiceSplitBillingDetail.push({
                    ImedClaimAdditionalBillToPartyId: selectedAdditionalBTP,
                    ImedClaimServiceId: selectedImedClaimService,
                    CarrierPartyAddressBookId: additionalBTP.CarrierPartyAddressBookId,
                    CarrierPartyIndividualId: additionalBTP?.CarrierPartyIndividualId,
                    Id: 0,
                });
            });
        });

        this.imedClaimFollowupService.createImedClaimServiceSplitBillingDetails(this.imedClaimServiceSplitBillingDetail, this.imedClaimId).subscribe(
            () => {
                this.notificationsService.success('Split Billing Details Saved Successfully');
            },
            (errorResponse) => {
                this.notificationsService.error(errorResponse.error as string);
            },
        );
    }

    setBTPName(btp: IImedClaimAdditionalBillToPartyPartial): string {
        if (!isNullOrWhitespace(btp?.AddressBook?.FacilityName)) {
            if (btp?.Individual) {
                return btp.Individual.FirstName + ' ' + btp.Individual.LastName + ' - ' + btp.AddressBook.FacilityName;
            }
            return btp.AddressBook.FacilityName;
        } else {
            return btp?.Individual?.FirstName + ' ' + btp?.Individual?.LastName;
        }
    }

    formSubmitted(): void {
        if (this.imedClaimFollowup?.Id > 0 || !this.isDefenseCounselChangedFollowup) {
            this.save();
        }

        if (this.imedClaimFollowup?.Id == 0 && this.isDefenseCounselChangedFollowup) {
            this.imedClaimService.checkForConsentToChangeDocument(this.imedClaimId).subscribe((response) => {
                this.isConsentToChangeDocumentUploaded = response;

                if (this.isConsentToChangeDocumentUploaded) {
                    this.save();
                } else {
                    this.ShowDocumentPopup();
                }
            });
        }
    }

    ShowDocumentPopup(): void {
        this.uploadAttachmentsModalApi.show();
    }

    onDocumentSaved(): void {
        this.isConsentToChangeDocumentUploaded = true;
        this.uploadAttachmentsModalApi.close();
        this.save();
    }

    onCloseDocument(): void {
        this.uploadAttachmentsModalApi.close();
        this.error();
    }

    assignRenderAdditionalInfo(): void {
        this.renderAdditionalInfoComponent =
            this.followUpTypeIdValue === +MasterFollowUpTypes.Appointment_scheduled ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Attendance_at_examination_confirmed ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Bill_paid_pending_check_clearance ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Check_Cleared ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Claimant_failed_to_attend_as_scheduled ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Confirmation_that_call_was_completed ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Defense_counsel_has_requested_an_extended_deferment ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Defense_counsel_has_requested_deferment_of_notification_of_appointment ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Films_confirmed_received_by_physician_office ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Films_copied_and_forwarded_to_physician ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Followed_up_with_facility_regarding_records_requested ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.IME_appointment_confirmed_with_plaintiff_attorney_office ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Medical_records_confirmed_received_by_physician_office ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Medical_records_forwarded_to_physician_again_as_they_were_not_received ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Original_films_forwarded_to_physician ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Payment_processed_pending_check_clearance ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Pending_additional_records_report_will_be_finalized_and_addendum_requested ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Pending_additional_records_report_will_be_held_pending_receipt ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Physician_has_advised_that_report_will_be_ready_on_date_specified ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Prepayment_processed_pending_check_clearance ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Receipt_of_notification_of_appointment_confirmed ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Records_compiled_and_forwarded_to_physicia_expert ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Records_forwarded_to_client ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Report_forwarded_to_client ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Request_for_rescheduling_from_claimant ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Request_for_rescheduling_from_defense_counsel ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Request_for_rescheduling_from_physician ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Request_for_rescheduling_from_plaintiff_attorney ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Retrieval_documents_processed_and_submitted ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Service_evaluated_for_further_follow_up ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Testimony_cancelled ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Testimony_paid ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Retrieval_documents_resubmitted ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Fee_approved_and_forwarded_to_accounting ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Report_due_by_DATE ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Invoice_forwarded_to_client ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Contacted_physician_office_to_confirm_receipt_of_records ||
            this.followUpTypeIdValue ==  +MasterFollowUpTypes.Appointment_rescheduled;

        this.renderReceiptOfNoticeOfImeConfirmedByName =
            this.followUpTypeIdValue === +MasterFollowUpTypes.Prepayment_processed_pending_check_clearance ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Receipt_of_notification_of_appointment_confirmed;

        this.renderImeAppointmentConfirmedWithPlaintiffsAttorneyOfficeByName =
            this.followUpTypeIdValue === +MasterFollowUpTypes.IME_appointment_confirmed_with_plaintiff_attorney_office ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Records_received;

        this.renderReceivedAppointmentDateTime = this.followUpTypeIdValue === +MasterFollowUpTypes.Appointment_scheduled;

        this.renderLastAppointment = this.followUpTypeIdValue === +MasterFollowUpTypes.Claimant_failed_to_attend_as_scheduled;

        this.renderFilmReviewReportForwardedToClientDate = this.followUpTypeIdValue === +MasterFollowUpTypes.Report_forwarded_to_client;

        this.renderCheckNumber =
            this.followUpTypeIdValue === +MasterFollowUpTypes.Bill_paid_pending_check_clearance ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Payment_processed_pending_check_clearance ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Prepayment_processed_pending_check_clearance ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Testimony_paid;

        this.renderClearanceDate = this.followUpTypeIdValue === +MasterFollowUpTypes.Check_Cleared;

        this.renderCommunicationMethod =
            this.followUpTypeIdValue === +MasterFollowUpTypes.Followed_up_with_facility_regarding_records_requested ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Retrieval_documents_processed_and_submitted ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Retrieval_documents_resubmitted ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Contacted_physician_office_to_confirm_receipt_of_records;

        this.renderConfirmedCancellationWith = this.followUpTypeIdValue === +MasterFollowUpTypes.Testimony_cancelled;

        this.renderConfirmedWithPhysiciansOffice =
            this.followUpTypeIdValue === +MasterFollowUpTypes.Pending_additional_records_report_will_be_finalized_and_addendum_requested ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Pending_additional_records_report_will_be_held_pending_receipt;

        this.renderDeferredUntil =
            this.followUpTypeIdValue === +MasterFollowUpTypes.Defense_counsel_has_requested_an_extended_deferment ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Defense_counsel_has_requested_deferment_of_notification_of_appointment;

        this.renderReportReadyOnOrAround =
            this.followUpTypeIdValue === +MasterFollowUpTypes.Physician_has_advised_that_report_will_be_ready_on_date_specified;

        this.renderPersonContactedOrSpokenTo =
            this.followUpTypeIdValue === +MasterFollowUpTypes.Claimant_failed_to_attend_as_scheduled ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Films_confirmed_received_by_physician_office ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Films_forwarded_to_physician_again_as_they_were_not_received ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Medical_records_confirmed_received_by_physician_office ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Medical_records_forwarded_to_physician_again_as_they_were_not_received ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Request_for_rescheduling_from_claimant ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Request_for_rescheduling_from_defense_counsel ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Request_for_rescheduling_from_physician ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Request_for_rescheduling_from_plaintiff_attorney;

        this.renderTrackingNumber =
            this.followUpTypeIdValue === +MasterFollowUpTypes.Films_copied_and_forwarded_to_physician ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Original_films_forwarded_to_physician;

        this.renderVia =
            this.followUpTypeIdValue === +MasterFollowUpTypes.Records_compiled_and_forwarded_to_physicia_expert ||
            this.followUpTypeIdValue === +MasterFollowUpTypes.Records_forwarded_to_client;

        this.renderAmount = this.followUpTypeIdValue === +MasterFollowUpTypes.Fee_approved_and_forwarded_to_accounting;

        this.renderReportDueBy = this.followUpTypeIdValue === +MasterFollowUpTypes.Report_due_by_DATE;

        this.isSplitBillingFollowup = this.followUpTypeIdValue === +MasterFollowUpTypes.Billing_should_be_split_amongst_listed_Co_counsels;
        this.isDefenseCounselChangedFollowup = this.followUpTypeIdValue === +MasterFollowUpTypes.Defense_counsel_has_changed;
        safeDetectChanges(this.cdr);
    }
}
