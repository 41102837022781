import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { finalize } from 'rxjs/operators';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { PhoneNumberDynamicConfig } from '@model/shared-entities/phone-numbers/phone-numbers.dynamic-config';
import { IPhoneNumber } from '@model/interfaces/phone-number';
import { PhoneLabelGenerator } from './phone-label.generator';
import { emptyPhoneNumber } from '@model/shared-entities/phone-numbers/phone-number.service';
import { IPhoneType } from '@model/interfaces/phone-type';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, DynamicLabel, IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { IndividualPhoneService } from '../individuals/shared/individual-phone.service';

@Component({
    selector: 'app-shared-phones',
    templateUrl: './shared-phones.component.html',
})
export class SharedPhonesComponent implements OnInit {
    @Input() canEdit: boolean;
    @Input() individualId: number;
    @Input() addressBookIsFacilityType: boolean;
    @Input() individualPhones: IPhoneNumber[] = [];
    @Input() facilityPhones: IPhoneNumber[] = [];
    @Input() phoneTypes: IPhoneType[] = [];
    @Output() onPhoneSaved: EventEmitter<boolean> = new EventEmitter<boolean>();

    phoneLabelGenerator = new PhoneLabelGenerator();
    phoneCardHeading = 'Phones';
    isEditing: boolean;
    doubleClickIsDisabled: boolean;
    selectionChanged = false;
    showFacilityPhonesControl = false;

    phoneFormFactory: PhoneNumberDynamicConfig<IPhoneNumber>;
    phoneForm: UntypedFormGroup;
    config: IDynamicFormConfig;
    facilityPhonesControl: DynamicField;
    phoneNumber: IPhoneNumber;

    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    constructor(
        private individualPhoneService: IndividualPhoneService,
        private fb: UntypedFormBuilder,
        private router: Router,
        private notificationsService: NotificationsService,
        private route: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        this.phoneForm = this.fb.group({});
        this.config = { formObject: [], viewOnly: [] };

        if (this.addressBookIsFacilityType) {
            this.facilityPhonesControl = new DynamicField({
                formGroup: '',
                label: 'Facility Phones',
                name: 'facilityPhoneId',
                options: this.facilityPhones.map((fp) => ({ Name: this.phoneLabelGenerator.GetDropdownLabel(fp), Id: fp.Id })),
                type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Select }),
                validation: [],
                validators: {},
                value: null,
            });
        }
    }

    ngOnChanges(changes): void {
        if (changes.facilityPhones && !changes.facilityPhones.firstChange && this.addressBookIsFacilityType) {
            const newFacilityPhonesControl = new DynamicField({
                formGroup: '',
                label: 'Facility Phones',
                name: 'facilityPhoneId',
                options: this.facilityPhones.map((fp) => ({ Name: this.phoneLabelGenerator.GetDropdownLabel(fp), Id: fp.Id })),
                type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Select }),
                validation: [],
                validators: {},
                value: null,
            });
            setTimeout(() => (this.facilityPhonesControl = newFacilityPhonesControl));
        }
    }

    setConfig(): void {
        if (this.phoneNumber.Id > 0) {
            this.phoneFormFactory = new PhoneNumberDynamicConfig<IPhoneNumber>(this.phoneNumber, this.phoneTypes);
            this.config = this.phoneFormFactory.getForUpdate();
        } else {
            this.phoneFormFactory = new PhoneNumberDynamicConfig<IPhoneNumber>(this.phoneNumber, this.phoneTypes);
            this.config = this.phoneFormFactory.getForCreate();
        }

        this.viewOnly = this.config?.viewOnly?.map((x) => new DynamicLabel(x));
        this.formObject = this.config.formObject?.map((x) => new DynamicField(x));
    }

    selectFacilityPhone(event): void {
        this.selectionChanged = true;
        const selectedPhoneNumber = this.facilityPhones.find((fp) => fp.Id === event);
        this.phoneNumber = { ...emptyPhoneNumber };
        this.phoneNumber.Phone = selectedPhoneNumber.Phone;
        this.phoneNumber.PhoneTypeId = selectedPhoneNumber.PhoneTypeId;
        this.phoneNumber.IsPrimary = selectedPhoneNumber.IsPrimary;
        this.phoneNumber.Notes = selectedPhoneNumber.Notes ? selectedPhoneNumber.Notes : '';
        this.setConfig();
        setTimeout(() => (this.selectionChanged = false));
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            this.phoneFormFactory.assignFormValues(this.phoneNumber, form.value.PhoneNumber as IPhoneNumber);
            this.individualPhoneService.saveEntity(this.individualId, this.phoneNumber).subscribe(
                () => {
                    this.success();
                },
                () => {
                    this.error();
                },
            );
        } else {
            markAllFormFieldsAsTouched(form);
            this.error();
            setTimeout(() => (this.doubleClickIsDisabled = false));
        }
    }

    seeAll(): void {
        void this.router.navigate(['phonenumbers'], { relativeTo: this.route });
    }

    editPhone(event): void {
        this.showFacilityPhonesControl = false;
        this.phoneNumber = event;
        this.setConfig();
        this.isEditing = true;
        this.phoneCardHeading = 'Edit Phone Number';
    }

    addPhone(): void {
        this.phoneNumber = { ...emptyPhoneNumber };
        this.setConfig();
        this.showFacilityPhonesControl = true;
        this.isEditing = true;
        this.phoneCardHeading = 'Add Phone Number';
    }

    cancel(): void {
        this.isEditing = false;
        this.phoneCardHeading = 'Phone Numbers';
    }

    error(): void {
        this.notificationsService.error('Save failed. Please check the form and try again.');
    }

    success(): void {
        this.phoneCardHeading = 'Phone Numbers';
        this.onPhoneSaved.emit();
        this.isEditing = false;
        this.notificationsService.success('Phone Number saved successfully!');
    }
}
