import { Subscription, forkJoin } from 'rxjs';
import { ImedClaimServiceDynamicConfig } from './../../imed-claim-service.dynamic-config';
import { IImedClaimServiceContactInfo } from './../../../imed-claim-basic-info/imed-claim-contacts-info';
import { IImedClaimService } from '@model/interfaces/imed-claim-service.d';
import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges, ChangeDetectorRef, OnDestroy, OnChanges } from '@angular/core';
import { AddressBooksSelectService } from '../../../../common/address-book-select-list/address-books-select.service';
import { IAddressBookSelectEntity } from '../../../../common/address-book-select-list/address-books-select-entity';
import {
    AddressBookSelectionEnums,
    AddressTypeIdEnums,
    MetaAddressBookTypeIdEnums,
    PhoneTypes,
    ServiceIdEnums,
    AttachmentTypeEnums,
} from '../../../../common/constants/Enums';
import { PhysicianService } from '../../../../address-books/individuals/physicians/physician.service';
import { CurrencyFormatterService } from '../../../../common/services/currency-formatter.service';
import { IAddress } from '@model/interfaces/address';
import { IndividualService } from '../../../../address-books/individuals/individual.service';
import { AddressBookService } from '../../../../address-books/addressbook.service';
import { IPhoneNumber } from '@model/interfaces/phone-number';
import { IEmailAddress } from '@model/interfaces/email-address';
import { safeDetectChanges } from '../../../../common/cdr-safety/cdr-safety.library';
import { IModalOptions, IModalWrapperApi, ModalService } from '@mt-ng2/modal-module';
import { ImedClaimTypes } from '@model/ImedClaimTypes';
import { IAddressBook } from '@model/interfaces/address-book';
import { IPhysicianDetail } from '../../../../model/interfaces/physician-detail';
import { IAttachmentDTO } from '@model/interfaces/custom/attachment-dto';
import { IDocument } from '@model/interfaces/base';

@Component({
    selector: 'app-imed-claim-service-physician-info',
    styles: [
        `
            .physician-font {
                font-size: 16px;
                font-weight: bold;
            }
            .form-control.invalid {
                border: 1px solid #dd4b39;
            }
            label.invalid {
                color: #dd4b39;
            }
        `,
    ],
    templateUrl: './imed-claim-service-physician-info.component.html',
})
export class ImedClaimServicePhysicianInfo implements OnInit, OnChanges, OnDestroy {
    get physicianSpecialties(): string {
        return this.selectedPhysicianDetail?.Physician?.PhysicianSpecialties?.map((s) => s.Specialty?.Name).join(', ') ?? '';
    }
    get physicianSpecialtyDetails(): string {
        return this.selectedPhysicianDetail?.Physician?.PhysicianSpecialtyDetails?.map((s) => s.SpecialtyDetail?.Name).join(', ') ?? '';
    }
    get hasFees(): boolean {
        return (
            this.selectedPhysicianDetail &&
            (this.selectedPhysicianDetail.FilmReviewFee !== null ||
                this.selectedPhysicianDetail.HourlyFee !== null ||
                this.selectedPhysicianDetail.AdditionalFee !== null ||
                this.selectedPhysicianDetail.ImeExamConsultationFee !== null ||
                this.selectedPhysicianDetail.NoShowFee !== null)
        );
    }
    get defaultAddress(): IAddress {
        return this.addresses.filter((x) => x.IsPrimary)[0];
    }
    get hasSpecialNote(): boolean {
        return this.selectedPhysicianDetail && this.selectedPhysicianDetail.SpecialNote !== null;
    }
    get hasNotes(): boolean {
        if (this.iImedClaimServiceModel && this.iImedClaimServiceModel?.Physician?.Individual?.Notes) {
            this.setNotes(this.iImedClaimServiceModel.Physician.Individual.Notes);
            return true;
        }
        if (this.iImedClaimServiceModel?.AddressBook?.Individuals[0]?.Notes) {
            this.setNotes(this.iImedClaimServiceModel.AddressBook.Individuals[0].Notes);
            return true;
        }
        if (this.selectedAddressBook && this.selectedAddressBook?.Notes) {
            this.setNotes(this.selectedAddressBook.Notes);
            return true;
        }
        return false;
    }

    get isWorkersCompType(): boolean {
        return this.iImedClaimServiceModel.ImedClaim && this.iImedClaimServiceModel.ImedClaim.ClaimTypeId === +ImedClaimTypes.WorkersCompensation;
    }
    get hasPhoneNumbers(): boolean {
        return this.phoneNumbers && this.phoneNumbers.length > 0;
    }
    get hasFaxNumbers(): boolean {
        return this.faxNumbers && this.faxNumbers.length > 0;
    }
    @Input() iImedClaimServiceModel: IImedClaimService;
    @Input() isEditing: boolean;
    @Input() iImedClaimServiceContactInfo: IImedClaimServiceContactInfo;
    @Input() disablePhysicianFacilityControl: boolean;
    @Input() transcriptionServiceRequested: boolean;
    @Output() physicianSelected: EventEmitter<boolean> = new EventEmitter();
    @Output() onFaxSent: EventEmitter<any> = new EventEmitter();
    imedClaimServiceForm: any;
    formFactory: ImedClaimServiceDynamicConfig<IImedClaimService>;
    doubleClickIsDisabled = false;
    subscriptions: Subscription = new Subscription();
    addresses: IAddress[] = [];
    addressChangedOrDefault = true;
    selectedAddressId: number;
    tempPhysicianId: number;
    phoneNumbers: IPhoneNumber[];
    faxNumbers: IPhoneNumber[];
    phoneNumberHtml: string;
    emailAddresses: IEmailAddress[];
    emailAddressHtml: string;
    selectedPhysicianDetail: IPhysicianDetail;
    selectedAddressBook: IAddressBook;
    primaryAddress: IAddress;
    imedClaimTypes = ImedClaimTypes;
    individualOrFacilityNotes: string;
    ServiceTypes = ServiceIdEnums;
    uploadedTranscriptionTemplates: IAttachmentDTO[];

    modalOptions: IModalOptions = {
        customClass: 'modal-hide-actions' as any,
        width: 800,
    };
    faxModalApi: IModalWrapperApi;
    setNotes = (Notes: string): void => {
        this.individualOrFacilityNotes = Notes;
    };

    constructor(
        private addressSelectService: AddressBooksSelectService,
        private commonPhysicianService: PhysicianService,
        private individualService: IndividualService,
        private addressBookService: AddressBookService,
        private commonCurrencyFormatterService: CurrencyFormatterService,
        private cdr: ChangeDetectorRef,
        private modalService: ModalService,
    ) {}

    ngOnInit(): void {
        this.validateAddressAndSetProperties();

        if (this.iImedClaimServiceModel.FacilityAddressBookId) {
            this.addressBookService.getById(this.iImedClaimServiceModel.FacilityAddressBookId).subscribe((addressBook) => {
                this.selectedAddressBook = addressBook;
                this.setConfig();
            });
        } else {
            this.setConfig();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes.iImedClaimServiceModel &&
            !changes.iImedClaimServiceModel.isFirstChange() &&
            changes.iImedClaimServiceModel.currentValue?.Address?.Id !== changes.iImedClaimServiceModel?.previousValue?.Address?.Id
        ) {
            this.setPrimaryAddress();
        }
    }

    ngOnDestroy(): void {
        this.cdr.detach();
        this.subscriptions.unsubscribe();
    }

    validateAddressAndSetProperties(): void {
        this.subscriptions.add(
            this.addressSelectService.changeEmitted$.subscribe((addressBookSelect) => {
                this.addressChangedOrDefault = true;
                if (
                    this.addressSelectService.typeFilter === AddressBookSelectionEnums.Physician.toString() &&
                    this.tempPhysicianId !== addressBookSelect.PhysicianId
                ) {
                    this.clearFacility();
                    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
                    forkJoin(
                        this.commonPhysicianService.getById(addressBookSelect.PhysicianId),
                        this.commonPhysicianService.getPhysicianAddressBook(addressBookSelect.PhysicianId),
                        this.commonPhysicianService.getPhysicianAddresses(addressBookSelect.PhysicianId),
                        this.commonPhysicianService.getPhysicianPhones(addressBookSelect.PhysicianId),
                        this.commonPhysicianService.getPhysicianEmailAddresses(addressBookSelect.PhysicianId),
                    ).subscribe(([physician, addressBook, addresses, phones, emails]) => {
                        this.tempPhysicianId = addressBookSelect.PhysicianId;
                        const physicianDetail = physician.PhysicianDetails.find(
                            (pd) => pd.ImedClaimTypeId === this.iImedClaimServiceModel.ImedClaim.ClaimTypeId,
                        );
                        let removePhysician = false;
                        this.uploadedTranscriptionTemplates = (physician.Documents as unknown as IAttachmentDTO[]).filter(
                            (doc: IAttachmentDTO) => doc.AttachmentTypeId === +AttachmentTypeEnums.Transcription_Template,
                        );
                        this.selectedPhysicianDetail = physicianDetail;
                        physician.PhysicianDetails = null;
                        this.selectedPhysicianDetail.Physician = physician;
                        this.selectedAddressBook = addressBook;

                        this.setListOfEmailAddresses(emails);

                        if (
                            addresses.length === 0 &&
                            this.selectedAddressBook.MetaAddressBookTypeId === +MetaAddressBookTypeIdEnums.Facility_Organization
                        ) {
                            this.getFacilityAddresses(this.selectedAddressBook.Id);
                        } else {
                            this.setListOfAddresses(addresses);
                        }

                        this.phoneNumbers = phones.filter((p) => p.PhoneTypeId !== +PhoneTypes.Office_fax);
                        this.faxNumbers = phones.filter((p) => p.PhoneTypeId === +PhoneTypes.Office_fax);

                        if (
                            (this.phoneNumbers.length === 0 || this.faxNumbers.length === 0) &&
                            this.selectedAddressBook.MetaAddressBookTypeId === +MetaAddressBookTypeIdEnums.Facility_Organization
                        ) {
                            if (this.phoneNumbers.length === 0 && this.faxNumbers.length === 0) {
                                this.getFacilityPhonesAndFaxes(this.selectedAddressBook.Id);
                            } else if (this.phoneNumbers.length === 0) {
                                this.getFacilityPhones(this.selectedAddressBook.Id);
                            } else {
                                this.getFacilityFaxes(this.selectedAddressBook.Id);
                            }
                        }

                        const diffDays = Math.round(
                            Math.abs((new Date().getTime() - new Date(physicianDetail.LicenseVerificationDate).getTime()) / oneDay),
                        );
                        if (diffDays >= 180 || physicianDetail.LicenseVerificationDate === null) {
                            this.modalService
                                .showModal({
                                    cancelButtonColor: '#d33',
                                    confirmButtonColor: '#3085d6',
                                    confirmButtonText: 'Yes',
                                    focusConfirm: false,
                                    html: 'It’s been 6 months since the last licensing verification.',
                                    showCancelButton: true,
                                    showCloseButton: false,
                                    title: `Are you sure you want to continue? `,
                                    width: 800,
                                })
                                .subscribe((result) => {
                                    // result.value represents the index of the selected set up
                                    if (result.value) {
                                        this.selectedPhysicianDetail = physicianDetail;
                                        this.assignDocuments();
                                    } else if (result.dismiss) {
                                        removePhysician = true;
                                        this.clearPhysician();
                                        this.physicianSelected.emit(false);
                                    }
                                });
                        }
                        if (!removePhysician && this.transcriptionServiceRequested && !this.uploadedTranscriptionTemplates.length) {
                            this.modalService
                                .showModal({
                                    cancelButtonColor: '#d33',
                                    confirmButtonColor: '#3085d6',
                                    confirmButtonText: 'Yes',
                                    focusConfirm: false,
                                    html: 'No Transcription template is uploaded.',
                                    showCancelButton: true,
                                    showCloseButton: false,
                                    title: `Are you sure you want to continue? `,
                                    width: 800,
                                })
                                .subscribe((result) => {
                                    if (result.value) {
                                        this.selectedPhysicianDetail = physicianDetail;
                                    } else if (result.dismiss) {
                                        this.clearPhysician();
                                        this.physicianSelected.emit(false);
                                    }
                                });
                        } else if (!removePhysician) {
                            this.selectedPhysicianDetail = physicianDetail;
                            this.assignDocuments();
                        }
                    });
                }
                if (
                    this.addressSelectService.typeFilter === AddressBookSelectionEnums.AddressBook.toString() ||
                    this.addressSelectService.typeFilter === AddressBookSelectionEnums.Individual.toString()
                ) {
                    this.clearPhysician();
                    this.addressBookService.getById(addressBookSelect.AddressBookId).subscribe((addressBook) => {
                        this.selectedAddressBook = addressBook;

                        if (addressBookSelect.IndividualId) {
                            this.getIndividualPhones(addressBookSelect.IndividualId);
                            this.getIndividualEmailAddresses(addressBookSelect.IndividualId);
                            this.getIndividualAddresses(addressBookSelect.IndividualId);
                        } else {
                            this.getFacilityPhones(addressBookSelect.AddressBookId);
                            this.getFacilityEmailAddresses(addressBookSelect.AddressBookId);
                            this.getFacilityAddresses(addressBookSelect.AddressBookId);
                        }
                    });
                }
            }),
        );
    }

    getPhysician(physicianId: number): void {
        forkJoin([
            this.commonPhysicianService.getPhysicianDetailByServiceId(this.iImedClaimServiceModel.Id),
            this.commonPhysicianService.getPhysicianAddressBook(physicianId),
            this.commonPhysicianService.getPhysicianPhones(physicianId),
            this.commonPhysicianService.getPhysicianEmailAddresses(physicianId),
            this.commonPhysicianService.getPhysicianAddresses(physicianId),
        ]).subscribe(([physicianDetail, addressBook, phones, emails, addresses]) => {
            this.selectedPhysicianDetail = physicianDetail;
            this.selectedAddressBook = addressBook;

            this.phoneNumbers = phones.filter((p) => p.PhoneTypeId !== +PhoneTypes.Office_fax);
            this.faxNumbers = phones.filter((p) => p.PhoneTypeId === +PhoneTypes.Office_fax);

            if (
                (this.phoneNumbers.length === 0 || this.faxNumbers.length === 0) &&
                this.selectedAddressBook.MetaAddressBookTypeId === +MetaAddressBookTypeIdEnums.Facility_Organization
            ) {
                if (this.phoneNumbers.length === 0 && this.faxNumbers.length === 0) {
                    this.getFacilityPhonesAndFaxes(this.selectedAddressBook.Id);
                } else if (this.phoneNumbers.length === 0) {
                    this.getFacilityPhones(this.selectedAddressBook.Id);
                } else {
                    this.getFacilityFaxes(this.selectedAddressBook.Id);
                }
            }

            this.setListOfEmailAddresses(emails);

            if (addresses.length === 0 && this.selectedAddressBook.MetaAddressBookTypeId === +MetaAddressBookTypeIdEnums.Facility_Organization) {
                this.getFacilityAddresses(this.selectedAddressBook.Id);
            } else {
                this.setListOfAddresses(addresses);
                this.setPrimaryAddress();
            }

            safeDetectChanges(this.cdr);
        });
    }

    getFacilityPhonesAndFaxes(facilityId: number): void {
        this.addressBookService.getFacilityPhones(facilityId).subscribe((phones) => {
            this.phoneNumbers = phones.filter((p) => p.PhoneTypeId !== +PhoneTypes.Office_fax);
            this.faxNumbers = phones.filter((p) => p.PhoneTypeId === +PhoneTypes.Office_fax);
        });
    }

    getFacilityPhones(facilityId: number): void {
        this.addressBookService.getFacilityPhones(facilityId).subscribe((phones) => {
            this.phoneNumbers = phones.filter((p) => p.PhoneTypeId !== +PhoneTypes.Office_fax);
        });
    }

    getFacilityFaxes(facilityId: number): void {
        this.addressBookService.getFacilityPhones(facilityId).subscribe((phones) => {
            this.faxNumbers = phones.filter((p) => p.PhoneTypeId === +PhoneTypes.Office_fax);
        });
    }

    getFacilityEmailAddresses(facilityId: number): void {
        this.addressBookService.getFacilityEmails(facilityId).subscribe((emails) => (this.emailAddresses = emails));
    }

    getIndividualPhones(individualId: number): void {
        this.individualService.getIndividualPhones(individualId).subscribe((individualPhones) => {
            this.phoneNumbers = individualPhones.filter((p) => p.PhoneTypeId !== +PhoneTypes.Office_fax);
            this.faxNumbers = individualPhones.filter((p) => p.PhoneTypeId === +PhoneTypes.Office_fax);

            if (
                (this.phoneNumbers.length === 0 || this.faxNumbers.length === 0) &&
                this.selectedAddressBook.MetaAddressBookTypeId === +MetaAddressBookTypeIdEnums.Facility_Organization
            ) {
                if (this.phoneNumbers.length === 0 && this.faxNumbers.length === 0) {
                    this.getFacilityPhonesAndFaxes(this.selectedAddressBook.Id);
                } else if (this.phoneNumbers.length === 0) {
                    this.getFacilityPhones(this.selectedAddressBook.Id);
                } else {
                    this.getFacilityFaxes(this.selectedAddressBook.Id);
                }
            }
        });
    }

    getIndividualEmailAddresses(individualId: number): void {
        this.individualService.getIndividualEmails(individualId).subscribe((emails) => {
            this.emailAddresses = emails;
        });
    }

    getPhysicianAddresses(physicianId: number): void {
        this.commonPhysicianService.getPhysicianAddresses(physicianId).subscribe((addresses: IAddress[]) => {
            if (addresses.length === 0 && this.selectedAddressBook.MetaAddressBookTypeId === +MetaAddressBookTypeIdEnums.Facility_Organization) {
                this.getFacilityAddresses(this.selectedAddressBook.Id);
            } else {
                this.setListOfAddresses(addresses);
            }
        });
    }

    getFacilityAddresses(addressBookId: number): void {
        this.addressBookService.getFacilityAddresses(addressBookId).subscribe((addresses: IAddress[]) => {
            this.setListOfAddresses(addresses);
            this.setPrimaryAddress();
        });
    }

    getIndividualAddresses(individualId: number): void {
        this.individualService.getIndividualAddresses(individualId).subscribe((addresses: IAddress[]) => {
            if (addresses.length === 0 && this.selectedAddressBook.MetaAddressBookTypeId === +MetaAddressBookTypeIdEnums.Facility_Organization) {
                this.getFacilityAddresses(this.selectedAddressBook.Id);
            } else {
                this.setListOfAddresses(addresses);
            }
        });
    }

    setServiceAddress(): void {
        safeDetectChanges(this.cdr);
        this.addressChangedOrDefault = false;
        this.selectedAddressId = parseInt(this.selectedAddressId.toString(), 10);
        this.iImedClaimServiceContactInfo.AddressId = this.selectedAddressId;
        this.setPrimaryAddress();
        this.physicianSelected.emit(true);
    }

    setDefaultSelectedAddressId(): void {
        if (this.addressChangedOrDefault && this.addresses && this.addresses.length) {
            this.selectedAddressId = 0;
            if (
                this.iImedClaimServiceModel.PhysicianId === this.iImedClaimServiceContactInfo.PhysicianId ||
                this.iImedClaimServiceModel.FacilityAddressBookId === this.iImedClaimServiceContactInfo.AddressBookId ||
                (this.iImedClaimServiceModel.FacilityIndividualId === this.iImedClaimServiceContactInfo.IndividualId &&
                    this.addresses.some((x) => x.IsPrimary))
            ) {
                if (this.iImedClaimServiceModel.ServiceAddressId) {
                    this.selectedAddressId = this.iImedClaimServiceModel.ServiceAddressId;
                } else if (this.addresses.some((x) => x.IsPrimary)) {
                    this.selectedAddressId = this.defaultAddress.Id;
                } else {
                    this.selectedAddressId = this.addresses[0].Id;
                }
            } else {
                if (this.addresses.some((x) => x.IsPrimary)) {
                    this.selectedAddressId = this.defaultAddress.Id;
                } else {
                    this.selectedAddressId = this.addresses[0].Id;
                }
            }
            this.iImedClaimServiceContactInfo.AddressId = this.selectedAddressId;
            this.physicianSelected.emit(true);
        }
    }

    formatAsCurrency(value: number): string {
        return this.commonCurrencyFormatterService.formatAsCurrency(value);
    }

    setPrimaryAddress(): void {
        this.primaryAddress =
            this.selectedAddressId && this.selectedAddressId > 0
                ? this.addresses.find((x) => x.Id === this.selectedAddressId)
                : this.iImedClaimServiceModel.Address;
    }

    setConfig(): void {
        if (this.iImedClaimServiceModel.PhysicianId) {
            this.tempPhysicianId = this.iImedClaimServiceModel.PhysicianId;
            this.getPhysician(this.iImedClaimServiceModel.PhysicianId);
        }

        if (this.iImedClaimServiceModel.FacilityAddressBookId) {
            if (this.iImedClaimServiceModel.FacilityIndividualId) {
                this.getIndividualPhones(this.iImedClaimServiceModel.FacilityIndividualId);
                this.getIndividualEmailAddresses(this.iImedClaimServiceModel.FacilityIndividualId);
                this.getIndividualAddresses(this.iImedClaimServiceModel.FacilityIndividualId);
            } else {
                this.getFacilityPhonesAndFaxes(this.iImedClaimServiceModel.FacilityAddressBookId);
                this.getFacilityEmailAddresses(this.iImedClaimServiceModel.FacilityAddressBookId);
                this.getFacilityAddresses(this.iImedClaimServiceModel.FacilityAddressBookId);
            }
        }
    }

    setListOfAddresses(addresses: IAddress[]): void {
        // TODO: Check if this filter is required after auto archiving old addresses
        this.addresses = addresses.filter((x) => x.AddressTypes.every((at) => at.Id !== +AddressTypeIdEnums.OldAddress));
        if (
            this.iImedClaimServiceModel &&
            this.iImedClaimServiceModel.ServiceAddressId &&
            this.iImedClaimServiceModel.Address &&
            !this.addresses.some((a) => a.Id === this.iImedClaimServiceModel.ServiceAddressId)
        ) {
            this.addresses.unshift(this.iImedClaimServiceModel.Address);
        }
        this.setDefaultSelectedAddressId();
    }

    setListOfPhones(phones: IPhoneNumber[]): void {
        this.phoneNumbers = [...phones];
    }

    setListOfEmailAddresses(emails: IEmailAddress[]): void {
        this.emailAddresses = [...emails];
    }

    SearchAddressBooks(addressBookType: string): void {
        const imedClaimTypeId = this.iImedClaimServiceModel.ImedClaim.ClaimTypeId;
        this.addressSelectService.openAddressBookSelect(addressBookType, this.assignContact, false, imedClaimTypeId); // note the bind(this)
    }

    clearPhysician(): void {
        this.iImedClaimServiceContactInfo.PhysicianId = null;
        this.iImedClaimServiceContactInfo.PhysicianName = '';
        this.clearAddressesAndDetails();
    }

    clearFacility(): void {
        this.iImedClaimServiceContactInfo.AddressBookId = null;
        this.iImedClaimServiceContactInfo.AddressBookName = null;
        this.iImedClaimServiceContactInfo.IndividualId = null;
        this.iImedClaimServiceContactInfo.IndividualName = null;
        this.clearAddressesAndDetails();
    }

    clearAddressesAndDetails(): void {
        this.selectedAddressId = null;
        this.iImedClaimServiceContactInfo.AddressId = null;
        this.addresses = [];
        this.phoneNumbers = [];
        this.emailAddresses = [];
    }

    assignContact = (addressBookSelect: IAddressBookSelectEntity): void => {
        this.iImedClaimServiceContactInfo[addressBookSelect.AddressBookType + 'Name'] =
            addressBookSelect && addressBookSelect.IndividualName ? addressBookSelect.IndividualName : addressBookSelect.AddressBookName;
        this.iImedClaimServiceContactInfo[addressBookSelect.AddressBookType + 'Id'] = addressBookSelect.PhysicianId
            ? addressBookSelect.PhysicianId
            : addressBookSelect.AddressBookId
            ? addressBookSelect.AddressBookId
            : null;

        if (addressBookSelect.IndividualId) {
            this.iImedClaimServiceContactInfo.IndividualId = addressBookSelect.IndividualId;
        } else {
            this.iImedClaimServiceContactInfo.IndividualId = null;
        }
    };

    ShowDocumentsPopup(event: Event): void {
        if (event && event.stopPropagation) {
            event.stopPropagation();
        }
        this.faxModalApi.show();
    }

    closeModal(): void {
        this.onFaxSent.emit();
        this.faxModalApi.close();
    }

    assignDocuments() {
        if (this.transcriptionServiceRequested && this.uploadedTranscriptionTemplates.length > 0) {
            this.iImedClaimServiceContactInfo.Documents = this.uploadedTranscriptionTemplates as unknown as IDocument[];
        }
    }
}
