import { ILabelGenerator } from '../../common/interfaces/ILabelGenerator';
import { IPhoneNumber } from '@model/interfaces/phone-number';
import { PhonePipe } from '@mt-ng2/phone-control';

export class PhoneLabelGenerator implements ILabelGenerator {
    phonePipe = new PhonePipe();

    GetLabel(entity: IPhoneNumber): string {
        return `${entity.IsPrimary ? '<i class="fa fa-star btn selected"></i>' : ''}
        ${entity.PhoneType ? `[${entity.PhoneType.Name}] ` : ''}<strong>${this.phonePipe.transform(entity.Phone)}</strong>
        ${entity.Notes ? `<br /><div style="padding-top:15px"><label>Notes: </label> ${entity.Notes}</div>` : ''}`;

    }

    GetDropdownLabel(entity: IPhoneNumber): string {
        const phoneLabel = `${entity.IsPrimary ? '(primary)' : ''} ${entity.PhoneType ? `[${entity.PhoneType.Name}] ` : ''} ${this.phonePipe.transform(entity.Phone)}`;
        return phoneLabel;
    }
}
