import { BillDeskReviewServiceDynamicControlsPartial } from '@model/partials/bill-desk-review-service.form-controls.partials';
import {
    IBillDeskReviewServiceDynamicControlsParameters,
    BillDeskReviewServiceDynamicControls,
} from '@model/form-controls/bill-desk-review-service.form-controls';
import { IBillDeskReviewService } from '@model/interfaces/bill-desk-review-service.d';
import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';

export class BillDeskReviewServiceDynamicConfig<T extends IBillDeskReviewService> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private billDeskReviewService: T, private configControls?: string[]) {
        super();
        const additionalParams: IBillDeskReviewServiceDynamicControlsParameters = {};

        const dynamicControls = new BillDeskReviewServiceDynamicControlsPartial(this.billDeskReviewService, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['ImedClaimServiceId', 'RequestDate', 'CreatedById', 'ModifiedById', 'DateCreated', 'DateModified', 'Archived'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
