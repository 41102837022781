import { Subscription, forkJoin } from 'rxjs';
import { IUser } from '@model/interfaces/user.d';
import { IFilmReviewService } from '@model/interfaces/film-review-service.d';
import { AuthService, ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IImedClaimServiceContactInfo } from './../../../../imed-claim-basic-info/imed-claim-contacts-info';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { ImedClaimServiceService } from '../../../imedclaimservice.service';
import { IImedClaimService } from '@model/interfaces/imed-claim-service';
import { UserService } from '../../../../../users/user.service';
import { IServicePayload } from '@model/interfaces/custom/update-service-payload';
import { finalize } from 'rxjs/operators';
import { ClaimTypes } from '@model/ClaimTypes';
import { PopUpService, IHasLegalFoundationPopUp } from '../../../../../common/services/popup-service';
import { FilmReviewServiceDynamicConfig } from '../../../common-components/film-review/film-review-service.dynamic-config';
import { FilmReviewServiceService } from '../../../common-components/film-review/film-review-service.service';

@Component({
    selector: 'app-film-review-service-basic-info',
    templateUrl: './film-review-service-basic-info.component.html',
})
export class FilmReviewServiceBasicInfoComponent implements OnInit, IHasLegalFoundationPopUp {
    @Input() iImedClaimServiceModel: IImedClaimService;
    @Input() filmReviewServiceModel: IFilmReviewService;
    @Input() canEdit: boolean;
    @Input() bodyPartsToBeExamined: string;
    @Input() reportDueBy: string;
    @Input() iImedClaimServiceContactInfo: IImedClaimServiceContactInfo;
    @Input() disablePhysicianFacilityControl: boolean;
    @Output('onRefreshStatus') onRefreshStatus: EventEmitter<any> = new EventEmitter<any>();
    @Output('physicianFaxSent') physicianFaxSent: EventEmitter<any> = new EventEmitter<any>();

    isEditing: boolean;
    isHovered: boolean;
    formRendered = false;

    filmReviewServiceFirstConfig: any = {};
    filmReviewServiceSecondConfig: any = {};

    filmReviewServiceForm: FormGroup;

    filmReviewFormFirstFactory: FilmReviewServiceDynamicConfig<IFilmReviewService>;
    filmReviewFormSecondFactory: FilmReviewServiceDynamicConfig<IFilmReviewService>;

    doubleClickIsDisabled = false;

    users = [];
    selectedDiscCopiedUser: IUser;
    selectedDiscMailedUser: IUser;
    imedClaimServiceId: number;
    ServiceId: number;
    subscriptions = new Subscription();
    canSeeAuditLog = false;
    initialImedClaimServiceContactInfo: IImedClaimServiceContactInfo;

    // Properties for IHasLegalFoundationPopUp
    popUpValidationFunction: () => void;
    hasPreLitigationCheckboxChecked: boolean;
    hasPreLitigationDocumentUploaded: boolean;
    isHardPopUp = false;
    isFederalCase: boolean;

    constructor(
        private imedClaimServiceService: ImedClaimServiceService,
        private notificationsService: NotificationsService,
        private fb: UntypedFormBuilder,
        private authService: AuthService,
        private filmReviewService: FilmReviewServiceService,
        private userservice: UserService,
        private claimsService: ClaimsService,
        private popUpService: PopUpService,
    ) {}

    ngOnInit(): void {
        this.isEditing = false;
        this.canSeeAuditLog = this.claimsService.hasClaim(ClaimTypes.Audit, [ClaimValues.FullAccess, ClaimValues.ReadOnly]);

        this.userservice.getAll().subscribe((users) => {
            this.users = users;
            this.setConfig();
        });
        this.subscriptions.add(
            this.imedClaimServiceService.changeEmitted$.subscribe((iImedClaimServiceModel) => {
                this.iImedClaimServiceModel = iImedClaimServiceModel;
                this.setConfig();
                this.popUpService.setPopupConfig(this);
            }),
        );

        this.popUpService.setPopupConfig(this);
    }

    ngOnChanges(changes: any): void {
        if (changes.iImedClaimServiceContactInfo) {
            this.initialImedClaimServiceContactInfo = { ...this.iImedClaimServiceContactInfo };
        }
    }

    setConfig(): void {
        if (this.filmReviewServiceModel.DiscCopiedById > 0) {
            this.selectedDiscCopiedUser = this.users.filter((x) => x.Id === this.filmReviewServiceModel.DiscCopiedById)[0];
        } else {
            this.selectedDiscCopiedUser = this.users.filter((x) => x.Id === this.authService.currentUser.getValue().Id)[0];
        }

        if (this.filmReviewServiceModel.DiscCopiedById > 0) {
            this.selectedDiscMailedUser = this.users.filter((x) => x.Id === this.filmReviewServiceModel.DiscMailedById)[0];
        } else {
            this.selectedDiscMailedUser = this.users.filter((x) => x.Id === this.authService.currentUser.getValue().Id)[0];
        }

        this.filmReviewServiceForm = this.fb.group({ FilmReviewService: this.fb.group({}) });

        this.filmReviewServiceFirstConfig = { formObject: [], viewOnly: [] };
        this.filmReviewServiceSecondConfig = { formObject: [], viewOnly: [] };

        this.filmReviewFormFirstFactory = new FilmReviewServiceDynamicConfig<IFilmReviewService>(this.filmReviewServiceModel, [
            'RequestDate',
            'DiscCopiedDate',
        ]);

        this.filmReviewFormSecondFactory = new FilmReviewServiceDynamicConfig<IFilmReviewService>(this.filmReviewServiceModel, [
            'OriginalFiledForReference',
            'DiscMailedDate',
            'HasPreLitigation',
            'IsFederalCase',
        ]);

        this.filmReviewServiceFirstConfig = this.filmReviewFormFirstFactory.getForUpdate();
        this.filmReviewServiceSecondConfig = this.filmReviewFormSecondFactory.getForUpdate();
        this.formRendered = true;
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    formCreated(formGroup: UntypedFormGroup): void {
        formGroup.get('FilmReviewService.HasPreLitigation').valueChanges.subscribe((value) => {
            this.hasPreLitigationCheckboxChecked = value;
        });

        formGroup.get('FilmReviewService.IsFederalCase').valueChanges.subscribe((value) => {
            this.isFederalCase = value;
        });
    }

    cancelClick(): void {
        this.isEditing = false;
        this.iImedClaimServiceContactInfo = { ...this.initialImedClaimServiceContactInfo };
    }

    formSubmitted(): void {
        const invalidServiceAddress = this.iImedClaimServiceContactInfo.PhysicianId > 0 && !this.iImedClaimServiceContactInfo.AddressId;

        if (this.filmReviewServiceForm.valid && !invalidServiceAddress) {
            this.iImedClaimServiceModel = this.imedClaimServiceService.assignContactsToImedClaimService(
                this.iImedClaimServiceModel,
                this.iImedClaimServiceContactInfo,
            );
            this.filmReviewFormFirstFactory.assignFormValues(
                this.filmReviewServiceModel,
                this.filmReviewServiceForm.value.FilmReviewService as IFilmReviewService,
            );
            this.filmReviewServiceModel.RequestDateChangedById = this.authService.currentUser.getValue().Id;
            this.filmReviewServiceModel.RequestDateChangedDate = new Date();

            const updatePayload = <IServicePayload<IFilmReviewService>>{};
            this.filmReviewServiceModel.DiscCopiedById = this.selectedDiscCopiedUser.Id;
            this.filmReviewServiceModel.DiscMailedById = this.selectedDiscMailedUser.Id;
            updatePayload.ChildService = this.filmReviewServiceModel;
            updatePayload.ParentService = this.iImedClaimServiceModel;

            this.filmReviewService.updateService(updatePayload).subscribe(() => {
                forkJoin(
                    this.imedClaimServiceService.getById(this.iImedClaimServiceModel.Id),
                    this.filmReviewService.getByImedClaimServiceId(this.iImedClaimServiceModel.Id),
                ).subscribe((response) => {
                    const [imedClaimServiceResponse, filmReviewServiceResponse] = response;
                    this.filmReviewServiceModel = filmReviewServiceResponse;
                    // this.onRefreshStatus.emit();
                    this.iImedClaimServiceModel = imedClaimServiceResponse;
                    this.imedClaimServiceService.emitChange(this.iImedClaimServiceModel);
                    this.filmReviewService.emitChange(this.filmReviewServiceModel);
                    this.isEditing = false;
                    this.success();
                    this.setConfig();
                });
            });
        } else {
            markAllFormFieldsAsTouched(this.filmReviewServiceForm);
            this.error();
        }
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }
}
