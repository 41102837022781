import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { IExpandableObject } from '../expandable-object';
import { IWcIssue } from '../interfaces/wc-issue';

export interface IWcIssueDynamicControlsParameters {
    formGroup?: string;
}

export class WcIssueDynamicControls {

    formGroup: string;

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private wcissue?: IWcIssue, additionalParameters?: IWcIssueDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'WcIssue';

        this.Form = {
            AdLsThatMayAffectThisClaim: new DynamicField({
                formGroup: this.formGroup,
                label: 'Ad Ls That May Affect This Claim',
                name: 'AdLsThatMayAffectThisClaim',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.wcissue && this.wcissue.hasOwnProperty('AdLsThatMayAffectThisClaim') && this.wcissue.AdLsThatMayAffectThisClaim !== null ? this.wcissue.AdLsThatMayAffectThisClaim : true,
            }),
            Apportionment: new DynamicField({
                formGroup: this.formGroup,
                label: 'Apportionment',
                name: 'Apportionment',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.wcissue && this.wcissue.hasOwnProperty('Apportionment') && this.wcissue.Apportionment !== null ? this.wcissue.Apportionment : false,
            }),
            AppropriatenessOfTreatment: new DynamicField({
                formGroup: this.formGroup,
                label: 'Appropriateness Of Treatment',
                name: 'AppropriatenessOfTreatment',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.wcissue && this.wcissue.hasOwnProperty('AppropriatenessOfTreatment') && this.wcissue.AppropriatenessOfTreatment !== null ? this.wcissue.AppropriatenessOfTreatment : true,
            }),
            CausalRelationship: new DynamicField({
                formGroup: this.formGroup,
                label: 'Causal Relationship',
                name: 'CausalRelationship',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.wcissue && this.wcissue.hasOwnProperty('CausalRelationship') && this.wcissue.CausalRelationship !== null ? this.wcissue.CausalRelationship : true,
            }),
            CompleteHistory: new DynamicField({
                formGroup: this.formGroup,
                label: 'Complete History',
                name: 'CompleteHistory',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.wcissue && this.wcissue.hasOwnProperty('CompleteHistory') && this.wcissue.CompleteHistory !== null ? this.wcissue.CompleteHistory : true,
            }),
            DegreeOfDisability: new DynamicField({
                formGroup: this.formGroup,
                label: 'Degree Of Disability',
                name: 'DegreeOfDisability',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.wcissue && this.wcissue.hasOwnProperty('DegreeOfDisability') && this.wcissue.DegreeOfDisability !== null ? this.wcissue.DegreeOfDisability : true,
            }),
            Diagnosis: new DynamicField({
                formGroup: this.formGroup,
                label: 'Diagnosis',
                name: 'Diagnosis',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.wcissue && this.wcissue.hasOwnProperty('Diagnosis') && this.wcissue.Diagnosis !== null ? this.wcissue.Diagnosis : true,
            }),
            FunctionalCapabilityEvaluation: new DynamicField({
                formGroup: this.formGroup,
                label: 'Functional Capability Evaluation',
                name: 'FunctionalCapabilityEvaluation',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.wcissue && this.wcissue.hasOwnProperty('FunctionalCapabilityEvaluation') && this.wcissue.FunctionalCapabilityEvaluation !== null ? this.wcissue.FunctionalCapabilityEvaluation : false,
            }),
            HistoryOfAllegedInjury: new DynamicField({
                formGroup: this.formGroup,
                label: 'History Of Alleged Injury',
                name: 'HistoryOfAllegedInjury',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.wcissue && this.wcissue.hasOwnProperty('HistoryOfAllegedInjury') && this.wcissue.HistoryOfAllegedInjury !== null ? this.wcissue.HistoryOfAllegedInjury : true,
            }),
            Mmi: new DynamicField({
                formGroup: this.formGroup,
                label: 'Mmi',
                name: 'Mmi',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.wcissue && this.wcissue.hasOwnProperty('Mmi') && this.wcissue.Mmi !== null ? this.wcissue.Mmi : false,
            }),
            NeedForContinuedFollowUp: new DynamicField({
                formGroup: this.formGroup,
                label: 'Need For Continued Follow Up',
                name: 'NeedForContinuedFollowUp',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.wcissue && this.wcissue.hasOwnProperty('NeedForContinuedFollowUp') && this.wcissue.NeedForContinuedFollowUp !== null ? this.wcissue.NeedForContinuedFollowUp : false,
            }),
            NeedForDme: new DynamicField({
                formGroup: this.formGroup,
                label: 'Need For Dme',
                name: 'NeedForDme',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.wcissue && this.wcissue.hasOwnProperty('NeedForDme') && this.wcissue.NeedForDme !== null ? this.wcissue.NeedForDme : false,
            }),
            NeedForEssentialServicesHouseholdHelp: new DynamicField({
                formGroup: this.formGroup,
                label: 'Need For Essential Services Household Help',
                name: 'NeedForEssentialServicesHouseholdHelp',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.wcissue && this.wcissue.hasOwnProperty('NeedForEssentialServicesHouseholdHelp') && this.wcissue.NeedForEssentialServicesHouseholdHelp !== null ? this.wcissue.NeedForEssentialServicesHouseholdHelp : false,
            }),
            NeedForFurtherDiagnosticTesting: new DynamicField({
                formGroup: this.formGroup,
                label: 'Need For Further Diagnostic Testing',
                name: 'NeedForFurtherDiagnosticTesting',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.wcissue && this.wcissue.hasOwnProperty('NeedForFurtherDiagnosticTesting') && this.wcissue.NeedForFurtherDiagnosticTesting !== null ? this.wcissue.NeedForFurtherDiagnosticTesting : false,
            }),
            NeedForFurtherTreatment: new DynamicField({
                formGroup: this.formGroup,
                label: 'Need For Further Treatment',
                name: 'NeedForFurtherTreatment',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.wcissue && this.wcissue.hasOwnProperty('NeedForFurtherTreatment') && this.wcissue.NeedForFurtherTreatment !== null ? this.wcissue.NeedForFurtherTreatment : false,
            }),
            NeedForFurtherTreatmentWithinYourScope: new DynamicField({
                formGroup: this.formGroup,
                label: 'Need For Further Treatment Within Your Scope',
                name: 'NeedForFurtherTreatmentWithinYourScope',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.wcissue && this.wcissue.hasOwnProperty('NeedForFurtherTreatmentWithinYourScope') && this.wcissue.NeedForFurtherTreatmentWithinYourScope !== null ? this.wcissue.NeedForFurtherTreatmentWithinYourScope : true,
            }),
            NeedForInjections: new DynamicField({
                formGroup: this.formGroup,
                label: 'Need For Injections',
                name: 'NeedForInjections',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.wcissue && this.wcissue.hasOwnProperty('NeedForInjections') && this.wcissue.NeedForInjections !== null ? this.wcissue.NeedForInjections : false,
            }),
            NeedForMassage: new DynamicField({
                formGroup: this.formGroup,
                label: 'Need For Massage',
                name: 'NeedForMassage',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.wcissue && this.wcissue.hasOwnProperty('NeedForMassage') && this.wcissue.NeedForMassage !== null ? this.wcissue.NeedForMassage : false,
            }),
            NeedForPhysicalTherapy: new DynamicField({
                formGroup: this.formGroup,
                label: 'Need For Physical Therapy',
                name: 'NeedForPhysicalTherapy',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.wcissue && this.wcissue.hasOwnProperty('NeedForPhysicalTherapy') && this.wcissue.NeedForPhysicalTherapy !== null ? this.wcissue.NeedForPhysicalTherapy : false,
            }),
            NeedForPrescriptionMedication: new DynamicField({
                formGroup: this.formGroup,
                label: 'Need For Prescription Medication',
                name: 'NeedForPrescriptionMedication',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.wcissue && this.wcissue.hasOwnProperty('NeedForPrescriptionMedication') && this.wcissue.NeedForPrescriptionMedication !== null ? this.wcissue.NeedForPrescriptionMedication : false,
            }),
            NeedForSurgery: new DynamicField({
                formGroup: this.formGroup,
                label: 'Need For Surgery',
                name: 'NeedForSurgery',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.wcissue && this.wcissue.hasOwnProperty('NeedForSurgery') && this.wcissue.NeedForSurgery !== null ? this.wcissue.NeedForSurgery : false,
            }),
            NeedForTransportation: new DynamicField({
                formGroup: this.formGroup,
                label: 'Need For Transportation',
                name: 'NeedForTransportation',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.wcissue && this.wcissue.hasOwnProperty('NeedForTransportation') && this.wcissue.NeedForTransportation !== null ? this.wcissue.NeedForTransportation : false,
            }),
            ObjectiveFindingsOnExam: new DynamicField({
                formGroup: this.formGroup,
                label: 'Objective Findings On Exam',
                name: 'ObjectiveFindingsOnExam',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.wcissue && this.wcissue.hasOwnProperty('ObjectiveFindingsOnExam') && this.wcissue.ObjectiveFindingsOnExam !== null ? this.wcissue.ObjectiveFindingsOnExam : true,
            }),
            ObjectiveFindingsOnExamDiagnosis: new DynamicField({
                formGroup: this.formGroup,
                label: 'Objective Findings On Exam Diagnosis',
                name: 'ObjectiveFindingsOnExamDiagnosis',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.wcissue && this.wcissue.hasOwnProperty('ObjectiveFindingsOnExamDiagnosis') && this.wcissue.ObjectiveFindingsOnExamDiagnosis !== null ? this.wcissue.ObjectiveFindingsOnExamDiagnosis : true,
            }),
            OccupationalHistory: new DynamicField({
                formGroup: this.formGroup,
                label: 'Occupational History',
                name: 'OccupationalHistory',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.wcissue && this.wcissue.hasOwnProperty('OccupationalHistory') && this.wcissue.OccupationalHistory !== null ? this.wcissue.OccupationalHistory : true,
            }),
            PastSubsequentMedicalHistory: new DynamicField({
                formGroup: this.formGroup,
                label: 'Past Subsequent Medical History',
                name: 'PastSubsequentMedicalHistory',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.wcissue && this.wcissue.hasOwnProperty('PastSubsequentMedicalHistory') && this.wcissue.PastSubsequentMedicalHistory !== null ? this.wcissue.PastSubsequentMedicalHistory : true,
            }),
            Permanency: new DynamicField({
                formGroup: this.formGroup,
                label: 'Permanency',
                name: 'Permanency',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.wcissue && this.wcissue.hasOwnProperty('Permanency') && this.wcissue.Permanency !== null ? this.wcissue.Permanency : false,
            }),
            PreExistingConditions: new DynamicField({
                formGroup: this.formGroup,
                label: 'Pre Existing Conditions',
                name: 'PreExistingConditions',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.wcissue && this.wcissue.hasOwnProperty('PreExistingConditions') && this.wcissue.PreExistingConditions !== null ? this.wcissue.PreExistingConditions : true,
            }),
            PresentComplaints: new DynamicField({
                formGroup: this.formGroup,
                label: 'Present Complaints',
                name: 'PresentComplaints',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.wcissue && this.wcissue.hasOwnProperty('PresentComplaints') && this.wcissue.PresentComplaints !== null ? this.wcissue.PresentComplaints : true,
            }),
            RecommendationForFurtherTreatment: new DynamicField({
                formGroup: this.formGroup,
                label: 'Recommendation For Further Treatment',
                name: 'RecommendationForFurtherTreatment',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.wcissue && this.wcissue.hasOwnProperty('RecommendationForFurtherTreatment') && this.wcissue.RecommendationForFurtherTreatment !== null ? this.wcissue.RecommendationForFurtherTreatment : false,
            }),
            ReturnToWorkRestrictionsAdl: new DynamicField({
                formGroup: this.formGroup,
                label: 'Return To Work Restrictions Adl',
                name: 'ReturnToWorkRestrictionsAdl',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.wcissue && this.wcissue.hasOwnProperty('ReturnToWorkRestrictionsAdl') && this.wcissue.ReturnToWorkRestrictionsAdl !== null ? this.wcissue.ReturnToWorkRestrictionsAdl : true,
            }),
            ReviewOfRecords: new DynamicField({
                formGroup: this.formGroup,
                label: 'Review Of Records',
                name: 'ReviewOfRecords',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.wcissue && this.wcissue.hasOwnProperty('ReviewOfRecords') && this.wcissue.ReviewOfRecords !== null ? this.wcissue.ReviewOfRecords : true,
            }),
            Slu: new DynamicField({
                formGroup: this.formGroup,
                label: 'Slu',
                name: 'Slu',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.wcissue && this.wcissue.hasOwnProperty('Slu') && this.wcissue.Slu !== null ? this.wcissue.Slu : false,
            }),
            SpecificTreatmentQuestions: new DynamicField({
                formGroup: this.formGroup,
                label: 'Specific Treatment Questions',
                name: 'SpecificTreatmentQuestions',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.wcissue && this.wcissue.hasOwnProperty('SpecificTreatmentQuestions') && this.wcissue.SpecificTreatmentQuestions !== null ? this.wcissue.SpecificTreatmentQuestions.toString() : '',
            }),
        };

        this.View = {
            AdLsThatMayAffectThisClaim: new DynamicLabel({
			    label: 'Ad Ls That May Affect This Claim',
			    value: this.wcissue && this.wcissue.hasOwnProperty('AdLsThatMayAffectThisClaim') && this.wcissue.AdLsThatMayAffectThisClaim !== null ? this.wcissue.AdLsThatMayAffectThisClaim : true,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Apportionment: new DynamicLabel({
			    label: 'Apportionment',
			    value: this.wcissue && this.wcissue.hasOwnProperty('Apportionment') && this.wcissue.Apportionment !== null ? this.wcissue.Apportionment : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            AppropriatenessOfTreatment: new DynamicLabel({
			    label: 'Appropriateness Of Treatment',
			    value: this.wcissue && this.wcissue.hasOwnProperty('AppropriatenessOfTreatment') && this.wcissue.AppropriatenessOfTreatment !== null ? this.wcissue.AppropriatenessOfTreatment : true,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            CausalRelationship: new DynamicLabel({
			    label: 'Causal Relationship',
			    value: this.wcissue && this.wcissue.hasOwnProperty('CausalRelationship') && this.wcissue.CausalRelationship !== null ? this.wcissue.CausalRelationship : true,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            CompleteHistory: new DynamicLabel({
			    label: 'Complete History',
			    value: this.wcissue && this.wcissue.hasOwnProperty('CompleteHistory') && this.wcissue.CompleteHistory !== null ? this.wcissue.CompleteHistory : true,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            DegreeOfDisability: new DynamicLabel({
			    label: 'Degree Of Disability',
			    value: this.wcissue && this.wcissue.hasOwnProperty('DegreeOfDisability') && this.wcissue.DegreeOfDisability !== null ? this.wcissue.DegreeOfDisability : true,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Diagnosis: new DynamicLabel({
			    label: 'Diagnosis',
			    value: this.wcissue && this.wcissue.hasOwnProperty('Diagnosis') && this.wcissue.Diagnosis !== null ? this.wcissue.Diagnosis : true,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            FunctionalCapabilityEvaluation: new DynamicLabel({
			    label: 'Functional Capability Evaluation',
			    value: this.wcissue && this.wcissue.hasOwnProperty('FunctionalCapabilityEvaluation') && this.wcissue.FunctionalCapabilityEvaluation !== null ? this.wcissue.FunctionalCapabilityEvaluation : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            HistoryOfAllegedInjury: new DynamicLabel({
			    label: 'History Of Alleged Injury',
			    value: this.wcissue && this.wcissue.hasOwnProperty('HistoryOfAllegedInjury') && this.wcissue.HistoryOfAllegedInjury !== null ? this.wcissue.HistoryOfAllegedInjury : true,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Mmi: new DynamicLabel({
			    label: 'Mmi',
			    value: this.wcissue && this.wcissue.hasOwnProperty('Mmi') && this.wcissue.Mmi !== null ? this.wcissue.Mmi : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            NeedForContinuedFollowUp: new DynamicLabel({
			    label: 'Need For Continued Follow Up',
			    value: this.wcissue && this.wcissue.hasOwnProperty('NeedForContinuedFollowUp') && this.wcissue.NeedForContinuedFollowUp !== null ? this.wcissue.NeedForContinuedFollowUp : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            NeedForDme: new DynamicLabel({
			    label: 'Need For Dme',
			    value: this.wcissue && this.wcissue.hasOwnProperty('NeedForDme') && this.wcissue.NeedForDme !== null ? this.wcissue.NeedForDme : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            NeedForEssentialServicesHouseholdHelp: new DynamicLabel({
			    label: 'Need For Essential Services Household Help',
			    value: this.wcissue && this.wcissue.hasOwnProperty('NeedForEssentialServicesHouseholdHelp') && this.wcissue.NeedForEssentialServicesHouseholdHelp !== null ? this.wcissue.NeedForEssentialServicesHouseholdHelp : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            NeedForFurtherDiagnosticTesting: new DynamicLabel({
			    label: 'Need For Further Diagnostic Testing',
			    value: this.wcissue && this.wcissue.hasOwnProperty('NeedForFurtherDiagnosticTesting') && this.wcissue.NeedForFurtherDiagnosticTesting !== null ? this.wcissue.NeedForFurtherDiagnosticTesting : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            NeedForFurtherTreatment: new DynamicLabel({
			    label: 'Need For Further Treatment',
			    value: this.wcissue && this.wcissue.hasOwnProperty('NeedForFurtherTreatment') && this.wcissue.NeedForFurtherTreatment !== null ? this.wcissue.NeedForFurtherTreatment : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            NeedForFurtherTreatmentWithinYourScope: new DynamicLabel({
			    label: 'Need For Further Treatment Within Your Scope',
			    value: this.wcissue && this.wcissue.hasOwnProperty('NeedForFurtherTreatmentWithinYourScope') && this.wcissue.NeedForFurtherTreatmentWithinYourScope !== null ? this.wcissue.NeedForFurtherTreatmentWithinYourScope : true,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            NeedForInjections: new DynamicLabel({
			    label: 'Need For Injections',
			    value: this.wcissue && this.wcissue.hasOwnProperty('NeedForInjections') && this.wcissue.NeedForInjections !== null ? this.wcissue.NeedForInjections : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            NeedForMassage: new DynamicLabel({
			    label: 'Need For Massage',
			    value: this.wcissue && this.wcissue.hasOwnProperty('NeedForMassage') && this.wcissue.NeedForMassage !== null ? this.wcissue.NeedForMassage : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            NeedForPhysicalTherapy: new DynamicLabel({
			    label: 'Need For Physical Therapy',
			    value: this.wcissue && this.wcissue.hasOwnProperty('NeedForPhysicalTherapy') && this.wcissue.NeedForPhysicalTherapy !== null ? this.wcissue.NeedForPhysicalTherapy : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            NeedForPrescriptionMedication: new DynamicLabel({
			    label: 'Need For Prescription Medication',
			    value: this.wcissue && this.wcissue.hasOwnProperty('NeedForPrescriptionMedication') && this.wcissue.NeedForPrescriptionMedication !== null ? this.wcissue.NeedForPrescriptionMedication : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            NeedForSurgery: new DynamicLabel({
			    label: 'Need For Surgery',
			    value: this.wcissue && this.wcissue.hasOwnProperty('NeedForSurgery') && this.wcissue.NeedForSurgery !== null ? this.wcissue.NeedForSurgery : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            NeedForTransportation: new DynamicLabel({
			    label: 'Need For Transportation',
			    value: this.wcissue && this.wcissue.hasOwnProperty('NeedForTransportation') && this.wcissue.NeedForTransportation !== null ? this.wcissue.NeedForTransportation : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ObjectiveFindingsOnExam: new DynamicLabel({
			    label: 'Objective Findings On Exam',
			    value: this.wcissue && this.wcissue.hasOwnProperty('ObjectiveFindingsOnExam') && this.wcissue.ObjectiveFindingsOnExam !== null ? this.wcissue.ObjectiveFindingsOnExam : true,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ObjectiveFindingsOnExamDiagnosis: new DynamicLabel({
			    label: 'Objective Findings On Exam Diagnosis',
			    value: this.wcissue && this.wcissue.hasOwnProperty('ObjectiveFindingsOnExamDiagnosis') && this.wcissue.ObjectiveFindingsOnExamDiagnosis !== null ? this.wcissue.ObjectiveFindingsOnExamDiagnosis : true,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            OccupationalHistory: new DynamicLabel({
			    label: 'Occupational History',
			    value: this.wcissue && this.wcissue.hasOwnProperty('OccupationalHistory') && this.wcissue.OccupationalHistory !== null ? this.wcissue.OccupationalHistory : true,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            PastSubsequentMedicalHistory: new DynamicLabel({
			    label: 'Past Subsequent Medical History',
			    value: this.wcissue && this.wcissue.hasOwnProperty('PastSubsequentMedicalHistory') && this.wcissue.PastSubsequentMedicalHistory !== null ? this.wcissue.PastSubsequentMedicalHistory : true,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Permanency: new DynamicLabel({
			    label: 'Permanency',
			    value: this.wcissue && this.wcissue.hasOwnProperty('Permanency') && this.wcissue.Permanency !== null ? this.wcissue.Permanency : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            PreExistingConditions: new DynamicLabel({
			    label: 'Pre Existing Conditions',
			    value: this.wcissue && this.wcissue.hasOwnProperty('PreExistingConditions') && this.wcissue.PreExistingConditions !== null ? this.wcissue.PreExistingConditions : true,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            PresentComplaints: new DynamicLabel({
			    label: 'Present Complaints',
			    value: this.wcissue && this.wcissue.hasOwnProperty('PresentComplaints') && this.wcissue.PresentComplaints !== null ? this.wcissue.PresentComplaints : true,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            RecommendationForFurtherTreatment: new DynamicLabel({
			    label: 'Recommendation For Further Treatment',
			    value: this.wcissue && this.wcissue.hasOwnProperty('RecommendationForFurtherTreatment') && this.wcissue.RecommendationForFurtherTreatment !== null ? this.wcissue.RecommendationForFurtherTreatment : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ReturnToWorkRestrictionsAdl: new DynamicLabel({
			    label: 'Return To Work Restrictions Adl',
			    value: this.wcissue && this.wcissue.hasOwnProperty('ReturnToWorkRestrictionsAdl') && this.wcissue.ReturnToWorkRestrictionsAdl !== null ? this.wcissue.ReturnToWorkRestrictionsAdl : true,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ReviewOfRecords: new DynamicLabel({
			    label: 'Review Of Records',
			    value: this.wcissue && this.wcissue.hasOwnProperty('ReviewOfRecords') && this.wcissue.ReviewOfRecords !== null ? this.wcissue.ReviewOfRecords : true,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Slu: new DynamicLabel({
			    label: 'Slu',
			    value: this.wcissue && this.wcissue.hasOwnProperty('Slu') && this.wcissue.Slu !== null ? this.wcissue.Slu : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            SpecificTreatmentQuestions: new DynamicLabel({
			    label: 'Specific Treatment Questions',
			    value: this.wcissue && this.wcissue.hasOwnProperty('SpecificTreatmentQuestions') && this.wcissue.SpecificTreatmentQuestions !== null ? this.wcissue.SpecificTreatmentQuestions.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Textarea,
			        scale: null,
			    })
			}
            ),
        };

    }
}
