import { IPhysician } from './../interfaces/physician.d';
import { IPhysicianDetail } from './../interfaces/physician-detail.d';
import { DynamicField, DynamicLabel, NumericInputTypes, DynamicFieldTypes, SelectInputTypes } from '@mt-ng2/dynamic-form';
import { PhysicianDynamicControls, IPhysicianDynamicControlsParameters } from '../form-controls/physician.form-controls';
import { PhysicianDetailDynamicControls, IPhysicianDetailDynamicControlsParameters } from '../form-controls/physician-detail.form-controls';

export class PhysicianDetailDynamicControlsPartial extends PhysicianDetailDynamicControls {
    constructor(
        physicianPartial?: IPhysicianDetail,
        additionalParameters?: IPhysicianDetailDynamicControlsParameters,
        canEditPhysicianFees?: boolean,
        canEditPhysicianCost?: boolean,
    ) {
        super(physicianPartial, additionalParameters);

        if (!canEditPhysicianFees) {
            (<DynamicField>this.Form.AdditionalFee).disabled = true;
            (<DynamicField>this.Form.FilmReviewFee).disabled = true;
            (<DynamicField>this.Form.HourlyFee).disabled = true;
            (<DynamicField>this.Form.ImeExamConsultationFee).disabled = true;
            (<DynamicField>this.Form.NoShowFee).disabled = true;
            (<DynamicField>this.Form.AdditionalFilmReviewFee).disabled = true;
            (<DynamicField>this.Form.AddendumFee).disabled = true;
            (<DynamicField>this.Form.ConferenceCallFee).disabled = true;
            (<DynamicField>this.Form.FullDayTestimonyFee).disabled = true;
            (<DynamicField>this.Form.HalfDayTestimonyFee).disabled = true;
            (<DynamicField>this.Form.VirtualTestimonyFee).disabled = true;
            (<DynamicField>this.Form.FullDayTelephoneDepositionFee).disabled = true;
            (<DynamicField>this.Form.HalfDayTelephoneDepositionFee).disabled = true;
            (<DynamicField>this.Form.Retainer).disabled = true;
            (<DynamicField>this.Form.RecordReviewRetainer).disabled = true;
        }

        if (!canEditPhysicianCost) {
            (<DynamicField>this.Form.AdditionalCost).disabled = true;
            (<DynamicField>this.Form.FilmReviewCost).disabled = true;
            (<DynamicField>this.Form.HourlyCost).disabled = true;
            (<DynamicField>this.Form.ImeExamConsultationCost).disabled = true;
            (<DynamicField>this.Form.NoShowCost).disabled = true;
            (<DynamicField>this.Form.AdditionalFilmReviewCost).disabled = true;
            (<DynamicField>this.Form.AddendumCost).disabled = true;
            (<DynamicField>this.Form.ConferenceCallCost).disabled = true;
            (<DynamicField>this.Form.FullDayTestimonyCost).disabled = true;
            (<DynamicField>this.Form.HalfDayTestimonyCost).disabled = true;
            (<DynamicField>this.Form.VirtualTestimonyCost).disabled = true;
            (<DynamicField>this.Form.FullDayTelephoneDepositionCost).disabled = true;
            (<DynamicField>this.Form.HalfDayTelephoneDepositionCost).disabled = true;
            (<DynamicField>this.Form.RetainerCost).disabled = true;
            (<DynamicField>this.Form.RecordReviewRetainerCost).disabled = true;
        }
        if (!physicianPartial?.WillingToTravel) {
            (<DynamicField>this.Form.TravelAreasOutsideOfVenue).disabled = true;
        }

        (<DynamicField>this.Form.AdditionalDate).labelHtml = '<label>Additional Effective Date</label>';
        (<DynamicField>this.Form.AdditionalDate).setRequired(false);
        (<DynamicField>this.Form.AdditionalFee).setRequired(false);
        (<DynamicField>this.Form.AdditionalNotes).setRequired(false);
        (<DynamicField>this.Form.AdditionalFee).type.inputType = NumericInputTypes.Currency;
        (<DynamicField>this.View.AdditionalFee).type.inputType = NumericInputTypes.Currency;
        (<DynamicField>this.Form.AdditionalCost).type.inputType = NumericInputTypes.Currency;

        (<DynamicField>this.Form.CvReceivedDate).setRequired(false);
        (<DynamicField>this.Form.CvReceivedDate).labelHtml = '<label>CV Received Effective Date</label>';
        (<DynamicLabel>this.View.CvReceivedDate).label = 'CV Received Date';

        (<DynamicField>this.Form.FeeScheduleReceivedDate).setRequired(false);
        (<DynamicField>this.Form.FilmReviewDate).labelHtml = '<label>Film Review Effective Date</label>';
        (<DynamicField>this.Form.FilmReviewDate).setRequired(false);
        (<DynamicField>this.Form.FilmReviewFee).type.inputType = NumericInputTypes.Currency;
        (<DynamicField>this.View.FilmReviewFee).type.inputType = NumericInputTypes.Currency;
        (<DynamicField>this.Form.FilmReviewCost).type.inputType = NumericInputTypes.Currency;

        (<DynamicField>this.Form.HourlyDate).labelHtml = '<label>Hourly Effective Date</label>';
        (<DynamicField>this.Form.HourlyDate).setRequired(false);
        (<DynamicField>this.Form.HourlyFee).setRequired(false);
        (<DynamicField>this.Form.HourlyFee).type.inputType = NumericInputTypes.Currency;
        (<DynamicField>this.View.HourlyFee).type.inputType = NumericInputTypes.Currency;
        (<DynamicField>this.Form.HourlyCost).type.inputType = NumericInputTypes.Currency;

        (<DynamicField>this.Form.ImeExamConsultationDate).setRequired(false);
        (<DynamicField>this.Form.ImeExamConsultationDate).labelHtml = '<label>Consultation Date</label>';
        (<DynamicLabel>this.View.ImeExamConsultationDate).label = 'IME Exam Consultation Date';

        (<DynamicField>this.Form.ImeExamConsultationFee).setRequired(false);
        (<DynamicField>this.Form.ImeExamConsultationFee).labelHtml = '<label>Consultation Fee</label>';
        (<DynamicLabel>this.View.ImeExamConsultationFee).label = 'IME Exam Consultation Fee';
        (<DynamicField>this.Form.ImeExamConsultationFee).type.inputType = NumericInputTypes.Currency;
        (<DynamicField>this.View.ImeExamConsultationFee).type.inputType = NumericInputTypes.Currency;

        (<DynamicField>this.Form.ImeExamConsultationCost).labelHtml = '<label>Consultation Cost</label>';
        (<DynamicLabel>this.View.ImeExamConsultationCost).label = 'IME Exam Consultation Cost';
        (<DynamicField>this.Form.ImeExamConsultationCost).type.inputType = NumericInputTypes.Currency;

        (<DynamicField>this.Form.InactiveDate).setRequired(true);
        (<DynamicField>this.Form.InactiveReason).setRequired(true);

        (<DynamicField>this.Form.IntroductoryLetterSentDate).setRequired(true);
        (<DynamicField>this.Form.IsPrepayment).setRequired(true);
        (<DynamicField>this.Form.LicenseVerificationDate).setRequired(true);

        (<DynamicField>this.Form.NoShowDate).labelHtml = '<label>No Show Effective Date</label>';
        (<DynamicField>this.Form.NoShowDate).setRequired(false);
        (<DynamicField>this.Form.NoShowFee).setRequired(false);
        (<DynamicField>this.Form.NoShowFee).type.inputType = NumericInputTypes.Currency;
        (<DynamicField>this.View.NoShowFee).type.inputType = NumericInputTypes.Currency;
        (<DynamicField>this.Form.NoShowCost).type.inputType = NumericInputTypes.Currency;

        (<DynamicField>this.Form.NonCompulsoryNote).setRequired(false);
        (<DynamicField>this.Form.PhysicianStatusId).setRequired(true);
        (<DynamicField>this.Form.SpecialNote).setRequired(false);

        (<DynamicLabel>this.View.WillingToTravel).value = physicianPartial && physicianPartial.WillingToTravel ? 'Yes' : 'No';

        (<DynamicField>this.Form.ConfirmationCancellationDays).label = 'Confirmation / Cancellation Days';
        (<DynamicField>this.Form.ConfirmationCancellationDays).labelHtml = '<label>Confirmation / Cancellation Days</label>';
        (<DynamicLabel>this.View.ConfirmationCancellationDays).label = 'Confirmation / Cancellation Days';

        (<DynamicField>this.Form.Retainer).type.inputType = NumericInputTypes.Currency;
        (<DynamicField>this.View.Retainer).type.inputType = NumericInputTypes.Currency;
        (<DynamicField>this.Form.RetainerCost).type.inputType = NumericInputTypes.Currency;

        (<DynamicField>this.Form.RecordReviewRetainer).type.inputType = NumericInputTypes.Currency;
        (<DynamicField>this.View.RecordReviewRetainer).type.inputType = NumericInputTypes.Currency;
        (<DynamicField>this.Form.RecordReviewRetainerCost).type.inputType = NumericInputTypes.Currency;

        (<DynamicField>this.Form.AdditionalFilmReviewFee).type.inputType = NumericInputTypes.Currency;
        (<DynamicField>this.View.AdditionalFilmReviewFee).type.inputType = NumericInputTypes.Currency;
        (<DynamicField>this.Form.AdditionalFilmReviewCost).type.inputType = NumericInputTypes.Currency;

        (<DynamicField>this.Form.AddendumFee).type.inputType = NumericInputTypes.Currency;
        (<DynamicField>this.View.AddendumFee).type.inputType = NumericInputTypes.Currency;
        (<DynamicField>this.Form.AddendumCost).type.inputType = NumericInputTypes.Currency;

        (<DynamicField>this.Form.ConferenceCallFee).type.inputType = NumericInputTypes.Currency;
        (<DynamicField>this.View.ConferenceCallFee).type.inputType = NumericInputTypes.Currency;
        (<DynamicField>this.Form.ConferenceCallCost).type.inputType = NumericInputTypes.Currency;

        (<DynamicField>this.Form.FullDayTestimonyFee).labelHtml = '<label>Full day testimony Fee<label>';
        (<DynamicLabel>this.View.FullDayTestimonyFee).label = 'Full day testimony Fee';
        (<DynamicField>this.Form.FullDayTestimonyFee).type.inputType = NumericInputTypes.Currency;
        (<DynamicField>this.View.FullDayTestimonyFee).type.inputType = NumericInputTypes.Currency;
        (<DynamicField>this.Form.FullDayTestimonyCost).type.inputType = NumericInputTypes.Currency;

        (<DynamicField>this.Form.HalfDayTestimonyFee).labelHtml = '<label>Half day testimony fee<label>';
        (<DynamicLabel>this.View.HalfDayTestimonyFee).label = 'Half day testimony fee';
        (<DynamicField>this.Form.HalfDayTestimonyFee).type.inputType = NumericInputTypes.Currency;
        (<DynamicField>this.View.HalfDayTestimonyFee).type.inputType = NumericInputTypes.Currency;
        (<DynamicField>this.Form.HalfDayTestimonyCost).type.inputType = NumericInputTypes.Currency;

        (<DynamicField>this.Form.VirtualTestimonyFee).labelHtml = '<label>Virtual testimony fee<label>';
        (<DynamicLabel>this.View.VirtualTestimonyFee).label = 'Virtual testimony fee';
        (<DynamicField>this.Form.VirtualTestimonyFee).type.inputType = NumericInputTypes.Currency;
        (<DynamicField>this.View.VirtualTestimonyFee).type.inputType = NumericInputTypes.Currency;
        (<DynamicField>this.Form.VirtualTestimonyCost).type.inputType = NumericInputTypes.Currency;

        (<DynamicField>this.Form.FullDayTelephoneDepositionFee).labelHtml = '<label>Full day telephone deposition fee<label>';
        (<DynamicLabel>this.View.FullDayTelephoneDepositionFee).label = 'Full day telephone deposition fee';
        (<DynamicField>this.Form.FullDayTelephoneDepositionFee).type.inputType = NumericInputTypes.Currency;
        (<DynamicField>this.View.FullDayTelephoneDepositionFee).type.inputType = NumericInputTypes.Currency;
        (<DynamicField>this.Form.FullDayTelephoneDepositionCost).type.inputType = NumericInputTypes.Currency;

        (<DynamicField>this.Form.HalfDayTelephoneDepositionFee).labelHtml = '<label>Half day telephone deposition fee<label>';
        (<DynamicField>this.View.HalfDayTelephoneDepositionFee).label = 'Half day telephone deposition fee';
        (<DynamicField>this.Form.HalfDayTelephoneDepositionFee).type.inputType = NumericInputTypes.Currency;
        (<DynamicField>this.View.HalfDayTelephoneDepositionFee).type.inputType = NumericInputTypes.Currency;
        (<DynamicField>this.Form.HalfDayTelephoneDepositionCost).type.inputType = NumericInputTypes.Currency;

        (<DynamicField>this.Form.WillingToTravel).labelHtml = '<label>Will travel?<label>';
        (<DynamicLabel>this.View.WillingToTravel).label = 'Will travel?';

        (<DynamicField>this.Form.TravelAreasOutsideOfVenue).labelHtml = '<label>Travel areas outside of venues noted<label>';
        (<DynamicLabel>this.View.TravelAreasOutsideOfVenue).label = 'Travel areas outside of venues noted';

        (<DynamicField>this.Form.WillCommentOnPediatricCases).labelHtml = '<label>Will comment on pediatric cases<label>';
        (<DynamicLabel>this.View.WillCommentOnPediatricCases).label = 'Will comment on pediatric cases';

        (<DynamicField>this.Form.LicenseExpirationDate).labelHtml = '<label>License expiration date<label>';
        (<DynamicLabel>this.View.LicenseExpirationDate).label = 'License expiration date';

        (<DynamicField>this.Form.BoardExpirationDate).labelHtml = '<label>Board expiration date<label>';
        (<DynamicLabel>this.View.BoardExpirationDate).label = 'Board expiration date';

        (<DynamicField>this.Form.ConfirmationCancellationDays).labelHtml = '<label>Confirmation Cancellation Days<label>';
        (<DynamicLabel>this.View.ConfirmationCancellationDays).label = 'Confirmation Cancellation Days';

        (<DynamicField>this.Form.NotRequiredForThisSpecialty).labelHtml = '<label>not required for this specialty<label>';
        (<DynamicLabel>this.View.NotRequiredForThisSpecialty).label = 'not required for this specialty';

    }
}
