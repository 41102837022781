import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IService } from '@model/interfaces/service';
import { ClaimTypeEnums } from '../../common/constants/Enums';
import { IMasterServiceStatus } from '@model/interfaces/master-service-status';
import { MasterServiceStatusDynamicControlsPartial } from '@model/partials/master-service-status-partial.form-controls';
import { IServiceStatus } from '@model/interfaces/service-status';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'master-service-status-form',
    styles: [
        `
            .form-select > dynamic-form-label {
                display: none;
            }
        `,
    ],
    templateUrl: './master-service-status-form.component.html',
})
export class MasterServiceStatusFormComponent implements OnInit {
    // abstract controls
    abstractMasterServiceStatusControls: any;
    abstractMasterServiceStatusServiceControls: any;

    @Input() masterServiceStatus: IMasterServiceStatus;
    @Input() Services: IService[];
    templateServices: IService[];
    selectedServiceIdsArray: number[] = [];

    @Output('formSubmit') formSubmit: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();
    @Output('attachedFks') attachedFks: EventEmitter<IMasterServiceStatus> = new EventEmitter<IMasterServiceStatus>();
    @Output('onCancelClick') onCancelClick: EventEmitter<void> = new EventEmitter<void>();

    masterServiceStatusForm: UntypedFormGroup;
    doubleClickIsDisabled = false;
    showSelectStateDropdown = false;
    autoCaseTypeSelected = false;
    workersCompCaseTypeSelected = false;
    generalLiabilityCaseTypeSelected = false;
    showSelectServiceDropdown = false;
    formCreated = false;
    invalidCaseTypeSelection = false;
    subscriptions$ = new Subscription();

    constructor(
        private fb: UntypedFormBuilder,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
    ) {}

    ngOnInit(): void {
        this.templateServices = this.Services;
        this.setServicesDropdownVisibility();
        this.createForm();
    }

    createForm(): void {
        this.getControls();
        this.masterServiceStatusForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    getControls(): void {
        if (!this.formCreated) {
            if (!this.masterServiceStatus.ServiceStatus) {
                this.masterServiceStatus.ServiceStatus = [];
            } else {
                this.masterServiceStatus.ServiceStatus.forEach((ServiceStatus) => this.selectedServiceIdsArray.push(ServiceStatus.ServiceId));
            }
        }
        this.abstractMasterServiceStatusControls = new MasterServiceStatusDynamicControlsPartial(this.masterServiceStatus, {
            formGroup: 'MasterServiceStatus',
            selectedServiceIdsArray: this.selectedServiceIdsArray,
            services: this.templateServices,
        }).Form;
    }

    assignFormGroups(): UntypedFormGroup {
        return this.fb.group({
            MasterServiceStatus: this.fb.group({}),
            MasterServiceStatusServices: this.fb.group({}),
        });
    }

    formSubmitted(): void {
        if (!this.autoCaseTypeSelected && !this.generalLiabilityCaseTypeSelected && !this.workersCompCaseTypeSelected) {
            this.invalidCaseTypeSelection = true;
        }

        if (this.masterServiceStatusForm.valid && !this.invalidCaseTypeSelection) {
             this.masterServiceStatus.ServiceStatus = [];
             if (this.masterServiceStatusForm.value.MasterServiceStatus.associatedServices) {
                this.masterServiceStatusForm.value.MasterServiceStatus.associatedServices.forEach((serviceId: number) => {
                    const ServiceStatus = {} as IServiceStatus;
                    ServiceStatus.Name = this.masterServiceStatusForm.value.MasterServiceStatus.Name;
                    ServiceStatus.ServiceId = serviceId;
                    ServiceStatus.Service = this.Services.find((x) => x.Id === serviceId);
                    this.masterServiceStatus.ServiceStatus.push(ServiceStatus);
                });
            }
             this.attachedFks.emit(this.masterServiceStatus);
             this.formSubmit.emit(this.masterServiceStatusForm);
        } else {
            markAllFormFieldsAsTouched(this.masterServiceStatusForm);
            this.error();
        }
    }

    cancelClick(): void {
        this.onCancelClick.emit();
    }

    error(): void {
        this.notificationsService.error('Save failed.  Please check the form and try again.');
    }

    success(): void {
        this.notificationsService.success('Dynamic Template saved successfully.');
    }

    showHideServiceSelectDropdown(): void {
        if (this.autoCaseTypeSelected || this.generalLiabilityCaseTypeSelected || this.workersCompCaseTypeSelected) {
            this.invalidCaseTypeSelection = false;
            this.showSelectServiceDropdown = false;
            setTimeout(() => {
                this.showSelectServiceDropdown = true;
            }, 0);
        } else {
            this.showSelectServiceDropdown = false;
        }
        this.filterServicesSelectOptions();
    }

    filterServicesSelectOptions(): void {
        this.templateServices = this.Services;
        if (!this.autoCaseTypeSelected) {
            this.templateServices = this.templateServices.filter((s) => s.ClaimTypeId !== +ClaimTypeEnums.Auto);
        }
        if (!this.generalLiabilityCaseTypeSelected) {
            this.templateServices = this.templateServices.filter((s) => s.ClaimTypeId !== +ClaimTypeEnums.GL);
        }
        if (!this.workersCompCaseTypeSelected) {
            this.templateServices = this.templateServices.filter((s) => s.ClaimTypeId !== +ClaimTypeEnums.WC);
        }
        const availableServiceIds = [];
        this.templateServices.forEach((templateService) => {
            availableServiceIds.push(templateService.Id);
        });
        if (availableServiceIds.length === 0) {
            this.selectedServiceIdsArray = [];
        }
        this.selectedServiceIdsArray.forEach((id) => {
            if (availableServiceIds.indexOf(id) === -1) {
                this.selectedServiceIdsArray = this.selectedServiceIdsArray.filter((x) => x !== id);
            }
        });
        this.getControls();
    }

    setServicesDropdownVisibility(): void {
        this.autoCaseTypeSelected = this.masterServiceStatus.Auto;
        this.workersCompCaseTypeSelected = this.masterServiceStatus.WorkersComp;
        this.generalLiabilityCaseTypeSelected = this.masterServiceStatus.GeneralLiability;
        if (this.autoCaseTypeSelected || this.generalLiabilityCaseTypeSelected || this.workersCompCaseTypeSelected) {
            this.showSelectServiceDropdown = false;
            setTimeout(() => {
                this.showSelectServiceDropdown = true;
            }, 0);
        } else {
            this.showSelectServiceDropdown = false;
        }
        this.templateServices = this.Services;
        if (!this.autoCaseTypeSelected) {
            this.templateServices = this.templateServices.filter((s) => s.ClaimTypeId !== +ClaimTypeEnums.Auto);
        }
        if (!this.generalLiabilityCaseTypeSelected) {
            this.templateServices = this.templateServices.filter((s) => s.ClaimTypeId !== +ClaimTypeEnums.GL);
        }
        if (!this.workersCompCaseTypeSelected) {
            this.templateServices = this.templateServices.filter((s) => s.ClaimTypeId !== +ClaimTypeEnums.WC);
        }
    }

    checkGLCaseType(event: any): void {
        this.generalLiabilityCaseTypeSelected = event;
        this.showHideServiceSelectDropdown();
    }

    checkWCCaseType(event: any): void {
        this.workersCompCaseTypeSelected = event;
        this.showHideServiceSelectDropdown();
    }

    checkAutoCaseType(event: any): void {
        this.autoCaseTypeSelected = event;
        this.showHideServiceSelectDropdown();
    }

    serviceSelectionChanged(event: any): void {
        this.selectedServiceIdsArray = event === null ? [] : event;
        this.getControls();
    }
}
