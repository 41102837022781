import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IWcIssue } from '@model/interfaces/wc-issue';
import { BaseService } from '@mt-ng2/base-service';

export const emptyWcIssue: IWcIssue = {
    AdLsThatMayAffectThisClaim: true,
    Apportionment: false,
    AppropriatenessOfTreatment: true,
    CausalRelationship: true,
    CompleteHistory: true,
    DegreeOfDisability: true,
    Diagnosis: true,
    FunctionalCapabilityEvaluation: false,
    HistoryOfAllegedInjury: true,
    Id: 0,
    Mmi: false,
    NeedForContinuedFollowUp: false,
    NeedForDme: false,
    NeedForEssentialServicesHouseholdHelp: false,
    NeedForFurtherDiagnosticTesting: false,
    NeedForFurtherTreatment: false,
    NeedForFurtherTreatmentWithinYourScope: true,
    NeedForInjections: false,
    NeedForMassage: false,
    NeedForPhysicalTherapy: false,
    NeedForPrescriptionMedication: false,
    NeedForSurgery: false,
    NeedForTransportation: false,
    ObjectiveFindingsOnExam: true,
    ObjectiveFindingsOnExamDiagnosis: true,
    OccupationalHistory: true,
    PastSubsequentMedicalHistory: true,
    Permanency: false,
    PreExistingConditions: true,
    PresentComplaints: true,
    RecommendationForFurtherTreatment: false,
    ReturnToWorkRestrictionsAdl: true,
    ReviewOfRecords: true,
    Slu: false,
    SpecificTreatmentQuestions: '',
};

@Injectable({ providedIn: 'root' })
export class WCIssueService extends BaseService<IWcIssue> {
    constructor(private httpClient: HttpClient) {
        super('/wcissues', httpClient);
    }

    getEmptyWcIssue(): IWcIssue {
        return { ...emptyWcIssue };
    }
}
