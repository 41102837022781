import { IConferenceCallServiceDateTime } from '@model/interfaces/conference-call-service-date-time.d';
import { IConferenceCallService } from '@model/interfaces/conference-call-service.d';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '@mt-ng2/base-service';
import { nullOutNestedObjects } from '../../../../common/custom/nullOutNestedObjects';
import { IServicePayload } from '@model/interfaces/custom/update-service-payload';

export const emptyConferenceCallService: IConferenceCallService = {
    Archived: false,
    CreatedById: 0,
    DateCreated: null,
    DateTimeAgreedByPhysician: null,
    Id: 0,
    ImedClaimServiceId: 0,
};

export const emptyConferenceCallDateTimeRequestedDate: IConferenceCallServiceDateTime = {
    ConferenceCallServiceId: 0,
    DateTimeRequested: null,
    Id: 0,
};

@Injectable()
export class ConferenceCallServiceService extends BaseService<IConferenceCallService> {
    constructor(public http: HttpClient) {
        super('/conferencecallservices', http);
    }

    getEmptyConferenceCallServiceService(): IConferenceCallService {
        return { ...emptyConferenceCallService };
    }

    getEmptyConferenceCallDateTimeRequested(): IConferenceCallServiceDateTime {
        return { ...emptyConferenceCallDateTimeRequestedDate };
    }

    getByImedClaimServiceId(imedClaimServiceId: number): Observable<IConferenceCallService> {
        return this.http.get<IConferenceCallService>(`/conferencecallservices/getByImedClaimServiceId/${imedClaimServiceId}`);
    }

    updateService(servicePayLoad: IServicePayload<IConferenceCallService>): any {
        const clone: IServicePayload<IConferenceCallService> = JSON.parse(JSON.stringify(servicePayLoad));
        nullOutNestedObjects(clone.ParentService);
        // not nulling out child service since there are required nested objects
        return this.http.put<number>(`/conferencecallservices/update-service/${clone.ParentService.Id}`, clone);
    }

    getConferenceCallDateTimeForAuditLog(dateId: number): Observable<any> {
        return this.http.get<any>(`/conferencecallservices/getConferencCallTimeForAuditLog/${dateId}`);
    }
}
