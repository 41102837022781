import { ImeServiceDynamicControlsPartial } from '@model/partials/ime-service-partial.form-controls';
import { IImeServiceDynamicControlsParameters } from '@model/form-controls/ime-service.form-controls';
import { IImeService } from '@model/interfaces/ime-service.d';
import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';

export class ImeServiceDynamicConfig<T extends IImeService> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private imeService: T, private configControls?: string[]) {
        super();
        const additionalParams: IImeServiceDynamicControlsParameters = {};

        const dynamicControls = new ImeServiceDynamicControlsPartial(this.imeService, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = [
                'ImedClaimServiceId',
                'PhysicianId',
                'FilmReviewId',
                'TranslationServiceRequested',
                'TranslationServiceScheduledDate',
                'TranslationServiceAddressBookVendorId',
                'TranslationServiceIndividualVendorId',
                'TranslationServiceNotes',
                'BackgroundInvestigationRequested',
                'BackgroundInvestigationScheduledDate',
                'BackgroundInvestigationAddressBookVendorId',
                'BackgroundInvestigationIndividualVendorId',
                'BackgroundInvestigationNotes',
                'TransporationServicesRequested',
                'TransporationServicesScheduledDate',
                'TransporationServicesAddressBookVendorId',
                'TransporationServicesIndividualVendorId',
                'TransporationServicesNotes',
                'CreatedById',
                'ModifiedById',
                'DateCreated',
                'DateModified',
                'Archived',
            ];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
