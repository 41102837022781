import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IImedClaimCourtFee } from '../interfaces/imed-claim-court-fee';
import { IUser } from '../interfaces/user';
import { IImedClaim } from '../interfaces/imed-claim';

export interface IImedClaimCourtFeeDynamicControlsParameters {
    formGroup?: string;
    imedClaims?: IImedClaim[];
    createdBies?: IUser[];
    modifiedBies?: IUser[];
}

export class ImedClaimCourtFeeDynamicControls {

    formGroup: string;
    imedClaims: IImedClaim[];
    createdBies: IUser[];
    modifiedBies: IUser[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private imedclaimcourtfee?: IImedClaimCourtFee, additionalParameters?: IImedClaimCourtFeeDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'ImedClaimCourtFee';
        this.imedClaims = additionalParameters && additionalParameters.imedClaims || undefined;
        this.createdBies = additionalParameters && additionalParameters.createdBies || undefined;
        this.modifiedBies = additionalParameters && additionalParameters.modifiedBies || undefined;

        this.Form = {
            CreatedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Created By',
                name: 'CreatedById',
                options: this.createdBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.imedclaimcourtfee && this.imedclaimcourtfee.CreatedById || null,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimcourtfee && this.imedclaimcourtfee.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimcourtfee && this.imedclaimcourtfee.DateModified || null,
            }),
            Fees: new DynamicField({
                formGroup: this.formGroup,
                label: 'Fees',
                name: 'Fees',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimcourtfee && this.imedclaimcourtfee.Fees || 0,
            }),
            ImedClaimId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Imed Claim',
                name: 'ImedClaimId',
                options: this.imedClaims,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.imedclaimcourtfee && this.imedclaimcourtfee.ImedClaimId || null,
            }),
            ModifiedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Modified By',
                name: 'ModifiedById',
                options: this.modifiedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimcourtfee && this.imedclaimcourtfee.ModifiedById || null,
            }),
        };

        this.View = {
            CreatedById: new DynamicLabel({
			    label: 'Created By',
			    value: getMetaItemValue(this.createdBies as unknown as IMetaItem[], this.imedclaimcourtfee && this.imedclaimcourtfee.hasOwnProperty('CreatedById') && this.imedclaimcourtfee.CreatedById !== null ? this.imedclaimcourtfee.CreatedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            DateCreated: new DynamicLabel({
			    label: 'Date Created',
			    value: this.imedclaimcourtfee && this.imedclaimcourtfee.DateCreated || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DateModified: new DynamicLabel({
			    label: 'Date Modified',
			    value: this.imedclaimcourtfee && this.imedclaimcourtfee.DateModified || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            Fees: new DynamicLabel({
			    label: 'Fees',
			    value: this.imedclaimcourtfee && this.imedclaimcourtfee.Fees || 0,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: null,
			        scale: 2,
			    })
			}
            ),
            ImedClaimId: new DynamicLabel({
			    label: 'Imed Claim',
			    value: getMetaItemValue(this.imedClaims as unknown as IMetaItem[], this.imedclaimcourtfee && this.imedclaimcourtfee.hasOwnProperty('ImedClaimId') && this.imedclaimcourtfee.ImedClaimId !== null ? this.imedclaimcourtfee.ImedClaimId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ModifiedById: new DynamicLabel({
			    label: 'Modified By',
			    value: getMetaItemValue(this.modifiedBies as unknown as IMetaItem[], this.imedclaimcourtfee && this.imedclaimcourtfee.hasOwnProperty('ModifiedById') && this.imedclaimcourtfee.ModifiedById !== null ? this.imedclaimcourtfee.ModifiedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };

    }
}
