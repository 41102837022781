import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';
import { IMasterFollowupType } from '@model/interfaces/master-followup-type';

export const emptyMasterFollowupType: IMasterFollowupType = {
    Archived: false,
    Auto: null,
    GeneralLiability: null,
    Id: 0,
    IsCaseFollowup: false,
    Name: null,
    ShowNotesOnClientPortal: null,
    WorkersComp: null,
};

@Injectable({
    providedIn: 'root',
})

export class MasterFollowUpTypeService extends BaseService<IMasterFollowupType> {
    constructor(public http: HttpClient) {
        super('/master-followup-types', http, null, {
            entityName: 'Followup Type',
        });
    }

    formatTitleText(masterFollowUpType: IMasterFollowupType): void {
        this.setTitle(`Followup Type: ${masterFollowUpType.Name}`);
    }

    getEmptyMasterFollowupType(): IMasterFollowupType {
        return { ...emptyMasterFollowupType };
    }
}
