import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, ClaimValues } from '@mt-ng2/auth-module';
import { TaskDetailComponent } from './task-detail/task-detail.component';
import { TasksComponent } from './task-list/tasks.component';
import { TaskService } from './task.service';

import { ClaimTypes } from '@model/ClaimTypes';
import { TaskAddComponent } from './task-add/task-add.component';
import { CommunicationLogsComponent } from '../imed-claims/services/common-components/communication-log/communication-logs.component';
import { CommunicationLogTypeEnums } from '../common/constants/Enums';

const taskEntityConfig = {
    claimType: ClaimTypes.Tasks,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'taskId',
    service: TaskService,
    title: 'Task Detail',
};

const taskCommunicationLogsConfig = {
    claimType: ClaimTypes.Tasks,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'taskId',
    logType: CommunicationLogTypeEnums.TASK,
    title: 'CommunicationLogs',
};

const taskListRoleGuard = {
    claimType: ClaimTypes.Tasks,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Tasks',
};

const taskAddRoleGuard = {
    claimType: ClaimTypes.Tasks,
    claimValues: [ClaimValues.FullAccess],
};

const taskRoutes: Routes = [
    { path: 'tasks', component: TasksComponent, canActivate: [AuthGuard], data: taskListRoleGuard },
    {
        canActivate: [AuthGuard],
        component: TaskAddComponent,
        data: taskAddRoleGuard,
        path: 'tasks/add',
        pathMatch: 'full',
    },
    {
        canActivate: [AuthGuard],
        component: TaskDetailComponent,
        data: taskEntityConfig,
        path: `tasks/:${taskEntityConfig.entityIdParam}`,
    },
    {
        canActivate: [AuthGuard],
        component: CommunicationLogsComponent,
        data: taskCommunicationLogsConfig,
        path: `tasks/:${taskEntityConfig.entityIdParam}/communication-logs`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(taskRoutes)],
})
export class TaskRoutingModule { }
