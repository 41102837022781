import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { IFilteredPhysicianStatusTaskDTO } from '@model/interfaces/custom/filtered-physician-status-task-dto';
import { IGroupedTaskTemplatesByPhysicianStatusDTO } from '@model/interfaces/custom/grouped-task-templates-by-physician-status-dto';
import { IPhysicianStatus } from '@model/interfaces/physician-status';
import { ITaskTemplate } from '@model/interfaces/task-template';

export interface IStatusTaskAssociation {
    PhysicianStatus: IPhysicianStatus;
    TaskTemplate: ITaskTemplate;
}

@Component({
    selector: 'task-row',
    styleUrls: ['../../row-styles.less'],
    templateUrl: './physician-status-task-row.component.html',
})
export class PhysicianStatusTaskRowComponent implements OnInit {
    private _filteredAddressBookTypeData: IFilteredPhysicianStatusTaskDTO;
    @Input('filteredAddressBookTypeData')
    set filteredAddressBookTypeData(val: IFilteredPhysicianStatusTaskDTO) {
        this._filteredAddressBookTypeData = { ...val };
    }
    get filteredAddressBookTypeData(): IFilteredPhysicianStatusTaskDTO {
        return this._filteredAddressBookTypeData;
    }
    @Input() groupedTasks: IGroupedTaskTemplatesByPhysicianStatusDTO;
    @Input() className = '';
    @Output() onTaskSelected: EventEmitter<IStatusTaskAssociation> = new EventEmitter();
    @Output() onTaskRemoved: EventEmitter<IStatusTaskAssociation> = new EventEmitter();

    selectedTask: ITaskTemplate;

    constructor() {}

    ngOnInit(): void {
        this.filteredAddressBookTypeData.TaskTemplates = this.filteredAddressBookTypeData.TaskTemplates.filter(
            (x) => !this.groupedTasks.TaskTemplates.some((y) => y.Id === x.Id),
        );
    }

    taskSelected(): void {
        this.filteredAddressBookTypeData.TaskTemplates = this.filteredAddressBookTypeData.TaskTemplates.filter((x) => x.Id !== this.selectedTask.Id);
        this.groupedTasks.TaskTemplates.push(this.selectedTask);
        this.onTaskSelected.emit({
            PhysicianStatus: this.groupedTasks.PhysicianStatus,
            TaskTemplate: this.selectedTask,
        } as IStatusTaskAssociation);
        this.selectedTask = null;
    }

    taskRemoved(index: number, task: ITaskTemplate): void {
        this.groupedTasks.TaskTemplates.splice(index, 1);
        this.filteredAddressBookTypeData.TaskTemplates.push(task);
        this.onTaskRemoved.emit({
            PhysicianStatus: this.groupedTasks.PhysicianStatus,
            TaskTemplate: task,
        } as IStatusTaskAssociation);
    }
}
