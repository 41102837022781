import { AddressBookDynamicControlsPartial } from '@model/partials/address-book-partial.form-controls';
import { IAddressBook } from '@model/interfaces/address-book';
import { IMetaAddressBookType } from '@model/interfaces/meta-address-book-type';
import { IAddressBookType } from '@model/interfaces/address-book-type';

import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';
export class AddressBookDynamicConfig<T extends IAddressBook> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(
        private addressBook: T,
        private metaaddressbooktypes?: IMetaAddressBookType[],
        private addressbooktypes?: IAddressBookType[],
        private configControls?: string[],
    ) {
        super();
        const dynamicControls = new AddressBookDynamicControlsPartial(this.addressBook, {
            addressBookTypes: this.addressbooktypes,
            metaAddressBookTypes: this.metaaddressbooktypes,
        });
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = [
                'FacilityName',
                'MetaAddressBookTypeId',
                'AddressBookTypeId',
                'Attention',
                'PrintOnCheckAs',
                'Ein',
                'DomainName',
                'Notes',
            ];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
