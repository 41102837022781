import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { IEmailTemplate } from '@model/interfaces/email-template';
import { EmailTemplateService } from '../emailtemplate.service';
import { AuthService, ClaimsService, ClaimValues } from '@mt-ng2/auth-module';

import { ClaimTypes } from '@model/ClaimTypes';
import { IEntityUser, ConcurrentUsersSocketService } from '@common/hub/user-on-service-socket.service';
import { trigger, transition, style, animate } from '@angular/animations';
@Component({
    animations: [
        trigger('animate', [
            transition(':enter', [
                style({ transform: 'translateX(100%)', opacity: 0 }),
                animate('500ms', style({ transform: 'translateX(0)', opacity: 1 })),
            ]),
            transition(':leave', [
                style({ transform: 'translateX(0)', opacity: 1 }),
                animate('500ms', style({ transform: 'translateX(100%)', opacity: 0 })),
            ]),
        ]),
    ],
    selector: 'app-email-template-detail',
    styles: [
        `
            .slide {
                -webkit-transition: all 0.25s ease;
                -moz-transition: all 0.25s ease;
                -o-transition: all 0.25s ease;
                -ms-transition: all 0.25s ease;
                transition: all 0.25s ease;
            }
        `,
    ],
    templateUrl: './email-template-detail.component.html',
})
export class EmailTemplateDetailComponent implements OnInit {
    emailTemplate: IEmailTemplate;
    editingComponent: Subject<any> = new Subject();
    canEdit: boolean;
    canAdd: boolean;
    id: number;
    ConcurrentUsers: IEntityUser[] = [];
    socketOn = false;
    subscriptions: Subscription = new Subscription();

    constructor(private emailTemplateService: EmailTemplateService,
                private claimsService: ClaimsService,
                private route: ActivatedRoute,
                private ConcurrentUsersSocketService: ConcurrentUsersSocketService,
                private authService: AuthService,
                ) { }

    ngOnInit(): void {
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.EmailTemplates, [ClaimValues.FullAccess]);
        this.canAdd = this.canEdit;
        this.id = +this.route.snapshot.paramMap.get('emailTemplateId');
        if (this.id > 0) {
            this.getEmailTemplateById(this.id);
        } else {
            this.emailTemplate = this.emailTemplateService.getEmptyEmailTemplate();
        }
        this.editingComponent.next('');
        this.initConcurrentUsersSocket();
        if (!this.socketOn) {
            this.initConcurrentUsersSocket();
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        this.ConcurrentUsersSocketService.disconnect();
    }

    getEmailTemplateById(id: number): void {
        this.emailTemplateService.getById(id).subscribe((emailTemplate) => {
            this.emailTemplate = emailTemplate;
        });
    }

    initConcurrentUsersSocket(): void {
        this.socketOn = true;
        this.ConcurrentUsersSocketService.connect();
        this.ConcurrentUsersSocketService.join({
            ServiceId: this.id,
            UserId: this.authService.currentUser.getValue().Id,
            UserName: this.authService.currentUser.getValue().Name,
        });
        this.ConcurrentUsersSocketService.afterJoin().subscribe((users: IEntityUser[]) => {
            this.ConcurrentUsers = users.filter((user) => user.UserId !== this.authService.currentUser.getValue().Id);
        });

        this.ConcurrentUsersSocketService.afterLeave().subscribe((users: IEntityUser[]) => {
            this.ConcurrentUsers = users.filter((user) => user.UserId !== this.authService.currentUser.getValue().Id);
        });
    }

}
