import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { Observable } from 'rxjs';
import { IMasterFollowupTypeTaskTemplate } from '@model/interfaces/master-followup-type-task-template';
import { IGroupedTaskTemplatesByFollowupDTO } from '@model/interfaces/custom/grouped-task-templates-by-followup-dto';

export const emptyServiceTaskAssociation: IMasterFollowupTypeTaskTemplate = {
    Id: 0,
    MasterFollowupTypeId: null,
    ServiceId: null,
    TaskTemplateId: null,
};

@Injectable()
export class MasterFollowupTypeTaskTemplateService extends BaseService<IMasterFollowupTypeTaskTemplate> {
    constructor(public http: HttpClient) {
        super('/masterfollowuptypetasktemplates', http);
    }

    getEmptyServiceTaskAssociation(): IMasterFollowupTypeTaskTemplate {
        return emptyServiceTaskAssociation;
    }

    getGroupedTaskTemplatesByService(serviceId: number): Observable<IGroupedTaskTemplatesByFollowupDTO[]> {
        return this.http.get<IGroupedTaskTemplatesByFollowupDTO[]>(`/masterfollowuptypetasktemplates/${serviceId}/groupedTaskTemplates`);
    }
}
