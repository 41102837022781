import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AddressBookAssociationsToggleService } from '../services/ab-associations-type-toggle.service';
import { IAddressBookType } from '@model/interfaces/address-book-type';
import { AddressBookAssociationTypeEnums } from '@common/constants/Enums';

@Component({
    selector: 'system-task-association-detail',
    templateUrl: './addressbook-associations-detail.component.html',
})
export class AddressBookTypeAssociationsDetailComponent implements OnInit, OnDestroy {
    showSystemTaskTemplateComponent: boolean;
    showEmailTemplateComponent: boolean;
    associationTypeSubscription: Subscription;
    private _selectedAddressBookType: IAddressBookType;
    get selectedAddressBookType(): IAddressBookType {
        return this._selectedAddressBookType;
    }
    set selectedAddressBookType(val: IAddressBookType) {
        this._selectedAddressBookType = val;
    }

    constructor(private associationToggleService: AddressBookAssociationsToggleService) {}

    ngOnInit(): void {
        this.associationTypeSubscription = this.associationToggleService.getAssociationType().subscribe((type) => {
            this.showSystemTaskTemplateComponent = type === AddressBookAssociationTypeEnums.SYSTEM_TASK;
            this.showEmailTemplateComponent = type === AddressBookAssociationTypeEnums.EMAIL_ASSOCIATIONS;
        });
    }

    ngOnDestroy(): void {
        this.associationTypeSubscription.unsubscribe();
    }
}
