import { IEntityListConfig, EntityListConfig, EntityListColumn, SortDirection, EntityListDeleteColumn } from '@mt-ng2/entity-list-module';
import { IPhysicianVacation } from '@model/interfaces/physician-vacation';

export class PhysicianVacationsEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['FromDate'],
                    name: 'From Date',
                    pipes: ['date'],
                }),
                new EntityListColumn({
                    accessors: ['ToDate'],
                    name: 'To Date',
                    pipes: ['date'],
                }),
            ],
            delete: new EntityListDeleteColumn({
                columnHtml: (entity: IPhysicianVacation) =>
                    entity.Archived
                        ? '<div><i class="fa fa-2x fa-trash" aria-hidden="true"></i><i class="fa fa-2x fa-undo overlay-fa" data-fa-transform="shrink-8 down-1"></i></div>'
                        : '<div><i class="fa fa-2x fa-trash" aria-hidden="true"></i><i class="fa fa-2x fa-undo overlay-fa-invisible" data-fa-transform="shrink-8 down-1"></i></div>',
                confirm: {
                    confirmButtonText: 'Yes, I am sure!',
                    text: 'Are you sure you want to modify this record?',
                    title: 'Archive Status',
                },
                headerText: 'Archive',
            }),
            rowClass: (entity: IPhysicianVacation) => (entity.Archived ? 'archived' : null),
        };
        super(listConfig);
    }
}
