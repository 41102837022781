import { IPhysician } from './../interfaces/physician.d';
import { DynamicField, DynamicLabel, NumericInputTypes, DynamicFieldTypes, SelectInputTypes } from '@mt-ng2/dynamic-form';
import { PhysicianDynamicControls, IPhysicianDynamicControlsParameters } from '../form-controls/physician.form-controls';

export class PhysicianDynamicControlsPartial extends PhysicianDynamicControls {
    constructor(physicianPartial?: IPhysician, additionalParameters?: IPhysicianDynamicControlsParameters, canManageFees?: boolean) {
        super(physicianPartial, additionalParameters);

        (<DynamicField>this.Form.SubspecialtyNotes).label = 'Specialty Notes';
        (<DynamicField>this.Form.SubspecialtyNotes).labelHtml = '<label>Specialty Notes</label>';
        (<DynamicLabel>this.View.SubspecialtyNotes).label = 'Specialty Notes';

        (<DynamicField>this.Form.Auto).label = 'No Fault / PIP';
        (<DynamicField>this.Form.Auto).labelHtml = '<label>No Fault / PIP</label>';
        (<DynamicLabel>this.View.Auto).label = 'No Fault / PIP';
    }
}
