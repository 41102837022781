import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { IDynamicField, emailEmptyAcceptedValidator } from '@mt-ng2/dynamic-form';
import { EmailAddressDynamicConfig } from '@model/shared-entities/email-addresses/email-address.dynamic-config';
import { emptyEmailAddress } from '@model/shared-entities/email-addresses/email-address.service';
import { IEmailType } from '@model/interfaces/email-type';
import { EmailTypeService } from '@model/shared-entities/email-type/emailtype.service';
import { safeDetectChanges } from '../../common/cdr-safety/cdr-safety.library';
import { EmailTypes } from '../../common/constants/Enums';

@Component({
    selector: 'app-imed-claim-primary-email',
    templateUrl: './primary-email.component.html',
})
export class IMedClaimPrimaryEmailComponent implements OnInit {
    @Input() form: UntypedFormGroup;
    @Input() overrideFormGroup = 'EmailAddress';

    private _required = true;
    @Input('required')
    get required(): boolean {
        return this._required;
    }
    set required(value: boolean) {
        this._required = value ? value : false;
    }

    emailConfig: IDynamicField[];

    constructor(private emailTypeService: EmailTypeService, private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        forkJoin(this.emailTypeService.getItems()).subscribe((forkJoinResponses) => {
            const [emailTypes] = forkJoinResponses;
            // Apply a spread operator to clone the emailTypes object so that we manipulate a copy on line instead of
            // changing the static value in the service (use this wherever you need to change names of email types)
            this.setEmailConfig(emailTypes.map((x) => ({ ...x })).filter((x) => x.Id !== +EmailTypes.Report && x.Id !== +EmailTypes.IME_Billing && x.Id !== +EmailTypes.Retrieval_Billing));
            safeDetectChanges(this.cdr);
        });
    }

    setEmailConfig(emailTypes: IEmailType[]): void {
        const emailControls = ['EmailTypeId', 'Email', 'Notes'];
        emailTypes.find((x) => x.Id === +EmailTypes.Office).Name = 'Claimant Email';
        const emailConfig = new EmailAddressDynamicConfig(emptyEmailAddress, emailTypes, emailControls);
        const formObject = emailConfig.getForCreate().formObject;
        formObject.forEach((field) => {
            (field ).formGroup = this.overrideFormGroup;
            if (field.name === 'Email') {
                (field ).validation = [emailEmptyAcceptedValidator];
                (field ).validators.email = true;
            }
            (field ).setRequired(this.required);
        });
        this.emailConfig = formObject;
    }
}
