import { Directive, Input, HostListener, OnInit, ComponentRef, ElementRef } from '@angular/core';
import { OverlayRef, Overlay, OverlayPositionBuilder } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { AddressesTooltipComponent } from './addresses-tooltip.component';
import { AddressBookInfoTooltipComponent } from './addressbook-info-tooltip.component';
import { Subscription } from 'rxjs';
import { TooltipService } from './tooltip.service';
import { ServiceTooltipComponent } from './services-tooltip.component';

@Directive({ selector: '[tooltip]' })
export class TooltipDirective implements OnInit {
    @Input('tooltipType') tooltipType;
    @Input('entity') entity;

    private overlayRef: OverlayRef;
    private subscriptions: Subscription = new Subscription();

    constructor(
        private overlay: Overlay,
        private overlayPositionBuilder: OverlayPositionBuilder,
        private elementRef: ElementRef,
        private tooltipService: TooltipService,
    ) {}

    ngOnInit(): void {
        const positionStrategy = this.overlayPositionBuilder.flexibleConnectedTo(this.elementRef).withPositions([
            {
                originX: 'center',
                originY: 'top',
                overlayX: 'center',
                overlayY: 'bottom',
            },
        ]);
        this.overlayRef = this.overlay.create({ positionStrategy, disposeOnNavigation: true });
    }

    ngAfterViewInit(): void {
        this.subscriptions.add(this.tooltipService.destroyTooltips.subscribe(() => this.hide()));
    }

    ngOnDestroy(): void {
        if (this.overlayRef.hasAttached()) {
            this.subscriptions.unsubscribe();
            this.hide();
        }
    }

    @HostListener('click')
    show(): void {
        if (!this.overlayRef.hasAttached()) {
            this.tooltipService.emitDestroyTooltips();
            if (this.tooltipType === 'address') {
                const tooltipPortal = new ComponentPortal(AddressesTooltipComponent);
                const tooltipRef: ComponentRef<AddressesTooltipComponent> = this.overlayRef.attach(tooltipPortal);
                tooltipRef.instance.addressBook = this.entity;
            }
            if (this.tooltipType === 'phone') {
                const tooltipPortal = new ComponentPortal(AddressBookInfoTooltipComponent);
                const tooltipRef: ComponentRef<AddressBookInfoTooltipComponent> = this.overlayRef.attach(tooltipPortal);
                tooltipRef.instance.addressBookToolTipEntity = this.entity;
            }
            if (this.tooltipType === 'service-parent') {
                const tooltipPortal = new ComponentPortal(ServiceTooltipComponent);
                const tooltipRef: ComponentRef<ServiceTooltipComponent> = this.overlayRef.attach(tooltipPortal);
                tooltipRef.instance.imedClaimService = this.entity;
                tooltipRef.instance.forParent = true;
            }
            if (this.tooltipType === 'service-children') {
                const tooltipPortal = new ComponentPortal(ServiceTooltipComponent);
                const tooltipRef: ComponentRef<ServiceTooltipComponent> = this.overlayRef.attach(tooltipPortal);
                tooltipRef.instance.imedClaimService = this.entity;
                tooltipRef.instance.forParent = false;
            }
        } else {
            this.hide();
        }
    }

    @HostListener('body:click')
    hide(): void {
        this.overlayRef.detach();
    }
}
