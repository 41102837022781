import { ChangeDetectorRef, Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { AuthUserDynamicControls } from '@model/form-controls/auth-user.form-controls';
import { EmailAddressDynamicControls } from '@model/form-controls/email-address.form-controls';
import { UserRoleService } from '../../../user-roles/user-role.service';
import { UserTypeValues } from '../../../common/constants/user-type.enum';
import { IUserRole } from '@model/interfaces/user-role';
import { IndividualService } from '../individual.service';
import { UserService } from '../../../users/user.service';
import { IUser } from '@model/interfaces/user';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, SelectInputTypes } from '@mt-ng2/dynamic-form';
import { IEmailAddress } from '@model/interfaces/email-address';
import { IMetaItem } from '@mt-ng2/base-service';
import { IExternalPortalUserPayload } from '@model/interfaces/custom/external-portal-user-payload';
import { IIndividual } from '@model/interfaces/individual';
import { finalize } from 'rxjs/operators';
import { safeDetectChanges } from '../../../common/cdr-safety/cdr-safety.library';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { trimObjectStringProperties } from '../../../common/custom/trimObjectStringProperties';
import { IAuthUser } from '@model/interfaces/auth-user';
import { ClaimValues, ClaimsService } from '@mt-ng2/auth-module';
import { ClaimTypes } from '@model/ClaimTypes';
import { AddressBookTypeIdEnums } from '@common/constants/Enums';
import { IExpandableObject } from '@model/expandable-object';

@Component({
    selector: 'app-external-user',
    templateUrl: './external-user.component.html',
})
export class ExternalUserComponent implements OnInit {
    // abstract controls
    abstractAuthUserControls: IExpandableObject;
    abstractEmailAddressControls: any;
    emailDropdown: DynamicField;

    externalUserForm: UntypedFormGroup;
    doubleClickIsDisabled = false;
    formCreated = false;

    userRoles: IUserRole[] = [];

    _individualEmails: IEmailAddress[];
    get individualEmails(): IEmailAddress[] {
        return this._individualEmails;
    }

    @Input()
    set individualEmails(v: IEmailAddress[]) {
        this._individualEmails = v;
        this.makeForm();
    }

    emailOptions: IMetaItem[] = [];

    @Input() Individual: IIndividual;
    @Input() canEdit: boolean;

    showDropdown: boolean;
    isEditing = false;
    isHovered = false;
    viewValues: IExternalPortalUserPayload | { Email: string; RoleId: number; Username: string; UserRoleName: string; UserTypeId: number };
    viewRendered: boolean;
    externalUser: IUser;
    authUser: IAuthUser;
    canAddClientToClientPortal: boolean;
    canAddPhysicianExpertToPhysicianExpertPortal: boolean;
    canRevokeAccess: boolean;
    canGrantAccess: boolean;
    userType = UserTypeValues.Client;
    canLoginAsExternalUser: boolean;

    _currentDropdownIndex: number;
    public get currentDropdownIndex(): number {
        return this._currentDropdownIndex;
    }
    public set currentDropdownIndex(v: number) {
        this._currentDropdownIndex = v;
        this.showDropdown = v > 0;
    }

    constructor(
        private fb: UntypedFormBuilder,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private userService: UserService,
        private userRoleService: UserRoleService,
        private claimsService: ClaimsService,
    ) {}

    ngOnInit(): void {
        let userType = UserTypeValues.Client;
        if ([AddressBookTypeIdEnums.Physician_panel, AddressBookTypeIdEnums.Expert_panel].includes(this.Individual.AddressBook.AddressBookTypeId)) {
            userType = UserTypeValues.PhysicianExpertPortal;
        }
        this.canAddClientToClientPortal = this.claimsService.hasClaim(ClaimTypes.AddClientToClientPortal, [ClaimValues.FullAccess]);
        this.canAddPhysicianExpertToPhysicianExpertPortal =
            userType === UserTypeValues.PhysicianExpertPortal &&
            this.claimsService.hasClaim(ClaimTypes.AddPhysicianExpertToPhysicianExpertPortal, [ClaimValues.FullAccess]);

        if (userType === UserTypeValues.PhysicianExpertPortal) {
            this.canGrantAccess = this.claimsService.hasClaim(ClaimTypes.AddPhysicianExpertToPhysicianExpertPortal, [ClaimValues.FullAccess]);
            this.canRevokeAccess = this.claimsService.hasClaim(ClaimTypes.RevokePhysicianExpertPortalAccess, [ClaimValues.FullAccess]);
            this.canLoginAsExternalUser = this.claimsService.hasClaim(ClaimTypes.LoginAsPhysicianExpert, [ClaimValues.FullAccess]);
            this.canEdit = this.claimsService.hasClaim(ClaimTypes.AddressBooks, [ClaimValues.FullAccess]); //override can edit for physicians and experts individual profiles
        } else {
            this.canGrantAccess = this.claimsService.hasClaim(ClaimTypes.AddClientToClientPortal, [ClaimValues.FullAccess]);
            this.canRevokeAccess = this.claimsService.hasClaim(ClaimTypes.RevokeClientAccess, [ClaimValues.FullAccess]);
            this.canLoginAsExternalUser = this.claimsService.hasClaim(ClaimTypes.LoginAsClient, [ClaimValues.FullAccess]);
        }
        this.userType = userType;

        this.fetchInitialData();
    }

    fetchInitialData(): void {
        forkJoin(this.userRoleService.getRolesByType(this.userType), this.userService.getByIndividualId(this.Individual.Id)).subscribe((answers) => {
            const [userRoles, user] = answers;
            this.userRoles = userRoles;
            this.externalUser = user;
            this.authUser = user ? user.AuthUser : null;
            this.makeForm();
        });
    }

    makeForm(): void {
        this.formCreated = false;
        this.getControls();
        this.getViews();
        this.externalUserForm = this.assignFormGroups();
        this.formCreated = true;
        safeDetectChanges(this.cdr);
    }

    getViews(): void {
        this.viewValues = {
            Email: (this.externalUser && this.externalUser.Email) || '',
            RoleId: (this.externalUser && this.externalUser.AuthUser && this.externalUser.AuthUser.RoleId) || 0,
            Username: (this.externalUser && this.externalUser.AuthUser && this.externalUser.AuthUser.Username) || '',
            UserRoleName:
                (this.externalUser &&
                    this.externalUser.AuthUser &&
                    this.externalUser.AuthUser.UserRole &&
                    this.externalUser.AuthUser.UserRole.Name) ||
                '',
            UserTypeId: UserTypeValues.Client,
        };
        this.viewRendered = true;
    }

    getControls(): void {
        const authUser = (this.externalUser && this.externalUser.AuthUser) || null;
        this.abstractAuthUserControls = new AuthUserDynamicControls(authUser, {
            formGroup: 'AuthUser',
            roles: this.userRoles,
        }).Form;

        const email = {
            Email: this.handleEmailOptions(),
        } as IEmailAddress;
        if (this.externalUser && this.externalUser.Email) {
            email.Email = this.externalUser.Email;
        }
        this.abstractEmailAddressControls = new EmailAddressDynamicControls(email, {
            formGroup: 'EmailAddress',
            // tslint:disable-next-line: object-literal-sort-keys
            createdBies: null,
            emailTypes: null,
            modifiedBies: null,
        }).Form;
        (<DynamicField>this.abstractEmailAddressControls.Email).setRequired(false);
        this.createDropdown();
    }

    handleEmailOptions(): string {
        this.emailOptions = this.individualEmails.map((emailAddress) => {
            return { Id: emailAddress.Id, Name: emailAddress.Email } as IMetaItem;
        });
        let currentEmailAddress = '';
        this.currentDropdownIndex = 0;
        if (this.individualEmails.length > 0) {
            let curItem = this.individualEmails.find((emailAddress) => emailAddress.IsPrimary);
            if (!curItem) {
                curItem = this.individualEmails[0];
            }
            this.currentDropdownIndex = curItem.Id;
            currentEmailAddress = curItem.Email;
        }
        if (this.externalUser !== null) {
            this.currentDropdownIndex = 0;
        }
        return currentEmailAddress;
    }

    createDropdown(): void {
        this.emailDropdown = new DynamicField({
            formGroup: this.abstractEmailAddressControls.formGroup,
            label: 'Existing Email',
            name: 'ExistingEmail',
            options: this.emailOptions,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.Dropdown,
            }),
            value: this.currentDropdownIndex,
        });
    }

    assignFormGroups(): UntypedFormGroup {
        return this.fb.group({
            AuthUser: this.fb.group({}),
            EmailAddress: this.fb.group({}),
        });
    }

    onDropdownValueChanged(newIndex: any): void {
        const emailValue = this.emailOptions.find((item) => item.Id === newIndex).Name;
        if (this.abstractEmailAddressControls && Object.prototype.hasOwnProperty.call(this.abstractEmailAddressControls, 'Email')) {
            this.externalUserForm.patchValue({
                EmailAddress: {
                    Email: emailValue,
                },
            });
        }
    }

    loginAsExternalUser(event: Event): void {
        if (event && event.stopPropagation) {
            event.stopPropagation();
        }

        this.userService.requestClientAccess(this.externalUser.Id).subscribe((url) => window.open(url));
    }

    resendCredentialsEmail(event: Event): void {
        if (event && event.stopPropagation) {
            event.stopPropagation();
        }

        this.userService.resendCredentialsEmail(this.Individual.Id).subscribe((success) => {
            this.notificationsService.success('Credential email resent!');
        });
    }

    formSubmitted(): void {
        if (this.externalUserForm.valid) {
            trimObjectStringProperties(this.Individual);
            const dto: IExternalPortalUserPayload = {
                Email: this.externalUserForm.get('EmailAddress.Email').value,
                FirstName: this.Individual.FirstName,
                LastName: this.Individual.LastName,
                RoleId: this.externalUserForm.get('AuthUser.RoleId').value,
                TwoFactorEnabled: true,
                UserId: (this.externalUser && this.externalUser.Id) || 0,
                Username: this.externalUserForm.get('AuthUser.Username').value,
                UserTypeId: this.userType,
            };
            this.saveForm(dto);
        } else {
            markAllFormFieldsAsTouched(this.externalUserForm);
            this.error();
        }
    }

    saveForm(dto: IExternalPortalUserPayload): void {
        if (this.externalUser === null) {
            this.userService.createExternalUser(this.Individual.Id, dto).subscribe((resultingUser) => {
                this.success(resultingUser, 'created');
            });
        } else {
            this.userService.updateExternalUserProfile(dto.UserId, dto).subscribe((resultingUser) => {
                this.success(resultingUser, 'updated');
            });
        }
    }

    cancelClick(): void {
        this.isEditing = false;
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    error(): void {
        this.notificationsService.error('Save failed.  Please check the form and try again.');
    }

    success(resultingUser: IUser, type: 'created' | 'updated'): void {
        this.externalUser = resultingUser;
        this.notificationsService.success(`External User ${type} successfully.`);
        this.isEditing = false;
        this.fetchInitialData();
    }
}
