import { ImedClaimServiceLabelGenerator } from './../imed-claim-service-label-generator';
import { IImedClaimService } from '@model/partials/imed-claim-service.partial.d';
import { IAddress } from '@model/interfaces/address.d';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription, Observable, forkJoin, of } from 'rxjs';
import { ImedClaimService } from '../imedclaim.service';
import { ClaimsService, ClaimValues, AuthService } from '@mt-ng2/auth-module';
import { ClaimTypes } from '@model/ClaimTypes';
import { emptyIMedClaimContactsInfo } from '../../common/address-book-select-list/address-books-select.service';
import { IIMedClaimContactsInfo } from '../imed-claim-basic-info/imed-claim-contacts-info';
import { IImedClaimPartial } from '@model/partials/imed-claim-partial';
import { emptyAddress } from '../../address-books/addresses/address.service';
import { BaseAttachmentsService } from '../../common/attachments/base-attachments.service';
import { IImedClaim } from '@model/interfaces/imed-claim';
import { AttachmentServiceTypeEnums, AutomatedTemplateFaxEmailEntityTypeEnums, ClaimTypeEnums, ViewLogEntityTypeEnums } from '../../common/constants/Enums';
import { tap, catchError } from 'rxjs/operators';
import { ViewLogService } from '../../common/services/viewlog.service';
import { ImedClaimServiceService } from '../services/imedclaimservice.service';
import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { IViewGetServicesForList } from '@model/interfaces/custom/view-get-services-for-list';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { saveAs } from 'file-saver';
import { ImedClaimTypes } from '@model/ImedClaimTypes';
import { IMedClaimSharedEntities } from '../shared/imed-claim.shared-entities';
import { emptyEmailAddress } from '@model/shared-entities/email-addresses/email-address.service';
import { IEmailAddress } from '@model/interfaces/email-address';
import { IName, NameTypeEnum } from '@model/interfaces/custom/name';
import { IImedClaimSubpoenaDetail } from '@model/interfaces/imed-claim-subpoena-detail';
import { ImedClaimSubpoenaDetailService } from '../services/imed-claim-subpoena-detail.service';
import { ImedClaimCourtFeesService } from '../imed-claim-court-fees/imed-claim-court-fees.service';
import { CommunicationLogLabelGenerator } from '../services/common-components/communication-log/communication-log-label-generator';
import { ICommunicationLogResponse } from '@model/interfaces/custom/communication-request';
import { CommunicationLogService, IResendCommunicationConfig, ResendCommunicationAttachmentTypes } from '@common/services/communication-log.service';
import { ICommunicationLog } from '@model/interfaces/communication-log';
import { IEntity } from '@mt-ng2/base-service';
import { CommonFaxComponent } from '@common/common-fax/common-fax.component';
import { CommonEmailComponent } from '@common/common-email/common-email.component';
import { NullHostDirective } from '@common/directives/null-host.directive';
import { IImedClaimFollowup } from '@model/interfaces/imed-claim-followup';
import { ImedClaimFollowupService } from '../imedclaim-followup.service';
import { IFollowupsWithSpecialInformationDto } from '@model/interfaces/custom/followups-with-prelim-invoice-date-dto';
import { FollowupLabelGenerator } from '../imedclaim-followups/followupLabelGenerator';

@Component({
    selector: 'app-imed-claim-detail',
    templateUrl: './imed-claim-detail.component.html',
})
export class ImedClaimDetailComponent implements OnInit {
    imedClaim: IImedClaimPartial;
    hasSubpoenaService = false;
    editingComponent: Subject<any> = new Subject();
    canEdit: boolean;
    canAdd: boolean;
    id: number;
    addressBookType = '';
    imedClaimContacts: IIMedClaimContactsInfo;
    searching = false;
    adding = false;
    subscriptions: Subscription = new Subscription();
    imedClaimAddress: IAddress;
    imedClaimEmailAddress: IEmailAddress;
    serviceCardName = 'Services';
    serviceLabelGenerator = new ImedClaimServiceLabelGenerator();
    services: IViewGetServicesForList[];
    attachmentServiceTypeEnum: AttachmentServiceTypeEnums = AttachmentServiceTypeEnums.Case;
    searchNameParameter: string;
    showATPopup = false;
    showAllSubpoenasPopup = false;
    emailSharedEntityId = IMedClaimSharedEntities.EmailAddresses;
    phoneNumberSharedEntityId = IMedClaimSharedEntities.PhoneNumbers;
    imedClaimSubpoenaDetail: IImedClaimSubpoenaDetail;
    formCreated: boolean;
    claimTypeEnums = ClaimTypeEnums;
    entityTypeEnums = AutomatedTemplateFaxEmailEntityTypeEnums;

    communicationLogCardName = 'Communication Logs';
    communicationLogLabelGenerator: CommunicationLogLabelGenerator;
    communicationLogs: ICommunicationLogResponse[];
    communicationLogsCount: number;

    //#region Followups

    followupsCardName = 'Follow-ups';
    followupsLabelGenerator = new FollowupLabelGenerator();
    followups: IImedClaimFollowup[];
    followUpCount: number;
    specialFollowupText: string;
    isDisabledImedClaim: boolean = false;
    //#endregion
    @ViewChild(NullHostDirective) commonEmailHost: NullHostDirective;

    constructor(
        private imedClaimService: ImedClaimService,
        private claimsService: ClaimsService,
        private authService: AuthService,
        private route: ActivatedRoute,
        private router: Router,
        private attachmentsService: BaseAttachmentsService,
        private courtFeeService: ImedClaimCourtFeesService,
        private viewLogService: ViewLogService,
        private imedClaimServiceService: ImedClaimServiceService,
        private notificationsService: NotificationsService,
        private subpoenaDetailService: ImedClaimSubpoenaDetailService,
        private commonCommunicationLogService: CommunicationLogService,
        private imedClaimFollowupService: ImedClaimFollowupService,
        private commonRouter: Router,
    ) {}

    ngOnInit(): void {
        this.imedClaimAddress = { ...emptyAddress };
        this.imedClaimEmailAddress = { ...emptyEmailAddress };
        // check claims
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.CaseManagement, [ClaimValues.FullAccess]);
        this.canAdd = this.canEdit;
        // get current id from route
        this.id = +this.route.snapshot.paramMap.get('imedClaimId');
        // try load if id > 0
        this.imedClaimContacts = { ...emptyIMedClaimContactsInfo };
        if (this.id > 0) {
            forkJoin(this.getImedClaimById(), this.getImedClaimServices(), this.setCourtFees(), this.getFollowups()).subscribe(() => {
                if (!Object.prototype.hasOwnProperty.call(this.route.snapshot.params, 'serviceId')) {
                    this.createViewLog();
                }
            });
        } else {
            // set imedClaim to emptyImedClaim
            this.imedClaim = this.imedClaimService.getEmptyImedClaim();
            const imedClaimType = +this.route.snapshot.paramMap.get('imedClaimTypeId');
            if (imedClaimType > 0) {
                this.imedClaim.ClaimTypeId = imedClaimType;
            }

            this.subscriptions.add(
                this.route.params.subscribe((routeParams) => {
                    if (this.imedClaim.ClaimTypeId !== routeParams.imedClaimTypeId && this.formCreated) {
                        location.reload();
                    }
                    this.formCreated = true;
                }),
            );
        }
        this.editingComponent.next('');
        this.communicationLogLabelGenerator = new CommunicationLogLabelGenerator();
        if (this.id > 0) {
            this.getImedClaimById();
            forkJoin([ this.getCommunicationLog()]).subscribe(([answer]) => {
                this.subscribeToCommunicationLogs();
            });
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    createViewLog(): void {
        const log = this.viewLogService.getEmptyViewLog();
        log.EntityType = ViewLogEntityTypeEnums.Case;
        log.Route = this.router.url;
        log.UserId = this.authService.currentUser.getValue().Id;
        log.View = 'Claimant: ' + this.imedClaim.FirstName + ' ' + this.imedClaim.LastName;
        this.viewLogService
            .create(log)
            .pipe(
                // Catch error and return empty observable in case max int size is reached in ViewLog table
                catchError((err) => of([])),
            )
            .subscribe();
    }

    getImedClaimById(): Observable<IImedClaim> {
        return this.imedClaimService.getById(this.id).pipe(
            tap((imedClaim) => {
                this.imedClaim = imedClaim;
                this.isDisabledImedClaim = this.imedClaim.IsDisabled;
                this.imedClaimSubpoenaDetail = this.imedClaim.ImedClaimSubpoenaDetails[0] ? this.imedClaim.ImedClaimSubpoenaDetails[0] : this.subpoenaDetailService.getEmptyImedClaimSubpoenaDetail();
                if (imedClaim.Addresses.length > 0) {
                    this.imedClaimAddress = this.imedClaim.Addresses[0];
                }
                if (imedClaim.EmailAddresses.length > 0) {
                    this.imedClaimEmailAddress = this.imedClaim.EmailAddresses[0];
                }
                Object.assign(this.imedClaimContacts, this.imedClaim);
                this.imedClaimService.assignClaimToContacts(this.imedClaimContacts, this.imedClaim);
            }),
        );
    }

    setCourtFees(): Observable<boolean> {
       return this.courtFeeService.hasSubpoenaService(this.id).pipe(
            tap((hasSubpoenaService) => {
                 this.hasSubpoenaService = hasSubpoenaService;
            }),
        );
    }

    getImedClaimServices(): Observable<IViewGetServicesForList[]> {
        const _extraSearchParams: ExtraSearchParams[] = [];
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'imedClaimId',
                value: this.id.toString(),
            }),
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'includeArchived',
                value: '0',
            }),
        );

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: 'Id',
            orderDirection: 'asc',
            query: '',
            skip: 0,
            take: 10,
        };

        const searchparams = new SearchParams(searchEntity);
        return this.imedClaimServiceService
            .searchImedClaimServices(this.id, searchparams)
            .pipe(tap(({ body: services }) => (this.services = services)));
    }

    seeAllServices(): void {
        void this.router.navigate([`cases/${this.id}/services/`]);
    }

    selectService(event: IImedClaimService): void {
        void this.router.navigate([`cases/${this.id}/services/${event.Id}`]);
    }

    closeAddressBookAction(): void {
        this.searching = false;
        this.adding = false;
    }

    downloadSummarySheet(): void {
        this.imedClaimService.downloadSummarySheet('admin-client-summary-sheet', this.imedClaim.Id).subscribe(
            (data) => {
                const thefile = new Blob([data.body as BlobPart], { type: 'application/octet-stream' });
                const fileName = data.headers.get('X-File-Name');
                saveAs(thefile, fileName);
            },
            (e) => {
                this.notificationsService.error('Document Download failed.');
            },
        );
    }

    isGeneralLiabilityClaim(): boolean {
        return this.imedClaim.ClaimTypeId === +ImedClaimTypes.GeneralLiability;
    }

    isAutoOrWorkersCompensationClaim(): boolean {
        return this.imedClaim.ClaimTypeId === +ImedClaimTypes.Auto || this.imedClaim.ClaimTypeId === +ImedClaimTypes.WorkersCompensation;
    }

    toggleAutomatedTemplateModal(_): void {
        this.showATPopup = !this.showATPopup;
    }

    toggleAllSubpoenasTemplateModal(_): void {
        this.showAllSubpoenasPopup = !this.showAllSubpoenasPopup;
    }

    getClaimantName(): IName {
        return { firstName: this.imedClaim.FirstName, lastName: this.imedClaim.LastName, nameType: NameTypeEnum.Individual };
    }

    refreshDocuments(): void {
        this.attachmentsService.requestRefresh();
        setTimeout(() => {
            this.showATPopup = false;
            this.showAllSubpoenasPopup = false;
        });
    }

    loadResendComponent(e: IEntity): void {
        const response = e as ICommunicationLogResponse;
        const data: IResendCommunicationConfig = {
            attachmentType: ResendCommunicationAttachmentTypes.CASE,
            component: response.Type === 'Fax' ? CommonFaxComponent : CommonEmailComponent,
            data: response,
        };
        this.commonCommunicationLogService.loadResendCommunicationComponent(this.commonEmailHost, data);
    }

    getCommunicationLog(): Observable<ICommunicationLog[]> {
        const _extraSearchParams: ExtraSearchParams[] = [];
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'imedClaimId',
                value: this.id.toString(),
            }),
        );
        const searchParams: SearchParams = new SearchParams({
            extraParams: _extraSearchParams,
            order: 'DateSent',
            orderDirection: 'desc',
            query: '',
            skip: 0,
            take: 3,
        });

        return this.commonCommunicationLogService.searchLogs(searchParams, 'imed-claim').pipe(
            tap((answer: any) => {
                this.communicationLogs = answer.body;
                this.communicationLogsCount = +answer.headers.get('X-List-Count');
            }),
        );
    }

    seeAllCommunicationLogs(): void {
        void this.router.navigate(['cases', this.id, 'communication-logs']);
    }


    subscribeToCommunicationLogs(): void {
        this.subscriptions.add(this.commonCommunicationLogService.emitLogChange.subscribe(() => this.getCommunicationLog().subscribe()));
    }


    //Followups
    getFollowups(): Observable<IImedClaimFollowup[]> {
        const searchParams: SearchParams = new SearchParams({
            extraParams: [
                new ExtraSearchParams({
                    name: 'includeArchived',
                    value: '0',
                }),
            ],
            order: 'ImedClaimFollowUpId',
            orderDirection: 'desc',
            query: '',
            skip: 0,
            take: 3,
        });

        return this.imedClaimFollowupService.search(searchParams, this.id).pipe(
            tap((answer: any) => {
                this.followups = answer.body;
                this.followUpCount = +answer.Count;
            }),
        );
    }

    seeAllFollowups(): void {
        void this.commonRouter.navigate([`cases/${this.id}/follow-ups/`]);
    }

    addFollowup(): void {
        void this.commonRouter.navigate([`cases/${this.id}/follow-ups/add`]);
    }



}
