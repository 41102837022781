import { Component, OnInit, ViewChild } from '@angular/core';
import { IEntityListDynamicCellComponent, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { IEntity } from '@mt-ng2/base-service';
import { AuthService } from '@mt-ng2/auth-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IModalOptions } from '@mt-ng2/modal-module';
import { ITaskDTO } from '@model/interfaces/custom/task-dto';
import {
    TaskStatusEnums,
    ServiceStatusIdEnums,
    SystemGeneratedEmailTemplateEnums,
    UserRoleIdEnums,
    AttachmentTypeEnums,
    FaxEmailEnum,
    FaxEmailEntityTypeEnums,
} from '../../common/constants/Enums';
import { EmailTemplateService } from '../../email-templates/emailtemplate.service';
import { CommonEmailComponent } from '../../common/common-email/common-email.component';
import { IAppFaxEmailComponentApi } from '../../common/fax-email/fax-email-component';
import { UserService } from '../../users/user.service';
import { TaskService } from '../task.service';
import { AssignedToTypes } from '../../../client-portal/common/constants/assigned-to-type.enum';
import { IUser } from '@model/interfaces/user';
import { forkJoin } from 'rxjs';

@Component({
    styles: [
        `
            .mr-3 {
                margin-right: 3px;
            }
            a {
                color: inherit;
                text-decoration: inherit;
            }
        `,
    ],
    templateUrl: './task-actions.component.html',
})
export class TaskActionsDynamicCellComponent implements IEntityListDynamicCellComponent, OnInit {
    @ViewChild('emailComponent') emailComponent: CommonEmailComponent;

    appFaxEmailComponentApi: IAppFaxEmailComponentApi;
    entityListComponentMembers: IEntityListComponentMembers;
    entityTypeEnums = FaxEmailEntityTypeEnums;
    currentUser: IUser;
    userRoleId: number;
    task: ITaskDTO;
    showEmailControl = false;

    set entity(value: IEntity) {
        this.task = value as ITaskDTO;
    }

    archiveConfirm: IModalOptions = {
        confirmButtonText: 'Yes, I am sure!',
        text: `Are you sure you want to delete this task?`,
        title: 'Delete Task',
        icon: 'question',
    };

    unarchiveConfirm: IModalOptions = {
        confirmButtonText: 'Yes, I am sure!',
        text: `Are you sure you want to restore this task?`,
        title: 'Restore Task',
        icon: 'question',
    };

    constructor(
        private emailTemplateService: EmailTemplateService,
        private userService: UserService,
        private authService: AuthService,
        private notificationService: NotificationsService,
        private taskService: TaskService,
    ) {}

    ngOnInit(): void {
        forkJoin(this.userService.getById(this.authService.currentUser.getValue().Id)).subscribe((responses) => {
            const [currentUser] = responses;

            this.currentUser = currentUser;
            this.userRoleId = currentUser.AuthUser.RoleId;
        });
    }

    archiveTask(event: Event): void {
        this.entityListComponentMembers.itemDeleted(this.task as IEntity, event);
    }

    get taskIsCompleted(): boolean {
        return this.task.TaskStatusId === +TaskStatusEnums.Completed || this.task.TaskStatusId === +TaskStatusEnums.CompletedBySystem;
    }

    completeTask(event: Event): void {
        event.stopPropagation();
        if (this.task.IsAccountingTask) {
            if (this.userRoleId !== +UserRoleIdEnums.Accounting && this.userRoleId !== +UserRoleIdEnums.Admin) {
                this.notificationService.error('Accounting Tasks can only be completed by Accounting or Admin');
                return;
            } else {
                this.task.TaskStatusId = TaskStatusEnums.Completed;
                this.taskService.completeTask(this.authService.currentUser.getValue().Id, this.task).subscribe(() => {
                    this.notificationService.success('Task Status Updated');
                    this.taskService.refreshTasks.emit();
                });
            }
        } else if (!this.taskService.canCompleteTask(this.task, this.userRoleId, this.currentUser)) {
            return;
        } else {
            this.task.TaskStatusId = TaskStatusEnums.Completed;
            this.taskService.completeTask(this.authService.currentUser.getValue().Id, this.task).subscribe(() => {
                this.notificationService.success('Task Status Updated');
                this.taskService.refreshTasks.emit();
            });
        }
    }

    get taskIsOfTypePendingScheduling(): boolean {
        return (
            this.task.ServiceStatusId === +ServiceStatusIdEnums.IME_Pending_Scheduling ||
            this.task.ServiceStatusId === +ServiceStatusIdEnums.IME_Re_Exam_Pending_Scheduling ||
            this.task.ServiceStatusId === +ServiceStatusIdEnums.IME_Expert_Consultation_Pending_Scheduling ||
            this.task.ServiceStatusId === +ServiceStatusIdEnums.IME_Conference_Call_Pending_Scheduling
        );
    }

    get showFaxButton(): boolean {
        return this.task.AttachmentTypeId === +AttachmentTypeEnums.Records_Retrieved;
    }

    openEmailPopUp(event: Event): void {
        event.stopPropagation();
        this.showEmailControl = true;
        this.emailTemplateService.getById(SystemGeneratedEmailTemplateEnums.IME_PENDING_SCHEDULING).subscribe((template) => {
            this.emailComponent.emailTemplates = [template];
            this.emailComponent.taskId = this.task.Id;
            this.emailComponent.imedClaimServiceId = this.task.ImedClaimServiceId;
            this.emailComponent.selectedTemplate = template;
            this.emailComponent.isMergingOrSending = false;
            this.emailComponent.showEmailForm();
        });
    }

    showFax(event: Event): void {
        event.stopPropagation();
        this.appFaxEmailComponentApi.setSelectedType(FaxEmailEnum.FAX);
        this.appFaxEmailComponentApi.show();
    }
}
