import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { DynamicField, DynamicFieldType, SelectInputTypes, DynamicFieldTypes, IDynamicFieldType, IDynamicField } from '@mt-ng2/dynamic-form';
import { AbstractControl } from '@angular/forms';
import { AssociationsToggleService, AssociationTypeEnums } from '../services/associations-toggle.service';

@Component({
    selector: 'app-task-status-followup-engine-header',
    template: `
        <div>
            <h2>
                <mt-route-back-button></mt-route-back-button>
                {{ header }}
                <div *ngIf="showToggle" class="pull-right">
                    <mt-dynamic-field [field]="associationTypeToggle" (valueChanges)="selectionChanged($event)"></mt-dynamic-field>
                </div>
            </h2>
        </div>
        <router-outlet></router-outlet>
    `,
})
export class TaskStatusFollowupEngineHeaderComponent implements OnInit {
    header = '';
    routeSubscription: Subscription;
    associationTypeToggle: DynamicField;
    showToggle = false;
    buttons: AbstractControl;
    types = [
        { Id: 1, Name: 'Service Status Associations' },
        { Id: 2, Name: 'Followup Type Associations' },
        { Id: 3, Name: 'Followup Type Exemptions' },
        { Id: 4, Name: 'Email Associations' },
    ];
    associationTypeSubscription: Subscription;
    selectedAssociationType: AssociationTypeEnums = AssociationTypeEnums.SERVICE_STATUS;
    constructor(private route: ActivatedRoute, private associationsToggleService: AssociationsToggleService) {}

    ngOnInit(): void {
        this.routeSubscription = this.route.url.subscribe((route) => {
            if (route[1].path === 'add') {
                this.header = 'Add System Task';
            } else if (route[1].path === 'associations') {
                this.createAssociationTypeToggle();
                this.associationTypeSubscription = this.associationsToggleService.getAssociationType().subscribe((type) => {
                    this.selectedAssociationType = type;
                    this.setAssociationHeader();
                });
                this.showToggle = true;
            } else {
                this.header = 'System Tasks';
            }
        });
    }

    ngOnDestroy(): void {
        this.routeSubscription.unsubscribe();
        if (this.associationTypeSubscription) {
            this.associationTypeSubscription.unsubscribe();
        }
    }

    createAssociationTypeToggle(): void {
        this.associationTypeToggle = new DynamicField({
            formGroup: null,
            label: '',
            name: 'associationTypeToggle',
            options: this.types,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.RadioButtonList,
            } as IDynamicFieldType),
            value: this.selectedAssociationType,
        } as IDynamicField);
    }

    setAssociationHeader(): void {
        switch (this.selectedAssociationType) {
            case AssociationTypeEnums.SERVICE_STATUS:
                this.header = 'Service Associations - Service Statuses';
                break;
            case AssociationTypeEnums.FOLLOWUP_Type:
                this.header = 'Service Associations - Followup Types';
                break;
            case AssociationTypeEnums.FOLLOWUP_Type_Exemptions:
                this.header = 'Followup Types - Exemptions';
                break;
            case AssociationTypeEnums.EMAIL_ASSOCIATIONS:
                this.header = 'Email Template Associations';
                break;
            default:
        }
    }

    selectionChanged(event: AssociationTypeEnums): void {
        this.associationsToggleService.setAssociationType(event);
    }
}
