import { BaseService } from '@mt-ng2/base-service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/operators';
import { IEmailAddress } from '@model/interfaces/email-address';

@Injectable()
export class EmailService extends BaseService<IEmailAddress> {
    constructor(public http: HttpClient) {
        super('/emails', http);
    }
}
