// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            .form-select > dynamic-form-label {
                display: none;
            }
            .addressbook-row {
                display: flex;
                flex-direction: row;
            }
            .addressbook-row > div {
                flex: 0 0 80%;
            }
        `, "",{"version":3,"sources":["webpack://./src/app/automated-templates/automated-template-form/automated-template-form.component.ts"],"names":[],"mappings":";YACY;gBACI,aAAa;YACjB;YACA;gBACI,aAAa;gBACb,mBAAmB;YACvB;YACA;gBACI,aAAa;YACjB","sourcesContent":["\n            .form-select > dynamic-form-label {\n                display: none;\n            }\n            .addressbook-row {\n                display: flex;\n                flex-direction: row;\n            }\n            .addressbook-row > div {\n                flex: 0 0 80%;\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
