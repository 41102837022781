import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IEmailTemplate } from '@model/interfaces/email-template';
import { IPhysicianStatus } from '@model/interfaces/physician-status';
import { IGroupedEmailTemplatesByPhysicianStatusDTO } from '@model/interfaces/custom/IGroupedEmailTemplatesByPhysicianStatusDTO';

export interface IEmailTemplatePhysicianStatusAssociation {
    EmailTemplate: IEmailTemplate;
    PhysicianStatus: IPhysicianStatus;
}

@Component({
    selector: 'email-template-physician-status-row',
    styleUrls: ['../../row-styles.less'],
    templateUrl: './email-template-status-row.component.html',
})
export class EmailTemplatePhysicianStatusRowComponent implements OnInit {
    private _filteredEmailTemplates: IEmailTemplate[] = [];
    @Input('filteredEmailTemplates')
    set filteredEmailTemplates(val: IEmailTemplate[]) {
        this._filteredEmailTemplates = [...val];
    }
    get filteredEmailTemplates(): IEmailTemplate[] {
        return this._filteredEmailTemplates;
    }
    @Input() filteredPhysicianStatuses: IPhysicianStatus[];
    @Input() groupedEmailTemplates: IGroupedEmailTemplatesByPhysicianStatusDTO;
    @Input() className = '';
    @Output() onTemplateSelected: EventEmitter<IEmailTemplatePhysicianStatusAssociation> = new EventEmitter();
    @Output() onTemplateRemoved: EventEmitter<IEmailTemplatePhysicianStatusAssociation> = new EventEmitter();

    selectedEmailTemplate: IEmailTemplate;

    constructor() {}

    ngOnInit(): void {
        this.filteredEmailTemplates = this.filteredEmailTemplates.filter(
            (x) => !this.groupedEmailTemplates.EmailTemplates.some((y) => y.Id === x.Id),
        );
        if (this.groupedEmailTemplates.PhysicianStatus) {
            this.filteredPhysicianStatuses = this.filteredPhysicianStatuses.filter((x) => !(this.groupedEmailTemplates.PhysicianStatus.Id === x.Id));
        }
    }

    emailTemplateSelected(): void {
        this.filteredEmailTemplates = this.filteredEmailTemplates.filter((x) => x.Id !== this.selectedEmailTemplate.Id);
        this.groupedEmailTemplates.EmailTemplates.push(this.selectedEmailTemplate);
        this.onTemplateSelected.emit({
            EmailTemplate: this.selectedEmailTemplate,
            PhysicianStatus: this.groupedEmailTemplates.PhysicianStatus,
        } as IEmailTemplatePhysicianStatusAssociation);
        this.selectedEmailTemplate = null;
    }

    emailTemplateRemoved(index: number, emailTemplate: IEmailTemplate): void {
        this.groupedEmailTemplates.EmailTemplates.splice(index, 1);
        this.filteredEmailTemplates.push(emailTemplate);
        this.onTemplateRemoved.emit({
            EmailTemplate: emailTemplate,
            PhysicianStatus: this.groupedEmailTemplates.PhysicianStatus,
        } as IEmailTemplatePhysicianStatusAssociation);
    }
}
