import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IFollowupType } from '@model/interfaces/followup-type';
import { Observable } from 'rxjs';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { map } from 'rxjs/operators';
import { IMasterFollowupType } from '@model/interfaces/master-followup-type';

@Injectable()
export class FollowupTypeService extends StaticMetaItemService<IFollowupType> {
    constructor(public http: HttpClient) {
        super('FollowupTypeService', 'Type', 'TypeIds', '/followuptypes', http);
    }
    getFollowupTypeSortedByName(): Observable<MtSearchFilterItem[]> {
        return this.http.get<IFollowupType[]>(`/followuptypes`, {}).pipe(
            map((followupType) => {
                return followupType
                    .sort((a, b) => (a.Name < b.Name ? -1 : 1))
                    .map((item) => {
                        return new MtSearchFilterItem(item, false);
                    });
            }),
        );
    }
}
