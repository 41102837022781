// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            .task-display {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 200px;
                max-width: 200px;
                display: inline-block;
                vertical-align: middle;
                font-size: 13px;
                cursor: pointer;
                margin-left: 6px;
            }
            .complete-task-button {
                float: right;
                margin-top: 3px;
                zoom: 1.3;
            }
            .btn-transparent:hover {
                background-color: transparent;
            }
            .marginBottom {
                margin-bottom: 3px;
            }
        `, "",{"version":3,"sources":["webpack://./src/app/nav/sidebar-task.component.ts"],"names":[],"mappings":";YACY;gBACI,gBAAgB;gBAChB,uBAAuB;gBACvB,mBAAmB;gBACnB,YAAY;gBACZ,gBAAgB;gBAChB,qBAAqB;gBACrB,sBAAsB;gBACtB,eAAe;gBACf,eAAe;gBACf,gBAAgB;YACpB;YACA;gBACI,YAAY;gBACZ,eAAe;gBACf,SAAS;YACb;YACA;gBACI,6BAA6B;YACjC;YACA;gBACI,kBAAkB;YACtB","sourcesContent":["\n            .task-display {\n                overflow: hidden;\n                text-overflow: ellipsis;\n                white-space: nowrap;\n                width: 200px;\n                max-width: 200px;\n                display: inline-block;\n                vertical-align: middle;\n                font-size: 13px;\n                cursor: pointer;\n                margin-left: 6px;\n            }\n            .complete-task-button {\n                float: right;\n                margin-top: 3px;\n                zoom: 1.3;\n            }\n            .btn-transparent:hover {\n                background-color: transparent;\n            }\n            .marginBottom {\n                margin-bottom: 3px;\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
