import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IEmailTemplate } from '../interfaces/email-template';
import { IUser } from '../interfaces/user';
import { IEmailTemplateType } from '../interfaces/email-template-type';

export interface IEmailTemplateDynamicControlsParameters {
    formGroup?: string;
    createdBies?: IUser[];
    modifiedBies?: IUser[];
    emailTemplateTypes?: IEmailTemplateType[];
}

export class EmailTemplateDynamicControls {

    formGroup: string;
    createdBies: IUser[];
    modifiedBies: IUser[];
    emailTemplateTypes: IEmailTemplateType[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private emailtemplate?: IEmailTemplate, additionalParameters?: IEmailTemplateDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'EmailTemplate';
        this.createdBies = additionalParameters && additionalParameters.createdBies || undefined;
        this.modifiedBies = additionalParameters && additionalParameters.modifiedBies || undefined;
        this.emailTemplateTypes = additionalParameters && additionalParameters.emailTemplateTypes || undefined;

        this.Form = {
            Archived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived',
                name: 'Archived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.emailtemplate && this.emailtemplate.hasOwnProperty('Archived') && this.emailtemplate.Archived !== null ? this.emailtemplate.Archived : false,
            }),
            Bcc: new DynamicField({
                formGroup: this.formGroup,
                label: 'Bcc',
                name: 'Bcc',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(500) ],
                validators: { 'maxlength': 500 },
                value: this.emailtemplate && this.emailtemplate.hasOwnProperty('Bcc') && this.emailtemplate.Bcc !== null ? this.emailtemplate.Bcc.toString() : '',
            }),
            Body: new DynamicField({
                formGroup: this.formGroup,
                label: 'Body',
                name: 'Body',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.emailtemplate && this.emailtemplate.hasOwnProperty('Body') && this.emailtemplate.Body !== null ? this.emailtemplate.Body.toString() : '',
            }),
            CanHaveAttachment: new DynamicField({
                formGroup: this.formGroup,
                label: 'Can Have Attachment',
                name: 'CanHaveAttachment',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.emailtemplate && this.emailtemplate.hasOwnProperty('CanHaveAttachment') && this.emailtemplate.CanHaveAttachment !== null ? this.emailtemplate.CanHaveAttachment : true,
            }),
            Cc: new DynamicField({
                formGroup: this.formGroup,
                label: 'Cc',
                name: 'Cc',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(500) ],
                validators: { 'maxlength': 500 },
                value: this.emailtemplate && this.emailtemplate.hasOwnProperty('Cc') && this.emailtemplate.Cc !== null ? this.emailtemplate.Cc.toString() : '',
            }),
            CreatedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Created By',
                name: 'CreatedById',
                options: this.createdBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.emailtemplate && this.emailtemplate.CreatedById || null,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.emailtemplate && this.emailtemplate.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.emailtemplate && this.emailtemplate.DateModified || null,
            }),
            EmailTemplateTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Email Template Type',
                name: 'EmailTemplateTypeId',
                options: this.emailTemplateTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.emailtemplate && this.emailtemplate.EmailTemplateTypeId || 1,
            }),
            IsAttachmentsEditable: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Attachments Editable',
                name: 'IsAttachmentsEditable',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.emailtemplate && this.emailtemplate.hasOwnProperty('IsAttachmentsEditable') && this.emailtemplate.IsAttachmentsEditable !== null ? this.emailtemplate.IsAttachmentsEditable : true,
            }),
            ModifiedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Modified By',
                name: 'ModifiedById',
                options: this.modifiedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.emailtemplate && this.emailtemplate.ModifiedById || null,
            }),
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(500) ],
                validators: { 'required': true, 'maxlength': 500 },
                value: this.emailtemplate && this.emailtemplate.hasOwnProperty('Name') && this.emailtemplate.Name !== null ? this.emailtemplate.Name.toString() : '',
            }),
            Subject: new DynamicField({
                formGroup: this.formGroup,
                label: 'Subject',
                name: 'Subject',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(500) ],
                validators: { 'required': true, 'maxlength': 500 },
                value: this.emailtemplate && this.emailtemplate.hasOwnProperty('Subject') && this.emailtemplate.Subject !== null ? this.emailtemplate.Subject.toString() : '',
            }),
            SystemGenerated: new DynamicField({
                formGroup: this.formGroup,
                label: 'System Generated',
                name: 'SystemGenerated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.emailtemplate && this.emailtemplate.hasOwnProperty('SystemGenerated') && this.emailtemplate.SystemGenerated !== null ? this.emailtemplate.SystemGenerated : false,
            }),
            To: new DynamicField({
                formGroup: this.formGroup,
                label: 'To',
                name: 'To',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(500) ],
                validators: { 'required': true, 'maxlength': 500 },
                value: this.emailtemplate && this.emailtemplate.hasOwnProperty('To') && this.emailtemplate.To !== null ? this.emailtemplate.To.toString() : '',
            }),
        };

        this.View = {
            Archived: new DynamicLabel({
			    label: 'Archived',
			    value: this.emailtemplate && this.emailtemplate.hasOwnProperty('Archived') && this.emailtemplate.Archived !== null ? this.emailtemplate.Archived : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Bcc: new DynamicLabel({
			    label: 'Bcc',
			    value: this.emailtemplate && this.emailtemplate.hasOwnProperty('Bcc') && this.emailtemplate.Bcc !== null ? this.emailtemplate.Bcc.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Body: new DynamicLabel({
			    label: 'Body',
			    value: this.emailtemplate && this.emailtemplate.hasOwnProperty('Body') && this.emailtemplate.Body !== null ? this.emailtemplate.Body.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Textarea,
			        scale: null,
			    })
			}
            ),
            CanHaveAttachment: new DynamicLabel({
			    label: 'Can Have Attachment',
			    value: this.emailtemplate && this.emailtemplate.hasOwnProperty('CanHaveAttachment') && this.emailtemplate.CanHaveAttachment !== null ? this.emailtemplate.CanHaveAttachment : true,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Cc: new DynamicLabel({
			    label: 'Cc',
			    value: this.emailtemplate && this.emailtemplate.hasOwnProperty('Cc') && this.emailtemplate.Cc !== null ? this.emailtemplate.Cc.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            CreatedById: new DynamicLabel({
			    label: 'Created By',
			    value: getMetaItemValue(this.createdBies as unknown as IMetaItem[], this.emailtemplate && this.emailtemplate.hasOwnProperty('CreatedById') && this.emailtemplate.CreatedById !== null ? this.emailtemplate.CreatedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            DateCreated: new DynamicLabel({
			    label: 'Date Created',
			    value: this.emailtemplate && this.emailtemplate.DateCreated || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DateModified: new DynamicLabel({
			    label: 'Date Modified',
			    value: this.emailtemplate && this.emailtemplate.DateModified || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            EmailTemplateTypeId: new DynamicLabel({
			    label: 'Email Template Type',
			    value: getMetaItemValue(this.emailTemplateTypes as unknown as IMetaItem[], this.emailtemplate && this.emailtemplate.hasOwnProperty('EmailTemplateTypeId') && this.emailtemplate.EmailTemplateTypeId !== null ? this.emailtemplate.EmailTemplateTypeId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            IsAttachmentsEditable: new DynamicLabel({
			    label: 'Is Attachments Editable',
			    value: this.emailtemplate && this.emailtemplate.hasOwnProperty('IsAttachmentsEditable') && this.emailtemplate.IsAttachmentsEditable !== null ? this.emailtemplate.IsAttachmentsEditable : true,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ModifiedById: new DynamicLabel({
			    label: 'Modified By',
			    value: getMetaItemValue(this.modifiedBies as unknown as IMetaItem[], this.emailtemplate && this.emailtemplate.hasOwnProperty('ModifiedById') && this.emailtemplate.ModifiedById !== null ? this.emailtemplate.ModifiedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Name: new DynamicLabel({
			    label: 'Name',
			    value: this.emailtemplate && this.emailtemplate.hasOwnProperty('Name') && this.emailtemplate.Name !== null ? this.emailtemplate.Name.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Subject: new DynamicLabel({
			    label: 'Subject',
			    value: this.emailtemplate && this.emailtemplate.hasOwnProperty('Subject') && this.emailtemplate.Subject !== null ? this.emailtemplate.Subject.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            SystemGenerated: new DynamicLabel({
			    label: 'System Generated',
			    value: this.emailtemplate && this.emailtemplate.hasOwnProperty('SystemGenerated') && this.emailtemplate.SystemGenerated !== null ? this.emailtemplate.SystemGenerated : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            To: new DynamicLabel({
			    label: 'To',
			    value: this.emailtemplate && this.emailtemplate.hasOwnProperty('To') && this.emailtemplate.To !== null ? this.emailtemplate.To.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };

    }
}
