import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';
import { IMasterServiceStatus } from '@model/interfaces/master-service-status';
import { IMasterServiceStatusDynamicControlsParametersPartial, MasterServiceStatusDynamicControlsPartial } from '@model/partials/master-service-status-partial.form-controls';

export class MasterServiceStatusDynamicConfig<T extends IMasterServiceStatus> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(
        private masterServiceStatus: T,
        private additionalParams?: IMasterServiceStatusDynamicControlsParametersPartial,
        private configControls?: string[],
    ) {
        super();

        const dynamicControls = new MasterServiceStatusDynamicControlsPartial(this.masterServiceStatus, this.additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['Name'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
