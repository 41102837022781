import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, IRoleGuarded, ClaimValues } from '@mt-ng2/auth-module';

import { ClaimTypes } from '@model/ClaimTypes';
import { MasterFollowUpTypesComponent } from './master-followup-types-list/master-followup-types.component';
import { MasterFollowUpTypeAddComponent } from './master-followup-types-add/master-followup-types-add.component';
import { MasterFollowupTypeDetailComponent } from './master-followup-type-detail/master-followup-type-detail.component';
import { MasterFollowupTypeHeaderComponent } from './master-followup-type-header/master-followup-type-header.component';
import { MasterFollowUpTypeService } from './services/master-followup-type.service';

const masterFollowUpTypeListRoleGuard: any = {
    claimType: ClaimTypes.MasterFollowUpTypes,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'MasterFollowUpTypes',
};

const masterFollowUpTypeEntityConfig = {
    claimType: ClaimTypes.MasterFollowUpTypes,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'followupTypeId',
    service: MasterFollowUpTypeService,
    title: 'Follow up Type Detail',
};

export const masterFollowUpTypePathSlugs = {
    add: 'add',
    root: 'follow-up-types',
};

export const masterFollowUpTypePaths = {
    masterFollowupTypeAdd: `${masterFollowUpTypePathSlugs.root}/${masterFollowUpTypePathSlugs.add}`,
    masterFollowUpTypes: masterFollowUpTypePathSlugs.root,
};

const masterFollowUpTypeAddRoleGuard: IRoleGuarded = {
    claimType: ClaimTypes.MasterFollowUpTypes,
    claimValues: [ClaimValues.FullAccess],
};

const masterFollowUpTypeRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: MasterFollowUpTypesComponent,
        data: masterFollowUpTypeListRoleGuard,
        path: masterFollowUpTypePaths.masterFollowUpTypes,
    },
    {
        canActivate: [AuthGuard],
        children: [{ component: MasterFollowUpTypeAddComponent, path: '', pathMatch: 'full' }],
        component: MasterFollowupTypeHeaderComponent,
        data: masterFollowUpTypeAddRoleGuard,
        path: masterFollowUpTypePaths.masterFollowupTypeAdd,
    },
    {
        canActivate: [AuthGuard],
        children: [{ component: MasterFollowupTypeDetailComponent, path: '', pathMatch: 'full' }],
        component: MasterFollowupTypeHeaderComponent,
        data: masterFollowUpTypeEntityConfig,
        path: `${masterFollowUpTypePaths.masterFollowUpTypes}/:${masterFollowUpTypeEntityConfig.entityIdParam}`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(masterFollowUpTypeRoutes)],
})
export class MasterFollowupTypeRoutingModule {}
