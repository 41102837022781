import { MetaAddressBookTypeIdEnums } from './../../../common/constants/Enums';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { IIndividual } from '@model/interfaces/individual';
import { IndividualService } from '../individual.service';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '@model/ClaimTypes';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-individual-header',
    styles: [
        `
            .back-button {
                padding-left: 4px;
                padding-right: 6px;
                padding-top: 3px;
                padding-bottom: 0;
                margin-top: -3px;
            }
            .back-button-description {
                margin-left: -8px;
            }
        `,
    ],
    templateUrl: './individual-header.component.html',
})
export class IndividualHeaderComponent implements OnInit, OnDestroy {
    individual: IIndividual;
    header: string;
    subscriptions: Subscription = new Subscription();
    isOfTypeIndividual = false;
    canSeeAuditLog = false;
    previousSiteTitle: string;

    imedClaimIdBackNavigation: string;
    showBackToCaseButton = false;
    showOtherBackButtons = true;
    constructor(
        private individualService: IndividualService,
        private route: ActivatedRoute,
        private claimsService: ClaimsService,
        private titleService: Title,
    ) {}

    ngOnInit(): void {
        this.previousSiteTitle = this.titleService.getTitle();
        this.canSeeAuditLog = this.claimsService.hasClaim(ClaimTypes.Audit, [ClaimValues.FullAccess, ClaimValues.ReadOnly]);
        this.subscriptions.add(
            this.individualService.changeEmitted$.subscribe((individual) => {
                this.setHeader(individual);
            }),
        );
        const id = +this.route.snapshot.paramMap.get('individualId');
        if (id > 0) {
            this.individualService.getById(id).subscribe((individual) => {
                this.setHeader(individual);
                this.isOfTypeIndividual = individual.AddressBook.MetaAddressBookTypeId === +MetaAddressBookTypeIdEnums.Individual;
                this.individual = individual;
                if (this.route.snapshot.queryParamMap.get('caseId')) {
                    this.imedClaimIdBackNavigation = this.route.snapshot.queryParamMap.get('caseId');
                    this.showOtherBackButtons = false;
                    if (this.individual.AddressBook.FacilityName === '') {
                        this.showBackToCaseButton = true;
                    }
                }
            });
        } else {
            this.header = 'Add Individual';
            this.individual = this.individualService.getEmptyIndividual();
        }
    }

    ngOnDestroy(): void {
        this.titleService.setTitle(this.previousSiteTitle);
        this.subscriptions.unsubscribe();
    }

    setHeader(individual: IIndividual): void {
        this.individual = individual;
        this.header = `Individual: ${individual.FirstName} ${individual.LastName} `;
        this.titleService.setTitle(`${individual.FirstName} ${individual.LastName} - Viewpoint`);
    }
}
