import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TrendingServiceChartEnums, DashboardTypeEnums } from '../common/constants/Enums';
import { IDashboardType } from '@model/interfaces/dashboard-type';
import { BehaviorSubject } from 'rxjs';

export interface ITrendingService {
    Month: string;
    Year: number;
    Count: number;
    MonthId: number;
}

export interface IActiveService {
    Count: number;
    Service: string;
    ServiceId: number;
    MonthId: number;
    Ids: number[];
}

export interface IProductivity {
    Count: number;
    UserRole: string;
    UserRoleId: number;
}

export interface INewService {
    Count: number;
    Yesterday: boolean;
    ThisWeek: boolean;
    Type: string;
}

export interface IRecentlyViewedCase {
    Route: string;
    View: string;
}

@Injectable()
export class DashboardService {
    private _userDashboardType: BehaviorSubject<DashboardTypeEnums> = new BehaviorSubject<DashboardTypeEnums>(DashboardTypeEnums.NoWidgets);

    constructor(public http: HttpClient) { }

    setUserDashboardType(value: DashboardTypeEnums): void {
        this._userDashboardType.next(value);
    }

    getUserDashboardtype(): Observable<DashboardTypeEnums> {
        return this._userDashboardType.asObservable();
    }

    dynamicColors(): string {
        const r = Math.floor(Math.random() * 255);
        const g = Math.floor(Math.random() * 255);
        const b = Math.floor(Math.random() * 255);
        return 'rgba(' + r + ',' + g + ',' + b + ', 0.5)';
    }

    poolColors(count: number): string[] {
        const pool = [];
        let i: number;
        for (i = 0; i < count; i++) {
            pool.push(this.dynamicColors());
        }
        return pool;
    }

    getStartDateWeek(): Date {
        return new Date().mtDate.toMoment().startOf('week').toDate();
    }

    getEndDateWeek(): Date {
        return new Date().mtDate.toMoment().endOf('week').toDate();
    }

    getActiveServices(startDate: string, endDate: string): Observable<IActiveService[]> {
        const dateRange = { startDate: startDate, endDate: endDate };
        return this.http.post<IActiveService[]>('/dashboard/active-service/', dateRange);
    }

    getNewService(): Observable<INewService[]> {
        return this.http.get<INewService[]>('/dashboard/new-service/');
    }

    getTrendingServices(trendingServiceTypeEnum: TrendingServiceChartEnums): Observable<ITrendingService[]> {
        return this.http.get<ITrendingService[]>(`/dashboard/trending-service/${trendingServiceTypeEnum}`);
    }

    getProductivity(): Observable<IProductivity[]> {
        return this.http.get<IProductivity[]>('/dashboard/productivity/');
    }

    getDashboardTypes(): Observable<IDashboardType[]> {
        return this.http.get<IDashboardType[]>('/dashboard/get-all/');
    }

    getRecentlyViewedCases(userId: number): Observable<IRecentlyViewedCase[]> {
        return this.http.get<IRecentlyViewedCase[]>(`/dashboard/recently-viewed-cases/${userId}`);
    }
}
