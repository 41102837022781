import { IImeAppointment } from '@model/interfaces/ime-appointment.d';
import { EntityListConfig, EntityListColumn, IEntityListColumn, IEntityListConfig } from '@mt-ng2/entity-list-module';
import { FaxEmailAppointmentWrapperComponent } from '../../../../../common/fax-email/fax-email-appointment-wrapper-component';
import * as moment from 'moment-timezone';
import { AutoImeAppointmentDeleteDynamicCellComponent } from './auto-ime-appointment-delete-cell.component';

export class AutoImeAppointmentEntityListConfig extends EntityListConfig {
    columns: IEntityListColumn[];

    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessorFunction: function (appt: IImeAppointment): string {
                        return moment.tz(appt.AppointmentDateTime, appt.Timezone).format('LLL');
                    },
                    name: 'Appointment Date/Time',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['Notes'],
                    bindToInnerHtml: true,
                    name: 'Notes',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (emaiLog: IImeAppointment): string {
                        return `${emaiLog.CreatedBy.FirstName} ${emaiLog.CreatedBy.LastName}`;
                    },
                    name: 'Created By',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    component: FaxEmailAppointmentWrapperComponent,
                    name: 'Document',
                    sort: { disableSort: true },
                    style: { width: 140 },
                }),
                new EntityListColumn({
                    component: AutoImeAppointmentDeleteDynamicCellComponent,
                    name: 'Delete',
                    sort: { disableSort: true },
                }),
            ],
            rowClass: (entity: IImeAppointment) => {
                return entity.Archived ? 'archived ' : '';
            },
        };
        super(listConfig);
    }
}
