import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IBillsForReview } from '@model/interfaces/bills-for-review';

@Component({
    selector: 'app-bills-for-review-list',
    templateUrl: './bills-for-review-list.component.html',
})
export class BillsForReviewListComponent {
    @Input() billsForReview: IBillsForReview[];
    @Input() canEdit: boolean;
    @Input() isEditing: boolean;

    @Output('onAddItem') onAddItem: EventEmitter<any> = new EventEmitter<any>();
    @Output('onDeleteItem') onDeleteItem: EventEmitter<any> = new EventEmitter<any>();
    @Output('onSelectItem') onSelectItem: EventEmitter<any> = new EventEmitter<any>();

    constructor() {}

    deleteItem(billForReview: IBillsForReview): void {
        this.onDeleteItem.emit(billForReview);
    }

    addItem(): void {
        this.onAddItem.emit();
    }

    selectItem(item: IBillsForReview): void {
        this.onSelectItem.emit(item);
    }
}
