import { FaxEmailWrapperComponent } from '../../common/fax-email/fax-email-wrapper-component';
import { EntityListConfig, EntityListColumn, IEntityListColumn, IEntityListConfig, EntityListDeleteColumn } from '@mt-ng2/entity-list-module';
import { IAddressBooksForList } from '@model/interfaces/address-books-for-list';
import { AddressBookDeleteDynamicCellComponent } from './address-book-delete-cell.component';
import { AddressBookAddressesDynamicCellComponent } from './address-book-addresses-cell.component';
import { abort } from 'process';
import { IAddressBookForList } from '@model/partials/address-books-for-list.partial';
import { ClaimTypeEnums } from '@common/constants/Enums';

export class AddressBooksEntityListConfig extends EntityListConfig {
    columns: IEntityListColumn[];

    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessorFunction: (ab: IAddressBooksForList) => {
                        return '<a href="#/address-books/' + ab.AddressBookId + '">' + ab.FacilityName + '</a>';
                    },
                    bindToInnerHtml: true,
                    fireOnColumnSelected: true,
                    name: 'Facility Name',
                }),
                new EntityListColumn({
                    accessors: ['Type'],
                    name: 'Facility Type',
                }),
                new EntityListColumn({
                    accessorFunction: function (ab: IAddressBooksForList): string {
                        if (ab.FirstName) {
                            const firstName = ab.FirstName ? ab.FirstName : '';
                            const lastName = ab.LastName ? ab.LastName : '';
                            const suffix = ab.Suffix ? ab.Suffix : '';
                            return '<a href="#/address-books/' + ab.AddressBookId + '/individuals/' + ab.IndividualId + '">' + lastName + ',' + firstName + ' ' + suffix + '</a>';
                        } else {
                            return '';
                        }
                    },
                    bindToInnerHtml: true,
                    fireOnColumnSelected: true,
                    name: 'Full Name',
                }),
                new EntityListColumn({
                    accessorFunction: function (addressBook: IAddressBooksForList): string {
                        return `${addressBook.Address1 ? addressBook.Address1 + ', ' : ''} ${addressBook.Address2 ? addressBook.Address2 + ', ' : ''
                            } ${addressBook.City ? addressBook.City + ', ' : ''} ${addressBook.StateCode ? addressBook.StateCode : ''}
                            ${addressBook.Zip ? addressBook.Zip : ''}`;
                    },
                    excludeFromView: true,
                    name: 'Address',
                    sort: { disableSort: true },
                    style: { width: 230 },
                }),
                new EntityListColumn({
                    component: AddressBookAddressesDynamicCellComponent,
                    excludeFromExport: true,
                    name: 'Address',
                    sort: { disableSort: true },
                    style: { width: 230 },
                }),
                new EntityListColumn({
                    accessorFunction: function (ab: IAddressBooksForList): string {
                        if (ab.CountyName) {
                            return `${ab.CountyName}`;
                        } else {
                            return '';
                        }
                    },
                    name: 'County',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    accessorFunction: function (ab: IAddressBooksForList): string {
                        if (ab.IndividualPhoneNumbers) {
                            return `${ab.IndividualPhoneNumbers}`;
                        } else if (ab.AddressBookPhoneNumbers) {
                            return `${ab.AddressBookPhoneNumbers}`;
                        } else {
                            return '';
                        }
                    },
                    name: 'Phone',
                    pipes: ['phone'],
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (ab: IAddressBooksForList): string {
                        if (ab.IndividualEmail) {
                            return `${ab.IndividualEmail}`;
                        } else if (ab.AddressBookEmail) {
                            return `${ab.AddressBookEmail}`;
                        } else {
                            return '';
                        }
                    },
                    name: 'Email',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (ab: IAddressBooksForList): string {
                        if (ab.Specilaities) {
                            return `${ab.Specilaities}`;
                        } else {
                            return '';
                        }
                    },
                    name: 'Specialty',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    accessorFunction: function (ab: IAddressBooksForList): string {
                        if (ab.SpecilaitiyDetails) {
                            return `${ab.SpecilaitiyDetails}`;
                        } else {
                            return '';
                        }
                    },
                    name: 'Specialty Details',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    accessorFunction: (ab: IAddressBooksForList) => ab.SubspecialtyNotes || '',
                    name: 'Specialty Note',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    accessorFunction: function (ab: IAddressBookForList): string {

                    ab.PhysicianStatus = '';
                    if (ab.PhysicianStatuses != null) {
                      ab.PhysicianStatuses.forEach ((ps) => {

                        const addCurrentPhysicianStatus =  ps.PhysicianStatusName + ' ' ;
                        const currentImedClaimId =  ps?.ImedClaimTypeId ;
                        const addWCStatus =  'WC - ' + addCurrentPhysicianStatus + '<br/>' ;
                        const addNFPStatus = 'NFP - ' + addCurrentPhysicianStatus + '<br/>' ;
                        const addGLStatus =  'GL - ' + addCurrentPhysicianStatus + '<br/>' ;

                        if ( currentImedClaimId === ClaimTypeEnums.GL) {
                            ab.PhysicianStatus != null ? ab.PhysicianStatus += addGLStatus : ab.PhysicianStatus = addGLStatus ;
                        } else if ( currentImedClaimId === ClaimTypeEnums.WC) {
                             ab.PhysicianStatus != null ? ab.PhysicianStatus += addWCStatus : ab.PhysicianStatus = addWCStatus ;
                        } else {
                            ab.PhysicianStatus != null ? ab.PhysicianStatus += addNFPStatus : ab.PhysicianStatus = addNFPStatus ;
                        }
                      });
                      return '<strong>' + ab.PhysicianStatus + '</strong>';
                    }
                    return ' ';
                    },
                    bindToInnerHtml: true,
                    name: 'Status',
                    sort: { disableSort: true },
                    style: { width: 200 },
                }),
                new EntityListColumn({
                    accessors: ['Wcb'],
                    name: 'WCB#',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    component: FaxEmailWrapperComponent,
                    excludeFromExport: true,
                    name: 'Document',
                    sort: { disableSort: true },
                    style: { width: 140 },
                }),
                new EntityListColumn({
                    component: AddressBookDeleteDynamicCellComponent,
                    excludeFromExport: true,
                    name: 'Delete',
                    sort: { disableSort: true },
                    style: { width: 50 },
                }),
            ],
            rowClass: (entity: IAddressBooksForList) => (entity.FacilityArchived || entity.IndividualArchived ? 'archived' : null),
        };
        super(listConfig);
    }
}
