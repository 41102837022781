// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            .tasks-header {
                vertical-align: sub;
            }
            .tasks-header .badge {
                zoom: 0.9;
                vertical-align: baseline;
            }
            .btn-transparent:hover {
                background-color: transparent;
            }
        `, "",{"version":3,"sources":["webpack://./src/app/nav/sidebar-task-header.component.ts"],"names":[],"mappings":";YACY;gBACI,mBAAmB;YACvB;YACA;gBACI,SAAS;gBACT,wBAAwB;YAC5B;YACA;gBACI,6BAA6B;YACjC","sourcesContent":["\n            .tasks-header {\n                vertical-align: sub;\n            }\n            .tasks-header .badge {\n                zoom: 0.9;\n                vertical-align: baseline;\n            }\n            .btn-transparent:hover {\n                background-color: transparent;\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
