import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';

import { IAddress } from '@model/interfaces/address';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { AddressService } from '../address.service';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';

import { ClaimTypes } from '@model/ClaimTypes';
import { MetaAddressBookTypeIdEnums } from '../../../common/constants/Enums';
import { AddressBookService } from '../../addressbook.service';

@Component({
    selector: 'app-address-detail',
    templateUrl: './address-detail.component.html',
})
export class AddressDetailComponent implements OnInit {
    address: IAddress;
    editingComponent: Subject<any> = new Subject();
    canEdit: boolean;
    canAdd: boolean;
    id: number;
    listRoute: string;

    constructor(
        private addressService: AddressService,
        private addressBookService: AddressBookService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
    ) { }

    ngOnInit(): void {
        // check claims
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.AddressBooks, [ClaimValues.FullAccess]);
        this.canAdd = this.canEdit;
        // get current id from route
        this.id = +this.route.snapshot.paramMap.get('addressId');
        // try load if id > 0
        if (this.id > 0) {
            this.getAddressById(this.id);
        } else {
            // set address to emptyAddress
            this.address = this.addressService.getEmptyAddress();
        }
        this.editingComponent.next('');
    }

    getAddressById(id: number): void {
        this.addressService.getById(id).subscribe((address) => {
            this.address = address;
            this.getListRoute();
        });
    }

    getListRoute(): void {
        const addressBookId = +this.route.parent.parent.snapshot.params.addressBookId;

        this.listRoute = `/address-books/${addressBookId}`; // Default
        if (this.address.AddressBooks_AddressBookId[0].MetaAddressBookTypeId === +MetaAddressBookTypeIdEnums.Individual) {
            this.addressBookService.getById(this.address.AddressBooks_AddressBookId[0].Id).subscribe((addressBook) => {
                const individual = addressBook.Individuals[0]; // since there is going to be just on individual for addressbook of individual type this is fine. // TODO - pass individual as a input
                this.listRoute = `/address-books/${addressBookId}/individuals/${individual.Id}`; // If the origin for the address is the individual
            });
        }
    }
}
