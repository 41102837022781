import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IPhysicianFollowupLog } from '../interfaces/physician-followup-log';
import { IImedClaimType } from '../interfaces/imed-claim-type';
import { IPhysician } from '../interfaces/physician';

export interface IPhysicianFollowupLogDynamicControlsParameters {
    formGroup?: string;
    physicians?: IPhysician[];
    imedClaimTypes?: IImedClaimType[];
}

export class PhysicianFollowupLogDynamicControls {

    formGroup: string;
    physicians: IPhysician[];
    imedClaimTypes: IImedClaimType[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private physicianfollowuplog?: IPhysicianFollowupLog, additionalParameters?: IPhysicianFollowupLogDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'PhysicianFollowupLog';
        this.physicians = additionalParameters && additionalParameters.physicians || undefined;
        this.imedClaimTypes = additionalParameters && additionalParameters.imedClaimTypes || undefined;

        this.Form = {
            AddedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Added By',
                name: 'AddedById',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.physicianfollowuplog && this.physicianfollowuplog.AddedById || null,
            }),
            AddedDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Added Date',
                name: 'AddedDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.physicianfollowuplog && this.physicianfollowuplog.AddedDate || null,
            }),
            FollowupDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Followup Date',
                name: 'FollowupDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.physicianfollowuplog && this.physicianfollowuplog.FollowupDate || null,
            }),
            FollowupNotes: new DynamicField({
                formGroup: this.formGroup,
                label: 'Followup Notes',
                name: 'FollowupNotes',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(200) ],
                validators: { 'maxlength': 200 },
                value: this.physicianfollowuplog && this.physicianfollowuplog.hasOwnProperty('FollowupNotes') && this.physicianfollowuplog.FollowupNotes !== null ? this.physicianfollowuplog.FollowupNotes.toString() : '',
            }),
            ImedClaimTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Imed Claim Type',
                name: 'ImedClaimTypeId',
                options: this.imedClaimTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.physicianfollowuplog && this.physicianfollowuplog.ImedClaimTypeId || null,
            }),
            PhysicianId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Physician',
                name: 'PhysicianId',
                options: this.physicians,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.physicianfollowuplog && this.physicianfollowuplog.PhysicianId || null,
            }),
        };

        this.View = {
            AddedById: new DynamicLabel({
			    label: 'Added By',
			    value: this.physicianfollowuplog && this.physicianfollowuplog.AddedById || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: NumericInputTypes.Integer,
			        scale: null,
			    })
			}
            ),
            AddedDate: new DynamicLabel({
			    label: 'Added Date',
			    value: this.physicianfollowuplog && this.physicianfollowuplog.AddedDate || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            FollowupDate: new DynamicLabel({
			    label: 'Followup Date',
			    value: this.physicianfollowuplog && this.physicianfollowuplog.FollowupDate || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            FollowupNotes: new DynamicLabel({
			    label: 'Followup Notes',
			    value: this.physicianfollowuplog && this.physicianfollowuplog.hasOwnProperty('FollowupNotes') && this.physicianfollowuplog.FollowupNotes !== null ? this.physicianfollowuplog.FollowupNotes.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ImedClaimTypeId: new DynamicLabel({
			    label: 'Imed Claim Type',
			    value: getMetaItemValue(this.imedClaimTypes as unknown as IMetaItem[], this.physicianfollowuplog && this.physicianfollowuplog.hasOwnProperty('ImedClaimTypeId') && this.physicianfollowuplog.ImedClaimTypeId !== null ? this.physicianfollowuplog.ImedClaimTypeId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            PhysicianId: new DynamicLabel({
			    label: 'Physician',
			    value: getMetaItemValue(this.physicians as unknown as IMetaItem[], this.physicianfollowuplog && this.physicianfollowuplog.hasOwnProperty('PhysicianId') && this.physicianfollowuplog.PhysicianId !== null ? this.physicianfollowuplog.PhysicianId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };

    }
}
