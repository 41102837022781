import { Component, OnInit, Input } from '@angular/core';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, IDynamicFieldType, IDynamicField, InputTypes } from '@mt-ng2/dynamic-form';
import { IImedClaimService } from '@model/interfaces/imed-claim-service';

@Component({
    selector: 'app-compilation-due-by-control',
    template: `
        <div *ngIf="!isEditing">
            <div class="PadL5"><label>Compilation Due By:</label> {{ imedClaimService.CompilationDueDate | utcDate }}<br /></div>
        </div>
        <div *ngIf="isEditing">
            <mt-dynamic-field [field]="compilationDueBy" (valueChanges)="imedClaimService.CompilationDueDate = $event"></mt-dynamic-field>
        </div>
    `,
})
export class CompilationDueByFormControlComponent implements OnInit {
    @Input('isEditing') isEditing: boolean;
    @Input('imedClaimService') imedClaimService: IImedClaimService;
    compilationDueBy: DynamicField;

    constructor() { }

    ngOnInit(): void {
        this.createCompilationDueByCheckbox();
    }

    createCompilationDueByCheckbox(): void {
        this.compilationDueBy = new DynamicField({
            formGroup: null,
            label: 'Compilation Due By',
            name: 'compilationDueBy',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Datepicker,
            } as IDynamicFieldType),
            value: this.imedClaimService.CompilationDueDate,
        } as IDynamicField);
    }
}
