import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { IUserRole } from '../interfaces/user-role';
import { IDashboardType } from '../interfaces/dashboard-type';
import { IUserType } from '../interfaces/user-type';

export interface IUserRoleDynamicControlsParameters {
    formGroup?: string;
    dashboardTypes?: IDashboardType[];
    userTypes?: IUserType[];
}

export class UserRoleDynamicControls {

    formGroup: string;
    dashboardTypes?: IDashboardType[];
    userTypes?: IUserType[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private userrole?: IUserRole, additionalParameters?: IUserRoleDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'UserRole';
        this.dashboardTypes = additionalParameters && additionalParameters.dashboardTypes || undefined;
        this.userTypes = additionalParameters && additionalParameters.userTypes || undefined;

        this.Form = {
            AllowToSendAsAttachment: new DynamicField({
                formGroup: this.formGroup,
                label: 'Allow To Send As Attachment',
                name: 'AllowToSendAsAttachment',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.userrole && this.userrole.hasOwnProperty('AllowToSendAsAttachment') && this.userrole.AllowToSendAsAttachment != null ? this.userrole.AllowToSendAsAttachment : false,
            }),
            AssignManualContactTask: new DynamicField({
                formGroup: this.formGroup,
                label: 'Assign Manual Contact Task',
                name: 'AssignManualContactTask',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.userrole && this.userrole.hasOwnProperty('AssignManualContactTask') && this.userrole.AssignManualContactTask != null ? this.userrole.AssignManualContactTask : false,
            }),
            AssignTaskOnPhysicianExpertDocUpdate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Assign Task On Physician Expert Doc Update',
                name: 'AssignTaskOnPhysicianExpertDocUpdate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.userrole && this.userrole.hasOwnProperty('AssignTaskOnPhysicianExpertDocUpdate') && this.userrole.AssignTaskOnPhysicianExpertDocUpdate != null ? this.userrole.AssignTaskOnPhysicianExpertDocUpdate : false,
            }),
            Auto: new DynamicField({
                formGroup: this.formGroup,
                label: 'Auto',
                name: 'Auto',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.userrole && this.userrole.hasOwnProperty('Auto') && this.userrole.Auto != null ? this.userrole.Auto : false,
            }),
            ClientCaseCreateEmail: new DynamicField({
                formGroup: this.formGroup,
                label: 'Client Case Create Email',
                name: 'ClientCaseCreateEmail',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.userrole && this.userrole.hasOwnProperty('ClientCaseCreateEmail') && this.userrole.ClientCaseCreateEmail != null ? this.userrole.ClientCaseCreateEmail : false,
            }),
            ClientCaseUpdateEmail: new DynamicField({
                formGroup: this.formGroup,
                label: 'Client Case Update Email',
                name: 'ClientCaseUpdateEmail',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.userrole && this.userrole.hasOwnProperty('ClientCaseUpdateEmail') && this.userrole.ClientCaseUpdateEmail != null ? this.userrole.ClientCaseUpdateEmail : false,
            }),
            ClientPortalRequestEmails: new DynamicField({
                formGroup: this.formGroup,
                label: 'Client Portal Request Emails',
                name: 'ClientPortalRequestEmails',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.userrole && this.userrole.hasOwnProperty('ClientPortalRequestEmails') && this.userrole.ClientPortalRequestEmails != null ? this.userrole.ClientPortalRequestEmails : false,
            }),
            ClientPortalSubpoenaServiceTask: new DynamicField({
                formGroup: this.formGroup,
                label: 'Client Portal Subpoena Service Task',
                name: 'ClientPortalSubpoenaServiceTask',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.userrole && this.userrole.hasOwnProperty('ClientPortalSubpoenaServiceTask') && this.userrole.ClientPortalSubpoenaServiceTask != null ? this.userrole.ClientPortalSubpoenaServiceTask : false,
            }),
            CompilationAssignedTo: new DynamicField({
                formGroup: this.formGroup,
                label: 'Compilation Assigned To',
                name: 'CompilationAssignedTo',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.userrole && this.userrole.hasOwnProperty('CompilationAssignedTo') && this.userrole.CompilationAssignedTo != null ? this.userrole.CompilationAssignedTo : false,
            }),
            DashboardTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Dashboard Type',
                name: 'DashboardTypeId',
                options: this.dashboardTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.userrole && this.userrole.DashboardTypeId || null,
            }),
            Description: new DynamicField({
                formGroup: this.formGroup,
                label: 'Description',
                name: 'Description',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(500) ],
                validators: { 'maxlength': 500 },
                value: this.userrole && this.userrole.hasOwnProperty('Description') && this.userrole.Description != null ? this.userrole.Description.toString() : '',
            }),
            EmailForTemplates: new DynamicField({
                formGroup: this.formGroup,
                label: 'Email For Templates',
                name: 'EmailForTemplates',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(40) ],
                validators: { 'maxlength': 40 },
                value: this.userrole && this.userrole.hasOwnProperty('EmailForTemplates') && this.userrole.EmailForTemplates != null ? this.userrole.EmailForTemplates.toString() : '',
            }),
            GeneralLiability: new DynamicField({
                formGroup: this.formGroup,
                label: 'General Liability',
                name: 'GeneralLiability',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.userrole && this.userrole.hasOwnProperty('GeneralLiability') && this.userrole.GeneralLiability != null ? this.userrole.GeneralLiability : false,
            }),
            IsEditable: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Editable',
                name: 'IsEditable',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.userrole && this.userrole.hasOwnProperty('IsEditable') && this.userrole.IsEditable != null ? this.userrole.IsEditable : true,
            }),
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.userrole && this.userrole.hasOwnProperty('Name') && this.userrole.Name != null ? this.userrole.Name.toString() : '',
            }),
            PhysicianExpertPortalRequestEmails: new DynamicField({
                formGroup: this.formGroup,
                label: 'Physician Expert Portal Request Emails',
                name: 'PhysicianExpertPortalRequestEmails',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.userrole && this.userrole.hasOwnProperty('PhysicianExpertPortalRequestEmails') && this.userrole.PhysicianExpertPortalRequestEmails != null ? this.userrole.PhysicianExpertPortalRequestEmails : false,
            }),
            ReceiveAutoCaseClosingJobEmail: new DynamicField({
                formGroup: this.formGroup,
                label: 'Receive Auto Case Closing Job Email',
                name: 'ReceiveAutoCaseClosingJobEmail',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.userrole && this.userrole.hasOwnProperty('ReceiveAutoCaseClosingJobEmail') && this.userrole.ReceiveAutoCaseClosingJobEmail != null ? this.userrole.ReceiveAutoCaseClosingJobEmail : false,
            }),
            ReceiveCpFilmDeleteEmail: new DynamicField({
                formGroup: this.formGroup,
                label: 'Receive Cp Film Delete Email',
                name: 'ReceiveCpFilmDeleteEmail',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.userrole && this.userrole.hasOwnProperty('ReceiveCpFilmDeleteEmail') && this.userrole.ReceiveCpFilmDeleteEmail != null ? this.userrole.ReceiveCpFilmDeleteEmail : false,
            }),
            ReportAssignedTo: new DynamicField({
                formGroup: this.formGroup,
                label: 'Report Assigned To',
                name: 'ReportAssignedTo',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.userrole && this.userrole.hasOwnProperty('ReportAssignedTo') && this.userrole.ReportAssignedTo != null ? this.userrole.ReportAssignedTo : false,
            }),
            RetrievalAssignedTo: new DynamicField({
                formGroup: this.formGroup,
                label: 'Retrieval Assigned To',
                name: 'RetrievalAssignedTo',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.userrole && this.userrole.hasOwnProperty('RetrievalAssignedTo') && this.userrole.RetrievalAssignedTo != null ? this.userrole.RetrievalAssignedTo : false,
            }),
            SchedulingAssignedTo: new DynamicField({
                formGroup: this.formGroup,
                label: 'Scheduling Assigned To',
                name: 'SchedulingAssignedTo',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.userrole && this.userrole.hasOwnProperty('SchedulingAssignedTo') && this.userrole.SchedulingAssignedTo != null ? this.userrole.SchedulingAssignedTo : false,
            }),
            SubpoenaAssignedTo: new DynamicField({
                formGroup: this.formGroup,
                label: 'Subpoena Assigned To',
                name: 'SubpoenaAssignedTo',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.userrole && this.userrole.hasOwnProperty('SubpoenaAssignedTo') && this.userrole.SubpoenaAssignedTo != null ? this.userrole.SubpoenaAssignedTo : false,
            }),
            TaskDistributionList: new DynamicField({
                formGroup: this.formGroup,
                label: 'Task Distribution List',
                name: 'TaskDistributionList',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.userrole && this.userrole.hasOwnProperty('TaskDistributionList') && this.userrole.TaskDistributionList != null ? this.userrole.TaskDistributionList : false,
            }),
            UserTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'User Type',
                name: 'UserTypeId',
                options: this.userTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.userrole && this.userrole.UserTypeId || 1,
            }),
            WorkersComp: new DynamicField({
                formGroup: this.formGroup,
                label: 'Workers Comp',
                name: 'WorkersComp',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.userrole && this.userrole.hasOwnProperty('WorkersComp') && this.userrole.WorkersComp != null ? this.userrole.WorkersComp : false,
            }),
        };

        this.View = {
            AllowToSendAsAttachment: new DynamicLabel({
                label: 'Allow To Send As Attachment',
                value: this.userrole && this.userrole.hasOwnProperty('AllowToSendAsAttachment') && this.userrole.AllowToSendAsAttachment != null ? this.userrole.AllowToSendAsAttachment : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            AssignManualContactTask: new DynamicLabel({
                label: 'Assign Manual Contact Task',
                value: this.userrole && this.userrole.hasOwnProperty('AssignManualContactTask') && this.userrole.AssignManualContactTask != null ? this.userrole.AssignManualContactTask : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            AssignTaskOnPhysicianExpertDocUpdate: new DynamicLabel({
                label: 'Assign Task On Physician Expert Doc Update',
                value: this.userrole && this.userrole.hasOwnProperty('AssignTaskOnPhysicianExpertDocUpdate') && this.userrole.AssignTaskOnPhysicianExpertDocUpdate != null ? this.userrole.AssignTaskOnPhysicianExpertDocUpdate : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            Auto: new DynamicLabel({
                label: 'Auto',
                value: this.userrole && this.userrole.hasOwnProperty('Auto') && this.userrole.Auto != null ? this.userrole.Auto : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            ClientCaseCreateEmail: new DynamicLabel({
                label: 'Client Case Create Email',
                value: this.userrole && this.userrole.hasOwnProperty('ClientCaseCreateEmail') && this.userrole.ClientCaseCreateEmail != null ? this.userrole.ClientCaseCreateEmail : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            ClientCaseUpdateEmail: new DynamicLabel({
                label: 'Client Case Update Email',
                value: this.userrole && this.userrole.hasOwnProperty('ClientCaseUpdateEmail') && this.userrole.ClientCaseUpdateEmail != null ? this.userrole.ClientCaseUpdateEmail : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            ClientPortalRequestEmails: new DynamicLabel({
                label: 'Client Portal Request Emails',
                value: this.userrole && this.userrole.hasOwnProperty('ClientPortalRequestEmails') && this.userrole.ClientPortalRequestEmails != null ? this.userrole.ClientPortalRequestEmails : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            ClientPortalSubpoenaServiceTask: new DynamicLabel({
                label: 'Client Portal Subpoena Service Task',
                value: this.userrole && this.userrole.hasOwnProperty('ClientPortalSubpoenaServiceTask') && this.userrole.ClientPortalSubpoenaServiceTask != null ? this.userrole.ClientPortalSubpoenaServiceTask : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            CompilationAssignedTo: new DynamicLabel({
                label: 'Compilation Assigned To',
                value: this.userrole && this.userrole.hasOwnProperty('CompilationAssignedTo') && this.userrole.CompilationAssignedTo != null ? this.userrole.CompilationAssignedTo : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            DashboardTypeId: new DynamicLabel({
                label: 'Dashboard Type',
                value: getMetaItemValue(this.dashboardTypes as unknown as IMetaItem[], this.userrole && this.userrole.hasOwnProperty('DashboardTypeId') ? this.userrole.DashboardTypeId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            Description: new DynamicLabel({
                label: 'Description',
                value: this.userrole && this.userrole.hasOwnProperty('Description') && this.userrole.Description != null ? this.userrole.Description.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            EmailForTemplates: new DynamicLabel({
                label: 'Email For Templates',
                value: this.userrole && this.userrole.hasOwnProperty('EmailForTemplates') && this.userrole.EmailForTemplates != null ? this.userrole.EmailForTemplates.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            GeneralLiability: new DynamicLabel({
                label: 'General Liability',
                value: this.userrole && this.userrole.hasOwnProperty('GeneralLiability') && this.userrole.GeneralLiability != null ? this.userrole.GeneralLiability : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            IsEditable: new DynamicLabel({
                label: 'Is Editable',
                value: this.userrole && this.userrole.hasOwnProperty('IsEditable') && this.userrole.IsEditable != null ? this.userrole.IsEditable : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            Name: new DynamicLabel({
                label: 'Name',
                value: this.userrole && this.userrole.hasOwnProperty('Name') && this.userrole.Name != null ? this.userrole.Name.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            PhysicianExpertPortalRequestEmails: new DynamicLabel({
                label: 'Physician Expert Portal Request Emails',
                value: this.userrole && this.userrole.hasOwnProperty('PhysicianExpertPortalRequestEmails') && this.userrole.PhysicianExpertPortalRequestEmails != null ? this.userrole.PhysicianExpertPortalRequestEmails : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            ReceiveAutoCaseClosingJobEmail: new DynamicLabel({
                label: 'Receive Auto Case Closing Job Email',
                value: this.userrole && this.userrole.hasOwnProperty('ReceiveAutoCaseClosingJobEmail') && this.userrole.ReceiveAutoCaseClosingJobEmail != null ? this.userrole.ReceiveAutoCaseClosingJobEmail : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            ReceiveCpFilmDeleteEmail: new DynamicLabel({
                label: 'Receive Cp Film Delete Email',
                value: this.userrole && this.userrole.hasOwnProperty('ReceiveCpFilmDeleteEmail') && this.userrole.ReceiveCpFilmDeleteEmail != null ? this.userrole.ReceiveCpFilmDeleteEmail : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            ReportAssignedTo: new DynamicLabel({
                label: 'Report Assigned To',
                value: this.userrole && this.userrole.hasOwnProperty('ReportAssignedTo') && this.userrole.ReportAssignedTo != null ? this.userrole.ReportAssignedTo : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            RetrievalAssignedTo: new DynamicLabel({
                label: 'Retrieval Assigned To',
                value: this.userrole && this.userrole.hasOwnProperty('RetrievalAssignedTo') && this.userrole.RetrievalAssignedTo != null ? this.userrole.RetrievalAssignedTo : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            SchedulingAssignedTo: new DynamicLabel({
                label: 'Scheduling Assigned To',
                value: this.userrole && this.userrole.hasOwnProperty('SchedulingAssignedTo') && this.userrole.SchedulingAssignedTo != null ? this.userrole.SchedulingAssignedTo : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            SubpoenaAssignedTo: new DynamicLabel({
                label: 'Subpoena Assigned To',
                value: this.userrole && this.userrole.hasOwnProperty('SubpoenaAssignedTo') && this.userrole.SubpoenaAssignedTo != null ? this.userrole.SubpoenaAssignedTo : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            TaskDistributionList: new DynamicLabel({
                label: 'Task Distribution List',
                value: this.userrole && this.userrole.hasOwnProperty('TaskDistributionList') && this.userrole.TaskDistributionList != null ? this.userrole.TaskDistributionList : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            UserTypeId: new DynamicLabel({
                label: 'User Type',
                value: getMetaItemValue(this.userTypes as unknown as IMetaItem[], this.userrole && this.userrole.hasOwnProperty('UserTypeId') ? this.userrole.UserTypeId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            WorkersComp: new DynamicLabel({
                label: 'Workers Comp',
                value: this.userrole && this.userrole.hasOwnProperty('WorkersComp') && this.userrole.WorkersComp != null ? this.userrole.WorkersComp : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
        };

    }
}
