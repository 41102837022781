import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { Observable } from 'rxjs';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { map } from 'rxjs/operators';
import { IMasterFollowupType } from '@model/interfaces/master-followup-type';

@Injectable()
export class MasterFollowupTypeService extends StaticMetaItemService<IMasterFollowupType> {
    constructor(public http: HttpClient) {
        super('MasterFollowupTypeService', 'Type', 'TypeIds', '/followuptypes', http);
    }
    getMasterFollowupTypes(): Observable<MtSearchFilterItem[]> {
        return this.http.get<IMasterFollowupType[]>(`/master-follow-up-types`, {}).pipe(
            map((followupType) => {
                return followupType.map((item) => {
                    return new MtSearchFilterItem(item, false);
                });
            }),
        );
    }

    getFollowupTypeByCaseType(imedClaimTypeId: number): Observable<IMasterFollowupType[]>{
        return this.http.get<IMasterFollowupType[]>(`/followuptypes/get-by-case-type/${imedClaimTypeId}`,{});
    }
}
