// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            * {
                cursor: default;
            }
            .table-heading {
                font-size: 14px;
                font-weight: 800;
                padding: 5px;
                border: 1px solid #ccc;
            }
        `, "",{"version":3,"sources":["webpack://./src/app/task-status-followup-engine/system-task-template-associations/system-task-template-associations.component.ts"],"names":[],"mappings":";YACY;gBACI,eAAe;YACnB;YACA;gBACI,eAAe;gBACf,gBAAgB;gBAChB,YAAY;gBACZ,sBAAsB;YAC1B","sourcesContent":["\n            * {\n                cursor: default;\n            }\n            .table-heading {\n                font-size: 14px;\n                font-weight: 800;\n                padding: 5px;\n                border: 1px solid #ccc;\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
