import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { INote } from '@model/interfaces/base';
import { ISetting } from '@model/interfaces/setting';

@Injectable({
    providedIn: 'root',
})
export class AutoRecordReviewService extends BaseService<ISetting> {
    constructor(public http: HttpClient) {
        super('', http);
    }
    updateAdjusterInquiries(imedClaimServiceId: number, notes: INote[]): any {
        return this.http.put<any>(`/imedclaimservices/${imedClaimServiceId}/notes/update`, notes);
    }
}
