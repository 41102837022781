import { SharedEntitiesComponentConfig, ISharedEntitiesComponentConfig, SharedEntitiesEditOptions } from '@mt-ng2/shared-entities-module';
import { entityListModuleConfig } from '../../../common/shared.module';
import { IPhoneNumber } from '../../interfaces/phone-number';
import { emptyPhoneNumber } from './phone-number.service';
import { PhoneTypeService } from '../../../address-books/phonetype.service';
import { PhoneNumberDynamicConfig } from './phone-numbers.dynamic-config';
import { PhoneNumberListConfig } from './phone-numbers.entity-list-config';
import { PhonePipe } from '@mt-ng2/phone-control';

export class PhoneNumberSharedEntitiesConfig extends SharedEntitiesComponentConfig<IPhoneNumber>
    implements ISharedEntitiesComponentConfig<IPhoneNumber> {
    pipe: PhonePipe = new PhonePipe();

    constructor() {
        super(
            emptyPhoneNumber,
            'Phone Number',
            'Phone Numbers',
            {
                EntityListConfig: new PhoneNumberListConfig(),
                FilterServices: [PhoneTypeService],
            },
            entityListModuleConfig.itemsPerPage,
        );

        this.MetaItemServices = [PhoneTypeService];

        // TODO CCC: handle this
        this.AdditionalSharedEntities = [];

        this.SharedEntitiesEditOption = SharedEntitiesEditOptions.InPlaceWithAdditionalInfoButton;
    }

    getFormValues(phoneNumber: IPhoneNumber, formValue: any): IPhoneNumber {
        const phoneTypes = this.getMetaItemValues('PhoneTypeService');
        const formFactory = new PhoneNumberDynamicConfig<IPhoneNumber>(phoneNumber, phoneTypes);
        formFactory.assignFormValues(phoneNumber, formValue.PhoneNumber);
        return phoneNumber;
    }

    getDynamicFormConfig(phoneNumber: IPhoneNumber): any {
        const phoneTypes = this.getMetaItemValues('PhoneTypeService');
        const formFactory = new PhoneNumberDynamicConfig<IPhoneNumber>(phoneNumber, phoneTypes);
        if (phoneNumber.Id === 0) {
            return formFactory.getForCreate();
        } else {
            return formFactory.getForUpdate();
        }
    }

    getRow = (phoneNumber: IPhoneNumber) =>
        `${phoneNumber.IsPrimary ? '<i class="fa fa-star btn selected"></i>' : ''}
        ${phoneNumber.PhoneType ? `[${phoneNumber.PhoneType.Name}] ` : ''}<strong>${this.pipe.transform(phoneNumber.Phone)}</strong>
        ${phoneNumber.Notes ? `<br /><div style="padding-top:15px"><label>Notes: </label> ${phoneNumber.Notes}</div>` : ''}`
}
