import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, OperatorFunction } from 'rxjs';

import { BaseService } from '@mt-ng2/base-service';
import { IImedClaimTreatingPhysician } from '@model/interfaces/imed-claim-treating-physician';
import { IImedClaimTreatingPhysicianDTO } from '@model/interfaces/custom/imed-claim-treating-physician.dto';
import { catchError } from 'rxjs/operators';

export const emptyImedClaimTreatingPhysicianDTO: IImedClaimTreatingPhysicianDTO = {
    AddressBookId: 0,
    Id: 0,
    IndividualId: 0,
    Name: '',
};

@Injectable({
    providedIn: 'root',
})
export class ImedClaimTreatingPhysicianService extends BaseService<IImedClaimTreatingPhysician> {
    newTreatingPhysicians: IImedClaimTreatingPhysicianDTO[] = [];

    constructor(public http: HttpClient) {
        super('/imedclaims', http);
    }

    resetTreatingPhysicians(): void {
        this.newTreatingPhysicians = [];
    }
    getEmptyImedClaimTreatingPhysician(): IImedClaimTreatingPhysicianDTO {
        return { ...emptyImedClaimTreatingPhysicianDTO };
    }

    getTreatingPhysicians(imedClaimId: number): Observable<IImedClaimTreatingPhysicianDTO[]> {
        return this.http.get<IImedClaimTreatingPhysicianDTO[]>(`/imedclaims/${imedClaimId}/treating-physicians`);
    }

    AddTreatingPhysician(imedClaimId: number, dtos: IImedClaimTreatingPhysicianDTO[]): Observable<number> {
        return this.http.post<number>(`/imedclaims/${imedClaimId}/treating-physicians`, dtos);
    }

    delete(id: number): Observable<any> {
        return this.http.delete(`/imedclaims/${id}/treating-physicians`).pipe(catchError((e: Response) => this.handleError(e)) as OperatorFunction<unknown, any>);
    }

    updateTreatingPhysician(dto: IImedClaimTreatingPhysicianDTO): Observable<any> {
        return this.http.post<number>(`/imedclaims/treating-physicians/`, dto);
    }
}
