import { ILabelGenerator } from './../interfaces/ILabelGenerator.d';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IEntity } from '@mt-ng2/base-service';
import { IModalOptions } from '@mt-ng2/modal-module';

@Component({
    selector: 'app-mini-dashboard-list',
    styles: [
        `
            .alternateColorItem:hover {
                background-color: #f5f5f5;
            }
            .alternateColorItem:nth-child(even) {
                background-color: #ececec;
            }
            .alternateColorItem:first-child {
                border-top: 1px solid #ddd;
            }
            .alternateColorItem:last-child {
                border-bottom: 1px solid #ddd;
            }
            .special-text {
                color: rgb(245, 66, 206);
                font-size: 14px;
                word-wrap: break-word;
                white-space: pre-wrap;
            }
        `,
    ],
    templateUrl: './mini-dashboard-list.component.html',
})
export class MiniDashboardListComponent implements OnInit {
    @Input()
    items: IEntity[];
    @Input()
    totalItems: number;
    @Input()
    canEdit: boolean;
    @Input()
    noOfItemsToShow: number;
    @Input()
    cardName: string;
    @Input()
    labelGenerator: ILabelGenerator;
    @Input()
    forceShowAll: boolean;
    @Input()
    showTotalCount: boolean;
    @Input()
    showRemove: boolean;
    @Input()
    alternateColor: boolean;
    @Input()
    showAddNew: boolean;
    @Input()
    showCloseButton: boolean;
    @Input()
    addNewButtonText: string;
    @Input()
    noBottomPadding: boolean;
    @Input() specialText: string;
    @Output('onSeeAll') onSeeAll: EventEmitter<any> = new EventEmitter<any>();
    @Output('onAddItem') onAddItem: EventEmitter<any> = new EventEmitter<any>();
    @Output('onAddNew') onAddNew: EventEmitter<any> = new EventEmitter<any>();
    @Output('onRemove') onRemove: EventEmitter<number> = new EventEmitter<number>();
    @Output('onClose') onClose: EventEmitter<number> = new EventEmitter<number>();

    @Output('onSelectItem') onSelectItem: EventEmitter<IEntity> = new EventEmitter<IEntity>();

    archiveConfirm: IModalOptions = {};

    constructor() {}

    ngOnInit(): void {
        this.archiveConfirm = {
            confirmButtonText: 'Yes, I am sure!',
            text: `Are you sure you want to delete this ${this.cardName.substring(0, this.cardName.length - 1).toLowerCase()}?`,
            title: 'Delete',
            icon: 'question',
        };
    }

    showTotal(): boolean {
        return (this.totalItems && this.totalItems > this.noOfItemsToShow) || this.forceShowAll;
    }

    seeAll(): void {
        this.onSeeAll.emit();
    }

    close(): void {
        this.onClose.emit();
    }

    addItem(): void {
        this.onAddItem.emit();
    }

    getItemName(item: IEntity): string {
        if (this.labelGenerator) {
            return this.labelGenerator.GetLabel(item);
        }
        return '';
    }

    selectItem(item: IEntity): void {
        this.onSelectItem.emit(item);
    }

    addNew(): void {
        this.onAddNew.emit();
    }

    removeItem(item: IEntity): void {
        this.onRemove.emit(item.Id);
    }
}
