import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IRequestorType } from '@model/interfaces/requestor-type';

@Injectable()
export class ImedClaimRequestorTypeService extends StaticMetaItemService<IRequestorType> {
    constructor(public http: HttpClient) {
        super('ImedClaimRequestorTypeService', 'RequestorType', 'Ids', '/options/requestortypes', http);
    }
}
