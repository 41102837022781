import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IBillsForReview } from '../interfaces/bills-for-review';
import { IImedClaimService } from '../interfaces/imed-claim-service';

export interface IBillsForReviewDynamicControlsParameters {
    formGroup?: string;
    imedClaimServices?: IImedClaimService[];
}

export class BillsForReviewDynamicControls {

    formGroup: string;
    imedClaimServices: IImedClaimService[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private billsforreview?: IBillsForReview, additionalParameters?: IBillsForReviewDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'BillsForReview';
        this.imedClaimServices = additionalParameters && additionalParameters.imedClaimServices || undefined;

        this.Form = {
            Amount: new DynamicField({
                formGroup: this.formGroup,
                label: 'Amount',
                name: 'Amount',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.billsforreview && this.billsforreview.Amount || 0,
            }),
            Dos: new DynamicField({
                formGroup: this.formGroup,
                label: 'Dos',
                name: 'Dos',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.billsforreview && this.billsforreview.Dos || null,
            }),
            Facility: new DynamicField({
                formGroup: this.formGroup,
                label: 'Facility',
                name: 'Facility',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(200) ],
                validators: { 'maxlength': 200 },
                value: this.billsforreview && this.billsforreview.hasOwnProperty('Facility') && this.billsforreview.Facility !== null ? this.billsforreview.Facility.toString() : '',
            }),
            HcpcsCode: new DynamicField({
                formGroup: this.formGroup,
                label: 'Hcpcs Code',
                name: 'HcpcsCode',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(10) ],
                validators: { 'maxlength': 10 },
                value: this.billsforreview && this.billsforreview.hasOwnProperty('HcpcsCode') && this.billsforreview.HcpcsCode !== null ? this.billsforreview.HcpcsCode.toString() : '',
            }),
            ImedClaimServiceId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Imed Claim Service',
                name: 'ImedClaimServiceId',
                options: this.imedClaimServices,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.billsforreview && this.billsforreview.ImedClaimServiceId || null,
            }),
        };

        this.View = {
            Amount: new DynamicLabel({
			    label: 'Amount',
			    value: this.billsforreview && this.billsforreview.Amount || 0,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: null,
			        scale: 2,
			    })
			}
            ),
            Dos: new DynamicLabel({
			    label: 'Dos',
			    value: this.billsforreview && this.billsforreview.Dos || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            Facility: new DynamicLabel({
			    label: 'Facility',
			    value: this.billsforreview && this.billsforreview.hasOwnProperty('Facility') && this.billsforreview.Facility !== null ? this.billsforreview.Facility.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            HcpcsCode: new DynamicLabel({
			    label: 'Hcpcs Code',
			    value: this.billsforreview && this.billsforreview.hasOwnProperty('HcpcsCode') && this.billsforreview.HcpcsCode !== null ? this.billsforreview.HcpcsCode.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ImedClaimServiceId: new DynamicLabel({
			    label: 'Imed Claim Service',
			    value: getMetaItemValue(this.imedClaimServices as unknown as IMetaItem[], this.billsforreview && this.billsforreview.hasOwnProperty('ImedClaimServiceId') && this.billsforreview.ImedClaimServiceId !== null ? this.billsforreview.ImedClaimServiceId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };

    }
}
