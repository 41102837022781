// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            .clear-padding {
                padding-left: 0;
            }
        `, "",{"version":3,"sources":["webpack://./src/app/task-status-followup-engine/followup-exemption/followup-exemption.component.ts"],"names":[],"mappings":";YACY;gBACI,eAAe;YACnB","sourcesContent":["\n            .clear-padding {\n                padding-left: 0;\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
