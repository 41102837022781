import { Injectable } from '@angular/core';
import { AttachmentTypeEnums, AutomatedTemplateFaxEmailEntityTypeEnums, FaxEmailEntityTypeEnums } from '@common/constants/Enums';
import { IViewGetServicesForList } from '@model/interfaces/custom/view-get-services-for-list';
import { IImedClaimService } from '@model/interfaces/imed-claim-service';

@Injectable()
export class BaseFaxEmailHelperService {
    noDateOfInjuryValidationServices: FaxEmailEntityTypeEnums[] = [
        FaxEmailEntityTypeEnums.RadiologyCoverLetter,
        FaxEmailEntityTypeEnums.RecordsRetrievalSimple,
        FaxEmailEntityTypeEnums.RecordsRetrievalComplex,
        FaxEmailEntityTypeEnums.FilmRetrievalLabel,
        FaxEmailEntityTypeEnums.RecordsRejectionLetter,
        FaxEmailEntityTypeEnums.UndeliverableRecords,
        FaxEmailEntityTypeEnums.AdditionalRecordsRetrieved,
        FaxEmailEntityTypeEnums.NoRecordsOnFile,
    ];
    showLinkToDocServices: FaxEmailEntityTypeEnums[] = [
        FaxEmailEntityTypeEnums.AppointmentNotificationLetter,
        FaxEmailEntityTypeEnums.AppointmentCancelLetter,
        FaxEmailEntityTypeEnums.AppointmentMissedLetter,
        FaxEmailEntityTypeEnums.AppointmentRescheduleLetter,
        FaxEmailEntityTypeEnums.FilmRetrievalLabel,
        FaxEmailEntityTypeEnums.RecordsRetrievalSimple,
        FaxEmailEntityTypeEnums.RecordsRejectionLetter,
    ];
    letterTypeAttachmentTypeIdMap = new Map([
        [FaxEmailEntityTypeEnums.AppointmentCancelLetter, AttachmentTypeEnums.Notice_Of_Appointment_Cancelled],
        [FaxEmailEntityTypeEnums.AppointmentMissedLetter, AttachmentTypeEnums.Notice_Of_Appointment_Missed],
        [FaxEmailEntityTypeEnums.AppointmentNotificationLetter, AttachmentTypeEnums.Notice_Of_Appointment],
        [FaxEmailEntityTypeEnums.AppointmentRescheduleLetter, AttachmentTypeEnums.Appointment_RescheduledTBD_Letter],
        [FaxEmailEntityTypeEnums.AppointmentTBDLetter, AttachmentTypeEnums.Appointment_RescheduledTBD_Letter],
        [FaxEmailEntityTypeEnums.AppointmentRescheduleTBDLetter, AttachmentTypeEnums.Appointment_RescheduledTBD_Letter],
        [FaxEmailEntityTypeEnums.AppointmentConfirmationLetter, AttachmentTypeEnums.Appointment_Confirmation_To_Physician],
        [FaxEmailEntityTypeEnums.FilmRetrievalLabel, AttachmentTypeEnums.Radiology_Retrieval_Label],
        [FaxEmailEntityTypeEnums.IntroLetter, AttachmentTypeEnums.Intro_Letter],
        [FaxEmailEntityTypeEnums.RadiologyCoverLetter, AttachmentTypeEnums.Film_Review_Cover_Letter],
        [FaxEmailEntityTypeEnums.IMEAddendum, AttachmentTypeEnums.Addendum],
        [FaxEmailEntityTypeEnums.IMEReExam, AttachmentTypeEnums.Re_Exam_Letter],
        [FaxEmailEntityTypeEnums.RecordsRejectionLetter, AttachmentTypeEnums.Rejection],
        [FaxEmailEntityTypeEnums.RecordReview, AttachmentTypeEnums.Record_Review_Cover_Letter],
        [FaxEmailEntityTypeEnums.RecordsRetrievalComplex, AttachmentTypeEnums.Retrieval_Documents_Complete],
        [FaxEmailEntityTypeEnums.RecordsRetrievalSimple, AttachmentTypeEnums.Retrieval_Cover_Letter],
        [FaxEmailEntityTypeEnums.WelcomeLetter, AttachmentTypeEnums.Welcome_Letter],
        [FaxEmailEntityTypeEnums.IntroNewToIMEsLetter, AttachmentTypeEnums.Introductory_Letter_Intro_To_Imes],
    ]);
    getEntityName(entityTypeId: FaxEmailEntityTypeEnums): string {
        switch (entityTypeId) {
            case FaxEmailEntityTypeEnums.IntroLetter:
                return 'Intro Letter';
            case FaxEmailEntityTypeEnums.WelcomeLetter:
                return 'Welcome Letter';
            case FaxEmailEntityTypeEnums.IntroNewToIMEsLetter:
                return 'Intro New To IMEs Letter';
            case FaxEmailEntityTypeEnums.RadiologyCoverLetter:
                return 'Radiology Cover Letter';
            case FaxEmailEntityTypeEnums.IMEReExam:
                return 'IME Re-Exam';
            case FaxEmailEntityTypeEnums.IMEAddendum:
                return 'IME Addendum';
            case FaxEmailEntityTypeEnums.AppointmentTBDLetter:
                return 'Appointment TBD Letter';
            case FaxEmailEntityTypeEnums.RecordsRetrievalSimple:
                return 'Record Retrieval';
            case FaxEmailEntityTypeEnums.RecordsRejectionLetter:
                return 'Records Rejection Letter';
            case FaxEmailEntityTypeEnums.RecordsRetrievalComplex:
                return 'Retrieval Package';
            case FaxEmailEntityTypeEnums.FilmRetrievalLabel:
                return 'Film Retrieval Label';
            case FaxEmailEntityTypeEnums.NoRecordsOnFile:
                return 'No Records On File';
            case FaxEmailEntityTypeEnums.UndeliverableRecords:
                return 'Undeliverable Records';
            case FaxEmailEntityTypeEnums.AdditionalRecordsRetrieved:
                return 'Additional Records Retrieved';
            case FaxEmailEntityTypeEnums.RecordReview:
                return 'Record Review';
            case FaxEmailEntityTypeEnums.AppointmentNotificationLetter:
                return 'Appointment NotificationLetter';
            case FaxEmailEntityTypeEnums.AppointmentCancelLetter:
                return 'Appointment Cancel Letter';
            case FaxEmailEntityTypeEnums.AppointmentMissedLetter:
                return 'Appointment Missed Letter';
            case FaxEmailEntityTypeEnums.AppointmentRescheduleLetter:
                return 'Appointment Reschedule Letter';
            case FaxEmailEntityTypeEnums.AppointmentConfirmationLetter:
                return 'Appointment Confirmation Letter';
            case FaxEmailEntityTypeEnums.AppointmentRescheduleTBDLetter:
                return 'Appointment Reschedule TBD Letter';
            default:
                return '';
        }
    }

    validateServiceFromList(service: IViewGetServicesForList): string[] {
        const missingFields = [];
        if (!service.PhysicianName) {
            missingFields.push('Physician');
        }
        if (!service.DefenseAttorneyAddressBookId) {
            missingFields.push('Defense attorney');
        }
        if (!service.ClaimNumber) {
            missingFields.push('Claim number');
        }
        if (!service.FileNumber) {
            missingFields.push('File number');
        }
        return missingFields;
    }

    validateServiceFromDetail(service: IImedClaimService): string[] {
        const missingFields = [];
        if (!service.PhysicianId && !service.FacilityAddressBookId) {
            missingFields.push('Physician or Facility');
        }
        if (!service.ImedClaim.DefenseAttorneyAddressBookId) {
            missingFields.push('Defense attorney');
        }
        if (!service.ImedClaim.ClaimNumber) {
            missingFields.push('Claim number');
        }
        if (!service.ImedClaim.FileNumber) {
            missingFields.push('File number');
        }
        return missingFields;
    }

    getEntityPath(entityTypeId: FaxEmailEntityTypeEnums): string {
        switch (entityTypeId) {
            case FaxEmailEntityTypeEnums.IntroLetter:
                return 'intro-letter';
            case FaxEmailEntityTypeEnums.WelcomeLetter:
                return 'welcome-letter';
            case FaxEmailEntityTypeEnums.IntroNewToIMEsLetter:
                return 'intro-new-to-imes-letter';
            case FaxEmailEntityTypeEnums.RadiologyCoverLetter:
                return 'radiology-cover-letter';
            case FaxEmailEntityTypeEnums.IMEReExam:
                return 'ime-reexam';
            case FaxEmailEntityTypeEnums.IMEAddendum:
                return 'ime-addendum';
            case FaxEmailEntityTypeEnums.AppointmentTBDLetter:
                return 'appointment-tbd-letter';
            case FaxEmailEntityTypeEnums.RecordsRetrievalSimple:
                return 'records-retrieval-simple';
            case FaxEmailEntityTypeEnums.RecordsRejectionLetter:
                return 'records-rejection-letter';
            case FaxEmailEntityTypeEnums.RecordsRetrievalComplex:
                return 'records-retrieval-complex';
            case FaxEmailEntityTypeEnums.FilmRetrievalLabel:
                return 'film-retrieval-label';
            case FaxEmailEntityTypeEnums.NoRecordsOnFile:
                return 'no-records-on-file';
            case FaxEmailEntityTypeEnums.UndeliverableRecords:
                return 'undeliverable-records';
            case FaxEmailEntityTypeEnums.AdditionalRecordsRetrieved:
                return 'additional-records-retrieved';
            case FaxEmailEntityTypeEnums.RecordReview:
                return 'record-review';
            case FaxEmailEntityTypeEnums.AppointmentNotificationLetter:
                return 'appointment-notification-letter';
            case FaxEmailEntityTypeEnums.AppointmentCancelLetter:
                return 'appointment-cancel-letter';
            case FaxEmailEntityTypeEnums.AppointmentMissedLetter:
                return 'appointment-missed-letter';
            case FaxEmailEntityTypeEnums.AppointmentRescheduleLetter:
                return 'appointment-reschedule-letter';
            case FaxEmailEntityTypeEnums.AppointmentConfirmationLetter:
                return 'appointment-confirmation-letter';
            case FaxEmailEntityTypeEnums.AppointmentRescheduleTBDLetter:
                return 'appointment-reschedule-tbd-letter';
            default:
                return '';
        }
    }

    getATEntityPath(entityTypeId: AutomatedTemplateFaxEmailEntityTypeEnums, isNF10DocPopup: boolean, isSubpoenaDocPopup: boolean, isCompletedSubpoenaDocPopup: boolean, isAllSubpoenasDocPopup: boolean, replaceSignature: boolean): string {
        switch (entityTypeId) {
            case AutomatedTemplateFaxEmailEntityTypeEnums.ImedClaim:
                if (isAllSubpoenasDocPopup) { return `all-subpoenas`; }
                return 'imedclaim-automated-template';
            case AutomatedTemplateFaxEmailEntityTypeEnums.ImedService:
                if (isNF10DocPopup) { return 'nf10-dynamic-template' ; }
                if (isSubpoenaDocPopup) { return `subpoena-service-package`; }
                if (isCompletedSubpoenaDocPopup) { return `completed-subpoena-package`; }
                return 'imedclaim-service-automated-template';
            case AutomatedTemplateFaxEmailEntityTypeEnums.AddressBookIndividual:
                return 'individual-automated-template';
            case AutomatedTemplateFaxEmailEntityTypeEnums.AddressBookFacility:
                return 'facility-automated-template';
            default:
                return null;
        }
    }

    getEntityType(entityTypeId: AutomatedTemplateFaxEmailEntityTypeEnums): string {
        switch (entityTypeId) {
            case AutomatedTemplateFaxEmailEntityTypeEnums.ImedClaim:
                return 'imed-claim';
            case AutomatedTemplateFaxEmailEntityTypeEnums.ImedService:
                return 'imed-claim-service';
            case AutomatedTemplateFaxEmailEntityTypeEnums.AddressBookIndividual:
                return 'individual';
            case AutomatedTemplateFaxEmailEntityTypeEnums.AddressBookFacility:
                return 'address-book';
            default:
                return null;
        }
    }

    validateEmailAddresses(emailList: string): boolean {
        const emailRegex =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const emails = emailList && emailList !== '' && emailList !== undefined ? emailList.split(/[,;]+/).map((email) => email.trim()) : null;
        if (emails && emails.every((email) => emailRegex.test(email) === true)) {
            return true;
        } else {
            return false;
        }
    }
}
