import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';

import { ClaimTypes } from '@model/ClaimTypes';
import { Subscription } from 'rxjs';
import { IMasterFollowupType } from '@model/interfaces/master-followup-type';
import { MasterFollowUpTypeService } from '../services/master-followup-type.service';

@Component({
    templateUrl: './master-followup-type-detail.component.html',
})
export class MasterFollowupTypeDetailComponent implements OnInit {
    masterFollowupType: IMasterFollowupType;
    canEdit: boolean;
    canAdd: boolean;

    routeSubscription: Subscription;

    get canDuplicate(): boolean {
        return this.canEdit && this.masterFollowupType && this.masterFollowupType.Id > 0;
    }

    constructor(
        private masterFollowupTypeService: MasterFollowUpTypeService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
        private router: Router,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        // check claims
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.MasterFollowUpTypes, [ClaimValues.FullAccess]);
        this.canAdd = this.canEdit;
        // get current id from route
        const id = this.getIdFromRoute();
        this.getMasterFollowupTypeById(id);

        this.routeSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const id = this.getIdFromRoute();
                if (id !== this.masterFollowupType.Id) {
                    this.masterFollowupType = null;
                    this.getMasterFollowupTypeById(id);
                }
            }
        });
    }

    ngOnDestroy(): void {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
        }
    }

    getMasterFollowupTypeById(id: number): void {
        this.masterFollowupTypeService.getById(id).subscribe((masterFollowupType) => {
            if (masterFollowupType === null) {
                this.notificationsService.error('Followup Type not found');
                void this.router.navigate(['follow-up-types']);
            }
            this.masterFollowupType = masterFollowupType;
            this.masterFollowupType.FollowupTypes = masterFollowupType.FollowupTypes.filter((ft) => !ft.Archived);
        });
    }

    getIdFromRoute(): number {
        const id = +this.route.snapshot.paramMap.get('followupTypeId');
        if (!id) {
            void this.router.navigate(['follow-up-types']); // if no id found, go back to list
        }
        return id;
    }
}
