import { Component } from '@angular/core';

import { IEntityListDynamicCellComponent, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { IEntity } from '@mt-ng2/base-service';
import { IModalOptions } from '@mt-ng2/modal-module';

import { IEmailTemplate } from '@model/interfaces/email-template';

@Component({
    template: `
        <div *ngIf="emailTemplate && !emailTemplate.SystemGenerated">
            <div *ngIf="emailTemplate.Archived" class="text-center">
                <i (mtConfirm)="archiveEmailTemplate($event)" class="fa fa-trash fa-2x" aria-hidden="true" [mtConfirmOptions]="unarchiveConfirm"></i
                ><i class="fa fa-lg fa-undo overlay-fa"></i>
            </div>
            <div *ngIf="!emailTemplate.Archived" class="text-center">
                <i (mtConfirm)="archiveEmailTemplate($event)" class="fa fa-trash fa-2x" aria-hidden="true" [mtConfirmOptions]="archiveConfirm"></i>
            </div>
        </div>
    `,
})
export class EmailTemplateDeleteCellComponent implements IEntityListDynamicCellComponent {
    entityListComponentMembers: IEntityListComponentMembers;
    set entity(value: IEntity) {
        this.emailTemplate = value as IEmailTemplate;
    }

    emailTemplate: IEmailTemplate;

    archiveConfirm: IModalOptions = {
        confirmButtonText: 'Yes, I am sure!',
        text: `Are you sure you want to delete this email template?`,
        title: 'Delete Email Template',
    	icon: 'question',
};

    unarchiveConfirm: IModalOptions = {
        confirmButtonText: 'Yes, I am sure!',
        text: `Are you sure you want to restore this email template?`,
        title: 'Restore Email Template',
    	icon: 'question',
};

    constructor() { }

    archiveEmailTemplate(event: Event): void {
        this.entityListComponentMembers.itemDeleted(this.emailTemplate as IEntity, event);
    }
}
