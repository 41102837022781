import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, IDynamicFieldType, IDynamicField } from '@mt-ng2/dynamic-form';
import { IImedClaimService } from '@model/interfaces/imed-claim-service';

@Component({
    selector: 'app-high-value-service-control',
    template: `
        <div *ngIf="!isEditing">
            <div class="PadL5"><label>High Value Service: </label>{{ imedClaimService.HighValueService ? ' Yes' : ' No' }}<br /></div>
        </div>
        <div *ngIf="isEditing">
            <mt-dynamic-field [field]="highValueServiceCheckBox" (valueChanges)="imedClaimService.HighValueService = $event"></mt-dynamic-field>
        </div>
    `,
})
export class HighValueServiceFormControlComponent implements OnInit {
    @Input('isEditing') isEditing: boolean;
    @Input('imedClaimService') imedClaimService: IImedClaimService;
    highValueServiceCheckBox: DynamicField;

    constructor() { }

    ngOnInit(): void {
        this.createHighValueServiceCheckbox();
    }

    createHighValueServiceCheckbox(): void {
        this.highValueServiceCheckBox = new DynamicField({
            formGroup: null,
            label: 'High Value Service',
            name: 'highValueService',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Checkbox,
            } as IDynamicFieldType),
            value: this.imedClaimService.HighValueService,
        } as IDynamicField);
    }
}
