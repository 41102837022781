import { DynamicField, DynamicFieldTypes, InputTypes, DynamicFieldType, ITimepickerOptions } from '@mt-ng2/dynamic-form';
import {
    ConferenceCallServiceDateTimeDynamicControls,
    IConferenceCallServiceDateTimeDynamicControlsParameters,
} from './../form-controls/conference-call-service-date-time.form-controls';
import { IConferenceCallServiceDateTime } from './../interfaces/conference-call-service-date-time.d';

export class ConferenceCallServiceDateTimeDynamicControlsPartial extends ConferenceCallServiceDateTimeDynamicControls {
    constructor(
        private childconferencecallservicedatetime?: IConferenceCallServiceDateTime,
        additionalParameters?: IConferenceCallServiceDateTimeDynamicControlsParameters,
    ) {
        super(childconferencecallservicedatetime, additionalParameters);

        (<DynamicField>this.Form.DateTimeRequested).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Input,
            inputType: InputTypes.DateTimepicker,
            timepickerOptions: { meridian: true } as ITimepickerOptions,
        });
        (<DynamicField>this.Form.DateTimeRequested).labelHtml = ``;
    }
}
