import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IPhysicianRecruitment } from '../interfaces/physician-recruitment';
import { IUser } from '../interfaces/user';
import { IPhysician } from '../interfaces/physician';

export interface IPhysicianRecruitmentDynamicControlsParameters {
    formGroup?: string;
    physicians?: IPhysician[];
    physicianUsers?: IUser[];
    createdBies?: IUser[];
    modifiedBies?: IUser[];
}

export class PhysicianRecruitmentDynamicControls {

    formGroup: string;
    physicians: IPhysician[];
    physicianUsers: IUser[];
    createdBies: IUser[];
    modifiedBies: IUser[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private physicianrecruitment?: IPhysicianRecruitment, additionalParameters?: IPhysicianRecruitmentDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'PhysicianRecruitment';
        this.physicians = additionalParameters && additionalParameters.physicians || undefined;
        this.physicianUsers = additionalParameters && additionalParameters.physicianUsers || undefined;
        this.createdBies = additionalParameters && additionalParameters.createdBies || undefined;
        this.modifiedBies = additionalParameters && additionalParameters.modifiedBies || undefined;

        this.Form = {
            Archived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived',
                name: 'Archived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physicianrecruitment && this.physicianrecruitment.hasOwnProperty('Archived') && this.physicianrecruitment.Archived !== null ? this.physicianrecruitment.Archived : false,
            }),
            CreatedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Created By',
                name: 'CreatedById',
                options: this.createdBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.physicianrecruitment && this.physicianrecruitment.CreatedById || null,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physicianrecruitment && this.physicianrecruitment.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physicianrecruitment && this.physicianrecruitment.DateModified || null,
            }),
            ModifiedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Modified By',
                name: 'ModifiedById',
                options: this.modifiedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physicianrecruitment && this.physicianrecruitment.ModifiedById || null,
            }),
            PhysicianId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Physician',
                name: 'PhysicianId',
                options: this.physicians,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.physicianrecruitment && this.physicianrecruitment.PhysicianId || null,
            }),
            PhysicianUserId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Physician User',
                name: 'PhysicianUserId',
                options: this.physicianUsers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.physicianrecruitment && this.physicianrecruitment.PhysicianUserId || null,
            }),
        };

        this.View = {
            Archived: new DynamicLabel({
			    label: 'Archived',
			    value: this.physicianrecruitment && this.physicianrecruitment.hasOwnProperty('Archived') && this.physicianrecruitment.Archived !== null ? this.physicianrecruitment.Archived : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            CreatedById: new DynamicLabel({
			    label: 'Created By',
			    value: getMetaItemValue(this.createdBies as unknown as IMetaItem[], this.physicianrecruitment && this.physicianrecruitment.hasOwnProperty('CreatedById') && this.physicianrecruitment.CreatedById !== null ? this.physicianrecruitment.CreatedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            DateCreated: new DynamicLabel({
			    label: 'Date Created',
			    value: this.physicianrecruitment && this.physicianrecruitment.DateCreated || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DateModified: new DynamicLabel({
			    label: 'Date Modified',
			    value: this.physicianrecruitment && this.physicianrecruitment.DateModified || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            ModifiedById: new DynamicLabel({
			    label: 'Modified By',
			    value: getMetaItemValue(this.modifiedBies as unknown as IMetaItem[], this.physicianrecruitment && this.physicianrecruitment.hasOwnProperty('ModifiedById') && this.physicianrecruitment.ModifiedById !== null ? this.physicianrecruitment.ModifiedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            PhysicianId: new DynamicLabel({
			    label: 'Physician',
			    value: getMetaItemValue(this.physicians as unknown as IMetaItem[], this.physicianrecruitment && this.physicianrecruitment.hasOwnProperty('PhysicianId') && this.physicianrecruitment.PhysicianId !== null ? this.physicianrecruitment.PhysicianId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            PhysicianUserId: new DynamicLabel({
			    label: 'Physician User',
			    value: getMetaItemValue(this.physicianUsers as unknown as IMetaItem[], this.physicianrecruitment && this.physicianrecruitment.hasOwnProperty('PhysicianUserId') && this.physicianrecruitment.PhysicianUserId !== null ? this.physicianrecruitment.PhysicianUserId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };

    }
}
