import { MiniDashboardListComponent } from './mini-dashboard-list/mini-dashboard-list.component';
import { NgModule } from '@angular/core';

import { AddressBooksSelectComponent } from './address-book-select-list/address-books-select.component';
import { AddressBooksSelectService } from './address-book-select-list/address-books-select.service';
import { SharedModule } from './shared.module';
import { AddressBooksSelectAddComponent } from './address-book-select-list/address-books-select-add.component';
import { AddressBooksAddIndividualComponent } from '../address-books/add/address-books-add-individual.component';
import { AddressBooksAddFacilityComponent } from '../address-books/add/address-books-add-facility.component';
import { PrimaryAddressComponent } from '../address-books/add/primary-address.component';
import { PrimaryEmailComponent } from '../address-books/add/primary-email.component';
import { PrimaryPhoneComponent } from '../address-books/add/primary-phone.component';
import { AddressBookService } from '../address-books/addressbook.service';
import { MetaAddressBookTypeService } from '../address-books/metaaddressbooktype.service';
import { AddressBookTypeService } from '../address-books/addressbooktype.service';
import { IndividualService } from '../address-books/individuals/individual.service';
import { AddressBasicInfoComponent } from '../address-books/addresses/address-basic-info/address-basic-info.component';
import { AddressDetailComponent } from '../address-books/addresses/address-detail/address-detail.component';
import { AddressHeaderComponent } from '../address-books/addresses/address-header/address-header.component';
import { AddressesComponent } from '../address-books/addresses/address-list/addresses.component';
import { AddressService } from '../address-books/addresses/address.service';
import { SelectedAddressBookDisplayComponent } from './address-book-select-list/selected-address-book-display/selected-address-book-display.component';
import { AddressBookSelectFiltersComponent } from './address-book-select-list/address-book-select-filters.component';
import { CommunicationMethodService } from './communication-methods/communication-methods.service';

@NgModule({
    declarations: [
        AddressBasicInfoComponent,
        AddressDetailComponent,
        AddressHeaderComponent,
        SelectedAddressBookDisplayComponent,
        AddressesComponent,
        AddressBooksSelectComponent,
        AddressBooksSelectAddComponent,
        AddressBooksAddIndividualComponent,
        AddressBooksAddFacilityComponent,
        PrimaryAddressComponent,
        PrimaryEmailComponent,
        PrimaryPhoneComponent,
        MiniDashboardListComponent,
        AddressBookSelectFiltersComponent,
    ],
    exports: [
        SharedModule,
        AddressBasicInfoComponent,
        AddressDetailComponent,
        AddressHeaderComponent,
        AddressesComponent,
        AddressBooksSelectComponent,
        AddressBooksSelectAddComponent,
        AddressBooksAddIndividualComponent,
        AddressBooksAddFacilityComponent,
        PrimaryAddressComponent,
        PrimaryEmailComponent,
        PrimaryPhoneComponent,
        MiniDashboardListComponent,
    ],
    imports: [SharedModule]
})
export class ImedCommonModule {
    static forRoot(): any {
        return {
            ngModule: ImedCommonModule,
            providers: [
                AddressService,
                AddressBooksSelectService,
                AddressBookService,
                MetaAddressBookTypeService,
                AddressBookTypeService,
                IndividualService,
                CommunicationMethodService,
            ],
        };
    }
}
