import { IImeContactInfo, IImedClaimServiceContactInfo } from './../../imed-claims/imed-claim-basic-info/imed-claim-contacts-info';
import { IAddressBooksForList } from '@model/interfaces/address-books-for-list.d';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import 'rxjs/operators';
import { Injectable } from '@angular/core';
import { IIMedClaimContactsInfo } from '../../imed-claims/imed-claim-basic-info/imed-claim-contacts-info';
import { IAddressBookSelectEntity } from './address-books-select-entity';
import { AppStateService, AppStates } from '../../app-state.service';

export const emptyAddressBookSelectEntity: IAddressBookSelectEntity = {
    AddressBookId: null,
    AddressBookName: null,
    Id: null,
    IndividualId: null,
    IndividualName: null,
    PhysicianId: null,
};

export const emptyIMedClaimContactsInfo: IIMedClaimContactsInfo = {
    AdjusterAddressBookId: null,
    AdjusterIndividualId: null,
    AdjusterIsDefault: false,
    AdjusterName: '',
    CarrierPartyAddressBookId: null,
    CarrierPartyIndividualId: null,
    CarrierPartyIsDefault: false,
    CarrierPartyName: '',
    CarriersCounselAddressBookId: null,
    CarriersCounselIndividualId: null,
    CarriersCounselIsDefault: false,
    CarriersCounselName: '',
    ClaimantsAttorneyAddressBookId: null,
    ClaimantsAttorneyIndividualId: null,
    ClaimantsAttorneyIsDefault: false,
    ClaimantsAttorneyName: '',
    DefenseAttorneyAddressBookId: null,
    DefenseAttorneyIndividualId: null,
    DefenseAttorneyIsDefault: false,
    DefenseAttorneyName: '',
    EmployerAddressBookId: null,
    EmployerIndividualId: null,
    EmployerIsDefault: false,
    EmployerName: '',
    OtherAddressBookId: null,
    OtherIndividualId: null,
    OtherIsDefault: false,
    OtherName: '',
    ParaLegalAddressBookId: null,
    ParaLegalIndividualId: null,
    ParaLegalIsDefault: false,
    ParaLegalName: '',
    PlantiffAttorneyAddressBookId: null,
    PlantiffAttorneyIndividualId: null,
    PlantiffAttorneyIsDefault: false,
    PlantiffAttorneyName: '',
};

export const emptyImeContactsInfo: IImeContactInfo = {
    TranslationServicesVendorAddressBookId: null,
    TranslationServicesVendorIndividualId: null,
    TranslationServicesVendorName: null,

    TransportationServicesVendorAddressBookId: null,
    TransportationServicesVendorIndividualId: null,
    TransportationServicesVendorName: null,

    BackgroundInvestigationVendorAddressBookId: null,
    BackgroundInvestigationVendorIndividualId: null,
    BackgroundInvestigationVendorName: null,

    SurveillanceVendorAddressBookId: null,
    SurveillanceVendorIndividualId: null,
    SurveillanceVendorName: null,

    TranscriptionServicesVendorAddressBookId: null,
    TranscriptionServicesVendorIndividualId: null,
    TranscriptionServicesVendorName: null,

    RentalFacilityVendorAddressBookId: null,
    RentalFacilityVendorIndividualId: null,
    RentalFacilityVendorName: null,
};

export const emptyImedClaimServiceContactInfo: IImedClaimServiceContactInfo = {
    AddressBookId: null,
    AddressBookName: null,
    AddressId: null,
    IndividualId: null,
    IndividualName: null,
    PhysicianId: null,
    PhysicianName: null,
};

export type AddressBookSelectedFunction = (addressBook: IAddressBookSelectEntity) => void;

@Injectable()
export class AddressBooksSelectService {
    protected emitChangeSource = new Subject<IAddressBookSelectEntity>();
    public changeEmitted$: Observable<IAddressBookSelectEntity> = this.emitChangeSource.asObservable();

    public typeFilter: string;
    public multiSelectMode = false;
    imedClaimTypeId: number;

    private selectFunction: AddressBookSelectedFunction;

    constructor(private appStateService: AppStateService) {}

    public emitChange(entity: IAddressBookSelectEntity): void {
        if (this.selectFunction) {
            const functionToCall = this.selectFunction;
            if (!this.multiSelectMode) {
                this.selectFunction = null;
            }
            functionToCall(entity);
        }
        if (this.appStateService.appState.getValue() !== AppStates.Main && !this.multiSelectMode) {
            this.resetAppState();
        }
        if (!this.multiSelectMode) {
            this.emitChangeSource.next(entity);
        }
    }

    resetAppState(): void {
        this.appStateService.appState.next(AppStates.Main);
    }

    openAddressBookSelect(typeFilter: string, selectFunction: AddressBookSelectedFunction, multiSelectMode = false, imedClaimTypeId = 0): void {
        this.multiSelectMode = multiSelectMode;
        this.typeFilter = typeFilter;
        this.imedClaimTypeId = imedClaimTypeId;
        this.selectFunction = selectFunction;
        this.appStateService.appState.next(AppStates.SearchAddressBook);
    }

    openAddressBookAdd(typeFilter?: string, selectFunction?: AddressBookSelectedFunction): void {
        if (typeFilter) {
            this.typeFilter = typeFilter;
        }
        if (selectFunction) {
            this.selectFunction = selectFunction;
        }
        this.appStateService.appState.next(AppStates.AddAddressBook);
    }

    addressBookSelectedOld(selectedEntity: IAddressBooksForList): void {
        const individualName =
            selectedEntity.FirstName && selectedEntity.LastName
                ? selectedEntity.FirstName + ' ' + selectedEntity.LastName
                : selectedEntity.LastName && !selectedEntity.FirstName
                ? selectedEntity.LastName
                : !selectedEntity.LastName && selectedEntity.FirstName
                ? selectedEntity.FirstName
                : null;
        const addressBookSelectEntity = this.createAddressBookSelect(
            selectedEntity.AddressBookId,
            selectedEntity.FacilityName,
            selectedEntity.IndividualId,
            individualName,
            selectedEntity.PhysicianId,
        );
        this.emitChange(addressBookSelectEntity);
    }

    addressBookSelected(selectedEntity: IAddressBookSelectEntity): void {
        selectedEntity.AddressBookType = this.typeFilter;
        this.emitChange(selectedEntity);
    }

    createAddressBookSelect(
        addressBookId: number,
        addressBookName: string,
        individualId: number,
        individualName: string,
        physicianId: number = null,
    ): IAddressBookSelectEntity {
        const newAddressBookSelect = { ...emptyAddressBookSelectEntity };
        newAddressBookSelect.AddressBookId = addressBookId;
        newAddressBookSelect.AddressBookName = addressBookName;
        newAddressBookSelect.IndividualId = individualId;
        newAddressBookSelect.IndividualName = individualName;
        newAddressBookSelect.PhysicianId = physicianId;
        newAddressBookSelect.AddressBookType = this.typeFilter;
        return newAddressBookSelect;
    }

    getEmptyAddressBookSelect(): IAddressBookSelectEntity {
        return { ...emptyAddressBookSelectEntity };
    }
}
