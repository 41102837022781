import { IImedClaimDTO } from '@model/interfaces/custom/imedclaim-dto.d';
import { IImedClaimService } from '@model/partials/imed-claim-service.partial.d';
import { AddressBooksSelectService } from './../common/address-book-select-list/address-books-select.service';
import { saveAs } from 'file-saver';
import { AttachmentTypeService } from './../common/attachments/attachment-type.service';
import { IDocumentQueueAttachmentPartial } from '@model/partials/document-queue-attachment-partial';
import { SearchParams, IEntitySearchParams, ExtraSearchParams } from '@mt-ng2/common-classes';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { DocumentQueueService } from './document-queue.service';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { ServiceService } from '../imed-claims/services/service.service';
import { UserService } from '../users/user.service';
import { IUser } from '@model/interfaces/user';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { BaseAttachmentsService } from '../common/attachments/base-attachments.service';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { IImedClaimServiceContactInfo } from '../imed-claims/imed-claim-basic-info/imed-claim-contacts-info';
import { IAddressBookSelectEntity } from '../common/address-book-select-list/address-books-select-entity';
import { IAttachmentType } from '@model/interfaces/attachment-type';
import { VirtualTypeAheadGetItemsFunction, ITypeAheadAPI } from '@mt-ng2/type-ahead-control';
import { ImedClaimService } from '../imed-claims/imedclaim.service';
import { IImedClaim } from '@model/interfaces/imed-claim';
import DocumentQueueAssignmentDTO from './document-queue-assignment-dto';
import {
    DocumentQueueAssignmentTypes,
    AttachmentTypeEnums,
    ServiceIdEnums,
    AttachmentServiceTypeEnums,
    DocumentTransactionTypeEnums,
    DocumentUploadSources,
} from '../common/constants/Enums';
import { DatePipe } from '@angular/common';
import { ImeServiceService } from '../imed-claims/services/service-specifics/ime-service/imeservice.service';
import { debounceTime } from 'rxjs/operators';
import { CommonSearchService } from '../common/services/common-search.service';
import { environment } from '../environments/environment';
import { DocumentTransactionLogService } from '../common/attachments/document-transaction-log.service';
import { IModalOptions, IModalWrapperApi } from '@mt-ng2/modal-module';
import { IDocumentGenerationDTO } from '@model/interfaces/custom/document-generation-dto';
import { FaxEmailService } from '@common/fax-email/fax-email.service';
import { ClosedServiceFilterPipe } from '../common/filters/closed-service-filter';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, IDynamicField, IDynamicFieldType } from '@mt-ng2/dynamic-form';
import { PhysicianService } from '../address-books/individuals/physicians/physician.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'document-queue',
    styles: [
        `
            .focusClass {
                background-color: rgb(170, 170, 170);
            }
            .modelDialog {
                max-height: 500px;
                max-width: 1000px;
                overflow: auto;
            }
            .accordion {
                background-color: rgb(170, 170, 170);
                color: #444;
                cursor: pointer;
                padding: 18px;
                width: 100%;
                border: none;
                text-align: left;
                outline: none;
                font-size: 15px;
                transition: 0.4s;
            }
        `,
    ],
    templateUrl: './document-queue-list.component.html',
})
export class DocumentQueueListComponent implements OnInit, OnDestroy {
    currentPage = 1;
    itemsPerPage = 12;
    checkAllSelection = false;
    total = 0;
    loading = false;
    isGl = false;
    isWC = false;
    isAuto = false;
    isPhysicianFeeSchedule = false;
    physicianName = '';
    claimaint = '';
    sender = '';
    users = [];
    selectedUser: IUser;
    documents: IDocumentQueueAttachmentPartial[];
    serviceTypes: MtSearchFilterItem[] = [];
    attachmentTypes: MtSearchFilterItem[] = [];
    selectDocumentType = 'Select Document Type';
    selectServiceType = 'Select Service Type';
    includeArchive = false;
    subscriptions = new Subscription();
    searchFormGroup: UntypedFormGroup;
    showAssignPanel = false;
    iImedClaimServiceContactInfo: IImedClaimServiceContactInfo = { PhysicianId: 0, PhysicianName: '' };
    documentTypes: IAttachmentType[];
    filteredDocumentTypes: IAttachmentType[];
    getItems: VirtualTypeAheadGetItemsFunction = this.getClaims.bind(this);
    services: IImedClaimService[] = [];
    selectedType: IAttachmentType;
    selectedService: IImedClaimService;
    selectedClaim: IImedClaimDTO | IImedClaim;
    virtualTypeAheadApi: ITypeAheadAPI;
    assignUser: IUser;
    hideCheckbox = false;
    prePopulatedDoc: IDocumentQueueAttachmentPartial[] = [];
    showReAssignPanel = false;
    assignedPhysician = '';
    assignedDocumentType = '';
    assignedClaimant = '';
    assignedService = '';
    assignedUser = '';
    clearfilter = false;
    noOfPages = 0;
    pages = 0;
    serviceTypeId = ServiceIdEnums.Record_Retrievals_HIPAA_Processing;
    recordRetrievedTypeId = AttachmentTypeEnums.Records_Retrieved;
    recordReceivedTypeId = AttachmentTypeEnums.Medical_Records_Received;
    link = '';
    showFilterPanel = true;
    docBody = '';
    dueDateFromTo: { startDate: null; endDate: null };
    pipe = new DatePipe('en-US');
    claimRequestFormUploadType = DocumentUploadSources.Claim_Request_Forms;
    clientPortalUploadType = DocumentUploadSources.Client_Portal_Upload;
    attachmentServiceTypeEnums = AttachmentServiceTypeEnums;
    commonSearchParams: SearchParams;

    modalOptions: IModalOptions = {
        width: 1000,
    };
    emailBodyModalApi: IModalWrapperApi;

    constructor(
        private documentQueueService: DocumentQueueService,
        private serviceService: ServiceService,
        private userservice: UserService,
        private attachmentTypeService: AttachmentTypeService,
        private notificationService: NotificationsService,
        private attachmentsService: BaseAttachmentsService,
        private addressSelectService: AddressBooksSelectService,
        private imedClaimsService: ImedClaimService,
        private physicianService: PhysicianService,
        private imeServiceService: ImeServiceService,
        private commonSearchService: CommonSearchService,
        private notificationsService: NotificationsService,
        private docTransactionLogService: DocumentTransactionLogService,
        private closedServiceFilter: ClosedServiceFilterPipe,
        protected faxEmailService: FaxEmailService,
    ) {}

    ngOnInit(): void {
        this.dueDateFromTo = { startDate: null, endDate: null };
        this.searchFormGroup = new UntypedFormGroup({
            searchClaimName: new UntypedFormControl(''),
            searchPhysicianName: new UntypedFormControl(''),
            searchSender: new UntypedFormControl(''),
        });

        this.commonSearchService.getCommonSearchFilters().subscribe((filters) => {
            this.commonSearchParams = filters ? filters.searchFilters : null;
            this.commonSearchService.clearCommonSearchFilters();
        });

        this.subscribeToUsers();

        forkJoin(this.serviceService.getServiceTypeSortedList(), this.attachmentTypeService.getItems()).subscribe((response) => {
            const [serviceTypes, attachmentTypes] = response;
            this.serviceTypes = serviceTypes;

            this.users.unshift({ Id: 0, FirstName: 'Select', LastName: 'User' });
            this.selectedUser = this.users[0];
            this.assignUser = this.users[0];
            this.attachmentTypes = attachmentTypes.map((item) => new MtSearchFilterItem(item, false));
            this.getDocuments();
        });
        this.subscriptions.add(
            this.searchFormGroup
                .get('searchPhysicianName')
                .valueChanges.pipe(debounceTime(400))
                .subscribe((value) => {
                    this.physicianName = value;
                    this.rebindDocumentList();
                }),
        );

        this.subscriptions.add(
            this.searchFormGroup
                .get('searchClaimName')
                .valueChanges.pipe(debounceTime(400))
                .subscribe((value) => {
                    this.claimaint = value;
                    this.rebindDocumentList();
                }),
        );

        this.subscriptions.add(
             this.searchFormGroup
                 .get('searchSender')
                 .valueChanges.pipe(debounceTime(400))
                 .subscribe((value) => {
                     this.sender = value;
                     this.rebindDocumentList();
                 }),
         );

        this.subscriptions.add(
            this.addressSelectService.changeEmitted$.subscribe(() => {
                this.services = [];
                this.filteredDocumentTypes = [];
                this.virtualTypeAheadApi.clearValue();
                this.assignUser = this.users[0];
                this.clearfilter = false;
                this.filterDocumentTypeBasedOnSelection(AttachmentServiceTypeEnums.Address_Book);
            }),
        );
    }

    subscribeToUsers(): void {
        this.subscriptions.add(
            this.userservice.getAll().subscribe((users) => {
                this.users = users;
            }),
        );
    }
    includeArchivedChanged(event): void {
        this.includeArchive = event.value;
        this.getDocuments();
    }
    resetFilters(): void {
        location.reload();
    }

    toggleFilterPanel(event: Event): void {
        event.stopPropagation();
        event.preventDefault();
        this.showFilterPanel = !this.showFilterPanel;
    }

    rebindDocumentList(): void {
        this.currentPage = 1;
        this.getDocuments();
    }

    getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    openPDFInNewTab(document: IDocumentQueueAttachmentPartial): void {
        window.open(environment.docPath + document.FilePath, '_blank');
    }

    downloadDoc(doc: IDocumentQueueAttachmentPartial, event: Event): void {
        event.stopPropagation();
        this.attachmentsService.downloadEmailedDocument('imed-claim', doc.Id).subscribe(
            (x) => {
                saveAs(x, doc.OriginalFileName);
            },
            (err) => {
                this.docTransactionLogService.logError(err as HttpErrorResponse, doc.Id, DocumentTransactionTypeEnums.MANUAL_DOWNLOAD_FE).subscribe();
                this.notificationsService.error('Document download failed');
            },
        );
    }
    copyDoc(doc: IDocumentQueueAttachmentPartial, event: Event): void {
        event.stopPropagation();
        this.documentQueueService.copy(doc.Id).subscribe(() => {
            this.getDocuments();
            this.notificationService.success('Record copied successfully');
        });
    }
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    setDueDates(event): void {
        this.dueDateFromTo = event;
        this.getDocuments();
    }

    getDocuments(): void {
        const params = this.buildParams();
        const searchEntity: IEntitySearchParams = {
            extraParams: params,
            order: null,
            orderDirection: null,
            query: '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };
        const searchParams = new SearchParams(searchEntity);
        this.documentQueueService.getAllDocuments(searchParams).subscribe((response) => {
            this.documents = response.body;
            this.total = response.headers.get('X-List-Count');
            this.loading = true;
            this.showReAssignPanel = false;
            this.showAssignPanel = false;

            if (this.commonSearchParams) {
                if (this.documents && this.documents.length > 0) {
                    this.showAssignmentPanel(this.documents[0]);
                }
                this.commonSearchParams = null;
            }
        });
    }

    // Clear out common search params before getting next results
    clearCommonSearchParamsAndSearch(): void {
        this.commonSearchParams = null;
        this.currentPage = 1;
        this.getDocuments();
    }

    buildParams(): ExtraSearchParams[] {
        const _extraSearchParams: ExtraSearchParams[] = [];
        const selectedServiceTypeIds: number[] = this.getSelectedFilters(this.serviceTypes);
        const selectedDocumentTypeIds: number[] = this.getSelectedFilters(this.attachmentTypes);
        if (this.physicianName) {
            this.hideCheckbox = true;
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'Physician',
                    value: this.physicianName,
                }),
            );
        }
        if (this.claimaint) {
            this.hideCheckbox = true;
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'Claimaint',
                    value: this.claimaint,
                }),
            );
        }
        if (this.sender) {
            this.hideCheckbox = true;
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'Sender',
                    value: this.sender,
                }),
            );
        }
        if (selectedServiceTypeIds.length) {
            this.hideCheckbox = true;
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'ServiceIds',
                    valueArray: selectedServiceTypeIds,
                }),
            );
        }
        if (this.selectedUser.Id) {
            this.hideCheckbox = true;
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'UserId',
                    value: this.selectedUser.Id.toString(),
                }),
            );
        }
        if (selectedDocumentTypeIds.length) {
            this.hideCheckbox = true;
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'DocumentTypeIds',
                    valueArray: selectedDocumentTypeIds,
                }),
            );
        }
        if (this.includeArchive) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'Archived',
                    value: 'true',
                }),
            );
        }
        if (this.dueDateFromTo && this.dueDateFromTo.startDate) {
            this.hideCheckbox = true;
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'DueDateFrom',
                    value: this.pipe.transform(this.dueDateFromTo.startDate, 'M/d/yyyy'),
                }),
            );
        }
        if (this.dueDateFromTo && this.dueDateFromTo.endDate) {
            this.hideCheckbox = true;
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'DueDateTo',
                    value: this.pipe.transform(this.dueDateFromTo.endDate, 'M/d/yyyy'),
                }),
            );
        }

        if (this.commonSearchParams) {
            this.hideCheckbox = true;
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'DocumentQueueAttachmentId',
                    value: this.commonSearchParams.query,
                }),
            );
        }
        if (
            !this.commonSearchParams &&
            !this.physicianName &&
            !this.claimaint &&
            !this.sender &&
            !selectedServiceTypeIds.length &&
            !this.selectedUser.Id &&
            !selectedDocumentTypeIds.length &&
            !(this.dueDateFromTo.startDate || this.dueDateFromTo.endDate)
        ) {
            this.hideCheckbox = false;
            this.prePopulatedDoc = [];
        }

        return _extraSearchParams;
    }

    searchAddressBooks(addressBookType: string): void {
        this.addressSelectService.openAddressBookSelect(addressBookType, this.assignContact);
    }

    assignContact = (addressBookSelect: IAddressBookSelectEntity): void => {
        this.iImedClaimServiceContactInfo[addressBookSelect.AddressBookType + 'Name'] = addressBookSelect.IndividualName;
        this.iImedClaimServiceContactInfo[addressBookSelect.AddressBookType + 'Id'] = addressBookSelect.PhysicianId;
    };

    virtualTypeAheadControlReady(controlApi: ITypeAheadAPI): void {
        this.virtualTypeAheadApi = controlApi;
    }

    filterCheckedItem(): IDocumentQueueAttachmentPartial[] {
        return this.documents.filter((item) => item.checked === true);
    }

    checkAll(): void {
        this.documents.forEach((item) => {
            if (this.checkAllSelection) {
                item.checked = true;
            } else {
                item.checked = false;
            }
        });

        this.showAssignmentPanel();
    }

    toggleArchive(doc: IDocumentQueueAttachmentPartial, event): void {
        event.preventDefault();
        event.stopPropagation();
        doc.Archived = !doc.Archived;
        this.documentQueueService.toggleArchive(doc.Id).subscribe(() => {
            if (doc.Archived) {
                this.notificationService.success('Archived successfully');
            } else {
                this.notificationService.success('UnArchived successfully');
            }
            this.getDocuments();
        });
    }

    showAssignmentPanel(document: IDocumentQueueAttachmentPartial = null): void {
        if (document && !this.hideCheckbox) {
            document.checked = !document.checked;
        }
        const checkedDocs = this.documents.filter((item) => {
            return item.checked === true;
        });
        if (checkedDocs.length) {
            this.showAssignPanel = true;
        } else {
            this.showAssignPanel = false;
        }
        if (document && this.hideCheckbox) {
            this.prePopulateAssignmentPanel(document);
            this.prePopulatedDoc = [];
            this.prePopulatedDoc.push(document);
            this.showReAssignPanel = true;
        }
    }
    prePopulateAssignmentPanel(document: IDocumentQueueAttachmentPartial): void {
        this.assignedPhysician = '';
        this.assignedDocumentType = '';
        this.assignedClaimant = '';
        this.assignedService = '';
        this.assignedUser = '';
        if (document.AssignmentTypeId === +DocumentQueueAssignmentTypes.Physicians) {
            this.assignedPhysician = document.Physicians[0].Individual.FirstName + ' ' + document.Physicians[0].Individual.LastName;
            this.assignedDocumentType = document.AttachmentType.Name;
            this.link = `/address-books/${document.Physicians[0].Id}/individuals/${document.Physicians[0].Individual.Id}`;
        }

        if (document.AssignmentTypeId === +DocumentQueueAssignmentTypes.Services) {
            this.assignedService = document.ImedClaimServices[0].Service.Name;
            this.assignedClaimant = document.ImedClaimServices[0].ImedClaim.FirstName + ' ' + document.ImedClaimServices[0].ImedClaim.LastName;
            this.assignedDocumentType = document.AttachmentType.Name;
            this.link = `/cases/${document.ImedClaimServices[0].ImedClaim.Id}/services/${document.ImedClaimServices[0].Id}`;
        }

        if (document.AssignmentTypeId === +DocumentQueueAssignmentTypes.Claims) {
            this.assignedClaimant = document.ImedClaims[0].FirstName + ' ' + document.ImedClaims[0].LastName;
            this.assignedDocumentType = document.AttachmentType.Name;
            this.link = `/cases/${document.ImedClaims[0].Id}`;
        }
        if (document.AssignedToUserId) {
            this.assignedUser = document.AssignedToUser.FirstName + ' ' + document.AssignedToUser.LastName;
        }
    }

    clearAssignmentFilter(): void {
        this.iImedClaimServiceContactInfo = { PhysicianId: 0, PhysicianName: '' };
        this.services = [];
        this.filteredDocumentTypes = [];
        this.clearfilter = true;
        if (this.virtualTypeAheadApi) {
            this.virtualTypeAheadApi.clearValue();
        }
        this.assignUser = this.users[0];
        this.selectedClaim = null;
        this.selectedType = null;
        this.noOfPages = 0;
        this.isPhysicianFeeSchedule = false;
        this.isGl = false;
        this.isWC = false;
        this.isAuto = false;
    }

    filterDocumentTypeBasedOnSelection(serviceType: AttachmentServiceTypeEnums): void {
        if (!this.clearfilter) {
            this.attachmentTypeService
                .getAttachmentTypes(serviceType)
                .pipe(debounceTime(400))
                .subscribe((types) => {
                    if (this.selectedService && this.selectedService.Id) {
                        if (
                            serviceType === AttachmentServiceTypeEnums.IME ||
                            serviceType === AttachmentServiceTypeEnums.IME_Addendum ||
                            serviceType === AttachmentServiceTypeEnums.IME_Re_Exam ||
                            serviceType === AttachmentServiceTypeEnums.IME_Expert_Consultation ||
                            serviceType === AttachmentServiceTypeEnums.IME_Conference_Call
                        ) {
                            this.imeServiceService.getByImedClaimServiceId(this.selectedService.Id).subscribe((service) => {
                                if (service.FilmReviewId == null) {
                                    this.filteredDocumentTypes = types.filter((item) => item.Id !== +AttachmentTypeEnums.Film_Review_Cover_Letter);
                                }
                            });
                        } else {
                            this.filteredDocumentTypes = types;
                        }
                    }
                    this.filteredDocumentTypes = types;
                    this.selectedType = this.filteredDocumentTypes[0];
                });
        }
    }

    filterDocumentTypeBasedOnServiceType(serviceId: number): void {
        this.attachmentTypeService
            .mapServiceToAttachmentServiceType(serviceId)
            .subscribe((response) => this.filterDocumentTypeBasedOnSelection(response));
    }

    getClaims(searchText: string): Observable<any[]> {
        const searchEntity: IEntitySearchParams = {
            query: searchText,
        };
        return this.imedClaimsService.getClaimsByName(searchEntity);
    }

    selectionChanged(event): void {
        this.selectedService = null;
        this.services = [];
        if (event.selection) {
            this.selectedClaim = event.selection;
            this.getImedClaimById(event.selection.Id as number);
            this.iImedClaimServiceContactInfo = { PhysicianId: 0, PhysicianName: '' };
            this.isPhysicianFeeSchedule = false;
            this.isGl = false;
            this.isWC = false;
            this.isAuto = false;
            this.assignUser = this.users[0];
            this.filterDocumentTypeBasedOnSelection(this.attachmentServiceTypeEnums.Case);
        } else {
            this.filteredDocumentTypes = [];
            this.selectedType = null;
            this.selectedClaim = null;
        }
    }
    assignEntity(): void {
        if (!this.assignUser.Id && !this.iImedClaimServiceContactInfo.PhysicianId && !this.selectedClaim) {
            this.notificationService.error('Select an assignment entity type');
            return;
        }
        if (!this.selectedType && !(this.assignUser.Id > 0)) {
            this.notificationService.error('Select document type');
            return;
        }
        if (this.isPhysicianFeeSchedule && !this.isCaseTypeSelected()) {
            this.notificationsService.error('Select a case type');
            return;
        }
        let assignmentTypeId = 0;
        if (this.iImedClaimServiceContactInfo.PhysicianId) {
            assignmentTypeId = DocumentQueueAssignmentTypes.Physicians;
        } else if (this.selectedService) {
            assignmentTypeId = DocumentQueueAssignmentTypes.Services;
        } else if (this.selectedClaim) {
            assignmentTypeId = DocumentQueueAssignmentTypes.Claims;
        } else {
            assignmentTypeId = DocumentQueueAssignmentTypes.User;
        }
        if (this.prePopulatedDoc.length) {
            if (this.iImedClaimServiceContactInfo.PhysicianId) {
                this.formObjectAndCallService(
                    this.iImedClaimServiceContactInfo.PhysicianId,
                    this.prePopulatedDoc,
                    this.selectedType.Id,
                    assignmentTypeId,
                );
            } else if (this.selectedService) {
                this.formObjectAndCallService(this.selectedService.Id, this.prePopulatedDoc, this.selectedType.Id, assignmentTypeId);
            } else if (this.selectedClaim) {
                this.formObjectAndCallService(this.selectedClaim.Id, this.prePopulatedDoc, this.selectedType.Id, assignmentTypeId);
            } else {
                this.formObjectAndCallService(this.assignUser.Id, this.prePopulatedDoc, null, assignmentTypeId);
            }
        } else {
            if (this.iImedClaimServiceContactInfo.PhysicianId) {
                this.formObjectAndCallService(
                    this.iImedClaimServiceContactInfo.PhysicianId,
                    this.filterCheckedItem(),
                    this.selectedType.Id,
                    assignmentTypeId,
                );
            } else if (this.selectedService) {
                this.formObjectAndCallService(this.selectedService.Id, this.filterCheckedItem(), this.selectedType.Id, assignmentTypeId);
            } else if (this.selectedClaim) {
                this.formObjectAndCallService(this.selectedClaim.Id, this.filterCheckedItem(), this.selectedType.Id, assignmentTypeId);
            } else {
                this.formObjectAndCallService(this.assignUser.Id, this.filterCheckedItem(), null, assignmentTypeId);
            }
        }
    }

    isCaseTypeSelected(): boolean {
        return this.isGl || this.isWC || this.isAuto;
    }

    formObjectAndCallService(...args: any[]): void {
        const assignmentId: number = args[0];
        const documentQueueAttachments: IDocumentQueueAttachmentPartial[] = args[1];
        const documentTypeId: any = args[2];
        const assignmentTypeId: number = args[3];
        const obj = new DocumentQueueAssignmentDTO({
            assignmentId: assignmentId,
            documentQueueAttachments: documentQueueAttachments,
            documentTypeId: documentTypeId,
            noOfPages: this.noOfPages,
            userId: this.assignUser.Id > 0 ? this.assignUser.Id : null,
        });
        this.documentQueueService.assignEntityType(assignmentTypeId, obj).subscribe(() => {
            if (obj.documentTypeId === +AttachmentTypeEnums.Records_Retrieved) {
                this.generateAndUploadRetrievalRelatedCover('records-retrieval-simple', this.selectedService.Id);
            } else if (obj.documentTypeId === +AttachmentTypeEnums.Rejection) {
                this.generateAndUploadRetrievalRelatedCover('records-rejection-letter', this.selectedService.Id);
            } else if (obj.documentTypeId === +AttachmentTypeEnums.No_Records_On_File) {
                this.generateAndUploadRetrievalRelatedCover('no-records-on-file', this.selectedService.Id);
            } else if (obj.documentTypeId === +AttachmentTypeEnums.Undeliverable) {
                this.generateAndUploadRetrievalRelatedCover('undeliverable-records', this.selectedService.Id);
            } else if (obj.documentTypeId === +AttachmentTypeEnums.Addl_Records_Retrieved) {
                this.generateAndUploadRetrievalRelatedCover('additional-records-retrieved', this.selectedService.Id);
            }
            if (this.isPhysicianFeeSchedule) {
                this.physicianService
                    .updateFeeScheduleReceivedDate(this.iImedClaimServiceContactInfo.PhysicianId, this.isGl, this.isWC, this.isAuto)
                    .subscribe((service) => {
                        this.getDocumentsAndNotify();
                    });
            } else {
                this.getDocumentsAndNotify();
            }
        });
    }

    getDocumentsAndNotify(): void {
        this.getDocuments();
        this.notificationService.success('Assignment completed');
    }

    generateAndUploadRetrievalRelatedCover(documentType: string, entityId: number): void {
        this.faxEmailService.generateDocuments(documentType, this.createDocDTO(entityId)).subscribe();
    }

    createDocDTO(entityId: number): IDocumentGenerationDTO {
        return { EntityId: entityId, Extras: null };
    }

    getImedClaimById(Id: number): void {
        this.imedClaimsService.getByIdForDocQueue(Id).subscribe((imedClaim) => {
            this.services = imedClaim.ImedClaimServices.filter((service) => service.Archived === false).sort((a, b) => {
                return a.Id - b.Id;
            });
            this.services = this.closedServiceFilter.transform(this.services);
            this.selectedService = null;
            this.clearfilter = false;
        });
    }
    setNoOfPagesFlag(): void {
        if (this.selectedType) {
            this.setIsPhysicianFeeSchedule(this.selectedType.Id);
            if (
                !(
                    this.selectedType.Id === +AttachmentTypeEnums.Records_Retrieved ||
                    this.selectedType.Id === +AttachmentTypeEnums.Medical_Records_Received
                )
            ) {
                this.noOfPages = 0;
            }
        } else {
            this.noOfPages = 0;
        }
    }

    setIsPhysicianFeeSchedule(selectedAttachmentTypeId: number): void {
        if (this.iImedClaimServiceContactInfo.PhysicianId && selectedAttachmentTypeId === +AttachmentTypeEnums.Fee_Schedule) {
            this.isPhysicianFeeSchedule = true;
        } else {
            this.isPhysicianFeeSchedule = false;
            this.isWC = false;
            this.isGl = false;
            this.isAuto = false;
        }
    }

    showBodyDialog(body: string, event: Event): void {
        event.stopPropagation();
        event.preventDefault();
        this.docBody = body;
        this.emailBodyModalApi.show();
    }

    getClaimTypeDynamicField(label: string, fieldName: string): DynamicField {
        return new DynamicField({
            formGroup: null,
            label: label,
            name: fieldName,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Checkbox,
            } as IDynamicFieldType),
            value: false,
        } as IDynamicField);
    }
}
