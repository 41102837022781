import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Chart } from 'chart.js';
import { DashboardService, IActiveService } from '../dashboard.service';
import { SearchParams, IEntitySearchParams, ExtraSearchParams } from '@mt-ng2/common-classes';
import { CommonSearchService, ICommonSearch } from '../../common/services/common-search.service';
import { MtSearchFilterDaterangeComponent, IContextualDate, ContextualDates } from '@mt-ng2/search-filter-daterange-control';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-active-services-widget',
    styleUrls: ['../base-dashboard-component/base-dashboard-common-css.component.less'],
    templateUrl: './active-services-widget.component.html',
})
export class ActiveServicesWidgetComponent implements OnInit {
    // Date picker config
    contextualDateType = ContextualDates.ThisWeek;
    today = new Date();
    minDate = new Date(this.today.getFullYear() - 1, this.today.getMonth(), this.today.getDate());
    maxDate = new Date(this.today.getFullYear() + 1, this.today.getMonth(), this.today.getDate());
    entityName = 'Date Range';
    startDate = this.dashboardService.getStartDateWeek();
    endDate = this.dashboardService.getEndDateWeek();
    chart;
    defaultDateRange = { startDate: this.startDate, endDate: this.endDate };

    @ViewChild('activeServiceChart') activeServiceChartRef: ElementRef;
    @ViewChild('datePicker', { static: true }) datePicker: MtSearchFilterDaterangeComponent;

    activeServiceChart: any;
    activeService: IActiveService[];

    contextualDates: IContextualDate[] = [
        ContextualDates.ThisWeek,
        ContextualDates.ThisMonth,
        ContextualDates.ThisQuarter,
        ContextualDates.ThisYear,
    ];

    constructor(
        private router: Router,
        private dashboardService: DashboardService,
        private commonSearchService: CommonSearchService,
        private datePipe: DatePipe,
    ) { }

    renderActiveServiceChart(event): void {
        if (event.startDate == null) {
            event.startDate = this.startDate;
        }

        if (event.endDate == null) {
            event.endDate = this.endDate;
        }
        const utcStartDate = this.datePipe.transform(event.startDate as string, 'M/d/yyyy', 'UTC');

        let utcEndDate = this.datePipe.transform(event.endDate as string, 'M/d/yyyy', 'UTC');

        utcEndDate = utcEndDate + ' 23:59:59';

        this.dashboardService.getActiveServices(utcStartDate, utcEndDate).subscribe((responses: IActiveService[]) => {
            this.activeService = responses;
            const labels = responses.map((response: IActiveService) => response.Service);
            const data = responses.map((response: IActiveService) => this.getActiveServiceObject(response));

            data.push(0);
            if (this.activeServiceChart) {
                this.activeServiceChart.destroy();
            }
            this.activeServiceChart = new Chart(this.activeServiceChartRef.nativeElement, {
                data: {
                    datasets: [
                        {
                            backgroundColor: this.dashboardService.poolColors(labels.length),
                            data: data,
                            fillColor: this.dashboardService.poolColors(labels.length),
                            highlightFill: this.dashboardService.poolColors(labels.length),
                            highlightStroke: this.dashboardService.poolColors(labels.length),
                            label: 'Active Service',
                            strokeColor: this.dashboardService.poolColors(labels.length),
                        },
                    ],
                    labels: labels,
                },
                options: {
                    legend: { display: false },
                    onHover: (e, el) => {
                        e.target.style.cursor = el[0] ? 'pointer' : 'default';
                    },
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                    precision: 0,
                                },
                            },
                        ],
                    },
                    title: {
                        display: true,
                        text: 'Active Services',
                    },
                },
                type: 'bar',
            });
        });
    }

    getActiveServiceObject(response: IActiveService): any {
        return { y: response.Count, activeService: response };
    }

    ngOnInit(): void {
        this.renderActiveServiceChart(this.defaultDateRange);
    }

    showActiveServiceData(evt: any): void {
        const data = this.activeServiceChart.getElementsAtEvent(evt);
        if (data.length) {
            const firstPoint = data[0];
            const value = this.activeServiceChart.data.datasets[firstPoint._datasetIndex].data[firstPoint._index];
            if (evt.startDate == null) {
                evt.startDate = this.startDate;
            }

            if (evt.endDate == null) {
                evt.endDate = this.endDate;
            }
            const utcStartDate = this.datePipe.transform(evt.startDate as string, 'M/d/yyyy', 'UTC');

            let utcEndDate = this.datePipe.transform(evt.endDate as string, 'M/d/yyyy', 'UTC');

            utcEndDate = utcEndDate + ' 23:59:59';
            const _extraSearchParams: ExtraSearchParams[] = [];
            const _activeServiceTypeIds: number[] = [value.activeService.ServiceId];
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'activeServiceTypeIds',
                    valueArray: [..._activeServiceTypeIds],
                }),
            );
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'activeDateFrom',
                    value: utcStartDate,
                }),
            );
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'activeDateTo',
                    value: utcEndDate,
                }),
            );

            const searchEntity: IEntitySearchParams = {
                extraParams: _extraSearchParams,
                order: 'ClaimantName',
                orderDirection: 'Asc',
                query: '',
            };

            const searchFilter: ICommonSearch = {
                dashboardName: 'Active Services',
                searchFilters: new SearchParams(searchEntity),
            };

            this.commonSearchService.setCommonSearchFilters(searchFilter);

            void this.router.navigate(['/cases/0/services']);
        }
    }
}
