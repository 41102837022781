import { ImeAppointmentDynamicControlsPartial } from '@model/partials/ime-appointment-partial.form-controls';
import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';
import { IImeAppointmentDynamicControlsParameters } from '@model/form-controls/ime-appointment.form-controls';
import { IImeAppointment } from '@model/interfaces/ime-appointment';

export class AutoImeAppointmentDynamicConfig<T extends IImeAppointment> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private imeAppointment: T, private configControls?: string[]) {
        super();
        const additionalParams: IImeAppointmentDynamicControlsParameters = {};
        const dynamicControls = new ImeAppointmentDynamicControlsPartial(this.imeAppointment, additionalParams);

        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['ImedClaimServiceId', 'AppointmentDateTime', 'CreatedById', 'DateCreated', 'Archived'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
