import { ILabelGenerator } from '../../../common/interfaces/ILabelGenerator.d';
import { DatePipe } from '../../../../../node_modules/@angular/common';
import { IPhysicianVacation } from '@model/interfaces/physician-vacation';

export class PhysicianVacationLabelGenerator implements ILabelGenerator {
    constructor() { }

    GetLabel(entity: IPhysicianVacation): string {
        return (
            new DatePipe('en-US').transform(entity.FromDate, 'MMMM d, yyyy', 'UTC') +
            ' to ' +
            new DatePipe('en-US').transform(entity.ToDate, 'MMMM d, yyyy', 'UTC')
        );
    }
}
