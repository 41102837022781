// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.widget .action {
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.08);
  position: absolute;
  height: 40px;
}
.panel .panel-heading {
  font-size: 16px;
  font-weight: bold;
}
.panel .panel-heading select {
  font-size: 14px;
  font-weight: 400;
}
.widget .action-bottom {
  padding-bottom: 40px;
}
.widget .action-bottom .contentarea {
  padding-bottom: 15px;
}
.widget {
  position: relative;
}
.action {
  text-align: center;
  padding: 10px;
  font-weight: bold;
}
.resize-block-element {
  height: 34px;
}
.search-list {
  max-height: 300px;
  overflow-y: auto;
}
.pointer {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/app/dashboard/base-dashboard-component/base-dashboard-common-css.component.less"],"names":[],"mappings":"AAAA;EACI,SAAA;EACA,OAAA;EACA,WAAA;EACA,qCAAA;EACA,kBAAA;EACA,YAAA;AACJ;AAEA;EAEQ,eAAA;EACA,iBAAA;AADR;AAFA;EAKY,eAAA;EACA,gBAAA;AAAZ;AAKA;EACI,oBAAA;AAHJ;AAMA;EACI,oBAAA;AAJJ;AAOA;EACI,kBAAA;AALJ;AAQA;EACI,kBAAA;EACA,aAAA;EACA,iBAAA;AANJ;AASA;EACI,YAAA;AAPJ;AAUA;EACI,iBAAA;EACA,gBAAA;AARJ;AAWA;EACI,eAAA;AATJ","sourcesContent":[".widget .action {\n    bottom: 0;\n    left: 0;\n    width: 100%;\n    background-color: rgba(0, 0, 0, 0.08);\n    position: absolute;\n    height: 40px;\n}\n\n.panel {\n    .panel-heading {\n        font-size: 16px;\n        font-weight: bold;\n        select {\n            font-size: 14px;\n            font-weight: 400;\n        }\n    }\n}\n\n.widget .action-bottom {\n    padding-bottom: 40px;\n}\n\n.widget .action-bottom .contentarea {\n    padding-bottom: 15px;\n}\n\n.widget {\n    position: relative;\n}\n\n.action {\n    text-align: center;\n    padding: 10px;\n    font-weight: bold;\n}\n\n.resize-block-element {\n    height: 34px;\n}\n\n.search-list {\n    max-height: 300px;\n    overflow-y: auto;\n}\n\n.pointer {\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
