import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IImedClaimType } from '@model/interfaces/imed-claim-type';

@Injectable()
export class ImedClaimAssociationSelectionService {
    private _imedClaimType: BehaviorSubject<IImedClaimType> = new BehaviorSubject<IImedClaimType>(null);

    constructor() { }

    setAssociationType(value: IImedClaimType): void {
        this._imedClaimType.next(value);
    }

    getAssociationType(): Observable<IImedClaimType> {
        return this._imedClaimType.asObservable();
    }
}
