import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { UserService } from '../user.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { environment } from '../../environments/environment';
import { FileItem } from 'ng2-file-upload';
import { AttachmentControlComponent } from '../../common/attachment-control/attachment-control.component';

@Component({
    selector: 'app-user-signature-photo',
    templateUrl: './user-signature.component.html',
})
export class UserSignaturePhotoComponent implements OnInit {
    @Input() user: any;
    @Input() canEdit: any;
    @ViewChild('docComponent') docComponent: AttachmentControlComponent;

    isHovered: boolean;
    isEditing: boolean;
    errorMessage: string;
    imagePath: string;
    croppedFile: any;

    constructor(private notificationsService: NotificationsService, private userService: UserService) {}

    ngOnInit(): void {
        this.imagePath = environment.imgPath;
        this.isEditing = false;
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    savePhoto(): any {
        const file: FileItem = this.docComponent.uploader.queue[this.docComponent.uploader.queue.length - 1];
        if (file) {
            this.userService.saveSignaturePhoto(this.user.Id as number, file._file).subscribe((answer) => {
                this.user.SignatureImage = answer.Image;
                this.user.SignatureImageId = answer.Image.Id;
                this.user.Version = answer.Version;
                this.isEditing = false;
                this.notificationsService.success('User Signature Photo Saved Successfully');
            });
        }
    }

    deletePhoto(): void {
        this.userService.deleteSignaturePhoto(this.user.Id as number).subscribe(() => {
            this.user.SignatureImage = null;
            this.user.SignatureImageId = null;
            this.isEditing = false;
            this.notificationsService.success('User Signature Photo Deleted Successfully');
        });
    }
}
