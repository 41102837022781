import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { IImedClaimAdditionalOtherDTO } from '@model/interfaces/custom/imed-claim-additional-other.dto';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { finalize } from 'rxjs/operators';
import { ImedClaimAdditionalOtherService } from './imed-claim-additional-others.service';

@Component({
    selector: 'app-imed-claim-additional-others',
    styles: ['.custom-padded { margin: 0 0 0.5em 0; }'],
    templateUrl: './imed-claim-additional-others.component.html',
})
export class ImedClaimAdditionalOthersComponent implements OnInit, OnDestroy {
    @Input() imedClaimId: number;
    @Input() canEdit: boolean;
    isHovered = false;
    doubleClickIsDisabled = false;

    additionalOthers: IImedClaimAdditionalOtherDTO[] = [];
    _imedClaimAdditionalOthersService: ImedClaimAdditionalOtherService;

    _isEditing: boolean;
    public set isEditing(v: boolean) {
        this._isEditing = v;
    }

    public get isEditing(): boolean {
        return this._isEditing;
    }

    constructor(private imedClaimAdditionalOthersService: ImedClaimAdditionalOtherService, private notificationsService: NotificationsService) {
        this._imedClaimAdditionalOthersService = imedClaimAdditionalOthersService;
    }

    ngOnInit(): void {
        this.isEditing = false;
        this.getAdditionalOthers();
        this.addMore();
    }

    ngOnDestroy(): void {
        this.imedClaimAdditionalOthersService.resetAdditionalOthers();
    }

    edit(): void {
        if (this.imedClaimAdditionalOthersService.newAdditionalOthers.length === 0) {
            this.addMore();
        }
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    addMore(): void {
        if (this.imedClaimAdditionalOthersService.newAdditionalOthers.length === 0 || this.isAdditionalOtherSelected()) {
            this.imedClaimAdditionalOthersService.newAdditionalOthers.push(this.imedClaimAdditionalOthersService.getEmptyImedClaimAdditionalOther());
        }
    }

    isAdditionalOtherSelected(): boolean {
        return (
            this.imedClaimAdditionalOthersService.newAdditionalOthers.length > 0 &&
            !(this.imedClaimAdditionalOthersService.newAdditionalOthers.filter((other) => other.AddressBookId === 0).length !== 0)
        );
    }

    getAdditionalOthers(): void {
        this.imedClaimAdditionalOthersService.getAdditionalOthers(this.imedClaimId).subscribe((incomingDTOs: IImedClaimAdditionalOtherDTO[]) => {
            this.additionalOthers = incomingDTOs;
        });
    }

    cancel(): void {
        this.isEditing = false;
    }

    save(): void {
        // filter out empty object(s) with no address book selection
        this.imedClaimAdditionalOthersService.newAdditionalOthers = this.imedClaimAdditionalOthersService.newAdditionalOthers.filter(
            (x) => x.IndividualId !== 0 || x.AddressBookId !== 0,
        );
        // filter duplicates out
        this.imedClaimAdditionalOthersService.newAdditionalOthers.forEach((newBTP) => {
            if (
                this.additionalOthers.some(
                    (x) => x.IndividualId === newBTP.IndividualId || (x.AddressBookId === newBTP.AddressBookId && newBTP.IndividualId === 0),
                )
            ) {
                this.imedClaimAdditionalOthersService.newAdditionalOthers.splice(
                    this.imedClaimAdditionalOthersService.newAdditionalOthers.indexOf(newBTP),
                    1,
                );
                this.notificationsService.warning(
                    `${newBTP.IndividualId === 0 ? newBTP.FirmName : newBTP.Name} is already an Additional Other on this case`,
                );
            }
        });
        if (this.imedClaimAdditionalOthersService.newAdditionalOthers.length > 0) {
            this.imedClaimAdditionalOthersService
                .AddAdditionalOther(this.imedClaimId, this.imedClaimAdditionalOthersService.newAdditionalOthers)
                .subscribe(() => {
                    this.notificationsService.success('Additional Other added successfully');
                    this.isEditing = false;
                    this.getAdditionalOthers();
                });
        } else {
            this.cancel();
        }
    }

    remove(item: IImedClaimAdditionalOtherDTO, event: Event): void {
        if (event && event.stopPropagation) {
            event.stopPropagation();
        }

        this.imedClaimAdditionalOthersService.delete(item.Id).subscribe(() => {
            this.getAdditionalOthers();
            this.notificationsService.success('Additional Other deleted successfully');
        });
    }
}
