import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { IImedClaimServiceFollowup } from '@model/interfaces/imed-claim-service-followup';
import { Observable, BehaviorSubject, OperatorFunction } from 'rxjs';
import { SearchParams } from '@mt-ng2/common-classes';
import { IViewGetFollowUpsForList } from '@model/interfaces/view-get-follow-ups-for-list';
import { catchError } from 'rxjs/operators';
import { ICreateFollowupResponseDto } from '@model/interfaces/custom/create-followup-response-dto';
import { IFollowupsWithSpecialInformationDto } from '@model/interfaces/custom/followups-with-prelim-invoice-date-dto';

export const emptyImedClaimServiceFollowup: IImedClaimServiceFollowup = {
    Amount: null,
    Archived: null,
    CheckNumber: null,
    ClearanceDate: null,
    CommunicationMethod: null,
    ConfirmedCancellationWithPhysiciansOfficeSpokeWith: null,
    ConfirmedWithPhysiciansOfficeSpokeWith: null,
    CreatedById: null,
    Date: new Date(), // default to today's date
    DateCreated: null,
    DeferredUntil: null,
    FollowupTypeId: null,
    Id: 0,
    ImeAppointmentConfirmedWithPlaintiffsAttorneyOfficeByName: null,
    ImedClaimServiceId: null,
    Notes: '',
    PersonContactedOrSpokenTo: null,
    ReceiptOfNoticeOfImeConfirmedByName: null,
    ReceivedAppointmentDateTime: null,
    ReportDueBy: null,
    ReportForwardedToClientDate: null,
    ReportReadyOnOrAround: null,
    TrackingNumber: null,
    Via: null,
};

@Injectable()
export class ImedClaimServiceFollowupService extends BaseService<IImedClaimServiceFollowup> {
    constructor(public http: HttpClient) {
        super('/imedclaimservicefollowups', http);
    }

    createFollowup(followUp: IImedClaimServiceFollowup): Observable<HttpResponse<ICreateFollowupResponseDto>> {
        return this.http.post<ICreateFollowupResponseDto>('/imedclaimservicefollowups', followUp, { observe: 'response' });
    }

    getEmptyImedClaimServiceFollowup(): IImedClaimServiceFollowup {
        return { ...emptyImedClaimServiceFollowup };
    }

    search(searchparameters: SearchParams, imedClaimServiceId: number): Observable<HttpResponse<IImedClaimServiceFollowup[]>> {
        const params = this.getHttpParams(searchparameters);
        return this.http
            .get<IImedClaimServiceFollowup[]>('/imedclaimservicefollowups/_search/' + imedClaimServiceId, { observe: 'response', params: params })
            .pipe(catchError((e: Response) => this.handleError(e)) as OperatorFunction<unknown, any>);
    }

    getFollowupsAndPrelimInvoiceDate(searchparameters: SearchParams, imedClaimServiceId: number): Observable<IFollowupsWithSpecialInformationDto> {
        const params = this.getHttpParams(searchparameters);
        return this.http
            .get<IFollowupsWithSpecialInformationDto>('/imedclaimservicefollowups/followups-with-prelim-invoice-date/' + imedClaimServiceId, {
                observe: 'body',
                params: params,
            })
            .pipe(catchError((e: Response) => this.handleError(e)) as OperatorFunction<unknown, any>);
    }

    searchForListPage(searchparameters: SearchParams, imedClaimServiceId: number): Observable<HttpResponse<IViewGetFollowUpsForList[]>> {
        const params = this.getHttpParams(searchparameters);
        return this.http
            .get<IViewGetFollowUpsForList[]>('/imedclaimservicefollowups/_searchList/' + imedClaimServiceId, { observe: 'response', params: params })
            .pipe(catchError((e: Response) => this.handleError(e)) as OperatorFunction<unknown, any>);
    }

    canFollowupBeCreatedForRecordRetreivalService(imedClaimServiceId: number): Observable<boolean> {
        return this.http.get<boolean>(`/imedclaimservicefollowups/checkFollowupCreationForRecordRetrieval/${imedClaimServiceId}`);
    }

    getLatestFollowup(imedClaimServiceId: number): Observable<IImedClaimServiceFollowup> {
        return this.http.get<IImedClaimServiceFollowup>(`/imedclaimservicefollowups/get-latest-follow-up/${imedClaimServiceId}`);
    }

    archive(id: number): any {
        return this.http.put<number>(`/imedclaimservicefollowups/archive/${id}`, {});
    }
}
