import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MetaItemService } from '@mt-ng2/base-service';
import { IImageType } from '@model/interfaces/image-type';

@Injectable()
export class ImageTypeService extends MetaItemService<IImageType> {
    constructor(public http: HttpClient) {
        super('ImageTypeService', 'ImageType', 'ImageTypeIds', '/image-types', http);
    }
}
