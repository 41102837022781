import { Component, Injector } from '@angular/core';
import { TaskService } from '../tasks/task.service';
import { NavContentDynamicHeaderComponent } from '@mt-ng2/nav-module';

@Component({
    styles: [
        `
            .tasks-header {
                vertical-align: sub;
            }
            .tasks-header .badge {
                zoom: 0.9;
                vertical-align: baseline;
            }
            .btn-transparent:hover {
                background-color: transparent;
            }
        `,
    ],
    template: `<div class="taskList">
        <span class="tasks-header">
            TASKS <span class="badge">{{ header.content }}</span>
        </span>
        <span class="pull-right">
            <a class="btn btn-xs btn-transparent" (click)="updateTasks()"><i class="fa fa-refresh"></i></a>
        </span>
    </div>`,
})
export class SidebarTaskHeaderComponent extends NavContentDynamicHeaderComponent {
    taskService: TaskService;

    constructor(private injector: Injector) {
        super();
        this.taskService = this.injector.get(TaskService);
    }

    updateTasks(): void {
        this.taskService.updateSidebarList();
    }
}
