import { Validators } from '@angular/forms';
import { IExpandableObject } from '../expandable-object';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { FilmInformationDynamicControls, IFilmInformationDynamicControlsParameters } from '../form-controls/film-information.form-controls';
import { IFilmInformation } from '../interfaces/film-information';
import { IBodyPart } from '../interfaces/body-part';
import { IUser } from '../interfaces/user';
import { IImageType } from '../interfaces/image-type';
import { IImedClaimService } from '../interfaces/imed-claim-service';
import { IFilmInformationDynamicControlsParametersPartial } from './film-information-dynamic-controls.partial';

export class FilmInformationDynamicControlsPartial extends FilmInformationDynamicControls {
    constructor(
        bodyParts: IBodyPart[],
        imageTypes: IImageType[],
        filminformationPartial?: IFilmInformation,
        additionalParameters?: IFilmInformationDynamicControlsParametersPartial,
    ) {
        super(filminformationPartial, additionalParameters);

        (<DynamicField>this.Form.ImageTypeId).type.fieldType = DynamicFieldTypes.Select;
        (<DynamicField>this.Form.ImageTypeId).options = imageTypes;
        (<DynamicField>this.Form.ImageTypeId).value = filminformationPartial?.ImageTypeId ? filminformationPartial.ImageTypeId : null;
        (<DynamicField>this.Form.FacilityName).value = filminformationPartial?.ImageDate ? filminformationPartial?.FacilityName : additionalParameters?.radiologyFacilityName !== undefined ? additionalParameters?.radiologyFacilityName : ''  ;

        (<DynamicField>this.Form.BodyPartImagedId).type.fieldType = DynamicFieldTypes.Select;
        (<DynamicField>this.Form.BodyPartImagedId).options = bodyParts;
        (<DynamicField>this.Form.BodyPartImagedId).value = filminformationPartial?.BodyPartImagedId ? filminformationPartial.BodyPartImagedId : null;
    }
}
