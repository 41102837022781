import { IServicePartial } from '@model/partials/service.d.partial';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IService } from '@model/interfaces/service';
import { Observable } from 'rxjs';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { map } from 'rxjs/operators';
import { ServiceIdEnums } from '@common/constants/Enums';

export const emptyService: IServicePartial = {
    ClaimTypeId: 0,
    ClientSort: 0,
    Id: 0,
    IsCourtRelatedService: false,
    IsExaminationAndReview: false,
    IsIme: false,
    IsNotimeAndCrs: false,
    IsRetrieval: false,
    Name: '',
    ShowCourtRelatedHeaderShowing: false,
    Sort: 0,
    SummarySheetSort: 0,
};

@Injectable()
export class ServiceService extends StaticMetaItemService<IService> {
    constructor(public http: HttpClient) {
        super('ServiceService', 'Service', 'ServiceIds', '/options/services', http);
    }

    getServiceTypeSortedList(): Observable<MtSearchFilterItem<IService>[]> {
        return this.http.get<IService[]>(`/options/services`, {}).pipe(
            map((services) => {
                return services
                    .sort((a, b) => (a.Sort < b.Sort ? -1 : 1))
                    .map((item) => {
                        item.Name = item.Alias;
                        return new MtSearchFilterItem(item, false);
                    });
            }),
        );
    }
    getServiceTypeSortedByName(): Observable<MtSearchFilterItem<IService>[]> {
        return this.http.get<IService[]>(`/options/services`, {}).pipe(
            map((services) => {
                return services
                    .sort((a, b) => (a.Name < b.Name ? -1 : 1))
                    .map((item) => {
                        item.Name = item.Alias;
                        return new MtSearchFilterItem(item, false);
                    });
            }),
        );
    }

    getServiceById(serviceId: number): Observable<IService> {
        return this.http.get<IService>(`/options/get-service/${serviceId}`, {});
    }

    isRestrictedToBeCreatedAsStandAlone(serviceId): boolean {
        const restrictedStandaloneServices = [ServiceIdEnums.Auto_NF_10, ServiceIdEnums.Film_Review];
        return restrictedStandaloneServices.some((s) => s === serviceId);
    }
}
