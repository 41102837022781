import { ClaimTypes } from '@model/ClaimTypes';
import { DocumentQueueListComponent } from './document-queue-list.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, ClaimValues } from '@mt-ng2/auth-module';

const documentListRoleGuard = {
    claimType: ClaimTypes.DocumentQueue,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'DocumentQueueList',
};
const documentRoute: Routes = [
    { path: 'documentqueue', component: DocumentQueueListComponent, canActivate: [AuthGuard], data: documentListRoleGuard },
];
@NgModule({
    declarations: [],
    imports: [RouterModule.forChild(documentRoute)],
})
export class DocumentRoutingModule { }
