import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavModule, NavSidebarServiceToken } from '@mt-ng2/nav-module';
import { SidebarTaskComponent } from './sidebar-task.component';
import { SidebarTaskHeaderComponent } from './sidebar-task-header.component';
import { SideBarService } from './sidebar.service';
import { NavCasesMenuItemComponent } from './nav-cases-menu-item/nav-cases-menu-item.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [SidebarTaskComponent, SidebarTaskHeaderComponent, NavCasesMenuItemComponent],
    exports: [NavModule],
    imports: [CommonModule, NavModule, NgbModule]
})
export class AppNavModule {
    static forRoot(): any {
        return {
            ngModule: AppNavModule,
            providers: [{ provide: NavSidebarServiceToken, useExisting: SideBarService }],
        };
    }
}
