import { IMasterFollowupTypeTaskCompletionExemption } from '@model/interfaces/master-followup-type-task-completion-exemption.d';
import { Component, OnInit, Input } from '@angular/core';
import { IService } from '@model/interfaces/service';
import { IFollowupTypesAndExemptions } from '@model/interfaces/custom/followup-types-and-exemptions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { TaskTemplateService } from '../services/tasktemplate.service';
import { IMasterFollowupType } from '@model/interfaces/master-followup-type';
import { FollowupTypeExecmptionService } from '../services/followup-type-exemption.service';
import { AssociationServiceSelectionService } from '../services/associations-service-selection.service';

@Component({
    selector: 'followup-exemption',
    styles: [
        `
            .clear-padding {
                padding-left: 0;
            }
        `,
    ],
    templateUrl: './followup-exemption.component.html',
})
export class FollowupExemptionComponent implements OnInit {
    @Input() selectedService: IService;

    selectedFollowupType: IMasterFollowupType;
    followupTypesAndExemptions: IFollowupTypesAndExemptions;

    constructor(
        private notificationService: NotificationsService,
        private tasktemplateService: TaskTemplateService,
        private followupTypeExecmptionService: FollowupTypeExecmptionService,
        private associationServiceSelectionService: AssociationServiceSelectionService,
    ) { }

    ngOnInit(): void {
        this.associationServiceSelectionService.getAssociationType().subscribe((service) => {
            this.selectedService = service;
            this.getFollowupTypeExemptions();
        });
        this.getFollowupTypeExemptions();
    }

    getFollowupTypeExemptions(): void {
        this.tasktemplateService.getFollowupTypesAndExemptions(this.selectedService.Id).subscribe((followupTypesAndExemptions) => {
            this.followupTypesAndExemptions = followupTypesAndExemptions;
        });
    }

    selectItem(evt: IMasterFollowupType): void {
        const exemption = this.followupTypeExecmptionService.getEmptyMasterFollowupTypeTaskCompletionExemption();
        exemption.MasterFollowupTypeId = evt.Id;
        exemption.ServiceId = this.selectedService.Id;
        this.followupTypeExecmptionService.create(exemption).subscribe(() => {
            this.getFollowupTypeExemptions();
            this.notificationService.success('Exemption added successfully');
        });
    }

    removeItem(evt: IMasterFollowupTypeTaskCompletionExemption): void {
        this.followupTypeExecmptionService.delete(evt.Id).subscribe(() => {
            this.getFollowupTypeExemptions();
            this.notificationService.success('Exemption removed successfully');
        });
    }
}
