import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { forkJoin, Subscription } from 'rxjs';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';

import { AutomatedTemplateService } from '../services/automated-template.service';
import { AutomatedTemplateDynamicConfig } from '../automated-template.dynamic-config';

import { IAutomatedTemplate } from '@model/interfaces/automated-template';
import { IDocument } from '@model/interfaces/document';
import { AuthService } from '@mt-ng2/auth-module';
import { DynamicField, DynamicLabel, IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { AddressBookTypeService } from '../../address-books/addressbooktype.service';
import { ServiceService } from '../../imed-claims/services/service.service';
import { StateService } from '@app-shared/services/state.service';
import { IService } from '@model/interfaces/service';
import { IState } from '@model/interfaces/state';
import { IAddressBookType } from '@model/interfaces/address-book-type';
import { IEmailTemplate } from '@model/interfaces/email-template';
import { EmailTemplateService } from '../../email-templates/emailtemplate.service';

@Component({
    selector: 'app-automated-template-basic-info',
    templateUrl: './automated-template-basic-info.component.html',
})
export class AutomatedTemplateBasicInfoComponent implements OnInit {
    @Input() automatedTemplate: IAutomatedTemplate;
    @Input() canEdit: boolean;
    @ViewChild('automatedTemplateInfoForm') automatedTemplateInfoForm: UntypedFormGroup;
    automatedTemplateServices: IService[] = [];
    automatedTemplateStates: IState[] = [];
    automatedTemplateAddressBookTypes: IAddressBookType[] = [];
    automatedTemplateEmailTemplates: IEmailTemplate[] = [];
    isEditing = false;
    isHovered: boolean;
    config: IDynamicFormConfig;
    formFactory: AutomatedTemplateDynamicConfig<IAutomatedTemplate>;
    form: UntypedFormGroup;
    doubleClickIsDisabled = false;
    currentUserId: number;
    serviceOptionsDropdown: DynamicField;

    documents: IDocument[];
    serviceOptions: any = [];

    subscriptions$ = new Subscription();

    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    get isNewAutomatedTemplate(): boolean {
        return this.automatedTemplate && this.automatedTemplate.Id ? false : true;
    }

    constructor(
        private automatedTemplateService: AutomatedTemplateService,
        private notificationsService: NotificationsService,
        private router: Router,
        private authService: AuthService,
        private addressBookTypeService: AddressBookTypeService,
        private serviceService: ServiceService,
        private stateService: StateService,
        private emailTemplateService: EmailTemplateService,
    ) {}

    ngOnInit(): void {
        this.config = { formObject: [], viewOnly: [] };
        this.setConfig();
        this.currentUserId = this.authService.currentUser.getValue().Id;
        this.form = this.automatedTemplateInfoForm;
        forkJoin(
            this.serviceService.getItems(),
            this.addressBookTypeService.getItems(),
            this.stateService.getItems(),
            this.emailTemplateService.getAll(),
        ).subscribe((forkJoinResponses) => {
            const [serviceTypes, addressBookTypes, states, emailTemplates] = forkJoinResponses;
            this.automatedTemplateServices = serviceTypes;
            this.automatedTemplateStates = states;
            this.automatedTemplateAddressBookTypes = addressBookTypes;
            this.automatedTemplateEmailTemplates = this.filterEmailTemplates(emailTemplates);
            this.setEmailTemplatesName();
        });
    }

    filterEmailTemplates(emailTemplate: IEmailTemplate[]): any {
        return emailTemplate.filter(
            (et) => et.EmailTemplateType.Name === 'Service' || et.EmailTemplateType.Name === 'Cases' || et.EmailTemplateType.Name === 'Address Book',
        );
    }

    setEmailTemplatesName(): void {
        this.automatedTemplateEmailTemplates.forEach(function (et): void {
            et.Name = `${et.Name} - ${et.EmailTemplateType.Name} Email`;
        });
    }

    setConfig(): void {
        this.formFactory = new AutomatedTemplateDynamicConfig<IAutomatedTemplate>(this.automatedTemplate);

        if (this.isNewAutomatedTemplate) {
            this.isEditing = true;
            this.config = this.formFactory.getForCreate();
        } else {
            this.config = this.formFactory.getForUpdate();
        }

        this.viewOnly = this.config?.viewOnly?.map((x) => new DynamicLabel(x));
        this.formObject = this.config.formObject?.map((x) => new DynamicField(x));
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.isNewAutomatedTemplate) {
            void this.router.navigate(['/dynamic-templates']);
        } else {
            this.isEditing = false;
        }
    }

    updateFks(automatedTemplate): void {
        this.automatedTemplate.States = automatedTemplate.States;
        this.automatedTemplate.Services = automatedTemplate.Services;
        this.automatedTemplate.AddressBookTypes = automatedTemplate.AddressBookTypes;
        this.automatedTemplate.AddressBooks = automatedTemplate.AddressBooks;
        this.automatedTemplate.EmailTemplates = automatedTemplate.EmailTemplates;
        this.automatedTemplate.Individuals = automatedTemplate.Individuals;
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            if (this.isNewAutomatedTemplate && !this.automatedTemplate.Template?.length) {
                this.notificationsService.error('A Template is required.');
                return;
            }
            this.formFactory.assignFormValues(this.automatedTemplate, form.value.AutomatedTemplate as IAutomatedTemplate);
            if (this.automatedTemplate.States.length === 0) {
                this.automatedTemplate.IsForAllStates = true;
            }
            this.saveAutomatedTemplate();
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error('Save failed. Please check the form and try again.');
        }
    }

    private saveAutomatedTemplate(): void {
        if (this.isNewAutomatedTemplate) {
            this.automatedTemplate.DateCreated = new Date();
            this.automatedTemplate.CreatedById = this.currentUserId;
            this.automatedTemplateService.createWithFks(this.automatedTemplate).subscribe((answer) => {
                this.automatedTemplate.Id = answer;
                this.automatedTemplateService.updateWithFks(this.automatedTemplate).subscribe(() => {
                    this.success(true);
                });
            });
            this.setConfig();
        } else {
            this.automatedTemplateService.updateWithFks(this.automatedTemplate).subscribe(() => {
                this.success();
            });
        }
    }

    private success(newAutomatedTemplateSave?: boolean): void {
        if (newAutomatedTemplateSave) {
            void this.router.navigate([`/dynamic-templates/${this.automatedTemplate.Id}`]);
        } else {
            this.setConfig();
            this.isEditing = false;
        }
        this.automatedTemplateService.emitChange(this.automatedTemplate);
        this.notificationsService.success('Dynamic Template saved successfully.');
    }

    renameFile(file: File, name: string): File {
        const oldFileItem: File = file;
        const newFile: File = new File([file], name, { type: oldFileItem.type });

        return newFile;
    }
}
