import { EmailAddressDynamicControlsPartial } from '@model/partials/email-address-partial.form-controls';
import { IEmailAddress } from '@model/interfaces/email-address.d';
import { emptyEmailAddress } from '@model/shared-entities/email-addresses/email-address.service';
import { EmailAddressDynamicConfig } from '@model/shared-entities/email-addresses/email-address.dynamic-config';
import { IEmailType } from '@model/interfaces/email-type.d';
import { EmailTypeService } from '@model/shared-entities/email-type/emailtype.service';
import { AddressFormatterService } from './../../common/services/address-formatter.service';
import { IDuplicateAddressBook } from '@model/interfaces/custom/duplicate-address-book';
import { IPhysicianStatusLog } from '@model/interfaces/physician-status-log.d';
import { IPhysician } from '@model/interfaces/physician.d';
import { AddressBookTypeIdEnums, PhysicianStatusesIdEnums, MetaAddressBookTypeIdEnums, ClaimTypeEnums } from '../../common/constants/Enums';
import { StateService } from '@app-shared/services/state.service';
import { CountyService } from '@app-shared/services/county.service';
import { ICounty } from '@model/interfaces/county.d';
import { IState } from '@model/interfaces/state.d';
import { emptyAddress } from './../addresses/address.service';
import { AddressDynamicConfig } from './../addresses/address.dynamic-config';
import { AddressTypeService } from './../addresses/addresstype.service';
import { AddressDynamicControls } from '@model/form-controls/address.form-controls';
import { emptyPhoneNumber } from './../../common/shared-dynamic-configs/phone-number.dynamic-config';
import { PhoneNumberDynamicControlsPartial } from '@model/partials/phone-number-partial.form-controls';
import { IPhoneType } from '@model/interfaces/phone-type.d';
import { IAddressType } from '@model/interfaces/address-type.d';
import { IPhoneNumber } from '@model/interfaces/phone-number.d';
import { IAddressBookWithPrimaryAddress } from '@model/interfaces/custom/address-book-with-primary-address';
import { IndividualDynamicConfig } from './../individuals/individual.dynamic-config';
import { IndividualDynamicControlsPartial } from '@model/partials/individual-partial.form-controls';
import { AddressBookDynamicControlsPartial } from '@model/partials/address-book-partial.form-controls';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { AddressBookService, emptyAddressBook } from '../addressbook.service';
import { IAddressBook } from '@model/partials/address-book.partial';
import { AddressBookDynamicConfig } from '../address-book.dynamic-config';
import { IMetaAddressBookType } from '@model/interfaces/meta-address-book-type';
import { MetaAddressBookTypeService } from '../metaaddressbooktype.service';
import { IAddressBookType } from '@model/interfaces/address-book-type';
import { AddressBookTypeService } from '../addressbooktype.service';
import { AuthService } from '@mt-ng2/auth-module';
import { IndividualService } from '../individuals/individual.service';
import { IIndividual } from '@model/interfaces/individual';
import { PhoneNumberDynamicConfig } from '../../common/shared-dynamic-configs/phone-number.dynamic-config';
import { PhoneTypeService } from '../phonetype.service';
import { AddressService } from './../addresses/address.service';
import { formatPhone } from '@mt-ng2/format-functions';
import { IPhysicianFollowupLog } from '@model/interfaces/physician-followup-log';
import { tap, finalize } from 'rxjs/operators';
import { safeDetectChanges } from '../../common/cdr-safety/cdr-safety.library';
import { trimObjectStringProperties } from '../../common/custom/trimObjectStringProperties';
import { ModalService } from '@mt-ng2/modal-module';
import { IPhysicianDetail } from '../../model/interfaces/physician-detail';
import { IAddress } from '@model/interfaces/address';
import { IDynamicFormConfig } from '@mt-ng2/dynamic-form';

@Component({
    selector: 'app-addressbook-individual-basic-info',
    templateUrl: './addressbook-individual-basic-info.component.html',
})

// TODO:PS - If statements
export class AddressBookIndividualBasicInfoComponent implements OnInit {
    @Input()
    addressBook: IAddressBook;
    @Input()
    individual: IIndividual;
    @Input()
    isIndividualPage: boolean;
    @Input()
    canEdit: boolean;
    @Input()
    addressBookWithPrimaryAddress: IAddressBookWithPrimaryAddress[];
    @Input()
    isNew = false;

    isEditing: boolean;
    isHovered: boolean;
    config: IDynamicFormConfig;
    addressBookForm: any;
    addressBookformFactory: AddressBookDynamicConfig<IAddressBook>;
    indivdualFormFactory: IndividualDynamicConfig<IIndividual>;
    doubleClickIsDisabled = false;
    addressBookId: number;
    metaaddressbooktypes?: IMetaAddressBookType[];
    addressbooktypes?: IAddressBookType[];

    form: UntypedFormGroup;
    individualControls: any;
    addressBookControls: any;
    individualViewControls: any;
    addressBookViewControls: any;
    selectedAddressBook: IAddressBook;
    selectedAddressBookViewControls: any;

    // Address
    addressBookAddress: IAddress;
    addressBookAddressControls: any;
    addressBookAddressformFactory: AddressDynamicConfig<IAddress>;
    addressTypes?: IAddressType[];
    states: IState[];
    county: ICounty;

     // Physical Address
     addressBookPhysicalAddress: IAddress;
     addressBookPhysicalAddressControls: any;
     addressBookPhysicalAddressformFactory: AddressDynamicConfig<IAddress>;
     physicalAddress = 'No physical address provided';

     // Mailing Address
     addressBookMailingAddress: IAddress;
     addressBookMailingAddressControls: any;
     addressBookMailingAddressformFactory: AddressDynamicConfig<IAddress>;
     mailingAddress = 'No mailing address provided';

    // Phone
    individualPhoneControls: any;
    addressBookPhoneControls: any;
    addressBookPhoneformFactory: PhoneNumberDynamicConfig<IPhoneNumber>;
    addressBookPhone: IPhoneNumber;
    individualPhone: IPhoneNumber;
    indivdualPhoneFormFactory: PhoneNumberDynamicConfig<IPhoneNumber>;
    phoneTypes?: IPhoneType[];

    // Email

    individualEmailControls: any;
    addressBookEmailControls: any;
    addressBookEmailformFactory: EmailAddressDynamicConfig<IEmailAddress>;
    addressBookEmail: IEmailAddress;
    individualEmail: IEmailAddress;
    indivdualEmailFormFactory: EmailAddressDynamicConfig<IEmailAddress>;
    emailTypes?: IEmailType[];

    isFormRenedered = false;
    isFacilitySelected = true;
    countyName: string;
    closeResult: string;
    displayContractRateRange = false;
    displayWcb = false;
    formatter: any;

    get isCarrierType(): boolean {
        return this.addressBook.AddressBookTypeId === +AddressBookTypeIdEnums.Carrier;
    }

    get isCourtType(): boolean {
        return this.addressBook.AddressBookTypeId === +AddressBookTypeIdEnums.Courts;
    }

    get isAttorneyType(): boolean {
        return this.addressBook.AddressBookTypeId === +AddressBookTypeIdEnums.Attorney;
    }

    get addressBookIsFacility(): boolean {
        return this.addressBook.MetaAddressBookTypeId === +MetaAddressBookTypeIdEnums.Facility_Organization;
    }

    constructor(
        private metaAddressBookTypeService: MetaAddressBookTypeService,
        private addressBookTypeService: AddressBookTypeService,
        private addressBookService: AddressBookService,
        private notificationsService: NotificationsService,
        private authService: AuthService,
        private router: Router,
        private individualService: IndividualService,
        private fb: UntypedFormBuilder,
        private cdr: ChangeDetectorRef,
        private phoneTypeService: PhoneTypeService,
        private addressTypeService: AddressTypeService,
        private emailTypeService: EmailTypeService,
        private addressService: AddressService,
        private stateService: StateService,
        private countyService: CountyService,
        private addressFormatterService: AddressFormatterService,
        private modalService: ModalService,
    ) {}

    ngOnInit(): void {
        this.formatter = new Intl.NumberFormat('en-US', {
            currency: 'USD',
            style: 'currency',
        });
        this.addressBookPhone = { ...emptyPhoneNumber };
        this.individualPhone = { ...emptyPhoneNumber };
        this.addressBookAddress = { ...emptyAddress };

        this.addressBookEmail = { ...emptyEmailAddress };
        this.individualEmail = { ...emptyEmailAddress };

        this.markNotEditing();

        this.form = this.fb.group({
            Address: this.fb.group({}),
            AddressBook: this.fb.group({}),
            AddressBookEmail: this.fb.group({}),
            AddressBookPhone: this.fb.group({}),
            Individual: this.fb.group({}),
            IndividualEmail: this.fb.group({}),
            IndividualPhone: this.fb.group({}),
            MailingAddress: this.fb.group({}),
            PhysicalAddress: this.fb.group({}),
        });

        if (this.isNew) {
            forkJoin(
                this.getMetaaddressbooktypes(),
                this.getAddressbooktypes(),
                this.getAddressTypes(),
                this.getPhoneTypes(),
                this.getStates(),
                this.getEmailTypes(),
            ).subscribe(() => this.setConfig());
        } else {
            forkJoin(this.getMetaaddressbooktypes(), this.getAddressbooktypes(), this.getStates()).subscribe(() => this.setConfig());
        }
        if (
            this.addressBook.AddressBookTypeId === +AddressBookTypeIdEnums.Government_offices ||
            this.addressBook.AddressBookTypeId === +AddressBookTypeIdEnums.Carrier ||
            this.addressBook.AddressBookTypeId === +AddressBookTypeIdEnums.Attorney ||
            this.addressBook.AddressBookTypeId === +AddressBookTypeIdEnums.Miscellaneous
        ) {
            this.displayContractRateRange = true;
        }

        this.displayWcb =
            this.addressBook.AddressBookTypeId === +AddressBookTypeIdEnums.Physician_panel ||
            this.addressBook.AddressBookTypeId === +AddressBookTypeIdEnums.Expert_panel;
    }

    ngOnDestroy(): void {
        this.cdr.detach();
    }

    getMetaaddressbooktypes(): Observable<IMetaAddressBookType[]> {
        return this.metaAddressBookTypeService.getAll().pipe(tap((answer) => (this.metaaddressbooktypes = answer)));
    }

    getAddressbooktypes(): Observable<IAddressBookType[]> {
        return this.addressBookTypeService.getAll().pipe(tap((answer) => (this.addressbooktypes = answer)));
    }

    getAddressTypes(): Observable<IAddressType[]> {
        return this.addressTypeService.getAll().pipe(tap((answer) => (this.addressTypes = answer)));
    }

    getEmailTypes(): Observable<IAddressType[]> {
        return this.emailTypeService.getAll().pipe(tap((answer) => (this.emailTypes = answer)));
    }

    getStates(): Observable<IState[]> {
        return this.stateService.getAll().pipe(tap((answer) => (this.states = answer)));
    }

    getPhoneTypes(): Observable<IPhoneType[]> {
        return this.phoneTypeService.getAll().pipe(tap((answer) => (this.phoneTypes = answer)));
    }

    getCountyName(): void {
        this.countyService.getByZip(this.form.value.Address.Zip as string).subscribe((answer) => {
            if (answer) {
                this.countyName = answer.CountyName;
            }
        });
    }

    setPhysicalAddress(): void {
        if (this.addressBook.Id > 0 && this.addressBook.PhysicalAddress) {
            const state = this.states.find((s) => s.Id === this.addressBook.PhysicalAddress.StateId).StateCode;
            this.physicalAddress = `${this.addressBook.PhysicalAddress.Address1}, ${
                this.addressBook.PhysicalAddress.Address2 ? this.addressBook.PhysicalAddress.Address2 + ',' : ''
            } ${this.addressBook.PhysicalAddress.City}, ${state} ${this.addressBook.PhysicalAddress.Zip}`;
        }
    }

    setMailingAddress(): void {
        if (this.addressBook.Id > 0 && this.addressBook.MailingAddress) {
            const state = this.states.find((s) => s.Id === this.addressBook.MailingAddress.StateId).StateCode;
            this.mailingAddress = `${this.addressBook.MailingAddress.Address1}, ${
                this.addressBook.MailingAddress.Address2 ? this.addressBook.MailingAddress.Address2 + ',' : ''
            } ${this.addressBook.MailingAddress.City}, ${state} ${this.addressBook.MailingAddress.Zip}`;
        }
    }

    setAddressBookFormAndView(): void {
        const viewAndFormAddressBookControl = new AddressBookDynamicControlsPartial(this.addressBook, {
            addressBookTypes: this.addressbooktypes,
            metaAddressBookTypes: this.metaaddressbooktypes,
        });
        viewAndFormAddressBookControl.setRequired(false);
        this.addressBookControls = viewAndFormAddressBookControl.Form;
        this.addressBookViewControls = viewAndFormAddressBookControl.View;
    }

    setIndividualFormAndView(isRequired: boolean): void {
        const viewAndFormIndividualControl = new IndividualDynamicControlsPartial(this.individual);
        viewAndFormIndividualControl.setRequired(isRequired);
        this.individualControls = viewAndFormIndividualControl.Form;
        this.individualViewControls = viewAndFormIndividualControl.View;
    }

    setAddressBookFormFactory(): void {
        this.addressBookformFactory = new AddressBookDynamicConfig<IAddressBook>(this.addressBook, this.metaaddressbooktypes, this.addressbooktypes);
    }

    setIndividualFormFactory(): void {
        this.indivdualFormFactory = new IndividualDynamicConfig<IIndividual>(this.individual, null);
    }

    setAddressBookPhoneForm(): void {
        this.addressBookPhoneControls = new PhoneNumberDynamicControlsPartial(this.addressBookPhone, {
            formGroup: 'AddressBookPhone',
            phoneTypes: this.phoneTypes,
        }).Form;
    }

    setAddressBookPhoneFormFactory(): void {
        this.addressBookPhoneformFactory = new PhoneNumberDynamicConfig<IPhoneNumber>(this.addressBookPhone, null);
    }

    setAddressBookEmailForm(): void {
        this.addressBookEmailControls = new EmailAddressDynamicControlsPartial(this.addressBookEmail, {
            emailTypes: this.emailTypes,
            formGroup: 'AddressBookEmail',
        }).Form;
    }

    setAddressBookEmailFormFactory(): void {
        this.addressBookEmailformFactory = new EmailAddressDynamicConfig<IEmailAddress>(this.addressBookEmail, null);
    }

    setIndividualPhoneForm(isRequired = false): void {
        const individualControls = new PhoneNumberDynamicControlsPartial(this.individualPhone, {
            formGroup: 'IndividualPhone',
            phoneTypes: this.phoneTypes,
        });

        this.individualPhoneControls = individualControls.Form;
        individualControls.setRequired(isRequired);
    }

    setIndividualPhoneFormFactory(): void {
        this.indivdualPhoneFormFactory = new PhoneNumberDynamicConfig<IPhoneNumber>(this.individualPhone, null);
    }

    setIndividualEmailForm(isRequired = false): void {
        const individualControls = new EmailAddressDynamicControlsPartial(this.individualEmail, {
            emailTypes: this.emailTypes,
            formGroup: 'IndividualEmail',
        });

        this.individualEmailControls = individualControls.Form;
        individualControls.setRequired(isRequired);
    }

    setIndividualEmailFormFactory(): void {
        this.indivdualEmailFormFactory = new EmailAddressDynamicConfig<IEmailAddress>(this.individualEmail, null);
    }

    setAddressBookAddressForm(): void {
        this.addressBookAddressControls = new AddressDynamicControls(this.addressBookAddress, {
            states: this.states,
        }).Form;
    }

    setAddressBookAddressFormFactory(): void {
        this.addressBookAddressformFactory = new AddressDynamicConfig<IAddress>(this.addressBookAddress, null);
    }

    setAddressBookPhysicalAddressForm(): void {
        this.addressBookPhysicalAddressControls = new AddressDynamicControls(this.addressBook.PhysicalAddress, {
            formGroup: 'PhysicalAddress',
            states: this.states,
        }).Form;
    }

    setAddressBookPhysicalAddressFormFactory(): void {
        this.addressBookPhysicalAddressformFactory = new AddressDynamicConfig<IAddress>(this.addressBook.PhysicalAddress, null);
    }

    setAddressBookMailingAddressForm(): void {
        this.addressBookMailingAddressControls = new AddressDynamicControls(this.addressBook.MailingAddress, {
            formGroup: 'MailingAddress',
            states: this.states,
        }).Form;
    }

    setAddressBookMailingAddressFormFactory(): void {
        this.addressBookMailingAddressformFactory = new AddressDynamicConfig<IAddress>(this.addressBook.MailingAddress, null);
    }

    setConfig(): void {
        this.setPhysicalAddress();
        this.setMailingAddress();
        this.setAddressBookFormAndView();
        this.setIndividualFormAndView(this.isIndividualPage);
        this.setAddressBookFormFactory();
        this.setIndividualFormFactory();

        this.setAddressBookPhysicalAddressForm();
        this.setAddressBookPhysicalAddressFormFactory();

        this.setAddressBookMailingAddressForm();
        this.setAddressBookMailingAddressFormFactory();

        // only applies when a new addressbook is being created

        this.setAddressBookAddressForm();
        this.setAddressBookAddressFormFactory();

        this.setAddressBookPhoneForm();
        this.setIndividualPhoneForm();
        this.setAddressBookPhoneFormFactory();
        this.setIndividualPhoneFormFactory();

        this.setAddressBookEmailForm();
        this.setIndividualEmailForm();
        this.setAddressBookEmailFormFactory();
        this.setIndividualEmailFormFactory();

        if (this.isNew) {
            this.markEditing();
        }
        // this.selectedAddressBook = this.getEmptyAddressBook();
        if (!this.isNew) {
            safeDetectChanges(this.cdr);
        }
        this.isFormRenedered = true;
    }

    isAddressBookFormCreated(): boolean {
        return this.form && this.form.value && this.form.value.AddressBook;
    }

    isOfTypeIndividual(): boolean {
        if (!this.isNewAddressBook()) {
            return this.addressBook.MetaAddressBookTypeId === +MetaAddressBookTypeIdEnums.Individual;
        } else {
            return (
                (this.isAddressBookFormCreated() && this.form.value.AddressBook.MetaAddressBookTypeId === MetaAddressBookTypeIdEnums.Individual) ||
                (!this.isAddressBookFormCreated() && this.addressBook.MetaAddressBookTypeId === +MetaAddressBookTypeIdEnums.Individual)
            );
        }
    }

    isOfTypeFacility(): boolean {
        if (!this.isNewAddressBook()) {
            return this.addressBook.MetaAddressBookTypeId === +MetaAddressBookTypeIdEnums.Facility_Organization;
        } else {
            return (
                (this.isAddressBookFormCreated() &&
                    this.form.value.AddressBook.MetaAddressBookTypeId === MetaAddressBookTypeIdEnums.Facility_Organization) ||
                (!this.isAddressBookFormCreated() && this.addressBook.MetaAddressBookTypeId === +MetaAddressBookTypeIdEnums.Facility_Organization)
            );
        }
    }

    FacitlityControlsVisible(): boolean {
        return (this.isOfTypeFacility() && !this.isIndividualPage) || this.isOfTypeIndividual();
    }

    IndividualControlsVisible(): boolean {
        return this.isNewAddressBook() || this.isIndividualPage;
    }

    getEmptyAddressBook(): IAddressBook {
        return { ...emptyAddressBook };
    }

    resetSetAddressbook(): void {
        this.selectedAddressBook = this.getEmptyAddressBook();
        const addressBookTypeId = this.form.value.AddressBook.MetaAddressBookTypeId;
        const phoneTypeId = this.form.get('IndividualPhone.PhoneTypeId');
        if (phoneTypeId) {
            phoneTypeId.mtSetRequired(addressBookTypeId === 1);
        }
        const phone = this.form.get('IndividualPhone.Phone');
        if (phone) {
            phone.mtFocus();
        }
        safeDetectChanges(this.cdr);
    }

    setAddressBook(event): void {
        if (event) {
            // setting ngmodel caused problems, so had to do a workaround
            this.selectedAddressBook = event;
            this.selectedAddressBookViewControls = new AddressBookDynamicControlsPartial(this.selectedAddressBook, {
                addressBookTypes: this.addressbooktypes,
                metaAddressBookTypes: this.metaaddressbooktypes,
            }).View;
            this.isFacilitySelected = true;
            this.setIndividualFormAndView(true);
            this.setIndividualPhoneForm(true);
            this.setIndividualEmailForm(true);
        } else {
            this.selectedAddressBook = this.getEmptyAddressBook();
            this.isFacilitySelected = false;
            this.setIndividualFormAndView(false);
            this.setIndividualPhoneForm(false);
            this.setIndividualEmailForm(false);
        }

        safeDetectChanges(this.cdr);
    }

    edit(): void {
        if (this.canEdit) {
            this.markEditing();
        }
    }

    markEditing(): void {
        this.isEditing = true;
    }

    markNotEditing(): void {
        this.isEditing = false;
    }

    isAddressSelected(): boolean {
        return this.selectedAddressBook && this.selectedAddressBook.Id > 0;
    }

    isNewAddressBook(): boolean {
        return !this.addressBook.Id || this.addressBook.Id === 0;
    }

    isNewIndividual(): boolean {
        return !this.individual.Id || this.individual.Id === 0;
    }

    isIndividualBasicInfoValid(): boolean {
        return (this.IndividualControlsVisible() && this.form.controls.Individual.valid) || !this.IndividualControlsVisible();
    }

    // Validate Facility Basic Info when a Facility Controls are visible and when an Existing Facilty is not returned
    isFaciltyBasicInfoValid(): boolean {
        return (
            (this.FacitlityControlsVisible() && !this.isExistingFacilitySelected() && this.form.controls.AddressBook.valid) ||
            (!this.FacitlityControlsVisible() && !this.isExistingFacilitySelected()) ||
            this.isExistingFacilitySelected()
        );
    }

    isFaciltyTypeFieldsValid(): boolean {
        return (
            this.isOfTypeFacility() &&
            this.form.controls.AddressBookPhone.valid &&
            this.form.controls.AddressBookEmail.valid &&
            this.form.controls.Address.valid
        ); //
    }

    // if individual, only validate Individual Phone and Facility Address, since we are going to use only Individuals Phone
    isIndividualTypeFieldsValid(): boolean {
        return (
            this.isOfTypeIndividual() &&
            this.form.controls.IndividualPhone.valid &&
            this.form.controls.IndividualEmail.valid &&
            this.form.controls.Address.valid
        ); //
    }

    // if facility is already selected do not need to ignore validating facility phone and address
    isIndividualForSelectedFacilityValid(): boolean {
        return (
            (this.isOfTypeFacility() &&
                ((this.isExistingFacilitySelected() &&
                    this.form.controls.IndividualPhone.valid &&
                    this.form.controls.IndividualEmail.valid &&
                    this.form.controls.Individual.valid) ||
                    !this.isExistingFacilitySelected())) ||
            !this.isOfTypeFacility()
        );
    }

    // only done on the initial creation of Addressbook, since the Address and Phone Split at as their own UI component after the addressbook is already created
    isAddressPhoneInformationValid(): boolean {
        return (
            !this.isNewAddressBook ||
            (this.isNewAddressBook && this.isFaciltyTypeFieldsValid()) ||
            this.isIndividualTypeFieldsValid() ||
            this.isIndividualForSelectedFacilityValid()
        );
    }

    isFormValid(): boolean {
        if (this.IndividualControlsVisible() && this.isOfTypeFacility() && !this.selectedAddressBook && this.isNewAddressBook()) {
            this.isFacilitySelected = false;
            return false;
        }

        return this.isIndividualBasicInfoValid() && this.isFaciltyBasicInfoValid() && this.isAddressPhoneInformationValid();
    }

    isContractRangeValid(): boolean {
        if (
            (this.addressBook.ContractRateFrom &&
                this.addressBook.ContractRateTo &&
                this.addressBook.ContractRateFrom > this.addressBook.ContractRateTo) ||
            (this.individual.ContractRateFrom && this.individual.ContractRateTo && this.individual.ContractRateFrom > this.individual.ContractRateTo)
        ) {
            this.notificationsService.error('Contract Range From cannot be higher than Contract Range To');
            return false;
        }
        return true;
    }

    // Since Facility Name is a required field for Individual Type Ignoring the field by making it valid
    setFacitityNameAsValidForIndividualType(): void {
        if (this.isOfTypeIndividual() && this.FacitlityControlsVisible()) {
            const FacitityField = this.form.get('AddressBook.FacilityName');
            if (FacitityField) {
                this.form.get('AddressBook.FacilityName').setErrors(null);
            }
        }
    }

    isExistingFacilitySelected(): boolean {
        return this.selectedAddressBook && this.selectedAddressBook.Id > 0;
    }

    setModifiersFieldForAddressbook(): void {
        if (this.isNewAddressBook()) {
            this.addressBook.CreatedById = this.authService.currentUser.getValue().Id;
            this.addressBook.DateCreated = new Date();
            this.setModifiersFieldForAddressBookPhonesAndAddress();
            this.setModifiersFieldForIndividuals();
        } else {
            this.setModifiersFieldForIndividuals();
            this.addressBook.ModifiedById = this.authService.currentUser.getValue().Id;
            this.addressBook.DateModified = new Date();
        }
    }

    setModifiersFieldForAddressBookPhonesAndAddress(): void {
        this.addressBookAddress.IsPrimary = true;
        this.addressBookAddress.CreatedById = this.authService.currentUser.getValue().Id;
        this.addressBookAddress.DateCreated = new Date();

        this.addressBookPhone.IsPrimary = true;
        this.addressBookPhone.CreatedById = this.authService.currentUser.getValue().Id;
        this.addressBookPhone.DateCreated = new Date();

        this.addressBookEmail.IsPrimary = true;
        this.addressBookEmail.CreatedById = this.authService.currentUser.getValue().Id;
        this.addressBookEmail.DateCreated = new Date();
    }

    setModifiersFieldForIndividualPhonesAndAddress(): void {
        if (this.isNewAddressBook()) {
            this.individualPhone.IsPrimary = true;
            this.individualPhone.CreatedById = this.authService.currentUser.getValue().Id;
            this.individualPhone.DateCreated = new Date();

            this.individualEmail.IsPrimary = true;
            this.individualEmail.CreatedById = this.authService.currentUser.getValue().Id;
            this.individualEmail.DateCreated = new Date();
        }
    }

    setModifiersFieldForIndividuals(): void {
        if (this.isNewIndividual()) {
            this.individual.CreatedById = this.authService.currentUser.getValue().Id;
            this.individual.DateCreated = new Date();
            this.setModifiersFieldForIndividualPhonesAndAddress();
        } else {
            this.individual.ModifiedById = this.authService.currentUser.getValue().Id;
            this.individual.DateModified = new Date();
        }
    }

    getIndividualHeader(): string {
        return `<thead>
                    <tr class="no-pointer">
                    <th>
                        <b>Name</b>
                    </th>
                    <th>
                        <b>Primary Phone</b>
                    </th>
                    <th>
                        <b>Primary Email</b>
                    </th>
                   </tr>
                </thead>`;
    }

    getIndividuaBody(duplicates: IDuplicateAddressBook[]): string {
        let tBody = '';
        duplicates.forEach((item: IDuplicateAddressBook) => {
            const linkAnchor = `<a target="_blank" href="/#/address-books/${item.AddressBook.Id}/individuals/${item.Individual.Id}"> `;
            const linkText = item.Individual ? item.Individual.FirstName + ' ' + item.Individual.LastName : '';
            const link = linkAnchor + linkText + ' </a>';

            tBody += `<tr class="no-pointer">
                <td class="floatLeft">${link}</td>
                <td class="floatLeft">${item.IndividualPhone ? formatPhone(item.IndividualPhone.Phone) : ''} </td>
                <td class="floatLeft">${item.IndividualEmail ? item.IndividualEmail.Email : ''}</td>
                </tr>`;
        });
        return `<tbody>${tBody}</tbody>`;
    }

    getAddressBookHeader(): string {
        return `<thead>
                    <tr class="no-pointer">
                    <th>
                        <b>Facility Name</b>
                    </th>
                    <th>
                        <b>Address</b>
                    </th>
                    <th>
                        <b>Primary Phone</b>
                    </th>
                    <th>
                        <b>Primary Email</b>
                    </th>
                   </tr>
                </thead>`;
    }

    getAddressBookBody(duplicates: IDuplicateAddressBook[]): string {
        let tBody = '';
        duplicates.forEach((item: IDuplicateAddressBook) => {
            const linkAnchor = `<a target="_blank" href="/#/address-books/${item.AddressBook.Id}"> `;
            const linkText = item.AddressBook ? item.AddressBook.FacilityName : '';
            const link = linkAnchor + linkText + ' </a>';
            tBody += `<tr class="no-pointer">
                <td class="floatLeft">${link}</td>
                <td class="floatLeft">${item.Address ? this.addressFormatterService.formatAddress(item.Address) : ''}</td>
                <td class="floatLeft">${item.AddressBookPhone ? formatPhone(item.AddressBookPhone.Phone) : ''} </td>
                <td class="floatLeft">${item.AddressBookEmail ? item.AddressBookEmail.Email : ''}</td>
                </tr>`;
        });
        return `<tbody>${tBody}</tbody>`;
    }

    getDuplicateTable(duplicates: IDuplicateAddressBook[]): string {
        return `<div class="table-responsive">
                <table class="table table-hover table-striped">
                ${this.isOfTypeIndividual() ? this.getIndividualHeader() : this.getAddressBookHeader()}
                ${this.isOfTypeIndividual() ? this.getIndividuaBody(duplicates) : this.getAddressBookBody(duplicates)}
                </table>
                </div>
                <br />
                Are you sure you want to continue?`;
    }

    formSubmitted(): void {
        if (this.addressBook && this.addressBook.Id > 0 && this.addressBook.AddressBookTypeId && this.isCourtType && this.FacitlityControlsVisible()) {
            this.form.get('AddressBook.AttachmentIdentifier').updateValueAndValidity();
            this.form.get('AddressBook.DaysToProduce').mtSetRequired(true);
            this.form.get('AddressBook.DaysToProduce').updateValueAndValidity();
        }
        this.setFacitityNameAsValidForIndividualType();
        this.setModifiersFieldForAddressbook();
        if (this.isFormValid()) {
            this.isFacilitySelected = true;
            if (this.FacitlityControlsVisible() && !this.isExistingFacilitySelected()) {
                this.addressBookformFactory.assignFormValues(this.addressBook, this.form.value.AddressBook as IAddressBook);
            }

            if (this.IndividualControlsVisible()) {
                this.indivdualFormFactory.assignFormValues(this.individual, this.form.value.Individual as IIndividual);
            }
            this.addressBook.Individuals.forEach((individual) => trimObjectStringProperties(individual));
            if (this.isContractRangeValid()) {
                this.saveAddressBook();
            }
        } else {
            this.updateFormAfterFailure();
        }
    }

    saveAddressBook(): void {
        if (this.isNewAddressBook() && !this.isExistingFacilitySelected()) {
            // Create Address Book / Address / Phone  / Individual and Individual Phone  when a facility is not selected on the Drop Down

            const addressBookObject = this.initializeAddressBook();

            // Check for Duplication before initial creation of address book;
            this.addressBookService
                .getPotentialDuplicates(addressBookObject)
                .pipe()
                .subscribe((response: IDuplicateAddressBook[]) => {
                    if (response && response.length > 0) {
                        this.modalService
                            .showModal({
                                cancelButtonColor: '#d33',
                                confirmButtonColor: '#3085d6',
                                confirmButtonText: 'Save',
                                focusConfirm: false,
                                html: this.getDuplicateTable(response),
                                showCancelButton: true,
                                showCloseButton: true,
                                title: '<strong>Duplicate Entrie(s) Found!</strong>',
                                width: 800,
                            })
                            .subscribe((result) => {
                                // result.value represents the index of the selected set up
                                if (result.value) {
                                    this.createAddressBook(addressBookObject);
                                }
                            });
                    } else {
                        this.createAddressBook(addressBookObject);
                    }
                });
        } else if (!this.isNewAddressBook() && !this.isExistingFacilitySelected()) {
            if (this.FacitlityControlsVisible()) {
                // Update Address Book when a facility is not selected on the Drop Down
                this.updateAddressBook();
            } else {
                // Update Individual
                this.saveIndividual();
            }
        } else if (this.selectedAddressBook.Id > 0) {
            // Create Individual and Phone for the Facility Selected
            this.createIndividualWhenFacilityIsSelected();
        }
    }

    initializeAddressBook(): IAddressBook {
        const addressBook: IAddressBook = this.initializeAddressBookBase();
        this.addAddress(addressBook);
        this.addPhoneNumber(addressBook);
        this.addEmailAddress(addressBook);
        this.addIndividuals(addressBook);
        return addressBook;
    }

    initializeAddressBookBase(): IAddressBook {
        const addressBook: IAddressBook = this.form.value.AddressBook;
        addressBook.CreatedById = this.getCurrentUser();
        addressBook.DateCreated = this.getTodaysDate();
        return addressBook;
    }

    addAddress(addressBook: IAddressBook): void {
        addressBook.OverridenAddresses = [];
        addressBook.OverridenAddresses.push(this.initializeAddress(this.form.value.Address as IAddress));
    }

    initializeAddress(Address: IAddress): IAddress {
        const address: IAddress = Address;
        address.CreatedById = this.getCurrentUser();
        address.DateCreated = this.getTodaysDate();
        address.IsPrimary = true;
        return address;
    }

    addPhoneNumber(addressBook: IAddressBook): void {
        if (!(+MetaAddressBookTypeIdEnums.Individual === addressBook.MetaAddressBookTypeId)) {
            addressBook.PhoneNumbers = [];
            addressBook.PhoneNumbers.push(this.initializePhone(this.form.value.AddressBookPhone as IPhoneNumber));
        }
    }

    addEmailAddress(addressBook: IAddressBook): void {
        if (!(+MetaAddressBookTypeIdEnums.Individual === addressBook.MetaAddressBookTypeId)) {
            addressBook.EmailAddresses = [];
            addressBook.EmailAddresses.push(this.initializeEmail(this.form.value.AddressBookEmail as IEmailAddress));
        }
    }

    initializePhone(Phone: IPhoneNumber): IPhoneNumber {
        const phone: IPhoneNumber = Phone;
        phone.CreatedById = this.getCurrentUser();
        phone.DateCreated = this.getTodaysDate();
        phone.IsPrimary = true;
        return phone;
    }

    initializeEmail(Email: IEmailAddress): IEmailAddress {
        const email: IEmailAddress = Email;
        email.CreatedById = this.getCurrentUser();
        email.DateCreated = this.getTodaysDate();
        email.IsPrimary = true;
        return email;
    }

    addIndividuals(addressBook: IAddressBook): void {
        if (
            +MetaAddressBookTypeIdEnums.Facility_Organization === addressBook.MetaAddressBookTypeId &&
            this.form.value.Individual.FirstName === '' &&
            this.form.value.Individual.LastName === ''
        ) {
            return;
        } // Do not save individuals if these fields aren't entered.

        const toCreateindividual: IIndividual = this.initializeIndividuals();

        toCreateindividual.PhoneNumbers = [];
        toCreateindividual.PhoneNumbers.push(this.initializePhone(this.form.value.IndividualPhone as IPhoneNumber));

        toCreateindividual.EmailAddresses = [];
        toCreateindividual.EmailAddresses.push(this.initializeEmail(this.form.value.IndividualEmail as IEmailAddress));

        if (+MetaAddressBookTypeIdEnums.Individual === addressBook.MetaAddressBookTypeId) {
            addressBook.FacilityName = '';
        }

        addressBook.Individuals = [];
        trimObjectStringProperties(toCreateindividual);
        addressBook.Individuals.push(toCreateindividual);
    }

    isAPhysician(): boolean {
        return (
            this.addressBook.AddressBookTypeId === +AddressBookTypeIdEnums.Physician_panel ||
            this.addressBook.AddressBookTypeId === +AddressBookTypeIdEnums.Expert_panel ||
            (this.selectedAddressBook &&
                this.selectedAddressBook.Id > 0 &&
                (this.selectedAddressBook.AddressBookTypeId === +AddressBookTypeIdEnums.Physician_panel ||
                    this.selectedAddressBook.AddressBookTypeId === +AddressBookTypeIdEnums.Expert_panel))
        );
    }

    initializeDefaultPhysicianStatus(toCreateindividual: IIndividual): void {
        if (this.isAPhysician()) {
            toCreateindividual.Physicians = [];

            const toCreatePhysician = <IPhysician>{};
            toCreatePhysician.PhysicianDetails = [];
            const toCreatePhysicianDetail = <IPhysicianDetail>{};

            toCreatePhysicianDetail.IntroductoryLetterSentDate = this.getTodaysDate();

            toCreatePhysicianDetail.PhysicianStatusId = PhysicianStatusesIdEnums.Introductory_Letter_Sent;
            toCreatePhysicianDetail.IntroductoryLetterSentDate = this.getTodaysDate();
            toCreatePhysicianDetail.CreatedById = this.getCurrentUser();
            toCreatePhysicianDetail.DateCreated = this.getTodaysDate();
            toCreatePhysicianDetail.ImedClaimTypeId = ClaimTypeEnums.GL;

            toCreatePhysicianDetail.PhysicianStatusLogs = [];

            const toCreatePhysicianLog = <IPhysicianStatusLog>{};

            toCreatePhysicianLog.ChangedById = this.getCurrentUser();
            toCreatePhysicianLog.ChangedDate = this.getTodaysDate();
            toCreatePhysicianLog.PhysicianStatusId = PhysicianStatusesIdEnums.Introductory_Letter_Sent;
            toCreatePhysicianDetail.PhysicianStatusLogs.push(toCreatePhysicianLog);
            toCreatePhysician.PhysicianDetails.push(toCreatePhysicianDetail);

            toCreatePhysician.PhysicianFollowupLogs = [];

            const toCreatePhysicianFollowupLog = <IPhysicianFollowupLog>{};

            toCreatePhysicianFollowupLog.ImedClaimTypeId = ClaimTypeEnums.GL;
            toCreatePhysicianFollowupLog.AddedById = this.getCurrentUser();
            toCreatePhysicianFollowupLog.AddedDate = this.getTodaysDate();
            toCreatePhysicianFollowupLog.FollowupDate = this.addDays(this.getTodaysDate(), 7);
            toCreatePhysician.PhysicianFollowupLogs.push(toCreatePhysicianFollowupLog);

            toCreateindividual.Physicians.push(toCreatePhysician);
        }
    }

    initializeIndividuals(): IIndividual {
        const toCreateindividual: IIndividual = this.form.value.Individual;
        toCreateindividual.CreatedById = this.getCurrentUser();
        toCreateindividual.DateCreated = this.getTodaysDate();
        this.initializeDefaultPhysicianStatus(toCreateindividual);
        return toCreateindividual;
    }

    initializeIndividualsForSelectedFacility(): IIndividual {
        const toCreateindividual: IIndividual = this.initializeIndividuals();
        toCreateindividual.AddressBookId = this.selectedAddressBook.Id;

        toCreateindividual.PhoneNumbers = [];
        toCreateindividual.PhoneNumbers.push(this.initializePhone(this.form.value.IndividualPhone as IPhoneNumber));

        toCreateindividual.EmailAddresses = [];
        toCreateindividual.EmailAddresses.push(this.initializeEmail(this.form.value.IndividualEmail as IEmailAddress));

        this.initializeDefaultPhysicianStatus(toCreateindividual);

        return toCreateindividual;
    }

    getCurrentUser(): number {
        return this.authService.currentUser.getValue().Id;
    }

    getTodaysDate(): Date {
        return new Date();
    }

    addDays(date: Date, days: number): Date {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    createAddressBook(toBeCreatedAddressBook: IAddressBook): void {
        toBeCreatedAddressBook.Notes = toBeCreatedAddressBook.Notes.replace(/[^(\x20-\x7F)]*/g, '');
        this.addressBookService
            .initializeAddressBook(toBeCreatedAddressBook)
            .pipe()
            .subscribe((addressBook) => {
                if (!this.isOfTypeIndividual()) {
                    this.redirectToAddressBookInfo(addressBook.Id);
                } else {
                    this.redirectToIndividualInfo(addressBook.Id, addressBook.Individuals[0].Id);
                }
            });
    }

    redirectToAddressBookInfo(addressBookId): void {
        void this.router.navigate(['/address-books/' + addressBookId]);
        this.addressBookService.emitChange(this.addressBook);
        this.addressService.emitChange(this.addressBookAddress);
        this.success();
    }

    updateAddressBook(): void {
        this.addressBook.Individuals.forEach((individual) => trimObjectStringProperties(individual));
        this.addressBook.Notes = this.addressBook.Notes.replace(/[^(\x20-\x7F)]*/g, '');
        if (this.isCourtType) {
            this.form.value.PhysicalAddress.Id = this.addressBook.PhysicalAddressId;
            this.form.value.MailingAddress.Id = this.addressBook.MailingAddressId;
            this.addressBook.PhysicalAddress = this.initializeAddress(this.form.value.PhysicalAddress as IAddress);
            this.addressBook.MailingAddress = this.initializeAddress(this.form.value.MailingAddress as IAddress);
        } else {
            this.addressBook.PhysicalAddress = null;
            this.addressBook.MailingAddress = null;
        }
        this.addressBookService
            .updateWithFks(this.addressBook)
            .pipe()
            .subscribe(
                () => {
                    if (this.IndividualControlsVisible()) {
                        this.saveIndividual();
                    } else {
                        this.markNotEditing();
                        this.addressBookService.emitChange(this.addressBook);
                        this.success();
                        this.setConfig();
                    }
                },
                () => {
                    this.updateFormAfterFailure();
                },
            );
    }

    saveIndividual(): void {
        trimObjectStringProperties(this.individual);
        if (this.isNewIndividual()) {
            this.createIndividual();
        } else {
            this.updateIndividual();
        }
    }

    redirectToIndividualInfo(addressBookId, individualId): void {
        void this.router.navigate([`address-books/${addressBookId}/individuals/${individualId}`]);
        this.success();
        this.individualService.emitChange(this.individual);
        this.addressBookService.emitChange(this.addressBook);
    }

    // only called on individual creation from Individual Basic Info
    createIndividual(): void {
        this.individual.AddressBookId = this.addressBook.Id;
        this.initializeDefaultPhysicianStatus(this.individual);
        this.individualService
            .initializeIndividual(this.individual)
            .pipe()
            .subscribe(
                (individual) => {
                    this.redirectToIndividualInfo(this.addressBook.Id, individual.Id);
                },
                () => {
                    this.updateFormAfterFailure();
                },
            );
    }

    createIndividualWhenFacilityIsSelected(): void {
        this.individualService
            .initializeIndividual(this.initializeIndividualsForSelectedFacility())
            .pipe()
            .subscribe((individual) => {
                this.redirectToIndividualInfo(individual.AddressBookId, individual.Id);
            });
    }

    updateIndividual(): void {
        this.individualService
            .update(this.individual)
            .pipe()
            .subscribe(
                () => {
                    this.markNotEditing();
                    this.success();
                    this.individualService.emitChange(this.individual);
                    this.addressBookService.emitChange(this.addressBook);
                    this.setConfig();
                },
                () => {
                    this.updateFormAfterFailure();
                },
            );
    }

    updateFormAfterFailure(): void {
        this.isFacilitySelected = false;
        markAllFormFieldsAsTouched(this.form);
        this.error();
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }

    cancelClick(): void {
        if (this.isIndividualPage) {
            if (this.isNewIndividual()) {
                if (this.isOfTypeIndividual()) {
                    void this.router.navigate(['address-books']);
                } else {
                    void this.router.navigate([`address-books/${this.addressBook.Id}/individuals`]); // TODO : PS - NGIN to figure out a better way of handling this
                }
            } else {
                this.markNotEditing();
            }
        } else {
            if (this.isNewAddressBook()) {
                this.markEditing();
                void this.router.navigate(['address-books']);
            } else {
                this.markNotEditing();
            }
        }
    }
}
