import { Component } from '@angular/core';

import { IEntityListDynamicCellComponent, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { IEntity } from '@mt-ng2/base-service';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IModalOptions } from '@mt-ng2/modal-module';

import { ClaimTypes } from '@model/ClaimTypes';
import { IImedClaimPartial } from '@model/partials/imed-claim-partial';

@Component({
    template: `
        <div *ngIf="imedClaim && canDelete">
            <div *ngIf="imedClaim.Archived" (mtConfirm)="archiveFollowup($event)" class="text-center" [mtConfirmOptions]="unarchiveConfirm">
                <i class="fa fa-trash fa-lg" aria-hidden="true"></i><i class="fa fa-lg fa-undo overlay-fa"></i>
            </div>
            <div *ngIf="!imedClaim.Archived" class="text-center" (mtConfirm)="archiveFollowup($event)" [mtConfirmOptions]="archiveConfirm">
                <i class="fa fa-trash fa-lg" aria-hidden="true"></i>
            </div>
        </div>
    `,
})
export class ImedClaimDeleteDynamicCellComponent implements IEntityListDynamicCellComponent {
    entityListComponentMembers: IEntityListComponentMembers;
    set entity(value: IEntity) {
        this.imedClaim = value as IImedClaimPartial;
    }

    imedClaim: IImedClaimPartial;
    canDelete: boolean;

    archiveConfirm: IModalOptions = {
        confirmButtonText: 'Yes, I am sure!',
        text: `Are you sure you want to delete this claim?`,
        title: 'Delete Claim',
    	icon: 'warning',
};

    unarchiveConfirm: IModalOptions = {
        confirmButtonText: 'Yes, I am sure!',
        text: `Are you sure you want to restore this claim?`,
        title: 'Restore Claim',
    	icon: 'question',
};

    constructor(private claimsService: ClaimsService) {}

    ngOnInit(): void {
        this.canDelete = this.claimsService.hasClaim(ClaimTypes.CanDeleteFollowups, [ClaimValues.FullAccess]);
    }

    archiveFollowup(event: Event): void {
        this.entityListComponentMembers.itemDeleted(this.imedClaim as IEntity, event);
    }
}
