import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { DynamicField, DynamicFieldType, SelectInputTypes, DynamicFieldTypes, IDynamicFieldType, IDynamicField } from '@mt-ng2/dynamic-form';
import { AbstractControl } from '@angular/forms';
import { AddressBookAssociationsToggleService } from './services/ab-associations-type-toggle.service';
import { AddressBookAssociationTypeEnums } from '@common/constants/Enums';

@Component({
    selector: 'app-task-status-followup-engine-header',
    template: `
        <div>
            <h2>
                <mt-route-back-button></mt-route-back-button>
                {{ header }}
                <div *ngIf="showToggle" class="pull-right">
                    <mt-dynamic-field [field]="associationTypeToggle" (valueChanges)="selectionChanged($event)"></mt-dynamic-field>
                </div>
            </h2>
        </div>
        <router-outlet></router-outlet>
    `,
})
export class AddressBookAssociationHeaderComponent implements OnInit {
    header = '';
    routeSubscription: Subscription;
    associationTypeToggle: DynamicField;
    showToggle = false;
    buttons: AbstractControl;
    addressBookAssociationTypes = [
        { Id: 1, Name: 'System Task Associations' },
        { Id: 2, Name: 'Email Associations' },
    ];
    ABTypeAssociationTypeSubscription: Subscription;
    selectedABTypeAssociationType: AddressBookAssociationTypeEnums = AddressBookAssociationTypeEnums.SYSTEM_TASK;
    constructor(private route: ActivatedRoute, private ABTypeAssociationsToggleService: AddressBookAssociationsToggleService) {}

    ngOnInit(): void {
        this.routeSubscription = this.route.url.subscribe((route) => {
            if (route[0].path === 'address-book-type') {
                this.createABTypeAssociationTypeToggle();
                this.ABTypeAssociationTypeSubscription = this.ABTypeAssociationsToggleService.getAssociationType().subscribe((type) => {
                    this.selectedABTypeAssociationType = type;
                    this.setAssociationHeader();
                });
                this.showToggle = true;
            }
        });
    }

    ngOnDestroy(): void {
        this.routeSubscription.unsubscribe();
        if (this.ABTypeAssociationTypeSubscription) {
            this.ABTypeAssociationTypeSubscription.unsubscribe();
        }
    }

    createABTypeAssociationTypeToggle(): void {
        this.associationTypeToggle = new DynamicField({
            formGroup: null,
            label: '',
            name: 'associationTypeToggle',
            options: this.addressBookAssociationTypes,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.RadioButtonList,
            } as IDynamicFieldType),
            value: this.selectedABTypeAssociationType,
        } as IDynamicField);
    }

    setAssociationHeader(): void {
        switch (this.selectedABTypeAssociationType) {
            case AddressBookAssociationTypeEnums.SYSTEM_TASK:
                this.header = 'Address Book Type Associations - System Tasks';
                break;
            case AddressBookAssociationTypeEnums.EMAIL_ASSOCIATIONS:
                this.header = 'Address Book Type Associations - Email Templates';
                break;
            default:
        }
    }

    selectionChanged(addressBookAssociationTypeEnum: AddressBookAssociationTypeEnums): void {
        this.ABTypeAssociationsToggleService.setAssociationType(addressBookAssociationTypeEnum);
    }
}
