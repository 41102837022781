import { IndividualService } from './individuals/individual.service';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, IRoleGuarded, ClaimValues } from '@mt-ng2/auth-module';
import { AddressBookDetailComponent } from './address-book-detail/address-book-detail.component';
import { AddressBooksComponent } from './address-book-list/address-books.component';
import { AddressBookService } from './addressbook.service';
import { AddressBookHeaderComponent } from './address-book-header/address-book-header.component';

import { ClaimTypes } from '@model/ClaimTypes';
import { IndividualsComponent } from './individuals/individual-list/individuals.component';
import { IndividualDetailComponent } from './individuals/individual-detail/individual-detail.component';
import { IndividualHeaderComponent } from './individuals/individual-header/individual-header.component';
import { SharedEntitiesListComponent, SharedEntitiesInfoComponent } from '../../../node_modules/@mt-ng2/shared-entities-module';
import { AddressBookEmailSharedEntity, AddressBookPhoneNumberSharedEntity } from './shared/addressbook.shared-entities';
import { AddressesComponent } from './addresses/address-list/addresses.component';
import { AddressDetailComponent } from './addresses/address-detail/address-detail.component';
import { AddressHeaderComponent } from './addresses/address-header/address-header.component';
import { AddressService } from './addresses/address.service';
import { IndividualEmailSharedEntity, IndividualPhoneNumberSharedEntity } from './individuals/shared/individual.shared-entities';
import { PhysicianFollowupsComponent } from './individuals/physicians/physician-followup-list/physician-followup.component';
import { PhysicianStatusLogsComponent } from './individuals/physicians/physician-status-list/physician-status-logs.component';
import { PhysicianVacationsComponent } from './individuals/physicians/physician-vacations/physician-vacation-list/physician-vacations.component';
import { PhysicianVacationService } from './individuals/physicians/physician-vacations/physicianvacation.service';
import { PhysicianVacationHeaderComponent } from './individuals/physicians/physician-vacations/physician-vacation-header/physician-vacation-header.component';
import { PhysicianVacationDetailComponent } from './individuals/physicians/physician-vacations/physician-vacation-detail/physician-vacation-detail.component';
import { AddressBooksAddIndividualComponent } from './add/address-books-add-individual.component';
import { AddressBooksAddFacilityComponent } from './add/address-books-add-facility.component';
import { CommunicationLogsComponent } from '../imed-claims/services/common-components/communication-log/communication-logs.component';
import { CommunicationLogTypeEnums } from '../common/constants/Enums';
// import { IndividualHeaderComponent } from './individuals/individual-header/individual-header.component';

const addressBookEntityConfig = {
    claimType: ClaimTypes.AddressBooks,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'addressBookId',
    service: AddressBookService,
    sharedEntities: [AddressBookEmailSharedEntity, AddressBookPhoneNumberSharedEntity],
    title: 'Address Book Info',
};
const addressBookRoleGuard: any = {
    claimType: ClaimTypes.AddressBooks,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Address Book',
};

const addressBookCommunicationLogsConfig: any = {
    claimType: ClaimTypes.AddressBooks,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    logType: CommunicationLogTypeEnums.INDIVIDUAL,
    title: 'Communication Logs',
};

const addressBookAddRoleGuard: IRoleGuarded = {
    claimType: ClaimTypes.AddressBooks,
    claimValues: [ClaimValues.FullAccess],
};

const individualEntityConfig = {
    claimType: ClaimTypes.AddressBooks,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'individualId',
    service: IndividualService,
    sharedEntities: [IndividualEmailSharedEntity, IndividualPhoneNumberSharedEntity],
    title: 'Individual Detail',
};

const addressEntityConfig = {
    claimType: ClaimTypes.AddressBooks,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'addressId',
    service: AddressService,
    title: 'Address Detail',
};

const physicianVacationEntityConfig = {
    claimType: ClaimTypes.AddressBooks,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'physicianVacationId',
    service: PhysicianVacationService,
    title: 'Physician Vacation Detail',
};

const individualListRoleGuard = {
    claimType: ClaimTypes.AddressBooks,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Individuals',
};

const physicianFollowupListRoleGuard = {
    claimType: ClaimTypes.AddressBooks,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Physician Followups',
};

const individualAddRoleGuard = {
    claimType: ClaimTypes.AddressBooks,
    claimValues: [ClaimValues.FullAccess],
};

const addressBookRoutes: Routes = [
    { path: 'address-books', component: AddressBooksComponent, canActivate: [AuthGuard], data: addressBookRoleGuard },
    // settings
    {
        canActivate: [AuthGuard],
        children: [
            { path: '', component: AddressBookDetailComponent, pathMatch: 'full' },
            { path: 'individual', component: AddressBooksAddIndividualComponent, pathMatch: 'full' },
            { path: 'facility', component: AddressBooksAddFacilityComponent, pathMatch: 'full' },
        ],
        component: AddressBookHeaderComponent,
        data: addressBookAddRoleGuard,
        path: 'address-books/add',
    },
    {
        canActivate: [AuthGuard],
        children: [
            { path: '', component: AddressBookDetailComponent, pathMatch: 'full' },
            {
                component: IndividualsComponent,
                data: individualListRoleGuard,
                path: `individuals`,
            },
            {
                children: [{ path: '', component: IndividualDetailComponent, pathMatch: 'full' }],
                component: IndividualHeaderComponent,
                data: individualAddRoleGuard,
                path: `individuals/add`,
            },
            {
                children: [
                    { path: '', component: IndividualDetailComponent, pathMatch: 'full' },
                    {
                        // Email Addresses
                        component: SharedEntitiesListComponent,
                        data: { title: 'Email Addresses' },
                        path: IndividualEmailSharedEntity.path,
                        pathMatch: 'full',
                    },
                    {
                        component: SharedEntitiesListComponent,
                        data: { title: 'Phone Numbers' },
                        path: IndividualPhoneNumberSharedEntity.path,
                        pathMatch: 'full',
                    },
                    {
                        component: SharedEntitiesInfoComponent,
                        path: `${IndividualEmailSharedEntity.path}/:${IndividualEmailSharedEntity.entityIdParam}`,
                        pathMatch: 'full',
                    },
                    {
                        component: SharedEntitiesInfoComponent,
                        path: `${IndividualPhoneNumberSharedEntity.path}/:${IndividualPhoneNumberSharedEntity.entityIdParam}`,
                        pathMatch: 'full',
                    },
                    {
                        component: PhysicianFollowupsComponent,
                        data: { title: 'Physician Followup Logs' },
                        path: 'physician-followups',
                        pathMatch: 'full',
                    },
                    {
                        component: PhysicianStatusLogsComponent,
                        data: { title: 'Physician Status Logs' },
                        path: 'physician-statuses',
                        pathMatch: 'full',
                    },
                    {
                        // Vacations
                        component: PhysicianVacationsComponent,
                        data: addressBookRoleGuard,
                        path: `physician-vacations`,
                    },
                    {
                        children: [{ path: '', component: PhysicianVacationDetailComponent, pathMatch: 'full' }],
                        component: PhysicianVacationHeaderComponent,
                        data: addressBookRoleGuard,
                        path: `physician-vacations/add`,
                    },
                    {
                        children: [{ path: '', component: PhysicianVacationDetailComponent, pathMatch: 'full' }],
                        component: PhysicianVacationHeaderComponent,
                        data: physicianVacationEntityConfig,
                        path: `physician-vacations/:${physicianVacationEntityConfig.entityIdParam}`,
                    },
                    {
                        component: CommunicationLogsComponent,
                        data: addressBookCommunicationLogsConfig,
                        path: `communication-logs`,
                    },
                ],
                component: IndividualHeaderComponent,
                data: individualEntityConfig,
                path: `individuals/:${individualEntityConfig.entityIdParam}`,
            },
            {
                // Email Addresses
                component: SharedEntitiesListComponent,
                data: { title: 'Email Addresses' },
                path: AddressBookEmailSharedEntity.path,
                pathMatch: 'full',
            },
            {
                component: SharedEntitiesListComponent,
                data: { title: 'Phone Numbers' },
                path: AddressBookPhoneNumberSharedEntity.path,
                pathMatch: 'full',
            },
            {
                component: SharedEntitiesInfoComponent,
                path: `${AddressBookEmailSharedEntity.path}/:${AddressBookEmailSharedEntity.entityIdParam}`,
                pathMatch: 'full',
            },
            {
                component: SharedEntitiesInfoComponent,
                path: `${AddressBookPhoneNumberSharedEntity.path}/:${AddressBookPhoneNumberSharedEntity.entityIdParam}`,
                pathMatch: 'full',
            },
            {
                // Addresses
                component: AddressesComponent,
                data: addressBookRoleGuard,
                path: `addresses`,
            },
            {
                children: [{ path: '', component: AddressDetailComponent, pathMatch: 'full' }],
                component: AddressHeaderComponent,
                data: addressBookRoleGuard,
                path: `addresses/add`,
            },
            {
                children: [{ path: '', component: AddressDetailComponent, pathMatch: 'full' }],
                component: AddressHeaderComponent,
                data: addressEntityConfig,
                path: `addresses/:${addressEntityConfig.entityIdParam}`,
            },
        ],
        component: AddressBookHeaderComponent,
        data: addressBookEntityConfig,
        path: `address-books/:${addressBookEntityConfig.entityIdParam}`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(addressBookRoutes)],
})
export class AddressBookRoutingModule { }
