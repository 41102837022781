import { ChangeDetectorRef, Component, OnInit, Input, ViewRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { AuthService } from '@mt-ng2/auth-module';
import { UserService } from '../../../../users/user.service';
import { IUser } from '@model/interfaces/user';
import { IPhysician } from '@model/interfaces/physician';
import { IndividualService } from '../../individual.service';
import { IIndividual } from '@model/interfaces/individual';
import { PhysicianService } from '../physician.service';
import { PhysicianRecruitmentDynamicControlsPartial } from '@model/partials/physician-recruitment-partial.form-controls';
import { IPhysicianRecruitment } from '@model/interfaces/physician-recruitment';
import { finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { safeDetectChanges } from '../../../../common/cdr-safety/cdr-safety.library';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';

@Component({
    selector: 'physician-recruitment',
    templateUrl: './physician-recruitment.component.html',
})
export class PhysicianRecruitmentsComponent implements OnInit {
    @Input('individual') individual: IIndividual;
    @Input('canEdit') canEdit: boolean;

    isHovered: boolean;

    // abstract controls
    abstractPhysicianRecruitmentControls: any;
    abstractPhysicianRecruitmentControlsView: any;

    physicianRecruitmentForm: UntypedFormGroup;
    users: IUser[] = [];
    physician: IPhysician;
    doubleClickIsDisabled = false;
    formCreated = false;
    isEditing = false;
    userSubscription: Subscription;

    constructor(
        private fb: UntypedFormBuilder,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private userService: UserService,
        private authService: AuthService,
        private physicianService: PhysicianService,
        private individualService: IndividualService,
    ) { }

    ngOnInit(): void {
        this.physician = this.individual.Physicians[0];
        this.userSubscription = this.userService.getAll().subscribe((users) => {
            this.users = users;
            this.createForm();
        });
    }

    ngOnDestroy(): void {
        this.userSubscription.unsubscribe();
        this.cdr.detach();
    }

    createForm(): void {
        this.getControls();
        this.physicianRecruitmentForm = this.assignFormGroups();
        this.formCreated = true;
        safeDetectChanges(this.cdr);
    }

    getControls(): void {
        const abstractPhysicianRecruitmentControls = new PhysicianRecruitmentDynamicControlsPartial(null, {
            createdBies: null,
            formGroup: 'PhysicianRecruitment',
            modifiedBies: null,
            physicians: null,
            physicianUsers: this.users,
        });
        this.abstractPhysicianRecruitmentControls = abstractPhysicianRecruitmentControls.Form;
        this.abstractPhysicianRecruitmentControlsView = abstractPhysicianRecruitmentControls.View;

        this.abstractPhysicianRecruitmentControls.PhysicianUserId.value = this.physician.PhysicianRecruitments.map((physicianRecruitment) => {
            return physicianRecruitment.PhysicianUserId;
        });
    }

    assignFormGroups(): UntypedFormGroup {
        return this.fb.group({
            PhysicianRecruitment: this.fb.group({}),
        });
    }

    formSubmitted(): void {
        if (this.physicianRecruitmentForm.valid) {
            this.formatDataForSave();
            this.physicianService
                .saveRecruitments(this.physician).subscribe(() => {
                    this.notificationsService.success('Recruitment Saved Successfully');
                    this.individualService.getById(this.individual.Id).subscribe((individual) => {
                        this.individual = individual;
                        this.physician = this.individual.Physicians[0];
                        this.isEditing = false;
                        this.getControls();
                    });
                });
        } else {
            markAllFormFieldsAsTouched(this.physicianRecruitmentForm);
            this.error();
        }
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }

    formatDataForSave(): void {
        const recruitmentIds: number[] = this.physicianRecruitmentForm.value.PhysicianRecruitment.PhysicianUserId;
        this.mergeRecruitments(recruitmentIds);
    }

    mergeRecruitments(recruitmentIds: number[]): void {
        this.physician.PhysicianRecruitments = this.physician.PhysicianRecruitments.filter((physicianRecruitment: IPhysicianRecruitment) => {
            return recruitmentIds.indexOf(physicianRecruitment.Id) > -1; // filter out specialties that aren't in the new array of Ids
        });
        recruitmentIds.forEach((id) => {
            if (
                this.physician.PhysicianRecruitments.findIndex((physicianRecruitment: IPhysicianRecruitment) => physicianRecruitment.Id === id) === -1
            ) {
                // if a recruitment should be added, add a new one
                this.physician.PhysicianRecruitments.push({
                    Archived: false,
                    CreatedById: this.authService.currentUser.getValue().Id,
                    DateCreated: new Date(),
                    Id: 0,
                    PhysicianId: this.physician.Id,
                    PhysicianUserId: id,
                });
            } // don't do anything if keeping an existing recruitment
        });
    }

    getValueToDisplayForRecruitments(): string {
        const recruitments = this.physician.PhysicianRecruitments;
        const result = recruitments
            .map((physicianRecruitment) => {
                return physicianRecruitment.PhysicianUser
                    ? physicianRecruitment.PhysicianUser.FirstName + ' ' + physicianRecruitment.PhysicianUser.LastName
                    : '';
            })
            .join(', ');
        return result;
    }
}
