import { IImedClaimService } from '@model/interfaces/imed-claim-service.d';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { IBillsForReview } from '@model/interfaces/bills-for-review';
import { BillsForReviewService, emptyBillsForReview } from '../bills-for-review.service';
import { BillsForReviewDynamicConfig } from './bills-for-review-form.dynamic-config';
import { DynamicField, DynamicLabel, IDynamicFormConfig } from '@mt-ng2/dynamic-form';

@Component({
    selector: 'app-bills-for-review-form',
    templateUrl: './bills-for-review-form.component.html',
})
export class BillsForReviewFormComponent implements OnInit {
    @Input() iImedClaimServiceModel: IImedClaimService;
    @Input() billForReview: IBillsForReview;
    @Output('refreshBillsForReview') refreshBillsForReview: EventEmitter<any> = new EventEmitter<any>();

    config: IDynamicFormConfig;
    formFactory: BillsForReviewDynamicConfig<IBillsForReview>;

    doubleClickIsDisabled = false;

    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    constructor(
        private billsForReviewService: BillsForReviewService,
        private notificationsService: NotificationsService,
        private fb: UntypedFormBuilder,
    ) {}

    ngOnInit(): void {
        this.config = { formObject: [], viewOnly: [] };
        this.setConfig();
    }

    ngOnChanges(changes): void {
        if (changes.billForReview && !changes.billForReview.isFirstChange()) {
            this.config = null;
            setTimeout(() => this.setConfig());
        }
    }

    resetForm(): void {
        this.config = null;
        this.billForReview = { ...emptyBillsForReview };
        setTimeout(() => this.setConfig());
    }

    setConfig(): void {
        this.formFactory = new BillsForReviewDynamicConfig<IBillsForReview>(this.billForReview);
        this.config = this.formFactory.getForUpdate();

        this.viewOnly = this.config?.viewOnly?.map((x) => new DynamicLabel(x));
        this.formObject = this.config.formObject?.map((x) => new DynamicField(x));
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.billForReview, form.value.BillsForReview as IBillsForReview);

            this.billForReview.ImedClaimServiceId = this.iImedClaimServiceModel.Id;
            this.billForReview.IMedClaimService = null;

            if (this.billForReview.Id > 0) {
                this.billsForReviewService.update(this.billForReview).subscribe(
                    () => {
                        this.success();
                    },
                    () => {
                        this.error();
                    },
                );
            } else {
                this.billsForReviewService.create(this.billForReview).subscribe(
                    () => {
                        this.success();
                    },
                    () => {
                        this.error();
                    },
                );
            }
        } else {
            markAllFormFieldsAsTouched(form);
            this.error();
        }
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.notificationsService.success('Bill for Review Saved Successfully');
        this.refreshBillsForReview.emit();
        this.resetForm();
    }
}
