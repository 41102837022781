import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, OperatorFunction } from 'rxjs';
import { BaseService } from '@mt-ng2/base-service';
import { IImedClaimCourtFee } from '@model/interfaces/imed-claim-court-fee';
import { DateTimeConverterService } from '@common/services/date-time-converter.service';
import { DatePipe } from '@angular/common';
import { catchError } from 'rxjs/operators';

export const emptyImedClaimCourtFee: IImedClaimCourtFee = {
    CreatedById: 0,
    DateCreated: null,
    Fees: 0,
    Id: 0,
    ImedClaimId: 0,
};

@Injectable({
    providedIn: 'root',
})
export class ImedClaimCourtFeesService extends BaseService<IImedClaimCourtFee> {

    newCourtFees: IImedClaimCourtFee[] = [];
    constructor(public http: HttpClient, private datePipe: DatePipe) {
        super('/imedclaims', http);
    }

    getEmptyImedClaimCourtFee(): IImedClaimCourtFee {
        return { ...emptyImedClaimCourtFee };
    }

    getCourtFees(imedClaimId: number): Observable<IImedClaimCourtFee[]> {
        return this.http.get<IImedClaimCourtFee[]>(`/imedclaims/${imedClaimId}/court-fees`);
    }

    addCourtFee(imedClaimId: number, imedClaimCourtFee: IImedClaimCourtFee): Observable<number> {
        return this.http.post<number>(`/imedclaims/${imedClaimId}/court-fees`, imedClaimCourtFee);
    }

    editCourtFee(imedClaimId: number, imedClaimCourtFee: IImedClaimCourtFee): Observable<number> {
        return this.http.put<number>(`/imedclaims/${imedClaimId}/court-fees`, imedClaimCourtFee);
    }

    delete(id: number): Observable<any> {
        return this.http.delete(`/imedclaims/${id}/court-fees`).pipe(catchError((e: Response) => this.handleError(e)) as OperatorFunction<unknown, any>);
    }

    hasSubpoenaService(imedClaimId: number): Observable<boolean> {
        return this.http.get<boolean>(`/imedclaims/${imedClaimId}/hasSubpoenaService`);
    }
}
