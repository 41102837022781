import { BaseAttachmentsService } from '../../../../../common/attachments/base-attachments.service';
import { AttachmentControlComponent } from '../../../../../common/attachment-control/attachment-control.component';

import { Component, Input, OnInit, ViewChild, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { FileItem, FileLikeObject } from 'ng2-file-upload';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { AttachmentTypeEnums, ServiceIdEnums } from '../../../../../common/constants/Enums';
import { IAttachmentType } from '@model/interfaces/attachment-type';
import { IAttachmentDTO } from '@model/interfaces/custom/attachment-dto';
import { DocumentDynamicControlsPartial } from '@model/partials/document-partial.form-controls';
import { DynamicFieldType, DynamicFieldTypes, SelectInputTypes, IDynamicFieldType, IDynamicField, DynamicField } from '@mt-ng2/dynamic-form';
import { AttachmentTypeService } from '../../../../../common/attachments/attachment-type.service';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'app-authorization-attachment-upload-client',
    templateUrl: './authorization-attachment-upload-client.component.html',
})
export class AuthorizationAttachmentUploadClientComponent implements OnInit, AfterViewInit {
    @Input('imedClaimId') imedClaimId: number;
    @Input('imedClaimServiceId') imedClaimServiceId: number;
    @Input('isSoftPopup') isSoftPopup: boolean;
    @Output('onClose') onClose: EventEmitter<any> = new EventEmitter<any>();
    @Output('onDocumentChanged') onDocumentChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output('onDocumentSaved') onDocumentSaved: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('docComponentAuthorization') docComponentAuthorization: AttachmentControlComponent;
    @ViewChild('docComponentPOA') docComponentPOA: AttachmentControlComponent;
    @Input('documentArray') documentArray: IAttachmentDTO[];

    errorMessage: string;
    documentForm: UntypedFormGroup;
    formCreated = false;
    attachmentTypes: IAttachmentType[];
    abstractDocumentControls: any;
    individualOrCaseAuth: AttachmentTypeEnums;
    authDynamicControl: DynamicField;
    authOptions: IAttachmentType[];

    constructor(
        private attachmentsService: BaseAttachmentsService,
        private attachmentTypeService: AttachmentTypeService,
        private fb: UntypedFormBuilder,
        private notificationsService: NotificationsService,
    ) { }

    ngOnInit(): void {
        this.attachmentTypeService
            .mapServiceToAttachmentServiceType(ServiceIdEnums.Radiology_Received_From_Client)
            .pipe(switchMap((attachmentTypeId) => this.attachmentTypeService.getAttachmentTypes(attachmentTypeId)))
            .subscribe(
                (types) =>
                (this.authOptions = types.filter(
                    (type) => type.Id === +AttachmentTypeEnums.Authorization_Individual || type.Id === +AttachmentTypeEnums.Authorizations_All,
                )),
                () => null,
                () => this.buildForm(),
            );
    }

    ngAfterViewInit(): void {
        if (this.docComponentAuthorization) {
            this.docComponentAuthorization.uploader.onWhenAddingFileFailed = (item, filter, docComponentAuthorization: AttachmentControlComponent) =>
                this.onWhenAddingFileFailed(item, filter, docComponentAuthorization);
        }

        if (this.docComponentPOA) {
            this.docComponentPOA.uploader.onWhenAddingFileFailed = (item, filter, docComponentPOA: AttachmentControlComponent) =>
                this.onWhenAddingFileFailed(item, filter, docComponentPOA);
        }
    }

    createAuthTypeControl(): void {
        this.authDynamicControl = new DynamicField({
            formGroup: this.documentForm.value,
            label: 'Authorization Type',
            name: 'AuthType',
            options: this.authOptions,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
            } as IDynamicFieldType),
            value: this.individualOrCaseAuth,
        } as IDynamicField);
        this.authDynamicControl.setRequired(true);
    }

    setAuthType(value: AttachmentTypeEnums): void {
        this.individualOrCaseAuth = value;
    }

    buildForm(): void {
        this.documentForm = this.fb.group({
            Document: this.fb.group({}),
        });
        this.abstractDocumentControls = new DocumentDynamicControlsPartial(null, {
            attachmentTypes: this.attachmentTypes,
        }).Form;
        this.createAuthTypeControl();
        this.ngAfterViewInit();
        this.formCreated = true;
    }

    onWhenAddingFileFailed(item: FileLikeObject, filter: any, docComponent: AttachmentControlComponent): void {
        const allowedTypes = docComponent.allowedMimeType.join();
        switch (filter.name) {
            case 'fileSize':
                this.errorMessage = `Maximum upload size exceeded (${item.size} of ${docComponent.maxFileSize} allowed)`;
                break;
            case 'mimeType':
                this.errorMessage = `Type "${item.type} is not allowed. Allowed types: "${allowedTypes}"`;
                break;
            default:
                this.errorMessage = `Unknown error (filter is ${filter.name})`;
        }
        this.notificationsService.error(this.errorMessage);
    }

    Save(): void {
        const Authorizationfile: FileItem = this.docComponentAuthorization.uploader.queue[this.docComponentAuthorization.uploader.queue.length - 1];
        if (!this.individualOrCaseAuth) {
            this.notificationsService.error('Authorization type must be selected.');
            return;
        }
        if (Authorizationfile) {
            this.attachmentsService
                .createAndUpload('imed-claim-service', this.imedClaimServiceId, Authorizationfile._file, this.individualOrCaseAuth, 0, 0)
                .subscribe(() => {
                    const POAFile: FileItem = this.docComponentPOA.uploader.queue[this.docComponentPOA.uploader.queue.length - 1];
                    if (POAFile) {
                        this.attachmentsService
                            .createAndUpload('imed-claim', this.imedClaimId, POAFile._file, AttachmentTypeEnums.Power_Of_Attorney, 0, 0)
                            .subscribe(() => {
                                this.onDocumentChanged.emit();
                                this.onDocumentSaved.emit();
                                this.notificationsService.success('Authorization / POA Documents uploaded');
                            });
                    } else {
                        this.onDocumentChanged.emit();
                        this.onDocumentSaved.emit();
                        this.notificationsService.success('Authorization document uploaded');
                    }
                });
        } else {
            this.notificationsService.error('No File(s) Chosen');
        }
    }

    closeSoftPopupAndSave(): void {
        this.onClose.emit();
    }

    cancel(): void {
        this.onClose.emit();
    }
}
