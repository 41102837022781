import { Component, Input, OnInit, ViewChild, AfterViewInit, EventEmitter, Output, ElementRef, ComponentRef, Renderer2 } from '@angular/core';
import { saveAs } from 'file-saver';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IAttachmentDTO } from '@model/interfaces/custom/attachment-dto';
import { BaseAttachmentsService } from '../attachments/base-attachments.service';
import { IAttachmentListWithFaxNumberDTO } from '@model/interfaces/custom/attachment-list-with-fax-number';
import { FaxService } from './fax.service';
import { finalize } from 'rxjs/operators';
import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Subscription } from 'rxjs';
import { DocumentTransactionLogService } from '../attachments/document-transaction-log.service';
import { DocumentTransactionTypeEnums } from '../constants/Enums';
import { SelectContactFaxes } from '../select-contact-faxes/select-contact-faxes.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-fax-control',
    templateUrl: './fax-control.component.html',
})
export class FaxControlComponent implements OnInit {
    @Input('entityId') entityId: number;
    @Input('isDialog') isDialog = true;
    @Input('type') type: string;
    @Input('imedclaimserviceid') imedclaimserviceid: number;
    @Output('closeComponent') closeComponent: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('faxNumberInput') faxNumberInput: ElementRef;
    documentArray: IAttachmentDTO[] = [];
    selectedDocuments: IAttachmentDTO[] = [];
    faxNumber: string;
    doubleClickIsDisabled = false;
    overlayRef: OverlayRef;
    subscriptions: Subscription = new Subscription();
    constructor(
        private attachmentsService: BaseAttachmentsService,
        private notificationsService: NotificationsService,
        private faxService: FaxService,
        private overlay: Overlay,
        private elementRef: ElementRef,
        private overlayPositionBuilder: OverlayPositionBuilder,
        private docTransactionLogService: DocumentTransactionLogService,
    ) { }

    ngOnInit(): void {
        this.getDocuments();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        if (this.overlayRef) {
            this.overlayRef.dispose();
        }
    }

    getDocuments(): void {
        this.attachmentsService.all(this.type, this.entityId, '').subscribe((response) => {
            this.documentArray = response.body;
        });
    }

    downloadDoc(index): void {
        if (this.documentArray[index].IsManual) {
            this.attachmentsService.downloadUploadedDocuments(this.type, this.documentArray[index].Id).subscribe(
                (x) => {
                    const thefile = new Blob([x], { type: 'application/octet-stream' });
                    saveAs(thefile, this.documentArray[index].Name);
                },
                (err) => {
                    this.docTransactionLogService
                        .logError(err.message as HttpErrorResponse, this.documentArray[index].Id, DocumentTransactionTypeEnums.MANUAL_DOWNLOAD_FE)
                        .subscribe();
                    this.notificationsService.error('Document upload failed');
                },
            );
        } else {
            this.attachmentsService.downloadEmailedDocument(this.type, this.documentArray[index].Id).subscribe(
                (x) => {
                    const thefile = new Blob([x], { type: 'application/octet-stream' });
                    saveAs(thefile, this.documentArray[index].Name);
                },
                (err) => {
                    this.docTransactionLogService
                        .logError(err.message as HttpErrorResponse, this.documentArray[index].Id, DocumentTransactionTypeEnums.MANUAL_DOWNLOAD_FE)
                        .subscribe(),
                        this.notificationsService.error('Document upload failed');
                },
            );
        }
    }

    itemSelected(item: IAttachmentDTO, index: number, event: any): void {
        if (event.target.checked) {
            this.selectedDocuments.push(item);
        } else {
            this.selectedDocuments.splice(index, 1);
        }
    }

    SendFax(): void {
        if (this.selectedDocuments.length === 0) {
            this.notificationsService.error('At-least one document is required for faxing.');
            return;
        }

        if (this.faxNumber === undefined || this.faxNumber === null || this.faxNumber === '') {
            this.notificationsService.error('Fax number is required.');
            return;
        }

        const attachmentListWithFaxNumberDTO = <IAttachmentListWithFaxNumberDTO>{};
        attachmentListWithFaxNumberDTO.FaxNumber = this.faxNumber;
        attachmentListWithFaxNumberDTO.ImedClaimServiceId = this.imedclaimserviceid;
        attachmentListWithFaxNumberDTO.SelectedAttachments = this.selectedDocuments;
        this.faxService
            .send(attachmentListWithFaxNumberDTO)            .subscribe(
                () => {
                    this.notificationsService.success('Fax Sent Successfully');
                    this.closeComponent.emit();
                },
                () => {
                    this.notificationsService.error(
                        'Fax could not be sent. Please check if the Fax Number exists and / or the document is not empty.',
                    );
                    setTimeout(() => (this.doubleClickIsDisabled = false));
                },
            );
    }

    Cancel(): void {
        this.closeComponent.emit();
    }

    openFaxSelect(): void {
        if (!this.overlayRef || !this.overlayRef.hasAttached()) {
            const positionStrategy = this.overlayPositionBuilder.flexibleConnectedTo(this.elementRef).withPositions([
                {
                    originX: 'end',
                    originY: 'top',
                    overlayX: 'center',
                    overlayY: 'bottom',
                },
            ]);
            this.overlayRef = this.overlay.create({ positionStrategy, disposeOnNavigation: true, panelClass: 'overlay-on-top' });
            const portal = new ComponentPortal(SelectContactFaxes);
            const ref: ComponentRef<SelectContactFaxes> = this.overlayRef.attach(portal);
            ref.instance.imedclaimserviceid = this.imedclaimserviceid;
            this.subscribeToFaxSelect(ref);
        }
    }

    subscribeToFaxSelect(component: ComponentRef<SelectContactFaxes>): void {
        this.subscriptions.add(component.instance.close.subscribe(() => this.overlayRef.detach()));
        this.subscriptions.add(component.instance.selectItems.subscribe((items) => (this.faxNumber = items.join(', '))));
    }
}
