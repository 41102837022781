import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-historical-notes',
    template: `
        <div class="miles-card padded" *ngIf="historicalNotes">
            <h4>
                Historical Notes:
            </h4>
            <p>{{ historicalNotes }}</p>
        </div>
    `,
})
export class HistoricalNotesComponent {
    @Input() historicalNotes: string;

    constructor() {}
}
