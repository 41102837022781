import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { IPhysicianVacation } from '@model/interfaces/physician-vacation';
import { PhysicianVacationService } from '../physicianvacation.service';

@Component({
    selector: 'app-physician-vacation-header',
    templateUrl: './physician-vacation-header.component.html',
})
export class PhysicianVacationHeaderComponent implements OnInit, OnDestroy {
    physicianVacation: IPhysicianVacation;
    header: string;
    subscriptions: Subscription = new Subscription();

    constructor(private physicianVacationService: PhysicianVacationService, private route: ActivatedRoute) { }

    ngOnInit(): void {
        this.subscriptions.add(
            this.physicianVacationService.changeEmitted$.subscribe((physicianVacation) => {
                this.setHeader(physicianVacation);
            }),
        );
        const id = +this.route.snapshot.paramMap.get('physicianVacationId');
        if (id > 0) {
            this.physicianVacationService.getById(id).subscribe((physicianVacation) => {
                this.setHeader(physicianVacation);
            });
        } else {
            this.header = 'Add Physician Vacation';
            this.physicianVacation = this.physicianVacationService.getEmptyPhysicianVacation();
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    setHeader(physicianVacation: IPhysicianVacation): void {
        this.physicianVacation = physicianVacation;
        this.header = `Physician Vacation: ${''}`;
    }
}
