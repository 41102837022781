import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ViewLogService } from '../../common/services/viewlog.service';
import { IViewLog } from '@model/interfaces/view-log';
import { AuthService } from '@mt-ng2/auth-module';
import { ViewLogEntityTypeEnums } from '../../common/constants/Enums';
import { DashboardService, IRecentlyViewedCase } from '../dashboard.service';

@Component({
    selector: 'app-recently-viewed-cases-widget',
    styleUrls: ['../base-dashboard-component/base-dashboard-common-css.component.less'],
    templateUrl: './recently-viewed-cases.component.html',
})
export class RecentlyViewedCasesWidget implements OnInit {
    recentCases: IRecentlyViewedCase[];

    constructor(private router: Router, private authService: AuthService, private dashboardService: DashboardService) { }

    ngOnInit(): void {
        this.dashboardService.getRecentlyViewedCases(this.authService.currentUser.getValue().Id).subscribe((result) => {
            this.recentCases = result;
        });
    }

    goToCase(route: string): void {
        void this.router.navigate([route]);
    }
}
