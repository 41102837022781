import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IUserRole } from '@model/interfaces/user-role';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { UserRoleService } from '../user-role.service';
import { ClaimTypes } from '@model/ClaimTypes';
import { IEntitySearchParams, ExtraSearchParams } from '@mt-ng2/common-classes';
import { entityListModuleConfig } from '../../common/shared.module';
import { debounceTime } from 'rxjs/operators';
import { UserTypeService } from '../usertype.service';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';

@Component({
    selector: 'app-user-roles',
    templateUrl: './user-roles.component.html',
})
export class UserRolesComponent implements OnInit {
    searchControl = new UntypedFormControl();
    userRoles: IUserRole[];
    userTypes: MtSearchFilterItem[] = [];
    currentPage = 1;
    total: number;
    canAddRole = false;
    itemsPerPage = entityListModuleConfig.itemsPerPage;

    constructor(private userRoleService: UserRoleService, private claimService: ClaimsService, public userTypeService: UserTypeService) {}

    ngOnInit(): void {
        this.searchControl.valueChanges.pipe(debounceTime(300)).subscribe(() => this.getUserRoles());

        this.userTypeService.getSearchFilterItems().subscribe((answer) => (this.userTypes = answer));

        this.canAddRole = this.claimService.hasClaim(ClaimTypes.UserRoles, [ClaimValues.FullAccess]);
        this.getUserRoles();
    }

    getUserRoles(): void {
        const search = this.searchControl.value;

        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'typeids',
                valueArray: this.userTypes.filter((item) => item.Selected).map((item) => item.Item.Id),
            }),
        );

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: entityListModuleConfig.itemsPerPage,
        };

        this.userRoleService.get(searchEntity).subscribe((answer) => {
            this.userRoles = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    getPrimaryCaseTypes(role: IUserRole): string {
        const defaults: string[] = [];
        if (role.GeneralLiability) {
            defaults.push('General Liability');
        }
        if (role.WorkersComp) {
            defaults.push('Workers Compensation');
        }
        if (role.Auto) {
            defaults.push('No Fault / PIP');
        }
        return defaults.join(', ');
    }

    clearSearch(): void {
        this.searchControl.setValue('');
    }

    noRoles(): boolean {
        return !this.userRoles || this.userRoles.length === 0;
    }

    pagingNeeded(): boolean {
        return this.total > this.itemsPerPage;
    }
}
