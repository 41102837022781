import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';
import { IMasterServiceStatus } from '@model/interfaces/master-service-status';

export const emptyMasterServiceStatus: IMasterServiceStatus = {
    Archived: false,
    Auto: null,
    GeneralLiability: null,
    Id: 0,
    Name: null,
    WorkersComp: null,
};

@Injectable({
    providedIn: 'root',
})
export class MasterServiceStatusService extends BaseService<IMasterServiceStatus> {
    constructor(public http: HttpClient) {
        super('/master-service-statuses', http, null, {
            entityName: 'Service Status',
        });
    }

    formatTitleText(masterServiceStatus: IMasterServiceStatus): void {
        this.setTitle(`Service Status: ${masterServiceStatus.Name}`);
    }

    getEmptyMasterServiceStatus(): IMasterServiceStatus {
        return { ...emptyMasterServiceStatus };
    }
}
