// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            .shared-list {
                display: block;
                padding: 5px;
                margin: 10px 0 10px;
                word-break: break-all;
                word-wrap: break-word;
            }
        `, "",{"version":3,"sources":["webpack://./src/app/imed-claims/services/service-specifics/auto-record-review/auto-record-reviewl-basic-info/auto-record-review-basic-info.component.ts"],"names":[],"mappings":";YACY;gBACI,cAAc;gBACd,YAAY;gBACZ,mBAAmB;gBACnB,qBAAqB;gBACrB,qBAAqB;YACzB","sourcesContent":["\n            .shared-list {\n                display: block;\n                padding: 5px;\n                margin: 10px 0 10px;\n                word-break: break-all;\n                word-wrap: break-word;\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
