import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IUserType } from '@model/interfaces/user-type';

@Injectable()
export class UserTypeService extends StaticMetaItemService<IUserType> {
    constructor(public http: HttpClient) {
        super('UserTypeService', 'Type', 'TypeIds', '/options/usertypes', http);
    }
}
