import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { IIndividual } from '@model/interfaces/individual';
import { IPhoneNumber } from '@model/interfaces/phone-number';
import { IEmailAddress } from '@model/interfaces/email-address';
import { IAddress } from '@model/interfaces/address';
import { INf10CCsDTO } from '@model/interfaces/custom/nf10-ccs-dto';
import { IImedClaimSubpoenaCoDefenseCounselAssociation } from '@model/interfaces/imed-claim-subpoena-co-defense-counsel-association';

export const emptyIndividual: IIndividual = {
    AddressBookId: 0,
    Archived: false,
    Attention: null,
    CreatedById: 0,
    DateCreated: null,
    FirstName: null,
    Id: 0,
    LastName: null,
    ModifiedById: null,
    Suffix: null,
    Title: null,
    Wcb: null,
};

@Injectable({
    providedIn: 'root'
})
export class IndividualService extends BaseService<IIndividual> {
    constructor(public http: HttpClient) {
        super('/individuals', http);
    }

    getEmptyIndividual(): IIndividual {
        return { ...emptyIndividual };
    }

    savePhones(individualId: number, phones: IPhoneNumber[]): Observable<number> {
        return this.http.put<number>(`/phones/individuals/${individualId}/phones`, phones);
    }

    initializeIndividual(individual: any): Observable<IIndividual> {
        return this.http.post<IIndividual>('/individuals/initialize', individual);
    }

    getIndividualPhones(individualId: number): Observable<IPhoneNumber[]> {
        return this.http.get<IPhoneNumber[]>(`/individuals/${individualId}/phones/`);
    }

    getIndividualEmails(individualId: number): Observable<IEmailAddress[]> {
        return this.http.get<IEmailAddress[]>(`/individuals/${individualId}/emails/`);
    }

    getIndividualAvailableLocations(individualId: number): Observable<IAddress[]> {
        return this.http.get<IAddress[]>(`/individuals/${individualId}/available-locations/`);
    }

    saveAvailableLocations(individualId: number, availableLocations: IAddress[]): Observable<IAddress[]> {
        return this.http.put<IAddress[]>(`/individuals/${individualId}/available-locations`, availableLocations);
    }

    getIndividualAddresses(individualId: number): Observable<IAddress[]> {
        return this.http.get<IAddress[]>(`/individuals/${individualId}/addresses/`);
    }

    getIndividualFacility(individualId: number): Observable<string> {
        return this.http.get<string>(`/individuals/${individualId}/facility/`);
    }

    getIndividualFacilityPhones(individualId: number): Observable<IPhoneNumber[]> {
        return this.http.get<IPhoneNumber[]>(`/individuals/${individualId}/facilityPhones/`);
    }

    getIndividualFacilityEmails(individualId: number): Observable<IEmailAddress[]> {
        return this.http.get<IEmailAddress[]>(`/individuals/${individualId}/facilityEmails/`);
    }

    getIndividualsByIds(individualIds: number[]): Observable<IIndividual[]> {
        return this.http.post<IIndividual[]>(`/individuals/get-by-ids/`, individualIds);
    }

    createNF10CC(individualId: number): Observable<INf10CCsDTO> {
        return this.http.get<INf10CCsDTO>(`/individuals/create-nf10cc/${individualId}`);
    }

    getSubpoenaCounsel(individualId: number): Observable<any> {
        return this.http.get<any>(`/individuals/get-subpoena-counsel/${individualId}`);
    }

    saveWetSignaturePhoto(individualId: number, photo: File): any {
        const formData: FormData = new FormData();
        formData.append('file', photo, photo.name);
        return this.http.post(`/individuals/${individualId}/wetsignature`, formData);
    }

    deleteWetSignaturePhoto(individualId: number): any {
        return this.http.delete(`/individuals/${individualId}/wetsignature`);
    }

    canUploadWetSignaturePic(individualId: number): any {
        return this.http.get(`/individuals/${individualId}/canUploadWetSignature`);
    }
}
