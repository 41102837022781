import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IRecordRetrievalService } from '../interfaces/record-retrieval-service';
import { IUser } from '../interfaces/user';
import { IImedClaimService } from '../interfaces/imed-claim-service';

export interface IRecordRetrievalServiceDynamicControlsParameters {
    formGroup?: string;
    imedClaimServices?: IImedClaimService[];
    createdBies?: IUser[];
    modifiedBies?: IUser[];
    processingDateChangedBies?: IUser[];
}

export class RecordRetrievalServiceDynamicControls {

    formGroup: string;
    imedClaimServices: IImedClaimService[];
    createdBies: IUser[];
    modifiedBies: IUser[];
    processingDateChangedBies: IUser[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private recordretrievalservice?: IRecordRetrievalService, additionalParameters?: IRecordRetrievalServiceDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'RecordRetrievalService';
        this.imedClaimServices = additionalParameters && additionalParameters.imedClaimServices || undefined;
        this.createdBies = additionalParameters && additionalParameters.createdBies || undefined;
        this.modifiedBies = additionalParameters && additionalParameters.modifiedBies || undefined;
        this.processingDateChangedBies = additionalParameters && additionalParameters.processingDateChangedBies || undefined;

        this.Form = {
            Archived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived',
                name: 'Archived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordretrievalservice && this.recordretrievalservice.hasOwnProperty('Archived') && this.recordretrievalservice.Archived !== null ? this.recordretrievalservice.Archived : false,
            }),
            CreatedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Created By',
                name: 'CreatedById',
                options: this.createdBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.recordretrievalservice && this.recordretrievalservice.CreatedById || null,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordretrievalservice && this.recordretrievalservice.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordretrievalservice && this.recordretrievalservice.DateModified || null,
            }),
            ImedClaimServiceId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Imed Claim Service',
                name: 'ImedClaimServiceId',
                options: this.imedClaimServices,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.recordretrievalservice && this.recordretrievalservice.ImedClaimServiceId || null,
            }),
            LegacyImedClaimServiceId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Legacy Imed Claim Service',
                name: 'LegacyImedClaimServiceId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(10) ],
                validators: { 'maxlength': 10 },
                value: this.recordretrievalservice && this.recordretrievalservice.hasOwnProperty('LegacyImedClaimServiceId') && this.recordretrievalservice.LegacyImedClaimServiceId !== null ? this.recordretrievalservice.LegacyImedClaimServiceId.toString() : '',
            }),
            ModifiedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Modified By',
                name: 'ModifiedById',
                options: this.modifiedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordretrievalservice && this.recordretrievalservice.ModifiedById || null,
            }),
            ProcessingDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Processing Date',
                name: 'ProcessingDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordretrievalservice && this.recordretrievalservice.ProcessingDate || null,
            }),
            ProcessingDateChangedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Processing Date Changed By',
                name: 'ProcessingDateChangedById',
                options: this.processingDateChangedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordretrievalservice && this.recordretrievalservice.ProcessingDateChangedById || null,
            }),
            ProcessingDateChangedDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Processing Date Changed Date',
                name: 'ProcessingDateChangedDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordretrievalservice && this.recordretrievalservice.ProcessingDateChangedDate || null,
            }),
            RadiologyFacility: new DynamicField({
                formGroup: this.formGroup,
                label: 'Radiology Facility',
                name: 'RadiologyFacility',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.recordretrievalservice && this.recordretrievalservice.hasOwnProperty('RadiologyFacility') && this.recordretrievalservice.RadiologyFacility !== null ? this.recordretrievalservice.RadiologyFacility.toString() : '',
            }),
            Reference: new DynamicField({
                formGroup: this.formGroup,
                label: 'Reference',
                name: 'Reference',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(1000) ],
                validators: { 'required': true, 'maxlength': 1000 },
                value: this.recordretrievalservice && this.recordretrievalservice.hasOwnProperty('Reference') && this.recordretrievalservice.Reference !== null ? this.recordretrievalservice.Reference.toString() : '',
            }),
            SensitiveConditionsInitialed: new DynamicField({
                formGroup: this.formGroup,
                label: 'Sensitive Conditions Initialed',
                name: 'SensitiveConditionsInitialed',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recordretrievalservice && this.recordretrievalservice.hasOwnProperty('SensitiveConditionsInitialed') && this.recordretrievalservice.SensitiveConditionsInitialed !== null ? this.recordretrievalservice.SensitiveConditionsInitialed : false,
            }),
        };

        this.View = {
            Archived: new DynamicLabel({
			    label: 'Archived',
			    value: this.recordretrievalservice && this.recordretrievalservice.hasOwnProperty('Archived') && this.recordretrievalservice.Archived !== null ? this.recordretrievalservice.Archived : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            CreatedById: new DynamicLabel({
			    label: 'Created By',
			    value: getMetaItemValue(this.createdBies as unknown as IMetaItem[], this.recordretrievalservice && this.recordretrievalservice.hasOwnProperty('CreatedById') && this.recordretrievalservice.CreatedById !== null ? this.recordretrievalservice.CreatedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            DateCreated: new DynamicLabel({
			    label: 'Date Created',
			    value: this.recordretrievalservice && this.recordretrievalservice.DateCreated || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DateModified: new DynamicLabel({
			    label: 'Date Modified',
			    value: this.recordretrievalservice && this.recordretrievalservice.DateModified || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            ImedClaimServiceId: new DynamicLabel({
			    label: 'Imed Claim Service',
			    value: getMetaItemValue(this.imedClaimServices as unknown as IMetaItem[], this.recordretrievalservice && this.recordretrievalservice.hasOwnProperty('ImedClaimServiceId') && this.recordretrievalservice.ImedClaimServiceId !== null ? this.recordretrievalservice.ImedClaimServiceId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            LegacyImedClaimServiceId: new DynamicLabel({
			    label: 'Legacy Imed Claim Service',
			    value: this.recordretrievalservice && this.recordretrievalservice.hasOwnProperty('LegacyImedClaimServiceId') && this.recordretrievalservice.LegacyImedClaimServiceId !== null ? this.recordretrievalservice.LegacyImedClaimServiceId.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ModifiedById: new DynamicLabel({
			    label: 'Modified By',
			    value: getMetaItemValue(this.modifiedBies as unknown as IMetaItem[], this.recordretrievalservice && this.recordretrievalservice.hasOwnProperty('ModifiedById') && this.recordretrievalservice.ModifiedById !== null ? this.recordretrievalservice.ModifiedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ProcessingDate: new DynamicLabel({
			    label: 'Processing Date',
			    value: this.recordretrievalservice && this.recordretrievalservice.ProcessingDate || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            ProcessingDateChangedById: new DynamicLabel({
			    label: 'Processing Date Changed By',
			    value: getMetaItemValue(this.processingDateChangedBies as unknown as IMetaItem[], this.recordretrievalservice && this.recordretrievalservice.hasOwnProperty('ProcessingDateChangedById') && this.recordretrievalservice.ProcessingDateChangedById !== null ? this.recordretrievalservice.ProcessingDateChangedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ProcessingDateChangedDate: new DynamicLabel({
			    label: 'Processing Date Changed Date',
			    value: this.recordretrievalservice && this.recordretrievalservice.ProcessingDateChangedDate || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            RadiologyFacility: new DynamicLabel({
			    label: 'Radiology Facility',
			    value: this.recordretrievalservice && this.recordretrievalservice.hasOwnProperty('RadiologyFacility') && this.recordretrievalservice.RadiologyFacility !== null ? this.recordretrievalservice.RadiologyFacility.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Reference: new DynamicLabel({
			    label: 'Reference',
			    value: this.recordretrievalservice && this.recordretrievalservice.hasOwnProperty('Reference') && this.recordretrievalservice.Reference !== null ? this.recordretrievalservice.Reference.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            SensitiveConditionsInitialed: new DynamicLabel({
			    label: 'Sensitive Conditions Initialed',
			    value: this.recordretrievalservice && this.recordretrievalservice.hasOwnProperty('SensitiveConditionsInitialed') && this.recordretrievalservice.SensitiveConditionsInitialed !== null ? this.recordretrievalservice.SensitiveConditionsInitialed : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };

    }
}
