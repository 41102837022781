import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { IUser } from '@model/interfaces/user';
import { IAuditLogUserService, IAuditLogUser } from '@mt-ng2/audit-logging-module';

@Injectable()
export class AuditLogUserService implements IAuditLogUserService {
    private _allUsers: BehaviorSubject<IAuditLogUser[]> = new BehaviorSubject([]);

    constructor(public http: HttpClient) {
        this.refreshAllUsers();
    }

    setUsers(value: IAuditLogUser[]): void {
        this._allUsers.next(value);
    }

    refreshAllUsers(): void {
        this.http
            .get<IUser[]>('/api/v1/users/getAll')
            .pipe(
                map((users: IUser[]) => {
                    return users.map((user: IUser) => {
                        return {
                            AuthUserId: user.AuthUserId,
                            Id: user.AuthUserId,
                            Name: `${user.FirstName} ${user.LastName}`,
                        };
                    });
                }),
            )
            .subscribe((user) => this.setUsers(user));
    }

    getUsers(): Observable<IAuditLogUser[]> {
        return this._allUsers.asObservable();
    }
}
