import { DynamicField, DynamicLabel } from '@mt-ng2/dynamic-form';
import { IWcIssueDynamicControlsParameters, WcIssueDynamicControls } from '@model/form-controls/wc-issue.form-controls';
import { IWcIssue } from '@model/interfaces/wc-issue';

export class WcIssueDynamicControlsPartial extends WcIssueDynamicControls {
    constructor(wcIssuePartial?: IWcIssue, additionalParameters?: IWcIssueDynamicControlsParameters) {
        super(wcIssuePartial, additionalParameters);

        (<DynamicLabel>this.View.Mmi).label = 'MMI';
        (<DynamicField>this.Form.Mmi).labelHtml = '<label>MMI</label>';
        (<DynamicLabel>this.View.Slu).label = 'SLU';
        (<DynamicField>this.Form.Slu).labelHtml = '<label>SLU</label>';
        (<DynamicLabel>this.View.ObjectiveFindingsOnExamDiagnosis).label = 'Objective Findings on Exam / Diagnosis';
        (<DynamicField>this.Form.ObjectiveFindingsOnExamDiagnosis).labelHtml = '<label>Objective Findings on Exam / Diagnosis</label>';
        (<DynamicLabel>this.View.NeedForDme).label = 'Need for DME (Durable Medical Equipment)';
        (<DynamicField>this.Form.NeedForDme).labelHtml = '<label>Need for DME (Durable Medical Equipment)</label>';
        (<DynamicLabel>this.View.NeedForEssentialServicesHouseholdHelp).label = 'Need for Essential Services / Household Help';
        (<DynamicField>this.Form.NeedForEssentialServicesHouseholdHelp).labelHtml = '<label>Need for Essential Services / Household Help</label>';
        (<DynamicLabel>this.View.ReturnToWorkRestrictionsAdl).label = 'Return to Work / Restrictions / ADL';
        (<DynamicField>this.Form.ReturnToWorkRestrictionsAdl).labelHtml = '<label>Return to Work / Restrictions / ADL</label>';
        (<DynamicLabel>this.View.PastSubsequentMedicalHistory).label = 'Past / Subsequent Medical History';
        (<DynamicField>this.Form.PastSubsequentMedicalHistory).labelHtml = '<label>Past / Subsequent Medical History</label>';
        (<DynamicLabel>this.View.HistoryOfAllegedInjury).label = 'History of Alleged Injury';
        (<DynamicField>this.Form.HistoryOfAllegedInjury).labelHtml = '<label>History of Alleged Injury</label>';
        (<DynamicLabel>this.View.OccupationalHistory).label = 'Occupational History';
        (<DynamicField>this.Form.OccupationalHistory).labelHtml = '<label>Occupational History</label>';
        (<DynamicLabel>this.View.AdLsThatMayAffectThisClaim).label = 'ADLs That May Affect This Claim';
        (<DynamicField>this.Form.AdLsThatMayAffectThisClaim).labelHtml = '<label>ADLs That May Affect This Claim</label>';
        (<DynamicLabel>this.View.ObjectiveFindingsOnExam).label = 'Objective Findings on Exam';
        (<DynamicField>this.Form.ObjectiveFindingsOnExam).labelHtml = '<label>Objective Findings on Exam</label>';
        (<DynamicLabel>this.View.NeedForFurtherTreatmentWithinYourScope).label = 'Need for Further Treatment (Within Your Scope)';
        (<DynamicField>this.Form.NeedForFurtherTreatmentWithinYourScope).labelHtml = '<label>Need for Further Treatment (Within Your Scope)</label>';
        (<DynamicLabel>this.View.RecommendationForFurtherTreatment).label = 'Recommendation for Further Treatment';
        (<DynamicField>this.Form.RecommendationForFurtherTreatment).labelHtml = '<label>Recommendation for Further Treatment</label>';
        (<DynamicLabel>this.View.FunctionalCapabilityEvaluation).label = 'Functional Capability Evaluation';
        (<DynamicField>this.Form.FunctionalCapabilityEvaluation).labelHtml = '<label>Functional Capability Evaluation</label>';
    }
}
