import { Component } from '@angular/core';

import { IEntityListDynamicCellComponent, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { IEntity } from '@mt-ng2/base-service';
import { IModalOptions } from '@mt-ng2/modal-module';
import { ClaimValues, ClaimsService } from '@mt-ng2/auth-module';
import { IAddress } from '@model/interfaces/address';
import { AddressService } from '../address.service';
import { Router } from '@angular/router';
import { ClaimTypes } from '@model/ClaimTypes';

@Component({
    template: `
        <div *ngIf="address && canDelete">
            <div *ngIf="!address.Archived" class="text-left">
                <i (mtConfirm)="archiveAddress($event)" class="fa fa-trash fa-lg" aria-hidden="true" [mtConfirmOptions]="archiveConfirm"></i>
            </div>
            <div *ngIf="address.Archived" class="text-left">
                <span (click)="unarchiveAddress()" class="fa fa-trash fa-lg" aria-hidden="true"><i class="fa fa-lg fa-undo overlay-fa" ></i></span>
            </div>
        </div>
    `,
})
export class AddressDeleteDynamicCellComponent implements IEntityListDynamicCellComponent {
    entityListComponentMembers: IEntityListComponentMembers;
    set entity(value: IEntity) {
        this.address = value as IAddress;
    }

    address: IAddress;
    canDelete: boolean;
    canEdit: boolean;
    isUnarchive = false;

    archiveConfirm: IModalOptions = {
        confirmButtonText: 'Yes, I am sure!',
        text: `Are you sure you want to delete this address?`,
        title: 'Delete Address',
    	icon: 'question',
};

    constructor(private addressService: AddressService,
        private claimsService: ClaimsService,
        private router: Router) { }

    ngOnInit(): void {
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.AddressBooks, [ClaimValues.FullAccess]);
        this.canDelete = this.addressService.canDelete.getValue();
    }

    archiveAddress(event: Event): void {
        this.entityListComponentMembers.itemDeleted(this.address as IEntity, event);
    }

    unarchiveAddress(): void {
        if (this.canEdit) {
            void this.router.navigate([`address-books/${this.address.AddressBooks_AddressBookId[0].Id}/addresses/${this.address.Id}`], {
                queryParams: { isUnarchive: true },
            });
        } else {
            void this.router.navigate([`address-books/${this.address.AddressBooks_AddressBookId[0].Id}/addresses/${this.address.Id}`], {
                queryParams: {isUnarchive: false },
            });
        }
    }
}
