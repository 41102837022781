import { IEntityListConfig, EntityListColumn, EntityListConfig } from '@mt-ng2/entity-list-module';
import { IPhoneNumber } from '../../interfaces/phone-number';
import { PhoneDeleteCellComponent } from '../../../address-books/phone-delete-cell/phone-delete-cell.component';

export class PhoneNumberListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    name: 'Phone',
                    pipes: ['phone'],
                }),
                new EntityListColumn({
                    accessors: ['PhoneType', 'Name'],
                    name: 'Phone Type',
                }),
                new EntityListColumn({
                    accessorFunction: (phone: IPhoneNumber) => (phone.IsPrimary ? 'Primary' : ''),
                    name: 'Primary',
                }),
                new EntityListColumn({
                    name: 'Notes',
                }),
                new EntityListColumn({
                    component: PhoneDeleteCellComponent,
                    name: 'Delete',
                    sort: { disableSort: true },
                    style: { width: 50 },
                }),
            ],
        };
        super(listConfig);
    }
}
