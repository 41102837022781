import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';
import { entityListModuleConfig } from '../../common/shared.module';
import { GeneratedLetterTemplateEnityListConfig } from './generated-letter-template.enity-list-config';
import { IGeneratedLetterTemplate } from '@model/interfaces/generated-letter-template';
import { GeneratedLetterTemplateService } from '../generated-letter-template.service';

@Component({
    selector: 'app-generated-letter-templates',
    templateUrl: './generated-letter-templates.component.html',
})
export class GeneratedLetterTemplatesComponent implements OnInit {
    generatedLetterTemplates: IGeneratedLetterTemplate[];
    currentPage = 1;
    query = '';
    total: number;
    entityListConfig = new GeneratedLetterTemplateEnityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();
    includeArchived = false;
    tableOfContentLetterId = 20;
    constructor(private generatedLetterTemplateService: GeneratedLetterTemplateService, private router: Router) { }

    ngOnInit(): void {
        this.getGeneratedLetterTemplates();
    }

    getGeneratedLetterTemplates(): void {
        const search = this.query;
        const searchEntity: IEntitySearchParams = {
            extraParams: [],
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: entityListModuleConfig.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        this.generatedLetterTemplateService.get(searchparams).subscribe((answer) => {
            this.generatedLetterTemplates = answer.body.filter((item) => item.Id !== this.tableOfContentLetterId);
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.query = query;
        this.getGeneratedLetterTemplates();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getGeneratedLetterTemplates();
    }

    templateSelected(event: IItemSelectedEvent): void {
        void this.router.navigate(['/letter-templates', event.entity.Id]);
    }
}
