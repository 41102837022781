import { Observable, Subscription } from 'rxjs';
import { IAutomatedTemplate } from '@model/interfaces/automated-template';
import { AutomatedTemplateService } from '../services/automated-template.service';
import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    templateUrl: './automated-template-header.component.html',
})
export class AutomatedTemplateHeaderComponent implements OnInit {
    automatedTemplate: IAutomatedTemplate;
    header$: Observable<string>;

    readonly automatedTemplateService = inject(AutomatedTemplateService);
    readonly route = inject(ActivatedRoute);

    ngOnInit(): void {
        const id = +this.route.snapshot.paramMap.get('automatedTemplateId');
        this.automatedTemplateService.setInitialTitle(id);
        this.header$ = this.automatedTemplateService.title$;
    }
}
