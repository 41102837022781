import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { IUser } from '@model/interfaces/user';
import { IModalOptions, IModalWrapperApi } from '@mt-ng2/modal-module';
import { IUserRole } from '@model/interfaces/user-role';
import { DynamicField, DynamicFieldType, DynamicFieldTypes } from '@mt-ng2/dynamic-form';
import { SelectedAssignedToTypes } from '../../task-add/task-add.component';
import { MultiselectItem } from '@mt-ng2/multiselect-control';
import { MetaItem } from '@mt-ng2/base-service';
import { TaskService } from '../../task.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
export interface IAppTaskAssignBulkComponentApi {
    close: () => void;
    show: () => void;
}

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-task-assign-bulk',
    styles: [
        `
            button {
                max-width: 760px;
            }
            button > span {
                overflow-wrap: break-word;
                max-width: 750px;
                white-space: normal;
            }
        `,
    ],
    templateUrl: './task-assign-bulk.component.html',
})
export class TaskAssignBulkComponent implements OnInit, OnDestroy {
    modalWrapperApi: IModalWrapperApi;
    @Input() users: IUser[] = [];
    @Input() userRoles: IUserRole[] = [];
    @Input() taskIds: IUserRole[] = [];
    @Output() ready = new EventEmitter<IAppTaskAssignBulkComponentApi>();
    @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
    @Output() onTaskListUpdate: EventEmitter<void> = new EventEmitter<void>();
    subscriptions: Subscription = new Subscription();
    selectedAssignedToTypeId: any;
    selectedUserRoleIds: number[] = [];
    selectedUserIds: number[] = [];
    userRolesSelectItems: any = [];
    usersSelectItems: any = [];
    doubleClickIsDisabled = false;
    assignedToTypeId = new DynamicField({
        formGroup: '',
        label: 'Assign To',
        name: 'AssignedToTypeId',
        options: [
            { Id: SelectedAssignedToTypes.UserRoles, Name: 'User Roles' },
            { Id: SelectedAssignedToTypes.Users, Name: 'Users' },
        ],
        type: new DynamicFieldType({
            fieldType: DynamicFieldTypes.Select,
            scale: null,
        }),
        value: null,
    });

    modalOptions: IModalOptions = {
        customClass: 'modal-hide-actions' as any,
        width: 800,
    };
    constructor(private taskService: TaskService, private notificationService: NotificationsService) {}

    ngOnInit(): void {
        this.ready.emit({
            close: this.closeTaskAssignModal.bind(this),
            show: this.showModal.bind(this),
        });
        this.userRolesSelectItems = this.userRoles.map((x) => new MultiselectItem(new MetaItem(x.Id, x.Name), false));
        this.usersSelectItems = this.users.filter((u) => !u.Archived).map((x) => new MultiselectItem(new MetaItem(x.Id, x.FirstName + ' ' + x.LastName), false));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    showModal(): void {
        this.modalWrapperApi.show();
    }

    closeTaskAssignModal(): void {
        this.closeModal.emit();
        this.modalWrapperApi.close();
    }

    userRoleSelectionChanged(): void {
        this.selectedUserRoleIds = this.userRolesSelectItems.filter((x) => x.Selected).map((x) => x.Item.Id);
    }

    userSelectionChanged(): void {
        this.selectedUserIds = this.usersSelectItems.filter((x) => x.Selected).map((x) => x.Item.Id);
    }

    clearSelections(): void {
        this.userRolesSelectItems.forEach((x) => {
            x.Selected = false;
        });
        this.usersSelectItems.forEach((x) => {
            x.Selected = false;
        });
        this.selectedUserRoleIds = [];
        this.selectedUserIds = [];
    }

    cancel(): void {
        this.closeTaskAssignModal();
    }

    assign(): void {
        if (this.selectedAssignedToTypeId === SelectedAssignedToTypes.UserRoles) {
            this.taskService
                .assignTasksInBulk({ SelectedUserRoleIds: this.selectedUserRoleIds, SelectedUserIds: [], TaskIds: this.taskIds })
                .subscribe(
                    () => {
                        this.success();
                    },
                    () => {
                        this.error();
                    },
                );
        } else if (this.selectedAssignedToTypeId === SelectedAssignedToTypes.Users) {
            this.taskService.assignTasksInBulk({ SelectedUserRoleIds: [], SelectedUserIds: this.selectedUserIds, TaskIds: this.taskIds }).subscribe(
                () => {
                    this.success();
                },
                () => {
                    this.error();
                },
            );
        }
    }

    success(): void {
        this.onTaskListUpdate.emit();
        this.notificationService.success('Tasks assigned successfully');
        
        this.closeTaskAssignModal();
    }

    error(): void {
        this.notificationService.error('An error occured while trying to assign tasks');
        
    }
}
