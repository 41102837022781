import { IEntitySearchParams } from '@mt-ng2/common-classes';
import { IImedClaimDTO } from '@model/interfaces/custom/imedclaim-dto.d';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, Observable, Subscription } from 'rxjs';
import { ITask } from '@model/interfaces/task';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { TaskService, emptyTask } from '../task.service';
import { IUser } from '@model/interfaces/user';
import { TaskDynamicConfig } from '../task.dynamic-config';
import { UserService } from '../../users/user.service';
import { ImedClaimService } from '../../imed-claims/imedclaim.service';
import { TaskDynamicControlsPartial } from '@model/partials/task-partial.form-controls';
import { MultiselectItem, ISelectionChangedEvent } from '@mt-ng2/multiselect-control';

import { UserRoleService } from '../../user-roles/user-role.service';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { ITaskAssignedUserRole } from '@model/interfaces/task-assigned-user-role';
import { ITaskAssignedUser } from '@model/interfaces/task-assigned-user';
import { ISelectionChangedEvent as ITypeAheadSelectionChangedEvent, VirtualTypeAheadGetItemsFunction } from '@mt-ng2/type-ahead-control';
import { finalize } from 'rxjs/operators';
import { MetaItem } from '@mt-ng2/base-service';
import { IImedClaimServiceDropdownListOptions } from '@model/interfaces/custom/imed-claim-service-dropdown-list-options';
import { AssignedToTypes } from '../../../client-portal/common/constants/assigned-to-type.enum';
import { IUserRole } from '@model/interfaces/user-role';

export enum SelectedAssignedToTypes {
    UserRoles = 1,
    Users = 2,
}
@Component({
    selector: 'app-task-add',
    styles: [
        `
            .btn-assignment {
                margin-top: 20px;
            }
        `,
    ],
    templateUrl: './task-add.component.html',
})
export class TaskAddComponent implements OnInit, OnDestroy {
    task: ITask = emptyTask;
    editingComponent: Subject<any> = new Subject();
    canEdit: boolean;
    canAdd: boolean;
    id: number;
    users: IUser[];
    isEditing: boolean;
    isHovered: boolean;
    config: any = {};
    taskForm = new UntypedFormGroup({ Task: new UntypedFormGroup({}) });
    formFactory: TaskDynamicConfig<ITask>;
    doubleClickIsDisabled = false;
    usersSelectItems: MultiselectItem[];
    userRolesSelectItems: MultiselectItem[];
    controls: any;
    selectedAssignedToTypeId: number;
    selectedUserRoles: MetaItem[] = [];
    selectedUsers: MetaItem[] = [];
    assignedUsers: ITaskAssignedUser[];
    selectedUserRoleIds: number[];
    allServicesSelected = false;
    selectedService: IImedClaimServiceDropdownListOptions;
    allServices: IImedClaimServiceDropdownListOptions = { ImedClaimServiceId: 0, DisplayName: 'All Services', UserRoles: [] };
    noServices: IImedClaimServiceDropdownListOptions = { ImedClaimServiceId: -1, DisplayName: 'No Services', UserRoles: [] };
    services: IImedClaimServiceDropdownListOptions[] = [];
    selectedClaim: IImedClaimDTO;
    formValid = true;
    getItems: VirtualTypeAheadGetItemsFunction = this.getClaims.bind(this);
    userSubscription: Subscription;
    userRoles: IUserRole[];

    constructor(
        private taskService: TaskService,
        private userService: UserService,
        private userRoleService: UserRoleService,
        private router: Router,
        private notificationsService: NotificationsService,
        private imedClaimsService: ImedClaimService,
    ) {}

    ngOnInit(): void {
        this.subscribeToUsers();
        this.userRoleService.getAll().subscribe((userRoles) => {
            this.userRoles = userRoles;
            this.userRolesSelectItems = userRoles.map((item) => new MultiselectItem(new MetaItem(item.Id, item.Name), false));
            this.setConfig();
        });
    }

    ngOnDestroy(): void {
        this.userSubscription.unsubscribe();
    }

    subscribeToUsers(): void {
        this.userSubscription = this.userService.getAll().subscribe((users) => {
            this.users = users;
            this.usersSelectItems = users.filter((u) => !u.Archived).map((item) => new MultiselectItem(new MetaItem(item.Id, `${item.FirstName} ${item.LastName}`), false));
        });
    }

    getClaims(searchText: string): Observable<any[]> {
        const searchEntity: IEntitySearchParams = {
            query: searchText,
        };

        return this.imedClaimsService.getClaimsByName(searchEntity);
    }

    setConfig(): void {
        this.task = this.taskService.getEmptyTask();
        this.selectedClaim = <IImedClaimDTO>{};
        this.selectedClaim.Id = 0;

        this.controls = new TaskDynamicControlsPartial(this.task, null).Form;
        this.formFactory = new TaskDynamicConfig<ITask>(this.task, [], ['DueDate', 'TaskDescriptions', 'Notes']);
    }

    userRoleSelectionChanged(event: ISelectionChangedEvent): void {
        this.selectedUserRoles = event.selectedItems;
    }

    userSelectionChanged(event: ISelectionChangedEvent): void {
        this.selectedUsers = event.selectedItems;
    }

    cancelClick(): void {
        void this.router.navigate(['/tasks']);
    }

    selectionChanged(event: ITypeAheadSelectionChangedEvent): void {
        this.selectedClaim = event.selection;

        if (this.selectedClaim != null && this.selectedClaim.Id > 0) {
            this.imedClaimsService.getImedClaimServiceListForDropdown(this.selectedClaim.Id).subscribe((data) => {
                data.unshift(this.allServices);
                data.push(this.noServices);
                this.services = data;
            });
        }
    }

    assignUsersBasedOnServiceType(serviceId: number): void {
        const userRoleIds: MetaItem[] = [];
        this.allServicesSelected = serviceId === this.allServices.ImedClaimServiceId;

        if (serviceId > 0) {
            // Store UserRoles associated with selected service
            this.services
                .filter((service) => service.ImedClaimServiceId === serviceId)
                .forEach((service) =>
                    service.UserRoles.forEach((ur) => {
                        const role = new MetaItem(ur.Id, ur.Name);
                        userRoleIds.push(role);
                    }),
                );
            // Assign associated ImedClaimServiceId to task
            this.task.ImedClaimServiceId = serviceId;
        } else if (this.allServicesSelected) {
            // Store ALL UserRoles associated with any services for this claim
            this.services.forEach((service) =>
                service.UserRoles.forEach((ur) => {
                    const role = new MetaItem(ur.Id, ur.Name);
                    userRoleIds.push(role);
                }),
            );
        } else {
            this.task.ImedClaimServiceId = null;
        }
        // Select any UserRoles stored in userRoleIds[]
        this.userRolesSelectItems.forEach((ursi) => {
            ursi.Selected = userRoleIds.map((x) => x.Id).includes(ursi.Item.Id);
        });
        // Assign selected UserRoles to selecteduserRoles[]
        this.selectedUserRoles = userRoleIds;
    }

    onChangeAssignedTo(key: string): void {
        if (this.selectedAssignedToTypeId === +SelectedAssignedToTypes.UserRoles) {
            const userRolesToAdd = this.userRoles.filter((ur) => ur[key]);
            userRolesToAdd.forEach((role) => {
                const index = this.userRolesSelectItems.findIndex((r) => r.Item.Id === role.Id);
                this.userRolesSelectItems[index].Selected = true;
            });
        } else if (this.selectedAssignedToTypeId === +SelectedAssignedToTypes.Users) {
            const usersToAdd = this.users.filter((u) => u.AuthUser.UserRole[key]);
            usersToAdd.forEach((user) => {
                const index = this.usersSelectItems.findIndex((u) => u.Item.Id === user.Id);
                this.usersSelectItems[index].Selected = true;
            });
        }
    }

    formSubmitted(): void {
        const form = this.taskForm;
        const finalTasks: ITask[] = [];

        if (form.valid && (this.validateAssignedTypes(form) || this.allServicesSelected) && this.selectedClaim && this.selectedClaim.Id > 0) {
            if (this.allServicesSelected) {
                // handle new task for all services
                this.generateAllServicesTasks(finalTasks);
            } else {
                this.formFactory.assignFormValues(this.task, form.value.Task as ITask);
                this.task.ImedClaimId = this.selectedClaim.Id;

                // handle new task creation for a single task
                if (this.task.AssignedToTypeId === +AssignedToTypes.UserRoles) {
                    this.task.TaskAssignedUserRoles = this.selectedUserRoles.map(
                        (UserRole) =>
                            <ITaskAssignedUserRole>{
                                Id: 0,
                                Task: null,
                                TaskId: 0,
                                UserRole: null,
                                UserRoleId: UserRole.Id,
                            },
                    );
                    finalTasks.push(this.task);
                } else {
                    this.task.TaskAssignedUsers = this.selectedUsers.map(
                        (User) =>
                            <ITaskAssignedUser>{
                                Id: 0,
                                Task: null,
                                TaskId: 0,
                                User: null,
                                UserId: User.Id,
                            },
                    );
                    finalTasks.push(this.task);
                }
            }
            this.taskService
                .createTask(finalTasks)                .subscribe(() => {
                    void this.router.navigate(['/tasks/']);
                    this.success();
                    // this.taskService.emitChange(this.task);
                });
        } else {
            markAllFormFieldsAsTouched(form);
            this.formValid = false;
            this.error();
        }
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }

    validateAssignedTypes(form): boolean {
        let isValid = false;
        if (form.value.Task.AssignedToTypeId === AssignedToTypes.UserRoles && this.selectedUserRoles.length !== 0) {
            isValid = true;
        }
        if (form.value.Task.AssignedToTypeId === AssignedToTypes.Users && this.selectedUsers.length !== 0) {
            isValid = true;
        }
        return isValid;
    }

    generateAllServicesTasks(tasks: ITask[]): void {
        const form = this.taskForm;

        // Iterate through all service options and create a new task for each service
        this.services
            .filter((x) => x.ImedClaimServiceId > 0)
            .forEach((option) => {
                const newTask = { ...emptyTask };

                this.formFactory.assignFormValues(newTask, form.value.Task as ITask);
                newTask.AssignedToTypeId = AssignedToTypes.UserRoles;
                newTask.ImedClaimId = this.selectedClaim.Id;
                newTask.ImedClaimServiceId = option.ImedClaimServiceId;
                newTask.TaskAssignedUserRoles = option.UserRoles.map(
                    (UserRole) =>
                        <ITaskAssignedUserRole>{
                            Id: 0,
                            Task: null,
                            TaskId: 0,
                            UserRole: null,
                            UserRoleId: UserRole.Id,
                        },
                );

                tasks.push(newTask);
            });
    }
}
