import { DynamicField } from '@mt-ng2/dynamic-form';
import { IAutoIssueDynamicControlsParameters, AutoIssueDynamicControls } from '@model/form-controls/auto-issue.form-controls';
import { IAutoIssue } from '@model/interfaces/auto-issue';

export class AutoIssueDynamicControlsPartial extends AutoIssueDynamicControls {
    constructor(autoIssuePartial?: IAutoIssue, additionalParameters?: IAutoIssueDynamicControlsParameters) {
        super(autoIssuePartial, additionalParameters);

        (<DynamicField>this.View.Mmi).label = 'MMI';
        (<DynamicField>this.Form.Mmi).labelHtml = '<label>MMI</label>';
        (<DynamicField>this.View.Slu).label = 'SLU';
        (<DynamicField>this.Form.Slu).labelHtml = '<label>SLU</label>';
        (<DynamicField>this.View.ObjectiveFindingsOnExamDiagnosis).label = 'Objective Findings On Exam / Diagnosis';
        (<DynamicField>this.Form.ObjectiveFindingsOnExamDiagnosis).labelHtml = '<label>Objective Findings On Exam / Diagnosis</label>';
        (<DynamicField>this.View.NeedForDme).label = 'Need For DME (Durable Medical Equipment)';
        (<DynamicField>this.Form.NeedForDme).labelHtml = '<label>Need For DME (Durable Medical Equipment)</label>';
        (<DynamicField>this.View.NeedForEssentialServicesHouseholdHelp).label = 'Need For Essential Services / Household Help';
        (<DynamicField>this.Form.NeedForEssentialServicesHouseholdHelp).labelHtml = '<label>Need For Essential Services / Household Help</label>';
        (<DynamicField>this.View.ReturnToWorkRestrictionsAdl).label = 'Return To Work / Restrictions / ADL';
        (<DynamicField>this.Form.ReturnToWorkRestrictionsAdl).labelHtml = '<label>Return To Work / Restrictions / ADL</label>';
        (<DynamicField>this.View.AdLsThatMayAffectThisClaim).label = 'ADLs that may affect this claim';
        (<DynamicField>this.Form.AdLsThatMayAffectThisClaim).labelHtml = '<label>ADLs that may affect this claim</label>';
    }
}
