import { APIService } from '../services/api-service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAttachmentListWithFaxNumberDTO } from '@model/interfaces/custom/attachment-list-with-fax-number';

@Injectable()
export class FaxService extends APIService {
    constructor(public http: HttpClient) {
        super('/fax/', http);
    }

    send(request: IAttachmentListWithFaxNumberDTO): any {
        return this.http.post('/fax/send', request);
    }

    resend(communicationLogId: number, request: IAttachmentListWithFaxNumberDTO, faxNumber: string): any {
        return this.http.post(`/fax/${communicationLogId}/resend/${faxNumber}`, request);
    }
}
