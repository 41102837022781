import { Component } from '@angular/core';
import { IEntityListDynamicCellComponent, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { IEntity } from '@mt-ng2/base-service';
import { ITaskTemplate } from '@model/interfaces/task-template';
import { TaskTemplateService } from '../services/tasktemplate.service';
import { Router } from '@angular/router';

@Component({
    template: ` <div *ngIf="taskTemplate"><button class="btn btn-sm btn-info" (click)="copyTaskTemplate()">Copy</button></div> `,
})
export class SystemTaskCopyCellComponent implements IEntityListDynamicCellComponent {
    entityListComponentMembers: IEntityListComponentMembers;
    set entity(value: IEntity) {
        this.taskTemplate = value as ITaskTemplate;
    }

    taskTemplate: ITaskTemplate;

    constructor(private taskTemplateService: TaskTemplateService, private router: Router) {}

    copyTaskTemplate(): void {
        this.taskTemplateService.copiedTask = this.taskTemplate;
        void this.router.navigate(['system-tasks', 'add']);
    }
}
