import { DynamicField, DynamicFieldTypes, SelectInputTypes } from '@mt-ng2/dynamic-form';

import { TaskDynamicControls, ITaskDynamicControlsParameters } from '../form-controls/task.form-controls';
import { ITask } from '../interfaces/task';

export class TaskDynamicControlsPartial extends TaskDynamicControls {
    constructor(taskPartial?: ITask, additionalParameters?: ITaskDynamicControlsParameters) {
        super(taskPartial, additionalParameters);

        (<DynamicField>this.Form.AssignedToTypeId).type.fieldType = DynamicFieldTypes.Select;
        (<DynamicField>this.Form.AssignedToTypeId).options = [
            { Id: 1, Name: 'User Roles' },
            { Id: 2, Name: 'Users' },
        ];
        (<DynamicField>this.Form.AssignedToTypeId).type.inputType = SelectInputTypes.RadioButtonList;
        (<DynamicField>this.Form.AssignedToTypeId).labelHtml = `<label>Assigned To</label>`;

        (<DynamicField>this.Form.TaskDescriptions).setRequired(true);
        (<DynamicField>this.Form.ImedClaimId).setRequired(true);
        (<DynamicField>this.Form.DueDate).setRequired(true);
        (<DynamicField>this.Form.AssignedToTypeId).setRequired(true);

        // (<DynamicField>this.Form.ImedClaimId).type.inputType = SelectInputTypes.Dropdown;
        // (<DynamicField>this.Form.ImedClaimId).options = (<DynamicField>this.Form.ImedClaimId).options.map((claim: IImedClaim) => {
        //     return Object.assign(claim, {Name: `${claim.FirstName} ${claim.LastName}` });
        // });
    }
}
