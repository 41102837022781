import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { Observable } from 'rxjs';
import { IPhysicianStatusEmailTemplate } from '@model/interfaces/physician-status-email-template';
import { IGroupedEmailTemplatesByPhysicianStatusDTO } from '@model/interfaces/custom/IGroupedEmailTemplatesByPhysicianStatusDTO';

export const emptyEmailTemplateAssociation: IPhysicianStatusEmailTemplate = {
    AddressBookTypeId: null,
    EmailTemplateId: null,
    Id: 0,
    PhysicianStatusId: null,
};

@Injectable({
    providedIn: 'root',
})
export class PhysicianStatusEmailTemplateService extends BaseService<IPhysicianStatusEmailTemplate> {
    constructor(public http: HttpClient) {
        super('/physician-status-email-templates', http);
    }

    getEmptyEmailTemplateAssociation(): IPhysicianStatusEmailTemplate {
        return emptyEmailTemplateAssociation;
    }

    getEmailTemplatesGroupedByFollowup(addressBookTypeId: number): Observable<IGroupedEmailTemplatesByPhysicianStatusDTO[]> {
        return this.http.get<IGroupedEmailTemplatesByPhysicianStatusDTO[]>(
            `/physician-status-email-templates/${addressBookTypeId}/grouped-email-templates`,
        );
    }
}
