import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { IService } from '@model/interfaces/service';
import { forkJoin } from 'rxjs';
import { TaskTemplateService } from '../../services/tasktemplate.service';
import { IFilteredServiceFollowupStatusTaskDTO } from '@model/interfaces/custom/filtered-service-followup-status-task-dto';
import { MasterFollowupTypeServiceStatusService } from '../../services/followup-type-service-status.service';
import { IMasterFollowupTypeServiceStatus } from '@model/interfaces/master-followup-type-service-status';
import { IStatusFollowupAssociation } from '../base-row/base-row.component';
import { MasterFollowupTypeTaskTemplateService } from '../../services/followup-type-task.service';
import { IGroupedTaskTemplatesByFollowupDTO } from '@model/interfaces/custom/grouped-task-templates-by-followup-dto';
import { IFollowupTaskAssociation } from './followup-task-row/followup-task-row.component';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IMasterFollowupTypeTaskTemplate } from '@model/interfaces/master-followup-type-task-template';

@Component({
    selector: 'system-task-followup-associations',
    styles: [
        `
            .clear-padding {
                padding-left: 0;
            }
        `,
    ],
    templateUrl: './system-task-followup-associations.component.html',
})
export class SystemTaskFollowupAssociationsComponent implements OnInit, OnChanges {
    @Input() selectedService: IService;
    filteredServiceDataForFollowups: IFilteredServiceFollowupStatusTaskDTO;
    filteredServiceDataForTasks: IFilteredServiceFollowupStatusTaskDTO;
    tasksGroupedByFollowup: IGroupedTaskTemplatesByFollowupDTO[] = [];
    filteredFollowupStatusAssociations: IMasterFollowupTypeServiceStatus[] = [];
    filteredFollowupTaskAssociations: IMasterFollowupTypeTaskTemplate[] = [];

    constructor(
        private taskTemplateService: TaskTemplateService,
        private followupStatusService: MasterFollowupTypeServiceStatusService,
        private followupTaskService: MasterFollowupTypeTaskTemplateService,
        private notificationService: NotificationsService,
    ) { }

    ngOnInit(): void {
        this.getFilteredServiceData();
    }

    ngOnChanges(): void {
        this.getFilteredServiceData();
    }

    getFilteredServiceData(): void {
        this.filteredServiceDataForFollowups = null;
        this.filteredServiceDataForTasks = null;
        forkJoin(
            this.followupStatusService.getAll(),
            this.followupTaskService.getAll(),
            this.taskTemplateService.getFilteredServiceStatusFollowupTasks(this.selectedService.Id),
            this.followupTaskService.getGroupedTaskTemplatesByService(this.selectedService.Id),
        ).subscribe(([followupStatusAssociations, followupTaskAssociations, filteredServiceData, groupedTasks]) => {
            this.tasksGroupedByFollowup = groupedTasks;
            this.filteredFollowupStatusAssociations = followupStatusAssociations.filter((x) => x.ServiceId === this.selectedService.Id);
            this.filteredFollowupTaskAssociations = followupTaskAssociations.filter((x) => x.ServiceId === this.selectedService.Id);
            this.filteredServiceDataForTasks = { ...filteredServiceData };
            this.filteredServiceDataForFollowups = { ...filteredServiceData };
            this.filteredServiceDataForFollowups.FollowupTypes = this.filteredServiceDataForFollowups.FollowupTypes.filter(
                (x) => !this.filteredFollowupStatusAssociations.some((y) => y.MasterFollowupTypeId === x.Id),
            );
            this.filteredServiceDataForTasks.FollowupTypes = this.filteredServiceDataForTasks.FollowupTypes.filter(
                (x) => !this.filteredFollowupTaskAssociations.some((y) => y.MasterFollowupTypeId === x.Id),
            );
        });
    }

    saveFollowupStatusAssociation(event: IStatusFollowupAssociation, index: number): void {
        this.filteredServiceDataForFollowups.FollowupTypes = this.filteredServiceDataForFollowups.FollowupTypes.filter(
            (x) => x.Id !== event.Followup.Id,
        );
        const followupStatusAssociations: IMasterFollowupTypeServiceStatus = {
            Id: 0,
            MasterFollowupTypeId: event.Followup.Id,
            MasterServiceStatusId: event.Status.Id,
            ServiceId: this.selectedService.Id,
        };

        this.followupStatusService.create(followupStatusAssociations).subscribe((id) => {
            this.filteredFollowupStatusAssociations[index].Id = id;
            this.notificationService.success('Followup status association saved successfully.');
        });
    }

    saveTaskAssociation(event: IFollowupTaskAssociation): void {
        const followupTaskAssociation: IMasterFollowupTypeTaskTemplate = {
            Id: 0,
            MasterFollowupTypeId: event.Followup.Id,
            ServiceId: this.selectedService.Id,
            TaskTemplateId: event.TaskTemplate.Id,
        };

        this.followupTaskService.create(followupTaskAssociation).subscribe((id) => {
            followupTaskAssociation.Id = id;
            this.filteredFollowupTaskAssociations.push(followupTaskAssociation);
            this.filteredServiceDataForTasks.FollowupTypes = this.filteredServiceDataForTasks.FollowupTypes.filter(
                (x) => x.Id !== followupTaskAssociation.MasterFollowupTypeId,
            );
            this.notificationService.success('Followup task association saved successfully.');
        });
    }

    addFollowupStatusAssociation(): void {
        this.filteredFollowupStatusAssociations.push({ ...this.followupStatusService.getEmptyServiceFollowupAssociation() });
    }

    addFollowupTaskAssociation(): void {
        this.tasksGroupedByFollowup.push({
            FollowupType: null,
            TaskTemplate: [],
        } as IGroupedTaskTemplatesByFollowupDTO);
    }

    deleteFollowupStatusAssociaton(index: number): void {
        this.followupStatusService.delete(this.filteredFollowupStatusAssociations[index].Id).subscribe(() => {
            this.filteredFollowupStatusAssociations.splice(index, 1);
            this.notificationService.info('Followup status association deleted successfully.');
        });
    }

    updateExemption(followupTaskAssociation: IFollowupTaskAssociation): void {
        const masterFollowupTypeTaskTemplate = this.filteredFollowupTaskAssociations.find(
            (x) => x.MasterFollowupTypeId === followupTaskAssociation.Followup.Id && x.TaskTemplateId === followupTaskAssociation.TaskTemplate.Id,
        );
        this.followupTaskService.update(masterFollowupTypeTaskTemplate).subscribe(() => {
            this.notificationService.success('Exemption Updated.');
        });
    }

    deleteFollowupTaskAssociation(followupTaskAssociation: IFollowupTaskAssociation, index: number): void {
        const id = this.filteredFollowupTaskAssociations.find(
            (x) => x.MasterFollowupTypeId === followupTaskAssociation.Followup.Id && x.TaskTemplateId === followupTaskAssociation.TaskTemplate.Id,
        ).Id;
        this.followupTaskService.delete(id).subscribe(() => {
            this.filteredFollowupTaskAssociations = this.filteredFollowupTaskAssociations.filter((x) => x.Id !== id);
            this.tasksGroupedByFollowup[index].TaskTemplate = this.tasksGroupedByFollowup[index].TaskTemplate.filter(
                (x) => x.Id !== followupTaskAssociation.TaskTemplate.Id,
            );
            if (!this.tasksGroupedByFollowup[index].TaskTemplate.length) {
                this.tasksGroupedByFollowup.splice(index, 1);
                this.filteredServiceDataForTasks.FollowupTypes.push(followupTaskAssociation.Followup);
            }
            this.notificationService.info('Followup task association deleted successfully.');
        });
    }
}
