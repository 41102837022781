import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClaimsService } from '@mt-ng2/auth-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { SharedEntitiesComponent } from '@mt-ng2/shared-entities-module';

@Component({
    selector: 'imed-claim-shared-entities',
    styles: [
        `
            .border {
                border: solid 1px #858a9f;
                margin-top: 10px;
                margin-bottom: 10px;
                border-radius: 0.5%;
            }
            .header-button {
                margin-top: -12px;
            }
            .delete-icon-span {
                height: fit-content;
                width: 5%;
                overflow: auto;
                margin-left: auto;
                margin-right: 5%;
                margin-top: auto;
                margin-bottom: auto;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                text-align: right;
            }
            .shared-entity-div {
                display: inline-block;
                width: fit-content;
            }
        `,
    ],
    templateUrl: './imed-claim-shared-entities.component.html',
})
export class ImedClaimSharedEntitiesComponent extends SharedEntitiesComponent {
    @Output('onCloseButtonClicked') onCloseButtonClicked: EventEmitter<any> = new EventEmitter<any>();

    constructor(route: ActivatedRoute, router: Router, injector: Injector, notificationsService: NotificationsService, claimsService: ClaimsService) {
        super();
    }

    remove(): void {
        setTimeout(() => {
            this.delete();
        }, 1);
    }

    exitEditing(): void {
        this.onCloseButtonClicked.emit(this.entitiesArray);
    }
}
