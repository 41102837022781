import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IImedClaimServiceExamRequestorType } from '@model/interfaces/imed-claim-service-exam-requestor-type';

@Injectable({
    providedIn: 'root',
})
export class ImedClaimServiceExamRequestorTypeService extends BaseService<IImedClaimServiceExamRequestorType> {
    constructor(public http: HttpClient) {
        super('/imed-claim-exam-requestor-types', http);
    }
}
