import { Subscription, Observable } from 'rxjs';
import { DynamicField, IDynamicField, DynamicFieldType, DynamicFieldTypes, IDynamicFieldType } from '@mt-ng2/dynamic-form';
import { IImeService } from '@model/interfaces/ime-service.d';
import { ImedClaimServiceService } from '../../../imedclaimservice.service';
import { ImedClaimServiceDynamicConfig } from '../../../imed-claim-service.dynamic-config';
import { IImedClaimServiceContactInfo } from '../../../../imed-claim-basic-info/imed-claim-contacts-info';
import { Component, Input, OnInit, OnDestroy, EventEmitter, Output, ViewChild } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IImedClaimService } from '@model/interfaces/imed-claim-service';
import { IServicePayload } from '@model/interfaces/custom/update-service-payload';
import { AutoImeServiceService } from '../auto-ime-service.service';
import { ConflictOfInterestFormSelection } from '../../../../../common/constants/Enums';
import { forkJoin } from 'rxjs';
import { AuthService, ClaimValues, ClaimsService } from '@mt-ng2/auth-module';
import { finalize } from 'rxjs/operators';
import { ClaimTypes } from '@model/ClaimTypes';
import { IUser } from '@model/interfaces/user';
import { UserService } from '../../../../../users/user.service';
import { VirtualTypeAheadGetItemsFunction } from '@mt-ng2/type-ahead-control';
import { ISpecialty } from '@model/interfaces/specialty';
import { SpecialityService } from '../../../../../imed-managed-list/speciality.service';
import { MetaItem } from '@mt-ng2/base-service';
import { INote } from '@mt-ng2/note-control';
import { ImedClaimServiceNotesService } from '../../../imed-claim-service-notes.service';
import { ImedClaimAdjusterInquiriesComponent } from '../../../common-components/adjuster-inquiry-notes/adjuster-inquiry-notes.component';
import { ImedClaimTypes } from '@model/ImedClaimTypes';

@Component({
    selector: 'app-auto-ime-service-basic-info',
    templateUrl: './auto-ime-service-basic-info.component.html',
})
export class AutoImeServiceBasicInfoComponent implements OnInit, OnDestroy {
    @Input() iImedClaimServiceModel: IImedClaimService;
    @Input() canEdit: boolean;
    @Input() iImedClaimServiceContactInfo: IImedClaimServiceContactInfo;
    @Input() iImeService: IImeService;
    @Input() disablePhysicianFacilityControl: boolean;
    @Input() bodyPartsToBeExamined: string;
    @Input() reportDueBy: string;
    @Output('physicianFaxSent') physicianFaxSent: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('adjusterInquiries') adjusterInquiries: ImedClaimAdjusterInquiriesComponent;

    notesService = ImedClaimServiceNotesService;

    isEditing: boolean;
    isHovered: boolean;
    imedClaimServiceForm: any;
    formFactory: ImedClaimServiceDynamicConfig<IImedClaimService>;
    doubleClickIsDisabled = false;
    iImeServiceClone: IImeService;
    subscriptions = new Subscription();

    ServiceId: number;
    physicianSelected: boolean;
    selectedValueConflictOfInterest: number;
    previousPatientControl: AbstractControl;
    conflictOfInterestControl: AbstractControl;
    canSeeAuditLog = false;
    initialImedClaimServiceModel: IImedClaimService;
    initialImedClaimServiceContactInfo: IImedClaimServiceContactInfo;

    selectedReportAssignedToUser: IUser;
    reportAssignedToUsers = [];
    formRendered = false;
    invalidReportAssignedTo = false;
    invalidPreviousPatient = false;

    adjusterInquiriesArray: INote[];

    getItems: VirtualTypeAheadGetItemsFunction = this.getSpecialties.bind(this);
    selectedSpecialty: number;

    constructor(
        private imedClaimServiceService: ImedClaimServiceService,
        private notificationsService: NotificationsService,
        private autoImeServiceService: AutoImeServiceService,
        private authService: AuthService,
        private claimsService: ClaimsService,
        private userService: UserService,
        private specialtyService: SpecialityService,
    ) {}

    ngOnInit(): void {
        this.canSeeAuditLog = this.claimsService.hasClaim(ClaimTypes.Audit, [ClaimValues.FullAccess, ClaimValues.ReadOnly]);
        this.initialImedClaimServiceModel = { ...this.iImedClaimServiceModel };
        this.isEditing = false;
        this.selectedValueConflictOfInterest = this.iImeService.ConflictOfInterestApproved
            ? ConflictOfInterestFormSelection.selected
            : ConflictOfInterestFormSelection.notSelected;
        this.physicianSelected = this.iImedClaimServiceModel.PhysicianId > 0 ? true : false;

        this.subscriptions.add(
            this.autoImeServiceService.changeEmitted$.subscribe((iImeService) => {
                this.iImeService = iImeService;
                this.clone();
            }),
        );

        this.subscriptions.add(
            this.imedClaimServiceService.changeEmitted$.subscribe((iImedClaimServiceModel) => {
                this.iImedClaimServiceModel = iImedClaimServiceModel;
                this.initialImedClaimServiceModel = { ...this.iImedClaimServiceModel };
            }),
        );

        this.clone();
        this.formRendered = true;
        this.selectedSpecialty = this.iImedClaimServiceModel.SpecialtyRequestedId > 0 ? this.iImedClaimServiceModel.SpecialtyRequestedId : null;
        this.adjusterInquiriesArray = this.iImedClaimServiceModel.Notes;
    }

    reloadAdjusterInquiries(): void {
        this.adjusterInquiriesArray = this.adjusterInquiries.NoteArray;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    ngOnChanges(changes: any): void {
        if (changes.iImedClaimServiceContactInfo) {
            this.initialImedClaimServiceContactInfo = { ...this.iImedClaimServiceContactInfo };
        }
    }

    clone(): void {
        this.iImeServiceClone = { ...this.iImeService };
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        this.isEditing = false;
        this.iImedClaimServiceModel = { ...this.initialImedClaimServiceModel };
        this.iImedClaimServiceContactInfo = { ...this.initialImedClaimServiceContactInfo };
        this.selectedReportAssignedToUser = this.reportAssignedToUsers.find((u) => u.Id === this.iImedClaimServiceModel.ServiceAssignedToId);
        this.adjusterInquiriesArray = this.adjusterInquiries._savedNotes;
    }

    getSpecialties(): Observable<ISpecialty[]> {
        return this.specialtyService.getItems();
    }

    selectionChanged({ selection }): void {
        if (selection) {
            this.selectedSpecialty = selection.Id;
        }
    }

    isPhysicianSelected(physicianSelected): void {
        if (physicianSelected && this.iImedClaimServiceModel.PhysicianId == null) {
            this.physicianSelected = true;
        } else if (physicianSelected && this.iImedClaimServiceModel.PhysicianId > 0) {
            this.physicianSelected = true;
            if (this.conflictOfInterestControl) {
                this.conflictOfInterestControl.setValue(ConflictOfInterestFormSelection.notSelected);
            }
        } else if (!physicianSelected) {
            this.physicianSelected = false;
        }
    }

    previousPatientField(): DynamicField {
        return new DynamicField({
            formGroup: null,
            label: 'Has this claimant ever been seen in this physician`s or expert`s office, for any reason - treating, Worker`s Compensation, No Fault, etc.?',
            name: 'PreviousPatient',
            options: [new MetaItem(1, 'Yes'), new MetaItem(0, 'No')],
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
            } as IDynamicFieldType),
            validation: [(c: AbstractControl) => Validators.required(c)],
            validators: { required: true },
            value:
                this.iImeService.PatientPreviouslyTreatedByPhysician === true
                    ? 1
                    : this.iImeService.PatientPreviouslyTreatedByPhysician === false
                    ? 0
                    : null,
        } as IDynamicField);
    }

    setPreviousPatientField(value: boolean): void {
        this.iImeServiceClone.PatientPreviouslyTreatedByPhysician = value;
    }

    conflictOfInterestField(): DynamicField {
        return new DynamicField({
            formGroup: null,
            label: 'Has client approved possible conflict of interest?',
            name: 'PreviousPatient',
            options: [new MetaItem(1, 'Yes'), new MetaItem(0, 'No')],
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
            } as IDynamicFieldType),
            value: this.selectedValueConflictOfInterest,
        } as IDynamicField);
    }

    setConflictOfInterestField(value: boolean): void {
        this.selectedValueConflictOfInterest = value ? ConflictOfInterestFormSelection.selected : ConflictOfInterestFormSelection.notSelected;
        this.iImeServiceClone.ConflictOfInterestApproved = value;
    }

    saveAllAdjusterInquiries(): void {
        this.autoImeServiceService.updateAdjusterInquiries(this.iImedClaimServiceModel.Id, this.adjusterInquiries.NoteArray).subscribe();
        this.adjusterInquiries.getNotes();
        this.adjusterInquiriesArray = this.adjusterInquiries.NoteArray;
    }

    formSubmitted(): void {
        this.iImedClaimServiceModel = this.imedClaimServiceService.assignContactsToImedClaimService(
            this.iImedClaimServiceModel,
            this.iImedClaimServiceContactInfo,
        );
        this.iImedClaimServiceModel.ServiceAssignedToId =
            this.selectedReportAssignedToUser && this.selectedReportAssignedToUser.Id ? this.selectedReportAssignedToUser.Id : null;
        this.iImedClaimServiceModel.SpecialtyRequestedId = this.selectedSpecialty ? this.selectedSpecialty : null;

        this.invalidPreviousPatient =
            this.iImeServiceClone.PatientPreviouslyTreatedByPhysician === null && this.iImedClaimServiceModel.PhysicianId > 0;
        const invalidServiceAddress = this.iImedClaimServiceModel.PhysicianId > 0 && !this.iImedClaimServiceModel.ServiceAddressId;

        if (this.invalidReportAssignedTo || this.invalidPreviousPatient || invalidServiceAddress) {
            return;
        }

        const updatePayload = <IServicePayload<IImeService>>{};
        this.iImeServiceClone.ModifiedById = this.authService.currentUser.getValue().Id;
        updatePayload.ParentService = { ...this.iImedClaimServiceModel };
        updatePayload.ChildService = { ...this.iImeServiceClone };
        forkJoin(
            this.autoImeServiceService.updateService(this.iImedClaimServiceModel.Id, updatePayload),
            this.autoImeServiceService.updateServiceConflictOfInterest(this.iImedClaimServiceModel.Id, updatePayload),
        )            .subscribe(() => {
                this.saveAllAdjusterInquiries();
                this.imedClaimServiceService.getById(this.iImedClaimServiceModel.Id).subscribe((response) => {
                    this.isEditing = false;
                    this.success();
                    this.iImedClaimServiceModel = response;
                    this.imedClaimServiceService.emitChange(this.iImedClaimServiceModel);
                    this.autoImeServiceService.emitChange(this.iImeServiceClone);
                });
            });
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }

    adjusterInquiryDeleted(): void {
        this.notificationsService.success('Adjuster Inquiry Successfully Deleted');
    }

    adjusterInquiryNotDeleted(): void {
        this.notificationsService.error('An error occurred while deleting Adjuster Inquiry');
    }
}
