import { IEntityListConfig, EntityListConfig, EntityListColumn, SortDirection, SelectTypes } from '@mt-ng2/entity-list-module';
import { ITaskDTO } from '@model/interfaces/custom/task-dto';
import { TaskActionsDynamicCellComponent } from './task-actions.component';
import { DatePipe } from '@angular/common';

export class TasksEntityListConfig extends EntityListConfig {
    constructor(private userRoleId: number) {
        super(<IEntityListConfig>{
            columns: [
                new EntityListColumn({
                    accessorFunction: (task: ITaskDTO) => {
                        if (task?.ImedClaimServiceId) {
                            return (
                                '<a href="#/cases/' + task.ImedClaimId + '/services/' + task.ImedClaimServiceId + '">' + task.Claimant || '' + '</a>'
                            );
                        }
                        return '<a href="#/cases/' + task.ImedClaimId + '">' + task.Claimant || '' + '</a>';
                    },
                    bindToInnerHtml: true,
                    fireOnColumnSelected: true,
                    name: 'Claimant Name',
                    sort: {
                        sortProperty: 'ClaimantName',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (task: ITaskDTO) => {
                        return task?.ImedClaimServiceId?.toString() || 'N/A';
                    },
                    bindToInnerHtml: true,
                    name: 'Service Order Number',
                    sort: {
                        sortProperty: 'ImedClaimServiceId',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (task: ITaskDTO) => task.TaskDescriptions || '',
                    bindToInnerHtml: true,
                    excludeFromExport: true,
                    name: 'Task Descriptions',
                    sort: {
                        sortProperty: 'TaskDescriptions',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (task: ITaskDTO) => task?.TaskDescriptionsNoHtml || '',
                    bindToInnerHtml: true,
                    excludeFromView: true,
                    name: 'Task Descriptions',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (task: ITaskDTO) => {
                        return task?.TaskUsersList || '';
                    },
                    name: 'User',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (task: ITaskDTO) => {
                        return task?.TaskUserRolesList || '';
                    },
                    name: 'User Roles',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (entity: ITaskDTO) => new DatePipe('en-US').transform(entity?.AppointmentDateTime, 'MM/dd/yyyy') || ' ',
                    name: 'Appointment Date',
                    sort: {
                        sortProperty: 'AppointmentDate',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (entity: ITaskDTO) => new DatePipe('en-US').transform(entity?.ReportDueByDate, 'MM/dd/yyyy', 'UTC') || ' ',
                    name: 'Report Due By',
                    sort: {
                        sortProperty: 'ReportDueBy',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (entity: ITaskDTO) => new DatePipe('en-US').transform(entity?.CompilationDueByDate, 'MM/dd/yyyy', 'UTC') || ' ',
                    name: 'Compilation Due By',
                    sort: {
                        sortProperty: 'CompilationDueBy',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (entity: ITaskDTO) => new DatePipe('en-US').transform(entity?.DueDate, 'MM/dd/yyyy', 'UTC') || ' ',
                    name: 'Due Date',
                    sort: {
                        direction: SortDirection.Asc,
                        isDefaultForSort: true,
                        sortProperty: 'DueDate',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (entity: ITaskDTO) => new DatePipe('en-US').transform(entity?.DateCompleted, 'MM/dd/yyyy', 'UTC') || ' ',
                    name: 'Date Completed',
                    sort: {
                        sortProperty: 'DateCompleted',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (task: ITaskDTO) => task?.CompletedByUser || '',
                    name: 'Completed By',
                    sort: {
                        sortProperty: 'CompletedBy',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (task: ITaskDTO) => task?.ServiceAssignedToUsers?.filter((user) => user.trim() !== '').join(', ') || '',
                    name: 'Assigned To',
                    sort: {
                        sortProperty: 'ServiceAssignedTo',
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (task: ITaskDTO) => task?.Notes || '',
                    name: 'Notes',
                    sort: {
                        sortProperty: 'Notes',
                    },
                }),
                new EntityListColumn({
                    columnClass: 'task-actions',
                    component: TaskActionsDynamicCellComponent,
                    excludeFromExport: true,
                    fireOnColumnSelected: false,
                    name: 'Actions',
                    sort: {
                        disableSort: true,
                    },
                }),
            ],
            rowClass: (task: ITaskDTO) => task?.TaskColor + (task?.Archived ? ' archived' : ''),
            select: { type: SelectTypes.Multi },
        });
    }
}
