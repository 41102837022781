import { ImedClaimSubpoenaDetailDynamicControls, IImedClaimSubpoenaDetailDynamicControlsParameters } from '../form-controls/imed-claim-subpoena-detail.form-controls';
import { IImedClaimSubpoenaDetail } from '../interfaces/imed-claim-subpoena-detail';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, DynamicLabel, IDynamicFieldType, ITimepickerOptions, InputTypes, LabelPosition, LabelPositions, SelectInputTypes, noZeroRequiredValidator } from '@mt-ng2/dynamic-form';
import * as moment from 'moment';
import { IImedClaimSubpoenaDetailDynamicControlsParametersPartial } from './imed-claim-subpoena-detail-dynamic-controls.partial';

export class ImedClaimSubpoenaDetailDynamicControlsPartial extends ImedClaimSubpoenaDetailDynamicControls {

    constructor(imedclaimsubpoenadetailPartial?: IImedClaimSubpoenaDetail, additionalParameters?: IImedClaimSubpoenaDetailDynamicControlsParametersPartial) {
        super(imedclaimsubpoenadetailPartial, additionalParameters);
        // examples shown below of how to alter Form fields that already exist from the extended DynamicControls class
        (<DynamicField>this.Form.HearingAddressId) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Hearing Address',
            name: 'HearingAddressId',
            options: additionalParameters.hearingAddresses,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.Dropdown,
            }as IDynamicFieldType),
            validators: { 'required': true },
            value: imedclaimsubpoenadetailPartial && imedclaimsubpoenadetailPartial.HearingAddressId || null,
        });

        (<DynamicField>this.View.HearingAddressId) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Hearing Address',
            name: 'HearingAddressId',
            options: additionalParameters.hearingAddresses,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.Dropdown,
            }as IDynamicFieldType),
            validators: { required : true },
            value: `${imedclaimsubpoenadetailPartial?.Address?.Address1} ${imedclaimsubpoenadetailPartial?.Address?.Address2}
                    ${imedclaimsubpoenadetailPartial?.Address?.City } ,${imedclaimsubpoenadetailPartial?.Address?.State?.StateCode}
                    ${imedclaimsubpoenadetailPartial?.Address?.Zip}`,
        });

        (<DynamicField> this.Form.Address1) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Address1',
            labelPosition: new LabelPosition({ position: LabelPositions.Left }),
            name: 'Address1',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
            }),
            validators: { required : true },
            value: imedclaimsubpoenadetailPartial?.Address?.Address1 ,
        });

        this.Form.Address2 = new DynamicField({
            formGroup: this.formGroup,
            label: 'Address2',
            labelPosition: new LabelPosition({ position: LabelPositions.Left }),
            name: 'Address2',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Textbox,
            }),
            value: imedclaimsubpoenadetailPartial?.Address?.Address2 ,
        });

        this.Form.City = new DynamicField({
            formGroup: this.formGroup,
            label: 'City',
            labelPosition: new LabelPosition({ position: LabelPositions.Left }),
            name: 'City',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Textbox,
            }),
            validators: { required : true },
            value: imedclaimsubpoenadetailPartial?.Address?.City ,
        });

        this.Form.State = new DynamicField({
            formGroup: this.formGroup,
            label: 'State',
            labelPosition: new LabelPosition({ position: LabelPositions.Left }),
            name: 'State',
            options: additionalParameters.states,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.Dropdown,
            }),
            validators: { required : true },
            value: imedclaimsubpoenadetailPartial?.Address?.StateId ,
        });

        this.Form.Zip = new DynamicField({
            formGroup: this.formGroup,
            label: 'Zip',
            labelPosition: new LabelPosition({ position: LabelPositions.Left }),
            name: 'Zip',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Textbox,
            }),
            validators: { required : true },
            value: imedclaimsubpoenadetailPartial?.Address?.Zip ,
        });

        ((<DynamicField>this.Form.HearingDateTime).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Input,
            inputType: InputTypes.DateTimepicker,
            timepickerOptions: { meridian: true } as ITimepickerOptions,
        })),

        (<DynamicField>this.View.HearingDateTime).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Input,
            inputType: InputTypes.DateTimepicker,
            timepickerOptions: { meridian: true } as ITimepickerOptions,
        }),

          (<DynamicField>this.Form.ResidingCourtAddressBookId) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Residing Court',
            name: 'ResidingCourtAddressBookId',
            options: additionalParameters.courts,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.TypeAhead,
                typeAheadOptions: {'isItemDisabled' : (court) => !(court.DaysToProduce && court.IsMailingAddressPresent
                                                                     && court.IsPhysicalAddressPresent)},
            }as IDynamicFieldType),
            validators: { 'required': true },
            value: imedclaimsubpoenadetailPartial && imedclaimsubpoenadetailPartial.ResidingCourtAddressBookId || null,
        });

        (<DynamicField>this.View.ResidingCourtAddressBookId) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Residing Court',
            name: 'ResidingCourtAddressBookId',
            options: additionalParameters.courts,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.TypeAhead,
            }as IDynamicFieldType),
            validators: { 'required': true },
            value: additionalParameters?.courtPrimaryAddress ?
                   `${additionalParameters?.selectedCourt[0].OriginalCourtName} - ${additionalParameters?.courtPrimaryAddress?.Address1}
                   ${additionalParameters?.courtPrimaryAddress?.Address2 }  ${additionalParameters?.courtPrimaryAddress?.City} ,
                   ${additionalParameters?.selectedCourt[0].PrimaryAddress?.StateCode}  ${additionalParameters?.courtPrimaryAddress?.Zip}`
                   : additionalParameters?.selectedCourt.length > 0 ? additionalParameters?.selectedCourt[0].OriginalCourtName : null,
        });

        (<DynamicField>this.Form.HearingAddressId).customOptions = {
            advancedEditor: true,
        };

        (<DynamicLabel>this.View.DefenseAttorneyIdBarNumber).label = 'Defense attorney id / bar number';
        (<DynamicField>this.Form.DefenseAttorneyIdBarNumber).labelHtml = `<label>Defense attorney id / bar number</label>`;

        (<DynamicLabel>this.View.HearingAddressId).label = 'Hearing address ';
        (<DynamicField>this.Form.HearingAddressId).labelHtml = `<label>Hearing address</label>`;
        (<DynamicField>this.View.HearingDateTime).label = 'Hearing Date';
        (<DynamicLabel>this.View.HearingDateTime).value = imedclaimsubpoenadetailPartial.HearingDateTime
          ? moment.tz(imedclaimsubpoenadetailPartial.HearingDateTime, 'America/New_York').format('MM/DD/yyyy')
          : null;

        (this.View.HearingTime = new DynamicLabel({
          label: 'Hearing Time',
          value: imedclaimsubpoenadetailPartial.HearingDateTime
              ? moment.tz(imedclaimsubpoenadetailPartial.HearingDateTime, 'America/New_York').format('hh:mm A')
              : null,
          type: new DynamicFieldType({
              fieldType: null,
          }),
        })),

        ((<DynamicLabel>this.View.PlaintiffLodgedObjectionDate).label = 'Plaintiff lodged objection');
        (<DynamicField>this.Form.PlaintiffLodgedObjectionDate).labelHtml = `<label>Plaintiff lodged objection</label>`;

        (<DynamicLabel>this.View.IndexDocketCaseNumber).label = 'Index / docket / case number';
        (<DynamicField>this.Form.IndexDocketCaseNumber).labelHtml = `<label>Index / docket / case number</label>`;

        (<DynamicLabel>this.View.JusticeJudgeName).label = 'Justice / judge name';
        (<DynamicField>this.Form.JusticeJudgeName).labelHtml = `<label>Justice / judge name</label>`;

        (<DynamicLabel>this.View.ResidingCourtAddressBookId).label = 'Residing Court';
        (<DynamicField>this.Form.ResidingCourtAddressBookId).labelHtml = `<label>Residing Court</label>`;

        (<DynamicLabel>this.View.PlaintiffAttorneyRequiresRecords).label = 'Plaintiff attorney requires copy of records retrieved?';
        (<DynamicField>this.Form.PlaintiffAttorneyRequiresRecords).labelHtml = `<label>Plaintiff attorney requires copy of records retrieved</label>`;

        (<DynamicField>this.Form.ResidingCourtAddressBookId).setRequired(true);
        (<DynamicField>this.Form.ClientApprovalDate).setRequired(true);
        (<DynamicField>this.Form.HearingAddressId).setRequired(true);
        (<DynamicField>this.Form.Address1).setRequired(true);
        (<DynamicField>this.Form.City).setRequired(true);
        (<DynamicField>this.Form.State).setRequired(true);
        (<DynamicField>this.Form.Zip).setRequired(true);
    }
}
