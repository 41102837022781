import { IDocumentQueueAssignmentDTO } from '@model/interfaces/custom/document-queue-assignment';
import { BaseService } from '@mt-ng2/base-service';
import { HttpClient } from '@angular/common/http';
import { SearchParams } from '@mt-ng2/common-classes';
import { Injectable } from '@angular/core';
import { IDocumentQueueAttachmentPartial } from '@model/partials/document-queue-attachment-partial';
import { DocumentQueueAssignmentTypes } from '../common/constants/Enums';
@Injectable()
export class DocumentQueueService extends BaseService<IDocumentQueueAttachmentPartial> {
    constructor(public http: HttpClient) {
        super('/document-queue-attachment', http);
    }

    getAllDocuments(data: SearchParams): any {
        const params = this.getHttpParams(data);
        return this.http.get<IDocumentQueueAttachmentPartial[]>(`/document-queue-attachment/getAllDocs`, { observe: 'response', params: params });
    }

    toggleArchive(Id: number): any {
        return this.http.delete(`/document-queue-attachment/archive/${Id}`);
    }
    copy(Id: number): any {
        return this.http.get(`/document-queue-attachment/copy/${Id}`);
    }

    assignEntityType(assignmentTypeId: number, objAssignment: IDocumentQueueAssignmentDTO): any {
        if (assignmentTypeId === +DocumentQueueAssignmentTypes.Physicians) {
            return this.http.put<IDocumentQueueAssignmentDTO>('/email-document/physician/assign', objAssignment);
        } else if (assignmentTypeId === +DocumentQueueAssignmentTypes.Services) {
            return this.http.put<IDocumentQueueAssignmentDTO>('/email-document/imed-claim-service/assign', objAssignment);
        } else if (assignmentTypeId === +DocumentQueueAssignmentTypes.Claims) {
            return this.http.put<IDocumentQueueAssignmentDTO>('/email-document/imed-claim/assign', objAssignment);
        } else {
            return this.http.put<IDocumentQueueAssignmentDTO>('/email-document/user/assign', objAssignment);
        }
    }
}
