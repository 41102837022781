import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IEmailTemplate } from '@model/interfaces/email-template';
import { EmailTemplateService } from '../../../email-templates/emailtemplate.service';
import { ExtraSearchParams, IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { tap } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { IAddressBookType } from '@model/interfaces/address-book-type';
import { IPhysicianStatus } from '@model/interfaces/physician-status';
import { IPhysicianStatusEmailTemplate } from '@model/interfaces/physician-status-email-template';
import { IGroupedEmailTemplatesByPhysicianStatusDTO } from '@model/interfaces/custom/IGroupedEmailTemplatesByPhysicianStatusDTO';
import { PhysicianStatusEmailTemplateService } from '../services/physician-status-email-template-service';
import { IEmailTemplatePhysicianStatusAssociation } from './email-template-status-row/email-template-status-row.component';
import { PhysicianStatusService } from '../../../address-books/individuals/physicians/physicianstatus.service';

@Component({
    selector: 'followup-email-template-associations',
    styles: [
        `
            .clear-padding {
                padding-left: 0;
            }
        `,
    ],
    templateUrl: './email-status-template-associations.component.html',
})
export class EmailTemplatePhysicianStatusAssociationsComponent implements OnInit, OnChanges {
    @Input() selectedAddressBookType: IAddressBookType;
    filteredEmailTemplates: IEmailTemplate[];
    filteredPhysicianStatuses: IPhysicianStatus[];
    filteredPhysicianStatusTypeEmailTemplates: IPhysicianStatusEmailTemplate[] = [];
    emailTemplatesGroupedByPhysicianType: IGroupedEmailTemplatesByPhysicianStatusDTO[] = [];
    constructor(
        private emailTemplateService: EmailTemplateService,
        private physicianStatusEmailTemplateService: PhysicianStatusEmailTemplateService,
        private notificationService: NotificationsService,
        private physicianStatusService: PhysicianStatusService,
    ) {}

    ngOnInit(): void {
        this.getFilteredEmailTemplates();
    }

    ngOnChanges(): void {
        this.getFilteredEmailTemplates();
    }

    getFilteredEmailTemplates(): void {
        this.filteredEmailTemplates = null;
        this.filteredPhysicianStatuses = null;
        forkJoin([
            this.physicianStatusService.getAll(),
            this.physicianStatusEmailTemplateService.getAll(),
            this.physicianStatusEmailTemplateService.getEmailTemplatesGroupedByFollowup(this.selectedAddressBookType.Id),
            this.searchEmailTemplates(),
        ]).subscribe(([filteredPhysicianStatuses, physicianStatusEmailTemplateAssociations, groupedEmailTemplates,]) => {
            this.emailTemplatesGroupedByPhysicianType = groupedEmailTemplates;
            this.filteredPhysicianStatusTypeEmailTemplates = physicianStatusEmailTemplateAssociations.filter(
                (x) => x.AddressBookTypeId === this.selectedAddressBookType.Id,
            );
            this.filteredPhysicianStatuses = filteredPhysicianStatuses.filter(
                (x) => !this.filteredPhysicianStatusTypeEmailTemplates.some((y) => y.PhysicianStatusId === x.Id),
            );
        });
    }

    searchEmailTemplates(): Observable<HttpResponse<IEmailTemplate[]>> {
        const _extraSearchParams: ExtraSearchParams[] = [];
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'includeArchived',
                value: '0',
            }),
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'addressBookTypeId',
                value: this.selectedAddressBookType.Id.toString(),
            }),
        );

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: 'Name',
            orderDirection: 'asc',
            query: '',
        };

        const searchparams = new SearchParams(searchEntity);
        return this.emailTemplateService.search(searchparams).pipe(
            tap(({ body: templates }) => {
                this.filteredEmailTemplates = templates;
            }),
        );
    }

    savePhysicianStatusEmailTemplateAssociation(event: IEmailTemplatePhysicianStatusAssociation): void {
        const physicianStatusEmailTemplateAssociation: IPhysicianStatusEmailTemplate = {
            AddressBookTypeId: this.selectedAddressBookType.Id,
            EmailTemplateId: event.EmailTemplate.Id,
            Id: 0,
            PhysicianStatusId: event.PhysicianStatus.Id,
        };

        this.physicianStatusEmailTemplateService.create(physicianStatusEmailTemplateAssociation).subscribe((id) => {
            physicianStatusEmailTemplateAssociation.Id = id;
            this.filteredPhysicianStatusTypeEmailTemplates.push(physicianStatusEmailTemplateAssociation);
            this.notificationService.success('Followup email template association saved successfully.');
            this.getFilteredEmailTemplates();
        });
    }

    addPhysicianStatusEmailTemplateAssociation(): void {
        this.emailTemplatesGroupedByPhysicianType.push({
            EmailTemplates: [],
            PhysicianStatus: null,
        } as IGroupedEmailTemplatesByPhysicianStatusDTO);
    }

    deletePhysicianStatusEmailTemplateAssociation(
        physicianStatusEmailTemplateAssociation: IEmailTemplatePhysicianStatusAssociation,
        index: number,
    ): void {
        const id = this.filteredPhysicianStatusTypeEmailTemplates.find(
            (x) =>
                x.PhysicianStatusId === physicianStatusEmailTemplateAssociation.PhysicianStatus.Id &&
                x.EmailTemplateId === physicianStatusEmailTemplateAssociation.EmailTemplate.Id,
        ).Id;
        this.physicianStatusEmailTemplateService.delete(id).subscribe(() => {
            this.filteredPhysicianStatusTypeEmailTemplates = this.filteredPhysicianStatusTypeEmailTemplates.filter((x) => x.Id !== id);
            this.emailTemplatesGroupedByPhysicianType[index].EmailTemplates = this.emailTemplatesGroupedByPhysicianType[index].EmailTemplates.filter(
                (x) => x.Id !== physicianStatusEmailTemplateAssociation.EmailTemplate.Id,
            );
            if (!this.emailTemplatesGroupedByPhysicianType[index].EmailTemplates.length) {
                this.emailTemplatesGroupedByPhysicianType.splice(index, 1);
                this.filteredPhysicianStatuses.push(physicianStatusEmailTemplateAssociation.PhysicianStatus);
            }
            this.notificationService.info('Physician status email template association deleted successfully.');
            this.getFilteredEmailTemplates();
        });
    }
}
