import {
    EntityListConfig,
    SortDirection,
    EntityListColumn,
    IEntityListColumn,
    IEntityListConfig,
    EntityListDeleteColumn,
} from '@mt-ng2/entity-list-module';
import { IUser } from '@model/interfaces/user';
import { IPhysicianStatusLog } from '@model/interfaces/physician-status-log';
import { IPhysicianStatusLogPartial } from '@model/partials/physician-status-log-partial';

export class PhysicianStatusLogsEntityListConfig extends EntityListConfig {
    columns: IEntityListColumn[];

    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['ChangedDate'],
                    name: 'Changed Date',
                    pipes: ['date'],
                    sort: {
                        defaultDirection: SortDirection.Desc,
                        isDefaultForSort: true,
                        sortProperty: 'ChangedDate',
                    },
                }),
                new EntityListColumn({
                    accessors: ['PhysicianStatus', 'Name'],
                    name: 'Status',
                    sort: {
                        sortProperty: 'PhysicianStatusId',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (phys: IPhysicianStatusLogPartial): string {
                        if (phys.User) {
                            return `${phys.User.FirstName} ${phys.User.LastName}`;
                        } else {
                            return 'System';
                        }
                    },
                    name: 'Name',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['PhysicianDetail', 'ImedClaimType', 'Name'],
                    name: 'Case Type',
                    sort: {
                        sortProperty: 'PhysicianStatusId',
                    },
                }),
            ],
            // delete: new EntityListDeleteColumn({ columnHtml: '<i class="fa fa-2x fa-fw fa-search icon-main"></i>', headerText: 'Disable', confirm: { text: 'Are you sure you want to disable?' } }),
            // rowClass: ((entity: any) => entity.archived ? 'archived' : null),
        };
        super(listConfig);
    }
}
