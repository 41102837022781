import { Subscription, Observable } from 'rxjs';
import { PopUpTypeEnums, IMedClaimStatusIdEnums, OtherServiceTypeEnums, ServiceIdEnums } from './../../../common/constants/Enums';
import { IAuthUser } from '@model/interfaces/auth-user.d';
import { UserService } from './../../../users/user.service';
import { ImedClaimService } from './../../imedclaim.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import 'rxjs/operators';
import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues, AuthService } from '@mt-ng2/auth-module';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection, IItemDeletedEvent } from '@mt-ng2/entity-list-module';
import { ImedClaimServiceService } from '../imedclaimservice.service';
import { entityListModuleConfig } from '../../../common/shared.module';
import { ClaimTypes } from '@model/ClaimTypes';
import { ImedClaimServicesEntityListConfig } from './imed-claim-services.entity-list-config';
import { IViewGetServicesForList } from '@model/interfaces/custom/view-get-services-for-list';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ServiceService } from '../service.service';
import { UserRoleService } from '../../../user-roles/user-role.service';
import { IService } from '@model/interfaces/service';
import { forkJoin } from 'rxjs';
import { CommonSearchService } from '../../../common/services/common-search.service';
import { ServiceStatusService } from '../common-components/service-status/servicestatus.service';

import { PopUpService, IPopUpParams } from '../../../common/services/popup-service';
import { DateParts } from '@mt-ng2/date-module';
import { debounceTime, map } from 'rxjs/operators';
import { MtSearchBarComponent } from '@mt-ng2/searchbar-control';
import { SpecialityService } from '../../../imed-managed-list/speciality.service';
import { IImedClaim } from '@model/interfaces/imed-claim';
import { IMetaItem, MetaItem } from '@mt-ng2/base-service';
import { DatePipe } from '@angular/common';
import { ReuseRoute } from '../../../common/cache-search-reuse-strategy/reuse-route.library';
import { HttpResponse } from '@angular/common/http';
import { IStatusChangedFilterDto } from '@model/interfaces/custom/status-changed-filter-dto';
import { ImedClaimTypeService } from '../../../common/services/imed-claim-type.service';
import { ImedClaimTypes } from '@model/ImedClaimTypes';

@Component({
    selector: 'app-imed-claim-services',
    styles: [
        '.service-select { font-weight: normal; width: 100%; padding: 3px 20px 3px 20px; margin: 0; border: none; background: transparent;} ',
        '.service-select:hover { background: #f5f5f5;} ',
        '.service-select:focus { outline: 0} ',
        '.service-select:active { background: #8AC175; color: #ffffff; outline: none} ',
    ],
    templateUrl: './imed-claim-services.component.html',
})
export class ImedClaimServicesComponent implements OnInit, OnDestroy, ReuseRoute {
    searchControl = new UntypedFormControl();
    imedClaimServices: IViewGetServicesForList[];
    currentPage = 1;
    query = '';
    total: number;
    imedClaims: MtSearchFilterItem[] = [];
    entityListConfig = new ImedClaimServicesEntityListConfig();
    canDelete: boolean;
    order = 'DateCreated';
    orderDirection = 'desc';
    allServiceTypes: MtSearchFilterItem<IService>[] = [];
    serviceTypes: MtSearchFilterItem<IService>[] = [];
    statusChangeServiceTypes: MtSearchFilterItem[] = [];
    userRoleServiceTypes: IService[] = [];
    canAddImedClaimService = false;
    imedClaimId: number;
    services: IService[];
    authUserId: number;
    userId: number;
    formCreated = false;
    searchClaimServicesForm: UntypedFormGroup;
    subscriptions: Subscription = new Subscription();
    selectedServices: IService[];
    allServiceStatuses: MtSearchFilterItem[] = [];
    filteredServiceStatuses: MtSearchFilterItem[] = [];
    statusChangefilteredServiceStatuses: MtSearchFilterItem[] = [];
    groupedStatusIds: any = [];
    physicianSpecialties: MtSearchFilterItem[] = [];
    requestedSpecialties: MtSearchFilterItem[] = [];
    reportAssignedTo: MtSearchFilterItem[] = [];
    schedulingAssignedTo: MtSearchFilterItem[] = [];
    retrievalAssignedTo: MtSearchFilterItem[] = [];
    compilationAssignedTo: MtSearchFilterItem[] = [];
    usersWhoChangedStatus: MtSearchFilterItem[] = [];
    imedClaimTypes: MtSearchFilterItem[] = [];
    userCustomOptions: any;

    // Additional search filters
    radiologyForwarded = false;
    radiologyNotForwarded = false;
    radiologyForwardedOutstandingRemain = false;
    recordsForwarded = false;
    recordsNotForwarded = false;
    recordsForwardedOutstandingRemain = false;
    radiologySubService = false;
    includeArchived = false;
    highValueOnly = false;
    rushOnly = false;
    serviceId: number;
    firmName: string;

    today = new Date();
    minDate: Date = new Date().mtDate.subtract(2, DateParts.years).date;
    maxDate: Date = new Date().mtDate.add(2, DateParts.years).date;
    appointmentEntityName = 'Appointment Date Range';
    appointmentStartDate: Date = null;
    appointmentEndDate: Date = null;
    followupEntityName = 'Last Follow-up Date Range';
    followupStartDate: Date = null;
    followupEndDate: Date = null;
    serviceAlert = 'You are trying to add a service outside of your defined role. Do you want to continue?';
    closedAlert = 'You are trying to add a service to a closed case. Do you want to continue?';
    // Track current search for input to saved search component
    currentSearch: SearchParams;
    searchChanged: boolean; // value to input to search component to reset the search filter dropdown ui
    // Property store common search parameters
    commonSearchParams: SearchParams;
    componentLoaded: boolean;

    imedClaim: IImedClaim;
    @ViewChild('searchBar') searchBar: MtSearchBarComponent;

    // Date created filter
    createdDateStartDate: Date = null;
    createdDateEndDate: Date = null;

    // Status date range search props
    searchExpanded = false;
    statusChangeDateFrom: Date = null;
    statusChangeDateTo: Date = null;

    constructor(
        private imedClaimServiceService: ImedClaimServiceService,
        private imedClaimService: ImedClaimService,
        private claimsService: ClaimsService,
        private router: Router,
        private authService: AuthService,
        private notificationsService: NotificationsService,
        private serviceService: ServiceService,
        private userRoleService: UserRoleService,
        private activatedRoute: ActivatedRoute,
        private commonSearchService: CommonSearchService,
        private serviceStatusService: ServiceStatusService,
        private physicianSpecialtyService: SpecialityService,
        private userService: UserService,
        private popUpService: PopUpService,
        private datePipe: DatePipe,
        private imedClaimTypeService: ImedClaimTypeService,
        private route: ActivatedRoute,
    ) {}

    resetFilters(): void {
        this.searchControl.reset();
        this.searchBar.clearSearch();
        this.imedClaimTypes.forEach((x) => (x.Selected = this.checkUserCustomOption(x)));
        this.updateServiceFilter();
        this.statusChangeServiceTypes.forEach((x) => (x.Selected = false));
        this.physicianSpecialties.forEach((x) => (x.Selected = false));
        this.reportAssignedTo.forEach((x) => (x.Selected = false));
        this.schedulingAssignedTo.forEach((x) => (x.Selected = false));
        this.retrievalAssignedTo.forEach((x) => (x.Selected = false));
        this.compilationAssignedTo.forEach((x) => (x.Selected = false));
        this.usersWhoChangedStatus.forEach((x) => (x.Selected = false));
        this.filteredServiceStatuses = [];
        this.allServiceStatuses.forEach((x) => (x.Selected = false));
        this.statusChangefilteredServiceStatuses = [];
        this.createdDateEndDate = null;
        this.createdDateStartDate = null;
        this.appointmentStartDate = null;
        this.appointmentEndDate = null;
        this.followupStartDate = null;
        this.followupEndDate = null;
        this.statusChangeDateFrom = null;
        this.statusChangeDateTo = null;
        if (this.recordsForwarded) {
            this.searchClaimServicesForm.get('searchRecordsForwarded').setValue(false);
        }
        if (this.recordsNotForwarded) {
            this.searchClaimServicesForm.get('searchRecordsNotForwarded').setValue(false);
        }
        if (this.radiologyForwarded) {
            this.searchClaimServicesForm.get('searchRadiologyForwarded').setValue(false);
        }
        if (this.radiologyForwardedOutstandingRemain) {
            this.searchClaimServicesForm.get('searchRadiologyForwardedOutstandingRemain').setValue(false);
        }
        if (this.radiologyNotForwarded) {
            this.searchClaimServicesForm.get('searchRadiologyNotForwarded').setValue(false);
        }
        if (this.recordsForwardedOutstandingRemain) {
            this.searchClaimServicesForm.get('searchRecordsForwardedOutstandingRemain').setValue(false);
        }
        if (this.includeArchived) {
            this.searchClaimServicesForm.get('searchIncludeArchived').setValue(false);
        }
        if (this.highValueOnly) {
            this.searchClaimServicesForm.get('searchHighValueOnly').setValue(false);
        }
        if (this.rushOnly) {
            this.searchClaimServicesForm.get('searchRushOnly').setValue(false);
        }
        if (this.serviceId) {
            this.searchClaimServicesForm.get('searchServiceId').setValue(null);
        }
        if (this.firmName) {
            this.searchClaimServicesForm.get('searchFirmName').setValue(null);
        }
        if (this.radiologySubService) {
            this.searchClaimServicesForm.get('searchRadiologySubService').setValue(false);
        }
        this.searchExpanded = false;
        this.commonSearchParams = null;
        this.searchChanged = !this.searchChanged;
    }

    ngOnInit(): void {
        this.entityListConfig.export = {
            exportName: 'Service List',
            getDataForExport: () => this.getImedClaimServicesSubscription(true).pipe(map((x) => x.body)),
        };
        this.imedClaimId = this.activatedRoute.parent.snapshot.params.imedClaimId;
        this.canDelete = this.claimsService.hasClaim(ClaimTypes.CanDeleteServices, [ClaimValues.FullAccess]);
        this.subscriptions.add(
            this.userService.getAll().subscribe((users) => {
                const activeUsers = users.filter((user) => !user.Archived);
                this.reportAssignedTo = activeUsers.map(
                    (user) => new MtSearchFilterItem(new MetaItem(user.Id, `${user.FirstName} ${user.LastName}`), false),
                );
                this.schedulingAssignedTo = activeUsers.map(
                    (user) => new MtSearchFilterItem(new MetaItem(user.Id, `${user.FirstName} ${user.LastName}`), false),
                );
                this.retrievalAssignedTo = activeUsers.map(
                    (user) => new MtSearchFilterItem(new MetaItem(user.Id, `${user.FirstName} ${user.LastName}`), false),
                );
                this.compilationAssignedTo = activeUsers.map(
                    (user) => new MtSearchFilterItem(new MetaItem(user.Id, `${user.FirstName} ${user.LastName}`), false),
                );
                this.usersWhoChangedStatus = activeUsers.map(
                    (user) => new MtSearchFilterItem(new MetaItem(user.Id, `${user.FirstName} ${user.LastName}`), false),
                );
            }),
        );
        this.searchClaimServicesForm = new UntypedFormGroup({
            searchFirmName: new UntypedFormControl(null),
            searchHighValueOnly: new UntypedFormControl(false),

            searchIncludeArchived: new UntypedFormControl(false),
            searchRadiologyForwarded: new UntypedFormControl(false),
            searchRadiologyForwardedOutstandingRemain: new UntypedFormControl(false),
            searchRadiologyNotForwarded: new UntypedFormControl(false),
            searchRecordsForwarded: new UntypedFormControl(false),
            searchRecordsForwardedOutstandingRemain: new UntypedFormControl(false),
            searchRecordsNotForwarded: new UntypedFormControl(false),
            searchRushOnly: new UntypedFormControl(false),
            searchServiceId: new UntypedFormControl(null),
            searchRadiologySubService: new UntypedFormControl(false),
        });

        this.imedClaimServiceService.setCanDelete(this.canDelete);
        this.userId = this.authService.currentUser.getValue().Id;
        this.authUserId = this.authService.currentUser.getValue().AuthId;
        this.canAddImedClaimService = this.claimsService.hasClaim(ClaimTypes.CaseManagement, [ClaimValues.FullAccess]);

        const pendingSubscriptions = [
            this.serviceService.getItems(),
            this.serviceService.getServiceTypeSortedByName(),
            this.userRoleService.getRolesServicesByAuthUserId(this.authUserId),
            this.commonSearchService.getCommonSearchFilters(),
            this.serviceStatusService.getGroupedStatusIds(),
            this.physicianSpecialtyService.getAll(),
            this.imedClaimTypeService.getAll(),
        ];

        if (this.imedClaimId > 0) {
            pendingSubscriptions.push(this.imedClaimService.getById(this.imedClaimId));
            this.subscriptions.add(
                this.router.events.subscribe((event) => {
                    if (event instanceof NavigationEnd && event.url === '/cases/0/services' && event.urlAfterRedirects === '/cases/0/services') {
                        window.location.reload();
                    }
                }),
            );
        }

        forkJoin(pendingSubscriptions).subscribe(
            ([services, serviceTypes, userRoles, filter, statusIds, specialities, imedClaimTypes, ...imedClaim]) => {
                if (imedClaim.length > 0) {
                    this.imedClaim = imedClaim[0];
                }
                this.imedClaimTypes = imedClaimTypes
                    .filter((type) => (this.imedClaimId > 0 ? type.Id === this.imedClaim.ClaimTypeId : true))
                    .map((type) => new MtSearchFilterItem(type, false));
                this.imedClaimTypes.forEach((x) => (x.Selected = this.checkUserCustomOption(x)));
                this.allServiceTypes = serviceTypes;
                this.updateServiceFilter();

                this.services = services.filter((s: IService) => {
                    return !this.serviceService.isRestrictedToBeCreatedAsStandAlone(s.Id) && (this.imedClaimId > 0 ? s.ClaimTypeId === this.imedClaim.ClaimTypeId : true);
                });
                this.statusChangeServiceTypes = this.allServiceTypes.filter((s) =>
                    this.imedClaimId > 0 ? s.Item.ClaimTypeId === this.imedClaim.ClaimTypeId : true,
                );
                this.userRoleServiceTypes = userRoles;
                this.commonSearchParams = filter ? filter.searchFilters : null;

                this.groupedStatusIds = statusIds;
                this.allServiceStatuses = statusIds.map((status: IMetaItem) => new MtSearchFilterItem(new MetaItem(status.Id, status.Name), false));

                this.physicianSpecialties = specialities.map(
                    (speciality: IMetaItem) => new MtSearchFilterItem(new MetaItem(speciality.Id, speciality.Name), false),
                );

                this.requestedSpecialties = JSON.parse(JSON.stringify(this.physicianSpecialties));

                if (this.commonSearchParams !== null) {
                    const OtherServiceTypeParams = this.commonSearchParams.extraParams.filter((x) => x.name === 'OtherServiceType');
                    if (OtherServiceTypeParams && OtherServiceTypeParams.length > 0) {
                        this.preSelectServicesSearchFilterByType(+OtherServiceTypeParams[0].value);
                    }
                } else {
                    this.preSelectServicesSearchFilter();
                }
                this.getImedClaimServices();
                this.subscribeToValueChanges();
                this.commonSearchService.clearCommonSearchFilters();
            },
        );

        this.componentLoaded = true;
        this.formCreated = true;
        this.setUserCustomOptions();
    }

    updateServiceFilter(): void {
        const selectedClaimTypes = this.imedClaimTypes.filter((ct) => ct.Selected);

        this.serviceTypes = this.allServiceTypes.filter((s) => {
            if (this.imedClaimId > 0) {
                return s.Item.ClaimTypeId === this.imedClaim.ClaimTypeId;
            } else if (selectedClaimTypes.length > 0) {
                const returnValue = selectedClaimTypes.some((c) => c.Item.Id === s.Item.ClaimTypeId);
                return returnValue;
            } else {
                return true;
            }
        });
    }

    setUserCustomOptions(): void {
        this.userCustomOptions = this.authService.currentUser.getValue().CustomOptions;
    }

    checkUserCustomOption(filterItem: MtSearchFilterItem): boolean {
        switch (filterItem.Item.Id as ImedClaimTypes) {
            case ImedClaimTypes.GeneralLiability:
                return this.userCustomOptions.GeneralLiability;
            case ImedClaimTypes.WorkersCompensation:
                return this.userCustomOptions.WorkersComp;
            case ImedClaimTypes.Auto:
                return this.userCustomOptions.Auto;
            default:
                return false;
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    onRouteReuse(): void {
        this.getImedClaimServices();
    }

    validateAndSaveNewService(service: IService): void {
        if (this.claimStatusClosedOrArchived() || this.userRoleDoesNotIncludeServiceType(service.Id)) {
            const message = this.claimStatusClosedOrArchived() ? this.closedAlert : this.serviceAlert;
            const popUp: IPopUpParams = {
                confirmButtonText: 'Continue',
                html: message,
                title: 'Service Alert',
                type: PopUpTypeEnums.Soft_Pop_Up,
            };
            this.popUpService.showPopUp(popUp).subscribe((response) => {
                if (response.value) {
                    this.saveService(service);
                    this.imedClaim.ImedClaimStatusId = IMedClaimStatusIdEnums.Active;
                    this.imedClaim.ModifiedById = this.userId;
                    this.imedClaim.Birthdate = this.imedClaim.Birthdate
                        ? this.setDatesToTimeZoneRelatedDate(this.imedClaim.Birthdate.toString())
                        : null;
                    this.imedClaim.DateofAllegedAccidentOrInjury = this.imedClaim.DateofAllegedAccidentOrInjury
                        ? this.setDatesToTimeZoneRelatedDate(this.imedClaim.DateofAllegedAccidentOrInjury.toString())
                        : null;
                    this.imedClaimService.update(this.imedClaim).subscribe();
                }
            });
        } else {
            this.saveService(service);
        }
    }

    setDatesToTimeZoneRelatedDate(dateToBeConverted: string): Date {
        const dateFromFormAsISOString = new Date(dateToBeConverted).toISOString();
        const dateWithoutUTCIdentifier = new Date(dateFromFormAsISOString.substring(0, dateFromFormAsISOString.length - 1));
        return new Date(dateWithoutUTCIdentifier.mtDate.toMoment().format('MM/DD/YYYY'));
    }

    userRoleDoesNotIncludeServiceType(serviceId: number): boolean {
        return !this.userRoleServiceTypes.some((usrServices) => usrServices.Id === serviceId);
    }

    claimStatusClosedOrArchived(): boolean {
        return (
            this.imedClaim.ImedClaimStatusId === +IMedClaimStatusIdEnums.Close ||
            this.imedClaim.ImedClaimStatusId === +IMedClaimStatusIdEnums.Archived
        );
    }

    saveService(service: IService): void {
        const newService = this.imedClaimService.getNewService(service.Id, this.authService.currentUser.getValue().Id, this.imedClaimId);
        if (service.Id === +ServiceIdEnums.WC_Record_Review) {
            const addressBooks = [
                this.imedClaim.CarrierPartyIndividual ? this.imedClaim.CarrierPartyIndividual : this.imedClaim.CarrierPartyAddressBook,
                this.imedClaim.ClaimantsAttorneyIndividual ? this.imedClaim.ClaimantsAttorneyIndividual : this.imedClaim.ClaimantsAttorneyAddressBook,
                this.imedClaim.CarriersCounselIndividual ? this.imedClaim.CarriersCounselIndividual : this.imedClaim.CarriersCounselAddressBook,
                this.imedClaim.EmployerIndividual ? this.imedClaim.EmployerIndividual : this.imedClaim.EmployerAddressBook,
                this.imedClaim.OtherIndividual ? this.imedClaim.OtherIndividual : this.imedClaim.OtherAddressBook,
            ];
            const highestRateAddressbook = addressBooks.reduce((highest, current) => {
                if (!highest) {
                    return current;
                } else {
                    return current &&
                        ((current.ContractRateFrom !== null && current.ContractRateFrom >= highest.ContractRateTo) ||
                            current.ContractRateTo > highest.ContractRateTo ||
                            (current.ContractRateFrom > highest.ContractRateFrom && current.ContractRateTo >= highest.ContractRateTo))
                        ? current
                        : highest;
                }
            });
            if (highestRateAddressbook) {
                newService.ContractRateFrom = highestRateAddressbook.ContractRateFrom;
                newService.ContractRateTo = highestRateAddressbook.ContractRateTo;
            }
        }
        this.imedClaimServiceService.createWithFks(newService).subscribe((imedServiceId) => {
            void this.router.navigate([`cases/${this.imedClaimId}/services/${imedServiceId}`]);
        });
    }

    confirmDocumentsAreAttached(message): Observable<boolean> {
        const popUpParams: IPopUpParams = {
            html: message,
            type: PopUpTypeEnums.Hard_Pop_Up,
        };
        return this.popUpService.showPopUp(popUpParams).pipe(map((result) => (result.value ? true : false)));
    }

    private subscribeToValueChanges(): void {
        this.subscriptions.add(
            this.searchClaimServicesForm.get('searchIncludeArchived').valueChanges.subscribe((value) => {
                this.includeArchived = value;
                this.clearCommonSearchParamsAndSearch();
            }),
        );

        this.subscriptions.add(
            this.searchClaimServicesForm.get('searchHighValueOnly').valueChanges.subscribe((value) => {
                this.highValueOnly = value;
                this.clearCommonSearchParamsAndSearch();
            }),
        );

        this.subscriptions.add(
            this.searchClaimServicesForm.get('searchRushOnly').valueChanges.subscribe((value) => {
                this.rushOnly = value;
                this.clearCommonSearchParamsAndSearch();
            }),
        );

        this.subscriptions.add(
            this.searchClaimServicesForm
                .get('searchServiceId')
                .valueChanges.pipe(debounceTime(600))
                .subscribe((value) => {
                    this.serviceId = value;
                    this.clearCommonSearchParamsAndSearch();
                }),
        );

        this.subscriptions.add(
            this.searchClaimServicesForm
                .get('searchFirmName')
                .valueChanges.pipe(debounceTime(600))
                .subscribe((value) => {
                    this.firmName = value;
                    this.clearCommonSearchParamsAndSearch();
                }),
        );

        this.subscriptions.add(
            this.searchClaimServicesForm.get('searchRecordsForwarded').valueChanges.subscribe((value) => {
                this.recordsForwarded = value;
                const recordsNotForwardedControl = this.searchClaimServicesForm.get('searchRecordsNotForwarded');
                if (value && recordsNotForwardedControl.value) {
                    recordsNotForwardedControl.setValue(false);
                } else {
                    this.clearCommonSearchParamsAndSearch();
                }
            }),
        );

        this.subscriptions.add(
            this.searchClaimServicesForm.get('searchRecordsNotForwarded').valueChanges.subscribe((value) => {
                this.recordsNotForwarded = value;
                const recordsForwardedControl = this.searchClaimServicesForm.get('searchRecordsForwarded');
                if (value && recordsForwardedControl.value) {
                    recordsForwardedControl.setValue(false);
                } else {
                    this.clearCommonSearchParamsAndSearch();
                }
            }),
        );

        this.subscriptions.add(
            this.searchClaimServicesForm.get('searchRecordsForwardedOutstandingRemain').valueChanges.subscribe((value) => {
                this.recordsForwardedOutstandingRemain = value;

                const radiologyForwardedOutstandingRemainControl = this.searchClaimServicesForm.get('searchRadiologyForwardedOutstandingRemain');
                const radiologyForwardedControl = this.searchClaimServicesForm.get('searchRadiologyForwarded');
                const radiologyNotForwardedControl = this.searchClaimServicesForm.get('searchRadiologyNotForwarded');

                if (value && radiologyNotForwardedControl.value) {
                    radiologyNotForwardedControl.setValue(false);
                } else if (value && radiologyForwardedControl.value) {
                    radiologyForwardedControl.setValue(false);
                } else if (value && radiologyForwardedOutstandingRemainControl.value) {
                    radiologyForwardedOutstandingRemainControl.setValue(false);
                } else {
                    this.clearCommonSearchParamsAndSearch();
                }
            }),
        );

        this.subscriptions.add(
            this.searchClaimServicesForm.get('searchRadiologyForwarded').valueChanges.subscribe((value) => {
                this.radiologyForwarded = value;
                const recordsForwardedOutstandingRemainControl = this.searchClaimServicesForm.get('searchRecordsForwardedOutstandingRemain');
                if (value && recordsForwardedOutstandingRemainControl.value) {
                    recordsForwardedOutstandingRemainControl.setValue(false);
                } else {
                    this.clearCommonSearchParamsAndSearch();
                }
            }),
        );

        this.subscriptions.add(
            this.searchClaimServicesForm.get('searchRadiologyNotForwarded').valueChanges.subscribe((value) => {
                this.radiologyNotForwarded = value;
                const radiologyForwardedControl = this.searchClaimServicesForm.get('searchRadiologyForwarded');
                if (value && radiologyForwardedControl.value) {
                    radiologyForwardedControl.setValue(false);
                } else {
                    this.clearCommonSearchParamsAndSearch();
                }
            }),
        );

        this.subscriptions.add(
            this.searchClaimServicesForm.get('searchRadiologyForwardedOutstandingRemain').valueChanges.subscribe((value) => {
                this.radiologyForwardedOutstandingRemain = value;
                this.clearCommonSearchParamsAndSearch();
            }),
        );

        this.subscriptions.add(
            this.searchClaimServicesForm.get('searchRadiologySubService').valueChanges.subscribe((value) => {
                this.radiologySubService = value;
                this.clearCommonSearchParamsAndSearch();
            }),
        );
    }

    overrideDebounceTime(searchControl: UntypedFormControl): void {
        this.searchControl = searchControl;
        this.subscriptions.add(
            searchControl.valueChanges.pipe(debounceTime(600)).subscribe((value: string) => {
                this.search(value);
            }),
        );
    }

    preSelectServicesSearchFilterByType(typeId: number): void {
        this.serviceTypes.map((item) => {
            const service = item.Item;
            if (typeId === +OtherServiceTypeEnums.Examination && service.IsExaminationAndReview) {
                item.Selected = true;
            } else if (typeId === +OtherServiceTypeEnums.Retrievals && service.IsRetrieval) {
                item.Selected = true;
            } else if (typeId === +OtherServiceTypeEnums.CRS && service.IsCourtRelatedService) {
                item.Selected = true;
            }
        });
    }

    preSelectServicesSearchFilter(): void {
        this.serviceTypes.map((item) => {
            this.userRoleServiceTypes.forEach((element) => {
                if (element.Id === item.Item.Id) {
                    item.Selected = true;
                }
            });
        });
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    private getSelectedServiceStatuses(filterObj: MtSearchFilterItem[]): string {
        return filterObj
            .filter((item) => item.Selected)
            .map((item) => this.groupedStatusIds[item.Item.Id].Name.trim())
            .filter((val, i, self) => self.indexOf(val) === i)
            .join('|');
    }

    searchBySavedSearch(): void {
        this.commonSearchService.getCommonSearchFilters().subscribe((filters) => {
            if (this.commonSearchParams) {
                this.resetFilters();
            }
            this.currentPage = 1;
            this.commonSearchParams = filters ? filters.searchFilters : null;
            this.searchExpanded =
                this.commonSearchParams.extraParams?.some((extraParam) => {
                    return extraParam.name === 'StatusChangedFilter';
                }) || false;
            this.preFillFiltersWithSavedSearch();
            this.getImedClaimServices();
            this.commonSearchService.clearCommonSearchFilters();
        });
    }
    preFillFiltersWithSavedSearch(): void {
        this.autoFillSelectedFilters(this.serviceTypes, 'ServiceTypeIds');
        this.autoFillSelectedFilters(this.imedClaimTypes, 'imedClaimTypeIds');
        const selectedServiceIds = this.getSelectedFilters(this.serviceTypes);
        this.filteredServiceStatuses = this.allServiceStatuses.filter((status) =>
            this.groupedStatusIds[status.Item.Id].ServiceIds.some((id: number) => selectedServiceIds.indexOf(id) > -1),
        );
        this.filteredServiceStatuses.sort((a, b) => (a.Item.Name < b.Item.Name ? -1 : 1));
        this.autoFillSelectedServiceStatusFilter(this.filteredServiceStatuses, 'ServiceStatuses');
        this.autoFillSelectedFilters(this.physicianSpecialties, 'physicianSpecialties');
        this.autoFillSelectedFilters(this.reportAssignedTo, 'ReportAssignedTo');
        this.autoFillSelectedFilters(this.schedulingAssignedTo, 'SchedulingAssignedTo');
        this.autoFillSelectedFilters(this.retrievalAssignedTo, 'RetrievalAssignedTo');
        this.autoFillSelectedFilters(this.compilationAssignedTo, 'CompilationAssignedTo');
        this.autoFillSelectedFilters(this.requestedSpecialties, 'requestedSpecialties');
        this.setCheckBoxFilter('highValueOnly', 'searchHighValueOnly', this.highValueOnly);
        this.setCheckBoxFilter('rushOnly', 'searchRushOnly', this.rushOnly);
        this.setCheckBoxFilter('includeArchived', 'searchIncludeArchived', this.includeArchived);
        this.setCheckBoxFilter('recordsForwarded', 'searchRecordsForwarded', this.recordsForwarded);
        this.setCheckBoxFilter('recordsNotForwarded', 'searchRecordsNotForwarded', this.recordsNotForwarded);
        this.setCheckBoxFilter(
            'recordsForwardedOutstandingRemain',
            'searchRecordsForwardedOutstandingRemain',
            this.recordsForwardedOutstandingRemain,
        );
        this.setCheckBoxFilter('radiologyForwarded', 'searchRadiologyForwarded', this.radiologyForwarded);
        this.setCheckBoxFilter('radiologyNotForwarded', 'searchRadiologyNotForwarded', this.radiologyNotForwarded);
        this.setCheckBoxFilter(
            'radiologyForwardedOutstandingRemain',
            'searchRadiologyForwardedOutstandingRemain',
            this.radiologyForwardedOutstandingRemain,
        );
        this.setCheckBoxFilter('radiologySubService', 'searchRadiologySubService', this.radiologySubService);
        this.setTextFilter('FirmName', 'searchFirmName');
        this.setTextFilter('ServiceId', 'searchServiceId');
        if (this.getDateFilter('FollowupStartDate') !== null) {
            this.followupStartDate = new Date(this.getDateFilter('FollowupStartDate') as string);
        }
        if (this.getDateFilter('FollowupEndDate') !== null) {
            this.followupEndDate = new Date(this.getDateFilter('FollowupEndDate') as string);
        }
        if (this.getDateFilter('DateCreatedStartDate') !== null) {
            this.createdDateStartDate = new Date(this.getDateFilter('DateCreatedStartDate') as string);
        }
        if (this.getDateFilter('DateCreatedEndDate') !== null) {
            this.createdDateEndDate = new Date(this.getDateFilter('DateCreatedEndDate') as string);
        }
        if (this.getDateFilter('AppointmentStartDate') !== null) {
            this.appointmentStartDate = new Date(this.getDateFilter('AppointmentStartDate') as string);
        }
        if (this.getDateFilter('AppointmentEndDate') !== null) {
            this.appointmentEndDate = new Date(this.getDateFilter('AppointmentEndDate') as string);
        }
        this.autoFillStatusChangedFilter();
        if (this.query !== this.commonSearchParams.query) {
            this.searchBar.writeValue(this.commonSearchParams.query);
            this.query = this.commonSearchParams.query;
        }
    }

    autoFillStatusChangedFilter(): void {
        const extrasearchParams = this.commonSearchParams.extraParams;
        const filteredParam = extrasearchParams?.find((extraParam) => {
            return extraParam.name === 'StatusChangedFilter';
        });
        if (filteredParam && filteredParam.value) {
            const statusChangedParamObj = JSON.parse(filteredParam.value);
            this.statusChangeDateFrom = statusChangedParamObj.DateFrom;
            this.statusChangeDateTo = statusChangedParamObj.DateTo;
            this.usersWhoChangedStatus.map((item) => {
                statusChangedParamObj?.UsersWhoChangedStatus.forEach((element) => {
                    if (element === item.Item.Id) {
                        item.Selected = true;
                    }
                });
            });

            if (statusChangedParamObj?.SelectedServices) {
                this.statusChangeServiceTypes.map((item) => {
                    statusChangedParamObj?.SelectedServices.forEach((element) => {
                        if (element === item.Item.Id) {
                            item.Selected = true;
                        }
                    });
                });
                const selectedServiceIds = this.getSelectedFilters(this.statusChangeServiceTypes);
                this.statusChangefilteredServiceStatuses = this.allServiceStatuses.filter((status) =>
                    this.groupedStatusIds[status.Item.Id].ServiceIds.some((id: number) => selectedServiceIds.indexOf(id) > -1),
                );
                this.statusChangefilteredServiceStatuses.sort((a, b) => (a.Item.Name < b.Item.Name ? -1 : 1));
            } else {
                this.statusChangefilteredServiceStatuses = this.allServiceStatuses;
            }

            this.statusChangefilteredServiceStatuses.map((item) => {
                const selectedStatusNames = statusChangedParamObj?.SelectedStatuses.split('|');
                selectedStatusNames.forEach((element) => {
                    if (element === item.Item.Name) {
                        item.Selected = true;
                    }
                });
            });
        }
    }

    getDateFilter(pptyName: string): any {
        const extrasearchParams = this.commonSearchParams.extraParams;
        const filteredParam = extrasearchParams?.find((extraParam) => {
            return extraParam.name === pptyName;
        });
        if (filteredParam && filteredParam.value) {
            return this.datePipe.transform(filteredParam.value, 'M/d/yyyy', 'UTC');
        }
        return null;
    }

    setCheckBoxFilter(pptyName: string, formControlName: string, ppty: any): void {
        const extrasearchParams = this.commonSearchParams.extraParams;
        const filteredParam = extrasearchParams?.find((extraParam) => {
            return extraParam.name === pptyName;
        });
        if (filteredParam && filteredParam.value) {
            const valueToSet = filteredParam.value === '1' ? true : false;
            if (valueToSet !== ppty) {
                this.searchClaimServicesForm.get(formControlName).setValue(valueToSet);
            }
        }
    }

    setTextFilter(pptyName: string, formControlName: string): void {
        const extrasearchParams = this.commonSearchParams.extraParams;
        const filteredParam = extrasearchParams?.find((extraParam) => {
            return extraParam.name === pptyName;
        });
        if (filteredParam && filteredParam.value) {
            this.searchClaimServicesForm.get(formControlName).setValue(filteredParam.value);
        }
    }

    private autoFillSelectedFilters(filterObj: MtSearchFilterItem[], pptyName: string): void {
        const extrasearchParams = this.commonSearchParams.extraParams;
        const filteredParam = extrasearchParams?.find((extraParam) => {
            return extraParam.name === pptyName;
        });
        if (filteredParam && filteredParam.valueArray && filteredParam.valueArray.length > 0) {
            filterObj.forEach((x) => (x.Selected = false));
            filterObj.map((item) => {
                filteredParam?.valueArray?.forEach((element) => {
                    if (element === item.Item.Id) {
                        item.Selected = true;
                    }
                });
            });
        }
    }

    private autoFillSelectedServiceStatusFilter(filterObj: MtSearchFilterItem[], pptyName: string): void {
        const extrasearchParams = this.commonSearchParams.extraParams;
        const filteredParam = extrasearchParams?.find((extraParam) => extraParam.name === pptyName);
        if (filteredParam && filteredParam.name) {
            const statusNames = filteredParam.value?.split('|').map((name) => name.toLowerCase().trim()); // Convert to lowercase and trim
            filterObj.map((item) => {
                if (statusNames.some((element) => element === item.Item.Name.toLowerCase().trim())) {
                    item.Selected = true;
                }
            });
        }
    }

    private buildSearch(): ExtraSearchParams[] {
        const selectedImedClaimTypeIds: number[] = this.getSelectedFilters(this.imedClaimTypes);
        const selectedServiceTypeIds: number[] = this.getSelectedFilters(this.serviceTypes);
        const selectedServiceStatuses: string = this.getSelectedServiceStatuses(this.filteredServiceStatuses);
        const selectedPhysicianSpecialties: number[] = this.getSelectedFilters(this.physicianSpecialties);

        const selectedRequestedSpecialties: number[] = this.getSelectedFilters(this.requestedSpecialties);

        const selectedReportAssignedTo: number[] = this.getSelectedFilters(this.reportAssignedTo);
        const selectedSchedulingAssignedTo: number[] = this.getSelectedFilters(this.schedulingAssignedTo);
        const selectedRetrievalAssignedTo: number[] = this.getSelectedFilters(this.retrievalAssignedTo);
        const selectedCompilationAssignedTo: number[] = this.getSelectedFilters(this.compilationAssignedTo);
        let _extraSearchParams: ExtraSearchParams[] = [];
        if (this.commonSearchParams && this.commonSearchParams.extraParams) {
            _extraSearchParams = this.commonSearchParams.extraParams.filter(
                (esp) =>
                    esp.name === 'activeServiceTypeIds' ||
                    esp.name === 'activeDateFrom' ||
                    esp.name === 'activeDateTo' ||
                    esp.name === 'newServiceChartType' ||
                    esp.name === 'serviceTrendType',
            );
        }
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'imedClaimTypeIds',
                valueArray: selectedImedClaimTypeIds,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'ServiceTypeIds',
                valueArray: selectedServiceTypeIds,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'ServiceStatuses',
                value: selectedServiceStatuses,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'physicianSpecialties',
                valueArray: selectedPhysicianSpecialties,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'highValueOnly',
                value: this.highValueOnly ? '1' : '0',
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'rushOnly',
                value: this.rushOnly ? '1' : '0',
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'ReportAssignedTo',
                valueArray: selectedReportAssignedTo,
            }),
            new ExtraSearchParams({
                name: 'SchedulingAssignedTo',
                valueArray: selectedSchedulingAssignedTo,
            }),
            new ExtraSearchParams({
                name: 'RetrievalAssignedTo',
                valueArray: selectedRetrievalAssignedTo,
            }),
            new ExtraSearchParams({
                name: 'CompilationAssignedTo',
                valueArray: selectedCompilationAssignedTo,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'requestedSpecialties',
                valueArray: selectedRequestedSpecialties,
            }),
        );

        if (this.appointmentStartDate && this.appointmentEndDate) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'AppointmentStartDate',
                    value: this.datePipe.transform(this.appointmentStartDate, 'M/d/yyyy', 'UTC'),
                }),
            );
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'AppointmentEndDate',
                    value: this.datePipe.transform(this.appointmentEndDate, 'M/d/yyyy', 'UTC'),
                }),
            );
        }

        if (this.followupStartDate && this.followupEndDate) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'FollowupStartDate',
                    value: this.datePipe.transform(this.followupStartDate, 'M/d/yyyy', 'UTC'),
                }),
            );
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'FollowupEndDate',
                    value: this.datePipe.transform(this.followupEndDate, 'M/d/yyyy', 'UTC'),
                }),
            );
        }

        if (this.createdDateStartDate && this.createdDateEndDate) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'DateCreatedStartDate',
                    value: this.datePipe.transform(this.createdDateStartDate, 'M/d/yyyy', 'UTC'),
                }),
            );
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'DateCreatedEndDate',
                    value: this.datePipe.transform(this.createdDateEndDate, 'M/d/yyyy', 'UTC'),
                }),
            );
        }

        // Handle status change search if necessary
        if (this.searchExpanded) {
            _extraSearchParams = this.handleStatusChangeParams(_extraSearchParams);
        }

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'includeArchived',
                value: this.includeArchived ? '1' : '0',
            }),
        );

        if (this.route.snapshot.queryParamMap.get('ParentServiceId')) {
            const parentId = this.route.snapshot.queryParamMap.get('ParentServiceId');
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'ParentServiceId',
                    value: parentId.toString(),
                }),
            );
        }

        if (this.route.snapshot.queryParamMap.get('AdditionalServiceType')) {
            const serviceType = this.route.snapshot.queryParamMap.get('AdditionalServiceType');
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'AdditionalServiceType',
                    value: serviceType.toString(),
                }),
            );
        }

        if (this.serviceId && this.serviceId > 0) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'ServiceId',
                    value: this.serviceId.toString(),
                }),
            );
        }

        if (this.firmName && this.firmName.length) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'FirmName',
                    value: this.firmName,
                }),
            );
        }

        if (this.recordsForwarded) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'recordsForwarded',
                    value: '1',
                }),
            );
        }

        if (this.recordsNotForwarded) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'recordsNotForwarded',
                    value: '1',
                }),
            );
        }

        if (this.recordsForwardedOutstandingRemain) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'recordsForwardedOutstandingRemain',
                    value: '1',
                }),
            );
        }

        if (this.radiologyForwarded) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'radiologyForwarded',
                    value: '1',
                }),
            );
        }

        if (this.radiologyNotForwarded) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'radiologyNotForwarded',
                    value: '1',
                }),
            );
        }

        if (this.radiologyForwardedOutstandingRemain) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'radiologyForwardedOutstandingRemain',
                    value: '1',
                }),
            );
        }

        if (this.radiologySubService) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'radiologySubService',
                    value: '1',
                }),
            );
        }

        return _extraSearchParams;
    }

    handleStatusChangeParams(_extraSearchParams: ExtraSearchParams[]): ExtraSearchParams[] {
        // Build status change params
        const statusChangedParams: IStatusChangedFilterDto = {};
        const selectedServiceIds = this.getSelectedFilters(this.statusChangeServiceTypes);
        const selectedStatusChangeServiceStatuses: string = this.getSelectedServiceStatuses(this.statusChangefilteredServiceStatuses);
        const selectedUsersWhoChangedStatus: number[] = this.getSelectedFilters(this.usersWhoChangedStatus);
        if (this.statusChangeDateFrom && this.statusChangeDateTo) {
            statusChangedParams.DateFrom = this.statusChangeDateFrom;
            statusChangedParams.DateTo = this.statusChangeDateTo ? this.statusChangeDateTo : new Date();
        }
        if (selectedServiceIds && selectedServiceIds.length) {
            statusChangedParams.SelectedServices = selectedServiceIds;
        }
        if (selectedStatusChangeServiceStatuses && selectedStatusChangeServiceStatuses.length) {
            statusChangedParams.SelectedStatuses = selectedStatusChangeServiceStatuses;
        }
        if (selectedUsersWhoChangedStatus && selectedUsersWhoChangedStatus.length) {
            statusChangedParams.UsersWhoChangedStatus = selectedUsersWhoChangedStatus;
        }
        if (Object.entries(statusChangedParams).length > 0) {
            // Clear other search params
            _extraSearchParams = [];
            // Set default date to if null
            statusChangedParams.DateTo = statusChangedParams.DateTo ? statusChangedParams.DateTo : new Date();
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'StatusChangedFilter',
                    value: JSON.stringify(statusChangedParams),
                }),
            );
        }

        return _extraSearchParams;
    }

    selectCaseTypeAndFilterServices(): void {
        this.updateServiceFilter();
        this.clearCommonSearchParamsAndSearch();
    }

    selectServiceAndFilterStatuses(): void {
        const selectedServiceIds = this.getSelectedFilters(this.serviceTypes);
        this.filteredServiceStatuses = this.allServiceStatuses.filter((status) =>
            this.groupedStatusIds[status.Item.Id].ServiceIds.some((id: number) => selectedServiceIds.indexOf(id) > -1),
        );
        this.filteredServiceStatuses.sort((a, b) => (a.Item.Name < b.Item.Name ? -1 : 1));
        this.clearCommonSearchParamsAndSearch();
    }

    selectServiceAndFilterStatusesForStatusChange(): void {
        const selectedServiceIds = this.getSelectedFilters(this.statusChangeServiceTypes);
        this.statusChangefilteredServiceStatuses = this.allServiceStatuses.filter((status) =>
            this.groupedStatusIds[status.Item.Id].ServiceIds.some((id: number) => selectedServiceIds.indexOf(id) > -1),
        );
        this.clearCommonSearchParamsAndSearch();
    }

    statusChangeDateChanged(evt): void {
        this.statusChangeDateFrom = evt.startDate;
        this.statusChangeDateTo = evt.endDate;
        this.clearCommonSearchParamsAndSearch();
    }

    appointmentDateSelectionChanged(evt): void {
        this.appointmentStartDate = evt.startDate;
        this.appointmentEndDate = evt.endDate;
        this.clearCommonSearchParamsAndSearch();
    }

    createdByDateSelectionChanged(evt): void {
        this.createdDateEndDate = evt.endDate;
        this.createdDateStartDate = evt.startDate;
        this.clearCommonSearchParamsAndSearch();
    }

    followupDateSelectionChanged(evt): void {
        this.followupStartDate = evt.startDate;
        this.followupEndDate = evt.endDate;
        this.clearCommonSearchParamsAndSearch();
    }

    // Clear out common search params before getting next results, also resets page to 1  to avoid 0 results
    clearCommonSearchParamsAndSearch(): void {
        // this.commonSearchParams = null;
        // this.searchChanged = !this.searchChanged;
        this.currentPage = 1;
        this.getImedClaimServices();
    }

    getImedClaimServices(): void {
        this.getImedClaimServicesSubscription()
            .pipe(debounceTime(400))
            .subscribe((answer) => {
                this.imedClaimServices = answer.body;
                this.total = +answer.headers.get('X-List-Count');
            });
    }

    getImedClaimServicesSubscription(skipPaging?: boolean): Observable<HttpResponse<IViewGetServicesForList[]>> {
        const search = this.commonSearchParams && this.commonSearchParams.query ? this.commonSearchParams.query : this.query;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();
        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.commonSearchParams && this.commonSearchParams.order ? this.commonSearchParams.order : this.order,
            orderDirection:
                this.commonSearchParams && this.commonSearchParams.orderDirection ? this.commonSearchParams.orderDirection : this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: skipPaging ? 0 : (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: skipPaging ? 0 : entityListModuleConfig.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);

        // store current search params in case user wants to saves this search
        this.currentSearch = searchparams;

        return this.imedClaimServiceService.searchImedClaimServices(this.imedClaimId, searchparams);
    }

    search(query: string): void {
        this.query = query;
        this.clearCommonSearchParamsAndSearch();
    }

    columnSorted(event: IColumnSortedEvent): void {
        if (this.commonSearchParams) {
            this.commonSearchParams.order = event.column.sort.sortProperty;
            this.commonSearchParams.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        } else {
            this.order = event.column.sort.sortProperty;
            this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        }
        this.getImedClaimServices();
    }

    imedClaimServiceSelected(event: IItemSelectedEvent): void {
        void this.router.navigateByUrl('/cases/' + event.entity.ImedClaimId + '/services/' + event.entity.Id);
    }

    onItemDeleted(event: IItemDeletedEvent): void {
        const selectedService: IViewGetServicesForList = event.entity;
        this.imedClaimServiceService.archive(selectedService.Id, this.authService.currentUser.getValue().Id).subscribe(() => {
            this.notificationsService.success('Service List Updated Successfully');
            this.getImedClaimServices();
        });
    }

    close(): void {
        void this.router.navigateByUrl('/cases/' + this.imedClaimId);
    }
}
