import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IDocumentGenerationDTO } from '@model/interfaces/custom/document-generation-dto';
import { ICommunicationRequest } from '@model/interfaces/custom/communication-request';
import { IAttachmentListWithFaxNumberDTO } from '@model/interfaces/custom/attachment-list-with-fax-number';

@Injectable()
export class FaxEmailService {
    constructor(private http: HttpClient) {}

    sendEmail(email: ICommunicationRequest): any {
        return this.http.post('/emails/send', email);
    }

    sendEmailWithAttachments(email: ICommunicationRequest): any {
        return this.http.post('/emails/send-with-attachments', email);
    }

    resendEmail(communicationLogId: number, email: ICommunicationRequest): any {
        return this.http.post(`/emails/${communicationLogId}/resend`, email);
    }

    resendEmailWithoutAttachment(communicationLogId: number, email: ICommunicationRequest): any {
        return this.http.post(`/emails/${communicationLogId}/resend-without-attachments`, email);
    }

    sendFax(fax: IAttachmentListWithFaxNumberDTO): any {
        return this.http.post('/fax/send', fax);
    }

    resendFax(communicationLogId: number, faxNumber: string, fax: ICommunicationRequest): any {
        return this.http.post(`/fax/${communicationLogId}/resend/${faxNumber}`, fax);
    }

    generateDocuments(entityTypeName: string, docPayload: IDocumentGenerationDTO): Observable<any> {
        return this.http.post(`/${entityTypeName}/download`, docPayload, {
            observe: 'response',
            responseType: 'blob',
        });
    }

    upload(entityTypeName: string, docPayload: IDocumentGenerationDTO): Observable<any> {
        return this.http.post(`/${entityTypeName}/upload`, docPayload);
    }

    generateDocumentsAsDocX(entityTypeName: string, docPayload: IDocumentGenerationDTO): Observable<any> {
        return this.http.post(`/${entityTypeName}/download-as-docx`, docPayload, {
            observe: 'response',
            responseType: 'blob',
        });
    }

    faxDocuments(entityTypeName: string, docPayload: IDocumentGenerationDTO, emailDTO: ICommunicationRequest, faxNumber: string): any {
        const payload = {
            communicationRequest: emailDTO,
            documentGeneratorRequest: docPayload,
        };
        return this.http.post(`/${entityTypeName}/fax/${faxNumber}`, payload);
    }

    faxDocumentsWithAttachment(entityTypeName: string, faxDTO: ICommunicationRequest, faxNumber: string): any {
        const emailPayload = {
            communicationRequest: faxDTO,
            documentGeneratorRequest: null,
        };
        return this.http.post(`/${entityTypeName}/fax-with-attachment/${faxNumber}`, emailPayload);
    }

    emailDocuments(entityTypeName: string, docPayload: IDocumentGenerationDTO, emailDTO: ICommunicationRequest): any {
        const emailPayload = {
            communicationRequest: emailDTO,
            documentGeneratorRequest: docPayload,
        };
        return this.http.post(`/${entityTypeName}/email`, emailPayload);
    }
    emailDocumentsWithConditionalAttachments(entityTypeName: string, docPayload: IDocumentGenerationDTO, emailDTO: ICommunicationRequest): any {
        const emailPayload = {
            communicationRequest: emailDTO,
            documentGeneratorRequest: docPayload,
        };
        return this.http.post(`/${entityTypeName}/email-with-conditional-attachment`, emailPayload);
    }
    sendEmailWithAttachment(entityTypeName: string, emailDTO: ICommunicationRequest): any {
        const emailPayload = {
            communicationRequest: emailDTO,
            documentGeneratorRequest: null,
        };
        return this.http.post(`/${entityTypeName}/send-email-with-attachment`, emailPayload);
    }
}
