import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IImedClaimFollowup } from '../interfaces/imed-claim-followup';
import { IUser } from '../interfaces/user';
import { IImedClaim } from '../interfaces/imed-claim';
import { IMasterFollowupType } from '../interfaces/master-followup-type';

export interface IImedClaimFollowupDynamicControlsParameters {
    formGroup?: string;
    imedClaims?: IImedClaim[];
    masterFollowupTypes?: IMasterFollowupType[];
    createdBies?: IUser[];
    modifiedBies?: IUser[];
}

export class ImedClaimFollowupDynamicControls {

    formGroup: string;
    imedClaims: IImedClaim[];
    masterFollowupTypes: IMasterFollowupType[];
    createdBies: IUser[];
    modifiedBies: IUser[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private imedclaimfollowup?: IImedClaimFollowup, additionalParameters?: IImedClaimFollowupDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'ImedClaimFollowup';
        this.imedClaims = additionalParameters && additionalParameters.imedClaims || undefined;
        this.masterFollowupTypes = additionalParameters && additionalParameters.masterFollowupTypes || undefined;
        this.createdBies = additionalParameters && additionalParameters.createdBies || undefined;
        this.modifiedBies = additionalParameters && additionalParameters.modifiedBies || undefined;

        this.Form = {
            Amount: new DynamicField({
                formGroup: this.formGroup,
                label: 'Amount',
                name: 'Amount',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimfollowup && this.imedclaimfollowup.Amount || null,
            }),
            Archived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived',
                name: 'Archived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimfollowup && this.imedclaimfollowup.hasOwnProperty('Archived') && this.imedclaimfollowup.Archived !== null ? this.imedclaimfollowup.Archived : false,
            }),
            CheckNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Check Number',
                name: 'CheckNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.imedclaimfollowup && this.imedclaimfollowup.hasOwnProperty('CheckNumber') && this.imedclaimfollowup.CheckNumber !== null ? this.imedclaimfollowup.CheckNumber.toString() : '',
            }),
            ClearanceDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Clearance Date',
                name: 'ClearanceDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimfollowup && this.imedclaimfollowup.ClearanceDate || null,
            }),
            CommunicationMethod: new DynamicField({
                formGroup: this.formGroup,
                label: 'Communication Method',
                name: 'CommunicationMethod',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimfollowup && this.imedclaimfollowup.CommunicationMethod || null,
            }),
            ConfirmedCancellationWithPhysiciansOfficeSpokeWith: new DynamicField({
                formGroup: this.formGroup,
                label: 'Confirmed Cancellation With Physicians Office Spoke With',
                name: 'ConfirmedCancellationWithPhysiciansOfficeSpokeWith',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(75) ],
                validators: { 'maxlength': 75 },
                value: this.imedclaimfollowup && this.imedclaimfollowup.hasOwnProperty('ConfirmedCancellationWithPhysiciansOfficeSpokeWith') && this.imedclaimfollowup.ConfirmedCancellationWithPhysiciansOfficeSpokeWith !== null ? this.imedclaimfollowup.ConfirmedCancellationWithPhysiciansOfficeSpokeWith.toString() : '',
            }),
            ConfirmedWithPhysiciansOfficeSpokeWith: new DynamicField({
                formGroup: this.formGroup,
                label: 'Confirmed With Physicians Office Spoke With',
                name: 'ConfirmedWithPhysiciansOfficeSpokeWith',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(75) ],
                validators: { 'maxlength': 75 },
                value: this.imedclaimfollowup && this.imedclaimfollowup.hasOwnProperty('ConfirmedWithPhysiciansOfficeSpokeWith') && this.imedclaimfollowup.ConfirmedWithPhysiciansOfficeSpokeWith !== null ? this.imedclaimfollowup.ConfirmedWithPhysiciansOfficeSpokeWith.toString() : '',
            }),
            CreatedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Created By',
                name: 'CreatedById',
                options: this.createdBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.imedclaimfollowup && this.imedclaimfollowup.CreatedById || null,
            }),
            Date: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date',
                name: 'Date',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.imedclaimfollowup && this.imedclaimfollowup.Date || null,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimfollowup && this.imedclaimfollowup.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimfollowup && this.imedclaimfollowup.DateModified || null,
            }),
            DeferredUntil: new DynamicField({
                formGroup: this.formGroup,
                label: 'Deferred Until',
                name: 'DeferredUntil',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimfollowup && this.imedclaimfollowup.DeferredUntil || null,
            }),
            ImeAppointmentConfirmedWithPlaintiffsAttorneyOfficeByName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Ime Appointment Confirmed With Plaintiffs Attorney Office By Name',
                name: 'ImeAppointmentConfirmedWithPlaintiffsAttorneyOfficeByName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.imedclaimfollowup && this.imedclaimfollowup.hasOwnProperty('ImeAppointmentConfirmedWithPlaintiffsAttorneyOfficeByName') && this.imedclaimfollowup.ImeAppointmentConfirmedWithPlaintiffsAttorneyOfficeByName !== null ? this.imedclaimfollowup.ImeAppointmentConfirmedWithPlaintiffsAttorneyOfficeByName.toString() : '',
            }),
            ImedClaimId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Imed Claim',
                name: 'ImedClaimId',
                options: this.imedClaims,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.imedclaimfollowup && this.imedclaimfollowup.ImedClaimId || null,
            }),
            LegacyFollowUpId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Legacy Follow Up',
                name: 'LegacyFollowUpId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(25) ],
                validators: { 'maxlength': 25 },
                value: this.imedclaimfollowup && this.imedclaimfollowup.hasOwnProperty('LegacyFollowUpId') && this.imedclaimfollowup.LegacyFollowUpId !== null ? this.imedclaimfollowup.LegacyFollowUpId.toString() : '',
            }),
            MasterFollowupTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Master Followup Type',
                name: 'MasterFollowupTypeId',
                options: this.masterFollowupTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.imedclaimfollowup && this.imedclaimfollowup.MasterFollowupTypeId || null,
            }),
            ModifiedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Modified By',
                name: 'ModifiedById',
                options: this.modifiedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimfollowup && this.imedclaimfollowup.ModifiedById || null,
            }),
            Notes: new DynamicField({
                formGroup: this.formGroup,
                label: 'Notes',
                name: 'Notes',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimfollowup && this.imedclaimfollowup.hasOwnProperty('Notes') && this.imedclaimfollowup.Notes !== null ? this.imedclaimfollowup.Notes.toString() : '',
            }),
            PersonContactedOrSpokenTo: new DynamicField({
                formGroup: this.formGroup,
                label: 'Person Contacted Or Spoken To',
                name: 'PersonContactedOrSpokenTo',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.imedclaimfollowup && this.imedclaimfollowup.hasOwnProperty('PersonContactedOrSpokenTo') && this.imedclaimfollowup.PersonContactedOrSpokenTo !== null ? this.imedclaimfollowup.PersonContactedOrSpokenTo.toString() : '',
            }),
            ReceiptOfNoticeOfImeConfirmedByName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Receipt Of Notice Of Ime Confirmed By Name',
                name: 'ReceiptOfNoticeOfImeConfirmedByName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.imedclaimfollowup && this.imedclaimfollowup.hasOwnProperty('ReceiptOfNoticeOfImeConfirmedByName') && this.imedclaimfollowup.ReceiptOfNoticeOfImeConfirmedByName !== null ? this.imedclaimfollowup.ReceiptOfNoticeOfImeConfirmedByName.toString() : '',
            }),
            ReceivedAppointmentDateTime: new DynamicField({
                formGroup: this.formGroup,
                label: 'Received Appointment Date Time',
                name: 'ReceivedAppointmentDateTime',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimfollowup && this.imedclaimfollowup.ReceivedAppointmentDateTime || null,
            }),
            ReportDueBy: new DynamicField({
                formGroup: this.formGroup,
                label: 'Report Due By',
                name: 'ReportDueBy',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimfollowup && this.imedclaimfollowup.ReportDueBy || null,
            }),
            ReportForwardedToClientDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Report Forwarded To Client Date',
                name: 'ReportForwardedToClientDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimfollowup && this.imedclaimfollowup.ReportForwardedToClientDate || null,
            }),
            ReportReadyOnOrAround: new DynamicField({
                formGroup: this.formGroup,
                label: 'Report Ready On Or Around',
                name: 'ReportReadyOnOrAround',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimfollowup && this.imedclaimfollowup.ReportReadyOnOrAround || null,
            }),
            TrackingNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Tracking Number',
                name: 'TrackingNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.imedclaimfollowup && this.imedclaimfollowup.hasOwnProperty('TrackingNumber') && this.imedclaimfollowup.TrackingNumber !== null ? this.imedclaimfollowup.TrackingNumber.toString() : '',
            }),
            Via: new DynamicField({
                formGroup: this.formGroup,
                label: 'Via',
                name: 'Via',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimfollowup && this.imedclaimfollowup.Via || null,
            }),
        };

        this.View = {
            Amount: new DynamicLabel({
			    label: 'Amount',
			    value: this.imedclaimfollowup && this.imedclaimfollowup.Amount || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: null,
			        scale: 2,
			    })
			}
            ),
            Archived: new DynamicLabel({
			    label: 'Archived',
			    value: this.imedclaimfollowup && this.imedclaimfollowup.hasOwnProperty('Archived') && this.imedclaimfollowup.Archived !== null ? this.imedclaimfollowup.Archived : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            CheckNumber: new DynamicLabel({
			    label: 'Check Number',
			    value: this.imedclaimfollowup && this.imedclaimfollowup.hasOwnProperty('CheckNumber') && this.imedclaimfollowup.CheckNumber !== null ? this.imedclaimfollowup.CheckNumber.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ClearanceDate: new DynamicLabel({
			    label: 'Clearance Date',
			    value: this.imedclaimfollowup && this.imedclaimfollowup.ClearanceDate || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            CommunicationMethod: new DynamicLabel({
			    label: 'Communication Method',
			    value: this.imedclaimfollowup && this.imedclaimfollowup.CommunicationMethod || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: NumericInputTypes.Integer,
			        scale: null,
			    })
			}
            ),
            ConfirmedCancellationWithPhysiciansOfficeSpokeWith: new DynamicLabel({
			    label: 'Confirmed Cancellation With Physicians Office Spoke With',
			    value: this.imedclaimfollowup && this.imedclaimfollowup.hasOwnProperty('ConfirmedCancellationWithPhysiciansOfficeSpokeWith') && this.imedclaimfollowup.ConfirmedCancellationWithPhysiciansOfficeSpokeWith !== null ? this.imedclaimfollowup.ConfirmedCancellationWithPhysiciansOfficeSpokeWith.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ConfirmedWithPhysiciansOfficeSpokeWith: new DynamicLabel({
			    label: 'Confirmed With Physicians Office Spoke With',
			    value: this.imedclaimfollowup && this.imedclaimfollowup.hasOwnProperty('ConfirmedWithPhysiciansOfficeSpokeWith') && this.imedclaimfollowup.ConfirmedWithPhysiciansOfficeSpokeWith !== null ? this.imedclaimfollowup.ConfirmedWithPhysiciansOfficeSpokeWith.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            CreatedById: new DynamicLabel({
			    label: 'Created By',
			    value: getMetaItemValue(this.createdBies as unknown as IMetaItem[], this.imedclaimfollowup && this.imedclaimfollowup.hasOwnProperty('CreatedById') && this.imedclaimfollowup.CreatedById !== null ? this.imedclaimfollowup.CreatedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Date: new DynamicLabel({
			    label: 'Date',
			    value: this.imedclaimfollowup && this.imedclaimfollowup.Date || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DateCreated: new DynamicLabel({
			    label: 'Date Created',
			    value: this.imedclaimfollowup && this.imedclaimfollowup.DateCreated || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DateModified: new DynamicLabel({
			    label: 'Date Modified',
			    value: this.imedclaimfollowup && this.imedclaimfollowup.DateModified || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DeferredUntil: new DynamicLabel({
			    label: 'Deferred Until',
			    value: this.imedclaimfollowup && this.imedclaimfollowup.DeferredUntil || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            ImeAppointmentConfirmedWithPlaintiffsAttorneyOfficeByName: new DynamicLabel({
			    label: 'Ime Appointment Confirmed With Plaintiffs Attorney Office By Name',
			    value: this.imedclaimfollowup && this.imedclaimfollowup.hasOwnProperty('ImeAppointmentConfirmedWithPlaintiffsAttorneyOfficeByName') && this.imedclaimfollowup.ImeAppointmentConfirmedWithPlaintiffsAttorneyOfficeByName !== null ? this.imedclaimfollowup.ImeAppointmentConfirmedWithPlaintiffsAttorneyOfficeByName.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ImedClaimId: new DynamicLabel({
			    label: 'Imed Claim',
			    value: getMetaItemValue(this.imedClaims as unknown as IMetaItem[], this.imedclaimfollowup && this.imedclaimfollowup.hasOwnProperty('ImedClaimId') && this.imedclaimfollowup.ImedClaimId !== null ? this.imedclaimfollowup.ImedClaimId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            LegacyFollowUpId: new DynamicLabel({
			    label: 'Legacy Follow Up',
			    value: this.imedclaimfollowup && this.imedclaimfollowup.hasOwnProperty('LegacyFollowUpId') && this.imedclaimfollowup.LegacyFollowUpId !== null ? this.imedclaimfollowup.LegacyFollowUpId.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            MasterFollowupTypeId: new DynamicLabel({
			    label: 'Master Followup Type',
			    value: getMetaItemValue(this.masterFollowupTypes as unknown as IMetaItem[], this.imedclaimfollowup && this.imedclaimfollowup.hasOwnProperty('MasterFollowupTypeId') && this.imedclaimfollowup.MasterFollowupTypeId !== null ? this.imedclaimfollowup.MasterFollowupTypeId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ModifiedById: new DynamicLabel({
			    label: 'Modified By',
			    value: getMetaItemValue(this.modifiedBies as unknown as IMetaItem[], this.imedclaimfollowup && this.imedclaimfollowup.hasOwnProperty('ModifiedById') && this.imedclaimfollowup.ModifiedById !== null ? this.imedclaimfollowup.ModifiedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Notes: new DynamicLabel({
			    label: 'Notes',
			    value: this.imedclaimfollowup && this.imedclaimfollowup.hasOwnProperty('Notes') && this.imedclaimfollowup.Notes !== null ? this.imedclaimfollowup.Notes.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Textarea,
			        scale: null,
			    })
			}
            ),
            PersonContactedOrSpokenTo: new DynamicLabel({
			    label: 'Person Contacted Or Spoken To',
			    value: this.imedclaimfollowup && this.imedclaimfollowup.hasOwnProperty('PersonContactedOrSpokenTo') && this.imedclaimfollowup.PersonContactedOrSpokenTo !== null ? this.imedclaimfollowup.PersonContactedOrSpokenTo.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ReceiptOfNoticeOfImeConfirmedByName: new DynamicLabel({
			    label: 'Receipt Of Notice Of Ime Confirmed By Name',
			    value: this.imedclaimfollowup && this.imedclaimfollowup.hasOwnProperty('ReceiptOfNoticeOfImeConfirmedByName') && this.imedclaimfollowup.ReceiptOfNoticeOfImeConfirmedByName !== null ? this.imedclaimfollowup.ReceiptOfNoticeOfImeConfirmedByName.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ReceivedAppointmentDateTime: new DynamicLabel({
			    label: 'Received Appointment Date Time',
			    value: this.imedclaimfollowup && this.imedclaimfollowup.ReceivedAppointmentDateTime || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            ReportDueBy: new DynamicLabel({
			    label: 'Report Due By',
			    value: this.imedclaimfollowup && this.imedclaimfollowup.ReportDueBy || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            ReportForwardedToClientDate: new DynamicLabel({
			    label: 'Report Forwarded To Client Date',
			    value: this.imedclaimfollowup && this.imedclaimfollowup.ReportForwardedToClientDate || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            ReportReadyOnOrAround: new DynamicLabel({
			    label: 'Report Ready On Or Around',
			    value: this.imedclaimfollowup && this.imedclaimfollowup.ReportReadyOnOrAround || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            TrackingNumber: new DynamicLabel({
			    label: 'Tracking Number',
			    value: this.imedclaimfollowup && this.imedclaimfollowup.hasOwnProperty('TrackingNumber') && this.imedclaimfollowup.TrackingNumber !== null ? this.imedclaimfollowup.TrackingNumber.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Via: new DynamicLabel({
			    label: 'Via',
			    value: this.imedclaimfollowup && this.imedclaimfollowup.Via || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: NumericInputTypes.Integer,
			        scale: null,
			    })
			}
            ),
        };

    }
}
