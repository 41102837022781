import { DateTimeConverterService } from './../../../../../common/services/date-time-converter.service';
import { ITestimonyDepositionServiceDate } from '@model/interfaces/testimony-deposition-service-date.d';
import { ISoftPopUpValidationFunctionResult, SoftPopUpResults, ISoftPopUp } from '@model/interfaces/custom/soft-popup';
import { TestimonyDepositionServiceService } from './../testimony-deposition-service.service';
import { IServicePayload } from '@model/interfaces/custom/update-service-payload.d';
import { TestimonyDepositionServiceDynamicControlsPartial } from '@model/partials/testimony-deposition-service.form-controls.partials';
import { IUser } from '@model/interfaces/user.d';
import { ITestimonyDepositionService } from '@model/interfaces/testimony-deposition-service.d';
import { AuthService, ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IImedClaimServiceContactInfo } from './../../../../imed-claim-basic-info/imed-claim-contacts-info';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormArray, FormGroup } from '@angular/forms';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { ImedClaimServiceService } from '../../../imedclaimservice.service';
import { IImedClaimService } from '@model/interfaces/imed-claim-service';
import { TestimonyDepositionServiceDynamicConfig } from '../testimony-deposition-service.dynamic-config';
import { UserService } from '../../../../../users/user.service';
import { SoftPopUpValidationFunction } from '../../../../../common/directives/click-popup-directive';
import { finalize } from 'rxjs/operators';
import { ClaimTypes } from '@model/ClaimTypes';
import { CurrencyPipe, DatePipe } from '@angular/common';

@Component({
    selector: 'app-testimony-deposition-service-basic-info',
    templateUrl: './testimony-deposition-service-basic-info.component.html',
})
export class TestimonyDepositionServiceBasicInfoComponent implements OnInit {
    @Input() iImedClaimServiceModel: IImedClaimService;
    @Input() testimonyDepositionServiceModel: ITestimonyDepositionService;
    @Input() canEdit: boolean;
    @Input() reportDueBy: string;
    @Input() iImedClaimServiceContactInfo: IImedClaimServiceContactInfo;
    @Output('physicianFaxSent') physicianFaxSent: EventEmitter<any> = new EventEmitter<any>();

    isEditing: boolean;
    isHovered: boolean;
    formRendered = false;

    testimonyDepositionFirstFormConfig: any = {};
    testimonyDepositionSecondFormConfig: any = {};

    testimonyDepositionServiceForm: FormGroup;
    testimonyDepositionFirstFormFactory: TestimonyDepositionServiceDynamicConfig<ITestimonyDepositionService>;
    testimonyDepositionSecondFormFactory: TestimonyDepositionServiceDynamicConfig<ITestimonyDepositionService>;
    testimonyDepositionControls: any;

    doubleClickIsDisabled = false;
    confirmationReceived = false;

    datesRequested = '';

    users = [];
    selectedUser: IUser;
    softPopUpErrorMessages: SoftPopUpValidationFunction;
    canSeeAuditLog = false;
    initialImedClaimServiceContactInfo: IImedClaimServiceContactInfo;

    constructor(
        private imedClaimServiceService: ImedClaimServiceService,
        private notificationsService: NotificationsService,
        private fb: UntypedFormBuilder,
        private authService: AuthService,
        private testimonyDepositionService: TestimonyDepositionServiceService,
        private userservice: UserService,
        private dateTimeConverterService: DateTimeConverterService,
        private claimsService: ClaimsService,
        protected currencyPipe: CurrencyPipe,
        private datePipe: DatePipe,
    ) {}

    ngOnInit(): void {
        this.canSeeAuditLog = this.claimsService.hasClaim(ClaimTypes.Audit, [ClaimValues.FullAccess, ClaimValues.ReadOnly]);

        this.isEditing = false;
        this.userservice.getAll().subscribe((users) => {
            this.users = users;
            this.setConfig();
        });

        this.softPopUpErrorMessages = this.testimonyDepositionValdiationFunction.bind(this);
    }

    ngOnChanges(changes: any): void {
        if (changes.iImedClaimServiceContactInfo) {
            this.initialImedClaimServiceContactInfo = { ...this.iImedClaimServiceContactInfo };
        }
    }

    setConfig(): void {
        this.confirmationReceived = this.testimonyDepositionServiceModel.ConfirmationReceived;

        if (this.testimonyDepositionServiceModel.ConfirmedById > 0) {
            this.selectedUser = this.users.filter((x) => x.Id === this.testimonyDepositionServiceModel.ConfirmedById)[0];
        } else {
            this.selectedUser = this.users.filter((x) => x.Id === this.authService.currentUser.getValue().Id)[0];
        }

        this.testimonyDepositionControls = new TestimonyDepositionServiceDynamicControlsPartial(
            this.currencyPipe,
            this.testimonyDepositionServiceModel,
        );
        this.testimonyDepositionServiceForm = this.fb.group({
            TestimonyDepositionService: this.fb.group({}),
            TestimonyDepositionServiceDatesRequested: this.fb.array([]),
        });

        this.testimonyDepositionFirstFormConfig = { formObject: [], viewOnly: [] };
        this.testimonyDepositionSecondFormConfig = { formObject: [], viewOnly: [] };

        this.testimonyDepositionFirstFormFactory = new TestimonyDepositionServiceDynamicConfig<ITestimonyDepositionService>(
            this.currencyPipe,
            this.testimonyDepositionServiceModel,
            ['DateConfirmed', 'PartyThatConfirmed'],
        );

        this.testimonyDepositionSecondFormFactory = new TestimonyDepositionServiceDynamicConfig<ITestimonyDepositionService>(
            this.currencyPipe,
            this.testimonyDepositionServiceModel,
            ['PhysicianTestimonyFee', 'PhysicianTestimonyDueDate'],
        );

        this.testimonyDepositionFirstFormConfig = this.testimonyDepositionFirstFormFactory.getForUpdate();
        this.testimonyDepositionSecondFormConfig = this.testimonyDepositionSecondFormFactory.getForUpdate();

        const allDatesRequested = this.testimonyDepositionServiceModel.TestimonyDepositionServiceDates.map((x) =>
            this.datePipe.transform(x.DateRequested, 'MMMM d, yyyy', 'UTC'),
        );
        this.datesRequested = allDatesRequested.join(', ');
        this.formRendered = true;
    }

    setRequiredForConfirmationFields(event: boolean): void {
        this.confirmationReceived = event;
        this.testimonyDepositionServiceForm.get('TestimonyDepositionService.DateConfirmed').mtSetRequired(event);
        this.testimonyDepositionServiceForm.get('TestimonyDepositionService.PartyThatConfirmed').mtSetRequired(event);
        this.testimonyDepositionServiceForm.get('TestimonyDepositionService.PhysicianTestimonyFee').mtSetRequired(event);
        this.testimonyDepositionServiceForm.get('TestimonyDepositionService.PhysicianTestimonyDueDate').mtSetRequired(event);
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        this.isEditing = false;
        this.iImedClaimServiceContactInfo = { ...this.initialImedClaimServiceContactInfo };
    }

    addDateRequested(updatePayload: IServicePayload<ITestimonyDepositionService>): void {
        const datesRequested = <UntypedFormArray>this.testimonyDepositionServiceForm.controls.TestimonyDepositionServiceDatesRequested;
        updatePayload.ChildService.TestimonyDepositionServiceDates = [];

        datesRequested.controls.forEach((dateRequested) => {
            const tempDateRequested = <ITestimonyDepositionServiceDate>{};

            if (!dateRequested.value.Id) {
                if (dateRequested.value.DateRequested) {
                    tempDateRequested.Id = 0;
                    tempDateRequested.TestimonyDepositionServiceId = this.testimonyDepositionServiceModel.Id;
                    tempDateRequested.DateRequested = dateRequested.value.DateRequested;
                    updatePayload.ChildService.TestimonyDepositionServiceDates.push(tempDateRequested);
                }
            } else {
                if (dateRequested.value.DateRequested) {
                    updatePayload.ChildService.TestimonyDepositionServiceDates.push(dateRequested.value as ITestimonyDepositionServiceDate);
                }
            }
        });
    }

    testimonyDepositionValdiationFunction(): ISoftPopUpValidationFunctionResult {
        // first check the form for valid, and if not handle error display and return failed result
        if (!this.testimonyDepositionServiceForm.valid || (this.confirmationReceived && this.selectedUser.Id === 0)) {
            markAllFormFieldsAsTouched(this.testimonyDepositionServiceForm);
            this.error();
            return { result: SoftPopUpResults.Failed };
        }

        this.testimonyDepositionFirstFormFactory.assignFormValues(
            this.testimonyDepositionServiceModel,
            this.testimonyDepositionServiceForm.value.TestimonyDepositionService as ITestimonyDepositionService,
        );
        this.testimonyDepositionServiceModel.ConfirmedById = this.selectedUser.Id;

        const popup: ISoftPopUp = { Messages: [] };

        if (
            !(this.testimonyDepositionServiceModel.ConfirmedById > 0) ||
            !(this.testimonyDepositionServiceModel.PartyThatConfirmed !== '') ||
            !(this.testimonyDepositionServiceModel.DateConfirmed !== null)
        ) {
            popup.Messages.push('Accounting will not release payment unless confirmation has been received and entered');
        }

        if (popup.Messages.length) {
            return { result: SoftPopUpResults.Failed, popup: popup };
        } else {
            return { result: SoftPopUpResults.Passed };
        }
    }

    formSubmitted(): void {
        const invalidServiceAddress = this.iImedClaimServiceContactInfo.PhysicianId > 0 && !this.iImedClaimServiceContactInfo.AddressId;

        if (invalidServiceAddress) {
            setTimeout(() => (this.doubleClickIsDisabled = false));
            return;
        }

        this.iImedClaimServiceModel = this.imedClaimServiceService.assignContactsToImedClaimService(
            this.iImedClaimServiceModel,
            this.iImedClaimServiceContactInfo,
        );
        this.testimonyDepositionFirstFormFactory.assignFormValues(
            this.testimonyDepositionServiceModel,
            this.testimonyDepositionServiceForm.value.TestimonyDepositionService as ITestimonyDepositionService,
        );
        this.testimonyDepositionServiceModel.ConfirmedById = this.selectedUser.Id > 0 ? this.selectedUser.Id : null;

        const updatePayload = <IServicePayload<ITestimonyDepositionService>>{};
        updatePayload.ChildService = this.testimonyDepositionServiceModel;
        updatePayload.ParentService = this.iImedClaimServiceModel;
        this.addDateRequested(updatePayload);

        this.testimonyDepositionService
            .updateService(updatePayload)            .subscribe(() => {
                this.imedClaimServiceService.getById(this.iImedClaimServiceModel.Id).subscribe((response) => {
                    this.isEditing = false;
                    this.success();
                    this.iImedClaimServiceModel = response;
                    this.imedClaimServiceService.emitChange(this.iImedClaimServiceModel);
                    this.testimonyDepositionService.emitChange(this.testimonyDepositionServiceModel);
                    this.setConfig();
                });
            });
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }
}
