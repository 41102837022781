// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            .btn-clear {
                position: absolute;
                top: 51px;
                right: 25px;
            }
            .invalid {
                border: 1px solid red;
            }
        `, "",{"version":3,"sources":["webpack://./src/app/common/ck-editor-with-merge-fields/ck-editor-with-merge-fields.component.ts"],"names":[],"mappings":";YACY;gBACI,kBAAkB;gBAClB,SAAS;gBACT,WAAW;YACf;YACA;gBACI,qBAAqB;YACzB","sourcesContent":["\n            .btn-clear {\n                position: absolute;\n                top: 51px;\n                right: 25px;\n            }\n            .invalid {\n                border: 1px solid red;\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
