import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IAutomatedTemplate } from '@model/interfaces/automated-template';
import { Observable } from 'rxjs';

export const emptyAutomatedTemplate: IAutomatedTemplate = {
    Archived: false,
    Auto: null,
    DateCreated: null,
    GeneralLiability: null,
    Id: 0,
    Name: null,
    OverrideMargins: false,
    WorkersComp: null,
};

@Injectable({
    providedIn: 'root',
})
export class AutomatedTemplateService extends BaseService<IAutomatedTemplate> {
    constructor(public http: HttpClient) {
        super('/automated-templates', http, null, {
            entityName: 'Dynamic Template',
        });
    }

    formatTitleText(automatedTemplate: IAutomatedTemplate): void {
        this.setTitle(`Dynamic Template: ${automatedTemplate.Name}`);
    }

    getEmptyAutomatedTemplate(): IAutomatedTemplate {
        return { ...emptyAutomatedTemplate };
    }

    getByImedClaimId(imedClaimTypeId: number, imedClaimId: number): Observable<IAutomatedTemplate[]> {
        return this.http.get<IAutomatedTemplate[]>(`/automated-templates/imedClaim/${imedClaimTypeId}/${imedClaimId}`);
    }

    getByImedClaimServiceId(imedClaimServiceId: number): Observable<IAutomatedTemplate[]> {
        return this.http.get<IAutomatedTemplate[]>(`/automated-templates/imedClaim/${imedClaimServiceId}/`);
    }

    getByIndividualId(individualId: number): Observable<IAutomatedTemplate[]> {
        return this.http.get<IAutomatedTemplate[]>(`/automated-templates/individual/${individualId}`);
    }

    getByAddressBookId(addressBookId: number): Observable<IAutomatedTemplate[]> {
        return this.http.get<IAutomatedTemplate[]>(`/automated-templates/addressbook/${addressBookId}`);
    }

    saveTemplate(templateId: number, template: string, overrideMargins: boolean): Observable<any> {
        return this.http.post<any>(`/automated-templates/${templateId}`, { Template: template, OverrideMargins: overrideMargins });
    }

    duplicateTemplate(templateId: number): Observable<number> {
        return this.http.post<number>(`/automated-templates/duplicate/${templateId}`, null);
    }

    downloadTemplate(templateId: number): Observable<any> {
        return this.http.post(`/automated-templates/download/${templateId}`, null, {
            observe: 'response',
            responseType: 'blob',
        });
    }
}
