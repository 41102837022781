import { FaxEmailWrapperComponent } from './fax-email/fax-email-wrapper-component';
import { DateTimeConverterService } from './services/date-time-converter.service';
import { AddressFormatterService } from './services/address-formatter.service';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { FileUploadModule } from 'ng2-file-upload';
import { DynamicFormModule, DynamicFormModuleConfigToken } from '@mt-ng2/dynamic-form';
import { MtNoteControlModule } from '@mt-ng2/note-control';
import { EntityComponentsNotesModule } from '@mt-ng2/entity-components-notes';
import { EntityComponentsAddressesModule } from '@mt-ng2/entity-components-addresses';
import { MtDocumentControlModule } from '@mt-ng2/document-control';
import { EntityComponentsDocumentsModule } from '@mt-ng2/entity-components-documents';
import { EntityComponentsPhonesModule } from '@mt-ng2/entity-components-phones';
import { MtSearchFilterSelectModule } from '@mt-ng2/search-filter-select-control';
import { MtSearchFilterDaterangeModule, MtSearchFilterDateRangeModuleConfigToken } from '@mt-ng2/search-filter-daterange-control';
import { MtSearchFilterCheckboxModule } from '@mt-ng2/search-filter-checkbox-control';
import { MtDisableDuringHttpCallsModule  } from '@mt-ng2/disable-during-http-calls';
import { MtPhotoControlModule } from '@mt-ng2/photo-control';
import { EntityListModule, IEntityListModuleConfig, EntityListModuleConfigToken } from '@mt-ng2/entity-list-module';
import { SharedEntitiesModule } from '@mt-ng2/shared-entities-module';
import { MtManagedListControlModule } from '@mt-ng2/managed-list-control';
import { MtSearchBarControlModule } from '@mt-ng2/searchbar-control';
import { BackButtonModule } from '@mt-ng2/back-button-module';
import { CommonService } from './services/common.service';
import { AuthEntityModule } from '../auth-entity/auth-entity.module';
import { AuthEntityService } from '../auth-entity/auth-entity.service';
import { ContactStatusService } from '@model/shared-entities/contacts/contact-status.service';
import { AddressBookEmailService } from '../address-books/shared/addressbook-email.service';
import { EmailTypeService } from '@model/shared-entities/email-type/emailtype.service';
import { IndividualEmailService } from '../address-books/individuals/shared/individual-email.service';
import { AttachmentTypeService } from './attachments/attachment-type.service';

import { MultiSelectCheckboxesComponent } from './multi-select-checkboxes/multi-select-checkboxes.component';
import { IMedClaimAddressService } from '../imed-claims/shared/imed-claim-address.service';
import { MultiselectControlModule } from '@mt-ng2/multiselect-control';
import { ClickPopUpDirective } from './directives/click-popup-directive';
import { TypeAheadModule } from '@mt-ng2/type-ahead-control';
import { SelectPopUpDirective } from './directives/select-popup-directive';
import { AttachmentControlComponent } from './attachment-control/attachment-control.component';
import { CommonAttachmentsComponent } from './attachments/common-attachments.component';
import { BaseAttachmentsService } from './attachments/base-attachments.service';
import { FaxControlComponent } from './fax-control/fax-control.component';
import { FaxService } from './fax-control/fax.service';
import { MtPhoneControlModule } from '@mt-ng2/phone-control';
import { FaxEmailComponent } from './fax-email/fax-email-component';
import { FaxEmailService } from './fax-email/fax-email.service';
import { FaxEmailAppointmentWrapperComponent } from './fax-email/fax-email-appointment-wrapper-component';
import { FaxEmailServicesWrapperComponent } from './fax-email/fax-email-services-wrapper.component';
import { SavedSearchFiltersComponent } from './saved-search-filters/saved-search-filters.component';
import { PopUpService } from './services/popup-service';
import { ViewLogService } from './services/viewlog.service';
import { AuditLogEagerLoadedRoutingModule, AuditLogModule, AuditLoggingModuleConfigToken, AuditLoggingModuleUserServiceToken } from '@mt-ng2/audit-logging-module';
import { auditLogModuleConfig } from './configs/audit-log.config';
import { AuditLogUserService } from './services/audit-log-user.service';
import { ConcurrentUsersSocketService } from './hub/user-on-service-socket.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HistoricalNotesComponent } from './historical-notes/historical-notes.component';
import { AuditLogRedirectComponent } from './audit-log-redirect/audit-log-redirect.component';
import { PhoneNumberService } from './services/phonenumber.service';
import { EmailService } from './services/email-service';
import { PhysicianSpecialityDetailService } from './services/physician-speciality-detail-service';
import { PhysicianSpecialityService } from './services/physician-speciality-service';
import { CkEditorDynamicFieldComponent } from './ckeditor-dynamic-field/ckeditor-dynamic-field.component';
import { dynamicFormModuleConfig } from './configs/dynamic-form-override-config';
import { IndividualPhoneService } from '../address-books/individuals/shared/individual-phone.service';
import { AddressBookPhoneNumberService } from '../address-books/shared/addressbook-phone.service';
import { UtcDate } from './utc-date-pipe/utc-date.pipe';
import { TooltipDirective } from './tooltip-directive/tooltip.directive';
import { OverlayModule } from '@angular/cdk/overlay';
import { AddressesTooltipComponent } from './tooltip-directive/addresses-tooltip.component';
import { MtSearchFilterDaterangeComponentOverride } from './mt-search-filter-daterange-override/mt-search-filter-daterange-override.component';
import { AddressBookInfoTooltipComponent } from './tooltip-directive/addressbook-info-tooltip.component';
import { TooltipService } from './tooltip-directive/tooltip.service';
import { MultiselectTableControlComponent } from './multiselect-table-control/multiselect-table-control.component';
import { CkEditorWithMergeFieldsComponent } from './ck-editor-with-merge-fields/ck-editor-with-merge-fields.component';
import { MergeFieldService } from './services/mergefield.service';
import { ImedClaimBasicInfoComponent } from '../imed-claims/imed-claim-basic-info/imed-claim-basic-info.component';
import { ImedClaimAddressBasicInfoComponent } from '../imed-claims/address/address-basic-info/claim-address-basic-info.component';
import { SelectContactFaxes } from './select-contact-faxes/select-contact-faxes.component';
import { CommonEmailComponent } from './common-email/common-email.component';
import { SelectContactsEmail } from './select-contacts-email/select-contacts-email.component';
import { UploadAnimationComponent } from './upload-animation/upload-animation.component';
import { RedirectComponent } from './redirect.component';
import { LogoutLogService } from './services/logout-log.service';
import { LoginLogService } from './services/login-log.service';
import { CommunicationAttachmentControlComponent } from './communication-attachment-control/communication-attachment-control.component';
import { NullHostDirective } from './directives/null-host.directive';
import { CommonFaxComponent } from './common-fax/common-fax.component';
import { FilmInformationService } from '@app-shared/services/film-information.service';
import { ImedClaimServiceFilmInformationList } from './film-information/imed-claim-service-film-information-list.component';
import { ImedClaimServiceFilmInformationComponent } from './film-information/imed-claim-service-film-information.component';
import { SafePipe } from './safe-pipe';
import { ModalModule } from '@mt-ng2/modal-module';
import { IMedClaimPrimaryEmailComponent } from '../imed-claims/primary-email/primary-email.component';
import { IMedClaimPrimaryPhoneComponent } from '../imed-claims/primary-phone/primary-phone.component';
import { IMedClaimEmailService } from '../imed-claims/shared/imed-claim-email.service';
import { IMedClaimPhoneNumberService } from '../imed-claims/shared/imed-claim-phone.service';
import { ImedClaimSharedEntitiesComponent } from '../imed-claims/imed-claim-shared-entities/imed-claim-shared-entities.component';
import { ImedClaimAdjusterInquiriesComponent } from '../imed-claims/services/common-components/adjuster-inquiry-notes/adjuster-inquiry-notes.component';
import { ImedClaimAdjusterInquiriesListComponent } from '../imed-claims/services/common-components/adjuster-inquiry-notes/adjuster-inquiry-notes-list/adjuster-inquiry-notes-list.component';
import { ImedClaimAdjusterInquiriesNoteComponent } from '../imed-claims/services/common-components/adjuster-inquiry-notes/adjuster-inquiry-note/adjuster-inquiry-note.component';
import { ATFaxEmailComponent } from './at-fax-email/at-fax-email-component';
import { ATFaxEmailService } from './at-fax-email/at-fax-email.service';
import { ServiceTooltipComponent } from './tooltip-directive/services-tooltip.component';
import { VisibilityModule } from '@mt-ng2/visibility-module';
import { TaskAssignBulkComponent } from '../tasks/task-list/task-assign-bulk/task-assign-bulk.component';
import { BaseFaxEmailHelperService } from './base-fax-email/base-fax-email-helper.service';
import { WysiwygModule } from '@mt-ng2/wysiwyg-control';
import { ClosedServiceFilterPipe } from './filters/closed-service-filter';
import { PhysicianService } from '../address-books/individuals/physicians/physician.service';
import { ConcurrentUserComponent } from './concurrent-users/concurrent-users.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { NavModule } from '@mt-ng2/nav-module';
import { CommonAttachmentsService } from '@app-shared/services/common-attachments.service';

export const entityListModuleConfig: IEntityListModuleConfig = {
    itemsPerPage: 10,
};

@NgModule({
    declarations: [
        NullHostDirective,
        MultiSelectCheckboxesComponent,
        ClickPopUpDirective,
        SelectPopUpDirective,
        AttachmentControlComponent,
        CommonAttachmentsComponent,
        SelectContactsEmail,
        SelectContactFaxes,
        FaxControlComponent,
        SavedSearchFiltersComponent,
        FaxEmailComponent,
        FaxEmailWrapperComponent,
        FaxEmailServicesWrapperComponent,
        FaxEmailAppointmentWrapperComponent,
        HistoricalNotesComponent,
        AuditLogRedirectComponent,
        RedirectComponent,
        ImedClaimBasicInfoComponent,
        CkEditorDynamicFieldComponent,
        ImedClaimAddressBasicInfoComponent,
        UtcDate,
        CommonFaxComponent,
        CommonEmailComponent,
        TooltipDirective,
        AddressesTooltipComponent,
        MtSearchFilterDaterangeComponentOverride,
        AddressBookInfoTooltipComponent,
        MultiselectTableControlComponent,
        CkEditorWithMergeFieldsComponent,
        UploadAnimationComponent,
        CommunicationAttachmentControlComponent,
        ImedClaimServiceFilmInformationList,
        ImedClaimServiceFilmInformationComponent,
        SafePipe,
        IMedClaimPrimaryEmailComponent,
        IMedClaimPrimaryPhoneComponent,
        ImedClaimAdjusterInquiriesComponent,
        ImedClaimAdjusterInquiriesListComponent,
        ImedClaimAdjusterInquiriesNoteComponent,
        ImedClaimSharedEntitiesComponent,
        ATFaxEmailComponent,
        TaskAssignBulkComponent,
        ServiceTooltipComponent,
        ClosedServiceFilterPipe,
        ConcurrentUserComponent,
    ],
    exports: [
        MtSearchFilterDaterangeComponentOverride,
        MultiSelectCheckboxesComponent,
        AuthEntityModule,
        CommonModule,
        NgbModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        DynamicFormModule,
        MtPhotoControlModule,
        NgxMaskModule,
        MtNoteControlModule,
        CommonEmailComponent,
        EntityComponentsNotesModule,
        EntityComponentsAddressesModule,
        MtManagedListControlModule,
        MtDocumentControlModule,
        EntityComponentsDocumentsModule,
        EntityComponentsPhonesModule,
        MtSearchFilterSelectModule,
        MtSearchFilterDaterangeModule,
        MtDisableDuringHttpCallsModule ,
        EntityListModule,
        ModalModule,
        CkEditorDynamicFieldComponent,
        MtSearchBarControlModule,
        TypeAheadModule,
        SharedEntitiesModule,
        MtSearchFilterCheckboxModule,
        BackButtonModule,
        MultiselectControlModule,
        ClickPopUpDirective,
        SelectPopUpDirective,
        AttachmentControlComponent,
        CommonAttachmentsComponent,
        FaxControlComponent,
        FaxEmailServicesWrapperComponent,
        MtPhoneControlModule,
        FaxEmailComponent,
        FaxEmailWrapperComponent,
        FaxEmailAppointmentWrapperComponent,
        CKEditorModule,
        SavedSearchFiltersComponent,
        AuditLogModule,
        HistoricalNotesComponent,
        AuditLogRedirectComponent,
        NullHostDirective,
        RedirectComponent,
        UtcDate,
        TooltipDirective,
        MultiselectTableControlComponent,
        CkEditorWithMergeFieldsComponent,
        ImedClaimBasicInfoComponent,
        ImedClaimAddressBasicInfoComponent,
        UploadAnimationComponent,
        CommunicationAttachmentControlComponent,
        ImedClaimServiceFilmInformationList,
        ImedClaimServiceFilmInformationComponent,
        SafePipe,
        ModalModule,
        IMedClaimPrimaryEmailComponent,
        IMedClaimPrimaryPhoneComponent,
        ImedClaimAdjusterInquiriesComponent,
        ImedClaimAdjusterInquiriesListComponent,
        ImedClaimAdjusterInquiriesNoteComponent,
        ImedClaimSharedEntitiesComponent,
        NavModule,
        ATFaxEmailComponent,
        TaskAssignBulkComponent,
        VisibilityModule,
        WysiwygModule,
        ClosedServiceFilterPipe,
        ConcurrentUserComponent,
    ],
    imports: [
        CKEditorModule,
        AuthEntityModule,
        CommonModule,
        NgbModule,
        RouterModule,
        FormsModule,
        BrowserAnimationsModule,
        DynamicFormModule,
        NgxMaskModule,
        FileUploadModule,
        MtNoteControlModule,
        EntityComponentsNotesModule,
        MtManagedListControlModule,
        MtPhotoControlModule,
        EntityComponentsAddressesModule,
        ModalModule,
        MtDocumentControlModule,
        EntityComponentsDocumentsModule,
        MtPhoneControlModule,
        EntityComponentsPhonesModule,
        MtSearchFilterSelectModule,
        MtSearchFilterDaterangeModule,
        MtDisableDuringHttpCallsModule ,
        EntityListModule,
        MtSearchBarControlModule,
        SharedEntitiesModule,
        MtSearchFilterCheckboxModule,
        TypeAheadModule,
        BackButtonModule,
        MultiselectControlModule,
        AuditLogModule,
        DragDropModule,
        OverlayModule,
        ModalModule,
        AuditLogEagerLoadedRoutingModule,
        NavModule,
        VisibilityModule,
        WysiwygModule,
    ]
})
export class SharedModule {
    static forRoot(): any {
        return {
            ngModule: SharedModule,
            providers: [
                AttachmentTypeService,
                CommonService,
                AuthEntityService,
                ContactStatusService,
                AddressBookEmailService,
                IndividualEmailService,
                IndividualPhoneService,
                AddressBookPhoneNumberService,
                ViewLogService,
                EmailTypeService,
                AddressFormatterService,
                PopUpService,
                IMedClaimAddressService,
                IMedClaimEmailService,
                IMedClaimPhoneNumberService,
                DateTimeConverterService,
                BaseAttachmentsService,
                FaxService,
                FaxEmailService,
                ATFaxEmailService,
                ConcurrentUsersSocketService,
                PhoneNumberService,
                EmailService,
                LogoutLogService,
                LoginLogService,
                PhysicianService,
                PhysicianSpecialityDetailService,
                PhysicianSpecialityService,
                TooltipService,
                MergeFieldService,
                FilmInformationService,
                SafePipe,
                BaseFaxEmailHelperService,
                ClosedServiceFilterPipe,
                CommonAttachmentsService,
                { provide: AuditLoggingModuleConfigToken, useValue: auditLogModuleConfig },
                { provide: AuditLoggingModuleUserServiceToken, useClass: AuditLogUserService },
                { provide: DynamicFormModuleConfigToken, useValue: dynamicFormModuleConfig },
                { provide: EntityListModuleConfigToken, useValue: entityListModuleConfig },
                { provide: MtSearchFilterDateRangeModuleConfigToken, useValue: {} },
            ],
        };
    }
}
