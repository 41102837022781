import { IEntityListConfig, EntityListConfig, EntityListColumn } from '@mt-ng2/entity-list-module';
import { ITaskTemplate } from '@model/interfaces/task-template';
import { SystemTaskDeleteCellComponent } from './system-task-delete-cell.component';
import { SystemTaskCopyCellComponent } from './system-task-copy-cell.component';

export class SystemTaskEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['Name'],
                    name: 'Name',
                }),
                new EntityListColumn({
                    accessors: ['Description'],
                    bindToInnerHtml: true,
                    name: 'Description',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    accessorFunction: function (task: ITaskTemplate): string {
                        let services = '';
                        if (task.TaskTemplateServices && task.TaskTemplateServices.length) {
                            task.TaskTemplateServices.forEach(
                                (service, i, self) => (services += service.Service.Name + (i !== self.length - 1 ? ', ' : '')),
                            );
                        }

                        return services;
                    },
                    name: 'Associated Services',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    accessorFunction: function (task: ITaskTemplate): string {
                        return task.Recurring ? 'Yes' : 'No';
                    },
                    name: 'Recurring',
                }),
                new EntityListColumn({
                    component: SystemTaskDeleteCellComponent,
                    name: 'Delete',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    component: SystemTaskCopyCellComponent,
                    fireOnColumnSelected: false,
                    name: 'Copy',
                    sort: { disableSort: true },
                }),
            ],
            rowClass: (entity: ITaskTemplate) => (entity.Archived ? 'archived' : null),
        };
        super(listConfig);
    }
}
