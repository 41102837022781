import {
    ITestimonyDepositionServiceDateDynamicControlsParameters,
    TestimonyDepositionServiceDateDynamicControls,
} from './../form-controls/testimony-deposition-service-date.form-controls';
import { ITestimonyDepositionServiceDate } from './../interfaces/testimony-deposition-service-date.d';
import { DynamicField, InputTypes } from '@mt-ng2/dynamic-form';

export class TestimonyDepositionServiceDateDynamicControlsPartial extends TestimonyDepositionServiceDateDynamicControls {
    constructor(
        private childTestimonydepositionservicedate?: ITestimonyDepositionServiceDate,
        additionalParameters?: ITestimonyDepositionServiceDateDynamicControlsParameters,
    ) {
        super(childTestimonydepositionservicedate, additionalParameters);

        (<DynamicField>this.Form.DateRequested).labelHtml = ``;
    }
}
