import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ServiceService } from '../../../imed-claims/services/service.service';
import { IService } from '@model/interfaces/service';
import { AssociationServiceSelectionService } from '../../services/associations-service-selection.service';

@Component({
    selector: 'service-select',
    styleUrls: ['../row-styles.less'],
    template: `
        <div class="row">
            <div class="col-md-4">
                <div class="miles-editable miles-card padded">
                    <h4>Select a service:</h4>
                    <select
                        name="service"
                        class="form-control service-select-input"
                        [(ngModel)]="selectedService"
                        (ngModelChange)="serviceSelected()"
                    >
                        <option *ngFor="let service of services" [ngValue]="service">{{ service.Name }}</option>
                    </select>
                </div>
            </div>
        </div>
    `,
})
export class ServiceSelectComponent implements OnInit {
    services: IService[] = [];
    selectedService: IService;
    @Output() onServiceSelected: EventEmitter<any> = new EventEmitter();

    constructor(private serviceService: ServiceService, private associationServiceSelectionService: AssociationServiceSelectionService) {}

    ngOnInit(): void {
        this.serviceService.getItems().subscribe((services) => (this.services = services.sort((a, b) => (a.Name < b.Name ? -1 : 1))));
    }

    serviceSelected(): void {
        this.associationServiceSelectionService.setAssociationType(this.selectedService);
        this.onServiceSelected.emit(this.selectedService);
    }
}
