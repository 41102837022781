import { IRecordRetrievalService } from '@model/interfaces/record-retrieval-service.d';
import { AuthService, ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IImedClaimServiceContactInfo } from '../../../../imed-claim-basic-info/imed-claim-contacts-info';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { ImedClaimServiceService } from '../../../imedclaimservice.service';
import { IImedClaimService } from '@model/interfaces/imed-claim-service';
import { RecordRetrievalServiceDynamicConfig } from '../record-retrieval-service-client.dynamic-config';
import { RecordRetrievalServiceClientService } from '../record-retrieval-service-client.service';
import { IServicePayload } from '@model/interfaces/custom/update-service-payload';
import { ServiceStatusIdEnums } from '../../../../../common/constants/Enums';
import { Subscription, forkJoin } from 'rxjs';
import { IAttachmentDTO } from '@model/interfaces/custom/attachment-dto';
import { finalize } from 'rxjs/operators';
import { ClaimTypes } from '@model/ClaimTypes';
import { IModalOptions, IModalWrapperApi } from '@mt-ng2/modal-module';
import { IUser } from '@model/interfaces/user';
import { UserService } from '../../../../../users/user.service';
import { ImedClaimTypes } from '@model/ImedClaimTypes';
import { DynamicField, DynamicLabel, IDynamicFormConfig } from '@mt-ng2/dynamic-form';

@Component({
    selector: 'app-record-retrieval-service-client-basic-info',
    templateUrl: './record-retrieval-service-client-basic-info.component.html',
})
export class RecordRetrievalServiceClientBasicInfoComponent implements OnInit {
    @Input() iImedClaimServiceModel: IImedClaimService;
    @Input() isAuhorizationDocumentUploaded: boolean;
    @Input() recordRetrievalServiceModel: IRecordRetrievalService;
    @Input() canEdit: boolean;
    @Input() iImedClaimServiceContactInfo: IImedClaimServiceContactInfo;
    @Output('authorizationDocumentUploaded') authorizationDocumentUploaded: EventEmitter<any> = new EventEmitter<any>();
    @Input() documentArray: IAttachmentDTO[];
    @Output('physicianFaxSent') physicianFaxSent: EventEmitter<any> = new EventEmitter<any>();
    @Input() reportDueBy: string;

    isEditing: boolean;
    isSoftPopup: boolean;
    isHovered: boolean;
    config: IDynamicFormConfig;
    recordRetrievalServiceForm: FormGroup;
    recordRetrievalformFactory: RecordRetrievalServiceDynamicConfig<IRecordRetrievalService>;

    doubleClickIsDisabled = false;

    subscriptions = new Subscription();
    canSeeAuditLog = false;

    modalOptions: IModalOptions = {
        customClass: 'modal-hide-actions' as any,
        width: 800,
    };
    uploadAttachmentsModalApi: IModalWrapperApi;
    selectedUser: IUser;
    retrievalAssignedToUsers = [];
    invalidServiceAssignedTo = false;
    initialImedClaimServiceContactInfo: IImedClaimServiceContactInfo;

    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    constructor(
        private imedClaimServiceService: ImedClaimServiceService,
        private notificationsService: NotificationsService,
        private fb: UntypedFormBuilder,
        private authService: AuthService,
        private recordRetrievalService: RecordRetrievalServiceClientService,
        private claimsService: ClaimsService,
        private userService: UserService,
    ) {}

    ngOnInit(): void {
        this.config = { formObject: [], viewOnly: [] };
        this.canSeeAuditLog = this.claimsService.hasClaim(ClaimTypes.Audit, [ClaimValues.FullAccess, ClaimValues.ReadOnly]);

        this.isEditing = false;
        this.subscriptions.add(
            this.imedClaimServiceService.changeEmitted$.subscribe((iImedClaimServiceModel) => {
                this.iImedClaimServiceModel = iImedClaimServiceModel;
            }),
        );

        this.userService.getRetrievalAssignedToUsers(ImedClaimTypes.GeneralLiability).subscribe((users) => {
            this.retrievalAssignedToUsers = users;
            let selectedUser = users.find((u) => u.Id === this.iImedClaimServiceModel.RetrievalAssignedToId);
            if (!selectedUser && this.iImedClaimServiceModel.RetrievalAssignedToId !== null) {
                this.userService
                    .getById(this.iImedClaimServiceModel.RetrievalAssignedToId)
                    .subscribe(
                        (user) => (
                            (selectedUser = user),
                            (this.selectedUser = selectedUser ? selectedUser : null),
                            this.retrievalAssignedToUsers.push(selectedUser)
                        ),
                    );
            } else {
                this.selectedUser = selectedUser ? selectedUser : null;
            }
        });

        this.setConfig();
    }

    ngOnChanges(changes: any): void {
        if (changes.iImedClaimServiceContactInfo) {
            this.initialImedClaimServiceContactInfo = { ...this.iImedClaimServiceContactInfo };
        }
    }

    setConfig(): void {
        this.recordRetrievalServiceForm = this.fb.group({});
        this.recordRetrievalformFactory = new RecordRetrievalServiceDynamicConfig<IRecordRetrievalService>(this.recordRetrievalServiceModel, [
            'ProcessingDate',
            'Reference',
            'SensitiveConditionsInitialed',
        ]);
        this.config = this.recordRetrievalformFactory.getForUpdate();

        this.viewOnly = this.config?.viewOnly?.map((x) => new DynamicLabel(x));
        this.formObject = this.config.formObject?.map((x) => new DynamicField(x));
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        this.isEditing = false;
        this.selectedUser = this.retrievalAssignedToUsers.find((u) => u.Id === this.iImedClaimServiceModel.RetrievalAssignedToId);
        this.iImedClaimServiceContactInfo = { ...this.initialImedClaimServiceContactInfo };
    }

    validateForDocumentUpload(): boolean {
        if (this.isAuhorizationDocumentUploaded) {
            return true;
        }

        if (
            this.iImedClaimServiceModel.ServiceStatusId !== +ServiceStatusIdEnums.RadiologyReceivedFromClient_Waiting_For_Medical_Records_Films
        ) {
            this.isSoftPopup = true;
            this.ShowDocumentPopup();
        } else {
            this.isSoftPopup = false;
            this.ShowDocumentPopup();
        }

        return false;
    }

    formSubmitted(): void {
        const invalidServiceAddress =
            (this.iImedClaimServiceContactInfo.PhysicianId > 0 ||
                this.iImedClaimServiceContactInfo.IndividualId > 0 ||
                this.iImedClaimServiceContactInfo.AddressBookId > 0) &&
            !this.iImedClaimServiceContactInfo.AddressId;

        if (!this.recordRetrievalServiceForm.valid || invalidServiceAddress) {
            markAllFormFieldsAsTouched(this.recordRetrievalServiceForm);
            this.error();
            return;
        }
        if (this.validateForDocumentUpload()) {
            this.save();
        }
    }

    save(): void {
        this.iImedClaimServiceModel = this.imedClaimServiceService.assignContactsToImedClaimService(
            this.iImedClaimServiceModel,
            this.iImedClaimServiceContactInfo,
        );
        this.iImedClaimServiceModel.RetrievalAssignedToId = this.selectedUser && this.selectedUser.Id > 0 ? this.selectedUser.Id : null;
        if (this.recordRetrievalServiceModel.ProcessingDate !== this.recordRetrievalServiceForm.value.RecordRetrievalService.ProcessingDate) {
            this.recordRetrievalServiceModel.ProcessingDateChangedById = this.authService.currentUser.getValue().Id;
            this.recordRetrievalServiceModel.ProcessingDateChangedDate = new Date();
        }
        this.recordRetrievalformFactory.assignFormValues(
            this.recordRetrievalServiceModel,
            this.recordRetrievalServiceForm.value.RecordRetrievalService as IRecordRetrievalService,
        );

        const updatePayload = <IServicePayload<IRecordRetrievalService>>{};
        updatePayload.ChildService = this.recordRetrievalServiceModel;
        updatePayload.ParentService = this.iImedClaimServiceModel;

        this.recordRetrievalService
            .updateService(updatePayload)
            .subscribe(() => {
                forkJoin(
                    this.imedClaimServiceService.getById(this.iImedClaimServiceModel.Id),
                    this.recordRetrievalService.getByImedClaimServiceId(this.iImedClaimServiceModel.Id),
                ).subscribe((response) => {
                    this.isEditing = false;
                    const [imedClaimServiceResponse, recordRetrievalServiceResponse] = response;
                    this.recordRetrievalServiceModel = recordRetrievalServiceResponse;
                    this.iImedClaimServiceModel = imedClaimServiceResponse;
                    this.imedClaimServiceService.emitChange(this.iImedClaimServiceModel);
                    this.recordRetrievalService.emitChange(this.recordRetrievalServiceModel);
                    this.success();
                    this.setConfig();
                });
            });
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }

    ShowDocumentPopup(): void {
        return this.uploadAttachmentsModalApi.show();
    }

    onDocumentChanged(): void {
        this.authorizationDocumentUploaded.emit();
    }

    onDocumentSaved(): void {
        this.isAuhorizationDocumentUploaded = true;
        this.uploadAttachmentsModalApi.close();
        this.formSubmitted();
    }

    onCloseDocument(): void {
        this.uploadAttachmentsModalApi.close();
        if (this.isSoftPopup) {
            this.save();
        } else {
            this.error();

        }
    }
}
