// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            .search-form {
                cursor: pointer;
            }
            .show {
                display: block;
            }
            .hide {
                display: none;
            }
        `, "",{"version":3,"sources":["webpack://./src/app/dashboard/claimant-name-search-widget/claimant-name-search-widget.component.ts"],"names":[],"mappings":";YACY;gBACI,eAAe;YACnB;YACA;gBACI,cAAc;YAClB;YACA;gBACI,aAAa;YACjB","sourcesContent":["\n            .search-form {\n                cursor: pointer;\n            }\n            .show {\n                display: block;\n            }\n            .hide {\n                display: none;\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
