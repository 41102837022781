
import { BehaviorSubject } from 'rxjs';
import { ClaimTypes } from '@model/ClaimTypes';
import { ClaimValues, ClaimsService } from '@mt-ng2/auth-module';
import { Injectable } from '@angular/core';
import { SidebarTaskComponent } from './sidebar-task.component';
import { HttpClient } from '@angular/common/http';
import { SidebarTaskHeaderComponent } from './sidebar-task-header.component';
import { AuthService } from '@mt-ng2/auth-module';
import { ITaskDTO } from '@model/interfaces/custom/task-dto';
import { TaskService } from '../tasks/task.service';
import { NavCasesMenuItemComponent } from './nav-cases-menu-item/nav-cases-menu-item.component';
import { KeyboardKeys } from '@mt-ng2/keyboard-shortcuts-module';
import { INavSidebarContent, NavSidebarContentContainer, INavSidebarService, INavSidebarRowItem, INavSidebarRowItemConstructor, NavSidebarRowItem, INavSidebarParentRowItem, INavSidebarParentRowItemConstructor, NavSidebarParentRowItem, NavSidebarHeaderItem } from '@mt-ng2/nav-module';

@Injectable({
    providedIn: 'root',
})
export class SideBarService implements INavSidebarService {
    content: BehaviorSubject<NavSidebarContentContainer[]>;
    menu: NavSidebarContentContainer;
    collapsedMenu: NavSidebarContentContainer;
    tasks: NavSidebarContentContainer;

    constructor(private http: HttpClient, private authService: AuthService, private claimService: ClaimsService, private taskService: TaskService) {
        const header = new NavSidebarHeaderItem({ content: 'NAVIGATION' });
        this.menu = new NavSidebarContentContainer({
            header: header,
            rows: menuItems,
        } as INavSidebarContent);
        this.collapsedMenu = new NavSidebarContentContainer({
            header: header,
            rows: collapsedMenuItems,
        } as INavSidebarContent);
        this.tasks = new NavSidebarContentContainer({
            header: new NavSidebarHeaderItem({ content: '0' }),
            headerComponent: SidebarTaskHeaderComponent,
            rowComponent: SidebarTaskComponent,
            rows: [],
        } as INavSidebarContent);
        this.content = new BehaviorSubject([this.menu]);
        this.taskService.$sidebarServiceUpdateRequired.subscribe(this.updateTasks);
    }

    updateContent(sidebarCollapsed: boolean, showTasks: boolean): void {
        if (sidebarCollapsed) {
            this.content.next([this.collapsedMenu]);
        } else {
            const nextContents = [this.menu];
            if (showTasks) {
                nextContents.push(this.tasks);
            }
            this.content.next(nextContents);
        }
    }

    updateTasks = (tasks: ITaskDTO[]): void => {
        // this would call the backend, and in that subscribe do the reassignment.
        const newTasks = tasks.map((x) => {
            return new NavSidebarRowItem({
                content: x as unknown,
            } as INavSidebarRowItemConstructor);
        });
        const currentTaskSidebarContent = this.tasks.content.getValue();
        currentTaskSidebarContent.rows = newTasks;
        currentTaskSidebarContent.header.content = newTasks.length as any;
        this.tasks.content.next(currentTaskSidebarContent);
    }
}

const menuItems: (INavSidebarParentRowItem | INavSidebarRowItem)[] = [
    new NavSidebarParentRowItem({
        children: [
            new NavSidebarRowItem({
                addClaimType: ClaimTypes.ManagedList,
                addClaimValues: [ClaimValues.FullAccess],
                claimType: ClaimTypes.ManagedList,
                claimValues: [ClaimValues.FullAccess],
                content: 'Managed List',
                icon: 'fa fa-fw fa-columns',
                link: '/managed-list',
            } as INavSidebarRowItemConstructor),
            new NavSidebarRowItem({
                addClaimType: ClaimTypes.UserRoles,
                addClaimValues: [ClaimValues.FullAccess],
                addLink: '/roles/add',
                claimType: ClaimTypes.UserRoles,
                claimValues: [ClaimValues.FullAccess],
                content: 'User Roles',
                icon: 'fa fa-fw fa-lock',
                link: '/roles',
            } as INavSidebarRowItemConstructor),
            new NavSidebarRowItem({
                addClaimType: ClaimTypes.TaskStatusFollowupEngine,
                addClaimValues: [ClaimValues.FullAccess],
                addLink: '/system-tasks/add',
                claimType: ClaimTypes.TaskStatusFollowupEngine,
                claimValues: [ClaimValues.FullAccess],
                content: 'System Tasks',
                icon: 'fa fa-fw fa-columns',
                link: '/system-tasks',
            } as INavSidebarRowItemConstructor),
            new NavSidebarRowItem({
                claimType: ClaimTypes.TaskStatusFollowupEngine,
                claimValues: [ClaimValues.FullAccess],
                content: 'Service Associations',
                icon: 'fa fa-fw fa-columns',
                link: '/system-tasks/associations',
            } as INavSidebarRowItemConstructor),
            new NavSidebarRowItem({
                claimType: ClaimTypes.TaskStatusFollowupEngine,
                claimValues: [ClaimValues.FullAccess],
                content: 'Address Book Associations',
                icon: 'fa fa-fw fa-book',
                link: '/address-book-type/associations',
            } as INavSidebarRowItemConstructor),
            new NavSidebarRowItem({
                claimType: ClaimTypes.TaskStatusFollowupEngine,
                claimValues: [ClaimValues.FullAccess],
                content: 'Case Associations',
                icon: 'fa fa-fw fa-book',
                link: '/case-type/associations',
            } as INavSidebarRowItemConstructor),
            new NavSidebarRowItem({
                addClaimType: ClaimTypes.EmailTemplates,
                addClaimValues: [ClaimValues.FullAccess],
                addLink: '/email-templates/add',
                claimType: ClaimTypes.EmailTemplates,
                claimValues: [ClaimValues.FullAccess],
                content: 'Email Templates',
                icon: 'fa fa-fw fa-reply',
                link: '/email-templates',
            } as INavSidebarRowItemConstructor),
            new NavSidebarRowItem({
                claimType: ClaimTypes.MergeCases,
                claimValues: [ClaimValues.FullAccess],
                content: 'Merge Cases',
                icon: 'fa fa-fw fa-compress',
                link: '/merge-cases',
            } as INavSidebarRowItemConstructor),
            new NavSidebarRowItem({
                claimType: ClaimTypes.LetterTemplates,
                claimValues: [ClaimValues.FullAccess],
                content: 'Letter Templates',
                icon: 'fa fa-fw fa-envelope',
                link: '/letter-templates',
            } as INavSidebarRowItemConstructor),
            new NavSidebarRowItem({
                addLink: '/dynamic-templates/add',
                claimType: ClaimTypes.AutomatedTemplates,
                claimValues: [ClaimValues.FullAccess],
                content: 'Dynamic Templates',
                icon: 'fa fa-fw fa-cogs',
                link: '/dynamic-templates',
            } as INavSidebarRowItemConstructor),
        ],
        content: 'Administration',
        expanded: false,
        icon: 'fa fa-fw fa-university',
    } as INavSidebarParentRowItemConstructor),
    new NavSidebarRowItem({
        content: 'Dashboard',
        icon: 'fa fa-fw fa-dashboard',
        link: '/home',
    } as INavSidebarRowItemConstructor),
    new NavSidebarRowItem({
        addClaimType: ClaimTypes.UserRoles,
        addClaimValues: [ClaimValues.FullAccess],
        addLink: '/users/add',
        claimType: ClaimTypes.Users,
        claimValues: [ClaimValues.FullAccess, ClaimValues.ReadOnly],
        content: 'Users',
        icon: 'fa fa-fw fa-user',
        link: '/users',
    } as INavSidebarRowItemConstructor),
    new NavSidebarRowItem({
        addClaimType: ClaimTypes.AddressBooks,
        addClaimValues: [ClaimValues.FullAccess],
        addLink: '/address-books/add',
        claimType: ClaimTypes.AddressBooks,
        claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
        content: 'Address Book',
        icon: 'fa fa-fw fa-book',
        link: '/address-books',
    } as INavSidebarRowItemConstructor),
    new NavSidebarRowItem({
        addClaimType: ClaimTypes.CaseManagement,
        addClaimValues: [ClaimValues.FullAccess],
        addLink: '/cases/add',
        claimType: ClaimTypes.CaseManagement,
        claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
        content: 'Cases',
        icon: 'fa fa-fw fa-briefcase',
        link: '/cases',
        rowComponent: NavCasesMenuItemComponent,
    } as INavSidebarRowItemConstructor),
    new NavSidebarRowItem({
        claimType: ClaimTypes.GlobalServiceList,
        claimValues: [ClaimValues.FullAccess],
        content: 'Global Service List',
        icon: 'fa fa-fw fa-user-md',
        link: '/cases/0/services',
    } as INavSidebarRowItemConstructor),
    new NavSidebarRowItem({
        claimType: ClaimTypes.GlobalFollowUpList,
        claimValues: [ClaimValues.FullAccess],
        content: 'Global Follow Up List',
        icon: 'fa fa-fw fa-list',
        link: '/cases/0/services/0/follow-ups',
    } as INavSidebarRowItemConstructor),
    new NavSidebarRowItem({
        addClaimType: ClaimTypes.Tasks,
        addClaimValues: [ClaimValues.FullAccess],
        addLink: '/tasks/add',
        claimType: ClaimTypes.Tasks,
        claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
        content: 'Tasks',
        icon: 'fa fa-fw fa-tasks',
        link: '/tasks',
    } as INavSidebarRowItemConstructor),
    new NavSidebarRowItem({
        addLink: '',
        claimType: ClaimTypes.DocumentQueue,
        claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
        content: 'Document Queue',
        icon: 'fa fa-fw fa-file',
        link: '/documentqueue',
    } as INavSidebarRowItemConstructor),
    new NavSidebarRowItem({
        claimType: ClaimTypes.Audit,
        content: 'Audit Logs',
        icon: 'fa fa-fw fa-list-alt',
        link: '/audit-logs',
        shortcut: {
            description: 'go to audit-logs',
            keyBinding: [KeyboardKeys.Alt, KeyboardKeys.Shift, 'A'],
        },
    }),
];

const collapsedMenuItems: (INavSidebarParentRowItem | INavSidebarRowItem)[] = [
    new NavSidebarParentRowItem({
        children: [
            new NavSidebarRowItem({
                addClaimType: ClaimTypes.ManagedList,
                addClaimValues: [ClaimValues.FullAccess],
                claimType: ClaimTypes.ManagedList,
                claimValues: [ClaimValues.FullAccess],
                content: 'Managed List',
                icon: 'fa fa-fw fa-columns',
                link: '/managed-list',
            } as INavSidebarRowItemConstructor),
            new NavSidebarRowItem({
                addClaimType: ClaimTypes.UserRoles,
                addClaimValues: [ClaimValues.FullAccess],
                addLink: '/roles/add',
                claimType: ClaimTypes.UserRoles,
                claimValues: [ClaimValues.FullAccess],
                content: 'User Roles',
                icon: 'fa fa-fw fa-lock',
                link: '/roles',
            } as INavSidebarRowItemConstructor),
            new NavSidebarRowItem({
                addClaimType: ClaimTypes.TaskStatusFollowupEngine,
                addClaimValues: [ClaimValues.FullAccess],
                addLink: '/system-tasks/add',
                claimType: ClaimTypes.TaskStatusFollowupEngine,
                claimValues: [ClaimValues.FullAccess],
                content: 'System Tasks',
                icon: 'fa fa-fw fa-columns',
                link: '/system-tasks',
            } as INavSidebarRowItemConstructor),
            new NavSidebarRowItem({
                claimType: ClaimTypes.TaskStatusFollowupEngine,
                claimValues: [ClaimValues.FullAccess],
                content: 'Service Associations',
                icon: 'fa fa-fw fa-columns',
                link: '/system-tasks/associations',
            } as INavSidebarRowItemConstructor),
            new NavSidebarRowItem({
                addClaimType: ClaimTypes.EmailTemplates,
                addClaimValues: [ClaimValues.FullAccess],
                addLink: '/email-templates/add',
                claimType: ClaimTypes.EmailTemplates,
                claimValues: [ClaimValues.FullAccess],
                content: 'Email Templates',
                icon: 'fa fa-fw fa-reply',
                link: '/email-templates',
            } as INavSidebarRowItemConstructor),
            new NavSidebarRowItem({
                claimType: ClaimTypes.MergeCases,
                claimValues: [ClaimValues.FullAccess],
                content: 'Merge Cases',
                icon: 'fa fa-fw fa-compress',
                link: '/merge-cases',
            } as INavSidebarRowItemConstructor),
            new NavSidebarRowItem({
                claimType: ClaimTypes.LetterTemplates,
                claimValues: [ClaimValues.FullAccess],
                content: 'Letter Templates',
                icon: 'fa fa-fw fa-envelope',
                link: '/letter-templates',
            } as INavSidebarRowItemConstructor),
            new NavSidebarRowItem({
                addLink: '/dynamic-templates/add',
                claimType: ClaimTypes.AutomatedTemplates,
                claimValues: [ClaimValues.FullAccess],
                content: 'Dynamic Templates',
                icon: 'fa fa-fw fa-cogs',
                link: '/dynamic-templates',
            } as INavSidebarRowItemConstructor),
        ],
        content: 'Administration',
        expanded: false,
        icon: 'fa fa-fw fa-university',
    } as INavSidebarParentRowItemConstructor),
    new NavSidebarRowItem({
        content: 'Dashboard',
        icon: 'fa fa-fw fa-dashboard',
        link: '/home',
    } as INavSidebarRowItemConstructor),
    new NavSidebarRowItem({
        addClaimType: ClaimTypes.UserRoles,
        addClaimValues: [ClaimValues.FullAccess],
        addLink: '/users/add',
        claimType: ClaimTypes.Users,
        claimValues: [ClaimValues.FullAccess, ClaimValues.ReadOnly],
        content: 'Users',
        icon: 'fa fa-fw fa-user',
        link: '/users',
    } as INavSidebarRowItemConstructor),
    new NavSidebarRowItem({
        addClaimType: ClaimTypes.AddressBooks,
        addClaimValues: [ClaimValues.FullAccess],
        addLink: '/address-books/add',
        claimType: ClaimTypes.AddressBooks,
        claimValues: [ClaimValues.FullAccess],
        content: 'Address Book',
        icon: 'fa fa-fw fa-book',
        link: '/address-books',
    } as INavSidebarRowItemConstructor),
    new NavSidebarRowItem({
        addClaimType: ClaimTypes.CaseManagement,
        addClaimValues: [ClaimValues.FullAccess],
        claimType: ClaimTypes.CaseManagement,
        claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
        content: 'Cases',
        icon: 'fa fa-fw fa-briefcase',
        link: '/cases',
    } as INavSidebarRowItemConstructor),
    new NavSidebarRowItem({
        claimType: ClaimTypes.GlobalServiceList,
        claimValues: [ClaimValues.FullAccess],
        content: 'Global Service List',
        icon: 'fa fa-fw fa-user-md',
        link: '/cases/0/services',
    } as INavSidebarRowItemConstructor),
    new NavSidebarRowItem({
        claimType: ClaimTypes.GlobalFollowUpList,
        claimValues: [ClaimValues.FullAccess],
        content: 'Global Follow Up List',
        icon: 'fa fa-fw fa-list',
        link: '/cases/0/services/0/follow-ups',
    } as INavSidebarRowItemConstructor),
    new NavSidebarRowItem({
        addClaimType: ClaimTypes.Tasks,
        addClaimValues: [ClaimValues.FullAccess],
        addLink: '/tasks/add',
        claimType: ClaimTypes.Tasks,
        claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
        content: 'Tasks',
        icon: 'fa fa-fw fa-tasks',
        link: '/tasks',
    } as INavSidebarRowItemConstructor),
    new NavSidebarRowItem({
        addLink: '',
        claimType: ClaimTypes.DocumentQueue,
        claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
        content: 'Document Queue',
        icon: 'fa fa-fw fa-file',
        link: '/documentqueue',
    } as INavSidebarRowItemConstructor),
    new NavSidebarRowItem({
        claimType: ClaimTypes.Audit,
        content: 'Audit Logs',
        icon: 'fa fa-fw fa-list-alt',
        link: '/audit-logs',
        shortcut: {
            description: 'go to audit-logs',
            keyBinding: [KeyboardKeys.Alt, KeyboardKeys.Shift, 'A'],
        },
    }),
];
