import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { IImedClaimAdditionalBillToPartyDTO } from '@model/interfaces/custom/imed-claim-additional-btp.dto';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { finalize } from 'rxjs/operators';
import { UntypedFormGroup } from '@angular/forms';
import { AuthService } from '@mt-ng2/auth-module';
import { IImedClaimServiceCourtFee } from '@model/interfaces/imed-claim-service-court-fee';
import { ImedClaimServiceCourtFeeService } from './subpoena-service-court-fees-service';
import { ImedClaimServiceCourtFeeDynamicControls } from '@model/form-controls/imed-claim-service-court-fee.form-controls';
import { ImedClaimServiceCourtFeesDynamicConfig } from './subpoena-service-court-fees-dynamic-config';
import { IImedClaimService } from '@model/interfaces/imed-claim-service';
import { ISubpoenaService } from '@model/interfaces/subpoena-service';
import { ImedClaimServiceCourtFeeDynamicControlsPartial } from '@model/partials/imed-claim-service-court-fee-partial.form-controls';

@Component({
    selector: 'app-imed-claim-service-court-fees',
    styles: ['.custom-padded { margin: 0 0 0.5em 0; } ', '.highlight {background-color: #FF45B5;}'],
    templateUrl: './subpoena-service-court-fees-component.html',
})
export class SubpoenaServiceCourtFeesComponent implements OnInit, OnDestroy {
    @Input() imedClaimServiceId: number;
    @Input() subpoenaService: ISubpoenaService;
    @Input() canEdit: boolean;
    isHovered = false;
    doubleClickIsDisabled = false;
    courtFeesForm = new UntypedFormGroup({ ImedClaimServiceCourtFee: new UntypedFormGroup({}) });
    formFactory: any;
    controls: any;

    courtFees: IImedClaimServiceCourtFee[] = [];
    newCourtFees: IImedClaimServiceCourtFee;
    _imedClaimServiceCourtFeesService: ImedClaimServiceCourtFeeService;
    selectedCourtFee: IImedClaimServiceCourtFee;

    _isEditing: boolean;
    public set isEditing(v: boolean) {
        this._isEditing = v;
    }

    public get isEditing(): boolean {
        return this._isEditing;
    }

    constructor(
        private imedClaimServiceCourtFeesService: ImedClaimServiceCourtFeeService,
        private notificationsService: NotificationsService,
        private authService: AuthService,
    ) {
        this._imedClaimServiceCourtFeesService = imedClaimServiceCourtFeesService;
    }

    ngOnInit(): void {
        this.isEditing = false;
        this.getImedClaimServiceCourtFees();
        this.setConfig();
    }

    setConfig(): void {
        this.newCourtFees = this.imedClaimServiceCourtFeesService.getEmptyImedClaimCourtServiceFee();
        this.controls = new ImedClaimServiceCourtFeeDynamicControlsPartial(this.newCourtFees, null).Form;
        this.formFactory = new ImedClaimServiceCourtFeesDynamicConfig<IImedClaimServiceCourtFee>(this.newCourtFees, []);
    }

    ngOnDestroy(): void {
        this.selectedCourtFee = null;
    }

    add(): void {
        if (this.canEdit) {
            this.isEditing = true;
            this.selectedCourtFee = null;
            this.setConfig();
        }
    }

    getImedClaimServiceCourtFees(): void {
        this.imedClaimServiceCourtFeesService.getCourtFees(this.imedClaimServiceId).subscribe((courtFees: IImedClaimServiceCourtFee[]) => {
            this.courtFees = courtFees;
        });
    }

    onPhysicalCheckUpdate(): void {
        this.imedClaimServiceCourtFeesService
            .togglePhysicalCheckRequired(this.imedClaimServiceId)
            .pipe()
            .subscribe(() => {
                this.notificationsService.success('updated successfully');
            });
    }

    cancel(): void {
        this.isEditing = false;
        this.selectedCourtFee = null;
    }

    editCourtFees(courtFee: IImedClaimServiceCourtFee): void {
        if (!this.canEdit) {
            return;
        }
        this.isEditing = true;
        this.selectedCourtFee = { ...courtFee };
        this.controls = new ImedClaimServiceCourtFeeDynamicControlsPartial(this.selectedCourtFee, null).Form;
    }

    save(): void {
        const form = this.courtFeesForm;
        if (this.selectedCourtFee && this.selectedCourtFee.Id > 0) {
            this.formFactory.assignFormValues(this.selectedCourtFee, form.value.ImedClaimServiceCourtFee);
            this.selectedCourtFee.DateModified = new Date();
            this.selectedCourtFee.ModifiedById = this.authService.currentUser.getValue().Id;
            this.imedClaimServiceCourtFeesService.editCourtFee(this.imedClaimServiceId, this.selectedCourtFee).subscribe(() => {
                this.notificationsService.success('Court Fees edited successfully');
                this.isEditing = false;
                this.selectedCourtFee = null;
                this.getImedClaimServiceCourtFees();
            });
        } else {
            this.formFactory.assignFormValues(this.newCourtFees, form.value.ImedClaimServiceCourtFee);
            this.newCourtFees.ImedClaimServiceId = this.imedClaimServiceId;
            this.newCourtFees.DateCreated = new Date();
            this.newCourtFees.CreatedById = this.authService.currentUser.getValue().Id;
            this.imedClaimServiceCourtFeesService.addCourtFee(this.imedClaimServiceId, this.newCourtFees).subscribe(() => {
                this.notificationsService.success('Court Fees added successfully');
                this.isEditing = false;
                this.getImedClaimServiceCourtFees();
            });
        }
    }

    remove(item: IImedClaimAdditionalBillToPartyDTO, event: Event): void {
        if (event && event.stopPropagation) {
            event.stopPropagation();
        }

        this.imedClaimServiceCourtFeesService.delete(item.Id).subscribe(() => {
            this.getImedClaimServiceCourtFees();
            this.notificationsService.success('Court Fees deleted successfully');
        });
    }
}
