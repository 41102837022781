import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/operators';
import { SearchParams } from '@mt-ng2/common-classes';
import { of } from 'rxjs';
import { IUserSearchFilter } from '@model/interfaces/user-search-filter';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '@mt-ng2/base-service';

export interface ICommonSearch {
    savedSearchName?: string;
    dashboardName?: string;
    searchFilters: SearchParams;
}

@Injectable({
    providedIn: 'root',
})
export class CommonSearchService extends BaseService<IUserSearchFilter> {
    private _searchFilters = null;
    set searchFilters(input: ICommonSearch) {
        this._searchFilters = input;
    }
    get searchFilters(): ICommonSearch {
        return this._searchFilters;
    }

    constructor(public http: HttpClient) {
        super('/searchfilters', http);
    }

    public getCommonSearchFilters(): Observable<ICommonSearch> {
        return of(this.searchFilters);
    }

    public setCommonSearchFilters(params: ICommonSearch): void {
        this.searchFilters = params;
    }
    public clearCommonSearchFilters(): void {
        this.searchFilters = null;
    }

    public getSavedSearchFilters(route: string, userId: number): Observable<IUserSearchFilter[]> {
        return this.http.get<IUserSearchFilter[]>(`/searchfilters/${route}/${userId}/getSavedSearches`, { responseType: 'json' });
    }

    public deleteSearch(searchId: number): Observable<object> {
        return this.http.delete<IUserSearchFilter>(`/searchfilters/${searchId}/delete`, { responseType: 'text' as 'json' });
    }
}
