import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IAddressBookType } from '@model/interfaces/address-book-type';
import { StaticMetaItemService } from '@mt-ng2/base-service';

@Injectable()
export class AddressBookTypeService extends StaticMetaItemService<IAddressBookType> {
    constructor(public http: HttpClient) {
        super('AddressBookTypeService', 'Type', 'TypeIds', '/addressbooktypes', http);
    }
}
