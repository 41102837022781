import { Component, OnInit, Input } from '@angular/core';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, IDynamicFieldType, IDynamicField, NumericInputTypes } from '@mt-ng2/dynamic-form';
import { IImedClaimService } from '@model/interfaces/imed-claim-service';

@Component({
    selector: 'app-page-count-control',
    templateUrl: './page-count.component.html',
})
export class PageCountFormControlComponent implements OnInit {
    @Input('isEditing') isEditing: boolean;
    @Input('imedClaimService') imedClaimService: IImedClaimService;
    beginningPageCount: DynamicField;
    endingPageCount: DynamicField;

    constructor() {}

    ngOnInit(): void {
        this.createBeginningPageCountField();
        this.createEndingPageCountField();
    }

    createBeginningPageCountField(): void {
        this.beginningPageCount = new DynamicField({
            formGroup: null,
            label: 'Beginning Page Count',
            name: 'BeginningPageCount',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: NumericInputTypes.Integer,
                scale: null,
            } as IDynamicFieldType),
            value: this.imedClaimService.BeginningPageCount,
        } as IDynamicField);
    }

    createEndingPageCountField(): void {
        this.endingPageCount = new DynamicField({
            formGroup: null,
            label: 'Ending Page Count',
            name: 'EndingPageCount',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: NumericInputTypes.Integer,
                scale: null,
            } as IDynamicFieldType),
            value: this.imedClaimService.EndingPageCount,
        } as IDynamicField);
    }
}
