import { DynamicField } from '@mt-ng2/dynamic-form';
import { IRecordRetrievalServiceDynamicControlsParameters } from './../form-controls/record-retrieval-service.form-controls';
import { RecordRetrievalServiceDynamicControls } from './../form-controls/record-retrieval-service.form-controls';
import { IRecordRetrievalService } from '../interfaces/record-retrieval-service';

export class RecordRetrievalServiceDynamicControlsPartial extends RecordRetrievalServiceDynamicControls {
    constructor(private service?: IRecordRetrievalService, additionalParameters?: IRecordRetrievalServiceDynamicControlsParameters) {
        super(service, additionalParameters);

        // (<DynamicField>this.Form.ProcessingDate).value = new Date();
    }
}
