import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SharedEntitiesService } from '@mt-ng2/shared-entities-module';
import { IAddress } from '../../interfaces/address';

export const emptyAddress: IAddress = {
    Address1: null,
    Address2: null,
    Archived: false,
    City: null,
    CreatedById: null,
    DateCreated: null,
    Id: 0,
    StateId: null,
    Zip: null,
};

@Injectable()
export class AddressService extends SharedEntitiesService<IAddress> {
    constructor(public baseEntityUrl: string, public http: HttpClient) {
        super(baseEntityUrl, '/addresses', http);
    }
}
