import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { DashboardTypeEnums } from '../../common/constants/Enums';
import { DashboardService } from '../dashboard.service';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '@model/ClaimTypes';

@Component({
    selector: 'app-base-dashboard',
    templateUrl: './base-dashboard.component.html',
})
export class BaseDashboardComponent implements OnInit, OnDestroy {
    dashboardSubscription: Subscription;
    selectedDashboardType: DashboardTypeEnums;
    hasCasesClaim: boolean;

    get bothDashboards(): boolean {
        return this.selectedDashboardType === DashboardTypeEnums.Both;
    }
    get managementDashboard(): boolean {
        return this.selectedDashboardType === DashboardTypeEnums.Management;
    }
    get operationalDashboard(): boolean {
        return this.selectedDashboardType === DashboardTypeEnums.Operational;
    }
    constructor(private dashboardService: DashboardService, private claimsService: ClaimsService) { }

    ngOnInit(): void {
        this.hasCasesClaim = this.claimsService.hasClaim(ClaimTypes.CaseManagement, [ClaimValues.ReadOnly, ClaimValues.FullAccess]);
        if (this.hasCasesClaim) {
            this.dashboardSubscription = this.dashboardService.getUserDashboardtype().subscribe((type) => {
                this.selectedDashboardType = type;
            });
        }
    }

    ngOnDestroy(): void {
        if (this.dashboardSubscription) {
            this.dashboardSubscription.unsubscribe();
        }
    }
}
