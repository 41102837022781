import { FilmReviewServiceDynamicControls } from '@model/form-controls/film-review-service.form-controls';
import { IFilmReviewService } from '@model/interfaces/film-review-service.d';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { FilmReviewServiceDynamicConfig } from '../film-review-service.dynamic-config';
import { FilmReviewServiceService } from '../film-review-service.service';
import { finalize } from 'rxjs/operators';
import { DynamicField, DynamicLabel, IDynamicFormConfig } from '@mt-ng2/dynamic-form';

@Component({
    selector: 'app-film-review-radiology-facility-info',
    templateUrl: './radiology-facility-info.component.html',
})
export class FilmReviewRadiologyFacilityComponent implements OnInit {
    @Input() filmReviewServiceModel: IFilmReviewService;
    @Input() isAddingFilmInfo = false;
    isEditing: boolean;

    config: IDynamicFormConfig;
    filmReviewServiceForm: any;
    filmReviewformFactory: FilmReviewServiceDynamicConfig<IFilmReviewService>;

    doubleClickIsDisabled = false;
    controls: any;

    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    constructor(private notificationsService: NotificationsService, private fb: UntypedFormBuilder, private filmReviewService: FilmReviewServiceService) { }

    ngOnInit(): void {
        this.isEditing = false;
        this.config = { formObject: [], viewOnly: [] };
        this.setConfig();
    }

    setConfig(): void {
        this.filmReviewServiceForm = this.fb.group({
            FilmReviewService: this.fb.group({}),
        });

        this.controls = new FilmReviewServiceDynamicControls(this.filmReviewServiceModel);

        this.filmReviewformFactory = new FilmReviewServiceDynamicConfig<IFilmReviewService>(this.filmReviewServiceModel, [
            'RadiologyFacility',
            'RetrievedByImedview',
        ]);
        this.config = this.filmReviewformFactory.getForUpdate();
        
        this.viewOnly = this.config?.viewOnly?.map((x) => new DynamicLabel(x));
        this.formObject = this.config.formObject?.map((x) => new DynamicField(x));
    }

    edit(): void {
        this.isEditing = true;
    }

    cancelClick(): void {
        this.isEditing = false;
    }

    formSubmitted(): void {
        this.filmReviewformFactory.assignFormValues(this.filmReviewServiceModel, this.filmReviewServiceForm.value.FilmReviewService as IFilmReviewService);
        this.filmReviewService
            .update(this.filmReviewServiceModel)            .subscribe(() => {
                this.isEditing = false;
                this.success();
                this.filmReviewService.emitChange(this.filmReviewServiceModel);
                this.setConfig();
            });
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }
}
