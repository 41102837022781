import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable()
export class CurrencyFormatterService {
    constructor(private currencyPipe: CurrencyPipe) {}

    formatAsCurrency(value: number): string {
        return this.currencyPipe.transform(value, 'USD', 'symbol', '1.2-2');
    }
}
