// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            .mr-3 {
                margin-right: 3px;
            }
            a {
                color: inherit;
                text-decoration: inherit;
            }
        `, "",{"version":3,"sources":["webpack://./src/app/tasks/task-list/task-actions.component.ts"],"names":[],"mappings":";YACY;gBACI,iBAAiB;YACrB;YACA;gBACI,cAAc;gBACd,wBAAwB;YAC5B","sourcesContent":["\n            .mr-3 {\n                margin-right: 3px;\n            }\n            a {\n                color: inherit;\n                text-decoration: inherit;\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
