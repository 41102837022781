import { Component, Input, OnInit } from '@angular/core';
import { IImedClaimService } from '@model/interfaces/imed-claim-service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { finalize } from 'rxjs/operators';
import { WcIssueDynamicConfig } from './wc-issue.dynamic-config';
import { WCIssueService } from '../../wc-issue.service';
import { IWcIssue } from '@model/interfaces/wc-issue';
import { ServiceIdEnums } from '../../../../common/constants/Enums';
import { DynamicLabel, DynamicField, IDynamicFormConfig } from '@mt-ng2/dynamic-form';

// Issues related to claims need to be shown at IME service level as Issues are  related to IME Service ONLY so we created a seperate component inside services and update claims table from there.
// If there are multiple IMES in a claim, updating one will update all based on the following task
// Task id:962201
@Component({
    selector: 'app-wc-issues',
    templateUrl: './wc-issues.component.html',
})
export class WCIssuesComponent implements OnInit {
    @Input() iImedClaimServiceModel: IImedClaimService;
    @Input() canEdit: boolean;
    serviceForm: UntypedFormGroup;
    wcIssueFormFactory: WcIssueDynamicConfig<IWcIssue>;
    config: IDynamicFormConfig;
    isEditing = false;
    isHovered = false;
    doubleClickIsDisabled: boolean;
    imeIssues: string[] = [];
    reExamIssues: string[] = [];

    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    constructor(private fb: UntypedFormBuilder, private notificationsService: NotificationsService, private wcIssueService: WCIssueService) {
        this.imeIssues = [
            'ReviewOfRecords',
            'CompleteHistory',
            'PresentComplaints',
            'PreExistingConditions',
            'ObjectiveFindingsOnExamDiagnosis',
            'CausalRelationship',
            'AppropriatenessOfTreatment',
            'NeedForFurtherTreatment',
            'NeedForContinuedFollowUp',
            'NeedForPhysicalTherapy',
            'NeedForMassage',
            'NeedForPrescriptionMedication',
            'NeedForSurgery',
            'NeedForInjections',
            'NeedForDme',
            'NeedForEssentialServicesHouseholdHelp',
            'NeedForTransportation',
            'NeedForFurtherDiagnosticTesting',
            'DegreeOfDisability',
            'ReturnToWorkRestrictionsAdl',
            'Apportionment',
            'Mmi',
            'Permanency',
            'Slu',
            'SpecificTreatmentQuestions',
        ];

        this.reExamIssues = [
            'ReviewOfRecords',
            'PastSubsequentMedicalHistory',
            'HistoryOfAllegedInjury',
            'OccupationalHistory',
            'AdLsThatMayAffectThisClaim',
            'PresentComplaints',
            'ObjectiveFindingsOnExam',
            'CausalRelationship',
            'Diagnosis',
            'AppropriatenessOfTreatment',
            'NeedForFurtherTreatmentWithinYourScope',
            'DegreeOfDisability',
            'ReturnToWorkRestrictionsAdl',
            'Slu',
            'Permanency',
            'RecommendationForFurtherTreatment',
            'NeedForFurtherTreatment',
            'Apportionment',
            'NeedForSurgery',
            'NeedForPhysicalTherapy',
            'FunctionalCapabilityEvaluation',
            'NeedForFurtherDiagnosticTesting',
            'NeedForEssentialServicesHouseholdHelp',
            'SpecificTreatmentQuestions',
        ];
    }

    ngOnInit(): void {
        this.serviceForm = this.fb.group({});
        this.config = { formObject: [], viewOnly: [] };
        this.setConfig();
    }

    setConfig(): void {
        if (this.iImedClaimServiceModel.ServiceId === +ServiceIdEnums.IME_WC) {
            this.wcIssueFormFactory = new WcIssueDynamicConfig(this.iImedClaimServiceModel.ImedClaim.WcIssue, this.imeIssues);
        } else if (this.iImedClaimServiceModel.ServiceId === +ServiceIdEnums.WC_IME_Re_Examination) {
            this.wcIssueFormFactory = new WcIssueDynamicConfig(this.iImedClaimServiceModel.ImedClaim.WcIssue, this.reExamIssues);
        } else {
            this.wcIssueFormFactory = new WcIssueDynamicConfig(this.iImedClaimServiceModel.ImedClaim.WcIssue);
        }
        this.config = this.wcIssueFormFactory.getForUpdate();

        this.viewOnly = this.config?.viewOnly?.map((x) => new DynamicLabel(x));
        this.formObject = this.config.formObject?.map((x) => new DynamicField(x));
    }

    edit(): void {
        this.isEditing = true;
    }

    cancelClick(): void {
        this.isEditing = false;
    }

    formSubmitted(): void {
        this.wcIssueFormFactory.assignFormValues(this.iImedClaimServiceModel.ImedClaim.WcIssue, this.serviceForm.value.WcIssue as IWcIssue);
        this.wcIssueService
            .update(this.iImedClaimServiceModel.ImedClaim.WcIssue)            .subscribe(() => {
                this.success();
            });
    }
    success(): void {
        this.setConfig();
        this.isEditing = false;
        this.notificationsService.success('Saved Successfully');
    }
}
