import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { IImedClaimAdditionalRecord } from '../interfaces/imed-claim-additional-record';
import { IImedClaim } from '../interfaces/imed-claim';

export interface IImedClaimAdditionalRecordDynamicControlsParameters {
    formGroup?: string;
    imedClaims?: IImedClaim[];
}

export class ImedClaimAdditionalRecordDynamicControls {

    formGroup: string;
    imedClaims?: IImedClaim[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private imedclaimadditionalrecord?: IImedClaimAdditionalRecord, additionalParameters?: IImedClaimAdditionalRecordDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'ImedClaimAdditionalRecord';
        this.imedClaims = additionalParameters && additionalParameters.imedClaims || undefined;

        this.Form = {
            BeginningPageCount: new DynamicField({
                formGroup: this.formGroup,
                label: 'Beginning Page Count',
                name: 'BeginningPageCount',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.imedclaimadditionalrecord && this.imedclaimadditionalrecord.BeginningPageCount || null,
            }),
            Date: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date',
                name: 'Date',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.imedclaimadditionalrecord && this.imedclaimadditionalrecord.Date || null,
            }),
            EndingPageCount: new DynamicField({
                formGroup: this.formGroup,
                label: 'Ending Page Count',
                name: 'EndingPageCount',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.imedclaimadditionalrecord && this.imedclaimadditionalrecord.EndingPageCount || null,
            }),
            ImedClaimId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Imed Claim',
                name: 'ImedClaimId',
                options: this.imedClaims,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.imedclaimadditionalrecord && this.imedclaimadditionalrecord.ImedClaimId || null,
            }),
        };

        this.View = {
            BeginningPageCount: new DynamicLabel({
                label: 'Beginning Page Count',
                value: this.imedclaimadditionalrecord && this.imedclaimadditionalrecord.BeginningPageCount || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            Date: new DynamicLabel({
                label: 'Date',
                value: this.imedclaimadditionalrecord && this.imedclaimadditionalrecord.Date || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            EndingPageCount: new DynamicLabel({
                label: 'Ending Page Count',
                value: this.imedclaimadditionalrecord && this.imedclaimadditionalrecord.EndingPageCount || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            ImedClaimId: new DynamicLabel({
                label: 'Imed Claim',
                value: getMetaItemValue(this.imedClaims as unknown as IMetaItem[], this.imedclaimadditionalrecord && this.imedclaimadditionalrecord.hasOwnProperty('ImedClaimId') && this.imedclaimadditionalrecord.ImedClaimId !== null ? this.imedclaimadditionalrecord.ImedClaimId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
        };

    }
}
