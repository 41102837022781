import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { IService } from '@model/interfaces/service';
import { forkJoin, Observable } from 'rxjs';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IMasterFollowupTypeEmailTemplate } from '@model/interfaces/master-followup-type-email-template';
import { MasterFollowupTypeEmailTemplateService } from '../../../services/followup-type-email-template.service';
import { IGroupedEmailTemplatesByFollowupDTO } from '@model/interfaces/custom/grouped-email-templates-by-followup-dto';
import { IEmailTemplate } from '@model/interfaces/email-template';
import { EmailTemplateService } from '../../../../email-templates/emailtemplate.service';
import { ExtraSearchParams, IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { tap } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { MasterFollowupTypeService } from '../../../services/master-followup-type.service';
import { IMasterFollowupType } from '@model/interfaces/master-followup-type';
import { EmailTemplateTypes } from '../../../../common/constants/Enums';
import { IFollowupEmailTemplateAssociation } from './../followup-email-template-row/followup-email-template-row.component';

@Component({
    selector: 'followup-email-template-associations',
    styles: [
        `
            .clear-padding {
                padding-left: 0;
            }
        `,
    ],
    templateUrl: './followup-email-template-associations.component.html',
})
export class FollowupEmailTemplateAssociationsComponent implements OnInit, OnChanges {
    @Input() selectedService: IService;
    filteredEmailTemplates: IEmailTemplate[];
    filteredFollowupTypes: IMasterFollowupType[];
    filteredFollowupTypeEmailTemplates: IMasterFollowupTypeEmailTemplate[] = [];
    emailTemplatesGroupedByFollowup: IGroupedEmailTemplatesByFollowupDTO[] = [];
    constructor(
        private emailTemplateService: EmailTemplateService,
        private followupEmailTemplateService: MasterFollowupTypeEmailTemplateService,
        private notificationService: NotificationsService,
        private masterFollowupTypeService: MasterFollowupTypeService,
    ) {}

    ngOnInit(): void {
        this.getFilteredEmailTemplates();
    }

    ngOnChanges(): void {
        this.getFilteredEmailTemplates();
    }

    getFilteredEmailTemplates(): void {
        this.filteredEmailTemplates = null;
        this.filteredFollowupTypes = null;
        forkJoin([
            this.masterFollowupTypeService.getMasterFollowupTypesByServiceId(this.selectedService.Id),
            this.followupEmailTemplateService.getAll(),
            this.followupEmailTemplateService.getEmailTemplatesGroupedByFollowup(this.selectedService.Id),
            this.searchEmailTemplates(),
        ]).subscribe(([filteredFollowupTypes, followupEmailTemplateAssociations, groupedEmailTemplates,]) => {
            this.emailTemplatesGroupedByFollowup = groupedEmailTemplates;
            this.filteredFollowupTypeEmailTemplates = followupEmailTemplateAssociations.filter((x) => x.ServiceId === this.selectedService.Id);
            this.filteredFollowupTypes = filteredFollowupTypes.filter(
                (x) => !this.filteredFollowupTypeEmailTemplates.some((y) => y.MasterFollowupTypeId === x.Id),
            );
        });
    }

    searchEmailTemplates(): Observable<HttpResponse<IEmailTemplate[]>> {
        const _extraSearchParams: ExtraSearchParams[] = [];
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'includeArchived',
                value: '0',
            }),
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'serviceId',
                value: this.selectedService.Id.toString(),
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'emailTemplateTypeId',
                value: EmailTemplateTypes.SERVICE.toString(),
            }),
        );

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: 'Name',
            orderDirection: 'asc',
            query: '',
        };

        const searchparams = new SearchParams(searchEntity);
        return this.emailTemplateService.search(searchparams).pipe(
            tap(({ body: templates }) => {
                this.filteredEmailTemplates = templates;
            }),
        );
    }

    saveFollowupEmailTemplateAssociation(event: IFollowupEmailTemplateAssociation): void {
        const followupEmailTemplateAssociation: IMasterFollowupTypeEmailTemplate = {
            EmailTemplateId: event.EmailTemplate.Id,
            Id: 0,
            MasterFollowupTypeId: event.Followup.Id,
            ServiceId: this.selectedService.Id,
        };

        this.followupEmailTemplateService.create(followupEmailTemplateAssociation).subscribe((id) => {
            followupEmailTemplateAssociation.Id = id;
            this.filteredFollowupTypeEmailTemplates.push(followupEmailTemplateAssociation);
            this.notificationService.success('Followup email template association saved successfully.');
            this.getFilteredEmailTemplates();
        });
    }

    addFollowupEmailTemplateAssociation(): void {
        this.emailTemplatesGroupedByFollowup.push({
            EmailTemplates: [],
            FollowupType: null,
        } as IGroupedEmailTemplatesByFollowupDTO);
    }

    deleteFollowupEmailTemplateAssociation(followupEmailTemplateAssociation: IFollowupEmailTemplateAssociation, index: number): void {
        const id = this.filteredFollowupTypeEmailTemplates.find(
            (x) =>
                x.MasterFollowupTypeId === followupEmailTemplateAssociation.Followup.Id &&
                x.EmailTemplateId === followupEmailTemplateAssociation.EmailTemplate.Id,
        ).Id;
        this.followupEmailTemplateService.delete(id).subscribe(() => {
            this.filteredFollowupTypeEmailTemplates = this.filteredFollowupTypeEmailTemplates.filter((x) => x.Id !== id);
            this.emailTemplatesGroupedByFollowup[index].EmailTemplates = this.emailTemplatesGroupedByFollowup[index].EmailTemplates.filter(
                (x) => x.Id !== followupEmailTemplateAssociation.EmailTemplate.Id,
            );
            if (!this.emailTemplatesGroupedByFollowup[index].EmailTemplates.length) {
                this.emailTemplatesGroupedByFollowup.splice(index, 1);
                this.filteredFollowupTypes.push(followupEmailTemplateAssociation.Followup);
            }
            this.notificationService.info('Followup email template association deleted successfully.');
            this.getFilteredEmailTemplates();
        });
    }
}
