import { Validators } from '@angular/forms';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import {
    DynamicField,
    SelectInputTypes,
    DynamicLabel,
    InputTypes,
    DynamicFieldType,
    DynamicFieldTypes,
    NumericInputTypes,
} from '@mt-ng2/dynamic-form';
import { ImedClaimServiceDynamicControls } from '../form-controls/imed-claim-service.form-controls';
import { IImedClaimService } from '../interfaces/imed-claim-service';
import { IImedClaimServiceDynamicControlsParametersPartial } from './imed-claim-service-dynamic-controls.partial';

export class ImedClaimServiceDynamicControlsPartial extends ImedClaimServiceDynamicControls {
    constructor(imedclaimservicePartial?: IImedClaimService, additionalParameters?: IImedClaimServiceDynamicControlsParametersPartial) {
        super(imedclaimservicePartial, additionalParameters);

        (<DynamicField>this.Form.PhysicianId).setRequired(true);
        (<DynamicField>this.Form.ServiceStatusId).labelHtml = '';

        (<DynamicField>this.Form.HighValueService).setRequired(true);
        (<DynamicField>this.Form.HighValueService).labelHtml = 'High Value Service';

        (<DynamicField>this.Form.RushService).setRequired(true);
        (<DynamicField>this.Form.RushService).labelHtml = 'Rush Service';

        (<DynamicField>this.Form.ContractRateFrom).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Numeric,
            inputType: NumericInputTypes.Currency,
            scale: 2,
        });
        (<DynamicField>this.Form.ContractRateFrom).validation = [Validators.min(0.01), Validators.max(100000000000.0)];
        (<DynamicField>this.Form.ContractRateFrom).validators = { min: 0.01, max: 100000000000.0 };

        (<DynamicField>this.Form.ContractRateTo).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Numeric,
            inputType: NumericInputTypes.Currency,
            scale: 2,
        });
        (<DynamicField>this.Form.ContractRateTo).validation = [Validators.min(0.01), Validators.max(100000000000.0)];
        (<DynamicField>this.Form.ContractRateTo).validators = { min: 0.01, max: 100000000000.0 };

        (<DynamicField>this.Form.EligibilityCriteriaId).options = additionalParameters?.eligibilityCriteria
            ? additionalParameters.eligibilityCriteria
            : [];
        (<DynamicField>this.Form.ExamRequestedById).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Select,
            inputType: null,
            scale: null,
        });
        (<DynamicField>this.Form.ExamRequestedById).options = additionalParameters?.examRequestorTypes ? additionalParameters.examRequestorTypes : [];
        (<DynamicLabel>this.View.ExamRequestedById).value = getMetaItemValue(
            additionalParameters?.examRequestorTypes,
            imedclaimservicePartial &&
                imedclaimservicePartial.hasOwnProperty('ExamRequestedById') &&
                imedclaimservicePartial.ExamRequestedById !== null
                ? imedclaimservicePartial.ExamRequestedById
                : null,
        );
        (<DynamicLabel>this.View.Mmi).label = 'MMI';
        (<DynamicField>this.Form.Mmi).labelHtml = '<label>MMI</label>';
    }
}
