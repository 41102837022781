import { IEntityListConfig, EntityListColumn, EntityListConfig } from '@mt-ng2/entity-list-module';
import { EmailAddressDeleteCellComponent } from '../../../address-books/email-address-delete-cell/email-address-delete-cell.component';
import { IEmailAddress } from '../../interfaces/email-address';

export class EmailAddressListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    name: 'Email',
                }),
                new EntityListColumn({
                    accessors: ['EmailType', 'Name'],
                    name: 'EmailType',
                }),
                new EntityListColumn({
                    accessorFunction: (email: IEmailAddress) => (email.IsPrimary ? 'Primary' : ''),
                    name: 'IsPrimary',
                }),
                new EntityListColumn({
                    component: EmailAddressDeleteCellComponent,
                    name: 'Delete',
                    sort: { disableSort: true },
                    style: { width: 50 },
                }),
            ],
        };
        super(listConfig);
    }
}
