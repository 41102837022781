import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues, AuthService } from '@mt-ng2/auth-module';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection, IItemDeletedEvent } from '@mt-ng2/entity-list-module';

import { EmailTemplateService } from '../emailtemplate.service';
import { IEmailTemplate } from '@model/interfaces/email-template';
import { entityListModuleConfig } from '../../common/shared.module';
import { ClaimTypes } from '@model/ClaimTypes';
import { EmailTemplatesEntityListConfig } from './email-templates.entity-list-config';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Component({
    selector: 'app-email-templates',
    templateUrl: './email-templates.component.html',
})
export class EmailTemplatesComponent implements OnInit {
    emailTemplates: IEmailTemplate[];
    currentPage = 1;
    query = '';
    total: number;
    entityListConfig = new EmailTemplatesEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();
    includeArchived = false;
    canAddEmailTemplate = false;

    constructor(
        private emailTemplateService: EmailTemplateService,
        private claimsService: ClaimsService,
        private router: Router,
        private authService: AuthService,
        private notificationsService: NotificationsService,
    ) { }

    ngOnInit(): void {
        this.canAddEmailTemplate = this.claimsService.hasClaim(ClaimTypes.EmailTemplates, [ClaimValues.FullAccess]);
        this.getEmailTemplates();
    }

    getEmailTemplates(): void {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = [];
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'includeArchived',
                value: this.includeArchived ? '1' : '0',
            }),
        );
        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: entityListModuleConfig.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        this.emailTemplateService.search(searchparams).subscribe((answer) => {
            this.emailTemplates = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.query = query;
        this.getEmailTemplates();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getEmailTemplates();
    }

    emailTemplateSelected(event: IItemSelectedEvent): void {
        void this.router.navigate(['/email-templates', event.entity.Id]);
    }

    onItemDeleted(event: IItemDeletedEvent): void {
        const selectedEmailTemplate: IEmailTemplate = event.entity;
        this.emailTemplateService.archive(selectedEmailTemplate.Id).subscribe(() => {
            this.notificationsService.success('Email Template Updated Successfully');
            this.getEmailTemplates();
        });
    }
}
