import { IPhoneType } from '@model/interfaces/base.d';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import 'rxjs/operators';

import { IStatesService, IState, ICountriesService, ICountry } from '@mt-ng2/dynamic-form';
import { tap } from 'rxjs/operators';
import { sortByProperty } from '@mt-ng2/common-functions';

@Injectable()
export class CommonService implements IStatesService, ICountriesService {
    private _states: IState[];
    private _countries: ICountry[];
    private _phoneTypes: IPhoneType[];

    constructor(private http: HttpClient) {}

    getStates(): Observable<IState[]> {
        if (!this._states) {
            return this.http.get<IState[]>('/options/states').pipe(
                tap((answer) => {
                    sortByProperty(answer, 'Name');
                    this._states = answer;
                }),
            );
        } else {
            return of(this._states);
        }
    }

    getCountries(): Observable<ICountry[]> {
        if (!this._countries) {
            return this.http.get<ICountry[]>('/options/countries').pipe(
                tap((answer) => {
                    sortByProperty(answer, 'Name');
                    const indexOfUS = answer.findIndex((country) => country.CountryCode === 'US');
                    answer.splice(0, 0, answer.splice(indexOfUS, 1)[0]);
                    this._countries = answer;
                }),
            );
        } else {
            return of(this._countries);
        }
    }

    getPhoneTypes(): Observable<IPhoneType[]> {
        if (!this._countries) {
            return this.http.get<IPhoneType[]>('/options/phoneTypes').pipe(
                tap((answer) => {
                    sortByProperty(answer, 'Name');
                    this._phoneTypes = answer;
                }),
            );
        } else {
            return of(this._phoneTypes);
        }
    }

    stripHtml(stringWithHTML: string): string {
        return stringWithHTML
            .trim()
            .replace(/<("[^"]*"|'[^']*'|[^'">])*>/gi, '')
            .replace(/^\s+|\s+$/g, '')
            .replace(/(\r\n|\n|\r)/gm, '');
    }
}
