import { Component, Input } from '@angular/core';
import { AddressBooksSelectService } from '../../common/address-book-select-list/address-books-select.service';
import { IAddressBookSelectEntity } from '../../common/address-book-select-list/address-books-select-entity';
import { IImedClaimAdditionalBillToPartyDTO } from '@model/interfaces/custom/imed-claim-additional-btp.dto';
import { ImedClaimAdditionalBillToPartyService } from '../imed-claim-additional-btp/imedclaimadditionalbilltoparty.service';

@Component({
    selector: 'app-imed-claim-carrier-party',
    templateUrl: './imed-claim-carrier-party.component.html',
})
export class ImedClaimCarrierPartyComponent {
    @Input() CarrierParty: IImedClaimAdditionalBillToPartyDTO;
    @Input() index: number;

    constructor(
        private addressSelectService: AddressBooksSelectService,
        private imedClaimAdditionalBTPService: ImedClaimAdditionalBillToPartyService,
    ) {}

    ClearAddressBooks(): void {
        this.imedClaimAdditionalBTPService.newBillToParties.splice(this.index, 1);
        if (this.imedClaimAdditionalBTPService.newBillToParties.length === 0) {
            this.imedClaimAdditionalBTPService.newBillToParties.push(this.imedClaimAdditionalBTPService.getEmptyImedClaimAdditionalBillToParty());
        }
    }

    SearchAddressBooks(): void {
        this.addressSelectService.openAddressBookSelect('CarrierParty', this.select);
    }

    select = (addressBookSelect: IAddressBookSelectEntity): void => {
        this.CarrierParty.AddressBookId = addressBookSelect.AddressBookId;
        this.CarrierParty.IndividualId = addressBookSelect.IndividualId;
        this.CarrierParty.Name =
            addressBookSelect.IndividualName && addressBookSelect.IndividualName.length
                ? addressBookSelect.IndividualName
                : addressBookSelect.AddressBookName;
    }
}
