import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ImedClaimTreatingPhysicianService } from './imed-claim-treating-physicians.service';
import { IImedClaimTreatingPhysicianDTO } from '@model/interfaces/custom/imed-claim-treating-physician.dto';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { IImedClaimTreatingPhysician } from '@model/interfaces/imed-claim-treating-physician';

@Component({
    selector: 'app-imed-claim-treating-physicians',
    styles: [
        `
            .custom-padded {
                margin: 0 0 0.5em 0;
            }
            .small-text {
                font-size: 12px;
            }
        `,
    ],
    templateUrl: './imed-claim-treating-physicians.component.html',
})
export class ImedClaimTreatingPhysiciansComponent implements OnInit, OnDestroy {
    @Input() imedClaimId: number;
    @Input() canEdit: boolean;
    isHovered = false;
    doubleClickIsDisabled = false;

    treatingPhysicians: IImedClaimTreatingPhysicianDTO[] = [];
    _imedClaimTreatingPhysiciansService: ImedClaimTreatingPhysicianService;

    _isEditing: boolean;
    public set isEditing(v: boolean) {
        this._isEditing = v;
    }

    public get isEditing(): boolean {
        return this._isEditing;
    }

    constructor(
        private imedClaimTreatingPhysiciansService: ImedClaimTreatingPhysicianService,
        private notificationsService: NotificationsService,
        private router: Router,
    ) {
        this._imedClaimTreatingPhysiciansService = imedClaimTreatingPhysiciansService;
    }

    ngOnInit(): void {
        this.isEditing = false;
        this.getTreatingPhysicians();
        this.addMore();
    }

    ngOnDestroy(): void {
        this.imedClaimTreatingPhysiciansService.resetTreatingPhysicians();
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    addMore(): void {
        if (this.imedClaimTreatingPhysiciansService.newTreatingPhysicians.length === 0 || this.isTreatingPhysicianSelected()) {
            this.imedClaimTreatingPhysiciansService.newTreatingPhysicians.push(
                this.imedClaimTreatingPhysiciansService.getEmptyImedClaimTreatingPhysician(),
            );
        }
    }

    isTreatingPhysicianSelected(): boolean {
        return (
            this.imedClaimTreatingPhysiciansService.newTreatingPhysicians.length > 0 &&
            !(this.imedClaimTreatingPhysiciansService.newTreatingPhysicians.filter((party) => party.AddressBookId === 0).length !== 0)
        );
    }

    getTreatingPhysicians(): void {
        this.imedClaimTreatingPhysiciansService
            .getTreatingPhysicians(this.imedClaimId)
            .subscribe((incomingDTOs: IImedClaimTreatingPhysicianDTO[]) => {
                this.treatingPhysicians = incomingDTOs;
            });
    }

    cancel(): void {
        this.isEditing = false;
    }

    save(): void {
        if (this.imedClaimTreatingPhysiciansService.newTreatingPhysicians[0].AddressBookId === 0) {
            this.notificationsService.error('You need to add at least one Treating Physician');
        } else {
            this.imedClaimTreatingPhysiciansService
                .AddTreatingPhysician(this.imedClaimId, this.imedClaimTreatingPhysiciansService.newTreatingPhysicians)
                .subscribe(() => {
                    this.notificationsService.success('Treating Physician added successfully');
                    this.imedClaimTreatingPhysiciansService.newTreatingPhysicians = [];
                    this.imedClaimTreatingPhysiciansService.newTreatingPhysicians.push(
                        this.imedClaimTreatingPhysiciansService.getEmptyImedClaimTreatingPhysician(),
                    );
                    this.isEditing = false;
                    this.getTreatingPhysicians();
                });
        }
    }

    remove(item: IImedClaimTreatingPhysicianDTO, event: Event): void {
        if (event && event.stopPropagation) {
            event.stopPropagation();
        }

        this.imedClaimTreatingPhysiciansService.delete(item.Id).subscribe(() => {
            this.getTreatingPhysicians();
            this.notificationsService.success('Treating Physician deleted successfully');
        });
    }

    NavigateToAddressBook(item: IImedClaimTreatingPhysicianDTO, event: Event): void {
        if (event && event.stopPropagation) {
            event.stopPropagation();
        }
        if (item.IndividualId) {
            void this.router.navigate([`address-books/${item.AddressBookId}/individuals/${item.IndividualId}`], {
                queryParams: { caseId: this.imedClaimId },
            });
        } else {
            void this.router.navigate([`address-books/${item.AddressBookId}`], { queryParams: { caseId: this.imedClaimId } });
        }
    }

    updateAddedToCaseDate(item: IImedClaimTreatingPhysicianDTO, event: Event): void {
        if (event && event.stopPropagation) {
            event.stopPropagation();
        }
        const tzoffset = new Date(item.DateAddedToImedClaim).getTimezoneOffset() * 60000;
        const localISOTime = new Date(new Date(item.DateAddedToImedClaim).getTime() - tzoffset).toISOString().split('.')[0];
        const currentISOTime = new Date(Date.now() - tzoffset).toISOString().split('.')[0];
        void Swal.fire({
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Update',
            html: `Please enter new "Added to case" date for this treating physician <br/> <input value=${localISOTime} type="datetime-local" max="${currentISOTime}" id="datepicker">`,
            preConfirm: function (): string {
                return (<HTMLInputElement>document.getElementById('datepicker')).value;
            },
            showCancelButton: true,
            title: 'Enter new date',
            width: '400px',
        }).then((answer) => {
            if (!answer.isConfirmed) {
                return;
            }
            item.DateAddedToImedClaim = new Date(answer.value as string);
            this._imedClaimTreatingPhysiciansService.updateTreatingPhysician(item).subscribe((answer) => this.getTreatingPhysicians());
        });
    }
}
