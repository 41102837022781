import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, IRoleGuarded, ClaimValues } from '@mt-ng2/auth-module';

import { AutomatedTemplateAddComponent } from './automated-template-add/automated-template-add.component';
import { AutomatedTemplateDetailComponent } from './automated-template-detail/automated-template-detail.component';
import { AutomatedTemplatesComponent } from './automated-template-list/automated-templates.component';
import { AutomatedTemplateService } from './services/automated-template.service';
import { AutomatedTemplateHeaderComponent } from './automated-template-header/automated-template-header.component';

import { ClaimTypes } from '@model/ClaimTypes';

const automatedTemplateEntityConfig = {
    claimType: ClaimTypes.AutomatedTemplates,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'automatedTemplateId',
    service: AutomatedTemplateService,
    title: 'AutomatedTemplate Detail',
};

const automatedTemplateListRoleGuard: any = {
    claimType: ClaimTypes.AutomatedTemplates,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'AutomatedTemplates',
};

const automatedTemplateAddRoleGuard: IRoleGuarded = {
    claimType: ClaimTypes.AutomatedTemplates,
    claimValues: [ClaimValues.FullAccess],
};

export const automatedTemplatePathSlugs = {
    add: 'add',
    root: 'dynamic-templates',
};

export const automatedTemplatePaths = {
    automatedTemplates: automatedTemplatePathSlugs.root,
    automatedTemplatesAdd: `${automatedTemplatePathSlugs.root}/${automatedTemplatePathSlugs.add}`,
};

const automatedTemplateRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: AutomatedTemplatesComponent,
        data: automatedTemplateListRoleGuard,
        path: automatedTemplatePaths.automatedTemplates,
    },
    {
        canActivate: [AuthGuard],
        children: [{ component: AutomatedTemplateAddComponent, path: '', pathMatch: 'full' }],
        component: AutomatedTemplateHeaderComponent,
        data: automatedTemplateAddRoleGuard,
        path: automatedTemplatePaths.automatedTemplatesAdd,
    },
    {
        canActivate: [AuthGuard],
        children: [{ component: AutomatedTemplateDetailComponent, path: '', pathMatch: 'full' }],
        component: AutomatedTemplateHeaderComponent,
        data: automatedTemplateEntityConfig,
        path: `${automatedTemplatePaths.automatedTemplates}/:${automatedTemplateEntityConfig.entityIdParam}`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(automatedTemplateRoutes)],
})
export class AutomatedTemplateRoutingModule {}
