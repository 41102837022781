import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, OperatorFunction } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { BaseService } from '@mt-ng2/base-service';
import { INote } from '@mt-ng2/note-control';
import { IHasNotes } from '@mt-ng2/entity-components-notes';
import { SearchParams } from '@mt-ng2/common-classes';

@Injectable({
    providedIn: 'root',
})
export class ImedClaimServiceNotesService extends BaseService<INote> implements IHasNotes {
    constructor(public http: HttpClient) {
        super('/imedclaimservices', http);
    }

    getNotes(imedClaimServiceId: number, searchparameters: SearchParams): Observable<HttpResponse<INote[]>> {
        const params = this.getHttpParams(searchparameters);
        return this.http
            .get<INote[]>(`/imedclaimservices/${imedClaimServiceId}/notes/_search`, {
                observe: 'response',
                params: params,
            })
            .pipe(catchError((e: Response) => this.handleError(e)) as OperatorFunction<unknown, any>);
    }

    saveNote(imedClaimServiceId: number, note: INote): Observable<number> {
        if (!note.Id) {
            note.Id = 0;
            return this.http.post<number>(`/imedclaimservices/${imedClaimServiceId}/notes`, note);
        } else {
            return this.http.put<number>(`/imedclaimservices/${imedClaimServiceId}/notes`, note, { responseType: 'text' as 'json' });
        }
    }

    deleteNote(imedClaimServiceId: number, noteId: number): Observable<object> {
        return this.http.delete(`/imedclaimservices/${imedClaimServiceId}/notes/${noteId}`, {
            responseType: 'text' as 'json',
        });
    }
}
