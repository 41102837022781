import { IUserRole } from '@model/interfaces/user-role';
import { Permission } from './user-role-permissions/permissions.library';
import { IUserRoleClaim } from '@model/interfaces/user-role-claim';
import { IAuthUser } from '@model/interfaces/auth-user';

export class UserRole implements IUserRole {
    AssignManualContactTask: boolean;
    ClientCaseCreateEmail: boolean;
    ClientCaseUpdateEmail: boolean;
    Id: number;
    Name: string;
    Description: string;
    IsEditable: boolean;
    TaskDistributionList: boolean;
    SchedulingAssignedTo: boolean;
    CompilationAssignedTo: boolean;
    ReceiveAutoCaseClosingJobEmail: boolean;
    RetrievalAssignedTo: boolean;
    ReportAssignedTo: boolean;
    RadiologyAssignedTo: boolean;
    GeneralLiability: boolean;
    WorkersComp: boolean;
    Auto: boolean;
    ReceiveCpFilmDeleteEmail: boolean;
    AllowToSendAsAttachment: boolean;
    SubpoenaAssignedTo: boolean;
    ClientPortalSubpoenaServiceTask: boolean;
    AssignTaskOnPhysicianExpertDocUpdate: boolean;
    ClientPortalRequestEmails: boolean;
    PhysicianExpertPortalRequestEmails: boolean;
    ClientAccess: boolean;

    // reverse nav
    AuthUsers?: IAuthUser[];
    UserRoleClaims?: IUserRoleClaim[];
    DashboardTypeId: number;
    UserTypeId: number;

    constructor(public userRole: IUserRole) {
        Object.assign(this, userRole);
    }

    /**
     * update the UserRoleClaims using a passed in Permission
     * @param permission
     */
    updateUserRoleClaims(permission: Permission): void {
        const existing = this.UserRoleClaims.find((urc) => urc.ClaimTypeId === permission.claimType.Id);
        const index = existing ? this.UserRoleClaims.indexOf(existing) : this.UserRoleClaims.length;
        if (permission.value === 0) {
            this.UserRoleClaims.splice(index, 1);
        } else {
            const claim: IUserRoleClaim = {
                ClaimTypeId: permission.claimType.Id,
                ClaimValueId: permission.value,
                RoleId: this.Id,
            };
            this.UserRoleClaims[index] = claim;
        }
    }
}
