import { FilmReviewServiceDynamicControlsPartial } from '@model/partials/film-review-service.form-controls.partial';
import {
    IFilmReviewServiceDynamicControlsParameters,
    FilmReviewServiceDynamicControls,
} from '@model/form-controls/film-review-service.form-controls';
import { IFilmReviewService } from '@model/interfaces/film-review-service.d';
import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';

export class FilmReviewServiceDynamicConfig<T extends IFilmReviewService> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private filmReviewService: T, private configControls?: string[]) {
        super();
        const additionalParams: IFilmReviewServiceDynamicControlsParameters = {};

        const dynamicControls = new FilmReviewServiceDynamicControlsPartial(this.filmReviewService, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = [
                'ImedClaimServiceId',
                'RadiologyFacility',
                'RequestDate',
                'DiscCopiedDate',
                'DiscCopiedById',
                'IsOriginalFiledForReference',
                'DiscMailedDate',
                'DiscCopiedById',
                'DiscMailedDate',
                'DiscMailedById',
                'IsDiscRetrievedByImedview',
                'CreatedById',
                'ModifiedById',
                'DateCreated',
                'DateModified',
                'Archived',
            ];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
