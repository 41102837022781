import { IFilmReviewService } from '@model/interfaces/film-review-service';
import { IFilmInformation } from '@model/interfaces/film-information';
import { IImedClaimService } from '@model/interfaces/imed-claim-service';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import 'rxjs/operators';

@Component({
    selector: 'app-film-review-film-retrieval-information',
    templateUrl: './film-retrieval-information.component.html',
})
export class FilmReviewFilmRetrievalInformationComponent {
    @Input() filmReviewServiceModel: IFilmReviewService;
    @Input() iImedClaimServiceModel: IImedClaimService;
    @Input() canEdit: boolean;
    @Input() filmInformations: IFilmInformation[];
    @Output('refreshFilmInformation') refreshFilmInformation: EventEmitter<any> = new EventEmitter<any>();

    isEditing: boolean;
    isHovered: boolean;

    constructor() { }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    rebindFilmInformation(): void {
        this.refreshFilmInformation.emit();
    }

    cancelClick(): void {
        this.isEditing = false;
    }
}
