import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from '@mt-ng2/base-service';
import { IMergeField } from '@model/interfaces/merge-field';

export const emptyMergeField: IMergeField = {
    Id: 0,
    MergeFieldTypeId: null,
    Name: null,
};

@Injectable()
export class MergeFieldService extends BaseService<IMergeField> {
    constructor(public http: HttpClient) {
        super('/mergefields', http);
    }

    getEmptyMergeField(): IMergeField {
        return { ...emptyMergeField };
    }

    getDynamicTemplateFields(): Observable<IMergeField[]> {
        return this.http.get<IMergeField[]>('/mergefields/dynamic-template');
    }
}
