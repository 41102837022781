import { MetaAddressBookTypeIdEnums } from './../../common/constants/Enums';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { IAddressBook } from '@model/interfaces/address-book';
import { AddressBookService } from '../addressbook.service';
import { IAddressBookType } from '@model/interfaces/address-book-type';
import { MetaAddressBookTypeService } from '../metaaddressbooktype.service';
import { ClaimTypes } from '@model/ClaimTypes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { Title } from '@angular/platform-browser';
import { CommonEmailComponent } from '@common/common-email/common-email.component';
import { Location } from '@angular/common';
import { ExtraSearchParams, IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { EmailTemplateService } from '../../email-templates/emailtemplate.service';
import { BaseAttachmentsService } from '@common/attachments/base-attachments.service';

@Component({
    selector: 'app-address-book-header',
    styles: [
        `
            .back-button {
                padding-left: 4px;
                padding-right: 6px;
                padding-top: 3px;
                padding-bottom: 0;
                margin-top: -3px;
            }
            .back-button-description {
                margin-left: -8px;
            }
            .mt-15 {
                margin-top: 15px;
            }
        `,
    ],
    templateUrl: './address-book-header.component.html',
})
export class AddressBookHeaderComponent implements OnInit, OnDestroy {
    addressBook: IAddressBook;
    header: string;
    subscriptions: Subscription = new Subscription();
    isOfTypeIndividual = false;

    addressBookTypes: IAddressBookType[];
    canSeeAuditLog = false;
    previousSiteTitle: string;

    imedClaimIdBackNavigation: string;
    showBackToCaseButton = false;
    showOtherBackButtons = true;

    @ViewChild('emailComponent') emailComponent: CommonEmailComponent;
    showEmailControl = false;

    addressBookId: number;
    individualId: number;

    constructor(
        private addressBookService: AddressBookService,
        private metaAddressBookTypeService: MetaAddressBookTypeService,
        private route: ActivatedRoute,
        private router: Router,
        private claimsService: ClaimsService,
        private titleService: Title,
        private location: Location,
        private emailTemplateService: EmailTemplateService,
        private attachmentsService: BaseAttachmentsService,
    ) {}

    ngOnInit(): void {
        this.previousSiteTitle = this.titleService.getTitle();
        this.canSeeAuditLog = this.claimsService.hasClaim(ClaimTypes.Audit, [ClaimValues.FullAccess, ClaimValues.ReadOnly]);
        this.subscriptions.add(
            this.addressBookService.changeEmitted$.subscribe((addressBook) => {
                this.setHeader(addressBook);
            }),
        );
        const addressBookId = +this.route.snapshot.paramMap.get('addressBookId');
        if (addressBookId > 0) {
            this.addressBookId = addressBookId;
            this.addressBookService.getById(addressBookId).subscribe((addressBook) => {
                this.isOfTypeIndividual = addressBook && addressBook.MetaAddressBookTypeId === +MetaAddressBookTypeIdEnums.Individual;
                this.addressBook = addressBook;
                this.setHeader(addressBook);
                if (this.route.snapshot.queryParamMap.get('caseId')) {
                    this.imedClaimIdBackNavigation = this.route.snapshot.queryParamMap.get('caseId');
                    this.showBackToCaseButton = true;
                    this.showOtherBackButtons = false;
                }
            });
            const individualId = +this.location.path().split('/').pop(); // get the last fragment of URL
            if (individualId > 0 && individualId !== addressBookId) {
                // check whether last fragment is addressBookId, if not, assign it to individualId
                this.individualId = individualId;
            }
            this.subscriptions.add(
                this.router.events.subscribe((event) => {
                    if (event instanceof NavigationEnd) {
                        this.resetEmailButton();
                    }
                }),
            );
        } else {
            this.header = 'Add Address Book';
            this.addressBook = this.addressBookService.getEmptyAddressBook();
            this.metaAddressBookTypeService.getItems().subscribe((items) => {
                this.addressBookTypes = items;
                this.ensureCorrectAddressBookTypeIsSelected(this.router.url);
                this.navigateByAddressBookType();
            });
            this.subscriptions.add(
                this.router.events.subscribe((event) => {
                    if (event instanceof NavigationEnd) {
                        this.ensureCorrectAddressBookTypeIsSelected(event.url);
                    }
                }),
            );
        }
    }

    resetEmailButton(): void {
        const addressBookId = +this.route.snapshot.paramMap.get('addressBookId');
        if (addressBookId > 0) {
            this.addressBookId = addressBookId;
            const individualId = +this.location.path().split('/').pop(); // get the last fragment of URL
            if (individualId > 0 && individualId !== addressBookId) {
                // check whether last fragment is addressBookId, if not, assign it to individualId
                this.individualId = individualId;
            } else {
                this.individualId = undefined;
            }
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        this.titleService.setTitle(this.previousSiteTitle);
    }

    ensureCorrectAddressBookTypeIsSelected(url: string): void {
        const urlFragment = url.slice(url.lastIndexOf('/') + 1, url.length);
        const addressBookTypeId = this.getAddressBookTypeIdBasedOnUrlFragment(urlFragment);
        if (addressBookTypeId) {
            this.addressBook.MetaAddressBookTypeId = addressBookTypeId;
        }
    }

    navigateByAddressBookType(): void {
        const urlFragment = this.getUrlFragmentBasedOnAddressBookTypeId(this.addressBook.MetaAddressBookTypeId);
        const url = `/address-books/add/${urlFragment}`;
        void this.router.navigateByUrl(url);
    }

    getUrlFragmentBasedOnAddressBookTypeId(addressBookTypeId: number): string {
        switch (addressBookTypeId as MetaAddressBookTypeIdEnums) {
            case MetaAddressBookTypeIdEnums.Individual:
                return 'individual';
            case MetaAddressBookTypeIdEnums.Facility_Organization:
                return 'facility';
            default:
                return '';
        }
    }

    getAddressBookTypeIdBasedOnUrlFragment(urlFragment: string): number {
        switch (urlFragment) {
            case 'individual':
                return MetaAddressBookTypeIdEnums.Individual;
            case 'facility':
                return MetaAddressBookTypeIdEnums.Facility_Organization;
            default:
                return 0;
        }
    }

    setHeader(addressBook: IAddressBook): void {
        this.addressBook = addressBook;
        this.header = `Address Book: ${addressBook.FacilityName}`;
        this.titleService.setTitle(`${addressBook.FacilityName} - Viewpoint`);
    }

    addressBookTypeChanged(): void {
        this.navigateByAddressBookType();
    }

    openEmailControl(): void {
        this.showEmailControl = true;
        const _extraSearchParams: ExtraSearchParams[] = [];
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'includeArchived',
                value: '0',
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'addressBookTypeId',
                value: this.addressBook.AddressBookTypeId.toString(),
            }),
        );

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: 'Id',
            orderDirection: 'asc',
            query: '',
        };

        const searchparams = new SearchParams(searchEntity);
        this.emailTemplateService.search(searchparams).subscribe(({ body: templates }) => {
            this.emailComponent.emailTemplates = templates;
            if (templates.length === 1) {
                this.emailComponent.selectedTemplate = this.emailComponent.emailTemplates[0];
            }
            this.emailComponent.showEmailForm();
        });
    }

    refreshDocuments(): void {
        this.attachmentsService.requestRefresh();
    }
}
