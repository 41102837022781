import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IFilmReviewService } from '../interfaces/film-review-service';
import { IUser } from '../interfaces/user';
import { IImedClaimService } from '../interfaces/imed-claim-service';

export interface IFilmReviewServiceDynamicControlsParameters {
    formGroup?: string;
    imedClaimServices?: IImedClaimService[];
    discCopiedBies?: IUser[];
    discMailedBies?: IUser[];
    createdBies?: IUser[];
    modifiedBies?: IUser[];
    requestDateChangedBies?: IUser[];
}

export class FilmReviewServiceDynamicControls {

    formGroup: string;
    imedClaimServices: IImedClaimService[];
    discCopiedBies: IUser[];
    discMailedBies: IUser[];
    createdBies: IUser[];
    modifiedBies: IUser[];
    requestDateChangedBies: IUser[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private filmreviewservice?: IFilmReviewService, additionalParameters?: IFilmReviewServiceDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'FilmReviewService';
        this.imedClaimServices = additionalParameters && additionalParameters.imedClaimServices || undefined;
        this.discCopiedBies = additionalParameters && additionalParameters.discCopiedBies || undefined;
        this.discMailedBies = additionalParameters && additionalParameters.discMailedBies || undefined;
        this.createdBies = additionalParameters && additionalParameters.createdBies || undefined;
        this.modifiedBies = additionalParameters && additionalParameters.modifiedBies || undefined;
        this.requestDateChangedBies = additionalParameters && additionalParameters.requestDateChangedBies || undefined;

        this.Form = {
            Archived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived',
                name: 'Archived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.filmreviewservice && this.filmreviewservice.hasOwnProperty('Archived') && this.filmreviewservice.Archived !== null ? this.filmreviewservice.Archived : false,
            }),
            CreatedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Created By',
                name: 'CreatedById',
                options: this.createdBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.filmreviewservice && this.filmreviewservice.CreatedById || null,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.filmreviewservice && this.filmreviewservice.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.filmreviewservice && this.filmreviewservice.DateModified || null,
            }),
            DiscCopiedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Disc Copied By',
                name: 'DiscCopiedById',
                options: this.discCopiedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.filmreviewservice && this.filmreviewservice.DiscCopiedById || null,
            }),
            DiscCopiedDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Disc Copied Date',
                name: 'DiscCopiedDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.filmreviewservice && this.filmreviewservice.DiscCopiedDate || null,
            }),
            DiscMailedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Disc Mailed By',
                name: 'DiscMailedById',
                options: this.discMailedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.filmreviewservice && this.filmreviewservice.DiscMailedById || null,
            }),
            DiscMailedDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Disc Mailed Date',
                name: 'DiscMailedDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.filmreviewservice && this.filmreviewservice.DiscMailedDate || null,
            }),
            HasPreLitigation: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Pre Litigation',
                name: 'HasPreLitigation',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.filmreviewservice && this.filmreviewservice.hasOwnProperty('HasPreLitigation') && this.filmreviewservice.HasPreLitigation !== null ? this.filmreviewservice.HasPreLitigation : false,
            }),
            ImedClaimServiceId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Imed Claim Service',
                name: 'ImedClaimServiceId',
                options: this.imedClaimServices,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.filmreviewservice && this.filmreviewservice.ImedClaimServiceId || null,
            }),
            IsFederalCase: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Federal Case',
                name: 'IsFederalCase',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.filmreviewservice && this.filmreviewservice.hasOwnProperty('IsFederalCase') && this.filmreviewservice.IsFederalCase !== null ? this.filmreviewservice.IsFederalCase : false,
            }),
            LegacyImedClaimServiceId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Legacy Imed Claim Service',
                name: 'LegacyImedClaimServiceId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.filmreviewservice && this.filmreviewservice.hasOwnProperty('LegacyImedClaimServiceId') && this.filmreviewservice.LegacyImedClaimServiceId !== null ? this.filmreviewservice.LegacyImedClaimServiceId.toString() : '',
            }),
            ModifiedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Modified By',
                name: 'ModifiedById',
                options: this.modifiedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.filmreviewservice && this.filmreviewservice.ModifiedById || null,
            }),
            OriginalFiledForReference: new DynamicField({
                formGroup: this.formGroup,
                label: 'Original Filed For Reference',
                name: 'OriginalFiledForReference',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.filmreviewservice && this.filmreviewservice.hasOwnProperty('OriginalFiledForReference') && this.filmreviewservice.OriginalFiledForReference !== null ? this.filmreviewservice.OriginalFiledForReference : false,
            }),
            RadiologyFacility: new DynamicField({
                formGroup: this.formGroup,
                label: 'Radiology Facility',
                name: 'RadiologyFacility',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.filmreviewservice && this.filmreviewservice.hasOwnProperty('RadiologyFacility') && this.filmreviewservice.RadiologyFacility !== null ? this.filmreviewservice.RadiologyFacility.toString() : '',
            }),
            RequestDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Request Date',
                name: 'RequestDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.filmreviewservice && this.filmreviewservice.RequestDate || null,
            }),
            RequestDateChangedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Request Date Changed By',
                name: 'RequestDateChangedById',
                options: this.requestDateChangedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.filmreviewservice && this.filmreviewservice.RequestDateChangedById || null,
            }),
            RequestDateChangedDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Request Date Changed Date',
                name: 'RequestDateChangedDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.filmreviewservice && this.filmreviewservice.RequestDateChangedDate || null,
            }),
            RetrievedByImedview: new DynamicField({
                formGroup: this.formGroup,
                label: 'Retrieved By Imedview',
                name: 'RetrievedByImedview',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.filmreviewservice && this.filmreviewservice.hasOwnProperty('RetrievedByImedview') && this.filmreviewservice.RetrievedByImedview !== null ? this.filmreviewservice.RetrievedByImedview : false,
            }),
        };

        this.View = {
            Archived: new DynamicLabel({
			    label: 'Archived',
			    value: this.filmreviewservice && this.filmreviewservice.hasOwnProperty('Archived') && this.filmreviewservice.Archived !== null ? this.filmreviewservice.Archived : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            CreatedById: new DynamicLabel({
			    label: 'Created By',
			    value: getMetaItemValue(this.createdBies as unknown as IMetaItem[], this.filmreviewservice && this.filmreviewservice.hasOwnProperty('CreatedById') && this.filmreviewservice.CreatedById !== null ? this.filmreviewservice.CreatedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            DateCreated: new DynamicLabel({
			    label: 'Date Created',
			    value: this.filmreviewservice && this.filmreviewservice.DateCreated || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DateModified: new DynamicLabel({
			    label: 'Date Modified',
			    value: this.filmreviewservice && this.filmreviewservice.DateModified || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DiscCopiedById: new DynamicLabel({
			    label: 'Disc Copied By',
			    value: getMetaItemValue(this.discCopiedBies as unknown as IMetaItem[], this.filmreviewservice && this.filmreviewservice.hasOwnProperty('DiscCopiedById') && this.filmreviewservice.DiscCopiedById !== null ? this.filmreviewservice.DiscCopiedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            DiscCopiedDate: new DynamicLabel({
			    label: 'Disc Copied Date',
			    value: this.filmreviewservice && this.filmreviewservice.DiscCopiedDate || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DiscMailedById: new DynamicLabel({
			    label: 'Disc Mailed By',
			    value: getMetaItemValue(this.discMailedBies as unknown as IMetaItem[], this.filmreviewservice && this.filmreviewservice.hasOwnProperty('DiscMailedById') && this.filmreviewservice.DiscMailedById !== null ? this.filmreviewservice.DiscMailedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            DiscMailedDate: new DynamicLabel({
			    label: 'Disc Mailed Date',
			    value: this.filmreviewservice && this.filmreviewservice.DiscMailedDate || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            HasPreLitigation: new DynamicLabel({
			    label: 'Has Pre Litigation',
			    value: this.filmreviewservice && this.filmreviewservice.hasOwnProperty('HasPreLitigation') && this.filmreviewservice.HasPreLitigation !== null ? this.filmreviewservice.HasPreLitigation : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ImedClaimServiceId: new DynamicLabel({
			    label: 'Imed Claim Service',
			    value: getMetaItemValue(this.imedClaimServices as unknown as IMetaItem[], this.filmreviewservice && this.filmreviewservice.hasOwnProperty('ImedClaimServiceId') && this.filmreviewservice.ImedClaimServiceId !== null ? this.filmreviewservice.ImedClaimServiceId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            IsFederalCase: new DynamicLabel({
			    label: 'Is Federal Case',
			    value: this.filmreviewservice && this.filmreviewservice.hasOwnProperty('IsFederalCase') && this.filmreviewservice.IsFederalCase !== null ? this.filmreviewservice.IsFederalCase : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            LegacyImedClaimServiceId: new DynamicLabel({
			    label: 'Legacy Imed Claim Service',
			    value: this.filmreviewservice && this.filmreviewservice.hasOwnProperty('LegacyImedClaimServiceId') && this.filmreviewservice.LegacyImedClaimServiceId !== null ? this.filmreviewservice.LegacyImedClaimServiceId.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ModifiedById: new DynamicLabel({
			    label: 'Modified By',
			    value: getMetaItemValue(this.modifiedBies as unknown as IMetaItem[], this.filmreviewservice && this.filmreviewservice.hasOwnProperty('ModifiedById') && this.filmreviewservice.ModifiedById !== null ? this.filmreviewservice.ModifiedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            OriginalFiledForReference: new DynamicLabel({
			    label: 'Original Filed For Reference',
			    value: this.filmreviewservice && this.filmreviewservice.hasOwnProperty('OriginalFiledForReference') && this.filmreviewservice.OriginalFiledForReference !== null ? this.filmreviewservice.OriginalFiledForReference : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            RadiologyFacility: new DynamicLabel({
			    label: 'Radiology Facility',
			    value: this.filmreviewservice && this.filmreviewservice.hasOwnProperty('RadiologyFacility') && this.filmreviewservice.RadiologyFacility !== null ? this.filmreviewservice.RadiologyFacility.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            RequestDate: new DynamicLabel({
			    label: 'Request Date',
			    value: this.filmreviewservice && this.filmreviewservice.RequestDate || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            RequestDateChangedById: new DynamicLabel({
			    label: 'Request Date Changed By',
			    value: getMetaItemValue(this.requestDateChangedBies as unknown as IMetaItem[], this.filmreviewservice && this.filmreviewservice.hasOwnProperty('RequestDateChangedById') && this.filmreviewservice.RequestDateChangedById !== null ? this.filmreviewservice.RequestDateChangedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            RequestDateChangedDate: new DynamicLabel({
			    label: 'Request Date Changed Date',
			    value: this.filmreviewservice && this.filmreviewservice.RequestDateChangedDate || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            RetrievedByImedview: new DynamicLabel({
			    label: 'Retrieved By Imedview',
			    value: this.filmreviewservice && this.filmreviewservice.hasOwnProperty('RetrievedByImedview') && this.filmreviewservice.RetrievedByImedview !== null ? this.filmreviewservice.RetrievedByImedview : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };

    }
}
