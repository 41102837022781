import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export enum AssociationTypeEnums {
    FOLLOWUP_TYPE = 1,
    EMAIL_ASSOCIATIONS = 2,
}

@Injectable()
export class ImedClaimTypeAssociationsToggleService {
    private _associationType: BehaviorSubject<AssociationTypeEnums> = new BehaviorSubject<AssociationTypeEnums>(AssociationTypeEnums.FOLLOWUP_TYPE);

    constructor() {}

    setAssociationType(value: AssociationTypeEnums): void {
        this._associationType.next(value);
    }

    getAssociationType(): Observable<AssociationTypeEnums> {
        return this._associationType.asObservable();
    }
}
