import { Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, IDynamicFieldType, InputTypes } from '@mt-ng2/dynamic-form';
import { CommonNotesComponent } from '@mt-ng2/entity-components-notes';
import { MtNoteComponent } from '@mt-ng2/note-control';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-adjuster-inquiries-note',
    templateUrl: './adjuster-inquiry-note.component.html',
})
export class ImedClaimAdjusterInquiriesNoteComponent extends MtNoteComponent {
    @Output('onDeleteClick') onDeleteClick: EventEmitter<number> = new EventEmitter<number>();
    subscriptions$ = new Subscription();
    loadForm(): void {
        if (!this.parentForm) {
            this.parentForm = new UntypedFormGroup({});
        }

        this.parentForm.addControl('Id', new UntypedFormControl(this.note.Id));

        this.titleField = new DynamicField({
            formGroup: '',
            label: 'Subject',
            name: 'Title',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Textbox,
            } as IDynamicFieldType),
            value: 'Adjuster Inquiry',
        });
        this.titleField.setRequired(true);

        this.noteTextField = new DynamicField({
            formGroup: '',
            label: '',
            labelHtml: '',
            name: 'NoteText',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Textarea,
            } as IDynamicFieldType),
            value: this.note.NoteText,
        });
    }

    delete(): void {
        this.onDeleteClick.emit(this.note.Id);
    }

    ngAfterViewInit(): void {
        this.formCreated();
    }

    formCreated(): void {
        this.subscriptions$.add(
            this.parentForm.controls.NoteText.valueChanges.subscribe((NoteText) => {
                this.note.NoteText = NoteText;
            }),
        );
    }
}
