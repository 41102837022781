// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    .pretty-select-wrapper {
        font-size: 16px;
        position: relative;
        border-radius:5px;
        transition: .4s;
        box-shadow: 0 2px 5px 0 rgba(0,0,0,.2), 0 3px 10px 0 rgba(0,0,0,.18824);
    }

    .pretty-select-wrapper:hover {
        box-shadow: 0 8px 20px  0 rgba(0,0,0,.2), 0 4px 15px 0 rgba(0,0,0,.18824);
        z-index: 200;
    }

    .pretty-select-wrapper .arr {
        bottom: 5px;
        position: absolute;
        right: 5px;
        top: 10px;
        width: 45px;
        pointer-events: none;
    }
    .pretty-select:hover {
        transition: border-color 0.3s;

    }

    .pretty-select {
        outline: none;
        -webkit-appearance: none;
        display: block;
        padding: 0.7em 3em 0.8em 1.5em;
        margin: 0;
        transition: border-color 0.2s;
        border: 2px solid #9dbff2;
        border-radius: 5px;
        background: #fff;
        color: #555;
        height:100%;
        font-weight:700
    }
    `, "",{"version":3,"sources":["webpack://./src/app/imed-claims/services/common-components/service-status/imed-claim-service-status.component.ts"],"names":[],"mappings":";IACI;QACI,eAAe;QACf,kBAAkB;QAClB,iBAAiB;QAEhB,eAAe;QAEhB,uEAAuE;IAC3E;;IAEA;QAEI,yEAAyE;QACzE,YAAY;IAChB;;IAEA;QACI,WAAW;QACX,kBAAkB;QAClB,UAAU;QACV,SAAS;QACT,WAAW;QACX,oBAAoB;IACxB;IACA;QAEI,6BAA6B;;IAEjC;;IAEA;QACI,aAAa;QACb,wBAAwB;QACxB,cAAc;QACd,8BAA8B;QAC9B,SAAS;QAET,6BAA6B;QAC7B,yBAAyB;QACzB,kBAAkB;QAClB,gBAAgB;QAChB,WAAW;QACX,WAAW;QACX;IACJ","sourcesContent":["\n    .pretty-select-wrapper {\n        font-size: 16px;\n        position: relative;\n        border-radius:5px;\n        -webkit-transition: .4s;\n         transition: .4s;\n        -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,.2), 0 3px 10px 0 rgba(0,0,0,.18824);\n        box-shadow: 0 2px 5px 0 rgba(0,0,0,.2), 0 3px 10px 0 rgba(0,0,0,.18824);\n    }\n\n    .pretty-select-wrapper:hover {\n        -webkit-box-shadow: 0 8px 20px 0 rgba(0,0,0,.2), 0 4px 15px 0 rgba(0,0,0,.18824);\n        box-shadow: 0 8px 20px  0 rgba(0,0,0,.2), 0 4px 15px 0 rgba(0,0,0,.18824);\n        z-index: 200;\n    }\n\n    .pretty-select-wrapper .arr {\n        bottom: 5px;\n        position: absolute;\n        right: 5px;\n        top: 10px;\n        width: 45px;\n        pointer-events: none;\n    }\n    .pretty-select:hover {\n        -webkit-transition: border-color 0.3s;\n        transition: border-color 0.3s;\n\n    }\n\n    .pretty-select {\n        outline: none;\n        -webkit-appearance: none;\n        display: block;\n        padding: 0.7em 3em 0.8em 1.5em;\n        margin: 0;\n        -webkit-transition: border-color 0.2s;\n        transition: border-color 0.2s;\n        border: 2px solid #9dbff2;\n        border-radius: 5px;\n        background: #fff;\n        color: #555;\n        height:100%;\n        font-weight:700\n    }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
