import { Component, ViewChild, OnInit, Input, Output, EventEmitter, ComponentRef, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { SelectContactFaxes } from '../select-contact-faxes/select-contact-faxes.component';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { FaxEmailService } from '../fax-email/fax-email.service';
import { ICommunicationRequest, IAttachmentLinks } from '@model/interfaces/custom/communication-request';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { finalize } from 'rxjs/operators';
import { OverlayRef, Overlay, OverlayPositionBuilder, GlobalPositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Subscription } from 'rxjs';
import { CommunicationLogService } from '../services/communication-log.service';
import { BaseAttachmentsService } from '../attachments/base-attachments.service';
import { IAttachmentDTO } from '@model/interfaces/custom/attachment-dto';
import { saveAs } from 'file-saver';
import { DocumentTransactionLogService } from '../attachments/document-transaction-log.service';
import { DocumentTransactionTypeEnums } from '../constants/Enums';
import { IAttachmentListWithFaxNumberDTO } from '@model/interfaces/custom/attachment-list-with-fax-number';
import { IModalOptions, IModalWrapperApi } from '@mt-ng2/modal-module';
import { HttpErrorResponse } from '@angular/common/http';

export interface ICommonFaxComponentApi {
    close: () => void;
    show: () => void;
}

@Component({
    selector: 'common-fax',
    styles: [
        `
            label {
                padding: 8px 0px;
                font-size: 20px;
                color: #858a9f;
            }
            .invalid {
                border: 1px solid red;
            }
            .error {
                color: red;
                font-size: 10px;
            }
            .merging {
                position: absolute;
                height: 100%;
                width: 100%;
                background: rgba(68, 68, 68, 0.3);
                top: 0;
                left: 0;
                z-index: 9999;
                padding: 1.25em;
                border-radius: 0.3125em;
            }
            .generating {
                width: 100%;
                height: 100%;
                display: flex;
                opacity: 100%;
                font-size: 50px;
                align-items: center;
                justify-content: center;
                text-align: center;
            }
        `,
    ],
    templateUrl: './common-fax.component.html',
})
export class CommonFaxComponent implements OnInit, OnDestroy {
    @Input() imedClaimServiceId: number;
    @Input() originalCommunicationLogId: number;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    faxForm: UntypedFormGroup;
    faxTo = new UntypedFormControl('', (c: AbstractControl) => Validators.required(c));
    overlayRef: OverlayRef;
    subscriptions: Subscription = new Subscription();
    doubleClickIsDisabled = false;
    isMergingOrSending = false;

    attachments: IAttachmentDTO[];
    attachmentLinks: IAttachmentLinks[] = [];
    defaultAttachments: string[] = [];
    canEditAttachments: boolean;

    modalOptions: IModalOptions = {
        customClass: 'modal-hide-actions' as any,
        width: '60%',
    };
    modalWrapperApi: IModalWrapperApi;
    @Output() ready = new EventEmitter<ICommonFaxComponentApi>();

    constructor(
        protected notificationsService: NotificationsService,
        protected faxEmailService: FaxEmailService,
        private communicationLogService: CommunicationLogService,
        private fb: UntypedFormBuilder,
        private overlay: Overlay,
        private overlayPositionBuilder: OverlayPositionBuilder,
        private commonattachmentsService: BaseAttachmentsService,
        private docTransactionLogService: DocumentTransactionLogService,
        private cdr: ChangeDetectorRef,
    ) { }

    ngOnInit(): void {
        this.buildForm();
        this.ready.emit({
            close: this.closeFaxModal.bind(this),
            show: this.showFaxForm.bind(this),
        });
    }

    buildForm(): void {
        this.faxForm = this.fb.group({
            faxTo: this.faxTo,
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        if (this.overlayRef) {
            this.overlayRef.dispose();
        }
    }

    showFaxForm(): void {
        this.modalWrapperApi.show();
        this.cdr.detectChanges(); // Needed for use in CommunicationLogResendCellComponent
    }

    closeFaxModal(): void {
        this.modalWrapperApi.close();
        
        this.onClose.emit();
    }

    handleFaxSend(): void {
        if (this.originalCommunicationLogId) {
            this.sendFax(this.resend);
        } else {
            this.sendFax(this.send);
        }
    }

    sendFax(sendFunction: (fax: IAttachmentListWithFaxNumberDTO) => void): void {
        this.isMergingOrSending = true;
        if (this.faxForm.valid) {
            const fax: IAttachmentListWithFaxNumberDTO = {
                FaxNumber: this.faxTo.value,
                ImedClaimServiceId: this.imedClaimServiceId,
                SelectedAttachments: this.attachments,
            };
            sendFunction.call(this, fax);
        } else {
            this.isMergingOrSending = false;
            markAllFormFieldsAsTouched(this.faxForm);
            setTimeout(() => (this.doubleClickIsDisabled = false));
            this.notificationsService.error('Please check form and try again');
        }
    }

    send = (fax: IAttachmentListWithFaxNumberDTO): void => {
        this.faxEmailService
            .sendFax(fax)            .subscribe(() => {
                this.notificationsService.info('Fax sent, check communication log for status');
                this.communicationLogService.notifySubscribersOfNewLogs();
                this.closeFaxModal();
            });
    }

    resend = (fax: IAttachmentListWithFaxNumberDTO): void => {
        const faxRequest: ICommunicationRequest = {
            AttachmentLinks: this.attachmentLinks,
            ImedClaimServiceId: this.imedClaimServiceId,
        };
        this.faxEmailService
            .resendFax(this.originalCommunicationLogId, fax.FaxNumber, faxRequest)            .subscribe(() => {
                this.notificationsService.success('Fax resent, check communication log for status');
                this.communicationLogService.notifySubscribersOfNewLogs();
                this.closeFaxModal();
            });
    }

    handleDocumentEmailResponse(): void {
        this.closeFaxModal();
    }

    downloadDoc(docId: number): void {
        const doc = this.attachments.find((a) => a.Id === docId);
        if (doc.IsManual) {
            this.commonattachmentsService.downloadUploadedDocuments('imed-claim-service', doc.Id).subscribe(
                (x) => {
                    const thefile = new Blob([x], { type: 'application/octet-stream' });
                    saveAs(thefile, doc.Name);
                },
                (err) => {
                    this.docTransactionLogService.logError(err as HttpErrorResponse, doc.Id, DocumentTransactionTypeEnums.MANUAL_DOWNLOAD_FE).subscribe();
                    this.notificationsService.error('Document download failed');
                },
            );
        } else {
            this.commonattachmentsService.downloadEmailedDocument('imed-claim-service', doc.Id).subscribe(
                (x) => {
                    const thefile = new Blob([x], { type: 'application/octet-stream' });
                    saveAs(thefile, doc.Name);
                },
                (err) => {
                    this.docTransactionLogService.logError(err as HttpErrorResponse, doc.Id, DocumentTransactionTypeEnums.MANUAL_DOWNLOAD_FE).subscribe();
                    this.notificationsService.error('Document download failed');
                },
            );
        }
    }

    // Fax select
    openFaxSelect(): void {
        if (!this.overlayRef || !this.overlayRef.hasAttached()) {
            this.createOverlay();
            // Component portal dyanmically creates an instance of the component type passed as argument
            const portal = new ComponentPortal(SelectContactFaxes);
            // Attaches the dynamically created component to the overlay created in createOverlay() method
            const faxContactSelect: ComponentRef<SelectContactFaxes> = this.overlayRef.attach(portal);
            // Setting the email contact select components claimId property
            faxContactSelect.instance.imedclaimserviceid = this.imedClaimServiceId;
            this.subscribeToFaxSelect(faxContactSelect);
        }
    }

    // Creates overlay modal that email select component is rendered in
    createOverlay(): void {
        const positionStrategy = this.createPositionStrategyForOverlay();
        // overlay-on-top class sets a high z-index to ensure the overlay is on top regardless of other modals being open
        this.overlayRef = this.overlay.create({ positionStrategy, disposeOnNavigation: true, panelClass: 'overlay-on-top' });
    }

    // The global position strategy determines where the component will be rendered
    createPositionStrategyForOverlay(): GlobalPositionStrategy {
        return this.overlayPositionBuilder.global().top('10%').right('10%');
    }

    subscribeToFaxSelect(component: ComponentRef<SelectContactFaxes>): void {
        // subscribe to the SelectContactFaxes close event and detach overlay from DOM when event is fired
        this.subscriptions.add(component.instance.close.subscribe(() => this.overlayRef.detach()));
        // susbcribe to SelectContactFaxes  selectEmails event and set the emailTo form value to the event value
        this.subscriptions.add(
            component.instance.selectItems.subscribe((items) => {
                this.faxTo.setValue(items.join(', '));
            }),
        );
    }
}
