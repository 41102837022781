import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, forkJoin, Subscription, Observable } from 'rxjs';
import { ITask } from '@model/interfaces/task';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { TaskService } from '../task.service';
import { ClaimsService, ClaimValues, AuthService } from '@mt-ng2/auth-module';

import { ClaimTypes } from '@model/ClaimTypes';
import { IUser } from '@model/interfaces/user';
import { TaskDynamicConfig } from '../task.dynamic-config';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { UserService } from '../../users/user.service';
import { TaskStatusService } from '../taskstatus.service';
import { UserRoleService } from '../../user-roles/user-role.service';
import { MultiselectItem, ISelectionChangedEvent } from '@mt-ng2/multiselect-control';
import { TaskDynamicControlsPartial } from '@model/partials/task-partial.form-controls';
import { ITaskDynamicControlsParameters } from '@model/form-controls/task.form-controls';
import { ITaskStatus } from '@model/interfaces/task-status';
import { ITaskAssignedUserRole } from '@model/interfaces/task-assigned-user-role';
import { ITaskAssignedUser } from '@model/interfaces/task-assigned-user';
import { finalize, tap } from 'rxjs/operators';
import { MetaItem } from '@mt-ng2/base-service';
import { ITaskDTO } from '@model/interfaces/custom/task-dto';
import { TaskStatusEnums, UserRoleIdEnums } from '../../common/constants/Enums';
import { CommunicationLogLabelGenerator } from '../../imed-claims/services/common-components/communication-log/communication-log-label-generator';
import { ICommunicationLog } from '@model/interfaces/communication-log';
import { SearchParams, ExtraSearchParams } from '@mt-ng2/common-classes';
import {
    CommunicationLogService,
    IResendCommunicationConfig,
    ResendCommunicationAttachmentTypes,
} from '../../common/services/communication-log.service';
import { UserTypeValues } from '../../../client-portal/common/constants/user-type.enum';
import { ICommunicationLogResponse } from '@model/interfaces/custom/communication-request';
import { CommonEmailComponent } from '../../common/common-email/common-email.component';
import { NullHostDirective } from '../../common/directives/null-host.directive';
import { IEntity } from '@model/interfaces/base';

@Component({
    selector: 'app-task-detail',
    templateUrl: './task-detail.component.html',
})
export class TaskDetailComponent implements OnInit, OnDestroy {
    taskDTO: ITaskDTO;
    currentUser: IUser;
    task: ITask;
    editingComponent: Subject<any> = new Subject();
    canEdit: boolean;
    canAdd: boolean;
    id: number;
    users: IUser[];
    isEditing: boolean;
    isHovered: boolean;
    config: any = {};
    taskForm = new UntypedFormGroup({ Task: new UntypedFormGroup({}) });
    formFactory: TaskDynamicConfig<ITask>;
    doubleClickIsDisabled = false;
    controls: any;
    usersSelectItems: MultiselectItem[];
    userRolesSelectItems: MultiselectItem[];
    userRoleId: number;
    canUpdateTask: boolean = true;
    taskStatuses: ITaskStatus[];
    selectedUserRoles: MetaItem[];
    selectedUsers: MetaItem[];
    selectedAssignedToTypeId: number;
    claims: any[];
    userSubscription: Subscription;
    // Removing at imedview's request
    // dueDateUpperBound: any;
    communicationLogLabelGenerator: CommunicationLogLabelGenerator;
    communicationLogs: ICommunicationLog[];
    communicationLogsCount: number;
    communicationLogCardName = 'Communication Logs';
    clientUser: number = UserTypeValues.Client;
    // For injecting email component
    @ViewChild(NullHostDirective) commonEmailHost: NullHostDirective;

    constructor(
        private taskService: TaskService,
        private userService: UserService,
        private userRoleService: UserRoleService,
        private claimsService: ClaimsService,
        private taskStatusService: TaskStatusService,
        private route: ActivatedRoute,
        private router: Router,
        private notificationsService: NotificationsService,
        private communicationLogService: CommunicationLogService,
        private authService: AuthService,
    ) {}

    ngOnInit(): void {
        // check claims
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.Tasks, [ClaimValues.FullAccess]);
        this.canAdd = this.canEdit;
        this.isEditing = false;
        // get current id from route
        this.id = +this.route.snapshot.paramMap.get('taskId');
        this.communicationLogLabelGenerator = new CommunicationLogLabelGenerator();
        forkJoin([
            this.userRoleService.getAll(),
            this.taskStatusService.getAll(),
            this.taskService.getTaskById(this.id),
            this.userService.getRoleId(this.authService.currentUser.getValue().Id),
            this.userService.getById(this.authService.currentUser.getValue().Id),
            this.getCommunicationLogs(),
        ]).subscribe(([userRoles, taskStatus, taskDTO, roleId, currentUser]) => {
            this.taskStatuses = taskStatus.filter((x) => x.Id !== +TaskStatusEnums.CompletedBySystem);
            this.currentUser = currentUser;
            this.claims = [];
            this.taskDTO = taskDTO;
            this.userRoleId = roleId;
            this.task = this.taskService.mapDTOtoTask(this.taskDTO);
            // Removing at imedview's request
            // this.dueDateUpperBound = this.task.DueDate;
            this.selectedUsers = [];
            this.selectedUserRoles = [];
            this.selectedUsers = taskDTO.TaskUsers.map((item) => new MetaItem(item.UserId, `${item.FirstName} ${item.LastName}`));
            this.selectedUserRoles = taskDTO.TaskUserRoles.map((item) => new MetaItem(item.UserRoleId, item.Name));
            this.userRolesSelectItems = userRoles.map(
                (item) =>
                    new MultiselectItem(
                        new MetaItem(item.Id, item.Name),
                        taskDTO.TaskUserRoles.some((userRole) => userRole.UserRoleId === item.Id),
                    ),
            );
            this.config = { formObject: [], viewOnly: [] };
            this.subscribeToUsers();
            this.setConfig();
        });
    }

    ngOnDestroy(): void {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
    }

    subscribeToUsers(): void {
        this.userSubscription = this.userService.getAll().subscribe((users) => {
            this.usersSelectItems = users
                .filter((u) => !u.Archived || this.taskDTO.TaskUsers.some((tu) => tu.UserId === u.Id))
                .map(
                    (item) =>
                        new MultiselectItem(
                            new MetaItem(item.Id, `${item.FirstName} ${item.LastName}`),
                            this.taskDTO.TaskUsers.some((user) => user.UserId === item.Id),
                        ),
                );
        });
    }

    setConfig(): void {
        this.controls = new TaskDynamicControlsPartial(this.task, {
            imedClaims: this.claims,
            taskStatuses: this.taskStatuses,
        } as ITaskDynamicControlsParameters).Form;

        this.formFactory = new TaskDynamicConfig<ITask>(this.task, [], []);
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.task.Id === 0) {
            void this.router.navigate(['/tasks']);
        } else {
            this.isEditing = false;
        }
    }

    formSubmitted(): void {
        const form = this.taskForm;
        if (form.valid && this.validateAssignedTypes(form)) {
            this.formFactory.assignFormValues(this.task, form.value.Task as ITask);
            if (this.task.AssignedToTypeId === 1) {
                this.task.TaskAssignedUserRoles = this.selectedUserRoles.map(
                    (UserRole) =>
                        <ITaskAssignedUserRole>{
                            Id: 0,
                            Task: null,
                            TaskId: this.task.Id,
                            UserRole: null,
                            UserRoleId: UserRole.Id,
                        },
                );
            } else {
                this.task.TaskAssignedUsers = this.selectedUsers.map(
                    (User) =>
                        <ITaskAssignedUser>{
                            Id: 0,
                            Task: null,
                            TaskId: this.task.Id,
                            User: null,
                            UserId: User.Id,
                        },
                );
            }

            if (this.task.TaskStatusId === +TaskStatusEnums.Completed) {
                this.selectedUserRoles.map((item) => {
                    if (item.Id === +UserRoleIdEnums.Accounting) {
                        if (this.userRoleId !== +UserRoleIdEnums.Accounting && this.userRoleId !== +UserRoleIdEnums.Admin) {
                            this.notificationsService.error('Accounting Tasks can only be completed by Accounting or Admin');
                            this.canUpdateTask = false;
                        }
                    }
                });
                if (!this.taskDTO.IsAccountingTask && !this.taskService.canCompleteTask(this.taskDTO, this.userRoleId, this.currentUser)) {
                    this.canUpdateTask = false;
                }
            }
            if (this.canUpdateTask) {
                this.taskService.updateTask(this.task).subscribe(() => {
                    this.isEditing = false;
                    this.success();
                    this.taskService.emitChange(this.task);
                    this.taskDTO.DateCreated = this.task.DateCreated;
                    this.setConfig();
                });
            }
        } else {
            markAllFormFieldsAsTouched(form);
            this.error();
        }
    }
    userRoleSelectionChanged(event: ISelectionChangedEvent): void {
        this.selectedUserRoles = event.selectedItems;
    }

    userSelectionChanged(event: ISelectionChangedEvent): void {
        this.selectedUsers = event.selectedItems;
    }

    error(message = 'Save Failed'): void {
        this.notificationsService.error(message);
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }
    getValueToDisplayForAssignments(): string {
        let result = '';
        if (this.task.AssignedToTypeId === 1) {
            const userRoles = this.selectedUserRoles;
            result = userRoles
                .map((ur) => {
                    return ur.Name ? ur.Name : '';
                })
                .join(', ');
        } else {
            const users = this.selectedUsers;
            result = users
                .map((ur) => {
                    return ur.Name ? `${ur.Name}` : '';
                })
                .join(', ');
        }
        return result;
    }

    validateAssignedTypes(form): boolean {
        let isValid = false;
        if (form.value.Task.AssignedToTypeId === 1 && this.selectedUserRoles.length !== 0) {
            isValid = true;
        }
        if (form.value.Task.AssignedToTypeId === 2 && this.selectedUsers.length !== 0) {
            isValid = true;
        }
        return isValid;
    }

    getCommunicationLogs(): Observable<ICommunicationLog[]> {
        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'taskId',
                value: this.id.toString(),
            }),
        );
        const searchParams: SearchParams = new SearchParams({
            extraParams: _extraSearchParams,
            order: 'DateSent',
            orderDirection: 'desc',
            query: '',
            skip: 0,
            take: 3,
        });

        return this.communicationLogService.searchLogs(searchParams, 'task').pipe(
            tap((answer: any) => {
                this.communicationLogs = answer.body;
                this.communicationLogsCount = +answer.headers.get('X-List-Count');
            }),
        );
    }

    seeAllCommunicationLogs(): void {
        void this.router.navigate(['tasks', this.id, 'communication-logs']);
    }

    loadResendComponent(e: IEntity): void {
        const data: IResendCommunicationConfig = {
            attachmentType: ResendCommunicationAttachmentTypes.TASK,
            component: CommonEmailComponent,
            data: e as ICommunicationLogResponse,
        };
        this.communicationLogService.loadResendCommunicationComponent(this.commonEmailHost, data);
    }
}
