import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImedCommonModule } from '@common/imed-common.module';
import { AddressBookTypeAssociationsDetailComponent } from './addressbook-type-associations-detail/addressbook-associations-detail.component';
import { AddressBookAssociationHeaderComponent } from './addressbook-association-header.component';
import { AddressBookTypeAssociationsRoutingModule } from './addressbook-association-routing.module';
import { AddressBookTypeSelectComponent } from './addressbook-type-select/addressbook-type-select.component';
import { AddressBookAssociationTypeSelectionService } from './services/ab-associations-type-selection.service';
import { AddressBookAssociationsToggleService } from './services/ab-associations-type-toggle.service';
import { PhysicianStatusService } from '../../address-books/individuals/physicians/physicianstatus.service';
import { PhysicianStatusTaskRowComponent } from './addressbook-status-task-associations/status-task-row/physician-status-task-row.component';
import { PhysicianStatusTaskTemplateService } from './services/physician-status-task-service';
import { TaskPhysicianStatusAssociationsComponent } from './addressbook-status-task-associations/task-status-association.component';
import { TaskTemplateService } from '../services/tasktemplate.service';
import { EmailTemplatePhysicianStatusRowComponent } from './addressbook-status-email-associations/email-template-status-row/email-template-status-row.component';
import { EmailTemplatePhysicianStatusAssociationsComponent } from './addressbook-status-email-associations/email-status-template-associations.component';
import { PhysicianStatusEmailTemplateService } from './services/physician-status-email-template-service';

@NgModule({
    declarations: [
        AddressBookAssociationHeaderComponent,
        AddressBookTypeAssociationsDetailComponent,
        AddressBookTypeSelectComponent,
        PhysicianStatusTaskRowComponent,
        TaskPhysicianStatusAssociationsComponent,
        EmailTemplatePhysicianStatusRowComponent,
        EmailTemplatePhysicianStatusAssociationsComponent,
    ],
    imports: [CommonModule, ImedCommonModule, AddressBookTypeAssociationsRoutingModule],
})
export class AddressBookAssociationModule {
    static forRoot(): any {
        return {
            ngModule: AddressBookAssociationModule,
            providers: [
                AddressBookAssociationsToggleService,
                AddressBookAssociationTypeSelectionService,
                PhysicianStatusService,
                PhysicianStatusTaskTemplateService,
                TaskTemplateService,
                PhysicianStatusEmailTemplateService,
            ],
        };
    }
}
