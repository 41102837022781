import { Component, OnInit, OnDestroy, Input, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { IAddressBook } from '@model/interfaces/address-book';
import { IAddressBookType } from '@model/interfaces/address-book-type';
import { AddressBookService } from '../../address-books/addressbook.service';
import { MetaAddressBookTypeService } from '../../address-books/metaaddressbooktype.service';
import { MetaAddressBookTypeIdEnums } from '../constants/Enums';
import { AppStateService, AppStates } from '../../app-state.service';
import { AddressBooksSelectService } from './address-books-select.service';

@Component({
    selector: 'app-address-books-select-add',
    templateUrl: './address-books-select-add.component.html',
})
export class AddressBooksSelectAddComponent implements OnInit, OnDestroy {
    @Input() typeFilter: string;
    @Output('closeAddressBookAction') closeAddressBookAction: EventEmitter<string> = new EventEmitter<string>();
    metaAddressBookTypeId: number = MetaAddressBookTypeIdEnums.Individual;
    metaAddressBookTypeIdEnums = MetaAddressBookTypeIdEnums;
    addressBook: IAddressBook;
    header: string;
    subscriptions: Subscription = new Subscription();
    isOfTypeIndividual = false;

    addressBookTypes: IAddressBookType[];

    constructor(
        private addressBookService: AddressBookService,
        private metaAddressBookTypeService: MetaAddressBookTypeService,
        private route: ActivatedRoute,
        private router: Router,
        private appStateService: AppStateService,
        private addressBookSelectService: AddressBooksSelectService,
    ) { }

    ngOnInit(): void {
        if (!this.typeFilter) {
            this.typeFilter = this.addressBookSelectService.typeFilter;
        }

        this.addressBook = this.addressBookService.getEmptyAddressBook();
        this.metaAddressBookTypeService.getItems().subscribe((items) => {
            this.addressBookTypes = items;
        });

        this.subscriptions.add(
            this.router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    // the route has changed, so lets change the AppState to Main
                    // since we shouldn't be showing this search if a route change
                    // has occurred
                    this.appStateService.appState.next(AppStates.Main);
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    close(): void {
        this.closeAddressBookAction.emit('');
    }
}
