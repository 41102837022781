import { ClaimTypes } from '@model/ClaimTypes';
import { Routes, RouterModule } from '@angular/router';
import { SystemTaskListComponent } from './system-task-list/system-task-list.component';
import { AuthGuard, ClaimValues } from '@mt-ng2/auth-module';
import { TaskStatusFollowupEngineHeaderComponent } from './task-status-followup-engine-header/task-status-follow-engine-header.component';
import { NgModule } from '@angular/core';
import { AddSystemTaskFormComponent } from './add-system-task-form/add-system-task-form.component';
import { SystemTaskDetailComponent } from './system-task-detail/system-task-detail.component';
import { SystemTaskAssociationsDetailComponent } from './system-task-associations/system-task-associations-detail/system-task-associations-detail.component';
import { ImedClaimTypeFollowUpAssociationsDetailComponent } from './imedclaim-type-followup-associations/imedclaim-type-associations-detail/imedclaim-type-associations-detail.component';
import { ImedClaimFollowupEngineHeaderComponent } from './imedclaim-type-followup-associations/imedclaim-followup-engine-header.component';

const taskStatusFollowupEngineEntityListConfig = {
    claimType: ClaimTypes.TaskStatusFollowupEngine,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'taskTemplateId',
    title: 'System Tasks',
};
const taskStatusFollowupEngineRoleGuard: any = {
    claimType: ClaimTypes.TaskStatusFollowupEngine,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'System Tasks',
};

const taskStatusFollowupEngineRoutes: Routes = [
    { path: 'system-tasks', component: SystemTaskListComponent, canActivate: [AuthGuard], data: taskStatusFollowupEngineRoleGuard },
    {
        canActivate: [AuthGuard],
        children: [{ path: '', component: AddSystemTaskFormComponent, pathMatch: 'full' }],
        component: TaskStatusFollowupEngineHeaderComponent,
        data: taskStatusFollowupEngineRoleGuard,
        path: 'system-tasks/add',
    },
    {
        canActivate: [AuthGuard],
        children: [{ path: '', component: SystemTaskAssociationsDetailComponent, pathMatch: 'full' }],
        component: TaskStatusFollowupEngineHeaderComponent,
        data: taskStatusFollowupEngineEntityListConfig,
        path: `system-tasks/associations`,
    },
    {
        canActivate: [AuthGuard],
        children: [{ path: '', component: SystemTaskDetailComponent, pathMatch: 'full' }],
        component: TaskStatusFollowupEngineHeaderComponent,
        data: taskStatusFollowupEngineEntityListConfig,
        path: `system-tasks/:${taskStatusFollowupEngineEntityListConfig.entityIdParam}`,
    },
    {
        canActivate: [AuthGuard],
        children: [{ path: '', component: ImedClaimTypeFollowUpAssociationsDetailComponent, pathMatch: 'full' }],
        component: ImedClaimFollowupEngineHeaderComponent,
        data: taskStatusFollowupEngineEntityListConfig,
        path: `case-type/associations`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(taskStatusFollowupEngineRoutes)],
})
export class TaskStatusFollowupEngineRoutingModule { }
