import { EmailAddressSharedEntitiesConfig } from '@model/shared-entities/email-addresses/email-address.shared-entities-config';
import { AddressBookEmailService } from './addressbook-email.service';
import { PhoneNumberSharedEntitiesConfig } from '@model/shared-entities/phone-numbers/phone-numbers.shared-entities-config';
import { AddressBookPhoneNumberService } from './addressbook-phone.service';

export enum AddressBookSharedEntities {
    EmailAddresses,
    PhoneNumbers,
}

export const AddressBookEmailSharedEntity = {
    config: EmailAddressSharedEntitiesConfig,
    entityIdParam: 'emailAddressId',
    path: 'emailaddresses',
    service: AddressBookEmailService,
    sharedEntity: AddressBookSharedEntities.EmailAddresses,
};

export const AddressBookPhoneNumberSharedEntity = {
    config: PhoneNumberSharedEntitiesConfig,
    entityIdParam: 'phoneNumberId',
    path: 'phonenumbers',
    service: AddressBookPhoneNumberService,
    sharedEntity: AddressBookSharedEntities.PhoneNumbers,
};
