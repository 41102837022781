import { Input, Component, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';
import { IAttachmentDTO } from '@model/interfaces/custom/attachment-dto';
import { IAttachmentLinks } from '@model/interfaces/custom/communication-request';

export interface IEmailAttachment {
    attachmentDto: IAttachmentDTO;
    selected: boolean;
}

@Component({
    selector: 'communication-attachment-control',
    styles: [
        `
            .attachment-table {
                font-size: 12px;
            }
        `,
    ],
    template: ` <label>Attachments</label>
        <table class="table table-border table-responsive no-pointer attachment-table">
            <thead>
                <tr>
                    <th>Type</th>
                    <th>Filename</th>
                    <th>Download</th>
                    <th *ngIf="canEdit" class="text-center">Include</th>
                </tr>
            </thead>
            <tbody *ngIf="getAttachmentList().length; else noAttachments">
                <tr
                    [ngStyle]="{ background: attachment.selected ? '#ECECEC' : '#fff' }"
                    *ngFor="let attachment of getAttachmentList(); let i = index"
                >
                    <td>{{ attachment.attachmentDto.AttachmentType.Name }}</td>
                    <td>{{ attachment.attachmentDto.Name }}</td>
                    <td class="text-center">
                        <span
                            class="fa fa-fw {{ attachment.attachmentDto.Name | fileicon }} pointer "
                            (click)="downloadDoc.emit(attachment.attachmentDto.Id)"
                        ></span>
                    </td>
                    <td *ngIf="canEdit" class="text-center pointer">
                        <input
                            type="checkbox"
                            (ngModelChange)="emitChange(); attachment.selected = !attachment.selected"
                            [ngModel]="attachment.selected"
                        />
                    </td>
                </tr>
            </tbody>
        </table>
        <ng-template #noAttachments>
            <tbody>
                <tr style="padding:10px 0;font-size:14px;">
                    <td colspan="4" class="text-center">No attachments available</td>
                </tr>
            </tbody>
        </ng-template>`,
})
export class CommunicationAttachmentControlComponent implements OnInit, OnChanges {
    @Input() preSelectedAttachmentTypeIds: number[] = [];
    @Input() preSelectedAttachmentPaths: string[] = []; // For Resends use the actual paths
    @Input() canEdit: boolean;
    @Input() resending: boolean;
    @Output() downloadDoc: EventEmitter<number> = new EventEmitter<number>();
    @Output() attachmentsChanged: EventEmitter<IAttachmentLinks[]> = new EventEmitter<IAttachmentLinks[]>();

    private _originalAttachmentList: IAttachmentDTO[] = [];
    private _attachmentList: IEmailAttachment[] = [];
    @Input('attachmentList')
    set attachmentList(value: IAttachmentDTO[]) {
        this._originalAttachmentList = value;

        if (!value) {
            return;
        }
        if (this.resending) {
            this._attachmentList = value
                .filter((doc) => this.preSelectedAttachmentPaths.includes(doc.FilePath))
                .map((doc) => {
                    return { attachmentDto: doc, selected: true } as IEmailAttachment;
                })
                .sort((a, b) => +b.selected - +a.selected);
        } else {
            this._attachmentList = value
                .filter((doc) => this.preSelectedAttachmentTypeIds.includes(doc.AttachmentTypeId))
                .map((doc) => {
                    return { attachmentDto: doc, selected: true } as IEmailAttachment;
                });
        }
    }

    getAttachmentList(): IEmailAttachment[] {
        return [...this._attachmentList];
    }

    constructor() {}

    ngOnInit(): void {
        this.emitChange();
    }

    ngOnChanges(changes): void {
        if (changes.resending && changes.resending.currentValue !== changes.resending.previousValue) {
            this.attachmentList = this._originalAttachmentList;
        }
        this.emitChange();
    }

    buildAttachmentLinks(): IAttachmentLinks[] {
        return this._attachmentList
            .filter((a) => a.selected === true)
            .map((a) => {
                return { AttachmentName: a.attachmentDto.Name, AttachmentPath: a.attachmentDto.FilePath } as IAttachmentLinks;
            });
    }

    emitChange(): void {
        let links: IAttachmentLinks[];
        setTimeout(() => {
            links = this.buildAttachmentLinks();
            this.attachmentsChanged.emit(links);
        }, 200);
    }
}
