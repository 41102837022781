import { Component } from '@angular/core';

import { IEntityListDynamicCellComponent, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { IEntity } from '@mt-ng2/base-service';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IModalOptions } from '@mt-ng2/modal-module';

import { IViewGetFollowUpsForList } from '@model/interfaces/view-get-follow-ups-for-list';
import { ClaimTypes } from '@model/ClaimTypes';

@Component({
    template: `
        <div *ngIf="imedClaimServiceFollowup && canDelete">
            <div *ngIf="imedClaimServiceFollowup.Archived" class="text-center">
                <i (mtConfirm)="archiveFollowup($event)" class="fa fa-trash fa-lg" aria-hidden="true" [mtConfirmOptions]="unarchiveConfirm"></i
                ><i class="fa fa-lg fa-undo overlay-fa"></i>
            </div>
            <div *ngIf="!imedClaimServiceFollowup.Archived" class="text-center">
                <i (mtConfirm)="archiveFollowup($event)" class="fa fa-trash fa-lg" aria-hidden="true" [mtConfirmOptions]="archiveConfirm"></i>
            </div>
        </div>
    `,
})
export class ServiceFollowupDeleteDynamicCellComponent implements IEntityListDynamicCellComponent {
    entityListComponentMembers: IEntityListComponentMembers;
    set entity(value: IEntity) {
        this.imedClaimServiceFollowup = value as IViewGetFollowUpsForList;
    }

    imedClaimServiceFollowup: IViewGetFollowUpsForList;
    canDelete: boolean;

    archiveConfirm: IModalOptions = {
        confirmButtonText: 'Yes, I am sure!',
        text: `Are you sure you want to delete this followup?`,
        title: 'Delete Followup',
    	icon: 'question',
};

    unarchiveConfirm: IModalOptions = {
        confirmButtonText: 'Yes, I am sure!',
        text: `Are you sure you want to restore this followup?`,
        title: 'Restore Followup',
    	icon: 'question',
};

    constructor(private claimsService: ClaimsService) { }

    ngOnInit(): void {
        this.canDelete = this.claimsService.hasClaim(ClaimTypes.CanDeleteFollowups, [ClaimValues.FullAccess]);
        if(this.imedClaimServiceFollowup.ImedClaimFollowupId &&  this.imedClaimServiceFollowup.ImedClaimFollowupId > 0) {
          this.canDelete = false;
        }
    }

    archiveFollowup(event: Event): void {
        this.entityListComponentMembers.itemDeleted(this.imedClaimServiceFollowup as IEntity, event);
    }
}
