// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            .border {
                border: solid 1px #858a9f;
                margin-top: 10px;
                margin-bottom: 10px;
                border-radius: 0.5%;
            }
            .header-button {
                margin-top: -12px;
            }
            .delete-icon-span {
                height: -moz-fit-content;
                height: fit-content;
                width: 5%;
                overflow: auto;
                margin-left: auto;
                margin-right: 5%;
                margin-top: auto;
                margin-bottom: auto;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                text-align: right;
            }
            .shared-entity-div {
                display: inline-block;
                width: -moz-fit-content;
                width: fit-content;
            }
        `, "",{"version":3,"sources":["webpack://./src/app/imed-claims/imed-claim-shared-entities/imed-claim-shared-entities.component.ts"],"names":[],"mappings":";YACY;gBACI,yBAAyB;gBACzB,gBAAgB;gBAChB,mBAAmB;gBACnB,mBAAmB;YACvB;YACA;gBACI,iBAAiB;YACrB;YACA;gBACI,wBAAmB;gBAAnB,mBAAmB;gBACnB,SAAS;gBACT,cAAc;gBACd,iBAAiB;gBACjB,gBAAgB;gBAChB,gBAAgB;gBAChB,mBAAmB;gBACnB,kBAAkB;gBAClB,MAAM;gBACN,OAAO;gBACP,SAAS;gBACT,QAAQ;gBACR,iBAAiB;YACrB;YACA;gBACI,qBAAqB;gBACrB,uBAAkB;gBAAlB,kBAAkB;YACtB","sourcesContent":["\n            .border {\n                border: solid 1px #858a9f;\n                margin-top: 10px;\n                margin-bottom: 10px;\n                border-radius: 0.5%;\n            }\n            .header-button {\n                margin-top: -12px;\n            }\n            .delete-icon-span {\n                height: fit-content;\n                width: 5%;\n                overflow: auto;\n                margin-left: auto;\n                margin-right: 5%;\n                margin-top: auto;\n                margin-bottom: auto;\n                position: absolute;\n                top: 0;\n                left: 0;\n                bottom: 0;\n                right: 0;\n                text-align: right;\n            }\n            .shared-entity-div {\n                display: inline-block;\n                width: fit-content;\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
