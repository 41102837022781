// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
        .m-2 {
            margin-bottom: 2rem;
        }
    `, "",{"version":3,"sources":["webpack://./src/app/task-status-followup-engine/system-task-detail/system-task-detail.component.ts"],"names":[],"mappings":";QACQ;YACI,mBAAmB;QACvB","sourcesContent":["\n        .m-2 {\n            margin-bottom: 2rem;\n        }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
