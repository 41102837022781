import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';

import { MasterServiceStatusService } from '../services/master-service-status.service';
import { ClaimTypes } from '@model/ClaimTypes';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IMasterServiceStatus } from '@model/interfaces/master-service-status';
import { MasterServiceStatusEntityListConfig } from './master-service-statuses.entity-list-config';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { ImedClaimTypeService } from '@common/services/imed-claim-type.service';
import { ServiceService } from '../../imed-claims/services/service.service';
import { IService } from '@model/interfaces/service';
import { ISearchFilterCheckboxValueChangedEvent } from '@mt-ng2/search-filter-checkbox-control';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-master-service-statuses',
    styles: [
        `
            .remove-dynamic-template-column {
                text-align: right;
                padding-right: 30px !important;
            }
        `,
    ],
    templateUrl: './master-service-statuses.component.html',
})
export class MasterServiceStatussComponent implements OnInit {
    masterServiceStatuss: IMasterServiceStatus[];
    currentPage = 1;
    itemsPerPage = 12;
    searchIncludeArchived = false;
    query = '';
    total: number;
    imedClaimTypes: MtSearchFilterItem[] = [];
    allServiceTypes: MtSearchFilterItem<IService>[] = [];
    serviceTypes: MtSearchFilterItem<IService>[] = [];
    entityListConfig = new MasterServiceStatusEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();

    canAddMasterServiceStatus = false;

    constructor(
        private masterServiceStatusService: MasterServiceStatusService,
        private claimsService: ClaimsService,
        private router: Router,
        private notificationsService: NotificationsService,
        private imedClaimTypeService: ImedClaimTypeService,
        private serviceService: ServiceService,
    ) {}

    ngOnInit(): void {
        this.canAddMasterServiceStatus = this.claimsService.hasClaim(ClaimTypes.MasterServiceStatuses, [ClaimValues.FullAccess]);
        forkJoin([
            this.imedClaimTypeService.getAll(),
            this.serviceService.getServiceTypeSortedByName(),
        ]).subscribe(([imedClaimTypes, serviceTypes]) => {
            this.imedClaimTypes = imedClaimTypes.map((type) => new MtSearchFilterItem(type, false));
            this.allServiceTypes = serviceTypes;
            this.updateServiceFilter();
        });
        this.getMasterServiceStatuss();
    }

    updateServiceFilter(): void {
        const selectedClaimTypes = this.imedClaimTypes.filter((ct) => ct.Selected);

        this.serviceTypes = this.allServiceTypes.filter((s) => {
            if (selectedClaimTypes.length > 0) {
                const returnValue = selectedClaimTypes.some((c) => c.Item.Id === s.Item.ClaimTypeId);
                return returnValue;
            } else {
                return true;
            }
        });
    }

   getMasterServiceStatussCall(): Observable<HttpResponse<IMasterServiceStatus[]>> {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'imedClaimTypeIds',
                valueArray: this.imedClaimTypes.filter((item) => item.Selected).map((item) => item.Item.Id),
            }),
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'serviceTypeIds',
                valueArray: this.serviceTypes.filter((item) => item.Selected).map((item) => item.Item.Id),
            }),
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'includeArchived',
                value: this.searchIncludeArchived ? '1' : '0',
            }),
        );
        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        return this.masterServiceStatusService.get(searchparams);
   }

   getAssociatedServices(masterServiceStatus: IMasterServiceStatus): string {
    if (masterServiceStatus && masterServiceStatus.ServiceStatus) {
        return masterServiceStatus.ServiceStatus.map((ft) => ft.Service.Name).join(', ');
    }
    return '';
   }

   getMasterServiceStatuss(): void {
        this.getMasterServiceStatussCall().subscribe((answer) => {
            this.masterServiceStatuss = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.currentPage = 1;
        this.query = query;
        this.getMasterServiceStatuss();
    }

    filterSelectionChanged(): void {
        this.currentPage = 1;
        this.getMasterServiceStatuss();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getMasterServiceStatuss();
    }

    onMasterServiceStatusSelected(event: IItemSelectedEvent): void {
        void this.router.navigate(['/statuses', event.entity.Id]);
    }

    onRemoveMasterServiceStatus(event: IItemSelectedEvent): void {
        this.masterServiceStatusService.delete(event.entity.Id as number).subscribe(() => {
            if (event.entity.Archived) {
                this.notificationsService.success('Successfully restored Service Status');
            } else {
                this.notificationsService.success('Successfully removed Service Status');
            }
            this.getMasterServiceStatuss();
        });
    }
}
