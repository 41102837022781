import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { GeneratedLetterTemplateService } from '../generated-letter-template.service';
import { IGeneratedLetterTemplate } from '@model/interfaces/generated-letter-template';
import { GeneratedLetterTemplateDynamicConfig } from '../generated-letter-template.dynamic-config';
import { Router } from '@angular/router';
import { DynamicLabel, DynamicField, IDynamicFormConfig } from '@mt-ng2/dynamic-form';

@Component({
    selector: 'app-generated-letter-template-basic-info',
    styles: [
        `
            :host ::ng-deep .cke_contents {
                min-height: 600px;
            }
        `,
    ],
    templateUrl: './generated-letter-template-basic-info.component.html',
})
export class GeneratedLetterTemplateBasicInfoComponent implements OnInit {
    @Input() generatedLetterTemplate: IGeneratedLetterTemplate;
    @Input() canEdit: boolean;

    config: IDynamicFormConfig;
    form: FormGroup;
    formFactory: GeneratedLetterTemplateDynamicConfig<IGeneratedLetterTemplate>;
    doubleClickIsDisabled = false;
    letterTemplateForm: UntypedFormGroup;

    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    constructor(
        private generatedLetterTemplateService: GeneratedLetterTemplateService,
        private notificationsService: NotificationsService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.config = { formObject: [], viewOnly: [] };
        this.setConfig();
    }

    setConfig(): void {
        this.formFactory = new GeneratedLetterTemplateDynamicConfig<IGeneratedLetterTemplate>(this.generatedLetterTemplate, null);
        this.config = this.formFactory.getForUpdate();

        this.viewOnly = this.config?.viewOnly?.map((x) => new DynamicLabel(x));
        this.formObject = this.config.formObject?.map((x) => new DynamicField(x));
    }

    goBack(): void {
        void this.router.navigate(['letter-templates']);
    }

    formSubmitted(form): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.generatedLetterTemplate, form.get('GeneratedLetterTemplate').value as IGeneratedLetterTemplate);
            this.generatedLetterTemplate.Template = this.generatedLetterTemplate.Template.replace(/[^(\x20-\x7F)]*/g, '');
            this.generatedLetterTemplateService.update(this.generatedLetterTemplate).subscribe(() => {
                this.success();
                this.generatedLetterTemplateService.emitChange(this.generatedLetterTemplate);
                this.goBack();
            });
        } else {
            markAllFormFieldsAsTouched(this.form);
            this.error();
        }
    }

    error(): void {
        this.notificationsService.error('Save failed.  Please check the form and try again.');
    }

    success(): void {
        this.notificationsService.success('Letter template saved successfully.');
    }
}
