import { RecordReviewServiceDynamicControlsPartial } from '@model/partials/record-review-service.form-controls.partial';
import {
    IRecordReviewServiceDynamicControlsParameters,
} from '@model/form-controls/record-review-service.form-controls';
import { IRecordReviewService } from '@model/interfaces/record-review-service.d';
import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';

export class WcRecordReviewServiceDynamicConfig<T extends IRecordReviewService> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private recordReviewService: T, private configControls?: string[]) {
        super();
        const additionalParams: IRecordReviewServiceDynamicControlsParameters = {};

        const dynamicControls = new RecordReviewServiceDynamicControlsPartial(this.recordReviewService, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['ImedClaimServiceId', 'RequestDate', 'CreatedById', 'ModifiedById', 'DateCreated', 'DateModified', 'Archived'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
