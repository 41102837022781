// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            .input-list {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            .type {
                display: flex;
                justify-content: flex-start;
            }
            .input-list > *,
            .type > * {
                margin: 5px;
            }
            .small-text {
                font-size: 12px;
                color: #777;
            }
            .scrollable {
                max-height: 710px;
                overflow-y: auto;
                overflow-x: hidden;
            }
        `, "",{"version":3,"sources":["webpack://./src/app/common/select-contacts-email/select-contacts-email.component.ts"],"names":[],"mappings":";YACY;gBACI,WAAW;gBACX,aAAa;gBACb,mBAAmB;gBACnB,8BAA8B;YAClC;YACA;gBACI,aAAa;gBACb,2BAA2B;YAC/B;YACA;;gBAEI,WAAW;YACf;YACA;gBACI,eAAe;gBACf,WAAW;YACf;YACA;gBACI,iBAAiB;gBACjB,gBAAgB;gBAChB,kBAAkB;YACtB","sourcesContent":["\n            .input-list {\n                width: 100%;\n                display: flex;\n                align-items: center;\n                justify-content: space-between;\n            }\n            .type {\n                display: flex;\n                justify-content: flex-start;\n            }\n            .input-list > *,\n            .type > * {\n                margin: 5px;\n            }\n            .small-text {\n                font-size: 12px;\n                color: #777;\n            }\n            .scrollable {\n                max-height: 710px;\n                overflow-y: auto;\n                overflow-x: hidden;\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
