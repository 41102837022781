import { IEntityListConfig, EntityListConfig, EntityListColumn } from '@mt-ng2/entity-list-module';
import { IEmailTemplate } from '@model/interfaces/email-template';
import { EmailTemplateDeleteCellComponent } from './email-template-delete-cell.component';

export class EmailTemplatesEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    name: 'Name',
                }),
                new EntityListColumn({
                    name: 'To',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    name: 'Subject',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    bindToInnerHtml: true,
                    name: 'Body',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    component: EmailTemplateDeleteCellComponent,
                    name: 'Delete',
                    sort: { disableSort: true },
                    style: { width: 50 },
                }),
            ],
            rowClass: (entity: IEmailTemplate) => (entity.Archived ? 'archived' : null),
        };
        super(listConfig);
    }
}
