import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { BaseService } from '@mt-ng2/base-service';
import { SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { IAddress } from '@model/interfaces/address';

export const emptyAddress: IAddress = {
    Address1: null,
    Address2: null,
    Archived: false,
    City: null,
    CreatedById: 0,
    DateCreated: new Date(),
    Id: 0,
    ModifiedById: null,
    StateId: 0,
    Zip: null,
};

export interface IAddressTypeAheadDTO {
    AddressBookId: number;
    Address: string;
    AddressId: number;
}

@Injectable()
export class AddressService extends BaseService<IAddress> {
    canDelete: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    setCanDelete(value: boolean): void {
        this.canDelete.next(value);
    }

    constructor(public http: HttpClient) {
        super('/addresses', http);
    }

    getEmptyAddress(): IAddress {
        return { ...emptyAddress };
    }

    saveAddress(addressBookId: number, address: IAddress): any {
        return this.http.post<number>(`/addresses/address-books/${addressBookId}/addresses`, address);
    }

    saveClaimAddress(claimId: number, address: IAddress): any {
        return this.http.post<number>(`/imedclaims/${claimId}/addresses`, address);
    }

    updateAddress(addressBookId: number, address: IAddress): any {
        return this.http.put<number>(`/addresses/address-books/${addressBookId}/addresses`, address);
    }

    updateClaimAddress(claimId: number, address: IAddress): any {
        return this.http.put<number>(`/imedclaims/${claimId}/addresses`, address);
    }

    searchAddress(addressBookId: number, data: SearchParams): Observable<HttpResponse<IAddress[]>> {
        const params = this.getHttpParams(data);
        return this.http.get<IAddress[]>(`/addresses/address-books/${addressBookId}/addresses/_search`, { observe: 'response', params: params });
    }

    getAddressesForSearchFilter(): Observable<IAddressTypeAheadDTO[]> {
        return this.http.get<IAddressTypeAheadDTO[]>(`/addresses/get-all/`);
    }
}
