import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClaimTypes } from '@model/ClaimTypes';
import { AuthGuard, ClaimValues } from '@mt-ng2/auth-module';
import { AddressBookTypeAssociationsDetailComponent } from './addressbook-type-associations-detail/addressbook-associations-detail.component';
import { AddressBookAssociationHeaderComponent } from './addressbook-association-header.component';

const addressBookStatusAssociationsEntityListConfig = {
    claimType: ClaimTypes.TaskStatusFollowupEngine,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'taskTemplateId',
    title: 'System Tasks',
};

const addressBookStatusAssociationsRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        children: [{ path: '', component: AddressBookTypeAssociationsDetailComponent, pathMatch: 'full' }],
        component: AddressBookAssociationHeaderComponent,
        data: addressBookStatusAssociationsEntityListConfig,
        path: `address-book-type/associations`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(addressBookStatusAssociationsRoutes)],
})
export class AddressBookTypeAssociationsRoutingModule {}
