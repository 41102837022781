import { Component, OnInit } from '@angular/core';
import { CommonSearchService, ICommonSearch } from '../../common/services/common-search.service';
import { UserService } from '../../users/user.service';
import { AuthService } from '@mt-ng2/auth-module';
import { IUserSearchFilter } from '@model/interfaces/user-search-filter';
import { SearchParams } from '@mt-ng2/common-classes';
import { Router } from '@angular/router';

@Component({
    selector: 'app-saved-searches-widget',
    styleUrls: ['../base-dashboard-component/base-dashboard-common-css.component.less'],
    templateUrl: './saved-searches-widget.component.html',
})
export class SavedSearchesWidgetComponent implements OnInit {
    userId: number;
    savedSearches: IUserSearchFilter[];

    constructor(
        private commonSearchService: CommonSearchService,
        private userService: UserService,
        private authService: AuthService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.userService.getByAuthUserId(this.authService.currentUser.getValue().Id).subscribe((user) => {
            this.userId = this.authService.currentUser.getValue().Id;
            this.getSavedServices();
        });
    }

    getSavedServices(): void {
        this.commonSearchService.getSavedSearchFilters('services', this.userId).subscribe((searches) => (this.savedSearches = searches));
    }

    goToServicePage(filter: IUserSearchFilter): void {
        const parsedParams = <SearchParams>JSON.parse(filter.Filter);
        const searchFilter: ICommonSearch = {
            savedSearchName: filter.FilterName,
            searchFilters: parsedParams,
        };
        this.commonSearchService.setCommonSearchFilters(searchFilter);
        void this.router.navigate(['/cases/0/services']);
    }
}
