import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { IGeneratedLetterTemplate } from '@model/interfaces/generated-letter-template';
import { GeneratedLetterTemplateService } from '../generated-letter-template.service';

@Component({
    selector: 'app-generated-letter-template-header',
    templateUrl: './generated-letter-template-header.component.html',
})
export class GeneratedLetterTemplateHeaderComponent implements OnInit, OnDestroy {
    generatedLetterTemplate: IGeneratedLetterTemplate;
    header: string;
    letterTemplateSubscription: Subscription = new Subscription();

    constructor(private generatedLetterTemplateService: GeneratedLetterTemplateService, private route: ActivatedRoute) { }

    ngOnInit(): void {
        this.letterTemplateSubscription.add(
            this.generatedLetterTemplateService.changeEmitted$.subscribe((letterTemplate) => {
                this.setHeader(letterTemplate);
            }),
        );
        const id = +this.route.snapshot.paramMap.get('generatedLetterTemplateId');
        this.generatedLetterTemplateService.getById(id).subscribe((letterTemplate) => {
            this.setHeader(letterTemplate);
        });
    }

    ngOnDestroy(): void {
        this.letterTemplateSubscription.unsubscribe();
    }

    setHeader(letterTemplate: IGeneratedLetterTemplate): void {
        this.generatedLetterTemplate = letterTemplate;
        this.header = `Template: ${letterTemplate.Name}`;
    }
}
