import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { IDynamicField } from '@mt-ng2/dynamic-form';
import { PhoneTypeService } from '../phonetype.service';
import { PhoneNumberDynamicConfig, emptyPhoneNumber } from '../../common/shared-dynamic-configs/phone-number.dynamic-config';
import { IPhoneType } from '@model/interfaces/phone-type';
import { safeDetectChanges } from '../../common/cdr-safety/cdr-safety.library';

@Component({
    selector: 'app-primary-phone',
    templateUrl: './primary-phone.component.html',
})
export class PrimaryPhoneComponent implements OnInit {
    @Input() form: UntypedFormGroup;
    @Input() overrideFormGroup = 'PhoneNumber';

    private _required = true;
    @Input('required')
    get required(): boolean {
        return this._required;
    }
    set required(value: boolean) {
        this._required = value;
        this.updateRequired();
    }

    phoneConfig: IDynamicField[];

    constructor(private phoneTypeService: PhoneTypeService, private cdr: ChangeDetectorRef) { }

    ngOnInit(): void {
        forkJoin(this.phoneTypeService.getItems()).subscribe((forkJoinResponses) => {
            const [phoneTypes] = forkJoinResponses;
            this.setPhoneConfig(phoneTypes);
            safeDetectChanges(this.cdr);
        });
    }

    ngOnDestroy(): void {
        this.cdr.detach();
    }

    setPhoneConfig(phoneTypes: IPhoneType[]): void {
        const phoneControls = ['PhoneTypeId', 'Phone', 'Notes'];
        const phoneNumberConfig = new PhoneNumberDynamicConfig(emptyPhoneNumber, phoneTypes, phoneControls);
        const formObject = phoneNumberConfig.getForCreate().formObject;
        formObject.forEach((field) => {
            if (field.name !== 'Notes') {
                (field ).setRequired(this.required);
            }
            (field ).formGroup = this.overrideFormGroup;
        });
        this.phoneConfig = formObject;
    }

    updateRequired(): void {
        const phoneFormGroup = this.form.get(this.overrideFormGroup) as UntypedFormGroup;
        if (phoneFormGroup) {
            for (const key in phoneFormGroup.controls) {
                if (Object.prototype.hasOwnProperty.call(phoneFormGroup.controls, key)) {
                    (phoneFormGroup.controls[key] ).mtSetRequired(this.required);
                }
            }
        }
    }
}
