import { DynamicField, SelectInputTypes } from '@mt-ng2/dynamic-form';

import {
    PhysicianRecruitmentDynamicControls,
    IPhysicianRecruitmentDynamicControlsParameters,
} from '../form-controls/physician-recruitment.form-controls';
import { IPhysicianRecruitment } from '../interfaces/physician-recruitment';
import { IUser } from '../interfaces/user';

export class PhysicianRecruitmentDynamicControlsPartial extends PhysicianRecruitmentDynamicControls {
    constructor(physicianrecruitmentPartial?: IPhysicianRecruitment, additionalParameters?: IPhysicianRecruitmentDynamicControlsParameters) {
        super(physicianrecruitmentPartial, additionalParameters);

        (<DynamicField>this.View.PhysicianUserId).label = 'User';
        (<DynamicField>this.Form.PhysicianUserId).label = 'User';
        (<DynamicField>this.Form.PhysicianUserId).labelHtml = '<label>User</label>';
        (<DynamicField>this.Form.PhysicianUserId).type.inputType = SelectInputTypes.MultiselectDropdown;
        (<DynamicField>this.Form.PhysicianUserId).options = (<DynamicField>this.Form.PhysicianUserId).options.map((user: IUser) => {
            return Object.assign(user, { Name: user.FirstName + ' ' + user.LastName });
        });
        // LAS: multi select control expects a list of meta items, so i'm spoofing a name property on IUsers that combines first and last name
    }
}
