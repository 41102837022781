import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { INf10Services } from '../interfaces/nf10-services';
import { IUser } from '../interfaces/user';
import { IImedClaimService } from '../interfaces/imed-claim-service';

export interface INf10ServicesDynamicControlsParameters {
    formGroup?: string;
    imedClaimServices?: IImedClaimService[];
    createdBies?: IUser[];
    modifiedBies?: IUser[];
}

export class Nf10ServicesDynamicControls {

    formGroup: string;
    imedClaimServices: IImedClaimService[];
    createdBies: IUser[];
    modifiedBies: IUser[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private nf10services?: INf10Services, additionalParameters?: INf10ServicesDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'Nf10Services';
        this.imedClaimServices = additionalParameters && additionalParameters.imedClaimServices || undefined;
        this.createdBies = additionalParameters && additionalParameters.createdBies || undefined;
        this.modifiedBies = additionalParameters && additionalParameters.modifiedBies || undefined;

        this.Form = {
            AmountInDispute: new DynamicField({
                formGroup: this.formGroup,
                label: 'Amount In Dispute',
                name: 'AmountInDispute',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.AmountInDispute || 0,
            }),
            AmountOfBill: new DynamicField({
                formGroup: this.formGroup,
                label: 'Amount Of Bill',
                name: 'AmountOfBill',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.AmountOfBill || 0,
            }),
            AmountOfClaimExceedsDailyLimit: new DynamicField({
                formGroup: this.formGroup,
                label: 'Amount Of Claim Exceeds Daily Limit',
                name: 'AmountOfClaimExceedsDailyLimit',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.hasOwnProperty('AmountOfClaimExceedsDailyLimit') && this.nf10services.AmountOfClaimExceedsDailyLimit !== null ? this.nf10services.AmountOfClaimExceedsDailyLimit : false,
            }),
            AmountPaidByInsurer: new DynamicField({
                formGroup: this.formGroup,
                label: 'Amount Paid By Insurer',
                name: 'AmountPaidByInsurer',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.AmountPaidByInsurer || 0,
            }),
            Archived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived',
                name: 'Archived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.hasOwnProperty('Archived') && this.nf10services.Archived !== null ? this.nf10services.Archived : false,
            }),
            AsAssignee: new DynamicField({
                formGroup: this.formGroup,
                label: 'As Assignee',
                name: 'AsAssignee',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.hasOwnProperty('AsAssignee') && this.nf10services.AsAssignee !== null ? this.nf10services.AsAssignee : false,
            }),
            Attorneyfee: new DynamicField({
                formGroup: this.formGroup,
                label: 'Attorneyfee',
                name: 'Attorneyfee',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.hasOwnProperty('Attorneyfee') && this.nf10services.Attorneyfee !== null ? this.nf10services.Attorneyfee : false,
            }),
            AttorneyfeeAmount: new DynamicField({
                formGroup: this.formGroup,
                label: 'Attorneyfee Amount',
                name: 'AttorneyfeeAmount',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.AttorneyfeeAmount || 0,
            }),
            ClaimedLossNotProven: new DynamicField({
                formGroup: this.formGroup,
                label: 'Claimed Loss Not Proven',
                name: 'ClaimedLossNotProven',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.hasOwnProperty('ClaimedLossNotProven') && this.nf10services.ClaimedLossNotProven !== null ? this.nf10services.ClaimedLossNotProven : false,
            }),
            ClaimNotWithinScopeOfElection: new DynamicField({
                formGroup: this.formGroup,
                label: 'Claim Not Within Scope Of Election',
                name: 'ClaimNotWithinScopeOfElection',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.hasOwnProperty('ClaimNotWithinScopeOfElection') && this.nf10services.ClaimNotWithinScopeOfElection !== null ? this.nf10services.ClaimNotWithinScopeOfElection : false,
            }),
            CreatedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Created By',
                name: 'CreatedById',
                options: this.createdBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.nf10services && this.nf10services.CreatedById || null,
            }),
            DateBillReceivedByInsurer: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Bill Received By Insurer',
                name: 'DateBillReceivedByInsurer',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.DateBillReceivedByInsurer || null,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.DateCreated || null,
            }),
            DateFinalVerificationReceived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Final Verification Received',
                name: 'DateFinalVerificationReceived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.DateFinalVerificationReceived || null,
            }),
            DateFinalVerificationRequested: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Final Verification Requested',
                name: 'DateFinalVerificationRequested',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.DateFinalVerificationRequested || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.DateModified || null,
            }),
            DateOfBill: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Of Bill',
                name: 'DateOfBill',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.DateOfBill || null,
            }),
            DeathBenefit: new DynamicField({
                formGroup: this.formGroup,
                label: 'Death Benefit',
                name: 'DeathBenefit',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.hasOwnProperty('DeathBenefit') && this.nf10services.DeathBenefit !== null ? this.nf10services.DeathBenefit : false,
            }),
            DeathBenefitAmount: new DynamicField({
                formGroup: this.formGroup,
                label: 'Death Benefit Amount',
                name: 'DeathBenefitAmount',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.DeathBenefitAmount || 0,
            }),
            ExaggeratedEarningClaimDenied: new DynamicField({
                formGroup: this.formGroup,
                label: 'Exaggerated Earning Claim Denied',
                name: 'ExaggeratedEarningClaimDenied',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.hasOwnProperty('ExaggeratedEarningClaimDenied') && this.nf10services.ExaggeratedEarningClaimDenied !== null ? this.nf10services.ExaggeratedEarningClaimDenied : false,
            }),
            ExaggeratedEarningClaimPerMonth: new DynamicField({
                formGroup: this.formGroup,
                label: 'Exaggerated Earning Claim Per Month',
                name: 'ExaggeratedEarningClaimPerMonth',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.ExaggeratedEarningClaimPerMonth || 0,
            }),
            ExcessiveTreatment: new DynamicField({
                formGroup: this.formGroup,
                label: 'Excessive Treatment',
                name: 'ExcessiveTreatment',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.hasOwnProperty('ExcessiveTreatment') && this.nf10services.ExcessiveTreatment !== null ? this.nf10services.ExcessiveTreatment : false,
            }),
            ExcessiveTreatmentFromDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Excessive Treatment From Date',
                name: 'ExcessiveTreatmentFromDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.ExcessiveTreatmentFromDate || null,
            }),
            ExcessiveTreatmentToDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Excessive Treatment To Date',
                name: 'ExcessiveTreatmentToDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.ExcessiveTreatmentToDate || null,
            }),
            FeesNotInAccordanceWithFeeSchedules: new DynamicField({
                formGroup: this.formGroup,
                label: 'Fees Not In Accordance With Fee Schedules',
                name: 'FeesNotInAccordanceWithFeeSchedules',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.hasOwnProperty('FeesNotInAccordanceWithFeeSchedules') && this.nf10services.FeesNotInAccordanceWithFeeSchedules !== null ? this.nf10services.FeesNotInAccordanceWithFeeSchedules : false,
            }),
            HealthServiceBenefits: new DynamicField({
                formGroup: this.formGroup,
                label: 'Health Service Benefits',
                name: 'HealthServiceBenefits',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.hasOwnProperty('HealthServiceBenefits') && this.nf10services.HealthServiceBenefits !== null ? this.nf10services.HealthServiceBenefits : false,
            }),
            HealthServiceBenefitsAmount: new DynamicField({
                formGroup: this.formGroup,
                label: 'Health Service Benefits Amount',
                name: 'HealthServiceBenefitsAmount',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.HealthServiceBenefitsAmount || 0,
            }),
            HealthServiceBenefitsDeniedOther: new DynamicField({
                formGroup: this.formGroup,
                label: 'Health Service Benefits Denied Other',
                name: 'HealthServiceBenefitsDeniedOther',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.hasOwnProperty('HealthServiceBenefitsDeniedOther') && this.nf10services.HealthServiceBenefitsDeniedOther !== null ? this.nf10services.HealthServiceBenefitsDeniedOther : false,
            }),
            Ime: new DynamicField({
                formGroup: this.formGroup,
                label: 'Ime',
                name: 'Ime',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.hasOwnProperty('Ime') && this.nf10services.Ime !== null ? this.nf10services.Ime : false,
            }),
            ImedClaimServiceId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Imed Claim Service',
                name: 'ImedClaimServiceId',
                options: this.imedClaimServices,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.nf10services && this.nf10services.ImedClaimServiceId || null,
            }),
            IncurredAfterOneYearFromDateOfAccident: new DynamicField({
                formGroup: this.formGroup,
                label: 'Incurred After One Year From Date Of Accident',
                name: 'IncurredAfterOneYearFromDateOfAccident',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.hasOwnProperty('IncurredAfterOneYearFromDateOfAccident') && this.nf10services.IncurredAfterOneYearFromDateOfAccident !== null ? this.nf10services.IncurredAfterOneYearFromDateOfAccident : false,
            }),
            InjuredPersonExcludedUnderPolicy: new DynamicField({
                formGroup: this.formGroup,
                label: 'Injured Person Excluded Under Policy',
                name: 'InjuredPersonExcludedUnderPolicy',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.hasOwnProperty('InjuredPersonExcludedUnderPolicy') && this.nf10services.InjuredPersonExcludedUnderPolicy !== null ? this.nf10services.InjuredPersonExcludedUnderPolicy : false,
            }),
            InjuredPersonNotEligible: new DynamicField({
                formGroup: this.formGroup,
                label: 'Injured Person Not Eligible',
                name: 'InjuredPersonNotEligible',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.hasOwnProperty('InjuredPersonNotEligible') && this.nf10services.InjuredPersonNotEligible !== null ? this.nf10services.InjuredPersonNotEligible : false,
            }),
            InjuriesDidNotAriseFromMv: new DynamicField({
                formGroup: this.formGroup,
                label: 'Injuries Did Not Arise From Mv',
                name: 'InjuriesDidNotAriseFromMv',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.hasOwnProperty('InjuriesDidNotAriseFromMv') && this.nf10services.InjuriesDidNotAriseFromMv !== null ? this.nf10services.InjuriesDidNotAriseFromMv : false,
            }),
            Interest: new DynamicField({
                formGroup: this.formGroup,
                label: 'Interest',
                name: 'Interest',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.hasOwnProperty('Interest') && this.nf10services.Interest !== null ? this.nf10services.Interest : false,
            }),
            InterestAmount: new DynamicField({
                formGroup: this.formGroup,
                label: 'Interest Amount',
                name: 'InterestAmount',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.InterestAmount || 0,
            }),
            IsEntireClaimDenied: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Entire Claim Denied',
                name: 'IsEntireClaimDenied',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.hasOwnProperty('IsEntireClaimDenied') && this.nf10services.IsEntireClaimDenied !== null ? this.nf10services.IsEntireClaimDenied : false,
            }),
            IsPortionOfClaimDenied: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Portion Of Claim Denied',
                name: 'IsPortionOfClaimDenied',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.hasOwnProperty('IsPortionOfClaimDenied') && this.nf10services.IsPortionOfClaimDenied !== null ? this.nf10services.IsPortionOfClaimDenied : false,
            }),
            LossOfEarnings: new DynamicField({
                formGroup: this.formGroup,
                label: 'Loss Of Earnings',
                name: 'LossOfEarnings',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.hasOwnProperty('LossOfEarnings') && this.nf10services.LossOfEarnings !== null ? this.nf10services.LossOfEarnings : false,
            }),
            LossOfEarningsAmount: new DynamicField({
                formGroup: this.formGroup,
                label: 'Loss Of Earnings Amount',
                name: 'LossOfEarningsAmount',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.LossOfEarningsAmount || 0,
            }),
            LossOfEarningsBenefitsDeniedOther: new DynamicField({
                formGroup: this.formGroup,
                label: 'Loss Of Earnings Benefits Denied Other',
                name: 'LossOfEarningsBenefitsDeniedOther',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.hasOwnProperty('LossOfEarningsBenefitsDeniedOther') && this.nf10services.LossOfEarningsBenefitsDeniedOther !== null ? this.nf10services.LossOfEarningsBenefitsDeniedOther : false,
            }),
            ModifiedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Modified By',
                name: 'ModifiedById',
                options: this.modifiedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.ModifiedById || null,
            }),
            NoReasonableJustificationGivenForLateNoticeOfClaim: new DynamicField({
                formGroup: this.formGroup,
                label: 'No Reasonable Justification Given For Late Notice Of Claim',
                name: 'NoReasonableJustificationGivenForLateNoticeOfClaim',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.hasOwnProperty('NoReasonableJustificationGivenForLateNoticeOfClaim') && this.nf10services.NoReasonableJustificationGivenForLateNoticeOfClaim !== null ? this.nf10services.NoReasonableJustificationGivenForLateNoticeOfClaim : false,
            }),
            OtherNecessaryExpenses: new DynamicField({
                formGroup: this.formGroup,
                label: 'Other Necessary Expenses',
                name: 'OtherNecessaryExpenses',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.hasOwnProperty('OtherNecessaryExpenses') && this.nf10services.OtherNecessaryExpenses !== null ? this.nf10services.OtherNecessaryExpenses : false,
            }),
            OtherNecessaryExpensesAmount: new DynamicField({
                formGroup: this.formGroup,
                label: 'Other Necessary Expenses Amount',
                name: 'OtherNecessaryExpensesAmount',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.OtherNecessaryExpensesAmount || 0,
            }),
            OtherReasonableAndNecessaryExpensesDeniedOther: new DynamicField({
                formGroup: this.formGroup,
                label: 'Other Reasonable And Necessary Expenses Denied Other',
                name: 'OtherReasonableAndNecessaryExpensesDeniedOther',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.hasOwnProperty('OtherReasonableAndNecessaryExpensesDeniedOther') && this.nf10services.OtherReasonableAndNecessaryExpensesDeniedOther !== null ? this.nf10services.OtherReasonableAndNecessaryExpensesDeniedOther : false,
            }),
            Peer: new DynamicField({
                formGroup: this.formGroup,
                label: 'Peer',
                name: 'Peer',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.hasOwnProperty('Peer') && this.nf10services.Peer !== null ? this.nf10services.Peer : false,
            }),
            PeriodOfBillTreatmentDates: new DynamicField({
                formGroup: this.formGroup,
                label: 'Period Of Bill Treatment Dates',
                name: 'PeriodOfBillTreatmentDates',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.nf10services && this.nf10services.hasOwnProperty('PeriodOfBillTreatmentDates') && this.nf10services.PeriodOfBillTreatmentDates !== null ? this.nf10services.PeriodOfBillTreatmentDates.toString() : '',
            }),
            PeriodOfDisability: new DynamicField({
                formGroup: this.formGroup,
                label: 'Period Of Disability',
                name: 'PeriodOfDisability',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.hasOwnProperty('PeriodOfDisability') && this.nf10services.PeriodOfDisability !== null ? this.nf10services.PeriodOfDisability : false,
            }),
            PeriodOfDisabilityFrom: new DynamicField({
                formGroup: this.formGroup,
                label: 'Period Of Disability From',
                name: 'PeriodOfDisabilityFrom',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.PeriodOfDisabilityFrom || null,
            }),
            PeriodOfDisabilityTo: new DynamicField({
                formGroup: this.formGroup,
                label: 'Period Of Disability To',
                name: 'PeriodOfDisabilityTo',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.PeriodOfDisabilityTo || null,
            }),
            PolicyConditionsViolated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Policy Conditions Violated',
                name: 'PolicyConditionsViolated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.hasOwnProperty('PolicyConditionsViolated') && this.nf10services.PolicyConditionsViolated !== null ? this.nf10services.PolicyConditionsViolated : false,
            }),
            PolicyHolder: new DynamicField({
                formGroup: this.formGroup,
                label: 'Policy Holder',
                name: 'PolicyHolder',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.nf10services && this.nf10services.hasOwnProperty('PolicyHolder') && this.nf10services.PolicyHolder !== null ? this.nf10services.PolicyHolder.toString() : '',
            }),
            PolicyNotInForce: new DynamicField({
                formGroup: this.formGroup,
                label: 'Policy Not In Force',
                name: 'PolicyNotInForce',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.hasOwnProperty('PolicyNotInForce') && this.nf10services.PolicyNotInForce !== null ? this.nf10services.PolicyNotInForce : false,
            }),
            PolicyNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Policy Number',
                name: 'PolicyNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.nf10services && this.nf10services.hasOwnProperty('PolicyNumber') && this.nf10services.PolicyNumber !== null ? this.nf10services.PolicyNumber.toString() : '',
            }),
            ReasonableJustificationNotEstablished: new DynamicField({
                formGroup: this.formGroup,
                label: 'Reasonable Justification Not Established',
                name: 'ReasonableJustificationNotEstablished',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.hasOwnProperty('ReasonableJustificationNotEstablished') && this.nf10services.ReasonableJustificationNotEstablished !== null ? this.nf10services.ReasonableJustificationNotEstablished : false,
            }),
            StatutoryOffsetTaken: new DynamicField({
                formGroup: this.formGroup,
                label: 'Statutory Offset Taken',
                name: 'StatutoryOffsetTaken',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.hasOwnProperty('StatutoryOffsetTaken') && this.nf10services.StatutoryOffsetTaken !== null ? this.nf10services.StatutoryOffsetTaken : false,
            }),
            SuggestedExplanationOfDenial: new DynamicField({
                formGroup: this.formGroup,
                label: 'Suggested Explanation Of Denial',
                name: 'SuggestedExplanationOfDenial',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.hasOwnProperty('SuggestedExplanationOfDenial') && this.nf10services.SuggestedExplanationOfDenial !== null ? this.nf10services.SuggestedExplanationOfDenial.toString() : '',
            }),
            TreatmentNotRelatedToAccident: new DynamicField({
                formGroup: this.formGroup,
                label: 'Treatment Not Related To Accident',
                name: 'TreatmentNotRelatedToAccident',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.hasOwnProperty('TreatmentNotRelatedToAccident') && this.nf10services.TreatmentNotRelatedToAccident !== null ? this.nf10services.TreatmentNotRelatedToAccident : false,
            }),
            UnnecessaryTreatment: new DynamicField({
                formGroup: this.formGroup,
                label: 'Unnecessary Treatment',
                name: 'UnnecessaryTreatment',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.hasOwnProperty('UnnecessaryTreatment') && this.nf10services.UnnecessaryTreatment !== null ? this.nf10services.UnnecessaryTreatment : false,
            }),
            UnnecessaryTreatmentFromDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Unnecessary Treatment From Date',
                name: 'UnnecessaryTreatmentFromDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.UnnecessaryTreatmentFromDate || null,
            }),
            UnnecessaryTreatmentToDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Unnecessary Treatment To Date',
                name: 'UnnecessaryTreatmentToDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.UnnecessaryTreatmentToDate || null,
            }),
            UnreasonableOrUnnecessaryExpenses: new DynamicField({
                formGroup: this.formGroup,
                label: 'Unreasonable Or Unnecessary Expenses',
                name: 'UnreasonableOrUnnecessaryExpenses',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.nf10services && this.nf10services.hasOwnProperty('UnreasonableOrUnnecessaryExpenses') && this.nf10services.UnreasonableOrUnnecessaryExpenses !== null ? this.nf10services.UnreasonableOrUnnecessaryExpenses : false,
            }),
        };

        this.View = {
            AmountInDispute: new DynamicLabel({
			    label: 'Amount In Dispute',
			    value: this.nf10services && this.nf10services.AmountInDispute || 0,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: null,
			        scale: 2,
			    })
			}
            ),
            AmountOfBill: new DynamicLabel({
			    label: 'Amount Of Bill',
			    value: this.nf10services && this.nf10services.AmountOfBill || 0,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: null,
			        scale: 2,
			    })
			}
            ),
            AmountOfClaimExceedsDailyLimit: new DynamicLabel({
			    label: 'Amount Of Claim Exceeds Daily Limit',
			    value: this.nf10services && this.nf10services.hasOwnProperty('AmountOfClaimExceedsDailyLimit') && this.nf10services.AmountOfClaimExceedsDailyLimit !== null ? this.nf10services.AmountOfClaimExceedsDailyLimit : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            AmountPaidByInsurer: new DynamicLabel({
			    label: 'Amount Paid By Insurer',
			    value: this.nf10services && this.nf10services.AmountPaidByInsurer || 0,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: null,
			        scale: 2,
			    })
			}
            ),
            Archived: new DynamicLabel({
			    label: 'Archived',
			    value: this.nf10services && this.nf10services.hasOwnProperty('Archived') && this.nf10services.Archived !== null ? this.nf10services.Archived : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            AsAssignee: new DynamicLabel({
			    label: 'As Assignee',
			    value: this.nf10services && this.nf10services.hasOwnProperty('AsAssignee') && this.nf10services.AsAssignee !== null ? this.nf10services.AsAssignee : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Attorneyfee: new DynamicLabel({
			    label: 'Attorneyfee',
			    value: this.nf10services && this.nf10services.hasOwnProperty('Attorneyfee') && this.nf10services.Attorneyfee !== null ? this.nf10services.Attorneyfee : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            AttorneyfeeAmount: new DynamicLabel({
			    label: 'Attorneyfee Amount',
			    value: this.nf10services && this.nf10services.AttorneyfeeAmount || 0,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: null,
			        scale: 2,
			    })
			}
            ),
            ClaimedLossNotProven: new DynamicLabel({
			    label: 'Claimed Loss Not Proven',
			    value: this.nf10services && this.nf10services.hasOwnProperty('ClaimedLossNotProven') && this.nf10services.ClaimedLossNotProven !== null ? this.nf10services.ClaimedLossNotProven : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ClaimNotWithinScopeOfElection: new DynamicLabel({
			    label: 'Claim Not Within Scope Of Election',
			    value: this.nf10services && this.nf10services.hasOwnProperty('ClaimNotWithinScopeOfElection') && this.nf10services.ClaimNotWithinScopeOfElection !== null ? this.nf10services.ClaimNotWithinScopeOfElection : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            CreatedById: new DynamicLabel({
			    label: 'Created By',
			    value: getMetaItemValue(this.createdBies as unknown as IMetaItem[], this.nf10services && this.nf10services.hasOwnProperty('CreatedById') && this.nf10services.CreatedById !== null ? this.nf10services.CreatedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            DateBillReceivedByInsurer: new DynamicLabel({
			    label: 'Date Bill Received By Insurer',
			    value: this.nf10services && this.nf10services.DateBillReceivedByInsurer || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DateCreated: new DynamicLabel({
			    label: 'Date Created',
			    value: this.nf10services && this.nf10services.DateCreated || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DateFinalVerificationReceived: new DynamicLabel({
			    label: 'Date Final Verification Received',
			    value: this.nf10services && this.nf10services.DateFinalVerificationReceived || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DateFinalVerificationRequested: new DynamicLabel({
			    label: 'Date Final Verification Requested',
			    value: this.nf10services && this.nf10services.DateFinalVerificationRequested || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DateModified: new DynamicLabel({
			    label: 'Date Modified',
			    value: this.nf10services && this.nf10services.DateModified || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DateOfBill: new DynamicLabel({
			    label: 'Date Of Bill',
			    value: this.nf10services && this.nf10services.DateOfBill || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DeathBenefit: new DynamicLabel({
			    label: 'Death Benefit',
			    value: this.nf10services && this.nf10services.hasOwnProperty('DeathBenefit') && this.nf10services.DeathBenefit !== null ? this.nf10services.DeathBenefit : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            DeathBenefitAmount: new DynamicLabel({
			    label: 'Death Benefit Amount',
			    value: this.nf10services && this.nf10services.DeathBenefitAmount || 0,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: null,
			        scale: 2,
			    })
			}
            ),
            ExaggeratedEarningClaimDenied: new DynamicLabel({
			    label: 'Exaggerated Earning Claim Denied',
			    value: this.nf10services && this.nf10services.hasOwnProperty('ExaggeratedEarningClaimDenied') && this.nf10services.ExaggeratedEarningClaimDenied !== null ? this.nf10services.ExaggeratedEarningClaimDenied : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ExaggeratedEarningClaimPerMonth: new DynamicLabel({
			    label: 'Exaggerated Earning Claim Per Month',
			    value: this.nf10services && this.nf10services.ExaggeratedEarningClaimPerMonth || 0,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: null,
			        scale: 2,
			    })
			}
            ),
            ExcessiveTreatment: new DynamicLabel({
			    label: 'Excessive Treatment',
			    value: this.nf10services && this.nf10services.hasOwnProperty('ExcessiveTreatment') && this.nf10services.ExcessiveTreatment !== null ? this.nf10services.ExcessiveTreatment : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ExcessiveTreatmentFromDate: new DynamicLabel({
			    label: 'Excessive Treatment From Date',
			    value: this.nf10services && this.nf10services.ExcessiveTreatmentFromDate || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            ExcessiveTreatmentToDate: new DynamicLabel({
			    label: 'Excessive Treatment To Date',
			    value: this.nf10services && this.nf10services.ExcessiveTreatmentToDate || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            FeesNotInAccordanceWithFeeSchedules: new DynamicLabel({
			    label: 'Fees Not In Accordance With Fee Schedules',
			    value: this.nf10services && this.nf10services.hasOwnProperty('FeesNotInAccordanceWithFeeSchedules') && this.nf10services.FeesNotInAccordanceWithFeeSchedules !== null ? this.nf10services.FeesNotInAccordanceWithFeeSchedules : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            HealthServiceBenefits: new DynamicLabel({
			    label: 'Health Service Benefits',
			    value: this.nf10services && this.nf10services.hasOwnProperty('HealthServiceBenefits') && this.nf10services.HealthServiceBenefits !== null ? this.nf10services.HealthServiceBenefits : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            HealthServiceBenefitsAmount: new DynamicLabel({
			    label: 'Health Service Benefits Amount',
			    value: this.nf10services && this.nf10services.HealthServiceBenefitsAmount || 0,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: null,
			        scale: 2,
			    })
			}
            ),
            HealthServiceBenefitsDeniedOther: new DynamicLabel({
			    label: 'Health Service Benefits Denied Other',
			    value: this.nf10services && this.nf10services.hasOwnProperty('HealthServiceBenefitsDeniedOther') && this.nf10services.HealthServiceBenefitsDeniedOther !== null ? this.nf10services.HealthServiceBenefitsDeniedOther : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Ime: new DynamicLabel({
			    label: 'Ime',
			    value: this.nf10services && this.nf10services.hasOwnProperty('Ime') && this.nf10services.Ime !== null ? this.nf10services.Ime : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ImedClaimServiceId: new DynamicLabel({
			    label: 'Imed Claim Service',
			    value: getMetaItemValue(this.imedClaimServices as unknown as IMetaItem[], this.nf10services && this.nf10services.hasOwnProperty('ImedClaimServiceId') && this.nf10services.ImedClaimServiceId !== null ? this.nf10services.ImedClaimServiceId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            IncurredAfterOneYearFromDateOfAccident: new DynamicLabel({
			    label: 'Incurred After One Year From Date Of Accident',
			    value: this.nf10services && this.nf10services.hasOwnProperty('IncurredAfterOneYearFromDateOfAccident') && this.nf10services.IncurredAfterOneYearFromDateOfAccident !== null ? this.nf10services.IncurredAfterOneYearFromDateOfAccident : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            InjuredPersonExcludedUnderPolicy: new DynamicLabel({
			    label: 'Injured Person Excluded Under Policy',
			    value: this.nf10services && this.nf10services.hasOwnProperty('InjuredPersonExcludedUnderPolicy') && this.nf10services.InjuredPersonExcludedUnderPolicy !== null ? this.nf10services.InjuredPersonExcludedUnderPolicy : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            InjuredPersonNotEligible: new DynamicLabel({
			    label: 'Injured Person Not Eligible',
			    value: this.nf10services && this.nf10services.hasOwnProperty('InjuredPersonNotEligible') && this.nf10services.InjuredPersonNotEligible !== null ? this.nf10services.InjuredPersonNotEligible : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            InjuriesDidNotAriseFromMv: new DynamicLabel({
			    label: 'Injuries Did Not Arise From Mv',
			    value: this.nf10services && this.nf10services.hasOwnProperty('InjuriesDidNotAriseFromMv') && this.nf10services.InjuriesDidNotAriseFromMv !== null ? this.nf10services.InjuriesDidNotAriseFromMv : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Interest: new DynamicLabel({
			    label: 'Interest',
			    value: this.nf10services && this.nf10services.hasOwnProperty('Interest') && this.nf10services.Interest !== null ? this.nf10services.Interest : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            InterestAmount: new DynamicLabel({
			    label: 'Interest Amount',
			    value: this.nf10services && this.nf10services.InterestAmount || 0,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: null,
			        scale: 2,
			    })
			}
            ),
            IsEntireClaimDenied: new DynamicLabel({
			    label: 'Is Entire Claim Denied',
			    value: this.nf10services && this.nf10services.hasOwnProperty('IsEntireClaimDenied') && this.nf10services.IsEntireClaimDenied !== null ? this.nf10services.IsEntireClaimDenied : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            IsPortionOfClaimDenied: new DynamicLabel({
			    label: 'Is Portion Of Claim Denied',
			    value: this.nf10services && this.nf10services.hasOwnProperty('IsPortionOfClaimDenied') && this.nf10services.IsPortionOfClaimDenied !== null ? this.nf10services.IsPortionOfClaimDenied : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            LossOfEarnings: new DynamicLabel({
			    label: 'Loss Of Earnings',
			    value: this.nf10services && this.nf10services.hasOwnProperty('LossOfEarnings') && this.nf10services.LossOfEarnings !== null ? this.nf10services.LossOfEarnings : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            LossOfEarningsAmount: new DynamicLabel({
			    label: 'Loss Of Earnings Amount',
			    value: this.nf10services && this.nf10services.LossOfEarningsAmount || 0,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: null,
			        scale: 2,
			    })
			}
            ),
            LossOfEarningsBenefitsDeniedOther: new DynamicLabel({
			    label: 'Loss Of Earnings Benefits Denied Other',
			    value: this.nf10services && this.nf10services.hasOwnProperty('LossOfEarningsBenefitsDeniedOther') && this.nf10services.LossOfEarningsBenefitsDeniedOther !== null ? this.nf10services.LossOfEarningsBenefitsDeniedOther : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ModifiedById: new DynamicLabel({
			    label: 'Modified By',
			    value: getMetaItemValue(this.modifiedBies as unknown as IMetaItem[], this.nf10services && this.nf10services.hasOwnProperty('ModifiedById') && this.nf10services.ModifiedById !== null ? this.nf10services.ModifiedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            NoReasonableJustificationGivenForLateNoticeOfClaim: new DynamicLabel({
			    label: 'No Reasonable Justification Given For Late Notice Of Claim',
			    value: this.nf10services && this.nf10services.hasOwnProperty('NoReasonableJustificationGivenForLateNoticeOfClaim') && this.nf10services.NoReasonableJustificationGivenForLateNoticeOfClaim !== null ? this.nf10services.NoReasonableJustificationGivenForLateNoticeOfClaim : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            OtherNecessaryExpenses: new DynamicLabel({
			    label: 'Other Necessary Expenses',
			    value: this.nf10services && this.nf10services.hasOwnProperty('OtherNecessaryExpenses') && this.nf10services.OtherNecessaryExpenses !== null ? this.nf10services.OtherNecessaryExpenses : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            OtherNecessaryExpensesAmount: new DynamicLabel({
			    label: 'Other Necessary Expenses Amount',
			    value: this.nf10services && this.nf10services.OtherNecessaryExpensesAmount || 0,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: null,
			        scale: 2,
			    })
			}
            ),
            OtherReasonableAndNecessaryExpensesDeniedOther: new DynamicLabel({
			    label: 'Other Reasonable And Necessary Expenses Denied Other',
			    value: this.nf10services && this.nf10services.hasOwnProperty('OtherReasonableAndNecessaryExpensesDeniedOther') && this.nf10services.OtherReasonableAndNecessaryExpensesDeniedOther !== null ? this.nf10services.OtherReasonableAndNecessaryExpensesDeniedOther : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Peer: new DynamicLabel({
			    label: 'Peer',
			    value: this.nf10services && this.nf10services.hasOwnProperty('Peer') && this.nf10services.Peer !== null ? this.nf10services.Peer : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            PeriodOfBillTreatmentDates: new DynamicLabel({
			    label: 'Period Of Bill Treatment Dates',
			    value: this.nf10services && this.nf10services.hasOwnProperty('PeriodOfBillTreatmentDates') && this.nf10services.PeriodOfBillTreatmentDates !== null ? this.nf10services.PeriodOfBillTreatmentDates.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            PeriodOfDisability: new DynamicLabel({
			    label: 'Period Of Disability',
			    value: this.nf10services && this.nf10services.hasOwnProperty('PeriodOfDisability') && this.nf10services.PeriodOfDisability !== null ? this.nf10services.PeriodOfDisability : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            PeriodOfDisabilityFrom: new DynamicLabel({
			    label: 'Period Of Disability From',
			    value: this.nf10services && this.nf10services.PeriodOfDisabilityFrom || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            PeriodOfDisabilityTo: new DynamicLabel({
			    label: 'Period Of Disability To',
			    value: this.nf10services && this.nf10services.PeriodOfDisabilityTo || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            PolicyConditionsViolated: new DynamicLabel({
			    label: 'Policy Conditions Violated',
			    value: this.nf10services && this.nf10services.hasOwnProperty('PolicyConditionsViolated') && this.nf10services.PolicyConditionsViolated !== null ? this.nf10services.PolicyConditionsViolated : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            PolicyHolder: new DynamicLabel({
			    label: 'Policy Holder',
			    value: this.nf10services && this.nf10services.hasOwnProperty('PolicyHolder') && this.nf10services.PolicyHolder !== null ? this.nf10services.PolicyHolder.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            PolicyNotInForce: new DynamicLabel({
			    label: 'Policy Not In Force',
			    value: this.nf10services && this.nf10services.hasOwnProperty('PolicyNotInForce') && this.nf10services.PolicyNotInForce !== null ? this.nf10services.PolicyNotInForce : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            PolicyNumber: new DynamicLabel({
			    label: 'Policy Number',
			    value: this.nf10services && this.nf10services.hasOwnProperty('PolicyNumber') && this.nf10services.PolicyNumber !== null ? this.nf10services.PolicyNumber.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ReasonableJustificationNotEstablished: new DynamicLabel({
			    label: 'Reasonable Justification Not Established',
			    value: this.nf10services && this.nf10services.hasOwnProperty('ReasonableJustificationNotEstablished') && this.nf10services.ReasonableJustificationNotEstablished !== null ? this.nf10services.ReasonableJustificationNotEstablished : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            StatutoryOffsetTaken: new DynamicLabel({
			    label: 'Statutory Offset Taken',
			    value: this.nf10services && this.nf10services.hasOwnProperty('StatutoryOffsetTaken') && this.nf10services.StatutoryOffsetTaken !== null ? this.nf10services.StatutoryOffsetTaken : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            SuggestedExplanationOfDenial: new DynamicLabel({
			    label: 'Suggested Explanation Of Denial',
			    value: this.nf10services && this.nf10services.hasOwnProperty('SuggestedExplanationOfDenial') && this.nf10services.SuggestedExplanationOfDenial !== null ? this.nf10services.SuggestedExplanationOfDenial.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Textarea,
			        scale: null,
			    })
			}
            ),
            TreatmentNotRelatedToAccident: new DynamicLabel({
			    label: 'Treatment Not Related To Accident',
			    value: this.nf10services && this.nf10services.hasOwnProperty('TreatmentNotRelatedToAccident') && this.nf10services.TreatmentNotRelatedToAccident !== null ? this.nf10services.TreatmentNotRelatedToAccident : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            UnnecessaryTreatment: new DynamicLabel({
			    label: 'Unnecessary Treatment',
			    value: this.nf10services && this.nf10services.hasOwnProperty('UnnecessaryTreatment') && this.nf10services.UnnecessaryTreatment !== null ? this.nf10services.UnnecessaryTreatment : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            UnnecessaryTreatmentFromDate: new DynamicLabel({
			    label: 'Unnecessary Treatment From Date',
			    value: this.nf10services && this.nf10services.UnnecessaryTreatmentFromDate || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            UnnecessaryTreatmentToDate: new DynamicLabel({
			    label: 'Unnecessary Treatment To Date',
			    value: this.nf10services && this.nf10services.UnnecessaryTreatmentToDate || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            UnreasonableOrUnnecessaryExpenses: new DynamicLabel({
			    label: 'Unreasonable Or Unnecessary Expenses',
			    value: this.nf10services && this.nf10services.hasOwnProperty('UnreasonableOrUnnecessaryExpenses') && this.nf10services.UnreasonableOrUnnecessaryExpenses !== null ? this.nf10services.UnreasonableOrUnnecessaryExpenses : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };

    }
}
