import { DynamicField, DynamicFieldTypes, DynamicLabel } from '@mt-ng2/dynamic-form';
import { IRecordReviewServiceDynamicControlsParameters } from './../form-controls/record-review-service.form-controls';
import { RecordReviewServiceDynamicControls } from './../form-controls/record-review-service.form-controls';
import { IRecordReviewService } from '../interfaces/record-review-service';

export class RecordReviewServiceDynamicControlsPartial extends RecordReviewServiceDynamicControls {
    constructor(private service?: IRecordReviewService, additionalParameters?: IRecordReviewServiceDynamicControlsParameters) {
        super(service, additionalParameters);

        (<DynamicField>this.Form.PatientPreviouslyTreatedByPhysician).label = 'Has patient ever been treated by indicated expert / physician?';
        (<DynamicField>this.Form.ConflictOfInterestApproved).label = 'Has client approved possible conflict of interest?';

        (<DynamicLabel>this.View.PatientPreviouslyTreatedByPhysician).label = 'Has patient ever been treated with indicated expert / physician? ';
        (<DynamicLabel>this.View.ConflictOfInterestApproved).label = 'Has client approved possible conflict of interest? ';
    }
}
