// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            .frequency-table tr {
                background-color: #fff;
                cursor: default;
                border: 1px solid #aaa;
            }
            .frequency-table:hover,
            tr:hover {
                background-color: #fff;
                cursor: default;
                border: 1px solid #aaa;
            }
            .invalid {
                border: 1px solid red;
            }
        `, "",{"version":3,"sources":["webpack://./src/app/task-status-followup-engine/task-template-recurrence-schedule/task-template-recurrence-schedule.component.ts"],"names":[],"mappings":";YACY;gBACI,sBAAsB;gBACtB,eAAe;gBACf,sBAAsB;YAC1B;YACA;;gBAEI,sBAAsB;gBACtB,eAAe;gBACf,sBAAsB;YAC1B;YACA;gBACI,qBAAqB;YACzB","sourcesContent":["\n            .frequency-table tr {\n                background-color: #fff;\n                cursor: default;\n                border: 1px solid #aaa;\n            }\n            .frequency-table:hover,\n            tr:hover {\n                background-color: #fff;\n                cursor: default;\n                border: 1px solid #aaa;\n            }\n            .invalid {\n                border: 1px solid red;\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
