import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { ITaskTemplate } from '@model/interfaces/task-template';
import { ITaskTemplateAssociationsDTO } from '@model/interfaces/custom/task-template-associations-dto';
import { Observable } from 'rxjs';
import { SearchParams } from '@mt-ng2/common-classes';
import { IFilteredServiceFollowupStatusTaskDTO } from '@model/interfaces/custom/filtered-service-followup-status-task-dto';
import { IFollowupTypesAndExemptions } from '@model/interfaces/custom/followup-types-and-exemptions';
import { ITaskTemplateCommunicationMethod } from '@model/interfaces/task-template-communication-method';
import { IFilteredPhysicianStatusTaskDTO } from '@model/interfaces/custom/filtered-physician-status-task-dto';

export const emptyTaskTemplate: ITaskTemplate = {
    Archived: false,
    CreatedById: 0,
    DateCreated: new Date(),
    Description: null,
    DueInDays: 0,
    ExemptFromAutoCompletion: false,
    Id: 0,
    IsCompilationAssignedTo: false,
    IsReportAssignedTo: false,
    IsRetrievalAssignedTo: false,
    IsSchedulingAssignedTo: false,
    IsSystemGenerated: false,
    Name: '',
    Recurring: false,
    TaskTemplateAddressBookTypes: null,
    TaskTemplateRecurrenceSchedules: null,
    TaskTemplateServices: null,
    TaskTemplateImedClaimTypes: null,
    TaskTemplateUserRoleAssociations: null,
    TaskTemplateTypeId: 1,
    IsSubpoenaAssignedTo: false,
};

export const emptyTaskTemplateCommunicationMethod: ITaskTemplateCommunicationMethod = {
    CommunicationMethodId: null,
    DueDateOffset: null,
    Id: 0,
    TaskTemplateId: null,
};

@Injectable()
export class TaskTemplateService extends BaseService<ITaskTemplate> {
    copiedTask: ITaskTemplate = null;

    constructor(public http: HttpClient) {
        super('/tasktemplates', http);
    }

    getEmptyTaskTemplate(): ITaskTemplate {
        return { ...emptyTaskTemplate };
    }

    getEmptyTaskCommunicationMethod(): ITaskTemplateCommunicationMethod {
        return { ...emptyTaskTemplateCommunicationMethod };
    }

    clearCopiedTask(): void {
        this.copiedTask = null;
    }

    selectedTask: ITaskTemplate = null;

    getTaskTemplateAssociations(taskTemplateId: number): Observable<ITaskTemplateAssociationsDTO> {
        return this.http.get<ITaskTemplateAssociationsDTO>(`/tasktemplates/${taskTemplateId}/getAssociations`);
    }

    getFilteredServiceStatusFollowupTasks(serviceId: number): Observable<IFilteredServiceFollowupStatusTaskDTO> {
        return this.http.get<IFilteredServiceFollowupStatusTaskDTO>(`/tasktemplates/${serviceId}/getFilteredServiceStatusFollowupTasks`);
    }

    getPhysicianStatusTasksByAddressBookType(addressBookTypeId: number): Observable<IFilteredPhysicianStatusTaskDTO> {
        return this.http.get<IFilteredPhysicianStatusTaskDTO>(`/tasktemplates/${addressBookTypeId}/getPhysicianStatusTasksByAddressBookType`);
    }

    search(data: SearchParams): Observable<any> {
        const params = this.getHttpParams(data);
        return this.http.get<ITaskTemplate>('/tasktemplates/search', { observe: 'response', params: params });
    }

    archive(taskTemplateId: number): Observable<any> {
        return this.http.put<ITaskTemplate>(`/tasktemplates/${taskTemplateId}/archive`, {});
    }

    getFollowupTypesAndExemptions(serviceId: number): Observable<IFollowupTypesAndExemptions> {
        return this.http.get<IFollowupTypesAndExemptions>(`/tasktemplates/${serviceId}/getFollowupTypesAndExemptions`);
    }

    createUpdateTaskTemplateCommunicationMethods(
        taskTemplateId: number,
        taskTemplateCommunicationMethods: ITaskTemplateCommunicationMethod[],
    ): Observable<any> {
        return this.http.post<ITaskTemplateCommunicationMethod[]>(
            `/tasktemplates/${taskTemplateId}/communication-methods`,
            taskTemplateCommunicationMethods,
        );
    }

    getImedClaimTasks(imedClaimTypeId: number): Observable<any> {
        return this.http.get<any>(`/tasktemplates/${imedClaimTypeId}/getImedClaimTasks`);
    }
}
