import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { forkJoin} from 'rxjs';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IGroupedEmailTemplatesByFollowupDTO } from '@model/interfaces/custom/grouped-email-templates-by-followup-dto';
import { IEmailTemplate } from '@model/interfaces/email-template';
import { IMasterFollowupType } from '@model/interfaces/master-followup-type';
import { IImedClaimType } from '@model/interfaces/imed-claim-type';
import { IFollowupEmailTemplateAssociation } from '../../email-template-associations/email-template-followup-associations/followup-email-template-row/followup-email-template-row.component';
import { EmailTemplateService } from '../../../email-templates/emailtemplate.service';
import { MasterFollowupTypeService } from '../../services/master-followup-type.service';
import { ImedClaimMasterFollowupTypeEmailTemplateService } from '../services/master-followup-type-imedclaim-email-template.service';
import { IImedClaimMasterFollowupTypeEmailTemplate } from '@model/interfaces/imed-claim-master-followup-type-email-template';

@Component({
    selector: 'imedclaim-followup-email-template-associations',
    styles: [
        `
            .clear-padding {
                padding-left: 0;
            }
        `,
    ],
    templateUrl: './imedclaim-followup-email-template-associations.component.html',
})
export class ImedClaimFollowUpEmailTemplateAssociationsComponent implements OnInit, OnChanges {

    @Input() selectedImedClaimType: IImedClaimType;

    filteredEmailTemplates: IEmailTemplate[];
    filteredFollowupTypes: IMasterFollowupType[];
    filteredFollowupTypeEmailTemplates: IImedClaimMasterFollowupTypeEmailTemplate[] = [];
    emailTemplatesGroupedByFollowup: IGroupedEmailTemplatesByFollowupDTO[] = [];
    constructor(
        private emailTemplateService: EmailTemplateService,
        private followupEmailTemplateService: ImedClaimMasterFollowupTypeEmailTemplateService,
        private notificationService: NotificationsService,
        private masterFollowupTypeService: MasterFollowupTypeService,
    ) {}

    ngOnInit(): void {
        this.getFilteredEmailTemplates();
    }

    ngOnChanges(): void {
        this.getFilteredEmailTemplates();
    }

    getFilteredEmailTemplates(): void {
        this.filteredFollowupTypes = null;
        this.filteredEmailTemplates = null;
        forkJoin([
            this.masterFollowupTypeService.getMasterFollowupTypesByImedClaimTypeId(this.selectedImedClaimType.Id),
            this.followupEmailTemplateService.getAll(),
            this.followupEmailTemplateService.getEmailTemplatesGroupedByFollowup(this.selectedImedClaimType.Id),
            this.emailTemplateService.getImedClaimEmailTemplates(),
        ]).subscribe(([filteredFollowupTypes, followupEmailTemplateAssociations, groupedEmailTemplates, emailTemplates,]) => {
            this.emailTemplatesGroupedByFollowup = groupedEmailTemplates;
            this.filteredFollowupTypeEmailTemplates = followupEmailTemplateAssociations.filter((x) => x.ImedClaimTypeId === this.selectedImedClaimType.Id);
            this.filteredFollowupTypes = filteredFollowupTypes.filter(
                (x) => !this.filteredFollowupTypeEmailTemplates.some((y) => y.MasterFollowupTypeId === x.Id),
            );
            this.filteredEmailTemplates = emailTemplates;
        });
    }

    saveFollowupEmailTemplateAssociation(event: IFollowupEmailTemplateAssociation): void {
        const followupEmailTemplateAssociation: IImedClaimMasterFollowupTypeEmailTemplate = {
            EmailTemplateId: event.EmailTemplate.Id,
            Id: 0,
            MasterFollowupTypeId: event.Followup.Id,
            ImedClaimTypeId: this.selectedImedClaimType.Id,
        };

        this.followupEmailTemplateService.create(followupEmailTemplateAssociation).subscribe((id) => {
            followupEmailTemplateAssociation.Id = id;
            this.filteredFollowupTypeEmailTemplates.push(followupEmailTemplateAssociation);
            this.notificationService.success('Followup email template association saved successfully.');
            this.getFilteredEmailTemplates();
        });
    }

    addFollowupEmailTemplateAssociation(): void {
        this.emailTemplatesGroupedByFollowup.push({
            EmailTemplates: [],
            FollowupType: null,
        } as IGroupedEmailTemplatesByFollowupDTO);
    }

    deleteFollowupEmailTemplateAssociation(followupEmailTemplateAssociation: IFollowupEmailTemplateAssociation, index: number): void {
        const id = this.filteredFollowupTypeEmailTemplates.find(
            (x) =>
                x.MasterFollowupTypeId === followupEmailTemplateAssociation.Followup.Id &&
                x.EmailTemplateId === followupEmailTemplateAssociation.EmailTemplate.Id,
        ).Id;

        this.followupEmailTemplateService.delete(id).subscribe(() => {
            this.filteredFollowupTypeEmailTemplates = this.filteredFollowupTypeEmailTemplates.filter((x) => x.Id !== id);
            this.emailTemplatesGroupedByFollowup[index].EmailTemplates = this.emailTemplatesGroupedByFollowup[index].EmailTemplates.filter(
                (x) => x.Id !== followupEmailTemplateAssociation.EmailTemplate.Id,
            );
            if (!this.emailTemplatesGroupedByFollowup[index].EmailTemplates.length) {
                this.emailTemplatesGroupedByFollowup.splice(index, 1);
                this.filteredFollowupTypes.push(followupEmailTemplateAssociation.Followup);
            }
            this.notificationService.info('Followup email template association deleted successfully.');
            this.getFilteredEmailTemplates();
        });
    }
}
