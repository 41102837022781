import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IAddressBookType } from '@model/interfaces/address-book-type';

@Injectable()
export class AddressBookAssociationTypeSelectionService {
    private _addressBookType: BehaviorSubject<IAddressBookType> = new BehaviorSubject<IAddressBookType>(null);

    constructor() {}

    setAssociationType(value: IAddressBookType): void {
        this._addressBookType.next(value);
    }

    getAssociationType(): Observable<IAddressBookType> {
        return this._addressBookType.asObservable();
    }
}
