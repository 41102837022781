import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IIndividual } from '../interfaces/individual';
import { IAddressBook } from '../interfaces/address-book';
import { IUser } from '../interfaces/user';
import { IImage } from '../interfaces/image';

export interface IIndividualDynamicControlsParameters {
    formGroup?: string;
    addressBooks?: IAddressBook[];
    createdBies?: IUser[];
    modifiedBies?: IUser[];
    wetSignatureImages?: IImage[];
}

export class IndividualDynamicControls {

    formGroup: string;
    addressBooks: IAddressBook[];
    createdBies: IUser[];
    modifiedBies: IUser[];
    wetSignatureImages: IImage[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private individual?: IIndividual, additionalParameters?: IIndividualDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'Individual';
        this.addressBooks = additionalParameters && additionalParameters.addressBooks || undefined;
        this.createdBies = additionalParameters && additionalParameters.createdBies || undefined;
        this.modifiedBies = additionalParameters && additionalParameters.modifiedBies || undefined;
        this.wetSignatureImages = additionalParameters && additionalParameters.wetSignatureImages || undefined;

        this.Form = {
            AddressBookId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Address Book',
                name: 'AddressBookId',
                options: this.addressBooks,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.individual && this.individual.AddressBookId || null,
            }),
            Archived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived',
                name: 'Archived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.individual && this.individual.hasOwnProperty('Archived') && this.individual.Archived !== null ? this.individual.Archived : false,
            }),
            Attention: new DynamicField({
                formGroup: this.formGroup,
                label: 'Attention',
                name: 'Attention',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(250) ],
                validators: { 'maxlength': 250 },
                value: this.individual && this.individual.hasOwnProperty('Attention') && this.individual.Attention !== null ? this.individual.Attention.toString() : '',
            }),
            AttorneyWithoutWetSignature: new DynamicField({
                formGroup: this.formGroup,
                label: 'Attorney Without Wet Signature',
                name: 'AttorneyWithoutWetSignature',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.individual && this.individual.hasOwnProperty('AttorneyWithoutWetSignature') && this.individual.AttorneyWithoutWetSignature !== null ? this.individual.AttorneyWithoutWetSignature : false,
            }),
            ContractRateFrom: new DynamicField({
                formGroup: this.formGroup,
                label: 'Contract Rate From',
                name: 'ContractRateFrom',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.individual && this.individual.ContractRateFrom || null,
            }),
            ContractRateTo: new DynamicField({
                formGroup: this.formGroup,
                label: 'Contract Rate To',
                name: 'ContractRateTo',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.individual && this.individual.ContractRateTo || null,
            }),
            CreatedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Created By',
                name: 'CreatedById',
                options: this.createdBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.individual && this.individual.CreatedById || null,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.individual && this.individual.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.individual && this.individual.DateModified || null,
            }),
            FirstName: new DynamicField({
                formGroup: this.formGroup,
                label: 'First Name',
                name: 'FirstName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(100) ],
                validators: { 'required': true, 'maxlength': 100 },
                value: this.individual && this.individual.hasOwnProperty('FirstName') && this.individual.FirstName !== null ? this.individual.FirstName.toString() : '',
            }),
            IndividualFacilityLegacyId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Individual Facility Legacy',
                name: 'IndividualFacilityLegacyId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.individual && this.individual.hasOwnProperty('IndividualFacilityLegacyId') && this.individual.IndividualFacilityLegacyId !== null ? this.individual.IndividualFacilityLegacyId.toString() : '',
            }),
            LastName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Last Name',
                name: 'LastName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(100) ],
                validators: { 'required': true, 'maxlength': 100 },
                value: this.individual && this.individual.hasOwnProperty('LastName') && this.individual.LastName !== null ? this.individual.LastName.toString() : '',
            }),
            ModifiedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Modified By',
                name: 'ModifiedById',
                options: this.modifiedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.individual && this.individual.ModifiedById || null,
            }),
            Notes: new DynamicField({
                formGroup: this.formGroup,
                label: 'Notes',
                name: 'Notes',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.WYSIWYG,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.individual && this.individual.hasOwnProperty('Notes') && this.individual.Notes !== null ? this.individual.Notes.toString() : '',
            }),
            RequiresWetSignature: new DynamicField({
                formGroup: this.formGroup,
                label: 'Requires Wet Signature',
                name: 'RequiresWetSignature',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.individual && this.individual.hasOwnProperty('RequiresWetSignature') && this.individual.RequiresWetSignature !== null ? this.individual.RequiresWetSignature : false,
            }),
            StrivenId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Striven',
                name: 'StrivenId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.individual && this.individual.StrivenId || null,
            }),
            Suffix: new DynamicField({
                formGroup: this.formGroup,
                label: 'Suffix',
                name: 'Suffix',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(20) ],
                validators: { 'maxlength': 20 },
                value: this.individual && this.individual.hasOwnProperty('Suffix') && this.individual.Suffix !== null ? this.individual.Suffix.toString() : '',
            }),
            Title: new DynamicField({
                formGroup: this.formGroup,
                label: 'Title',
                name: 'Title',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(150) ],
                validators: { 'maxlength': 150 },
                value: this.individual && this.individual.hasOwnProperty('Title') && this.individual.Title !== null ? this.individual.Title.toString() : '',
            }),
            Wcb: new DynamicField({
                formGroup: this.formGroup,
                label: 'Wcb',
                name: 'Wcb',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(20) ],
                validators: { 'maxlength': 20 },
                value: this.individual && this.individual.hasOwnProperty('Wcb') && this.individual.Wcb !== null ? this.individual.Wcb.toString() : '',
            }),
            WetSignatureImageId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Wet Signature Image',
                name: 'WetSignatureImageId',
                options: this.wetSignatureImages,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.individual && this.individual.WetSignatureImageId || null,
            }),
        };

        this.View = {
            AddressBookId: new DynamicLabel({
			    label: 'Address Book',
			    value: getMetaItemValue(this.addressBooks as unknown as IMetaItem[], this.individual && this.individual.hasOwnProperty('AddressBookId') && this.individual.AddressBookId !== null ? this.individual.AddressBookId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Archived: new DynamicLabel({
			    label: 'Archived',
			    value: this.individual && this.individual.hasOwnProperty('Archived') && this.individual.Archived !== null ? this.individual.Archived : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Attention: new DynamicLabel({
			    label: 'Attention',
			    value: this.individual && this.individual.hasOwnProperty('Attention') && this.individual.Attention !== null ? this.individual.Attention.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            AttorneyWithoutWetSignature: new DynamicLabel({
			    label: 'Attorney Without Wet Signature',
			    value: this.individual && this.individual.hasOwnProperty('AttorneyWithoutWetSignature') && this.individual.AttorneyWithoutWetSignature !== null ? this.individual.AttorneyWithoutWetSignature : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ContractRateFrom: new DynamicLabel({
			    label: 'Contract Rate From',
			    value: this.individual && this.individual.ContractRateFrom || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: null,
			        scale: 2,
			    })
			}
            ),
            ContractRateTo: new DynamicLabel({
			    label: 'Contract Rate To',
			    value: this.individual && this.individual.ContractRateTo || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: null,
			        scale: 2,
			    })
			}
            ),
            CreatedById: new DynamicLabel({
			    label: 'Created By',
			    value: getMetaItemValue(this.createdBies as unknown as IMetaItem[], this.individual && this.individual.hasOwnProperty('CreatedById') && this.individual.CreatedById !== null ? this.individual.CreatedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            DateCreated: new DynamicLabel({
			    label: 'Date Created',
			    value: this.individual && this.individual.DateCreated || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DateModified: new DynamicLabel({
			    label: 'Date Modified',
			    value: this.individual && this.individual.DateModified || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            FirstName: new DynamicLabel({
			    label: 'First Name',
			    value: this.individual && this.individual.hasOwnProperty('FirstName') && this.individual.FirstName !== null ? this.individual.FirstName.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            IndividualFacilityLegacyId: new DynamicLabel({
			    label: 'Individual Facility Legacy',
			    value: this.individual && this.individual.hasOwnProperty('IndividualFacilityLegacyId') && this.individual.IndividualFacilityLegacyId !== null ? this.individual.IndividualFacilityLegacyId.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            LastName: new DynamicLabel({
			    label: 'Last Name',
			    value: this.individual && this.individual.hasOwnProperty('LastName') && this.individual.LastName !== null ? this.individual.LastName.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ModifiedById: new DynamicLabel({
			    label: 'Modified By',
			    value: getMetaItemValue(this.modifiedBies as unknown as IMetaItem[], this.individual && this.individual.hasOwnProperty('ModifiedById') && this.individual.ModifiedById !== null ? this.individual.ModifiedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Notes: new DynamicLabel({
			    label: 'Notes',
			    value: this.individual && this.individual.hasOwnProperty('Notes') && this.individual.Notes !== null ? this.individual.Notes.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.WYSIWYG,
			        scale: null,
			    })
			}
            ),
            RequiresWetSignature: new DynamicLabel({
			    label: 'Requires Wet Signature',
			    value: this.individual && this.individual.hasOwnProperty('RequiresWetSignature') && this.individual.RequiresWetSignature !== null ? this.individual.RequiresWetSignature : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            StrivenId: new DynamicLabel({
			    label: 'Striven',
			    value: this.individual && this.individual.StrivenId || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: NumericInputTypes.Integer,
			        scale: null,
			    })
			}
            ),
            Suffix: new DynamicLabel({
			    label: 'Suffix',
			    value: this.individual && this.individual.hasOwnProperty('Suffix') && this.individual.Suffix !== null ? this.individual.Suffix.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Title: new DynamicLabel({
			    label: 'Title',
			    value: this.individual && this.individual.hasOwnProperty('Title') && this.individual.Title !== null ? this.individual.Title.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Wcb: new DynamicLabel({
			    label: 'Wcb',
			    value: this.individual && this.individual.hasOwnProperty('Wcb') && this.individual.Wcb !== null ? this.individual.Wcb.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            WetSignatureImageId: new DynamicLabel({
			    label: 'Wet Signature Image',
			    value: getMetaItemValue(this.wetSignatureImages as unknown as IMetaItem[], this.individual && this.individual.hasOwnProperty('WetSignatureImageId') && this.individual.WetSignatureImageId !== null ? this.individual.WetSignatureImageId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };

    }
}
