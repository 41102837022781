import {
    DynamicField,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { PhysicianSpecialtyDynamicControls, IPhysicianSpecialtyDynamicControlsParameters } from '../form-controls/physician-specialty.form-controls';
import { IPhysicianSpecialty } from '../interfaces/physician-specialty';

export class PhysicianSpecialtyDynamicControlsPartial extends PhysicianSpecialtyDynamicControls {
    constructor(physicianspecialtyPartial?: IPhysicianSpecialty, additionalParameters?: IPhysicianSpecialtyDynamicControlsParameters) {
        super(physicianspecialtyPartial, additionalParameters);

        (<DynamicField>this.Form.SpecialtyId).type.inputType = SelectInputTypes.MultiselectDropdown;
        (<DynamicField>this.Form.SpecialtyId).labelHtml = '<label>Specialty</label>';
        (<DynamicField>this.Form.SpecialtyId).label = 'Specialties';
        (<DynamicField>this.Form.SpecialtyId).setRequired(false);
        (<DynamicField>this.Form.SpecialtyId).validation = [];
    }
}
