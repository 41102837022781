import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { IImedClaimType } from '@model/interfaces/imed-claim-type';
import { AssociationTypeEnums, ImedClaimTypeAssociationsToggleService } from '../services/imedclaim-type-associations-toggle.service';

@Component({
    selector: 'imedclaim-followup-association-detail',
    templateUrl: './imedclaim-type-associations-detail.component.html',
})
export class ImedClaimTypeFollowUpAssociationsDetailComponent implements OnInit, OnDestroy {
    showFollowupAssociationComponent: boolean;
    showEmailTemplateComponent: boolean;
    associationTypeSubscription: Subscription;
    private _selectedImedClaimType: IImedClaimType;

    get selectedImedClaimType(): IImedClaimType {
        return this._selectedImedClaimType;
    }
    set selectedImedClaimType(val: IImedClaimType) {
        this._selectedImedClaimType = val;
    }

    constructor(private associationToggleService: ImedClaimTypeAssociationsToggleService) { }

    ngOnInit(): void {
        this.associationTypeSubscription = this.associationToggleService.getAssociationType().subscribe((type) => {
            this.showFollowupAssociationComponent = type === AssociationTypeEnums.FOLLOWUP_TYPE;
            this.showEmailTemplateComponent = type === AssociationTypeEnums.EMAIL_ASSOCIATIONS;
        });
    }

    ngOnDestroy(): void {
        this.associationTypeSubscription.unsubscribe();
    }
}
