import { NgModule } from '@angular/core';
import { ImedManagedListComponent } from './imed-managed-list.component';
import { Routes, RouterModule } from '@angular/router';
import { ClaimTypes } from '@model/ClaimTypes';
import { ClaimValues, AuthGuard } from '@mt-ng2/auth-module';

const managedListRoleGuard: any = {
    claimType: ClaimTypes.ManagedList,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Managed List',
};

const managedListRoutes: Routes = [
    { path: 'managed-list', component: ImedManagedListComponent, canActivate: [AuthGuard], data: managedListRoleGuard },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(managedListRoutes)],
})
export class ImedManagedListRoutingModule { }
