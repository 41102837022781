import { IRecordReviewService } from '@model/interfaces/record-review-service.d';
import { AuthService, ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IImedClaimServiceContactInfo } from './../../../../imed-claim-basic-info/imed-claim-contacts-info';
import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, AbstractControl, Validators, FormGroup } from '@angular/forms';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { ImedClaimServiceService } from '../../../imedclaimservice.service';
import { IImedClaimService } from '@model/interfaces/imed-claim-service';
import { RecordReviewServiceDynamicConfig } from '../record-review-service.dynamic-config';
import { RecordReviewServiceService } from '../record-review-service.service';
import { IServicePayload } from '@model/interfaces/custom/update-service-payload';
import { ConflictOfInterestFormSelection } from '../../../../../common/constants/Enums';
import { Subscription, Observable } from 'rxjs';
import {
    DynamicField,
    DynamicFieldType,
    IDynamicFieldType,
    DynamicFieldTypes,
    IDynamicField,
    DynamicLabel,
    IDynamicFormConfig,
} from '@mt-ng2/dynamic-form';

import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ClaimTypes } from '@model/ClaimTypes';
import { MetaItem } from '@mt-ng2/base-service';
import { VirtualTypeAheadGetItemsFunction } from '@mt-ng2/type-ahead-control';
import { ISpecialty } from '@model/interfaces/specialty';
import { SpecialityService } from '../../../../../imed-managed-list/speciality.service';
import { PopUpService, IHasLegalFoundationPopUp } from '../../../../../common/services/popup-service';

@Component({
    selector: 'app-record-review-service-basic-info',
    templateUrl: './record-review-service-basic-info.component.html',
})
export class RecordReviewServiceBasicInfoComponent implements OnInit, OnDestroy, IHasLegalFoundationPopUp {
    @Input() iImedClaimServiceModel: IImedClaimService;
    @Input() recordReviewServiceModel: IRecordReviewService;
    @Input() canEdit: boolean;
    @Input() iImedClaimServiceContactInfo: IImedClaimServiceContactInfo;
    @Input() bodyPartsToBeExamined: string;
    @Input() reportDueBy: string;
    @Input() disablePhysicianFacilityControl: boolean;
    @Output('physicianFaxSent') physicianFaxSent: EventEmitter<any> = new EventEmitter<any>();

    isEditing: boolean;
    isHovered: boolean;
    formRendered = false;

    config: IDynamicFormConfig;
    recordReviewServiceForm: FormGroup;
    recordReviewformFactory: RecordReviewServiceDynamicConfig<IRecordReviewService>;

    doubleClickIsDisabled = false;
    ImedClaimServiceId: number;
    ServiceId: number;
    subscriptions = new Subscription();
    physicianSelected: boolean;
    selectedValueConflictOfInterest: number;
    previousPatientControl: AbstractControl;
    conflictOfInterestControl: AbstractControl;
    canSeeAuditLog = false;
    invalidPreviousPatient = false;

    getItems: VirtualTypeAheadGetItemsFunction = this.getSpecialties.bind(this);
    selectedSpecialty: number;
    initialImedClaimServiceContactInfo: IImedClaimServiceContactInfo;

    // Properties for IHasLegalFoundationPopUp
    popUpValidationFunction: () => void;
    hasPreLitigationCheckboxChecked: boolean;
    hasPreLitigationDocumentUploaded: boolean;
    isHardPopUp = false;
    isFederalCase: boolean;

    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    constructor(
        private imedClaimServiceService: ImedClaimServiceService,
        private notificationsService: NotificationsService,
        private fb: UntypedFormBuilder,
        private authService: AuthService,
        private recordReviewService: RecordReviewServiceService,
        private claimsService: ClaimsService,
        private specialtyService: SpecialityService,
        private popUpService: PopUpService,
    ) {}

    ngOnInit(): void {
        this.config = { formObject: [], viewOnly: [] };
        this.canSeeAuditLog = this.claimsService.hasClaim(ClaimTypes.Audit, [ClaimValues.FullAccess, ClaimValues.ReadOnly]);

        this.isEditing = false;
        this.selectedValueConflictOfInterest = this.recordReviewServiceModel.ConflictOfInterestApproved
            ? ConflictOfInterestFormSelection.selected
            : ConflictOfInterestFormSelection.notSelected;
        this.physicianSelected = this.iImedClaimServiceModel.PhysicianId > 0 ? true : false;
        this.subscriptions.add(
            this.imedClaimServiceService.changeEmitted$.subscribe((iImedClaimServiceModel) => {
                this.iImedClaimServiceModel = iImedClaimServiceModel;
                this.setConfig();
                this.popUpService.setPopupConfig(this);
            }),
        );

        this.setConfig();
        this.popUpService.setPopupConfig(this);

        this.selectedSpecialty = this.iImedClaimServiceModel.SpecialtyRequestedId > 0 ? this.iImedClaimServiceModel.SpecialtyRequestedId : null;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    ngOnChanges(changes: any): void {
        if (changes.iImedClaimServiceContactInfo) {
            this.initialImedClaimServiceContactInfo = { ...this.iImedClaimServiceContactInfo };
        }
    }

    getSpecialties(): Observable<ISpecialty[]> {
        return this.specialtyService.getItems();
    }

    selectionChanged({ selection }): void {
        if (selection) {
            this.selectedSpecialty = selection.Id;
        }
    }

    setConfig(): void {
        this.recordReviewServiceForm = this.fb.group({});
        this.recordReviewformFactory = new RecordReviewServiceDynamicConfig<IRecordReviewService>(this.recordReviewServiceModel, [
            'RequestDate',
            'HasPreLitigation',
            'IsFederalCase',
        ]);
        this.config = this.recordReviewformFactory.getForUpdate();
        this.formRendered = true;

        this.viewOnly = this.config?.viewOnly?.map((x) => new DynamicLabel(x));
        this.formObject = this.config.formObject?.map((x) => new DynamicField(x));
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        this.isEditing = false;
        this.iImedClaimServiceContactInfo = { ...this.initialImedClaimServiceContactInfo };
    }

    formCreated(formGroup: UntypedFormGroup): void {
        formGroup.get('RecordReviewService.HasPreLitigation').valueChanges.subscribe((value) => {
            this.hasPreLitigationCheckboxChecked = value;
        });

        formGroup.get('RecordReviewService.IsFederalCase').valueChanges.subscribe((value) => {
            this.isFederalCase = value;
        });
    }

    isPhysicianSelected(physicianSelected): void {
        if (physicianSelected && this.iImedClaimServiceModel.PhysicianId == null) {
            this.physicianSelected = true;
        } else if (physicianSelected && this.iImedClaimServiceModel.PhysicianId > 0) {
            this.physicianSelected = true;
            if (this.conflictOfInterestControl) {
                this.conflictOfInterestControl.setValue(ConflictOfInterestFormSelection.notSelected);
            }
        } else if (!physicianSelected) {
            this.physicianSelected = false;
        }
    }

    previousPatientField(): DynamicField {
        return new DynamicField({
            formGroup: null,
            label: 'Has this claimant ever been seen in this physician`s or expert`s office, for any reason - treating, Worker`s Compensation, No Fault, etc.?',
            name: 'PreviousPatient',
            options: [new MetaItem(1, 'Yes'), new MetaItem(0, 'No')],
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
            } as IDynamicFieldType),
            validation: [(c: AbstractControl) => Validators.required(c)],
            validators: { required: true },
            value:
                this.recordReviewServiceModel.PatientPreviouslyTreatedByPhysician === true
                    ? 1
                    : this.recordReviewServiceModel.PatientPreviouslyTreatedByPhysician === false
                    ? 0
                    : null,
        } as IDynamicField);
    }

    setPreviousPatientField(value: boolean): void {
        this.recordReviewServiceModel.PatientPreviouslyTreatedByPhysician = value;
    }

    conflictOfInterestField(): DynamicField {
        return new DynamicField({
            formGroup: null,
            label: 'Has client approved possible conflict of interest?',
            name: 'PreviousPatient',
            options: [new MetaItem(1, 'Yes'), new MetaItem(0, 'No')],
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
            } as IDynamicFieldType),
            value: this.selectedValueConflictOfInterest,
        } as IDynamicField);
    }

    setConflictOfInterestField(value: boolean): void {
        this.selectedValueConflictOfInterest = value ? ConflictOfInterestFormSelection.selected : ConflictOfInterestFormSelection.notSelected;
        this.recordReviewServiceModel.ConflictOfInterestApproved = value;
    }

    formSubmitted(): void {
        const invalidServiceAddress = this.iImedClaimServiceContactInfo.PhysicianId > 0 && !this.iImedClaimServiceContactInfo.AddressId;
        this.invalidPreviousPatient = this.recordReviewServiceModel.PatientPreviouslyTreatedByPhysician === null && this.physicianSelected;

        if (this.invalidPreviousPatient || invalidServiceAddress) {
            return;
        }

        if (this.recordReviewServiceForm.valid && !invalidServiceAddress) {
            this.iImedClaimServiceModel = this.imedClaimServiceService.assignContactsToImedClaimService(
                this.iImedClaimServiceModel,
                this.iImedClaimServiceContactInfo,
            );
            this.iImedClaimServiceModel.SpecialtyRequestedId = this.selectedSpecialty ? this.selectedSpecialty : null;
            this.recordReviewformFactory.assignFormValues(
                this.recordReviewServiceModel,
                this.recordReviewServiceForm.value.RecordReviewService as IRecordReviewService,
            );
            this.recordReviewServiceModel.RequestDateChangedById = this.authService.currentUser.getValue().Id;
            this.recordReviewServiceModel.RequestDateChangedDate = new Date();
            this.recordReviewServiceModel.ModifiedById = this.authService.currentUser.getValue().Id;

            const updatePayload = <IServicePayload<IRecordReviewService>>{};
            updatePayload.ChildService = this.recordReviewServiceModel;
            updatePayload.ParentService = this.iImedClaimServiceModel;
            forkJoin(
                this.recordReviewService.updateServiceConflictOfInterest(this.iImedClaimServiceModel.Id, updatePayload) as Observable<unknown>,
                this.recordReviewService.updateService(updatePayload) as Observable<unknown>,
            ).subscribe(() => {
                forkJoin(
                    this.imedClaimServiceService.getById(this.iImedClaimServiceModel.Id),
                    this.recordReviewService.getByImedClaimServiceId(this.iImedClaimServiceModel.Id),
                ).subscribe((response) => {
                    const [imedClaimServiceResponse, recordReviewServiceResponse] = response;
                    this.recordReviewServiceModel = recordReviewServiceResponse;
                    this.iImedClaimServiceModel = imedClaimServiceResponse;
                    this.imedClaimServiceService.emitChange(this.iImedClaimServiceModel);
                    this.recordReviewService.emitChange(this.recordReviewServiceModel);
                    this.isEditing = false;
                    this.success();
                    this.setConfig();
                });
            });
        } else {
            markAllFormFieldsAsTouched(this.recordReviewServiceForm);
            this.error();
        }
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }
}
