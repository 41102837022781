import { IBillDeskReviewService } from '@model/interfaces/bill-desk-review-service.d';
import { AuthService, ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IImedClaimServiceContactInfo } from './../../../../imed-claim-basic-info/imed-claim-contacts-info';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { ImedClaimServiceService } from '../../../imedclaimservice.service';
import { IImedClaimService } from '@model/interfaces/imed-claim-service';
import { BillDeskReviewServiceDynamicConfig } from '../bill-desk-review-service.dynamic-config';
import { BillDeskReviewServiceService } from '../bill-desk-review-service.service';
import { IServicePayload } from '@model/interfaces/custom/update-service-payload';
import { Subscription, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ClaimTypes } from '@model/ClaimTypes';
import { IHasLegalFoundationPopUp, PopUpService } from '../../../../../common/services/popup-service';
import { DynamicField, DynamicLabel, IDynamicField, IDynamicFormConfig, IDynamicLabel } from '@mt-ng2/dynamic-form';

@Component({
    selector: 'app-bill-desk-review-service-basic-info',
    templateUrl: './bill-desk-review-service-basic-info.component.html',
})
export class BillDeskReviewServiceBasicInfoComponent implements OnInit, IHasLegalFoundationPopUp {
    @Input() iImedClaimServiceModel: IImedClaimService;
    @Input() billDeskReviewServiceModel: IBillDeskReviewService;
    @Input() canEdit: boolean;
    @Input() iImedClaimServiceContactInfo: IImedClaimServiceContactInfo;
    @Input() bodyPartsToBeExamined: string;
    @Input() reportDueBy: string;
    @Output('physicianFaxSent') physicianFaxSent: EventEmitter<any> = new EventEmitter<any>();
    @Input() disablePhysicianFacilityControl: boolean;
    isEditing: boolean;
    isHovered: boolean;

    config: IDynamicFormConfig;
    billDeskReviewServiceForm: UntypedFormGroup;
    billDeskReviewformFactory: BillDeskReviewServiceDynamicConfig<IBillDeskReviewService>;

    doubleClickIsDisabled = false;
    ServiceId: number;
    formRendered = false;
    subscriptions = new Subscription();
    canSeeAuditLog = false;
    highValueServiceChecked: boolean;
    rushServiceChecked: boolean;
    initialImedClaimServiceContactInfo: IImedClaimServiceContactInfo;

    // Properties for IHasLegalFoundationPopUp
    popUpValidationFunction: () => void;
    hasPreLitigationCheckboxChecked: boolean;
    hasPreLitigationDocumentUploaded: boolean;
    isHardPopUp = false;
    isFederalCase: boolean;

    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    constructor(
        private imedClaimServiceService: ImedClaimServiceService,
        private notificationsService: NotificationsService,
        private activatedRoute: ActivatedRoute,
        private fb: UntypedFormBuilder,
        private authService: AuthService,
        private billDeskReviewService: BillDeskReviewServiceService,
        private claimsService: ClaimsService,
        private popUpService: PopUpService,
    ) {}

    ngOnInit(): void {
        this.config = { formObject: [], viewOnly: [] };
        this.isEditing = false;
        this.canSeeAuditLog = this.claimsService.hasClaim(ClaimTypes.Audit, [ClaimValues.FullAccess, ClaimValues.ReadOnly]);

        this.subscriptions.add(
            this.imedClaimServiceService.changeEmitted$.subscribe((iImedClaimServiceModel) => {
                this.iImedClaimServiceModel = iImedClaimServiceModel;
                this.popUpService.setPopupConfig(this);
                this.setConfig();
            }),
        );
        this.popUpService.setPopupConfig(this);
        this.setConfig();
    }

    ngOnChanges(changes: any): void {
        if (changes.iImedClaimServiceContactInfo) {
            this.initialImedClaimServiceContactInfo = { ...this.iImedClaimServiceContactInfo };
        }
    }

    setConfig(): void {
        this.billDeskReviewServiceForm = this.fb.group({});
        this.billDeskReviewformFactory = new BillDeskReviewServiceDynamicConfig<IBillDeskReviewService>(this.billDeskReviewServiceModel, [
            'RequestDate',
            'HasPreLitigation',
            'IsFederalCase',
        ]);

        this.config = this.billDeskReviewformFactory.getForUpdate();
        this.formRendered = true;

        this.viewOnly = this.config?.viewOnly?.map((x: IDynamicLabel) => new DynamicLabel(x));
        this.formObject = this.config.formObject?.map((x: IDynamicField) => new DynamicField(x));
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    formCreated(formGroup: UntypedFormGroup): void {
        formGroup.get('BillDeskReviewService.HasPreLitigation').valueChanges.subscribe((value) => {
            this.hasPreLitigationCheckboxChecked = value;
        });

        formGroup.get('BillDeskReviewService.IsFederalCase').valueChanges.subscribe((value) => {
            this.isFederalCase = value;
        });
    }

    cancelClick(): void {
        this.isEditing = false;
        this.iImedClaimServiceContactInfo = { ...this.initialImedClaimServiceContactInfo };
    }

    formSubmitted(): void {
        const invalidServiceAddress = this.iImedClaimServiceContactInfo.PhysicianId > 0 && !this.iImedClaimServiceContactInfo.AddressId;

        if (this.billDeskReviewServiceForm.valid && !invalidServiceAddress) {
            this.iImedClaimServiceModel = this.imedClaimServiceService.assignContactsToImedClaimService(
                this.iImedClaimServiceModel,
                this.iImedClaimServiceContactInfo,
            );
            this.billDeskReviewformFactory.assignFormValues(
                this.billDeskReviewServiceModel,
                this.billDeskReviewServiceForm.value.BillDeskReviewService as IBillDeskReviewService,
            );
            this.billDeskReviewServiceModel.RequestDateChangedById = this.authService.currentUser.getValue().Id;
            this.billDeskReviewServiceModel.RequestDateChangedDate = new Date();

            const updatePayload = <IServicePayload<IBillDeskReviewService>>{};
            updatePayload.ChildService = this.billDeskReviewServiceModel;
            updatePayload.ParentService = this.iImedClaimServiceModel;
            this.billDeskReviewService.updateService(updatePayload).subscribe(() => {
                this.isEditing = false;
                this.success();
                forkJoin(
                    this.imedClaimServiceService.getById(this.iImedClaimServiceModel.Id),
                    this.billDeskReviewService.getByImedClaimServiceId(this.iImedClaimServiceModel.Id),
                ).subscribe((response) => {
                    const [imedClaimServiceResponse, billDeskReviewServiceResponse] = response;
                    this.billDeskReviewServiceModel = billDeskReviewServiceResponse;
                    this.iImedClaimServiceModel = imedClaimServiceResponse;
                    this.imedClaimServiceService.emitChange(this.iImedClaimServiceModel);
                    this.billDeskReviewService.emitChange(this.billDeskReviewServiceModel);
                    this.setConfig();
                });
            });
        } else {
            markAllFormFieldsAsTouched(this.billDeskReviewServiceForm);
            this.error();
        }
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }
}
