import { HttpClient } from '@angular/common/http';
import { AppError, NotFoundError } from '@mt-ng2/errors-module';
import { Injectable } from '@angular/core';
import { throwError, Observable, OperatorFunction } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export abstract class APIService {
    constructor(private baseurl: string, protected http: HttpClient) {}

    post(object: any, url: string): Observable<any> {
        const clone: any = JSON.parse(JSON.stringify(object));
        return this.http.post<any>(this.baseurl + '/' + url, clone).pipe(catchError((e: Response) => this.handleError(e)) as OperatorFunction<unknown, any>);
    }

    handleError(error: Response, formObject?: any): Observable<any> {
        if (error.status === 400) {
            return throwError(error);
        }

        if (error.status === 404) {
            return throwError(new NotFoundError());
        }

        return throwError(new AppError(error, formObject));
    }
}

