import { Component } from '@angular/core';

import { IEntityListDynamicCellComponent, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { IEntity } from '@mt-ng2/base-service';
import { IModalOptions } from '@mt-ng2/modal-module';

import { ImedClaimServiceService } from '../imedclaimservice.service';
import { IViewGetServicesForList } from '@model/interfaces/custom/view-get-services-for-list';

@Component({
    template: `
        <div *ngIf="imedClaimService && canDelete">
            <div *ngIf="imedClaimService.Archived" class="text-center">
                <i (mtConfirm)="archiveService($event)" class="fa fa-trash fa-lg" aria-hidden="true" [mtConfirmOptions]="unarchiveConfirm"></i
                ><i class="fa fa-lg fa-undo overlay-fa"></i>
            </div>
            <div *ngIf="!imedClaimService.Archived" class="text-center">
                <i (mtConfirm)="archiveService($event)" class="fa fa-trash fa-lg" aria-hidden="true" [mtConfirmOptions]="archiveConfirm"></i>
            </div>
        </div>
    `,
})
export class ServiceDeleteDynamicCellComponent implements IEntityListDynamicCellComponent {
    entityListComponentMembers: IEntityListComponentMembers;
    set entity(value: IEntity) {
        this.imedClaimService = value as IViewGetServicesForList;
    }

    imedClaimService: IViewGetServicesForList;
    canDelete = false;

    archiveConfirm: IModalOptions = {
        confirmButtonText: 'Yes, I am sure!',
        text: `Are you sure you want to delete this service?`,
        title: 'Delete Service',
    	icon: 'question',
};

    unarchiveConfirm: IModalOptions = {
        confirmButtonText: 'Yes, I am sure!',
        text: `Are you sure you want to restore this service?`,
        title: 'Restore Service',
    	icon: 'question',
};

    constructor(private imedClaimServiceService: ImedClaimServiceService) { }

    ngOnInit(): void {
        this.canDelete = this.imedClaimServiceService.canDelete.getValue();
    }

    archiveService(event: Event): void {
        this.entityListComponentMembers.itemDeleted(this.imedClaimService as IEntity, event);
    }
}
