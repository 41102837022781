import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { IDynamicField } from '@mt-ng2/dynamic-form';
import { PhoneTypeService } from '../phonetype.service';
import { PhoneNumberDynamicConfig, emptyPhoneNumber } from '../../common/shared-dynamic-configs/phone-number.dynamic-config';
import { IPhoneType } from '@model/interfaces/phone-type';
import { safeDetectChanges } from '../../common/cdr-safety/cdr-safety.library';
import { PhoneTypes } from '../../common/constants/Enums';

@Component({
    selector: 'app-imed-claim-primary-phone',
    templateUrl: './primary-phone.component.html',
})
export class IMedClaimPrimaryPhoneComponent implements OnInit {
    @Input() form: UntypedFormGroup;
    @Input() overrideFormGroup = 'PhoneNumber';

    private _required = true;
    @Input('required')
    get required(): boolean {
        return this._required;
    }
    set required(value: boolean) {
        this._required = value ? value : false;
    }

    phoneConfig: IDynamicField[];

    constructor(private phoneTypeService: PhoneTypeService, private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        forkJoin(this.phoneTypeService.getItems()).subscribe((forkJoinResponses) => {
            const [phoneTypes] = forkJoinResponses;
            this.setPhoneConfig(phoneTypes.filter((x) => x.Id !== +PhoneTypes.Doctor && x.Id !== +PhoneTypes.Alternate));
            safeDetectChanges(this.cdr);
        });
    }

    ngOnDestroy(): void {
        this.cdr.detach();
    }

    setPhoneConfig(phoneTypes: IPhoneType[]): void {
        const phoneControls = ['PhoneTypeId', 'Phone', 'Notes'];
        phoneTypes.find((x) => x.Id === +PhoneTypes.Office_fax).Name = 'Fax';
        const phoneNumberConfig = new PhoneNumberDynamicConfig(emptyPhoneNumber, phoneTypes, phoneControls);
        const formObject = phoneNumberConfig.getForCreate().formObject;
        formObject.forEach((field) => {
            if (field.name !== 'Notes') {
                (field ).setRequired(this.required);
            }
            (field ).formGroup = this.overrideFormGroup;
        });
        this.phoneConfig = formObject;
    }
}
