import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { IImedClaimAdditionalBillToPartyDTO } from '@model/interfaces/custom/imed-claim-additional-btp.dto';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { finalize } from 'rxjs/operators';
import { ImedClaimCourtFeesService } from './imed-claim-court-fees.service';
import { IImedClaimCourtFee } from '@model/interfaces/imed-claim-court-fee';
import { UntypedFormGroup } from '@angular/forms';
import { ImedClaimCourtFeeDynamicControls } from '@model/form-controls/imed-claim-court-fee.form-controls';
import { ImedClaimCourtFeesDynamicConfig } from './imed-claim-court-fees-dynamic-config';
import { AuthService } from '@mt-ng2/auth-module';
import { IImedClaimSubpoenaDetail } from '@model/interfaces/imed-claim-subpoena-detail';
import { IImedClaim } from '@model/interfaces/imed-claim';
import { ImedClaimService } from '../imedclaim.service';

@Component({
    selector: 'app-imed-claim-court-fees',
    styles: ['.custom-padded { margin: 0 0 0.5em 0; } ', '.highlight {background-color: #FF45B5;}'],
    templateUrl: './imed-claim-court-fees.component.html',
})
export class ImedClaimCourtFeesComponent implements OnInit, OnDestroy {
    @Input() imedClaimId: number;
    @Input() canEdit: boolean;
    @Input() imedClaim: IImedClaim;
    isHovered = false;
    doubleClickIsDisabled = false;
    courtFeesForm = new UntypedFormGroup({ ImedClaimCourtFee: new UntypedFormGroup({}) });
    formFactory: any;
    controls: any;

    courtFees: IImedClaimCourtFee[] = [];
    newCourtFees: IImedClaimCourtFee;
    _imedClaimCourtFeesService: ImedClaimCourtFeesService;
    selectedCourtFee: IImedClaimCourtFee;

    _isEditing: boolean;
    public set isEditing(v: boolean) {
        this._isEditing = v;
    }

    public get isEditing(): boolean {
        return this._isEditing;
    }

    constructor(
        private imedClaimCourtFeesService: ImedClaimCourtFeesService,
        private imedClaimService: ImedClaimService,
        private notificationsService: NotificationsService,
        private authService: AuthService,
    ) {
        this._imedClaimCourtFeesService = imedClaimCourtFeesService;
    }

    ngOnInit(): void {
        this.isEditing = false;
        this.getImedClaimCourtFees();
        this.setConfig();
    }

    setConfig(): void {
        this.newCourtFees = this.imedClaimCourtFeesService.getEmptyImedClaimCourtFee();
        this.controls = new ImedClaimCourtFeeDynamicControls(this.newCourtFees, null).Form;
        this.formFactory = new ImedClaimCourtFeesDynamicConfig<IImedClaimCourtFee>(this.newCourtFees, []);
    }

    ngOnDestroy(): void {
        this.selectedCourtFee = null;
    }

    add(): void {
        if (this.canEdit) {
            this.isEditing = true;
            this.selectedCourtFee = null;
            this.setConfig();
        }
    }

    getImedClaimCourtFees(): void {
        this.imedClaimCourtFeesService.getCourtFees(this.imedClaimId).subscribe((courtFees: IImedClaimCourtFee[]) => {
            this.courtFees = courtFees;
        });
    }

    cancel(): void {
        this.isEditing = false;
        this.selectedCourtFee = null;
    }

    editCourtFees(courtFee: IImedClaimCourtFee): void {
        if (!this.canEdit) {
            return;
        }
        this.isEditing = true;
        this.selectedCourtFee = { ...courtFee };
        this.controls = new ImedClaimCourtFeeDynamicControls(this.selectedCourtFee, null).Form;
    }

    onPhysicalCheckUpdate(): void {
        this.imedClaimService
            .togglePhysicalCheckRequired(this.imedClaim)
            .pipe()
            .subscribe(() => {
                this.notificationsService.success('updated successfully');
            });
    }

    save(): void {
        const form = this.courtFeesForm;
        if (this.selectedCourtFee && this.selectedCourtFee.Id > 0) {
            this.formFactory.assignFormValues(this.selectedCourtFee, form.value.ImedClaimCourtFee);
            this.selectedCourtFee.DateModified = new Date();
            this.selectedCourtFee.ModifiedById = this.authService.currentUser.getValue().Id;
            this._imedClaimCourtFeesService.editCourtFee(this.imedClaimId, this.selectedCourtFee).subscribe(() => {
                this.notificationsService.success('Court Fees edited successfully');
                this.isEditing = false;
                this.selectedCourtFee = null;
                this.getImedClaimCourtFees();
            });
        } else {
            this.formFactory.assignFormValues(this.newCourtFees, form.value.ImedClaimCourtFee);
            this.newCourtFees.ImedClaimId = this.imedClaimId;
            this.newCourtFees.DateCreated = new Date();
            this.newCourtFees.CreatedById = this.authService.currentUser.getValue().Id;
            this._imedClaimCourtFeesService.addCourtFee(this.imedClaimId, this.newCourtFees).subscribe(() => {
                this.notificationsService.success('Court Fees added successfully');
                this.isEditing = false;
                this.getImedClaimCourtFees();
            });
        }
    }

    remove(item: IImedClaimAdditionalBillToPartyDTO, event: Event): void {
        if (event && event.stopPropagation) {
            event.stopPropagation();
        }

        this.imedClaimCourtFeesService.delete(item.Id).subscribe(() => {
            this.getImedClaimCourtFees();
            this.notificationsService.success('Court Fees deleted successfully');
        });
    }
}
