import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
    providedIn: 'root',
})
export class AdditionalServicesModalService {
    constructor(private ngbModal: NgbModal) {}

    showModal(modal: any): void {
        if (this.ngbModal.hasOpenModals()) {
            this.ngbModal.dismissAll();
        }
        const options: NgbModalOptions = {};
        options.size = 'lg';

        this.ngbModal.open(modal, options);
    }

    hideModal(): void {
        if (this.ngbModal.hasOpenModals()) {
            this.ngbModal.dismissAll();
        }
    }
}
