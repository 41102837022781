import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MetaItemService } from '@mt-ng2/base-service';
import { IImedClaimSubpoenaDetail } from '@model/interfaces/imed-claim-subpoena-detail';
import { ICourtAddressBookDTO } from '@model/interfaces/custom/court-address-book-dto';
import { Observable, OperatorFunction } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IImedClaimSubpoenaCoDefenseCounselAssociation } from '@model/interfaces/imed-claim-subpoena-co-defense-counsel-association';
import { IImedClaimSubpoenaDefenseCounselAssociation } from '@model/interfaces/imed-claim-subpoena-defense-counsel-association';

export const emptySubpoenaDetails: IImedClaimSubpoenaDetail = {
    Address: {
        Address1: '',
        Address2: '',
        Archived: false,
        City: '',
        CreatedById: 0,
        DateCreated: new Date(),
        Id: 0,
        StateId: 0,
        Zip: '',
    },
    BranchName: null,
    ClerkName: null,
    ClientApprovalDate: null,
    DefenseAttorneyIdBarNumber: null,
    Division: null,
    DocumentReason: null,
    GoodCause: null,
    HearingAddressId: 0,
    HearingDateTime: null,
    Id: 0,
    ImedClaimId: 0,
    ImedClaimSubpoenaCoDefenseCounselAssociations: null,
    IndexDocketCaseNumber: null,
    JusticeJudgeName: null,
    Other1: null,
    Other2: null,
    Other3: null,
    PartOrDepartment: null,
    PlaintiffAttorneyRequiresRecords: false,
    PlaintiffLodgedObjectionDate: null,
    ResidingCourtAddressBookId: 0,
    Room: null,
    Title: null,
};

@Injectable({
    providedIn: 'root',
})
export class ImedClaimSubpoenaDetailService extends MetaItemService<any> {
    getEmptyImedClaimSubpoenaDetail(): IImedClaimSubpoenaDetail {
        return { ...emptySubpoenaDetails };
    }
    constructor(public http: HttpClient) {
        super('ImedClaimSubpoenaDetailService', 'Detail', 'DetailIds', '/subpoena-details', http);
    }

    getCourtAddressBook(): Observable<ICourtAddressBookDTO[]> {
        return this.http.get<ICourtAddressBookDTO[]>(`/subpoena-details/get-court-addressbook`);
    }

    deleteCoDefenseCounsel(id: number): Observable<any> {
        return this.http
            .delete(`/subpoena-details/${id}/co-defense-counsels`)
            .pipe(catchError((e: Response) => this.handleError(e)) as OperatorFunction<unknown, any>);
    }

    deleteDefenseCounsel(id: number): Observable<any> {
        return this.http
            .delete(`/subpoena-details/${id}/defense-counsels`)
            .pipe(catchError((e: Response) => this.handleError(e)) as OperatorFunction<unknown, any>);
    }

    getCoDefenseCounsel(subpoenaDetailId: number): Observable<IImedClaimSubpoenaCoDefenseCounselAssociation[]> {
        return this.http.get<IImedClaimSubpoenaCoDefenseCounselAssociation[]>(`/subpoena-details/${subpoenaDetailId}/co-defense-counsels`);
    }

    getDefenseCounsel(subpoenaDetailId: number): Observable<IImedClaimSubpoenaDefenseCounselAssociation[]> {
        return this.http.get<IImedClaimSubpoenaDefenseCounselAssociation[]>(`/subpoena-details/${subpoenaDetailId}/defense-counsels`);
    }

    createPlaintiffLodgedFollowups( subpoenaDetails: IImedClaimSubpoenaDetail, selectedPlaintiffLodgedImedClaimServiceIds: number[]): Observable<any> {
        return this.http.post(`/subpoena-details/create-plaintiff-lodged-followups`, {
            ImedClaimSubpoenaDetail: subpoenaDetails,
            ImedClaimServiceIds: selectedPlaintiffLodgedImedClaimServiceIds,
        });
    }
}
