import { AddressFormatterService } from './../../../common/services/address-formatter.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { IAddress } from '@model/interfaces/address';
import { AddressService } from '../address.service';

@Component({
    selector: 'app-address-header',
    templateUrl: './address-header.component.html',
})
export class AddressHeaderComponent implements OnInit, OnDestroy {
    address: IAddress;
    header: string;
    subscriptions: Subscription = new Subscription();
    constructor(private addressService: AddressService, private route: ActivatedRoute, private addressFormatterService: AddressFormatterService) { }

    ngOnInit(): void {
        this.subscriptions.add(
            this.addressService.changeEmitted$.subscribe((address) => {
                this.setHeader(address);
            }),
        );
        const id = +this.route.snapshot.paramMap.get('addressId');
        if (id > 0) {
            this.addressService.getById(id).subscribe((address) => {
                this.setHeader(address);
            });
        } else {
            this.header = 'Add Address';
            this.address = this.addressService.getEmptyAddress();
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    setHeader(address: IAddress): void {
        this.address = address;
        this.header = `Address: ${this.addressFormatterService.formatAddress(address)}`;
    }
}
