// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            .physician-font {
                font-size: 16px;
                font-weight: bold;
            }
            .form-control.invalid {
                border: 1px solid #dd4b39;
            }
            label.invalid {
                color: #dd4b39;
            }
        `, "",{"version":3,"sources":["webpack://./src/app/imed-claims/services/common-components/physician-info/imed-claim-service-physician-info.component.ts"],"names":[],"mappings":";YACY;gBACI,eAAe;gBACf,iBAAiB;YACrB;YACA;gBACI,yBAAyB;YAC7B;YACA;gBACI,cAAc;YAClB","sourcesContent":["\n            .physician-font {\n                font-size: 16px;\n                font-weight: bold;\n            }\n            .form-control.invalid {\n                border: 1px solid #dd4b39;\n            }\n            label.invalid {\n                color: #dd4b39;\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
