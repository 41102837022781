import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import {
    PhysicianSpecialtyDetailDynamicControls,
    IPhysicianSpecialtyDetailDynamicControlsParameters,
} from '../form-controls/physician-specialty-detail.form-controls';
import { IPhysicianSpecialtyDetail } from '../interfaces/physician-specialty-detail';

export class PhysicianSpecialtyDetailDynamicControlsPartial extends PhysicianSpecialtyDetailDynamicControls {
    constructor(
        physicianspecialtydetailPartial?: IPhysicianSpecialtyDetail,
        additionalParameters?: IPhysicianSpecialtyDetailDynamicControlsParameters,
    ) {
        super(physicianspecialtydetailPartial, additionalParameters);

        (<DynamicField>this.Form.SpecialtyDetailId).type.inputType = SelectInputTypes.MultiselectDropdown;
        (<DynamicField>this.Form.SpecialtyDetailId).setRequired(false);
        (<DynamicField>this.Form.SpecialtyDetailId).validation = [];
    }
}
