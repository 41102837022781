import { IDocumentQueueAssignmentDTO } from '@model/interfaces/custom/document-queue-assignment';
import { IDocumentQueueAttachmentPartial } from '@model/partials/document-queue-attachment-partial';

export default class DocumentQueueAssignmentDTO implements IDocumentQueueAssignmentDTO {
    documentQueueAttachments: IDocumentQueueAttachmentPartial[];
    documentTypeId?: number;
    assignmentId: number;
    userId?: number;
    noOfPages: number;
    constructor(assignmentDTO: IDocumentQueueAssignmentDTO) {
        this.documentQueueAttachments = assignmentDTO.documentQueueAttachments;
        this.documentTypeId = assignmentDTO.documentTypeId;
        this.assignmentId = assignmentDTO.assignmentId;
        this.userId = assignmentDTO.userId;
        this.noOfPages = assignmentDTO.noOfPages;
    }
}
