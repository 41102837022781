// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            .additional-fields-group {
                padding: 0.5em;
                border: thin solid gainsboro;
                border-radius: 1%;
            }
            .badge {
                margin-bottom: 3px;
                background-color: #8ac175 !important;
            }
        `, "",{"version":3,"sources":["webpack://./src/app/imed-claims/services/common-components/record-review-additional-service/record-reivew-additional-service.component.ts"],"names":[],"mappings":";YACY;gBACI,cAAc;gBACd,4BAA4B;gBAC5B,iBAAiB;YACrB;YACA;gBACI,kBAAkB;gBAClB,oCAAoC;YACxC","sourcesContent":["\n            .additional-fields-group {\n                padding: 0.5em;\n                border: thin solid gainsboro;\n                border-radius: 1%;\n            }\n            .badge {\n                margin-bottom: 3px;\n                background-color: #8ac175 !important;\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
