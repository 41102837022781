// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            .back-button {
                padding-left: 4px;
                padding-right: 6px;
                padding-top: 3px;
                padding-bottom: 0;
                margin-top: -3px;
            }
            .back-button-description {
                margin-left: -8px;
            }
        `, "",{"version":3,"sources":["webpack://./src/app/address-books/individuals/individual-header/individual-header.component.ts"],"names":[],"mappings":";YACY;gBACI,iBAAiB;gBACjB,kBAAkB;gBAClB,gBAAgB;gBAChB,iBAAiB;gBACjB,gBAAgB;YACpB;YACA;gBACI,iBAAiB;YACrB","sourcesContent":["\n            .back-button {\n                padding-left: 4px;\n                padding-right: 6px;\n                padding-top: 3px;\n                padding-bottom: 0;\n                margin-top: -3px;\n            }\n            .back-button-description {\n                margin-left: -8px;\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
