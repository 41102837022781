import { CommunicationLogService } from '../common/services/communication-log.service';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { Injectable, Injector, EventEmitter, Output } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { SearchParams } from '@mt-ng2/common-classes';
import { ITask } from '@model/interfaces/task';
import { AuthService, ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { DateTimeConverterService } from '../common/services/date-time-converter.service';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ClaimTypes } from '@model/ClaimTypes';
import { Location } from '@angular/common';
import { ITaskDTO } from '@model/interfaces/custom/task-dto';
import { IUser } from '@model/interfaces/user';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { AssignedToTypes } from '../../client-portal/common/constants/assigned-to-type.enum';

export const emptyTask: ITask = {
    Archived: false,
    AssignedToTypeId: 1,
    CreatedById: 0,
    DateCreated: new Date(),
    DueDate: null,
    Id: 0,
    ImedClaimId: 0,
    IsAutomated: false,
    IsPhysicalCheckRequiredToFacility: false,
    Notes: null,
    TaskDescriptions: null,
    TaskStatusId: 1,
    TriggeredFromServicePhysicalCheckRequiredToFacility: false,
    TriggeredFromServiceProcessServerFee: false,
    TriggeredFromServiceWitnessFee: false,
    TriggeredFromServiceWitnessFeePhysicalCheckToForeignCourtRequired: false,
    TriggeredFromServiceFacilityFeePhysicalCheckToFacilityRequired: false,
};

@Injectable({
    providedIn: 'root',
})
export class TaskService extends BaseService<ITask> {
    private _timeOfLastUpdate: Date = new Date();
    private _claimIdFromClaimDetail: number;
    @Output() refreshTasks: EventEmitter<any> = new EventEmitter();

    $sidebarServiceUpdateRequired = new Subject<ITaskDTO[]>();

    constructor(
        private claimService: ClaimsService,
        public http: HttpClient,
        private communicationLogService: CommunicationLogService,
        private authService: AuthService,
        private injector: Injector,
        private dateService: DateTimeConverterService,
        private location: Location,
        private notificationService: NotificationsService,
    ) {
        super('/tasks', http);
    }

    private fixDueDate = (task: ITask): ITask => {
        task.DueDate = this.dateService.assumeLocalTimeForUTCDateObject(task.DueDate);
        return task;
    };

    public getTimeOfLastUpdate(): Date {
        return this._timeOfLastUpdate;
    }

    public setTimeOfLastUpdate(val: Date): void {
        this._timeOfLastUpdate = val;
    }

    public getClaimIdFromClaimDetail(): number {
        return this._claimIdFromClaimDetail;
    }

    public setClaimIdFromClaimDetail(val: number): void {
        this._claimIdFromClaimDetail = val;
    }

    public mapDTOtoTask(taskDTO: ITaskDTO): ITask {
        const task: ITask = {
            Archived: taskDTO.Archived,
            AssignedToTypeId: taskDTO.AssignedToTypeId,
            AttachmentTypeId: taskDTO.AttachmentTypeId,
            CompletedById: taskDTO.CompletedById,
            CreatedById: taskDTO.CreatedById,
            DateCompleted: taskDTO.DateCompleted,
            DateCreated: taskDTO.DateCreated,
            DateModified: taskDTO.DateModified,
            DueDate: taskDTO.DueDate,
            FollowupTypeId: taskDTO.FollowupTypeId,
            Id: taskDTO.Id,
            ImeAppointmentId: taskDTO.ImeAppointmentId,
            ImedClaimId: taskDTO.ImedClaimId,
            ImedClaimServiceFollowupId: taskDTO.ImedClaimServiceFollowupId,
            ImedClaimServiceId: taskDTO.ImedClaimServiceId,
            IsAutomated: taskDTO.IsAutomated,
            IsPhysicalCheckRequiredToFacility: false,
            MasterFollowupTypeId: taskDTO.MasterFollowupTypeId,
            MasterServiceStatusId: taskDTO.MasterServiceStatusId,
            ModifiedById: taskDTO.ModifiedById,
            Notes: taskDTO.Notes,
            ParentTaskId: taskDTO.ParentTaskId,
            ServiceStatusId: taskDTO.ServiceStatusId,
            TaskDescriptions: taskDTO.TaskDescriptions,
            TaskStatusId: taskDTO.TaskStatusId,
            TaskTemplateId: taskDTO.TaskTemplateId,
            TriggeredFromServicePhysicalCheckRequiredToFacility: false,
            TriggeredFromServiceProcessServerFee: false,
            TriggeredFromServiceWitnessFee: false,
            TriggeredFromServiceWitnessFeePhysicalCheckToForeignCourtRequired: false,
            TriggeredFromServiceFacilityFeePhysicalCheckToFacilityRequired: false,
        };
        return task;
    }

    getById(id: number): Observable<ITask> {
        return super.getById(id).pipe(map(this.fixDueDate));
    }

    getTaskById(taskId: number): Observable<ITaskDTO> {
        return this.http.get<any>(`/tasks/${taskId}/getTaskById`);
    }

    get(searchparameters: SearchParams): Observable<HttpResponse<ITask[]>> {
        return super.get(searchparameters).pipe(
            map((response) => {
                (<any>response.body) = response.body.map(this.fixDueDate);
                return response;
            }),
        );
    }

    getEmptyTask(): ITask {
        return { ...emptyTask };
    }

    getTaskList(data: SearchParams): Observable<any> {
        const params = this.getHttpParams(data);
        return this.http.get<any>(`/tasks/_getTasksForListPage`, {
            observe: 'response',
            params: params,
        });
    }

    completeTask(userId: number, task: ITaskDTO): Observable<void> {
        return this.http.post<void>(`/tasks/${userId}/_completeTask`, task).pipe(tap(() => this.updateSidebarList()));
    }

    archive(id: number, userId: number): any {
        return this.http.put<number>(`/tasks/archive/${id}/user/${userId}`, {}).pipe(tap(() => this.updateSidebarList()));
    }

    updateSidebarList(isAutomated = false): void {
        const userId = this.authService.currentUser.getValue().Id;
        if (
            !userId ||
            !this.claimService.hasClaim(ClaimTypes.Tasks, [ClaimValues.FullAccess, ClaimValues.ReadOnly]) ||
            this.location.path() === '/login'
        ) {
            return;
        }
        this.setTimeOfLastUpdate(new Date());
        let request;
        if (isAutomated) {
            request = this.http.get<ITaskDTO[]>(`/tasks/${userId}/_getTasksForNavigationPanel`, {
                params: new HttpParams().set('automated', 'true'),
            });
        } else {
            request = this.http.get<ITaskDTO[]>(`/tasks/${userId}/_getTasksForNavigationPanel`);
        }

        request.subscribe((result: ITaskDTO[]) => this.$sidebarServiceUpdateRequired.next(result));
    }

    createTask(data: ITask[]): Observable<number> {
        return this.http.post<number>(`/tasks/create`, data).pipe(
            tap(() => {
                this.updateSidebarList();
            }),
        );
    }

    updateTask(data: ITask): Observable<number> {
        return this.http.post<number>(`/tasks/${data.Id}`, data).pipe(
            tap(() => {
                this.updateSidebarList();
            }),
        );
    }

    getTasksForClaim(claimId: number): Observable<ITaskDTO[]> {
        return this.http.get<ITaskDTO[]>(`/tasks/${claimId}/_getTasksForClaim`);
    }

    assignTasksInBulk(data: any): Observable<any> {
        return this.http.post<any>(`/tasks/assign-in-bulk`, data).pipe(
            tap(() => {
                this.updateSidebarList();
            }),
        );
    }

    canCompleteTask(task: ITaskDTO, userRoleId: number, currentUser: IUser): boolean {
        if (task.AssignedToTypeId === AssignedToTypes.UserRoles as number) {
            const isTaskAssignedUserRole = task.TaskUserRoles.find((ur) => ur.UserRoleId === userRoleId);
            if (!isTaskAssignedUserRole && !currentUser.CanCompleteOthersTasks) {
                this.notificationService.error(`Task ${task.Id} can not be completed by other user roles`);
                return false;
            }
        } else {
            const isTaskAssignedUser = task.TaskUsers.find((tu) => tu.UserId === currentUser.Id);
            if (!isTaskAssignedUser && !currentUser.CanCompleteOthersTasks) {
                this.notificationService.error(`Task ${task.Id} can not be completed by other users`);
                return false;
            }
        }
        return true;
    }
}
