import { ILabelGenerator } from '../../../common/interfaces/ILabelGenerator.d';
import { DatePipe } from '../../../../../node_modules/@angular/common';
import { IPhysicianFollowupLogPartial } from '@model/partials/physician-followup-log-partial';

export class PhysicianFollowupLabelGenerator implements ILabelGenerator {
    constructor() {}

    GetLabel(entity: IPhysicianFollowupLogPartial): string {
        return `<div class="status-log-info">Follow-up Date Set to <b> ${new DatePipe('en-US').transform(
            entity.FollowupDate,
            'MMMM d, yyyy',
            'UTC',
        )}</b><div class="pull-right"><b><font color='red'>${
            entity.ImedClaimType.Name
        }</font> </b></div></div><div class="status-log-subtext">by User <b>${entity.User.FirstName}</b> on ${new DatePipe('en-US').transform(
            entity.AddedDate,
            'MMMM d, yyyy',
            'UTC',
        )}</div><div class="status-log-clear"></div>`;
    }
}
