import { EmailAddressSharedEntitiesConfig } from '@model/shared-entities/email-addresses/email-address.shared-entities-config';
import { AddressBookEmailService } from './addressbook-email.service';
import { PhoneNumberSharedEntitiesConfig } from '@model/shared-entities/phone-numbers/phone-numbers.shared-entities-config';
import { AddressBookPhoneNumberService } from './addressbook-phone.service';
import { EmailAddressesForAttorneyAndCarrierEntitiesConfig } from '@model/shared-entities/email-addresses/email-address-for-special-address-book-types.shared-entities-config';

export enum AddressBookSharedEntities {
    EmailAddresses,
    PhoneNumbers,
    EmailAddressesForAttorneyAndCarrier,
}

export const AddressBookEmailSharedEntity = {
    config: EmailAddressSharedEntitiesConfig,
    entityIdParam: 'emailAddressId',
    path: 'emailaddresses',
    service: AddressBookEmailService,
    sharedEntity: AddressBookSharedEntities.EmailAddresses,
};

export const AddressBookPhoneNumberSharedEntity = {
    config: PhoneNumberSharedEntitiesConfig,
    entityIdParam: 'phoneNumberId',
    path: 'phonenumbers',
    service: AddressBookPhoneNumberService,
    sharedEntity: AddressBookSharedEntities.PhoneNumbers,
};

export const AddressBookEmailSharedEntityForAttorneyAndCarrier = {
    config: EmailAddressesForAttorneyAndCarrierEntitiesConfig,
    entityIdParam: 'emailAddressForAttorneyCarrierId',
    path: 'attorney-carrier-email-addresses',
    service: AddressBookEmailService,
    sharedEntity: AddressBookSharedEntities.EmailAddressesForAttorneyAndCarrier,
};
