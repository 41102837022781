import { Component } from '@angular/core';
import { AddressBooksSelectService } from './common/address-book-select-list/address-books-select.service';
import { IAddressBookSelectEntity } from './common/address-book-select-list/address-books-select-entity';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Component({
    template: `
        <div>
            <h2>Address Book Select POC Component</h2>
        </div>
        <br />
        <div><label>Selected:</label> {{ selected }}</div>
        <br />
        <button class="btn btn-primary" (click)="showSelect()">Show Select Address Book</button>
    `,
})
export class PocComponent {
    selected = 'nothing selected, click button below to select an address book';

    constructor(private addressSelectService: AddressBooksSelectService, private notificationsService: NotificationsService) {}

    showSelect(): void {
        // call the openAddressBookSelect with the type as the first param
        // and then a function as the second param that should be run if
        // an addressBook is selected/added
        this.addressSelectService.openAddressBookSelect('Physician', this.select); // note the bind(this)
    }

    select = (addressBook: IAddressBookSelectEntity): void => {
        this.selected = addressBook.AddressBookName + addressBook.IndividualName;
    }
}
