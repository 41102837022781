import { IEntityListConfig, EntityListConfig, EntityListColumn } from '@mt-ng2/entity-list-module';
import { IViewGetServicesForList } from '@model/interfaces/custom/view-get-services-for-list';
import * as moment from 'moment-timezone';
import { ServiceDeleteDynamicCellComponent } from './service-delete-dynamic-cell.component';
import { OtherServiceDynamicCellComponent } from './other-service-dynamic-cell.component';
import { ServiceTypeDynamicCellComponent } from './service-type-dynamic-cell.component';

export class ImedClaimServicesEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['Id'],
                    name: 'Service #',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['DateCreated'],
                    name: 'Date Created',
                    pipes: ['date:MM/dd/yyyy'],
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (s: IViewGetServicesForList): string {
                        return `${s.ClaimantName} `;
                    },
                    fireOnColumnSelected: true,
                    name: 'Name',
                    sort: {
                        sortProperty: 'ClaimantName',
                    },
                }),
                new EntityListColumn({
                    accessors: ['ServiceType'],
                    excludeFromView: true,
                    name: 'Service Type',
                    sort: {
                        sortProperty: 'ServiceType',
                    },
                }),
                new EntityListColumn({
                    component: ServiceTypeDynamicCellComponent,
                    excludeFromExport: true,
                    fireOnColumnSelected: true,
                    name: 'Service Type',
                    sort: {
                        sortProperty: 'ServiceType',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (s: IViewGetServicesForList): string {
                        return s.PhysicianName ? s.PhysicianName : '';
                    },
                    fireOnColumnSelected: true,
                    name: 'Physician',
                    sort: {
                        sortProperty: 'PhysicianName',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (s: IViewGetServicesForList): string {
                        let name = ' ';
                        if (s.IndividualName.trim() && s.FacilityName) {
                            name = `${s.IndividualName} / ${s.FacilityName}`;
                        } else if (s.IndividualName.trim() && !s.FacilityName) {
                            name = s.IndividualName;
                        } else if (s.FacilityName && !s.IndividualName.trim()) {
                            name = s.FacilityName;
                        }
                        return name;
                    },
                    fireOnColumnSelected: true,
                    name: 'Facility',
                    sort: {
                        sortProperty: 'FacilityName',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (entity: IViewGetServicesForList) => entity.BeginningPageCount?.toString() || ' ',
                    name: 'Beginning PC',
                    sort: {
                        sortProperty: 'BeginningPageCount',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (entity: IViewGetServicesForList) => entity.EndingPageCount?.toString() || ' ',
                    name: 'Ending PC',
                    sort: {
                        sortProperty: 'EndingPageCount',
                    },
                }),
                new EntityListColumn({
                    component: OtherServiceDynamicCellComponent,
                    excludeFromExport: true,
                    fireOnColumnSelected: false,
                    name: 'Other Service',
                    sort: { disableSort: true },
                    style: { width: 140 },
                }),
                new EntityListColumn({
                    accessors: ['Specilaities'],
                    fireOnColumnSelected: true,
                    name: 'Specialties',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (s: IViewGetServicesForList): string {
                        if (s.AppointmentDateTime) {
                            return moment.tz(s.AppointmentDateTime, s.Timezone).format('MM/DD/YYYY hh:mm A');
                        }
                        return '';
                    },
                    fireOnColumnSelected: true,
                    name: 'Appointment Date',
                    sort: {
                        sortProperty: 'AppointmentDateTime',
                    },
                }),
                new EntityListColumn({
                    accessors: ['ServiceStatus'],
                    fireOnColumnSelected: true,
                    name: 'Service Status',
                    sort: {
                        sortProperty: 'ServiceStatus',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (entity: IViewGetServicesForList) => entity.RadiologyFacility || ' ',
                    fireOnColumnSelected: true,
                    name: 'Radiology Facility',
                    sort: {
                        sortProperty: 'RadiologyFacility',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (entity: IViewGetServicesForList) => entity.SubServicesInfo || ' ',
                    fireOnColumnSelected: true,
                    name: 'Add`l Records / Claim Info / Hard Copy',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (entity: IViewGetServicesForList) => {
                        return [
                            entity.HasCRSServices ? 'CRS' : '',
                            entity.HasRetrievalServices ? 'Record Retrieval' : '',
                            entity.HasReviewServices ? 'Record Review' : '',
                        ]
                            .filter((x) => x.trim())
                            .join(', ');
                    },
                    excludeFromView: true,
                    name: 'Other Services',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (entity: IViewGetServicesForList) => {
                        if (!(entity && entity.FollowUpDate && entity.FollowUpName && entity.FollowUpUser)) {
                            return '';
                        }
                        const followupDate = new Date(entity.FollowUpDate);
                        return `<b>${moment.utc(followupDate).format('MM/DD/YYYY')}</b> ${entity.FollowUpName} - <em>${entity.FollowUpUser}</em>`;
                    },
                    bindToInnerHtml: true,
                    excludeFromExport: true,
                    fireOnColumnSelected: true,
                    name: 'Last Follow-up',
                    sort: {
                        sortProperty: 'LatestFollowUp.FollowUpDate',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (entity: IViewGetServicesForList) => {
                        if (!(entity && entity.FollowUpDate && entity.FollowUpName && entity.FollowUpUser)) {
                            return '';
                        }
                        const followupDate = new Date(entity.FollowUpDate);
                        return `${moment.utc(followupDate).format('MM/DD/YYYY')} ${entity.FollowUpName} - ${entity.FollowUpUser}`;
                    },
                    bindToInnerHtml: true,
                    excludeFromView: true,
                    fireOnColumnSelected: true,
                    name: 'Last Follow-up',
                    sort: {
                        sortProperty: 'LatestFollowUp.FollowUpDate',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (entity: IViewGetServicesForList) => {
                        if (!(entity && entity.FollowUpNotes)) {
                            return '';
                        }
                        return `<div class="notes-column">${entity.FollowUpNotes}</div>`;
                    },
                    bindToInnerHtml: true,
                    fireOnColumnSelected: true,
                    name: 'Last Follow-up Notes',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (s: IViewGetServicesForList): string {
                        if (s.ReportDueBy) {
                            return moment.tz(s.ReportDueBy, s.Timezone).format('MM/DD/YYYY');
                        }
                        return '';
                    },
                    fireOnColumnSelected: true,
                    name: 'Report Due By',
                    sort: {
                        sortProperty: 'ReportDueBy',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (s: IViewGetServicesForList): string {
                        if (s.CompilationDueBy) {
                            return moment.tz(s.CompilationDueBy, s.Timezone).format('MM/DD/YYYY');
                        }
                        return '';
                    },
                    fireOnColumnSelected: true,
                    name: 'Compilation Due By',
                    sort: {
                        sortProperty: 'CompilationDueBy',
                    },
                }),
                new EntityListColumn({
                    component: ServiceDeleteDynamicCellComponent,
                    excludeFromExport: true,
                    fireOnColumnSelected: false,
                    name: 'Delete',
                    sort: { disableSort: true },
                }),
            ],
            rowClass: (entity: IViewGetServicesForList) => {
                let classesToAdd = '';
                if (entity.Archived) {
                    classesToAdd += 'archived ';
                } else if (entity.HighValueService && !entity.RushService) {
                    classesToAdd += 'high-value-service ';
                } else if (entity.RushService && !entity.HighValueService) {
                    classesToAdd += 'rush-service ';
                } else if (entity.RushService && entity.HighValueService) {
                    classesToAdd += 'high-value-rush-service ';
                }
                return classesToAdd;
            },
        };
        super(listConfig);
    }
}
