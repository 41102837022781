import { CurrencyFormatterService } from '../../../../../common/services/currency-formatter.service';
import { FilmReviewServiceService } from './../film-review-service.service';
import { ServiceStatusService } from '../../service-status/servicestatus.service';
import { ImedClaimService } from '../../../../imedclaim.service';
import { SubServiceService } from '../../sub-services/sub-service.service';
import { ImedClaimServiceService } from '../../../imedclaimservice.service';
import { ImedClaimServiceFollowupService } from '../../service-followups/imedclaimservicefollowup.service';
import { ImedClaimServiceStatusLogService } from '../../service-status/imed-claim-service-status-log.service';
import { CommunicationLogService } from '../../../../../common/services/communication-log.service';
import { IFilmReviewService } from '@model/interfaces/film-review-service.d';
import { CommonImedClaimService } from '../../../../shared/common-imed-claim-service';
import { AddressBooksSelectService } from '../../../../../common/address-book-select-list/address-books-select.service';
import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { PhysicianService } from '../../../../../address-books/individuals/physicians/physician.service';
import { BaseAttachmentsService } from '../../../../../common/attachments/base-attachments.service';
import { AttachmentTypeEnums, AttachmentServiceTypeEnums, ServiceIdEnums, FaxEmailEntityTypeEnums } from '../../../../../common/constants/Enums';
import { IAppFaxEmailComponentApi } from '../../../../../common/fax-email/fax-email-component';
import { tap } from 'rxjs/operators';
import { IFilmInformationDTO } from '@model/interfaces/custom/film-information-dto';
import { ModalService } from '../../../../../common/services/modal.service';
import { AuthService, ClaimsService } from '@mt-ng2/auth-module';
import { FilmInformationService } from '@app-shared/services/film-information.service';
import { ImeAppointmentService } from '../../../service-specifics/ime-service/ime-appointments/ime-appointment.service';
import { IName, NameTypeEnum } from '@model/interfaces/custom/name';

@Component({
    selector: 'app-film-review-service-detail',
    templateUrl: './film-review-service-detail.component.html',
})
export class FilmReviewServiceDetailComponent extends CommonImedClaimService implements OnInit, OnDestroy {
    filmReviewServiceModel: IFilmReviewService;
    filmInformations: IFilmInformationDTO[];
    @Output('onRefreshStatus') onRefreshStatus: EventEmitter<any> = new EventEmitter<any>();
    appCoverLetterFaxEmailApi: IAppFaxEmailComponentApi;
    attachmentServiceTypeEnum: AttachmentServiceTypeEnums = AttachmentServiceTypeEnums.Radiology_Review;
    hasIMEAssociation: boolean;
    ServiceTypes = ServiceIdEnums;
    entityTypeEnums = FaxEmailEntityTypeEnums;

    get serviceIsWCOrAuto(): boolean {
        return (
            this.imedClaimServiceModel.ServiceId !== +ServiceIdEnums.WC_Film_Review &&
            this.imedClaimServiceModel.ServiceId !== +ServiceIdEnums.Auto_Radiology_Review &&
            this.imedClaimServiceModel.ServiceId !== + ServiceIdEnums.WC_Independent_Film_Review &&
            this.imedClaimServiceModel.ServiceId !== + ServiceIdEnums.Auto_Independent_Film_Review
        );
    }

    constructor(
        private communicationLogService: CommunicationLogService,
        private router: Router,
        private route: ActivatedRoute,
        private imedclaimServiceStausLogService: ImedClaimServiceStatusLogService,
        private imedClaimServiceFollowupService: ImedClaimServiceFollowupService,
        private imedClaimServiceService: ImedClaimServiceService,
        private addressBookSelectService: AddressBooksSelectService,
        private currencyFormatterService: CurrencyFormatterService,
        private physicianService: PhysicianService,
        private imeAppointmentService: ImeAppointmentService,
        private subServiceService: SubServiceService,
        private imedClaimService: ImedClaimService,
        private serviceStatusService: ServiceStatusService,
        private filmReviewServiceService: FilmReviewServiceService,
        private filmInformationService: FilmInformationService,
        private attachmentsService: BaseAttachmentsService,
        private modalService: ModalService,
        private claimService: ClaimsService,
        private authService: AuthService,
    ) {
        super(
            communicationLogService,
            router,
            route,
            imedclaimServiceStausLogService,
            imedClaimServiceFollowupService,
            imedClaimServiceService,
            addressBookSelectService,
            currencyFormatterService,
            physicianService,
            imeAppointmentService,
            subServiceService,
            imedClaimService,
            serviceStatusService,
            attachmentsService,
            modalService,
            claimService,
            authService,
        );

        this.subscriptions.add(
            this.imedClaimServiceService.changeEmitted$.subscribe((imedClaimService) => {
                this.getCommunicationLog().subscribe();
            }),
        );
    }

    ngOnInit(): void {
        forkJoin(
            this.getImedClaimServiceStatusLog(),
            this.getCommunicationLog(),
            this.getFollowups(),
            this.getSubServices(),
            this.getServiceStatuses(),
            this.getPrimaryEmailForImedClaimDefenseAttorney(),
            this.getFilmReviewServiceByImedClaimServiceId(),
            this.getFilmInformations(),
        ).subscribe(() => {
            this.subscribeToCommunicationLogs(), this.setPhysicianInformations();
            this.setIsPhysicianSelected(); // 1 - 1 there is not going to be more than 1 ImeServices_ImedClaimServiceId records
            this.setFormRendered();
            this.checkIfIMERelatedRadiologyReview();
            this.setDisablePhysicianFacilityControl();
        });

        this.editingComponent.next('');
    }

    getClaimantName(): IName {
        return { firstName: this.imedClaimServiceModel.ImedClaim.FirstName, lastName: this.imedClaimServiceModel.ImedClaim.LastName, nameType: NameTypeEnum.Individual };
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    getFilmReviewServiceByImedClaimServiceId(): Observable<IFilmReviewService> {
        return this.filmReviewServiceService
            .getByImedClaimServiceId(this.imedClaimServiceModel.Id)
            .pipe(tap((filmReviewServiceService: IFilmReviewService) => (this.filmReviewServiceModel = filmReviewServiceService)));
    }

    checkIfIMERelatedRadiologyReview(): void {
        this.filmReviewServiceService.checkForIMEService(this.imedClaimServiceModel.Id).subscribe((response) => (this.hasIMEAssociation = response));
    }

    rebindFilmInformation(): void {
        this.getFilmInformations().subscribe();
    }

    refreshStatus(): void {
        this.onRefreshStatus.emit();
        this.getImedClaimServiceStatusLog().subscribe();
    }

    getFilmInformations(): Observable<IFilmInformationDTO[]> {
        return this.filmInformationService
            .getByImedClaimServiceId(this.imedClaimServiceId, ServiceIdEnums.Film_Review)
            .pipe(tap((filmInformations) => (this.filmInformations = filmInformations)));
    }

    otherAttachmentChanged(value: any): void {
        super.otherAttachmentChanged(value);
        if (value === AttachmentTypeEnums.Report_From_Physician) {
            this.getFollowups().subscribe();
        }
    }

    attachmentChanged(value: number): void {
        super.attachmentChanged(value);
        if (value === +AttachmentTypeEnums.Report_From_Physician) {
            this.getFollowups().subscribe();
        }
    }

    showRadiologyFaxEmailDialog(): void {
        this.appCoverLetterFaxEmailApi.show();
    }

    refreshDocuments(): void {
        this.attachmentsService.requestRefresh();
    }
}
