import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { IUserRole } from '@model/interfaces/user-role';
import { IMetaItem } from '@mt-ng2/base-service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { UserRoleService } from '../../user-roles/user-role.service';

@Component({
    selector: 'multiselect-table-control',
    styles: [
        `
            .invalid {
                border-color: red;
            }
            .btn-assignment {
                margin-top: 20px;
            }
        `,
    ],
    templateUrl: './multiselect-table-control.component.html',
})
export class MultiselectTableControlComponent implements OnInit {
    @Output() onItemSelected: EventEmitter<number[]> = new EventEmitter();
    @Input() valid = true;
    @Input('itemType') itemType = '';
    private _allItems: IMetaItem[] = [];
    userRoles: IUserRole[];
    @Input('allItems')
    set allItems(value: IMetaItem[]) {
        this._allItems = value;
    }
    get allItems(): IMetaItem[] {
        return this._allItems;
    }
    @Input('copiedIds') copiedIds: number[];
    @Input('currentItems') currentItems: any[];
    @Input('idProperty') idProperty;
    @Input() displayAssignedToButtons: boolean;

    get placeholderText(): string {
        return this.unselectedItems.length > 0 ? `Select an item` : 'All items selected';
    }

    private _selectedIds: number[] = [];
    set selectedIds(val: number[]) {
        this._selectedIds = val;
    }
    get selectedIds(): number[] {
        return this._selectedIds;
    }
    selectedItem: IMetaItem | string = '';
    allItemsSelected = false;
    constructor(private notificationService: NotificationsService, private userRoleService: UserRoleService) {}

    ngOnInit(): void {
        this.userRoleService.getAll().subscribe((roles) => (this.userRoles = roles));
        this.selectedIds = this.currentItems ? this.currentItems.map((i) => i[this.idProperty]) : [];
        this.selectedItem = this.placeholderText;
        this.onItemSelected.emit(this.selectedIds);
        if (this.copiedIds) {
            this.selectedIds = this.copiedIds;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.copiedIds && changes.copiedIds.currentValue && changes.copiedIds.isFirstChange()) {
            this.selectedIds = changes.copiedIds.currentValue;
        } else if (changes.currentItems) {
            this.selectedIds = this.currentItems ? this.currentItems.map((i) => i[this.idProperty]) : [];
            this.onItemSelected.emit(this.selectedIds);
        }
    }

    get unselectedItems(): IMetaItem[] {
        return this.allItems.filter((item) => this._selectedIds.indexOf(item.Id) < 0);
    }

    get selectedItems(): IMetaItem[] {
        return this.allItems.filter((item) => this._selectedIds.indexOf(item.Id) >= 0);
    }

    selectItem(evt: IMetaItem): void {
        this._selectedIds.push(evt.Id);
        this.onItemSelected.emit(this.selectedIds);
        if (!this.unselectedItems.length) {
            this.allItemsSelected = true;
            this.notificationService.info(`All ${this.itemType.toLowerCase()} are selected.`);
        }
    }

    removeItem(itemId: number): void {
        this._selectedIds = this._selectedIds.filter((id) => id !== itemId);
        this.selectedItem = this.placeholderText;
        this.onItemSelected.emit(this.selectedIds);
        if (this.unselectedItems.length === 1) {
            this.allItemsSelected = false;
        }
    }

    onChangeAssignedTo(key: string): void {
        const userRolesToAdd = this.userRoles.filter((ur) => ur[key]);
        userRolesToAdd.forEach((role) => {
            const index = this._selectedIds.findIndex((id) => id === role.Id);
            if (index === -1) {
                this.selectItem({ Id: role.Id, Name: role.Name });
            }
        });
    }
}
