// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            h4.permissions {
                color: #4e6365;
                font-weight: 700;
                border-bottom: solid 1px #ccc;
                padding-bottom: 5px;
            }
            tr.permission {
                cursor: default;
            }
            td.claim-value-buttons {
                width: 300px;
            }
            .claim-type-name {
                margin-top: 8px;
                margin-bottom: 8px;
            }
            tr.child-permission > td:nth-child(1) {
                padding-top: 15px;
            }
            tr.child-permission > td:nth-child(1) > i {
                padding-top: 4px;
                padding-left: 5px;
                padding-right: 12px;
            }
            tr.child-permission > td:nth-child(2) > div > label.btn-primary-inverse.btn.read-only {
                visibility: hidden;
            }
        `, "",{"version":3,"sources":["webpack://./src/app/user-roles/user-role-permissions/user-role-permissions.component.ts"],"names":[],"mappings":";YACY;gBACI,cAAc;gBACd,gBAAgB;gBAChB,6BAA6B;gBAC7B,mBAAmB;YACvB;YACA;gBACI,eAAe;YACnB;YACA;gBACI,YAAY;YAChB;YACA;gBACI,eAAe;gBACf,kBAAkB;YACtB;YACA;gBACI,iBAAiB;YACrB;YACA;gBACI,gBAAgB;gBAChB,iBAAiB;gBACjB,mBAAmB;YACvB;YACA;gBACI,kBAAkB;YACtB","sourcesContent":["\n            h4.permissions {\n                color: #4e6365;\n                font-weight: 700;\n                border-bottom: solid 1px #ccc;\n                padding-bottom: 5px;\n            }\n            tr.permission {\n                cursor: default;\n            }\n            td.claim-value-buttons {\n                width: 300px;\n            }\n            .claim-type-name {\n                margin-top: 8px;\n                margin-bottom: 8px;\n            }\n            tr.child-permission > td:nth-child(1) {\n                padding-top: 15px;\n            }\n            tr.child-permission > td:nth-child(1) > i {\n                padding-top: 4px;\n                padding-left: 5px;\n                padding-right: 12px;\n            }\n            tr.child-permission > td:nth-child(2) > div > label.btn-primary-inverse.btn.read-only {\n                visibility: hidden;\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
