import { Subscription, Observable, forkJoin } from 'rxjs';
import { AddressBookTypeService } from './../addressbooktype.service';
import { MetaAddressBookTypeService } from './../metaaddressbooktype.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import 'rxjs/operators';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues, AuthService } from '@mt-ng2/auth-module';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';

import { AddressBookService } from '../addressbook.service';
import { entityListModuleConfig } from '../../common/shared.module';
import { ClaimTypes } from '@model/ClaimTypes';
import { AddressBooksEntityListConfig } from './address-books.entity-list-config';
import { IColumnSortedEvent, SortDirection, IItemDeletedEvent, EntityListExportService } from '@mt-ng2/entity-list-module';
import { IAddressBook } from '@model/partials/address-book.partial';
import { IAddressBookListViewItem } from '@model/interfaces/custom/address-book-list-view-item';
import { NotificationsService } from '../../../../node_modules/@mt-ng2/notifications-module';
import { UserService } from '../../users/user.service';
import { CommonSearchService } from '../../common/services/common-search.service';
import { debounceTime, map } from 'rxjs/operators';
import { PhysicianStatusService } from '../individuals/physicians/physicianstatus.service';
import { SpecialityDetailService } from '../../imed-managed-list/speciality-detail.service';
import { SpecialityService } from '../../imed-managed-list/speciality.service';
import {
    ISelectionChangedEvent as ITypeAheadSelectionChangedEvent,
    VirtualTypeAheadGetItemsFunction,
    ITypeAheadAPI,
} from '@mt-ng2/type-ahead-control';
import { AddressService, IAddressTypeAheadDTO } from '../addresses/address.service';
import { MtSearchBarComponent } from '@mt-ng2/searchbar-control';
import { ReuseRoute } from '../../common/cache-search-reuse-strategy/reuse-route.library';
import { HttpResponse } from '@angular/common/http';
import { CommonService } from '../../common/services/common.service';
import { ImedClaimTypeService } from '../../common/services/imed-claim-type.service';
import { AddressTypeService } from '../addresses/addresstype.service';
import { AddressBookTypeIdEnums, AddressTypeIdEnums } from '@common/constants/Enums';
import { AddressBooksEntityListExportConfig } from './address-books.entity-list-export-config';
import { AddressBooksEntityListFeesExportConfig } from './address-books.entity-list-fees-export-config';
@Component({
    selector: 'app-address-books',
    styleUrls: ['./address-books.component.less'],
    templateUrl: './address-books.component.html',
})
export class AddressBooksComponent implements OnInit, OnDestroy, ReuseRoute {
    searchControl = new UntypedFormControl();
    addressBooks: IAddressBook[];
    currentPage = 1;
    query = '';
    total: number;
    metaAddressBookTypes: MtSearchFilterItem[] = [];
    addressBookTypes: MtSearchFilterItem[] = [];
    addressTypes: MtSearchFilterItem[] = [];
    states: MtSearchFilterItem[] = [];
    physicianSpecialities: MtSearchFilterItem[] = [];
    physicianSpecialtyDetails: MtSearchFilterItem[] = [];
    physicianStatuses: MtSearchFilterItem[] = [];
    externalAccessStatuses: MtSearchFilterItem[] = [];
    imedClaimTypes: MtSearchFilterItem[] = [];
    canAddAddressBook = false;
    formCreated = false;
    entityListConfig = new AddressBooksEntityListConfig();
    entityListConfigExport = new AddressBooksEntityListExportConfig();
    entityListConfigFeesExport = new AddressBooksEntityListFeesExportConfig();
    canExportPhysicianExpertFees = false;
    order = 'FacilityAndName';
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();
    subscription: Subscription = new Subscription();
    searchFormGroup: UntypedFormGroup;
    minConsultationFee: any;
    maxConsultationFee: any;
    includedArchived: boolean = false;
    willingToTravel: boolean = false;
    checkForExternalAccess: boolean = false;
    phoneNo: string;
    email: string;
    getItems: VirtualTypeAheadGetItemsFunction = this.getAddresses.bind(this);
    selectedAddressBookId: number;
    selectedAddressId: number;
    virtualTypeAheadControl: ITypeAheadAPI;
    searchExpanded = false;
    county: string;
    // Variables for saved search component
    commonSearchParams: SearchParams;
    userId: number;
    currentSearch: SearchParams;
    searchChanged: boolean;

    componentLoaded: boolean;

    @ViewChild('searchBar') searchBar: MtSearchBarComponent;

    constructor(
        private addressBookService: AddressBookService,
        private metaAddressBookTypesService: MetaAddressBookTypeService,
        private addressBookTypesService: AddressBookTypeService,
        private addressTypesService: AddressTypeService,
        private claimsService: ClaimsService,
        private notificationService: NotificationsService,
        private authService: AuthService,
        private commonSearchService: CommonSearchService,
        private commonService: CommonService,
        private physicianStatusService: PhysicianStatusService,
        private physicianSpecialtyService: SpecialityService,
        private physicianSpecialtyDetailService: SpecialityDetailService,
        private addressService: AddressService,
        private imedClaimTypeService: ImedClaimTypeService,
        private entityListExportService: EntityListExportService,
    ) {}

    resetFilters(): void {
        this.searchBar.clearSearch();
        this.addressBookTypes.forEach((x) => (x.Selected = false));
        this.addressTypes.forEach((x) => (x.Selected = false));
        this.states.forEach((x) => (x.Selected = false));
        this.includedArchived = false;
        if (this.virtualTypeAheadControl) {
            this.clearTypeAhead();
        }
        this.physicianSpecialities.forEach((x) => (x.Selected = false));
        this.physicianSpecialtyDetails.forEach((x) => (x.Selected = false));
        this.physicianStatuses.forEach((x) => (x.Selected = false));
        this.imedClaimTypes.forEach((x) => (x.Selected = false));
        if (this.phoneNo) {
            this.searchFormGroup.get('phoneNumberSearch').setValue(null);
        }
        if (this.email) {
            this.searchFormGroup.get('countySearch').setValue(null);
        }
        if (this.county) {
            this.searchFormGroup.get('emailSearch').setValue(null);
        }
        if (this.includedArchived) {
            this.searchFormGroup.get('searchIncludeArchived').setValue(false);
        }
        if (this.willingToTravel) {
            this.searchFormGroup.get('filterWillingToTravel').setValue(false);
        }
        if (this.checkForExternalAccess) {
            this.searchFormGroup.get('hasExternalAccess').setValue(false);
        }
        if (this.minConsultationFee) {
            this.searchFormGroup.get('minConsultationFee').setValue(null);
        }
        if (this.maxConsultationFee) {
            this.searchFormGroup.get('maxConsultationFee').setValue(null);
        }

        this.searchExpanded = false;
        this.commonSearchParams = null;
        this.searchChanged = !this.searchChanged;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    onRouteReuse(): void {
        this.getAddressBooks();
    }

    subscribeToForm(): void {
        this.subscription.add(
            this.searchFormGroup.get('searchIncludeArchived').valueChanges.subscribe((value) => {
                this.includedArchived = value;
                this.clearCommonSearchParamsAndSearch();
            }),
        );

        this.subscription.add(
            this.searchFormGroup.get('filterWillingToTravel').valueChanges.subscribe((value) => {
                this.willingToTravel = value;
                this.clearCommonSearchParamsAndSearch();
            }),
        );

        this.subscription.add(
            this.searchFormGroup.get('hasExternalAccess').valueChanges.subscribe((value) => {
                this.checkForExternalAccess = value;
                this.clearCommonSearchParamsAndSearch();
            }),
        );

        this.subscription.add(
            this.searchFormGroup
                .get('phoneNumberSearch')
                .valueChanges.pipe(debounceTime(300))
                .subscribe((value) => {
                    if ((value && value.length >= 3) || !value) {
                        this.phoneNo = value;
                        this.clearCommonSearchParamsAndSearch();
                    }
                }),
        );

        this.subscription.add(
            this.searchFormGroup
                .get('countySearch')
                .valueChanges.pipe(debounceTime(300))
                .subscribe((value) => {
                    if ((value && value.length >= 3) || !value) {
                        this.county = value;
                        this.clearCommonSearchParamsAndSearch();
                    }
                }),
        );

        this.subscription.add(
            this.searchFormGroup
                .get('emailSearch')
                .valueChanges.pipe(debounceTime(300))
                .subscribe((value) => {
                    if ((value && value.length >= 3) || !value) {
                        this.email = value;
                        this.clearCommonSearchParamsAndSearch();
                    }
                }),
        );

        this.subscription.add(
            this.searchFormGroup
                .get('minConsultationFee')
                .valueChanges.pipe(debounceTime(300))
                .subscribe((value) => {
                    this.minConsultationFee = value;
                    this.clearCommonSearchParamsAndSearch();
                }),
        );

        this.subscription.add(
            this.searchFormGroup
                .get('maxConsultationFee')
                .valueChanges.pipe(debounceTime(300))
                .subscribe((value) => {
                    this.maxConsultationFee = value;
                    this.clearCommonSearchParamsAndSearch();
                }),
        );
    }

    onExportClick(): void {
        this.getAddressBookSubscription(true)
            .pipe(map((x) => x.body))
            .subscribe((data) => {
                this.entityListExportService.export(data, this.entityListConfigExport);
            });
    }

    ngOnInit(): void {
        const hasExportAddressBookFullAccess = this.claimsService.hasClaim(ClaimTypes.ExportAddressBook, [ClaimValues.FullAccess]);
        const hasAddressBookFullAccess = this.claimsService.hasClaim(ClaimTypes.AddressBooks, [ClaimValues.FullAccess]);
        const hasAddressBookReadOnlyAccess = this.claimsService.hasClaim(ClaimTypes.AddressBooks, [ClaimValues.ReadOnly]);

        if (hasExportAddressBookFullAccess && (hasAddressBookFullAccess || hasAddressBookReadOnlyAccess)) {
            this.entityListConfig.export = {
                exportName: 'Address Book List',
                onExportClick: () => this.onExportClick(),
            };
        }

        this.searchFormGroup = new UntypedFormGroup({
            countySearch: new UntypedFormControl(''),
            emailSearch: new UntypedFormControl(''),
            filterWillingToTravel: new UntypedFormControl(false),
            hasExternalAccess: new UntypedFormControl(false),
            maxConsultationFee: new UntypedFormControl(''),
            minConsultationFee: new UntypedFormControl(''),
            phoneNumberSearch: new UntypedFormControl(''),
            searchIncludeArchived: new UntypedFormControl(false),
        });
        this.canAddAddressBook = this.claimsService.hasClaim(ClaimTypes.AddressBooks, [ClaimValues.FullAccess]);
        this.getAddressBooks();

        this.userId = this.authService.currentUser.getValue().Id;

        forkJoin([
            this.commonService.getStates(),
            this.metaAddressBookTypesService.getSearchFilterItems(),
            this.physicianStatusService.getSearchFilterItems(),
            this.physicianSpecialtyDetailService.getSearchFilterItems(),
            this.physicianSpecialtyService.getSearchFilterItems(),
            this.addressBookTypesService.getSearchFilterItems(),
            this.addressTypesService.getSearchFilterItems(),
            this.imedClaimTypeService.getSearchFilterItems(),
        ]).subscribe(
            ([
                states,
                metaAddressBookTypes,
                physicianStatuses,
                physicianSpecialtyDetails,
                physicianSpecialities,
                addressBookTypes,
                addressTypes,
                imedClaimTypes,
            ]) => {
                this.states = (states as any).map((item) => new MtSearchFilterItem(item, false));
                this.metaAddressBookTypes = metaAddressBookTypes;
                this.physicianStatuses = physicianStatuses;
                this.physicianSpecialtyDetails = physicianSpecialtyDetails;
                this.physicianSpecialities = physicianSpecialities;
                this.addressBookTypes = addressBookTypes;
                this.addressTypes = addressTypes;
                this.addressTypes = this.addressTypes.filter((at) => at.Item.Id !== (AddressTypeIdEnums.OldAddress as number));
                this.imedClaimTypes = imedClaimTypes;
            },
        );

        this.subscribeToForm();
        this.formCreated = true;
        this.componentLoaded = true;
        this.setPhysicianExpertFeesExportRights(hasAddressBookFullAccess, hasAddressBookReadOnlyAccess);
    }

    onFeesExportClick(): void {
        this.getAddressBookSubscription(true, true)
            .pipe(map((x) => x.body))
            .subscribe((data) => {
                this.entityListExportService.export(data, this.entityListConfigFeesExport);
            });
    }

    setPhysicianExpertFeesExportRights(hasAddressBookFullAccess, hasAddressBookReadOnlyAccess): void {
        const hasExportPhysicianExpertFeesAccess = this.claimsService.hasClaim(ClaimTypes.ExportPhysicianExpertPanelFees, [ClaimValues.FullAccess]);
        this.canExportPhysicianExpertFees = hasExportPhysicianExpertFeesAccess && (hasAddressBookFullAccess || hasAddressBookReadOnlyAccess);
    }

    overrideDebounceTime(searchControl: UntypedFormControl): void {
        this.subscription.add(
            searchControl.valueChanges.pipe(debounceTime(600)).subscribe((value: string) => {
                this.search(value);
            }),
        );
    }

    getAddresses(): Observable<IAddressTypeAheadDTO[]> {
        return this.addressService.getAddressesForSearchFilter();
    }

    selectionChanged({ selection }: ITypeAheadSelectionChangedEvent): void {
        if (selection) {
            this.selectedAddressBookId = selection.AddressBookId;
            this.selectedAddressId = selection.AddressId;
            this.clearCommonSearchParamsAndSearch();
        }
    }

    virtualTypeAheadControlReady(controlApi: ITypeAheadAPI): void {
        this.virtualTypeAheadControl = controlApi;
    }

    clearTypeAhead(): void {
        this.virtualTypeAheadControl.clearValue();
        this.selectedAddressBookId = null;
        this.selectedAddressId = null;
        this.clearCommonSearchParamsAndSearch();
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    private buildSearch(): ExtraSearchParams[] {
        const selectedAddressBookTypesIds: number[] = this.getSelectedFilters(this.addressBookTypes);
        const selectedAddressTypeIds: number[] = this.getSelectedFilters(this.addressTypes);
        const selectedStateIds: number[] = this.getSelectedFilters(this.states);
        const selectedPhysicianSpecialities: number[] = this.getSelectedFilters(this.physicianSpecialities);
        const selectedPhysicianSpecialtyDetails: number[] = this.getSelectedFilters(this.physicianSpecialtyDetails);
        const selectedPhysicianStatuses: number[] = this.getSelectedFilters(this.physicianStatuses);
        const selectedImedClaimTypes: number[] = this.getSelectedFilters(this.imedClaimTypes);
        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'AddressBookTypeIds',
                valueArray: selectedAddressBookTypesIds,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'AddressTypeIds',
                valueArray: selectedAddressTypeIds,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'StateIds',
                valueArray: selectedStateIds,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'AddressBookId',
                value: this.selectedAddressBookId ? this.selectedAddressBookId.toString() : '',
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'AddressId',
                value: this.selectedAddressId ? this.selectedAddressId.toString() : '',
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'PhysicianStatusIds',
                valueArray: selectedPhysicianStatuses,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'PhysicianSpecialtyIds',
                valueArray: selectedPhysicianSpecialities,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'PhysicianSpecialtyDetailIds',
                valueArray: selectedPhysicianSpecialtyDetails,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'ImedClaimTypeIds',
                valueArray: selectedImedClaimTypes,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'checkForExternalAccess',
                value: this.checkForExternalAccess ? '1' : '0',
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'includeArchived',
                value: this.includedArchived ? '1' : '0',
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'willingToTravel',
                value: this.willingToTravel ? '1' : '0',
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'phoneNo',
                value: this.phoneNo ? this.phoneNo : '',
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'email',
                value: this.email ? this.email : '',
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'county',
                value: this.county ? this.county : '',
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'minConsultationFee',
                value: this.minConsultationFee ? this.minConsultationFee : '',
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'maxConsultationFee',
                value: this.maxConsultationFee ? this.maxConsultationFee : '',
            }),
        );

        return _extraSearchParams;
    }

    private buildPhysicianExpertFeesSearch(): ExtraSearchParams[] {
        const selectedAddressBookTypesIds = [AddressBookTypeIdEnums.Physician_panel, AddressBookTypeIdEnums.Expert_panel];
        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'AddressBookTypeIds',
                valueArray: selectedAddressBookTypesIds,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'includeArchived',
                value: '0',
            }),
        );

        return _extraSearchParams;
    }

    preFillFiltersWithSavedSearch(): void {
        this.autoFillSelectedFilters(this.addressBookTypes, 'AddressBookTypeIds');
        this.autoFillSelectedFilters(this.addressTypes, 'AddressTypeIds');
        this.autoFillSelectedFilters(this.states, 'StateIds');
        this.autoFillSelectedFilters(this.physicianSpecialities, 'PhysicianSpecialtyIds');
        this.autoFillSelectedFilters(this.physicianSpecialtyDetails, 'PhysicianSpecialtyDetailIds');
        this.autoFillSelectedFilters(this.physicianStatuses, 'PhysicianStatusIds');
        this.autoFillSelectedFilters(this.imedClaimTypes, 'ImedClaimTypeIds');
        this.setCheckBoxFilter('includeArchived', 'searchIncludeArchived', this.includedArchived);
        this.setCheckBoxFilter('willingToTravel', 'filterWillingToTravel', this.willingToTravel);
        this.setCheckBoxFilter('checkForExternalAccess', 'hasExternalAccess', this.checkForExternalAccess);
        this.setTextFilter('AddressBookId', 'searchAddressBookId', this.selectedAddressBookId);
        this.setTextFilter('phoneNo', 'phoneNumberSearch', this.phoneNo);
        this.setTextFilter('email', 'emailSearch', this.email);
        this.setTextFilter('county', 'countySearch', this.county);
        this.setTextFilter('minConsultationFee', 'minConsultationFee', this.minConsultationFee);
        this.setTextFilter('maxConsultationFee', 'maxConsultationFee', this.maxConsultationFee);
        if (this.commonSearchParams.query !== '') {
            this.query = this.commonSearchParams.query;
            this.searchBar.writeValue(this.query);
        }
    }

    setCheckBoxFilter(pptyName: string, formControlName: string, ppty: any): void {
        const extrasearchParams = this.commonSearchParams.extraParams;
        const filteredParam = extrasearchParams?.find((extraParam) => {
            return extraParam.name === pptyName;
        });
        if (filteredParam && filteredParam.value) {
            const valueToSet = filteredParam.value === '1' ? true : false;
            if (valueToSet !== ppty) {
                this.searchFormGroup.get(formControlName).setValue(valueToSet);
            }
        }
    }

    setTextFilter(pptyName: string, formControlName: string, currentVal: any): void {
        const extrasearchParams = this.commonSearchParams.extraParams;
        const filteredParam = extrasearchParams?.find((extraParam) => {
            return extraParam.name === pptyName;
        });
        if (filteredParam && filteredParam.value) {
            if (currentVal !== filteredParam.value) {
                this.searchFormGroup.get(formControlName).setValue(filteredParam.value);
            }
        }
    }

    private autoFillSelectedFilters(filterObj: MtSearchFilterItem[], pptyName: string): void {
        const extrasearchParams = this.commonSearchParams.extraParams;
        const filteredParam = extrasearchParams?.find((extraParam) => {
            return extraParam.name === pptyName;
        });
        if (filteredParam && filteredParam.valueArray && filteredParam.valueArray.length > 0) {
            filterObj.map((item) => {
                filteredParam?.valueArray?.forEach((element) => {
                    if (element === item.Item.Id) {
                        item.Selected = true;
                    }
                });
            });
        }
    }

    clearCommonSearchParamsAndSearch(): void {
        this.currentPage = 1;
        this.getAddressBooks();
    }

    getAddressBooks(): void {
        this.getAddressBookSubscription().subscribe((answer) => {
            this.addressBooks = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    getAddressBookSubscription(skipPaging?: boolean, physicianExpertFeesExport = false): Observable<HttpResponse<IAddressBook[]>> {
        const search = this.commonSearchParams && this.commonSearchParams.query ? this.commonSearchParams.query : this.query;
        const _extraSearchParams: ExtraSearchParams[] = physicianExpertFeesExport ? this.buildPhysicianExpertFeesSearch() : this.buildSearch();

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.commonSearchParams && this.commonSearchParams.order ? this.commonSearchParams.order : this.order,
            orderDirection:
                this.commonSearchParams && this.commonSearchParams.orderDirection ? this.commonSearchParams.orderDirection : this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: skipPaging ? 0 : (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: skipPaging ? 0 : entityListModuleConfig.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);

        // store current search params in case user wants to saves this search
        this.currentSearch = searchparams;
        return this.addressBookService.searchAddressBooks(searchparams);
    }

    search(query: string): void {
        this.query = query;
        this.clearCommonSearchParamsAndSearch();
    }

    searchBySavedSearch(): void {
        this.commonSearchService.getCommonSearchFilters().subscribe((filters) => {
            if (this.commonSearchParams) {
                this.resetFilters();
            }
            this.query = '';
            this.currentPage = 1;
            this.commonSearchParams = filters ? filters.searchFilters : null;
            this.preFillFiltersWithSavedSearch();
            this.getAddressBooks();
            this.commonSearchService.clearCommonSearchFilters();
        });
    }

    columnSorted(event: IColumnSortedEvent): void {
        if (this.commonSearchParams) {
            this.commonSearchParams.order = event.column.sort.sortProperty;
            this.commonSearchParams.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        } else {
            this.order = event.column.sort.sortProperty;
            this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        }
        this.getAddressBooks();
    }

    onItemDeleted(event: IItemDeletedEvent): void {
        const selectedAddressBook: IAddressBookListViewItem = event.entity;
        this.addressBookService.archive(selectedAddressBook.AddressBookId, this.authService.currentUser.getValue().Id).subscribe(() => {
            this.notificationService.success('Address Book Updated Successfully');
            this.getAddressBooks();
        });
    }
}
