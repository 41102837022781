import { Component, EventEmitter, Output } from '@angular/core';
import { IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';

import { CommonNotesComponent } from '@mt-ng2/entity-components-notes';
import { SortDirection } from '@mt-ng2/entity-list-module';
import { INote } from '@mt-ng2/note-control';
const emptyNote: INote = {
    Id: 0,
    NoteText: '',
    Title: 'Adjuster Inquiry',
};

@Component({
    selector: 'app-adjuster-inquiries',
    templateUrl: './adjuster-inquiry-notes.component.html',
})
export class ImedClaimAdjusterInquiriesComponent extends CommonNotesComponent {
    @Output('onNoteDeleted') onNoteDeleted: EventEmitter<void> = new EventEmitter<void>();
    @Output('onNoteDeleteFailed') onNoteDeleteFailed: EventEmitter<void> = new EventEmitter<void>();
    @Output('onGetNotes') onGetNotes: EventEmitter<number> = new EventEmitter<number>();
    formCreated: boolean;

    _savedNotes: INote[] = [];
    getEmptyNote(): INote {
        return { ...emptyNote };
    }

    ngOnInit(): void {
        this.getNotes();
        this.formCreated = true;
    }

    addEmptyInquiry(): void {
        this.saveEmptyNote();
    }

    saveEmptyNote(): void {
        this.service.saveNote(this.parentId, this.getEmptyNote()).subscribe((success) => {
            this.saved.emit();
        });
    }

    deleteThisNote(noteId: number): void {
        this.service.deleteNote(this.parentId, noteId).subscribe(
            (success) => {
                this.onNoteDeleted.emit();
                this.deleted.emit();
                this.NoteArray = this.NoteArray.filter((x) => x.Id !== noteId);
                this.getNotes();
            },
            (error) => this.onNoteDeleteFailed.emit(),
        );
    }

    refreshSavedNotes(notes: INote[]): void {
        this._savedNotes = [];
        for (let i = 0; i < notes.length; i++) {
            const note = this.getEmptyNote();
            note.Id = notes[i].Id;
            note.NoteText = notes[i].NoteText;
            this._savedNotes.push(note);
        }
    }

    getNotes(): void {
        if (!this.notesPassedIn && this.service != undefined) {
            const searchparamProperties: IEntitySearchParams = {
                order: 'Title',
                orderDirection: SortDirection.Asc,
                query: '',
                take: this.max,
            };

            const searchparams = new SearchParams(searchparamProperties);
            this.service.getNotes(this.parentId, searchparams).subscribe((response) => {
                const tempNotes = this.NoteArray;
                this.NoteArray = response.body;
                this.refreshSavedNotes(response.body);
                if (this.formCreated) {
                    this.NoteArray.forEach((note) =>
                        tempNotes[this.NoteArray.indexOf(note)] ? (note.NoteText = tempNotes[this.NoteArray.indexOf(note)].NoteText) : null,
                    );
                }
                this.total = +response.headers.get('X-List-Count');
                this.onGetNotes.emit();
            });
        }
    }
}
