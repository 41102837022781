import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IImedClaimServiceWitnessFee } from '../interfaces/imed-claim-service-witness-fee';
import { IUser } from '../interfaces/user';
import { IImedClaimService } from '../interfaces/imed-claim-service';
import { ITask } from '../interfaces/task';

export interface IImedClaimServiceWitnessFeeDynamicControlsParameters {
    formGroup?: string;
    imedClaimServices?: IImedClaimService[];
    createdBies?: IUser[];
    modifiedBies?: IUser[];
    physicalCheckTasks?: ITask[];
    physicalCheckForeignTasks?: ITask[];
}

export class ImedClaimServiceWitnessFeeDynamicControls {

    formGroup: string;
    imedClaimServices: IImedClaimService[];
    createdBies: IUser[];
    modifiedBies: IUser[];
    physicalCheckTasks: ITask[];
    physicalCheckForeignTasks: ITask[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private imedclaimservicewitnessfee?: IImedClaimServiceWitnessFee, additionalParameters?: IImedClaimServiceWitnessFeeDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'ImedClaimServiceWitnessFee';
        this.imedClaimServices = additionalParameters && additionalParameters.imedClaimServices || undefined;
        this.createdBies = additionalParameters && additionalParameters.createdBies || undefined;
        this.modifiedBies = additionalParameters && additionalParameters.modifiedBies || undefined;
        this.physicalCheckTasks = additionalParameters && additionalParameters.physicalCheckTasks || undefined;
        this.physicalCheckForeignTasks = additionalParameters && additionalParameters.physicalCheckForeignTasks || undefined;

        this.Form = {
            CreatedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Created By',
                name: 'CreatedById',
                options: this.createdBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.imedclaimservicewitnessfee && this.imedclaimservicewitnessfee.CreatedById || null,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservicewitnessfee && this.imedclaimservicewitnessfee.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservicewitnessfee && this.imedclaimservicewitnessfee.DateModified || null,
            }),
            Fees: new DynamicField({
                formGroup: this.formGroup,
                label: 'Fees',
                name: 'Fees',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservicewitnessfee && this.imedclaimservicewitnessfee.Fees || 0,
            }),
            ImedClaimServiceId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Imed Claim Service',
                name: 'ImedClaimServiceId',
                options: this.imedClaimServices,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.imedclaimservicewitnessfee && this.imedclaimservicewitnessfee.ImedClaimServiceId || null,
            }),
            ModifiedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Modified By',
                name: 'ModifiedById',
                options: this.modifiedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservicewitnessfee && this.imedclaimservicewitnessfee.ModifiedById || null,
            }),
            PhysicalCheckForeignTaskId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Physical Check Foreign Task',
                name: 'PhysicalCheckForeignTaskId',
                options: this.physicalCheckForeignTasks,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservicewitnessfee && this.imedclaimservicewitnessfee.PhysicalCheckForeignTaskId || null,
            }),
            PhysicalCheckTaskId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Physical Check Task',
                name: 'PhysicalCheckTaskId',
                options: this.physicalCheckTasks,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservicewitnessfee && this.imedclaimservicewitnessfee.PhysicalCheckTaskId || null,
            }),
            PhysicalCheckToFacilityRequired: new DynamicField({
                formGroup: this.formGroup,
                label: 'Physical Check To Facility Required',
                name: 'PhysicalCheckToFacilityRequired',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservicewitnessfee && this.imedclaimservicewitnessfee.hasOwnProperty('PhysicalCheckToFacilityRequired') && this.imedclaimservicewitnessfee.PhysicalCheckToFacilityRequired !== null ? this.imedclaimservicewitnessfee.PhysicalCheckToFacilityRequired : false,
            }),
            PhysicalCheckToForeignCourtRequired: new DynamicField({
                formGroup: this.formGroup,
                label: 'Physical Check To Foreign Court Required',
                name: 'PhysicalCheckToForeignCourtRequired',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservicewitnessfee && this.imedclaimservicewitnessfee.hasOwnProperty('PhysicalCheckToForeignCourtRequired') && this.imedclaimservicewitnessfee.PhysicalCheckToForeignCourtRequired !== null ? this.imedclaimservicewitnessfee.PhysicalCheckToForeignCourtRequired : false,
            }),
        };

        this.View = {
            CreatedById: new DynamicLabel({
			    label: 'Created By',
			    value: getMetaItemValue(this.createdBies as unknown as IMetaItem[], this.imedclaimservicewitnessfee && this.imedclaimservicewitnessfee.hasOwnProperty('CreatedById') && this.imedclaimservicewitnessfee.CreatedById !== null ? this.imedclaimservicewitnessfee.CreatedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            DateCreated: new DynamicLabel({
			    label: 'Date Created',
			    value: this.imedclaimservicewitnessfee && this.imedclaimservicewitnessfee.DateCreated || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DateModified: new DynamicLabel({
			    label: 'Date Modified',
			    value: this.imedclaimservicewitnessfee && this.imedclaimservicewitnessfee.DateModified || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            Fees: new DynamicLabel({
			    label: 'Fees',
			    value: this.imedclaimservicewitnessfee && this.imedclaimservicewitnessfee.Fees || 0,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: null,
			        scale: 2,
			    })
			}
            ),
            ImedClaimServiceId: new DynamicLabel({
			    label: 'Imed Claim Service',
			    value: getMetaItemValue(this.imedClaimServices as unknown as IMetaItem[], this.imedclaimservicewitnessfee && this.imedclaimservicewitnessfee.hasOwnProperty('ImedClaimServiceId') && this.imedclaimservicewitnessfee.ImedClaimServiceId !== null ? this.imedclaimservicewitnessfee.ImedClaimServiceId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ModifiedById: new DynamicLabel({
			    label: 'Modified By',
			    value: getMetaItemValue(this.modifiedBies as unknown as IMetaItem[], this.imedclaimservicewitnessfee && this.imedclaimservicewitnessfee.hasOwnProperty('ModifiedById') && this.imedclaimservicewitnessfee.ModifiedById !== null ? this.imedclaimservicewitnessfee.ModifiedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            PhysicalCheckForeignTaskId: new DynamicLabel({
			    label: 'Physical Check Foreign Task',
			    value: getMetaItemValue(this.physicalCheckForeignTasks as unknown as IMetaItem[], this.imedclaimservicewitnessfee && this.imedclaimservicewitnessfee.hasOwnProperty('PhysicalCheckForeignTaskId') && this.imedclaimservicewitnessfee.PhysicalCheckForeignTaskId !== null ? this.imedclaimservicewitnessfee.PhysicalCheckForeignTaskId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            PhysicalCheckTaskId: new DynamicLabel({
			    label: 'Physical Check Task',
			    value: getMetaItemValue(this.physicalCheckTasks as unknown as IMetaItem[], this.imedclaimservicewitnessfee && this.imedclaimservicewitnessfee.hasOwnProperty('PhysicalCheckTaskId') && this.imedclaimservicewitnessfee.PhysicalCheckTaskId !== null ? this.imedclaimservicewitnessfee.PhysicalCheckTaskId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            PhysicalCheckToFacilityRequired: new DynamicLabel({
			    label: 'Physical Check To Facility Required',
			    value: this.imedclaimservicewitnessfee && this.imedclaimservicewitnessfee.hasOwnProperty('PhysicalCheckToFacilityRequired') && this.imedclaimservicewitnessfee.PhysicalCheckToFacilityRequired !== null ? this.imedclaimservicewitnessfee.PhysicalCheckToFacilityRequired : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            PhysicalCheckToForeignCourtRequired: new DynamicLabel({
			    label: 'Physical Check To Foreign Court Required',
			    value: this.imedclaimservicewitnessfee && this.imedclaimservicewitnessfee.hasOwnProperty('PhysicalCheckToForeignCourtRequired') && this.imedclaimservicewitnessfee.PhysicalCheckToForeignCourtRequired !== null ? this.imedclaimservicewitnessfee.PhysicalCheckToForeignCourtRequired : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };

    }
}
