import { ConferenceCallServiceDynamicControlsPartial } from '@model/partials/conference-call-service.form-controls.partials';
import { IConferenceCallServiceDynamicControlsParameters } from '@model/form-controls/conference-call-service.form-controls';
import { IConferenceCallService } from '@model/interfaces/conference-call-service.d';
import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';

export class ConferenceCallServiceDynamicConfig<T extends IConferenceCallService> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private conferenceCallService: T, private configControls?: string[]) {
        super();
        const additionalParams: IConferenceCallServiceDynamicControlsParameters = {};

        const dynamicControls = new ConferenceCallServiceDynamicControlsPartial(this.conferenceCallService, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = [
                'Id',
                'ImedClaimServiceId',
                'DateTimeAgreedByPhysician',
                'CreatedById',
                'ModifiedById',
                'DateCreated',
                'DateModified',
                'Archived',
            ];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
