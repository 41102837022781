import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IService } from '@model/interfaces/service';
import { ClaimTypeEnums } from '../../common/constants/Enums';
import { IMasterFollowupType } from '@model/interfaces/master-followup-type';
import { MasterFollowupTypeDynamicControlsPartial } from '@model/partials/master-followup-type.form-controls.partial';
import { IFollowupType } from '@model/interfaces/followup-type';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'master-followup-type-form',
    styles: [
        `
            .form-select > dynamic-form-label {
                display: none;
            }
        `,
    ],
    templateUrl: './master-followup-type-form.component.html',
})
export class MasterFollowupTypeFormComponent implements OnInit {
    // abstract controls
    abstractMasterFollowupTypeControls: any;
    abstractMasterFollowupTypeServiceControls: any;

    @Input() masterFollowupType: IMasterFollowupType;
    @Input() Services: IService[];
    templateServices: IService[];
    selectedServiceIdsArray: number[] = [];

    @Output('formSubmit') formSubmit: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();
    @Output('attachedFks') attachedFks: EventEmitter<IMasterFollowupType> = new EventEmitter<IMasterFollowupType>();
    @Output('onCancelClick') onCancelClick: EventEmitter<void> = new EventEmitter<void>();

    masterFollowupTypeForm: UntypedFormGroup;
    doubleClickIsDisabled = false;
    showSelectStateDropdown = false;
    autoCaseTypeSelected = false;
    workersCompCaseTypeSelected = false;
    generalLiabilityCaseTypeSelected = false;
    showSelectServiceDropdown = false;
    formCreated = false;
    invalidCaseTypeSelection = false;
    invalidServiceTypeFollowupSelection = false;
    subscriptions$ = new Subscription();

    constructor(
        private fb: UntypedFormBuilder,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
    ) {}

    ngOnInit(): void {
        this.templateServices = this.Services;
        this.setServicesDropdownVisivility();
        this.createForm();
    }

    createForm(): void {
        this.getControls();
        this.masterFollowupTypeForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    getControls(): void {
        if (!this.formCreated) {
            if (!this.masterFollowupType.FollowupTypes) {
                this.masterFollowupType.FollowupTypes = [];
            } else {
                this.masterFollowupType.FollowupTypes.forEach((followUpType) => this.selectedServiceIdsArray.push(followUpType.ServiceId));
            }
        }
        this.abstractMasterFollowupTypeControls = new MasterFollowupTypeDynamicControlsPartial(this.masterFollowupType, {
            formGroup: 'MasterFollowupType',
            selectedServiceIdsArray: this.selectedServiceIdsArray,
            services: this.templateServices,
        }).Form;
    }

    assignFormGroups(): UntypedFormGroup {
        return this.fb.group({
            MasterFollowupType: this.fb.group({}),
            MasterFollowupTypeServices: this.fb.group({}),
        });
    }

    formSubmitted(): void {
        if (!this.autoCaseTypeSelected && !this.generalLiabilityCaseTypeSelected && !this.workersCompCaseTypeSelected) {
            this.invalidCaseTypeSelection = true;
        }
        this.setServiceFollowupStatus(this.masterFollowupType.IsCaseFollowup);

        if (this.masterFollowupTypeForm.valid && !this.invalidCaseTypeSelection && !this.invalidServiceTypeFollowupSelection) {
             this.masterFollowupType.FollowupTypes = [];
             if (this.masterFollowupTypeForm.value.MasterFollowupType.associatedServices) {
                this.masterFollowupTypeForm.value.MasterFollowupType.associatedServices.forEach((serviceId: number) => {
                    const followUpType = {} as IFollowupType;
                    followUpType.Name = this.masterFollowupTypeForm.value.MasterFollowupType.Name;
                    followUpType.ServiceId = serviceId;
                    followUpType.Service = this.Services.find((x) => x.Id === serviceId);
                    this.masterFollowupType.FollowupTypes.push(followUpType);
                });
            }
             this.attachedFks.emit(this.masterFollowupType);
             this.formSubmit.emit(this.masterFollowupTypeForm);
        } else {
            markAllFormFieldsAsTouched(this.masterFollowupTypeForm);
            this.error();
        }
    }

    cancelClick(): void {
        this.onCancelClick.emit();
    }

    error(): void {
        this.notificationsService.error('Save failed.  Please check the form and try again.');
    }

    success(): void {
        this.notificationsService.success('Dynamic Template saved successfully.');
    }

    showHideServiceSelectDropdown(): void {
        if (this.autoCaseTypeSelected || this.generalLiabilityCaseTypeSelected || this.workersCompCaseTypeSelected) {
            this.invalidCaseTypeSelection = false;
            this.showSelectServiceDropdown = false;
            setTimeout(() => {
                this.showSelectServiceDropdown = true;
            }, 0);
        } else {
            this.showSelectServiceDropdown = false;
        }
        this.filterServicesSelectOptions();
    }

    filterServicesSelectOptions(): void {
        this.templateServices = this.Services;
        if (!this.autoCaseTypeSelected) {
            this.templateServices = this.templateServices.filter((s) => s.ClaimTypeId !== +ClaimTypeEnums.Auto);
        }
        if (!this.generalLiabilityCaseTypeSelected) {
            this.templateServices = this.templateServices.filter((s) => s.ClaimTypeId !== +ClaimTypeEnums.GL);
        }
        if (!this.workersCompCaseTypeSelected) {
            this.templateServices = this.templateServices.filter((s) => s.ClaimTypeId !== +ClaimTypeEnums.WC);
        }
        const availableServiceIds = [];
        this.templateServices.forEach((templateService) => {
            availableServiceIds.push(templateService.Id);
        });
        if (availableServiceIds.length === 0) {
            this.selectedServiceIdsArray = [];
        }
        this.selectedServiceIdsArray.forEach((id) => {
            if (availableServiceIds.indexOf(id) === -1) {
                this.selectedServiceIdsArray = this.selectedServiceIdsArray.filter((x) => x !== id);
            }
        });
        this.getControls();
    }

    setServicesDropdownVisivility(): void {
        this.autoCaseTypeSelected = this.masterFollowupType.Auto;
        this.workersCompCaseTypeSelected = this.masterFollowupType.WorkersComp;
        this.generalLiabilityCaseTypeSelected = this.masterFollowupType.GeneralLiability;
        if (this.autoCaseTypeSelected || this.generalLiabilityCaseTypeSelected || this.workersCompCaseTypeSelected) {
            this.showSelectServiceDropdown = false;
            setTimeout(() => {
                this.showSelectServiceDropdown = true;
            }, 0);
        } else {
            this.showSelectServiceDropdown = false;
        }
        this.templateServices = this.Services;
        if (!this.autoCaseTypeSelected) {
            this.templateServices = this.templateServices.filter((s) => s.ClaimTypeId !== +ClaimTypeEnums.Auto);
        }
        if (!this.generalLiabilityCaseTypeSelected) {
            this.templateServices = this.templateServices.filter((s) => s.ClaimTypeId !== +ClaimTypeEnums.GL);
        }
        if (!this.workersCompCaseTypeSelected) {
            this.templateServices = this.templateServices.filter((s) => s.ClaimTypeId !== +ClaimTypeEnums.WC);
        }
    }

    checkGLCaseType(event: any): void {
        this.generalLiabilityCaseTypeSelected = event;
        this.showHideServiceSelectDropdown();
    }

    checkWCCaseType(event: any): void {
        this.workersCompCaseTypeSelected = event;
        this.showHideServiceSelectDropdown();
    }

    checkAutoCaseType(event: any): void {
        this.autoCaseTypeSelected = event;
        this.showHideServiceSelectDropdown();
    }

    setServiceFollowupStatus(event : boolean): void {
        this.masterFollowupType.IsCaseFollowup = event;
        if (!this.masterFollowupType.IsCaseFollowup) {
            const serviceIds : number[] = this.masterFollowupTypeForm.value.MasterFollowupType.associatedServices;
            this.invalidServiceTypeFollowupSelection = serviceIds.length <= 0 ? true : false;
        }
        else {
            this.invalidServiceTypeFollowupSelection = false;
        }
    }

    serviceSelectionChanged(event: any): void {
        this.selectedServiceIdsArray = event === null ? [] : event;
        this.getControls();
    }
}
