import { IBillDeskReviewService } from './../interfaces/bill-desk-review-service.d';
import {
    BillDeskReviewServiceDynamicControls,
    IBillDeskReviewServiceDynamicControlsParameters,
} from './../form-controls/bill-desk-review-service.form-controls';
import { DynamicField } from '@mt-ng2/dynamic-form';

export class BillDeskReviewServiceDynamicControlsPartial extends BillDeskReviewServiceDynamicControls {
    constructor(private billdeskreviewService?: IBillDeskReviewService, additionalParameters?: IBillDeskReviewServiceDynamicControlsParameters) {
        super(billdeskreviewService, additionalParameters);
        (<DynamicField>this.Form.RequestDate).value = new Date();
        (<DynamicField>this.Form.IsFederalCase).label = 'Federal Case';
        (<DynamicField>this.View.IsFederalCase).label = 'Federal Case';
    }
}
