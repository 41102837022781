import { Component, Input } from '@angular/core';
import { AddressBooksSelectService } from '../../common/address-book-select-list/address-books-select.service';
import { IAddressBookSelectEntity } from '../../common/address-book-select-list/address-books-select-entity';
import { IImedClaimTreatingPhysicianDTO } from '@model/interfaces/custom/imed-claim-treating-physician.dto';
import { ImedClaimTreatingPhysicianService } from '../imed-claim-treating-physicians/imed-claim-treating-physicians.service';

@Component({
    selector: 'app-imed-claim-treating-physician',
    templateUrl: './imed-claim-treating-physician.component.html',
})
export class ImedClaimTreatingPhysicianComponent {
    @Input() TreatingPhysician: IImedClaimTreatingPhysicianDTO;
    @Input() index: number;

    constructor(
        private addressSelectService: AddressBooksSelectService,
        private imedClaimTreatingPhysicianService: ImedClaimTreatingPhysicianService,
    ) {}

    ClearAddressBooks(): void {
        this.imedClaimTreatingPhysicianService.newTreatingPhysicians.splice(this.index, 1);
        if (this.imedClaimTreatingPhysicianService.newTreatingPhysicians.length === 0) {
            this.imedClaimTreatingPhysicianService.newTreatingPhysicians.push(
                this.imedClaimTreatingPhysicianService.getEmptyImedClaimTreatingPhysician(),
            );
        }
    }

    SearchAddressBooks(): void {
        this.addressSelectService.openAddressBookSelect('', this.select);
    }

    select = (addressBookSelect: IAddressBookSelectEntity): void => {
        this.TreatingPhysician.AddressBookId = addressBookSelect.AddressBookId;
        this.TreatingPhysician.IndividualId = addressBookSelect.IndividualId;
        this.TreatingPhysician.Name =
            addressBookSelect.IndividualName && addressBookSelect.IndividualName.length
                ? addressBookSelect.IndividualName
                : addressBookSelect.AddressBookName;
    }
}
