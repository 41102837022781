import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, IterableDiffer } from '@angular/core';
import { Router } from '@angular/router';
import { Chart } from 'chart.js';
import { DashboardService, ITrendingService } from '../dashboard.service';
import { SearchParams, IEntitySearchParams, ExtraSearchParams } from '@mt-ng2/common-classes';
import { CommonSearchService, ICommonSearch } from '../../common/services/common-search.service';
import { TrendingServiceChartEnums } from '../../common/constants/Enums';
import { IMetaItem, MetaItem } from '@mt-ng2/base-service';

@Component({
    selector: 'app-service-trend-widget',
    styleUrls: ['../base-dashboard-component/base-dashboard-common-css.component.less'],
    templateUrl: './service-trend-widget.component.html',
})
export class ServiceTrendWidgetComponent implements OnInit {
    @ViewChild('trendingServiceChart') serviceTrendChartRef: ElementRef;

    serviceTrendChart: any;
    trendingService: ITrendingService[];
    chart;

    serviceTrendTypes: IMetaItem[] = [
        new MetaItem(TrendingServiceChartEnums.IME, 'IME Services'),
        new MetaItem(TrendingServiceChartEnums.Court_Related_Service, 'CRS Services'),
        new MetaItem(TrendingServiceChartEnums.Record_Retrieval_HIPPA_Processing, 'Record Retrieval/HIPAA Processing'),
        new MetaItem(TrendingServiceChartEnums.Radiology_Review, 'Radiology Review'),
        new MetaItem(TrendingServiceChartEnums.Record_Review, 'Record Review'),
        new MetaItem(TrendingServiceChartEnums.Billing_Desk_Review, 'Billing Desk Review'),
    ];
    selectedServiceTrend: IMetaItem = this.serviceTrendTypes[0];

    constructor(private router: Router, private dashboardService: DashboardService, private commonSearchService: CommonSearchService) {}

    renderTrendingServiceChart(serviceTrendEnum: TrendingServiceChartEnums): void {
        this.dashboardService.getTrendingServices(serviceTrendEnum).subscribe((responses: ITrendingService[]) => {
            this.trendingService = responses;
            const labels = responses.map((response: ITrendingService) => response.Month);
            const data = responses.map((response: ITrendingService) => this.getTrendingServiceObject(response));

            if (this.serviceTrendChart) {
                this.serviceTrendChart.destroy();
            }

            this.serviceTrendChart = new Chart(this.serviceTrendChartRef.nativeElement, {
                data: {
                    datasets: [
                        {
                            backgroundColor: this.dashboardService.poolColors(labels.length),
                            data: data,
                            fillColor: this.dashboardService.poolColors(labels.length),
                            highlightFill: this.dashboardService.poolColors(labels.length),
                            highlightStroke: this.dashboardService.poolColors(labels.length),
                            label: 'Service Trends',
                            strokeColor: this.dashboardService.poolColors(labels.length),
                        },
                    ],
                    labels: labels,
                },
                options: {
                    legend: { display: false },
                    onHover: (e, el) => {
                        e.target.style.cursor = el[0] ? 'pointer' : 'default';
                    },
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                    precision: 0,
                                },
                            },
                        ],
                    },
                    title: {
                        display: true,
                        text: 'Service Trends',
                    },
                },
                type: 'bar',
            });
        });
    }

    getTrendingServiceObject(response: ITrendingService): any {
        return { y: response.Count, trendingService: response };
    }

    ngOnInit(): void {
        this.renderTrendingServiceChart(TrendingServiceChartEnums.IME);
    }

    updateChartOnSelection(): void {
        this.renderTrendingServiceChart(this.selectedServiceTrend.Id);
    }

    showServiceTrendData(evt: any): void {
        const data = this.serviceTrendChart.getElementsAtEvent(evt);

        if (data.length) {
            const firstPoint = data[0];
            const value = this.serviceTrendChart.data.datasets[firstPoint._datasetIndex].data[firstPoint._index];

            const _extraSearchParams: ExtraSearchParams[] = [];
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'serviceTrendType',
                    value: this.selectedServiceTrend.Id.toString(),
                }),
            );

            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'monthId',
                    value: value.trendingService.MonthId.toString(),
                }),
            );

            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'year',
                    value: value.trendingService.Year.toString(),
                }),
            );

            const searchEntity: IEntitySearchParams = {
                extraParams: _extraSearchParams,
                order: 'ServiceType',
                orderDirection: 'Asc',
                query: '',
            };

            const searchFilter: ICommonSearch = {
                dashboardName: 'Service Trend',
                searchFilters: new SearchParams(searchEntity),
            };

            this.commonSearchService.setCommonSearchFilters(searchFilter);
            void this.router.navigate(['/cases/0/services']);
        }
    }
}
