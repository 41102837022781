// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            .search-bar-container {
                padding-bottom: 5px;
            }
            .user-or-user-role {
                margin-left: 0;
                padding-bottom: 4px;
                border: 2px dashed rgb(204, 204, 204);
                border-radius: 10px;
            }
            .user-or-user-role .or-label {
                color: grey;
            }
            .notes-column {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 150px;
                max-width: 150px;
            }
            .task-action-button {
                padding: 2px 6px;
            }
            .task-action-button:hover {
                background-color: transparent;
                color: inherit;
            }
            .task-actions {
                width: 120px;
            }
            .back-button {
                padding-left: 4px;
                padding-right: 6px;
                padding-top: 3px;
                padding-bottom: 0;
                margin-top: -3px;
            }
            .btn-red {
                background: red;
            }
        `, "",{"version":3,"sources":["webpack://./src/app/tasks/task-list/tasks.component.ts"],"names":[],"mappings":";YACY;gBACI,mBAAmB;YACvB;YACA;gBACI,cAAc;gBACd,mBAAmB;gBACnB,qCAAqC;gBACrC,mBAAmB;YACvB;YACA;gBACI,WAAW;YACf;YACA;gBACI,gBAAgB;gBAChB,uBAAuB;gBACvB,mBAAmB;gBACnB,YAAY;gBACZ,gBAAgB;YACpB;YACA;gBACI,gBAAgB;YACpB;YACA;gBACI,6BAA6B;gBAC7B,cAAc;YAClB;YACA;gBACI,YAAY;YAChB;YACA;gBACI,iBAAiB;gBACjB,kBAAkB;gBAClB,gBAAgB;gBAChB,iBAAiB;gBACjB,gBAAgB;YACpB;YACA;gBACI,eAAe;YACnB","sourcesContent":["\n            .search-bar-container {\n                padding-bottom: 5px;\n            }\n            .user-or-user-role {\n                margin-left: 0;\n                padding-bottom: 4px;\n                border: 2px dashed rgb(204, 204, 204);\n                border-radius: 10px;\n            }\n            .user-or-user-role .or-label {\n                color: grey;\n            }\n            .notes-column {\n                overflow: hidden;\n                text-overflow: ellipsis;\n                white-space: nowrap;\n                width: 150px;\n                max-width: 150px;\n            }\n            .task-action-button {\n                padding: 2px 6px;\n            }\n            .task-action-button:hover {\n                background-color: transparent;\n                color: inherit;\n            }\n            .task-actions {\n                width: 120px;\n            }\n            .back-button {\n                padding-left: 4px;\n                padding-right: 6px;\n                padding-top: 3px;\n                padding-bottom: 0;\n                margin-top: -3px;\n            }\n            .btn-red {\n                background: red;\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
