import { Subscription, Observable } from 'rxjs';
import { Component, OnInit, OnDestroy, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import 'rxjs/operators';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues, AuthService } from '@mt-ng2/auth-module';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';

import { entityListModuleConfig } from '../../common/shared.module';
import { ClaimTypes } from '@model/ClaimTypes';
import { IAddressBook } from '@model/partials/address-book.partial';
import { CommonSearchService } from '../../common/services/common-search.service';
import { debounceTime, map } from 'rxjs/operators';
import { SpecialityDetailService } from '../../imed-managed-list/speciality-detail.service';
import { SpecialityService } from '../../imed-managed-list/speciality.service';
import {
    ISelectionChangedEvent as ITypeAheadSelectionChangedEvent,
    VirtualTypeAheadGetItemsFunction,
    ITypeAheadAPI,
} from '@mt-ng2/type-ahead-control';
import { MtSearchBarComponent } from '@mt-ng2/searchbar-control';
import { CommonService } from '../../common/services/common.service';
import { AddressService, IAddressTypeAheadDTO } from '../../address-books/addresses/address.service';
import { PhysicianStatusService } from '../../address-books/individuals/physicians/physicianstatus.service';
import { AddressBookTypeService } from '../../address-books/addressbooktype.service';
import { MetaAddressBookTypeService } from '../../address-books/metaaddressbooktype.service';
import { AddressBookService } from '../../address-books/addressbook.service';
import { AddressBookSelectionEnums, AddressBookTypeIdEnums } from '../constants/Enums';
import { IStatesService, IState, ICountriesService, ICountry } from '@mt-ng2/dynamic-form';
import { IName, INamedEntity } from '@mt-ng2/multiselect-control';

@Component({
    selector: 'address-book-select-filters',
    styles: [
        `
            entity-list {
                font-size: 12px;
            }
            .search-heading {
                color: #858a9f;
                font-size: 16px;
            }
        `,
    ],
    templateUrl: './address-book-select-filters.component.html',
})
export class AddressBookSelectFiltersComponent implements OnInit, OnDestroy {
    searchControl = new UntypedFormControl();
    addressBooks: IAddressBook[];
    currentPage = 1;
    query = '';
    total: number;
    metaAddressBookTypes: MtSearchFilterItem[] = [];
    addressBookTypes: MtSearchFilterItem[] = [];
    states: MtSearchFilterItem<IState>[] = [];
    physicianSpecialities: MtSearchFilterItem[] = [];
    physicianSpecialtyDetails: MtSearchFilterItem[] = [];
    physicianStatuses: MtSearchFilterItem[] = [];
    externalAccessStatuses: MtSearchFilterItem[] = [];
    canAddAddressBook = false;
    formCreated = false;
    order = 'FacilityAndName';
    subscription: Subscription = new Subscription();
    searchFormGroup: UntypedFormGroup;
    includedArchived: boolean = false;
    willingToTravel: boolean = false;
    checkForExternalAccess: boolean = false;
    phoneNo: string;
    email: string;
    getItems: VirtualTypeAheadGetItemsFunction = this.getAddresses.bind(this);
    selectedAddressBookId: number;
    virtualTypeAheadControl: ITypeAheadAPI;
    searchExpanded = false;
    county: string;

    // Variables for saved search component
    commonSearchParams: SearchParams;
    userId: number;
    currentSearch: SearchParams;
    searchChanged: boolean;

    componentLoaded: boolean;

    @ViewChild('searchBar') searchBar: MtSearchBarComponent;
    orderDirection: string;
    @Output() onSearchParamsUpdate: EventEmitter<SearchParams> = new EventEmitter<SearchParams>();
    @Input() typeFilter: string;

    constructor(
        private addressBookService: AddressBookService,
        private metaAddressBookTypesService: MetaAddressBookTypeService,
        private addressBookTypesService: AddressBookTypeService,
        private claimsService: ClaimsService,
        private authService: AuthService,
        private commonSearchService: CommonSearchService,
        private commonService: CommonService,
        private physicianStatusService: PhysicianStatusService,
        private physicianSpecialtyService: SpecialityService,
        private physicianSpecialtyDetailService: SpecialityDetailService,
        private addressService: AddressService,
    ) {}

    resetFilters(): void {
        this.searchBar.clearSearch();
        this.addressBookTypes.forEach((x) => (x.Selected = false));
        this.states.forEach((x) => (x.Selected = false));
        this.searchFormGroup.get('searchIncludeArchived').setValue(false);
        this.includedArchived = false;
        if (this.virtualTypeAheadControl) {
            this.clearTypeAhead();
        }
        this.searchFormGroup.get('countySearch').setValue(null);
        this.searchFormGroup.get('phoneNumberSearch').setValue(null);
        this.searchFormGroup.get('emailSearch').setValue(null);
        this.searchFormGroup.get('filterWillingToTravel').setValue(false);
        this.searchFormGroup.get('hasExternalAccess').setValue(false);
        this.physicianSpecialities.forEach((x) => (x.Selected = false));
        this.physicianSpecialtyDetails.forEach((x) => (x.Selected = false));
        this.physicianStatuses.forEach((x) => (x.Selected = false));
        this.searchControl.reset();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    subscribeToForm(): void {
        this.subscription.add(
            this.searchFormGroup.get('searchIncludeArchived').valueChanges.subscribe((value) => {
                this.includedArchived = value;
                this.clearCommonSearchParamsAndSearch();
            }),
        );

        this.subscription.add(
            this.searchFormGroup.get('filterWillingToTravel').valueChanges.subscribe((value) => {
                this.willingToTravel = value;
                this.clearCommonSearchParamsAndSearch();
            }),
        );

        this.subscription.add(
            this.searchFormGroup.get('hasExternalAccess').valueChanges.subscribe((value) => {
                this.checkForExternalAccess = value;
                this.clearCommonSearchParamsAndSearch();
            }),
        );

        this.subscription.add(
            this.searchFormGroup
                .get('phoneNumberSearch')
                .valueChanges.pipe(debounceTime(300))
                .subscribe((value) => {
                    if ((value && value.length >= 3) || !value) {
                        this.phoneNo = value;
                        this.clearCommonSearchParamsAndSearch();
                    }
                }),
        );

        this.subscription.add(
            this.searchFormGroup
                .get('countySearch')
                .valueChanges.pipe(debounceTime(300))
                .subscribe((value) => {
                    if ((value && value.length >= 3) || !value) {
                        this.county = value;
                        this.clearCommonSearchParamsAndSearch();
                    }
                }),
        );

        this.subscription.add(
            this.searchFormGroup
                .get('emailSearch')
                .valueChanges.pipe(debounceTime(300))
                .subscribe((value) => {
                    if ((value && value.length >= 3) || !value) {
                        this.email = value;
                        this.clearCommonSearchParamsAndSearch();
                    }
                }),
        );
    }

    ngOnInit(): void {
        this.searchFormGroup = new UntypedFormGroup({
            countySearch: new UntypedFormControl(''),
            emailSearch: new UntypedFormControl(''),
            filterWillingToTravel: new UntypedFormControl(false),
            hasExternalAccess: new UntypedFormControl(false),
            phoneNumberSearch: new UntypedFormControl(''),
            searchIncludeArchived: new UntypedFormControl(false),
        });
        this.canAddAddressBook = this.claimsService.hasClaim(ClaimTypes.AddressBooks, [ClaimValues.FullAccess]);
        this.updateSearchParams();

        this.userId = this.authService.currentUser.getValue().Id;

        this.commonService.getStates().subscribe((states) => (this.states = states.map((item) => new MtSearchFilterItem(item, false))));

        this.metaAddressBookTypesService
            .getAll()
            .subscribe((answer) => (this.metaAddressBookTypes = answer.map((item) => new MtSearchFilterItem(item, false))));

        this.physicianStatusService
            .getAll()
            .subscribe((answer) => (this.physicianStatuses = answer.map((item) => new MtSearchFilterItem(item, false))));

        this.physicianSpecialtyDetailService
            .getAll()
            .subscribe((answer) => (this.physicianSpecialtyDetails = answer.map((item) => new MtSearchFilterItem(item, false))));

        this.physicianSpecialtyService
            .getAll()
            .subscribe((answer) => (this.physicianSpecialities = answer.map((item) => new MtSearchFilterItem(item, false))));

        this.addressBookTypesService.getAll().subscribe({
            next: (answer) => {
                this.addressBookTypes = answer.map((item) => new MtSearchFilterItem(item, false));
                this.setConfig();
            },
        });

        this.subscribeToForm();
        this.formCreated = true;
        this.componentLoaded = true;
    }

    overrideDebounceTime(searchControl: UntypedFormControl): void {
        this.subscription.add(
            searchControl.valueChanges.pipe(debounceTime(600)).subscribe((value: string) => {
                this.search(value);
            }),
        );
    }

    getAddresses(): Observable<IAddressTypeAheadDTO[]> {
        return this.addressService.getAddressesForSearchFilter();
    }

    selectionChanged({ selection }: ITypeAheadSelectionChangedEvent): void {
        if (selection) {
            this.selectedAddressBookId = selection.AddressBookId;
            this.clearCommonSearchParamsAndSearch();
        }
    }

    virtualTypeAheadControlReady(controlApi: ITypeAheadAPI): void {
        this.virtualTypeAheadControl = controlApi;
    }

    clearTypeAhead(): void {
        this.virtualTypeAheadControl.clearValue();
        this.selectedAddressBookId = null;
        this.clearCommonSearchParamsAndSearch();
    }

    private getSelectedFilters<T extends INamedEntity>(filterObj: MtSearchFilterItem<T>[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    private buildSearch(): ExtraSearchParams[] {
        const selectedAddressBookTypesIds: number[] = this.getSelectedFilters(this.addressBookTypes);
        const selectedStateIds: string[] = this.states.filter((item) => item.Selected).map((item) => item.Item.StateCode);
        const selectedPhysicianSpecialities: number[] = this.getSelectedFilters(this.physicianSpecialities);
        const selectedPhysicianSpecialtyDetails: number[] = this.getSelectedFilters(this.physicianSpecialtyDetails);
        const selectedPhysicianStatuses: number[] = this.getSelectedFilters(this.physicianStatuses);
        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'AddressBookTypeIds',
                valueArray: selectedAddressBookTypesIds,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'StateIds',
                valueArray: selectedStateIds,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'AddressBookId',
                value: this.selectedAddressBookId ? this.selectedAddressBookId.toString() : '',
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'PhysicianStatusIds',
                valueArray: selectedPhysicianStatuses,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'PhysicianSpecialtyIds',
                valueArray: selectedPhysicianSpecialities,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'PhysicianSpecialtyDetailIds',
                valueArray: selectedPhysicianSpecialtyDetails,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'checkForExternalAccess',
                value: this.checkForExternalAccess ? '1' : '0',
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'includeArchived',
                value: this.includedArchived ? '1' : '0',
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'willingToTravel',
                value: this.willingToTravel ? '1' : '0',
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'phoneNo',
                value: this.phoneNo ? this.phoneNo : '',
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'email',
                value: this.email ? this.email : '',
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'county',
                value: this.county ? this.county : '',
            }),
        );

        return _extraSearchParams;
    }

    // Clear out common search params before getting next results
    clearCommonSearchParamsAndSearch(): void {
        this.commonSearchParams = null;
        this.searchChanged = !this.searchChanged;
        this.currentPage = 1;
        this.updateSearchParams();
    }

    updateSearchParams(skipPaging?: boolean): void {
        const search = this.commonSearchParams && this.commonSearchParams.query ? this.commonSearchParams.query : this.query;
        const _extraSearchParams: ExtraSearchParams[] =
            this.commonSearchParams && this.commonSearchParams.extraParams ? this.commonSearchParams.extraParams : this.buildSearch();

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.commonSearchParams && this.commonSearchParams.order ? this.commonSearchParams.order : this.order,
            orderDirection:
                this.commonSearchParams && this.commonSearchParams.orderDirection ? this.commonSearchParams.orderDirection : this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: skipPaging ? 0 : (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: skipPaging ? 0 : entityListModuleConfig.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);

        // store current search params in case user wants to saves this search
        this.currentSearch = searchparams;
        this.onSearchParamsUpdate.emit(searchparams);
    }

    search(query: string): void {
        this.query = query;
        this.clearCommonSearchParamsAndSearch();
    }

    searchBySavedSearch(): void {
        this.commonSearchService.getCommonSearchFilters().subscribe((filters) => {
            this.query = '';
            this.currentPage = 1;
            this.commonSearchParams = filters ? filters.searchFilters : null;
            this.updateSearchParams();
            this.commonSearchService.clearCommonSearchFilters();
        });
    }

    // Methods to pre-select filters
    setConfig(): void {
        this.resetPreselection();
        switch (this.typeFilter as AddressBookSelectionEnums) {
            case AddressBookSelectionEnums.Physician:
                this.preSelectPhysicianRelatedFilters();
                this.updateSearchParams();
                break;
            case AddressBookSelectionEnums.TranslationServicesVendor:
                this.preSelectVendorRelatedFilters();
                this.updateSearchParams();
                break;
            case AddressBookSelectionEnums.TransportationServicesVendor:
                this.preSelectVendorRelatedFilters();
                this.updateSearchParams();
                break;
            case AddressBookSelectionEnums.BackgroundInvestigationVendor:
                this.preSelectVendorRelatedFilters();
                this.updateSearchParams();
                break;
            case AddressBookSelectionEnums.ParaLegal:
                this.preSelectAttorneyRelatedFilters();
                this.updateSearchParams();
                break;
            case AddressBookSelectionEnums.DefenseAttorney:
                this.preSelectAttorneyRelatedFilters();
                this.updateSearchParams();
                break;
            case AddressBookSelectionEnums.PlantiffAttorney:
                this.preSelectAttorneyRelatedFilters();
                this.updateSearchParams();
                break;
            case AddressBookSelectionEnums.CarrierParty:
                this.preSelectAttorneyRelatedFilters();
                this.updateSearchParams();
                break;
            case AddressBookSelectionEnums.ProcessServer:
                this.preSelectProcessServerFilters();
                this.updateSearchParams();
                break;
            case AddressBookSelectionEnums.Adjuster:
                this.preSelectCarrierPartyFilters();
                this.updateSearchParams();
                break;
            case AddressBookSelectionEnums.AddressBook || AddressBookSelectionEnums.Individual:
                this.preSelectNotPhysicianRelatedFilters();
                this.updateSearchParams();
                break;
            case AddressBookSelectionEnums.ClaimantsAttorney:
                this.preSelectAttorneyRelatedFilters();
                this.updateSearchParams();
                break;
            case AddressBookSelectionEnums.CarriersCounsel:
                this.preSelectAttorneyRelatedFilters();
                this.updateSearchParams();
                break;
            case AddressBookSelectionEnums.Employer:
                this.preSelectEmployerFilters();
                this.updateSearchParams();
                break;
            case AddressBookSelectionEnums.Attorney:
                this.preSelectAttorneyFilters();
                this.updateSearchParams();
                break;
            case AddressBookSelectionEnums.TranscriptionServicesVendor:
                this.preSelectVendorRelatedFilters();
                this.updateSearchParams();
                break;
            case AddressBookSelectionEnums.RentalFacilityVendor:
                this.preSelectVendorRelatedFilters();
                this.updateSearchParams();
                break;
            default:
                this.updateSearchParams();
                break;
        }
    }

    preSelectAttorneyRelatedFilters(): void {
        this.addressBookTypes.map((item) => {
            if (item.Item.Id === AddressBookTypeIdEnums.Carrier as number || item.Item.Id === AddressBookTypeIdEnums.Attorney as number) {
                item.Selected = true;
            }
        });
    }

    preSelectProcessServerFilters(): void {
        this.addressBookTypes.map((item) => {
            if (item.Item.Id === AddressBookTypeIdEnums.Process_Server as number) {
                item.Selected = true;
            }
        });
    }

    preSelectCarrierPartyFilters(): void {
        this.addressBookTypes.map((item) => {
            if (item.Item.Id === AddressBookTypeIdEnums.Carrier as number) {
                item.Selected = true;
            }
        });
    }

    preSelectVendorNotRelatedFilters(): void {
        this.addressBookTypes.map((item) => {
            if (
                !(
                    item.Item.Id === AddressBookTypeIdEnums.Translation_Service as number ||
                    item.Item.Id === AddressBookTypeIdEnums.Transportation_Providers as number ||
                    item.Item.Id === AddressBookTypeIdEnums.Background_Investigators as number ||
                    item.Item.Id === AddressBookTypeIdEnums.Rental_Facility as number ||
                    item.Item.Id === AddressBookTypeIdEnums.Transcription_Service as number
                )
            ) {
                item.Selected = true;
            }
        });
    }

    preSelectVendorRelatedFilters(): void {
        this.addressBookTypes.map((item) => {
            if (
                item.Item.Id === (AddressBookTypeIdEnums.Translation_Service as number) ||
                item.Item.Id === (AddressBookTypeIdEnums.Transportation_Providers as number) ||
                item.Item.Id === (AddressBookTypeIdEnums.Background_Investigators as number) ||
                item.Item.Id === (AddressBookTypeIdEnums.Rental_Facility as number) ||
                item.Item.Id === (AddressBookTypeIdEnums.Transcription_Service as number)
            ) {
                item.Selected = true;
            }
        });
    }

    preSelectPhysicianRelatedFilters(): void {
        this.addressBookTypes.map((item) => {
            if (item.Item.Id === AddressBookTypeIdEnums.Expert_panel as number || item.Item.Id === AddressBookTypeIdEnums.Physician_panel as number) {
                item.Selected = true;
            }
        });
    }

    preSelectNotPhysicianRelatedFilters(): void {
        this.addressBookTypes.map((item) => {
            if (item.Item.Id !== AddressBookTypeIdEnums.Expert_panel as number && item.Item.Id !== AddressBookTypeIdEnums.Physician_panel as number) {
                item.Selected = true;
            }
        });
    }

    preSelectEmployerFilters(): void {
        this.addressBookTypes.map((item) => {
            if (item.Item.Id === AddressBookTypeIdEnums.Claimant_employers as number) {
                item.Selected = true;
            }
        });
    }

    preSelectAttorneyFilters(): void {
        this.addressBookTypes.map((item) => {
            if (item.Item.Id === AddressBookTypeIdEnums.Attorney as number) {
                item.Selected = true;
            }
        });
    }

    resetPreselection(): void {
        this.addressBookTypes.map((item) => (item.Selected = false));
    }
}
