import { Validators } from '@angular/forms';
import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    NumericInputTypes,
} from '@mt-ng2/dynamic-form';
import { IndividualDynamicControls, IIndividualDynamicControlsParameters } from '../form-controls/individual.form-controls';
import { IIndividual } from '../interfaces/individual';
import { CkEditorDynamicFieldComponent } from '../../common/ckeditor-dynamic-field/ckeditor-dynamic-field.component';
export class IndividualDynamicControlsPartial extends IndividualDynamicControls {
    constructor(individualPartial?: IIndividual, additionalParameters?: IIndividualDynamicControlsParameters) {
        super(individualPartial, additionalParameters);

        // Contract Rate From
        (<DynamicField>this.View.ContractRateFrom).label = 'Contract Rate';
        (<DynamicField>this.Form.ContractRateFrom).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Numeric,
            inputType: NumericInputTypes.Currency,
            scale: 2,
        });
        (<DynamicField>this.Form.ContractRateFrom).validation = [Validators.min(0.01), Validators.max(100000000000.0)];
        (<DynamicField>this.Form.ContractRateFrom).validators = { min: 0.01, max: 100000000000.0 };

        // Contract Rate To
        (<DynamicField>this.Form.ContractRateTo).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Numeric,
            inputType: NumericInputTypes.Currency,
            scale: 2,
        });
        (<DynamicField>this.Form.ContractRateTo).validation = [Validators.min(0.01), Validators.max(100000000000.0)];
        (<DynamicField>this.Form.ContractRateTo).validators = { min: 0.01, max: 100000000000.0 };

        (<DynamicField>this.Form.Wcb).labelHtml = '<label>WCB</label>';
        (<DynamicLabel>this.View.Wcb).label = 'WCB';

        (<DynamicField>this.Form.AttorneyWithoutWetSignature).labelHtml = '<label>Attorney without a wet signature?</label>';
        (<DynamicLabel>this.View.AttorneyWithoutWetSignature).label = 'Attorney without a wet signature?';

        (<DynamicField>this.Form.StrivenId).labelHtml = '<label>Striven ID: </label>';
        (<DynamicField>this.View.StrivenId).label = 'Striven ID';

        (<DynamicField>this.Form.Notes).component = CkEditorDynamicFieldComponent;
    }

    setRequired(isRequired: boolean): void {
        (<DynamicField>this.Form.FirstName).setRequired(isRequired);
        (<DynamicField>this.Form.LastName).setRequired(isRequired);
    }
}
