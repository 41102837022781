import { Subscription } from 'rxjs';
import { ImeServiceService } from '../../service-specifics/ime-service/imeservice.service';
import { ImedClaimServiceService } from '../../imedclaimservice.service';
import { ImedClaimService } from '../../../imedclaim.service';
import { ImeServiceDynamicConfig } from '../../service-specifics/ime-service/ime-service.dynamic-config';
import { IImeContactInfo } from '../../../imed-claim-basic-info/imed-claim-contacts-info';
import { IImeService } from '@model/interfaces/ime-service.d';
import { IImedClaimService } from '@model/interfaces/imed-claim-service.d';
import { AuthService } from '@mt-ng2/auth-module';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { ServiceIdEnums, EmailTemplateTypes, AdditionalServiceTypeEnums } from '../../../../common/constants/Enums';
import { IAddressBookSelectEntity } from '../../../../common/address-book-select-list/address-books-select-entity';
import { AddressBooksSelectService } from '../../../../common/address-book-select-list/address-books-select.service';
import { LanguageService } from '../../../../imed-managed-list/language.service';
import { ILanguage } from '@model/interfaces/language';
import { IImeAppointment } from '@model/interfaces/ime-appointment';
import { ImeServiceDynamicControlsPartial } from '@model/partials/ime-service-partial.form-controls';
import { EmailTemplateService } from '../../../../email-templates/emailtemplate.service';
import { CommonEmailComponent } from '../../../../common/common-email/common-email.component';
import { IEmailTemplate } from '@model/interfaces/email-template';
import { ExtraSearchParams, IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { EmailTemplateFilterBuilder } from '../../../../email-templates/email-template-filter-builder/email-template-filter-builder';
import { IViewGetServicesForList } from '@model/interfaces/custom/view-get-services-for-list';
import { AdditionalServicesSharedService } from '../../service-specifics/additional-services-shared.service';
import { ImedClaimServiceBillDocumentsUploadStatusDTO } from '@model/interfaces/imedclaimservice-bill-doc-upload-status';
import * as _ from 'lodash';

@Component({
    selector: 'app-ime-additional-service',
    styles: [
        `
            .additional-fields-group {
                padding: 0.5em;
                border: thin solid gainsboro;
                border-radius: 1%;
            }
            .badge {
                margin-bottom: 3px;
                background-color: #8ac175 !important;
            }
        `,
    ],
    templateUrl: './ime-additional-service.component.html',
})
export class ImeAdditionalServiceComponent implements OnInit {
    @Input() iImedClaimServiceModel: IImedClaimService;
    @Input() iImeServiceModel: IImeService;
    @Input() canEdit: boolean;
    @Input() showNF10: boolean;
    @Input() imeContactInfo: IImeContactInfo;
    @Input() existingAdditionalServices: IViewGetServicesForList[];
    @Output('onOpenServicesModal') onOpenServicesModal: EventEmitter<number> = new EventEmitter<number>();
    @Output('onAddNewService') onAddNewService: EventEmitter<number[]> = new EventEmitter<number[]>();
    @ViewChild('emailComponent') emailComponent: CommonEmailComponent;
    additionalServiceTypeEnums = AdditionalServiceTypeEnums;
    originalImeServiceModel: IImeService;

    _latestAppointment: IImeAppointment;

    get latestAppointment(): IImeAppointment {
        return this._latestAppointment;
    }

    get additionalRadiologyCount(): number {
        return this.additionalServicesSharedService.getAdditionalServicesCount(
            AdditionalServiceTypeEnums.RadiologyReview,
            this.existingAdditionalServices,
        );
    }

    get additionalAddendumCount(): number {
        return this.additionalServicesSharedService.getAdditionalServicesCount(AdditionalServiceTypeEnums.Addendum, this.existingAdditionalServices);
    }

    get additionalConferenceCallCount(): number {
        return this.additionalServicesSharedService.getAdditionalServicesCount(
            AdditionalServiceTypeEnums.ConferenceCall,
            this.existingAdditionalServices,
        );
    }

    get additionalNF10Count(): number {
        return this.additionalServicesSharedService.getAdditionalServicesCount(AdditionalServiceTypeEnums.NF10, this.existingAdditionalServices);
    }

    @Input() set latestAppointment(newAppointment: IImeAppointment) {
        this._latestAppointment = newAppointment;
        if (this.formRendered) {
            // need this check to prevent a timing issue of updating the form
            // before XHRs (language types) have come back
            this.setConfig();
        }
    }

    isEditing: boolean;
    isHovered: boolean;
    imeServiceForm: FormGroup;
    isFilmServiceCreated = false;
    isAddendumServiceCreated = false;
    isConferenceCallCreated = false;
    isNF10Created = false;

    imeFormFactory: ImeServiceDynamicConfig<IImeService>;

    // translationFormFactory: ImeServiceDynamicConfig<IImeService>;
    // backgroundFormFactory: ImeServiceDynamicConfig<IImeService>;
    // transportationFormFactory: ImeServiceDynamicConfig<IImeService>;

    doubleClickIsDisabled = false;
    imedClaimId: number;

    // translationFormConfig: any = {};
    // transportationFormConfig: any = {};
    // backgroundFormConfig: any = {};

    imeFormControls: any = {};
    imeFormControlsView: any = {};

    isFilmReviewSelected = false;
    isAddendumServiceSelected = false;
    isConferenceCallSelected = false;
    isNF10Selected = false;
    isTranslationServiceSelected = false;
    isTransportationServiceSelected = false;
    isBackgroundInvestigationSelected = false;
    isSurveillanceServiceSelected = false;
    isTranscriptionServiceSelected = false;
    isRentalFacilitySelected = false;
    languages: ILanguage[];
    formRendered = false;
    subscriptions = new Subscription();
    showEmailControl = false;
    emailTemplate: IEmailTemplate;
    billDocsUploadStatus: ImedClaimServiceBillDocumentsUploadStatusDTO;

    constructor(
        private imedClaimService: ImedClaimService,
        private imedClaimServiceService: ImedClaimServiceService,
        private imeServiceService: ImeServiceService,
        private notificationsService: NotificationsService,
        private activatedRoute: ActivatedRoute,
        private fb: UntypedFormBuilder,
        private authService: AuthService,
        private router: Router,
        private addressSelectService: AddressBooksSelectService,
        private languageService: LanguageService,
        private emailTemplateService: EmailTemplateService,
        private additionalServicesSharedService: AdditionalServicesSharedService,
    ) {}

    ngOnInit(): void {
        this.imedClaimId = this.activatedRoute.parent.parent.snapshot.params.imedClaimId;
        this.isEditing = false;
        this.originalImeServiceModel = _.cloneDeep(this.iImeServiceModel);
        this.subscriptions.add(
            this.imeServiceService.changeEmitted$.subscribe((iImeService) => {
                this.iImeServiceModel = iImeService;
                // TODO: does this need to get the language types as well?
                this.setConfig();
            }),
        );
        this.getLanguageTypes();
    }

    private getLanguageTypes(): void {
        this.languageService.getItems().subscribe((languages) => {
            this.languages = languages;
            this.setConfig();
        });
    }

    setConfig(): void {
        this.isFilmServiceCreated =
            this.iImeServiceModel &&
            this.existingAdditionalServices.filter(
                (x) =>
                    x.ServiceTypeId === +ServiceIdEnums.Film_Review ||
                    x.ServiceTypeId === +ServiceIdEnums.WC_Film_Review ||
                    x.ServiceTypeId === +ServiceIdEnums.Auto_Radiology_Review,
            ).length > 0;
        this.isAddendumServiceCreated =
            this.iImeServiceModel &&
            this.existingAdditionalServices.filter(
                (x) =>
                    x.ServiceTypeId === +ServiceIdEnums.IME_Addendum_Additional_Records ||
                    x.ServiceTypeId === +ServiceIdEnums.WC_Addendum ||
                    x.ServiceTypeId === +ServiceIdEnums.Auto_Addendum,
            ).length > 0;
        this.isConferenceCallCreated =
            this.iImeServiceModel &&
            this.existingAdditionalServices.filter(
                (x) =>
                    x.ServiceTypeId === +ServiceIdEnums.IME_Conference_Call ||
                    x.ServiceTypeId === +ServiceIdEnums.WC_Conference_Call ||
                    x.ServiceTypeId === +ServiceIdEnums.Auto_Conference_Call,
            ).length > 0;
        this.isNF10Created =
            this.iImeServiceModel && this.existingAdditionalServices.filter((x) => x.ServiceTypeId === +ServiceIdEnums.Auto_NF_10).length > 0;
        this.isTranslationServiceSelected = this.iImeServiceModel && this.iImeServiceModel.TranslationServicesRequested;
        this.isBackgroundInvestigationSelected = this.iImeServiceModel && this.iImeServiceModel.BackgroundInvestigationRequested;
        this.isTransportationServiceSelected = this.iImeServiceModel && this.iImeServiceModel.TransportationServicesRequested;
        this.isSurveillanceServiceSelected = this.iImeServiceModel && this.iImeServiceModel.Surveillance;
        this.isTranscriptionServiceSelected = this.iImeServiceModel && this.iImeServiceModel.TranscriptionServicesRequested;
        this.isRentalFacilitySelected = this.iImeServiceModel && this.iImeServiceModel.RentalFacilityRequested;

        this.imeServiceForm = this.fb.group({
            ImeService: this.fb.group({}),
        });

        this.imeFormFactory = new ImeServiceDynamicConfig<IImeService>(this.iImeServiceModel, [
            'TranslationServicesRequested',
            'TranslationServicesScheduledDate',
            'TranslationServicesNotes',
            'BackgroundInvestigationRequested',
            'BackgroundInvestigationScheduledDate',
            'BackgroundInvestigationNotes',
            'TransportationServicesRequested',
            'TransportationServicesScheduledDate',
            'TransportationServicesNotes',
            'Surveillance',
            'SurveillanceNumOperativesAuthorized',
            'SurveillanceNumHoursAuthorized',
            'SurveillanceScheduledDate',
            'LanguageTypeId',
        ]);

        const viewAndFormControl = new ImeServiceDynamicControlsPartial(
            this.iImeServiceModel,
            { languageTypes: this.languages },
            this.latestAppointment && this.latestAppointment.AppointmentDateTime,
        );

        this.imeFormControls = viewAndFormControl.Form;
        this.imeFormControlsView = viewAndFormControl.View;
        this.formRendered = true;
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        this.isEditing = false;
    }

    formSubmitted(): void {
        if (this.imeServiceForm.valid) {
            this.imeFormFactory.assignFormValues(this.iImeServiceModel, this.imeServiceForm.value.ImeService as IImeService);
            this.iImeServiceModel = this.imeServiceService.assignContactsToIme(this.iImeServiceModel, this.imeContactInfo);
            this.iImeServiceModel.ModifiedById = this.authService.currentUser.getValue().Id; // DO NOT Remove, will cause status logs to break.

            const newServiceEnumArray: number[] = [];
            if (this.isFilmReviewSelected && !this.isFilmServiceCreated) {
                newServiceEnumArray.push(AdditionalServiceTypeEnums.RadiologyReview);
            }
            if (this.isAddendumServiceSelected && !this.isAddendumServiceCreated) {
                newServiceEnumArray.push(AdditionalServiceTypeEnums.Addendum);
            }
            if (this.isConferenceCallSelected && !this.isConferenceCallCreated) {
                newServiceEnumArray.push(AdditionalServiceTypeEnums.ConferenceCall);
            }
            if (this.isNF10Selected && !this.isNF10Created) {
                newServiceEnumArray.push(AdditionalServiceTypeEnums.NF10);
            }
            if (
                !(this.isFilmReviewSelected && !this.isFilmServiceCreated) &&
                !(this.isAddendumServiceSelected && !this.isAddendumServiceCreated) &&
                !(this.isConferenceCallSelected && !this.isConferenceCallCreated) &&
                !(this.isNF10Selected && !this.isNF10Created)
            ) {
                this.imedClaimServiceService.checkBillDocumentsUploadStatus(this.iImedClaimServiceModel.Id).subscribe((billDocsUploadStatus) => {
                    this.billDocsUploadStatus = billDocsUploadStatus;
                    this.validateAndUpdateForm(this.iImeServiceModel);
                });
            } else {
                this.onAddNewService.emit(newServiceEnumArray);
            }
        } else {
            markAllFormFieldsAsTouched(this.imeServiceForm);
            this.error();
        }
    }

    resetDoubleClickIsDisabled(): void {
        this.doubleClickIsDisabled = false;
    }

    updateIMEService(): void {
        this.imeServiceService.update(this.iImeServiceModel).subscribe(() => {
            this.updateCorrespondingVendorTasks();
            this.isEditing = false;
            this.success();
            this.imeServiceService.emitChange(this.iImeServiceModel);
            this.setConfig();
        });
    }

    updateIMEServiceWhileEditing(): void {
        this.imeServiceService.update(this.iImeServiceModel).subscribe((success) => {
            this.imeServiceService.emitChange(this.iImeServiceModel);
            this.setConfig();
        });
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }

    SearchAddressBooks(addressBookType: string): void {
        this.addressSelectService.openAddressBookSelect(addressBookType, this.select); // note the bind(this)
    }
    select = (addressBookSelect: IAddressBookSelectEntity): void => {
        this.imeContactInfo[addressBookSelect.AddressBookType + 'Name'] = addressBookSelect.IndividualId
            ? addressBookSelect.IndividualName
            : addressBookSelect.AddressBookName;
        this.imeContactInfo[addressBookSelect.AddressBookType + 'IndividualId'] = addressBookSelect.IndividualId;
        this.imeContactInfo[addressBookSelect.AddressBookType + 'AddressBookId'] = addressBookSelect.AddressBookId;
    };

    openAdditionalServicesPopup(additionalServiceTypeId: number): void {
        this.onOpenServicesModal.emit(additionalServiceTypeId);
    }

    ClearAddressBooks(name: string): void {
        this.imeServiceService.unAssignContactFromIME(this.imeContactInfo, name);
    }

    translationServiceSelected(): void {
        this.isTranslationServiceSelected = !this.isTranslationServiceSelected;
    }

    transportationServiceSelected(): void {
        this.isTransportationServiceSelected = !this.isTransportationServiceSelected;
    }

    surveillanceServiceSelected(): void {
        this.isSurveillanceServiceSelected = !this.isSurveillanceServiceSelected;
    }

    backgroundInvestigationSelected(): void {
        this.isBackgroundInvestigationSelected = !this.isBackgroundInvestigationSelected;
    }

    transcriptionServiceSelected(): void {
        this.isTranscriptionServiceSelected = !this.isTranscriptionServiceSelected;
    }

    rentalFacilitySelected(): void {
        this.isRentalFacilitySelected = !this.isRentalFacilitySelected;
    }

    showEmailTemplatePrompt(event: Event): void {
        event.stopPropagation();
        this.showEmailControl = true;
        const _extraSearchParams: ExtraSearchParams[] = [];
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'includeArchived',
                value: '0',
            }),
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'emailTemplateTypeId',
                value: EmailTemplateTypes.ADDITIONAL_SERVICES.toString(),
            }),
        );
        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: 'Id',
            orderDirection: 'asc',
            query: '',
        };

        const searchparams = new SearchParams(searchEntity);
        this.emailTemplateService.search(searchparams).subscribe(({ body: templates }) => {
            this.emailComponent.imedClaimServiceId = this.iImedClaimServiceModel.Id;
            this.emailComponent.imedClaimId = this.iImedClaimServiceModel.ImedClaimId;
            this.emailComponent.emailTemplates = new EmailTemplateFilterBuilder(templates as IEmailTemplate[], this.iImeServiceModel)
                .filterBackgroundInvestigationEmail()
                .filterTranslationServicesEmail()
                .filterSurveillanceEmail()
                .returnFilteredTemplates();
            this.emailComponent.showEmailForm();
        });
    }

    get hasAdditionalService(): boolean {
        return (
            this.iImeServiceModel &&
            (this.iImeServiceModel.TransportationServicesRequested ||
                this.iImeServiceModel.TranslationServicesRequested ||
                this.iImeServiceModel.BackgroundInvestigationRequested ||
                this.iImeServiceModel.Surveillance ||
                this.iImeServiceModel.TranscriptionServicesRequested ||
                this.iImeServiceModel.RentalFacilityRequested)
        );
    }

    validateAndUpdateForm(imeService: IImeService): void {
        if (
            !this.validTranscriptionServicesRequestedAction(imeService) ||
            !this.validTranslationServicesRequestedAction(imeService) ||
            !this.validTransportationServicesRequestedAction(imeService) ||
            !this.validBackgroundInvestigationRequestedAction(imeService) ||
            !this.validSurveillanceAction(imeService) ||
            !this.validRentalFacilityAction(imeService)
        ) {
            this.uploadBillError();
        } else {
            this.updateIMEService();
        }
    }

    updateCorrespondingVendorTasks(): void {
        this.imeServiceService.updateCorrespondingVendorTasks(this.iImeServiceModel, this.originalImeServiceModel).subscribe(() => {
            //
        });
    }

    validTranscriptionServicesRequestedAction(imeService: IImeService): boolean {
        if (!this.billDocsUploadStatus.HasTranscriptionBill) return true;
        return imeService.TranscriptionServicesRequested && imeService.TranscriptionServicesVendorAddressBookId != null;
    }

    validTranslationServicesRequestedAction(imeService: IImeService): boolean {
        if (!this.billDocsUploadStatus.HasTranslationBill) return true;
        return imeService.TranslationServicesRequested && imeService.TranslationServicesVendorAddressBookId != null;
    }

    validTransportationServicesRequestedAction(imeService: IImeService): boolean {
        if (!this.billDocsUploadStatus.HasTransportationBill) return true;
        return imeService.TransportationServicesRequested && imeService.TransportationServicesVendorAddressBookId != null;
    }

    validBackgroundInvestigationRequestedAction(imeService: IImeService): boolean {
        if (!this.billDocsUploadStatus.HasBackgroundInvestigationORSurveillanceBill) return true;
        return imeService.BackgroundInvestigationRequested && imeService.BackgroundInvestigationVendorAddressBookId != null;
    }

    validSurveillanceAction(imeService: IImeService): boolean {
        if (!this.billDocsUploadStatus.HasBackgroundInvestigationORSurveillanceBill) return true;
        return imeService.Surveillance && imeService.SurveillanceVendorAddressBookId != null;
    }

    validRentalFacilityAction(imeService: IImeService): boolean {
        if (!this.billDocsUploadStatus.HasRentalFacilityBill) return true;
        return imeService.RentalFacilityRequested && imeService.RentalFacilityVendorAddressBookId != null;
    }

    uploadBillError(): void {
        this.notificationsService.error('The action could not be completed. Please ensure to remove the corresponding bill document first.');
    }
}
