import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IFilmInformation } from '../interfaces/film-information';
import { IBodyPart } from '../interfaces/body-part';
import { IUser } from '../interfaces/user';
import { IImageType } from '../interfaces/image-type';
import { IImedClaimService } from '../interfaces/imed-claim-service';

export interface IFilmInformationDynamicControlsParameters {
    formGroup?: string;
    imedClaimServices?: IImedClaimService[];
    imageTypes?: IImageType[];
    bodyPartImageds?: IBodyPart[];
    createdBies?: IUser[];
    modifiedBies?: IUser[];
}

export class FilmInformationDynamicControls {

    formGroup: string;
    imedClaimServices: IImedClaimService[];
    imageTypes: IImageType[];
    bodyPartImageds: IBodyPart[];
    createdBies: IUser[];
    modifiedBies: IUser[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private filminformation?: IFilmInformation, additionalParameters?: IFilmInformationDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'FilmInformation';
        this.imedClaimServices = additionalParameters && additionalParameters.imedClaimServices || undefined;
        this.imageTypes = additionalParameters && additionalParameters.imageTypes || undefined;
        this.bodyPartImageds = additionalParameters && additionalParameters.bodyPartImageds || undefined;
        this.createdBies = additionalParameters && additionalParameters.createdBies || undefined;
        this.modifiedBies = additionalParameters && additionalParameters.modifiedBies || undefined;

        this.Form = {
            Archived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived',
                name: 'Archived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.filminformation && this.filminformation.hasOwnProperty('Archived') && this.filminformation.Archived !== null ? this.filminformation.Archived : false,
            }),
            BodyPartImagedId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Body Part Imaged',
                name: 'BodyPartImagedId',
                options: this.bodyPartImageds,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.filminformation && this.filminformation.BodyPartImagedId || null,
            }),
            CreatedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Created By',
                name: 'CreatedById',
                options: this.createdBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.filminformation && this.filminformation.CreatedById || null,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.filminformation && this.filminformation.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.filminformation && this.filminformation.DateModified || null,
            }),
            DateProcessed: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Processed',
                name: 'DateProcessed',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.filminformation && this.filminformation.DateProcessed || null,
            }),
            DeleteRequested: new DynamicField({
                formGroup: this.formGroup,
                label: 'Delete Requested',
                name: 'DeleteRequested',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.filminformation && this.filminformation.hasOwnProperty('DeleteRequested') && this.filminformation.DeleteRequested !== null ? this.filminformation.DeleteRequested : false,
            }),
            FacilityName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Facility Name',
                name: 'FacilityName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.filminformation && this.filminformation.hasOwnProperty('FacilityName') && this.filminformation.FacilityName !== null ? this.filminformation.FacilityName.toString() : '',
            }),
            HistoricalBodyPartImaged: new DynamicField({
                formGroup: this.formGroup,
                label: 'Historical Body Part Imaged',
                name: 'HistoricalBodyPartImaged',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(250) ],
                validators: { 'maxlength': 250 },
                value: this.filminformation && this.filminformation.hasOwnProperty('HistoricalBodyPartImaged') && this.filminformation.HistoricalBodyPartImaged !== null ? this.filminformation.HistoricalBodyPartImaged.toString() : '',
            }),
            HistoricalImageType: new DynamicField({
                formGroup: this.formGroup,
                label: 'Historical Image Type',
                name: 'HistoricalImageType',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(250) ],
                validators: { 'maxlength': 250 },
                value: this.filminformation && this.filminformation.hasOwnProperty('HistoricalImageType') && this.filminformation.HistoricalImageType !== null ? this.filminformation.HistoricalImageType.toString() : '',
            }),
            ImageDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Image Date',
                name: 'ImageDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.filminformation && this.filminformation.ImageDate || null,
            }),
            ImageTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Image Type',
                name: 'ImageTypeId',
                options: this.imageTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.filminformation && this.filminformation.ImageTypeId || null,
            }),
            ImedClaimServiceId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Imed Claim Service',
                name: 'ImedClaimServiceId',
                options: this.imedClaimServices,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.filminformation && this.filminformation.ImedClaimServiceId || null,
            }),
            LegacyFilmInformationId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Legacy Film Information',
                name: 'LegacyFilmInformationId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(10) ],
                validators: { 'maxlength': 10 },
                value: this.filminformation && this.filminformation.hasOwnProperty('LegacyFilmInformationId') && this.filminformation.LegacyFilmInformationId !== null ? this.filminformation.LegacyFilmInformationId.toString() : '',
            }),
            ModifiedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Modified By',
                name: 'ModifiedById',
                options: this.modifiedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.filminformation && this.filminformation.ModifiedById || null,
            }),
            StudyUuid: new DynamicField({
                formGroup: this.formGroup,
                label: 'Study Uu',
                name: 'StudyUuid',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.filminformation && this.filminformation.hasOwnProperty('StudyUuid') && this.filminformation.StudyUuid !== null ? this.filminformation.StudyUuid.toString() : '',
            }),
        };

        this.View = {
            Archived: new DynamicLabel({
			    label: 'Archived',
			    value: this.filminformation && this.filminformation.hasOwnProperty('Archived') && this.filminformation.Archived !== null ? this.filminformation.Archived : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            BodyPartImagedId: new DynamicLabel({
			    label: 'Body Part Imaged',
			    value: getMetaItemValue(this.bodyPartImageds as unknown as IMetaItem[], this.filminformation && this.filminformation.hasOwnProperty('BodyPartImagedId') && this.filminformation.BodyPartImagedId !== null ? this.filminformation.BodyPartImagedId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            CreatedById: new DynamicLabel({
			    label: 'Created By',
			    value: getMetaItemValue(this.createdBies as unknown as IMetaItem[], this.filminformation && this.filminformation.hasOwnProperty('CreatedById') && this.filminformation.CreatedById !== null ? this.filminformation.CreatedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            DateCreated: new DynamicLabel({
			    label: 'Date Created',
			    value: this.filminformation && this.filminformation.DateCreated || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DateModified: new DynamicLabel({
			    label: 'Date Modified',
			    value: this.filminformation && this.filminformation.DateModified || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DateProcessed: new DynamicLabel({
			    label: 'Date Processed',
			    value: this.filminformation && this.filminformation.DateProcessed || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DeleteRequested: new DynamicLabel({
			    label: 'Delete Requested',
			    value: this.filminformation && this.filminformation.hasOwnProperty('DeleteRequested') && this.filminformation.DeleteRequested !== null ? this.filminformation.DeleteRequested : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            FacilityName: new DynamicLabel({
			    label: 'Facility Name',
			    value: this.filminformation && this.filminformation.hasOwnProperty('FacilityName') && this.filminformation.FacilityName !== null ? this.filminformation.FacilityName.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            HistoricalBodyPartImaged: new DynamicLabel({
			    label: 'Historical Body Part Imaged',
			    value: this.filminformation && this.filminformation.hasOwnProperty('HistoricalBodyPartImaged') && this.filminformation.HistoricalBodyPartImaged !== null ? this.filminformation.HistoricalBodyPartImaged.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            HistoricalImageType: new DynamicLabel({
			    label: 'Historical Image Type',
			    value: this.filminformation && this.filminformation.hasOwnProperty('HistoricalImageType') && this.filminformation.HistoricalImageType !== null ? this.filminformation.HistoricalImageType.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ImageDate: new DynamicLabel({
			    label: 'Image Date',
			    value: this.filminformation && this.filminformation.ImageDate || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            ImageTypeId: new DynamicLabel({
			    label: 'Image Type',
			    value: getMetaItemValue(this.imageTypes as unknown as IMetaItem[], this.filminformation && this.filminformation.hasOwnProperty('ImageTypeId') && this.filminformation.ImageTypeId !== null ? this.filminformation.ImageTypeId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ImedClaimServiceId: new DynamicLabel({
			    label: 'Imed Claim Service',
			    value: getMetaItemValue(this.imedClaimServices as unknown as IMetaItem[], this.filminformation && this.filminformation.hasOwnProperty('ImedClaimServiceId') && this.filminformation.ImedClaimServiceId !== null ? this.filminformation.ImedClaimServiceId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            LegacyFilmInformationId: new DynamicLabel({
			    label: 'Legacy Film Information',
			    value: this.filminformation && this.filminformation.hasOwnProperty('LegacyFilmInformationId') && this.filminformation.LegacyFilmInformationId !== null ? this.filminformation.LegacyFilmInformationId.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ModifiedById: new DynamicLabel({
			    label: 'Modified By',
			    value: getMetaItemValue(this.modifiedBies as unknown as IMetaItem[], this.filminformation && this.filminformation.hasOwnProperty('ModifiedById') && this.filminformation.ModifiedById !== null ? this.filminformation.ModifiedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            StudyUuid: new DynamicLabel({
			    label: 'Study Uu',
			    value: this.filminformation && this.filminformation.hasOwnProperty('StudyUuid') && this.filminformation.StudyUuid !== null ? this.filminformation.StudyUuid.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };

    }
}
