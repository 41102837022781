import {
    Component,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    SimpleChanges,
    ChangeDetectorRef,
    OnChanges,
    OnDestroy,
} from '@angular/core';
import { IMergeField } from '@model/interfaces/merge-field';

@Component({
    selector: 'ck-editor-with-merge-fields-component',
    styles: [
        `
            .btn-clear {
                position: absolute;
                top: 51px;
                right: 25px;
            }
            .invalid {
                border: 1px solid red;
            }
        `,
    ],
    templateUrl: './ck-editor-with-merge-fields.component.html',
})
export class CkEditorWithMergeFieldsComponent implements OnChanges, OnDestroy {
    @Input() mergeFields: IMergeField[] = [];
    @Input() valid = true;
    @Input() copiedTaskDescription;
    @Output() onDescriptionChanged: EventEmitter<string> = new EventEmitter();
    @ViewChild('editor') editor;
    private _text = '';
    set text(val: string) {
        this._text = val;
    }
    get text(): string {
        return this._text;
    }
    isInvalidDescription = false;

    constructor(private cdr: ChangeDetectorRef) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.copiedTaskDescription && changes.copiedTaskDescription.currentValue) {
            this.text = changes.copiedTaskDescription.currentValue;
        }
        if (changes.valid && !changes.valid.firstChange) {
            this.isInvalidDescription = !changes.valid.currentValue;
        }
    }

    ngOnDestroy(): void {
        this.cdr.detach();
    }

    appendMergeField(field: string): void {
        this.editor.instance.insertText(field);
    }

    clearEditor(): void {
        this.editor.instance.setData('');
    }

    onBlur(event: string): void {
        this.onDescriptionChanged.emit(event);
    }
}
