import { IImedClaim } from '@model/interfaces/imed-claim';
import { IImedClaimPartial } from '@model/partials/imed-claim-partial';
import { EntityListConfig, EntityListColumn, IEntityListConfig, IEntityListExportConfig } from '@mt-ng2/entity-list-module';
import { RequestorTypeEnums } from '../../common/constants/Enums';
import { ImedClaimDeleteDynamicCellComponent } from './imed-claims-delete-dynamic-cell.component';
export class ImedClaimsEntityListExportConfig extends EntityListConfig {
    export: IEntityListExportConfig = {
        exportName: 'Claims List',
    };
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['Id'],
                    name: 'Id',
                }),
                new EntityListColumn({
                    accessorFunction: (claim: IImedClaimPartial): string => `${claim.FirstName} ${claim.LastName}`,
                    name: 'Claimant Name',
                }),
                new EntityListColumn({
                    accessorFunction: (entity: IImedClaimPartial) => entity.ClaimNumber || ' ',
                    name: 'Claim Number',
                }),
                new EntityListColumn({
                    accessorFunction: (entity: IImedClaimPartial) => entity.FileNumber || ' ',
                    name: 'File Number',
                }),
                new EntityListColumn({
                    accessorFunction: (claim: IImedClaimPartial) => {
                        return claim.ParaLegalAddressBook && claim.ParaLegalAddressBook.FacilityName !== ''
                            ? claim.ParaLegalAddressBook.FacilityName
                            : claim.ParaLegalIndividual
                            ? claim.ParaLegalIndividual.FirstName + ' ' + claim.ParaLegalIndividual.LastName
                            : ' ';
                    },
                    name: 'Paralegal Firm',
                }),
                new EntityListColumn({
                    accessorFunction: (claim: IImedClaimPartial) => {
                        return claim.DefenseAttorneyAddressBook && claim.DefenseAttorneyAddressBook.FacilityName !== ''
                            ? claim.DefenseAttorneyAddressBook.FacilityName
                            : claim.DefenseAttorneyIndividual
                            ? claim.DefenseAttorneyIndividual.FirstName + ' ' + claim.DefenseAttorneyIndividual.LastName
                            : ' ';
                    },
                    name: 'Defense Attorney Firm',
                }),
                new EntityListColumn({
                    accessorFunction: (claim: IImedClaim) =>
                        claim.PlantiffAttorneyAddressBook && claim.PlantiffAttorneyAddressBook.FacilityName !== ''
                            ? claim.PlantiffAttorneyAddressBook.FacilityName
                            : claim.PlantiffAttorneyIndividual
                            ? claim.PlantiffAttorneyIndividual.FirstName + ' ' + claim.PlantiffAttorneyIndividual.LastName
                            : ' ',
                    name: 'Plaintiff Attorney Firm',
                }),
                new EntityListColumn({
                    accessorFunction: (claim: IImedClaimPartial) => {
                        return claim.CarrierPartyAddressBook && claim.CarrierPartyAddressBook.FacilityName !== ''
                            ? claim.CarrierPartyAddressBook.FacilityName
                            : claim.CarrierPartyIndividual
                            ? claim.CarrierPartyIndividual.FirstName + ' ' + claim.CarrierPartyIndividual.LastName
                            : ' ';
                    },
                    name: 'Carrier/Bill-To Party Firm',
                }),
                new EntityListColumn({
                    accessorFunction: (claim: IImedClaimPartial) => claim.ImedClaimStatus.Name,
                    name: 'Status',
                }),
                new EntityListColumn({
                    accessors: ['DateCreated'],
                    name: 'Date Created',
                    pipes: ['date:medium'],
                }),
                new EntityListColumn({
                    accessorFunction: (claim: IImedClaimPartial) => claim.RequestorType?.Name ?? ' ',
                    name: 'Requestor Type',
                }),
                new EntityListColumn({
                    accessorFunction: (claim: IImedClaimPartial) => {
                        switch (claim.RequestorTypeId) {
                            case +RequestorTypeEnums.Carrier:
                                return claim.CarrierPartyIndividual
                                    ? claim.CarrierPartyIndividual.FirstName + ' ' + claim.CarrierPartyIndividual.LastName
                                    : claim.CarrierPartyAddressBook && claim.CarrierPartyAddressBook.FacilityName !== ''
                                    ? claim.CarrierPartyAddressBook.FacilityName
                                    : ' ';

                            case +RequestorTypeEnums.Defense_Attorney:

                                return claim.DefenseAttorneyIndividual
                                    ? claim.DefenseAttorneyIndividual.FirstName + ' ' + claim.DefenseAttorneyIndividual.LastName
                                    : claim.DefenseAttorneyAddressBook && claim.DefenseAttorneyAddressBook.FacilityName !== ''
                                    ? claim.DefenseAttorneyAddressBook.FacilityName
                                    : ' ';

                            case +RequestorTypeEnums.Paralegal:
                                return claim.ParaLegalIndividual
                                    ? claim.ParaLegalIndividual.FirstName + ' ' + claim.ParaLegalIndividual.LastName
                                    : claim.ParaLegalAddressBook && claim.ParaLegalAddressBook.FacilityName !== ''
                                    ? claim.ParaLegalAddressBook.FacilityName
                                    : ' ';
                            case +RequestorTypeEnums.Claimant:
                                return `${claim.FirstName} ${claim.LastName}`;
                            case +RequestorTypeEnums.Employer:
                                return claim.EmployerAddressBook?.FacilityName ?? ' ';
                            default:
                                return ' ';
                        }
                    },
                    name: 'Requestor',
                }),
            ],
        };
        super(listConfig);
    }
}
