import { forkJoin, Observable, Subscription } from 'rxjs';
import { IImeService } from '@model/interfaces/ime-service.d';
import { ImedClaimServiceService } from '../../../imedclaimservice.service';
import { ImedClaimServiceDynamicConfig } from '../../../imed-claim-service.dynamic-config';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IImedClaimService } from '@model/interfaces/imed-claim-service';
import { IServicePayload } from '@model/interfaces/custom/update-service-payload';
import { finalize, tap } from 'rxjs/operators';
import { ImeServiceService } from '../../ime-service/imeservice.service';
import { IImedClaimServiceExamRequestorType } from '@model/interfaces/imed-claim-service-exam-requestor-type';
import { IEligibilityCriteria } from '@model/interfaces/eligibility-criteria';
import { EligibilityCriteriaService } from '../eligibility-criteria.service';
import { ImedClaimServiceExamRequestorTypeService } from '../imed-claim-exam-requestor-type.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DynamicField, DynamicLabel, IDynamicField, IDynamicLabel } from '@mt-ng2/dynamic-form';

@Component({
    selector: 'app-wc-fce',
    templateUrl: './wc-fce.component.html',
})
export class WcFCE implements OnInit, OnDestroy {
    @Input() iImedClaimServiceModel: IImedClaimService;
    @Input() canEdit: boolean;

    isEditing: boolean;
    isHovered: boolean;
    config: any = {};
    formFactory: ImedClaimServiceDynamicConfig<IImedClaimService>;
    doubleClickIsDisabled = false;
    subscriptions = new Subscription();
    eligibilityCriteria: IEligibilityCriteria[];
    imedClaimServiceExamRequestorTypes: IImedClaimServiceExamRequestorType[];
    configControls: string[];
    formRendered = false;
    form: any;
    eligibilityCriteriaId: number;
    imedClaimServiceExamRequestorTypeId: number;
    MMI: Date;

    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    constructor(
        private imedClaimServiceService: ImedClaimServiceService,
        private notificationsService: NotificationsService,
        private imeServiceService: ImeServiceService,
        private eligibilityCriteriaService: EligibilityCriteriaService,
        private imedClaimServiceExamRequestorTypeService: ImedClaimServiceExamRequestorTypeService,
        private fb: UntypedFormBuilder,
    ) {
        this.configControls = ['Mmi', 'EligibilityCriteriaId', 'ExamRequestedById'];
    }

    ngOnInit(): void {
        this.config = { formObject: [], viewOnly: [] };
        this.form = this.fb.group({});
        forkJoin(this.getImedClaimServiceExamRequestorTypes(), this.getEligibilityCriteria()).subscribe(() => this.setConfig());
    }

    setConfig(): void {
        this.formFactory = new ImedClaimServiceDynamicConfig<IImedClaimService>(
            this.iImedClaimServiceModel,
            null,
            null,
            this.imedClaimServiceExamRequestorTypes,
            this.eligibilityCriteria,
            this.configControls,
        );
        this.config = this.formFactory.getForUpdate();
        this.formRendered = true;

        this.viewOnly = this.config?.viewOnly?.map((x: IDynamicLabel) => new DynamicLabel(x));
        this.formObject = this.config.formObject?.map((x: IDynamicField) => new DynamicField(x));
    }

    getImedClaimServiceExamRequestorTypes(): Observable<IImedClaimServiceExamRequestorType[]> {
        return this.imedClaimServiceExamRequestorTypeService.getAll().pipe(tap((answer) => (this.imedClaimServiceExamRequestorTypes = answer)));
    }

    getEligibilityCriteria(): Observable<IEligibilityCriteria[]> {
        return this.eligibilityCriteriaService.getAll().pipe(tap((answer) => (this.eligibilityCriteria = answer)));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    formCreated(formGroup: UntypedFormGroup): void {
        formGroup.get('ImedClaimService.EligibilityCriteriaId').valueChanges.subscribe((value) => {
            this.eligibilityCriteriaId = value;
        });

        formGroup.get('ImedClaimService.ExamRequestedById').valueChanges.subscribe((value) => {
            this.imedClaimServiceExamRequestorTypeId = value;
        });

        formGroup.get('ImedClaimService.Mmi').valueChanges.subscribe((value) => {
            this.MMI = value;
        });
    }

    cancelClick(): void {
        this.isEditing = false;
    }

    formSubmitted(form: any): void {
        this.iImedClaimServiceModel.EligibilityCriteriaId = this.eligibilityCriteriaId ? this.eligibilityCriteriaId : null;
        this.iImedClaimServiceModel.ExamRequestedById = this.imedClaimServiceExamRequestorTypeId ? this.imedClaimServiceExamRequestorTypeId : null;
        this.iImedClaimServiceModel.Mmi = this.MMI ? this.MMI : null;
        const updatePayload = <IServicePayload<IImeService>>{};
        updatePayload.ParentService = { ...this.iImedClaimServiceModel };
        this.imeServiceService
            .updateService(this.iImedClaimServiceModel.Id, updatePayload)            .subscribe((success) => {
                this.imedClaimServiceService.getById(this.iImedClaimServiceModel.Id).subscribe((response) => {
                    this.isEditing = false;
                    this.success();
                    this.iImedClaimServiceModel = response;
                    this.imedClaimServiceService.emitChange(this.iImedClaimServiceModel);
                    this.setConfig();
                });
            });
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }
}
