import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { DynamicField, DynamicFieldType, SelectInputTypes, DynamicFieldTypes, IDynamicFieldType, IDynamicField } from '@mt-ng2/dynamic-form';
import { AbstractControl } from '@angular/forms';
import { AssociationTypeEnums, ImedClaimTypeAssociationsToggleService } from './services/imedclaim-type-associations-toggle.service';

@Component({
    selector: 'app-imedclaim-followup-engine-header',
    template: `
        <div>
            <h2>
                <mt-route-back-button></mt-route-back-button>
                {{ header }}
                <div *ngIf="showToggle" class="pull-right">
                    <mt-dynamic-field [field]="associationTypeToggle" (valueChanges)="selectionChanged($event)"></mt-dynamic-field>
                </div>
            </h2>
        </div>
        <router-outlet></router-outlet>
    `,
})
export class ImedClaimFollowupEngineHeaderComponent implements OnInit {
    header = '';
    routeSubscription: Subscription;
    associationTypeToggle: DynamicField;
    showToggle = false;
    buttons: AbstractControl;
    types = [
        { Id: 1, Name: 'Followup Type Associations' },
        { Id: 2, Name: 'Email Associations' },
    ];
    associationTypeSubscription: Subscription;
    selectedAssociationType: AssociationTypeEnums = AssociationTypeEnums.FOLLOWUP_TYPE;
    constructor(private route: ActivatedRoute, private associationsToggleService: ImedClaimTypeAssociationsToggleService) {}

    ngOnInit(): void {
        this.routeSubscription = this.route.url.subscribe((route) => {
            this.createAssociationTypeToggle();
            this.associationTypeSubscription = this.associationsToggleService.getAssociationType().subscribe((type) => {
                this.selectedAssociationType = type;
                this.setAssociationHeader();
            });
            this.showToggle = true;
        });
    }

    ngOnDestroy(): void {
        this.routeSubscription.unsubscribe();
        if (this.associationTypeSubscription) {
            this.associationTypeSubscription.unsubscribe();
        }
    }

    createAssociationTypeToggle(): void {
        this.associationTypeToggle = new DynamicField({
            formGroup: null,
            label: '',
            name: 'associationTypeToggle',
            options: this.types,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.RadioButtonList,
            } as IDynamicFieldType),
            value: this.selectedAssociationType,
        } as IDynamicField);
    }

    setAssociationHeader(): void {
        switch (this.selectedAssociationType) {
            case AssociationTypeEnums.FOLLOWUP_TYPE:
                this.header = 'Case Associations - Followup Types';
                break;
            case AssociationTypeEnums.EMAIL_ASSOCIATIONS:
                this.header = 'Email Template Associations';
                break;
            default:
        }
    }

    selectionChanged(event: AssociationTypeEnums): void {
        this.associationsToggleService.setAssociationType(event);
    }
}
