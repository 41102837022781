import {
    SharedEntitiesComponentConfig,
    ISharedEntitiesComponentConfig,
    SharedEntitiesEditOptions,
} from '@mt-ng2/shared-entities-module';
import { emptyAddress } from './address.service';
import { AddressListConfig } from './address.entity-list-config';
import { AddressDynamicConfig } from './address.dynamic-config';
import { entityListModuleConfig } from '../../../common/shared.module';
import { IAddress } from '../../interfaces/address';
import { StateService } from '@app-shared/services/state.service';
import { AddressTypeService } from '../../../address-books/addresses/addresstype.service';

export class AddressSharedEntitiesConfig extends SharedEntitiesComponentConfig<IAddress> implements ISharedEntitiesComponentConfig<IAddress> {
    constructor() {
        super(
            emptyAddress,
            'Address',
            'Addresses',
            {
                EntityListConfig: new AddressListConfig(),
                FilterServices: [StateService, AddressTypeService],
            },
            entityListModuleConfig.itemsPerPage,
        );

        this.MetaItemServices = [StateService, AddressTypeService];

        // TODO CCC: handle this
        this.AdditionalSharedEntities = [];

        this.SharedEntitiesEditOption = SharedEntitiesEditOptions.InPlaceWithAdditionalInfoButton;
    }

    getFormValues(address: IAddress, formValue: any): IAddress {
        // let states = this.getMetaItemValues('StateService');
        const types = this.getMetaItemValues('AddressTypeService');
        const formFactory = new AddressDynamicConfig<IAddress>(address, [], types);
        formFactory.assignFormValues(address, formValue.Address);
        return address;
    }

    getDynamicFormConfig(address: IAddress): any {
        const types = this.getMetaItemValues('AddressTypeService');
        const formFactory = new AddressDynamicConfig<IAddress>(address, [], types);
        if (address.Id === 0) {
            return formFactory.getForCreate();
        } else {
            return formFactory.getForUpdate();
        }
    }

    getRow = (address: IAddress) => `${address.Address1}`;
}
