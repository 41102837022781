import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { IPlaceholder } from '@common/ckeditor-dynamic-field/ckeditor-dynamic-field.component';
import { MergeFieldService } from '@common/services/mergefield.service';
import { IAutomatedTemplate } from '@model/interfaces/automated-template';
import { IAutomatedTemplateDynamicControlsParametersPartial } from '@model/partials/automated-template.form-controls.partial';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { DynamicField, DynamicLabel, IDynamicFormConfig, LabelPositions } from '@mt-ng2/dynamic-form';
import { IModalOptions, IModalWrapperApi } from '@mt-ng2/modal-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { Subscription, forkJoin } from 'rxjs';
import { debounceTime, finalize } from 'rxjs/operators';
import { AutomatedTemplateDynamicConfig } from '../automated-template.dynamic-config';
import { AutomatedTemplateService } from '../services/automated-template.service';

@Component({
    selector: 'app-automated-template-editor',
    templateUrl: 'automated-template-editor.component.html',
})
export class AutomatedTemplateEditorComponent implements OnInit, OnChanges, OnDestroy {
    @Input('automatedTemplate') automatedTemplate: IAutomatedTemplate;
    @Input('templateText') templateText: string;
    @Input('canEdit') canEdit: boolean;
    @Input('isExpandable') isExpandable = true;
    @Input('expanded') expanded: boolean;
    @Output('onTemplateUpdated') onTemplateUpdated = new EventEmitter<string>();

    isEditing = false;
    isHovered = false;
    expand = false;
    expandedModal: IModalWrapperApi;
    modalOptions: IModalOptions;
    reloadModal: boolean;

    templateForm: UntypedFormGroup;
    dynamicFormConfig: IDynamicFormConfig;
    templateControlSubscription: Subscription;
    doubleClickIsDisabled = false;
    mergeFields: IPlaceholder[];

    overrideMargins = false;

    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    get templateFormControl(): UntypedFormControl {
        return (<UntypedFormGroup>this.templateForm?.controls?.AutomatedTemplate)?.controls.Template as UntypedFormControl;
    }
    get hasTemplate(): boolean {
        return this.automatedTemplate?.Template?.length > 0 ?? false;
    }
    get isNewTemplate(): boolean {
        return this.automatedTemplate.Id === 0;
    }

    constructor(
        private automatedTemplateService: AutomatedTemplateService,
        private notificationsService: NotificationsService,
        private mergeFieldService: MergeFieldService,
    ) {}

    ngOnInit(): void {
        // this.config = { formObject: [], viewOnly: [] };

        this.mergeFieldService.getDynamicTemplateFields().subscribe((mergeFields) => {
            this.mergeFields = mergeFields.map((mf) => ({
                description: mf.MergeFieldType?.Name,
                id: mf.Id,
                name: mf.Name.replace(/[[\]]*/g, ''),
                title: mf.Name.replace(/[[\]]*/g, ''),
            }));
            this.setConfig();
        });

        this.modalOptions = {
            cancelButtonText: 'Close',
            confirmButtonText: 'Close and Save',
            showCancelButton: true,
            showConfirmButton: !this.isNewTemplate,
            width: '90%',
        };

        if (this.expanded) {
            this.edit();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.expanded && changes.templateText && !changes.templateText.firstChange && this.templateText !== changes.templateText.previousValue) {
            this.templateUpdated(this.templateText);
        }
    }

    ngOnDestroy(): void {
        if (this.templateControlSubscription) {
            this.templateControlSubscription.unsubscribe();
        }
    }

    setConfig(): void {
        const additionalParameters: IAutomatedTemplateDynamicControlsParametersPartial = {
            advancedEditor: true,
            autoComplete: {
                closeControlCharacter: ']',
                count: 1,
                enable: true,
                mergeFields: this.mergeFields,
                openControlCharacter: '[',
            },
        };
        const config = new AutomatedTemplateDynamicConfig(this.automatedTemplate, additionalParameters, ['Template']);

        if (this.isNewTemplate) {
            this.dynamicFormConfig = config.getForCreate();
        } else {
            this.dynamicFormConfig = config.getForUpdate();
        }

        this.dynamicFormConfig.formObject[0].labelPosition.position = LabelPositions.Hidden;
    }

    onCreate(form): void {
        this.templateForm = form;
        if (!this.templateText) {
            this.templateText = this.automatedTemplate.Template;
        } else {
            this.templateFormControl.setValue(this.templateText);
        }
        this.templateControlSubscription = this.templateFormControl.valueChanges.pipe(debounceTime(600)).subscribe((value: string) => {
            if (this.templateText !== value) {
                this.templateText = value;
                this.onTemplateUpdated.emit(value);
            }
        });
    }

    edit(): void {
        this.isEditing = true;
    }

    cancel(): void {
        this.isEditing = false;
        this.templateControlSubscription.unsubscribe();
    }

    clear(): void {
        this.templateFormControl.setValue('');
    }

    save(): void {
        if (this.templateForm.valid) {
            forkJoin([
                this.automatedTemplateService.updateWithFks(this.automatedTemplate),
                this.automatedTemplateService
                    .saveTemplate(this.automatedTemplate.Id, this.templateForm.value.AutomatedTemplate.Template as string, this.automatedTemplate.OverrideMargins),
            ])                .subscribe(
                    () => {
                        this.automatedTemplate.Template = this.templateForm.value.AutomatedTemplate.Template;
                        this.onSaveSuccess();
                    },
                    () => {
                        this.onSaveError();
                    },
                );
        } else {
            markAllFormFieldsAsTouched(this.templateForm);
            this.notificationsService.error('A template is required.');
        }
    }

    onSaveSuccess(): void {
        this.notificationsService.success('Template saved successfully');
        this.isEditing = false;
        this.expand = false;
        this.templateControlSubscription.unsubscribe();
        this.setConfig();
    }

    onSaveError(): void {
        this.notificationsService.error('Template failed to save');
    }

    openModal(): void {
        this.expand = true;
    }

    templateUpdated(event): void {
        if (this.templateFormControl) {
            this.templateFormControl.setValue(event);
        }
    }
}
