import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';

import { IImedClaimDynamicControlsParameters } from '@model/form-controls/imed-claim.form-controls';
import { IImedClaim } from '@model/interfaces/imed-claim';
import { IImedClaimStatus } from '@model/interfaces/imed-claim-status';
import { ImedClaimDynamicControlsPartial } from '@model/partials/imed-claim-partial.form-controls';
import { IRequestorType } from '@model/interfaces/requestor-type';
import { RequestorTypeEnums } from '../common/constants/Enums';
import { AbstractControl, Validators } from '@angular/forms';
import { IState } from '@model/interfaces/state';

export class ImedClaimDynamicConfig<T extends IImedClaim> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(
        private imedClaim: T,
        private imedClaimStatuses?: IImedClaimStatus[],
        private requestorTypes?: IRequestorType[],
        private schedulingAssignedTos?: any[],
        private compilationAssignedTos?: any[],
        private reportAssignedTos?: any[],
        private radiologyAssignedTos?: any[],
        private states?: IState[],
        private configControls?: string[],
    ) {
        super();
        const additionalParams: IImedClaimDynamicControlsParameters = {
            compilationAssignedTos: this.compilationAssignedTos,
            imedClaimStatuses: this.imedClaimStatuses,
            requestorTypes: this.requestorTypes,
            schedulingAssignedTos: this.schedulingAssignedTos,
            reportAssignedTos: this.reportAssignedTos,
            radiologyAssignedTos: this.radiologyAssignedTos,
            states: this.states,
        };
        const dynamicControls = new ImedClaimDynamicControlsPartial(this.imedClaim, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = [
                'FirstName',
                'LastName',
                'ImedClaimStatusId',
                'PastMedicalHistory',
                'HistoryOfAllegedInjury',
                'OccupationalStatus',
                'CurrentSymptomsAndCompliants',
                'CurrentCourseOfTreatment',
                'Diagnosis',
                'DegreeOfDisability',
                'NeedForFurtherTreatment',
                'MMI',
                'ReturnToWork',
                'CausalRelationship',
                'Apportionment',
                'ScheduleLossOfUse',
                'NeedForSurgery',
                'ActivityRestrictions',
                'CreatedById',
                'DateCreated',
                'Archived',
            ];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForUpdateGL(additionalConfigs?: any[]): IDynamicFormConfig {
        const formObject = this.getFormObject(additionalConfigs);
        const requestorTypeField = formObject.find((x) => x.name === 'RequestorTypeId');
        if (requestorTypeField) {
            requestorTypeField.options = requestorTypeField.options.filter(
                (x) => x.Id === RequestorTypeEnums.Carrier || x.Id === RequestorTypeEnums.Defense_Attorney || x.Id === RequestorTypeEnums.Paralegal || x.Id === RequestorTypeEnums.NA,
            );
        }

        const viewOnly = this.DynamicLabels;
        return {
            formObject: formObject,
            viewOnly: viewOnly,
        };
    }

    getForUpdateWorkersCompOrAuto(additionalConfigs?: any[]): IDynamicFormConfig {
        const formObject = this.getFormObject(additionalConfigs);
        formObject.forEach((f) => {
            if (f.name === 'ClaimNumber' || f.name === 'JobNumber' || f.name === 'DateofAllegedAccidentOrInjury' || f.name === 'RequestorTypeId') {
                f.validation.push((c: AbstractControl) => Validators.required(c));
                f.validators.required = true;
            }
        });

        const jobNumberField = formObject.find((x) => x.name === 'JobNumber');
        if (jobNumberField) {
            jobNumberField.labelHtml = '<label>Job / WCB Case Number</label>';
        }

        const requestorTypeField = formObject.find((x) => x.name === 'RequestorTypeId');
        if (requestorTypeField) {
            requestorTypeField.options = requestorTypeField.options.filter(
                (x) => x.Id === RequestorTypeEnums.Carrier || x.Id === RequestorTypeEnums.Employer || x.Id === RequestorTypeEnums.Claimant,
            );
        }

        let viewOnly = this.DynamicLabels;
        const jobNumberView = viewOnly.find((x) => x.label === 'Job Number');
        if (jobNumberView) {
            jobNumberView.label = 'Job / WCB Case Number';
        }

        const allStates = viewOnly.find((v) => v.label === 'All States');
        if (allStates && allStates.value === true) {
            viewOnly = viewOnly.filter((v) => v.label !== 'State');
        }

        return {
            formObject: formObject,
            viewOnly: viewOnly,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }

    getForCreateGL(additionalConfigs?: any[]): IDynamicFormConfig {
        const formObject = this.getFormObject(additionalConfigs);
        const requestorTypeField = formObject.find((x) => x.name === 'RequestorTypeId');
        if (requestorTypeField) {
            requestorTypeField.options = requestorTypeField.options.filter(
                (x) => x.Id === RequestorTypeEnums.Carrier || x.Id === RequestorTypeEnums.Defense_Attorney || x.Id === RequestorTypeEnums.Paralegal || x.Id === RequestorTypeEnums.NA,
            );
        }
        return {
            formObject,
        };
    }

    getForCreateWorkersCompOrAuto(additionalConfigs?: any[]): IDynamicFormConfig {
        const formObject = this.getFormObject(additionalConfigs);
        formObject.forEach((f) => {
            if (f.name === 'ClaimNumber' || f.name === 'JobNumber' || f.name === 'DateofAllegedAccidentOrInjury' || f.name === 'RequestorTypeId') {
                f.validation.push((c: AbstractControl) => Validators.required(c));
                f.validators.required = true;
            }
        });

        const jobNumberField = formObject.find((x) => x.name === 'JobNumber');
        if (jobNumberField) {
            jobNumberField.labelHtml = '<label>Job / WCB Case Number</label>';
        }

        const requestorTypeField = formObject.find((x) => x.name === 'RequestorTypeId');
        if (requestorTypeField) {
            requestorTypeField.options = requestorTypeField.options.filter(
                (x) => x.Id === RequestorTypeEnums.Carrier || x.Id === RequestorTypeEnums.Employer || x.Id === RequestorTypeEnums.Claimant,
            );
        }
        return {
            formObject,
        };
    }
}
