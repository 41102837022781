import { Component, Input, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { UserRoleService } from '../user-role.service';
import { IUserRole } from '@model/interfaces/user-role';
import { UserRoleDynamicConfig } from '../user-role.dynamic-config';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { UserRoleIdEnums } from '../../common/constants/Enums';
import { IDashboardType } from '@model/interfaces/dashboard-type';
import { DashboardService } from '../../dashboard/dashboard.service';
import { finalize } from 'rxjs/operators';
import { UserTypeService } from '../usertype.service';
import { forkJoin, Subscription } from 'rxjs';
import { UserTypeValues } from '../../common/constants/user-type.enum';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { DynamicField, DynamicLabel, IDynamicField, IDynamicLabel } from '@mt-ng2/dynamic-form';

@Component({
    selector: 'app-user-role-basic-info',
    templateUrl: './user-role-basic-info.component.html',
})
export class UserRoleBasicInfoComponent implements OnInit, OnDestroy {
    @Input() userRole: IUserRole;
    @Input() canEdit: boolean;
    isEditing: boolean;
    isHovered: boolean;
    config: any = {};
    userRoleForm: UntypedFormGroup;
    formFactory: UserRoleDynamicConfig<IUserRole>;
    doubleClickIsDisabled = false;
    dashboardTypes: IDashboardType[];
    subscriptions: Subscription = new Subscription();
    resultingSubscription: Subscription; // TODO: unsure how to name this property
    caseTypeFormFactory: UserRoleDynamicConfig<IUserRole>;
    emailNotificationFormFactory: UserRoleDynamicConfig<IUserRole>;
    caseTypeConfig: any = {};
    emailNotificationConfig: any = {};
    isAdminType: boolean;

    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    constructor(
        private userRoleService: UserRoleService,
        private notificationsService: NotificationsService,
        private dashboardService: DashboardService,
        private userTypeService: UserTypeService,
        private fb: UntypedFormBuilder,
        private router: Router,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.config = { formObject: [], viewOnly: [] };
        this.isEditing = false;
        this.config = { formObject: [], viewOnly: [] };
        this.userRoleForm = this.fb.group({});
        forkJoin(this.dashboardService.getDashboardTypes(), this.userTypeService.getItems()).subscribe((answers) => {
            const [dashboardTypes,] = answers;
            this.dashboardTypes = dashboardTypes;
            this.setConfig();
        });
        this.isAdminType = this.userRole.UserTypeId === +UserTypeValues.Admin;
    }

    setConfig(): void {
        this.formFactory = new UserRoleDynamicConfig<IUserRole>(this.userRole, this.dashboardTypes, this.userTypeService.items, [
            'Name',
            'Description',
            'UserTypeId',
            'DashboardTypeId',
            'EmailForTemplates',
            'AssignManualContactTask',
            'AllowToSendAsAttachment',
            'TaskDistributionList',
            'ClientCaseCreateEmail',
            'ClientCaseUpdateEmail',
            'SchedulingAssignedTo',
            'CompilationAssignedTo',
            'RetrievalAssignedTo',
            'ReportAssignedTo',
            'SubpoenaAssignedTo',
            'ClientPortalSubpoenaServiceTask',
            'AssignTaskOnPhysicianExpertDocUpdate',
            'ClientPortalRequestEmails',
            'PhysicianExpertPortalRequestEmails',
        ]);
        this.caseTypeFormFactory = new UserRoleDynamicConfig<IUserRole>(this.userRole, this.dashboardTypes, this.userTypeService.items, [
            'GeneralLiability',
            'WorkersComp',
            'Auto',
        ]);
        this.emailNotificationFormFactory = new UserRoleDynamicConfig<IUserRole>(this.userRole, this.dashboardTypes, this.userTypeService.items, [
            'ReceiveAutoCaseClosingJobEmail',
            'ReceiveCpFilmDeleteEmail',
        ]);

        if (this.userRole.Id === 0) {
            this.isEditing = true;
            this.config = this.formFactory.getForCreate();
            this.caseTypeConfig = this.caseTypeFormFactory.getForCreate();
            this.emailNotificationConfig = this.emailNotificationFormFactory.getForCreate();
        } else {
            this.config = this.formFactory.getForUpdate();
            this.caseTypeConfig = this.caseTypeFormFactory.getForUpdate();
            this.emailNotificationConfig = this.emailNotificationFormFactory.getForUpdate();
            if (this.userRole.Id === +UserRoleIdEnums.Accounting || this.userRole.Id === +UserRoleIdEnums.Admin) {
                this.config.formObject[0].disabled = true;
                this.config.formObject[1].disabled = true;
            }
        }

        this.viewOnly = this.config?.viewOnly?.map((x: IDynamicLabel) => new DynamicLabel(x));
        this.formObject = this.config.formObject?.map((x: IDynamicField) => new DynamicField(x));
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.userRole.Id === 0) {
            void this.router.navigate(['/roles']);
        } else {
            this.isEditing = false;
        }
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.userRole, form.value.UserRole as IUserRole);
            this.userRoleService
                .saveUserRole(this.userRole)
                .pipe()
                .subscribe((answer) => {
                    this.success();
                    this.userRoleService.emitChange(this.userRole);
                    if (this.userRole.Id === 0) {
                        void this.router.navigate(['/roles/' + answer.Id]);
                    } else {
                        this.isEditing = false;
                        this.setConfig();
                    }
                });
        } else {
            markAllFormFieldsAsTouched(form);
            this.error();
        }
    }

    error(): void {
        this.notificationsService.error('Save failed.  Please check the form and try again.');
    }

    success(): void {
        this.notificationsService.success('User Role saved successfully.');
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    onFormCreated(group: UntypedFormGroup): void {
        this.resultingSubscription = this.userRoleForm.get('UserRole.UserTypeId').valueChanges.subscribe((value) => {
            const userRoleGroup = group.controls.UserRole as UntypedFormGroup;
            if (value === UserTypeValues.Admin) {
                this.isAdminType = true;
                userRoleGroup.controls.DashboardTypeId.enable();
                userRoleGroup.controls.DashboardTypeId.setValue(null);
                userRoleGroup.controls.DashboardTypeId.mtSetRequired(true);
                userRoleGroup.controls.EmailForTemplates.enable();
                userRoleGroup.controls.AssignManualContactTask.enable();
                userRoleGroup.controls.AssignManualContactTask.setValue(false);
            } else {
                this.isAdminType = false;
                userRoleGroup.controls.DashboardTypeId.disable();
                userRoleGroup.controls.DashboardTypeId.mtSetRequired(false);
                userRoleGroup.controls.DashboardTypeId.setValue(null);
                userRoleGroup.controls.EmailForTemplates.disable();
                userRoleGroup.controls.AssignManualContactTask.disable();
                userRoleGroup.controls.AssignManualContactTask.setValue(false);
            }

            this.cdr.detectChanges();
        });
        this.subscriptions.add(this.resultingSubscription);
    }
}
