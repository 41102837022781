import { IAppFaxEmailComponentApi } from './fax-email-component';
import { Component, OnInit } from '@angular/core';
import { IEntityListDynamicCellComponent, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { AppointmentDocument, FaxEmailEntityTypeEnums } from '../constants/Enums';
import { IImeAppointment } from '@model/interfaces/ime-appointment';
import { IName, NameTypeEnum } from '@model/interfaces/custom/name';

@Component({
    template: `
        <select
            *ngIf="!appointment.Archived"
            [(ngModel)]="selectedType"
            class="form-control"
            (change)="setComponentValueBasedOnSelection(selectedType)"
        >
            <option *ngFor="let doctype of doctypes" [ngValue]="doctype">{{ doctype.docName }}</option>
        </select>
        <app-fax-email
            *ngIf="appFaxEmailComponentExists"
            (closeModal)="resetDropDownList()"
            [imedclaimServiceId]="appointment.ImedClaimServiceId"
            [entity]="appointment"
            [entityId]="appointment?.Id"
            [entityTypeId]="entityTypeId"
            [name]="claimantName"
            (ready)="appFaxEmailComponentApi = $event"
        ></app-fax-email>
    `,
})
export class FaxEmailAppointmentWrapperComponent implements IEntityListDynamicCellComponent, OnInit {
    appFaxEmailComponentExists = true;
    entityListComponentMembers: IEntityListComponentMembers;
    appointment: IImeAppointment;
    selectedType = {};
    claimantName: IName;
    doctypes = [
        { Id: 0, docName: 'Select' },
        { Id: 5, docName: 'Appointment Confirmation Letter' },
        { Id: 1, docName: 'Appointment Notification Letter' },
        { Id: 2, docName: 'Appointment Cancel Letter' },
        { Id: 3, docName: 'Appointment Missed Letter' },
        { Id: 4, docName: 'Appointment Reschedule Letter' },
        { Id: 8, docName: 'Appointment Reschedule TBD Letter' },
    ];
    set entity(value: IImeAppointment) {
        this.appointment = value;
    }

    appFaxEmailComponentApi: IAppFaxEmailComponentApi;

    entityTypeId: FaxEmailEntityTypeEnums;

    constructor() {}

    ngOnInit(): void {
        this.selectedType = this.doctypes[0];
        this.claimantName = {firstName: this.appointment.ImedClaimService.ImedClaim.FirstName, lastName: this.appointment.ImedClaimService.ImedClaim.LastName, nameType: NameTypeEnum.Individual};
    }

    resetDropDownList(): void {
        this.selectedType = this.doctypes[0];
        // force destroy and recreate app-fax-email component so that CKEditor doesn't error out
        this.appFaxEmailComponentExists = false;
        setTimeout(() => {
            this.appFaxEmailComponentExists = true;
        }, 0);
    }

    setComponentValueBasedOnSelection(value: any): void {
        if (value.Id > 0) {
            if (value.Id === AppointmentDocument.Appointment_Notification_Letter) {
                this.entityTypeId = FaxEmailEntityTypeEnums.AppointmentNotificationLetter;
            }

            if (value.Id === AppointmentDocument.Appointment_Cancel_Letter) {
                this.entityTypeId = FaxEmailEntityTypeEnums.AppointmentCancelLetter;
            }

            if (value.Id === AppointmentDocument.Appointment_Missed_Letter) {
                this.entityTypeId = FaxEmailEntityTypeEnums.AppointmentMissedLetter;
            }

            if (value.Id === AppointmentDocument.Appointment_Reschedule_Letter) {
                this.entityTypeId = FaxEmailEntityTypeEnums.AppointmentRescheduleLetter;
            }

            if (value.Id === AppointmentDocument.Appointment_Confirmation_Letter) {
                this.entityTypeId = FaxEmailEntityTypeEnums.AppointmentConfirmationLetter;
            }

            if (value.Id === AppointmentDocument.Appointment_Reschedule_TBD_Letter) {
                this.entityTypeId = FaxEmailEntityTypeEnums.AppointmentRescheduleTBDLetter;
            }

            this.appFaxEmailComponentApi.createEmail(this.entityTypeId);
            this.showComponent();
        }
    }

    showComponent(): void {
        this.appFaxEmailComponentApi.show();
    }
}
