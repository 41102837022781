import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { IDynamicField, emailEmptyAcceptedValidator } from '@mt-ng2/dynamic-form';
import { EmailAddressDynamicConfig } from '@model/shared-entities/email-addresses/email-address.dynamic-config';
import { emptyEmailAddress } from '@model/shared-entities/email-addresses/email-address.service';
import { IEmailType } from '@model/interfaces/email-type';
import { EmailTypeService } from '@model/shared-entities/email-type/emailtype.service';
import { safeDetectChanges } from '../../common/cdr-safety/cdr-safety.library';

@Component({
    selector: 'app-primary-email',
    templateUrl: './primary-email.component.html',
})
export class PrimaryEmailComponent implements OnInit {
    @Input() form: UntypedFormGroup;
    @Input() overrideFormGroup = 'EmailAddress';

    private _required = true;
    @Input('required')
    get required(): boolean {
        return this._required;
    }
    set required(value: boolean) {
        this._required = value;
        if (this.form) {
            this.updateRequired();
        }
    }

    emailConfig: IDynamicField[];

    constructor(private emailTypeService: EmailTypeService, private cdr: ChangeDetectorRef) { }

    ngOnInit(): void {
        forkJoin(this.emailTypeService.getItems()).subscribe((forkJoinResponses) => {
            const [emailTypes] = forkJoinResponses;
            this.setEmailConfig(emailTypes);
            safeDetectChanges(this.cdr);
        });
    }

    setEmailConfig(emailTypes: IEmailType[]): void {
        const emailControls = ['EmailTypeId', 'Email', 'Notes'];
        const emailConfig = new EmailAddressDynamicConfig(emptyEmailAddress, emailTypes, emailControls);
        const formObject = emailConfig.getForCreate().formObject;
        formObject.forEach((field) => {
            (field ).formGroup = this.overrideFormGroup;
            if (field.name === 'Email') {
                (field ).validation = [emailEmptyAcceptedValidator];
                (field ).validators.email = true;
            }
            (field ).setRequired(this.required);
        });
        this.emailConfig = formObject;
    }

    updateRequired(): void {
        const phoneFormGroup = this.form?.get(this.overrideFormGroup) as UntypedFormGroup || null;
        if (phoneFormGroup) {
            for (const key in phoneFormGroup.controls) {
                if (Object.prototype.hasOwnProperty.call(phoneFormGroup.controls, key)) {
                    (phoneFormGroup.controls[key] ).mtSetRequired(this.required);
                }
            }
        }
    }
}
