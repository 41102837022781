// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            label {
                padding: 8px 0px;
                font-size: 20px;
                color: #858a9f;
            }
            .invalid {
                border: 1px solid red;
            }
            .error {
                color: red;
                font-size: 10px;
            }
            .merging {
                position: absolute;
                height: 100%;
                width: 100%;
                background: rgba(68, 68, 68, 0.3);
                top: 0;
                left: 0;
                z-index: 9999;
                padding: 1.25em;
                border-radius: 0.3125em;
            }
            .generating {
                width: 100%;
                height: 100%;
                display: flex;
                opacity: 100%;
                font-size: 50px;
                align-items: center;
                justify-content: center;
                text-align: center;
            }
        `, "",{"version":3,"sources":["webpack://./src/app/common/common-email/common-email.component.ts"],"names":[],"mappings":";YACY;gBACI,gBAAgB;gBAChB,eAAe;gBACf,cAAc;YAClB;YACA;gBACI,qBAAqB;YACzB;YACA;gBACI,UAAU;gBACV,eAAe;YACnB;YACA;gBACI,kBAAkB;gBAClB,YAAY;gBACZ,WAAW;gBACX,iCAAiC;gBACjC,MAAM;gBACN,OAAO;gBACP,aAAa;gBACb,eAAe;gBACf,uBAAuB;YAC3B;YACA;gBACI,WAAW;gBACX,YAAY;gBACZ,aAAa;gBACb,aAAa;gBACb,eAAe;gBACf,mBAAmB;gBACnB,uBAAuB;gBACvB,kBAAkB;YACtB","sourcesContent":["\n            label {\n                padding: 8px 0px;\n                font-size: 20px;\n                color: #858a9f;\n            }\n            .invalid {\n                border: 1px solid red;\n            }\n            .error {\n                color: red;\n                font-size: 10px;\n            }\n            .merging {\n                position: absolute;\n                height: 100%;\n                width: 100%;\n                background: rgba(68, 68, 68, 0.3);\n                top: 0;\n                left: 0;\n                z-index: 9999;\n                padding: 1.25em;\n                border-radius: 0.3125em;\n            }\n            .generating {\n                width: 100%;\n                height: 100%;\n                display: flex;\n                opacity: 100%;\n                font-size: 50px;\n                align-items: center;\n                justify-content: center;\n                text-align: center;\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
