import { Observable } from 'rxjs';
import * as io from 'socket.io-client';
import { environment } from '../../environments/environment';

export interface IEntityUser {
    UserId: number;
    ServiceId: number;
    UserName: string;
}

export class ConcurrentUsersSocketService {
    private socket: any;

    constructor() {}

    public connect(): void {
        this.socket = io(environment.socketUrl, { transports: ['polling'] });
    }

    public join(serviceUserInfo: IEntityUser): void {
        this.socket.emit('user-on-service', serviceUserInfo);
    }

    public afterJoin(): Observable<IEntityUser[]> {
        return new Observable<IEntityUser[]>((observer) => {
            this.socket.on('user-on-service', (data: IEntityUser[]) => observer.next(data));
        });
    }

    public afterLeave(): Observable<IEntityUser[]> {
        return new Observable<IEntityUser[]>((observer) => {
            this.socket.on('user-off-service', (data: IEntityUser[]) => observer.next(data));
        });
    }

    public disconnect(): void {
        this.socket.disconnect();
    }
}
