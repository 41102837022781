import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';

import { EmailTemplateDynamicControls, IEmailTemplateDynamicControlsParameters } from '@model/form-controls/email-template.form-controls';
import { IEmailTemplate } from '@model/interfaces/email-template';
import { IUser } from '@model/interfaces/user';
import { EmailTemplateDynamicControlsPartial } from '@model/partials/email-template-partial.form-controls';

export class EmailTemplateDynamicConfig<T extends IEmailTemplate> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private emailTemplate: T, private createdBies?: IUser[], private modifiedBies?: IUser[], private configControls?: string[]) {
        super();
        const additionalParams: IEmailTemplateDynamicControlsParameters = {
            createdBies: this.createdBies,
            modifiedBies: this.modifiedBies,
        };
        const dynamicControls = new EmailTemplateDynamicControlsPartial(this.emailTemplate, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['Name', 'To', 'Cc', 'Bcc', 'Subject', 'Body', 'CreatedById', 'DateCreated', 'SystemGenerated', 'Archived'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
