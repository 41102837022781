import { EmailAddressSharedEntitiesConfig } from '@model/shared-entities/email-addresses/email-address.shared-entities-config';
import { IndividualEmailService } from './individual-email.service';
import { PhoneNumberSharedEntitiesConfig } from '@model/shared-entities/phone-numbers/phone-numbers.shared-entities-config';
import { IndividualPhoneService } from './individual-phone.service';

export enum IndividualSharedEntities {
    EmailAddresses,
    PhoneNumbers,
}

export const IndividualEmailSharedEntity = {
    config: EmailAddressSharedEntitiesConfig,
    entityIdParam: 'emailAddressId',
    path: 'emailaddresses',
    service: IndividualEmailService,
    sharedEntity: IndividualSharedEntities.EmailAddresses,
};

export const IndividualPhoneNumberSharedEntity = {
    config: PhoneNumberSharedEntitiesConfig,
    entityIdParam: 'phoneNumberId',
    path: 'phonenumbers',
    service: IndividualPhoneService,
    sharedEntity: IndividualSharedEntities.PhoneNumbers,
};
