import { DynamicField, DynamicFieldTypes, InputTypes, NumericInputTypes } from '@mt-ng2/dynamic-form';
import { ITestimonyDepositionService } from './../interfaces/testimony-deposition-service.d';
import {
    TestimonyDepositionServiceDynamicControls,
    ITestimonyDepositionServiceDynamicControlsParameters,
} from './../form-controls/testimony-deposition-service.form-controls';
import { CurrencyPipe } from '@angular/common';

export class TestimonyDepositionServiceDynamicControlsPartial extends TestimonyDepositionServiceDynamicControls {
    constructor(
        protected currencyPipe: CurrencyPipe,
        private testimonydepositionService?: ITestimonyDepositionService,
        additionalParameters?: ITestimonyDepositionServiceDynamicControlsParameters,
    ) {
        super(testimonydepositionService, additionalParameters);
        (<DynamicField>this.Form.DateConfirmed).labelHtml = `<label>Confirmed Date</label>`;
        (<DynamicField>this.View.DateConfirmed).label = `Confirmed Date`;

        (<DynamicField>this.Form.PhysicianTestimonyDueDate).labelHtml = `<label>Due Date</label>`;
        (<DynamicField>this.View.PhysicianTestimonyDueDate).label = `Due Date`;

        (<DynamicField>this.Form.PhysicianTestimonyFee).labelHtml = `<label>Fee</label>`;
        (<DynamicField>this.View.PhysicianTestimonyFee).label = `Fee`;

        (<DynamicField>this.Form.PhysicianTestimonyFee).type.inputType = NumericInputTypes.Currency;
        (<DynamicField>this.View.PhysicianTestimonyFee).value = this.currencyPipe.transform(this.testimonydepositionService.PhysicianTestimonyFee);

        // (<DynamicField>this.Form.PhysicianTestimonyFee).type = new DynamicFieldType({
        //     fieldType: DynamicFieldTypes.Input,
        //     inputType: InputTypes.DateTimepicker,
        //     },
        // );

        // (<DynamicField>this.Form.DateConfirmed).setRequired(true);
        // (<DynamicField>this.Form.PartyThatConfirmed).setRequired(true);
        // (<DynamicField>this.Form.PhysicianTestimonyDueDate).setRequired(true);
        // (<DynamicField>this.Form.PhysicianTestimonyFee).setRequired(true);
    }
}
