import { IEntityListConfig, EntityListConfig, EntityListColumn, SortDirection, EntityListDeleteColumn } from '@mt-ng2/entity-list-module';
import { IAddress } from '@model/interfaces/address';
import { AddressDeleteDynamicCellComponent } from './address-delete-dynamic-cell.component';

export class AddressesEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    name: 'Address1',
                }),
                new EntityListColumn({
                    name: 'Address2',
                }),
                new EntityListColumn({
                    name: 'City',
                }),
                new EntityListColumn({
                    accessorFunction: (address: IAddress) => (address.County && address.County.CountyName ? address.County.CountyName : ''),
                    name: 'County',
                }),
                new EntityListColumn({
                    accessors: ['State', 'StateCode'],
                    name: 'State',
                }),
                new EntityListColumn({
                    name: 'Zip',
                }),
                new EntityListColumn({
                    accessorFunction: (address: IAddress) => (address.IsPrimary ? 'Primary' : ''),
                    name: 'Primary',
                }),
                new EntityListColumn({
                    accessorFunction: function (ab: IAddress): string {
                        if (ab.AddressTypes) {
                            return `${ab.AddressTypes.map((at) => at.Name).join(', ')}`;
                        } else {
                            return '';
                        }
                    },
                    name: 'Address Type',
                }),
                new EntityListColumn({
                    component: AddressDeleteDynamicCellComponent,
                    name: 'Delete',
                    sort: { disableSort: true },
                }),
            ],
            rowClass: (entity: IAddress) => (entity.Archived ? 'archived' : null),
        };
        super(listConfig);
    }
}
