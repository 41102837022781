import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { IMasterServiceStatusFollowupType } from '@model/interfaces/master-service-status-followup-type';

export const emptyServiceFollowupAssociation: IMasterServiceStatusFollowupType = {
    Id: 0,
    MasterFollowupTypeId: null,
    MasterServiceStatusId: null,
    ServiceId: null,
};

@Injectable()
export class MasterServiceStatusFollowupTypeService extends BaseService<IMasterServiceStatusFollowupType> {
    constructor(public http: HttpClient) {
        super('/masterservicestatusfollowuptypes', http);
    }

    getEmptyServiceFollowupAssociation(): IMasterServiceStatusFollowupType {
        return emptyServiceFollowupAssociation;
    }
}
