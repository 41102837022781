import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SharedEntitiesService } from '@mt-ng2/shared-entities-module';

import { IEmailAddress } from '../../interfaces/email-address';
import { Observable } from '../../../../../node_modules/rxjs';
import { AuthService } from '../../../../../node_modules/@mt-ng2/auth-module';

export const emptyEmailAddress: IEmailAddress = {
    Archived: false,
    CreatedById: null,
    DateCreated: new Date(),
    Email: null,
    EmailTypeId: 1,
    Id: 0,
    IsPrimary: false,
    ModifiedById: null,
    Notes: '',
};

@Injectable()
export class EmailAddressService extends SharedEntitiesService<IEmailAddress> {
    constructor(public baseEntityUrl: string, public http: HttpClient, public authService: AuthService) {
        super(baseEntityUrl, '/email-addresses', http);
    }

    saveEntity(parentId: number, entity: IEmailAddress): Observable<number> {
        if (entity.Id === 0) {
            entity.CreatedById = this.authService.currentUser.getValue().Id;
        } else {
            entity.ModifiedById = this.authService.currentUser.getValue().Id;
        }
        return super.saveEntity(parentId, entity);
    }
}
