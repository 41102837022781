import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, IRoleGuarded, ClaimValues } from '@mt-ng2/auth-module';

import { ClaimTypes } from '@model/ClaimTypes';
import { MasterServiceStatussComponent } from './master-service-statuses-list/master-service-statuses.component';
import { MasterServiceStatusAddComponent } from './master-service-statuses-add/master-service-statuses-add.component';
import { MasterServiceStatusDetailComponent } from './master-service-status-detail/master-service-status-detail.component';
import { MasterServiceStatusHeaderComponent } from './master-service-status-header/master-service-status-header.component';
import { MasterServiceStatusService } from './services/master-service-status.service';

const masterServiceStatusListRoleGuard: any = {
    claimType: ClaimTypes.MasterServiceStatuses,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'MasterServiceStatuss',
};

const masterServiceStatusEntityConfig = {
    claimType: ClaimTypes.MasterServiceStatuses,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'ServiceStatusId',
    service: MasterServiceStatusService,
    title: 'Service Status Detail',
};

export const masterServiceStatusPathSlugs = {
    add: 'add',
    root: 'statuses',
};

export const masterServiceStatusPaths = {
    masterServiceStatusAdd: `${masterServiceStatusPathSlugs.root}/${masterServiceStatusPathSlugs.add}`,
    masterServiceStatuses: masterServiceStatusPathSlugs.root,
};

const masterServiceStatusAddRoleGuard: IRoleGuarded = {
    claimType: ClaimTypes.MasterServiceStatuses,
    claimValues: [ClaimValues.FullAccess],
};

const masterServiceStatusRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: MasterServiceStatussComponent,
        data: masterServiceStatusListRoleGuard,
        path: masterServiceStatusPaths.masterServiceStatuses,
    },
    {
        canActivate: [AuthGuard],
        children: [{ component: MasterServiceStatusAddComponent, path: '', pathMatch: 'full' }],
        component: MasterServiceStatusHeaderComponent,
        data: masterServiceStatusAddRoleGuard,
        path: masterServiceStatusPaths.masterServiceStatusAdd,
    },
    {
        canActivate: [AuthGuard],
        children: [{ component: MasterServiceStatusDetailComponent, path: '', pathMatch: 'full' }],
        component: MasterServiceStatusHeaderComponent,
        data: masterServiceStatusEntityConfig,
        path: `${masterServiceStatusPaths.masterServiceStatuses}/:${masterServiceStatusEntityConfig.entityIdParam}`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(masterServiceStatusRoutes)],
})
export class MasterServiceStatusRoutingModule {}
