import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MetaItemService } from '@mt-ng2/base-service';
import { ILanguage } from '@model/interfaces/language';

@Injectable()
export class LanguageService extends MetaItemService<ILanguage> {
    constructor(public http: HttpClient) {
        super('LanguageService', 'Language', 'LanguageIds', '/languages', http);
    }
}
