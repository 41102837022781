import { NgModule } from '@angular/core';
import { SharedModule } from '@common/shared.module';
import { MasterFollowUpTypeBasicInfoComponent } from './master-followup-type-basic-info/master-followup-type-basic-info.component';
import { MasterFollowupTypeDetailComponent } from './master-followup-type-detail/master-followup-type-detail.component';
import { MasterFollowupTypeFormComponent } from './master-followup-type-form/master-followup-type-form.component';
import { MasterFollowupTypeHeaderComponent } from './master-followup-type-header/master-followup-type-header.component';
import { MasterFollowupTypeRoutingModule } from './master-followup-type-routing.module';
import { MasterFollowUpTypeAddComponent } from './master-followup-types-add/master-followup-types-add.component';
import { MasterFollowUpTypeAssociatedServicesComponent } from './master-followup-types-list/master-followup-type-associated-services.component';
import { MasterFollowUpTypesComponent } from './master-followup-types-list/master-followup-types.component';
import { MasterFollowUpTypeService } from './services/master-followup-type.service';

@NgModule({
    declarations: [
        MasterFollowUpTypesComponent,
        MasterFollowUpTypeAssociatedServicesComponent,
        MasterFollowUpTypeAddComponent,
        MasterFollowUpTypeBasicInfoComponent,
        MasterFollowupTypeDetailComponent,
        MasterFollowupTypeHeaderComponent,
        MasterFollowupTypeFormComponent,
    ],
    imports: [SharedModule, MasterFollowupTypeRoutingModule],
})
export class MasterFollowUpTypeModule {
    static forRoot(): any {
        return {
            ngModule: MasterFollowUpTypeModule,
            providers: [MasterFollowUpTypeService],
        };
    }
}
