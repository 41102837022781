import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MetaItemService } from '@mt-ng2/base-service';
import { IBodyPart } from '@model/interfaces/body-part';

@Injectable()
export class BodyPartService extends MetaItemService<IBodyPart> {
    constructor(public http: HttpClient) {
        super('BodyPartService', 'BodyPart', 'BodyPartIds', '/body-parts', http);
    }
}
