export enum NameTypeEnum {
    Individual,
    Facility,
}

export interface IName {
    facilityName?: string,
    firstName?: string,
    lastName?: string,
    nameType: NameTypeEnum,
}
