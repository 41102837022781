import { IDynamicConfig, IDynamicFormConfig, DynamicConfig} from '@mt-ng2/dynamic-form';
import { IState } from '@model/interfaces/state.d';
import { ImedClaimSubpoenaDetailDynamicControls, IImedClaimSubpoenaDetailDynamicControlsParameters } from '@model/form-controls/imed-claim-subpoena-detail.form-controls';
import { IImedClaimSubpoenaDetail } from '@model/interfaces/imed-claim-subpoena-detail';
import { IAddress } from '@model/interfaces/address';
import { IImedClaim } from '@model/interfaces/imed-claim';
import { IAddressBook } from '@model/interfaces/address-book';
import { ImedClaimSubpoenaDetailDynamicControlsPartial } from '@model/partials/imed-claim-subpoena-detail-partial.form-controls';
import { ICourtAddressBookDTO } from '@model/interfaces/custom/court-address-book-dto';
import { IImedClaimSubpoenaDetailDynamicControlsParametersPartial } from '@model/partials/imed-claim-subpoena-detail-dynamic-controls.partial';

export class ImedClaimSubpoenaDetailDynamicConfig<T extends IImedClaimSubpoenaDetail> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private courts: ICourtAddressBookDTO[] , private courtPrimaryAddress: IAddress, private imedClaimSubpoenaDetail: T, private hearingAddresses: IAddress[], private imedClaims: IImedClaim[], private selectedCourt?: ICourtAddressBookDTO[], private states?: IState[], private configControls?: string[]) {
        super();
        const additionalParams: IImedClaimSubpoenaDetailDynamicControlsParametersPartial = {
            courtPrimaryAddress: this.courtPrimaryAddress,
            courts: this.courts,
            hearingAddresses: this.hearingAddresses,
            imedClaims: this.imedClaims,
            selectedCourt: this.selectedCourt,
            states: this.states,
        };
        const dynamicControls = new ImedClaimSubpoenaDetailDynamicControlsPartial(this.imedClaimSubpoenaDetail, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = [ 'ResidingCourtAddressBookId', 'HearingAddressId', 'Address1', 'Address2', 'City', 'State', 'Zip', 'HearingDateTime', 'HearingTime', 'IndexDocketCaseNumber', 'Division', 'PartOrDepartment', 'Room', 'GoodCause', 'DocumentReason', 'JusticeJudgeName', 'BranchName', 'ClerkName', 'DefenseAttorneyIdBarNumber', 'Title', 'Other1', 'Other2', 'Other3', 'ClientApprovalDate', 'PlaintiffAttorneyRequiresRecords', 'PlaintiffLodgedObjectionDate'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
