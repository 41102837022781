import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { forkJoin } from 'rxjs';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IStatusTaskAssociation } from './status-task-row/physician-status-task-row.component';
import { IGroupedTaskTemplatesByPhysicianStatusDTO } from '@model/interfaces/custom/grouped-task-templates-by-physician-status-dto';
import { PhysicianStatusTaskTemplateService } from '../services/physician-status-task-service';
import { IPhysicianStatusTaskTemplate } from '@model/interfaces/physician-status-task-template';
import { IAddressBookType } from '@model/interfaces/address-book-type';
import { TaskTemplateService } from '../../services/tasktemplate.service';
import { IFilteredPhysicianStatusTaskDTO } from '@model/interfaces/custom/filtered-physician-status-task-dto';

@Component({
    selector: 'app-task-status-associations',
    styles: [
        `
            .clear-padding {
                padding-left: 0;
            }
        `,
    ],
    templateUrl: './task-status-association.component.html',
})
export class TaskPhysicianStatusAssociationsComponent implements OnInit, OnChanges {
    @Input() selectedAddressBookType: IAddressBookType;
    tasksGroupedByServiceStatus: IGroupedTaskTemplatesByPhysicianStatusDTO[] = [];
    filteredStatusTaskAssociations: IPhysicianStatusTaskTemplate[] = [];
    filteredAddressBookTypeDataForTasks: IFilteredPhysicianStatusTaskDTO;

    constructor(
        private physicianStatusTaskService: PhysicianStatusTaskTemplateService,
        private notificationService: NotificationsService,
        private taskTemplateService: TaskTemplateService,
    ) {}

    ngOnInit(): void {
        this.getFilteredAddressBookTypeData();
    }

    ngOnChanges(): void {
        this.getFilteredAddressBookTypeData();
    }

    getFilteredAddressBookTypeData(): void {
        this.filteredAddressBookTypeDataForTasks = null;
        forkJoin(
            this.physicianStatusTaskService.getAll(),
            this.physicianStatusTaskService.getGroupedTaskTemplatesByAddressBookType(this.selectedAddressBookType.Id),
            this.taskTemplateService.getPhysicianStatusTasksByAddressBookType(this.selectedAddressBookType.Id),
        ).subscribe(([statusTaskAssociations, groupedTasks, filteredAddressBookTypeData]) => {
            this.tasksGroupedByServiceStatus = groupedTasks;
            this.filteredAddressBookTypeDataForTasks = { ...filteredAddressBookTypeData };
            this.filteredStatusTaskAssociations = statusTaskAssociations.filter((x) => x.AddressBookTypeId === this.selectedAddressBookType.Id);
            this.filteredAddressBookTypeDataForTasks.PhysicianStatuses = this.filteredAddressBookTypeDataForTasks.PhysicianStatuses.filter(
                (x) => !this.filteredStatusTaskAssociations.some((y) => y.PhysicianStatusId === x.Id),
            );
        });
    }

    saveTaskAssociation(event: IStatusTaskAssociation): void {
        const statusTaskAssociation: IPhysicianStatusTaskTemplate = {
            AddressBookTypeId: this.selectedAddressBookType.Id,
            Id: 0,
            PhysicianStatusId: event.PhysicianStatus.Id,
            TaskTemplateId: event.TaskTemplate.Id,
        };

        this.physicianStatusTaskService.create(statusTaskAssociation).subscribe((id) => {
            statusTaskAssociation.Id = id;
            this.filteredStatusTaskAssociations.push(statusTaskAssociation);
            this.filteredAddressBookTypeDataForTasks.PhysicianStatuses = this.filteredAddressBookTypeDataForTasks.PhysicianStatuses.filter(
                (x) => x.Id !== statusTaskAssociation.PhysicianStatusId,
            );
            this.notificationService.success('Status task association saved successfully.');
        });
    }

    addStatusTaskAssociation(): void {
        this.tasksGroupedByServiceStatus.push({
            PhysicianStatus: null,
            TaskTemplates: [],
        } as IGroupedTaskTemplatesByPhysicianStatusDTO);
    }

    deleteStatusTaskAssociation(statusTaskAssociation: IStatusTaskAssociation, index: number): void {
        const id = this.filteredStatusTaskAssociations.find(
            (x) => x.PhysicianStatusId === statusTaskAssociation.PhysicianStatus.Id && x.TaskTemplateId === statusTaskAssociation.TaskTemplate.Id,
        ).Id;
        this.physicianStatusTaskService.delete(id).subscribe(() => {
            this.filteredStatusTaskAssociations = this.filteredStatusTaskAssociations.filter((x) => x.Id !== id);
            this.tasksGroupedByServiceStatus[index].TaskTemplates = this.tasksGroupedByServiceStatus[index].TaskTemplates.filter(
                (x) => x.Id !== statusTaskAssociation.TaskTemplate.Id,
            );

            if (!this.tasksGroupedByServiceStatus[index].TaskTemplates.length) {
                this.tasksGroupedByServiceStatus.splice(index, 1);
                this.filteredAddressBookTypeDataForTasks.PhysicianStatuses.push(statusTaskAssociation.PhysicianStatus);
            }
            this.notificationService.info('Status task association deleted successfully.');
        });
    }
}
