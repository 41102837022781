import { Component, Input } from '@angular/core';
import { AddressBooksSelectService } from '../../common/address-book-select-list/address-books-select.service';
import { IAddressBookSelectEntity } from '../../common/address-book-select-list/address-books-select-entity';
import { IImedClaimAdditionalOtherDTO } from '@model/interfaces/custom/imed-claim-additional-other.dto';
import { ImedClaimAdditionalOtherService } from '../imed-claim-additional-others/imed-claim-additional-others.service';

@Component({
    selector: 'app-imed-claim-additional-other',
    templateUrl: './imed-claim-additional-other.component.html',
})
export class ImedClaimAdditionalOtherComponent {
    @Input() AdditionalOther: IImedClaimAdditionalOtherDTO;
    @Input() index: number;

    constructor(private addressSelectService: AddressBooksSelectService, private imedClaimAdditionalOtherService: ImedClaimAdditionalOtherService) {}

    ClearAddressBooks(): void {
        this.imedClaimAdditionalOtherService.newAdditionalOthers.splice(this.index, 1);
        if (this.imedClaimAdditionalOtherService.newAdditionalOthers.length === 0) {
            this.imedClaimAdditionalOtherService.newAdditionalOthers.push(this.imedClaimAdditionalOtherService.getEmptyImedClaimAdditionalOther());
        }
    }

    SearchAddressBooks(): void {
        this.addressSelectService.openAddressBookSelect('AdditionalOther', this.select);
    }

    select = (addressBookSelect: IAddressBookSelectEntity): void => {
        this.AdditionalOther.AddressBookId = addressBookSelect.AddressBookId;
        this.AdditionalOther.IndividualId = addressBookSelect.IndividualId;
        this.AdditionalOther.Name =
            addressBookSelect.IndividualName && addressBookSelect.IndividualName.length
                ? addressBookSelect.IndividualName
                : addressBookSelect.AddressBookName;
    }
}
