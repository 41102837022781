// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            .alternateColorItem:hover {
                background-color: #f5f5f5;
            }
            .alternateColorItem:nth-child(even) {
                background-color: #ececec;
            }
            .alternateColorItem:first-child {
                border-top: 1px solid #ddd;
            }
            .alternateColorItem:last-child {
                border-bottom: 1px solid #ddd;
            }
            .special-text {
                color: rgb(245, 66, 206);
                font-size: 14px;
                word-wrap: break-word;
                white-space: pre-wrap;
            }
        `, "",{"version":3,"sources":["webpack://./src/app/common/mini-dashboard-list/mini-dashboard-list.component.ts"],"names":[],"mappings":";YACY;gBACI,yBAAyB;YAC7B;YACA;gBACI,yBAAyB;YAC7B;YACA;gBACI,0BAA0B;YAC9B;YACA;gBACI,6BAA6B;YACjC;YACA;gBACI,wBAAwB;gBACxB,eAAe;gBACf,qBAAqB;gBACrB,qBAAqB;YACzB","sourcesContent":["\n            .alternateColorItem:hover {\n                background-color: #f5f5f5;\n            }\n            .alternateColorItem:nth-child(even) {\n                background-color: #ececec;\n            }\n            .alternateColorItem:first-child {\n                border-top: 1px solid #ddd;\n            }\n            .alternateColorItem:last-child {\n                border-bottom: 1px solid #ddd;\n            }\n            .special-text {\n                color: rgb(245, 66, 206);\n                font-size: 14px;\n                word-wrap: break-word;\n                white-space: pre-wrap;\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
