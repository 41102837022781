import { IAutoIssue } from '@model/interfaces/auto-issue';
import { AutoIssueDynamicControlsPartial } from '@model/partials/auto-issue.fom-controls';
import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';

export class AutoIssueDynamicConfig<T extends IAutoIssue> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private autoIssue: T, private configControls?: string[]) {
        super();

        const dynamicControls = new AutoIssueDynamicControlsPartial(this.autoIssue);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = [
                'AdLsThatMayAffectThisClaim',
                'Apportionment',
                'AppropriatenessOfTreatment',
                'CausalRelationship',
                'CompleteHistory',
                'DegreeOfDisability',
                'Diagnosis',
                'FunctionalCapabilityEvaluation',
                'HistoryOfAllegedInjury',
                'Mmi',
                'NeedForContinuedFollowUp',
                'NeedForDme',
                'NeedForEssentialServicesHouseholdHelp',
                'NeedForFurtherDiagnosticTesting',
                'NeedForFurtherTreatment',
                'NeedForFurtherTreatmentWithinYourScope',
                'NeedForInjections',
                'NeedForMassage',
                'NeedForPhysicalTherapy',
                'NeedForPrescriptionMedication',
                'NeedForSurgery',
                'NeedForTransportation',
                'ObjectiveFindingsOnExamDiagnosis',
                'OccupationalHistory',
                'PastSubsequentMedicalHistory',
                'Permanency',
                'PreExistingConditions',
                'PresentComplaints',
                'RecommendationForFurtherTreatment',
                'ReturnToWorkRestrictionsAdl',
                'ReviewOfRecords',
                'Slu',
                'SpecificTreatmentQuestions',
            ];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
