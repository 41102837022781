import { AfterContentInit, Component } from '@angular/core';
import { CommonNotesComponent, CommonNotesListComponent } from '@mt-ng2/entity-components-notes';
import { ImedClaimAdjusterInquiriesListConfig } from './adjuster-inquiry-notes-list.entity-list-config';

@Component({
    selector: 'app-adjuster-inquiries-list',
    templateUrl: './adjuster-inquiry-notes-list.component.html',
})
export class ImedClaimAdjusterInquiriesListComponent extends CommonNotesListComponent implements AfterContentInit {
    ngAfterContentInit(): void {
        this.objectName = 'Adjuster Inquiry';
        this.itemsPerPage = 20;
        this.entityListConfig = new ImedClaimAdjusterInquiriesListConfig(this.disableSort);
    }
}
