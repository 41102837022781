import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { Observable } from 'rxjs';
import { IMasterFollowupTypeEmailTemplate } from '@model/interfaces/master-followup-type-email-template';
import { IGroupedEmailTemplatesByFollowupDTO } from '@model/interfaces/custom/grouped-email-templates-by-followup-dto';

export const emptyEmailTemplateAssociation: IMasterFollowupTypeEmailTemplate = {
    EmailTemplateId: null,
    Id: 0,
    MasterFollowupTypeId: null,
    ServiceId: null,
};

@Injectable({
    providedIn: 'root',
})
export class MasterFollowupTypeEmailTemplateService extends BaseService<IMasterFollowupTypeEmailTemplate> {
    constructor(public http: HttpClient) {
        super('/masterfollowuptypeemailtemplates', http);
    }

    getEmptyEmailTemplateAssociation(): IMasterFollowupTypeEmailTemplate {
        return emptyEmailTemplateAssociation;
    }

    getEmailTemplatesGroupedByFollowup(serviceId: number): Observable<IGroupedEmailTemplatesByFollowupDTO[]> {
        return this.http.get<IGroupedEmailTemplatesByFollowupDTO[]>(`/masterfollowuptypeemailtemplates/${serviceId}/grouped-email-templates`);
    }
}
