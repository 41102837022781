import { IUserRole } from '@model/interfaces/user-role';
import { DynamicConfig, IDynamicConfig, IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { IDashboardType } from '@model/interfaces/dashboard-type';
import { IUserType } from '@model/interfaces/user-type';
import { IUserRoleDynamicControlsPartialParameters, UserRoleDynamicControlsPartial } from '@model/partials/user-role-partial.form-controls';

export class UserRoleDynamicConfig<T extends IUserRole> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private userRole: T, private dashboardTypes: IDashboardType[], private userTypes: IUserType[], private configControls?: string[]) {
        super();
        const additionalParams: IUserRoleDynamicControlsPartialParameters = {
            dashboardTypes: this.dashboardTypes,
            userTypes: this.userTypes,
        };
        const dynamicControls = new UserRoleDynamicControlsPartial(this.userRole, additionalParams);
        if (!configControls) {
            this.configControls = ['Name', 'Description'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
