import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { IImedClaimServiceFacilityFee } from '../interfaces/imed-claim-service-facility-fee';
import { IUser } from '../interfaces/user';
import { IImedClaimService } from '../interfaces/imed-claim-service';

export interface IImedClaimServiceFacilityFeeDynamicControlsParameters {
    formGroup?: string;
    imedClaimServices?: IImedClaimService[];
    createdBies?: IUser[];
    modifiedBies?: IUser[];
}

export class ImedClaimServiceFacilityFeeDynamicControls {

    formGroup: string;
    imedClaimServices?: IImedClaimService[];
    createdBies?: IUser[];
    modifiedBies?: IUser[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private imedclaimservicefacilityfee?: IImedClaimServiceFacilityFee, additionalParameters?: IImedClaimServiceFacilityFeeDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'ImedClaimServiceFacilityFee';
        this.imedClaimServices = additionalParameters && additionalParameters.imedClaimServices || undefined;
        this.createdBies = additionalParameters && additionalParameters.createdBies || undefined;
        this.modifiedBies = additionalParameters && additionalParameters.modifiedBies || undefined;

        this.Form = {
            CreatedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Created By',
                name: 'CreatedById',
                options: this.createdBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.imedclaimservicefacilityfee && this.imedclaimservicefacilityfee.CreatedById || null,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservicefacilityfee && this.imedclaimservicefacilityfee.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservicefacilityfee && this.imedclaimservicefacilityfee.DateModified || null,
            }),
            Fees: new DynamicField({
                formGroup: this.formGroup,
                label: 'Fees',
                name: 'Fees',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservicefacilityfee && this.imedclaimservicefacilityfee.Fees || 0,
            }),
            ImedClaimServiceId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Imed Claim Service',
                name: 'ImedClaimServiceId',
                options: this.imedClaimServices,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.imedclaimservicefacilityfee && this.imedclaimservicefacilityfee.ImedClaimServiceId || null,
            }),
            ModifiedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Modified By',
                name: 'ModifiedById',
                options: this.modifiedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservicefacilityfee && this.imedclaimservicefacilityfee.ModifiedById || null,
            }),
        };

        this.View = {
            CreatedById: new DynamicLabel({
                label: 'Created By',
                value: getMetaItemValue(this.createdBies as unknown as IMetaItem[], this.imedclaimservicefacilityfee && this.imedclaimservicefacilityfee.hasOwnProperty('CreatedById') ? this.imedclaimservicefacilityfee.CreatedById : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            DateCreated: new DynamicLabel({
                label: 'Date Created',
                value: this.imedclaimservicefacilityfee && this.imedclaimservicefacilityfee.DateCreated || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            DateModified: new DynamicLabel({
                label: 'Date Modified',
                value: this.imedclaimservicefacilityfee && this.imedclaimservicefacilityfee.DateModified || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            Fees: new DynamicLabel({
                label: 'Fees',
                value: this.imedclaimservicefacilityfee && this.imedclaimservicefacilityfee.Fees || 0,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            ImedClaimServiceId: new DynamicLabel({
                label: 'Imed Claim Service',
                value: getMetaItemValue(this.imedClaimServices as unknown as IMetaItem[], this.imedclaimservicefacilityfee && this.imedclaimservicefacilityfee.hasOwnProperty('ImedClaimServiceId') ? this.imedclaimservicefacilityfee.ImedClaimServiceId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            ModifiedById: new DynamicLabel({
                label: 'Modified By',
                value: getMetaItemValue(this.modifiedBies as unknown as IMetaItem[], this.imedclaimservicefacilityfee && this.imedclaimservicefacilityfee.hasOwnProperty('ModifiedById') ? this.imedclaimservicefacilityfee.ModifiedById : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
        };

    }
}
