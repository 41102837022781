import { IImedClaimServiceFollowup } from '@model/interfaces/imed-claim-service-followup.d';
import { Subscription, Observable } from 'rxjs';
import {
    PhysicianStatusesIdEnums,
    ServiceStatusIdEnums,
    StatusValidationMessageTypeEnums,
    ValidationOverrideEnums,
    SystemGeneratedEmailTemplateEnums,
} from './../../../../common/constants/Enums';
import { AuthService } from '@mt-ng2/auth-module';
import { PhysicianService } from './../../../../address-books/individuals/physicians/physician.service';
import { ImedClaimServiceService } from './../../imedclaimservice.service';
import { IImeAppointment } from '@model/interfaces/ime-appointment.d';
import { IEmailAddress } from '@model/interfaces/email-address.d';
import { IServiceStatus } from '@model/interfaces/service-status.d';
import { IImedClaimService } from '@model/interfaces/imed-claim-service.d';
import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IAttachmentDTO } from '@model/interfaces/custom/attachment-dto';
import { finalize, map } from 'rxjs/operators';
import { IImedClaimStatusResponse } from '@model/interfaces/custom/imed-claim-status-response';
import { HttpErrorResponse } from '@angular/common/http';
import { IImedClaimServiceStatusValidationDTO } from '@model/interfaces/custom/imed-claim-service-status-validation-dto';
import { EmailTemplateService } from '../../../../email-templates/emailtemplate.service';
import { CommonEmailComponent } from '../../../../common/common-email/common-email.component';
import { ExtraSearchParams, IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { IModalOptions, IModalWrapperApi } from '@mt-ng2/modal-module';
import { IEmailTemplate } from '@model/interfaces/email-template';
import { IPhysician } from '@model/interfaces/physician';
import { IPhysicianDetail } from '@model/interfaces/physician-detail';

@Component({
    selector: 'app-imed-claim-service-status',
    styles: [
        `
    .pretty-select-wrapper {
        font-size: 16px;
        position: relative;
        border-radius:5px;
        -webkit-transition: .4s;
         transition: .4s;
        -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,.2), 0 3px 10px 0 rgba(0,0,0,.18824);
        box-shadow: 0 2px 5px 0 rgba(0,0,0,.2), 0 3px 10px 0 rgba(0,0,0,.18824);
    }

    .pretty-select-wrapper:hover {
        -webkit-box-shadow: 0 8px 20px 0 rgba(0,0,0,.2), 0 4px 15px 0 rgba(0,0,0,.18824);
        box-shadow: 0 8px 20px  0 rgba(0,0,0,.2), 0 4px 15px 0 rgba(0,0,0,.18824);
        z-index: 200;
    }

    .pretty-select-wrapper .arr {
        bottom: 5px;
        position: absolute;
        right: 5px;
        top: 10px;
        width: 45px;
        pointer-events: none;
    }
    .pretty-select:hover {
        -webkit-transition: border-color 0.3s;
        transition: border-color 0.3s;

    }

    .pretty-select {
        outline: none;
        -webkit-appearance: none;
        display: block;
        padding: 0.7em 3em 0.8em 1.5em;
        margin: 0;
        -webkit-transition: border-color 0.2s;
        transition: border-color 0.2s;
        border: 2px solid #9dbff2;
        border-radius: 5px;
        background: #fff;
        color: #555;
        height:100%;
        font-weight:700
    }
    `,
    ],
    templateUrl: './imed-claim-service-status.component.html',
})
export class ImedClaimServiceStatusComponent implements OnInit {
    @Input() imedClaimServiceModel: IImedClaimService;
    @Input() canEdit: boolean;
    @Input() serviceStatus: IServiceStatus[];
    @Input() defenseAttorneyEmailAddress: IEmailAddress;
    @Input() latestAppointment: IImeAppointment;
    @Output('onRefreshStatus') onRefreshStatus: EventEmitter<any> = new EventEmitter<any>();
    @Output('authorizationDocumentUploaded') authorizationDocumentUploaded: EventEmitter<any> = new EventEmitter<any>();
    @Input('documentArray') documentArray: IAttachmentDTO[];
    @Input() isAuhorizationDocumentUploaded: boolean;
    @ViewChild('emailComponent') emailComponent: CommonEmailComponent;

    isEditing: boolean;
    isHovered: boolean;
    latestFollowup: IImedClaimServiceFollowup;
    doubleClickIsDisabled = false;
    subscriptions: Subscription = new Subscription();
    formRendered = false;
    selectedStatus: IServiceStatus;
    ImedClaimServiceId: number;
    ServiceId: number;
    HasPreLitigationCheckboxChecked: boolean;
    HasPreLitigationDocumentUploaded: boolean;
    isSoftPopup = false;
    savedStatusID: number;
    validationOverrides: ValidationOverrideEnums[] = [];
    showEmailControl = false;
    showPendingReceiptOfRecordsEmailStatuses: ServiceStatusIdEnums[] = [
        ServiceStatusIdEnums.IME_Scheduled_Pending_Receipt_Of_Records,
        ServiceStatusIdEnums.IME_Re_Exam_Scheduled_Pending_Receipt_Of_Records,
        ServiceStatusIdEnums.IME_Addendum_Scheduled_Pending_Receipt_Of_Records,
        ServiceStatusIdEnums.IME_Expert_Consultation_Scheduled_Pending_Receipt_Of_Records,
        ServiceStatusIdEnums.Billing_Desk_Review_Scheduled_Pending_Receipt_Of_Records,
        ServiceStatusIdEnums.Record_Review_Scheduled_Pending_Receipt_Of_Records,
    ];
    updatePhysicianServiceStatuses: ServiceStatusIdEnums[] = [
        ServiceStatusIdEnums.IME_Complete,
        ServiceStatusIdEnums.IME_Complete_To_Be_Invoiced,
        ServiceStatusIdEnums.IME_Addendum_Complete,
        ServiceStatusIdEnums.IME_Re_Exam_Complete,
        ServiceStatusIdEnums.IME_Expert_Consultation_Complete,
        ServiceStatusIdEnums.IME_Conference_Call_Completed,
    ];

    modalOptions: IModalOptions = {
        customClass: 'modal-hide-actions' as any,
        width: 800,
    };
    uploadAttachmentsModalApi: IModalWrapperApi;

    constructor(
        private imedClaimServiceService: ImedClaimServiceService,
        private notificationsService: NotificationsService,
        private physicianService: PhysicianService,
        private authService: AuthService,
        private emailTemplateService: EmailTemplateService,
    ) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.imedClaimServiceService.changeEmitted$.subscribe((imedClaimService) => {
                this.imedClaimServiceModel = imedClaimService;
                this.isEditing = false;
                this.setConfig();
            }),
        );

        this.isEditing = false;
        this.setConfig();
    }

    setConfig(): void {
        this.formRendered = false;
        this.savedStatusID = this.imedClaimServiceModel.ServiceStatusId;
        this.selectedStatus = this.serviceStatus.find((x) => x.Id === this.imedClaimServiceModel.ServiceStatusId);
        this.formRendered = true;
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    ShowDocumentPopup(): void {
        return this.uploadAttachmentsModalApi.show();
    }

    onDocumentChanged(): void {
        this.authorizationDocumentUploaded.emit();
    }

    onDocumentSaved(): void {
        this.uploadAttachmentsModalApi.close();
        this.saveServiceStatus();
    }

    onCloseDocument(): void {
        this.uploadAttachmentsModalApi.close();
        if (this.isSoftPopup) {
            this.saveServiceStatus();
        } else {
            this.error();
            this.imedClaimServiceModel.ServiceStatusId = this.savedStatusID;
            this.setConfig();

        }
    }

    updatePhysicianStatus(physicianDetail: IPhysicianDetail): void {
        if (physicianDetail.PhysicianStatusId === +PhysicianStatusesIdEnums.Trial_Period && physicianDetail.ServiceCompletedOnTrialPeriod < 2) {
            physicianDetail.ModifiedById = this.authService.currentUser.getValue().Id;
            physicianDetail.DateModified = new Date();
            physicianDetail.ServiceCompletedOnTrialPeriod = physicianDetail.ServiceCompletedOnTrialPeriod + 1;
        } else if (physicianDetail.PhysicianStatusId === +PhysicianStatusesIdEnums.Trial_Period && physicianDetail.ServiceCompletedOnTrialPeriod === 2) {
            physicianDetail.ModifiedById = this.authService.currentUser.getValue().Id;
            physicianDetail.DateModified = new Date();
            physicianDetail.ServiceCompletedOnTrialPeriod = physicianDetail.ServiceCompletedOnTrialPeriod + 1;
            physicianDetail.PhysicianStatusId = PhysicianStatusesIdEnums.Active;
        }
        this.physicianService.updateServicePhysicianStatus(physicianDetail).subscribe(({ body: { Id, EmailTemplates } }) => {
            if (EmailTemplates && EmailTemplates.length > 0) {
                this.updateAndShowEmailComponent(EmailTemplates as IEmailTemplate[], Id as number);
            }
        });
    }

    showScheduledPendingReceiptOfRecordsEmailForm(): void {
        this.showEmailControl = true;
        const _extraSearchParams: ExtraSearchParams[] = [];
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'includeArchived',
                value: '0',
            }),
        );
        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: 'Id',
            orderDirection: 'asc',
            query: '',
        };
        const searchparams = new SearchParams(searchEntity);
        this.emailTemplateService.search(searchparams).subscribe((templates) => {
            this.emailComponent.imedClaimServiceId = this.imedClaimServiceModel.Id;
            this.emailComponent.imedClaimId = this.imedClaimServiceModel.ImedClaimId;
            this.emailComponent.emailTemplates = templates.body;
            this.emailComponent.selectedTemplate = this.emailComponent.emailTemplates.find(
                (template) => template.Id === +SystemGeneratedEmailTemplateEnums.SCHEDULED_PENDING_RECEIPT_OF_RECORDS,
            );
            this.emailComponent.mergeTemplate();
            this.emailComponent.showEmailForm();
        });
    }
    showHardPopup(imedClaimServiceModel: IImedClaimService, message = ''): Observable<boolean> {
        return this.imedClaimServiceService.getHardPopupMessages(imedClaimServiceModel, message).pipe(
            map((result) => {
                if (result.value) {
                    return true;
                } else {
                    return false;
                }
            }),
        );
    }

    showSoftPopup(message = ''): Observable<boolean> {
        return this.imedClaimServiceService.getSoftPopupMessages(message).pipe(
            map((result) => {
                if (result.value) {
                    return true;
                } else {
                    return false;
                }
            }),
        );
    }

    saveServiceStatus(): void {
        this.imedClaimServiceModel.ServiceStatusId = this.selectedStatus.Id;
        this.imedClaimServiceModel.ModifiedById = this.authService.currentUser.getValue().Id;
        this.imedClaimServiceModel.DateModified = new Date();
        this.imedClaimServiceModel.StatusSetDate = new Date();
        this.imedClaimServiceModel.Individual = null;
        const imedClaimServiceStatusDTO: IImedClaimServiceStatusValidationDTO = {
            imedClaimService: this.imedClaimServiceModel,
            validationOverrides: this.validationOverrides,
        };

        this.imedClaimServiceService
            .updateStatus(imedClaimServiceStatusDTO)
            .pipe()
            .subscribe(
                () => {
                    if (this.updatePhysicianServiceStatuses.indexOf(this.imedClaimServiceModel.ServiceStatusId) > -1) {
                        const physician: IPhysician = this.imedClaimServiceModel.Physician;
                        const physicianDetail: IPhysicianDetail = physician.PhysicianDetails.find((pd) => pd.ImedClaimTypeId === this.imedClaimServiceModel.ImedClaim.ClaimTypeId);
                        this.updatePhysicianStatus(physicianDetail);
                    }

                    if (this.showPendingReceiptOfRecordsEmailStatuses.indexOf(this.imedClaimServiceModel.ServiceStatusId) > -1) {
                        this.showScheduledPendingReceiptOfRecordsEmailForm();
                    }

                    this.isEditing = false;
                    this.success();
                    this.validationOverrides = [];
                    this.imedClaimServiceService.emitChange(this.imedClaimServiceModel);
                    this.onRefreshStatus.emit();
                    this.setConfig();
                },
                (error: HttpErrorResponse) => {
                    const response = error.error as IImedClaimStatusResponse;

                    if (response.MessageType === StatusValidationMessageTypeEnums.Authorization_Doc_Hard_Pop_Up) {
                        this.isSoftPopup = false;
                        this.ShowDocumentPopup();
                    }

                    if (response.MessageType === StatusValidationMessageTypeEnums.Authorization_Doc_Soft_Pop_Up) {
                        this.isSoftPopup = true;
                        this.validationOverrides.push(ValidationOverrideEnums.AuthorizationDocument);
                        this.ShowDocumentPopup();
                    }

                    if (response.MessageType === StatusValidationMessageTypeEnums.Legal_Foundation_Soft_Pop_Up) {
                        this.showSoftPopup(response.ErrMessage).subscribe((result) => {
                            if (result) {
                                this.validationOverrides.push(ValidationOverrideEnums.LegalFoundation);
                                this.saveServiceStatus();
                            } else {
                                this.resetStatus();
                            }
                        });
                    }

                    if (response.MessageType === StatusValidationMessageTypeEnums.Hard_Pop_Up) {
                        this.resetStatus();
                        this.showHardPopup(null, response.ErrMessage).subscribe();
                    }

                    if (response.MessageType === StatusValidationMessageTypeEnums.Soft_Pop_Up) {
                        this.showSoftPopup(response.ErrMessage).subscribe((result) => {
                            if (result) {
                                this.saveServiceStatus();
                            } else {
                                this.resetStatus();
                            }
                        });
                    }
                },
            );
    }

    handleEmailConfirmationResponse(confirmed: boolean): void {
        if (!confirmed) {
            this.resetStatus();
        } else {
            this.validationOverrides.push(ValidationOverrideEnums.EmailConfirmation);
            this.saveServiceStatus();
        }
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.notificationsService.success('Status Updated Successfully');
    }

    clearStatus(): void {
        this.selectedStatus = this.serviceStatus.find((x) => x.Id === this.imedClaimServiceModel.ServiceStatusId);
    }

    resetStatus(): void {
        this.imedClaimServiceModel.ServiceStatusId = this.savedStatusID;
        this.setConfig();
    }

    updateAndShowEmailComponent(templates: IEmailTemplate[], individualId: number): void {
        this.emailComponent.emailTemplates = templates;
        this.emailComponent.individualId = individualId;
        this.emailComponent.showEmailForm();
    }
}
