import { FollowupExemptionComponent } from './followup-exemption/followup-exemption.component';
import { NgModule } from '@angular/core';
import { ImedCommonModule } from '../common/imed-common.module';
import { AddSystemTaskFormComponent } from './add-system-task-form/add-system-task-form.component';
import { SystemTaskListComponent } from './system-task-list/system-task-list.component';
import { CommonModule } from '@angular/common';
import { TaskStatusFollowupEngineHeaderComponent } from './task-status-followup-engine-header/task-status-follow-engine-header.component';
import { TaskStatusFollowupEngineRoutingModule } from './task-status-followup-engine-routing.module';
import { TaskTemplateService } from './services/tasktemplate.service';
import { TaskTemplateRecurrenceScheduleComponent } from './task-template-recurrence-schedule/task-template-recurrence-schedule.component';
import { SystemTaskBasicInfoComponent } from './system-task-basic-info/system-task-basic-info.component';
import { SystemTaskDetailComponent } from './system-task-detail/system-task-detail.component';
import { SystemTaskTemplateAssociationsComponent } from './system-task-template-associations/system-task-template-associations.component';
import { SystemTaskDeleteCellComponent } from './system-task-list/system-task-delete-cell.component';
import { SystemTaskCopyCellComponent } from './system-task-list/system-task-copy-cell.component';
import { SystemTaskStatusAssociationsComponent } from './system-task-associations/system-task-status-associations/system-task-status-associations.component';
import { MasterServiceStatusFollowupTypeService } from './services/service-status-followup.service';
import { StatusTaskRowComponent } from './system-task-associations/system-task-status-associations/status-task-row/status-task-row.component';
import { MasterServiceStatusTaskTemplateService } from './services/service-status-task.service';
import { ServiceSelectComponent } from './system-task-associations/service-select/service-select.component';
import { AssociationsToggleService } from './services/associations-toggle.service';
import { SystemTaskFollowupAssociationsComponent } from './system-task-associations/system-task-followup-associations/system-task-followup-associations.component';
import { MasterFollowupTypeServiceStatusService } from './services/followup-type-service-status.service';
import { BaseRowComponent } from './system-task-associations/base-row/base-row.component';
import { FollowupStatusRowComponent } from './system-task-associations/system-task-followup-associations/followup-status-row/followup-status-row.component';
import { StatusFollowupRowComponent } from './system-task-associations/system-task-status-associations/status-followup-row/status-followup-row.component';
import { FollowupTaskRowComponent } from './system-task-associations/system-task-followup-associations/followup-task-row/followup-task-row.component';
import { MasterFollowupTypeTaskTemplateService } from './services/followup-type-task.service';
import { FollowupTypeExecmptionService } from './services/followup-type-exemption.service';
import { AssociationServiceSelectionService } from './services/associations-service-selection.service';
import { FollowupEmailTemplateRowComponent } from './email-template-associations/email-template-followup-associations/followup-email-template-row/followup-email-template-row.component';
import { ImedClaimFollowupEngineHeaderComponent } from './imedclaim-type-followup-associations/imedclaim-followup-engine-header.component';
import { SystemTaskAssociationsDetailComponent } from './system-task-associations/system-task-associations-detail/system-task-associations-detail.component';
import { FollowupEmailTemplateAssociationsComponent } from './email-template-associations/email-template-followup-associations/followup-service-email-template-associations/followup-email-template-associations.component';
import { ImedClaimFollowUpEmailTemplateAssociationsComponent } from './imedclaim-type-followup-associations/imedclaim-followup-email-template-associations/imedclaim-followup-email-template-associations.component';
import { ImedClaimTypeFollowUpAssociationsDetailComponent } from './imedclaim-type-followup-associations/imedclaim-type-associations-detail/imedclaim-type-associations-detail.component';
import { ImedClaimFollowupSystemTaskAssociationsComponent } from './imedclaim-type-followup-associations/imedclaim-followup-system-task-associations/imedclaim-followup-system-task-associations.component';
import { ImedClaimTypeSelectComponent } from './imedclaim-type-followup-associations/imedclaim-type-select/imedclaim-type-select.component';
import { ImedClaimAssociationSelectionService } from './imedclaim-type-followup-associations/services/imedclaim-associations-selection.service';
import { ImedClaimTypeAssociationsToggleService } from './imedclaim-type-followup-associations/services/imedclaim-type-associations-toggle.service';
import { ImedClaimMasterFollowupTypeTaskTemplateService } from './services/imedclaim-followup-type-task.service';


@NgModule({
    declarations: [
        TaskStatusFollowupEngineHeaderComponent,
        ImedClaimFollowupEngineHeaderComponent,
        AddSystemTaskFormComponent,
        SystemTaskListComponent,
        TaskTemplateRecurrenceScheduleComponent,
        SystemTaskDetailComponent,
        BaseRowComponent,
        SystemTaskBasicInfoComponent,
        SystemTaskTemplateAssociationsComponent,
        SystemTaskDeleteCellComponent,
        SystemTaskFollowupAssociationsComponent,
        FollowupExemptionComponent,
        ServiceSelectComponent,
        SystemTaskCopyCellComponent,
        SystemTaskStatusAssociationsComponent,
        SystemTaskAssociationsDetailComponent,
        StatusFollowupRowComponent,
        StatusTaskRowComponent,
        FollowupStatusRowComponent,
        FollowupTaskRowComponent,
        FollowupEmailTemplateRowComponent,
        FollowupEmailTemplateAssociationsComponent,
        ImedClaimFollowUpEmailTemplateAssociationsComponent,
        ImedClaimTypeFollowUpAssociationsDetailComponent,
        ImedClaimFollowupSystemTaskAssociationsComponent,
        ImedClaimTypeSelectComponent
    ],
    imports: [CommonModule, ImedCommonModule, TaskStatusFollowupEngineRoutingModule]
})
export class TaskStatusFollowupEngineModule {
    static forRoot(): any {
        return {
            ngModule: TaskStatusFollowupEngineModule,
            providers: [
                MasterServiceStatusTaskTemplateService,
                MasterServiceStatusFollowupTypeService,
                MasterFollowupTypeServiceStatusService,
                MasterFollowupTypeTaskTemplateService,
                TaskTemplateService,
                AssociationsToggleService,
                FollowupTypeExecmptionService,
                AssociationServiceSelectionService,
                ImedClaimAssociationSelectionService,
                ImedClaimTypeAssociationsToggleService,
                ImedClaimMasterFollowupTypeTaskTemplateService,
            ],
        };
    }
}
