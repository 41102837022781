import { Component } from '@angular/core';

import { IEntityListDynamicCellComponent, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { IEntity } from '@mt-ng2/base-service';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IModalOptions } from '@mt-ng2/modal-module';


import { ClaimTypes } from '@model/ClaimTypes';
import { IViewGetCaseFollowupsForList } from '@model/interfaces/view-get-case-followups-for-list';

@Component({
    template: `
        <div *ngIf="imedClaimFollowup && canDelete">
            <div *ngIf="imedClaimFollowup.Archived" class="text-center">
                <i (mtConfirm)="archiveFollowup($event)" class="fa fa-trash fa-lg" aria-hidden="true" [mtConfirmOptions]="unarchiveConfirm"></i
                ><i class="fa fa-lg fa-undo overlay-fa"></i>
            </div>
            <div *ngIf="!imedClaimFollowup.Archived" class="text-center">
                <i (mtConfirm)="archiveFollowup($event)" class="fa fa-trash fa-lg" aria-hidden="true" [mtConfirmOptions]="archiveConfirm"></i>
            </div>
        </div>
    `,
})
export class CaseFollowupDeleteDynamicCellComponent implements IEntityListDynamicCellComponent {
    entityListComponentMembers: IEntityListComponentMembers;
    set entity(value: IEntity) {
        this.imedClaimFollowup = value as IViewGetCaseFollowupsForList;
    }

    imedClaimFollowup: IViewGetCaseFollowupsForList;
    canDelete: boolean;

    archiveConfirm: IModalOptions = {
        confirmButtonText: 'Yes, I am sure!',
        text: `Are you sure you want to delete this followup?`,
        title: 'Delete Followup',
    	icon: 'question',
};

    unarchiveConfirm: IModalOptions = {
        confirmButtonText: 'Yes, I am sure!',
        text: `Are you sure you want to restore this followup?`,
        title: 'Restore Followup',
    	icon: 'question',
};

    constructor(private claimsService: ClaimsService) { }

    ngOnInit(): void {
        this.canDelete = this.claimsService.hasClaim(ClaimTypes.CanDeleteFollowups, [ClaimValues.FullAccess]);
    }

    archiveFollowup(event: Event): void {
        this.entityListComponentMembers.itemDeleted(this.imedClaimFollowup as IEntity, event);
    }
}
