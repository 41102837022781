import { CurrencyFormatterService } from './../../../../../common/services/currency-formatter.service';
import { ConferenceCallServiceService } from './../conference-call-service.service';
import { ServiceStatusService } from './../../../common-components/service-status/servicestatus.service';
import { ImedClaimService } from './../../../../imedclaim.service';
import { SubServiceService } from './../../../common-components/sub-services/sub-service.service';
import { ImeAppointmentService } from './../../ime-service/ime-appointments/ime-appointment.service';
import { ImedClaimServiceService } from './../../../imedclaimservice.service';
import { ImedClaimServiceFollowupService } from './../../../common-components/service-followups/imedclaimservicefollowup.service';
import { ImedClaimServiceStatusLogService } from './../../../common-components/service-status/imed-claim-service-status-log.service';
import { CommunicationLogService } from '../../../../../common/services/communication-log.service';
import { CommonImedClaimService } from './../../../../shared/common-imed-claim-service';
import { IConferenceCallService } from '@model/interfaces/conference-call-service.d';
import { AddressBooksSelectService } from './../../../../../common/address-book-select-list/address-books-select.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { PhysicianService } from '../../../../../address-books/individuals/physicians/physician.service';
import { BaseAttachmentsService } from '../../../../../common/attachments/base-attachments.service';
import { AttachmentServiceTypeEnums } from '../../../../../common/constants/Enums';
import { tap } from 'rxjs/operators';
import { ModalService } from '../../../../../common/services/modal.service';
import { AuthService, ClaimsService } from '@mt-ng2/auth-module';

@Component({
    selector: 'app-conference-call-service-detail',
    templateUrl: './conference-call-service-detail.component.html',
})
export class ConferenceCallServiceDetailComponent extends CommonImedClaimService implements OnInit, OnDestroy {
    conferenceCallServiceModel: IConferenceCallService;
    attachmentServiceTypeEnum: AttachmentServiceTypeEnums = AttachmentServiceTypeEnums.Conference_Call_Between_Attorney;

    constructor(
        private communicationLogService: CommunicationLogService,
        private router: Router,
        private route: ActivatedRoute,
        private imedclaimServiceStausLogService: ImedClaimServiceStatusLogService,
        private imedClaimServiceFollowupService: ImedClaimServiceFollowupService,
        private imedClaimServiceService: ImedClaimServiceService,
        private addressBookSelectService: AddressBooksSelectService,
        private currencyFormatterService: CurrencyFormatterService,
        private physicianService: PhysicianService,
        private imeAppointmentService: ImeAppointmentService,
        private subServiceService: SubServiceService,
        private imedClaimService: ImedClaimService,
        private serviceStatusService: ServiceStatusService,
        private conferenceCallServiceService: ConferenceCallServiceService,
        private attachmentsService: BaseAttachmentsService,
        private modalService: ModalService,
        private claimService: ClaimsService,
        private authService: AuthService,
    ) {
        super(
            communicationLogService,
            router,
            route,
            imedclaimServiceStausLogService,
            imedClaimServiceFollowupService,
            imedClaimServiceService,
            addressBookSelectService,
            currencyFormatterService,
            physicianService,
            imeAppointmentService,
            subServiceService,
            imedClaimService,
            serviceStatusService,
            attachmentsService,
            modalService,
            claimService,
            authService,
        );

        this.subscriptions.add(
            this.imedClaimServiceService.changeEmitted$.subscribe((imedClaimService) => {
                this.getCommunicationLog().subscribe();
            }),
        );
    }

    ngOnInit(): void {
        forkJoin(
            this.getImedClaimServiceStatusLog(),
            this.getCommunicationLog(),
            this.getFollowups(),
            this.getSubServices(),
            this.getServiceStatuses(),
            this.getPrimaryEmailForImedClaimDefenseAttorney(),
            this.getConferenceCallServiceByImedClaimServiceId(),
        ).subscribe(() => {
            this.subscribeToCommunicationLogs(), this.setPhysicianInformations();
            this.setIsPhysicianSelected(); // 1 - 1 there is not going to be more than 1 ImeServices_ImedClaimServiceId records
            this.setFormRendered();
        });

        this.editingComponent.next('');
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    getConferenceCallServiceByImedClaimServiceId(): Observable<IConferenceCallService> {
        return this.conferenceCallServiceService.getByImedClaimServiceId(this.imedClaimServiceModel.Id).pipe(
            tap((conferenceCallServiceService: IConferenceCallService) => {
                this.conferenceCallServiceModel = conferenceCallServiceService; // 1 - 1 there is not going to be more than 1 ImeServices_ImedClaimServiceId records
            }),
        );
    }
}
