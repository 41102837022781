import { IAutomatedTemplate } from '@model/interfaces/automated-template';
import { IEntityListConfig, EntityListConfig, EntityListColumn, SortDirection, EntityListDeleteColumn } from '@mt-ng2/entity-list-module';

export class AutomatedTemplatesEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    name: 'Name',
                }),
            ],
            delete: new EntityListDeleteColumn({
                columnHtml: (entity: IAutomatedTemplate) =>
                    entity.Archived
                        ? '<div class="text-center"><i class="fa fa-trash fa-lg aria-hidden="true"></i><i class="fa fa-lg fa-undo overlay-fa" ></i></div>'
                        : '<div class="text-center"><i class="fa fa-trash fa-lg" aria-hidden="true"></i></div>',
                confirm: false,
                headerText: '',
            }),
            rowClass: (entity: IAutomatedTemplate) => (entity.Archived ? 'archived' : null),
        };
        super(listConfig);
    }
}
