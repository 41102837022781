import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, OperatorFunction } from 'rxjs';
import { BaseService } from '@mt-ng2/base-service';
import { DatePipe } from '@angular/common';
import { catchError } from 'rxjs/operators';
import { IImedClaimServiceCourtFee } from '@model/interfaces/imed-claim-service-court-fee';
import { IImedClaimServiceFacilityFee } from '@model/interfaces/imed-claim-service-facility-fee';

export const emptyImedClaimServiceFacilityFee: IImedClaimServiceFacilityFee = {
    CreatedById: 0,
    DateCreated: null,
    Fees: 0,
    Id: 0,
    ImedClaimServiceId: 0,
};

@Injectable({
    providedIn: 'root',
})
export class ImedClaimServiceFacilityFeeService extends BaseService<IImedClaimServiceCourtFee> {
    newCourtFees: IImedClaimServiceCourtFee[] = [];
    constructor(public http: HttpClient, private datePipe: DatePipe) {
        super('/subpoenaservices', http);
    }

    getEmptyImedClaimFacilityServiceFee(): IImedClaimServiceFacilityFee {
        return { ...emptyImedClaimServiceFacilityFee };
    }

    getFacilityFees(imedClaimId: number): Observable<IImedClaimServiceFacilityFee[]> {
        return this.http.get<IImedClaimServiceFacilityFee[]>(`/subpoenaservices/${imedClaimId}/facility-fees`);
    }

    addFacilityFee(imedClaimId: number, imedClaimFacilityFee: IImedClaimServiceFacilityFee): Observable<number> {
        return this.http.post<number>(`/subpoenaservices/${imedClaimId}/facility-fees`, imedClaimFacilityFee);
    }

    editFacilityFee(imedClaimId: number, imedClaimFacilityFee: IImedClaimServiceFacilityFee): Observable<number> {
        return this.http.put<number>(`/subpoenaservices/${imedClaimId}/facility-fees`, imedClaimFacilityFee);
    }

    delete(id: number): Observable<any> {
        return this.http
            .delete(`/subpoenaservices/${id}/facility-fees`)
            .pipe(catchError((e: Response) => this.handleError(e)) as OperatorFunction<unknown, any>);
    }

    togglePhysicalCheckRequired(imedClaimServiceId: number): Observable<void> {
        return this.http.put<void>(`/subpoenaservices/toggleFacilityPhysicalCheckRequired/${imedClaimServiceId}`, {});
    }
}
