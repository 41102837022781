import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { finalize } from 'rxjs/operators';
import { CourtProcessServerService } from './court-process-server.service';
import { IAddressBookProcessServerDTO } from '@model/interfaces/custom/address-book-process-server.dto';

@Component({
    selector: 'app-court-process-server',
    styles: ['.custom-padded { margin: 0 0 0.5em 0; }'],
    templateUrl: './court-process-server.component.html',
})
export class CourtProcessServersComponent implements OnInit, OnDestroy {
    @Input() addreeBookId: number;
    @Input() canEdit: boolean;
    isHovered = false;
    doubleClickIsDisabled = false;

    courtProcessServers: IAddressBookProcessServerDTO[] = [];
    _courtProcessServerService: CourtProcessServerService;

    _isEditing: boolean;
    public set isEditing(v: boolean) {
        this._isEditing = v;
    }

    public get isEditing(): boolean {
        return this._isEditing;
    }

    constructor(private courtProcessServerService: CourtProcessServerService, private notificationsService: NotificationsService) {
        this._courtProcessServerService = courtProcessServerService;
    }

    ngOnInit(): void {
        this.isEditing = false;
        this.getProcessServers();
        this.addMore();
    }

    ngOnDestroy(): void {
        this.courtProcessServerService.resetProcessServers();
    }

    edit(): void {
        if (this.courtProcessServerService.newProcessServer.length === 0) {
            this.addMore();
        }
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    addMore(): void {
        if (this.courtProcessServerService.newProcessServer.length === 0 || this.isProcessServerSelected()) {
            this.courtProcessServerService.newProcessServer.push(this.courtProcessServerService.getEmptyProcessServer());
        }
    }

    isProcessServerSelected(): boolean {
        return (
            this.courtProcessServerService.newProcessServer.length > 0 &&
            !(this.courtProcessServerService.newProcessServer.filter((party) => party.AddressBookId === 0).length !== 0)
        );
    }

    getProcessServers(): void {
        this.courtProcessServerService.getAdditionalProcessServers(this.addreeBookId).subscribe((incomingDTOs: IAddressBookProcessServerDTO[]) => {
            this.courtProcessServers = incomingDTOs;
        });
    }

    cancel(): void {
        this.isEditing = false;
    }

    save(): void {
        // filter out empty object(s) with no address book selection
        this.courtProcessServerService.newProcessServer = this.courtProcessServerService.newProcessServer.filter((x) => x.AddressBookId !== 0);
        // filter duplicates out
        this.courtProcessServerService.newProcessServer.forEach((newProcessServer) => {
            if (this.courtProcessServers.some((x) => x.AddressBookId === newProcessServer.AddressBookId)) {
                this.courtProcessServerService.newProcessServer.splice(this.courtProcessServerService.newProcessServer.indexOf(newProcessServer), 1);
                this.notificationsService.warning(`${newProcessServer.Name} is already Process Server on this court`);
            }
        });
        if (this.courtProcessServerService.newProcessServer.length > 0) {
            this.courtProcessServerService.AddProcessServer(this.addreeBookId, this.courtProcessServerService.newProcessServer).subscribe(() => {
                this.notificationsService.success('Process Server added successfully');
                this.isEditing = false;
                this.courtProcessServerService.newProcessServer = [];
                this.courtProcessServerService.newProcessServer.push(this.courtProcessServerService.getEmptyProcessServer());
                this.getProcessServers();
            });
        } else {
            this.cancel();
        }
    }

    remove(item: IAddressBookProcessServerDTO, event: Event): void {
        if (event && event.stopPropagation) {
            event.stopPropagation();
        }

        this.courtProcessServerService.delete(item.Id).subscribe(() => {
            this.getProcessServers();
            this.notificationsService.success('Process Server deleted successfully');
        });
    }
}
