import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { ITestimonyDepositionService } from '../interfaces/testimony-deposition-service';
import { IUser } from '../interfaces/user';
import { IImedClaimService } from '../interfaces/imed-claim-service';

export interface ITestimonyDepositionServiceDynamicControlsParameters {
    formGroup?: string;
    imedClaimServices?: IImedClaimService[];
    confirmedBies?: IUser[];
    createdBies?: IUser[];
    modifiedBies?: IUser[];
}

export class TestimonyDepositionServiceDynamicControls {

    formGroup: string;
    imedClaimServices: IImedClaimService[];
    confirmedBies: IUser[];
    createdBies: IUser[];
    modifiedBies: IUser[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private testimonydepositionservice?: ITestimonyDepositionService, additionalParameters?: ITestimonyDepositionServiceDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'TestimonyDepositionService';
        this.imedClaimServices = additionalParameters && additionalParameters.imedClaimServices || undefined;
        this.confirmedBies = additionalParameters && additionalParameters.confirmedBies || undefined;
        this.createdBies = additionalParameters && additionalParameters.createdBies || undefined;
        this.modifiedBies = additionalParameters && additionalParameters.modifiedBies || undefined;

        this.Form = {
            Archived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived',
                name: 'Archived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.testimonydepositionservice && this.testimonydepositionservice.hasOwnProperty('Archived') && this.testimonydepositionservice.Archived !== null ? this.testimonydepositionservice.Archived : false,
            }),
            ConfirmationReceived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Confirmation Received',
                name: 'ConfirmationReceived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.testimonydepositionservice && this.testimonydepositionservice.hasOwnProperty('ConfirmationReceived') && this.testimonydepositionservice.ConfirmationReceived !== null ? this.testimonydepositionservice.ConfirmationReceived : false,
            }),
            ConfirmedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Confirmed By',
                name: 'ConfirmedById',
                options: this.confirmedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.testimonydepositionservice && this.testimonydepositionservice.ConfirmedById || null,
            }),
            CreatedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Created By',
                name: 'CreatedById',
                options: this.createdBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.testimonydepositionservice && this.testimonydepositionservice.CreatedById || null,
            }),
            DateConfirmed: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Confirmed',
                name: 'DateConfirmed',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.testimonydepositionservice && this.testimonydepositionservice.DateConfirmed || null,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.testimonydepositionservice && this.testimonydepositionservice.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.testimonydepositionservice && this.testimonydepositionservice.DateModified || null,
            }),
            ImedClaimServiceId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Imed Claim Service',
                name: 'ImedClaimServiceId',
                options: this.imedClaimServices,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.testimonydepositionservice && this.testimonydepositionservice.ImedClaimServiceId || null,
            }),
            LegacyImedClaimServiceId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Legacy Imed Claim Service',
                name: 'LegacyImedClaimServiceId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.testimonydepositionservice && this.testimonydepositionservice.hasOwnProperty('LegacyImedClaimServiceId') && this.testimonydepositionservice.LegacyImedClaimServiceId !== null ? this.testimonydepositionservice.LegacyImedClaimServiceId.toString() : '',
            }),
            ModifiedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Modified By',
                name: 'ModifiedById',
                options: this.modifiedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.testimonydepositionservice && this.testimonydepositionservice.ModifiedById || null,
            }),
            PartyThatConfirmed: new DynamicField({
                formGroup: this.formGroup,
                label: 'Party That Confirmed',
                name: 'PartyThatConfirmed',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.testimonydepositionservice && this.testimonydepositionservice.hasOwnProperty('PartyThatConfirmed') && this.testimonydepositionservice.PartyThatConfirmed !== null ? this.testimonydepositionservice.PartyThatConfirmed.toString() : '',
            }),
            PhysicianTestimonyDueDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Physician Testimony Due Date',
                name: 'PhysicianTestimonyDueDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.testimonydepositionservice && this.testimonydepositionservice.PhysicianTestimonyDueDate || null,
            }),
            PhysicianTestimonyFee: new DynamicField({
                formGroup: this.formGroup,
                label: 'Physician Testimony Fee',
                name: 'PhysicianTestimonyFee',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.testimonydepositionservice && this.testimonydepositionservice.PhysicianTestimonyFee || null,
            }),
        };

        this.View = {
            Archived: new DynamicLabel({
			    label: 'Archived',
			    value: this.testimonydepositionservice && this.testimonydepositionservice.hasOwnProperty('Archived') && this.testimonydepositionservice.Archived !== null ? this.testimonydepositionservice.Archived : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ConfirmationReceived: new DynamicLabel({
			    label: 'Confirmation Received',
			    value: this.testimonydepositionservice && this.testimonydepositionservice.hasOwnProperty('ConfirmationReceived') && this.testimonydepositionservice.ConfirmationReceived !== null ? this.testimonydepositionservice.ConfirmationReceived : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ConfirmedById: new DynamicLabel({
			    label: 'Confirmed By',
			    value: getMetaItemValue(this.confirmedBies as unknown as IMetaItem[], this.testimonydepositionservice && this.testimonydepositionservice.hasOwnProperty('ConfirmedById') && this.testimonydepositionservice.ConfirmedById !== null ? this.testimonydepositionservice.ConfirmedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            CreatedById: new DynamicLabel({
			    label: 'Created By',
			    value: getMetaItemValue(this.createdBies as unknown as IMetaItem[], this.testimonydepositionservice && this.testimonydepositionservice.hasOwnProperty('CreatedById') && this.testimonydepositionservice.CreatedById !== null ? this.testimonydepositionservice.CreatedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            DateConfirmed: new DynamicLabel({
			    label: 'Date Confirmed',
			    value: this.testimonydepositionservice && this.testimonydepositionservice.DateConfirmed || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DateCreated: new DynamicLabel({
			    label: 'Date Created',
			    value: this.testimonydepositionservice && this.testimonydepositionservice.DateCreated || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DateModified: new DynamicLabel({
			    label: 'Date Modified',
			    value: this.testimonydepositionservice && this.testimonydepositionservice.DateModified || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            ImedClaimServiceId: new DynamicLabel({
			    label: 'Imed Claim Service',
			    value: getMetaItemValue(this.imedClaimServices as unknown as IMetaItem[], this.testimonydepositionservice && this.testimonydepositionservice.hasOwnProperty('ImedClaimServiceId') && this.testimonydepositionservice.ImedClaimServiceId !== null ? this.testimonydepositionservice.ImedClaimServiceId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            LegacyImedClaimServiceId: new DynamicLabel({
			    label: 'Legacy Imed Claim Service',
			    value: this.testimonydepositionservice && this.testimonydepositionservice.hasOwnProperty('LegacyImedClaimServiceId') && this.testimonydepositionservice.LegacyImedClaimServiceId !== null ? this.testimonydepositionservice.LegacyImedClaimServiceId.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ModifiedById: new DynamicLabel({
			    label: 'Modified By',
			    value: getMetaItemValue(this.modifiedBies as unknown as IMetaItem[], this.testimonydepositionservice && this.testimonydepositionservice.hasOwnProperty('ModifiedById') && this.testimonydepositionservice.ModifiedById !== null ? this.testimonydepositionservice.ModifiedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            PartyThatConfirmed: new DynamicLabel({
			    label: 'Party That Confirmed',
			    value: this.testimonydepositionservice && this.testimonydepositionservice.hasOwnProperty('PartyThatConfirmed') && this.testimonydepositionservice.PartyThatConfirmed !== null ? this.testimonydepositionservice.PartyThatConfirmed.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            PhysicianTestimonyDueDate: new DynamicLabel({
			    label: 'Physician Testimony Due Date',
			    value: this.testimonydepositionservice && this.testimonydepositionservice.PhysicianTestimonyDueDate || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            PhysicianTestimonyFee: new DynamicLabel({
			    label: 'Physician Testimony Fee',
			    value: this.testimonydepositionservice && this.testimonydepositionservice.PhysicianTestimonyFee || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: null,
			        scale: 2,
			    })
			}
            ),
        };

    }
}
