// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `entity-list {
  font-size: 12px;
}
.search-heading {
  color: #858a9f;
  font-size: 16px;
}
.export-button {
  display: flex;
  justify-content: flex-end;
}
.export-button .btn {
  box-sizing: border-box;
  height: 18px;
  border-radius: 3px 3px 0 0;
  background-color: #ccc;
  border: 0;
  margin-right: 80px;
}
`, "",{"version":3,"sources":["webpack://./src/app/address-books/address-book-list/address-books.component.less"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ;AACA;EACI,cAAA;EACA,eAAA;AACJ;AACA;EACI,aAAA;EACA,yBAAA;AACJ;AACA;EACI,sBAAA;EACA,YAAA;EACA,0BAAA;EACA,sBAAA;EACA,SAAA;EACA,kBAAA;AACJ","sourcesContent":["entity-list {\n    font-size: 12px;\n}\n.search-heading {\n    color: #858a9f;\n    font-size: 16px;\n}\n.export-button {\n    display: flex;\n    justify-content: flex-end;\n}\n.export-button .btn {\n    box-sizing: border-box;\n    height: 18px;\n    border-radius: 3px 3px 0 0;\n    background-color: #ccc;\n    border: 0;\n    margin-right: 80px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
