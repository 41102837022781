import { IRecordRetrievalService } from '@model/interfaces/record-retrieval-service.d';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '@mt-ng2/base-service';
import { IServicePayload } from '@model/interfaces/custom/update-service-payload';
import { nullOutNestedObjects } from '../../../../common/custom/nullOutNestedObjects';
import { IFilmRetrievalExtrasDTO } from '@model/interfaces/custom/film-retrieval-extras-dto';

export const emptyRecordRetrievalService: IRecordRetrievalService = {
    Archived: false,
    CreatedById: 0,
    DateCreated: null,
    Id: 0,
    ImedClaimServiceId: 0,
    ProcessingDate: null,
    ProcessingDateChangedById: null,
    ProcessingDateChangedDate: null,
    RadiologyFacility: '',
    Reference:
        'Entire medical record including patient histories, office notes (except psychotherapy notes), test results, RADIOLOGY STUDIES, FILMS, referrals, consults, billing records, insurance records, and records sent to you by other health care providers;',
    SensitiveConditionsInitialed: false,
};

@Injectable({
    providedIn: 'root',
})
export class RecordRetrievalServiceClientService extends BaseService<IRecordRetrievalService> {
    constructor(public http: HttpClient) {
        super('/recordretrievalservices', http);
    }

    getEmptyRecordRetrievalService(): IRecordRetrievalService {
        return { ...emptyRecordRetrievalService };
    }

    getByImedClaimServiceId(imedClaimServiceId: number): Observable<IRecordRetrievalService> {
        return this.http.get<IRecordRetrievalService>(`/recordretrievalservices/getByImedClaimServiceId/${imedClaimServiceId}`);
    }

    updateService(servicePayLoad: IServicePayload<IRecordRetrievalService>): any {
        const clone: IServicePayload<IRecordRetrievalService> = JSON.parse(JSON.stringify(servicePayLoad));
        nullOutNestedObjects(clone.ParentService);
        nullOutNestedObjects(clone.ChildService);
        return this.http.put<number>(`/recordretrievalservices/update-service/${clone.ParentService.Id}`, clone);
    }
}
