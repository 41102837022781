import { Component, Input, OnInit } from '@angular/core';
import { MultiselectItem } from '@mt-ng2/multiselect-control';
import { UntypedFormGroup, AbstractControl, UntypedFormControl, ValidatorFn } from '@angular/forms';
import { DynamicField } from '@mt-ng2/dynamic-form';
import { pluralize } from '@mt-ng2/common-functions';
import { IMetaItem } from '@mt-ng2/base-service';

@Component({
    selector: 'multi-select-checkboxes',
    templateUrl: './multi-select-checkboxes.component.html',
})
export class MultiSelectCheckboxesComponent implements OnInit {
    @Input('field') field: DynamicField;
    @Input('form') form: UntypedFormGroup;

    multiSelectItems: MultiselectItem[];

    ngOnInit(): void {
        this.mapIds();
        const currentGroup = this.field.formGroup ? <UntypedFormGroup>this.form.get(this.field.formGroup) : this.form;
        if (currentGroup.get(this.field.name)) {
            currentGroup.setControl(this.field.name, this.createControl(this.field));
        } else {
            currentGroup.addControl(this.field.name, this.createControl(this.field));
        }
    }

    mapIds(): void {
        const valueIds = <number[]>this.field.value || [];
        this.multiSelectItems = this.field.options.map(
            (option: IMetaItem) => new MultiselectItem(option, valueIds && valueIds.length && valueIds.indexOf(option.Id) >= 0),
        );
    }

    createControl(configuration: any): UntypedFormControl {
        const { validation, value, disabled } = configuration;
        return new UntypedFormControl({ value: value, disabled: disabled }, validation as ValidatorFn);
    }

    getItemText(item: MultiselectItem): string {
        return item && item.Item ? item.Item.Name : '';
    }

    itemSelected(index: number): void {
        const selectedItem = this.multiSelectItems[index];
        selectedItem.Selected = !selectedItem.Selected;
        const event = {
            items: [...this.multiSelectItems],
            selectedItems: [...this.multiSelectItems.filter((item) => item.Selected).map((item) => item.Item)],
        };
        const selectedIds = event.selectedItems.map((item) => item.Id);
        this.getControl().patchValue(selectedIds.length ? selectedIds : null);
        this.getControl().markAsDirty();
    }

    getControl(): AbstractControl {
        let control = this.form.get([this.field.formGroup, this.field.name]);
        if (!control) {
            control = this.form.get(this.field.name);
        }
        return control;
    }

    hasError(error?: string): boolean {
        const control = this.getControl();
        if (error) {
            return control.hasError(error) && (error === 'maxlength' || control.touched);
        } else {
            return control.errors && (control.touched || control.errors.maxlength);
        }
    }

    showRequired(): boolean {
        return this.field.validators && this.field.validators.required && this.field.validators.showRequired;
    }

    showOptional(): boolean {
        return this.field.validators && this.field.validators.required && this.field.validators.showOptional;
    }

    getNoItemsErrorMessage(): string {
        return `no ${pluralize(this.field.label.toLowerCase())} available`;
    }
}
