import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { forkJoin, Subscription } from 'rxjs';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { IDocument } from '@model/interfaces/document';
import { AuthService } from '@mt-ng2/auth-module';
import { DynamicField, DynamicLabel, IDynamicField, IDynamicLabel } from '@mt-ng2/dynamic-form';
import { ServiceService } from '../../imed-claims/services/service.service';
import { IService } from '@model/interfaces/service';
import { IMasterFollowupType } from '@model/interfaces/master-followup-type';
import { MasterFollowUpTypeService } from '../services/master-followup-type.service';
import { MasterFollowupTypeDynamicConfig } from '../master-followup-type.dynamic-config';

@Component({
    selector: 'app-master-followup-type-basic-info',
    templateUrl: './master-followup-type-basic-info.component.html',
})
export class MasterFollowUpTypeBasicInfoComponent implements OnInit {
    @Input() masterFollowupType: IMasterFollowupType;
    @Input() canEdit: boolean;
    @ViewChild('masterFollowUpTypeInfoForm') masterFollowUpTypeInfoForm: UntypedFormGroup;
    masterFollowUpTypeServices: IService[] = [];
    isEditing = false;
    isHovered: boolean;
    config: any = { formObject: [], viewOnly: [] };
    formFactory: MasterFollowupTypeDynamicConfig<IMasterFollowupType>;
    form: UntypedFormGroup;
    doubleClickIsDisabled = false;
    currentUserId: number;
    serviceOptionsDropdown: DynamicField;

    documents: IDocument[];
    serviceOptions: any = [];

    subscriptions$ = new Subscription();

    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    get isNewMasterFollowUpType(): boolean {
        return this.masterFollowupType && this.masterFollowupType.Id ? false : true;
    }

    constructor(
        private masterFollowupTypeService: MasterFollowUpTypeService,
        private notificationsService: NotificationsService,
        private router: Router,
        private authService: AuthService,
        private serviceService: ServiceService,
    ) {}

    ngOnInit(): void {
        this.config = { formObject: [], viewOnly: [] };
        this.setConfig();
        this.currentUserId = this.authService.currentUser.getValue().Id;
        this.form = this.masterFollowUpTypeInfoForm;
        forkJoin(this.serviceService.getItems()).subscribe((forkJoinResponses) => {
            const [serviceTypes] = forkJoinResponses;
            this.masterFollowUpTypeServices = serviceTypes;
        });
    }

    setConfig(): void {
        this.formFactory = new MasterFollowupTypeDynamicConfig<IMasterFollowupType>(this.masterFollowupType);

        if (this.isNewMasterFollowUpType) {
            this.isEditing = true;
            this.config = this.formFactory.getForCreate();
        } else {
            this.config = this.formFactory.getForUpdate();
        }

        this.viewOnly = this.config?.viewOnly?.map((x: IDynamicLabel) => new DynamicLabel(x));
        this.formObject = this.config.formObject?.map((x: IDynamicField) => new DynamicField(x));
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.isNewMasterFollowUpType) {
            void this.router.navigate(['/follow-ups']);
        } else {
            this.isEditing = false;
        }
    }

    updateFks(masterFollowupType): void {
        this.masterFollowupType.FollowupTypes = masterFollowupType.FollowupTypes;
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.masterFollowupType, form.value.MasterFollowupType as IMasterFollowupType);
            this.saveMasterFollowUpType();
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error('Save failed. Please check the form and try again.');
        }
    }

    private saveMasterFollowUpType(): void {
        if (this.isNewMasterFollowUpType) {
            this.masterFollowupTypeService.createWithFks(this.masterFollowupType).subscribe((answer) => {
                this.masterFollowupType.Id = answer;
                this.masterFollowupTypeService.updateWithFks(this.masterFollowupType).subscribe(() => {
                    this.success(true);
                });
            });
            this.setConfig();
        } else {
            this.masterFollowupTypeService.updateWithFks(this.masterFollowupType).subscribe(() => {
                this.success();
            });
        }
    }

    private success(newMasterFollowupTypeSave?: boolean): void {
        if (newMasterFollowupTypeSave) {
            void this.router.navigate([`/follow-up-types/${this.masterFollowupType.Id}`]);
        } else {
            this.setConfig();
            this.isEditing = false;
        }
        this.masterFollowupTypeService.emitChange(this.masterFollowupType);
        this.notificationsService.success('Follow up Type saved successfully.');
    }
}
