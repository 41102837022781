// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            .slide {
                transition: all 0.25s ease;
            }
            .generateTemplateButton {
                position: absolute;
                bottom: 71px;
                left: 653px;
            }
            @media only screen and (max-width: 992px) {
                .generateTemplateButton {
                    bottom: 91px;
                }
            }
            @media only screen and (max-width: 768px) {
                .generateTemplateButton {
                    left: 422px;
                }
            }
        `, "",{"version":3,"sources":["webpack://./src/app/imed-claims/services/service-detail/imed-claim-service-detail.component.ts"],"names":[],"mappings":";YACY;gBAKI,0BAA0B;YAC9B;YACA;gBACI,kBAAkB;gBAClB,YAAY;gBACZ,WAAW;YACf;YACA;gBACI;oBACI,YAAY;gBAChB;YACJ;YACA;gBACI;oBACI,WAAW;gBACf;YACJ","sourcesContent":["\n            .slide {\n                -webkit-transition: all 0.25s ease;\n                -moz-transition: all 0.25s ease;\n                -o-transition: all 0.25s ease;\n                -ms-transition: all 0.25s ease;\n                transition: all 0.25s ease;\n            }\n            .generateTemplateButton {\n                position: absolute;\n                bottom: 71px;\n                left: 653px;\n            }\n            @media only screen and (max-width: 992px) {\n                .generateTemplateButton {\n                    bottom: 91px;\n                }\n            }\n            @media only screen and (max-width: 768px) {\n                .generateTemplateButton {\n                    left: 422px;\n                }\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
