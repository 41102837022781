import { TestimonyDepositionServiceDynamicControlsPartial } from '@model/partials/testimony-deposition-service.form-controls.partials';
import { ITestimonyDepositionServiceDynamicControlsParameters } from '@model/form-controls/testimony-deposition-service.form-controls';
import { ITestimonyDepositionService } from '@model/interfaces/testimony-deposition-service.d';
import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';
import { CurrencyPipe } from '@angular/common';

export class TestimonyDepositionServiceDynamicConfig<T extends ITestimonyDepositionService> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(protected currencyPipe: CurrencyPipe, private testimonyDepositionService: T, private configControls?: string[]) {
        super();
        const additionalParams: ITestimonyDepositionServiceDynamicControlsParameters = {};

        const dynamicControls = new TestimonyDepositionServiceDynamicControlsPartial(currencyPipe, this.testimonyDepositionService, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = [
                'Id',
                'ImedClaimServiceId',
                'ConfirmationReceived',
                'DateConfirmed',
                'PartyThatConfirmed',
                'ConfirmedById',
                'PhysicianTestimonyFee',
                'PhysicianTestimonyDueDate',
                'CreatedById',
                'ModifiedById',
                'DateCreated',
                'DateModified',
                'Archived',
            ];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
