import { DynamicField } from '@mt-ng2/dynamic-form';
import {
    GeneratedLetterTemplateDynamicControls,
    IGeneratedLetterTemplateDynamicControlsParameters,
} from '../form-controls/generated-letter-template.form-controls';
import { IGeneratedLetterTemplate } from '../interfaces/generated-letter-template';
import { CkEditorDynamicFieldComponent } from '@common/ckeditor-dynamic-field/ckeditor-dynamic-field.component';
import { IDynamicFieldPartial } from './dynamic-field.partial';

export class GeneratedLetterTemplateDynamicControlsPartial extends GeneratedLetterTemplateDynamicControls {
    constructor(generatedLetterTemplatePartial?: IGeneratedLetterTemplate, additionalParameters?: IGeneratedLetterTemplateDynamicControlsParameters) {
        super(generatedLetterTemplatePartial, additionalParameters);

        (<DynamicField>this.Form.Template).component = CkEditorDynamicFieldComponent;
        (<IDynamicFieldPartial>this.Form.Template).additionalOptions = { advancedEditor: true };
    }
}
