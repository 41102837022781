// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            .template-div {
                position: relative;
            }
            .template-edit-icon {
                position: absolute;
                top: 9px;
                right: 8px;
            }
            .float-right {
                position: absolute;
                right: 35px;
            }
            .shared-list {
                display: block;
                padding: 5px;
                margin: 10px 0 10px;
                word-break: break-all;
                word-wrap: break-word;
                border: 1px solid #ccc;
                border-radius: 4px;
            }
            .shared-list .col-md-2 {
                padding: 0;
            }
        `, "",{"version":3,"sources":["webpack://./src/app/imed-claims/imed-claim-basic-info/imed-claim-basic-info.component.ts"],"names":[],"mappings":";YACY;gBACI,kBAAkB;YACtB;YACA;gBACI,kBAAkB;gBAClB,QAAQ;gBACR,UAAU;YACd;YACA;gBACI,kBAAkB;gBAClB,WAAW;YACf;YACA;gBACI,cAAc;gBACd,YAAY;gBACZ,mBAAmB;gBACnB,qBAAqB;gBACrB,qBAAqB;gBACrB,sBAAsB;gBACtB,kBAAkB;YACtB;YACA;gBACI,UAAU;YACd","sourcesContent":["\n            .template-div {\n                position: relative;\n            }\n            .template-edit-icon {\n                position: absolute;\n                top: 9px;\n                right: 8px;\n            }\n            .float-right {\n                position: absolute;\n                right: 35px;\n            }\n            .shared-list {\n                display: block;\n                padding: 5px;\n                margin: 10px 0 10px;\n                word-break: break-all;\n                word-wrap: break-word;\n                border: 1px solid #ccc;\n                border-radius: 4px;\n            }\n            .shared-list .col-md-2 {\n                padding: 0;\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
