import { Component, ViewChild, Type, ViewChildren, QueryList } from '@angular/core';
import { IEntityListDynamicCellComponent, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { IEntity } from '@mt-ng2/base-service';
import { NullHostDirective } from '../../../../common/directives/null-host.directive';
import {
    CommunicationLogService,
    IResendCommunicationConfig,
    ResendCommunicationAttachmentTypes,
} from '../../../../common/services/communication-log.service';
import { CommonEmailComponent } from '../../../../common/common-email/common-email.component';
import { ICommunicationLogResponse } from '@model/interfaces/custom/communication-request';
import { CommonFaxComponent } from '../../../../common/common-fax/common-fax.component';

@Component({
    template: `
        <div (click)="loadResendComponent()">
            <i
                class="fa"
                [ngClass]="{ 'fa-envelope': communicationLog.Type === 'Email', 'fa-fax': communicationLog.Type === 'Fax' }"
                aria-hidden="true"
            ></i>
        </div>
        <ng-template null-host></ng-template>
    `,
})
export class CommunicationLogResendCellComponent implements IEntityListDynamicCellComponent {
    // For injecting email component
    @ViewChild(NullHostDirective) commonEmailHost: NullHostDirective;

    entityListComponentMembers: IEntityListComponentMembers;
    set entity(value: IEntity) {
        this.communicationLog = value as ICommunicationLogResponse;
    }

    communicationLog: ICommunicationLogResponse;

    constructor(private communicationLogService: CommunicationLogService) {}

    loadResendComponent(): void {
        const data: IResendCommunicationConfig = {
            attachmentType: this.communicationLog.Physicians.length
                ? ResendCommunicationAttachmentTypes.PHYSICIAN
                : this.communicationLog.IndividualId
                ? ResendCommunicationAttachmentTypes.INDIVIDUAL
                : this.communicationLog.TaskId
                ? ResendCommunicationAttachmentTypes.TASK
                : this.communicationLog.FollowupId
                ? ResendCommunicationAttachmentTypes.FOLLOWUP
                : this.communicationLog.ImedClaimServiceId
                ? ResendCommunicationAttachmentTypes.SERVICE
                : ResendCommunicationAttachmentTypes.CASE,
            component: this.communicationLog.Type === 'Fax' ? CommonFaxComponent : CommonEmailComponent,
            data: this.communicationLog,
        };
        this.communicationLogService.loadResendCommunicationComponent(this.commonEmailHost, data);
    }
}
