import { DynamicField, DynamicLabel, DynamicFieldType, DynamicFieldTypes, NumericInputTypes } from '@mt-ng2/dynamic-form';
import { AddressBookDynamicControls, IAddressBookDynamicControlsParameters } from '../form-controls/address-book.form-controls';
import { IAddressBook } from '../interfaces/address-book';
import { CkEditorDynamicFieldComponent } from '../../common/ckeditor-dynamic-field/ckeditor-dynamic-field.component';
import { Validators } from '@angular/forms';

export class AddressBookDynamicControlsPartial extends AddressBookDynamicControls {
    constructor(addressbookPartial?: IAddressBook, additionalParameters?: IAddressBookDynamicControlsParameters) {
        super(addressbookPartial, additionalParameters);

        (<DynamicField>this.Form.AddressBookTypeId).labelHtml = '<label>Type</label>';
        (<DynamicField>this.Form.Ein).labelHtml = '<label>EIN</label>';
        (<DynamicField>this.Form.MetaAddressBookTypeId).labelHtml = '<label>Address Book Type</label>';
        (<DynamicField>this.Form.DomainName).labelHtml =
            '<label>Identifier (<span class="text text-danger">Must be a valid email domain, such as <em>example.com</em></span>)</label>';
        (<DynamicField>this.Form.Naic).labelHtml = '<label>NAIC</label>';

        (<DynamicLabel>this.View.AddressBookTypeId).label = 'Type';
        (<DynamicLabel>this.View.MetaAddressBookTypeId).label = 'Address Book Type';
        (<DynamicLabel>this.View.Ein).label = 'EIN';
        (<DynamicField>this.View.HistoricalNotes).labelHtml = '<label>Historical Notes: </label>';
        (<DynamicField>this.View.DomainName).label = 'Identifier';
        (<DynamicLabel>this.View.Naic).label = 'NAIC';
        (<DynamicField>this.Form.StrivenId).labelHtml = '<label>Striven ID: </label>';
        (<DynamicField>this.View.StrivenId).label = 'Striven ID';
        (<DynamicField>this.Form.StrivenContactId).labelHtml = '<label>Striven Contact ID: </label>';
        (<DynamicField>this.View.StrivenContactId).label = 'Striven Contact ID';

        // Contract Rate From
        (<DynamicField>this.View.ContractRateFrom).label = 'Contract Rate';
        (<DynamicField>this.Form.ContractRateFrom).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Numeric,
            inputType: NumericInputTypes.Currency,
            scale: 2,
        });
        (<DynamicField>this.Form.ContractRateFrom).validation = [Validators.min(0.01), Validators.max(100000000000.0)];
        (<DynamicField>this.Form.ContractRateFrom).validators = { min: 0.01, max: 100000000000.0 };

        // Contract Rate To
        (<DynamicField>this.Form.ContractRateTo).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Numeric,
            inputType: NumericInputTypes.Currency,
            scale: 2,
        });
        (<DynamicField>this.Form.ContractRateTo).validation = [Validators.min(0.01), Validators.max(100000000000.0)];
        (<DynamicField>this.Form.ContractRateTo).validators = { min: 0.01, max: 100000000000.0 };

        (<DynamicField>this.Form.Notes).component = CkEditorDynamicFieldComponent;
        (<DynamicField>this.Form.DaysToProduce).validation = [Validators.min(0), Validators.max(999)];
        (<DynamicField>this.Form.DaysToProduce).validators = { min: 0, max: 999 };

        (<DynamicField>this.Form.AttachmentIdentifier).validation = [ Validators.maxLength(10)];
        (<DynamicField>this.Form.AttachmentIdentifier).validators = { maxlength: 10 };
    }
    setRequired(isRequired: boolean): void {
        (<DynamicField>this.Form.DaysToProduce).setRequired(isRequired);
    }
}
