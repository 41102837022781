import { DynamicConfig, IDynamicConfig, IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { IPhoneNumber } from '../../interfaces/phone-number';
import { IPhoneNumberDynamicControlsParameters } from '../../form-controls/phone-number.form-controls';
import { IPhoneType } from '../../interfaces/base';
import { PhoneNumberDynamicControlsPartial } from '../../partials/phone-number-partial.form-controls';
import { PhoneTypes } from '../../../common/constants/Enums';

export class PhoneNumberDynamicConfig<T extends IPhoneNumber> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private phoneNumber: T, private phoneTypes: IPhoneType[], private configControls?: string[]) {
        super();
        if (location.href.includes('cases')) {
            phoneTypes = phoneTypes.filter((x) => x.Id !== PhoneTypes.Alternate && x.Id !== PhoneTypes.Doctor);
            phoneTypes.find((x) => x.Id === PhoneTypes.Office_fax).Name = 'Fax';
        }
        const additionalParams: IPhoneNumberDynamicControlsParameters = {
            phoneTypes: phoneTypes,
        };
        const dynamicControls = new PhoneNumberDynamicControlsPartial(this.phoneNumber, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['Phone', 'PhoneTypeId', 'IsPrimary', 'Notes'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
