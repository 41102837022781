import { Component } from '@angular/core';
import { IEntityListDynamicCellComponent, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { IEntity } from '@mt-ng2/base-service';
import { IAddressBooksForList } from '@model/interfaces/address-books-for-list';

@Component({
    template: `
        <div *ngIf="addressBook && addressBook.Address1">
            <i tooltip [tooltipType]="tooltipType" [entity]="addressBook" class="fa fa-info-circle fa-lg" aria-hidden="true"></i>
            <span (click)="navigateToAddressBookList()"
                ><a>{{ primaryAddress }}</a></span
            >&nbsp;&nbsp;
        </div>
    `,
})
export class AddressBookAddressesDynamicCellComponent implements IEntityListDynamicCellComponent {
    entityListComponentMembers: IEntityListComponentMembers;
    primaryAddress: string;
    tooltipType = 'address';
    result;

    set entity(value: IEntity) {
        this.addressBook = value as IAddressBooksForList;
        this.primaryAddress = `${this.addressBook.Address1 ? this.addressBook.Address1 + ', ' : ''} ${this.addressBook.Address2 ? this.addressBook.Address2 + ', ' : ''
            }
        ${this.addressBook.City ? this.addressBook.City + ', ' : ''} ${this.addressBook.StateCode ? this.addressBook.StateCode : ''}
        ${this.addressBook.Zip ? this.addressBook.Zip : ''}`;
    }

    addressBook: IAddressBooksForList;

    constructor() { }

    navigateToAddressBookList(): void {
        window.location.assign(`#/address-books/${this.addressBook.AddressBookId}/addresses`);
    }
}
