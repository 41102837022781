import { ImedClaimAdditionalRecordDynamicControls } from "@model/form-controls/imed-claim-additional-record.form-controls";
import { IImedClaimAdditionalRecord } from "@model/interfaces/imed-claim-additional-record";
import { DynamicConfig, IDynamicFormConfig } from "@mt-ng2/dynamic-form";

export class ImedClaimAdditionalRecordDynamicConfig extends DynamicConfig<IImedClaimAdditionalRecord> {
    constructor(
        private imedClaimAdditionalRecord: IImedClaimAdditionalRecord,
        private configControls?: string[],
    ) {
        super();
        if (!configControls) {
            this.configControls = [
                'BeginningPageCount',
                'EndingPageCount',
                'Date'           
            ];
        }

        const dynamicControls = new ImedClaimAdditionalRecordDynamicControls(this.imedClaimAdditionalRecord);
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(),
            viewOnly: [],
        };
    }
}