import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '@mt-ng2/base-service';
import { IAttachmentDTO } from '@model/interfaces/custom/attachment-dto';

@Injectable({
    providedIn: 'root',
})
export class DocumentsCrudService extends BaseService<IAttachmentDTO> {
    constructor(public http: HttpClient) {
        super('/documents-crud', http);
    }
}
