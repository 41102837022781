import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IClaimType } from '@model/interfaces/claim-type';
import { UserTypeValues } from '../common/constants/user-type.enum';
import { Observable } from 'rxjs';

@Injectable()
export class ClaimTypeService extends StaticMetaItemService<IClaimType> {
    constructor(public http: HttpClient) {
        super('ClaimTypeService', 'Claim Type', 'ClaimTypeIds', '/userRoles/claimTypes', http);
    }

    public getFilteredItems(userType: UserTypeValues): Observable<IClaimType[]> {
        // TODO: performance optimization with RxJs magic i don't understand yet
        // https://gitlab.4miles.com/MilesNPM/mt-ng2-base-service/blob/develop/src/meta-item.library.ts#L93
        return this.http.get<IClaimType[]>(`/userRoles/filteredClaimTypes/${userType}`);
    }
}
