import { ICommunicationLog } from '@model/interfaces/communication-log.d';
import { EntityListConfig, EntityListColumn, IEntityListColumn, IEntityListConfig } from '@mt-ng2/entity-list-module';
import { ICommunicationLogResponse } from '@model/interfaces/custom/communication-request';
import { DatePipe } from '@angular/common';
import { EmailAddressDeleteCellComponent } from '../../../../address-books/email-address-delete-cell/email-address-delete-cell.component';
import { CommunicationLogResendCellComponent } from './communication-log-resend.component';

export class CommunicationLogEntityListConfig extends EntityListConfig {
    columns: IEntityListColumn[];
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessorFunction: function (response: ICommunicationLogResponse): string {
                        return new DatePipe('en-US').transform(response.DateSent, 'MMM d, y', 'UTC');
                    },
                    name: 'Date Sent',
                    pipes: ['date:MM/dd/yyyy'],
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    accessorFunction: function (response: ICommunicationLogResponse): string {
                        if (response.SentBy) {
                            return `${response.SentBy}`;
                        } else {
                            return 'System';
                        }
                    },
                    name: 'Sent By',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    accessors: ['Recepient'],
                    name: 'To',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    accessorFunction: function (response: ICommunicationLogResponse): string {
                        return response.Type;
                    },
                    name: 'Type',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    accessorFunction: function (response: ICommunicationLogResponse): string {
                        return response.Status;
                    },
                    name: 'Status',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    accessors: ['Body'],
                    bindToInnerHtml: true,
                    name: 'Body',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    component: CommunicationLogResendCellComponent,
                    name: 'Re-Send',
                    sort: { disableSort: true },
                }),
            ],
        };
        super(listConfig);
    }
}
