import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, OperatorFunction } from 'rxjs';

import { BaseService } from '@mt-ng2/base-service';
import { catchError } from 'rxjs/operators';
import { IAddressBookProcessServerDTO } from '@model/interfaces/custom/address-book-process-server.dto';
import { ICourtProcessServer } from '@model/interfaces/court-process-server';

export const emptyCourtProcessServerDTO: IAddressBookProcessServerDTO = {
    AddressBookId: 0,
    Id: 0,
    Name: '',
};

@Injectable()
export class CourtProcessServerService extends BaseService<ICourtProcessServer> {
    newProcessServer: IAddressBookProcessServerDTO[] = [];

    constructor(public http: HttpClient) {
        super('/address-books', http);
    }

    resetProcessServers(): void {
        this.newProcessServer = [];
    }
    getEmptyProcessServer(): IAddressBookProcessServerDTO {
        return { ...emptyCourtProcessServerDTO };
    }

    getAdditionalProcessServers(courtId: number): Observable<IAddressBookProcessServerDTO[]> {
        return this.http.get<IAddressBookProcessServerDTO[]>(`/address-books/${courtId}/process-servers`);
    }

    AddProcessServer(courtId: number, dtos: IAddressBookProcessServerDTO[]): Observable<number> {
        return this.http.post<number>(`/address-books/${courtId}/process-servers`, dtos);
    }

    delete(id: number): Observable<any> {
        return this.http.delete(`/address-books/${id}/process-servers`).pipe(catchError((e: Response) => this.handleError(e)) as OperatorFunction<unknown, any>);
    }

    getAllProcessServers(): Observable<any> {
        return this.http.get<any>(`/address-books/get-all-process-servers`);
    }
}
