import { DatePipe } from '@angular/common';
import { ILabelGenerator } from '../../../../common/interfaces/ILabelGenerator';
import { IImedClaimServiceFollowup } from '@model/interfaces/imed-claim-service-followup';

export class FollowupLabelGenerator implements ILabelGenerator {
    constructor() {}

    GetLabel(entity: IImedClaimServiceFollowup): string {
        return `<div><label>${
            entity.ReportDueBy
                ? 'Report Due By ' + this.getDateWithNoOffset(new Date(entity.ReportDueBy.toString())).toLocaleDateString()
                :  entity.ImedClaimFollowupId ? 'Case - ' + entity.FollowupType.Name : entity.FollowupType.Name
        }</label><br>${new DatePipe('en-US').transform(entity.Date, 'MMMM d, yyyy hh:mm a')}</div>`;
    }

    getDateWithNoOffset(date: Date): Date {
        const userTimezoneOffset = date.getTimezoneOffset() * 60000;
        return new Date(date.getTime() + userTimezoneOffset);
    }
}
