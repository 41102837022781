import { IEmailTemplate } from '@model/interfaces/email-template';
import { SystemGeneratedEmailTemplateEnums } from '../../common/constants/Enums';
import { IImeService } from '@model/interfaces/ime-service';

export class EmailTemplateFilterBuilder {
    emailTemplates: IEmailTemplate[] = [];
    iImeServiceModel: IImeService;

    constructor(emailTemplates: IEmailTemplate[], iImeServiceModel: IImeService) {
        this.iImeServiceModel = iImeServiceModel;
        this.emailTemplates = emailTemplates;
    }

    filterBackgroundInvestigationEmail(): EmailTemplateFilterBuilder {
        if (this.iImeServiceModel.BackgroundInvestigationRequested) {
            return this;
        }
        this.emailTemplates.filter((template) => template.Id !== +SystemGeneratedEmailTemplateEnums.BACKGROUND_INVESTIGATION);
        return this;
    }

    filterTranslationServicesEmail(): EmailTemplateFilterBuilder {
        if (this.iImeServiceModel.TranslationServicesRequested) {
            return this;
        }
        this.emailTemplates = this.emailTemplates.filter((template) => template.Id !== +SystemGeneratedEmailTemplateEnums.TRANSLATION_SERVICES);
        return this;
    }

    filterSurveillanceEmail(): EmailTemplateFilterBuilder {
        if (this.iImeServiceModel.Surveillance) {
            return this;
        }
        this.emailTemplates = this.emailTemplates.filter((template) => template.Id !== +SystemGeneratedEmailTemplateEnums.SURVEILLANCE);
        return this;
    }

    returnFilteredTemplates(): IEmailTemplate[] {
        return this.emailTemplates;
    }
}
