import { Validators } from '@angular/forms';
import { IMasterFollowupTypeDynamicControlsParameters, MasterFollowupTypeDynamicControls } from '@model/form-controls/master-followup-type.form-controls';
import { IMasterFollowupType } from '@model/interfaces/master-followup-type';
import { IService } from '@model/interfaces/service';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, IDynamicField, IDynamicFieldType, IState, SelectInputTypes } from '@mt-ng2/dynamic-form';

export interface IMasterFollowupTypeDynamicControlsParametersPartial extends IMasterFollowupTypeDynamicControlsParameters {
    services?: IService[];
    selectedServiceIdsArray?: number[];
}

export class MasterFollowupTypeDynamicControlsPartial extends MasterFollowupTypeDynamicControls {
    selectOptions: any;
    constructor(masterFollowupType?: IMasterFollowupType, additionalParameters?: IMasterFollowupTypeDynamicControlsParametersPartial) {
        super(masterFollowupType, additionalParameters);
        this.Form.associatedServices = new DynamicField({
            formGroup: this.formGroup,
            label: 'Select Services',
            name: 'associatedServices',
            options: additionalParameters?.services,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.MultiselectDropdown,
                maxToShowInSelectedText: 1,
                showSelectAllButtons: true,
            } as IDynamicFieldType),
            value: additionalParameters?.selectedServiceIdsArray,
        } as IDynamicField);
    }
}
