import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '@mt-ng2/base-service';
import { IPhysician } from '@model/interfaces/physician';
import { IPhoneNumber } from '@model/interfaces/phone-number';
import { IEmailAddress } from '@model/interfaces/email-address';
import { IPhysicianDetail } from '../../../model/interfaces/physician-detail';
import { IAddress } from '@model/interfaces/address';

export const emptyPhysician: IPhysician = {
    Auto: false,
    GeneralLiability: false,
    Id: 0,
    IndividualId: 0,
    WorkersComp: false,
};

@Injectable()
export class PhysicianService extends BaseService<IPhysician> {
    constructor(public http: HttpClient) {
        super('/physicians', http);
    }

    getByIndividualId(individualId: number): Observable<IPhysician> {
        return this.http.get<IPhysician>(`/physicians/getByIndividualId/${individualId}`);
    }

    getEmptyPhysician(): IPhysician {
        return { ...emptyPhysician };
    }

    // TODO: Not used?
    savePhysician(physician: IPhysician): any {
        return this.http.post<number>(`/physicians`, physician);
    }

    updatePhysicianStatus(physicianDetail: IPhysicianDetail): any {
        return this.http.put<number>(`/physicians/updatePhysicianStatus/${physicianDetail.Id}`, physicianDetail);
    }

    updateServicePhysicianStatus(physicianDetail: IPhysicianDetail): any {
        return this.http.put<number>(`/physicians/updateServicePhysicianStatus/${physicianDetail.Id}`, physicianDetail);
    }

    updateFeeScheduleReceivedDate(physicianId: number, gl: boolean, wc: boolean, auto: boolean): any {
        return this.http.put<number>(`/physicians/updateFeeScheduleReceivedDate/${physicianId}/${gl}/${wc}/${auto}`, physicianId);
    }

    saveSpecialties(physician: IPhysician): Observable<any> {
        return this.http.put<any>('/physicians/saveSpecialties/', physician);
    }

    saveRecruitments(physician: IPhysician): Observable<any> {
        return this.http.put<any>('/physicians/saveRecruitments/', physician);
    }

    getPhysicianAddressBook(physicianId: number): Observable<any> {
        return this.http.get<any>(`/physicians/getPhysicianAddressBook/${physicianId}`);
    }

    getPhysicianAddresses(physicianId: number): Observable<IAddress[]> {
        return this.http.get<IAddress[]>(`/physicians/getPhysicianAddresses/${physicianId}`);
    }

    getPhysicianPhones(physicianId: number): Observable<IPhoneNumber[]> {
        return this.http.get<IPhoneNumber[]>(`/physicians/getPhysicianPhones/${physicianId}`);
    }

    getPhysicianEmailAddresses(physicianId: number): Observable<IEmailAddress[]> {
        return this.http.get<IEmailAddress[]>(`/physicians/getPhysicianEmailAddresses/${physicianId}`);
    }

    getPhysicianDetailByServiceId(imedServiceId: number): Observable<IPhysicianDetail> {
        return this.http.get<IPhysicianDetail>(`/physicians/getPhysicianDetailByServiceId/${imedServiceId}`);
    }
}
