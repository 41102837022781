import { IServiceStatus } from '@model/interfaces/service-status.d';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StaticMetaItemService } from '@mt-ng2/base-service';
import { Observable } from 'rxjs';

@Injectable()
export class ServiceStatusService extends StaticMetaItemService<IServiceStatus> {
    constructor(public http: HttpClient) {
        super('ServiceStatusService', 'Status', 'StatusIds', '/options/servicestatuses', http);
    }

    getGroupedStatusIds(): Observable<any> {
        return this.http.get<any>('/options/groupedstatusids/');
    }
}
