import { IRecordRetrievalService } from '@model/interfaces/record-retrieval-service';
import { IFilmInformation } from '@model/interfaces/film-information';
import { IImedClaimService } from '@model/interfaces/imed-claim-service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import 'rxjs/operators';
import { ModalService } from '@mt-ng2/modal-module';
import { AuthService } from '@mt-ng2/auth-module';
import { environment } from '../../../../../environments/environment';

@Component({
    selector: 'app-record-retrieval-film-retrieval-information',
    templateUrl: './film-retrieval-information.component.html',
})
export class RecordRetrievalFilmRetrievalInformationComponent {
    @Input() recordRetrievalServiceModel: IRecordRetrievalService;
    @Input() iImedClaimServiceModel: IImedClaimService;
    @Input() canEdit: boolean;
    @Input() filmInformations: IFilmInformation[];
    @Output('refreshFilmInformation') refreshFilmInformation: EventEmitter<any> = new EventEmitter<any>();

    isEditing: boolean;
    isHovered: boolean;

    constructor(private modalService: ModalService, private authService: AuthService) {}

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    rebindFilmInformation(): void {
        this.refreshFilmInformation.emit();
    }

    cancelClick(): void {
        this.isEditing = false;
    }

    addAmbraStudy(): void {
        const userEmail = this.authService.currentUser.value.Email;
        const ambraUploadItemUrl =
            environment.ambraUploadUrl +
            `email_address=${encodeURIComponent(userEmail)}&suppress_notification=1&integration_key=${this.iImedClaimServiceModel.Id}`;
        this.modalService
            .showModal({
                allowEscapeKey: false,
                allowOutsideClick: false,

                confirmButtonText: 'Close',
                html: `
                    <iframe
                        src="${ambraUploadItemUrl}"
                        height="900px"
                        width="100%">
                    </iframe>
                    <i class="small">*Note: Ambra studies require time to process after upload. You will receive an email once the processing completes and the study is ready for viewing.</i>
                `,
                title: 'Upload Study',
                width: '80%',
                customClass: {
                    confirmButton: 'swal-ambra',
                },
            })
            .subscribe();
    }
}
