// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            .slide {
                transition: all 0.25s ease;
            }
        `, "",{"version":3,"sources":["webpack://./src/app/email-templates/email-template-detail/email-template-detail.component.ts"],"names":[],"mappings":";YACY;gBAKI,0BAA0B;YAC9B","sourcesContent":["\n            .slide {\n                -webkit-transition: all 0.25s ease;\n                -moz-transition: all 0.25s ease;\n                -o-transition: all 0.25s ease;\n                -ms-transition: all 0.25s ease;\n                transition: all 0.25s ease;\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
