import { Component } from '@angular/core';
import { IEntityListDynamicCellComponent, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { IEntity } from '@mt-ng2/base-service';
import { IAddressBooksForList } from '@model/interfaces/address-books-for-list';
import { emptyAddressBookSelectEntity, AddressBooksSelectService } from './address-books-select.service';

@Component({
    template: `
        <div *ngIf="imedClaimService.SearchType !== 'Physician'">
            <div>
                <button
                    class="btn btn-primary"
                    (click)="addressBookSelected(imedClaimService.FacilityName, imedClaimService.Id)"
                    *ngIf="imedClaimService.FacilityName != '' && imedClaimService.SearchType !== 'Attorney' "
                >
                    Select Facility
                </button>
            </div>
            <br />
            <div>
                <button
                    class="btn btn-primary"
                    *ngIf="imedClaimService.IndividualId > 0 && imedClaimService.SearchType != 'ProcessServer'"
                    (click)="
                        individualSelected(
                            imedClaimService.FacilityName,
                            imedClaimService.Id,
                            imedClaimService.FirstName,
                            imedClaimService.LastName,
                            imedClaimService.IndividualId
                        )
                    "
                >
                    Select Individual
                </button>
            </div>
        </div>
        <div>
            <button
                class="btn btn-primary"
                *ngIf="imedClaimService.SearchType === 'Physician'"
                (click)="
                    individualSelected(
                        imedClaimService.FacilityName,
                        imedClaimService.Id,
                        imedClaimService.FirstName,
                        imedClaimService.LastName,
                        imedClaimService.IndividualId,
                        imedClaimService.PhysicianId
                    )
                "
            >
                Select Physician
            </button>
        </div>
    `,
})
export class AddressBookSelectOptionComponent implements IEntityListDynamicCellComponent {
    entityListComponentMembers: IEntityListComponentMembers;
    set entity(value: IEntity) {
        this.imedClaimService = value as IAddressBooksForList;
    }

    imedClaimService: IAddressBooksForList;

    constructor(private addressBookSelectService: AddressBooksSelectService) { }

    addressBookSelected(facilityName: string, addressBookId: number): void {
        const addressBookSelectEntity = { ...emptyAddressBookSelectEntity };
        addressBookSelectEntity.AddressBookId = addressBookId;
        addressBookSelectEntity.AddressBookName = facilityName;
        this.addressBookSelectService.addressBookSelected(addressBookSelectEntity);
    }

    individualSelected(
        facilityName: string,
        addressBookId: number,
        firstName: string,
        lastName: string,
        individualId: number,
        physicianId?: number,
    ): void {
        const addressBookSelectEntity = { ...emptyAddressBookSelectEntity };
        addressBookSelectEntity.AddressBookId = addressBookId;
        addressBookSelectEntity.AddressBookName = facilityName;
        addressBookSelectEntity.IndividualId = individualId;
        addressBookSelectEntity.IndividualName = firstName + ' ' + lastName;
        addressBookSelectEntity.PhysicianId = physicianId;
        this.addressBookSelectService.addressBookSelected(addressBookSelectEntity);
    }
}
