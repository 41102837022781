import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { forkJoin } from 'rxjs';
import { TaskTemplateService } from '../../services/tasktemplate.service';
import { IGroupedTaskTemplatesByFollowupDTO } from '@model/interfaces/custom/grouped-task-templates-by-followup-dto';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IImedClaimType } from '@model/interfaces/imed-claim-type';
import { IFollowupTaskAssociation } from '../../system-task-associations/system-task-followup-associations/followup-task-row/followup-task-row.component';
import { MasterFollowupTypeService } from '../../services/master-followup-type.service';
import { IFilteredImedClaimFollowupTaskDTO } from '@model/interfaces/custom/filtered-imedclaim-followup-task-dto';
import { IImedClaimMasterFollowupTypeTaskTemplate } from '@model/interfaces/imed-claim-master-followup-type-task-template';
import { ImedClaimMasterFollowupTypeTaskTemplateService } from '../../services/imedclaim-followup-type-task.service';

@Component({
    selector: 'imedcliam-followup-system-task-associations',
    styles: [
        `
            .clear-padding {
                padding-left: 0;
            }
        `,
    ],
    templateUrl: './imedclaim-followup-system-task-associations.component.html',
})
export class ImedClaimFollowupSystemTaskAssociationsComponent implements OnInit, OnChanges {
    @Input() selectedImedClaimType: IImedClaimType;

    filteredImedClaimDataForTasks: IFilteredImedClaimFollowupTaskDTO;
    tasksGroupedByFollowup: IGroupedTaskTemplatesByFollowupDTO[] = [];
    filteredFollowupTaskAssociations: IImedClaimMasterFollowupTypeTaskTemplate[] = [];

    constructor(
        private taskTemplateService: TaskTemplateService,
        private followupTaskService: ImedClaimMasterFollowupTypeTaskTemplateService,
        private notificationService: NotificationsService,
        private masterFollowupTypeService: MasterFollowupTypeService,
    ) { }

    ngOnInit(): void {
        this.getFilteredServiceData();
    }

    ngOnChanges(): void {
        this.getFilteredServiceData();
    }

    getFilteredServiceData(): void {
        this.filteredImedClaimDataForTasks = null;
        forkJoin(
            this.followupTaskService.getAll(),
            this.taskTemplateService.getImedClaimTasks(this.selectedImedClaimType.Id),
            this.masterFollowupTypeService.getMasterFollowupTypesByImedClaimTypeId(this.selectedImedClaimType.Id),
            this.followupTaskService.getGroupedTaskTemplatesByService(this.selectedImedClaimType.Id),
        ).subscribe(([ followupTaskAssociations, taskTemplates, followupTypes, groupedTasks]) => {
            this.tasksGroupedByFollowup = groupedTasks;
            this.filteredFollowupTaskAssociations = followupTaskAssociations.filter((x) => x.ImedClaimTypeId === this.selectedImedClaimType.Id);
            this.filteredImedClaimDataForTasks = {
                FollowupTypes:followupTypes,
                TaskTemplates: taskTemplates,
            };
            this.filteredImedClaimDataForTasks.FollowupTypes = this.filteredImedClaimDataForTasks.FollowupTypes.filter(
                (x) => !this.filteredFollowupTaskAssociations.some((y) => y.MasterFollowupTypeId === x.Id),
            );
        });
    }


    saveTaskAssociation(event: IFollowupTaskAssociation): void {
        const followupTaskAssociation: IImedClaimMasterFollowupTypeTaskTemplate = {
            Id: 0,
            MasterFollowupTypeId: event.Followup.Id,
            ImedClaimTypeId: this.selectedImedClaimType.Id,
            TaskTemplateId: event.TaskTemplate.Id,
        };

        this.followupTaskService.create(followupTaskAssociation).subscribe((id) => {
            followupTaskAssociation.Id = id;
            this.filteredFollowupTaskAssociations.push(followupTaskAssociation);
            this.filteredImedClaimDataForTasks.FollowupTypes = this.filteredImedClaimDataForTasks.FollowupTypes.filter(
                (x) => x.Id !== followupTaskAssociation.MasterFollowupTypeId,
            );
            this.notificationService.success('Followup task association saved successfully.');
        });
    }

    addFollowupTaskAssociation(): void {
        this.tasksGroupedByFollowup.push({
            FollowupType: null,
            TaskTemplate: [],
        } as IGroupedTaskTemplatesByFollowupDTO);
    }

    deleteFollowupTaskAssociation(followupTaskAssociation: IFollowupTaskAssociation, index: number): void {
        const id = this.filteredFollowupTaskAssociations.find(
            (x) => x.MasterFollowupTypeId === followupTaskAssociation.Followup.Id && x.TaskTemplateId === followupTaskAssociation.TaskTemplate.Id,
        ).Id;
        this.followupTaskService.delete(id).subscribe(() => {
            this.filteredFollowupTaskAssociations = this.filteredFollowupTaskAssociations.filter((x) => x.Id !== id);
            this.tasksGroupedByFollowup[index].TaskTemplate = this.tasksGroupedByFollowup[index].TaskTemplate.filter(
                (x) => x.Id !== followupTaskAssociation.TaskTemplate.Id,
            );
            if (!this.tasksGroupedByFollowup[index].TaskTemplate.length) {
                this.tasksGroupedByFollowup.splice(index, 1);
                this.filteredImedClaimDataForTasks.FollowupTypes.push(followupTaskAssociation.Followup);
            }
            this.notificationService.info('Followup task association deleted successfully.');
        });
    }
}
