import { ImedClaimModule } from './imed-claims/imed-claim.module';
import { ImedManagedListModule } from './imed-managed-list/imed-managed-list.module';
import { ErrorHandler, NgModule, Injector } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpBackend } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { AuthModule } from '@mt-ng2/auth-module';
import { EnvironmentModule, EnvironmentModuleConfigToken } from '@mt-ng2/environment-module';
import { NotificationsModule } from '@mt-ng2/notifications-module';
import { BreckErrorHandler, ErrorsModuleConfigToken } from '@mt-ng2/errors-module';
import { MtLoginModule, LoginModuleConfigToken } from '@mt-ng2/login-module';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { AppRoutingModule } from './app-routing.module';
import { AppNavModule } from './nav/app-nav.module';
import { SharedModule } from './common/shared.module';
import { AppComponent } from './app.component';
import { environment } from './environments/environment';
import { CacheSearchReuseStrategy } from './common/cache-search-reuse-strategy/cache-search-reuse.strategy';
import { UserModule } from './users/user.module';
import { UserRoleModule } from './user-roles/user-roles.module';
import { AppSettingsComponent } from './app-settings.component';
import { AppSettingsService } from './app-settings.service';
import { AddressBookModule } from './address-books/address-book.module';
import { ImedCommonModule } from './common/imed-common.module';
import { MtFabSpeedDialControlModule } from '@mt-ng2/fab-speed-dial-control';
import { LoginConfigOverride } from './common/configs/login.config';
import { MtDateModule } from '@mt-ng2/date-module';
import { TaskModule } from './tasks/task.module';
import { AppStateService } from './app-state.service';
import { PocComponent } from './poc.component';
import { DocumentQueueModule } from './document-queue/document.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { KeyboardShortcutModule } from '@mt-ng2/keyboard-shortcuts-module';
import { ToastrModule } from 'ngx-toastr';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { TaskStatusFollowupEngineModule } from './task-status-followup-engine/task-status-followup-engine.module';
import { CommonSearchService } from './common/services/common-search.service';
import { setGlobalInjector } from './global-injector';
import { EmailTemplateModule } from './email-templates/email-template.module';
import { PageTitlesModuleConfigToken } from '@mt-ng2/page-titles-config';
import { ErrorConfigOverride } from './common/configs/error.config';
import { TaskRefreshInterceptor } from './task-refresh.interceptor';
import { TimeZoneInterceptor } from './time-zone.interceptor';
import { AuthConfigOverride } from './common/configs/auth.config';
import { GenerateLetterTemplateModule as GeneratedLetterTemplateModule } from './generated-letter-templates/generated-letter-template.module';
import { AutomatedTemplateModule } from './automated-templates/automated-template.module';
import { UserInactivityMonitorService } from '@app-shared/services/user-inactivity-monitor.service';
import { AddressBookAssociationModule } from './task-status-followup-engine/addressbook-status-associations/addressbook-association.module';
import { MasterFollowUpTypeModule } from './master-followup-types/master-followup-type.module';
import { MasterServiceStatusModule } from './master-service-statuses/master-service-status.module';
import { CKEditorModule } from 'ckeditor4-angular';
import { CookieModule } from '@mt-ng2/cookie';
import { MtDisableDuringHttpCallsModule } from '@mt-ng2/disable-during-http-calls';
import { NavModule } from '@mt-ng2/nav-module';
import { StateService } from '@app-shared/services/state.service';
import { CountyService } from '@app-shared/services/county.service';

@NgModule({
    bootstrap: [AppComponent],
    declarations: [AppComponent, AppSettingsComponent, PocComponent],
    exports: [CKEditorModule],
    imports: [
        BrowserModule,
        MtFabSpeedDialControlModule,
        NgbModule,
        SharedModule.forRoot(),
        HttpClientModule,
        KeyboardShortcutModule,
        NgProgressModule.withConfig({
            color: '#ff8b56',
            spinnerPosition: 'left',
            thick: false,
        }),
        NgProgressHttpModule,
        CookieModule.withOptions({ secure: true, sameSite: 'strict' }),
        EnvironmentModule,
        AuthModule.forRoot(AuthConfigOverride),
        UserModule.forRoot(),
        GeneratedLetterTemplateModule.forRoot(),
        UserRoleModule.forRoot(),
        AddressBookModule.forRoot(),
        CKEditorModule,
        TaskStatusFollowupEngineModule.forRoot(),
        DashboardModule.forRoot(),
        AddressBookAssociationModule.forRoot(),
        ImedClaimModule.forRoot(),
        ImedManagedListModule.forRoot(),
        TaskModule.forRoot(),
        EmailTemplateModule.forRoot(),
        DocumentQueueModule,
        AutomatedTemplateModule.forRoot(),
        MasterFollowUpTypeModule.forRoot(),
        MasterServiceStatusModule.forRoot(),
        AppRoutingModule,
        NotificationsModule,
        NgxMaskModule.forRoot(),
        AppNavModule.forRoot(),
        MtLoginModule,
        ImedCommonModule.forRoot(),
        MtDateModule,
        ToastrModule.forRoot({
            positionClass: 'toast-top-left',
        }),
        NoopAnimationsModule,
        NavModule,
        MtDisableDuringHttpCallsModule.forRoot(),
    ],
    providers: [
        { provide: ErrorHandler, useClass: BreckErrorHandler },
        AppSettingsService,
        AppStateService,
        UserInactivityMonitorService,
        StateService,
        CountyService,
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: RouteReuseStrategy, useClass: CacheSearchReuseStrategy, deps: [CommonSearchService, Injector] },
        { provide: HTTP_INTERCEPTORS, useClass: TaskRefreshInterceptor, multi: true, deps: [HttpBackend] },
        { provide: HTTP_INTERCEPTORS, useClass: TimeZoneInterceptor, multi: true, deps: [HttpBackend] },
        { provide: ErrorsModuleConfigToken, useValue: ErrorConfigOverride },
        { provide: LoginModuleConfigToken, useValue: LoginConfigOverride },
        { provide: EnvironmentModuleConfigToken, useValue: environment },
    ],
})
export class AppModule {
    constructor(injector: Injector) {
        setGlobalInjector(injector);
    }
}
