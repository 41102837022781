import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { IBillsForReview } from '@model/interfaces/bills-for-review';
import { Observable } from 'rxjs';

export const emptyBillsForReview: IBillsForReview = {
    Amount: 0,
    Dos: null,
    Facility: '',
    HcpcsCode: '',
    Id: 0,
    ImedClaimServiceId: 0,
};

@Injectable({
    providedIn: 'root',
})
export class BillsForReviewService extends BaseService<IBillsForReview> {
    constructor(public http: HttpClient) {
        super('/bills-for-review', http);
    }

    getEmptyBillsForReview(): IBillsForReview {
        return { ...emptyBillsForReview };
    }

    getByImedClaimServiceId(imedClaimServiceId: number): Observable<IBillsForReview[]> {
        return this.http.get<IBillsForReview[]>(`/bills-for-review/getByImedClaimServiceId/${imedClaimServiceId}`);
    }
}
