import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, OperatorFunction } from 'rxjs';
import { BaseService } from '@mt-ng2/base-service';
import { DatePipe } from '@angular/common';
import { catchError } from 'rxjs/operators';
import { IImedClaimServiceCourtFee } from '@model/interfaces/imed-claim-service-court-fee';
import { IImedClaimServiceProcessServerFee } from '@model/interfaces/imed-claim-service-process-server-fee';
import { IImedClaimServiceWitnessFee } from '@model/interfaces/imed-claim-service-witness-fee';

export const emptyImedClaimServiceWitnessFee: IImedClaimServiceWitnessFee = {
    CreatedById: 0,
    DateCreated: null,
    Fees: 0,
    Id: 0,
    ImedClaimServiceId: 0,
    PhysicalCheckToFacilityRequired: false,
    PhysicalCheckToForeignCourtRequired: false,
};

@Injectable({
    providedIn: 'root',
})
export class ImedClaimServiceWitnessService extends BaseService<IImedClaimServiceCourtFee> {

    newCourtFees: IImedClaimServiceCourtFee[] = [];
    constructor(public http: HttpClient, private datePipe: DatePipe) {
        super('/subpoenaservices', http);
    }

    getEmptyImedClaimWitnessFee(): IImedClaimServiceWitnessFee {
        return { ...emptyImedClaimServiceWitnessFee };
    }

    getWitnessFees(imedClaimId: number): Observable<IImedClaimServiceWitnessFee[]> {
        return this.http.get<IImedClaimServiceWitnessFee[]>(`/subpoenaservices/${imedClaimId}/witness-fees`);
    }

    addWitnessFee(imedClaimId: number, imedClaimWitnessFee: IImedClaimServiceWitnessFee): Observable<number> {
        return this.http.post<number>(`/subpoenaservices/${imedClaimId}/witness-fees`, imedClaimWitnessFee);
    }

    editWitnessFee(imedClaimId: number, imedClaimWitnessFee: IImedClaimServiceProcessServerFee): Observable<number> {
        return this.http.put<number>(`/subpoenaservices/${imedClaimId}/witness-fees`, imedClaimWitnessFee);
    }

    delete(id: number): Observable<any> {
        return this.http.delete(`/subpoenaservices/${id}/witness-fees`).pipe(catchError((e: Response) => this.handleError(e)) as OperatorFunction<unknown, any>);
    }

    togglePhysicalCheckRequired(imedClaimServiceId: number): any {
        return this.http.put(`/subpoenaservices/toggleWitnessPhysicalCheckRequired/${imedClaimServiceId}`, {});
    }

    togglePhysicalCheckToForeignCourtRequired(imedClaimServiceId: number): any {
        return this.http.put(`/subpoenaservices/toggleWitnessPhysicalCheckToForeignCourtRequired/${imedClaimServiceId}`, {});
    }
}
