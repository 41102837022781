import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { IFaxEmailDataDTO, IFaxEmailDataDetail } from '../select-contact-faxes/select-contact-faxes.component';
import { ImedClaimServiceService } from '../../imed-claims/services/imedclaimservice.service';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Component({
    selector: 'select-contacts-email',
    styles: [
        `
            .input-list {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            .type {
                display: flex;
                justify-content: flex-start;
            }
            .input-list > *,
            .type > * {
                margin: 5px;
            }
            .small-text {
                font-size: 12px;
                color: #777;
            }
            .scrollable {
                max-height: 710px;
                overflow-y: auto;
                overflow-x: hidden;
            }
        `,
    ],
    templateUrl: './select-contacts-email.component.html',
})
export class SelectContactsEmail implements OnInit {
    contacts: IFaxEmailDataDTO[] = [];
    includeNoContactFound = false;

    @Input() imedClaimServiceId: number;
    @Input() imedClaimId: number;
    @ViewChild('default') default;
    @Output() close: EventEmitter<any> = new EventEmitter();
    @Output() selectEmails: EventEmitter<string[]> = new EventEmitter();

    constructor(private imedClaimServiceService: ImedClaimServiceService, private notificationsService: NotificationsService) {}

    ngOnInit(): void {
        if (this.imedClaimServiceId) {
            this.imedClaimServiceService.getAllContactEmails(this.imedClaimServiceId).subscribe((contactData) => {
                this.contacts = contactData;
                if (contactData.length === 0) {
                    this.notificationsService.warning('No emails were found for this Service.');
                }
            });
        } else {
            this.imedClaimServiceService.getAllContactEmailsForCase(this.imedClaimId).subscribe((contactData) => {
                this.contacts = contactData;
                if (contactData.length === 0) {
                    this.notificationsService.warning('No emails were found for this Claim.');
                }
            });
        }
    }

    emitClose(): void {
        this.close.emit();
    }

    // Filters the FaxEmailDTO into an array of email address strings
    emitSelectedEmails(): void {
        // The selectedItems variable is set based the following:
        // 1. The contacts array is filtered based on type selected (e.g. Defense Attorney)
        // 2. The result of step 1 is mapped to be only selected email address strings under each contact returned by step 1
        // 3. The reduce method flattens the array returned by step 2 into a one dimensional array
        const selectedItems = this.contacts
            .filter((x) => x.Selected)
            .map((x) => x.Values.filter((y) => y.Selected).map((z) => z.Value))
            .reduce((acc, val) => acc.concat(val), []);
        this.selectEmails.emit(selectedItems);
        this.emitClose();
    }

    selectEmail(index: number, value: string): void {
        this.contacts[index].Values.find((x) => x.Id === parseInt(value, 10)).Selected = true;
        this.filterSelectedEmails(index);
    }

    selectContact(index: number, value: string): void {
        this.contacts[index].Selected = !this.contacts[index].Selected;
        if (!value) {
            this.contacts[index].Values.forEach((x) => (x.Selected = false));
            this.filterSelectedEmails(index);
        }
    }

    filterSelectedEmails(index): IFaxEmailDataDetail[] {
        return this.contacts[index].Values.filter((x) => x.Selected);
    }

    filterUnselectedEmails(index): IFaxEmailDataDetail[] {
        return this.contacts[index].Values.filter((x) => !x.Selected);
    }

    checkIfNoEmailsPresent(index): boolean {
        const contact = this.contacts[index];
        return contact.Values.length === 1 && contact.Values[0].Id === -1;
    }

    toggleIncludeNoContactFound(): void {
        this.includeNoContactFound = !this.includeNoContactFound;
    }
}
