import { AddressSharedEntitiesConfig } from '@model/shared-entities/addresses/address.shared-entities-config';
import { PhoneNumberSharedEntitiesConfig } from '@model/shared-entities/phone-numbers/phone-numbers.shared-entities-config';
import { IMedClaimAddressService } from './imed-claim-address.service';
import { ImedClaimEmailAddressSharedEntitiesConfig } from './imed-claim-email-address.shared-entities-config';
import { IMedClaimEmailService } from './imed-claim-email.service';
import { IMedClaimPhoneNumberService } from './imed-claim-phone.service';

export enum IMedClaimSharedEntities {
    Addresses,
    EmailAddresses,
    PhoneNumbers,
}

export const IMedClaimAddressSharedEntity = {
    config: AddressSharedEntitiesConfig,
    entityIdParam: 'AddressId',
    path: 'addresses',
    service: IMedClaimAddressService,
    sharedEntity: IMedClaimSharedEntities.Addresses,
};

export const IMedClaimEmailSharedEntity = {
    config: ImedClaimEmailAddressSharedEntitiesConfig,
    entityIdParam: 'emailAddressId',
    path: 'emailaddresses',
    service: IMedClaimEmailService,
    sharedEntity: IMedClaimSharedEntities.EmailAddresses,
};

export const IMedClaimPhoneNumberSharedEntity = {
    config: PhoneNumberSharedEntitiesConfig,
    entityIdParam: 'phoneNumberId',
    path: 'phonenumbers',
    service: IMedClaimPhoneNumberService,
    sharedEntity: IMedClaimSharedEntities.PhoneNumbers,
};
