import { Component, OnInit, OnDestroy, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { ServiceService } from '../../imed-claims/services/service.service';
import { IMetaItem, MetaItem } from '@mt-ng2/base-service';
import { forkJoin, Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import { UserRoleService } from '../../user-roles/user-role.service';
import { IMergeField } from '@model/interfaces/merge-field';
import { MergeFieldService } from '../../common/services/mergefield.service';
import { ITaskTemplate } from '@model/interfaces/task-template';
import { TaskTemplateService } from '../services/tasktemplate.service';
import { ITaskTemplateUserRoleAssociation } from '@model/interfaces/task-template-user-role-association';
import { ITaskTemplateService } from '@model/interfaces/task-template-service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AddressBookTypeIdEnums, MergeFieldTypeEnums, TaskTemplateDueDateTypes, TaskTemplateTypes, TimeFrames } from '../../common/constants/Enums';
import { safeDetectChanges } from '../../common/cdr-safety/cdr-safety.library';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { CommunicationMethodService } from '@common/communication-methods/communication-methods.service';
import { ITaskTemplateCommunicationMethod } from '../../model/interfaces/task-template-communication-method';
import { ICommunicationMethod } from '@model/interfaces/communication-method';
import { AddressBookTypeService } from '../../address-books/addressbooktype.service';
import { ITaskTemplateAddressBookType } from '@model/interfaces/task-template-address-book-type';
import { MasterServiceStatusTaskTemplateService } from '../services/service-status-task.service';
import { MasterFollowupTypeTaskTemplateService } from '../services/followup-type-task.service';
import { IMasterServiceStatusTaskTemplate } from '@model/interfaces/master-service-status-task-template';
import { IMasterFollowupTypeTaskTemplate } from '@model/interfaces/master-followup-type-task-template';
import { ITaskTemplateDueDateType } from '@model/interfaces/task-template-due-date-type';
import { TaskTemplateDueDateTypeService } from '../services/tasktemplate-due-date-type.service';
import Swal from 'sweetalert2';
import { ImedClaimTypeService } from '@common/services/imed-claim-type.service';
import { ITaskTemplateImedClaimType } from '@model/interfaces/task-template-imed-claim-type';
@Component({
    selector: 'app-add-system-task-form',
    styles: [
        `
            .dayOffsetSpan {
                position: absolute;
                top: 5.5px;
                left: 200px;
            }
            .dayOffsetInput {
                width: 50px;
            }
            .block {
                display: block;
            }
        `,
    ],
    templateUrl: './add-system-task-form.component.html',
})
export class AddSystemTaskFormComponent implements OnInit, OnDestroy {
    services: IMetaItem[];
    imedClaimTypes: IMetaItem[];
    addressBookTypes: IMetaItem[];
    userRoles: IMetaItem[];
    allMergeFields: IMergeField[] = [];
    communicationMethods: MtSearchFilterItem[] = [];
    selectedCommunicationMethods: MtSearchFilterItem[] = [];
    doubleClickIsDisabled = false;
    subscriptions = new Subscription();
    addAnother = false;
    showComponents: boolean;
    form: UntypedFormGroup;
    selectedServiceIds = new UntypedFormControl([]);
    selectedImedClaimTypeIds = new UntypedFormControl([]);
    selectedUserRoleIds = new UntypedFormControl([]);
    selectedAddressBookTypeIds = new UntypedFormControl([], []);
    taskDescription = new UntypedFormControl('');
    name = new UntypedFormControl('', [(c: AbstractControl) => Validators.required(c), Validators.minLength(1), Validators.maxLength(500)]);
    exemptFromAutoCompletion = new UntypedFormControl(null, [(c: AbstractControl) => Validators.required(c)]);
    isSchedulingAssignedTo = new UntypedFormControl(null);
    isCompilationAssignedTo = new UntypedFormControl(null);
    isReportAssignedTo = new UntypedFormControl(null);
    isRetrievalAssignedTo = new UntypedFormControl(null);
    isSubpoenaAssignedTo = new UntypedFormControl(null);
    taskTemplateDueDateTypeId = new UntypedFormControl(null);
    taskTemplateDueDateTimeframe = new UntypedFormControl(null);
    taskTemplateDueDays = new UntypedFormControl(null);
    dueInDays = new UntypedFormControl(0, [(c: AbstractControl) => Validators.required(c)]);
    dayOffsets: number[] = [];
    communicationMethodEntities: ICommunicationMethod[];
    taskTemplateTypes = TaskTemplateTypes;
    taskTemplateTypeId: number;

    copiedServiceIds: number[];
    copiedImedClaimTypeIds: number[];
    copiedAddressBookTypeIds: number[];
    copiedUserRoles: number[];
    copiedTaskDescription: string;
    copiedServiceStatusAssociations: IMasterServiceStatusTaskTemplate[] = [];
    copiedFollowUpAssociations: IMasterFollowupTypeTaskTemplate[] = [];

    taskTemplateDueDateTypes: ITaskTemplateDueDateType[] = [];

    showDueDaysField = false;
    showAdditionalDueDateConfiguration = false;

    @Output('toggleEdit') toggleEdit = new EventEmitter<void>();

    isEditForm: boolean;

    constructor(
        private fb: UntypedFormBuilder,
        private serviceService: ServiceService,
        private userRoleService: UserRoleService,
        private mergeFieldService: MergeFieldService,
        private taskTemplateService: TaskTemplateService,
        private notificationService: NotificationsService,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private communicationMethodService: CommunicationMethodService,
        private addresssBookTypeService: AddressBookTypeService,
        private statusTaskService: MasterServiceStatusTaskTemplateService,
        private followupTaskService: MasterFollowupTypeTaskTemplateService,
        private taskTemplateDueDateTypeService: TaskTemplateDueDateTypeService,
        private imedClaimTypeService: ImedClaimTypeService,
    ) {}

    prefillFieldsIfCopiedTaskExists(): void {
        const copiedTask = this.taskTemplateService.copiedTask || this.taskTemplateService.selectedTask;

        if (copiedTask) {
            if (this.taskTemplateService.copiedTask) {
                this.name.setValue(`Copy of ${copiedTask.Name}`);
            } else {
                this.name.setValue(copiedTask.Name);
            }
            const serviceIds = copiedTask.TaskTemplateServices.map((x) => x.ServiceId);
            this.copiedServiceIds = serviceIds;
            setTimeout(() => {
                this.selectedServiceIds.setValue(this.copiedServiceIds);
            });
            const imedClaimTypeIds = copiedTask.TaskTemplateImedClaimTypes.map((x) => x.ImedClaimTypeId);
            this.copiedImedClaimTypeIds = imedClaimTypeIds;
            setTimeout(() => {
                this.selectedImedClaimTypeIds.setValue(this.copiedImedClaimTypeIds);
            });
            this.copiedTaskDescription = copiedTask.Description.replace(/[^(\x20-\x7F)]*/g, '');
            this.taskDescription.setValue(copiedTask.Description);
            const userRoleIds = copiedTask.TaskTemplateUserRoleAssociations.map((x) => x.UserRoleId);
            this.copiedUserRoles = userRoleIds;
            setTimeout(() => {
                this.selectedUserRoleIds.setValue(this.copiedUserRoles);
            });
            const addressBookTypeIds = copiedTask.TaskTemplateAddressBookTypes.map((x) => x.AddressBookTypeId);
            this.copiedAddressBookTypeIds = addressBookTypeIds;
            setTimeout(() => {
                this.selectedAddressBookTypeIds.setValue(this.copiedAddressBookTypeIds);
            });
            this.exemptFromAutoCompletion.setValue(copiedTask.ExemptFromAutoCompletion ? 'true' : 'false');
            this.isSchedulingAssignedTo.setValue(copiedTask.IsSchedulingAssignedTo);
            this.isCompilationAssignedTo.setValue(copiedTask.IsCompilationAssignedTo);
            this.isReportAssignedTo.setValue(copiedTask.IsReportAssignedTo);
            this.isRetrievalAssignedTo.setValue(copiedTask.IsRetrievalAssignedTo);
            this.isSubpoenaAssignedTo.setValue(copiedTask.IsSubpoenaAssignedTo);
            this.taskTemplateTypeId = copiedTask.TaskTemplateTypeId;
            this.dueInDays.setValue(copiedTask.DueInDays);
            const dueDateOffsets = this.communicationMethods.map((x) =>
                copiedTask.TaskTemplateCommunicationMethods.find((y) => y.CommunicationMethodId === x.Item.Id) !== undefined
                    ? copiedTask.TaskTemplateCommunicationMethods.find((y) => y.CommunicationMethodId === x.Item.Id).DueDateOffset
                    : 0,
            );
            this.dayOffsets = dueDateOffsets;
            const communicationMethods = copiedTask.TaskTemplateCommunicationMethods.map((x) => x.CommunicationMethodId);
            for (let i = 0; i < communicationMethods.length; i++) {
                this.selectedCommunicationMethods.push(this.communicationMethods.find((x) => x.Item.Id === communicationMethods[i]));
            }
            this.communicationMethods.map((x) => (x.Selected = communicationMethods.includes(x.Item.Id)));
            this.copiedServiceStatusAssociations = [...copiedTask.MasterServiceStatusTaskTemplates];
            this.copiedFollowUpAssociations = [...copiedTask.MasterFollowupTypeTaskTemplates];

            this.taskTemplateDueDateTypeId.setValue(copiedTask.TaskTemplateDueDateTypeId);

            if (copiedTask.DaysBefore !== null) {
                this.showDueDaysField = true;
                this.taskTemplateDueDateTimeframe.setValue(TimeFrames.Before);
                this.taskTemplateDueDays.setValue(copiedTask.DaysBefore);
            }
            if (copiedTask.DaysOn !== null) {
                this.taskTemplateDueDateTimeframe.setValue(TimeFrames.On);
                this.taskTemplateDueDays.setValue(copiedTask.DaysOn);
            }
            if (copiedTask.DaysAfter !== null) {
                this.showDueDaysField = true;
                this.taskTemplateDueDateTimeframe.setValue(TimeFrames.After);
                this.taskTemplateDueDays.setValue(copiedTask.DaysAfter);
            }

            if ([TimeFrames.Before, TimeFrames.After].includes(this.taskTemplateDueDateTimeframe.value as TimeFrames)) {
                this.showDueDaysField = true;
            } else {
                this.showDueDaysField = false;
            }

            this.taskTemplateService.clearCopiedTask();
        } else {
            this.dayOffsets = this.communicationMethodService.getEmptyDayOffsetArray(this.communicationMethodEntities);
        }
    }

    ngOnInit(): void {
        if (this.taskTemplateService.selectedTask) {
            this.isEditForm = true;
        } else {
            this.isEditForm = false;
        }

        const copiedTask = this.taskTemplateService.copiedTask || this.taskTemplateService.selectedTask;
        if (!copiedTask) {
            this.showTaskTemplateTypeSelectionPopUp();
        }
        this.exemptFromAutoCompletion.setValue('false');
        this.form = this.fb.group({
            dueInDays: this.dueInDays,
            exemptFromAutoCompletion: this.exemptFromAutoCompletion,
            isCompilationAssignedTo: this.isCompilationAssignedTo,
            isReportAssignedTo: this.isReportAssignedTo,
            isRetrievalAssignedTo: this.isRetrievalAssignedTo,
            isSchedulingAssignedTo: this.isSchedulingAssignedTo,
            isSubpoenaAssignedTo: this.isSubpoenaAssignedTo,
            name: this.name,
            selectedAddressBookTypeIds: this.selectedAddressBookTypeIds,
            selectedServiceIds: this.selectedServiceIds,
            selectedImedClaimTypeIds: this.selectedImedClaimTypeIds,
            selectedUserRoleIds: this.selectedUserRoleIds,
            taskDescription: this.taskDescription,
            taskTemplateDueDateTypeId: this.taskTemplateDueDateTypeId,
            taskTemplateDueDateTimeframe: this.taskTemplateDueDateTimeframe,
            taskTemplateDueDays: this.taskTemplateDueDays,
        });

        forkJoin(
            this.serviceService.getItems(),
            this.userRoleService.getAll(),
            this.mergeFieldService.getAll().pipe(map((fields) => fields.filter((field) => field.MergeFieldTypeId === +MergeFieldTypeEnums.TASK))),
            this.communicationMethodService.getAll(),
            this.addresssBookTypeService.getItems(),
            this.taskTemplateDueDateTypeService.getItems(),
            this.imedClaimTypeService.getItems(),
        ).subscribe(([services, userRoles, mergeFields, communicationMethods, addressBookTypes, taskTemplateDueDateTypes, imedClaimTypes]) => {
            this.services = services.map((x) => new MetaItem(x.Id, x.Name));
            this.imedClaimTypes = imedClaimTypes.map((x) => new MetaItem(x.Id, x.Name));
            this.userRoles = userRoles.map((x) => new MetaItem(x.Id, x.Name));
            this.addressBookTypes = addressBookTypes.filter(
                (x) => x.Id === +AddressBookTypeIdEnums.Expert_panel || x.Id === +AddressBookTypeIdEnums.Physician_panel,
            );
            this.allMergeFields = mergeFields;
            this.communicationMethodEntities = communicationMethods.sort((a, b) => (a.Id > b.Id ? 1 : -1));
            this.communicationMethods = communicationMethods.map((item) => new MtSearchFilterItem(item, false));
            this.taskTemplateDueDateTypes = taskTemplateDueDateTypes;

            this.showComponents = true;
            this.prefillFieldsIfCopiedTaskExists();
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        this.taskTemplateService.copiedTask = null;
        this.taskTemplateService.selectedTask = null;
        this.cdr.detach();
    }

    onTimeframeChange(timeframe: TimeFrames) {
        if (timeframe === TimeFrames.On) {
            this.showDueDaysField = false;
        } else {
            this.showDueDaysField = true;
        }
    }

    toggleAdditionalDueDateConfiguration() {
        this.showAdditionalDueDateConfiguration = !this.showAdditionalDueDateConfiguration;
    }

    get taskTemplateForSave(): ITaskTemplate {
        const taskTemplate: ITaskTemplate = this.isEditForm ? this.taskTemplateService.selectedTask : this.taskTemplateService.getEmptyTaskTemplate();
        taskTemplate.Name = this.form.get('name').value;
        taskTemplate.Description = this.form.get('taskDescription').value?.replace(/[^(\x20-\x7F)]*/g, '');
        taskTemplate.ExemptFromAutoCompletion = this.form.get('exemptFromAutoCompletion').value;
        taskTemplate.IsSchedulingAssignedTo = this.form.get('isSchedulingAssignedTo').value ?? false;
        taskTemplate.IsCompilationAssignedTo = this.form.get('isCompilationAssignedTo').value ?? false;
        taskTemplate.IsRetrievalAssignedTo = this.form.get('isRetrievalAssignedTo').value ?? false;
        taskTemplate.IsReportAssignedTo = this.form.get('isReportAssignedTo').value ?? false;
        taskTemplate.IsSubpoenaAssignedTo = this.form.get('isSubpoenaAssignedTo').value ?? false;
        taskTemplate.TaskTemplateServices = this.form.get('selectedServiceIds').value.map((id) => {
            return {
                Id: 0,
                ServiceId: id,
                TaskTemplateId: taskTemplate.Id,
            } as ITaskTemplateService;
        });
        taskTemplate.TaskTemplateImedClaimTypes = this.form.get('selectedImedClaimTypeIds').value.map((id) => {
            return {
                Id: 0,
                ImedClaimTypeId: id,
                TaskTemplateId: taskTemplate.Id,
            } as ITaskTemplateImedClaimType;
        });
        taskTemplate.TaskTemplateUserRoleAssociations = this.form.get('selectedUserRoleIds').value.map((id) => {
            return {
                Id: 0,
                TaskTemplateId: taskTemplate.Id,
                UserRoleId: id,
            } as ITaskTemplateUserRoleAssociation;
        });
        taskTemplate.TaskTemplateAddressBookTypes = this.form.get('selectedAddressBookTypeIds').value.map((id) => {
            return {
                AddressBookTypeId: id,
                Id: 0,
                TaskTemplateId: taskTemplate.Id,
            } as ITaskTemplateAddressBookType;
        });
        taskTemplate.DueInDays = this.form.get('dueInDays').value;

        taskTemplate.TaskTemplateDueDateTypeId = this.form.get('taskTemplateDueDateTypeId').value;
        const timeframes = [TimeFrames.After, TimeFrames.Before, TimeFrames.On];
        const timeframe = this.form.get('taskTemplateDueDateTimeframe').value;

        timeframes.forEach((tf) => {
            if (tf === timeframe) {
                taskTemplate[`Days${tf}`] = this.form.get('taskTemplateDueDays').value;
            } else {
                taskTemplate[`Days${tf}`] = null;
            }
        });

        taskTemplate.TaskTemplateTypeId = this.taskTemplateTypeId;
        return taskTemplate;
    }

    onSubmit(): void {
        if (this.isEditForm) {
            this.taskTemplateService.updateWithFks(this.taskTemplateForSave).subscribe(() => {
                const id = this.taskTemplateService.selectedTask.Id;
                const taskTemplateCommunicationMethods = this.getTaskTemplateCommunicationMethods(id);
                this.taskTemplateService.createUpdateTaskTemplateCommunicationMethods(id, taskTemplateCommunicationMethods).subscribe(() => {
                    this.finalizeCopyProcess(id);
                });
            });
        } else {
            this.taskTemplateService.createWithFks(this.taskTemplateForSave).subscribe((id) => {
                const taskTemplateCommunicationMethods = this.getTaskTemplateCommunicationMethods(id);
                this.taskTemplateService.createUpdateTaskTemplateCommunicationMethods(id, taskTemplateCommunicationMethods).subscribe(() => {
                    const additionalAssociationObservables = [];
                    for (const serviceStatusAssociation of this.copiedServiceStatusAssociations) {
                        serviceStatusAssociation.TaskTemplateId = id;
                        additionalAssociationObservables.push(this.statusTaskService.create(serviceStatusAssociation));
                    }
                    for (const followupAssociation of this.copiedFollowUpAssociations) {
                        followupAssociation.TaskTemplateId = id;
                        additionalAssociationObservables.push(this.followupTaskService.create(followupAssociation));
                    }
                    if (additionalAssociationObservables.length > 0) {
                        forkJoin(additionalAssociationObservables).subscribe(() => {
                            this.finalizeCopyProcess(id);
                        });
                    } else {
                        this.finalizeCopyProcess(id);
                    }
                });
            });
        }
    }

    finalizeCopyProcess(id: number): void {
        this.success();
        this.clearCopiedValues();
        if (this.addAnother) {
            if (this.router.url.includes('add')) {
                window.location.reload();
            } else {
                void this.router.navigate(['system-tasks', 'add'], {});
            }
        } else {
            if (this.router.url.includes('add')) {
                void this.router.navigate(['system-tasks', id]);
            } else {
                window.location.reload();
            }
        }
    }

    setAddAnother() {
        this.addAnother = true;
    }

    success(): void {
        this.notificationService.success('System task saved successfully.');
    }

    error(): void {
        this.notificationService.error('Something went wrong. Please make sure to fill out all required fields and try to resubmit the template.');
    }

    clearCopiedValues(): void {
        this.taskTemplateService.clearCopiedTask();
        this.taskTemplateService.selectedTask = null;
        this.copiedServiceStatusAssociations = [];
        this.copiedFollowUpAssociations = [];
        this.copiedServiceIds = [];
        this.copiedTaskDescription = '';
        this.copiedUserRoles = [];
        this.copiedAddressBookTypeIds = [];
        this.copiedImedClaimTypeIds = [];
    }

    reloadComponents(): void {
        this.addAnother = false;
        this.showComponents = false;
        safeDetectChanges(this.cdr);
        this.showComponents = true;
    }

    cancel(): void {
        if (this.isEditForm) {
            this.toggleEdit.emit();
        } else {
            void this.router.navigate(['/system-tasks']);
        }
    }

    communicationMehtodSelectionChanged(): void {
        this.selectedCommunicationMethods = this.communicationMethods.filter((x) => x.Selected === true);
    }

    removeCommunicationMethod(communicationMethodId: number): void {
        this.dayOffsets[communicationMethodId] = 0;
        this.selectedCommunicationMethods = this.selectedCommunicationMethods.filter((x) => x.Item.Id !== communicationMethodId);
        this.communicationMethods.forEach((x) => (x.Item.Id === communicationMethodId ? (x.Selected = false) : null));
    }

    updateDueDateOffset(communicationMethodId, value): void {
        this.dayOffsets[communicationMethodId] = +value;
    }

    getTaskTemplateCommunicationMethods(taskTemplateId: number): ITaskTemplateCommunicationMethod[] {
        const taskTemplateCommunicationMethods = [];
        let index = 0;
        this.dayOffsets.forEach((dayOffset) => {
            if (dayOffset !== 0 && this.communicationMethods[this.dayOffsets.indexOf(dayOffset)].Selected === true) {
                const taskTemplateCommunicationMethod = this.taskTemplateService.getEmptyTaskCommunicationMethod();
                taskTemplateCommunicationMethod.DueDateOffset = dayOffset;
                taskTemplateCommunicationMethod.CommunicationMethodId = index;
                taskTemplateCommunicationMethod.TaskTemplateId = taskTemplateId;
                taskTemplateCommunicationMethods.push(taskTemplateCommunicationMethod);
            }
            index++;
        });
        return taskTemplateCommunicationMethods;
    }

    getDueDateOffsetIfExists(index: number): number {
        return this.dayOffsets[index] ? this.dayOffsets[index] : 0;
    }

    resetCommunicationMethods(): void {
        this.dayOffsets = this.communicationMethodService.getEmptyDayOffsetArray(this.communicationMethodEntities);
        this.selectedCommunicationMethods = [];
        this.communicationMethods.forEach((x) => {
            x.Selected = false;
        });
    }

    showTaskTemplateTypeSelectionPopUp(): void {
        void Swal.fire({
            cancelButtonText: 'Service Task',
            confirmButtonText: 'Case Task',
            showCancelButton: true,
            title: 'Select System Task Type',
            width: '500px',
        }).then((answer) => {
            if (answer.isConfirmed) {
                this.taskTemplateTypeId = TaskTemplateTypes.CASE;
            } else {
                this.taskTemplateTypeId = TaskTemplateTypes.SERVICE;
            }
        });
    }
}
