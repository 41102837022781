import { DynamicField, DynamicLabel } from '@mt-ng2/dynamic-form';
import { IImedClaimServiceCourtFeeDynamicControlsParameters, ImedClaimServiceCourtFeeDynamicControls } from '@model/form-controls/imed-claim-service-court-fee.form-controls';
import { IImedClaimServiceCourtFee } from '@model/interfaces/imed-claim-service-court-fee';

export class ImedClaimServiceCourtFeeDynamicControlsPartial extends ImedClaimServiceCourtFeeDynamicControls {
    constructor(private imedclaimservicecourtfeePartial?: IImedClaimServiceCourtFee, additionalParameters?: IImedClaimServiceCourtFeeDynamicControlsParameters) {
        super(imedclaimservicecourtfeePartial, additionalParameters);
        (<DynamicLabel>this.View.Fees).label = 'Court Fees';
        (<DynamicField>this.Form.Fees).labelHtml = '<label>Court Fees</label>';
        (<DynamicField>this.Form.Fees).label = 'Court Fees';
    }
}
