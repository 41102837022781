import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IPhysicianSpecialty } from '../interfaces/physician-specialty';
import { IUser } from '../interfaces/user';
import { IPhysician } from '../interfaces/physician';
import { ISpecialty } from '../interfaces/specialty';

export interface IPhysicianSpecialtyDynamicControlsParameters {
    formGroup?: string;
    physicians?: IPhysician[];
    specialties?: ISpecialty[];
    createdBies?: IUser[];
    modifiedBies?: IUser[];
}

export class PhysicianSpecialtyDynamicControls {

    formGroup: string;
    physicians: IPhysician[];
    specialties: ISpecialty[];
    createdBies: IUser[];
    modifiedBies: IUser[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private physicianspecialty?: IPhysicianSpecialty, additionalParameters?: IPhysicianSpecialtyDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'PhysicianSpecialty';
        this.physicians = additionalParameters && additionalParameters.physicians || undefined;
        this.specialties = additionalParameters && additionalParameters.specialties || undefined;
        this.createdBies = additionalParameters && additionalParameters.createdBies || undefined;
        this.modifiedBies = additionalParameters && additionalParameters.modifiedBies || undefined;

        this.Form = {
            Archived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived',
                name: 'Archived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physicianspecialty && this.physicianspecialty.hasOwnProperty('Archived') && this.physicianspecialty.Archived !== null ? this.physicianspecialty.Archived : false,
            }),
            CreatedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Created By',
                name: 'CreatedById',
                options: this.createdBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.physicianspecialty && this.physicianspecialty.CreatedById || null,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physicianspecialty && this.physicianspecialty.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physicianspecialty && this.physicianspecialty.DateModified || null,
            }),
            ModifiedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Modified By',
                name: 'ModifiedById',
                options: this.modifiedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physicianspecialty && this.physicianspecialty.ModifiedById || null,
            }),
            PhysicianId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Physician',
                name: 'PhysicianId',
                options: this.physicians,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.physicianspecialty && this.physicianspecialty.PhysicianId || null,
            }),
            SpecialtyId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Specialty',
                name: 'SpecialtyId',
                options: this.specialties,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.physicianspecialty && this.physicianspecialty.SpecialtyId || null,
            }),
        };

        this.View = {
            Archived: new DynamicLabel({
			    label: 'Archived',
			    value: this.physicianspecialty && this.physicianspecialty.hasOwnProperty('Archived') && this.physicianspecialty.Archived !== null ? this.physicianspecialty.Archived : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            CreatedById: new DynamicLabel({
			    label: 'Created By',
			    value: getMetaItemValue(this.createdBies as unknown as IMetaItem[], this.physicianspecialty && this.physicianspecialty.hasOwnProperty('CreatedById') && this.physicianspecialty.CreatedById !== null ? this.physicianspecialty.CreatedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            DateCreated: new DynamicLabel({
			    label: 'Date Created',
			    value: this.physicianspecialty && this.physicianspecialty.DateCreated || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DateModified: new DynamicLabel({
			    label: 'Date Modified',
			    value: this.physicianspecialty && this.physicianspecialty.DateModified || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            ModifiedById: new DynamicLabel({
			    label: 'Modified By',
			    value: getMetaItemValue(this.modifiedBies as unknown as IMetaItem[], this.physicianspecialty && this.physicianspecialty.hasOwnProperty('ModifiedById') && this.physicianspecialty.ModifiedById !== null ? this.physicianspecialty.ModifiedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            PhysicianId: new DynamicLabel({
			    label: 'Physician',
			    value: getMetaItemValue(this.physicians as unknown as IMetaItem[], this.physicianspecialty && this.physicianspecialty.hasOwnProperty('PhysicianId') && this.physicianspecialty.PhysicianId !== null ? this.physicianspecialty.PhysicianId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            SpecialtyId: new DynamicLabel({
			    label: 'Specialty',
			    value: getMetaItemValue(this.specialties as unknown as IMetaItem[], this.physicianspecialty && this.physicianspecialty.hasOwnProperty('SpecialtyId') && this.physicianspecialty.SpecialtyId !== null ? this.physicianspecialty.SpecialtyId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };

    }
}
