import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { INf10Services } from '@model/interfaces/nf10-services';
import { INf10ServicesDTO } from '@model/interfaces/custom/nf10-services-dto';
import { Observable } from 'rxjs';

export const emptyNf10Service: INf10Services = {
    Archived: false,
    CreatedById: 0,
    DateCreated: null,
    Id: 0,
    ImedClaimServiceId: 0,
};

@Injectable({
    providedIn: 'root',
})
export class Nf10ServicesService extends BaseService<INf10Services> {
    constructor(public http: HttpClient) {
        super('/nf10services', http);
    }

    getEmptyNf10Services(): INf10Services {
        return { ...emptyNf10Service };
    }

    getByImedClaimServiceId(imedClaimServiceId: number): Observable<INf10ServicesDTO> {
        return this.http.get<INf10ServicesDTO>(`/nf10services/get-by-imedclaim-service-id/${imedClaimServiceId}`);
    }
}
