export enum SoftPopUpResults {
    Passed,
    Failed,
}

export interface ISoftPopUpValidationFunctionResult {
    popup?: ISoftPopUp;
    result: SoftPopUpResults;
    isHardPopup?: boolean;
}

export interface ISoftPopUp {
    Messages: string[];
}
