import { formatAddress } from '@common/services/address-formatter.service';
import { ISubpoenaServiceDynamicControlsParameters, SubpoenaServiceDynamicControls } from '@model/form-controls/subpoena-service.form-controls';
import { ICourtProcessServer } from '@model/interfaces/court-process-server';
import { ICourtAddressBookDTO } from '@model/interfaces/custom/court-address-book-dto';
import { ISubpoenaService } from '@model/interfaces/subpoena-service';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, IDynamicFieldType, SelectInputTypes } from '@mt-ng2/dynamic-form';

export class SubpoenaServiceDynamicControlsPartial extends SubpoenaServiceDynamicControls {

    constructor(private service?: ISubpoenaService, private courts?: ICourtAddressBookDTO[], private courtProcessServers?: ICourtProcessServer[], additionalParameters?: ISubpoenaServiceDynamicControlsParameters) {
        super(service, additionalParameters);

        (<DynamicField>this.Form.ProofOfservice).labelHtml = '<label>Proof Of Service</label>';
        (<DynamicField>this.View.ProofOfservice).label = 'Proof Of Service';

        (<DynamicField>this.Form.DomesticationRequired).labelHtml = '<label>Is Domestication Required?</label>';
        (<DynamicField>this.View.DomesticationRequired).label = 'Is Domestication Required?';
        (<DynamicField>this.Form.DomesticationRequired).setRequired(true);

        (<DynamicField>this.Form.ProcessServerRequired).labelHtml = '<label>Process server required?</label>';
        (<DynamicField>this.View.ProcessServerRequired).label = 'Process server required?';

        (<DynamicField>this.Form.ForeignCourtId) = new DynamicField({
            formGroup: this.formGroup,
            label: ' Foreign Court',
            name: 'ForeignCourtId',
            options: this.courts,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.Dropdown,
            }as IDynamicFieldType),
            validators: { 'required': true },
            value: service && service.ForeignCourtId || null,
        });

        (<DynamicField>this.View.ForeignCourtId) = new DynamicField({
            formGroup: this.formGroup,
            label: ' Foreign Court',
            name: 'ForeignCourtId',
            options: this.courts,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.Dropdown,
            }as IDynamicFieldType),
            validators: { 'required': true },
            value: this.setCourtName(service),
        });

        (<DynamicField>this.Form.ProcessServerId) = new DynamicField({
             formGroup: this.formGroup,
             label: 'Process Server',
             name: 'ProcessServerId',
             options: this.courtProcessServers,
             type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.Dropdown,
             }as IDynamicFieldType),
             value: service && service.ProcessServerId || null,
         });

        (<DynamicField>this.View.ProcessServerId) = new DynamicField({
             formGroup: this.formGroup,
             label: 'Process Server',
             name: 'ProcessServerId',
             options: this.courtProcessServers,
             type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.Dropdown,
         }as IDynamicFieldType),
         value: service && service.ProcessServerId ? service.ProcessServer.FacilityName : null,
      });

       (<DynamicField>this.Form.OptionalField1).labelHtml = '<label>Optional Field 1</label>';
       (<DynamicField>this.View.OptionalField1).label = 'Optional Field 1';

       (<DynamicField>this.Form.OptionalField2).labelHtml = '<label>Optional Field 2</label>';
       (<DynamicField>this.View.OptionalField2).label = 'Optional Field 2';
    }

    setCourtName(service: any): string {
        const selectedCourt = this.courts?.find((court) => court.Id === service.ForeignCourtId);
        return selectedCourt && selectedCourt.PrimaryAddress != null ? `${selectedCourt.OriginalCourtName} - ${formatAddress(selectedCourt.PrimaryAddress)}` : selectedCourt ? `${selectedCourt.OriginalCourtName}` : null;
    }
}
