import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { IImedClaimAdditionalBillToPartyDTO } from '@model/interfaces/custom/imed-claim-additional-btp.dto';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { finalize } from 'rxjs/operators';
import { UntypedFormGroup } from '@angular/forms';
import { AuthService } from '@mt-ng2/auth-module';
import { IImedClaimService } from '@model/interfaces/imed-claim-service';
import { ISubpoenaService } from '@model/interfaces/subpoena-service';
import { ImedClaimServiceFacilityFeeDynamicControls } from '@model/form-controls/imed-claim-service-facility-fee.form-controls';
import { IImedClaimServiceFacilityFee } from '@model/interfaces/imed-claim-service-facility-fee';
import { ImedClaimServiceFacilityFeesDynamicConfig } from './subpoena-service-facility-fees-dynamic-config';
import { ImedClaimServiceFacilityFeeService } from './subpoena-service-facility-fees-service';

@Component({
    selector: 'app-imed-claim-service-facility-fees',
    styles: ['.custom-padded { margin: 0 0 0.5em 0; } ', '.highlight {background-color: #FF45B5;}'],
    templateUrl: './subpoena-service-facility-fees-component.html',
})
export class SubpoenaServiceFacilityFeesComponent implements OnInit, OnDestroy {
    @Input() imedClaimServiceId: number;
    @Input() subpoenaService: ISubpoenaService;
    @Input() canEdit: boolean;
    isHovered = false;
    doubleClickIsDisabled = false;
    FacilityFeesForm = new UntypedFormGroup({ ImedClaimServiceFacilityFee: new UntypedFormGroup({}) });
    formFactory: any;
    controls: any;

    FacilityFees: IImedClaimServiceFacilityFee[] = [];
    newFacilityFees: IImedClaimServiceFacilityFee;
    _imedClaimServiceFacilityFeesService: ImedClaimServiceFacilityFeeService;
    selectedFacilityFee: IImedClaimServiceFacilityFee;

    _isEditing: boolean;
    public set isEditing(v: boolean) {
        this._isEditing = v;
    }

    public get isEditing(): boolean {
        return this._isEditing;
    }

    constructor(
        private imedClaimServiceFacilityFeesService: ImedClaimServiceFacilityFeeService,
        private notificationsService: NotificationsService,
        private authService: AuthService,
    ) {
        this._imedClaimServiceFacilityFeesService = imedClaimServiceFacilityFeesService;
    }

    ngOnInit(): void {
        this.isEditing = false;
        this.getImedClaimServiceFacilityFees();
        this.setConfig();
    }

    setConfig(): void {
        this.newFacilityFees = this.imedClaimServiceFacilityFeesService.getEmptyImedClaimFacilityServiceFee();
        this.controls = new ImedClaimServiceFacilityFeeDynamicControls(this.newFacilityFees, null).Form;
        this.formFactory = new ImedClaimServiceFacilityFeesDynamicConfig<IImedClaimServiceFacilityFee>(this.newFacilityFees, []);
    }

    ngOnDestroy(): void {
        this.selectedFacilityFee = null;
    }

    add(): void {
        if (this.canEdit) {
            this.isEditing = true;
            this.selectedFacilityFee = null;
            this.setConfig();
        }
    }

    getImedClaimServiceFacilityFees(): void {
        this.imedClaimServiceFacilityFeesService
            .getFacilityFees(this.imedClaimServiceId)
            .subscribe((FacilityFees: IImedClaimServiceFacilityFee[]) => {
                this.FacilityFees = FacilityFees;
            });
    }

    onPhysicalCheckUpdate(): void {
        this.imedClaimServiceFacilityFeesService
            .togglePhysicalCheckRequired(this.imedClaimServiceId)
            .pipe()
            .subscribe(() => {
                this.notificationsService.success('updated successfully');
            });
    }

    cancel(): void {
        this.isEditing = false;
        this.selectedFacilityFee = null;
    }

    editFacilityFees(FacilityFee: IImedClaimServiceFacilityFee): void {
        if (!this.canEdit) {
            return;
        }
        this.isEditing = true;
        this.selectedFacilityFee = { ...FacilityFee };
        this.controls = new ImedClaimServiceFacilityFeeDynamicControls(this.selectedFacilityFee, null).Form;
    }

    save(): void {
        const form = this.FacilityFeesForm;
        if (this.selectedFacilityFee && this.selectedFacilityFee.Id > 0) {
            this.formFactory.assignFormValues(this.selectedFacilityFee, form.value.ImedClaimServiceFacilityFee);
            this.selectedFacilityFee.DateModified = new Date();
            this.selectedFacilityFee.ModifiedById = this.authService.currentUser.getValue().Id;
            this.imedClaimServiceFacilityFeesService
                .editFacilityFee(this.imedClaimServiceId, this.selectedFacilityFee)
                .subscribe(() => {
                    this.notificationsService.success('Facility Fees edited successfully');
                    this.isEditing = false;
                    this.selectedFacilityFee = null;
                    this.getImedClaimServiceFacilityFees();
                });
        } else {
            this.formFactory.assignFormValues(this.newFacilityFees, form.value.ImedClaimServiceFacilityFee);
            this.newFacilityFees.ImedClaimServiceId = this.imedClaimServiceId;
            this.newFacilityFees.DateCreated = new Date();
            this.newFacilityFees.CreatedById = this.authService.currentUser.getValue().Id;
            this.imedClaimServiceFacilityFeesService.addFacilityFee(this.imedClaimServiceId, this.newFacilityFees).subscribe(() => {
                this.notificationsService.success('Facility Fees added successfully');
                this.isEditing = false;
                this.getImedClaimServiceFacilityFees();
            });
        }
    }

    remove(item: IImedClaimAdditionalBillToPartyDTO, event: Event): void {
        if (event && event.stopPropagation) {
            event.stopPropagation();
        }

        this.imedClaimServiceFacilityFeesService.delete(item.Id).subscribe(() => {
            this.getImedClaimServiceFacilityFees();
            this.notificationsService.success('Facility Fees deleted successfully');
        });
    }
}
