import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';

import { ClaimTypes } from '@model/ClaimTypes';
import { Subscription } from 'rxjs';
import { IMasterServiceStatus } from '@model/interfaces/master-service-status';
import { MasterServiceStatusService } from '../services/master-service-status.service';

@Component({
    templateUrl: './master-service-status-detail.component.html',
})
export class MasterServiceStatusDetailComponent implements OnInit {
    masterServiceStatus: IMasterServiceStatus;
    canEdit: boolean;
    canAdd: boolean;

    routeSubscription: Subscription;

    get canDuplicate(): boolean {
        return this.canEdit && this.masterServiceStatus && this.masterServiceStatus.Id > 0;
    }

    constructor(
        private masterServiceStatusService: MasterServiceStatusService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
        private router: Router,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        // check claims
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.MasterServiceStatuses, [ClaimValues.FullAccess]);
        this.canAdd = this.canEdit;
        // get current id from route
        const id = this.getIdFromRoute();
        this.getMasterServiceStatusById(id);

        this.routeSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const id = this.getIdFromRoute();
                if (id !== this.masterServiceStatus.Id) {
                    this.masterServiceStatus = null;
                    this.getMasterServiceStatusById(id);
                }
            }
        });
    }

    ngOnDestroy(): void {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
        }
    }

    getMasterServiceStatusById(id: number): void {
        this.masterServiceStatusService.getById(id).subscribe((masterServiceStatus) => {
            if (masterServiceStatus === null) {
                this.notificationsService.error('Service Status not found');
                void this.router.navigate(['statuses']);
            }
            this.masterServiceStatus = masterServiceStatus;
            this.masterServiceStatus.ServiceStatus = masterServiceStatus.ServiceStatus.filter((ft) => !ft.Archived);
        });
    }

    getIdFromRoute(): number {
        const id = +this.route.snapshot.paramMap.get('ServiceStatusId');
        if (!id) {
            void this.router.navigate(['statuses']); // if no id found, go back to list
        }
        return id;
    }
}
