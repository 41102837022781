import { IFilmReviewService } from '@model/interfaces/film-review-service.d';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '@mt-ng2/base-service';
import { IServicePayload } from '@model/interfaces/custom/update-service-payload';
import { nullOutNestedObjects } from '../../../../common/custom/nullOutNestedObjects';
import { INote } from '@mt-ng2/note-control';

export const emptyFilmReviewService: IFilmReviewService = {
    Archived: false,
    CreatedById: 0,
    DateCreated: null,
    DiscCopiedDate: null,
    DiscMailedDate: null,
    HasPreLitigation: false,
    Id: 0,
    ImedClaimServiceId: 0,
    IsFederalCase: false,
    RadiologyFacility: '',
    RequestDate: null,
    RequestDateChangedById: null,
    RequestDateChangedDate: null,
};

@Injectable()
export class FilmReviewServiceService extends BaseService<IFilmReviewService> {
    constructor(public http: HttpClient) {
        super('/filmreviewservices', http);
    }

    getEmptyFilmReviewServiceService(): IFilmReviewService {
        return { ...emptyFilmReviewService };
    }

    getByImedClaimServiceId(imedClaimServiceId: number): Observable<IFilmReviewService> {
        return this.http.get<IFilmReviewService>(`/filmreviewservices/getByImedClaimServiceId/${imedClaimServiceId}`);
    }

    updateService(servicePayLoad: IServicePayload<IFilmReviewService>): any {
        const clone: IServicePayload<IFilmReviewService> = JSON.parse(JSON.stringify(servicePayLoad));
        nullOutNestedObjects(clone.ParentService);
        nullOutNestedObjects(clone.ChildService);
        return this.http.put<number>(`/filmreviewservices/update-service/${clone.ParentService.Id}`, clone);
    }

    updateAdjusterInquiries(imedClaimServiceId: number, notes: INote[]): any {
        return this.http.put<any>(`/imedclaimservices/${imedClaimServiceId}/notes/update`, notes);
    }

    checkForIMEService(imedClaimServiceId: number): Observable<boolean> {
        return this.http.post<boolean>(`/filmreviewservices/check-for-ime-service/${imedClaimServiceId}`, {});
    }
}
