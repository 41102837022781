import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { forkJoin, Subscription } from 'rxjs';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { IDocument } from '@model/interfaces/document';
import { AuthService } from '@mt-ng2/auth-module';
import { DynamicField, DynamicLabel, IDynamicField, IDynamicLabel } from '@mt-ng2/dynamic-form';
import { ServiceService } from '../../imed-claims/services/service.service';
import { IService } from '@model/interfaces/service';
import { IMasterServiceStatus } from '@model/interfaces/master-service-status';
import { MasterServiceStatusService } from '../services/master-service-status.service';
import { MasterServiceStatusDynamicConfig } from '../master-service-status.dynamic-config';

@Component({
    selector: 'app-master-service-status-basic-info',
    templateUrl: './master-service-status-basic-info.component.html',
})
export class MasterServiceStatusBasicInfoComponent implements OnInit {
    @Input() masterServiceStatus: IMasterServiceStatus;
    @Input() canEdit: boolean;
    @ViewChild('masterServiceStatusInfoForm') masterServiceStatusInfoForm: UntypedFormGroup;
    masterServiceStatusServices: IService[] = [];
    isEditing = false;
    isHovered: boolean;
    config: any = { formObject: [], viewOnly: [] };
    formFactory: MasterServiceStatusDynamicConfig<IMasterServiceStatus>;
    form: UntypedFormGroup;
    doubleClickIsDisabled = false;
    currentUserId: number;
    serviceOptionsDropdown: DynamicField;

    documents: IDocument[];
    serviceOptions: any = [];

    subscriptions$ = new Subscription();

    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    get isNewMasterServiceStatus(): boolean {
        return !(this.masterServiceStatus && this.masterServiceStatus.Id);
    }

    constructor(
        private masterServiceStatusService: MasterServiceStatusService,
        private notificationsService: NotificationsService,
        private router: Router,
        private authService: AuthService,
        private serviceService: ServiceService,
    ) {}

    ngOnInit(): void {
        this.config = { formObject: [], viewOnly: [] };
        this.setConfig();
        this.currentUserId = this.authService.currentUser.getValue().Id;
        this.form = this.masterServiceStatusInfoForm;
        forkJoin(this.serviceService.getItems()).subscribe((forkJoinResponses) => {
            const [serviceTypes] = forkJoinResponses;
            this.masterServiceStatusServices = serviceTypes;
        });
    }

    setConfig(): void {
        this.formFactory = new MasterServiceStatusDynamicConfig<IMasterServiceStatus>(this.masterServiceStatus);

        if (this.isNewMasterServiceStatus) {
            this.isEditing = true;
            this.config = this.formFactory.getForCreate();
        } else {
            this.config = this.formFactory.getForUpdate();
        }

        this.viewOnly = this.config?.viewOnly?.map((x: IDynamicLabel) => new DynamicLabel(x));
        this.formObject = this.config.formObject?.map((x: IDynamicField) => new DynamicField(x));
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.isNewMasterServiceStatus) {
            void this.router.navigate(['/services']);
        } else {
            this.isEditing = false;
        }
    }

    updateFks(masterServiceStatus): void {
        this.masterServiceStatus.ServiceStatus = masterServiceStatus.ServiceStatus;
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.masterServiceStatus, form.value.MasterServiceStatus as IMasterServiceStatus);
            this.saveMasterServiceStatus();
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error('Save failed. Please check the form and try again.');
        }
    }

    private saveMasterServiceStatus(): void {
        if (this.isNewMasterServiceStatus) {
            this.masterServiceStatusService.createWithFks(this.masterServiceStatus).subscribe((answer) => {
                this.masterServiceStatus.Id = answer;
                this.masterServiceStatusService.updateWithFks(this.masterServiceStatus).subscribe(() => {
                    this.success(true);
                });
            });
            this.setConfig();
        } else {
            this.masterServiceStatusService.updateWithFks(this.masterServiceStatus).subscribe(() => {
                this.success();
            });
        }
    }

    private success(newMasterServiceStatusSave?: boolean): void {
        if (newMasterServiceStatusSave) {
            void this.router.navigate([`/statuses/${this.masterServiceStatus.Id}`]);
        } else {
            this.setConfig();
            this.isEditing = false;
        }
        this.masterServiceStatusService.emitChange(this.masterServiceStatus);
        this.notificationsService.success('Service Status saved successfully.');
    }
}
