import { AddressBookService } from './../addressbook.service';
import { Component, Input, ViewChild } from '@angular/core';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { UserService } from '../../users/user.service';
import { environment } from '../../environments/environment';
import { FileItem } from 'ng2-file-upload';
import { AttachmentControlComponent } from '../../common/attachment-control/attachment-control.component';

@Component({
    selector: 'app-letterhead-upload',
    templateUrl: './letterhead-upload-component.html',
})
export class LetterHeadUploadComponent {
    @Input() addressBook: any;
    @Input() canEdit: any;
    @ViewChild('docComponent') docComponent: AttachmentControlComponent;

    isHovered: boolean;
    isEditing: boolean;
    errorMessage: string;
    imagePath: string;
    croppedFile: any;

    constructor(
        private notificationsService: NotificationsService,
        private userService: UserService,
        private addressBookService: AddressBookService,
    ) {}

    ngOnInit(): void {
        this.imagePath = environment.imgPath;
        this.isEditing = false;
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    savePhoto(): any {
        const file: FileItem = this.docComponent.uploader.queue[this.docComponent.uploader.queue.length - 1];
        if (file) {
            this.addressBookService.saveLetterheadPhoto(this.addressBook.Id as number, file._file).subscribe((answer) => {
                this.addressBook.LetterHeadImage = answer.Image;
                this.addressBook.LetterHeadImageId = answer.Image.Id;
                this.isEditing = false;
                this.notificationsService.success('Letterhead Image Saved Successfully');
            });
        }
    }

    deletePhoto(): void {
        this.addressBookService.deleteLetterheadPhoto(this.addressBook.Id as number).subscribe((answer) => {
            this.addressBook.Image = null;
            this.addressBook.LetterHeadImageId = null;
            this.isEditing = false;
            this.notificationsService.success('Letterhead Image Deleted Successfully');
        });
    }
}
