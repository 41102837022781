import { AutoImeAppointmentService } from './auto-ime-appointment.service';
import { AuthService } from '@mt-ng2/auth-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { IImedClaimService } from '@model/partials/imed-claim-service.partial.d';
import { IImeAppointment } from '@model/interfaces/ime-appointment.d';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import 'rxjs/operators';
import { ISoftPopUpValidationFunctionResult, ISoftPopUp, SoftPopUpResults } from '@model/interfaces/custom/soft-popup';
import { IPhysicianVacation } from '@model/interfaces/physician-vacation';
import { DatePipe } from '@angular/common';
import { AutoImeAppointmentDynamicConfig } from './auto-ime-appointment.dynamic-config';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment-timezone';
import { DynamicLabel, DynamicField, IDynamicFormConfig } from '@mt-ng2/dynamic-form';

@Component({
    selector: 'app-auto-ime-add-appointments',
    templateUrl: './auto-ime-add-appointments.component.html',
})
export class AutoImeAddAppointmentsComponent implements OnInit {
    @Output('onClose') onClose: EventEmitter<any> = new EventEmitter<any>();
    @Input() iImedClaimServiceModel: IImedClaimService;
    @Input() canEdit: boolean;
    @Input() vacations: IPhysicianVacation[];

    imeAppointment: IImeAppointment;
    imedClaimId: number;
    serviceId: number;

    config: IDynamicFormConfig;
    form: UntypedFormGroup;
    imeAppointmentForm: any;
    formFactory: AutoImeAppointmentDynamicConfig<IImeAppointment>;
    doubleClickIsDisabled = false;
    softPopUpErrorMessages = Function;

    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    constructor(
        private autoImeAppointmentService: AutoImeAppointmentService,
        private activatedRoute: ActivatedRoute,
        private notificationsService: NotificationsService,
        private authService: AuthService,
    ) {}

    ngOnInit(): void {
        this.config = { formObject: [], viewOnly: [] };
        this.imedClaimId = this.activatedRoute.parent.parent.snapshot.params.imedClaimId;
        this.serviceId = this.activatedRoute.parent.snapshot.params.serviceId;
        this.setConfig();
        this.softPopUpErrorMessages = this.validateAppointment.bind(this);
    }

    setConfig(): void {
        this.imeAppointment = this.autoImeAppointmentService.getEmptyImeAppointments();
        this.config = { formObject: [], viewOnly: [] };
        this.formFactory = new AutoImeAppointmentDynamicConfig<IImeAppointment>(this.imeAppointment, ['AppointmentDateTime', 'Notes']);

        this.config = this.formFactory.getForUpdate();

        this.viewOnly = this.config?.viewOnly?.map((x) => new DynamicLabel(x));
        this.formObject = this.config.formObject?.map((x) => new DynamicField(x));
    }

    formCreated(form: UntypedFormGroup): void {
        this.form = form;
    }

    createAppointment(): void {
        this.imeAppointment.Timezone = moment.tz.guess();
        this.imeAppointment.Notes = this.imeAppointment.Notes.replace(/[^(\x20-\x7F)]*/g, '');
        this.autoImeAppointmentService
            .create(this.imeAppointment)            .subscribe(() => {
                this.success();
                this.close();
            });
    }

    validateAppointment(): ISoftPopUpValidationFunctionResult {
        if (this.form.valid) {
            this.formFactory.assignFormValues(this.imeAppointment, this.form.value.ImeAppointment as IImeAppointment);
            this.imeAppointment.ImedClaimServiceId = this.iImedClaimServiceModel.Id;
            const popup: ISoftPopUp = { Messages: [] };
            if (this.vacations.length > 0) {
                const filteredDate = this.vacations.filter((item) => {
                    return (
                        new Date(item.FromDate) <= new Date(this.imeAppointment.AppointmentDateTime) &&
                        new Date(item.ToDate) >= new Date(this.imeAppointment.AppointmentDateTime)
                    );
                });

                if (filteredDate.length > 0) {
                    popup.Messages.push(
                        'Physician is on vacation from ' +
                            new DatePipe('en-US').transform(filteredDate[0].FromDate, 'MMMM d, yyyy') +
                            ' - ' +
                            new DatePipe('en-US').transform(filteredDate[0].ToDate, 'MMMM d, yyyy'),
                    );
                    return { result: SoftPopUpResults.Failed, popup: popup };
                } else {
                    return { result: SoftPopUpResults.Passed };
                }
            } else {
                return { result: SoftPopUpResults.Passed };
            }
        } else {
            markAllFormFieldsAsTouched(this.form);
            this.error();
            return { result: SoftPopUpResults.Failed };
        }
    }

    formSubmitted(): void {
        this.createAppointment();
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }

    close(): void {
        this.onClose.emit();
    }
}
