import { ILabelGenerator } from '../common/interfaces/ILabelGenerator';
import { IViewGetServicesForList } from '@model/interfaces/custom/view-get-services-for-list';
import * as moment from 'moment-timezone';
export class ImedClaimServiceLabelGenerator implements ILabelGenerator {
    constructor() { }

    GetLabel(entity: IViewGetServicesForList): string {
        const requestedSpecialty = entity.RequestedSpecialtyName
            ? `<li><strong>Requested Specialty: </strong> ${entity.RequestedSpecialtyName}</li>`
            : '';
        const appointment = entity.AppointmentDateTime
            ? `<li><strong>Appointment Date/Time:</strong> ${this.formatAppointmentDateTime(entity)}</li>`
            : '';
        const serviceAddress = entity.ServiceAddress ? `<li><strong>Exam Location:</strong> ${entity.ServiceAddress}</li>` : '';
        const label = ` <ul class="details-list">
                        <li><strong>Service:</strong> ${entity.ServiceType}</li>
                        <li><strong>Status:</strong> ${entity.ServiceStatus}</li>
                        <li><strong>Physician/Facility:</strong> ${this.parsePhysicianFacilityIndividualName(entity)}</li>
                        </ul>
                       <ul class="details-list">
                            ${requestedSpecialty}
                            ${appointment}
                            ${serviceAddress}
                        </ul>

`;
        return label;
    }
    parsePhysicianFacilityIndividualName(service: IViewGetServicesForList): string {
        if (service.PhysicianName.trim() !== '') {
            return service.PhysicianName;
        } else if (service.IndividualName.trim() !== '' && service.FacilityName.trim() !== '') {
            return service.IndividualName + ' / ' + service.FacilityName;
        } else if (service.IndividualName.trim() !== '' && service.FacilityName === '') {
            return service.IndividualName;
        } else if (service.FacilityName.trim() !== '') {
            return service.FacilityName;
        }
        return 'Not assigned';
    }

    formatAppointmentDateTime(service: IViewGetServicesForList): string {
        return moment.tz(service.AppointmentDateTime, service.Timezone).format('LLL');
    }
}
