import { Component, OnInit, OnDestroy } from '@angular/core';
import { AssociationsToggleService, AssociationTypeEnums } from '../../services/associations-toggle.service';
import { Subscription } from 'rxjs';
import { IService } from '@model/interfaces/service';

@Component({
    selector: 'system-task-association-detail',
    templateUrl: './system-task-association-detail.component.html',
})
export class SystemTaskAssociationsDetailComponent implements OnInit, OnDestroy {
    showStatusAssociationComponent: boolean;
    showFollowupAssociationComponent: boolean;
    showFollowupExemptionComponent: boolean;
    showEmailTemplateComponent: boolean;
    associationTypeSubscription: Subscription;
    private _selectedService: IService;
    get selectedService(): IService {
        return this._selectedService;
    }
    set selectedService(val: IService) {
        this._selectedService = val;
    }

    constructor(private associationToggleService: AssociationsToggleService) { }

    ngOnInit(): void {
        this.associationTypeSubscription = this.associationToggleService.getAssociationType().subscribe((type) => {
            this.showStatusAssociationComponent = type === AssociationTypeEnums.SERVICE_STATUS;
            this.showFollowupAssociationComponent = type === AssociationTypeEnums.FOLLOWUP_Type;
            this.showFollowupExemptionComponent = type === AssociationTypeEnums.FOLLOWUP_Type_Exemptions;
            this.showEmailTemplateComponent = type === AssociationTypeEnums.EMAIL_ASSOCIATIONS;
        });
    }

    ngOnDestroy(): void {
        this.associationTypeSubscription.unsubscribe();
    }
}
