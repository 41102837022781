import { ILabelGenerator } from '../../common/interfaces/ILabelGenerator';
import { IEmailAddress } from '@model/interfaces/email-address';

/*
Ordinarily I would make this Injectable but this interface is small enough
and only used in one place so I think it would be too much work for little reward.
*/
export class EmailLabelGenerator implements ILabelGenerator {
    GetLabel(entity: IEmailAddress): string {
        return `${entity.IsPrimary ? '<i class="fa fa-star btn selected"></i>' : ''}
        ${entity.EmailType ? `[${entity.EmailType.Name}] ` : ''}<strong>${entity.Email}</strong>
        ${entity.Notes ? `<br /><div style="padding-top:15px"><label>Notes: </label> ${entity.Notes}</div>` : ''}`;

    }

    GetDropdownLabel(entity: IEmailAddress): string {
        return `${entity.IsPrimary ? '(primary)' : ''} ${entity.EmailType ? `[${entity.EmailType.Name}] ` : ''} ${entity.Email}`;
    }
}
